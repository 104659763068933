import { lazier } from '@shared';

export const MyPaymentLogsPage = lazier(() => import('./MyPaymentLogsPage'));
export const UnitPaymentLogsPage = lazier(() =>
  import('./UnitPaymentLogsPage'),
);
export const YouthPaymentLogsPage = lazier(() =>
  import('./YouthPaymentLogsPage'),
);

export { default as PaymentLogWidget } from './PaymentLogWidget';
