import React from 'react';

import { EditActivityButton } from '../../../common';
import { openEditEagleProject, loadAndOpenEagleProjectModal } from '../../duck';

const EditEagleProjectButton = props => (
  <EditActivityButton
    onLoadAction={loadAndOpenEagleProjectModal}
    onOpenAction={openEditEagleProject}
    {...props}
  />
);

export default EditEagleProjectButton;
