import { isMobileSel } from '@screen';
import { gtm, navigateToReports, navigateToRoster } from '@shared';

import { moduleName } from '../constants';
import { poidSel } from './advancementReport.selectors';

const namespace = `${moduleName}`;

export const ROUTE_EDIT_ADVANCEMENT_REPORT = `${namespace}/ROUTE_EDIT_ADVANCEMENT_REPORT`;
export const routeEditAdvancementReport = () => ({
  type: ROUTE_EDIT_ADVANCEMENT_REPORT,
});

export const OPEN_ADVANCEMENT_REPORT_MODAL = `${namespace}/OPEN_ADVANCEMENT_REPORT_MODAL`;
export const CLOSE_ADVANCEMENT_REPORT_MODAL = `${namespace}/CLOSE_ADVANCEMENT_REPORT_MODAL`;
export const openAdvancementReportModal = reportInfo => ({
  type: OPEN_ADVANCEMENT_REPORT_MODAL,
  payload: reportInfo,
  gtm: {
    category: gtm.categories.REPORTS,
  },
});
export const closeAdvancementReportModal = () => ({
  type: CLOSE_ADVANCEMENT_REPORT_MODAL,
  gtm: {
    category: gtm.categories.REPORTS,
  },
});

export const cancelAdvancementReportModal = () => (dispatch, getState) => {
  const isMobile = isMobileSel(getState());
  const poid = poidSel(getState());

  dispatch(closeAdvancementReportModal());

  if (isMobile || poid) {
    dispatch(navigateToRoster());
  }
};

export const SET_EDITED_REPORT = `${namespace}/SET_EDITED_REPORT`;
export const setEditedReport = report => ({
  type: SET_EDITED_REPORT,
  payload: report,
});

export const reviewReport = report => dispatch => {
  dispatch(setEditedReport(report));
  dispatch(openAdvancementReportModal());
};

export const GENERATE_ADVANCEMENT_REPORT_REQUEST = `${namespace}/GENERATE_ADVANCEMENT_REPORT_REQUEST`;
export const GENERATE_ADVANCEMENT_REPORT_RESPONSE = `${namespace}/GENERATE_ADVANCEMENT_REPORT_RESPONSE`;
export const GENERATE_ADVANCEMENT_REPORT_ERROR = `${namespace}/GENERATE_ADVANCEMENT_REPORT_ERROR`;

export const generateAdvancementReportRequest = settings => ({
  type: GENERATE_ADVANCEMENT_REPORT_REQUEST,
  payload: settings,
  gtm: {
    category: gtm.categories.REPORTS,
  },
});
export const generateAdvancementReportResponse = poid => ({
  type: GENERATE_ADVANCEMENT_REPORT_RESPONSE,
  payload: poid,
});
export const generateAdvancementReportError = error => ({
  type: GENERATE_ADVANCEMENT_REPORT_ERROR,
  payload: error,
});

export const ADVANCEMENT_REPORT_REQUEST = `${namespace}/ADVANCEMENT_REPORT_REQUEST`;
export const ADVANCEMENT_REPORT_RESPONSE = `${namespace}/ADVANCEMENT_REPORT_RESPONSE`;
export const ADVANCEMENT_REPORT_ERROR = `${namespace}/ADVANCEMENT_REPORT_ERROR`;
export const advancementReportRequest = organizationGuid => ({
  type: ADVANCEMENT_REPORT_REQUEST,
  payload: organizationGuid,
  gtm: {
    category: gtm.categories.REPORTS,
  },
});
export const advancementReportResponse = report => ({
  type: ADVANCEMENT_REPORT_RESPONSE,
  payload: report,
});
export const advancementReportError = error => ({
  type: ADVANCEMENT_REPORT_ERROR,
  payload: error,
});

export const FETCH_ADVANCEMENT_REPORT = `${namespace}/FETCH_ADVANCEMENT_REPORT`;
export const fetchAdvancementReport = () => ({
  type: FETCH_ADVANCEMENT_REPORT,
});

export const LOAD_ADVANCEMENT_REPORT = `${namespace}/LOAD_ADVANCEMENT_REPORT`;
export const loadAdvancementReport = report => ({
  type: LOAD_ADVANCEMENT_REPORT,
  payload: report,
});

export const SET_SELECTED_ADVANCEMENTS = `${namespace}/SET_SELECTED_ADVANCEMENTS`;
export const setSelectedAdvancements = (advancementType, keys) => ({
  type: SET_SELECTED_ADVANCEMENTS,
  payload: { advancementType, keys },
});

export const SET_SELECTED_YOUTH = `${namespace}/SET_SELECTED_YOUTH`;
export const setSelectedYouth = (advancementType, advancementId, keys) => ({
  type: SET_SELECTED_YOUTH,
  payload: { advancementType, advancementId, keys },
});

export const SET_AWARDED_YOUTH = `${namespace}/SET_AWARDED_YOUTH`;
export const setAwardedYouth = (advancementType, advancementId, keys) => ({
  type: SET_AWARDED_YOUTH,
  payload: { advancementType, advancementId, keys },
});

export const SET_SEARCH = `${namespace}/SET_SEARCH`;
export const setSearch = search => ({ type: SET_SEARCH, payload: search });

export const SET_SHOW_PRICE = `${namespace}/SET_SHOW_PRICE`;
export const setShowPrice = show => ({ type: SET_SHOW_PRICE, payload: show });

export const SET_NOTES = `${namespace}/SET_NOTES`;
export const setNotes = notes => ({ type: SET_NOTES, payload: notes });

export const goToReports = () => dispatch => {
  dispatch(closeAdvancementReportModal());
  dispatch(navigateToReports());
};
