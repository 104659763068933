import React from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Draggable from 'react-draggable';

import { API_SWITCH_DEV } from '@config';

import ApiEnvInfo from '../ApiEnvInfo';
import DevMockHighlightToggle from '../DevMockHighlightToggle';
import styles from './DevWidget.less';

class DevWidget extends React.PureComponent {
  render() {
    return (
      <Draggable handle={`.${styles.handle}`}>
        <div className={styles.container}>
          <div className={styles.handle}>
            <MoreHorizIcon />
          </div>
          <DevMockHighlightToggle />
          {API_SWITCH_DEV && <ApiEnvInfo />}
        </div>
      </Draggable>
    );
  }
}

export default DevWidget;
