/* eslint-disable react/display-name */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon, Radio } from 'bsa-ui';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { SBL_4724_PENDING_REQUIREMENTS } from '@config';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { useUnitPendingLeadershipPositions } from '@modules/leadershipPositions/hooks';
import { notificationCountsSel } from '@modules/userNotifications/duck';
import { isMobileSel } from '@screen';
import {
  Badge,
  ConditionalRender,
  S,
  SearchInput,
  Spin,
  activityTypeIds,
  advancementHistoryRequestIfNotLoaded,
  goToActivityLogs,
  goToAdultActivityLogs,
  goToAdultProfile,
  goToYouthProfile,
  intl,
  offlineSel,
  pendingAdvancementsByTypeSel,
  radioGroupStyles,
  shouldFilterUsersBySubunitsSel,
} from '@shared';

import { currentUserSubunitsSel } from '../../../packRoster/duck';
import { PendingItemsTabs, tabsToAdvancementTypeMap } from '../../constants';
import {
  filteredItemsSel,
  getPendingItemsTabsSel,
  loadingSel,
  pendingItemsActiveTabSel,
  searchFilterSel,
  selectedRowKeysSel,
  setPendingItemsActiveTab,
  setSearchFilter,
  setSelectedRowKeys,
  setSorter,
  sorterSel,
} from '../../duck';
import styles from './PendingItems.less';
import PendingItemsList from './PendingItemsList';
import { PendingRequirementsList } from './PendingItemsList/PendingRequirementsList';
import PendingItemsTable from './PendingItemsTable';
import { PendingLeadershipPositionsTable } from './PendingItemsTable/PendingLeadershipPositionsTable';
import { PendingRequirementsTable } from './PendingItemsTable/PendingRequirementsTable';

const antIcon = <Icon type="loading" spin style={{ fontSize: 12 }} />;

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const enableGrouping = !!SBL_4724_PENDING_REQUIREMENTS;

const isUserInSubUnit = (item, mySubUnits = []) =>
  item.positions?.some(pos =>
    mySubUnits.some(mySub => {
      const mySubId = mySub.patrolId || mySub.denId;
      const posSubId = pos.patrolId || pos.denId;
      return mySubId === posSubId;
    }),
  );

const PendingItems = () => {
  const dispatch = useDispatch();
  const tabs = useSelector(getPendingItemsTabsSel);
  const activeTab = useSelector(pendingItemsActiveTabSel);

  const pendingByType = useSelector(pendingAdvancementsByTypeSel);
  const { pendingCount: pendingPositionsCount } =
    useUnitPendingLeadershipPositions();

  const isMobile = useSelector(isMobileSel);
  const isOffline = useSelector(offlineSel);
  const isLoading = useSelector(loadingSel);
  const items = useSelector(filteredItemsSel);
  const search = useSelector(searchFilterSel);
  const sorter = useSelector(sorterSel);
  const notificationCounts = useSelector(notificationCountsSel);
  const enableNewCounts = featureFlags.getFlag('SBL_5150_DASHBOARD_API');
  const selectedRowKeys = useSelector(selectedRowKeysSel);
  const shouldFilterUsersBySubunits = useSelector(
    shouldFilterUsersBySubunitsSel,
  );

  const mySubUnits = useSelector(currentUserSubunitsSel);

  const { filteredItems, filteredPendingByType } = useMemo(() => {
    if (!shouldFilterUsersBySubunits) {
      return { filteredItems: items, filteredPendingByType: pendingByType };
    }

    const filtered = items.filter(item => isUserInSubUnit(item, mySubUnits));
    const filteredByType = Object.keys(pendingByType).reduce(
      (curr, categoryKey) => {
        const reduced = pendingByType[categoryKey].filter(item =>
          isUserInSubUnit(item, mySubUnits),
        );
        return { ...curr, [categoryKey]: reduced };
      },
      {},
    );
    return { filteredItems: filtered, filteredPendingByType: filteredByType };
  }, [items, pendingByType, mySubUnits, shouldFilterUsersBySubunits]);

  const itemsByType = useMemo(
    () =>
      filteredItems.filter(item => {
        if (activeTab === PendingItemsTabs.ACTIVITIES)
          return item.type === 'activity';

        return item.advancementType === activeTab;
      }),
    [filteredItems, activeTab],
  );

  const [searchPinned, setSearchPinned] = useState(false);

  useEffect(
    () => () => {
      dispatch(setPendingItemsActiveTab(PendingItemsTabs.RANKS));
    },
    [dispatch],
  );

  const handleSetSelectedRowKeys = useCallback(
    rowKeys => dispatch(setSelectedRowKeys(rowKeys)),
    [dispatch],
  );

  const handleGoToYouthProfile = useCallback(
    (userId, activityType) => event => {
      if (!isOffline) {
        event.stopPropagation();
        if (
          activityType &&
          activityType !== activityTypeIds.EAGLE_SERVICE_PROJECT
        ) {
          dispatch(goToActivityLogs({ userId, activityType }));
        } else {
          dispatch(goToYouthProfile(userId));
        }
      }
    },
    [isOffline, dispatch],
  );

  const handleGoToAdultProfile = useCallback(
    (userId, activityType) => event => {
      if (!isOffline) {
        event.stopPropagation();
        if (activityType) {
          dispatch(goToAdultActivityLogs({ userId, activityType }));
        } else {
          dispatch(goToAdultProfile(userId));
        }
      }
    },
    [isOffline, dispatch],
  );

  const handleSetSorter = useCallback(
    sorter => dispatch(setSorter(sorter)),
    [dispatch],
  );

  const handleSearch = useCallback(
    value => dispatch(setSearchFilter(value)),
    [dispatch],
  );

  let Component;
  if (enableGrouping && activeTab === PendingItemsTabs.REQUIREMENTS) {
    Component = isMobile ? (
      <PendingRequirementsList
        onGoToYouthProfile={handleGoToYouthProfile}
        onGoToAdultProfile={handleGoToAdultProfile}
        search={search}
      />
    ) : (
      <PendingRequirementsTable
        onGoToYouthProfile={handleGoToYouthProfile}
        onGoToAdultProfile={handleGoToAdultProfile}
        sorter={sorter}
      />
    );
  } else if (activeTab === PendingItemsTabs.LEADERSHIP_POSITIONS) {
    Component = <PendingLeadershipPositionsTable />;
  } else {
    Component = isMobile ? (
      <PendingItemsList
        items={enableGrouping ? itemsByType : filteredItems}
        loading={isLoading}
        isOffline={isOffline}
        sorter={sorter}
        selectedRowKeys={selectedRowKeys}
        searchPinned={searchPinned}
        onSetSelectedRowKeys={handleSetSelectedRowKeys}
        onGoToYouthProfile={handleGoToYouthProfile}
        onGoToAdultProfile={handleGoToAdultProfile}
      />
    ) : (
      <PendingItemsTable
        items={enableGrouping ? itemsByType : filteredItems}
        loading={isLoading}
        isOffline={isOffline}
        sorter={sorter}
        selectedRowKeys={selectedRowKeys}
        onSetSelectedRowKeys={handleSetSelectedRowKeys}
        onGoToYouthProfile={handleGoToYouthProfile}
        onGoToAdultProfile={handleGoToAdultProfile}
        onSorterChange={handleSetSorter}
      />
    );
  }

  return (
    <React.Fragment>
      <ConditionalRender value={enableGrouping}>
        <div className={styles.tabsContainer}>
          <div className={styles.radioGroup}>
            <RadioGroup
              defaultValue={activeTab}
              size="large"
              className={radioGroupStyles.group}
              onChange={ev => {
                // reset selection
                dispatch(setSelectedRowKeys([]));
                dispatch(setPendingItemsActiveTab(ev.target.value));
              }}
            >
              {tabs.map(tabKey => {
                const isLeadershipTab =
                  tabKey === PendingItemsTabs.LEADERSHIP_POSITIONS;

                let count =
                  (isLeadershipTab
                    ? pendingPositionsCount
                    : filteredPendingByType[tabsToAdvancementTypeMap[tabKey]]
                        ?.length) || 0;

                if (enableNewCounts) {
                  switch (tabKey) {
                    case PendingItemsTabs.REQUIREMENTS:
                      count = notificationCounts.requirementsCount;
                      break;
                    case PendingItemsTabs.RANKS:
                      count = notificationCounts.ranksCount;
                      break;
                    case PendingItemsTabs.MERIT_BADGES:
                      count = notificationCounts.meritBadgesCount;
                      break;
                    case PendingItemsTabs.AWARDS:
                      count = notificationCounts.awardsCount;
                      break;
                    case PendingItemsTabs.LEADERSHIP_POSITIONS:
                      count = pendingPositionsCount;
                      break;
                    default:
                      break;
                  }
                }

                const tabName = (
                  <FormattedMessage
                    id={`advancement.PendingItems.tabs.${tabKey}`}
                  />
                );
                return (
                  <RadioButton
                    key={tabKey}
                    className={radioGroupStyles.radioBtn}
                    value={tabKey}
                  >
                    {tabName}
                    {count > 0 && <Badge count={count} />}
                  </RadioButton>
                );
              })}
            </RadioGroup>
          </div>
          <ConditionalRender value={false}>
            <div className={styles.loadingIndicator}>
              <Spin indicator={antIcon} />
              <S size="5" semibold>
                <FormattedMessage id="shared.loading" />
              </S>
            </div>
          </ConditionalRender>
        </div>
      </ConditionalRender>
      <SearchInput
        size="large"
        mobileSticky
        placeholder={intl.formatMessage({
          id: 'advancement.PendingItems.search',
        })}
        value={search}
        onChange={handleSearch}
        onPinChange={setSearchPinned}
      />
      {Component}
    </React.Fragment>
  );
};

const PendingItemsContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!featureFlags.getFlag('SBL_5150_DASHBOARD_API')) return;

    dispatch(advancementHistoryRequestIfNotLoaded());
  }, [dispatch]);

  return <PendingItems />;
};

export default PendingItemsContainer;
