import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@shared';
import { hasPermissionSel, permissions } from '@user';

import {
  isSubUnitAllowedSel, // selectors
  subUnitTypeNameSel,
} from '../../duck';
import { showSetSubUnitModal } from '../../duck/actions';

const AddSubUnitButton = ({ ...props }) => {
  const dispatch = useDispatch();
  const subUnitTypeName = useSelector(subUnitTypeNameSel);
  const isSubUnitAllowed = useSelector(isSubUnitAllowedSel);
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );

  const handleOnClick = useCallback(() => {
    dispatch(showSetSubUnitModal());
  }, [dispatch]);

  return isSubUnitAllowed && canEditSubUnits ? (
    <Button {...props} uppercase={false} onClick={handleOnClick}>
      <FormattedMessage
        id={`packRoster.AddSubUnit.AddSubUnitForm.addSubUnitButton.${subUnitTypeName}`}
      />
    </Button>
  ) : null;
};

export default AddSubUnitButton;
