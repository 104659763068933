import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import { closeEditTermModal, isEditTermModalVisibleSel } from '../../../duck';
import EditTermForm from './EditTermForm/EditTermForm';
import styles from './EditTermModal.less';

const EditTermModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isEditTermModalVisibleSel);

  const handleCloseAddMembersModal = () => dispatch(closeEditTermModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.editTermModal.title',
      })}
      onCancel={handleCloseAddMembersModal}
      onBack={handleCloseAddMembersModal}
    >
      <EditTermForm />
    </Modal>
  );
};

export default EditTermModal;
