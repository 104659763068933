import {
  SearchUnitRequest,
  SearchUnitRequestQueryParams,
  SearchUnitResponse,
} from '@appTypes/esb';
import { tagTypes } from '@modules/rtk/constants';
import { esbApi } from '@modules/rtk/esb-api';

export const councilUnitsApi = esbApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * @esbEndpoint POST /organizations/units/search
     */
    getCouncilUnits: builder.query<
      SearchUnitResponse,
      {
        params: SearchUnitRequestQueryParams;
        payload: SearchUnitRequest;
      }
    >({
      query: arg => ({
        url: `/organizations/units/search`,
        body: arg.payload,
        method: 'POST',
        params: arg.params,
      }),
      providesTags: (result, error, arg) => {
        if (!result || error) return [];

        const tags = [
          {
            type: tagTypes.UnitSearchResult,
            id: `${arg.payload.organizationGuid}`,
          },
        ];

        return tags;
      },
    }),
  }),
});

export const { useGetCouncilUnitsQuery, useLazyGetCouncilUnitsQuery } =
  councilUnitsApi;
