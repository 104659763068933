import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './DetailItem.less';

const DetailItem = ({ label, children, className, colon, vertical }) => {
  const classNames = cn(
    styles.container,
    vertical && styles.vertical,
    className,
  );

  return (
    <div className={classNames}>
      <div className={styles.label}>
        {label}
        {colon && ':'}
      </div>
      <div>{children}</div>
    </div>
  );
};

DetailItem.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  colon: PropTypes.bool,
  vertical: PropTypes.bool,
};

DetailItem.defaultProps = {
  colon: true,
  vertical: false,
};

export default DetailItem;
