import { useSelector } from 'react-redux';

import { intl, appName } from '@shared';
import { pageTitleIdSel } from '@location';

const PageTitle = () => {
  const titleId = useSelector(pageTitleIdSel);

  let title = appName;
  if (titleId) {
    title = `${intl.formatMessage({ id: titleId })} | ${title}`;
  }
  document.title = title;

  return '';
};

export default PageTitle;
