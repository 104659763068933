import { cloneDeep, isEmpty, isNull, isNumber, isUndefined } from 'lodash';
import moment from 'moment';
import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, iif, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { filter, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';

import { SHOW_AKELA_PROFILE } from '@config';
import { SET_ORGANIZATION, unitTypeIdSel } from '@context';
import { dictionariesRequest, dictionaryIds } from '@dict';
import { isCurrentPageSel, querySel } from '@location';
import { getCustomErrorMessage } from '@modules/bulkEntry/duck/utils';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import {
  APPROVE_ADVANCEMENTS_RESPONSE,
  RECORD_ADVANCEMENT_RESPONSE,
  UNAPPROVE_ADVANCEMENT_RESPONSE,
} from '@modules/progress/duck/actions';
import { START_ADVANCEMENT_RESPONSE } from '@modules/progress/startAdvancement/duck/actions';
import { unstartAdvancementResponse } from '@modules/progress/startAdvancement/duck/actionsTyped';
import {
  advancementStatuses,
  advancementTypes,
} from '@modules/shared/constants';
import {
  isParentOrYouthMemberSel,
  organizationGuidSel,
  urlUserIdSel,
} from '@modules/shared/duck/selectors';
import { intl } from '@modules/shared/localization';
import {
  APP_BOOTSTRAP_DONE,
  MEMBER_DETAILS_REFRESH,
  goToYouthProfile,
  navigateToRoster,
  setIsLoadingDeleteEmailModal,
  setShouldMemberDetailsReload,
  setShowDeleteAddressModal,
  setShowDeleteEmailModal,
  setShowDeletePhoneModal,
} from '@shared/duck/actions';
import { toastService } from '@toasts';
import { eligibleAdvancementTypesSel } from '@unit';
import { userIdSel as loggedInUserIdSel } from '@user';
import { catchAndReport } from '@utils/rxjs/operators';

import { formatApiDateTime } from '../../../modules/progress/common/utils';
import { advancementHistoryRequest } from '../../advancement/advancementHistory';
import { packRosterRequest } from '../../advancement/common/actions';
import { youthsAndAdultsSel } from '../../progress/activities';
import { UPDATE_ADVANCEMENT_RESPONSE } from '../../progress/editAdvancement';
import { shouldScoutDetailsReloadSel } from '../../progress/unapproveAdvancement/duck/selectors';
import { imageUploadS3$ } from '../../shared/duck/services';
import {
  containsPendingComments,
  getEagleHours,
  transformComments,
} from '../utils';
// TODO: we need to remove this cyclic deps
// eslint-disable-next-line import/no-cycle
import {
  ADD_ADV_COMMENTS_CANCEL,
  ADD_ADV_COMMENTS_PHOTO_REQUEST,
  ADD_ADV_COMMENTS_REQUEST,
  ADD_BATCH_ADV_COMMENTS_REQUEST,
  ADV_COMMENTS_REQUEST,
  ADV_VERSIONS_REQUEST,
  DELETE_ADV_COMMENTS_PHOTO_REQUEST,
  DELETE_ADV_COMMENTS_REQUEST,
  DELETE_AKELA_EMAIL_REQUEST,
  EAGLE_ACTIVITY_REQUEST,
  EDIT_EAGLE_PROJECT_REQUEST,
  MEMBER_DETAILS_REQUEST,
  ROUTE_YOUTH_ADV_REQUIREMENTS,
  ROUTE_YOUTH_PROFILE,
  SAVE_YOUTH_DETAILS_REQUEST,
  SAVE_YOUTH_PROFILE_PICTURE_REQUEST,
  SAVE_YOUTH_PROFILE_REQUEST,
  SET_EDIT_MODE,
  SUBMIT_EAGLE_PROJECT_REQUEST,
  UPDATE_ACTIVE_VERSION_REQUEST,
  UPDATE_ADV_COMMENTS_REQUEST,
  YOUTH_ACTIVITIES_SUMMARY_REQUEST,
  YOUTH_ADV_REQUIREMENTS_REQUEST,
  YOUTH_AKELA_PROFILE_REQUEST,
  YOUTH_YPT_REQUEST,
  addAdvCommentsCancel,
  addAdvCommentsError,
  addAdvCommentsPhotoError,
  addAdvCommentsPhotoResponse,
  addAdvCommentsResponse,
  addBatchAdvCommentsError,
  addBatchAdvCommentsResponse,
  advCommentsError,
  advCommentsRequest,
  advCommentsResponse,
  advancementVersionsError,
  advancementVersionsResponse,
  deleteAdvCommentsError,
  deleteAdvCommentsPhotoError,
  deleteAdvCommentsPhotoResponse,
  deleteAdvCommentsResponse,
  deleteAkelaEmailError,
  eagleActivityError,
  eagleActivityResponse,
  memberDetailsError,
  memberDetailsRequest,
  memberDetailsResponse,
  saveYouthDetailsError,
  saveYouthDetailsResponse,
  saveYouthProfileError,
  saveYouthProfilePictureError,
  saveYouthProfilePictureResponse,
  saveYouthProfileResponse,
  setEditYouthDetailSidebar,
  submitEagleProjectError,
  submitEagleProjectResponse,
  updateActiveVersionError,
  updateActiveVersionResponse,
  updateAdvCommentsError,
  updateAdvCommentsResponse,
  youthActivitySummaryError,
  youthActivitySummaryResponse,
  youthAdvRequirementsError,
  youthAdvRequirementsRequest,
  youthAdvRequirementsResponse,
  youthAkelaProfileError,
  youthAkelaProfileRequest,
  youthAkelaProfileResponse,
  youthYPTError,
  youthYPTRequest,
  youthYPTResponse,
} from './actions';
import {
  editAdvRqmtsError,
  editAdvRqmtsRequest,
  editAdvRqmtsResponse,
  editPreferedName,
} from './actionsTyped';
import {
  adventuresSel,
  eagleActivitySel,
  memberDetailsSel,
  ranksSel,
  shouldMemberDetailsReloadSel,
  userIdSel,
  youthAdvRequirementsParamsSel,
  youthAdvRequirementsSel,
  youthInfoSel,
  youthPersonGuidSel,
  youthPersonIdSel,
  youthProfileInfoSel,
} from './selectors';
import services from './services';

const goToRosterEpic$ = (action$, state$) =>
  action$
    .skipUntil(action$.ofType(APP_BOOTSTRAP_DONE))
    .ofType(SET_ORGANIZATION)
    .filter(() => isCurrentPageSel(state$.value, ROUTE_YOUTH_ADV_REQUIREMENTS))
    .mapTo(navigateToRoster());

const fetchFormDictsEpic$ = action$ =>
  action$.pipe(
    ofType(MEMBER_DETAILS_REQUEST, SET_EDIT_MODE),
    map(() =>
      dictionariesRequest(
        dictionaryIds.STATES,
        dictionaryIds.PHONE_COUNTRIES,
        dictionaryIds.RANKS,
      ),
    ),
  );

const loadMemberDetailsEpic$ = (action$, state$) =>
  action$.pipe(
    // Remove YOUTH_ADV_REQUIREMENTS_REQUEST when we have an API for Merit Badge Counselor
    ofType(
      MEMBER_DETAILS_REQUEST,
      MEMBER_DETAILS_REFRESH,
      YOUTH_ADV_REQUIREMENTS_REQUEST,
    ),
    switchMap(() => {
      const state = state$.value;
      const { adultUserId = '' } = querySel(state) || {};
      const memberDetails = memberDetailsSel(state);
      const userId = userIdSel(state);
      const eligibleAdvancementTypes = eligibleAdvancementTypesSel(state);
      const selectedUnitTypeId = unitTypeIdSel(state);
      const unitTypeId =
        typeof selectedUnitTypeId === 'string'
          ? Number(selectedUnitTypeId)
          : selectedUnitTypeId;
      const isParentOrYouthMember = isParentOrYouthMemberSel(state);
      const isRefresh =
        shouldMemberDetailsReloadSel(state) ||
        shouldScoutDetailsReloadSel(state);
      const dataSource =
        isRefresh ||
        isEmpty(memberDetails) ||
        memberDetails.userId !== userId ||
        memberDetails.isParentOrYouthMember !== isParentOrYouthMember
          ? services.getYouthData$({
              userId,
              eligibleAdvancementTypes,
              unitTypeId,
              isParentOrYouthMember,
            })
          : of(memberDetails);

      return dataSource.pipe(
        mergeMap(res => {
          const { profile } = res;

          if (SHOW_AKELA_PROFILE && !adultUserId) {
            return Observable.concat(
              of(youthAkelaProfileRequest(profile.personGuid)),
              of(youthYPTRequest(profile.personGuid)),
              of(memberDetailsResponse(res)),
              of(setShouldMemberDetailsReload(false)),
              of(setShouldMemberDetailsReload(false)),
              of(setEditYouthDetailSidebar(false)),
            );
          }

          return Observable.concat(
            of(memberDetailsResponse(res)),
            of(setShouldMemberDetailsReload(false)),
            of(setShouldMemberDetailsReload(false)),
            of(setEditYouthDetailSidebar(false)),
          );
        }),
        catchAndReport(err => of(memberDetailsError(err))),
      );
    }),
  );

const saveProfileSibscriptions = (subs = [], personGuid) => {
  if (!subs.length || !personGuid) {
    return [of({})];
  }

  return subs.map(sub =>
    sub.id
      ? services.updateUsersubscriptions$(sub, personGuid)
      : services.createUsersubscriptions$(sub, personGuid),
  );
};

const saveYouthProfile$ = (action$, state$) =>
  action$.ofType(SAVE_YOUTH_PROFILE_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const { adultUserId = '' } = querySel(state) || {};
    const personGuid = youthPersonGuidSel(state);
    const userId = userIdSel(state);
    const {
      akelaProfile,
      sbProfile,
      originalProfile: { profile },
    } = payload;

    return forkJoin([
      services.updateSBProfile$(sbProfile, userId),
      adultUserId ? of({}) : services.saveProfile$(akelaProfile, personGuid),
      ...saveProfileSibscriptions(akelaProfile.subs, personGuid),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'profile.ProfileInfo.save.success',
          }),
        ),
      ),
      mergeMap(() => {
        const actions = [
          of(setShouldMemberDetailsReload(true)),
          of(saveYouthProfileResponse()),
          of(memberDetailsRequest()),
        ];
        if (profile.nickName !== akelaProfile.nickName) {
          actions.push(of(editPreferedName()));
        }

        return Observable.concat(...actions);
      }),
      catchAndReport(err => Observable.of(saveYouthProfileError(err))),
    );
  });

const saveYouthProfilePicture$ = (action$, state$) =>
  action$
    .ofType(SAVE_YOUTH_PROFILE_PICTURE_REQUEST)
    .switchMap(({ payload }) => {
      const state = state$.value;
      const youthUserId = youthPersonIdSel(state);
      const personGuid = youthPersonGuidSel(state);
      const { imageFile } = payload;
      const imgType = imageFile.type;

      return forkJoin([
        services.saveProfilePicture$({ ...payload, personGuid }),
        services.getPreSignedUrl$(youthUserId, imgType).pipe(
          switchMap(response =>
            imageUploadS3$(youthUserId, imgType, payload, response),
          ),
          tap(() =>
            toastService.info(
              intl.formatMessage({
                id: 'profile.ProfileInfo.save.profileSaveDisclaimer',
              }),
            ),
          ),
          tap(() =>
            toastService.success(
              intl.formatMessage({
                id: 'profile.ProfileInfo.save.success',
              }),
            ),
          ),
          catchAndReport(err =>
            Observable.of(saveYouthProfilePictureError(err)),
          ),
        ),
      ]).pipe(
        mergeMap(() =>
          Observable.concat(
            of(memberDetailsRequest()),
            of(saveYouthProfilePictureResponse()),
            of(packRosterRequest()),
          ),
        ),
        catchAndReport(err => Observable.of(saveYouthProfilePictureError(err))),
      );
    });

const saveYouthDetails$ = (action$, state$) =>
  action$.ofType(SAVE_YOUTH_DETAILS_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const userId = userIdSel(state);

    return forkJoin([services.updateSBProfile$(payload, userId)]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'profile.ProfileInfo.save.success',
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(memberDetailsRequest()),
          of(saveYouthDetailsResponse()),
        ),
      ),
      catchAndReport(err => Observable.of(saveYouthDetailsError(err))),
    );
  });

const youthYPTRequestEpic$ = action$ =>
  action$.ofType(YOUTH_YPT_REQUEST).switchMap(({ payload }) =>
    services.getUserYPTrainingRequest$(payload).pipe(
      mergeMap(res => Observable.concat(of(youthYPTResponse(res)))),
      catchAndReport(err => Observable.of(youthYPTError(err))),
    ),
  );

const youthAkelaProfileRequestEpic$ = action$ =>
  action$.ofType(YOUTH_AKELA_PROFILE_REQUEST).switchMap(({ payload }) =>
    forkJoin(
      services.getYouthProfile$(payload),
      services.getUsersubscriptionsRequest$(payload),
    ).pipe(
      map(([akelaProfile, subscriptions]) => {
        const profile = {
          ...akelaProfile,
          subscriptions,
        };
        return profile;
      }),
      mergeMap(res => of(youthAkelaProfileResponse(res))),
      catchAndReport(err => Observable.of(youthAkelaProfileError(err))),
    ),
  );

const deleteAkelaEmail$ = (action$, state$) =>
  action$.ofType(DELETE_AKELA_EMAIL_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const personGuid = youthPersonGuidSel(state);
    const userId = userIdSel(state);
    const { adultUserId = '' } = querySel(state) || {};

    const { sbPhones = {} } = payload;
    const deleteSBPhone =
      sbPhones.homePhone === '' ||
      sbPhones.mobilePhone === '' ||
      sbPhones.workPhone === '';

    return forkJoin([
      !adultUserId ? services.deleteProfileItem$(payload, personGuid) : of({}),
      deleteSBPhone ? services.updateSBProfile$(sbPhones, userId) : of({}),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: `shared.contactInfo.delete${payload.type}.success`,
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(setShouldMemberDetailsReload(true)),
          of(memberDetailsRequest()),
          of(setShowDeleteEmailModal(false)),
          of(setShowDeleteAddressModal(false)),
          of(setShowDeletePhoneModal(false)),
          of(setIsLoadingDeleteEmailModal(false)),
        ),
      ),
      catchAndReport(err =>
        Observable.concat(
          of(deleteAkelaEmailError(err)),
          of(setShowDeleteEmailModal(false)),
          of(setShowDeleteAddressModal(false)),
          of(setShowDeletePhoneModal(false)),
          of(setIsLoadingDeleteEmailModal(false)),
        ),
      ),
    );
  });

const eagleActivityRequestEpic$ = action$ =>
  action$.pipe(
    ofType(EAGLE_ACTIVITY_REQUEST),
    switchMap(({ payload: id }) =>
      services.getEagleActivity$(id).pipe(
        map(eagleActivityResponse),
        catchAndReport(err => of(eagleActivityError(err))),
      ),
    ),
  );

const reloadYouthAdvancementsEpic$ = (action$, state$) =>
  action$.pipe(
    filter(() => isCurrentPageSel(state$.value, ROUTE_YOUTH_PROFILE)),
    ofType(
      RECORD_ADVANCEMENT_RESPONSE,
      APPROVE_ADVANCEMENTS_RESPONSE,
      UNAPPROVE_ADVANCEMENT_RESPONSE,
    ),
    switchMap(() => {
      const state = state$.value;
      const userId = userIdSel(state);
      const eligibleAdvancementTypes = eligibleAdvancementTypesSel(state);
      const unitTypeId = unitTypeIdSel(state);
      const userData = memberDetailsSel(state);
      return services
        .getYouthAdvancements$({
          userId,
          userData,
          eligibleAdvancementTypes,
          unitTypeId,
        })
        .pipe(
          map(memberDetailsResponse),
          catchAndReport(err => of(memberDetailsError(err))),
        );
    }),
  );

const loadYouthAdvRequirementsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(YOUTH_ADV_REQUIREMENTS_REQUEST),
    filter(() => isCurrentPageSel(state$.value, ROUTE_YOUTH_ADV_REQUIREMENTS)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { userId, advancementType, advancementId, userAwardId } =
        youthAdvRequirementsParamsSel(state);
      let hasNotStartedAdvancement = false;
      switch (advancementType) {
        case advancementTypes.RANKS:
          {
            const ranks = ranksSel(state);
            if (!ranks?.length) break;

            const selectedRank = ranks.find(
              ({ id }) => `${id}` === `${advancementId}`,
            );
            hasNotStartedAdvancement = !selectedRank?.status;
          }
          break;
        case advancementTypes.ADVENTURES: {
          const adventures = adventuresSel(state);
          if (!adventures?.length) break;

          const selectedAdventure = adventures.find(
            ({ id }) => `${id}` === `${advancementId}`,
          );
          hasNotStartedAdvancement = !selectedAdventure?.status;
          break;
        }

        default:
          break;
      }

      return services
        .getYouthAdvRequirements$({
          userId,
          advancementType,
          advancementId,
          userAwardId,
          omitYouthRequirement:
            featureFlags.getFlag('SBL_5399_SKIP_UNSTARTED_REQUIREMENTS') &&
            payload?.pageLoad &&
            hasNotStartedAdvancement,
        })
        .pipe(
          map(youthAdvRequirementsResponse),
          catchAndReport(err => of(youthAdvRequirementsError(err))),
        );
    }),
  );

const getYouthAdvRankRequirementsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(YOUTH_ADV_REQUIREMENTS_REQUEST),
    filter(() => isCurrentPageSel(state$.value, ROUTE_YOUTH_PROFILE)),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { userId } = youthInfoSel(state);
      const { rankId } = payload;
      return services
        .getYouthAdvRequirements$({
          userId,
          advancementId: rankId,
          advancementType: advancementTypes.RANKS,
        })
        .pipe(
          map(youthAdvRequirementsResponse),
          catchAndReport(err => of(youthAdvRequirementsError(err))),
        );
    }),
  );

const loadVersionsEpic$ = action$ =>
  action$.pipe(
    ofType(ADV_VERSIONS_REQUEST),
    switchMap(({ payload }) =>
      services.fetchAdvancementVersions$(payload).pipe(
        map(advancementVersionsResponse),
        catchAndReport(err => of(advancementVersionsError(err))),
      ),
    ),
  );

const updateActiveVersionEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ACTIVE_VERSION_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const versionId = Number(payload);
      const userId = urlUserIdSel(state);
      const { advancementType, id, status } = youthAdvRequirementsSel(state);
      const organizationGuid = organizationGuidSel(state);

      return iif(
        () =>
          advancementType === advancementTypes.MERIT_BADGES &&
          status === advancementStatuses.NOT_STARTED,
        // new merit badges need to be started in order to set version (can be done simultaneously)
        services.startYouthMeritBadge$({
          userId,
          organizationGuid,
          id,
          versionId,
          dateStarted: moment().format('YYYY-MM-DD'),
        }),

        // for all other cases we can just set version directly.
        services.updateActiveVersion$({
          userId,
          advancementType,
          advancementId: id,
          versionId,
        }),
      ).pipe(
        mergeMap(() =>
          Observable.concat(
            of(updateActiveVersionResponse()),
            of(youthAdvRequirementsRequest(versionId)),
          ),
        ),
        catchAndReport(err => of(updateActiveVersionError(err))),
      );
    }),
  );

const editAdvRqmtsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(editAdvRqmtsRequest.type),
    switchMap(({ payload }) => {
      const { requirements } = payload;
      const state = state$.value;
      const { userId, advancementType, advancementId, userAwardId } =
        youthAdvRequirementsParamsSel(state);
      const loggedInUserId = loggedInUserIdSel(state);
      const organizationGuid = organizationGuidSel(state);
      const serviceCall =
        advancementType !== advancementTypes.SSELECTIVES
          ? services.editAdvRequirements$({
              userId: +userId,
              advancementType,
              advancementId,
              userAwardId,
              requirements,
              loggedInUserId,
              organizationGuid,
            })
          : services.editSSEElectiveRequirements$({ userId, requirements });

      return serviceCall.pipe(
        map(editAdvRqmtsResponse),
        tap(() => {
          toastService.success(
            intl.formatMessage(
              {
                id: 'youthProfile.editAdvRequirements.update',
              },
              { count: requirements.length },
            ),
          );
        }),
        catchAndReport(err => {
          toastService.error(getCustomErrorMessage(err.response));
          return of(editAdvRqmtsError(err));
        }),
      );
    }),
  );

const reloadYouthAdvRequirementsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(
      editAdvRqmtsResponse.type,
      START_ADVANCEMENT_RESPONSE,
      unstartAdvancementResponse.type,
      UPDATE_ADVANCEMENT_RESPONSE,
    ),
    filter(() => isCurrentPageSel(state$.value, ROUTE_YOUTH_ADV_REQUIREMENTS)),
    mapTo(youthAdvRequirementsRequest()),
  );

const getYouthActivitiesSummary$ = action$ =>
  action$.pipe(
    ofType(YOUTH_ACTIVITIES_SUMMARY_REQUEST),
    switchMap(({ payload: userId }) =>
      services.getPersonalActivitiesSummary$(userId).pipe(
        map(summaryInfo => youthActivitySummaryResponse(summaryInfo)),
        catchAndReport(err => of(youthActivitySummaryError(err))),
      ),
    ),
  );

const submitEagleProjectEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_EAGLE_PROJECT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      const { youths, adults } = youthsAndAdultsSel(state);
      const { profile } = youthProfileInfoSel(state);

      const timeActivityValues = getEagleHours(payload.totalHours);

      const registeredAdults = adults.map(adult => ({
        userId: adult.userId,
        organizationGuid,
        activityValues: [...timeActivityValues],
      }));
      const registeredYouths = youths.map(youth => ({
        userId: youth.userId,
        organizationGuid,
        activityValues: [...timeActivityValues],
      }));
      const {
        categoryId,
        startDate,
        endDate,
        specificGroupDescription,
        name,
        location,
        addressLine1,
        addressLine2,
        city,
        akelaStateId,
        zip5,
      } = payload;
      const data = {
        activityTypeId: 5,
        personGuid: profile.personGuid,
        categoryId,
        name,
        startDateTime: formatApiDateTime(startDate, !!payload.id),
        endDateTime: formatApiDateTime(endDate, !!payload.id),
        hostOrganizationGuid: organizationGuid,
        isEveryChildOrg: false,
        isPersonalActivity: true,
        organizationGuid,

        benefitGroup: specificGroupDescription,
        location: location ? location : undefined,
        addressLine1,
        addressLine2,
        city,
        akelaStateId: akelaStateId ? Number(akelaStateId) : akelaStateId,
        zip5,
        registeredAdults,
        registeredYouths,
      };
      return services.createEagleActivity$(data).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({
              id: 'youthProfile.createEagleProject',
            }),
          );
        }),
        mergeMap(() => {
          const { userId } = youthInfoSel(state);
          return Observable.concat(
            of(submitEagleProjectResponse),
            of(setShouldMemberDetailsReload(true)),
            of(goToYouthProfile(userId)),
            of(advancementHistoryRequest()),
          );
        }),
        catchAndReport(err => of(submitEagleProjectError(err))),
      );
    }),
  );

const editEagleParticipants$ = (
  eagleActivityId,
  organizationGuid,
  timeActivityValues = [],
  prevParticipants = [],
) => {
  const updatedParticipants = prevParticipants.map(participant => {
    const updatedActivityValues = participant.activityValues.map(
      activityObj => {
        const activityObjClone = cloneDeep(activityObj);
        const [hours] = timeActivityValues;

        if (activityObjClone.activityValueTypeId === 10) {
          activityObjClone.activityValue = hours.activityValue;
        }
        return activityObjClone;
      },
    );

    return {
      userId: participant.userId,
      organizationGuid,
      activityValues: updatedActivityValues,
      isDeclined: false,
      note: 'N/A',
    };
  });

  return services.updateEagleParticipants$(
    eagleActivityId,
    updatedParticipants,
  );
};

const editEagleProjectEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(EDIT_EAGLE_PROJECT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { id, data } = payload;
      const organizationGuid = organizationGuidSel(state);
      const eagleActivity = eagleActivitySel(state);
      const timeActivityValues = getEagleHours(data.totalHours);
      const prevParticipants = eagleActivity.registeredAdults.concat(
        eagleActivity.registeredYouths,
      );

      const {
        categoryId,
        startDate,
        endDate,
        specificGroupDescription,
        name,
        location,
        addressLine1,
        addressLine2,
        city,
        akelaStateId,
        zip5,
      } = data;

      const body = {
        categoryId,
        startDateTime: formatApiDateTime(startDate, id),
        endDateTime: formatApiDateTime(endDate, id),
        name,

        benefitGroup: specificGroupDescription,
        location: location ? location : '',
        addressLine1: addressLine1 ? addressLine1 : '',
        addressLine2: addressLine2 ? addressLine2 : '',
        city: city ? city : '',
        akelaStateId: akelaStateId ? Number(akelaStateId) : null,
        zip5: zip5 ? zip5 : '',
      };
      return forkJoin([
        services.updateEagleActivity$(id, body),
        editEagleParticipants$(
          id,
          organizationGuid,
          timeActivityValues,
          prevParticipants,
        ),
      ]).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({
              id: 'youthProfile.editEagleProject',
            }),
          );
        }),
        mergeMap(() => {
          const { userId } = youthInfoSel(state);
          return Observable.concat(
            of(submitEagleProjectResponse()),
            of(setShouldMemberDetailsReload(true)),
            of(goToYouthProfile(userId)),
            of(advancementHistoryRequest()),
          );
        }),
        catchAndReport(err => of(submitEagleProjectError(err))),
      );
    }),
  );

const getAdvCommentsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADV_COMMENTS_REQUEST),
    switchMap(() =>
      Observable.timer(0, 25000)
        .switchMap(() => {
          const state = state$.value;
          const { userId, advancementType, advancementId, userAwardId } =
            youthAdvRequirementsParamsSel(state);
          const { versionId } = youthAdvRequirementsSel(state);
          const body = {
            advancementId: Number(advancementId),
            advancementType,
          };

          if (!isNumber(Number(advancementId))) {
            return of(addAdvCommentsCancel());
          }

          if (!isNull(versionId) && !isUndefined(versionId)) {
            body.versionId = Number(versionId);
          }

          if (!isNull(userAwardId) && !isUndefined(userAwardId)) {
            body.userAwardId = Number(userAwardId);
          }

          return services.getAdvComments$(userId, body).pipe(
            mergeMap(response => {
              const comments = transformComments(response);
              const pending = containsPendingComments(comments);

              if (pending) {
                return Observable.concat(of(advCommentsResponse(comments)));
              }

              return Observable.concat(
                of(advCommentsResponse(comments)),
                of(addAdvCommentsCancel()),
              );
            }),
            catchAndReport(error => of(advCommentsError(error))),
          );
        })
        .takeUntil(action$.ofType(ADD_ADV_COMMENTS_CANCEL)),
    ),
  );

const addAdvCommentsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADD_ADV_COMMENTS_REQUEST),
    switchMap(({ payload: { body, requirementId } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const { userId, advancementType, advancementId } =
        youthAdvRequirementsParamsSel(state);
      const { versionId } = youthAdvRequirementsSel(state);
      const requestBody = {
        advancementId: Number(advancementId),
        advancementType,
        body,
        scoutUserId: Number(userId),
        subject: 'subject-post',
        userId: Number(loggedInUserId),
        versionId: Number(versionId),
      };

      if (!isNull(requirementId)) {
        requestBody.requirementId = Number(requirementId);
      }

      return services.addAdvComments$(loggedInUserId, requestBody).pipe(
        map(response => addAdvCommentsResponse(response)),
        map(advCommentsRequest),
        catchAndReport(error => of(addAdvCommentsError(error))),
      );
    }),
  );

const addBatchAdvCommentsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADD_BATCH_ADV_COMMENTS_REQUEST),
    switchMap(({ payload: { body, requirementIds } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const { userId, advancementType, advancementId } =
        youthAdvRequirementsParamsSel(state);
      const { versionId } = youthAdvRequirementsSel(state);
      const requestBody = {
        advancementId: Number(advancementId),
        advancementType,
        body,
        scoutUserId: Number(userId),
        subject: 'subject-post',
        userId: Number(loggedInUserId),
        versionId: Number(versionId),
      };

      return forkJoin(
        requirementIds.map(requirementId => {
          if (!isNull(requirementId)) {
            requestBody.requirementId = Number(requirementId);
          }

          return services.addAdvComments$(loggedInUserId, requestBody);
        }),
      ).pipe(
        map(response => addBatchAdvCommentsResponse(response)),
        map(advCommentsRequest),
        catchAndReport(error => of(addBatchAdvCommentsError(error))),
      );
    }),
  );

const deleteAdvCommentsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ADV_COMMENTS_REQUEST),
    switchMap(({ payload: { commentId } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);

      return services.deleteAdvComments$(loggedInUserId, commentId).pipe(
        map(response => deleteAdvCommentsResponse(response)),
        map(advCommentsRequest),
        catchAndReport(error => of(deleteAdvCommentsError(error))),
      );
    }),
  );

const updateAdvCommentsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_ADV_COMMENTS_REQUEST),
    switchMap(({ payload: { comments, commentId } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const { versionId } = youthAdvRequirementsSel(state);
      const requestBody = {
        versionId: Number(versionId),
        subject: 'subject-edit',
        comments,
      };

      return services
        .updateAdvComments$(loggedInUserId, commentId, requestBody)
        .pipe(
          map(response => updateAdvCommentsResponse(response)),
          map(advCommentsRequest),
          catchAndReport(error => of(updateAdvCommentsError(error))),
        );
    }),
  );

const addAdvCommentsPhotoEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADD_ADV_COMMENTS_PHOTO_REQUEST),
    switchMap(({ payload: { body, photo, requirementId } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const { userId, advancementType, advancementId } =
        youthAdvRequirementsParamsSel(state);
      const { versionId } = youthAdvRequirementsSel(state);
      const requestBody = {
        advancementId: Number(advancementId),
        advancementType,
        body,
        fileContentType: photo.type,
        scoutUserId: Number(userId),
        subject: 'subject-post',
        userId: Number(loggedInUserId),
        versionId: Number(versionId),
      };

      if (!isNull(requirementId)) {
        requestBody.requirementId = Number(requirementId);
      }

      return services.addAdvCommentsFile$(loggedInUserId, requestBody).pipe(
        map(({ preSignedUrl }) =>
          services.commentImageUploadS3$(photo, preSignedUrl),
        ),
        map(response => addAdvCommentsPhotoResponse(response)),
        map(advCommentsRequest),
        catchAndReport(error => of(addAdvCommentsPhotoError(error))),
      );
    }),
  );

const deleteAdvCommentsPhotoEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ADV_COMMENTS_PHOTO_REQUEST),
    switchMap(({ payload: { commentId, photoId } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);

      return services
        .deleteAdvCommentsPhoto$(loggedInUserId, commentId, photoId)
        .pipe(
          map(response => deleteAdvCommentsPhotoResponse(response)),
          map(advCommentsRequest),
          catchAndReport(error => of(deleteAdvCommentsPhotoError(error))),
        );
    }),
  );

export default combineEpics(
  goToRosterEpic$,
  eagleActivityRequestEpic$,
  youthYPTRequestEpic$,
  youthAkelaProfileRequestEpic$,
  deleteAkelaEmail$,
  fetchFormDictsEpic$,
  loadMemberDetailsEpic$,
  saveYouthProfile$,
  saveYouthDetails$,
  saveYouthProfilePicture$,
  reloadYouthAdvancementsEpic$,
  loadYouthAdvRequirementsEpic$,
  getYouthAdvRankRequirementsEpic$,
  loadVersionsEpic$,
  editAdvRqmtsEpic$,
  reloadYouthAdvRequirementsEpic$,
  getYouthActivitiesSummary$,
  submitEagleProjectEpic$,
  editEagleProjectEpic$,
  updateActiveVersionEpic$,
  getAdvCommentsEpic$,
  addAdvCommentsEpic$,
  addBatchAdvCommentsEpic$,
  deleteAdvCommentsEpic$,
  updateAdvCommentsEpic$,
  addAdvCommentsPhotoEpic$,
  deleteAdvCommentsPhotoEpic$,
);
