import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import LoopIcon from '@material-ui/icons/Loop';

import { Form, S, Input, intl, Select, emptyArr } from '@shared';
import { unitInfoSel, unitInfoRequest } from '@unit';
import { dictionaryIds, dictionarySel } from '@dict';

import {
  localLocationsListSel,
  localLocationsZipSel,
  getLocalLocations,
  searchLocations,
  searchLocationListSel,
  isLoadingLocationsSel,
} from '../../duck';
import AddressState from './AddressState';

const { Option, OptGroup } = Select;

const FormItem = Form.Item;

const ActivityLocation = ({ form, sectionTitle, isDisabled }) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const { primaryAddress: { zip5: unitZipCode = undefined } = {} } =
    useSelector(unitInfoSel);
  const localLocationsList = useSelector(localLocationsListSel);
  const localZipCode = useSelector(localLocationsZipSel);
  const searchResults = useSelector(searchLocationListSel) || emptyArr;
  const states =
    useSelector(state => dictionarySel(state, dictionaryIds.STATES)) ||
    emptyArr;
  const isLoadingLocations = useSelector(isLoadingLocationsSel);
  const [locationText, setLocationText] = useState();
  useEffect(() => {
    if (unitZipCode === undefined) {
      dispatch(unitInfoRequest());
    }
  }, [dispatch, unitZipCode]);

  useEffect(() => {
    if (unitZipCode && localZipCode !== unitZipCode) {
      dispatch(getLocalLocations(unitZipCode));
    }
  }, [dispatch, unitZipCode, localZipCode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchLocations = useCallback(
    debounce(searchText => {
      dispatch(searchLocations(searchText));
    }, 800),
    [debounce, searchLocations],
  );

  const onSearch = useCallback(
    searchText => {
      if (searchText) {
        fetchLocations(searchText);
        setLocationText(searchText);
      }
    },
    [setLocationText, fetchLocations],
  );

  const handleAnyLocationSelect = useCallback(() => {
    form.setFieldsValue({
      campId: undefined,
    });
  }, [form]);

  const populateLocation = useCallback(
    locationDetails => {
      const {
        zip5,
        state: locationState,
        addressLine1,
        city,
        id: campId,
      } = locationDetails;
      const { id: akelaStateId } = states.find(
        state => state.short === locationState,
      );
      form.setFieldsValue({
        zip5,
        akelaStateId,
        addressLine1,
        addressLine2: '',
        city,
        campId,
      });
    },
    [form, states],
  );
  getFieldDecorator('campId');
  return (
    <React.Fragment>
      <S size="4" bold colored colon>
        {sectionTitle || (
          <FormattedMessage id="progress.ActivityLocation.title" />
        )}
      </S>
      <br />
      <FormItem
        colon={false}
        label={intl.formatMessage({
          id: 'progress.ActivityLocation.field.location',
        })}
        standardLayout
      >
        {getFieldDecorator('location', {
          rules: [
            {
              max: 50,
              message: intl.formatMessage(
                {
                  id: 'shared.maxCharacters',
                },
                { max: 50 },
              ),
            },
          ],
        })(
          <Select
            showSearch
            filterOption
            size="large"
            optionFilterProp="text"
            notFoundContent={isLoadingLocations ? <LoopIcon /> : null}
            onSearch={onSearch}
            disabled={isDisabled}
          >
            {isLoadingLocations
              ? []
              : [
                  locationText ? (
                    <Option
                      key="any_locations"
                      value={locationText}
                      text={locationText}
                      onClick={handleAnyLocationSelect}
                    >
                      {locationText}
                    </Option>
                  ) : null,
                  <OptGroup
                    key="local_locations"
                    label={
                      <FormattedMessage id="progress.ActivityLocation.local" />
                    }
                  >
                    {localLocationsList.map(locationItem => (
                      <Option
                        key={`local${locationItem.id}`}
                        value={locationItem.campName}
                        text={locationItem.campName}
                        onClick={() => populateLocation(locationItem)}
                      >
                        {locationItem.campName}
                      </Option>
                    ))}
                  </OptGroup>,
                  <OptGroup
                    key="search_results"
                    label={
                      <FormattedMessage id="progress.ActivityLocation.all" />
                    }
                  >
                    {searchResults.map(locationItem => (
                      <Option
                        key={`all${locationItem.id}`}
                        value={String(locationItem.id)}
                        text={locationItem.campName}
                        onClick={() => populateLocation(locationItem)}
                      >
                        {locationItem.campName}
                      </Option>
                    ))}
                  </OptGroup>,
                ]}
          </Select>,
        )}
      </FormItem>
      <FormItem
        colon={false}
        label={intl.formatMessage({
          id: 'shared.address.address1.placeholder',
        })}
        standardLayout
      >
        {getFieldDecorator('addressLine1', {
          rules: [
            {
              pattern: /^[. a-zA-Z0-9_-]*$/,
              message: intl.formatMessage({
                id: 'shared.address.address1.errors.specialCharsValidation',
              }),
            },
            {
              max: 255,
              message: intl.formatMessage(
                {
                  id: 'shared.maxCharacters',
                },
                { max: 255 },
              ),
            },
          ],
        })(
          <Input
            disabled={isDisabled}
            size="large"
            placeholder={intl.formatMessage({
              id: 'shared.address.address1.placeholder',
            })}
          />,
        )}
      </FormItem>
      <FormItem
        colon={false}
        label={intl.formatMessage({
          id: 'shared.address.address2.placeholder',
        })}
        standardLayout
      >
        {getFieldDecorator('addressLine2', {
          rules: [
            {
              pattern: /^[ a-zA-Z0-9_-]*$/,
              message: intl.formatMessage({
                id: 'shared.address.address1.errors.specialCharsValidation',
              }),
            },
            {
              max: 255,
              message: intl.formatMessage(
                {
                  id: 'shared.maxCharacters',
                },
                { max: 255 },
              ),
            },
          ],
        })(
          <Input
            disabled={isDisabled}
            size="large"
            placeholder={intl.formatMessage({
              id: 'shared.address.address2.placeholder',
            })}
          />,
        )}
      </FormItem>
      <FormItem
        colon={false}
        label={intl.formatMessage({
          id: 'shared.address.city.placeholder',
        })}
        standardLayout
      >
        {getFieldDecorator('city', {
          rules: [
            {
              max: 50,
              message: intl.formatMessage(
                {
                  id: 'shared.maxCharacters',
                },
                { max: 50 },
              ),
            },
          ],
        })(
          <Input
            disabled={isDisabled}
            size="large"
            placeholder={intl.formatMessage({
              id: 'shared.address.city.placeholder',
            })}
          />,
        )}
      </FormItem>
      <AddressState form={form} disabled={isDisabled} />
      <FormItem
        colon={false}
        label={intl.formatMessage({
          id: 'shared.address.zipCode.label',
        })}
        standardLayout
      >
        {getFieldDecorator('zip5', {
          rules: [
            {
              pattern: /^(?:[0-9]{5})?$/,
              message: intl.formatMessage({
                id: 'progress.ActivityLocation.field.zipCodeError',
              }),
            },
          ],
        })(
          <Input
            disabled={isDisabled}
            size="large"
            placeholder={intl.formatMessage({
              id: 'shared.address.zipCode.label',
            })}
          />,
        )}
      </FormItem>
      <br />
    </React.Fragment>
  );
};

ActivityLocation.propTypes = {
  // From Parent
  isDisabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  sectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ActivityLocation;
