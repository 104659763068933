import {
  statusMapParser,
  statusValues,
} from '@bsa/scouting-js-utils/src/constants/advancements';
import {
  filter,
  find,
  findIndex,
  flatten,
  get,
  groupBy,
  indexOf,
  isEmpty,
  isNil,
  map,
  reduce,
  reverse,
  set,
  sortBy,
  toLower,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import stable from 'stable';

import { SBL_5102_MY_SCOUT } from '@config';
import { unitTypeIdSel } from '@context';
import { programSel } from '@context/duck/selectorsTyped';
import {
  adventuresSel as adventureDictSel,
  awardsSel as awardDictSel,
  coreAwardsSel,
  meritBadgesSel as meritBadgeDictSel,
  ranksSel as rankDictSel,
  ssElectivesSel as ssElectivesDictSel,
} from '@dict';
import { payloadSel, querySel } from '@location';
import { currentUserSubunitsSel } from '@modules/advancement/packRoster/duck/selectors';
import {
  advancementStatus,
  canApproveAdvancement,
  getUnapproveConstraint,
  isValueTruthy,
} from '@modules/shared';
import {
  ProgramId,
  advancementStatuses,
  advancementTypes,
  advancementsExpiredKeys,
  emptyObj,
  historyItemsTypes,
  venturingCoreAwardsIds,
} from '@shared/constants';
import {
  advancementHistoryItemsSel,
  isParentGuardianSel,
  isParentOrYouthMemberSel,
  isYouthMemberSel,
  packRosterItemsSel,
  urlUserIdSel,
} from '@shared/duck';
import {
  organizationPositionSel,
  selectedOrganizationSel,
} from '@shared/duck/selectors/general.selectors';
import {
  canViewRosterSel,
  profileSel as selfProfileSel,
  userIdSel as selfUserIdSel,
} from '@user';
import {
  canEditAdvancement,
  makeSearch,
  personNameBuilder,
  sorters,
} from '@utils';

import { normalizeRankData } from '../../advancement/packRoster/utils';
import { childrenSel } from '../../parentGuardian';
import {
  advancementFilters,
  advancementSorters,
  advancementsStates,
  moduleName,
  sortAdvancementTypes,
} from '../constants';
import {
  getAdvancementUid,
  hasDenChiefPositionInAnyUnitAndIsPackSelected,
  hasDenChiefPositionInPack,
} from '../utils';

const youthProfileModuleSel = state => state[moduleName];

export const isMemberDetailsLoadingSel = state =>
  youthProfileModuleSel(state).isMemberDetailsLoading;
export const isEditingSel = state => youthProfileModuleSel(state).isEditing;

export const userIdSel = createSelector(
  urlUserIdSel,
  selfUserIdSel,
  isYouthMemberSel,
  (urlUserId, selfUserId, isYouthMember) =>
    isYouthMember ? selfUserId : urlUserId,
);

export const activityTypeSel = state => payloadSel(state).activityType;

export const isYouthWithinUnitSel = createSelector(
  userIdSel,
  packRosterItemsSel,
  (userId, packRoster) => !!packRoster.find(item => item.userId == userId),
);

export const memberDetailsSel = state =>
  youthProfileModuleSel(state).memberDetails || emptyObj;

export const youthYPTDetailsSel = state =>
  youthProfileModuleSel(state).youthYPTDetails;

export const youthAkelaProfileDetailsSel = state =>
  youthProfileModuleSel(state).youthAkelaProfileDetails;

export const youthBioSel = state =>
  youthAkelaProfileDetailsSel(state)
    ? youthAkelaProfileDetailsSel(state).extendedProfile.find(
        attr => attr.classification === 'MyBiography',
      )
    : '';

export const youthAkelaProfileLoadingSel = state =>
  youthProfileModuleSel(state).youthAkelaProfileLoading;

export const isSavingProfileSel = state =>
  youthProfileModuleSel(state).isSavingProfile;

export const youthDetailsSaveLoadingSel = state =>
  youthProfileModuleSel(state).youthDetailsSaveLoading;

export const youthProfilePictureSaveLoadingSel = state =>
  youthProfileModuleSel(state).youthProfilePictureSaveLoading;

export const memberEagleDetailsSel = createSelector(
  memberDetailsSel,
  memberDetails =>
    !isEmpty(memberDetails.profile)
      ? Object.fromEntries(
          Object.entries(memberDetails.profile).filter(([key]) =>
            key.includes('eagle'),
          ),
        )
      : {},
);

export const shouldMemberDetailsReloadSel = state =>
  youthProfileModuleSel(state).shouldMemberDetailsReload;

export const setEditYouthDetailSidebarSel = state =>
  youthProfileModuleSel(state).setEditYouthDetailSidebar;

export const isEagleActivityLoadingSel = state =>
  youthProfileModuleSel(state).isEagleActivityLoading;

export const eagleActivitySel = state =>
  youthProfileModuleSel(state).eagleActivity;

const rosterYouthProfileInfoSel = createSelector(
  userIdSel,
  packRosterItemsSel,
  (selectedUserId, packRoster) => ({
    ...(packRoster.find(({ userId }) => userId == selectedUserId) || {}),
    userId: selectedUserId,
  }),
);

/**
 * @returns {import('@modules/advancement/duck/types').RosterScout}
 */
const convertToYouthProfileModel = ({ userId, profile, memberDetails }) => {
  const { pictureUrl = '', dateOfBirth } = profile;
  let rankData = {};
  if (!isEmpty(memberDetails)) {
    const { ranks } = memberDetails;
    const normalizedRanks = ranks.map(normalizeRankData);

    const sortedRanks = normalizedRanks.sort(
      (rankA, rankB) => rankB.level - rankA.level,
    );

    const highestRankApproved = sortedRanks.filter(
      rank => rank.status === advancementStatuses.APPROVED,
    );

    const highestRanksAwarded = sortedRanks.filter(
      rank => rank.status === advancementStatuses.AWARDED,
    );

    rankData = {
      highestRankApproved,
      highestRanksAwarded,
      currentHighestRankApproved: sortedRanks.find(
        rank => rank.status === 'approved' || rank.status === 'awarded',
      ),
    };
  }

  const youth = {
    ...rankData,
    ...profile,
    userId,
    personShortFullName: personNameBuilder.short(profile),
    isAdult: false,
    pictureUrl,
    age: moment().diff(moment(dateOfBirth), 'years'),
  };

  return youth;
};

export const getYouthCurrentDen = createSelector(
  memberDetailsSel,
  memberDetails => {
    const denRegExp =
      /(lion)|(bobcat)|(tiger)|(wolf)|(bear)|(webelos)|(arrow of light)/i;
    const { organizationPositions = [] } = memberDetails;
    const positionNames = flatten(
      organizationPositions.map(position =>
        position.positions.map(position => position.name || ''),
      ),
    );
    if (positionNames.length === 0) {
      return null;
    }
    const denMatchIdx = findIndex(positionNames, position =>
      denRegExp.test(position),
    );
    return denMatchIdx >= 0
      ? positionNames[denMatchIdx].match(denRegExp)[0]
      : null;
  },
);

export const getYouthCurrentDenRankId = createSelector(
  getYouthCurrentDen,
  rankDictSel,
  (currentDen, ranksDict) => {
    if (currentDen) {
      const rankId = get(
        find(ranksDict, rank => toLower(rank.name) === toLower(currentDen)),
        'id',
      );

      return rankId;
    }
    return null;
  },
);

export const getYouthCurrentDenRankLevel = createSelector(
  getYouthCurrentDen,
  rankDictSel,
  (currentDen, ranksDict) => {
    if (currentDen) {
      const rankFound = find(
        ranksDict,
        rank => toLower(rank.name) === toLower(currentDen),
      );
      const rankLevel = rankFound?.level;
      return rankLevel;
    }
    return null;
  },
);

const mergeAdvancements = (youthAdvancements, dict, advancementType) => {
  const merged = [...youthAdvancements];
  dict.forEach(item => {
    if (!merged.find(mergedItem => mergedItem.id == item.id)) {
      merged.push({
        ...item,
        advancementType,
      });
    }
    merged.push({
      ...item,
      status: advancementStatuses.DICTIONARY,
      advancementType,
    });
  });

  return merged;
};

const enrichedAdventuresSel = createSelector(
  memberDetailsSel,
  adventureDictSel,
  (memberDetails, adventureDict) => {
    if (isEmpty(memberDetails)) {
      return [];
    }
    const { adventures } = memberDetails;
    const mergedAdventures = mergeAdvancements(
      adventures,
      adventureDict,
      advancementTypes.ADVENTURES,
    );
    return mergedAdventures;
  },
);

export const allAdventuresByIdSel = createSelector(
  enrichedAdventuresSel,
  enrichedAdventures => groupBy(enrichedAdventures, 'adventureId'),
);

export const adventuresSel = createSelector(
  enrichedAdventuresSel,
  enrichedAdventures => {
    // Filter expired adventures
    const filtered = filter(enrichedAdventures, adventure =>
      isNil(adventure.expiryDt)
        ? true
        : !moment().isAfter(moment(adventure.expiryDt)) ||
          (statusValues[statusMapParser[adventure.status]] ||
            statusValues.NOT_STARTED) >= statusValues.COMPLETED,
    );
    return filtered;
  },
);

export const pastYouthApprovedAdventuresSel = createSelector(
  adventuresSel,
  getYouthCurrentDenRankId,
  (adventures, denRankId) =>
    reverse(
      sortBy(
        filter(
          adventures,
          ({ status, rankId }) =>
            statusValues[statusMapParser[status]] > statusValues.COMPLETED &&
            (!denRankId || Number(denRankId) !== Number(rankId)),
        ),
        ({ dateCompleted }) => dateCompleted,
      ),
    ),
);

const filterAdvancements = (advancements, unitTypeId, hasMinFirstClass) => {
  if (unitTypeId == ProgramId.CUB_SCOUT) {
    return advancements;
  }
  let filteredAdvancements = advancements.filter(
    ({ unitTypeId }) => unitTypeId != ProgramId.CUB_SCOUT,
  );

  if (
    [ProgramId.VENTURING, ProgramId.SEA_SCOUT].includes(unitTypeId) &&
    !hasMinFirstClass
  ) {
    return filteredAdvancements.filter(
      ({ unitTypeId, programId }) =>
        !(
          unitTypeId == ProgramId.BOY_SCOUT || programId == ProgramId.BOY_SCOUT
        ),
    );
  }
  return filteredAdvancements;
};

export const highestCoreAwardSel = createSelector(
  memberDetailsSel,
  extraInfo => {
    const recordedAwards = extraInfo.awards || [];
    const recordedCoreAwards = recordedAwards.filter(
      award =>
        venturingCoreAwardsIds.includes(award.awardId) &&
        advancementStatus.isAtLeastApproved(award),
    );
    return recordedCoreAwards.pop();
  },
);

const getCoreAwardState = (award, highestCoreAward = {}) =>
  Number(highestCoreAward.awardId) > Number(award.awardId)
    ? advancementsStates.PREVIOUS
    : Number(highestCoreAward.awardId) == Number(award.awardId)
    ? advancementsStates.LAST_APPROVED
    : advancementsStates.NEXT;

export const venturingCoreAwardsSel = createSelector(
  memberDetailsSel,
  coreAwardsSel,
  highestCoreAwardSel,
  (memberDetails, coreAwards, highestCoreAward) => {
    const recordedAwards = memberDetails.awards || [];
    const mergedAwards = coreAwards.map(award => {
      const recordedAward = recordedAwards.find(
        ({ awardId }) => award.id == awardId,
      );
      if (recordedAward) {
        return recordedAward;
      }
      return {
        ...award,
        uid: getAdvancementUid(award, advancementTypes.AWARDS),
      };
    });
    return mergedAwards.map(award => ({
      ...award,
      state: getCoreAwardState(award, highestCoreAward),
    }));
  },
);

const unitRanksSel = createSelector(
  rankDictSel,
  unitTypeIdSel,
  (_state, unitTypeId) => unitTypeId,
  (ranks, unitTypeId, customUnitTypeId) => {
    const selectedProgramId = customUnitTypeId || unitTypeId;
    return ranks.filter(({ programId }) => selectedProgramId == programId);
  },
);

const ownChildrenSelV1 = createSelector(
  urlUserIdSel,
  childrenSel,
  memberDetailsSel,
  (urlUserId, children, memberDetails) => {
    const ownChild = children.find(({ userId }) => userId === urlUserId);
    if (ownChild) {
      return convertToYouthProfileModel({
        memberDetails,
        userId: urlUserId,
        profile: ownChild,
      });
    }
    return false;
  },
);

const ownChildrenSelV2 = createSelector(
  urlUserIdSel,
  childrenSel,
  memberDetailsSel,
  (urlUserId, children, memberDetails) => {
    const ownChild = children.find(({ userId }) => userId === urlUserId);
    if (ownChild) {
      return convertToYouthProfileModel({
        memberDetails,
        userId: urlUserId,
        profile: { ...ownChild, ...memberDetails },
      });
    }
    return false;
  },
);

const ownChildrenSel = SBL_5102_MY_SCOUT ? ownChildrenSelV2 : ownChildrenSelV1;

const selfYouthProfileSel = createSelector(
  selfUserIdSel,
  selfProfileSel,
  memberDetailsSel,
  (selfUserId, selfProfile, memberDetails) =>
    convertToYouthProfileModel({
      memberDetails,
      userId: selfUserId,
      profile: selfProfile,
    }),
);

export const youthInfoSel = state => {
  const { adultUserId = '' } = querySel(state) || {};
  const memberDetails = memberDetailsSel(state);
  let youth;
  switch (true) {
    case canViewRosterSel(state): {
      youth = rosterYouthProfileInfoSel(state);
      break;
    }
    case isParentGuardianSel(state): {
      youth = ownChildrenSel(state);
      break;
    }
    case !!adultUserId && !isEmpty(memberDetails): {
      const userId = urlUserIdSel(state);
      youth = convertToYouthProfileModel({
        memberDetails,
        userId,
        profile: memberDetails.profile,
      });
      break;
    }
    default:
      youth = selfYouthProfileSel(state);
  }

  const {
    pictureUrl,
    isAdult,
    age,
    personShortFullName,
    currentHighestRankApproved,
    otherHighestRanksApproved,
    hasMinFirstClass,
  } = youth;
  const scoutsBSAHighestRank = (otherHighestRanksApproved || []).find(
    ({ unitTypeId }) => unitTypeId == ProgramId.BOY_SCOUT,
  );

  return {
    pictureUrl,
    isAdult,
    age,
    hasMinFirstClass,
    personShortFullName,
    scoutsBSAHighestRank,
    currentHighestRankApproved,
    userId: youth.userId,
  };
};

export const highestRankSel = state =>
  youthInfoSel(state).currentHighestRankApproved;

const getRankState = (rank, highestRank = {}) =>
  Number(highestRank.level) > Number(rank.level)
    ? advancementsStates.PREVIOUS
    : Number(highestRank.level) == Number(rank.level)
    ? advancementsStates.LAST_APPROVED
    : advancementsStates.NEXT;

const getRanks = ({ ranksList, recordedRanks, highestRank }) =>
  ranksList
    .map(rank => {
      const recordedRank = recordedRanks.find(({ id }) => id == rank.id);
      if (recordedRank) {
        return recordedRank;
      }
      return { ...rank, uid: getAdvancementUid(rank, advancementTypes.RANKS) };
    })
    .map(rank => ({
      ...rank,
      state: getRankState(rank, highestRank),
    }));

export const ranksSel = createSelector(
  highestRankSel,
  memberDetailsSel,
  (state, unitTypeId) => unitRanksSel(state, unitTypeId),
  (highestRank, memberDetails, ranksList) => {
    const recordedRanks = memberDetails.ranks || [];
    const ranks = getRanks({ ranksList, recordedRanks, highestRank });
    const sortedRanks = stable(
      ranks,
      (a, b) => Number(a.level) > Number(b.level),
    );
    return sortedRanks;
  },
);

const highestHierarchicalAdvancementSel = createSelector(
  unitTypeIdSel,
  highestRankSel,
  highestCoreAwardSel,
  (unitTypeId, highestRank, highestCoreAward) =>
    unitTypeId == ProgramId.VENTURING ? highestCoreAward : highestRank,
);

const advancementsSel = createSelector(
  memberDetailsSel,
  unitTypeIdSel,
  youthInfoSel,
  awardDictSel,
  adventureDictSel,
  meritBadgeDictSel,
  rankDictSel,
  ssElectivesDictSel,
  (
    memberDetails,
    unitTypeId,
    youthInfo = {},
    awardDict = [],
    adventureDict = [],
    meritBadgeDict = [],
    rankDict = [],
    ssElectivesDict = [],
  ) => {
    if (isEmpty(memberDetails)) {
      return [];
    }

    const { awards, meritBadges, adventures, ranks, ssElectives } =
      memberDetails;
    const advancements = [
      ...mergeAdvancements(awards, awardDict, advancementTypes.AWARDS),
      ...mergeAdvancements(
        meritBadges,
        meritBadgeDict,
        advancementTypes.MERIT_BADGES,
      ),
      ...mergeAdvancements(
        adventures,
        adventureDict,
        advancementTypes.ADVENTURES,
      ),
      ...mergeAdvancements(ranks, rankDict, advancementTypes.RANKS),
      ...mergeAdvancements(
        ssElectives,
        ssElectivesDict,
        advancementTypes.SSELECTIVES,
      ),
    ];
    return filterAdvancements(
      advancements,
      unitTypeId,
      youthInfo.hasMinFirstClass,
    );
  },
);

const scoutsBSAHighestRankSel = state =>
  youthInfoSel(state).scoutsBSAHighestRank;

export const startedItemsSel = createSelector(
  advancementsSel,
  highestHierarchicalAdvancementSel,
  unitTypeIdSel,
  scoutsBSAHighestRankSel,
  (
    advancements,
    highestHierarchicalAdvancement,
    unitTypeId,
    scoutsBSAHighestRank,
  ) =>
    advancements
      .filter(({ status }) => status === advancementStatuses.STARTED)
      .map(advancement => ({
        ...advancement,
        isApprovable: canApproveAdvancement({
          unitTypeId,
          advancement,
          scoutsBSAHighestRank,
          highestHierarchicalAdvancement,
        }),
      })),
);

export const approvedItemsSel = createSelector(
  advancementsSel,
  userIdSel,
  programSel,
  packRosterItemsSel,
  (advancements, userId, program, youthItems) =>
    advancements
      .filter(advancement => advancementStatus.isAtLeastApproved(advancement))
      .map(item => {
        const isEditable = canEditAdvancement({
          item,
          program,
          youthItems,
        });
        const isUnapprovable =
          isEditable &&
          !getUnapproveConstraint({
            item: { ...item, userId },
            program,
            advancementsHistory: advancements,
          });

        return {
          ...item,
          userId,
          isEditable,
          isUnapprovable,
        };
      }),
);
export const completedItemsSel = createSelector(
  advancementsSel,
  userIdSel,
  programSel,
  packRosterItemsSel,
  (advancements, userId, program, youthItems) =>
    advancements
      .filter(
        advancement =>
          advancementStatus.isCompleted(advancement) &&
          !advancementStatus.isApproved(advancement),
      )
      .map(item => {
        const isEditable = canEditAdvancement({
          item,
          program,
          youthItems,
        });
        const isUnapprovable =
          isEditable &&
          !getUnapproveConstraint({
            item: { ...item, userId },
            program,
            advancementsHistory: advancements,
          });

        return {
          ...item,
          userId,
          isEditable,
          isUnapprovable,
        };
      }),
);

function getDate(date) {
  return date === '' || !date ? '9999-99-99' : date;
}

export const seaScoutsItemsSel = createSelector(
  advancementsSel,
  advancements => {
    const seaElectives = sortBy(
      filter(
        advancements,
        item => item.advancementType === advancementTypes.SSELECTIVES,
      ),
      item =>
        `${getDate(item.leaderApprovedDate)}-${item.ssElectiveLevel}-${getDate(
          item.dateCompleted,
        )}-${item.ssElectiveLevel}-${getDate(item.date)}-${
          item.ssElectiveLevel
        }`,
    );
    const sortOrder = map(uniqBy(seaElectives, 'ssElectiveId'), item =>
      item.ssElectiveId.toString(),
    );
    return {
      groups: reduce(
        groupBy(seaElectives, 'ssElectiveId'),
        (memo, group, index) => {
          set(memo, indexOf(sortOrder, index), group);
          return memo;
        },
        [],
      ),
      electiveIds: sortOrder,
    };
  },
);

export const youthProfileInfoSel = createSelector(
  youthInfoSel,
  memberDetailsSel,
  (youthInfo, fetchedInfo) => ({ ...youthInfo, ...fetchedInfo }),
);

export const parentsGuardiansSel = createSelector(
  youthProfileInfoSel,
  youthProfileInfo => {
    const { parentsGuardiansInfo } = youthProfileInfo;
    if (parentsGuardiansInfo) {
      return parentsGuardiansInfo.map(adult => ({
        ...adult,
        personShortFullName: personNameBuilder.short(adult),
      }));
    }
    return [];
  },
);

export const youthAdvRequirementsParamsSel = state => payloadSel(state);
export const youthAdvRequirementsLoadingSel = state =>
  youthProfileModuleSel(state).youthAdvRequirementsLoading;
export const youthAdvRequirementsSel = createSelector(
  youthProfileModuleSel,
  advancementHistoryItemsSel,
  programSel,
  (youthProfile, itemsHistory, program) => {
    const { youthAdvRequirements, memberDetails } = youthProfile;
    const { userId } = memberDetails || {};
    const unapproveLock = getUnapproveConstraint({
      program,
      item: { ...youthAdvRequirements, userId },
      advancementsHistory: itemsHistory,
    });

    const advancement = {
      canUnapprove: !unapproveLock,
      unapproveLock,
      type: historyItemsTypes.ADVANCEMENT,
      ...youthAdvRequirements,
    };
    return advancement;
  },
);
export const youthSel = createSelector(
  packRosterItemsSel,
  payloadSel,
  memberDetailsSel,
  (roster, routeParams, { profile: currentYouthProfile }) => {
    const { userId } = routeParams;
    if (roster.length === 0) {
      return currentYouthProfile
        ? {
            ...currentYouthProfile,
            personShortFullName: personNameBuilder.short(currentYouthProfile),
          }
        : {};
    }
    return roster.find(person => person.userId == userId) || {};
  },
);
export const youthAdvNotFoundSel = state =>
  youthProfileModuleSel(state).youthAdvNotFound;

export const advListFilterSel = state =>
  youthProfileModuleSel(state).advListFilter;
const advListSorterSel = state => youthProfileModuleSel(state).advListSorter;
export const advListSearchSel = state =>
  youthProfileModuleSel(state).advListSearch;

const filterByStatus = (filter, advancement) => {
  const { status } = advancement || {};
  switch (filter) {
    case advancementFilters.ALL: {
      return status !== advancementStatuses.DICTIONARY;
    }
    case advancementFilters.ALL_SSELECTIVES: {
      return status === advancementStatuses.DICTIONARY;
    }
    case advancementFilters.APPROVED: {
      return (
        status === advancementStatuses.APPROVED ||
        status === advancementStatuses.AWARDED
      );
    }
    case advancementFilters.STARTED: {
      return (
        status === advancementStatuses.STARTED ||
        status === advancementStatuses.COMPLETED ||
        status === advancementStatuses.COUNSELOR_APPROVED
      );
    }
    case advancementFilters.NOT_STARTED: {
      return !status;
    }
    case advancementFilters.COMPLETED: {
      return status === advancementStatuses.COMPLETED;
    }
    default: {
      return status !== advancementStatuses.DICTIONARY;
    }
  }
};

const getAdvListSorter = (sorter, advType) => {
  switch (sorter) {
    case advancementSorters.ALPHA: {
      return sorters.text('name');
    }
    case advancementSorters.ALPHA_DESC: {
      return sorters.text('name', true);
    }
    case advancementSorters.DATE_EARNED: {
      return advType === sortAdvancementTypes.MERIT_BADGES ||
        advType === sortAdvancementTypes.ADVENTURES
        ? sorters.date('dateCompleted')
        : sorters.date('dateEarned');
    }
    case advancementSorters.DATE_EARNED_DESC: {
      return advType === sortAdvancementTypes.MERIT_BADGES ||
        advType === sortAdvancementTypes.ADVENTURES
        ? sorters.date('dateCompleted', true)
        : sorters.date('dateEarned', true);
    }
    default: {
      return sorters.text('name');
    }
  }
};

export const filteredAdvListItemsSel = createSelector(
  advancementsSel,
  payloadSel,
  advListFilterSel,
  advListSorterSel,
  advListSearchSel,
  (advancements, routeParams, filter, sorter, search) => {
    const { advancementType } = routeParams;

    const searchIncludes = makeSearch(search);
    const filteredAdvancements = advancements
      .filter(item => {
        if (item.advancementType !== advancementTypes.RANKS) {
          return true;
        }
        return isValueTruthy(item.active) || item.awarded;
      })
      .filter(item => {
        if (
          item.advancementType !== advancementTypes.ADVENTURES ||
          filterByStatus(advancementFilters.APPROVED, item)
        ) {
          return true;
        }
        const expiryDt = item[advancementsExpiredKeys.ADVENTURES];
        return expiryDt ? moment(expiryDt).isSameOrAfter(moment()) : true;
      })
      .filter(
        item =>
          item.advancementType === advancementType &&
          searchIncludes(item.name) &&
          filterByStatus(filter, item),
      );
    return stable(
      filteredAdvancements,
      getAdvListSorter(sorter, advancementType),
    );
  },
);

export const youthPersonGuidSel = createSelector(
  memberDetailsSel,
  isParentOrYouthMemberSel,
  userIdSel,
  packRosterItemsSel,
  (memberDetails, isParentOrYouthMember, userId, rosterItems) => {
    if (memberDetails && isParentOrYouthMember) {
      const { profile: { personGuid: guid } = {} } = memberDetails || {};
      return guid;
    } else if (!isParentOrYouthMember) {
      const foundPerson = rosterItems.find(person => person.userId == userId);
      return foundPerson && foundPerson.personGuid;
    }
  },
);
export const youthPersonIdSel = createSelector(userIdSel, id => id);

export const updatingYouthAdvancementSel = state =>
  youthProfileModuleSel(state).updatingYouthAdvancement;

export const advReqEditModalOpenSel = state =>
  youthProfileModuleSel(state).advReqEditModalOpen;

export const advReqToEditSel = state =>
  youthProfileModuleSel(state).advReqToEdit;

export const activityLogsSummarySel = state =>
  youthProfileModuleSel(state).activityLogsSummary;

export const isLoadingActivityLogsSummarySel = state =>
  youthProfileModuleSel(state).isLoadingActivityLogsSummary;

export const isLoadingSubmitEagleProjectSel = state =>
  youthProfileModuleSel(state).isLoadingSubmitEagleProject;

const rankVersionsSel = state => youthProfileModuleSel(state).rankVersions;

export const nonExpiredAdvVersionsSel = createSelector(
  youthAdvRequirementsSel,
  rankVersionsSel,
  (youthReqs, rankVersions) => {
    const { versionId: activeVersionId } = youthReqs;
    const now = moment();

    return rankVersions.filter(version => {
      //expiredDate is the "scoutbook" expired date which controls whether we should hide the version
      const expiredDate = moment(version.expiredDate);

      if (
        !version.expiredDate ||
        expiredDate.isAfter(now) ||
        // also make an exception for versions that are already Active.
        version.versionId === activeVersionId
      ) {
        return true;
      }

      return false;
    });
  },
);

export const versionExpiryDateSel = createSelector(
  youthAdvRequirementsSel,
  rankVersionsSel,
  (requirements, versions) => {
    const { versionId: activeVersionId } = requirements;
    const activeVersion = versions.find(
      version => version.versionId === activeVersionId,
    );
    const expiryDate = activeVersion ? activeVersion.versionExpiryDt : null;
    return expiryDate;
  },
);
export const versionEffectiveDateSel = createSelector(
  youthAdvRequirementsSel,
  rankVersionsSel,
  (requirements, versions) => {
    const { versionId: activeVersionId } = requirements;
    const activeVersion = versions.find(
      version => version.versionId === activeVersionId,
    );
    const effectiveDate = activeVersion
      ? activeVersion.versionEffectiveDt
      : null;
    return effectiveDate;
  },
);
export const newestVersionSel = createSelector(
  rankVersionsSel,
  versions => versions[0] || {},
);
export const effectiveVersionSel = createSelector(
  rankVersionsSel,
  versions =>
    versions.find(version => {
      const versionStart = moment(version.versionEffectiveDt);
      const versionEnd = moment(version.versionExpiryDt);

      return (
        versionStart.isBefore(moment()) &&
        (versionEnd.isAfter(moment()) || !version.versionExpiryDt)
      );
    }) || {},
);

export const advRequirementsCommentsLoadingSel = state =>
  youthProfileModuleSel(state).advRequirementsCommentsLoading;

export const advRequirementsCommentsSel = state =>
  youthProfileModuleSel(state).advRequirementsComments;

export const patrolIdSelect = createSelector(memberDetailsSel, memberDetails =>
  memberDetails.organizationPositions
    ? memberDetails.organizationPositions[0]?.positions[0]?.patrolId
    : null,
);

export const denIdSelect = createSelector(memberDetailsSel, memberDetails =>
  memberDetails.organizationPositions
    ? memberDetails.organizationPositions[0]?.positions[0]?.denId
    : null,
);

export const isDenChiefSel = createSelector(
  selectedOrganizationSel,
  memberDetailsSel,
  (selectedOrganization, memberDetails) => {
    const isDenChief = hasDenChiefPositionInPack(
      selectedOrganization,
      memberDetails.organizationPositions,
    );
    return isDenChief;
  },
);

export const isDenChiefInAnyUnitAndIsPackSel = createSelector(
  selectedOrganizationSel,
  memberDetailsSel,
  (selectedOrganization, memberDetails) => {
    const isDenChief = hasDenChiefPositionInAnyUnitAndIsPackSelected(
      selectedOrganization,
      memberDetails.organizationPositions,
    );
    return isDenChief;
  },
);

export const groupedDenAdventuresSel = createSelector(
  getYouthCurrentDenRankId,
  adventuresSel,
  (denRankId, adventures) => {
    if (denRankId) {
      return groupBy(
        filter(adventures, adventure => adventure.rankId === Number(denRankId)),
        adventure =>
          adventure.isRequired || adventure.required ? 'required' : 'elective',
      );
    }
    return { required: [], elective: [] };
  },
);

export const canSeeYouthInfoSectionSel = createSelector(
  organizationPositionSel,
  isParentOrYouthMemberSel,
  currentUserSubunitsSel,
  memberDetailsSel,
  (currentUnit, isParentOrYouthMember, currentUserSubUnits, youthDetails) => {
    if (isParentOrYouthMember || currentUnit.canEditYouthProfilePerms)
      return true;

    // Match to sub units
    if (currentUnit.canEditSubCalendar) {
      if (!currentUserSubUnits.length || isEmpty(youthDetails)) return false;

      const foundYouthUnit = youthDetails.organizationPositions?.find(
        ({ organizationGuid }) =>
          organizationGuid === currentUnit.organizationGuid,
      );
      if (foundYouthUnit) {
        const hasValidSubUnits = foundYouthUnit.positions.some(pos => {
          const youthSubUnitId = pos.denId || pos.patrolId;
          return youthSubUnitId
            ? !!currentUserSubUnits.find(userSubunit => {
                const userSubunitId = userSubunit.denId || userSubunit.patrolId;

                return +userSubunitId === +youthSubUnitId;
              })
            : false;
        });

        return hasValidSubUnits;
      }
    }

    return true;
  },
);
