export const reportsModuleName = 'reports';

export const basicReportsModuleName = 'basicReports';
export const basicReportsModuleNamespace = `${reportsModuleName}/${basicReportsModuleName}`;

export const historyReportsModuleName = 'historyReports';
export const historyReportsModuleNamespace = `${reportsModuleName}/${historyReportsModuleName}`;

export const advancementReportModuleName = 'advancementReport';
export const advancementReportModuleNamespace = `${reportsModuleName}/${advancementReportModuleName}`;
