import { createSelector } from 'reselect';

import { programSel } from '@context/duck/selectorsTyped';
import {
  advancementHistoryItemsSel,
  packRosterItemsSel,
  syncOperations,
} from '@shared';
import {
  advancementStatusDate,
  findPreviousHierarchicalAdvancement,
  getUnapproveConstraint,
  isHierarchicalAdvancement,
} from '@shared/utils';

import { editAdvancementModuleSel } from '../../common';

const moduleSel = state => editAdvancementModuleSel(state);

export const visibleSel = state => moduleSel(state).visible;
export const fromProfileSel = state => moduleSel(state).fromProfile;
export const loadingSel = state => moduleSel(state).loading;
export const updateErrorSel = state => moduleSel(state).updateError;
const _personSel = state => moduleSel(state).person;
export const personSel = createSelector(
  _personSel,
  packRosterItemsSel,
  (person, packRoster) => {
    const youthData =
      packRoster.find(({ userId }) => userId === person.userId) || {};
    return { ...youthData, ...person };
  },
);
export const unapproveModalVisibleSel = state =>
  moduleSel(state).unapproveModalVisible;
export const unapproveLoadingSel = state => moduleSel(state).unapproveLoading;

export const advancementSel = createSelector(
  advancementHistoryItemsSel,
  state => moduleSel(state).advancement,
  programSel,
  (activityItems, advancement, program) => {
    if (!advancement) {
      return null;
    }

    if (!isHierarchicalAdvancement(advancement)) {
      return { ...advancement, canUnapproveAdvancement: true };
    }

    const previousHistoryItem = findPreviousHierarchicalAdvancement({
      item: advancement,
      itemsHistory: activityItems,
    });
    const previousAdvancementCompletionDate =
      advancementStatusDate.getCompleted(previousHistoryItem);

    const unapproveLock = getUnapproveConstraint({
      program,
      item: advancement,
      advancementsHistory: activityItems,
    });

    return {
      ...advancement,
      unapproveLock,
      previousAdvancementCompletionDate,
      canUnapproveAdvancement: !unapproveLock,
    };
  },
);

export const isOfflineOperationSel = state => {
  const advancement = moduleSel(state).advancement;
  if (!advancement) {
    return false;
  }
  return (
    !advancement.isSynced &&
    advancement.operation == syncOperations.RECORD_ADVANCEMENT
  );
};
