import { organizationPositionSel } from '@shared/duck/selectors/general.selectors';
import _ from 'lodash';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export function usePositions() {
  const orgInfo = useSelector(organizationPositionSel);

  /**
   * Checks if the user has any of the given organization positions.
   *
   * Usage:
    ```
    const { hasPositions } = usePositions();
    const isTroopAdminOrKey3 = hasPositions(['Key 3 Delegate', 'Troop Admin']);
    ```
   */
  const hasPositions = useCallback(
    (positions: string[]) => {
      const common = _.intersection(orgInfo.allRoles, positions);
      const found = !_.isEmpty(common);

      return found;
    },
    [orgInfo],
  );

  return { hasPositions };
}
