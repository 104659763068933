import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SortSelect } from '@shared';

const { Option } = SortSelect;

class SortSection extends React.PureComponent {
  render() {
    const { sorter, onChange } = this.props;

    return (
      <SortSelect sorter={sorter} onChange={onChange}>
        <Option name="name" type="string">
          <FormattedMessage id="advancement.AdvancementHistory.column.name" />
        </Option>
        <Option name="updatedDate" type="date">
          <FormattedMessage id="advancement.AdvancementHistory.column.updatedDate" />
        </Option>
        <Option name="recordedBy" type="string">
          <FormattedMessage id="advancement.AdvancementHistory.column.recordedBy" />
        </Option>
        <Option name="description" type="string">
          <FormattedMessage id="advancement.AdvancementHistory.column.description" />
        </Option>
        <Option name="status" type="number">
          <FormattedMessage id="advancement.AdvancementHistory.column.status" />
        </Option>
      </SortSelect>
    );
  }
}

SortSection.propTypes = {
  sorter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SortSection;
