import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './TableCard.less';

const isChildComponentOpen = ({ child }) =>
  child && child.content && (child.open || child.defaultOpen);

function retrieveChildElement({ child: { content } }) {
  const { props: oldProps } = content;
  return React.cloneElement(
    content,
    { className: cn(styles.row, oldProps.className) },
    oldProps.children,
  );
}

// eslint-disable-next-line react/no-multi-comp
function renderRow(row) {
  if (row.label) {
    return (
      <React.Fragment key={row.rowId}>
        <div
          className={cn(
            styles.row,
            {
              [styles.noBorderRow]: isChildComponentOpen(row),
              [styles.clickable]: !!row.onClick,
            },
            row.className,
          )}
          onClick={row.onClick}
        >
          <div className={cn(styles.labelCol, 'label')}>{row.label}</div>
          <div className={cn(styles.contentCol, 'content')}>{row.content}</div>
        </div>
        {isChildComponentOpen(row) && retrieveChildElement(row)}
      </React.Fragment>
    );
  } else {
    return (
      <div
        key={row.rowId}
        className={cn(styles.row, row.className, {
          [styles.clickable]: !!row.onClick,
        })}
        onClick={row.onClick}
      >
        {row.content}
      </div>
    );
  }
}

function TableCard({
  item,
  rows,
  className,
  hasBorder,
  borderColorIntensity,
  disabled,
  onClick,
}) {
  const rowsNodes = rows(item)
    .filter(({ omit }) => !omit)
    .map(row => renderRow(row));

  return (
    <div
      className={cn(
        {
          [styles.outerBorder]: hasBorder,
          [styles[`borderGray-${borderColorIntensity}`]]: hasBorder,
          [styles.disabled]: disabled,
        },
        className,
      )}
      onClick={onClick}
    >
      {rowsNodes}
    </div>
  );
}

TableCard.propTypes = {
  item: PropTypes.object.isRequired,
  rows: PropTypes.func.isRequired,
  className: PropTypes.string,
  hasBorder: PropTypes.bool,
  borderColorIntensity: PropTypes.oneOf([1, 2, 3, 4, 5]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

TableCard.defaultProps = {
  className: null,
  borderColorIntensity: 2,
  hasBorder: true,
};

export default TableCard;
