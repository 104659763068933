import {
  activityValueTypesNames as valueTypes,
  activityTypeIds,
} from '../../constants';

export default Object.freeze({
  [activityTypeIds.CAMPOUTS]: [
    valueTypes.NIGHTS,
    valueTypes.DAYS,
    valueTypes.FROST_POINTS,
  ],
  [activityTypeIds.HIKES]: [
    valueTypes.MILES,
    valueTypes.HIGHEST_POINT,
    valueTypes.LOWEST_POINT,
  ],
  [activityTypeIds.SERVICE_PROJECTS]: [valueTypes.HOURS],
  [activityTypeIds.LONG_CRUISE]: [valueTypes.LONG_CRUISE_DAYS],
});
