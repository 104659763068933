import { cloneDeep } from 'lodash';
import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { organizationGuidSel } from '@context';
import { intl } from '@shared';
import { toastService } from '@toasts';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  formatApiDateTime,
  loadAndOpenActivity,
  selectedActivitySel,
} from '../../common';
import { getEagleHours } from '../utils';
import {
  LOAD_AND_OPEN_EAGLE_PROJECT_MODAL,
  UPDATE_EAGLE_PROJECT_REQUEST,
  advancementHistoryRequest,
  closeEagleProjectEditModal,
  loadAndOpenEagleProjectModalError,
  openEditEagleProject,
  submitEagleProjectError,
  submitEagleProjectResponse,
} from './actions';
import services from './services';

const editEagleParticipants$ = (
  eagleActivityId,
  organizationGuid,
  timeActivityValues = [],
  prevParticipants = [],
) => {
  const updatedParticipants = prevParticipants.map(participant => {
    const updatedActivityValues = participant.activityValues.map(
      activityObj => {
        const activityObjClone = cloneDeep(activityObj);
        const [hours] = timeActivityValues;

        if (activityObjClone.activityValueTypeId === 10) {
          activityObjClone.activityValue = hours.activityValue;
        }
        return activityObjClone;
      },
    );

    return {
      userId: participant.userId,
      organizationGuid,
      activityValues: updatedActivityValues,
      isDeclined: false,
      note: 'N/A',
    };
  });

  return services.updateEagleParticipants$(
    eagleActivityId,
    updatedParticipants,
  );
};

const editEagleProjectEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_EAGLE_PROJECT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { id, data } = payload;
      const organizationGuid = organizationGuidSel(state);
      const eagleActivity = selectedActivitySel(state);
      const timeActivityValues = getEagleHours(data.totalHours);
      const prevParticipants = eagleActivity.registeredAdults.concat(
        eagleActivity.registeredYouths,
      );

      const {
        categoryId,
        startDate,
        endDate,
        benefitGroup,
        name,
        location,
        addressLine1,
        addressLine2,
        city,
        akelaStateId,
        zip5,
      } = data;

      const body = {
        categoryId,
        startDateTime: formatApiDateTime(startDate, id),
        endDateTime: formatApiDateTime(endDate, id),
        name,

        benefitGroup: benefitGroup,
        location: location ? location : '',
        addressLine1: addressLine1 ? addressLine1 : '',
        addressLine2: addressLine2 ? addressLine2 : '',
        city: city ? city : '',
        akelaStateId: akelaStateId ? Number(akelaStateId) : null,
        zip5: zip5 ? zip5 : '',
      };
      return forkJoin([
        services.updateEagleActivity$(id, body),
        editEagleParticipants$(
          id,
          organizationGuid,
          timeActivityValues,
          prevParticipants,
        ),
      ]).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({
              id: 'youthProfile.editEagleProject',
            }),
          );
        }),
        mergeMap(() =>
          Observable.concat(
            of(submitEagleProjectResponse()),
            of(closeEagleProjectEditModal()),
            of(advancementHistoryRequest()),
          ),
        ),
        catchAndReport(err => of(submitEagleProjectError(err))),
      );
    }),
  );

const loadEagleProjectByIdEpic$ = action$ =>
  action$.pipe(
    ofType(LOAD_AND_OPEN_EAGLE_PROJECT_MODAL),
    map(({ payload }) =>
      loadAndOpenActivity({
        ...payload,
        successActionFn: openEditEagleProject,
        errorActionFn: loadAndOpenEagleProjectModalError,
      }),
    ),
  );

export default combineEpics(editEagleProjectEpic$, loadEagleProjectByIdEpic$);
