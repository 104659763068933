import { advancementTypes } from '@modules/shared';

export {
  pendingItemsModuleName as moduleName,
  historyItemsTypes,
} from '../common';

export const filterPanels = Object.freeze({
  ADVANCEMENT_TYPES: 'advancementTypes',
  ACTIVITY_TYPES: 'activityTypes',
  SORTER: 'sorter',
});

export const PendingItemsTabs = Object.freeze({
  RANKS: 'ranks',
  MERIT_BADGES: 'meritBadges',
  ACTIVITIES: 'activities',
  ADVENTURES: 'adventures',
  AWARDS: 'awards',
  SS_ELECTIVES: 'ssElectives',
  LEADERSHIP_POSITIONS: 'leadershipPositions',
  REQUIREMENTS: 'requirements',
});

export const advancementTypeToTabsMap = Object.freeze({
  [advancementTypes.MERIT_BADGES]: PendingItemsTabs.MERIT_BADGES,
  [advancementTypes.RANKS]: PendingItemsTabs.RANKS,
  [advancementTypes.AWARDS]: PendingItemsTabs.AWARDS,
  [advancementTypes.ADVENTURES]: PendingItemsTabs.ADVENTURES,
  [advancementTypes.SSELECTIVES]: PendingItemsTabs.SS_ELECTIVES,
});

export const tabsToAdvancementTypeMap = Object.freeze({
  [PendingItemsTabs.MERIT_BADGES]: advancementTypes.MERIT_BADGES,
  [PendingItemsTabs.RANKS]: advancementTypes.RANKS,
  [PendingItemsTabs.AWARDS]: advancementTypes.AWARDS,
  [PendingItemsTabs.ACTIVITIES]: 'activities',
  [PendingItemsTabs.ADVENTURES]: advancementTypes.ADVENTURES,
  [PendingItemsTabs.SS_ELECTIVES]: advancementTypes.SSELECTIVES,
});

export const AdvancementsTabsNames = Object.freeze({
  BULK_ENTRY: 'bulkEntry',
  TO_APPROVE: 'toApprove',
  TO_PURCHASE: 'toPurchase',
  TO_AWARD: 'toAward',
  AWARDED: 'awarded',
});

export const AdvancementsTabs = Object.freeze([
  AdvancementsTabsNames.BULK_ENTRY,
  AdvancementsTabsNames.TO_APPROVE,
  AdvancementsTabsNames.TO_PURCHASE,
  AdvancementsTabsNames.TO_AWARD,
  AdvancementsTabsNames.AWARDED,
]);
