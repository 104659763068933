import React from 'react';

import { EditActivityButton } from '../../../common';
import { loadAndOpenCampoutModal, openEditCampout } from '../../duck/actions';

const EditCampoutButton = props => (
  <EditActivityButton
    onLoadAction={loadAndOpenCampoutModal}
    onOpenAction={openEditCampout}
    {...props}
  />
);

export default EditCampoutButton;
