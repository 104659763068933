import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  //components
  Checkbox,
  Form,
  Row,
  Col,
} from '@shared';

const { Item: FormItem } = Form;

const ActivityRsvp = ({ form }) => {
  const { getFieldDecorator } = form;
  return (
    <Row type="flex" justify="space-between">
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('rsvp', {
            initialValue: true,
            valuePropName: 'checked',
          })(
            <Checkbox>
              <FormattedMessage id="progress.activity.CreateActivityForm.allowRsvp" />
            </Checkbox>,
          )}
        </FormItem>
      </Col>
    </Row>
  );
};

ActivityRsvp.propTypes = {
  form: PropTypes.object.isRequired,
};

export default ActivityRsvp;
