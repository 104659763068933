import { moduleName } from '../constants';

export const ROUTE_RECHARTER = `${moduleName}/ROUTE_RECHARTER`;

export const SET_CURRENT_STEP = `${moduleName}/SET_CURRENT_STEP`;
export const setCurrentStep = step => ({
  type: SET_CURRENT_STEP,
  payload: step,
});

// update member position in recharter
export const OPEN_UPDATE_POSITION_MODAL = `${moduleName}/OPEN_UPDATE_POSITION_MODAL`;
export const openUpdatePositionModal = () => ({
  type: OPEN_UPDATE_POSITION_MODAL,
});

export const CLOSE_UPDATE_POSITION_MODAL = `${moduleName}/CLOSE_UPDATE_POSITION_MODAL`;
export const closeUpdatePositionModal = () => ({
  type: CLOSE_UPDATE_POSITION_MODAL,
});

export const SET_MEMBER_POSITION_TO_UPDATE = `${moduleName}/SET_MEMBER_POSITION_TO_UPDATE`;
export const setMemberPositionToUpdate = (
  personId,
  personFullName,
  positionId,
  memberId,
  memberTypeId,
  age,
) => ({
  type: SET_MEMBER_POSITION_TO_UPDATE,
  payload: {
    personId,
    personFullName,
    positionId,
    memberId,
    memberTypeId,
    age,
  },
});

export const OPEN_SELECT_ADD_MEMBERS_FLOW_MODAL = `${moduleName}/OPEN_SELECT_ADD_MEMBERS_FLOW_MODAL`;
export const openSelectAddMembersFlowModal = () => ({
  type: OPEN_SELECT_ADD_MEMBERS_FLOW_MODAL,
});

export const CLOSE_SELECT_ADD_MEMBERS_FLOW_MODAL = `${moduleName}/CLOSE_SELECT_ADD_MEMBERS_FLOW_MODAL`;
export const closeSelectAddMembersFlowModal = () => ({
  type: CLOSE_SELECT_ADD_MEMBERS_FLOW_MODAL,
});

export const OPEN_ADD_MEMBERS_MODAL = `${moduleName}/OPEN_ADD_MEMBERS_MODAL`;
export const openAddMembersModal = isNewMemberApplication => ({
  type: OPEN_ADD_MEMBERS_MODAL,
  payload: isNewMemberApplication,
});

export const CLOSE_ADD_MEMBERS_MODAL = `${moduleName}/CLOSE_ADD_MEMBERS_MODAL`;
export const closeAddMembersModal = () => ({
  type: CLOSE_ADD_MEMBERS_MODAL,
});

export const OPEN_UNIT_PAYMENT_ERROR_MODAL = `${moduleName}/OPEN_UNIT_PAYMENT_ERROR_MODAL`;
export const openUnitPaymentErrorModal = () => ({
  type: OPEN_UNIT_PAYMENT_ERROR_MODAL,
});

export const CLOSE_UNIT_PAYMENT_ERROR_MODAL = `${moduleName}/CLOSE_UNIT_PAYMENT_ERROR_MODAL`;
export const closeUnitPaymentErrorModal = () => ({
  type: CLOSE_UNIT_PAYMENT_ERROR_MODAL,
});

export const OPEN_PAYMENT_MODAL = `${moduleName}/OPEN_PAYMENT_MODAL`;
export const openPaymentModal = () => ({
  type: OPEN_PAYMENT_MODAL,
});

export const CLOSE_PAYMENT_MODAL = `${moduleName}/CLOSE_PAYMENT_MODAL`;
export const closePaymentModal = () => ({
  type: CLOSE_PAYMENT_MODAL,
});

export const OPEN_UPLOAD_DOCUMENT_MODAL = `${moduleName}/OPEN_UPLOAD_DOCUMENT_MODAL`;
export const openUploadDocumentModal = () => ({
  type: OPEN_UPLOAD_DOCUMENT_MODAL,
});

export const CLOSE_UPLOAD_DOCUMENT_MODAL = `${moduleName}/CLOSE_UPLOAD_DOCUMENT_MODAL`;
export const closeUploadDocumentModal = () => ({
  type: CLOSE_UPLOAD_DOCUMENT_MODAL,
});
export const OPEN_CONFIRMATION_PAYMENT_MODAL = `${moduleName}/OPEN_CONFIRMATION_PAYMENT_MODAL`;
export const openConfirmationPaymentModal = ({
  message,
  onConfirm = () => {},
}) => ({
  type: OPEN_CONFIRMATION_PAYMENT_MODAL,
  payload: {
    message,
    onConfirm,
  },
});

export const CLOSE_CONFIRMATION_PAYMENT_MODAL = `${moduleName}/CLOSE_CONFIRMATION_PAYMENT_MODAL`;
export const closeConfirmationPaymentModal = () => ({
  type: CLOSE_CONFIRMATION_PAYMENT_MODAL,
});

export const OPEN_RESEND_INVITE_MODAL = `${moduleName}/OPEN_RESEND_INVITE_MODAL`;
export const openResendInviteModal = paymentInfo => ({
  type: OPEN_RESEND_INVITE_MODAL,
  payload: paymentInfo,
});

export const CLOSE_RESEND_INVITE_MODAL = `${moduleName}/CLOSE_RESEND_INVITE_MODAL`;
export const closeResendInviteModal = () => ({
  type: CLOSE_RESEND_INVITE_MODAL,
});

export const OPEN_EDIT_TERM_MODAL = `${moduleName}/OPEN_EDIT_TERM_MODAL`;
export const openEditTermModal = () => ({ type: OPEN_EDIT_TERM_MODAL });

export const CLOSE_EDIT_TERM_MODAL = `${moduleName}/CLOSE_EDIT_TERM_MODAL`;
export const closeEditTermModal = () => ({ type: CLOSE_EDIT_TERM_MODAL });

export const OPEN_ACH_VERIFICATION_MODAL = `${moduleName}/OPEN_ACH_VERIFICATION_MODAL`;
export const openAchVerificationModal = () => ({
  type: OPEN_ACH_VERIFICATION_MODAL,
});

export const CLOSE_ACH_VERIFICATION_MODAL = `${moduleName}/CLOSE_ACH_VERIFICATION_MODAL`;
export const closeAchVerificationModal = () => ({
  type: CLOSE_ACH_VERIFICATION_MODAL,
});

export const BATCHES_REQUEST = `${moduleName}/BATCHES_REQUEST`;
export const BATCHES_RESPONSE = `${moduleName}/BATCHES_RESPONSE`;
export const BATCHES_ERROR = `${moduleName}/BATCHES_ERROR`;
export const batchesRequest = () => ({
  type: BATCHES_REQUEST,
});
export const batchesResponse = roster => ({
  type: BATCHES_RESPONSE,
  payload: roster,
});
export const batchesError = err => ({
  type: BATCHES_ERROR,
  payload: err,
});

export const SET_SHOW_TERM_SELECTOR = `${moduleName}/SET_SHOW_TERM_SELECTOR`;
export const setShowSelector = value => ({
  type: SET_SHOW_TERM_SELECTOR,
  payload: value,
});

//
// unit recharter
//
export const RECHARTER_ROSTER_REQUEST = `${moduleName}/RECHARTER_ROSTER_REQUEST`;
export const RECHARTER_ROSTER_RESPONSE = `${moduleName}/RECHARTER_ROSTER_RESPONSE`;
export const RECHARTER_ROSTER_ERROR = `${moduleName}/RECHARTER_ROSTER_ERROR`;
export const recharterRosterRequest = () => ({
  type: RECHARTER_ROSTER_REQUEST,
});
export const recharterRosterResponse = roster => ({
  type: RECHARTER_ROSTER_RESPONSE,
  payload: roster,
});
export const recharterRosterError = err => ({
  type: RECHARTER_ROSTER_ERROR,
  payload: err,
});

export const SET_PENDING_SELECTED_KEYS = `${moduleName}/SET_PENDING_SELECTED_KEYS`;
export const setPendingSelectedKeys = keys => ({
  type: SET_PENDING_SELECTED_KEYS,
  payload: keys,
});

export const REMOVE_PENDING_SELECTED_KEY = `${moduleName}/REMOVE_PENDING_SELECTED_KEY`;
export const removePendingSelectedKey = key => ({
  type: REMOVE_PENDING_SELECTED_KEY,
  payload: key,
});

export const SET_RECHARTER_ROSTER_SELECTED_KEYS = `${moduleName}/SET_RECHARTER_ROSTER_SELECTED_KEYS`;
export const setRecharterRosterSelectedKeys = keys => ({
  type: SET_RECHARTER_ROSTER_SELECTED_KEYS,
  payload: keys,
});

export const UPDATE_SCOUTS_LIFE_REQUEST = `${moduleName}/UPDATE_SCOUTS_LIFE_REQUEST`;
export const UPDATE_SCOUTS_LIFE_RESPONSE = `${moduleName}/UPDATE_SCOUTS_LIFE_RESPONSE`;
export const UPDATE_SCOUTS_LIFE_ERROR = `${moduleName}/UPDATE_SCOUTS_LIFE_ERROR`;
export const updateScoutsLifeRequest = memberInfo => ({
  type: UPDATE_SCOUTS_LIFE_REQUEST,
  payload: memberInfo,
});
export const updateScoutsLifeResponse = () => ({
  type: UPDATE_SCOUTS_LIFE_RESPONSE,
});
export const updateScoutsLifeError = () => ({
  type: UPDATE_SCOUTS_LIFE_ERROR,
});
export const REMOVE_RECHARTER_ROSTER_SELECTED_KEY = `${moduleName}/REMOVE_RECHARTER_ROSTER_SELECTED_KEY`;
export const removeRecharterRosterSelectedKey = key => ({
  type: REMOVE_RECHARTER_ROSTER_SELECTED_KEY,
  payload: key,
});

export const SET_REMOVED_MEMBERS_SELECTED_KEYS = `${moduleName}/SET_REMOVED_MEMBERS_SELECTED_KEYS`;
export const setRemovedMembersSelectedKeys = keys => ({
  type: SET_REMOVED_MEMBERS_SELECTED_KEYS,
  payload: keys,
});

export const SET_RECHARTER_ROSTER_SEARCH = `${moduleName}/SET_RECHARTER_ROSTER_SEARCH`;
export const setRecharterRosterSearch = search => ({
  type: SET_RECHARTER_ROSTER_SEARCH,
  payload: search,
});

export const SET_RECHARTER_ROSTER_FILTERS = `${moduleName}/SET_RECHARTER_ROSTER_FILTERS`;
export const setRecharterRosterFilters = filters => ({
  type: SET_RECHARTER_ROSTER_FILTERS,
  payload: filters,
});

export const REMOVE_MEMBERS_REQUEST = `${moduleName}/REMOVE_MEMBERS_REQUEST`;
export const REMOVE_MEMBERS_RESPONSE = `${moduleName}/REMOVE_MEMBERS_RESPONSE`;
export const REMOVE_MEMBERS_ERROR = `${moduleName}/REMOVE_MEMBERS_ERROR`;
export const removeMembersRequest = keys => ({
  type: REMOVE_MEMBERS_REQUEST,
  payload: keys,
});
export const removeMembersResponse = () => ({
  type: REMOVE_MEMBERS_RESPONSE,
});
export const removeMembersError = () => ({
  type: REMOVE_MEMBERS_ERROR,
});

export const ADD_BACK_MEMBERS_REQUEST = `${moduleName}/ADD_BACK_MEMBERS_REQUEST`;
export const ADD_BACK_MEMBERS_RESPONSE = `${moduleName}/ADD_BACK_MEMBERS_RESPONSE`;
export const ADD_BACK_MEMBERS_ERROR = `${moduleName}/ADD_BACK_MEMBERS_ERROR`;
export const addBackMembersRequest = keys => ({
  type: ADD_BACK_MEMBERS_REQUEST,
  payload: keys,
});
export const addBackMembersResponse = () => ({
  type: ADD_BACK_MEMBERS_RESPONSE,
});
export const addBackMembersError = () => ({
  type: ADD_BACK_MEMBERS_ERROR,
});

export const CLOSE_ADD_BACK_MEMBERS_MODAL = `${moduleName}/CLOSE_ADD_BACK_MEMBERS_MODAL`;
export const closeAddToRecharterModal = () => ({
  type: CLOSE_ADD_BACK_MEMBERS_MODAL,
});

export const OPEN_ADD_BACK_MEMBERS_MODAL = `${moduleName}/OPEN_ADD_BACK_MEMBERS_MODAL`;
export const openAddToRecharterModal = () => ({
  type: OPEN_ADD_BACK_MEMBERS_MODAL,
});

export const ADD_MEMBER_REQUEST = `${moduleName}/ADD_MEMBER_REQUEST`;
export const addMemberRequest = memberInfo => ({
  type: ADD_MEMBER_REQUEST,
  payload: memberInfo,
});

export const ADD_MEMBER_RESPONSE = `${moduleName}/ADD_MEMBER_RESPONSE`;
export const addMemberResponse = () => ({
  type: ADD_MEMBER_RESPONSE,
});

export const ADD_MEMBER_ERROR = `${moduleName}/ADD_MEMBER_ERROR`;
export const addMemberError = () => ({
  type: ADD_MEMBER_ERROR,
});

export const UPLOAD_DOCUMENT_REQUEST = `${moduleName}/UPLOAD_DOCUMENT_REQUEST`;
export const uploadDocumentRequest = files => ({
  type: UPLOAD_DOCUMENT_REQUEST,
  payload: files,
});

export const UPLOAD_DOCUMENT_RESPONSE = `${moduleName}/UPLOAD_DOCUMENT_RESPONSE`;
export const uploadDocumentResponse = () => ({
  type: UPLOAD_DOCUMENT_RESPONSE,
});

export const UPLOAD_DOCUMENT_ERROR = `${moduleName}/UPLOAD_DOCUMENT_ERROR`;
export const uploadDocumentError = () => ({
  type: UPLOAD_DOCUMENT_ERROR,
});

export const GET_UNIT_POSITIONS_REQUEST = `${moduleName}/GET_UNIT_POSITIONS_REQUEST`;
export const getUnitPositionsRequest = () => ({
  type: GET_UNIT_POSITIONS_REQUEST,
});

export const GET_UNIT_POSITIONS_RESPONSE = `${moduleName}/GET_UNIT_POSITIONS_RESPONSE`;
export const getUnitPositionsResponse = positions => ({
  type: GET_UNIT_POSITIONS_RESPONSE,
  payload: positions,
});

export const GET_UNIT_POSITIONS_ERROR = `${moduleName}/GET_UNIT_POSITIONS_ERROR`;
export const getUnitPositionsError = () => ({
  type: GET_UNIT_POSITIONS_ERROR,
});

export const GET_UNIT_BASIC_REQUIREMENTS_REQUEST = `${moduleName}/GET_UNIT_BASIC_REQUIREMENTS_REQUEST`;
export const getUnitBasicRequirementsRequest = () => ({
  type: GET_UNIT_BASIC_REQUIREMENTS_REQUEST,
});

export const GET_UNIT_BASIC_REQUIREMENTS_RESPONSE = `${moduleName}/GET_UNIT_BASIC_REQUIREMENTS_RESPONSE`;
export const getUnitBasicRequirementsResponse = positions => ({
  type: GET_UNIT_BASIC_REQUIREMENTS_RESPONSE,
  payload: positions,
});

export const GET_UNIT_BASIC_REQUIREMENTS_ERROR = `${moduleName}/GET_UNIT_BASIC_REQUIREMENTS_ERROR`;
export const getUnitBasicRequirementsError = () => ({
  type: GET_UNIT_BASIC_REQUIREMENTS_ERROR,
});

export const GET_UNIT_PAYMENT_SUMMARY_REQUEST = `${moduleName}/GET_UNIT_PAYMENT_SUMMARY_REQUEST`;
export const getUnitPaymentSummaryRequest = batchApplicationId => ({
  type: GET_UNIT_PAYMENT_SUMMARY_REQUEST,
  payload: batchApplicationId,
});

export const GET_UNIT_PAYMENT_SUMMARY_RESPONSE = `${moduleName}/GET_UNIT_PAYMENT_SUMMARY_RESPONSE`;
export const getUnitPaymentSummaryResponse = paymentSummary => ({
  type: GET_UNIT_PAYMENT_SUMMARY_RESPONSE,
  payload: paymentSummary,
});

export const GET_UNIT_PAYMENT_SUMMARY_ERROR = `${moduleName}/GET_UNIT_PAYMENT_SUMMARY_ERROR`;
export const getUnitPaymentSummaryError = () => ({
  type: GET_UNIT_PAYMENT_SUMMARY_ERROR,
});

export const UPDATE_POSITION_REQUEST = `${moduleName}/UPDATE_POSITION_REQUEST`;
export const UPDATE_POSITION_RESPONSE = `${moduleName}/UPDATE_POSITION_RESPONSE`;
export const UPDATE_POSITION_ERROR = `${moduleName}/UPDATE_POSITION_ERROR`;
export const updatePositionRequest = memberInfo => ({
  type: UPDATE_POSITION_REQUEST,
  payload: memberInfo,
});
export const updatePositionResponse = () => ({
  type: UPDATE_POSITION_RESPONSE,
});
export const updatePositionError = () => ({
  type: UPDATE_POSITION_ERROR,
});

// controls for validate recharter modal
export const OPEN_VALIDATION_MODAL = `${moduleName}/OPEN_VALIDATION_MODAL`;
export const openValidationModal = () => ({
  type: OPEN_VALIDATION_MODAL,
});

export const CLOSE_VALIDATION_MODAL = `${moduleName}/CLOSE_VALIDATION_MODAL`;
export const closeValidationModal = () => ({
  type: CLOSE_VALIDATION_MODAL,
});

export const OPEN_PRIVACY_MODAL = `${moduleName}/OPEN_PRIVACY_MODAL`;
export const openPrivacyModal = () => ({
  type: OPEN_PRIVACY_MODAL,
});

export const CLOSE_PRIVACY_MODAL = `${moduleName}/CLOSE_PRIVACY_MODAL`;
export const closePrivacyModal = () => ({
  type: CLOSE_PRIVACY_MODAL,
});

export const RECHARTER_VALIDATE_ROSTER_REQUEST = `${moduleName}/RECHARTER_VALIDATE_ROSTER_REQUEST`;
export const recharterValidateRosterRequest = (orgGuid, batchId) => ({
  type: RECHARTER_VALIDATE_ROSTER_REQUEST,
  payload: {
    orgGuid,
    batchId,
  },
});

export const RECHARTER_VALIDATE_ROSTER_RESPONSE = `${moduleName}/RECHARTER_VALIDATE_ROSTER_RESPONSE`;
export const RECHARTER_VALIDATE_ROSTER_ERROR = `${moduleName}/RECHARTER_VALIDATE_ROSTER_ERROR`;
export const recharterValidateRosterResponse = validations => ({
  type: RECHARTER_VALIDATE_ROSTER_RESPONSE,
  payload: validations,
});

export const recharterValidateRosterError = () => ({
  type: RECHARTER_VALIDATE_ROSTER_ERROR,
});

export const RECHARTER_ROSTER_REFRESH_REQUEST = `${moduleName}/RECHARTER_ROSTER_REFRESH_REQUEST`;
export const RECHARTER_ROSTER_REFRESH_RESPONSE = `${moduleName}/RECHARTER_ROSTER_REFRESH_RESPONSE`;
export const RECHARTER_ROSTER_REFRESH_ERROR = `${moduleName}/RECHARTER_ROSTER_REFRESH_ERROR`;
export const refreshRecharterRosterRequest = () => ({
  type: RECHARTER_ROSTER_REFRESH_REQUEST,
});
export const refreshRecharterRosterResponse = () => ({
  type: RECHARTER_ROSTER_REFRESH_RESPONSE,
});
export const refreshRecharterRosterError = err => ({
  type: RECHARTER_ROSTER_REFRESH_ERROR,
  payload: err,
});

export const OPEN_REMOVE_MEMBERS_MODAL = `${moduleName}/OPEN_REMOVE_MEMBERS_MODAL`;
export const openRemoveMembersModal = () => ({
  type: OPEN_REMOVE_MEMBERS_MODAL,
});

export const CLOSE_REMOVE_MEMBERS_MODAL = `${moduleName}/CLOSE_REMOVE_MEMBERS_MODAL`;
export const closeRemoveMembersModal = () => ({
  type: CLOSE_REMOVE_MEMBERS_MODAL,
});

export const RECHARTER_BATCH_INFO_REQUEST = `${moduleName}/RECHARTER_BATCH_INFO_REQUEST`;
export const RECHARTER_BATCH_INFO_RESPONSE = `${moduleName}/RECHARTER_BATCH_INFO_RESPONSE`;
export const RECHARTER_BATCH_INFO_ERROR = `${moduleName}/RECHARTER_BATCH_INFO_ERROR`;
export const recharterBatchInfoRequest = () => ({
  type: RECHARTER_BATCH_INFO_REQUEST,
});
export const recharterBatchInfoResponse = batchInfo => ({
  type: RECHARTER_BATCH_INFO_RESPONSE,
  payload: batchInfo,
});
export const recharterBatchInfoError = err => ({
  type: RECHARTER_BATCH_INFO_ERROR,
  payload: err,
});
export const GET_FUTURE_CHARTER_REQUEST = `${moduleName}/GET_FUTURE_CHARTER_REQUEST`;
export const GET_FUTURE_CHARTER_RESPONSE = `${moduleName}/GET_FUTURE_CHARTER_RESPONSE`;
export const GET_FUTURE_CHARTER_ERROR = `${moduleName}/GET_FUTURE_CHARTER_ERROR`;
export const getFutureCharterRequest = () => ({
  type: GET_FUTURE_CHARTER_REQUEST,
});
export const getFutureCharterResponse = charter => ({
  type: GET_FUTURE_CHARTER_RESPONSE,
  payload: charter,
});
export const getFutureCharterError = err => ({
  type: GET_FUTURE_CHARTER_ERROR,
  payload: err,
});
export const SET_SELECTED_PAYMENT_METHOD = `${moduleName}/SET_SELECTED_PAYMENT_METHOD`;
export const setSelectedPaymentMethod = paymentMethod => ({
  type: SET_SELECTED_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const PAYMENT_PROCESS_REQUEST = `${moduleName}/PAYMENT_PROCESS_REQUEST`;
export const paymentProcessRequest = data => ({
  type: PAYMENT_PROCESS_REQUEST,
  payload: data,
});

export const PAYMENT_PROCESS_RESPONSE = `${moduleName}/PAYMENT_PROCESS_RESPONSE`;
export const paymentProcessResponse = response => ({
  type: PAYMENT_PROCESS_RESPONSE,
  payload: response,
});

export const PAYMENT_PROCESS_ERROR = `${moduleName}/PAYMENT_PROCESS_ERROR`;
export const paymentProcessError = err => ({
  type: PAYMENT_PROCESS_ERROR,
  payload: err,
});

export const WEPAY_PROCESS_REQUEST = `${moduleName}/WEPAY_PROCESS_REQUEST`;
export const wepayProcessRequest = () => ({ type: WEPAY_PROCESS_REQUEST });

export const WEPAY_PROCESS_RESPONSE = `${moduleName}/WEPAY_PROCESS_RESPONSE`;
export const wepayProcessResponse = () => ({ type: WEPAY_PROCESS_RESPONSE });

export const WEPAY_PROCESS_ERROR = `${moduleName}/WEPAY_PROCESS_ERROR`;
export const wepayProcessError = () => ({ type: WEPAY_PROCESS_ERROR });

export const ACH_VERIFICATION_REQUEST = `${moduleName}/ACH_VERIFICATION_REQUEST`;
export const achVerificationRequest = data => ({
  type: ACH_VERIFICATION_REQUEST,
  payload: data,
});

export const ACH_VERIFICATION_RESPONSE = `${moduleName}/ACH_VERIFICATION_RESPONSE`;
export const achVerificationResponse = response => ({
  type: ACH_VERIFICATION_RESPONSE,
  payload: response,
});

export const ACH_VERIFICATION_ERROR = `${moduleName}/ACH_VERIFICATION_ERROR`;
export const achVerificationError = response => ({
  type: ACH_VERIFICATION_ERROR,
  payload: response,
});

export const SEND_REMINDERS_REQUEST = `${moduleName}/SEND_REMINDERS_REQUEST`;
export const sendRemindersRequest = () => ({
  type: SEND_REMINDERS_REQUEST,
});

export const SEND_REMINDERS_RESPONSE = `${moduleName}/SEND_REMINDERS_RESPONSE`;
export const sendRemindersResponse = response => ({
  type: SEND_REMINDERS_RESPONSE,
  payload: response,
});

export const SEND_REMINDERS_ERROR = `${moduleName}/SEND_REMINDERS_ERROR`;
export const sendRemindersError = err => ({
  type: SEND_REMINDERS_ERROR,
  payload: err,
});

export const EDIT_TERM_REQUEST = `${moduleName}/EDIT_TERM_REQUEST`;
export const editTermRequest = keys => ({
  type: EDIT_TERM_REQUEST,
  payload: keys,
});

export const SET_SELECTED_TERM = `${moduleName}/SET_SELECTED_TERM`;
export const setSelectedTerm = selectedTerm => ({
  type: SET_SELECTED_TERM,
  payload: selectedTerm,
});

export const EDIT_TERM_RESPONSE = `${moduleName}/EDIT_TERM_RESPONSE`;
export const editTermResponse = data => ({
  type: EDIT_TERM_RESPONSE,
  payload: data,
});

export const EDIT_TERM_ERROR = `${moduleName}/EDIT_TERM_ERROR`;
export const editTermError = err => ({
  type: EDIT_TERM_ERROR,
  payload: err,
});

export const MARK_AS_MULTIPLE_REQUEST = `${moduleName}/MARK_AS_MULTIPLE_REQUEST`;
export const markAsMultipleRequest = keys => ({
  type: MARK_AS_MULTIPLE_REQUEST,
  payload: keys,
});

export const MARK_AS_MULTIPLE_RESPONSE = `${moduleName}/MARK_AS_MULTIPLE_RESPONSE`;
export const markAsMultipleResponse = data => ({
  type: MARK_AS_MULTIPLE_RESPONSE,
  payload: data,
});

export const MARK_AS_MULTIPLE_ERROR = `${moduleName}/MARK_AS_MULTIPLE_ERROR`;
export const markAsMultipleError = err => ({
  type: MARK_AS_MULTIPLE_ERROR,
  payload: err,
});

export const OPEN_MARK_AS_MULTIPLE_MODAL = `${moduleName}/OPEN_MARK_AS_MULTIPLE_MODAL`;
export const openMarkAsMultipleModal = () => ({
  type: OPEN_MARK_AS_MULTIPLE_MODAL,
});

export const CLOSE_MARK_AS_MULTIPLE_MODAL = `${moduleName}/CLOSE_MARK_AS_MULTIPLE_MODAL`;
export const closeMarkAsMultipleModal = () => ({
  type: CLOSE_MARK_AS_MULTIPLE_MODAL,
});

export const UNMARK_AS_MULTIPLE_REQUEST = `${moduleName}/UNMARK_AS_MULTIPLE_REQUEST`;
export const unmarkAsMultipleRequest = keys => ({
  type: UNMARK_AS_MULTIPLE_REQUEST,
  payload: keys,
});

export const UNMARK_AS_MULTIPLE_RESPONSE = `${moduleName}/UNMARK_AS_MULTIPLE_RESPONSE`;
export const unmarkAsMultipleResponse = data => ({
  type: UNMARK_AS_MULTIPLE_RESPONSE,
  payload: data,
});

export const UNMARK_AS_MULTIPLE_ERROR = `${moduleName}/UNMARK_AS_MULTIPLE_ERROR`;
export const unmarkAsMultipleError = err => ({
  type: UNMARK_AS_MULTIPLE_ERROR,
  payload: err,
});

export const OPEN_UNMARK_AS_MULTIPLE_MODAL = `${moduleName}/OPEN_UNMARK_AS_MULTIPLE_MODAL`;
export const openUnmarkAsMultipleModal = () => ({
  type: OPEN_UNMARK_AS_MULTIPLE_MODAL,
});

export const CLOSE_UNMARK_AS_MULTIPLE_MODAL = `${moduleName}/CLOSE_UNMARK_AS_MULTIPLE_MODAL`;
export const closeUnmarkAsMultipleModal = () => ({
  type: CLOSE_UNMARK_AS_MULTIPLE_MODAL,
});

export const GET_COUNCILS_REQUEST = `${moduleName}/GET_COUNCILS_REQUEST`;
export const getCouncilsRequest = () => ({
  type: GET_COUNCILS_REQUEST,
});

export const GET_COUNCILS_RESPONSE = `${moduleName}/GET_COUNCILS_RESPONSE`;
export const getCouncilsResponse = data => ({
  type: GET_COUNCILS_RESPONSE,
  payload: data,
});

export const GET_COUNCILS_ERROR = `${moduleName}/GET_COUNCILS_ERROR`;
export const getCouncilsError = err => ({
  type: GET_COUNCILS_ERROR,
  payload: err,
});

export const SET_SELECTED_COUNCIL = `${moduleName}/SET_SELECTED_COUNCIL`;
export const setSelectedCouncil = council => ({
  type: SET_SELECTED_COUNCIL,
  payload: council,
});

export const SET_COUNCIL_UNIT_TYPE = `${moduleName}/SET_COUNCIL_UNIT_TYPE`;
export const setCouncilUnitType = type => ({
  type: SET_COUNCIL_UNIT_TYPE,
  payload: type,
});

export const SET_COUNCIL_UNIT = `${moduleName}/SET_COUNCIL_UNIT`;
export const setCouncilUnit = councilUnit => ({
  type: SET_COUNCIL_UNIT,
  payload: councilUnit,
});

export const GET_COUNCIL_UNITS_REQUEST = `${moduleName}/GET_COUNCIL_UNITS_REQUEST`;
export const getCouncilUnitsRequest = () => ({
  type: GET_COUNCIL_UNITS_REQUEST,
});

export const GET_COUNCIL_UNITS_RESPONSE = `${moduleName}/GET_COUNCIL_UNITS_RESPONSE`;
export const getCouncilUnitsResponse = data => ({
  type: GET_COUNCIL_UNITS_RESPONSE,
  payload: data,
});

export const GET_COUNCIL_UNITS_ERROR = `${moduleName}/GET_COUNCIL_UNITS_ERROR`;
export const getCouncilUnitsError = err => ({
  type: GET_COUNCIL_UNITS_ERROR,
  payload: err,
});

export const CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST = `${moduleName}/CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST`;
export const createTransientDocumentWithAgreementRequest =
  transientDocument => ({
    type: CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST,
    payload: transientDocument,
  });

export const CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE = `${moduleName}/CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE`;
export const createTransientDocumentWithAgreementResponse = () => ({
  type: CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE,
});

export const CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_ERROR = `${moduleName}/CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_ERROR`;
export const createTransientDocumentWithAgreementError = () => ({
  type: CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_ERROR,
});

export const GENERATE_RECHARTER_PDF_REQUEST = `${moduleName}/GENERATE_RECHARTER_PDF_REQUEST`;
export const generateRecharterPDFRequest = (payload = {}) => ({
  type: GENERATE_RECHARTER_PDF_REQUEST,
  payload,
});

export const GET_PAPERWORK_LINK_REQUEST = `${moduleName}/GET_PAPERWORK_LINK_REQUEST`;
export const getPaperworkLinkRequest = () => ({
  type: GET_PAPERWORK_LINK_REQUEST,
});
export const GET_PAPERWORK_LINK_RESPONSE = `${moduleName}/GET_PAPERWORK_LINK_RESPONSE`;
export const getPaperworkLinkResponse = (batchId, response) => ({
  type: GET_PAPERWORK_LINK_RESPONSE,
  payload: { batchId, response },
});
export const GET_PAPERWORK_LINK_ERROR = `${moduleName}/GET_PAPERWORK_LINK_ERROR`;
export const getPaperworkLinkError = (payload = {}) => ({
  type: GET_PAPERWORK_LINK_ERROR,
  payload,
});

export const OPEN_ADOBE_ERROR_MODAL = `${moduleName}/OPEN_ADOBE_ERROR_MODAL`;
export const openAdobeErrorModal = () => ({
  type: OPEN_ADOBE_ERROR_MODAL,
});

export const CLOSE_ADOBE_ERROR_MODAL = `${moduleName}/CLOSE_ADOBE_ERROR_MODAL`;
export const closeAdobeErrorModal = () => ({
  type: CLOSE_ADOBE_ERROR_MODAL,
});

export const GENERATE_RECHARTER_PDF_ERROR = `${moduleName}/GENERATE_RECHARTER_PDF_ERROR`;
export const generateRecharterPDFError = () => ({
  type: GENERATE_RECHARTER_PDF_ERROR,
});

export const GENERATE_RECHARTER_PDF_RESPONSE = `${moduleName}/GENERATE_RECHARTER_PDF_RESPONSE`;
export const generateRecharterPDFResponse = () => ({
  type: GENERATE_RECHARTER_PDF_RESPONSE,
});
export const SUBMIT_RECHARTER_REQUEST = `${moduleName}/SUBMIT_RECHARTER_REQUEST`;
export const SUBMIT_RECHARTER_RESPONSE = `${moduleName}/SUBMIT_RECHARTER_RESPONSE`;
export const SUBMIT_RECHARTER_ERROR = `${moduleName}/SUBMIT_RECHARTER_ERROR`;
export const submitRecharterRequest = () => ({
  type: SUBMIT_RECHARTER_REQUEST,
});
export const submitRecharterResponse = response => ({
  type: SUBMIT_RECHARTER_RESPONSE,
  payload: response,
});
export const submitRecharterError = err => ({
  type: SUBMIT_RECHARTER_ERROR,
  payload: err,
});

export const REMOVE_PENDING_MEMBERS_REQUEST = `${moduleName}/REMOVE_PENDING_MEMBERS_REQUEST`;
export const REMOVE_PENDING_MEMBERS_RESPONSE = `${moduleName}/REMOVE_PENDING_MEMBERS_RESPONSE`;
export const REMOVE_PENDING_MEMBERS_ERROR = `${moduleName}/REMOVE_PENDING_MEMBERS_ERROR`;
export const removePendingMembersRequest = () => ({
  type: REMOVE_PENDING_MEMBERS_REQUEST,
});
export const removePendingMembersResponse = res => ({
  type: REMOVE_PENDING_MEMBERS_RESPONSE,
  payload: res,
});
export const removePendingMembersError = err => ({
  type: REMOVE_PENDING_MEMBERS_ERROR,
  payload: err,
});

// Invite new member modal
export const OPEN_INVITE_MEMBERS_MODAL = `${moduleName}/OPEN_INVITE_MEMBERS_MODAL`;
export const openInviteMembersModal = () => ({
  type: OPEN_INVITE_MEMBERS_MODAL,
});

export const CLOSE_INVITE_MEMBERS_MODAL = `${moduleName}/CLOSE_INVITE_MEMBERS_MODAL`;
export const closeInviteMembersModal = () => ({
  type: CLOSE_INVITE_MEMBERS_MODAL,
});

export const INVITE_MEMBERS_REQUEST = `${moduleName}/INVITE_MEMBERS_REQUEST`;
export const inviteMembersRequest = inviteeInfo => ({
  type: INVITE_MEMBERS_REQUEST,
  payload: inviteeInfo,
});

export const INVITE_MEMBERS_RESPONSE = `${moduleName}/INVITE_MEMBERS_RESPONSE`;
export const inviteMembersResponse = () => ({
  type: INVITE_MEMBERS_RESPONSE,
});

export const INVITE_MEMBERS_ERROR = `${moduleName}/INVITE_MEMBERS_ERROR`;
export const inviteMembersError = () => ({
  type: INVITE_MEMBERS_ERROR,
});

export const UNIT_ROSTER_SORT = `${moduleName}/UNIT_ROSTER_SORT`;
export const UNIT_ROSTER_PENDING_SORT = `${moduleName}/UNIT_ROSTER_PENDING_SORT`;
export const UNIT_ROSTER_REMOVED_SORT = `${moduleName}/UNIT_ROSTER_REMOVED_SORT`;
export const unitRosterSort = ({ columnKey, order }, type) => ({
  type,
  payload: { columnKey, order },
});

export const GET_ADJUSTED_OLR_SETTINGS_REQUEST = `${moduleName}/GET_ADJUSTED_OLR_SETTINGS_REQUEST`;
export const getAdjustedOLRSettingsRequest = () => ({
  type: GET_ADJUSTED_OLR_SETTINGS_REQUEST,
});

export const GET_ADJUSTED_OLR_SETTINGS_RESPONSE = `${moduleName}/GET_ADJUSTED_OLR_SETTINGS_RESPONSE`;
export const getAdjustedOLRSettingsResponse = adjustedOLRSettings => ({
  type: GET_ADJUSTED_OLR_SETTINGS_RESPONSE,
  payload: adjustedOLRSettings,
});

export const GET_ADJUSTED_OLR_SETTINGS_ERROR = `${moduleName}/GET_ADJUSTED_OLR_SETTINGS_ERROR`;
export const getAdjustedOLRSettingsError = err => ({
  type: GET_ADJUSTED_OLR_SETTINGS_ERROR,
  payload: err,
});

export const HARD_DELETE_PENDING_PERSON_REQUEST = `${moduleName}/HARD_DELETE_PENDING_PERSON_REQUEST`;
export const hardDeletePendingPersonRequest = batchApplicationPersonId => ({
  type: HARD_DELETE_PENDING_PERSON_REQUEST,
  payload: batchApplicationPersonId,
});

export const HARD_DELETE_PENDING_PERSON_RESPONSE = `${moduleName}/HARD_DELETE_PENDING_PERSON_RESPONSE`;
export const hardDeletePendingPersonResponse = () => ({
  type: HARD_DELETE_PENDING_PERSON_RESPONSE,
});

export const HARD_DELETE_PENDING_PERSON_ERROR = `${moduleName}/HARD_DELETE_PENDING_PERSON_ERROR`;
export const hardDeletePendingPersonError = err => ({
  type: HARD_DELETE_PENDING_PERSON_ERROR,
  payload: err,
});

export const GET_ORG_ADULTS_REQUEST = `${moduleName}/GET_ORG_ADULTS_REQUEST`;
export const getOrgAdultsRequest = () => ({
  type: GET_ORG_ADULTS_REQUEST,
});

export const GET_ORG_ADULTS_RESPONSE = `${moduleName}/GET_ORG_ADULTS_RESPONSE`;
export const getOrgAdultsResponse = orgAdults => ({
  type: GET_ORG_ADULTS_RESPONSE,
  payload: orgAdults,
});

export const GET_ORG_ADULTS_ERROR = `${moduleName}/GET_ORG_ADULTS_ERROR`;
export const getOrgAdultsError = err => ({
  type: GET_ORG_ADULTS_ERROR,
  payload: err,
});
