import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from '@shared/components';
import { isMobileSel } from '@shared/duck';

import { closeTimeoutModal, logout, timeoutModalOpenSel } from '../../duck';
import styles from './TimeoutModal.less';
import TimeoutModalContent from './TimeoutModalContent';

class TimeoutModal extends React.PureComponent {
  render() {
    const { isTimeoutModalOpen, onLogout, onCloseTimeoutModal, isMobile } =
      this.props;

    return (
      <Modal
        fullscreenMobile={false}
        visible={isTimeoutModalOpen}
        size="small"
        closable={false}
        className={styles.container}
      >
        <TimeoutModalContent
          onLogout={onLogout}
          onCloseTimeoutModal={onCloseTimeoutModal}
          isMobile={isMobile}
        />
      </Modal>
    );
  }
}

TimeoutModal.propTypes = {
  // Provided by component itself
  isTimeoutModalOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  onCloseTimeoutModal: PropTypes.func.isRequired,
};

const mapState = state => ({
  isTimeoutModalOpen: timeoutModalOpenSel(state),
  isMobile: isMobileSel(state),
});

const mapDispatch = dispatch => ({
  onLogout: () =>
    dispatch(logout(undefined, { preserveDiscourseSession: true })),
  onCloseTimeoutModal: () => dispatch(closeTimeoutModal()),
});

export default connect(mapState, mapDispatch)(TimeoutModal);
