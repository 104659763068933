import { createSelector } from 'reselect';

import { unitTypeIdSel } from '@context';
import {
  ProgramId,
  advancementHistoryItemsSel,
  advancementStatuses,
} from '@shared';

import { advancementModuleSel } from '../../../common';
import {
  personsMinRankFirstClassRankSel,
  personsSel,
} from './helpers.selectors';

export { personsSel };

const moduleSel = state => advancementModuleSel(state);

export const visibleSel = state => moduleSel(state).visible;
export const loadingSel = state => moduleSel(state).loading;
export const errorSel = state => moduleSel(state).error;
export const verifyTimeoutErrorSel = state =>
  moduleSel(state).verifyTimeoutError;
export const additionalVerifyTimeoutErrorSel = state =>
  moduleSel(state).additionalVerifyTimeoutError;
export const formTabSel = state => moduleSel(state).formTab;
export const advancementVerificationLoadingSel = state =>
  moduleSel(state).advancementVerificationLoading;
export const additionalAdvancementVerificationLoadingSel = state =>
  moduleSel(state).additionalAdvancementVerificationLoading;
export const advancementVerificationSel = state =>
  moduleSel(state).advancementVerification;
export const additionalAdvancementVerificationSel = state =>
  moduleSel(state).additionalAdvancementVerification;
export const completionDateSel = state => moduleSel(state).completionDate;
export const advancementTypeSel = state => moduleSel(state).advancementType;
export const advancementIdSel = state => moduleSel(state).advancementId;
export const additionalCompletionDateSel = state =>
  moduleSel(state).additionalCompletionDate;
export const additionalAdvancementTypeSel = state =>
  moduleSel(state).additionalAdvancementType;
export const additionalAdvancementIdSel = state =>
  moduleSel(state).additionalAdvancementId;
export const selectedSuggestedYouthIdsSel = state =>
  moduleSel(state).selectedSuggestedYouthIds;
export const selectedAdditionalSuggestedYouthIdsSel = state =>
  moduleSel(state).selectedAdditionalSuggestedYouthIds;
export const youthSuggestionsSel = createSelector(
  advancementHistoryItemsSel,
  historyItems =>
    historyItems.filter(({ status }) =>
      [advancementStatuses.COMPLETED, advancementStatuses.APPROVED].includes(
        status,
      ),
    ),
);
export const selectedAdvancementStatusSel = state =>
  moduleSel(state).selectedAdvancementStatus;
export const selectedAdditionalAdvStatusSel = state =>
  moduleSel(state).selectedAdditionalAdvancementStatus;

export const personsRanksSel = createSelector(personsSel, persons =>
  persons.map(({ currentHighestRankApproved }) => currentHighestRankApproved),
);

export const shouldDisplayTabsSel = createSelector(
  unitTypeIdSel,
  personsMinRankFirstClassRankSel,
  (unitTypeId, validPersons) =>
    (unitTypeId == ProgramId.SEA_SCOUT || unitTypeId == ProgramId.VENTURING) &&
    validPersons.length > 0,
);
