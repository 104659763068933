import { lazier } from '@shared';

export const ApproveAdvancementsModal = lazier(
  () => import('./ApproveAdvancementsModal'),
  false,
);
export { default as ApproveAdvancementsAction } from './ApproveAdvancementsAction';
export { default as ApproveAdvancementsButton } from './ApproveAdvancementsButton';

export { default as DeclineAdvancementsButton } from './DeclineAdvancementsButton';

export const DeclineMultipleAdvancementModal = lazier(
  // eslint-disable-next-line import/no-cycle
  () => import('./DeclineMultipleAdvancementModal'),
  false,
);
