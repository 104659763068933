/* eslint-disable react/no-multi-comp */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import UsersIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { tableSortOrder } from '@utils';
import { TableHeadingSort, intl, advancementFileStatuses } from '@shared';
import { historyItemsTypes } from '../../../constants';
import HistoryItemAvatar from '../HistoryItemAvatar';
import HistoryItemIcon from '../HistoryItemIcon';
import HistoryItemName from '../HistoryItemName';
import HistoryItemDate from '../HistoryItemDate';
import HistoryItemDescription from '../HistoryItemDescription';
import HistoryItemStatus from '../HistoryItemStatus';
import CancelFileButton from '../CancelFileButton';
import ItemDetailsToggle from '../ItemDetailsToggle';
import styles from './AdvancementHistoryTable.less';

export default ({
  sorter,
  expandedKeys,
  isOffline,
  cancellingFiles,
  onExpandedKeyChange,
  onCancelFile,
  onGoToYouthProfile,
  onGoToAdultProfile,
}) => {
  const nameSortOrder = tableSortOrder(sorter, 'name');
  const recordedBySortOrder = tableSortOrder(sorter, 'recordedBy');
  const dateSortOrder = tableSortOrder(sorter, 'updatedDate');
  const descriptionSortOrder = tableSortOrder(sorter, 'description');
  const statusSortOrder = tableSortOrder(sorter, 'status');

  return [
    {
      key: 'avatar',
      width: '3.5rem',
      align: 'right',
      className: styles.firstCell,
      render(_, { type, name, profilePictureUrl, isAdult }) {
        if (type == historyItemsTypes.ADVANCEMENT_FILE) {
          return {
            children: <HistoryItemName type={type} name={name} />,
            props: { colSpan: 2 },
          };
        }

        return (
          <HistoryItemAvatar
            type={type}
            isAdult={isAdult}
            url={profilePictureUrl}
          />
        );
      },
    },
    {
      key: 'individualOrGroup',
      width: '3.5rem',
      align: 'right',
      className: styles.firstCell,
      render(_, { type, isPersonalActivity }) {
        if (type == 'activity') {
          return isPersonalActivity ? (
            <AccountCircle className={styles.icon} />
          ) : (
            <UsersIcon className={styles.icon} />
          );
        }

        return null;
      },
    },
    {
      key: 'name',
      title: (
        <TableHeadingSort name="name" sorter={sorter}>
          <FormattedMessage id="advancement.AdvancementHistory.column.name" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: nameSortOrder,
      render(_, { type, name, userId, isAdult }) {
        return type == historyItemsTypes.ADVANCEMENT_FILE ? (
          { props: { colSpan: 0 } }
        ) : (
          <HistoryItemName
            type={type}
            name={name}
            isOffline={isOffline}
            onGoToProfilePage={
              !isAdult
                ? onGoToYouthProfile({ userId })
                : onGoToAdultProfile({ userId })
            }
          />
        );
      },
    },
    {
      key: 'memberId',
      width: '10rem',
      title: intl.formatMessage({
        id: 'advancement.AdvancementHistory.column.memberId',
      }),
      render(_, { type, memberId }) {
        return (
          type !== historyItemsTypes.ADVANCEMENT_FILE && <span>{memberId}</span>
        );
      },
    },
    {
      key: 'updatedDate',
      width: '15rem',
      title: (
        <TableHeadingSort name="updatedDate" sorter={sorter}>
          <FormattedMessage id="advancement.AdvancementHistory.column.updatedDate" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: dateSortOrder,
      render(_, { canEdit, canUnapprove, isEagleScoutRank, ...advancement }) {
        const { type, status, userId, name, date } = advancement;
        const person = { userId, name };
        const availableAction = canEdit
          ? 'canEdit'
          : canUnapprove
          ? 'canUnapprove'
          : undefined;

        return (
          <HistoryItemDate
            isMobile={false}
            type={type}
            status={status}
            date={date}
            person={person}
            advancement={advancement}
            availableAction={availableAction}
            isEagleScoutRank={isEagleScoutRank}
          />
        );
      },
    },
    {
      key: 'recordedBy',
      title: (
        <TableHeadingSort name="recordedBy" sorter={sorter}>
          <FormattedMessage id="advancement.AdvancementHistory.column.recordedBy" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: recordedBySortOrder,
      dataIndex: 'recordedByName',
      render(name) {
        return <span>{name || 'N/A'}</span>;
      },
    },
    {
      key: 'type',
      width: '6rem',
      title: (
        <FormattedMessage id="advancement.AdvancementHistory.column.type" />
      ),
      className: styles.typeCell,
      align: 'center',
      render(_, { type, ...item }) {
        return <HistoryItemIcon type={type} item={item} />;
      },
    },
    {
      key: 'description',
      title: (
        <TableHeadingSort name="description" sorter={sorter}>
          <FormattedMessage id="advancement.AdvancementHistory.column.description" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: descriptionSortOrder,
      className: styles.descriptionCell,
      render(
        _,
        {
          type,
          status,
          statusLabel,
          totalRecords,
          recordsImported,
          recordsSkipped,
          description,
        },
      ) {
        return (
          <HistoryItemDescription
            type={type}
            status={status}
            statusLabel={statusLabel}
            totalRecords={totalRecords}
            recordsImported={recordsImported}
            recordsSkipped={recordsSkipped}
          >
            {description}
          </HistoryItemDescription>
        );
      },
    },
    {
      key: 'status',
      title: (
        <TableHeadingSort name="status" sorter={sorter}>
          <FormattedMessage id="advancement.AdvancementHistory.column.status" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: statusSortOrder,
      render(_, { type, isSynced, statusLabel }) {
        return (
          <HistoryItemStatus
            status={statusLabel}
            type={type}
            isSynced={isSynced}
          />
        );
      },
    },
    {
      key: 'action',
      className: styles.actionCell,
      render(_, { key, id, type, status }) {
        if (
          type === historyItemsTypes.ADVANCEMENT_FILE &&
          status === advancementFileStatuses.PENDING
        ) {
          return (
            <CancelFileButton
              id={id}
              loading={cancellingFiles[id]}
              onClick={onCancelFile}
            />
          );
        }

        if (
          type === historyItemsTypes.ADVANCEMENT_FILE &&
          status === advancementFileStatuses.PROCESSED
        ) {
          return (
            <ItemDetailsToggle
              color="info"
              ghost
              noBorder
              fitText
              itemKey={key}
              expanded={expandedKeys.includes(key)}
              chevronClassName={styles.itemDetailsToggleChevron}
              onClick={onExpandedKeyChange}
            >
              <FormattedMessage id="advancement.AdvancementHistory.view" />
            </ItemDetailsToggle>
          );
        }

        return null;
      },
    },
  ];
};
