import { advancementStatus } from '@modules/shared/utils';
import { advancementStatuses } from '@shared/constants';

import { unapproveAdvancementModuleSel as moduleSel } from '../../common';

export const visibleSel = state => moduleSel(state).visible;
export const loadingSel = state => moduleSel(state).loading;
export const advancementSel = state => moduleSel(state).advancement || {};
export const modalActionSel = state =>
  advancementStatus.getStatus(advancementSel(state)) ==
  advancementStatuses.COMPLETED
    ? 'uncomplete'
    : 'unapprove';

export const youthNameSel = state => moduleSel(state).youthName;
export const shouldScoutDetailsReloadSel = state =>
  moduleSel(state).shouldMemberDetailsReload;
