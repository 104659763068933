import React from 'react';
import { Checkbox as BsaCheckbox } from 'bsa-ui';
import cn from 'classnames';

class Checkbox extends React.Component {
  state = {
    checked: this.props.defaultChecked,
  };

  getChecked = () =>
    this.isControlled() ? this.props.checked : this.state.checked;

  isControlled = () => this.props.checked != null;

  handleChange = e => {
    if (!this.isControlled()) {
      this.setState({ checked: e.target.checked });
    }
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { indeterminate, className, onChange, ...rest } = this.props;
    const checked = this.getChecked();
    const classNames = cn({
      'bsa-checkbox-wrapper-checked': checked,
      'bsa-checkbox-wrapper-indeterminate': indeterminate,
    });

    return (
      <BsaCheckbox
        {...rest}
        indeterminate={indeterminate}
        className={classNames}
        checked={checked}
        onChange={this.handleChange}
      />
    );
  }
}

Checkbox.propTypes = BsaCheckbox.propTypes;

Checkbox.Group = BsaCheckbox.Group;

export default Checkbox;
