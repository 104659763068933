import React from 'react';

import WarnIcon from '@material-ui/icons/WarningRounded';

import { featureFlags } from '../utils/featureFlags';
import styles from './OverridesBanner.styles.less';

export const OverridesBanner: React.FC = () => {
  const isOverriding = featureFlags.isOverriding();

  if (!isOverriding) return null;

  return (
    <div className={styles.ffOverridesBanner}>
      <WarnIcon />
      <span>Feature Flags are being overriden</span>
    </div>
  );
};

export default OverridesBanner;
