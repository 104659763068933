import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ViewListIcon from '@material-ui/icons/ViewList';
import { FormComponentProps } from 'antd/lib/form';
import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  mapSubUnitAdults,
  mapSubUnitScouts,
} from '@modules/advancement/utilsTyped';
import { setShouldMemberDetailsReload } from '@modules/youthProfile/duck/actions';
import { isMobileSel } from '@screen';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  S,
  Select,
  T,
  denTypes,
  denTypesNames,
  denTypesSingleNames,
  intl,
} from '@shared';
import { validateForm } from '@utils';

import {
  editSubUnitRequest,
  saveSubUnitRequest,
  setSelectedSubUnitId,
} from '../../duck/actions';
import {
  approvedSubUnitsSel,
  createSubUnitInProgressSel,
  groupedRosterAttendeesSel,
  isDenSel,
  origMembersSel,
  selectedSubUnitIdSel,
  subUnitTypeNameSel,
} from '../../duck/selectors';
import AddSubUnitDesktop from './AddSubUnitDesktop';
import styles from './AddSubUnitForm.less';
import AddSubUnitMobile from './AddSubUnitMobile';

const { Item: FormItem } = Form;

const AddSubUnitForm: React.FC<FormComponentProps> = ({ form }) => {
  const dispatch = useDispatch();
  const subUnitTypeName = useSelector(subUnitTypeNameSel);
  const isMobile = useSelector(isMobileSel);
  const isLoadingCreateSubUnit = useSelector(createSubUnitInProgressSel);
  const selectedSubunitId = useSelector(selectedSubUnitIdSel);
  const origMembers = useSelector(origMembersSel);
  const isDen = useSelector(isDenSel);

  const subUnitsList = useSelector(approvedSubUnitsSel);

  const { adults: rosterAdults, youths: rosterYouths } = useSelector(
    groupedRosterAttendeesSel,
  );

  const [selectedUserIds, setSelectedUserIds] = useState(
    () => new Set(origMembers.map(({ userId }) => userId)),
  );

  const membersToDelete = useMemo(
    () =>
      origMembers
        .filter(({ userId }) => !selectedUserIds.has(userId))
        .map(({ userId }) => userId),
    [origMembers, selectedUserIds],
  );

  const { getFieldDecorator, getFieldValue, isFieldsTouched, validateFields } =
    form;

  useEffect(
    () => () => {
      dispatch(setSelectedSubUnitId(null));
    },
    [dispatch],
  );

  const validate = useCallback(() => validateForm(form), [form]);

  const initialValues = useMemo(
    () => subUnitsList.find(sub => sub.subUnitId === selectedSubunitId),
    [subUnitsList, selectedSubunitId],
  );

  const currentDenType = getFieldValue('denType');
  const isAOL = currentDenType === denTypesNames.aol;

  useEffect(() => {
    if (isFieldsTouched(['subUnitName'])) {
      validateFields(['subUnitName']);
    }
    // eslint-disable-next-line
  }, [currentDenType]);

  const handleSaveSubUnit = useCallback(async () => {
    const subUnitData = await validate();
    if (subUnitData) {
      dispatch(setShouldMemberDetailsReload(true));
      const formattedSelectedYouths = [...rosterYouths]
        .filter(({ userId }) => selectedUserIds.has(userId))
        .map(scout => mapSubUnitScouts(scout, isDen, subUnitData.denType));
      const formattedSelectedAdults = rosterAdults
        .filter(({ userId }) => selectedUserIds.has(userId))
        .map(adult => mapSubUnitAdults(adult, isDen));

      if (!selectedSubunitId) {
        dispatch(
          saveSubUnitRequest({
            ...subUnitData,
            selectedAdults: formattedSelectedAdults,
            selectedUsers: formattedSelectedYouths,
          }),
        );
        return;
      }

      const origMembersUserIds = new Set(
        origMembers.map(({ userId }) => userId),
      );

      const filteredSelectedUsers = formattedSelectedYouths.filter(user => {
        if (!origMembersUserIds.has(user.userId)) return true;

        if (isDen) {
          return initialValues?.denType !== subUnitData.denType;
        }
      });

      const filteredSelectedAdults = formattedSelectedAdults.filter(
        user => !origMembersUserIds.has(user.userId),
      );

      dispatch(
        editSubUnitRequest({
          ...subUnitData,
          selectedUsers: filteredSelectedUsers,
          selectedAdults: filteredSelectedAdults,
          subUnitId: selectedSubunitId,
          membersToDelete,
        }),
      );
    }
  }, [
    validate,
    isDen,
    origMembers,
    selectedSubunitId,
    initialValues,
    rosterAdults,
    rosterYouths,
    selectedUserIds,
    membersToDelete,
    dispatch,
  ]);

  const handleAddPerson = (userId: number) => {
    setSelectedUserIds(new Set([...selectedUserIds, userId]));
  };

  const handleRemovePerson = (userId: number) => {
    setSelectedUserIds(
      new Set([...selectedUserIds].filter(id => id !== userId)),
    );
  };

  return (
    <Row type="flex" justify="space-between">
      <Col span={24}>
        <T colored size="5" className={styles.upperCase}>
          <FormattedMessage
            id={`packRoster.AddSubUnit.AddSubUnitForm.information.${subUnitTypeName}`}
          />
        </T>
      </Col>
      <Col xs={selectedSubunitId ? 12 : 24} lg={selectedSubunitId ? 12 : 11}>
        <FormItem>
          {getFieldDecorator('subUnitName', {
            initialValue: initialValues?.subUnitNameRaw ?? '',
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'shared.form.error.isRequired',
                }),
              },
              {
                pattern: /^[ :_;@%&/A-Za-z0-9.'--()]*$/,
                message: intl.formatMessage({
                  id: 'shared.address.address1.errors.specialCharsValidation',
                }),
              },
              isDen && !isAOL
                ? {
                    pattern: /^\d/,
                    message: intl.formatMessage({
                      id: 'packRoster.AddSubUnit.AddSubUnitForm.information.den.error',
                    }),
                  }
                : {},
            ],
          })(
            <Input
              size="large"
              placeholder={intl.formatHTMLMessage({
                id: `subUnits.form.${subUnitTypeName}`,
              })}
            />,
          )}
        </FormItem>
        {isDen && (
          <Fragment>
            <T colored size="5" className={styles.upperCase}>
              <FormattedMessage
                id={'packRoster.AddSubUnit.AddSubUnitForm.denLevel'}
              />
            </T>
            <FormItem>
              {getFieldDecorator('denType', {
                initialValue: initialValues?.denType ?? denTypes[0].name,
                rules: [
                  {
                    required: isDen,
                    message: intl.formatMessage({
                      id: 'shared.form.error.isRequired',
                    }),
                  },
                ],
              })(
                <Select size="large">
                  <Select.OptGroup>
                    {denTypes.map(({ id, name, label }) => (
                      <Select.Option key={id} value={name}>
                        {label ??
                          (denTypesSingleNames[name]
                            ? capitalize(denTypesSingleNames[name])
                            : capitalize(name))}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>,
              )}
            </FormItem>
          </Fragment>
        )}
      </Col>
      {!selectedSubunitId && (
        <Col xs={12} lg={12}>
          <S size="5" inline>
            <FormattedMessage
              id="packRoster.AddSubUnit.AddSubUnitForm.information.note.firstPart"
              values={{ type: isDen ? 'dens' : 'patrols' }}
            />
          </S>
          <span className={styles.fakeBtn}>
            <FormattedMessage
              id={`packRoster.AddSubUnit.AddSubUnitForm.information.label.${subUnitTypeName}`}
            />
            <ViewListIcon fontSize="small" />
          </span>
          <S size="5" inline>
            <FormattedMessage
              id="packRoster.AddSubUnit.AddSubUnitForm.information.note.secondPart"
              values={{ type: isDen ? 'den' : 'patrol' }}
            />
          </S>
        </Col>
      )}
      {isMobile ? (
        <React.Fragment>
          <AddSubUnitMobile
            attendeeLabel="youths"
            availableUsers={rosterYouths}
            selectedUserIds={selectedUserIds}
            onAddPerson={handleAddPerson}
            onRemovePerson={handleRemovePerson}
            subUnitTypeName={subUnitTypeName}
          />
          <AddSubUnitMobile
            attendeeLabel="adults"
            availableUsers={rosterAdults}
            selectedUserIds={selectedUserIds}
            onAddPerson={handleAddPerson}
            onRemovePerson={handleRemovePerson}
            subUnitTypeName={subUnitTypeName}
          />
        </React.Fragment>
      ) : (
        <Col span={24}>
          <AddSubUnitDesktop
            attendeeLabel="youths"
            persons={rosterYouths}
            subUnitTypeName={subUnitTypeName}
            isDen={isDen}
            selectedUserIds={selectedUserIds}
            onAddPerson={handleAddPerson}
            onRemovePerson={handleRemovePerson}
          />
          <AddSubUnitDesktop
            attendeeLabel="adults"
            persons={rosterAdults}
            subUnitTypeName={subUnitTypeName}
            selectedUserIds={selectedUserIds}
            onAddPerson={handleAddPerson}
            onRemovePerson={handleRemovePerson}
          />
        </Col>
      )}
      <Col span={24} className={styles.saveButton}>
        <Button
          onClick={handleSaveSubUnit}
          disabled={
            (!selectedUserIds.size && !membersToDelete.length) ||
            isLoadingCreateSubUnit
          }
        >
          <FormattedMessage
            id={selectedSubunitId ? 'shared.saveChanges' : 'shared.create'}
          />
        </Button>
      </Col>
    </Row>
  );
};

export default Form.create()(AddSubUnitForm);
