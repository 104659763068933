import { activityValueTypesToName } from '../common';

export const groupByPerson = personsAdvancements => {
  const persons = [];

  personsAdvancements.forEach(pa => {
    const personIndex = persons.findIndex(({ userId }) => userId == pa.userId);

    if (personIndex === -1) {
      persons.push({ ...pa, advancements: [pa] });
    } else {
      persons[personIndex].advancements.push(pa);
    }
  });

  return persons;
};

export const mapActivityRecordValues = activityRecords =>
  activityRecords.reduce((values, currentRecord) => {
    const valueTypeName =
      activityValueTypesToName[currentRecord.activityValueTypeId];
    return { ...values, [valueTypeName]: currentRecord.activityValue };
  }, {});
