import { dateAndTimeToDateTime } from '@utils';

import {
  activityTypeIds,
  saveActivity$,
  mapPersonValuesToDto,
  activityValueTypes,
  normalizeAdvancedValues,
} from '../../common';

const saveServiceActivity$ = data => {
  const {
    startDate,
    endDate,
    organizationGuid,
    loggedInUserId,
    persons,
    isAdvancedMode,
    hoursServed,
    youthHours,
    adultHours,
    isPersonalActivity = false,
    isApproved,
    startTime,
    endTime,
  } = data;
  const basicValues = {
    [activityValueTypes.HOURS]: hoursServed,
  };
  const personValues = mapPersonValuesToDto({
    persons,
    organizationGuid,
    loggedInUserId,
    isAdvancedMode,
    isApproved,
    basicAdultValues: basicValues,
    basicYouthValues: basicValues,
    advancedValues: {
      [activityValueTypes.HOURS]: {
        ...normalizeAdvancedValues(youthHours),
        ...normalizeAdvancedValues(adultHours),
      },
    },
  });
  const startDateTime = dateAndTimeToDateTime(startDate, startTime);
  const endDateTime = dateAndTimeToDateTime(endDate, endTime);

  const processedData = {
    ...data,
    ...personValues,
    activityTypeId: activityTypeIds.SERVICE_PROJECTS,
    startDateTime,
    endDateTime,
    hostOrganizationGuid: organizationGuid,
    isPersonalActivity,
    isEveryChildOrg: false,
  };

  return saveActivity$(processedData);
};

export default {
  saveServiceActivity$,
};
