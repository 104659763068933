import React from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';

import { S, appName, appNameShort } from '@shared';

import styles from './Sidebar.less';

const stopPropagation = e => e.stopPropagation();

const SidebarTrigger = ({ collapsed }) => (
  <div className={styles.triggerWrapper}>
    <ArrowBackIcon
      className={styles.expandTrigger}
      id={collapsed ? 'qa_expand' : 'qa_collapse'}
    />
    <S size="6" bold className={styles.appName} onClick={stopPropagation}>
      {appName}
    </S>
    <S size="6" bold className={styles.appNameShort} onClick={stopPropagation}>
      {appNameShort}
    </S>
  </div>
);

SidebarTrigger.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default SidebarTrigger;
