import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { Button, intl, OfflineTooltip } from '@shared';
import { openDeclineMultipleAdvancements } from '../../duck';
import { Tooltip } from 'bsa-ui';
import { FormattedMessage } from 'react-intl';

function DeclineAdvancementsButton({
  disabled,
  className,
  children,
  uppercase,
  personsAdvancements,
  advancementStatus,
  ...rest
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      openDeclineMultipleAdvancements(personsAdvancements, advancementStatus),
    );
  };

  return (
    <OfflineTooltip placement="top">
      <Tooltip
        title={<FormattedMessage id="decline.tooltip.message" />}
        trigger={!disabled ? [] : ['hover']}
        placement="top"
      >
        <Button
          id="qa_declineAdvancement"
          className={className}
          disabled={disabled}
          color="info"
          ghost
          noBorder
          fitText
          icon={<CancelOutlinedIcon />}
          uppercase={uppercase}
          {...rest}
          onClick={handleClick}
        >
          {children ||
            intl.formatMessage(
              {
                id: 'progress.DeclineAdvancementsButton.title',
              },
              { count: personsAdvancements.length },
            )}
        </Button>
      </Tooltip>
    </OfflineTooltip>
  );
}

DeclineAdvancementsButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  personsAdvancements: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  uppercase: PropTypes.bool,
  advancementStatus: PropTypes.string,
};

DeclineAdvancementsButton.defaultProps = {
  uppercase: false,
};

export default DeclineAdvancementsButton;
