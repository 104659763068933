import React, { useCallback, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Form, Row, S, Select, Tooltip } from '@shared/components';
import { intl } from '@shared/localization';
import { validateForm } from '@utils';

import { unitInfoSel } from '../../../../../unit';
import { nonUnit } from '../../../../constants';
import {
  councilsSel,
  filteredCounciUnitsByTypeSel,
  getCouncilUnitsRequest,
  getCouncilsRequest,
  isLoadingCouncilUnitsSel,
  isLoadingCouncilsSel,
  isLoadingMarkAsMultipleSel,
  markAsMultipleRequest,
  removeRecharterRosterSelectedKey,
  selectedCouncilSel,
  selectedCouncilUnitSel,
  selectedCouncilUnitTypeSel,
  selectedMembersSel,
  setCouncilUnit,
  setCouncilUnitType,
  setSelectedCouncil,
} from '../../../../duck';
import { getValidRecharterUnitTypes } from '../../../../utils';
import { PersonTag } from '../../../common';
import styles from './MarkAsMultipleForm.less';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const MarkAsMultipleForm = ({ form }) => {
  const { getFieldDecorator, resetFields } = form;
  const dispatch = useDispatch();
  const selectedMembers = useSelector(selectedMembersSel);
  const selectedCouncil = useSelector(selectedCouncilSel);
  const selectedCouncilUnitType = useSelector(selectedCouncilUnitTypeSel);
  const selectedCounciUnit = useSelector(selectedCouncilUnitSel);
  const councils = useSelector(councilsSel);
  const isLoadingCouncils = useSelector(isLoadingCouncilsSel);
  const filteredCounciUnits = useSelector(filteredCounciUnitsByTypeSel);
  const isLoadingCouncilUnits = useSelector(isLoadingCouncilUnitsSel);
  const isLoadingMarkAsMultiple = useSelector(isLoadingMarkAsMultipleSel);
  const unitInfo = useSelector(unitInfoSel);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({
      id: 'shared.form.error.isRequired',
    }),
  };

  const handleDeselectMember = personId => {
    dispatch(removeRecharterRosterSelectedKey(personId));
  };

  const requiredUnitRule = useMemo(
    () => ({
      required: selectedCouncilUnitType !== nonUnit.id,
      message: intl.formatMessage({
        id: 'shared.form.error.isRequired',
      }),
    }),
    [selectedCouncilUnitType],
  );

  const validate = useCallback(() => validateForm(form), [form]);

  const handleMarkAsMultiple = useCallback(async () => {
    const validData = await validate();
    if (validData) {
      dispatch(markAsMultipleRequest(selectedMembers));
    }
  }, [dispatch, validate, selectedMembers]);

  const handleSelectCouncil = useCallback(
    selectedGuid => {
      if (selectedGuid !== selectedCouncil) {
        dispatch(setSelectedCouncil(selectedGuid));
        resetFields(['unit', 'unitType']);
      }
    },
    [dispatch, selectedCouncil, resetFields],
  );

  const handleSelectUnitType = useCallback(
    unitType => {
      if (unitType !== selectedCouncilUnitType) {
        dispatch(setCouncilUnitType(unitType));
        if (unitType) {
          dispatch(getCouncilUnitsRequest());
        }
        resetFields(['unit']);
      }
    },
    [dispatch, selectedCouncilUnitType, resetFields],
  );

  const handleSelectCouncilUnit = unit => {
    dispatch(setCouncilUnit(unit));
  };

  useEffect(() => {
    dispatch(getCouncilsRequest());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Row>
        <S size="4" className={styles.greyLabel}>
          <FormattedMessage id="recharterModals.markAsMultipleModal.subTitle" />
        </S>
      </Row>
      <Row className={styles.tagsContainer}>
        {selectedMembers.map(({ personId, personFullName, isAdult }) => (
          <PersonTag
            key={`person_tag_${personId}`}
            id={personId}
            isAdult={isAdult}
            name={personFullName}
            onCloseAction={handleDeselectMember}
          />
        ))}
      </Row>
      <Row type="flex" justify="center">
        <Col span={18}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('council', {
              initialValue: selectedCouncil ? selectedCouncil : undefined,
              rules: [requiredRule],
            })(
              <Select
                className={styles.graySelect}
                showSearch
                filterOption
                placeholder={intl.formatMessage({
                  id: 'recharterModals.markAsMultipleModal.council',
                })}
                size="large"
                optionFilterProp="text"
                disabled={isLoadingCouncils || councils.length === 0}
                onSelect={handleSelectCouncil}
                rounded
                floatingLabel
              >
                <OptGroup
                  key="council"
                  label={
                    <FormattedMessage id="recharterModals.markAsMultipleModal.council" />
                  }
                >
                  {councils.map(({ councilGuid, councilName }, i) => (
                    <Option
                      key={`council_item_${i}`}
                      value={councilGuid}
                      text={councilName}
                    >
                      {councilName}
                    </Option>
                  ))}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col span={18}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('unitType', {
              initialValue: selectedCouncilUnitType
                ? selectedCouncilUnitType
                : undefined,
              rules: [requiredRule],
            })(
              <Select
                className={styles.graySelect}
                showSearch
                filterOption
                placeholder={intl.formatMessage({
                  id: 'recharterModals.markAsMultipleModal.unitType',
                })}
                size="large"
                disabled={!selectedCouncil}
                optionFilterProp="text"
                onSelect={handleSelectUnitType}
                rounded
                floatingLabel
              >
                <OptGroup
                  key="unitType"
                  label={
                    <FormattedMessage id="recharterModals.markAsMultipleModal.unitType" />
                  }
                >
                  {getValidRecharterUnitTypes(unitInfo.type).map(
                    ({ value, label }, i) => (
                      <Option
                        key={`type_${label}_${i}`}
                        value={value}
                        text={label}
                      >
                        {label}
                      </Option>
                    ),
                  )}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col span={18}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('unit', {
              initialValue: selectedCounciUnit ? selectedCounciUnit : undefined,
              rules: [requiredUnitRule],
            })(
              <Select
                className={styles.graySelect}
                showSearch
                filterOption
                placeholder={intl.formatMessage({
                  id: 'recharterModals.markAsMultipleModal.unit',
                })}
                size="large"
                optionFilterProp="text"
                disabled={
                  isLoadingCouncilUnits ||
                  filteredCounciUnits.length === 0 ||
                  selectedCouncilUnitType === nonUnit.id ||
                  !selectedCouncil
                }
                onSelect={handleSelectCouncilUnit}
                rounded
                floatingLabel
              >
                <OptGroup
                  key="council"
                  label={
                    <FormattedMessage id="recharterModals.markAsMultipleModal.unit" />
                  }
                >
                  {filteredCounciUnits.map(
                    (
                      {
                        organizationGuid,
                        charteredOrganizationName,
                        acceptGender,
                        unitNumber,
                      },
                      i,
                    ) => (
                      <Option
                        key={`unit_item_${i}`}
                        value={organizationGuid}
                        text={`${charteredOrganizationName} ${unitNumber} ${acceptGender}`}
                      >
                        <Tooltip
                          title={`${charteredOrganizationName} ${unitNumber} ${acceptGender}`}
                          placement="top"
                        >
                          {`${charteredOrganizationName} ${unitNumber} ${acceptGender}`}
                        </Tooltip>
                      </Option>
                    ),
                  )}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Button
          loading={isLoadingMarkAsMultiple}
          className={styles.button}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={false}
          disabled={selectedMembers.length === 0}
          onClick={handleMarkAsMultiple}
        >
          <FormattedMessage id="recharterModals.markAsMultipleModal.mark" />
        </Button>
      </Row>
    </React.Fragment>
  );
};

MarkAsMultipleForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create()(MarkAsMultipleForm);
