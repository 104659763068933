export default (formPhones = {}, profilePhones = []) => {
  try {
    const formattedPhones = Object.entries(formPhones).map(([key, phone]) => {
      const phoneNumber = phone.phone.replace(/\D/g, '');
      const areaCode = phoneNumber.slice(0, 3);
      const prefix = phoneNumber.slice(3, 6);
      const lineNumber = phoneNumber.slice(6, 10);
      const foundProfilePhone = profilePhones.find(
        profilePhone => profilePhone.id.toString() === key.split('_')[1],
      );

      if (foundProfilePhone) {
        return {
          ...foundProfilePhone,
          ...phone,
          mobilePhoneCarrier: phone.mobilePhoneCarrier
            ? phone.mobilePhoneCarrier
            : null,
          phoneCountryId: phone.countryId,
          typeId: Number(phone.typeId),
          id: Number(foundProfilePhone.id),
          areaCode,
          prefix,
          lineNumber,
        };
      }

      return {
        ...phone,
        phoneCountryId: phone.countryId,
        typeId: Number(phone.typeId),
        areaCode,
        prefix,
        lineNumber,
        extension: '',
      };
    });
    return formattedPhones;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    return [];
  }
};
