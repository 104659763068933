import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';

import {
  CLEAR_PEOPLE,
  DESELECT_PERSON,
  ModalMode,
  SELECT_MULTIPLE_PERSONS,
  SELECT_PERSON,
} from '../../common';
import { detailModes } from '../constants';
import {
  CLOSE_EAGLE_PROJECT_EDIT_MODAL,
  OPEN_EDIT_EAGLE_PROJECT,
  SET_DETAIL_MODE,
} from './actions';

const visible = (state = false, { type }) => {
  switch (type) {
    //case OPEN_CAMPOUTS_CREATE:
    //case OPEN_CAMPOUTS:
    case OPEN_EDIT_EAGLE_PROJECT: {
      return true;
    }
    case CLOSE_EAGLE_PROJECT_EDIT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const modalMode = (state = ModalMode.RECORD, { type }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT: {
      return ModalMode.EDIT;
    }
    default: {
      return state;
    }
  }
};

const allowRemovePersons = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT:
      return true;
    default:
      return state;
  }
};

const disableGetActivities = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT:
      return false;
    default:
      return state;
  }
};

const disableFutureDays = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT:
      return false;
    default:
      return state;
  }
};

const detailMode = (state = detailModes.SUGGEST, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT: {
      return detailModes.EDIT;
    }
    case SET_DETAIL_MODE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const userIds = (state = [], { type, payload }) => {
  switch (type) {
    case CLEAR_PEOPLE:
    case OPEN_EDIT_EAGLE_PROJECT: {
      return [];
    }
    case DESELECT_PERSON: {
      return state.filter(userId => userId !== payload);
    }
    case SELECT_PERSON:
      return [...new Set([...state, payload])];

    case SELECT_MULTIPLE_PERSONS:
      return [...new Set([...state, ...payload])];

    default: {
      return state;
    }
  }
};

const dates = (state = {}, { type }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const allowEditPersons = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_EAGLE_PROJECT: {
      const { allowEditOrAddPersonsPersons = false } = payload || {};
      return allowEditOrAddPersonsPersons;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  visible,
  userIds,
  modalMode,
  detailMode,
  dates,
  allowEditPersons,
  disableGetActivities,
  disableFutureDays,
  allowRemovePersons,
});

export default resetOnLogoutReducer(reducer);
