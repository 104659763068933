import DOMPurify from 'dompurify';
import { Moment } from 'moment';

import { FetchEventsResponse } from '@appTypes/esb';
import { InvitedUser } from '@appTypes/esb/advancements/events/event';
import { SBL_5060_ENABLE_HTML_EVENT_DESCRIPTION } from '@config';
import { parseBBCode } from '@modules/utils';
import momentReactCalendar from '@modules/utils/momentReactCalendar';
import momentWithOffset from '@modules/utils/momentWithOffset';

// Copy & paste from calendar epic
export function transformFetchEvents(
  events: FetchEventsResponse,
  params: {
    timezoneCode: string;
    loggedInUserId: number;
  },
) {
  if (!events) return [];

  const { timezoneCode, loggedInUserId } = params;

  const transformed = events.map(event => {
    const startDateTime = momentWithOffset(event.startDate, timezoneCode);
    const updatedStart = momentReactCalendar(startDateTime, event.startDate);
    const endDateTime = momentWithOffset(event.endDate, timezoneCode);
    const updatedEnd = momentReactCalendar(endDateTime, event.endDate);
    const startTime = startDateTime.format('HH:mm');
    const endTime = endDateTime.format('HH:mm');
    const isAllDay = startTime === '00:00' && endTime === '23:59';
    const invitedUser: InvitedUser =
      (event.invitedUsers &&
        event.invitedUsers.find(user => loggedInUserId === user.userId)) ||
      ({} as InvitedUser);
    return {
      title: event.name,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      start: (updatedStart as any)._d as Moment,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      end: (updatedEnd as any)._d as Moment,
      allDay: isAllDay,
      id: event.id,
      resource: {
        ...event,
        isEvent: !event.activityTypeId,
        description: SBL_5060_ENABLE_HTML_EVENT_DESCRIPTION
          ? DOMPurify.sanitize(event.description || '')
          : parseBBCode(event.description || ''),
        canRsvp: !!event.rsvp,
        userRsvp: invitedUser.rsvp,
      },
    };
  });

  return transformed;
}
