import { WEPAY_CLIENT_ID, WEPAY_ENDPOINT } from '@config';

const WEPAY_SDK_URL = 'https://static.wepay.com/min/js/tokenization.4.0.2.js';
const WEPAY_SDK_ID = '__wepay_sdk_id';

//Implementation

//token
/*
  Wepay.loadSDK().then(() =>
    Wepay.getToken({
      values: {
        cardholderName: 'Some User',
        email: 'some@email.org',
        cardNumber: '341134113411347',
        cvv: '1234',
        expirationDate: '11/21',
        country: 'US',
        zip: '76137',
      },
      onSuccess: data => console.log(data),
      onError: error => console.log(error),
    }),
  );
*/

//popup
/*
    Wepay.loadSDK().then(() =>
    Wepay.popUp({
        email: 'some@email.org',
        onSuccess: data => console.log(data),
        onError: error => console.log(error),
    }),
    );
*/

class Wepay {
  loadSDK = async () =>
    new Promise((resolve, reject) => {
      const existingScript = document.getElementById(WEPAY_SDK_ID);
      if (existingScript) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = WEPAY_SDK_URL;
      script.id = WEPAY_SDK_ID;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => resolve(this);
      script.onerror = error => reject(error);
    });

  getToken = ({ values, onSuccess, onError }) => {
    const payload = {
      client_id: WEPAY_CLIENT_ID, //QA: 159335 PD: 7095
      user_name: values.cardHoldersName,
      email: values.email,
      cc_number: values.cardNumber,
      cvv: values.cardCvv, //SECURITY CHECK!!!
      expiration_month: values.expDateMonth,
      expiration_year: values.expDateYear,
      address: {
        country: values.country,
        postal_code:
          values.country === 'US' ? values.zip.substring(0, 5) : values.zip,
      },
      card_on_file: false, //Indicates if the resulting token will be kept on file as a non-recurring payment method.
      recurring: false, //Indicates if the resulting token will be kept on file as a recurring payment method
    };
    window.WePay.set_endpoint(WEPAY_ENDPOINT);
    window.WePay.credit_card.create(payload, data =>
      data.error
        ? onError(data.error_description)
        : onSuccess(data.credit_card_id),
    );
  };

  popUp = ({ email, onSuccess, onError }) => {
    window.WePay.set_endpoint(WEPAY_ENDPOINT);
    window.WePay.bank_account.create(
      {
        client_id: WEPAY_CLIENT_ID, //QA: 159335 PD: 7095
        email: email,
      },
      data =>
        data.error ? onError(data.error) : onSuccess(data.bank_account_id),
    );
  };
}

export default new Wepay();
