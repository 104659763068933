import React from 'react';

import { Col, Radio, Row } from 'bsa-ui';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { shortViewDateFormat } from '../../constants';
import { intl } from '../../localization';
import Card from '../Card/Card';
import DatePicker from '../DatePicker';
import Form from '../Form';
import Input from '../Input';
import S from '../S';
import Select from '../Select';
import T from '../T';
import styles from './ProfileOtherInfoCard.less';
import { SchoolForm } from './SchoolForm';

const RadioGroup = Radio.Group;

const { Section: CardSection } = Card;
const { Item: FormItem } = Form;

// eslint-disable-next-line
const ProfileOtherInfoCard = ({
  form,
  profile,
  isLoading = false,
  isAdult = false,
  hasPermissions = false,
  swimmingClass = [],
  isReadOnly,
}) => {
  const { getFieldDecorator } = form;
  const { extendedProfile = [], sbExtendedProfile = {} } = profile;

  const grade = extendedProfile.find(
    extendedProfile => extendedProfile.classification === 'Grade',
  );

  const {
    talentReleaseUserId,
    talentReleaseDate,
    swimmingClassification,
    swimmingClassificationDate,
    annualHealthRecordABDate,
    annualHealthRecordCDate,
  } = sbExtendedProfile;

  return (
    <Card margin noPadding className={styles.card} loading={isLoading}>
      <CardSection
        title={
          <T size="5" colored>
            <FormattedMessage id="shared.otherInfo.title" />
          </T>
        }
      >
        <Row type="flex" justify="space-between">
          <Col xs={24}>
            <S size="4" inline>
              <FormattedMessage id={'shared.otherInfo.talentRelease'} />
            </S>
          </Col>
          <Col xs={24}>
            <FormItem>
              {getFieldDecorator('talentRelease', {
                initialValue: !!talentReleaseUserId && !!talentReleaseDate,
              })(
                <RadioGroup disabled={isReadOnly}>
                  <Radio value={true}>
                    <FormattedMessage id="shared.yes" />
                  </Radio>
                  <Radio value={false}>
                    <FormattedMessage id="shared.no" />
                  </Radio>
                </RadioGroup>,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={11} className={styles.col}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('swimmingClassification', {
                initialValue: swimmingClassification || 'Nonswimmer',
              })(
                <Select
                  disabled={!hasPermissions || isReadOnly}
                  rounded
                  size="large"
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.otherInfo.swimmingClass',
                  })}
                >
                  <Select.OptGroup>
                    {swimmingClass.map(({ short }) => (
                      <Select.Option key={short} value={short} text={short}>
                        {short}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={13} className={styles.col}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('swimmingClassificationDate', {
                initialValue: swimmingClassificationDate
                  ? moment(swimmingClassificationDate)
                  : swimmingClassificationDate,
              })(
                <DatePicker
                  disabled={!hasPermissions || isReadOnly}
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.otherInfo.swimmingClassDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={11} className={styles.col}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('annualHealthRecordABDate', {
                initialValue: annualHealthRecordABDate
                  ? moment(annualHealthRecordABDate)
                  : annualHealthRecordABDate,
              })(
                <DatePicker
                  disabled={!hasPermissions || isReadOnly}
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.otherInfo.medicalABDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={13} className={styles.col}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('annualHealthRecordCDate', {
                initialValue: annualHealthRecordCDate
                  ? moment(annualHealthRecordCDate)
                  : annualHealthRecordCDate,
              })(
                <DatePicker
                  disabled={!hasPermissions || isReadOnly}
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.otherInfo.medicalCDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                />,
              )}
            </FormItem>
          </Col>
        </Row>
        <S size="6" inline>
          <FormattedMessage id={'shared.otherInfo.note'} />
        </S>
      </CardSection>
      {!isAdult && (
        <CardSection>
          <Row type="flex" justify="space-between">
            {!isAdult && (
              <Col xs={24} lg={11} className={styles.col}>
                <div className={styles.selectInputContainer}>
                  <div className={styles.gradeContainer}>
                    <FormItem>
                      {getFieldDecorator('grades', {
                        initialValue:
                          grade && grade.lookupLong
                            ? grade.lookupLong
                            : undefined,
                      })(
                        <Input
                          disabled={true}
                          size="large"
                          placeholder={intl.formatMessage({
                            id: 'shared.otherInfo.grade',
                          })}
                          rounded
                          floatingLabel
                        />,
                      )}
                    </FormItem>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <SchoolForm form={form} profile={profile} />
          </Row>
        </CardSection>
      )}
    </Card>
  );
};

ProfileOtherInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  isAdult: PropTypes.bool,
  hasPermissions: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  grades: PropTypes.array.isRequired,
  swimmingClass: PropTypes.array,
  isReadOnly: PropTypes.bool,
};

export default ProfileOtherInfoCard;
