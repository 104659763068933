import {
  LoginPage,
  LoginPagePlaceholder,
  ChooseScoutbookPage,
  NoAccessPage,
  SandBoxPage,
  AuthRedirect,
} from './components';
import {
  ROUTE_LOGIN,
  ROUTE_CHOOSE_SCOUTBOOK,
  ROUTE_NO_ACCESS,
  ROUTE_SAND_BOX,
  ROUTE_AUTH_REDIRECT,
} from './duck';

const routes = {
  [ROUTE_LOGIN]: {
    path: '/login',
    Component: LoginPage,
    Placeholder: LoginPagePlaceholder,
    titleId: 'page.login',
    unauthorizedAccess: true,
    standalone: true,
  },
  [ROUTE_AUTH_REDIRECT]: {
    path: '/app-login',
    Component: AuthRedirect,
    titleId: 'page.app-login',
    unauthorizedAccess: true,
    standalone: true,
  },
  [ROUTE_CHOOSE_SCOUTBOOK]: {
    path: '/choose-scoutbook',
    Component: ChooseScoutbookPage,
    titleId: 'page.chooseScoutbook',
    standalone: true,
  },
  [ROUTE_NO_ACCESS]: {
    path: '/no-access',
    Component: NoAccessPage,
    titleId: 'page.noAccess',
    standalone: true,
  },
  [ROUTE_SAND_BOX]: {
    path: '/sand-box',
    Component: SandBoxPage,
    titleId: 'page.sandBox',
    standalone: true,
  },
};

export default routes;
