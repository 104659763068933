import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, Checkbox } from '@shared';
import { peopleFilters } from '../../../../constants';

class PeopleFilterSection extends React.PureComponent {
  handleChange = e => {
    const { name } = e.target;
    const { values, onChange } = this.props;
    const newValues = {
      ...values,
      [name]: !values[name],
    };

    onChange(newValues);
  };

  render() {
    const { values } = this.props;

    return (
      <div>
        <Checkbox
          checked={values[peopleFilters.YOUTH]}
          name={peopleFilters.YOUTH}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.filter.option.youth" />
          </S>
        </Checkbox>
        <Checkbox
          checked={values[peopleFilters.ADULTS]}
          name={peopleFilters.ADULTS}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.filter.option.adults" />
          </S>
        </Checkbox>
      </div>
    );
  }
}

PeopleFilterSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PeopleFilterSection;
