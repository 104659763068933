import { combineReducers } from 'redux';

import { emptyObj } from '@shared';
import { resetOnLogoutReducer } from '@utils';

import { activityValuesTabs } from '../../common';
import {
  ACTIVITY_LOGS_ERROR,
  ACTIVITY_LOGS_REQUEST,
  ACTIVITY_LOGS_RESPONSE,
  ACTIVITY_LOGS_SEARCH,
  CLEAR_PEOPLE,
  DESELECT_PERSON,
  GET_ACTIVITY_BY_ID_ERROR,
  GET_ACTIVITY_BY_ID_REQUEST,
  GET_ACTIVITY_BY_ID_RESPONSE,
  REMOVE_CURRENT_LOGS_INFO,
  REMOVE_PARTICIPANT,
  REMOVE_PARTICIPANT_RESPONSE,
  SAVE_ACTIVITY_ERROR,
  SAVE_ACTIVITY_REQUEST,
  SAVE_ACTIVITY_RESPONSE,
  SELECT_MULTIPLE_PERSONS,
  SELECT_PERSON,
  SET_ACTIVE_TAB,
  SET_ACTIVITY_TO_DELETE,
  SET_SELECTED_ACTIVITY_DATA,
  SHOW_REMOVE_PARTICIPANT_MODAL,
} from './actions';

// Activity logs reducers

const activityLogs = (state = [], { type, payload }) => {
  switch (type) {
    case ACTIVITY_LOGS_REQUEST:
    case ACTIVITY_LOGS_ERROR:
      return [];
    case ACTIVITY_LOGS_RESPONSE:
      return payload;
    default: {
      return state;
    }
  }
};

const isLoadingActivityLogs = (state = false, { type }) => {
  switch (type) {
    case ACTIVITY_LOGS_REQUEST:
      return true;
    case ACTIVITY_LOGS_ERROR:
    case ACTIVITY_LOGS_RESPONSE:
      return false;
    default: {
      return state;
    }
  }
};

const currentLogsInfo = (state = {}, { type, payload }) => {
  switch (type) {
    case ACTIVITY_LOGS_REQUEST:
      return payload;
    case REMOVE_CURRENT_LOGS_INFO:
    case ACTIVITY_LOGS_ERROR: {
      return {};
    }
    default:
      return state;
  }
};

const showRemoveParticipantModal = (state = false, { type, payload }) => {
  switch (type) {
    case SHOW_REMOVE_PARTICIPANT_MODAL:
      return payload;
    case REMOVE_PARTICIPANT_RESPONSE:
      return false;
    default:
      return state;
  }
};

const removingParticipant = (state = false, { type }) => {
  switch (type) {
    case REMOVE_PARTICIPANT: {
      return true;
    }
    case REMOVE_PARTICIPANT_RESPONSE:
      return false;
    default: {
      return state;
    }
  }
};

const setActivityToDelete = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ACTIVITY_TO_DELETE:
      return payload;
    default:
      return state;
  }
};

// Create/Edit activity form reducers

const selectedUserIds = (state = [], { type, payload }) => {
  switch (type) {
    case DESELECT_PERSON: {
      return state.filter(userId => userId !== payload);
    }
    case CLEAR_PEOPLE:
      return [];
    case SELECT_PERSON:
      return [...new Set([...state, payload])];
    case SELECT_MULTIPLE_PERSONS:
      return [...new Set([...state, ...payload])];
    default: {
      return state;
    }
  }
};

const activeTab = (state = activityValuesTabs.BASIC, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_TAB: {
      return payload;
    }
    case GET_ACTIVITY_BY_ID_RESPONSE:
    case SET_SELECTED_ACTIVITY_DATA: {
      const { areSameAdvancedValues = true } = payload || {};
      return areSameAdvancedValues
        ? activityValuesTabs.BASIC
        : activityValuesTabs.ADVANCED;
    }
    default: {
      return state;
    }
  }
};

const isLoadingSaveActivity = (state = false, { type }) => {
  switch (type) {
    case SAVE_ACTIVITY_REQUEST: {
      return true;
    }
    case SAVE_ACTIVITY_RESPONSE:
    case SAVE_ACTIVITY_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedActivityData = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_ACTIVITY_BY_ID_REQUEST: {
      return emptyObj;
    }
    case GET_ACTIVITY_BY_ID_RESPONSE:
    case SET_SELECTED_ACTIVITY_DATA: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const getActivityByIdLoading = (state = false, { type }) => {
  switch (type) {
    case GET_ACTIVITY_BY_ID_REQUEST: {
      return true;
    }
    case GET_ACTIVITY_BY_ID_RESPONSE:
    case GET_ACTIVITY_BY_ID_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const activityLogSearch = (state = '', { type, payload }) => {
  switch (type) {
    case ACTIVITY_LOGS_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  activityLogs,
  isLoadingActivityLogs,
  currentLogsInfo,
  showRemoveParticipantModal,
  removingParticipant,
  setActivityToDelete,
  selectedUserIds,
  activeTab,
  isLoadingSaveActivity,
  getActivityByIdLoading,
  selectedActivityData,
  activityLogSearch,
});

export default resetOnLogoutReducer(reducer);
