type Profile = {
  nickName?: string | null;
  firstName?: string;
  middleName?: string | null;
  lastName?: string;
};

const formatName = (name: string): string =>
  name && name.length === 1 ? `${name}.` : name;

const isOneCharLong = (str: string) => {
  switch (str.length) {
    case 1:
      return true;
    case 2:
      return str[1] === '.';
  }
  return false;
};

const collapseWhitespaceAndTrim = (str: string) =>
  str.trim().replace(/\s\s+/g, ' ');

const buildPersonName = ({
  nickName,
  firstName,
  middleName,
  lastName,
}: Profile) => {
  const formattedFirstName = formatName(nickName || firstName || ''); //NOSONAR

  return collapseWhitespaceAndTrim(
    `${formattedFirstName} ${middleName} ${lastName ?? ''}`,
  );
};

const buildShortPersonName = (profile: Profile) => {
  const { firstName, middleName } = profile;
  const formattedMiddleName =
    !firstName || isOneCharLong(firstName) ? formatName(middleName ?? '') : '';

  return buildPersonName({ ...profile, middleName: formattedMiddleName });
};

const buildFullPersonName = (profile: Profile) => {
  const { middleName } = profile;
  const formattedMiddleName = formatName(middleName ?? '');

  return buildPersonName({ ...profile, middleName: formattedMiddleName });
};

export default {
  short: buildShortPersonName,
  full: buildFullPersonName,
};
