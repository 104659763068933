import identity from 'lodash/identity';
import { useSelector } from 'react-redux';

import { RosterScout } from '@modules/advancement/duck/types';
import { usePositions } from '@modules/user/duck/hooks';

import { positionsAllowedToAddAnyPeople } from '../constants';
import { peopleInSubUnitsSel } from './selectorsTyped';

export function useAddPeopleFilter() {
  const { hasPositions } = usePositions();
  const peopleInSubUnits = useSelector(peopleInSubUnitsSel);

  const filter = hasPositions(positionsAllowedToAddAnyPeople)
    ? identity
    : (item: RosterScout) =>
        peopleInSubUnits.some(person => item.userId === person.userId);

  return filter;
}

export function useCanAddAnyPeple() {
  const { hasPositions } = usePositions();
  return hasPositions(positionsAllowedToAddAnyPeople);
}
