import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@shared';
import { testLoadingSel, testRequest } from '../../duck';

// This component is just to test syncing updates after restoring internet connection.
class TestOffline extends React.PureComponent {
  render() {
    const { loading, onRequest } = this.props;

    return (
      <Button loading={loading} onClick={onRequest}>
        request
      </Button>
    );
  }
}

TestOffline.propTypes = {
  loading: PropTypes.bool.isRequired,
  onRequest: PropTypes.func.isRequired,
};

const mapState = state => ({
  loading: testLoadingSel(state),
});

const mapDispatch = dispatch => ({
  onRequest: () => dispatch(testRequest()),
});

export default connect(mapState, mapDispatch)(TestOffline);
