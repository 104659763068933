import React, { useMemo, useState } from 'react';

import CheckIcon from '@material-ui/icons/OfflinePinOutlined';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type { UnitPendingLeadershipPosition } from '@appTypes/esb';
import {
  searchFilterSel,
  selectedRowKeysSel,
  setSelectedRowKeys,
} from '@modules/advancement/pendingItems/duck';
import { useUnitPendingLeadershipPositions } from '@modules/leadershipPositions/hooks';
import {
  Button,
  hasPermissionSel,
  packRosterLoadingSel,
  permissions,
} from '@modules/shared';
import Table from '@modules/shared/components/Table';
import { getPreferredName } from '@modules/shared/utils/preferredName';

import {
  LeadershipApproveModalContainer,
  SelectedPositionItem,
} from '../ApproveModal/ApproveModal';
import styles from './PendingItemsTable.less';

export const PendingLeadershipPositionsTable: React.FC = () => {
  const loadingRoster = useSelector(packRosterLoadingSel);
  const {
    youthPositions: positions,
    response: { isLoading },
  } = useUnitPendingLeadershipPositions();
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );
  const [modalVisible, setModalVisible] = useState(false);
  const selectedRowKeys = useSelector(selectedRowKeysSel);
  const dispatch = useDispatch();

  const searchTerm: string = useSelector(searchFilterSel);

  const pendingPositions = useMemo(() => {
    if (!searchTerm) return positions || [];

    return (
      positions?.filter(position => {
        const scoutName = position.personFullName || '';
        const searchValue =
          `${position.bsaMemberId} ${scoutName}`.toLowerCase();

        const searchMatch = searchValue.includes(searchTerm.toLowerCase());
        return searchMatch;
      }) || []
    );
  }, [positions, searchTerm]);

  const selectedPositions = useMemo(
    () =>
      positions
        ?.filter(({ userPositionId }) =>
          selectedRowKeys.includes(userPositionId),
        )
        .map(
          item =>
            ({
              position: item,
            } as SelectedPositionItem),
        ) || [],
    [positions, selectedRowKeys],
  );

  const renderTitle = () => (
    <>
      {canApprove && (
        <Button
          id="qa_approveAdvancement"
          disabled={!selectedPositions.length}
          className={styles.approveAdvancements}
          color="primary"
          ghost
          noBorder
          fitText
          icon={<CheckIcon />}
          onClick={() => setModalVisible(true)}
        >
          <FormattedMessage
            id="progress.ApproveAdvancementsButton.title"
            values={{ count: selectedPositions.length }}
          />
        </Button>
      )}
    </>
  );

  return (
    <>
      <LeadershipApproveModalContainer
        visible={modalVisible}
        selectedPositions={selectedPositions}
        onCancel={() => setModalVisible(false)}
      />
      <div className={styles.tableContainer}>
        <Table
          className={styles.table}
          title={renderTitle}
          rowKey="userPositionId"
          loading={isLoading || loadingRoster}
          dataSource={pendingPositions}
          rowSelection={{
            selectedRowKeys,
            onSelect: (
              record: UnitPendingLeadershipPosition,
              selected: boolean,
            ) => {
              if (selected) {
                dispatch(
                  setSelectedRowKeys([
                    ...selectedRowKeys,
                    record.userPositionId,
                  ]),
                );
              } else {
                dispatch(
                  setSelectedRowKeys(
                    [...selectedRowKeys].filter(
                      key => key !== record.userPositionId,
                    ),
                  ),
                );
              }
            },
          }}
          size="small"
          columns={[
            {
              title: <FormattedMessage id="shared.name" />,
              render: (_value: unknown, row: UnitPendingLeadershipPosition) => {
                const personFullName = getPreferredName(row);
                return <span>{personFullName}</span>;
              },
            },
            {
              dataIndex: 'bsaMemberId',
              title: <FormattedMessage id="shared.memberId" />,
              width: 100,
            },
            {
              dataIndex: 'rank',
              title: <FormattedMessage id="shared.rank" />,
              width: 160,
            },
            { dataIndex: 'position', title: 'Position' },
            {
              dataIndex: 'startDate',
              width: 130,
              title: <FormattedMessage id="shared.date.startDate" />,
            },
            {
              dataIndex: 'notes',
              title: <FormattedMessage id="shared.notesTitle" />,
              ellipsis: true,
            },
          ]}
        />
      </div>
    </>
  );
};
