// custom prop types
// https://github.com/facebook/prop-types#usage
import React from 'react';

const getNameOfType = type => {
  if (typeof type === 'function') {
    return type.name;
  }
  return type;
};

const getNamesOfTypes = types =>
  types.map(type => getNameOfType(type)).join(', ');

const nodeOfTypes =
  (...types) =>
  (props, propName, componentName) => {
    const prop = props[propName];
    let error = null;

    React.Children.toArray(prop)
      .filter(child => child)
      .forEach(child => {
        if (!types.includes(child.type)) {
          error = new Error(
            `Invalid prop '${propName}' of type '${getNameOfType(
              child.type,
            )}' supplied to '${componentName}'. Expected node of type '${getNamesOfTypes(
              types,
            )}'`,
          );
        }
      });

    return error;
  };

export default {
  nodeOfTypes,
};
