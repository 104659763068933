import { createAction } from '@reduxjs/toolkit';

import { createModuleNameSpace } from '@modules/utils/misc';

import { modulePath } from '../constants';

const moduleNamespace = createModuleNameSpace(modulePath);

export const showSearchDenChiefModal = createAction(
  `${moduleNamespace}/SHOW_SEARCH_DEN_CHIEF_MODAL`,
);
export const hideSearchDenChiefModal = createAction(
  `${moduleNamespace}/HIDE_SEARCH_DEN_CHIEF_MODAL`,
);
