import moment from 'moment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  districtCouncilStructureTypeId,
  districtOrganizationTypeId,
  dtoDateFormat,
  normalizeUnitGender,
  programForUnitTypeId,
  subdistrictCouncilStructureTypeId,
  subdistrictOrganizationTypeId,
  unitTypeIdForUnitType,
  unitTypes,
} from '@shared';
import { esbApiService, sorters } from '@utils';

const validUnitTypes = [
  unitTypes.PACK,
  unitTypes.TROOP,
  unitTypes.CREW,
  unitTypes.SHIP,
  unitTypes.POST,
  unitTypes.CLUB,
];

const formatUnitRoleName = (unitType, { unitNumber }) =>
  `${unitType} ${unitNumber}`;

const fetchingDistrictsNotAllowed = err => {
  const { response = {} } = err;
  const { message = '' } = response;

  return message === 'CouncilGuid is not valid';
};

export function transformUnitsResponse({ units, unitTypeId }) {
  return (units || [])
    .filter(
      ({ unitType }) =>
        validUnitTypes.includes(unitType) &&
        (unitTypeId ? unitTypeIdForUnitType[unitType] === unitTypeId : true),
    )
    .map(({ unitType, unitStatus, ...unit }) => {
      unitTypeId = unitTypeIdForUnitType[unitType];
      const shouldHaveGenderLabel = !(
        unitType === unitTypes.SHIP || unitType === unitTypes.CREW
      );

      const currentUnitStatus = (unitStatus || '').toLowerCase();
      return {
        unitTypeId,
        unitType,
        organizationName: formatUnitRoleName(unitType, unit),
        program: programForUnitTypeId[unitTypeId],
        // active and lapsed units should behave the same way for council units,
        // the only difference is that lapsed units will not longer available after 60 days of expiration date
        active:
          currentUnitStatus === 'active' ||
          currentUnitStatus === 'lapsed' ||
          currentUnitStatus === 'unposted',
        unitStatus,
        unitGender: shouldHaveGenderLabel ? normalizeUnitGender(unit) : '',
        ...unit,
      };
    });
}

const getUnits$ = ({
  unitTypeId,
  organizationGuid,
  unitNumber,
  districtName,
  expirationDate,
}) =>
  esbApiService
    .post$(
      '/organizations/units/search?unitStatus=all',
      {
        unitTypeId,
        organizationGuid,
        districtName,
        expireDate: expirationDate
          ? moment(expirationDate).format(dtoDateFormat)
          : undefined,
        unitNumber,
      },
      {
        gtm: {
          label: '/organizations/units/search',
        },
        swCache: true,
      },
    )
    .pipe(map(units => transformUnitsResponse({ units, unitTypeId })));

const getDistrictsAndSubdistrictsFromOrganizations = orgs => {
  const districts = orgs
    .filter(
      ({ organizationTypeId, councilStructureTypeId }) =>
        (organizationTypeId == districtOrganizationTypeId &&
          councilStructureTypeId == districtCouncilStructureTypeId) ||
        (organizationTypeId == subdistrictOrganizationTypeId &&
          councilStructureTypeId == subdistrictCouncilStructureTypeId),
    )
    .map(
      ({
        organizationGuid,
        organizationName,
        organizationTypeId,
        councilStructureTypeId,
      }) => ({
        organizationGuid,
        organizationFullName: organizationName,
        organizationTypeId,
        councilStructureTypeId,
      }),
    );

  return districts;
};

const getDistricts$ = (organizationGuid, fallbackOrgs) =>
  esbApiService
    .get$(`/organizations/${organizationGuid}/districtAndSubDistricts`, {
      gtm: {
        label: '/organizations/{organizationGuid}/districtAndSubDistricts',
      },
      suppressErrorToasts: [400],
      suppressSentry: [400],
    })
    .pipe(
      map(res =>
        res.organizations.map(org => {
          const isDistrict = org.organizationType === 'District';

          return {
            ...org,
            organizationTypeId: isDistrict
              ? districtOrganizationTypeId
              : subdistrictOrganizationTypeId,
            councilStructureTypeId: isDistrict
              ? districtCouncilStructureTypeId
              : subdistrictCouncilStructureTypeId,
          };
        }),
      ),
      catchError(err => {
        if (fetchingDistrictsNotAllowed(err)) {
          return of(getDistrictsAndSubdistrictsFromOrganizations(fallbackOrgs));
        }

        throw err;
      }),
      map(orgs =>
        orgs
          .map(org => ({
            ...org,
            organizationFullName: (org.organizationFullName || '').trim(),
          }))
          .sort(sorters.string('organizationFullName')),
      ),
    );

export default {
  getUnits$,
  getDistricts$,
};
