import { createElement } from 'react';
import PropTypes from 'prop-types';
import { Form as BsaForm } from 'bsa-ui';

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    lg: { span: 10 },
  },
  wrapperCol: {
    sm: { span: 24 },
    lg: { span: 14 },
  },
};

/**
 * @type {React.FC<import('./FormItem.types').FormItemProps>}
 */
const Item = ({ children, standardLayout, ...props }) =>
  createElement(
    BsaForm.Item,
    { ...(standardLayout ? formItemLayout : null), ...props },
    children,
  );

Item.propTypes = {
  children: PropTypes.node.isRequired,
  standardLayout: PropTypes.bool,
};

Item.defaultProps = {
  standardLayout: false,
};

export default Item;
