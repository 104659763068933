import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import {
  AdvancementImage,
  ActivityTypeIcon,
  Tooltip,
  advancementStatuses,
  intl,
} from '@shared';
import { historyItemsTypes } from '../../../../common';
import styles from './PendingAdvancementIcon.less';

const PendingAdvancementIcon = ({
  item = {},
  type,
  imgUrl,
  activityType,
  className,
}) => {
  switch (type) {
    case historyItemsTypes.ADVANCEMENT: {
      return (
        <React.Fragment>
          <AdvancementImage src={imgUrl} className={className} />
          {item.status === advancementStatuses.COUNSELOR_APPROVED && (
            <Tooltip
              title={intl.formatMessage({
                id: 'shared.AdvancementStatus.counselorApproved',
              })}
            >
              <DoneAllIcon className={cn(styles.icon, styles.approved)} />
            </Tooltip>
          )}
        </React.Fragment>
      );
    }
    case historyItemsTypes.ACTIVITY: {
      return (
        <ActivityTypeIcon
          isJTE={item.isJTE}
          activityType={activityType}
          className={className}
        />
      );
    }
    default: {
      return null;
    }
  }
};

PendingAdvancementIcon.propTypes = {
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  item: PropTypes.object,
  imgUrl: PropTypes.string,
  activityType: PropTypes.string,
  className: PropTypes.string,
};

export default PendingAdvancementIcon;
