import React from 'react';
import PropTypes from 'prop-types';

import styles from './ActivitiesFormModal.less';
import { useSelector } from 'react-redux';
import { isMobileSel } from '../../duck';
import Modal from '../Modal';
import { FormattedMessage } from 'react-intl';
import Form from '../Form';
import { Row, Col } from '../bsaComponents';
import Button from '../Button';
import S from '../S/S';
import cn from 'classnames';

const ActivitiesFormModal = ({
  visible,
  activityId,
  activityName,
  onSave,
  onCancel,
}) => {
  const isMobile = useSelector(isMobileSel);

  const handleConfirm = () => onSave(activityId);

  return (
    <Modal
      mask={!isMobile}
      headerColor="scouting-warm-gray"
      visible={visible}
      title={<FormattedMessage id="shared.confirm" />}
      onCancel={onCancel}
      className={cn(styles.roundedModal, styles.deleteModal)}
    >
      <Row>
        <Col>
          <S size="3">
            <FormattedMessage
              id="activitiesModal.confirmDeleteActivity"
              values={{ name: <b>{activityName}</b> }}
            />
          </S>
        </Col>
      </Row>
      <Row type="flex" justify="end" gutter={8}>
        <Col>
          <Button size="small" onClick={onCancel}>
            <FormattedMessage id="shared.cancel" />
          </Button>
        </Col>
        <Col>
          <Button type="primary" size="small" noBorder onClick={handleConfirm}>
            <FormattedMessage id="shared.delete" />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ActivitiesFormModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  activityId: PropTypes.number.isRequired,
  activityName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default Form.create()(ActivitiesFormModal);
