/**
  Created this file because if we import from ./constant.js it also includes
  other dependencies ending in circular dependency imports
 */

// See SBL-3729, SBL-3770
export const HOUSTON_COUNCIL_GUID = '36BD2085-C185-4A83-AF7B-84F34361BB70';
export const COUNCIL_364_GUID = 'B9AFA24E-013B-4112-AC74-03247996AFC6';
export const COUNCIL_562_GUID = 'E149389A-85D0-4E51-AA04-6CCC6FB2FC41';

export const COUNCIL_602_GUID = '5DB4A447-E12F-4384-BCA1-0A1822B142AD';
export const COUNCIL_198_GUID = 'F0AFF30D-6D49-4D71-BA40-27B10CD04977';

// See SBL-4152
export const PP_CUSTOM_COUNCILS = {
  COUNCIL_27: '6988B95C-C392-4D9F-808D-4D7409F23CDC',
  COUNCIL_55: '09305C8C-1875-4C4D-9DF6-F81E0A936B5E',
  COUNCIL_61: '76FA7A75-2646-4B98-9750-AE3CBEF8C687',
  COUNCIL_92: 'F9EAC777-34BF-4F9E-8A2F-7C75049CE2FF',
  COUNCIL_583: 'B2A2EF18-512B-4F4C-A094-2E14BA60F491',
  COUNCIL_636: 'B038617D-1E90-4ABE-B38E-40D4A43FE72E',
};

// See SBL-4744
export const LAST_ENABLED_COUNCILS = new Set(
  [
    // "Council Name","Council Guid",
    ['Alabama-Florida Council 003', 'D668B449-41CA-40A4-8AC2-8CE866BB1925'],
    ['Allegheny Highlands Council 382', '9BD4DDDF-B7B2-4BD1-B8FE-5368405BA3DD'],
    ['Arbuckle Area Council 468', '0CF62B84-C734-47AB-9F9F-62B439C90434'],
    ['Baltimore Area Council 220', '07F16158-3BBA-4EE6-B384-E9CBA7CC464C'],
    ['Buckeye Council 436', 'B58A9B52-7D1F-4856-AE11-9E2A5F25B591'],
    ['Buckskin 617', '70EF5BC5-8A2D-407F-8A17-5BC6B5AEBD87'],
    ['Buffalo Trace 156', '318A1438-B11C-49E0-8CDA-4D998FC1AE0D'],
    ['Cape Cod and Islands Cncl 224', 'AFC4BD3A-40F5-437F-87F6-CC1AA7DAF35B'],
    ['Cape Fear Council 425', '141E7CE5-BA1B-4F05-98A9-50B4C88FE827'],
    ['Central Georgia Council 096', 'C9B8F500-6F1C-41AD-945A-F3964A6BD0E9'],
    [
      'Chief Cornplanter Council, BSA 538',
      'A239B8F9-A0CC-4D65-A7B9-58ED7F729C95',
    ],
    ['Chippewa Valley Council 637', 'E59BBFC5-607B-4BFC-8E6D-0BAFCFBDE737'],
    ['Cimarron Council 474', '8ACF03C8-AE96-4D79-861E-5D5CB7A1BC83'],
    ['Coronado Area Council 192', '082E87A3-F6FC-4E0E-A6D7-4E49D87106AD'],
    ['Erie Shores Council 460', 'ADE3D616-9931-4726-B807-59DC89F42AE3'],
    ['Flint River Council 095', 'B45E963B-DA5F-488A-BB86-614895F5CC7A'],
    ['French Creek Council 532', '05B6E563-92EA-4A56-989D-063FBEE9F851'],
    ['Georgia-Carolina 093', '7B387E7F-EA0B-4ABB-82B1-AD6F7E385638'],
    ['Golden Gate Area 023', 'B3EF07BA-A2E5-420C-B232-C9F6D8A2E28E'],
    ['Golden Spread Council 562', 'E149389A-85D0-4E51-AA04-6CCC6FB2FC41'],
    ['Greater Alabama Council 001', 'FEDF672E-02A8-41B8-B8D0-112085BDC67E'],
    ['Greater Hudson Valley 388', '902094E6-CF9A-4A64-9299-176D1E9CCCC9'],
    [
      'Greater New York Councils, BSA 640',
      '11AFD3F2-5F33-4B4C-A993-2F35B2159C58',
    ],
    [
      'Greater St. Louis Area Council 312',
      '1B612164-E40B-4097-89F2-849277860E96',
    ],
    ['Greater Tampa Bay Area 089', '17F7B7CC-B864-4774-BEB2-4D6B963F6102'],
    ['Green Mountain 592', 'BCC93898-5658-4E9C-9B8B-9ACF0F7DCFAA'],
    ['Greenwich 067', 'EC24AF34-E2FB-4BD8-AF8E-A39428CF99C8'],
    ['Gulf Stream Council 085', '4F569F41-A672-482C-83FA-C4CD7C7D963E'],
    ['Housatonic Council, BSA 069', '4363B5EE-D9CB-4714-B92F-C2844E2D111D'],
    ['Indian Waters Council 553', 'F972A89D-6F59-4C46-83DF-738555134734'],
    ['Istrouma Area Council 211', 'E6716B5A-F0E1-4C13-B127-9009E2C793C4'],
    ['Jayhawk Area Council 197', '546EF235-E5DC-40C6-86C7-90BA001A3D46'],
    ['Last Frontier Council 480', 'D37D66D4-EA48-48FB-9CD7-F67BA78BE5EF'],
    ['Longhouse Council 373', '31C1D0FC-AF9A-4217-A7B2-2B92B88592A3'],
    ['Mecklenburg County Council 415', 'E4F73ED0-4AF6-486B-97F4-CB01CE53687B'],
    ['Michigan Crossroads 780', 'D70587BB-25B7-421F-A97F-D953178CB3B2'],
    ['Middle Tennessee Council 560', '609B4B75-8DFC-41DF-BFDA-3337FB1F6067'],
    ['Mountain West Council 106', '4F6AB97E-C804-4014-8D97-A65D05845E25'],
    ['Natchez Trace Council 748', '8F1C450F-4063-47BF-9F8F-602F1CD63216'],
    ['Natural State Council 018', '601C4396-C176-438D-B830-E95B87D8F2A4'],
    ['Nevada Area Council 329', '27E4142D-9174-4256-A988-153FB8347D2C'],
    ['New Birth of Freedom 544', '63D3BDA1-F908-4EB0-9DEA-5E7C7D7709B8'],
    ['North Florida Council 087', 'DE580550-75DB-4D62-B4DD-E0CADD95260C'],
    ['Northeast Georgia Council 101', '72B858B7-97E3-43CA-9128-A9AF7D6FEA92'],
    ['Northeast Iowa Council 178', '7490958E-0B17-4459-B220-49263FE17B00'],
    [
      'Northern New Jersey Council, BSA 333',
      'E9FACA77-F499-4D13-AE87-5B0A432ED57B',
    ],
    ['Ohio River Valley Council 619', 'AD895B0D-FA0A-42B8-BB36-9FA8D140AD8E'],
    ['Old North State Council 070', '96668C74-218F-42B4-B74A-E80F8161EED2'],
    ['Pacific Skyline Council 031', '038739DB-902D-4ADE-AE03-9CCB3F047745'],
    ['Pathway to the Rockies 060', '95574A6B-C5AB-4BBD-A45B-879484E7DE4C'],
    ["Patriots' Path Council 358", '3EED73DC-D9C2-4513-8C16-170E55753D03'],
    ['Piedmont Council 420', '864B2074-EF5C-4543-B10F-C01E5AF00C34'],
    ['Pine Burr Area Council 304', 'EB7655A2-BDE2-4120-8147-59CF7AC4BF6F'],
    ['Prairielands 117', '2C421277-0A95-46A0-A7E2-148C8FD4F593'],
    ['Quivira Council, BSA 198', 'F0AFF30D-6D49-4D71-BA40-27B10CD04977'],
    ['Rio Grande Council 775', 'E9A2A79A-8E22-46D7-A94E-6CF52E020BA0'],
    ['Rip Van Winkle Council 405', 'E03D8D5B-94D6-466F-827E-7D9A655E1560'],
    ['San Diego Imperial Council 049', 'DEFB40A6-A5B0-4408-A6B5-80F3057A6F20'],
    ['Shenandoah Area Council 598', 'B746289F-B33C-464F-BDB0-28F94DDFD2BD'],
    ['Sioux Council 733', '40AA8078-BD3D-4795-8A4C-CC9A91900058'],
    ['Southeast Louisiana Council 214', '76D5C732-20CC-4253-A4B9-54F196A3756E'],
    ['Southwest Florida Council 088', 'B499648F-9D01-4289-ACB4-7E7F2CC6FF9F'],
    ['Theodore Roosevelt Council 386', 'D48C5EB6-F6E9-453A-9E83-D55DC0B01CD8'],
    ['Tukabatchee Area Council 005', '140A89F7-957F-4043-9D5F-E6B86E0B1D6C'],
    ['Tuscarora Council 424', '0511A402-C087-4C62-BA3F-66458B6FC7A6'],
    ['Verdugo Hills Council 058', '81004110-0609-45D8-B7A0-2E780751157A'],
    ['Virginia Headwaters Council 763', '85C57901-391B-4712-8E07-9C701A8720F7'],
    ['W.L.A.C.C. 051', '31EB7C9B-5C78-4760-A58A-815970403140'],
    [
      'Western New York Scout Council 380',
      'E3994435-68F9-4A10-96BA-831D078EC145',
    ],
    ['Westmoreland Fayette 512', 'C76FC5D9-0ED7-4585-8145-C1D1B9B1DB8E'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ].map(([_councilName, councilGuid]) => councilGuid),
);
