import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';

import {
  isLoadingSubmitRecharterSel,
  isPaymentModalVisibleSel,
  submitRecharterRequest,
} from '../../../duck';
import Payment from './Payment';
import styles from './PaymentModal.less';

const PaymentModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isPaymentModalVisibleSel);
  const isLoadingSubmitRecharter = useSelector(isLoadingSubmitRecharterSel);

  const handleOnContinue = () => {
    dispatch(submitRecharterRequest());
  };

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      onCancel={handleOnContinue}
    >
      <Payment
        onContinue={handleOnContinue}
        isLoading={isLoadingSubmitRecharter}
      />
    </Modal>
  );
};

export default PaymentModal;
