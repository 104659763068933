import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import {
  OPEN_EAGLE_SCOUT_APPLICATION,
  CLOSE_EAGLE_SCOUT_APPLICATION,
} from './actions';

const visible = (state = false, { type }) => {
  switch (type) {
    case OPEN_EAGLE_SCOUT_APPLICATION: {
      return true;
    }
    case CLOSE_EAGLE_SCOUT_APPLICATION: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const userId = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_EAGLE_SCOUT_APPLICATION: {
      return payload.userId;
    }
    default: {
      return state;
    }
  }
};

const youthName = (state = '', { type, payload }) => {
  switch (type) {
    case OPEN_EAGLE_SCOUT_APPLICATION: {
      return payload.youthName;
    }
    default: {
      return state;
    }
  }
};

export default resetOnLogoutReducer(
  combineReducers({ visible, userId, youthName }),
);
