import React from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-named-as-default
import Calendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { maxModeWidthSel } from '../../../duck';
import Modal from '../../Modal';
import disableDatesShape from '../disableDatesShape';
import styles from './DatePicker.less';

const getElementHeight = query => {
  const element = document.querySelector(query);
  if (element) {
    return element.clientHeight;
  }
  return 0;
};

class BaseDatePicker extends React.PureComponent {
  state = {
    calendarHeight: 500,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setCalendarHeight();
      const monthsListNode = this.getMonthsListNode();
      if (this.props.open) {
        return disableBodyScroll(monthsListNode);
      } else if (monthsListNode) {
        enableBodyScroll(monthsListNode);
      }
    });
  }

  componentDidUpdate({ maxWidth: prevMaxWidth, open: prevOpen }) {
    setTimeout(() => {
      if (
        prevMaxWidth !== this.props.maxWidth ||
        prevOpen !== this.props.open
      ) {
        const monthsListNode = this.getMonthsListNode();
        // The sentence bellow is odd, because if only `this.props.open` is set
        // in the iPhone X the calendar gets locked
        if (this.props.open || this.props.open === prevOpen) {
          this.calculateMonthListHeight();
          disableBodyScroll(monthsListNode);
        } else if (monthsListNode) {
          enableBodyScroll(monthsListNode);
        }
      }
      this.setCalendarHeight();
    });
  }

  calculateMonthListHeight = () => {
    const { modalHeaderHeight, calendarHeaderHeight, weekHeight } =
      this.getHeadersHeight();

    const isPortrait = window.innerHeight > window.innerWidth;

    const monthListHeight =
      window.innerHeight -
      modalHeaderHeight -
      weekHeight -
      (isPortrait && calendarHeaderHeight);

    const element = document.querySelector('.Cal__MonthList__root');
    if (element && element.style) {
      element.style['height'] = `${monthListHeight}px`;
    }
  };

  getDatesRange = () => {
    const { disableDates } = this.props;
    if (disableDates && typeof disableDates === 'object') {
      // Make sure it's a date object
      const { minDate: _minDate, maxDate: _maxDate } = disableDates;
      const minDate = _minDate ? moment(_minDate).toDate() : undefined;
      const maxDate = _maxDate
        ? moment(_maxDate === 'today' ? undefined : _maxDate).toDate()
        : undefined;
      return { minDate, maxDate };
    }

    if (disableDates === 'future') {
      return { maxDate: new Date() };
    } else if (disableDates === 'past') {
      return { minDate: new Date() };
    }
    return {};
  };

  setCalendarHeight = () => {
    const { modalHeaderHeight, calendarHeaderHeight, weekHeight } =
      this.getHeadersHeight();
    const { calendarHeight: currentCalendarHeight } = this.state;

    const sumHeaders = modalHeaderHeight + calendarHeaderHeight + weekHeight;
    const calendarHeight = window.innerHeight - sumHeaders;

    if (calendarHeight !== currentCalendarHeight) {
      this.setState({
        calendarHeight,
      });
    }
  };

  getHeadersHeight = () => {
    const modalHeaderHeight = getElementHeight(`.${styles.header}`);
    const calendarHeaderHeight = getElementHeight('.Cal__Header__root');
    const weekHeight = getElementHeight('.Cal__Weekdays__root');
    return { modalHeaderHeight, calendarHeaderHeight, weekHeight };
  };

  getMonthsListNode = () => document.querySelector('.Cal__MonthList__root');

  render() {
    const {
      dismiss,
      onDismiss,
      confirm,
      onConfirm,
      title,
      open,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maxWidth,
      ...rest
    } = this.props;
    const { maxDate, minDate } = this.getDatesRange();
    const { calendarHeight } = this.state;
    const windowInnerHeight = window.innerHeight;
    const windowInnerWidth = window.innerWidth;
    const isLandscape = windowInnerWidth > windowInnerHeight;

    return (
      <Modal
        visible={open}
        onCancel={onDismiss}
        className={styles.modal}
        wrapClassName={styles.modalWrap}
      >
        <div className={styles.header}>
          <span onClick={onDismiss} className={styles.headerButton}>
            {dismiss || (
              <FormattedMessage id="shared.MobileDatePicker.dismiss" />
            )}
          </span>
          <span>
            {title || <FormattedMessage id="shared.MobileDatePicker.title" />}
          </span>
          <span onClick={onConfirm} className={styles.headerButton}>
            {confirm || (
              <FormattedMessage id="shared.MobileDatePicker.confirm" />
            )}
          </span>
        </div>
        <Calendar
          className={cn(className, { [styles.landscape]: isLandscape })}
          maxDate={maxDate}
          max={maxDate}
          minDate={minDate}
          min={minDate}
          width={windowInnerWidth}
          height={calendarHeight}
          displayOptions={{
            layout: isLandscape ? 'landscape' : 'portrait',
          }}
          {...rest}
        />
      </Modal>
    );
  }
}

const mapState = state => ({
  maxWidth: maxModeWidthSel(state),
});

BaseDatePicker.propTypes = {
  ...Calendar.propTypes,
  dismiss: PropTypes.node,
  onDismiss: PropTypes.func,
  confirm: PropTypes.node,
  onConfirm: PropTypes.func,
  title: PropTypes.node,
  open: PropTypes.bool,
  disableDates: disableDatesShape,
};

BaseDatePicker.defaultProps = {
  selected: null,
};

export default connect(mapState)(BaseDatePicker);
