import { sortBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import memoize from 'memoize-one';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import { isCurrentPageSel } from '@location';
import { getIsDenChief } from '@modules/advancement/packRoster/utilsTyped';
import {
  ROUTE_OWN_ACTIVITY_LOGS, // constants
  dtoDateFormat,
  intl,
  isParentOrYouthMemberSel,
  packRosterItemsSel,
} from '@shared';
import { userDataSel, userIdSel } from '@user';
import { basicActionCreator, isIntGreaterThan } from '@utils';

import { memberDetailsSel } from '../../youthProfile/duck/selectors';
import {
  activityImportedFromTypes,
  activityTypesToAdvancedKeys,
  activityValueTypes,
  cstTimeZone,
  detailModes,
} from './constants';

const BIGGER_THAN_NUM = -1;
const isIntEqualOrGreater0 = isIntGreaterThan(-1);

const activityTypeAdvancedMap = {
  [activityValueTypes.HOURS]: 'Hours',
  [activityValueTypes.MILES]: 'Miles',
  [activityValueTypes.HIGHEST_POINT]: 'HighestPoint',
  [activityValueTypes.LOWEST_POINT]: 'LowestPoint',
  [activityValueTypes.NIGHTS]: 'Nights',
  [activityValueTypes.DAYS]: 'Days',
  [activityValueTypes.CONSERVATION_HOURS]: 'Hours',
  [activityValueTypes.LONG_CRUISE_DAYS]: 'Days',
  [activityValueTypes.FROST_POINTS]: 'FrostPoints',
  [activityValueTypes.EAGLE_HOURS]: 'Hours',
};

export const mapActivitiesToAdvanceValues = (persons, type) =>
  persons.reduce((advancedValues, person) => {
    const advancedValuesCopy = { ...advancedValues };
    const valuesLength = person.activityValues.length;
    for (let i = 0; i < valuesLength; i++) {
      const activity = person.activityValues[i];
      const activityKey = `${type}${
        activityTypeAdvancedMap[activity.activityValueTypeId]
      }`;
      advancedValuesCopy[activityKey] = {
        ...(advancedValues[activityKey] || {}),
        [`u${person.userId}`]: `${activity.activityValue}`,
      };
    }
    return advancedValuesCopy;
  }, {});

export const validateEqualAdvancedValues = advancedValuesObject => {
  let areSameAdvancedValues = true;
  const equalAdvancedValues = {};
  Object.keys(advancedValuesObject).forEach(advancedValueKey => {
    const personsObject = advancedValuesObject[advancedValueKey];
    const personsKeys = Object.keys(personsObject);
    const compareValue = personsObject[personsKeys[0]];
    const allAreEqual = personsKeys.every(
      personKey => compareValue === personsObject[personKey],
    );
    if (!allAreEqual) {
      areSameAdvancedValues = false;
    } else {
      equalAdvancedValues[advancedValueKey] = compareValue;
    }
  });
  return {
    areSameAdvancedValues,
    equalAdvancedValues,
  };
};

export const mapActivitiesToSaveObject = persons =>
  persons.reduce((acc, { activityValues, ...person }) => {
    const separatedActivities = activityValues.map(activityValue => ({
      ...person,
      ...activityValue,
    }));
    return [...acc, ...separatedActivities];
  }, []);

export const formatActivityTime = time => {
  let validTime = moment.isMoment(time) ? time : moment(time);
  return validTime.format('HH:mm');
};

export const getEditActivityObject = (
  activity,
  compareUserIds = [],
  canApprove = false,
) => {
  const {
    startDateTime,
    endDateTime,
    akelaStateId = undefined,
    collaborativeOrganizations = [],
    registeredAdults,
    registeredYouths,
    activityTypeId,
    nonRegisteredOrgParticipants: {
      nonRegisteredAdultCount = undefined,
      nonRegisteredAdultHours = undefined,
      nonRegisteredYouthCount = undefined,
      nonRegisteredYouthHours = undefined,
    },
  } = activity;
  const adultActivities = mapActivitiesToAdvanceValues(
    registeredAdults,
    'adult',
  );
  const youthActivities = mapActivitiesToAdvanceValues(
    registeredYouths,
    'youth',
  );
  const { areSameAdvancedValues, equalAdvancedValues } =
    validateEqualAdvancedValues({
      ...adultActivities,
      ...youthActivities,
    });
  const savedPersonDetails = [
    ...mapActivitiesToSaveObject(registeredAdults),
    ...mapActivitiesToSaveObject(registeredYouths),
  ];
  const personIdsInActivity = savedPersonDetails.map(person => person.userId);
  const addingNewPerson = compareUserIds.some(
    userId => !personIdsInActivity.includes(userId),
  );
  const basicValues = {};
  let sameBasicKeyForAdvanceValue = false;
  if (areSameAdvancedValues && !addingNewPerson) {
    const advancedKeysToBasicKeys = activityTypesToAdvancedKeys[activityTypeId];
    const advancedKeys = Object.keys(advancedKeysToBasicKeys);
    advancedKeys.forEach(advancedKey => {
      const basicValueKey = advancedKeysToBasicKeys[advancedKey];
      if (
        basicValues[basicValueKey] &&
        equalAdvancedValues[advancedKey] &&
        basicValues[basicValueKey] !== equalAdvancedValues[advancedKey]
      ) {
        sameBasicKeyForAdvanceValue = true;
        basicValues[basicValueKey] = undefined;
      } else if (equalAdvancedValues[advancedKey]) {
        basicValues[basicValueKey] = equalAdvancedValues[advancedKey];
      }
    });
  }
  const showBasicTab = sameBasicKeyForAdvanceValue
    ? false
    : areSameAdvancedValues;
  const isApproved = canApprove ? true : activity.isApproved;
  const startDate = moment(startDateTime);
  const endDate = moment(endDateTime);
  const formattedStartTime = formatActivityTime(startDate);
  const formattedEndTime = formatActivityTime(endDate);
  const allDay = formattedEndTime === '23:59' && formattedStartTime === '00:00';
  const processedActivityValues = {
    ...activity,
    ...adultActivities,
    ...youthActivities,
    ...basicValues,
    isApproved,
    savedPersonDetails,
    savedCollaborativeOrganizations: [...collaborativeOrganizations],
    startDate,
    startTime: startDate,
    endDate,
    endTime: endDate,
    allDay,
    akelaStateId: akelaStateId && `${akelaStateId}`,
    nonRegisteredAdultCount,
    nonRegisteredAdultHours,
    nonRegisteredYouthCount,
    nonRegisteredYouthHours,
    areSameAdvancedValues: addingNewPerson ? false : showBasicTab,
    collaborativeOrganizations: uniq(
      collaborativeOrganizations.map(item => item.collabOrgId),
    ),
  };
  return processedActivityValues;
};

export const getAdvancedValuesFromActivity = (activity = {}) => {
  const advancedNames = Object.values(activityTypeAdvancedMap);
  const youthAdvancedNames = advancedNames.map(item => `youth${item}`);
  const adultAdvancedNames = advancedNames.map(item => `adult${item}`);
  return pick(activity, [...youthAdvancedNames, ...adultAdvancedNames]);
};

export const isFutureStartDate = startDate =>
  startDate && moment(startDate).isAfter(moment().endOf('day'));

export const getFilteredFormValues = (activityType, values) => {
  const basicFieldsKeys = Object.values(
    activityTypesToAdvancedKeys[activityType],
  );
  const advancedFieldsKeys = Object.keys(
    activityTypesToAdvancedKeys[activityType],
  );
  return Object.keys(values)
    .filter(
      key =>
        !basicFieldsKeys.includes(key) && !advancedFieldsKeys.includes(key),
    )
    .reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});
};

const checkValidationType = (isFloat, value) => {
  if (value && value.trim()) {
    return isFloat
      ? Number(value) > BIGGER_THAN_NUM
      : isIntEqualOrGreater0(value);
  }
  return true;
};

export const activityValueRule = (
  isActive,
  validateFloat = true,
  required = true,
) =>
  isActive
    ? [
        {
          validator: (_, value) => checkValidationType(validateFloat, value),
          message: intl.formatMessage(
            {
              id: 'shared.form.error.greaterOrEqualThan',
            },
            {
              num: 0,
            },
          ),
        },
        {
          required: required,
          message: intl.formatMessage({
            id: 'shared.form.error.isRequired',
          }),
        },
      ]
    : [];
export const formHookValidationRules = (
  isActive,
  validateFloat = true,
  required = true,
) =>
  isActive
    ? {
        validate: {
          numberGreaterOrEqualThan: value =>
            checkValidationType(validateFloat, value),
        },
        required: {
          value: required,
          message: intl.formatMessage({
            id: 'shared.form.error.isRequired',
          }),
        },
      }
    : {
        validate: () => true,
        required: false,
      };

export const formHookValidationLessOrEqualRule = (diff, { isDayDate }) => ({
  validate: {
    [`${isDayDate ? 'days' : 'nights'}GreaterOrEqual`]: value =>
      Number(value.trim()) <= diff,
  },
});

export const isScoutbookImport = createdBy =>
  createdBy && createdBy.includes('Log Import')
    ? activityImportedFromTypes.SCOUTBOOK_IMPORT
    : false;

export const isGtfaImport = createdBy =>
  createdBy && createdBy.includes('GTFA Import')
    ? activityImportedFromTypes.GTFA_IMPORT
    : false;

export const getPersonsInActivity = selectedActivity => {
  const { registeredYouths = [], registeredAdults = [] } = selectedActivity;
  const personsInActivity = [...registeredYouths, ...registeredAdults];
  return {
    personsInActivity,
    userIds: personsInActivity.map(person => person.userId),
  };
};

export const normalizeAdvancedValues = (advancedValues = {}) =>
  Object.keys(advancedValues).reduce(
    (normalizedValues, userIdWithPrefix) => ({
      ...normalizedValues,
      [userIdWithPrefix.substr(1)]: advancedValues[userIdWithPrefix],
    }),
    {},
  );

export const groupYouthsAdults = persons => {
  const groups = {
    youths: [],
    adults: [],
  };

  persons.forEach(p => {
    if (p.isAdult) {
      groups.adults.push(p);
    } else {
      groups.youths.push(p);
    }
  });
  return groups;
};

export const groupYouthsAdultsParents = (
  persons,
  selectedUnitsHasDen = false,
) => {
  // Non-RSVP Edit Event Attendee List
  const groups = {
    youths: [],
    adults: [],
    parents: [],
    guests: [],
    denChiefs: [],
  };

  persons.forEach(p => {
    if (p.isGuest) return groups.guests.push(p);

    if (p.isAdult && !p.isParticipant) return groups.adults.push(p);

    if (selectedUnitsHasDen && getIsDenChief(p))
      return groups.denChiefs.push(p);

    groups.youths.push(p);
  });

  const sortedGroup = {
    youths: sortBy(groups.youths, 'lastName'),
    adults: sortBy(groups.adults, 'lastName'),
    parents: sortBy(groups.parents, 'lastName'),
    guests: sortBy(groups.guests, 'lastName'),
    denChiefs: sortBy(groups.denChiefs, 'lastName'),
  };

  return sortedGroup;
};

export const groupByRSVP = persons => {
  const groups = {
    attending: [],
    notAttending: [],
    maybe: [],
  };

  persons.forEach(p => {
    switch (p.rsvp) {
      //attending
      case true:
      case 'true':
        return groups.attending.push(p);
      //notAttending
      case false:
      case 'false':
        return groups.notAttending.push(p);
      // maybe
      default:
        return groups.maybe.push(p);
    }
  });

  const sortedGroup = {
    attending: groupYouthsAdultsParents(groups.attending),
    notAttending: groupYouthsAdultsParents(groups.notAttending),
    maybe: groupYouthsAdultsParents(groups.maybe),
  };

  return sortedGroup;
};

export const groupByYouthsAdults = persons => {
  const groups = {
    youths: [],
    adults: [],
  };

  persons.forEach(p => {
    p.isAdult ? groups.adults.push(p) : groups.youths.push(p);
  });

  return groups;
};

const extractPersonInfo = (person, noExistingUserId, isAdult = false) => {
  const {
    userId,
    profile: {
      memberId,
      personId,
      personGuid,
      fullName,
      firstName,
      middleName,
      lastName,
    } = {},
  } = person || {};
  return {
    userId: userId || noExistingUserId,
    memberId,
    personId,
    personGuid,
    personFullName: fullName,
    personShortFullName: `${firstName || ''} ${middleName || ''} ${
      lastName || ''
    }`,
    isAdult,
  };
};

export const createPersonsSelector = (userIdsSel, checkForMember = false) =>
  createSelector(
    userIdsSel,
    packRosterItemsSel,
    isParentOrYouthMemberSel,
    memberDetailsSel,
    userDataSel,
    userIdSel,
    state => isCurrentPageSel(state, ROUTE_OWN_ACTIVITY_LOGS),
    (
      userIds,
      rosterItems,
      isParentOrYouthMember,
      youthMemberDetails,
      loggedInUserData,
      loggedInUserId,
      isOwnLogsPage,
    ) => {
      if (isOwnLogsPage && checkForMember) {
        return [extractPersonInfo(loggedInUserData, loggedInUserId, true)];
      }
      if (isParentOrYouthMember && checkForMember) {
        return [extractPersonInfo(youthMemberDetails)];
      }
      const existingRosterItems = rosterItems.filter(({ userId }) =>
        userIds.includes(userId),
      );
      const loggedInUserFound = existingRosterItems.find(
        person => person.userId === loggedInUserId,
      );
      const personItems = existingRosterItems.map(
        ({
          userId,
          memberId,
          personId,
          personGuid,
          personFullName,
          personShortFullName,
          isAdult,
          isParent,
          isLeader,
          otherHighestRanksApproved,
          currentHighestRankApproved,
          dateOfBirth,
          age,
        }) => ({
          userId,
          memberId,
          personId,
          personGuid,
          personFullName,
          personShortFullName,
          isAdult,
          isParent,
          isLeader,
          dateOfBirth,
          age,
          otherHighestRanksApproved,
          currentHighestRankApproved,
        }),
      );
      if (userIds.includes(loggedInUserId) && !loggedInUserFound) {
        personItems.push(
          extractPersonInfo(loggedInUserData, loggedInUserId, true),
        );
      }
      return personItems;
    },
  );

const getPersonHoursDtoRecord = ({
  loggedInUserId,
  organizationGuid,
  personGuid,
  userId,
  memberId,
  isAdult,
  activityValueTypeId,
  activityValue,
  isApproved = true,
}) => ({
  organizationGuid,
  personGuid,
  userId: +userId,
  memberId: parseInt(memberId, 10),
  isAdult,
  activityValueTypeId: parseInt(activityValueTypeId, 10),
  activityValue: Number(parseFloat(activityValue).toFixed(2)),
  isApproved,
  leaderApprovedId: loggedInUserId,
  leaderApprovedDate: moment().format(dtoDateFormat),
});

const mapAdvancedValuesToDto = ({
  persons,
  organizationGuid,
  loggedInUserId,
  advancedValues,
  isApproved,
}) => {
  const personValues = [];
  persons.forEach(person => {
    Object.keys(advancedValues).forEach(activityValueTypeId => {
      const activityValues = advancedValues[activityValueTypeId];
      const personValue = activityValues[person.userId] || '0';
      if (personValue && personValue.trim()) {
        personValues.push(
          getPersonHoursDtoRecord({
            ...person,
            loggedInUserId,
            organizationGuid,
            activityValueTypeId,
            activityValue: personValue,
            isApproved,
          }),
        );
      }
    });
  });
  const [registeredAdults, registeredYouths] = partition(
    personValues,
    'isAdult',
  );
  return {
    registeredAdults,
    registeredYouths,
  };
};

const mapBasicValuesToDto = ({
  persons,
  organizationGuid,
  loggedInUserId,
  basicYouthValues,
  basicAdultValues,
  isApproved,
}) => {
  const advancedValues = {};
  persons.forEach(person => {
    const values = person.isAdult ? basicAdultValues : basicYouthValues;
    Object.keys(values).forEach(activityValueTypeId => {
      advancedValues[activityValueTypeId] =
        advancedValues[activityValueTypeId] || {};
      advancedValues[activityValueTypeId][person.userId] =
        values[activityValueTypeId];
    });
  });
  return mapAdvancedValuesToDto({
    persons,
    organizationGuid,
    loggedInUserId,
    advancedValues,
    isApproved,
  });
};

export const mapPersonValuesToDto = ({
  persons,
  organizationGuid,
  loggedInUserId,
  isAdvancedMode,
  basicYouthValues,
  basicAdultValues,
  advancedValues,
  isApproved,
}) => {
  if (isAdvancedMode) {
    return mapAdvancedValuesToDto({
      persons,
      organizationGuid,
      loggedInUserId,
      advancedValues,
      isApproved,
    });
  } else {
    return mapBasicValuesToDto({
      persons,
      organizationGuid,
      loggedInUserId,
      basicYouthValues,
      basicAdultValues,
      isApproved,
    });
  }
};

export const isFormChanged = (initialFormValues, currentFormValues) =>
  !isEqual(omitBy(initialFormValues, isNil), omitBy(currentFormValues, isNil));

export const setTime = (hour = 0, minute = 0, second = 0) =>
  moment().set({ hour, minute, second });

export const getDefaultDate = date => (date ? moment(date) : undefined);

export const getTimePlaceholder = ({ allDay, type = 'startTime' }) =>
  intl.formatMessage({
    id: `progress.Form.field.${allDay ? 'allDayEvent' : type}.placeholder`,
  });

export const fieldLessOrEqualRule = (diff, { isDayDate }) => [
  {
    validator: (_, value) => Number(value.trim()) <= diff,
    message: intl.formatMessage({
      id: `progress.Form.error.bigger${isDayDate ? 'Days' : 'Nights'}`,
    }),
  },
];

export const makeEndDateDisabler =
  fixedStart =>
  (end, disableFutureDays = true) => {
    if (!fixedStart || !end) {
      return false;
    }
    const endValue = end.valueOf();
    const fixedStartValue = fixedStart.valueOf();
    const todayValue = moment().valueOf();
    const isFutureDate = disableFutureDays ? endValue >= todayValue : false;
    return fixedStartValue > endValue || isFutureDate;
  };

export const getStartDateRule = (disableFutureDates = true) => [
  {
    validator: (_, date) =>
      disableFutureDates && date ? date.isBefore(moment()) : true,
    message: intl.formatMessage({
      id: 'progress.Form.field.startDate.error.futureRule',
    }),
  },
];

export const isJoinDetailMode = detailMode => detailModes.JOIN === detailMode;

export const filterActivityValuesByPersonGuid = (
  activityValues,
  comparePersonGuid,
) => {
  if (Array.isArray(activityValues)) {
    return activityValues.filter(
      ({ personGuid }) => personGuid === comparePersonGuid,
    );
  }
  return [];
};

const getScoutInfo = memoize(
  (userId, packRoster) =>
    packRoster.find(rosterItem => rosterItem.userId == userId) || {},
);

export const addPersonsMissingInfo = (persons, packRosterItems) =>
  persons.map(({ userId, ...person }) => {
    const { firstName, lastName, personShortFullName } = getScoutInfo(
      userId,
      packRosterItems,
    );
    return {
      ...person,
      userId,
      firstName: person.firstName || firstName,
      lastName: person.lastName || lastName,
      personShortFullName: person.personShortFullName || personShortFullName,
    };
  });

export const loadAndOpenActivityAction =
  actionConst =>
  ({ activityId, duplicateActivity, afterLoadCallBack, processBeforeOpen }) =>
    basicActionCreator(actionConst, {
      activityId,
      duplicateActivity,
      afterLoadCallBack,
      processBeforeOpen,
    });

// For some reason the api requires a different timezone when it is adding or updating an activity
// so we use UTC for update, and CST for create
export const formatApiDateTime = (date, converToUtc) => {
  const dateTime = moment(date);
  if (converToUtc) {
    dateTime.utc();
  } else {
    dateTime.tz(cstTimeZone);
  }
  return dateTime;
};

export const formatDate = date => moment(date).format(dtoDateFormat);

const getOnlyActivityValuesOfSameType = (
  personsArray = [],
  activityValueTypeId,
) =>
  personsArray.map(({ activityValues }) => {
    const activityObj = activityValues.find(
      obj => obj.activityValueTypeId === activityValueTypeId,
    );
    if (activityObj) {
      return activityObj.activityValue;
    }
  });

const hasActivityValues = (personsArray = []) =>
  personsArray.length &&
  personsArray.every(
    person => person.activityValues && person.activityValues.length,
  );

const hasSameValue = (valuesArray = []) =>
  valuesArray.length && valuesArray.every((value, _, arr) => arr[0] === value);

// If the time (days/nights) of the persons are the same it returns the basicValue setted on that time.
// If not, the attribute is omited in the returned object.
export const getCampingTimes = (youths = [], adults = []) => {
  const values = {};
  if (hasActivityValues(youths)) {
    const dayValues = getOnlyActivityValuesOfSameType(youths, 2); //2 is the id for days
    const nightValues = getOnlyActivityValuesOfSameType(youths, 3); //3 is the id for nights

    if (hasSameValue(dayValues)) {
      values.basicYouthDays = dayValues[0];
    }

    if (hasSameValue(nightValues)) {
      values.basicYouthNights = nightValues[0];
    }
  }

  if (hasActivityValues(adults)) {
    const dayValues = getOnlyActivityValuesOfSameType(adults, 2); //2 is the id for days
    const nightValues = getOnlyActivityValuesOfSameType(adults, 3); //3 is the id for nights

    if (hasSameValue(dayValues)) {
      values.basicAdultDays = dayValues[0];
    }

    if (hasSameValue(nightValues)) {
      values.basicAdultNights = nightValues[0];
    }
  }

  return values;
};

export const isActivityAllDay = (startTime, endTime) => {
  const validStartTime = formatActivityTime(startTime);
  const validEndTime = formatActivityTime(endTime);

  if (validStartTime && validEndTime) {
    const result = validStartTime === '00:00' && validEndTime === '23:59';

    return result;
  }

  return false;
};
