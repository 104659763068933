import { ProgramId } from '@shared/constants';

import { reportCodes, moduleName as rootModuleName } from '../constants';

export { reportCodes };

export const moduleName = 'basicReports';

export const moduleNamespace = `${rootModuleName}/${moduleName}`;

export const PERSONAL_REPORTS = 'PERSONAL_REPORTS';

const activitiesReports = [reportCodes.ACTIVITY_LOG, reportCodes.AUDIT_LOG];

export const validReports = Object.freeze({
  [ProgramId.CUB_SCOUT]: [
    reportCodes.CUB_SCOUT_HISTORY,
    reportCodes.PACK_RECOGNITION,
    reportCodes.ELIGIBILITY_REPORT,
    reportCodes.ATTENDANCE,
    ...activitiesReports,
  ],
  [ProgramId.BOY_SCOUT]: [
    reportCodes.INDIVIDUAL_SCOUTS_BSA,
    reportCodes.TROOP_RECOGNITION,
    reportCodes.ELIGIBILITY_REPORT,
    reportCodes.SCOUTS_BSA_HISTORY,
    reportCodes.ATTENDANCE,
    ...activitiesReports,
  ],
  [ProgramId.VENTURING]: [
    reportCodes.VENTURING_HISTORY,
    reportCodes.ELIGIBILITY_REPORT,
    reportCodes.ATTENDANCE,
    ...activitiesReports,
  ],
  [ProgramId.SEA_SCOUT]: [
    reportCodes.SEA_SCOUT_HISTORY,
    reportCodes.ELIGIBILITY_REPORT,
    reportCodes.ATTENDANCE,
    ...activitiesReports,
  ],
});

export const reportsV2 = [
  reportCodes.AUDIT_LOG,
  reportCodes.INDIVIDUAL_SCOUTS_BSA,
  reportCodes.CUB_SCOUT_HISTORY,
  reportCodes.SEA_SCOUT_HISTORY,
  reportCodes.ACTIVITY_LOG,
  reportCodes.VENTURING_HISTORY,
  reportCodes.SCOUTS_BSA_HISTORY,
];

export const individualReports = [
  reportCodes.INDIVIDUAL_SCOUTS_BSA,
  reportCodes.CUB_SCOUT_HISTORY,
  reportCodes.SEA_SCOUT_HISTORY,
  reportCodes.VENTURING_HISTORY,
  reportCodes.SCOUTS_BSA_HISTORY,
];

export const attendanceReportPositions = [
  'Troop Admin',
  'Key 3 Delegate',
  'Chartered Organization Rep.',
  'COR/CUR Delegate',
  'Council Unit Representative',
  'Chartered Organization Rep Delegate',
  'Unit Advancement',
  'Committee Chairman',
  'Scoutmaster',
  'Crew Admin',
  'Venturing Crew Advisor',
  'Ship Admin',
  'Skipper',
];
