import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/switchMap';

import { SET_ORGANIZATION, organizationGuidSel } from '@context';
import { isCurrentPageSel } from '@location';
import {
  ADVANCEMENT_HISTORY_RESPONSE,
  allReportSel,
  navigateToRoster,
  packRosterItemsSel,
  usersReportSel,
} from '@shared';
import { userIdSel } from '@user';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  ADVANCEMENT_REPORT_REQUEST,
  ADVANCEMENT_REPORT_RESPONSE,
  FETCH_ADVANCEMENT_REPORT,
  GENERATE_ADVANCEMENT_REPORT_REQUEST,
  GENERATE_ADVANCEMENT_REPORT_RESPONSE,
  OPEN_ADVANCEMENT_REPORT_MODAL,
  ROUTE_EDIT_ADVANCEMENT_REPORT,
  advancementReportError,
  advancementReportRequest,
  advancementReportResponse,
  generateAdvancementReportError,
  generateAdvancementReportResponse,
  loadAdvancementReport,
} from './advancementReport.actions';
import { poidSel, showAllUsersSel } from './advancementReport.selectors';
import services, {
  generateAdvancementReport,
} from './advancementReport.services';

const loadAdvancementReport$ = (action$, state$) =>
  action$
    .ofType(ADVANCEMENT_REPORT_REQUEST)
    .switchMap(({ payload: organizationGuid }) => {
      const roster = packRosterItemsSel(state$.value);

      return services
        .getAdvancementReport$(organizationGuid, roster)
        .map(advancementReportResponse)
        .catchAndReport(err => Observable.of(advancementReportError(err)));
    });

const fetchAdvancementReport$ = (action$, state$) =>
  action$
    .ofType(
      GENERATE_ADVANCEMENT_REPORT_RESPONSE,
      ADVANCEMENT_HISTORY_RESPONSE,
      FETCH_ADVANCEMENT_REPORT,
    )
    .switchMap(() => {
      const organizationGuid = organizationGuidSel(state$.value);
      return Observable.of(advancementReportRequest(organizationGuid));
    });

const loadSpecificAdvancementReport$ = (action$, state$) =>
  action$
    .ofType(OPEN_ADVANCEMENT_REPORT_MODAL, ADVANCEMENT_REPORT_RESPONSE)
    .filter(({ type }) => {
      const state = state$.value;
      if (type == ADVANCEMENT_REPORT_RESPONSE) {
        return !poidSel(state);
      }

      return !isCurrentPageSel(state, ROUTE_EDIT_ADVANCEMENT_REPORT);
    })
    .map(() => {
      const state = state$.value;
      const showAllUsers = showAllUsersSel(state);
      const userReports = usersReportSel(state);
      const allReports = allReportSel(state);

      return loadAdvancementReport(showAllUsers ? allReports : userReports);
    });

const cancelEditingReport$ = (action$, state$) =>
  action$
    .ofType(SET_ORGANIZATION)
    .filter(() => isCurrentPageSel(state$.value, ROUTE_EDIT_ADVANCEMENT_REPORT))
    .mapTo(navigateToRoster());

const generateAdvancementReportEpic$ = (action$, state$) =>
  action$
    .ofType(GENERATE_ADVANCEMENT_REPORT_REQUEST)
    .switchMap(({ payload }) => {
      const loggedInUserId = userIdSel(state$.value);
      return Observable.fromPromise(
        generateAdvancementReport({ ...payload, loggedInUserId }),
      )
        .map(generateAdvancementReportResponse)
        .catchAndReport(err =>
          Observable.of(generateAdvancementReportError(err)),
        );
    });

export default combineEpics(
  loadAdvancementReport$,
  fetchAdvancementReport$,
  loadSpecificAdvancementReport$,
  cancelEditingReport$,
  generateAdvancementReportEpic$,
);
