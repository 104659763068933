import React from 'react';

import memoize from 'memoize-one';
import PropTypes from 'prop-types';

import { SyncPendingButton } from '@offline';
import {
  PaginationTotal,
  Table,
  advancementFileStatuses,
  advancementStatuses,
  sorterPropTypes,
} from '@shared';
import { toggleArrayItem } from '@utils';

import { RunReportDropdown, activityItemsTypes } from '../../../../common';
import AdvancementFileRecordsInfo from '../AdvancementFileRecordsInfo';
import styles from './AdvancementHistoryTable.less';
import AdvancementHistoryTableFilter from './AdvancementHistoryTableFilter';
import columns from './advancementHistoryTableColumns';

class AdvancementHistoryTable extends React.PureComponent {
  handleTableChange = (pagination, filters, sorter) => {
    this.props.onSorterChange(sorter);
  };

  handleSelectedRowKeysChange = selectedRowKeys => {
    this.props.onSetSelectedRowsKeys(selectedRowKeys);
  };

  handleRowClick = record => () => {
    const { key, isAdvancementPendingApprovable } = record;
    const { selectedRowKeys } = this.props;
    const newKeys = toggleArrayItem(selectedRowKeys, key);

    if (isAdvancementPendingApprovable) {
      this.props.onSetSelectedRowsKeys(newKeys);
    }
  };

  getRowClassName = ({ type, status, isAdvancementPendingApprovable }) => {
    if (isAdvancementPendingApprovable) {
      return styles.checkboxRow;
    }

    return type === 'advancementFile' &&
      status === advancementFileStatuses.CANCELLED
      ? styles.cancelledFile
      : '';
  };

  getAllRowsIds = memoize(items => items.map(({ key }) => key));

  getColumns = memoize((sorter, expandedKeys, isOffline, cancellingFiles) => {
    const {
      onExpandedKeyChange,
      onCancelFile,
      onGoToYouthProfile,
      onGoToAdultProfile,
    } = this.props;

    return columns({
      sorter,
      expandedKeys,
      isOffline,
      cancellingFiles,
      onExpandedKeyChange,
      onCancelFile,
      onGoToYouthProfile,
      onGoToAdultProfile,
    });
  });

  getSelectedRows = memoize((items, selectedRowKeys) =>
    items.filter(({ key }) => selectedRowKeys.includes(key)),
  );

  renderPaginationTotal = () => {
    const { items, selectedRowKeys } = this.props;
    const total =
      items[activityItemsTypes.PENDING_APPROVAL].length +
      items[activityItemsTypes.HISTORY].length;
    return <PaginationTotal total={total} selected={selectedRowKeys.length} />;
  };

  renderTitle = () => {
    const { sorter, onSorterChange, items, selectedRowKeys, isOffline } =
      this.props;
    const selectedRows = this.getSelectedRows(
      items[activityItemsTypes.PENDING_APPROVAL],
      selectedRowKeys,
    );
    const areAnyAdvancementsSelected = selectedRows.length > 0;

    return (
      <React.Fragment>
        <RunReportDropdown
          disabled={isOffline || areAnyAdvancementsSelected}
          showAllUsers
        />
        <SyncPendingButton className={styles.syncPending} />
        <AdvancementHistoryTableFilter
          sorter={sorter}
          onSorterChange={onSorterChange}
        />
      </React.Fragment>
    );
  };

  renderExpandedRow = item => {
    if (
      item.type === 'advancementFile' &&
      item.status === advancementFileStatuses.PROCESSED
    ) {
      return (
        <AdvancementFileRecordsInfo
          item={item}
          onDownloadErrorLog={this.props.onDownloadFileErrorLog}
        />
      );
    }

    return '';
  };

  getItemsPresentation = memoize((items, loading) => {
    if (loading) {
      return [];
    }
    return items[activityItemsTypes.HISTORY];
  });

  render() {
    const {
      sorter,
      loading,
      items,
      expandedKeys,
      cancellingFiles,
      selectedRowKeys,
      isOffline,
      onSetSelectedRowsKeys,
      pendingApprovableItems,
    } = this.props;

    const itemsPresentation = this.getItemsPresentation(items, loading);

    const pagination = {
      showTotal: this.renderPaginationTotal,
    };

    const rowSelection = {
      selectedRowKeys,
      getCheckboxProps: record => ({
        disabled:
          !!activityItemsTypes[record.type] ||
          !record.isAdvancementPendingApprovable ||
          record.status === advancementStatuses.COUNSELOR_APPROVED,
      }),
      onChange: this.handleSelectedRowKeysChange,
      onSelectAll: isSelectAll => {
        const selectedItemsKeys = isSelectAll
          ? this.getAllRowsIds(pendingApprovableItems)
          : [];
        onSetSelectedRowsKeys(selectedItemsKeys);
      },
    };

    return (
      <div>
        <Table
          size="small"
          fixedLayout
          className={styles.table}
          loading={loading}
          rowKey="key"
          dataSource={itemsPresentation}
          pagination={pagination}
          title={this.renderTitle}
          rowSelection={rowSelection}
          columns={this.getColumns(
            sorter,
            expandedKeys,
            isOffline,
            cancellingFiles,
          )}
          rowClassName={this.getRowClassName}
          onRow={record => ({
            onClick: this.handleRowClick(record),
          })}
          sorter={sorter}
          expandedRowKeys={expandedKeys}
          expandedRowRender={this.renderExpandedRow}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

AdvancementHistoryTable.propTypes = {
  items: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  expandedKeys: PropTypes.array.isRequired,
  sorter: sorterPropTypes,
  cancellingFiles: PropTypes.object.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  pendingApprovableItems: PropTypes.array.isRequired,
  onSorterChange: PropTypes.func.isRequired,
  onExpandedKeyChange: PropTypes.func.isRequired,
  onCancelFile: PropTypes.func.isRequired,
  onDownloadFileErrorLog: PropTypes.func.isRequired,
  onSetSelectedRowsKeys: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  onGoToAdultProfile: PropTypes.func.isRequired,
};

export default AdvancementHistoryTable;
