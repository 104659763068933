import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button } from '@shared';
import { openEagleScoutApplication } from '../duck';
import styles from './EagleScoutApplicationModal.less';

function EagleScoutButton({ onClick }) {
  return (
    <Button
      className={styles.eagleScoutButton}
      fitText
      color="info"
      ghost
      noBorder
      onClick={onClick}
      uppercase={false}
    >
      <FormattedMessage id="shared.more" />
    </Button>
  );
}

EagleScoutButton.propTypes = {
  person: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, { person }) => ({
  onClick: () => dispatch(openEagleScoutApplication(person)),
});

export default connect(null, mapDispatch)(EagleScoutButton);
