import { combineReducers } from 'redux';

import { locationHash } from '@modules/shared';
import { resetOnLogoutReducer } from '@utils';

import {
  moduleName as advancementHistoryModuleName,
  reducers as advancementHistoryReducers,
} from '../advancementHistory';
import {
  moduleName as advancementUploadModuleName,
  reducers as advancementUploadReducers,
} from '../advancementUpload';
import {
  EDUCATION_RECORDS_ERROR,
  EDUCATION_RECORDS_REQUEST,
  EDUCATION_RECORDS_RESPONSE,
  EXTENDED_ADULT_ROSTER_REQUEST,
  EXTENDED_ADULT_ROSTER_RESPONSE,
  EXTENDED_PARENT_ROSTER_REQUEST,
  EXTENDED_PARENT_ROSTER_RESPONSE,
  EXTENDED_ROSTER_REQUEST,
  EXTENDED_YOUTH_ROSTER_RESPONSE,
  PARENT_ROSTER_RESPONSE,
  ROUTE_ADVANCEMENT,
  SEARCH_SCHOOLS_RESPONSE,
  tabNames,
} from '../common';
import { moduleName as addDenChiefModuleName } from '../denChiefSearch/constants';
import addDenChiefReducers from '../denChiefSearch/duck/reducers';
import {
  moduleName as packRosterModuleName,
  reducers as packRosterReducers,
} from '../packRoster';
import {
  moduleName as pendingItemsModuleName,
  reducers as pendingItemsReducers,
} from '../pendingItems';
import { purchaseOrdersModuleName } from '../purchaseOrder/constants';
import purchaseOrdersReducers from '../purchaseOrder/duck/reducers';
import { moduleName as subUnitsModuleName } from '../subUnits/constants';
import subUnitsReducers from '../subUnits/duck/reducers';
import {
  CLOSE_UNIT_REPORT_MODAL,
  OPEN_UNIT_REPORT_MODAL,
  REQUIREMENTS_BY_RANK_AND_VERSION_RESPONSE,
  SELECT_QE_RANK,
  SELECT_QE_VERSION,
  UNIT_ROSTER_REPORT_LOADING,
  VERSIONS_BY_RANK_RESPONSE,
} from './actions';

const validTabs = Object.values(tabNames);
const defaultTab = locationHash.getValid(validTabs, tabNames.ROSTER);
const activeTab = (state = defaultTab, { type, payload = {} }) => {
  switch (type) {
    case ROUTE_ADVANCEMENT: {
      const { hash } = payload;
      return hash || state;
    }
    default: {
      return state;
    }
  }
};

const unitRosterReportLoading = (state = false, { type, payload }) => {
  switch (type) {
    case UNIT_ROSTER_REPORT_LOADING: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const unitReportModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_UNIT_REPORT_MODAL: {
      return true;
    }
    case CLOSE_UNIT_REPORT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedUnitReportCode = (state = '', { type, payload }) => {
  switch (type) {
    case OPEN_UNIT_REPORT_MODAL: {
      return payload;
    }
    case CLOSE_UNIT_REPORT_MODAL: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const youthExtendedSBProfile = (state = [], { type, payload }) => {
  switch (type) {
    case EXTENDED_YOUTH_ROSTER_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const adultExtendedSBProfile = (state = [], { type, payload }) => {
  switch (type) {
    case EXTENDED_ADULT_ROSTER_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const parentExtendedSBProfile = (state = [], { type, payload }) => {
  switch (type) {
    case EXTENDED_PARENT_ROSTER_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const extendedSBProfileLoading = (state = false, { type }) => {
  switch (type) {
    case EXTENDED_ADULT_ROSTER_REQUEST:
    case EXTENDED_ROSTER_REQUEST: {
      return true;
    }
    case EXTENDED_YOUTH_ROSTER_RESPONSE:
    case EXTENDED_ADULT_ROSTER_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const extendedParentSBProfileLoading = (state = false, { type }) => {
  switch (type) {
    case EXTENDED_PARENT_ROSTER_REQUEST: {
      return true;
    }
    case EXTENDED_PARENT_ROSTER_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const parentItems = (state = [], { type, payload }) => {
  switch (type) {
    case PARENT_ROSTER_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const searchSchools = (state = [], { type, payload }) => {
  switch (type) {
    case SEARCH_SCHOOLS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const educationRecords = (state = [], { type, payload }) => {
  switch (type) {
    case EDUCATION_RECORDS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const educationRecordsLoading = (state = false, { type }) => {
  switch (type) {
    case EDUCATION_RECORDS_REQUEST: {
      return true;
    }
    case EDUCATION_RECORDS_ERROR:
    case EDUCATION_RECORDS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedQeRank = (state = '', { type, payload }) => {
  switch (type) {
    case SELECT_QE_RANK: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
const selectedQeVersion = (state = '', { type, payload }) => {
  switch (type) {
    case SELECT_QE_VERSION: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const availableVersions = (state = [], { type, payload }) => {
  switch (type) {
    case VERSIONS_BY_RANK_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
const requirementsByRankAndVersion = (state = [], { type, payload }) => {
  switch (type) {
    case REQUIREMENTS_BY_RANK_AND_VERSION_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  activeTab,
  unitRosterReportLoading,
  unitReportModalOpen,
  selectedUnitReportCode,
  youthExtendedSBProfile,
  adultExtendedSBProfile,
  parentExtendedSBProfile,
  extendedSBProfileLoading,
  extendedParentSBProfileLoading,
  parentItems,
  searchSchools,
  educationRecords,
  educationRecordsLoading,
  selectedQeRank,
  selectedQeVersion,
  availableVersions,
  requirementsByRankAndVersion,
  [advancementHistoryModuleName]: advancementHistoryReducers,
  [advancementUploadModuleName]: advancementUploadReducers,
  [packRosterModuleName]: packRosterReducers,
  [pendingItemsModuleName]: pendingItemsReducers,
  [purchaseOrdersModuleName]: purchaseOrdersReducers,
  [subUnitsModuleName]: subUnitsReducers,
  [addDenChiefModuleName]: resetOnLogoutReducer(addDenChiefReducers),
});

export default resetOnLogoutReducer(reducer);
