import groupBy from 'lodash/groupBy';
import memoize from 'memoize-one';
import { createSelector } from 'reselect';

import {
  ProgramId,
  advancementHistoryModuleName,
  advancementModuleName,
  advancementTypes,
  historyItemsTypes,
} from '../../constants';
import {
  advancementStatus,
  canApproveAdvancement,
  isEagleScoutRank,
} from '../../utils';
import {
  adventuresSel,
  allAwardsSel,
  meritBadgesSel,
  packRosterItemsSel,
  ranksSel,
  shouldFilterUsersBySubunitsSel,
  unitTypeIdSel,
} from './general.selectors';

const advancementModuleSel = state => state[advancementModuleName];

export const advancementHistoryModuleSel = state =>
  advancementModuleSel(state)[advancementHistoryModuleName];
export const advancementHistoryLoadingSel = state =>
  advancementHistoryModuleSel(state).loading;
export const advancementHistoryRawItemsSel = state =>
  advancementHistoryModuleSel(state).items;

const getScoutInfo = memoize(
  (userId, packRoster) =>
    packRoster.find(rosterItem => rosterItem.userId == userId) || {},
);

const getAdvancementDictInfo = (item, advancements) => {
  const { advancementType } = item;
  const [awards, ranks, meritBadges, adventures] = advancements;
  const advancementList =
    advancementTypes.AWARDS == advancementType
      ? awards
      : advancementTypes.RANKS == advancementType
      ? ranks
      : advancementTypes.MERIT_BADGES == advancementType
      ? meritBadges
      : adventures;

  const { imageUrl100, programId, unitTypeId, level, id } =
    advancementList.find(({ id }) => id == item.id) || {};
  const newProgramId =
    advancementType == advancementTypes.ADVENTURES
      ? ProgramId.CUB_SCOUT
      : programId || unitTypeId;

  return {
    imgUrl: imageUrl100,
    level: level || id,
    programId: newProgramId,
  };
};

export const advancementHistoryItemsSel = createSelector(
  advancementHistoryRawItemsSel,
  packRosterItemsSel,
  unitTypeIdSel,
  shouldFilterUsersBySubunitsSel,
  allAwardsSel,
  ranksSel,
  meritBadgesSel,
  adventuresSel,
  (
    items,
    packRoster,
    unitTypeId,
    shouldFilterUsersBySubunits,
    ...advancements
  ) =>
    items
      .map(record => {
        const scoutInfo = getScoutInfo(record.userId, packRoster);
        const { isAdult, isYouth, firstName, lastName, personShortFullName } =
          scoutInfo;
        return {
          isAdult: isAdult === undefined ? !isYouth : isAdult,
          ...record,
          ...getAdvancementDictInfo(record, advancements),
          firstName: record.firstName || firstName,
          lastName: record.lastName || lastName,
          personShortFullName:
            record.personShortFullName || personShortFullName,
          name: record.name || personShortFullName,
          ...(shouldFilterUsersBySubunits && {
            positions: scoutInfo.positions,
          }),
        };
      })
      .filter(record => {
        if (record.isAdult && record.type === historyItemsTypes.ADVANCEMENT) {
          return false;
        }
        if (!advancementStatus.isCompleted(record)) {
          return true;
        }
        const {
          currentHighestRankApproved: highestHierarchicalAdvancement,
          otherHighestRanksApproved,
        } = getScoutInfo(record.userId, packRoster);

        const scoutsBSAHighestRank = (otherHighestRanksApproved || []).find(
          ({ programId }) => programId == ProgramId.BOY_SCOUT,
        );

        return canApproveAdvancement({
          unitTypeId,
          scoutsBSAHighestRank,
          highestHierarchicalAdvancement,
          advancement: record,
          treatEagleScoutAsRegular: true,
        });
      })
      .map(record => ({
        ...record,
        isAdvancementPendingApprovable:
          record.isAdvancementPending && !isEagleScoutRank(record),
      })),
);

export const pendingAdvancementsSel = createSelector(
  advancementHistoryItemsSel,
  items => items.filter(({ isAdvancementPending }) => isAdvancementPending),
);
export const pendingApprovableAdvancementsSel = createSelector(
  pendingAdvancementsSel,
  items =>
    items.filter(
      ({ isAdvancementPendingApprovable, isDeclined }) =>
        isAdvancementPendingApprovable && !isDeclined,
    ),
);
export const pendingAdvancementsByTypeSel = createSelector(
  pendingApprovableAdvancementsSel,
  items => {
    const grouped = groupBy(items, item => {
      if (item.type === 'activity') return 'activities';
      return item.advancementType;
    });
    return grouped;
  },
);
export const historyItemsSel = createSelector(
  advancementHistoryItemsSel,
  items => items.filter(({ isAdvancementPending }) => !isAdvancementPending),
);

export const approvedAdvancementsSel = createSelector(
  advancementHistoryItemsSel,
  items =>
    items.filter(
      ({ status, type }) => status === 'approved' && type === 'advancement',
    ),
);
