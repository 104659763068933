import React from 'react';

import PropTypes from 'prop-types';

import BSAColorLogo from '../../../../assets/images/BSAColorLogo.svg';
import styles from './BSALogo.less';

const BSALogo = ({ className, ...props }) => (
  <span className={className} {...props}>
    <BSAColorLogo className={styles.icon} />
  </span>
);

BSALogo.propTypes = {
  className: PropTypes.string,
};

export default BSALogo;
