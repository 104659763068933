import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/switchMap';
import { combineEpics } from 'redux-observable';

import '@utils/rxjs.add.operator.catchAndReport';
import {
  USER_PERMISSIONS_REQUEST,
  userPermissionsResponse,
  userPermissionsError,
  AVAILABLE_ROLES_REQUEST,
  availableRolesResponse,
  availableRolesError,
  AVAILABLE_USERS_REQUEST,
  availableUsersResponse,
  availableUsersError,
} from './actions';
import services from './services';

const loadUserPermissions$ = action$ =>
  action$.ofType(USER_PERMISSIONS_REQUEST).switchMap(() =>
    services
      .getUserPermissions$()
      .map(userPermissionsResponse)
      .catchAndReport(err => Observable.of(userPermissionsError(err))),
  );

const loadUserRoles$ = action$ =>
  action$.ofType(AVAILABLE_ROLES_REQUEST).switchMap(() =>
    services
      .getRoles$()
      .map(availableRolesResponse)
      .catchAndReport(err => Observable.of(availableRolesError(err))),
  );

const loadUsers$ = action$ =>
  action$.ofType(AVAILABLE_USERS_REQUEST).switchMap(() =>
    services
      .getUsers$()
      .map(availableUsersResponse)
      .catchAndReport(err => Observable.of(availableUsersError(err))),
  );

export default combineEpics(loadUserPermissions$, loadUserRoles$, loadUsers$);
