import React, { useMemo, useState } from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { Col, SearchInput, T, S, intl } from '@shared';
import MoveUsersList from '../../MoveUsersList';
import parentStyles from '../AddSubUnitForm.less';
import styles from './AddSubUnitDesktop.less';
import { RosterScout } from '@modules/advancement/duck/types';
import { Row } from 'bsa-ui';

type Props = {
  attendeeLabel: 'adults' | 'youths' | 'denChiefs';
  onAddPerson: (userId: number) => void;
  onRemovePerson: (userId: number) => void;
  selectedUserIds: Set<number>;
  persons: RosterScout[];
  subUnitTypeName: string;
  isDen?: boolean;
};

const AddSubUnitDesktop: React.FC<Props> = ({
  attendeeLabel,
  onAddPerson,
  persons,
  onRemovePerson,
  subUnitTypeName,
  selectedUserIds,
  isDen = false,
}) => {
  const [searchStr, setSearchStr] = useState('');

  const { selectedUsers, availableUsers } = useMemo(
    () =>
      persons.reduce(
        (acc, p) => {
          const { userId, memberId } = p;
          if (!userId || !memberId) return acc;
          if (selectedUserIds.has(userId)) {
            return {
              ...acc,
              selectedUsers: [...acc.selectedUsers, p],
            };
          }
          return {
            ...acc,
            availableUsers: [...acc.availableUsers, p],
          };
        },
        {
          selectedUsers: [] as RosterScout[],
          availableUsers: [] as RosterScout[],
        },
      ),
    [persons, selectedUserIds],
  );

  return (
    <Row className={styles.wrapper}>
      <Row gutter={24}>
        <Col span={12} />
        <Col span={12}>
          <SearchInput
            onChange={setSearchStr}
            placeholder={intl.formatMessage({
              id: 'packRoster.AddSubUnit.AddSubUnitForm.search',
            })}
            size="large"
            bordered
          />

          {isDen && (
            <div>
              <S size="6" className={parentStyles.note}>
                <FormattedHTMLMessage id="subUnits.form.note" />
              </S>
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <T colored size="5" className={parentStyles.upperCase}>
            <FormattedMessage
              id={`packRoster.AddSubUnit.AddSubUnitForm.available.${attendeeLabel}`}
            >
              {(text: string) => `${text} (${availableUsers.length})`}
            </FormattedMessage>
          </T>
          <MoveUsersList
            onSelect={onAddPerson}
            persons={availableUsers}
            searchStr={searchStr}
            selectIcon={
              <div className={parentStyles.addPersonsIcon}>
                <AddCircleIcon />
              </div>
            }
            emptyListTitle={
              <FormattedMessage
                id={`packRoster.AddSubUnit.AddSubUnitForm.emptyTitle.${subUnitTypeName}`}
              />
            }
            emptyListDsc={
              <FormattedMessage
                id={`packRoster.AddSubUnit.AddSubUnitForm.emptySelectDsc.${attendeeLabel}`}
              />
            }
          />
        </Col>
        <Col span={12}>
          <T colored size="5" className={parentStyles.upperCase}>
            <FormattedMessage
              id={`packRoster.AddSubUnit.AddSubUnitForm.selected.${attendeeLabel}.${subUnitTypeName}`}
            >
              {(text: string) => `${text} (${selectedUsers.length})`}
            </FormattedMessage>
          </T>
          <MoveUsersList
            onSelect={onRemovePerson}
            persons={selectedUsers}
            selectIcon={<CancelIcon />}
            searchStr={searchStr}
            emptyListTitle={
              <FormattedMessage
                id={`packRoster.AddSubUnit.AddSubUnitForm.emptyTitle.${subUnitTypeName}`}
              />
            }
            emptyListDsc={
              <FormattedMessage
                id={`packRoster.AddSubUnit.AddSubUnitForm.emptyDeselectDsc.${attendeeLabel}`}
              />
            }
          />
        </Col>
      </Row>
    </Row>
  );
};

export default AddSubUnitDesktop;
