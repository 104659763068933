import React, { forwardRef, useMemo } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getPreferredName } from '@modules/shared/utils/preferredName';

import PersonAvatar from '../PersonAvatar';
import Select from '../Select';
import { Col, Row } from '../bsaComponents';
import styles from './PersonSelector.less';
import { groupYouthsAdultsParents } from './utils';

const { Option } = Select;

const filterOption = (textInput, { props: optionProps }) =>
  optionProps.text === 'label'
    ? optionProps
    : optionProps.text.toLowerCase().includes(textInput.toLowerCase());

const PersonSelector = forwardRef(function PersonSelectorForward(
  {
    id,
    loggedInUserData,
    loggedInUserId,
    notAllowedToAdd,
    notAllowedToRemove,
    onDeselectPerson,
    onSelectPerson,
    onToggle,
    persons,
    selectedUnitsHasDen,
  },
  ref,
) {
  const foundloggedInUser = useMemo(
    () => persons.find(person => person.userId === loggedInUserId),
    [persons, loggedInUserId],
  );
  const options = useMemo(() => {
    const allowedPersons = [...persons];

    if (!foundloggedInUser && loggedInUserData) {
      const {
        fullName: personFullName,
        firstName,
        lastName,
        nickName,
      } = loggedInUserData.profile;
      allowedPersons.push({
        userId: loggedInUserId,
        personFullName,
        firstName,
        lastName,
        nickName,
        isAdult: true,
      });
    }

    const updatedList = groupYouthsAdultsParents(
      allowedPersons,
      selectedUnitsHasDen,
    );
    return [
      <Option
        className={styles.default}
        value="Youths"
        key={'label'}
        text="label"
        disabled
      >
        <strong>Youths</strong>
      </Option>,
      _.orderBy(updatedList.youths, ['lastName', 'firstName']).map(person => {
        const { userId, memberId, personFullName, isAdult } = person;
        const preferredName = getPreferredName(person);

        if (
          userId &&
          memberId &&
          !(notAllowedToRemove || []).includes(userId)
        ) {
          return (
            <Option
              key={userId}
              value={userId}
              text={`${personFullName} ${preferredName}`}
            >
              <Row type="flex" align="middle" className={styles.person}>
                <Col>
                  <PersonAvatar className={styles.avatar} isAdult={isAdult} />
                </Col>
                <Col className={styles.personName}>{preferredName}</Col>
              </Row>
            </Option>
          );
        }
      }),
      selectedUnitsHasDen ? (
        <Option
          className={styles.default}
          value="Den Chiefs"
          key={'label'}
          text="label"
          disabled
        >
          <strong>Den Chiefs</strong>
        </Option>
      ) : null,
      _.orderBy(updatedList.denChiefs, ['lastName', 'firstName']).map(
        person => {
          const { userId, memberId, personFullName, isAdult } = person;
          const preferredName = getPreferredName(person);

          if (
            userId &&
            memberId &&
            !(notAllowedToRemove || []).includes(userId)
          ) {
            return (
              <Option
                key={userId}
                value={userId}
                text={`${personFullName} ${preferredName}`}
              >
                <Row type="flex" align="middle" className={styles.person}>
                  <Col>
                    <PersonAvatar className={styles.avatar} isAdult={isAdult} />
                  </Col>
                  <Col className={styles.personName}>{preferredName}</Col>
                </Row>
              </Option>
            );
          }
        },
      ),
      <Option
        className={styles.default}
        value="Leaders"
        key={'label'}
        text="label"
        disabled
      >
        <strong>Leaders</strong>
      </Option>,
      _.orderBy(updatedList.adults, ['lastName', 'firstName']).map(person => {
        const { userId, memberId, personFullName, isAdult } = person;
        const preferredName = getPreferredName(person);

        if (
          userId &&
          memberId &&
          !(notAllowedToRemove || []).includes(userId)
        ) {
          return (
            <Option
              key={userId}
              value={userId}
              text={`${personFullName} ${preferredName}`}
            >
              <Row type="flex" align="middle" className={styles.person}>
                <Col>
                  <PersonAvatar className={styles.avatar} isAdult={isAdult} />
                </Col>
                <Col className={styles.personName}>{preferredName}</Col>
              </Row>
            </Option>
          );
        }
      }),
      <Option
        className={styles.default}
        value="Parents/Guardians"
        key={'label'}
        text="label"
        disabled
      >
        <strong>Parents/Guardians</strong>
      </Option>,
      _.orderBy(updatedList.parents, ['lastName', 'firstName']).map(person => {
        const {
          userId,
          memberId,
          personFullName,
          firstName,
          lastName,
          isAdult,
          nickName,
        } = person;
        const preferredName = `${nickName || firstName} ${lastName}`;

        if (
          userId &&
          memberId &&
          !(notAllowedToRemove || []).includes(userId)
        ) {
          return (
            <Option
              key={userId}
              value={userId}
              text={`${personFullName} ${preferredName}`}
            >
              <Row type="flex" align="middle" className={styles.person}>
                <Col>
                  <PersonAvatar className={styles.avatar} isAdult={isAdult} />
                </Col>
                <Col className={styles.personName}>{preferredName}</Col>
              </Row>
            </Option>
          );
        }
      }),
    ];
  }, [
    foundloggedInUser,
    loggedInUserData,
    loggedInUserId,
    notAllowedToRemove,
    persons,
    selectedUnitsHasDen,
  ]);

  return (
    <Select
      className={styles.dropDownContainer}
      filterOption={filterOption}
      optionFilterProp="children"
      id={id}
      keepOpenOnSelect
      mode="multiple"
      autoClearSearchValue={false}
      onDeselect={onDeselectPerson}
      onDropdownVisibleChange={onToggle}
      onModalClose={onToggle}
      onSelect={onSelectPerson}
      ref={ref}
      showSearch
      value={notAllowedToAdd}
    >
      {options}
    </Select>
  );
});

PersonSelector.propTypes = {
  id: PropTypes.string,
  loggedInUserData: PropTypes.object.isRequired,
  loggedInUserId: PropTypes.number.isRequired,
  notAllowedToAdd: PropTypes.array,
  notAllowedToRemove: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  onDeselectPerson: PropTypes.func.isRequired,
  onSelectPerson: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  persons: PropTypes.array,
  selectedUnitsHasDen: PropTypes.bool,
};

PersonSelector.defaultProps = {
  id: 'personSelector',
  notAllowedToAdd: [],
  notAllowedToRemove: false,
  persons: [],
};

export default PersonSelector;
