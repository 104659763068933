import React from 'react';

import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { TableFilterCard, sorterPropTypes } from '@shared';

import { AdvancementTypesFilterSection } from '../../../../common';
import {
  activityTypesFilterSel,
  advancementHistoryRequestIfNotLoaded,
  advancementStatusFilterSel,
  advancementTypesFilterSel,
  fieldsVisibilitySel,
  peopleFilterSel,
  setFieldsVisibility,
  setFilterCardFilters,
  showAdvancementFilesSel,
  updatedDateFilterSel,
} from '../../../duck';
import {
  ActivityTypesFilterSection,
  AdvancementStatusFilterSection,
  AdvancementsFilesSection,
  PeopleFilterSection,
  SortSection,
  UpdatedDateFilterSection,
  VisibilitySection,
} from './Sections';

const { Collapse, Panel, PanelGroup } = TableFilterCard;

class AdvancementHistoryFilterCard extends React.PureComponent {
  state = {
    advancementTypes: this.props.advancementTypes,
    advancementStatus: this.props.advancementStatus,
    activityTypes: this.props.activityTypes,
    updatedDate: this.props.updatedDate,
    people: this.props.people,
    sorter: this.props.sorter,
    fieldsVisibility: this.props.fieldsVisibility,
    activePanel: 'advancementTypes',
    showAdvancementFiles: this.props.showAdvancementFiles,
  };

  componentDidUpdate = prevProps => {
    const { activityTypes, people, updatedDate } = this.props;
    if (
      !isEqual(activityTypes, prevProps.activityTypes) ||
      !isEqual(people, prevProps.people) ||
      updatedDate !== prevProps.updatedDate
    ) {
      this.props.onLoadHistory();
    }
  };

  handleActivePanelChange = activePanel => {
    this.setState({ activePanel });
  };

  handleSorterChange = sorter => {
    this.setState({ sorter });
  };

  setFilter = name => value => {
    this.setState({ [name]: value });
  };

  handleAdvancementTypesChange = this.setFilter('advancementTypes');
  handleAdvancementStatusChange = this.setFilter('advancementStatus');
  handleActivityTypeChange = this.setFilter('activityTypes');
  handleUpdatedDateChange = this.setFilter('updatedDate');
  handlePeopleChange = this.setFilter('people');
  handleFieldsVisibilityChange = this.setFilter('fieldsVisibility');
  handleShowAdvancementFilesChange = this.setFilter('showAdvancementFiles');

  handleOpenChange = open => {
    if (open) {
      this.resetFiltersState();
    }
  };

  resetFiltersState = () => {
    const {
      advancementTypes,
      advancementStatus,
      activityTypes,
      updatedDate,
      people,
      sorter,
      fieldsVisibility,
      showAdvancementFiles,
    } = this.props;
    this.setState({
      advancementTypes,
      advancementStatus,
      activityTypes,
      updatedDate,
      people,
      sorter,
      fieldsVisibility,
      showAdvancementFiles,
    });
  };

  handleSubmit = () => {
    this.props.onSetFilters(this.state);
    this.props.onSorterChange(this.state.sorter);
    this.props.onSetFieldsVisibility(this.state.fieldsVisibility);
  };

  renderFilterContent = () => {
    const { program } = this.props;
    const {
      advancementTypes,
      advancementStatus,
      activityTypes,
      updatedDate,
      activePanel,
      sorter,
      fieldsVisibility,
      showAdvancementFiles,
      people,
    } = this.state;

    return (
      <Collapse activeKey={activePanel} onChange={this.handleActivePanelChange}>
        <PanelGroup key="columns">
          <Panel
            key="advancementTypes"
            header={
              <FormattedMessage id="advancement.AdvancementHistory.filter.section.advancements" />
            }
          >
            <AdvancementTypesFilterSection
              program={program}
              values={advancementTypes}
              onChange={this.handleAdvancementTypesChange}
            />
          </Panel>
          <Panel
            key="importedFiles"
            header={
              <FormattedMessage id="advancement.AdvancementHistory.filter.section.importedFiles" />
            }
          >
            <AdvancementsFilesSection
              value={showAdvancementFiles}
              onChange={this.handleShowAdvancementFilesChange}
            />
          </Panel>
        </PanelGroup>
        <Panel
          key="advancementStatus"
          header={
            <FormattedMessage id="advancement.AdvancementHistory.filter.section.advancementStatus" />
          }
        >
          <AdvancementStatusFilterSection
            values={advancementStatus}
            onChange={this.handleAdvancementStatusChange}
          />
        </Panel>
        <Panel
          key="people"
          header={
            <FormattedMessage id="advancement.AdvancementHistory.filter.section.people" />
          }
        >
          <PeopleFilterSection
            values={people}
            onChange={this.handlePeopleChange}
          />
        </Panel>
        <Panel
          key="activityTypes"
          header={
            <FormattedMessage id="advancement.AdvancementHistory.filter.section.activities" />
          }
        >
          <ActivityTypesFilterSection
            values={activityTypes}
            onChange={this.handleActivityTypeChange}
          />
        </Panel>
        <Panel
          key="updatedDate"
          header={
            <FormattedMessage id="advancement.AdvancementHistory.filter.section.updatedDate" />
          }
        >
          <UpdatedDateFilterSection
            value={updatedDate}
            onChange={this.handleUpdatedDateChange}
          />
        </Panel>
        <Panel
          key="sort"
          header={<FormattedMessage id="shared.sortBy" />}
          mobileOnly
        >
          <SortSection sorter={sorter} onChange={this.handleSorterChange} />
        </Panel>
        <Panel
          key="visibility"
          header={<FormattedMessage id="shared.visibility" />}
          mobileOnly
        >
          <VisibilitySection
            values={fieldsVisibility}
            onChange={this.handleFieldsVisibilityChange}
          />
        </Panel>
      </Collapse>
    );
  };

  render() {
    const { children } = this.props;
    const { updatedDate } = this.props;

    return (
      <TableFilterCard
        submitId="qa_results"
        content={this.renderFilterContent()}
        onOpenChange={this.handleOpenChange}
        onSubmit={this.handleSubmit}
      >
        {children(updatedDate)}
      </TableFilterCard>
    );
  }
}

AdvancementHistoryFilterCard.propTypes = {
  // from parent
  children: PropTypes.func.isRequired,
  sorter: sorterPropTypes,
  onSorterChange: PropTypes.func.isRequired,
  // from redux
  updatedDate: PropTypes.string.isRequired,
  advancementTypes: PropTypes.object.isRequired,
  advancementStatus: PropTypes.object.isRequired,
  activityTypes: PropTypes.object.isRequired,
  people: PropTypes.object.isRequired,
  program: PropTypes.string.isRequired,
  fieldsVisibility: PropTypes.object.isRequired,
  showAdvancementFiles: PropTypes.bool.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onLoadHistory: PropTypes.func.isRequired,
  onSetFieldsVisibility: PropTypes.func.isRequired,
};

const mapState = state => ({
  updatedDate: updatedDateFilterSel(state),
  advancementTypes: advancementTypesFilterSel(state),
  advancementStatus: advancementStatusFilterSel(state),
  activityTypes: activityTypesFilterSel(state),
  people: peopleFilterSel(state),
  program: programSel(state),
  fieldsVisibility: fieldsVisibilitySel(state),
  showAdvancementFiles: showAdvancementFilesSel(state),
});

const mapDispatch = dispatch => ({
  onSetFilters: filters => dispatch(setFilterCardFilters(filters)),
  onLoadHistory: () => dispatch(advancementHistoryRequestIfNotLoaded()),
  onSetFieldsVisibility: fieldsVisibility =>
    dispatch(setFieldsVisibility(fieldsVisibility)),
});

export default connect(mapState, mapDispatch)(AdvancementHistoryFilterCard);
