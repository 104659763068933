import { gtm } from '@shared';

import { unapproveAdvancementModuleNamespace as namespace } from '../../common';

export const CLOSE_UNAPPROVE_MODAL = `${namespace}/CLOSE_UNAPPROVE_MODAL`;
export const OPEN_UNAPPROVE_MODAL = `${namespace}/OPEN_UNAPPROVE_MODAL`;
export const closeUnapproveModal = () => ({
  type: CLOSE_UNAPPROVE_MODAL,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const openUnapproveModal = (advancement, youthName) => ({
  type: OPEN_UNAPPROVE_MODAL,
  payload: { advancement, youthName },
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const UNAPPROVE_ADVANCEMENT_REQUEST = `${namespace}/UNAPPROVE_ADVANCEMENT_REQUEST`;
export const UNAPPROVE_ADVANCEMENT_RESPONSE = `${namespace}/UNAPPROVE_ADVANCEMENT_RESPONSE`;
export const UNAPPROVE_ADVANCEMENT_DEFERRED = `${namespace}/UNAPPROVE_ADVANCEMENT_DEFERRED`;
export const UNAPPROVE_ADVANCEMENT_ERROR = `${namespace}/UNAPPROVE_ADVANCEMENT_ERROR`;
export const unapproveAdvancementRequest = advancement => ({
  type: UNAPPROVE_ADVANCEMENT_REQUEST,
  payload: advancement,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const unapproveAdvancementResponse = advancementType => ({
  type: UNAPPROVE_ADVANCEMENT_RESPONSE,
  payload: advancementType,
});
export const unapproveAdvancementDeferred = () => ({
  type: UNAPPROVE_ADVANCEMENT_DEFERRED,
});
export const unapproveAdvancementError = error => ({
  type: UNAPPROVE_ADVANCEMENT_ERROR,
  payload: error,
});
