import React from 'react';
import PropTypes from 'prop-types';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import { Select as BsaSelect } from 'bsa-ui';

import styles from './BasicSelectMobile.less';

function BasicSelectMobile({ onFocus, ...props }) {
  return (
    <BsaSelect
      dropdownClassName={styles.hiddenDropdown}
      onFocus={onFocus}
      {...props}
      showSearch={false} //Prevents Mobile devices (ESPECIALLY IPHONESS!!) to search using this Component.
      // Prevent BSASelect from triggering onBlur event
      // when modal is opened
      onBlur={undefined}
    />
  );
}

BasicSelectMobile.propTypes = {
  onFocus: PropTypes.func.isRequired,
};

export default BasicSelectMobile;
