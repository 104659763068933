import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { ProgressButton, setSelectedActivity } from '../../../common';
import { datesShape } from '../../constants';
import { openServiceProject } from '../../duck';

class ServiceProjectButton extends React.PureComponent {
  handleClick = () => {
    const { onOpenServiceProject, onClick } = this.props;
    onOpenServiceProject();
    if (onClick) {
      onClick();
    }
  };

  render() {
    const { title, disabled, className, children, replaceButton } = this.props;
    const button = replaceButton ? (
      React.cloneElement(replaceButton, { onClick: this.handleClick })
    ) : (
      <ProgressButton
        color="scouting-warm-gray"
        noBorder
        fitText
        title={title}
        disabled={disabled}
        icon={<AccessTimeIcon />}
        className={className}
        onClick={this.handleClick}
      >
        {children || (
          <FormattedMessage id="service.ServiceProjectButton.label" />
        )}
      </ProgressButton>
    );
    return button;
  }
}

ServiceProjectButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  allowRemovePersons: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  dates: datesShape,
  onClick: PropTypes.func,
  onOpenServiceProject: PropTypes.func.isRequired,
  replaceButton: PropTypes.node,
};

const mapDispatch = (
  dispatch,
  { dates, allowAddPersons, activityData, allowRemovePersons },
) => ({
  onOpenServiceProject: () => {
    dispatch(openServiceProject(dates, allowAddPersons, allowRemovePersons));
    activityData && dispatch(setSelectedActivity(activityData));
  },
});

export default connect(null, mapDispatch)(ServiceProjectButton);
