import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useForm, FormProvider } from 'react-hook-form';
import pick from 'lodash/pick';

import {
  ActivityTypeIcon,
  Row,
  Col,
  Card,
  T,
  Form,
  Button,
  Spin,
  intl,
  activityTypeForActivityTypeId,
  activityCalendarTypes,
  emptyObj,
  activityTypeIds,
  packRosterItemsSel,
} from '@shared';
import { validateForm } from '@utils';

import { AddPersonButton } from '../../../common';
import { activitiesConfig } from '../../constants';
import {
  // selectors
  userIdsSel,
  isLoadingSaveActivitySel,
  selectedActivityDataSel,
  getActivityByIdLoadingSel,
  // actions
  deselectPerson,
  selectPerson,
  saveActivityRequest,
  getActivityByIdRequest,
  setSelectedActivityData,
  clearPeople,
} from '../../duck';
import PersonList from './PersonList';
import ActivityLocation from './ActivityLocation';
import ActivityInformation from './ActivityInformation';
import ActivityDetails from './ActivityDetails';
import ActivityRsvp from './ActivityRsvp';
import ActivityTabs from './ActivityTabs';
import styles from './CreateActivityForm.less';

const { Section: CardSection } = Card;

const CreateActivityForm = ({
  activityId,
  activityTypeId: propsActivityTypeId,
  disableFutureDays,
  onSaveSuccess,
  onSaveFailure,
  form,
  data,
  clone,
}) => {
  const dispatch = useDispatch();
  const selectedUserIds = useSelector(userIdsSel);
  const isLoading = useSelector(isLoadingSaveActivitySel);
  const getActivityByIdLoading = useSelector(getActivityByIdLoadingSel);
  const persons = useSelector(packRosterItemsSel);
  const [isAddPersonsVisible, setIsAddPersonsVisible] = useState();
  const methods = useForm();
  const selectedActivityData = useSelector(selectedActivityDataSel);
  const { setFieldsValue, getFieldsValue, resetFields } = form;
  const { trigger, getValues, setValue, reset } = methods;
  const activityTypeId =
    propsActivityTypeId || selectedActivityData.activityTypeId;
  const activityType =
    activityTypeId && activityTypeForActivityTypeId[activityTypeId];
  const fieldsValue = getFieldsValue();
  const isFutureDate =
    fieldsValue.startDate &&
    moment(fieldsValue.startDate).isAfter(moment().endOf('day'));

  const activityTypeTranslation = useMemo(() => {
    const isServiceProject =
      activityTypeId === activityTypeIds.SERVICE_PROJECTS;
    const translationId = isServiceProject
      ? activityCalendarTypes.SERVICE_PROJECT
      : activityType;
    return intl
      .formatMessage({ id: `shared.ActivityType.${translationId}` })
      .toUpperCase();
  }, [activityType, activityTypeId]);

  const validate = useCallback(() => validateForm(form), [form]);

  const onToggleView = useCallback(
    visible => {
      setIsAddPersonsVisible(visible);
    },
    [setIsAddPersonsVisible],
  );

  const handleDeselectPerson = useCallback(
    userId => {
      dispatch(deselectPerson(userId));
    },
    [dispatch],
  );

  const handleSelectPerson = useCallback(
    userId => {
      dispatch(selectPerson(userId));
    },
    [dispatch],
  );

  const activityConfig = activitiesConfig[activityTypeId] || emptyObj;

  const {
    activityInfo = emptyObj,
    activityDetails = emptyObj,
    activityTabs = emptyObj,
  } = activityConfig;

  const handleSave = useCallback(async () => {
    const validActivityValues = await trigger();
    const activityData = await validate();
    if (validActivityValues && activityData) {
      const isCalendarActivity = true;
      const activityValues = isFutureDate ? {} : getValues();
      const saveActivityData = {
        ...activityData,
        ...activityValues,
      };
      dispatch(
        saveActivityRequest(
          activityTypeId,
          saveActivityData,
          isCalendarActivity,
          onSaveSuccess,
          onSaveFailure,
        ),
      );
    }
  }, [
    dispatch,
    trigger,
    validate,
    getValues,
    onSaveSuccess,
    onSaveFailure,
    activityTypeId,
    isFutureDate,
  ]);

  useEffect(() => {
    if (selectedActivityData) {
      resetFields();
      const allowedValues = pick(
        selectedActivityData,
        Object.keys(getFieldsValue()),
      );
      setFieldsValue(allowedValues);
      Object.keys(getValues()).forEach(fieldKey => {
        const fieldValue = selectedActivityData[fieldKey];
        setValue(fieldKey, fieldValue);
      });
    }
  }, [
    selectedActivityData,
    setFieldsValue,
    getFieldsValue,
    resetFields,
    reset,
    setValue,
    getValues,
  ]);

  useEffect(() => {
    if (activityId) {
      dispatch(getActivityByIdRequest(activityId, clone));
    } else if (data) {
      dispatch(setSelectedActivityData(data));
    }
  }, [dispatch, data, activityId, clone]);

  useEffect(() => () => dispatch(clearPeople()), [dispatch]);

  if (getActivityByIdLoading || !activityTypeId) {
    return <Card loading />;
  }

  return (
    <Spin
      spinning={isLoading}
      className={styles.spinner}
      size="large"
      delay={200}
    >
      <FormProvider {...methods}>
        <Row type="flex">
          <Col
            xs={24}
            lg={{ span: 6, order: 1 }}
            className={styles.stickySidebar}
          >
            <Button
              onClick={handleSave}
              type="primary"
              shape="round"
              size="large"
              shadow
              block
            >
              <FormattedMessage id="shared.save" />
            </Button>
            {!isFutureDate && (
              <Card rounded margin>
                <CardSection
                  title={
                    <Row type="flex" justify="space-between">
                      {!isAddPersonsVisible && (
                        <Col>
                          <T size="3" colored>
                            <FormattedMessage id="progress.activity.CreateActivityForm.registeredPersons" />
                          </T>
                        </Col>
                      )}
                      <Col
                        className={cn({
                          [styles.dropdownVisible]: isAddPersonsVisible,
                        })}
                      >
                        <AddPersonButton
                          persons={persons}
                          selectedPersons={selectedUserIds}
                          onDeselectPerson={handleDeselectPerson}
                          onSelectPerson={handleSelectPerson}
                          onToggleView={onToggleView}
                          canRemove
                        >
                          <div className={styles.addButton}>
                            <T size="3" colored>
                              <FormattedMessage id="progress.activity.CreateActivityForm.add" />{' '}
                              <AddCircleIcon />
                            </T>
                          </div>
                        </AddPersonButton>
                      </Col>
                    </Row>
                  }
                >
                  <PersonList onDeselectPerson={handleDeselectPerson} />
                </CardSection>
              </Card>
            )}
          </Col>
          <Col xs={24} lg={{ span: 18, order: 0 }}>
            <Card rounded margin>
              <React.Fragment>
                <CardSection
                  title={
                    <div className="d-flex items-center">
                      <ActivityTypeIcon
                        activityTypeId={activityTypeId}
                        circleSize={50}
                        wrapInCircle
                        className={styles.activityIcon}
                      />
                      <T size="3" noMargin colored>
                        <FormattedMessage
                          id="progress.activity.CreateActivityForm.information"
                          values={{ name: activityTypeTranslation }}
                        />
                      </T>
                    </div>
                  }
                >
                  <ActivityInformation
                    form={form}
                    disableFutureDays={disableFutureDays}
                    {...activityInfo}
                  />
                </CardSection>
                <CardSection
                  title={
                    <T size="3" colored>
                      <FormattedMessage
                        id="progress.activity.CreateActivityForm.location"
                        values={{ name: activityTypeTranslation }}
                      />
                    </T>
                  }
                >
                  <ActivityLocation form={form} />
                </CardSection>
                <CardSection
                  title={
                    <T size="3" colored>
                      <FormattedMessage
                        id="progress.activity.CreateActivityForm.details"
                        values={{ name: activityTypeTranslation }}
                      />
                    </T>
                  }
                >
                  <Row type="flex">
                    <Col xs={24}>
                      <ActivityDetails
                        form={form}
                        activityTypeId={activityTypeId}
                        {...activityDetails}
                      />
                    </Col>
                  </Row>
                </CardSection>
                <CardSection
                  title={
                    <T size="3" colored>
                      <FormattedMessage
                        id="progress.activity.CreateActivityForm.rsvp"
                        values={{ name: activityTypeTranslation }}
                      />
                    </T>
                  }
                >
                  <Row type="flex">
                    <Col xs={24}>
                      <ActivityRsvp form={form} />
                    </Col>
                  </Row>
                </CardSection>
                <CardSection
                  title={
                    <T size="3" colored>
                      <FormattedMessage
                        id="progress.activity.CreateActivityForm.metrics"
                        values={{ name: activityTypeTranslation }}
                      />
                    </T>
                  }
                >
                  {isFutureDate ? (
                    <div>
                      <i>
                        <FormattedMessage id="progress.activity.CreateActivityForm.cannotRecordActivity" />
                      </i>
                    </div>
                  ) : (
                    <ActivityTabs
                      activityTypeId={activityTypeId}
                      form={form}
                      isFutureDate={isFutureDate}
                      showTabDescription
                      {...activityTabs}
                    />
                  )}
                </CardSection>
              </React.Fragment>
            </Card>
          </Col>
        </Row>
      </FormProvider>
    </Spin>
  );
};

CreateActivityForm.propTypes = {
  activityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activityTypeId: PropTypes.oneOf(Object.values(activityTypeIds)),
  form: PropTypes.object.isRequired,
  disableFutureDays: PropTypes.bool,
  onSaveSuccess: PropTypes.func,
  onSaveFailure: PropTypes.func,
  data: PropTypes.object,
  clone: PropTypes.bool,
};

CreateActivityForm.defaultProps = {
  onSaveSuccess: () => {},
  onSaveFailure: () => {},
};

export default Form.create()(CreateActivityForm);
