import React from 'react';
import Img from '@modules/shared/components/Img';
import AdultIcon from '../../../../../../assets/images/Adult.svg';
import YouthIcon from '../../../../../../assets/images/Youth.svg';

import styles from './Placeholder.less';

type Props = {
  pictureUrl?: string;
  isAdult: boolean;
};

const Placeholder: React.FC<Props> = ({ pictureUrl, isAdult }) => {
  if (pictureUrl)
    return <Img src={pictureUrl} className={styles.personAvatar} />;

  if (isAdult)
    return <AdultIcon className={styles.placeholderIcon} role="img" />;

  return <YouthIcon className={styles.placeholderIcon} role="img" />;
};

export default Placeholder;
