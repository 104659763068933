import moment from 'moment';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, startWith, tap } from 'rxjs/operators';

import { organizationGuidSel } from '@context';
import {
  advancementTypes,
  intl, // navigateToRoster, // navigateToPurchaseOrderDetails,
} from '@modules/shared';
import { toastService } from '@modules/toasts';
import { catchAndReport } from '@modules/utils/rxjs/operators';
import { userIdSel as loggedInUserIdSel } from '@user';

// import { POStatusTypes } from '../constants';
import {
  ADD_ITEMS_TO_CART_ERROR,
  ADD_ITEMS_TO_CART_REQUEST,
  ADD_ITEMS_TO_CART_RESPONSE,
  ADD_TO_PURCHASE_ORDER_REQUEST,
  CREATE_PURCHASE_ORDER_REQUEST,
  DELETE_FROM_ORDER_AND_RETRY,
  DELETE_FROM_PURCHASE_ORDER_REQUEST,
  DELETE_PURCHASE_ORDER_REQUEST,
  FETCH_ALL_PURCHASE_ORDERS_REQUEST,
  FETCH_DETAILS_AND_RETRY_REQUEST,
  FETCH_PURCHASE_ORDER_DETAILS_REQUEST,
  SET_CART_TOKEN,
  UPDATE_PURCHASE_ORDER_REQUEST,
  addItemsToCartError,
  addItemsToCartRequest,
  addToPurchaseOrderError,
  addToPurchaseOrderResponse,
  createPurchaseOrderError,
  createPurchaseOrderResponse,
  deleteFromOrderAndRetryError,
  deleteFromPurchaseOrderError,
  deleteFromPurchaseOrderResponse,
  deletePurchaseOrderError,
  deletePurchaseOrderResponse,
  fetchAllPurchaseOrdersError,
  fetchAllPurchaseOrdersRequest,
  fetchAllPurchaseOrdersResponse,
  fetchDetailsAndRetryError,
  fetchDetailsAndRetryRequest,
  fetchPurchaseOrderDetailsError,
  fetchPurchaseOrderDetailsRequest,
  fetchPurchaseOrderDetailsResponse,
  setCreatePurchaseOrderLoading,
  setPurchaseOrderId,
  setUpdatePurchaseOrderLoading,
  updatePurchaseOrderError,
  updatePurchaseOrderResponse,
} from './actions';
import { itemsForCartSel } from './selectors';
import { cartService, esbServices } from './services';

const guestCartEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADD_ITEMS_TO_CART_REQUEST),
    mergeMap(() => {
      const state = state$.value;
      const items = itemsForCartSel(state);

      // first create a cart and get the cart token
      return cartService.createGuestCart$().pipe(
        mergeMap(cartToken =>
          // then update cart with desired items
          cartService.updateGuestCart$({ cartToken, items }).pipe(
            map(data => ({
              type: ADD_ITEMS_TO_CART_RESPONSE,
              payload: data,
            })),
            catchAndReport(err => {
              let errorMessage =
                err.response?.data?.message || err.message || 'Unknown error';
              return of(addItemsToCartError(errorMessage));
            }),
            // save cart token for later navigation to scoutshop
            startWith({ type: SET_CART_TOKEN, payload: cartToken }),
          ),
        ),
        catchError(error =>
          of({
            type: ADD_ITEMS_TO_CART_ERROR,
            payload: error,
            error: true,
          }),
        ),
      );
    }),
  );

const fetchAllPurchaseOrdersEpic$ = (action$, state$) =>
  action$.ofType(FETCH_ALL_PURCHASE_ORDERS_REQUEST).switchMap(() => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);
    return esbServices
      .getAllPurchaseOrders$(organizationGuid)
      .map(fetchAllPurchaseOrdersResponse)
      .catchAndReport(err => Observable.of(fetchAllPurchaseOrdersError(err)));
  });

const createPurchaseOrderEpic$ = (action$, state$) =>
  action$.ofType(CREATE_PURCHASE_ORDER_REQUEST).switchMap(request => {
    const { payload: items } = request;
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);
    const userId = loggedInUserIdSel(state);
    const POdetails = {
      dateCreated: moment().format('YYYY-MM-DD'),
      userId: userId,
    };
    return esbServices
      .createPurchaseOrderWithItems$(POdetails, organizationGuid, items)
      .mergeMap(response =>
        Observable.concat(
          Observable.of(createPurchaseOrderResponse),
          Observable.of(setPurchaseOrderId(response.id)),
          Observable.of(fetchPurchaseOrderDetailsRequest(response.id)),
          Observable.of(fetchAllPurchaseOrdersRequest()),
          Observable.of(setCreatePurchaseOrderLoading(false)),
        ),
      )
      .catchAndReport(err => Observable.of(createPurchaseOrderError(err)));
  });

const fetchPurchaseOrderDetailsEpic$ = (action$, state$) =>
  action$
    .ofType(FETCH_PURCHASE_ORDER_DETAILS_REQUEST)
    .switchMap(({ payload }) => {
      const state = state$.value;
      const poid = payload;
      const organizationGuid = organizationGuidSel(state);
      return esbServices
        .getPurchaseOrderDetails$(organizationGuid, poid)
        .mergeMap(response =>
          Observable.concat(
            Observable.of(fetchPurchaseOrderDetailsResponse(response)),
            Observable.of(setCreatePurchaseOrderLoading(false)),
          ),
        )
        .catchAndReport(err =>
          Observable.of(fetchPurchaseOrderDetailsError(err)),
        );
    });

const updatePurchaseOrderEpic$ = (action$, state$) =>
  action$.ofType(UPDATE_PURCHASE_ORDER_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const { id, data } = payload;
    const organizationGuid = organizationGuidSel(state);

    return esbServices.updatePurchaseOrder$(data, organizationGuid, id).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'PurchaseOrder.PODetailsPage.success',
          }),
        ),
      ),
      mergeMap(response =>
        Observable.from([
          updatePurchaseOrderResponse(response),
          fetchPurchaseOrderDetailsRequest(id),
          fetchAllPurchaseOrdersRequest(),
          setUpdatePurchaseOrderLoading(false),
          // data.status === POStatusTypes.CLOSED ? navigateToRoster() : null,
        ]),
      ),
      catchAndReport(err => Observable.of(updatePurchaseOrderError(err))),
    );
  });

const deleteItemFromOrderEpic$ = (action$, state$) =>
  action$
    .ofType(DELETE_FROM_PURCHASE_ORDER_REQUEST)
    .switchMap(({ payload }) => {
      const state = state$.value;
      const { poid, data } = payload;
      const organizationGuid = organizationGuidSel(state);

      return esbServices
        .deleteFromPurchaseOrder$(poid, organizationGuid, data)
        .pipe(
          tap(() =>
            toastService.success(
              intl.formatMessage({
                id: 'PurchaseOrder.PODetailsPage.success',
              }),
            ),
          ),
          mergeMap(response =>
            Observable.from([
              deleteFromPurchaseOrderResponse(response),
              fetchPurchaseOrderDetailsRequest(poid),
              setUpdatePurchaseOrderLoading(false),
            ]),
          ),
          catchAndReport(err =>
            Observable.of(deleteFromPurchaseOrderError(err)),
          ),
        );
    });

const addToPurchaseOrderEpic$ = (action$, state$) =>
  action$.ofType(ADD_TO_PURCHASE_ORDER_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const { poid, selectedItems } = payload;
    const organizationGuid = organizationGuidSel(state);
    const data = selectedItems.map(item => {
      const dataItem = {
        userId: item.userId,
        advancementType: item.advancementType,
        advancementId: item.id,
      };
      if (item.advancementType === advancementTypes.AWARDS) {
        dataItem.userAdvancementId = item.userAwardId;
      }
      return dataItem;
    });

    return esbServices.addToPurchaseOrder$(poid, organizationGuid, data).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'PurchaseOrder.PODetailsPage.success',
          }),
        ),
      ),
      mergeMap(response =>
        Observable.from([
          addToPurchaseOrderResponse(response),
          fetchPurchaseOrderDetailsRequest(poid),
          setUpdatePurchaseOrderLoading(false),
        ]),
      ),
      catchAndReport(err => Observable.of(addToPurchaseOrderError(err))),
    );
  });

const deleteFromOrderAndRetryEpic$ = (action$, state$) =>
  action$.ofType(DELETE_FROM_ORDER_AND_RETRY).switchMap(({ payload }) => {
    const state = state$.value;
    const { poid, data } = payload;
    const organizationGuid = organizationGuidSel(state);
    return esbServices
      .deleteFromPurchaseOrder$(poid, organizationGuid, data)
      .mergeMap(response =>
        Observable.from([
          deleteFromPurchaseOrderResponse(response),
          fetchDetailsAndRetryRequest(poid),
        ]),
      )
      .catchAndReport(err => Observable.of(deleteFromOrderAndRetryError(err)));
  });

const fetchDetailsAndRetryEpic$ = (action$, state$) =>
  action$.ofType(FETCH_DETAILS_AND_RETRY_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const poid = payload;
    const organizationGuid = organizationGuidSel(state);
    return esbServices
      .getPurchaseOrderDetails$(organizationGuid, poid)
      .mergeMap(response =>
        Observable.from([
          fetchPurchaseOrderDetailsResponse(response),
          addItemsToCartRequest(),
        ]),
      )
      .catchAndReport(err => Observable.of(fetchDetailsAndRetryError(err)));
  });

const deletePurchaseOrderEpic$ = (action$, state$) =>
  action$.ofType(DELETE_PURCHASE_ORDER_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);
    const { id } = payload;

    return esbServices.deletePurchaseOrder$(id, organizationGuid).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'PurchaseOrder.PODetailsPage.delete.success',
          }),
        ),
      ),
      mergeMap(response =>
        Observable.concat(
          of(deletePurchaseOrderResponse(response)),
          of(fetchAllPurchaseOrdersRequest()),
          of(setUpdatePurchaseOrderLoading(false)),
        ),
      ),
      catchAndReport(err => Observable.of(deletePurchaseOrderError(err))),
    );
  });

export default combineEpics(
  guestCartEpic$,
  fetchAllPurchaseOrdersEpic$,
  createPurchaseOrderEpic$,
  fetchPurchaseOrderDetailsEpic$,
  updatePurchaseOrderEpic$,
  deleteItemFromOrderEpic$,
  addToPurchaseOrderEpic$,
  deleteFromOrderAndRetryEpic$,
  fetchDetailsAndRetryEpic$,
  deletePurchaseOrderEpic$,
);
