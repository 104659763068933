import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import cn from 'classnames';

import { Card, FlexSpacer, T, CollapseDetails } from '@shared';
import { validTypesByPerson } from '../../../constants';
import WarningMessage from '../WarningMessage';
import PersonList from './PersonsList';
import styles from './PersonsListMobile.less';

class PersonsListMobile extends React.Component {
  renderWarnings = () => {
    const {
      ineligibleYouthUsersIds,
      ineligibleAdultsUsersIds,
      adultsWarningMessage,
      youthWarningMessage,
    } = this.props;
    const hasYouthErrors = ineligibleYouthUsersIds.length !== 0;
    const hasAdultErrors = ineligibleAdultsUsersIds.length !== 0;
    const youthMessage = youthWarningMessage || (
      <FormattedMessage id="progress.PersonsListMobile.warning.placeholder" />
    );
    const adultsMessage = adultsWarningMessage || (
      <FormattedMessage id="progress.PersonsListMobile.warning.placeholder" />
    );

    return (
      <div>
        {hasYouthErrors && <WarningMessage>{youthMessage}</WarningMessage>}
        {hasAdultErrors && <WarningMessage>{adultsMessage}</WarningMessage>}
      </div>
    );
  };

  render() {
    const {
      open,
      handleListToggleClick,
      stats,
      type,
      persons,
      counter,
      fullWidth,
      ineligibleYouthUsersIds,
      ineligibleAdultsUsersIds,
      onRemove,
      listId,
      personClassName,
      showAdvStatus,
      personsNotAllowedToRemove,
    } = this.props;
    const numOfPersons = persons.length;
    const ineligiblePersonsIds = [
      ...ineligibleAdultsUsersIds,
      ...ineligibleYouthUsersIds,
    ];
    const numOfIneligiblePersons = ineligiblePersonsIds.length;
    const shouldHideToggle = numOfPersons === 1;
    const shouldShowArrow = !shouldHideToggle;

    const isOpen = open || shouldHideToggle;

    const header = (
      <div className={styles.header}>
        <div>
          <T.Responsive>
            <FormattedMessage
              id={`progress.PersonsListMobile.${type}Summary`}
            />
          </T.Responsive>
          {stats}
        </div>
        <FlexSpacer />
        <span
          className={cn(
            { [styles.noDisplay]: counter || shouldHideToggle },
            styles.viewButton,
          )}
          onClick={handleListToggleClick}
        >
          <FormattedMessage id="progress.PersonsListMobile.view" />
        </span>
        <span
          className={cn({
            [styles.noDisplay]: !counter,
          })}
        >
          <ErrorIcon
            className={cn(styles.iconWarning, {
              [styles.noDisplay]: numOfIneligiblePersons === 0,
            })}
          />
          <div className={styles.numOfPersonsShort}>
            <FormattedMessage
              id="progress.PersonsListMobile.toBeApprovedCountShort"
              values={{
                eligible: numOfPersons - numOfIneligiblePersons,
                total: numOfPersons,
              }}
            />
          </div>
        </span>
      </div>
    );

    return (
      <Card
        className={cn(styles.mobileContainer, {
          [styles.fullWidth]: fullWidth,
        })}
        shadow
      >
        <CollapseDetails
          open={isOpen}
          showArrow={shouldShowArrow}
          header={header}
          onChange={handleListToggleClick}
          panelClassName={styles.panel}
        >
          {this.renderWarnings()}
          <PersonList
            persons={persons}
            onRemove={onRemove}
            ineligibleYouthUsersIds={ineligibleYouthUsersIds}
            ineligibleAdultsUsersIds={ineligibleAdultsUsersIds}
            listId={listId}
            personClassName={personClassName}
            showAdvStatus={showAdvStatus}
            personsNotAllowedToRemove={personsNotAllowedToRemove}
          />
        </CollapseDetails>
      </Card>
    );
  }
}

PersonsListMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  persons: PropTypes.array.isRequired,
  personsNotAllowedToRemove: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  ineligibleYouthUsersIds: PropTypes.array,
  ineligibleAdultsUsersIds: PropTypes.array,
  youthWarningMessage: PropTypes.node,
  adultsWarningMessage: PropTypes.node,
  stats: PropTypes.node,
  counter: PropTypes.bool,
  type: PropTypes.oneOf(validTypesByPerson),
  fullWidth: PropTypes.bool,
  listId: PropTypes.string,
  onRemove: PropTypes.func,
  handleListToggleClick: PropTypes.func.isRequired,
  personClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  showAdvStatus: PropTypes.bool.isRequired,
};

PersonsListMobile.defaultProps = {
  ineligibleUsersIds: [],
  counter: false,
  fullWidth: false,
};

export default PersonsListMobile;
