import React from 'react';

import { useSelector } from 'react-redux';

import { EDIT_SUB_UNITS, SHOW_QUICK_ENTRY } from '@config';
import { programSel } from '@context/duck/selectorsTyped';
import { AddIndividualDropdown } from '@modules/advancement/common/components/AddIndividualDropdown';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { Program } from '@shared';

import { QuickEntryDropdown, RunReportDropdown } from '../../../../../common';
import { AddSubUnitButton } from '../../../../../subUnits';
import styles from './PackRosterListTopHeader.less';

type PackRosterListTopHeaderProps = {
  isOffline: boolean;
};

const PackRosterListTopHeader = ({
  isOffline = false,
}: PackRosterListTopHeaderProps) => {
  const unitProgram = useSelector(programSel);

  return (
    <div className={styles.container}>
      {unitProgram === Program.CUB_SCOUT &&
        featureFlags.getFlag('SBL_4688_ADD_INDIVIDUAL') && (
          <AddIndividualDropdown disabled={isOffline} />
        )}
      {!!EDIT_SUB_UNITS && (
        <AddSubUnitButton size="small" color="white" ghost noBorder />
      )}
      {!!SHOW_QUICK_ENTRY && <QuickEntryDropdown disabled={isOffline} />}
      <RunReportDropdown disabled={isOffline} showAllUsers={false} />
    </div>
  );
};

export default PackRosterListTopHeader;
