import { dtoDateFormat } from '../constants';

export default profile => {
  const {
    oaActive,
    oaMember,
    oaMemberNumber,
    oaElectionDate,
    oaOrdealDate,
    oaBrotherhoodDate,
    oaVigilDate,
  } = profile;

  return {
    oaActive: !!oaActive.length,
    oaMember: !!oaMember.length,
    oaMemberNumber: oaMemberNumber ? oaMemberNumber : null,
    oaElectionDate: oaElectionDate
      ? oaElectionDate.format(dtoDateFormat)
      : null,
    oaOrdealDate: oaOrdealDate ? oaOrdealDate.format(dtoDateFormat) : null,
    oaBrotherhoodDate: oaBrotherhoodDate
      ? oaBrotherhoodDate.format(dtoDateFormat)
      : null,
    oaVigilDate: oaVigilDate ? oaVigilDate.format(dtoDateFormat) : null,
  };
};
