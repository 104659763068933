/* eslint-disable react/display-name */

/* eslint-disable react/no-multi-comp */
import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, S, advancementFileStatuses } from '@shared';

import {
  EagleScoutButton,
  EditAdvancementButton,
  UnapproveAdvancementButton,
} from '../../../../../../progress/components';
import { visibilityFields } from '../../../../constants';
import CancelFileButton from '../../CancelFileButton';
import HistoryItemAvatar from '../../HistoryItemAvatar';
import HistoryItemDate from '../../HistoryItemDate';
import HistoryItemDescription from '../../HistoryItemDescription';
import HistoryItemIcon from '../../HistoryItemIcon';
import HistoryItemName from '../../HistoryItemName';
import HistoryItemStatus from '../../HistoryItemStatus';
import ItemDetailsToggle from '../../ItemDetailsToggle';
import ExpandedRow from '../ExpandedRow';
import mainRows from './advancementHistoryListMainRows';
import styles from './advancementHistoryListRows.less';

function advancementHistoryListRowsWrapper({
  searchPinned,
  expandedKeys,
  cancellingFiles,
  areAllPendingItemsSelected,
  fieldsVisibility,
  isOffline,
  onExpandedKeyChange,
  onCancelFile,
  onDownloadFileErrorLog,
  onGoToYouthProfile,
  onGoToAdultProfile,
  onSelectedAllItems,
}) {
  return function rowsItem(item) {
    if (item.isMainRow) {
      return mainRows({
        item,
        searchPinned,
        onSelectedAllItems,
        areAllPendingItemsSelected,
      });
    }

    const open = expandedKeys.includes(item.key);
    const isFile = item.type === 'advancementFile';
    const isProcessedFile =
      isFile && item.status === advancementFileStatuses.PROCESSED;
    const {
      type,
      userId,
      name,
      date,
      id,
      status,
      statusLabel,
      canEdit,
      canUnapprove,
      isAdvancementPending,
      isEagleScoutRank,
    } = item;
    const person = { userId, name };
    const recordedByName = item.recordedByName || 'N/A';

    return [
      {
        rowId: 'header',
        className: styles.header,
        label: (
          <S size="4" bold colored>
            <div className={styles.userHeaderLeft}>
              <HistoryItemAvatar
                type={item.type}
                isAdult={item.isAdult}
                profilePictureUrl={item.profilePictureUrl}
              />
              {!isFile && (
                <HistoryItemIcon
                  type={item.type}
                  status={item.status}
                  item={item}
                />
              )}
            </div>
          </S>
        ),
        content: (
          <HistoryItemName
            type={item.type}
            name={item.name}
            isOffline={isOffline}
            onGoToProfilePage={
              !item.isAdult
                ? onGoToYouthProfile(item)
                : onGoToAdultProfile(item)
            }
          />
        ),
      },
      {
        rowId: 'memberId',
        omit: isFile || !fieldsVisibility[visibilityFields.MEMBER_ID],
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.AdvancementHistory.column.memberId" />
          </S>
        ),
        content: <S size="4">{item.memberId} </S>,
      },
      {
        rowId: 'updatedDate',
        omit:
          (isFile && !isProcessedFile) ||
          !fieldsVisibility[visibilityFields.DATE],
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.AdvancementHistory.column.updatedDate" />
          </S>
        ),
        content: (
          <S size="4">
            <HistoryItemDate
              isMobile={true}
              type={type}
              date={date}
              status={status}
            />
          </S>
        ),
      },
      {
        rowId: 'recordedByName',
        omit: !fieldsVisibility[visibilityFields.RECORDED_BY],
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.AdvancementHistory.column.recordedBy" />
          </S>
        ),
        content: <S size="4">{recordedByName}</S>,
      },
      {
        rowId: 'description',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.AdvancementHistory.column.description" />
          </S>
        ),
        className: styles.expandableContentRow,
        content: isProcessedFile ? (
          <ItemDetailsToggle
            color="scouting-warm-gray"
            size="small"
            ghost
            noBorder
            fitText
            uppercase={false}
            itemKey={item.key}
            expanded={expandedKeys.includes(item.key)}
            className={styles.itemDetailsToggle}
            chevronClassName={styles.itemDetailsToggleChevron}
            onClick={onExpandedKeyChange}
          >
            <HistoryItemDescription
              type={item.type}
              status={item.status}
              statusLabel={item.statusLabel}
              totalRecords={item.totalRecords}
              recordsImported={item.recordsImported}
              recordsSkipped={item.recordsSkipped}
              className={styles.itemDetailsToggleLabel}
            >
              {item.description}
            </HistoryItemDescription>
          </ItemDetailsToggle>
        ) : (
          <HistoryItemDescription
            type={item.type}
            status={item.status}
            statusLabel={item.statusLabel}
          >
            {item.description}
          </HistoryItemDescription>
        ),
        child: isProcessedFile && {
          open,
          content: <ExpandedRow item={item} />,
        },
      },
      {
        rowId: 'status',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.AdvancementHistory.column.status" />
          </S>
        ),
        content: (
          <HistoryItemStatus
            type={type}
            status={statusLabel}
            isSynced={item.isSynced}
          />
        ),
        child: !item.isSynced && {
          open: true,
          content: <ExpandedRow item={item} />,
        },
      },
      {
        rowId: 'downloadLog',
        omit: !isProcessedFile,
        className: styles.actionRow,
        content: (
          <Button
            id="qa_downloadButton"
            ghost
            noBorder
            fitText
            color="scouting-warm-gray"
            onClick={() => onDownloadFileErrorLog(item)}
          >
            <FormattedMessage id="advancement.AdvancementHistory.downloadLogFile" />
          </Button>
        ),
      },
      {
        rowId: 'edit',
        omit: !canEdit,
        className: styles.actionRow,
        content: (
          <EditAdvancementButton person={person} uppercase advancement={item} />
        ),
      },
      {
        rowId: 'unapprove',
        omit: !canUnapprove,
        className: styles.actionRow,
        content: (
          <UnapproveAdvancementButton
            youthName={person.name}
            advancement={item}
            uppercase
          />
        ),
      },
      {
        rowId: 'eagleScoutRankApplication',
        omit: !isEagleScoutRank || !isAdvancementPending,
        className: styles.actionRow,
        content: (
          <EagleScoutButton person={{ youthName: person.name, userId }} />
        ),
      },
      {
        rowId: 'cancelFile',
        omit: !(isFile && status === advancementFileStatuses.PENDING),
        className: styles.actionRow,
        content: (
          <CancelFileButton
            id={id}
            loading={cancellingFiles[id]}
            onClick={onCancelFile}
          />
        ),
      },
    ];
  };
}

export default advancementHistoryListRowsWrapper;
