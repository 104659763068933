import React from 'react';

import cn from 'classnames';

import { personTagType } from '../../constants';
import S from '../S';
import styles from './PersonTag.less';

type Props = {
  /**
   * Sets a font-size where `'1'` is the biggest, and `'6'` the smallest
   */
  size: '1' | '2' | '3' | '4' | '5' | '6';
  personType: string;
  isMultiple?: boolean;
};

const PersonTag: React.FC<Props> = ({
  size: fontSize = '5',
  personType,
  isMultiple = true,
}) => {
  const classNames = cn(styles.personRoleTag, {
    [styles.leader]: personType === personTagType.LEADER,
    [styles.parent]: personType === personTagType.PARENT,
    [styles.child]: personType === personTagType.CHILD,
    [styles.isMultiple]: isMultiple,
  });

  return isMultiple ? (
    <S size={fontSize} className={classNames}>
      {personType}
    </S>
  ) : (
    <span className={classNames}>{personType}</span>
  );
};

export default PersonTag;
