import { capitalize } from 'lodash';
import moment from 'moment';
import { Action } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { Observable, forkJoin, of } from 'rxjs';
import { AjaxError } from 'rxjs-compat';
import { filter, switchMap } from 'rxjs/operators';

import { OrgSubUnit } from '@appTypes/esb';
import { EDIT_SUB_UNITS } from '@config';
import { organizationGuidSel, selectedOrganizationSel } from '@context';
import {
  PACK_ROSTER_REQUEST,
  isValidOrganizationSel,
} from '@modules/advancement/common';
import {
  DenTypeId,
  getAddedAdults,
  getAddedYouths,
} from '@modules/advancement/utilsTyped';
import { intl } from '@modules/shared';
import { toastService } from '@modules/toasts';
import { catchAndReport } from '@modules/utils/rxjs/operators';

import { RootState } from '../../../../root/store';
import { subUnitTypes } from '../constants';
import {
  deleteSubUnitError,
  deleteSubUnitRequest,
  deleteSubUnitResponse,
  editSubUnitFailure,
  editSubUnitMembers,
  editSubUnitRequest,
  editSubUnitSuccess,
  getSubUnitsError,
  getSubUnitsResponse,
  hideAddToSubUnitModal,
  hideSetSubUnitModal,
  saveSubUnitFailure,
  saveSubUnitRequest,
  saveSubUnitSuccess,
} from './actions';
import {
  isDenSel,
  isSubUnitAllowedSel,
  selectedSubUnitSel,
  subUnitTypeSel,
  subUnitsInCacheSel,
} from './selectors';
import services from './services';

const saveSubUnitEpic$: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(saveSubUnitRequest.match),
    filter(() => EDIT_SUB_UNITS),
    switchMap(
      ({
        payload: { denType, subUnitName, selectedAdults, selectedUsers },
      }) => {
        const state = state$.value;

        const subUnitType = subUnitTypeSel(state);
        const orgGuid = organizationGuidSel(state);
        const organizationProfile = selectedOrganizationSel(state);

        const isDen = subUnitType === subUnitTypes.DEN;

        const subUnitData = {
          subUnitName,
          subUnitApproved: true,
          forumEnabled: false,
          ...(isDen
            ? {
                denType,
                showDlEvents: true,
              }
            : {}),
        };

        return services
          .createSubUnit$({
            organizationGuid: orgGuid,
            subUnitType,
            subUnitData,
          })
          .pipe(
            switchMap(response => {
              const createdSubUnitData: OrgSubUnit = {
                subUnitId: response.id,
                subUnitName: subUnitData.subUnitName,
                denTypeId: isDen ? DenTypeId[subUnitData.denType ?? ''] : null,
                denType: isDen ? subUnitData.denType ?? '' : null,
                isApproved: subUnitData.subUnitApproved,
                isForumEnabled: subUnitData.forumEnabled,
                showDlEvents: isDen || null,
                unitId: Number(organizationProfile.unitId),
                dateCreated: moment().format('YYYY-MM-DD'),
              };

              return forkJoin([
                selectedUsers.length
                  ? services.addSubUnitYouths$(
                      orgGuid,
                      subUnitType,
                      response.id,
                      selectedUsers,
                    )
                  : of({ message: '' }),
                selectedAdults.length
                  ? services.addSubUnitAdults$(
                      orgGuid,
                      subUnitType,
                      response.id,
                      selectedAdults,
                    )
                  : of({ message: '' }),
                of(createdSubUnitData),
              ]);
            }),
            switchMap(response => {
              const createdSubUnit = response[2];
              toastService.success(
                intl.formatMessage(
                  { id: 'subUnits.create.success' },
                  {
                    subUnitType: capitalize(subUnitType),
                    subUnitName,
                  },
                ),
              );
              const addedYouths = getAddedYouths(
                selectedUsers,
                createdSubUnit,
                isDen,
              );
              const addedAdults = getAddedAdults(
                selectedAdults,
                createdSubUnit,
                isDen,
              );

              return of(
                hideSetSubUnitModal(),
                saveSubUnitSuccess({ subUnit: createdSubUnit, orgGuid }),
                editSubUnitMembers({
                  subUnit: createdSubUnit,
                  addedYouths,
                  addedAdults,
                }),
              );
            }),
            catchAndReport(err => of(saveSubUnitFailure(err))),
          );
      },
    ),
  );

const editSubUnitEpic$: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(editSubUnitRequest.match),
    switchMap(
      ({
        payload: {
          denType,
          membersToDelete = [],
          selectedAdults = [],
          selectedUsers = [],
          subUnitId,
          subUnitName,
        },
      }) => {
        const state = state$.value;
        const isDen = isDenSel(state);
        const subUnitType = subUnitTypeSel(state);
        const orgGuid = organizationGuidSel(state);
        const selectedSubUnit = selectedSubUnitSel(state);

        const subUnitData = {
          subUnitName,
          ...(isDen ? { denType } : {}),
        };

        return forkJoin([
          services.editSubUnit$({
            organizationGuid: orgGuid,
            subUnitType,
            subUnitId,
            subUnitData,
          }),
          selectedUsers.length
            ? services.addSubUnitYouths$(
                orgGuid,
                subUnitType,
                subUnitId,
                selectedUsers,
              )
            : of({}),
          selectedAdults.length
            ? services.addSubUnitAdults$(
                orgGuid,
                subUnitType,
                subUnitId,
                selectedAdults,
              )
            : of({}),
          membersToDelete.length
            ? services.deleteSubUnitUsers$(
                orgGuid,
                subUnitType,
                subUnitId,
                membersToDelete,
              )
            : of({}),
        ]).pipe(
          switchMap(() => {
            toastService.success(
              intl.formatMessage(
                { id: 'subUnits.edit.success' },
                {
                  subUnitType: capitalize(subUnitType),
                  subUnitName,
                },
              ),
            );

            const editedSubUnitData = {
              subUnitId: selectedSubUnit?.subUnitId ?? subUnitId,
              subUnitName: subUnitData.subUnitName,
              denTypeId: isDen ? DenTypeId[subUnitData.denType ?? ''] : null,
              denType: isDen ? denType ?? '' : null,
              isApproved: selectedSubUnit?.isApproved ?? true,
              isForumEnabled: selectedSubUnit?.isForumEnabled ?? false,
              showDlEvents: isDen || null,
              unitId: selectedSubUnit?.unitId ?? 0,
              dateCreated: selectedSubUnit?.dateCreated ?? '',
            };

            const changedName = !!(
              selectedSubUnit && subUnitName !== selectedSubUnit.subUnitName
            );

            const addedYouths = getAddedYouths(
              selectedUsers,
              editedSubUnitData,
              isDen,
            );
            const addedAdults = getAddedAdults(
              selectedAdults,
              editedSubUnitData,
              isDen,
            );

            return of(
              hideSetSubUnitModal(),
              hideAddToSubUnitModal(),
              editSubUnitSuccess({ subUnit: editedSubUnitData, orgGuid }),
              editSubUnitMembers({
                subUnit: editedSubUnitData,
                addedYouths,
                addedAdults,
                deletedMembers: membersToDelete,
                changedName,
              }),
            );
          }),
          catchAndReport(err => of(editSubUnitFailure(err))),
        );
      },
    ),
  );

const deleteSubUnitEpic$: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(deleteSubUnitRequest.match),
    filter(() => EDIT_SUB_UNITS),
    switchMap(({ payload: subUnitId }) => {
      const state = state$.value;
      const subUnitType = subUnitTypeSel(state);
      const orgGuid = organizationGuidSel(state);
      return services.deleteSubUnit$(orgGuid, subUnitType, subUnitId).pipe(
        switchMap(() => {
          toastService.success(
            intl.formatMessage(
              { id: 'subUnits.delete.success' },
              { subUnitType: capitalize(subUnitType) },
            ),
          );
          return of(
            deleteSubUnitResponse({
              orgGuid,
              subUnitId,
              isDen: subUnitType === 'den',
            }),
          );
        }),
        catchAndReport(err => {
          const { status } = err as AjaxError;
          if (status === 401) {
            toastService.dismiss(undefined);
            toastService.error(
              intl.formatMessage({ id: 'subUnits.delete.fail.notAllowed' }),
            );
          }
          return of(deleteSubUnitError(err));
        }),
      );
    }),
  );

const loadSubUnitsEpic$: Epic<Action, Action, RootState> = (action$, state$) =>
  action$
    .ofType(PACK_ROSTER_REQUEST)
    .filter(() => isValidOrganizationSel(state$.value))
    .filter(() => isSubUnitAllowedSel(state$.value))
    .switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const subUnitsInCache = subUnitsInCacheSel(state);
      if (subUnitsInCache) {
        return of(getSubUnitsResponse({ orgGuid, subUnits: subUnitsInCache }));
      }

      return services.getSubUnits$(orgGuid).pipe(
        switchMap(subUnits => of(getSubUnitsResponse({ orgGuid, subUnits }))),
        catchAndReport(() => Observable.of(getSubUnitsError(orgGuid))),
      );
    });

export default combineEpics(
  saveSubUnitEpic$,
  editSubUnitEpic$,
  loadSubUnitsEpic$,
  deleteSubUnitEpic$,
);
