import { DateTime } from 'luxon';
import moment from 'moment';
import stable from 'stable';
import { v4 as uuid } from 'uuid';

import {
  LeadershipPosition,
  OrgSubUnit,
  UnitLeadershipPosition,
} from '@appTypes/esb';
import { PositionId, denTypesNames } from '@modules/shared';
import { isIncludedIn, sorters } from '@modules/utils';

import { RosterScout } from './duck/types';

export const subUnitYouthDictionary = {
  YOUTH_MEMBER: 222,
  SCOUTS_BSA: 111,
  LION_CUB: 209,
  TIGER_MEMBER: 99,
  WOLF_MEMBER: 100,
  BEAR_MEMBER: 101,
  WEBELOS_MEMBER: 102,
  ARROW_OF_LIGHT: 363,
} as const;

export const subUnitYouthReverseDictionary = {
  222: 'Youth Member',
  111: 'Scouts BSA',
  209: 'Lion Cub',
  99: 'Tiger Member',
  100: 'Wolf Member',
  101: 'Bear Member',
  102: 'Webelos Member',
  363: 'Arrow of Light',
};

export type SubUnitYouthDictionary =
  (typeof subUnitYouthDictionary)[keyof typeof subUnitYouthDictionary];

type SubUnitYouthReverseDictionary =
  (typeof subUnitYouthReverseDictionary)[keyof typeof subUnitYouthReverseDictionary];

export const subUnitAdultDictionary = {
  DEN_ADMIN: 151,
  PATROL_ADMIN: 152,
  FULL_CONTROL: 3,
} as const;

export const subUnitAdultReverseDictionary = {
  151: 'Den Admin',
  152: 'Patrol Admin',
} as const;

export type SubUnitAdultDictionary =
  (typeof subUnitAdultDictionary)[keyof typeof subUnitAdultDictionary];

type SubUnitAdultReverseDictionary =
  (typeof subUnitAdultReverseDictionary)[keyof typeof subUnitAdultReverseDictionary];

export const mapSubUnitScouts = (
  scout: RosterScout,
  isDen: boolean,
  denTypeName?: string | null,
) => {
  let positionId: SubUnitYouthDictionary = isDen
    ? subUnitYouthDictionary.YOUTH_MEMBER
    : subUnitYouthDictionary.SCOUTS_BSA;

  if (denTypeName && isDen) {
    switch (denTypeName) {
      case denTypesNames.lions:
        positionId = subUnitYouthDictionary.LION_CUB;
        break;
      case denTypesNames.tigers:
        positionId = subUnitYouthDictionary.TIGER_MEMBER;
        break;
      case denTypesNames.wolves:
        positionId = subUnitYouthDictionary.WOLF_MEMBER;
        break;
      case denTypesNames.bears:
        positionId = subUnitYouthDictionary.BEAR_MEMBER;
        break;
      case denTypesNames.webelos:
        positionId = subUnitYouthDictionary.WEBELOS_MEMBER;
        break;
      case denTypesNames.aol:
        positionId = subUnitYouthDictionary.ARROW_OF_LIGHT;
        break;
      default:
        positionId = subUnitYouthDictionary.YOUTH_MEMBER;
        break;
    }
  }

  return {
    userId: scout.userId,
    positionId,
  };
};

export const mapSubUnitAdults = (adult: RosterScout, isDen: boolean) => ({
  userId: adult.userId,
  positionIds: [
    isDen
      ? subUnitAdultDictionary.DEN_ADMIN
      : subUnitAdultDictionary.PATROL_ADMIN,
  ],
  permissionsForAllScouts: [subUnitAdultDictionary.FULL_CONTROL],
  isSubUnitAdmin: true,
});

export type FormattedSubUnitYouth = { userId: number; positionId: number };

export type FormattedSubUnitAdult = {
  userId: number;
  positionIds: number[];
  permissionsForAllScouts: number[];
  isSubUnitAdmin: boolean;
};

export type SubUnitAdultPosition = {
  userId: number;
  id: string;
  positionId: SubUnitAdultDictionary;
  position: SubUnitAdultReverseDictionary;
  isKey3: boolean;
  dateStarted: string;
  isPending: boolean;
  patrolId: number | null;
  patrolName: string | null;
  denId: number | null;
  denNumber: string | null;
  denType: string | null;
};

export type SubUnitYouthPosition = {
  userId: number;
  id: string;
  positionId: SubUnitYouthDictionary;
  position: SubUnitYouthReverseDictionary;
  dateStarted: string;
  isPending: boolean;
  patrolId: number | null;
  patrolName: string | null;
  denId: number | null;
  denNumber: string | null;
  denType: string | null;
};

export const DenTypeId: Readonly<{ [key: string]: number }> = {
  lions: 0,
  tigers: 1,
  wolves: 2,
  bears: 3,
  webelos: 4,
  aol: 5,
  '': -1,
};

export const getAddedYouths = (
  selectedUsers: FormattedSubUnitYouth[],
  subUnit: OrgSubUnit,
  isDen: boolean,
) =>
  selectedUsers.map(
    (user): SubUnitYouthPosition => ({
      userId: user.userId,
      id: uuid(),
      positionId: user.positionId as SubUnitYouthDictionary,
      position:
        subUnitYouthReverseDictionary[
          user.positionId as keyof typeof subUnitYouthReverseDictionary
        ],
      dateStarted: moment().format('YYYY-MM-DD'),
      isPending: false,
      patrolId: isDen ? null : subUnit.subUnitId,
      patrolName: isDen ? null : subUnit.subUnitName,
      denId: isDen ? subUnit.subUnitId : null,
      denNumber: isDen ? subUnit.subUnitName : null,
      denType: isDen ? subUnit.denType : null,
    }),
  );

export const getAddedAdults = (
  selectedAdults: FormattedSubUnitAdult[],
  subUnit: OrgSubUnit,
  isDen: boolean,
) =>
  selectedAdults.map(
    (user): SubUnitAdultPosition => ({
      userId: user.userId,
      id: uuid(),
      positionId: user.positionIds[0] as SubUnitAdultDictionary,
      position:
        subUnitAdultReverseDictionary[
          user.positionIds[0] as keyof typeof subUnitAdultReverseDictionary
        ],
      isKey3: false,
      dateStarted: moment().format('YYYY-MM-DD'),
      isPending: true,
      patrolId: isDen ? null : subUnit.subUnitId,
      patrolName: isDen ? null : subUnit.subUnitName,
      denId: isDen ? subUnit.subUnitId : null,
      denNumber: isDen ? subUnit.subUnitName : null,
      denType: isDen ? subUnit.denType : null,
    }),
  );

export const getDeletedYouthPos = (
  isDen: boolean,
): Omit<SubUnitYouthPosition, 'userId'> => ({
  id: uuid(),
  positionId: isDen
    ? subUnitYouthDictionary.YOUTH_MEMBER
    : subUnitYouthDictionary.SCOUTS_BSA,
  position: isDen
    ? subUnitYouthReverseDictionary[222]
    : subUnitYouthReverseDictionary[111],
  dateStarted: moment().format('YYYY-MM-DD'),
  isPending: false,
  patrolId: null,
  patrolName: null,
  denId: null,
  denNumber: null,
  denType: null,
});
export interface EnhancedLeadershipPosition extends LeadershipPosition {
  userId: number;
  memberId: number;
  personGuid: string;
  personFullName?: string;
  pictureUrl?: string;
  allPositionsId: Map<number | string, number>;
}

export interface EnhancedUnitLeadershipPosition extends UnitLeadershipPosition {
  leadershipPositionHistory: EnhancedLeadershipPosition[];
}

export function transformUnitLeadershipPositions(
  data: UnitLeadershipPosition[],
  positionOptions: Array<{
    id: number;
    name: string;
  }> = [],
) {
  return data.map(record => {
    const filteredHistory = record.leadershipPositionHistory.filter(pos => {
      // Allow only current positions
      if (
        pos.endDate &&
        DateTime.fromISO(pos.endDate).startOf('day') <
          DateTime.now().startOf('day')
      )
        return false;

      //Remove any position from other programs
      return positionOptions.find(option => option.id === pos.positionId);
    });
    const { userId, memberId, personGuid, personFullName, pictureUrl } = record;
    const updatedHistory: EnhancedLeadershipPosition[] = [];

    if (!filteredHistory.length) {
      return {
        ...record,
        leadershipPositionHistory: [
          {
            userId,
            memberId,
            personGuid,
            personFullName,
            pictureUrl,
            allPositionsId: new Map(),
          },
        ],
      } as EnhancedUnitLeadershipPosition;
    }

    const allPositionsId = new Map(
      filteredHistory.map(historyItem => [
        historyItem.id,
        historyItem.positionId,
      ]),
    );

    filteredHistory.forEach((pos, index) => {
      index
        ? updatedHistory.push({
            ...pos,
            userId,
            memberId,
            personGuid,
            allPositionsId,
          })
        : updatedHistory.push({
            ...pos,
            userId,
            memberId,
            personGuid,
            personFullName,
            pictureUrl,
            allPositionsId,
          });
    });

    return {
      ...record,
      leadershipPositionHistory: updatedHistory,
    } as EnhancedUnitLeadershipPosition;
  });
}

export interface QELeadershipPosition
  extends Omit<
    EnhancedLeadershipPosition,
    'id' | 'positionId' | 'startDate' | 'endDate'
  > {
  id?: string | number;
  positionId?: PositionId;
  startDate?: string;
  endDate?: string;
  currentYouthPatrolId?: number;
}
export const filterUnitLeadershipPositionsItems = (
  unitLeadershipPositions: UnitLeadershipPosition[] = [],
  rosterItems: RosterScout[] = [],
  positionOptions: Array<{
    id: number;
    name: string;
  }> = [],
  search = '',
) => {
  const transformedPositions = transformUnitLeadershipPositions(
    unitLeadershipPositions,
    positionOptions,
  );

  const filteredItems = search
    ? transformedPositions.filter(
        scout =>
          isIncludedIn(scout.personFullName, search) ||
          isIncludedIn(String(scout.memberId), search),
      )
    : transformedPositions;

  const filteredItemsAddedPatrolId = filteredItems.map(scout => {
    const foundPatrolId = rosterItems
      .find(rosterItem => rosterItem.userId === scout.userId)
      ?.positions?.find(({ patrolId }) => patrolId)?.patrolId;

    if (foundPatrolId) {
      const updatedHistory = scout.leadershipPositionHistory.map(pos => ({
        ...pos,
        currentYouthPatrolId: foundPatrolId,
      }));

      return { ...scout, leadershipPositionHistory: updatedHistory };
    }
    return scout;
  });

  const sortedItems = stable(
    filteredItemsAddedPatrolId,
    sorters.text('lastName'),
  );

  return sortedItems
    .map(({ leadershipPositionHistory }) => leadershipPositionHistory)
    .flat() as QELeadershipPosition[];
};
