import { permissions, emptyObj } from '@shared';
import { replace } from 'redux-first-router';
import { ROUTE_PROFILE, ROUTE_OWN_ACTIVITY_LOGS } from './duck';
import {
  ProfilePage,
  ProfilePagePlaceholder,
  OwnActivityLogsPage,
} from './components';
import isEmpty from 'lodash/isEmpty';
import { querySel } from '@location';
import { organizationPositionsSel, setOrganization } from '@context';

const routes = {
  [ROUTE_PROFILE]: {
    path: '/profile',
    Component: ProfilePage,
    Placeholder: ProfilePagePlaceholder,
    titleId: 'page.profile',
    permission: permissions.VIEW_PROFILE_PAGE,
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;
      if (!isEmpty(organizationGuid) && !isEmpty(positions)) {
        const foundedPosition = positions.find(
          p => p.organizationGuid === organizationGuid,
        );
        replace('/profile');
        if (foundedPosition) {
          dispatch(
            setOrganization({
              guid: foundedPosition.organizationGuid,
              programType: foundedPosition.programType,
              parentOrgGuid: foundedPosition.organizationGuid,
            }),
          );
        }
      }
    },
  },
  [ROUTE_OWN_ACTIVITY_LOGS]: {
    path: '/profile/activityLogs/:activityType',
    Component: OwnActivityLogsPage,
    Placeholder: ProfilePagePlaceholder,
    titleId: 'page.youthActivityLogs',
    permission: permissions.VIEW_PROFILE_PAGE,
  },
};

export default routes;
