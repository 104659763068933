import React from 'react';

import { FormattedMessage } from 'react-intl';

import { T } from '@modules/shared/components';

import styles from './NotFound.less';

type Props = {
  hasNotEligibleResults: boolean;
};

const NotFound: React.FC<Props> = ({ hasNotEligibleResults }) => (
  <div className={styles.notFound}>
    <T className={styles.resultsTitle}>
      <FormattedMessage
        id={'packRoster.addIndividual.addDenChiefModal.results'}
      />
    </T>
    <FormattedMessage
      id={'packRoster.addIndividual.addDenChiefModal.notFoundDescription'}
    >
      {(text: string) => `${hasNotEligibleResults ? 'Eligible ' : ''}${text}`}
    </FormattedMessage>
  </div>
);

export default NotFound;
