import PropTypes from 'prop-types';

import { activityTypeIds } from '@shared';

export { activityTypeIds, activityTypes } from '@shared';

export const progressModuleName = 'progress';

export const commonModuleName = 'common';
export const commonModuleNamespace = `${progressModuleName}/${commonModuleName}`;

export const activitiesModuleName = 'activities';
export const activitiesModuleNamespace = `${progressModuleName}/${activitiesModuleName}`;

export const advancementModuleName = 'advancement';
export const advancementModuleNamespace = `${progressModuleName}/${advancementModuleName}`;

export const serviceModuleName = 'service';
export const serviceModuleNamespace = `${progressModuleName}/${serviceModuleName}`;

export const campoutsModuleName = 'campouts';
export const campoutsModuleNamespace = `${progressModuleName}/${campoutsModuleName}`;

export const eagleProjectModuleName = 'eagleProject';
export const eagelProjectModuleNamespace = `${progressModuleName}/${eagleProjectModuleName}`;

export const hikesModuleName = 'hikes';
export const hikesModuleNamespace = `${progressModuleName}/${hikesModuleName}`;

export const longCruiseModuleName = 'longCruise';
export const longCruiseModuleNamespace = `${progressModuleName}/${longCruiseModuleName}`;

export const editAdvancementModuleName = 'editAdvancement';
export const editAdvancementModuleNamespace = `${progressModuleName}/${editAdvancementModuleName}`;

export const eagleScoutApplicationModuleName = 'eagleScoutApplication';
export const eagleScoutApplicationModuleNamespace = `${progressModuleName}/${editAdvancementModuleName}`;

export const approveAdvancementsModuleName = 'approveAdvancements';
export const approveAdvancementsModuleNamespace = `${progressModuleName}/${approveAdvancementsModuleName}`;

export const declineAdvancementModuleName = 'declineAdvancement';
export const declineAdvancementModuleNamespace = `${progressModuleName}/${declineAdvancementModuleName}`;

export const unapproveAdvancementModuleName = 'unapproveAdvancement';
export const unapproveAdvancementModuleNamespace = `${progressModuleName}/${unapproveAdvancementModuleName}`;

export const validTypesByPerson = [
  advancementModuleName,
  serviceModuleName,
  'hiking',
  'campout',
  editAdvancementModuleName,
  longCruiseModuleName,
];

export enum ModalMode {
  CREATE = 'CREATE',
  RECORD = 'RECORD',
  EDIT = 'EDIT',
}

export const detailModes = Object.freeze({
  SUGGEST: 'SUGGEST',
  PREVIEW: 'PREVIEW',
  CREATE: 'CREATE',
  RECORD: 'RECORD',
  EDIT: 'EDIT',
  EDIT_SERIES: 'EDIT_SERIES',
  CLONE: 'CLONE',
  JOIN: 'JOIN',
});

export const actionButtons = Object.freeze({
  JOIN: 'JOIN',
  EDIT: 'EDIT',
});

export const addActivityKeys = Object.freeze([
  'activityTypeId',
  'categoryId',
  'name',
  'startDateTime',
  'endDateTime',
  'location',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'city',
  'akelaStateId',
  'zip5',
  'zip4',
  'akelaCountryId',
  'description',
  'isPersonalActivity',
  'personGuid',
  'hostOrganizationGuid',
  'isEveryChildOrg',
  'organizationGuid',
  'nonRegisteredYouthCount',
  'nonRegisteredYouthHours',
  'nonRegisteredAdultCount',
  'nonRegisteredAdultHours',
  'collaborativeOrganizations',
  'registeredAdults',
  'registeredYouths',
  'campId',
  'rsvp',
]);

export const ActivityKeys = Object.freeze([
  'id',
  'akelaStateId',
  'hostOrganizationGuid',
  'organizationTypeId',
  'activityTypeId',
  'categoryId',
  'name',
  'description',
  'startDateTime',
  'endDateTime',
  'location',
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'city',
  'zip5',
  'zip4',
  'isEveryChildOrg',
  'isPersonalActivity',
  'personGuid',
  'campId',
]);

export const updateActivityKeys = (isCalendarActivity: boolean) =>
  isCalendarActivity ? [...ActivityKeys, 'rsvp'] : ActivityKeys;

export const updateNonRegisteredParticipantsKeys = Object.freeze([
  'nonRegisteredYouthCount',
  'nonRegisteredYouthHours',
  'nonRegisteredAdultCount',
  'nonRegisteredAdultHours',
]);

export const datesShape = PropTypes.shape({
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

export const personsShape = PropTypes.arrayOf(
  PropTypes.shape({
    userId: PropTypes.number.isRequired,
    personFullName: PropTypes.string,
    personShortFullName: PropTypes.string,
    isAdult: PropTypes.bool,
    currentHighestRankApproved: PropTypes.object,
    otherHighestRanksApproved: PropTypes.array,
    dateOfBirth: PropTypes.string,
    age: PropTypes.number,
  }),
);

export const activityValueTypes = Object.freeze({
  HOURS: 1,
  DAYS: 2,
  NIGHTS: 3,
  FROST_POINTS: 4,
  MILES: 5,
  LOWEST_POINT: 6,
  HIGHEST_POINT: 7,
  CONSERVATION_HOURS: 8,
  LONG_CRUISE_DAYS: 9,
  EAGLE_HOURS: 10,
});

export const activityValueTypesNames = Object.freeze({
  HOURS: 'hours',
  DAYS: 'days',
  NIGHTS: 'nights',
  FROST_POINTS: 'frostPoints',
  MILES: 'miles',
  LOWEST_POINT: 'lowestPoint',
  HIGHEST_POINT: 'highestPoint',
  CONSERVATION_HOURS: 'conservationHours',
  LONG_CRUISE_DAYS: 'longCruiseDays',
});

export const activityValueTypesToName = Object.freeze({
  [activityValueTypes.HOURS]: activityValueTypesNames.HOURS,
  [activityValueTypes.DAYS]: activityValueTypesNames.DAYS,
  [activityValueTypes.NIGHTS]: activityValueTypesNames.NIGHTS,
  [activityValueTypes.FROST_POINTS]: activityValueTypesNames.FROST_POINTS,
  [activityValueTypes.MILES]: activityValueTypesNames.MILES,
  [activityValueTypes.LOWEST_POINT]: activityValueTypesNames.LOWEST_POINT,
  [activityValueTypes.HIGHEST_POINT]: activityValueTypesNames.HIGHEST_POINT,
  [activityValueTypes.CONSERVATION_HOURS]:
    activityValueTypesNames.CONSERVATION_HOURS,
  [activityValueTypes.LONG_CRUISE_DAYS]:
    activityValueTypesNames.LONG_CRUISE_DAYS,
  [activityValueTypes.EAGLE_HOURS]: activityValueTypesNames.HOURS,
});

export const activityValueTypesNameToId = Object.freeze({
  [activityValueTypesNames.HOURS]: activityValueTypes.HOURS,
  [activityValueTypesNames.DAYS]: activityValueTypes.DAYS,
  [activityValueTypesNames.NIGHTS]: activityValueTypes.NIGHTS,
  [activityValueTypesNames.FROST_POINTS]: activityValueTypes.FROST_POINTS,
  [activityValueTypesNames.MILES]: activityValueTypes.MILES,
  [activityValueTypesNames.LOWEST_POINT]: activityValueTypes.LOWEST_POINT,
  [activityValueTypesNames.HIGHEST_POINT]: activityValueTypes.HIGHEST_POINT,
  [activityValueTypesNames.CONSERVATION_HOURS]:
    activityValueTypes.CONSERVATION_HOURS,
  [activityValueTypesNames.LONG_CRUISE_DAYS]:
    activityValueTypes.LONG_CRUISE_DAYS,
});

export const savingAction = Object.freeze({
  UPDATED: 'updated',
  CREATED: 'created',
});

export const activityImportedFromTypes = Object.freeze({
  SCOUTBOOK_IMPORT: 'SCOUTBOOK_IMPORT',
  GTFA_IMPORT: 'GTFA_IMPORT',
});

export const activityValuesTabs = Object.freeze({
  BASIC: 'basic',
  ADVANCED: 'advanced',
});

export const basicValuesKeys = Object.freeze({
  LONG_CRUISE_BASIC_YOUTH: 'basicYouthDays',
  LONG_CRUISE_BASIC_ADULT: 'basicAdultDays',
  HIKE_MILES_YOUTH: 'milesPerYouth',
  HIKE_LOWES_POINT_YOUTH: 'lowestPointYouth',
  HIKE_HIGHEST_POINT_YOUTH: 'highestPointYouth',
  HIKE_MILES_ADULT: 'milesPerAdult',
  HIKE_LOWES_POINT_ADULT: 'lowestPointAdult',
  HIKE_HIGHEST_POINT_ADULT: 'highestPointAdult',
  SERVICE_HOURS_YOUTH: 'hoursServed',
  SERVICE_HOURS_ADULT: 'hoursServed',
  CAMPOUT_DAYS_ADULT: 'basicAdultDays',
  CAMPOUT_NIGHTS_ADULT: 'basicAdultNights',
  CAMPOUT_FROST_POINTS_ADULT: 'basicAdultFrostPoints',
  CAMPOUT_DAYS_YOUTH: 'basicYouthDays',
  CAMPOUT_NIGHTS_YOUTH: 'basicYouthNights',
  CAMPOUT_FROST_POINTS_YOUTH: 'basicYouthFrostPoints',
});

const campoutAdvancedKeysToBasicKeys = Object.freeze({
  youthDays: basicValuesKeys.CAMPOUT_DAYS_YOUTH,
  youthNights: basicValuesKeys.CAMPOUT_NIGHTS_YOUTH,
  youthFrostPoints: basicValuesKeys.CAMPOUT_FROST_POINTS_YOUTH,
  adultDays: basicValuesKeys.CAMPOUT_DAYS_ADULT,
  adultNights: basicValuesKeys.CAMPOUT_NIGHTS_ADULT,
  adultFrostPoints: basicValuesKeys.CAMPOUT_FROST_POINTS_ADULT,
});

const hikeAdvancedKeysToBasicKeys = Object.freeze({
  youthMiles: basicValuesKeys.HIKE_MILES_YOUTH,
  youthLowestPoint: basicValuesKeys.HIKE_LOWES_POINT_YOUTH,
  youthHighestPoint: basicValuesKeys.HIKE_HIGHEST_POINT_YOUTH,
  adultMiles: basicValuesKeys.HIKE_MILES_ADULT,
  adultLowestPoint: basicValuesKeys.HIKE_LOWES_POINT_ADULT,
  adultHighestPoint: basicValuesKeys.HIKE_HIGHEST_POINT_ADULT,
});

const longCruisedvancedKeysToBasicKeys = Object.freeze({
  youthDays: basicValuesKeys.LONG_CRUISE_BASIC_YOUTH,
  adultDays: basicValuesKeys.LONG_CRUISE_BASIC_ADULT,
});

const serviceAdvancedKeysToBasicKeys = Object.freeze({
  youthHours: basicValuesKeys.SERVICE_HOURS_YOUTH,
  adultHours: basicValuesKeys.SERVICE_HOURS_ADULT,
});

const eagleProjectAdvancedKeysToBasicKeys = Object.freeze({
  youthDays: basicValuesKeys.CAMPOUT_DAYS_YOUTH,
});

export const activityTypesToAdvancedKeys = Object.freeze({
  [activityTypeIds.CAMPOUTS]: campoutAdvancedKeysToBasicKeys,
  [activityTypeIds.HIKES]: hikeAdvancedKeysToBasicKeys,
  [activityTypeIds.LONG_CRUISE]: longCruisedvancedKeysToBasicKeys,
  [activityTypeIds.SERVICE_PROJECTS]: serviceAdvancedKeysToBasicKeys,
  [activityTypeIds.EAGLE_SERVICE_PROJECT]: eagleProjectAdvancedKeysToBasicKeys,
});

// config for Activity Tabs per activity type:

export const advancedConfig = Object.freeze({
  // [ColumnName, isRequired, validateFloat, isDayDate]
  [activityTypeIds.CAMPOUTS]: [
    ['Days', true, true, true],
    ['Nights', true, true, false],
    ['FrostPoints', false],
  ],
  [activityTypeIds.HIKES]: [
    ['Miles', true, true],
    ['LowestPoint', false, true],
    ['HighestPoint', false, true],
  ],
  [activityTypeIds.LONG_CRUISE]: [['Days', true, false, true]],
  [activityTypeIds.SERVICE_PROJECTS]: [['Hours', true, true]],
});

// inputName, isRequired, validateFloat, isDayDate(true = Day, false = Night, undefined = not date value), enable Input(default disabled when it is a date value)
export const basicConfig = Object.freeze({
  [activityTypeIds.CAMPOUTS]: {
    translationKeys: ['Days', 'Nights', 'FrostPoints'],
    youths: [
      [basicValuesKeys.CAMPOUT_DAYS_YOUTH, true, true, true, true],
      [basicValuesKeys.CAMPOUT_NIGHTS_YOUTH, true, true, false, true],
      [basicValuesKeys.CAMPOUT_FROST_POINTS_YOUTH, false, true],
    ],
    adults: [
      [basicValuesKeys.CAMPOUT_DAYS_ADULT, true, true, true, true],
      [basicValuesKeys.CAMPOUT_NIGHTS_ADULT, true, true, false, true],
      [basicValuesKeys.CAMPOUT_FROST_POINTS_ADULT, false, true],
    ],
  },
  [activityTypeIds.HIKES]: {
    translationKeys: ['Miles', 'LowestPoint', 'HighestPoint'],
    youths: [
      [basicValuesKeys.HIKE_MILES_YOUTH, true, true],
      [basicValuesKeys.HIKE_LOWES_POINT_YOUTH, false, true],
      [basicValuesKeys.HIKE_HIGHEST_POINT_YOUTH, false, true],
    ],
    adults: [
      [basicValuesKeys.HIKE_MILES_ADULT, true, true],
      [basicValuesKeys.HIKE_LOWES_POINT_ADULT, false, true],
      [basicValuesKeys.HIKE_HIGHEST_POINT_ADULT, false, true],
    ],
  },
  [activityTypeIds.LONG_CRUISE]: {
    translationKeys: ['Days'],
    youths: [[basicValuesKeys.LONG_CRUISE_BASIC_YOUTH, true, false, true]],
    adults: [[basicValuesKeys.LONG_CRUISE_BASIC_ADULT, true, false, true]],
  },
  [activityTypeIds.SERVICE_PROJECTS]: {
    translationKeys: ['Hours'],
    mergeTitleKey: 'registeredParicipation',
    merge: [[basicValuesKeys.SERVICE_HOURS_YOUTH, true, true]],
  },
});

export const usAkelaCountryId = 616;

export const validAllDayActivity = Object.freeze({
  [activityTypeIds.CAMPOUTS]: true,
  [activityTypeIds.HIKES]: true,
  [activityTypeIds.LONG_CRUISE]: true,
});

export const cstTimeZone = 'America/Chicago';
