import React from 'react';

/* eslint-disable import/named */
import { Menu as BsaMenu } from 'bsa-ui';

const Menu = props => React.cloneElement(<BsaMenu />, { ...props });

Object.keys(BsaMenu).forEach(key => {
  Menu[key] = BsaMenu[key];
});

Menu.Item = props => <BsaMenu.Item onItemHover={() => {}} {...props} />;

Menu.Item.displayName = BsaMenu.Item.displayName;

Menu.Divider = BsaMenu.Divider;

export default Menu;
