import React from 'react';

import cn from 'classnames';

import PlaceholderIcon from '../../../../assets/images/BSAColorLogo.svg';
import BoyScoutIcon from '../../../../assets/images/BoyScout.svg';
import CubScoutIcon from '../../../../assets/images/CubScout.svg';
import ExploringIcon from '../../../../assets/images/Exploring.svg';
import SeaScoutIcon from '../../../../assets/images/SeaScout.svg';
import VenturingIcon from '../../../../assets/images/Venturing.svg';
import { Program, ProgramId } from '../../constants';
import styles from './ProgramLogo.less';

const programLogos = {
  [Program.CUB_SCOUT]: CubScoutIcon,
  [ProgramId.CUB_SCOUT]: CubScoutIcon,
  [Program.BOY_SCOUT]: BoyScoutIcon,
  [ProgramId.BOY_SCOUT]: BoyScoutIcon,
  [Program.VENTURING]: VenturingIcon,
  [ProgramId.VENTURING]: VenturingIcon,
  [Program.SEA_SCOUT]: SeaScoutIcon,
  [ProgramId.SEA_SCOUT]: SeaScoutIcon,
  [Program.VARSITY]: PlaceholderIcon,
  [ProgramId.VARSITY]: PlaceholderIcon,
  [Program.EXPLORING]: ExploringIcon,
  [ProgramId.EXPLORING]: ExploringIcon,
  [Program.CLUB]: ExploringIcon,
  [ProgramId.CLUB]: ExploringIcon,
};

export interface Props {
  program?: Program;
  programId?: string | number;
  className?: string;
}

const ProgramLogo: React.FC<Props> = ({
  program,
  programId,
  className,
  ...props
}) => {
  const Icon =
    programLogos[(program || Number(programId)) as keyof typeof programLogos] ||
    PlaceholderIcon;

  return (
    <span className={cn(styles.wrapper, className)} {...props}>
      <Icon className={styles.icon} />
    </span>
  );
};

export default ProgramLogo;
