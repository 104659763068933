import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { T } from '@shared';
import UploadItem from '../UploadItem';
import styles from './UploadsList.less';

class UploadsList extends React.PureComponent {
  renderFiles = () => {
    const fileNodes = [
      ...this.renderUploadedFiles(),
      ...this.renderUploadingFile(),
      ...this.renderErroredFile(),
    ];

    if (fileNodes.length === 0) {
      return <FormattedMessage id="advancementUpload.UploadsList.noFiles" />;
    }

    return fileNodes;
  };

  renderUploadedFiles = () => {
    const { uploadedFiles } = this.props;

    return uploadedFiles.map(file => (
      <UploadItem
        key={`${file.name}${file.uploadedDate}`}
        type="uploaded"
        file={file}
      />
    ));
  };

  renderUploadingFile = () => {
    const { uploading, progress, onCancel } = this.props;

    if (!uploading) {
      return [];
    }

    return [
      <UploadItem
        key="uploading"
        type="uploading"
        progress={progress}
        onCancel={onCancel}
      />,
    ];
  };

  renderErroredFile = () => {
    const {
      uploadError,
      selectedFile,
      onRetry,
      uploadErrorDesc,
      onShowErrors,
    } = this.props;

    if (!(uploadError && selectedFile)) {
      return [];
    }

    return [
      <UploadItem
        key="error"
        type="error"
        file={selectedFile}
        onRetry={onRetry}
        errorsCount={uploadErrorDesc.length}
        onShowErrors={onShowErrors}
      />,
    ];
  };

  render() {
    const { className } = this.props;

    return (
      <div className={cn(styles.container, className)}>
        <T size="5" bold colored colon>
          <FormattedMessage id="advancementUpload.UploadsList.files" />
        </T>
        {this.renderFiles()}
      </div>
    );
  }
}

UploadsList.propTypes = {
  className: PropTypes.string,
  uploading: PropTypes.bool.isRequired,
  progress: PropTypes.object.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  uploadError: PropTypes.bool.isRequired,
  selectedFile: PropTypes.object,
  uploadErrorDesc: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onShowErrors: PropTypes.func.isRequired,
};

export default UploadsList;
