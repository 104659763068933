import moment from 'moment';

export default (date, time, tzCode) => {
  const timezoneCode = tzCode || 'America/Chicago';
  const dateStr = moment(date).format('YYYY-MM-DD');
  const timeStr = moment(time).format('HH:mm:ss');
  const mergeDate = moment(`${dateStr} ${timeStr}`);

  const formattedDate = mergeDate.toDate();

  const appliedTz = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'longOffset',
    timeZone: timezoneCode,
  }).format(formattedDate);
  const [, GMT] = appliedTz.split(',');
  const utcOffset = GMT.replace(' GMT', '');

  const formattedOffset = utcOffset.replace(':', '');
  const mergedOffset = moment(mergeDate).utcOffset(formattedOffset, true);
  return moment.utc(mergedOffset).format();
};
