import { sortBy } from 'lodash';

import { getIsDenChief } from '@modules/advancement/packRoster/utilsTyped';

export const groupYouthsAdultsParents = (
  persons,
  selectedUnitsHasDen = false,
) => {
  // Creation/Edit Event ADD invitees/attendees
  const groups = {
    youths: [],
    adults: [],
    parents: [],
    denChiefs: [],
  };

  persons.forEach(p => {
    if (p.isAdult) {
      if (p.isParent && !p.isLeader) {
        groups.parents.push(p);
        return;
      }

      groups.adults.push(p);
      return;
    }

    if (selectedUnitsHasDen && getIsDenChief(p)) {
      groups.denChiefs.push(p);
      return;
    }

    groups.youths.push(p);
  });

  const sortedGroup = {
    youths: sortBy(groups.youths, 'firstName'),
    adults: sortBy(groups.adults, 'firstName'),
    parents: sortBy(groups.parents, 'firstName'),
    denChiefs: sortBy(groups.denChiefs, 'firstName'),
  };

  return sortedGroup;
};
