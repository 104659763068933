import moment from 'moment';

import { ENV } from '@config';
import { dtoDateFormat } from '@shared';
import { checkBrowser, esbApiService } from '@utils';

// https://stackoverflow.com/a/31732310

export const uploadFile$ = (file, organizationGuid, progressSubscriber) => {
  const fd = new FormData();
  const name = `import_${moment().format(dtoDateFormat)}.txt`;
  let newFile;
  // Edge does not support File constructor -_-
  // but we can fall back to Blob
  // https://stackoverflow.com/questions/40911927/instantiate-file-object-in-microsoft-edge/43241922
  try {
    newFile = new File([file], name, { type: 'text/plain' });
  } catch (e) {
    newFile = new Blob([file], { type: 'text/plain' });
    newFile.name = name;
  }

  fd.append('file', newFile);
  fd.append('name', name);

  return esbApiService.post$(
    `/persons/advancementImports?organizationGuid=${organizationGuid}`,
    fd,
    {
      progressSubscriber,
      gtm: {
        label: '/persons/advancementImports',
      },
      // (hopefully) temporary workaround - file uploads fail with 405 when going through Service Worker
      // but only on non-localhost environments
      // so we conditionally bypass SW if on Safari
      // TODO: find a proper fix
      skipSw: ENV !== 'local' && checkBrowser.isSafari,
    },
  );
};

export const cancelPendingFile$ = id =>
  esbApiService.delete$(`/persons/advancementImports/${id}`, null, {
    gtm: {
      label: '/persons/advancementImports/{id}',
    },
  });
