import React, { useCallback, useMemo } from 'react';

import cn from 'classnames';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_SUB_UNITS } from '@config';
import {
  deleteSubUnitRequest,
  setSelectedSubUnitId,
  showSetSubUnitModal,
} from '@modules/advancement/subUnits/duck/actions';
import { subUnitTypeSel } from '@modules/advancement/subUnits/duck/selectors';
import {
  ExpandToggle,
  denTypesSingleNames,
  hasPermissionSel,
  permissions,
  selectedOrganizationSel,
} from '@shared';

import {
  currentUserSubunitsSel,
  setSubUnitsSectionExpanded,
} from '../../../../duck';
import styles from './MainRow.less';
import MainRowButtons from './MainRowButtons';

function MainRow(props) {
  const {
    subUnitId,
    subUnitName,
    subUnitNameRaw,
    denType,
    itemsCount,
    expanded,
    hideExpanded = false,
  } = props;
  const dispatch = useDispatch();
  const isSubUnit = !!subUnitName;
  const subUnitTypeName = useSelector(subUnitTypeSel);
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );
  const mySubUnits = useSelector(currentUserSubunitsSel);
  const { canEditSubCalendar: hasSubUnitOnlyPerms } = useSelector(
    selectedOrganizationSel,
  );

  const canDoActions = useMemo(() => {
    if (hasSubUnitOnlyPerms && !canEditSubUnits) {
      const hasMatchingSubUnits = mySubUnits.some(sub => {
        const mySubId = sub.denId || sub.patrolId;
        return mySubId === subUnitId;
      });
      return hasMatchingSubUnits;
    }

    return canEditSubUnits;
  }, [mySubUnits, hasSubUnitOnlyPerms, subUnitId, canEditSubUnits]);

  const denTypeDisplay = useMemo(() => {
    if (!denType) {
      return '';
    }
    return denTypesSingleNames[denType]
      ? `${capitalize(denTypesSingleNames[denType])} `
      : `${capitalize(denType)} `;
  }, [denType]);

  const handleOnClick = useCallback(() => {
    dispatch(setSubUnitsSectionExpanded(subUnitId, !expanded));
  }, [dispatch, subUnitId, expanded]);

  const handleOnDeleteSubUnit = useCallback(() => {
    dispatch(deleteSubUnitRequest(subUnitId));
  }, [dispatch, subUnitId]);

  const handleOnEditSubUnit = useCallback(() => {
    dispatch(setSelectedSubUnitId(subUnitId));
    dispatch(showSetSubUnitModal());
  }, [dispatch, subUnitId]);

  return (
    <div className={styles.row}>
      <div className={styles.denInfoWrapper}>
        <div>
          {isSubUnit ? (
            <FormattedMessage
              id="packRoster.MainRow.subUnitRowName"
              values={{
                subUnitTypeName: capitalize(subUnitTypeName),
                subUnitName: subUnitNameRaw,
                denType: denTypeDisplay,
              }}
            />
          ) : (
            <FormattedMessage id="packRoster.MainRow.unassigned" />
          )}
          <span className={styles.count}>({itemsCount})</span>
        </div>
        {!hideExpanded && (
          <ExpandToggle
            className={cn(styles.chevron, {
              [styles.noDisplay]: false,
            })}
            expanded={expanded}
            onClick={handleOnClick}
          />
        )}
      </div>
      {EDIT_SUB_UNITS && isSubUnit && subUnitName && canDoActions && (
        <MainRowButtons
          deleteConfirmationMessage={
            <FormattedHTMLMessage
              id="packRoster.MainRow.confirmDeleteSubUnit"
              values={{
                subUnitName: subUnitName,
                subUnitTypeName: capitalize(subUnitTypeName),
              }}
            />
          }
          onDelete={handleOnDeleteSubUnit}
          onEdit={handleOnEditSubUnit}
        />
      )}
    </div>
  );
}

MainRow.propTypes = {
  subUnitId: PropTypes.number,
  subUnitName: PropTypes.string,
  subUnitNameRaw: PropTypes.string,
  denType: PropTypes.string,
  itemsCount: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  hideExpanded: PropTypes.bool,
};

export default MainRow;
