import { activityTypes } from '@shared';

import { activityItemsTypes } from '../common';

export {
  advancementHistoryModuleName as moduleName,
  historyItemsTypes,
} from '@shared/constants';

export {
  advancementTypeFilters,
  advancementStatusFilters,
} from '@modules/advancement/common/constants';

export const pendingMainRow = Object.freeze({
  isMainRow: true,
  type: activityItemsTypes.PENDING_APPROVAL,
  key: activityItemsTypes.PENDING_APPROVAL,
});

export const historyMainRow = Object.freeze({
  isMainRow: true,
  type: activityItemsTypes.HISTORY,
  key: activityItemsTypes.HISTORY,
});

export const updatedDateFilters = Object.freeze({
  TODAY: 'today',
  LAST_30_DAYS: 'last30Days',
  LAST_365_DAYS: 'last365Days',
  ALL: 'all',
});

// values match API param names
export const activityTypeFilters = Object.freeze({
  SERVICE: 'showService',
  CAMPING: 'showCamping',
  HIKING: 'showHiking',
  LONG_CRUISE: 'showLongCruise',
  EAGLE_SERVICE_PROJECT: 'showEagleProject',
});

export const activityTypesToFilterType = Object.freeze({
  [activityTypes.SERVICE_PROJECTS]: activityTypeFilters.SERVICE,
  [activityTypes.CAMPOUTS]: activityTypeFilters.CAMPING,
  [activityTypes.HIKES]: activityTypeFilters.HIKING,
  [activityTypes.LONG_CRUISE]: activityTypeFilters.LONG_CRUISE,
  [activityTypes.EAGLE_SERVICE_PROJECT]:
    activityTypeFilters.EAGLE_SERVICE_PROJECT,
});

export const lastUpdatedDateAll = '1970-01-01';

// values match API param names
export const peopleFilters = Object.freeze({
  YOUTH: 'showYouths',
  ADULTS: 'showAdults',
});

export const visibilityFields = Object.freeze({
  MEMBER_ID: 'memberId',
  DATE: 'updatedDate',
  RECORDED_BY: 'recordedBy',
});
