import { gtm } from '@modules/shared/utils';

import { moduleName } from '../constants';
import { personGuidSel } from './selectors';
import service from './services';

export {
  LOGOUT,
  logout,
  LOGIN_RESPONSE,
  loginResponse,
  SELFSESSION_RESPONSE,
  selfsessionResponse,
  MASQUERADE_RESPONSE,
  masqueradeResponse,
  CONTINUE_LOGIN,
  continueLogin,
} from '@shared/duck';

export const ROUTE_LOGIN = `${moduleName}/ROUTE_LOGIN`;
// 3rd-party auth redirect
export const ROUTE_AUTH_REDIRECT = `${moduleName}/ROUTE_AUTH_REDIRECT`;
export const ROUTE_CHOOSE_SCOUTBOOK = `${moduleName}/ROUTE_CHOOSE_SCOUTBOOK`;
export const ROUTE_NO_ACCESS = `${moduleName}/ROUTE_NO_ACCESS`;
export const ROUTE_SAND_BOX = `${moduleName}/ROUTE_SAND_BOX`;

export const GOOGLE_LOGIN_VALIDATE = `${moduleName}/GOOGLE_LOGIN_VALIDATE`;
export const googleLoginValidate = googleUser => ({
  type: GOOGLE_LOGIN_VALIDATE,
  payload: googleUser,
  gtm: {
    category: gtm.categories.USER,
  },
});
export const APPLE_LOGIN_VALIDATE = `${moduleName}/APPLE_LOGIN_VALIDATE`;
export const appleLoginValidate = token => ({
  type: APPLE_LOGIN_VALIDATE,
  payload: token,
  gtm: {
    category: gtm.categories.USER,
  },
});

export const GOOGLE_LOGIN_ERROR = `${moduleName}/GOOGLE_LOGIN_ERROR`;
export const googleLoginError = error => ({
  type: GOOGLE_LOGIN_ERROR,
  payload: error,
  gtm: {
    category: gtm.categories.USER,
  },
});

export const LOGIN_REQUEST = `${moduleName}/LOGIN_REQUEST`;
export const LOGIN_ERROR = `${moduleName}/LOGIN_ERROR`;
export const loginRequest = (login, password) => ({
  type: LOGIN_REQUEST,
  payload: { login, password },
  gtm: {
    category: gtm.categories.USER,
    label: login,
  },
});
export const loginError = error => ({
  type: LOGIN_ERROR,
  payload: error,
  gtm: {
    category: gtm.categories.USER,
  },
});

export const SELFSESSION_REQUEST = `${moduleName}/SELFSESSION_REQUEST`;
export const SELFSESSION_ERROR = `${moduleName}/SELFSESSION_ERROR`;
export const selfsessionRequest = () => ({
  type: SELFSESSION_REQUEST,
  gtm: {
    category: gtm.categories.USER,
  },
});
export const selfsessionError = error => ({
  type: SELFSESSION_ERROR,
  payload: error,
  gtm: {
    category: gtm.categories.USER,
  },
});
export const SELFSESSION_SKIP_CHECK = `${moduleName}/SELFSESSION_SKIP_CHECK`;
export const selfsessionSkipCheck = () => ({
  type: SELFSESSION_SKIP_CHECK,
});

export const OPEN_MASQUERADE_MODAL = `${moduleName}/OPEN_MASQUERADE_MODAL`;
export const CLOSE_MASQUERADE_MODAL = `${moduleName}/CLOSE_MASQUERADE_MODAL`;
export const openMasqueradeModal = () => ({
  type: OPEN_MASQUERADE_MODAL,
});
export const closeMasqueradeModal = () => ({
  type: CLOSE_MASQUERADE_MODAL,
});

export const MASQUERADE_REQUEST = `${moduleName}/MASQUERADE_REQUEST`;
export const MASQUERADE_ERROR = `${moduleName}/MASQUERADE_ERROR`;
export const masqueradeRequest = (
  memberId,
  suppressErrors = false,
  editWhileMasquerading = false,
) => ({
  type: MASQUERADE_REQUEST,
  payload: memberId,
  suppressErrors,
  editWhileMasquerading,
  gtm: {
    category: gtm.categories.USER,
  },
});
export const masqueradeError = error => ({
  type: MASQUERADE_ERROR,
  payload: error,
  gtm: {
    category: gtm.categories.USER,
  },
});

export const USER_DATA_REQUEST = `${moduleName}/USER_DATA_REQUEST`;
export const USER_DATA_RESPONSE = `${moduleName}/USER_DATA_RESPONSE`;
export const USER_DATA_ERROR = `${moduleName}/USER_DATA_ERROR`;
export const userDataRequest = () => ({ type: USER_DATA_REQUEST });
export const userDataResponse = userData => ({
  type: USER_DATA_RESPONSE,
  payload: userData,
});

export const userDataError = error => ({
  type: USER_DATA_ERROR,
  payload: error,
});

export const OPEN_TIMEOUT_MODAL = `${moduleName}/OPEN_TIMEOUT_MODAL`;
export const CLOSE_TIMEOUT_MODAL = `${moduleName}/CLOSE_TIMEOUT_MODAL`;
export const openTimeoutModal = () => ({ type: OPEN_TIMEOUT_MODAL });
export const closeTimeoutModal = () => ({ type: CLOSE_TIMEOUT_MODAL });

export const REFRESH_SESSION_REQUEST = `${moduleName}/REFRESH_SESSION_REQUEST`;
export const REFRESH_SESSION_RESPONSE = `${moduleName}/REFRESH_SESSION_RESPONSE`;
export const REFRESH_SESSION_ERROR = `${moduleName}/REFRESH_SESSION_ERROR`;
export const refreshSessionRequest = () => ({ type: REFRESH_SESSION_REQUEST });
export const refreshSessionResponse = payload => ({
  type: REFRESH_SESSION_RESPONSE,
  payload,
});
export const refreshSessionError = error => ({
  type: REFRESH_SESSION_ERROR,
  payload: error,
});

export const SET_DEFAULT_APP = `${moduleName}/SET_DEFAULT_APP`;
export const setDefaultApp = app => (dispatch, getState) => {
  const personGuid = personGuidSel(getState());
  service.storeDefaultApp(personGuid, app);

  return dispatch({
    type: SET_DEFAULT_APP,
    payload: app,
  });
};

export const SET_USER_DATA = `${moduleName}/SET_USER_DATA`;
export const setUserData = userData => ({
  type: SET_USER_DATA,
  payload: userData,
});

export const AUTH_REDIRECT_REQUEST = `${moduleName}/AUTH_REDIRECT_REQUEST`;
export const AUTH_REDIRECT_SUCCESS = `${moduleName}/AUTH_REDIRECT_SUCCESS`;
export const AUTH_REDIRECT_ERROR = `${moduleName}/AUTH_REDIRECT_ERROR`;

export const authRedirectRequest = payload => ({
  type: AUTH_REDIRECT_REQUEST,
  payload,
});
export const authRedirectSuccess = payload => ({
  type: AUTH_REDIRECT_SUCCESS,
  payload,
});

export const authRedirectError = payload => ({
  type: AUTH_REDIRECT_ERROR,
  payload,
});
