import React from 'react';

import cn from 'classnames';

import styles from './S.less';

export interface SProps {
  /**
   * Sets a font-size where `'1'` is the biggest, and `'6'` the smallest
   */
  size?: '1' | '2' | '3' | '4' | '5' | '6';
  hr?: boolean;
  semibold?: boolean;
  informative?: boolean;
  bold?: boolean;
  colored?: boolean;
  colon?: boolean;
  inline?: boolean;
  inlineBlock?: boolean;
  serif?: boolean;
  clickable?: boolean;
  className?: string;
  onClick?: React.DOMAttributes<HTMLSpanElement>['onClick'];
  style?: React.CSSProperties;
  children?: React.ReactNode;
  id?: string;
}

const S: React.FC<SProps> = ({
  size,
  hr = false,
  semibold = false,
  bold = false,
  colored = false,
  informative = false,
  colon = false,
  inline = false,
  inlineBlock = false,
  serif = false,
  clickable = false,
  className,
  children,
  ...rest
}) => {
  const sizeClassName = `subtitle-${size}`;
  const classNames = cn(
    sizeClassName,
    styles.block,
    {
      'text-primary': colored,
      [styles.hr]: hr,
      [styles.semibold]: semibold,
      [styles.bold]: bold,
      [styles.inline]: inline,
      [styles.inlineBlock]: inlineBlock,
      [styles.informativeColor]: informative,
      [styles.serif]: serif,
      [styles.clickable]: clickable,
    },
    className,
  );

  return (
    <span className={classNames} {...rest}>
      {children}
      {colon && ':'}
    </span>
  );
};

export default S;
