export default (text, fileName = 'file.txt') => {
  const element = document.createElement('a');
  const file = new Blob([text], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  // for click() event to work in FF, the node must be attached to the document
  document.body.appendChild(element);
  element.click();
  element.parentNode.removeChild(element);
};
