import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';

import { payloadSel } from '../../../location/duck/selectors';
import {
  ProgramId,
  advancementModuleName,
  advancementReportModuleName,
  contextModuleName,
  coreModuleName,
  councilUnitsModuleName,
  dictModuleName,
  dictionaryIds,
  emptyArr,
  emptyObj,
  lgWidth,
  mdWidth,
  moduleName,
  offlineModuleName,
  packRosterModuleName,
  screenLg,
  screenMd,
  screenModuleName,
  screenXl,
  unitTypeIdForUnitType,
  userModuleName,
} from '../../constants';
import { getUserPermissions, permissions } from '../../permissions';
import extractOrgGuidAndUserId from '../../utils/extractOrgGuidAndUserId';

const moduleSel = state => state[moduleName];
const coreModuleSel = state => state[coreModuleName];
const userModuleSel = state => state[userModuleName];

export const tablePageSizeSel = state => moduleSel(state).tablePageSize;
export const userPreferencesSel = (state, featureName) =>
  moduleSel(state).userPreferences[featureName];

// Core
export const appBootstrappedSel = state => coreModuleSel(state).appBootstrapped;
export const headerVisibleSel = state => coreModuleSel(state).headerVisible;
export const sidebarOpenSel = state => coreModuleSel(state).sidebarOpen;
export const screenModuleSel = state => state[screenModuleName];
export const minModeWidthSel = state => screenModuleSel(state).min;
export const screenModeSel = state => screenModuleSel(state).mode;
export const isScreenXlSel = state => screenModeSel(state) === screenXl;
export const isScreenAtLeastXlSel = state =>
  isScreenXlSel(state) || minModeWidthSel(state) > lgWidth;
export const sidebarExpandedSel = state =>
  isScreenAtLeastXlSel(state) && coreModuleSel(state).sidebarExpandedPreferred;
export const modalOpenSel = state => coreModuleSel(state).modalOpenCount !== 0;

// Dict
const dicModuleSel = state => state[dictModuleName];
const dataSel = state => dicModuleSel(state).data;
export const dictionarySel = (state, id) => dataSel(state)[id];
export const adventuresSel = state =>
  dictionarySel(state, dictionaryIds.ADVENTURES) || emptyArr;
export const adventuresByIdSel = createSelector(adventuresSel, adventures =>
  keyBy(adventures, 'id'),
);
export const allAwardsSel = state =>
  dictionarySel(state, dictionaryIds.AWARDS) || emptyArr;
//displays only active awards
export const awardsSel = createSelector(allAwardsSel, awards =>
  awards.filter(({ expiredDate }) => !expiredDate),
);
export const ranksSel = state =>
  dictionarySel(state, dictionaryIds.RANKS) || emptyArr;
export const ranksByIdSel = createSelector(ranksSel, ranks =>
  keyBy(ranks, 'id'),
);

export const allSSElectivesSel = state =>
  dictionarySel(state, dictionaryIds.SSELECTIVES) || emptyArr;

export const ssElectivesSel = createSelector(allSSElectivesSel, ssElectives =>
  ssElectives.map(ssElective => ({
    ...ssElective,
    id: ssElective.ssElectiveId,
    name: ssElective.ssName,
  })),
);

export const cubRanksSel = createSelector(ranksSel, ranks =>
  ranks.filter(rank => +rank.programId === ProgramId.CUB_SCOUT),
);
export const meritBadgesSel = state =>
  dictionarySel(state, dictionaryIds.MERIT_BADGES) || emptyArr;
export const meritBadgesByIdSel = createSelector(meritBadgesSel, mbs =>
  keyBy(mbs, 'id'),
);

// User
export const loginDataSel = state => userModuleSel(state).loginData;
export const safeLoginDataSel = state => loginDataSel(state) || emptyObj;
/** @returns {string | null} */
export const personGuidSel = state => safeLoginDataSel(state).personGuid;
export const userIdSel = state => userModuleSel(state).account?.userId;
export const userDataSel = state => userModuleSel(state).userData;
export const safeUserDataSel = state => userDataSel(state) || emptyObj;
export const masqueradeLoadingSel = state =>
  userModuleSel(state).masqueradeLoading;
export const masqueradingSel = state => userModuleSel(state).masquerading;
export const editWhileMasqueradingSel = state =>
  userModuleSel(state).editWhileMasquerading;
export const showDeleteEmailModalSel = state =>
  moduleSel(state).showDeleteEmailModal;
export const showDeleteAddressModalSel = state =>
  moduleSel(state).showDeleteAddressModal;
export const showDeletePhoneModalSel = state =>
  moduleSel(state).showDeletePhoneModal;
export const isLoadingDeleteEmailModalSel = state =>
  moduleSel(state).isLoadingDeleteEmailModal;
export const cassResultsSel = state => moduleSel(state).cassResults;
export const triggerCalendarRequestSel = state =>
  moduleSel(state).triggerCalendarRequest;
export const triggerAllRostersRequestSel = state =>
  moduleSel(state).triggerAllRostersRequest;

// Pack Roster
export const advancementModuleSel = state => state[advancementModuleName];
export const packRosterModuleSel = state =>
  advancementModuleSel(state)[packRosterModuleName];
export const packRosterLoadingSel = state => packRosterModuleSel(state).loading;
export const packRosterItemsSel = state => {
  /** @type {import('@modules/advancement/duck/types').RosterScout[]} */
  const result = packRosterModuleSel(state).items;
  return result;
};
export const packRosterItemsCountSel = createSelector(
  packRosterItemsSel,
  rosterItems => rosterItems?.length || 0,
);
export const rosterYouthSelectedSel = createSelector(
  packRosterModuleSel,
  rosterModule => rosterModule.selectedKeys || [],
);
export const packRosterAllItemsByMIDSel = createSelector(
  packRosterItemsSel,
  roster => {
    const result = keyBy(roster, item => {
      if (!item) return null;
      return +item.memberId;
    });
    return result;
  },
);

// advancementReport
export const advancementReportModuleSel = state =>
  state[advancementReportModuleName];
export const allReportSel = state =>
  advancementReportModuleSel(state).allReport;
export const reportInfoSel = state =>
  advancementReportModuleSel(state).reportInfo;
export const selectedUserIdsSel = state =>
  advancementReportModuleSel(state).userIds || [];
/** @returns Set<number> */
export const selectedUserIdsSetSel = state =>
  new Set(selectedUserIdsSel(state).map(id => +id)) || new Set();
export const usersReportSel = createSelector(
  allReportSel,
  selectedUserIdsSetSel,
  (allReport, userIdsSet) => {
    if (userIdsSet.size === 0) {
      return allReport;
    }
    return allReport.filter(({ youth: { userId } }) => userIdsSet.has(+userId));
  },
);
export const notAwardedAdvancementsCountSel = state =>
  usersReportSel(state).length;
export const allNotAwardedAdvancementsCountSel = state =>
  allReportSel(state).length;

// screen
export const maxModeWidthSel = state => screenModuleSel(state).max;
export const isScreenMdSel = state => screenModeSel(state) === screenMd;
// alias for convenience
export const isMobileSel = state =>
  isScreenMdSel(state) || maxModeWidthSel(state) <= mdWidth;
export const isScreenLgSel = state => screenModeSel(state) === screenLg;
export const isScreenAtLeastLgSel = state =>
  isScreenLgSel(state) || minModeWidthSel(state) > mdWidth;

// Offline
const offlineModuleSel = state => state[offlineModuleName];
export const manualOfflineSel = state => offlineModuleSel(state).manualOffline;
export const offlineSel = state =>
  offlineModuleSel(state).offline || manualOfflineSel(state);

// councilUnits
const councilUnitsModuleSel = state => state[councilUnitsModuleName];
export const recentUnitsSel = state => councilUnitsModuleSel(state).recentUnits;

// Context
export const contextModuleSel = state => state[contextModuleName];
export const organizationPositionsSel = state => {
  const orgs = (userDataSel(state) || {}).organizations;
  return orgs || [];
};

const organizationsGuidsSel = createSelector(
  organizationPositionsSel,
  organizationPositions =>
    organizationPositions &&
    organizationPositions.map(({ organizationGuid }) => organizationGuid),
);
export const defaultOrganizationGuidSel = state =>
  contextModuleSel(state).defaultOrganizationGuid;

const safeDefaultOrganizationGuidSel = createSelector(
  organizationsGuidsSel,
  defaultOrganizationGuidSel,
  (organizationsGuids, defaultOrganizationGuid) => {
    if (!organizationsGuids || !organizationsGuids.length) {
      return null;
    }
    if (
      !defaultOrganizationGuid ||
      !organizationsGuids.includes(defaultOrganizationGuid)
    ) {
      return organizationsGuids[0];
    }
    return defaultOrganizationGuid;
  },
);

export const organizationGuidSel = state => {
  let organizationGuid =
    contextModuleSel(state).organizationGuid ||
    safeDefaultOrganizationGuidSel(state);
  const { orgGuid } = extractOrgGuidAndUserId(organizationGuid);
  return orgGuid;
};

export const childUserIdSel = state => {
  let organizationGuid =
    contextModuleSel(state).organizationGuid ||
    safeDefaultOrganizationGuidSel(state);
  const { userId } = extractOrgGuidAndUserId(organizationGuid);
  return userId;
};

/**
 * Returns the current org guid for the parent/guardian role selected.
 * Since the org GUID is <orgGuid>*<userId> you can control using the
 * `extractOrgGuid` to either just return `orgGuid` or both.
 *
 * @param {boolean} [extractOrgGuid] If `true`, returns only the org guid without the `userId`
 **/
export const parentOrgGuidSel = (state, extractOrgGuid = false) => {
  const organizationGuid = contextModuleSel(state).parentOrgGuid;
  const { orgGuid } = extractOrgGuidAndUserId(organizationGuid);
  return extractOrgGuid ? orgGuid : organizationGuid;
};

export const organizationPositionSel = createSelector(
  organizationPositionsSel,
  parentOrgGuidSel,
  (organizationPositions, selectedOrganizationGuid) => {
    /** @type {import('@modules/user/duck/types').UserOrganizationInfo} */
    const orgInfo =
      (organizationPositions &&
        organizationPositions.find(
          ({ organizationGuid }) =>
            organizationGuid === selectedOrganizationGuid,
        )) ||
      {};
    return orgInfo;
  },
);

export const isCouncilOrgActiveSel = createSelector(
  organizationPositionSel,
  org => org && org.isCouncil,
);

export const childOrganizationSel = state =>
  contextModuleSel(state).childOrganization || emptyObj;

export const selectedOrganizationSel = createSelector(
  organizationPositionSel,
  childOrganizationSel,
  (
    organizationPosition,
    /** @type {import('@modules/user/duck/types').UserOrganizationInfo} */
    childOrganization,
  ) => {
    if (organizationPosition && organizationPosition.isCouncil) {
      return childOrganization;
    }
    return organizationPosition;
  },
);

export const isSelectedOrganizationValidSel = createSelector(
  selectedOrganizationSel,
  selectedOrganization =>
    !!selectedOrganization && !isEmpty(selectedOrganization),
);

/** @returns {number} Unit Type ID */
export const unitTypeIdSel = state =>
  (selectedOrganizationSel(state) || {}).unitTypeId;

export const organizationTypeIdSel = state =>
  (selectedOrganizationSel(state) || {}).organizationTypeId || 6; // default to 6 (Unit)

export const userRoleSel = state => selectedOrganizationSel(state).role || '';

export const useDenDetailsArrSel = state =>
  selectedOrganizationSel(state).denDetailsArr || [];

export const hasCouncilPositionSel = createSelector(
  userDataSel,
  user => user.isCouncil,
);

export const hasCouncilEditRightsActiveOrgSel = createSelector(
  organizationPositionSel,
  org => org.hasCouncilEditRights,
);

export const hasCouncilEditRightsSel = createSelector(
  userDataSel,
  user => user.hasCouncilEditRights,
);

export const isMeritBadgeCounselorSel = createSelector(
  organizationPositionSel,
  org => org.isMeritBadgeCounselor,
);

export const isYouthMemberSel = createSelector(organizationPositionSel, org =>
  Boolean(org.isYouthMember),
);

export const isParentGuardianSel = createSelector(
  organizationPositionSel,
  org => Boolean(org.isParentGuardian),
);

export const isScoutbookRoleSel = createSelector(organizationPositionSel, org =>
  Boolean(org.isScoutbookRole),
);

export const isKey3Sel = createSelector(organizationPositionSel, org =>
  Boolean(org.isKey3),
);

export const isCouncilAdminSel = createSelector(organizationPositionSel, org =>
  Boolean(org.isCouncilAdmin),
);

export const isParentOrYouthMemberSel = createSelector(
  isYouthMemberSel,
  isParentGuardianSel,
  (isYouthMember, isParentGuardian) => isYouthMember || isParentGuardian,
);

export const youthMemberPersonGuidSel = createSelector(
  organizationPositionSel,
  isYouthMemberSel,
  isParentGuardianSel,
  (org, isYouthMember, isParentGuardian) =>
    (isParentGuardian || isYouthMember) && org.personGuid,
);

export const permissionsSel = createSelector(
  userDataSel,
  organizationPositionSel,
  masqueradingSel,
  editWhileMasqueradingSel,
  isSelectedOrganizationValidSel,
  (
    userData,
    orgInfo,
    isMasquerading,
    editWhileMasquerading,
    isSelectedOrganizationValid,
  ) => {
    const permissions = getUserPermissions({
      ...userData,
      activeOrganization: orgInfo,
      isMasquerading,
      editWhileMasquerading,
      isSelectedOrganizationValid,
    });

    return permissions;
  },
);

export const hasPermissionSel = (state, ...permissions) => {
  const permissionsObject = permissionsSel(state);
  const isAllowed = permissions.some(
    permission => !!permissionsObject[permission],
  );
  return isAllowed;
};

export const urlUserIdSel = createSelector(
  payloadSel,
  payload => +payload.userId,
);

export const canEditSubUnitsSel = state =>
  hasPermissionSel(state, permissions.EDIT_SUB_UNITS);

export const shouldFilterUsersBySubunitsSel = createSelector(
  canEditSubUnitsSel,
  selectedOrganizationSel,
  (canEditSubUnits, selectedOrganization) => {
    const { canEditSubCalendar: hasSubUnitOnlyPerms, unitTypeId } =
      selectedOrganization;

    const shouldFilterUsersBySubunits =
      [unitTypeIdForUnitType.Troop, unitTypeIdForUnitType.Pack].includes(
        Number(unitTypeId),
      ) &&
      hasSubUnitOnlyPerms &&
      !canEditSubUnits;
    return shouldFilterUsersBySubunits;
  },
);
