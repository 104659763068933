import React from 'react';

import { Switch as BsaSwitch } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Switch.less';

class Switch extends React.Component {
  render() {
    const { className, size, checkedColor, ...rest } = this.props;
    const classNames = cn(
      styles[checkedColor],
      {
        [styles.large]: size === 'large',
      },
      className,
    );

    return (
      <BsaSwitch
        className={classNames}
        size={size === 'large' ? 'default' : size}
        {...rest}
      />
    );
  }
}

Switch.propTypes = {
  className: PropTypes.string,
  checkedColor: PropTypes.oneOf([
    'scouting-red',
    'default',
    'information-blue',
  ]),
  size: PropTypes.oneOf(['large', 'default', 'small']),
};

Switch.defaultProps = {
  checkedColor: 'scouting-red',
};

export default Switch;
