import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { Recipients } from '@modules/messaging/types';
import { resetOnLogoutReducer } from '@utils';

import { getRecipientsResponse } from './actions';

const recipients = createReducer<Recipients>(
  { leaders: [], parents: [], youth: [] },
  builder => {
    builder.addCase(getRecipientsResponse, (_, { payload }) => payload);
  },
);

const reducer = combineReducers({
  recipients,
});

export default resetOnLogoutReducer(reducer);
