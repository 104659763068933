import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { EditButton as EditButtonView } from '../../../../common';
import { detailModes } from '../../../constants';
import { setDetailMode, selectedLongCruiseSel } from '../../../duck';

// eslint-disable-next-line react/prop-types
const EditButton = ({ visible }) => {
  const dispatch = useDispatch();

  const handleEditClick = useCallback(
    () => dispatch(setDetailMode(detailModes.EDIT)),
    [dispatch],
  );

  const handleEditSeriesClick = useCallback(
    () => dispatch(setDetailMode(detailModes.EDIT_SERIES)),
    [dispatch],
  );

  const recurring = useSelector(
    state => (selectedLongCruiseSel(state) || {}).recurring,
  );

  return (
    visible && (
      <EditButtonView
        isRecurring={!!recurring}
        onEditClick={handleEditClick}
        onEditSeriesClick={handleEditSeriesClick}
      />
    )
  );
};

export default EditButton;
