import React from 'react';
import PropTypes from 'prop-types';

import MainRow from './MainRow';

const CustomRow = props => {
  const { children } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, second] = children;
  const {
    props: { record },
  } = second;
  const { mainRows } = record;
  if (mainRows && Array.isArray(mainRows)) {
    const mainRowsNodes = mainRows.map(mainRow => (
      <MainRow key={mainRow.key} {...mainRow} />
    ));
    return [...mainRowsNodes, <tr key="tr" {...props} />];
  }
  if (record.isMainRow) {
    return <MainRow {...record} />;
  }
  return <tr {...props} />;
};

CustomRow.propTypes = {
  children: PropTypes.array.isRequired,
};

export default CustomRow;
