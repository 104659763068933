import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Row, Col, Form, Input, T, intl } from '@shared';
import ActivityValueInput from '../ActivityValueInput';

const FormItem = Form.Item;

const options = {
  initialValue: undefined,
  normalize: value =>
    !Number.isNaN(value) && value >= 0 ? parseInt(value, 10) : null,
};

const hoursRules = {
  pattern: /^[+-]?\d+(\.\d+)?$/,
};

class NonRegisteredPersons extends React.PureComponent {
  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Row type="flex" justify="space-between">
        <Col span={24}>
          <T size="5" bold colored colon>
            <FormattedMessage id="activity.NonRegisteredPersons.nonRegisteredYouth" />
          </T>
        </Col>
        <Col xs={24} lg={11}>
          <FormItem>
            {getFieldDecorator(
              'nonRegisteredYouthCount',
              options,
            )(
              <Input
                size="large"
                type="number"
                min="0"
                step="1"
                placeholder={intl.formatMessage({
                  id: 'activity.NonRegisteredPersons.field.nonRegisteredYouthCount.placeholder',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
        <Col xs={24} lg={11}>
          <ActivityValueInput
            valueName={
              <FormattedMessage id="activity.NonRegisteredPersons.field.nonRegisteredYouthHours.placeholder" />
            }
            name="nonRegisteredYouthHours"
            rules={hoursRules}
            noMargin
          />
        </Col>
        <Col span={24}>
          <T size="5" bold colored colon>
            <FormattedMessage id="activity.NonRegisteredPersons.nonRegisteredAdults" />
          </T>
        </Col>
        <Col xs={24} lg={11}>
          <FormItem>
            {getFieldDecorator(
              'nonRegisteredAdultCount',
              options,
            )(
              <Input
                size="large"
                type="number"
                min="0"
                step="1"
                placeholder={intl.formatMessage({
                  id: 'activity.NonRegisteredPersons.field.nonRegisteredAdultCount.placeholder',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
        <Col xs={24} lg={11}>
          <ActivityValueInput
            valueName={
              <FormattedMessage id="activity.NonRegisteredPersons.field.nonRegisteredAdultHours.placeholder" />
            }
            name="nonRegisteredAdultHours"
            rules={hoursRules}
            noMargin
          />
        </Col>
      </Row>
    );
  }
}

NonRegisteredPersons.propTypes = {
  form: PropTypes.object.isRequired,
};

export default NonRegisteredPersons;
