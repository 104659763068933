import { advancementTypes } from '../constants';

// brute-force method of dealing with advancement types being written in many different ways throughout the API

const dict = {
  awards: advancementTypes.AWARDS,
  award: advancementTypes.AWARDS,
  adventures: advancementTypes.ADVENTURES,
  adventure: advancementTypes.ADVENTURES,
  ranks: advancementTypes.RANKS,
  rank: advancementTypes.RANKS,
  meritbadges: advancementTypes.MERIT_BADGES,
  meritbadge: advancementTypes.MERIT_BADGES,
};

export default (advancementType = '') => dict[advancementType.toLowerCase()];
