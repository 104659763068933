import { ENABLE_VENTURING_RANKS } from '@config';
import { ProgramId, advancementTypes } from '@shared/constants';

export const moduleName = 'unit';

const extraVenturingPrograms = ENABLE_VENTURING_RANKS
  ? [advancementTypes.RANKS]
  : [];

export const unitsAvailableAdvancements = Object.freeze({
  [ProgramId.CUB_SCOUT]: [
    advancementTypes.ADVENTURES,
    advancementTypes.AWARDS,
    advancementTypes.RANKS,
  ],
  [ProgramId.BOY_SCOUT]: [
    advancementTypes.MERIT_BADGES,
    advancementTypes.AWARDS,
    advancementTypes.RANKS,
  ],
  [ProgramId.VARSITY]: [],
  [ProgramId.VENTURING]: [advancementTypes.AWARDS, ...extraVenturingPrograms],
  [ProgramId.SEA_SCOUT]: [
    advancementTypes.AWARDS,
    advancementTypes.RANKS,
    advancementTypes.SSELECTIVES,
  ],
  [ProgramId.EXPLORING]: [advancementTypes.AWARDS],
  [ProgramId.CLUB]: [advancementTypes.AWARDS],
});

export const boyScoutAdvancementTypes =
  unitsAvailableAdvancements[ProgramId.BOY_SCOUT];

export const allowedAdvancementsMeritBadgeCounselor = [
  advancementTypes.MERIT_BADGES,
];
