import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { organizationGuidSel } from '@context';
import { getTimeZoneData } from '@modules/utils';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  DISABLE_FORUM_REQUEST,
  ENABLE_FORUM_REQUEST,
  GET_TIMEZONES_REQUEST,
  GET_UNIT_DETAILS_REQUEST,
  SET_TIMEZONE_REQUEST,
  SYNC_FORUM_REQUEST,
  disableForumError,
  disableForumResponse,
  enableForumError,
  enableForumResponse,
  getTimezonesError,
  getTimezonesResponse,
  getUnitDetailsError,
  getUnitDetailsResponse,
  setTimezoneError,
  setTimezoneResponse,
  syncForumError,
  syncForumResponse,
} from './actions';
import services from './services';

const enableForumEpic$ = action$ =>
  action$.ofType(ENABLE_FORUM_REQUEST).switchMap(({ payload }) =>
    services
      .enableForum$(payload)
      .do(() =>
        toastService.success(
          intl.formatMessage({ id: 'settings.ForumSwitch.success' }),
        ),
      )
      .map(({ discourse }) => enableForumResponse(discourse))
      .catchAndReport(err => Observable.of(enableForumError(err))),
  );

const syncForumEpic$ = action$ =>
  action$.ofType(SYNC_FORUM_REQUEST).switchMap(({ payload }) =>
    services
      .enableForum$(payload)
      .do(() =>
        toastService.success(
          intl.formatMessage({ id: 'settings.ForumSwitch.syncSuccess' }),
        ),
      )
      .map(() => syncForumResponse())
      .catchAndReport(err => Observable.of(syncForumError(err))),
  );

const getTimezonesEpic$ = action$ =>
  action$.ofType(GET_TIMEZONES_REQUEST).switchMap(() =>
    services
      .getTimezones$()
      .mergeMap(payload => {
        const formattedResponse = payload.map(({ timezoneCode }) =>
          getTimeZoneData(timezoneCode),
        );
        return Observable.of(getTimezonesResponse(formattedResponse));
      })
      .catchAndReport(err => Observable.of(getTimezonesError(err))),
  );

const getUnitDetailsEpic$ = (action$, state$) =>
  action$.ofType(GET_UNIT_DETAILS_REQUEST).switchMap(() => {
    const organizationGuid = organizationGuidSel(state$.value);

    return services
      .getUnitDetails$(organizationGuid)
      .map(payload => getUnitDetailsResponse(payload))
      .catchAndReport(err => Observable.of(getUnitDetailsError(err)));
  });

const setTimezoneEpic$ = (action$, state$) =>
  action$.ofType(SET_TIMEZONE_REQUEST).switchMap(({ payload }) => {
    const { timezoneData } = payload;
    const organizationGuid = organizationGuidSel(state$.value);

    return services
      .setTimezone$(timezoneData, organizationGuid)
      .do(() =>
        toastService.success(
          intl.formatMessage({ id: 'settings.UnitTimezoneSettings.success' }),
        ),
      )
      .map(payload => setTimezoneResponse(payload))
      .catchAndReport(err => Observable.of(setTimezoneError(err)));
  });

const disableForumEpic$ = action$ =>
  action$.ofType(DISABLE_FORUM_REQUEST).switchMap(({ payload }) =>
    services
      .disableForum$(payload)
      .map(({ discourse }) => disableForumResponse(discourse))
      .catchAndReport(err => Observable.of(disableForumError(err))),
  );

export default combineEpics(
  enableForumEpic$,
  syncForumEpic$,
  disableForumEpic$,
  getTimezonesEpic$,
  getUnitDetailsEpic$,
  setTimezoneEpic$,
);
