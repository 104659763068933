import { clone } from 'lodash';

import { moduleName } from '../constants';

export {
  loadingSel as rosterLoadingSel,
  itemsSel as rosterItemsSel,
} from '../packRoster';
export {
  youthItemsSel as rosterYouthSel,
  adultItemsSel as rosterAdultsSel,
  unitRosterReportLoadingSel,
  selectedUnitReportCodeSel,
  selectedUnitReportSel,
  selectedYouthSel,
} from '../common';

const moduleSel = state => state[moduleName];

export const activeTabSel = state => moduleSel(state).activeTab;
export const reportParamsModalOpenSel = state =>
  moduleSel(state).reportParamsModalOpen;
export const reportTypeSel = state => moduleSel(state).reportType;

export const unitReportModalOpenSel = state =>
  moduleSel(state).unitReportModalOpen;

export const youthExtendedSBProfileSel = state =>
  moduleSel(state).youthExtendedSBProfile;

export const adultExtendedSBProfileSel = state =>
  moduleSel(state).adultExtendedSBProfile;

export const parentExtendedSBProfileSel = state =>
  moduleSel(state).parentExtendedSBProfile;

export const extendedSBProfileLoadingSel = state =>
  moduleSel(state).extendedSBProfileLoading;

export const extendedParentSBProfileLoadingSel = state =>
  moduleSel(state).extendedParentSBProfileLoading;

export const parentItemsSel = state => moduleSel(state).parentItems;

export const searchSchoolsSel = state => {
  const list = moduleSel(state).searchSchools;

  if (list && list.length > 24) {
    const resultList = clone(list);
    resultList.length = 24;
    return resultList;
  }

  return list;
};

export const educationRecordsSel = state => moduleSel(state).educationRecords;

export const educationRecordsLoadingSel = state =>
  moduleSel(state).educationRecordsLoading;

export const availableVersionsSel = state => moduleSel(state).availableVersions;

export const selectedQeVersionSel = state => moduleSel(state).selectedQeVersion;

export const requirementsByRankAndVersionSel = state =>
  moduleSel(state).requirementsByRankAndVersion;
