import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import Button from '../Button';
import styles from './AppMobileMenu.less';

const mobileMenuRoot = document.getElementById('app-mobile-menu-root');
const emptyFn = () => {};

class AppMobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  state = {
    open: false,
  };

  componentDidMount() {
    mobileMenuRoot.appendChild(this.el);
  }

  componentDidUpdate(_, prevState) {
    const { open } = this.state;
    if (open !== prevState.open) {
      const { onExpandOrCollapse = emptyFn } = this.props;
      onExpandOrCollapse(open);
    }
  }

  onClose = () => {
    this.setState({
      open: false,
    });
  };

  toggleOpen = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  isOpen = () => {
    const { open } = this.props;
    if (open === undefined) {
      return this.state.open;
    }
    return open;
  };

  render() {
    const {
      className,
      button: toggleButton,
      buttonTitle = <FormattedMessage id="shared.AppMobileMenu.defaultTitle" />,
      expandedContent = <div />,
    } = this.props;
    const open = this.isOpen();
    const button = toggleButton ? (
      React.cloneElement(toggleButton, { onClick: this.toggleOpen })
    ) : (
      <Button onClick={this.toggleOpen}>{buttonTitle}</Button>
    );
    const menuContainer = (
      <div className={className}>
        <CSSTransition in={open} timeout={200} classNames="fade" appear>
          {transitionState => (
            <div
              className={cn(styles.mask, transitionState)}
              onClick={this.onClose}
            />
          )}
        </CSSTransition>
        <div className={styles.container}>
          <div className={cn(styles.bodyContent, { [styles.isOpen]: open })}>
            {expandedContent}
          </div>
          <div>{button}</div>
        </div>
      </div>
    );

    return ReactDOM.createPortal(menuContainer, this.el);
  }
}

AppMobileMenu.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  button: PropTypes.node,
  buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  expandedContent: PropTypes.node.isRequired,
  onExpandOrCollapse: PropTypes.func,
};

export default AppMobileMenu;
