export const moduleName = 'advancement';
export const mobileWizardId = 'ROSTER_MOBILE';
export const desktopWizardId = 'ROSTER_DESKTOP';
export { reportTypes } from '../reports/constants';

export const quickEntryTabNames = Object.freeze({
  YOUTHS: 'youths',
  LEADERS: 'leaders',
  PARENTS: 'parents',
});

export const versionStatusLabels = Object.freeze({
  UPCOMING: 'versionStatus.upcoming',
  CURRENT: 'versionStatus.current',
  EXPIRED: 'versionStatus.expired',
});

export const pocketCertificateTypes = {
  ADVENTURE: 'adventures',
  LION: 'lion',
  BOBCAT: 'bobcat',
  TIGER: 'tiger',
  WOLF: 'wolf',
  BEAR: 'bear',
  WEBELOS: 'webelos',
  ARROW: 'arrow',
  MERIT_BADGE: 'meritBadges',
};

export const pocketCertificateTypesList = [
  pocketCertificateTypes.ADVENTURE,
  pocketCertificateTypes.LION,
  pocketCertificateTypes.BOBCAT,
  pocketCertificateTypes.TIGER,
  pocketCertificateTypes.WOLF,
  pocketCertificateTypes.BEAR,
  pocketCertificateTypes.WEBELOS,
  pocketCertificateTypes.ARROW,
  pocketCertificateTypes.MERIT_BADGE,
];
