import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import SyncIcon from '@material-ui/icons/Sync';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';

import { Fab, S } from '@shared';
import styles from './ActionMenu.less';

const { Item, Divider } = Fab;

// eslint-disable-next-line react/display-name
const ActionMenu = React.memo(
  ({ alreadyRecorded, isOpen, isOffline, onSync, onEdit, onCancel, onClose }) =>
    ReactDOM.createPortal(
      <React.Fragment>
        <CSSTransition
          in={isOpen}
          timeout={200}
          classNames={{
            appear: 'fade',
          }}
        >
          {transitionState => (
            <div
              className={cn(styles.mask, transitionState)}
              onClick={onClose}
            />
          )}
        </CSSTransition>
        <div className={styles.menuContainer}>
          <div
            className={cn(styles.items, {
              [styles.open]: isOpen,
            })}
          >
            <div className={styles.titleContainer}>
              <S size="3" bold className={styles.title}>
                <FormattedMessage id="offline.SyncPendingModal.item.chooseOne" />
              </S>
            </div>
            {!isOffline && (
              <Item
                onClick={onSync}
                icon={<SyncIcon />}
                disabled={alreadyRecorded}
              >
                <FormattedMessage id="offline.SyncPendingModal.item.syncNow" />
              </Item>
            )}
            <Divider />
            <Item onClick={onEdit} icon={<EditIcon />}>
              <FormattedMessage id="offline.SyncPendingModal.item.edit" />
            </Item>
            <Divider />
            <Item onClick={onCancel} icon={<ClearIcon />}>
              <FormattedMessage id="offline.SyncPendingModal.item.cancelItem" />
            </Item>
          </div>
        </div>
      </React.Fragment>,
      document.querySelector('body'),
    ),
);

ActionMenu.propTypes = {
  alreadyRecorded: PropTypes.bool,
  isOpen: PropTypes.bool,
  isOffline: PropTypes.bool,
  onSync: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
};

export default ActionMenu;
