export const userActivityEvents = ['mousemove', 'click', 'keydown'];
/**
 * How often to check for user activity, e.g. mousemove, click, keydown events
 */
export const INTERVAL_CHECK = 60000;

/**
 * Threshold to open the session timeout modal. After this, the user needs to confirm
 * whether to keep signed in or logging out.
 */
export const INACTIVE_MINUTES = 25;

/**
 * Timeout in minutes that automatically logs out the user
 */
export const MAX_INACTIVE_MINUTES = 30;

/**
 * Timeout in minutes for the user to confirm if keep signed in or log out
 */
export const PROMPT_TIMEOUT_MINUTES = 5;
