import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { userSubUnitMembersSel } from '@modules/advancement/packRoster';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { shouldFilterUsersBySubunitsSel } from '@modules/shared';
import { selectedOrganizationSel } from '@shared/duck/selectors/general.selectors';

import { useGetUnitPendingLeadershipPositionsQuery } from './api';

export function useUnitPendingLeadershipPositions() {
  const org = useSelector(selectedOrganizationSel);
  const shouldFilterUsersBySubunits = useSelector(
    shouldFilterUsersBySubunitsSel,
  );
  const userSubUnitMembers = useSelector(userSubUnitMembersSel);

  const orgGuid = org?.organizationGuid;
  const isUnit = String(org?.organizationType).toLowerCase() === 'units';
  const res = useGetUnitPendingLeadershipPositionsQuery(
    { orgGuid },
    {
      skip:
        !isUnit ||
        !orgGuid ||
        !featureFlags.getFlag('SBL_4848_PENDING_LEADERSHIP_POSITIONS'),
    },
  );

  const positions = res.data;

  const userSubUnitUsersIds = useMemo(
    () => userSubUnitMembers.map(member => member.userId),
    [userSubUnitMembers],
  );

  const youthPositions = useMemo(() => {
    if (!positions) return [];
    return positions
      ?.filter(
        position =>
          !shouldFilterUsersBySubunits ||
          userSubUnitUsersIds.includes(position.userId),
      )
      .filter(item => !item.isAdultPosition);
  }, [positions, shouldFilterUsersBySubunits, userSubUnitUsersIds]);

  /** Only youth positions are considered */
  const pendingCount = youthPositions.length || 0;

  return {
    response: res,
    /** Only youth positions are considered for the count */
    pendingCount,
    /** All positions incl. adult ones */
    positions,
    /** Positions filtered by isAdultPosition */
    youthPositions,
  };
}
