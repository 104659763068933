import type { AdvancementHistoryItem } from '@appTypes/esb';
import AdvancementStatusComponent from '@modules/shared/components/AdvancementStatus/AdvancementStatus';
import { advancementStatuses } from '@modules/shared/constants';
import getAdvancementDescription from '@modules/utils/getAdvancementDescription';
import personNameBuilder from '@modules/utils/personNameBuilder';
import advancementStatus from '@shared/utils/advancementStatus';
import advancementStatusDate from '@shared/utils/advancementStatusDate';
import normalizeAdvancementType from '@shared/utils/normalizeAdvancementType';

import {
  advancementStatusFilters,
  historyItemsTypes,
} from '../common/constants';

const filterableAdvancementStatusDict = {
  [advancementStatuses.STARTED]: advancementStatusFilters.STARTED,
  [advancementStatuses.COMPLETED]: advancementStatusFilters.COMPLETED,
  [advancementStatuses.COUNSELOR_APPROVED]:
    advancementStatusFilters.COUNSELOR_APPROVED,
  [advancementStatuses.AWARDED]: advancementStatusFilters.AWARDED,
  [advancementStatuses.APPROVED]: advancementStatusFilters.APPROVED,
} as const;

export const getFilterableAdvancementStatus = (status: string) =>
  filterableAdvancementStatusDict[
    status as keyof typeof filterableAdvancementStatusDict
  ];

export const isPendingItem = (status: string) =>
  status === advancementStatuses.COMPLETED ||
  status === advancementStatuses.COUNSELOR_APPROVED;

export const denormalizeAdvancementHistoryItem = (
  person: AdvancementHistoryItem,
) => {
  const { recordedInformation } = person;

  return (
    recordedInformation
      // there's a bunch of essentially empty rows that we can filter out
      // simplest way is to check if status is truthy
      .filter(advancement => advancement.status)
      .map(advancement => {
        const { recordedBy, name: advancementName } = advancement;
        const { firstName, lastName } = recordedBy;
        const advancementType = normalizeAdvancementType(
          advancement.advancementType,
        );
        const description = getAdvancementDescription(
          advancementName,
          advancement.advancementType,
        );

        const status = advancementStatus.getStatus(advancement);
        const type = historyItemsTypes.ADVANCEMENT;
        const isAdvancementPending = isPendingItem(status);
        const requirements = advancement.requirements.map(req => {
          const requirementName = [
            req.requirementNumber || req.listNumber,
            req.name,
          ]
            .filter(Boolean)
            .join(' - ');
          const name = `${requirementName}`;
          return {
            ...req,
            description: name,
            requirementName: name,
            date: req.dateCompleted,
          };
        });
        return {
          ...person,
          ...advancement,
          requirements,
          type,
          status,
          description,
          advancementType,
          advancementName,
          isAdvancementPending,
          date: advancementStatusDate.getCompleted(advancement),
          statusLabel: AdvancementStatusComponent.getTranslation(status),
          filterableStatus: getFilterableAdvancementStatus(status),
          recordedInformation: undefined,
          recordedBy: undefined,
          recordedByFirstName: firstName,
          recordedByLastName: lastName,
          recordedByName: personNameBuilder.short(recordedBy),
        };
      })
  );
};
