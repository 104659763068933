import { NotificationsState } from './types';

export const moduleName = 'userNotifications';

export const initialState: NotificationsState = {
  totalCount: 0,
  toApproveCount: 0,
  toPurchaseCount: 0,
  dashboardStats: undefined,
  positionsCount: 0,
  notificationsVisible: false,
};
