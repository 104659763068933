import { gtm, locationHash } from '@shared';

import { moduleName, tabNames } from '../constants';

export {
  SET_UNIT,
  setUnit,
  SET_RECENT_UNITS,
  setRecentUnits,
  setRecentUnit,
  removeRecentUnit,
} from '@shared';

export const ROUTE_COUNCIL_UNITS = `${moduleName}/ROUTE_COUNCIL_UNITS`;
export const goToCouncilUnitsPage = () => ({ type: ROUTE_COUNCIL_UNITS });

export const SET_ACTIVE_TAB = `${moduleName}/SET_ACTIVE_TAB`;
export const setActiveTab = tabName => dispatch => {
  locationHash.set(tabName);
  dispatch({
    type: SET_ACTIVE_TAB,
    payload: tabName,
  });
};

export const goToRecentUnitsTabPage = () => dispatch => {
  dispatch(goToCouncilUnitsPage());
  dispatch(setActiveTab(tabNames.RECENT_UNITS));
};

export const UNITS_SEARCH_REQUEST = `${moduleName}/UNITS_SEARCH_REQUEST`;
export const UNITS_SEARCH_RESPONSE = `${moduleName}/UNITS_SEARCH_RESPONSE`;
export const UNITS_SEARCH_ERROR = `${moduleName}/UNITS_SEARCH_ERROR`;
export const unitsSearchRequest = filter => ({
  type: UNITS_SEARCH_REQUEST,
  payload: filter,
  gtm: {
    category: gtm.categories.COUNCIL_UNITS,
  },
});
export const unitsSearchResponse = units => ({
  type: UNITS_SEARCH_RESPONSE,
  payload: units,
});
export const unitsSearchError = err => ({
  type: UNITS_SEARCH_ERROR,
  payload: err,
});

export const SET_SEARCH = `${moduleName}/SET_SEARCH`;
export const setSearch = search => ({ type: SET_SEARCH, payload: search });
export const SET_SORTER = `${moduleName}/SET_SORTER`;
export const setSorter = ({ columnKey, order }) => ({
  type: SET_SORTER,
  payload: { columnKey, order },
});
export const SET_RECENT_UNITS_SORTER = `${moduleName}/SET_RECENT_UNITS_SORTER`;
export const setRecentUnitsSorter = ({ columnKey, order }) => ({
  type: SET_RECENT_UNITS_SORTER,
  payload: { columnKey, order },
});
export const SET_RECENT_UNITS_SEARCH = `${moduleName}/SET_RECENT_UNITS_SEARCH`;
export const setRecentUnitsSearch = search => ({
  type: SET_RECENT_UNITS_SEARCH,
  payload: search,
});

export const LOAD_DISTRICTS_REQUEST = `${moduleName}/LOAD_DISTRICTS_REQUEST`;
export const LOAD_DISTRICTS_RESPONSE = `${moduleName}/LOAD_DISTRICTS_RESPONSE`;
export const LOAD_DISTRICTS_ERROR = `${moduleName}/LOAD_DISTRICTS_ERROR`;
export const loadDistrictsRequest = () => ({
  type: LOAD_DISTRICTS_REQUEST,
  gtm: {
    category: gtm.categories.COUNCIL_UNITS,
  },
});
export const loadDistrictsResponse = districts => ({
  type: LOAD_DISTRICTS_RESPONSE,
  payload: districts,
});
export const loadDistrictsError = err => ({
  type: LOAD_DISTRICTS_ERROR,
  payload: err,
});
