import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { MyScoutItem } from '@appTypes/esb';
import { parentGuardianModuleName } from '@modules/shared/constants';

import { ADD_CHILDREN } from './actions';

export interface ParentGuardianState {
  children: MyScoutItem[];
  loading: boolean;
}

const initialState: ParentGuardianState = {
  children: [],
  loading: false,
};

export const parentGuardianSlice = createSlice({
  name: parentGuardianModuleName,
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      ADD_CHILDREN,
      (state, action: PayloadAction<MyScoutItem[]>) => {
        state.loading = false;
        state.children = action.payload;
      },
    );
  },
});
