import { createSelector } from 'reselect';
import stable from 'stable';

import { sorters } from '@utils';

import { moduleName } from '../constants';
import { groupReport } from '../helpers';

export { reportInfoSel, selectedUserIdsSel, usersReportSel } from '@shared';

const reportSorter = sorters.compose(
  sorters.stringMulti('youth.lastName', 'youth.firstName'),
  sorters.date('youth.dateCompletedEarned', true),
  sorters.stringMulti('advancementType', 'name'),
);

const moduleSel = state => state[moduleName];

export const advancementReportModalOpenSel = state =>
  moduleSel(state).advancementReportModalOpen;
export const poidSel = state => moduleSel(state).poid;
export const showAllUsersSel = state => moduleSel(state).showAllUsers;
export const generatingReportSel = state => moduleSel(state).generatingReport;
export const isEditEnabledSel = state => moduleSel(state).isEditEnabled;
export const loadingSel = state => moduleSel(state).loading;
export const reportSel = state => moduleSel(state).report;
export const editReportLoadingSel = state => moduleSel(state).editReportLoading;
export const rawEditedReportSel = state => moduleSel(state).editedReport;

export const editedReportSel = createSelector(
  rawEditedReportSel,
  editedReport => stable(editedReport, reportSorter),
);
export const searchSel = state => moduleSel(state).search;
export const showPriceSel = state => moduleSel(state).showPrice;
export const notesSel = state => moduleSel(state).notes;
export const selectedAdvancementsSel = state =>
  moduleSel(state).selectedAdvancements;
export const selectedYouthSel = state => moduleSel(state).selectedYouth;
export const awardedYouthSel = state => moduleSel(state).awardedYouth;
export const tempEditedReportSel = createSelector(
  reportSel,
  selectedYouthSel,
  (report, selectedYouth) =>
    report.filter(
      ({ advancementType, advancementId, youth }) =>
        selectedYouth[advancementType] &&
        selectedYouth[advancementType][advancementId] &&
        selectedYouth[advancementType][advancementId].includes(youth.userId),
    ),
);
export const groupedReportSel = createSelector(reportSel, report =>
  groupReport(report),
);
export const filteredGroupedReportSel = createSelector(
  reportSel,
  searchSel,
  (report, search = '') => {
    search = search.toLowerCase();
    const filteredReport =
      search == ''
        ? report
        : report.filter(
            ({ youth, name: advancementName }) =>
              (advancementName || '').toLowerCase().includes(search) ||
              youth.shortName.toLowerCase().includes(search) ||
              `${youth.memberId}`.toLowerCase().includes(search),
          );

    return groupReport(filteredReport);
  },
);
