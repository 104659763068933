import React from 'react';

import { Col, Row, Tag } from 'bsa-ui';

import PersonTag from '@modules/shared/components/PersonTag';
import { personTagType } from '@modules/shared/constants';
import { personNameBuilder } from '@modules/utils';

import PersonAvatar from '../../../PersonAvatar';
import S from '../../../S';
import styles from './Person.less';
import RSVPActionsMenu from './RSVPActionsMenu';

type Props = {
  data: {
    firstName: string;
    lastName: string;
    nickName: string;
    isAdult: boolean;
    isGuest: boolean;
    isLeader: boolean;
    isParent: boolean;
    isParticipant: boolean;
  };
  onRemoveAction: () => void;
  rsvpKey: string;
  onRSVPAction: () => void;
  isRSVP: boolean;
  isMyYouth?: boolean;
  canRemove?: boolean;
};

const Person: React.FC<Props> = ({
  data,
  rsvpKey,
  onRemoveAction,
  onRSVPAction,
  isRSVP,
  isMyYouth = false,
  canRemove = true,
}) => {
  const { isAdult, isGuest, isLeader, isParent, isParticipant } = data;
  const personName = personNameBuilder.short(data);
  const showLeaderTag = isLeader && !isParticipant;

  return (
    <Tag className={styles.tag} outline={false}>
      <Row type="flex" align="middle">
        <Col>
          <PersonAvatar isAdult={(isAdult && !isParticipant) || isGuest} />
        </Col>
        <Col className={styles.personName}>
          <S size={'3'}>{personName}</S>
          {isAdult && (
            <Row>
              {showLeaderTag && (
                <PersonTag size="5" personType={personTagType.LEADER} />
              )}
              {isParent && (
                <PersonTag size="5" personType={personTagType.PARENT} />
              )}
            </Row>
          )}
          {isMyYouth && (
            <Row>
              <PersonTag size="5" personType={personTagType.CHILD} />
            </Row>
          )}
        </Col>
        {canRemove && (
          <Col className={styles.closeIcon}>
            <RSVPActionsMenu
              data={data}
              rsvpKey={rsvpKey}
              onRemoveAction={onRemoveAction}
              onRSVPAction={onRSVPAction}
              isRSVP={isRSVP}
            />
          </Col>
        )}
      </Row>
    </Tag>
  );
};

export default Person;
