import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Tooltip } from '../bsaComponents';
import { isMobileSel, offlineSel } from '../../duck';

function OfflineTooltip({
  children,
  isOffline,
  isMobile,
  disabled,
  renderProps,
  ...rest
}) {
  if (isOffline) {
    return (
      <Tooltip
        title={<FormattedMessage id="offline.OfflineTooltip.message" />}
        trigger={disabled ? [] : isMobile ? ['click'] : ['hover']}
        {...rest}
      >
        {renderProps ? children(isOffline) : children}
      </Tooltip>
    );
  }

  return renderProps ? children(isOffline) : children;
}

OfflineTooltip.propTypes = {
  isOffline: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  renderProps: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const mapState = state => ({
  isOffline: offlineSel(state),
  isMobile: isMobileSel(state),
});

export default connect(mapState)(OfflineTooltip);
