export { purchaseOrdersModuleName } from '../common';

export const POStatusTypes = Object.freeze({
  OPEN: 'open',
  CLOSED: 'closed',
});

export const cartStatusTypes = Object.freeze({
  REVIEWING: 'Reviewing',
  AVAILABLE: 'Available',
  ERROR: 'Error',
});
