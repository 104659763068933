import { gtm } from '@shared';

import { moduleName } from '../constants';

export const ROUTE_FEEDBACK = `${moduleName}/ROUTE_FEEDBACK`;

export const OPEN_RATING_MODAL = `${moduleName}/OPEN_RATING_MODAL`;
export const CLOSE_RATING_MODAL = `${moduleName}/CLOSE_RATING_MODAL`;
export const openRatingModal = () => ({
  type: OPEN_RATING_MODAL,
  gtm: {
    category: gtm.categories.FEEDBACK,
  },
});
export const closeRatingModal = () => ({ type: CLOSE_RATING_MODAL });

export const OPEN_BUG_MODAL = `${moduleName}/OPEN_BUG_MODAL`;
export const CLOSE_BUG_MODAL = `${moduleName}/CLOSE_BUG_MODAL`;
export const openBugModal = () => ({
  type: OPEN_BUG_MODAL,
  gtm: {
    category: gtm.categories.FEEDBACK,
  },
});
export const closeBugModal = () => ({ type: CLOSE_BUG_MODAL });

export const OPEN_FEATURE_MODAL = `${moduleName}/OPEN_FEATURE_MODAL`;
export const CLOSE_FEATURE_MODAL = `${moduleName}/CLOSE_FEATURE_MODAL`;
export const openFeatureModal = () => ({
  type: OPEN_FEATURE_MODAL,
  gtm: {
    category: gtm.categories.FEEDBACK,
  },
});
export const closeFeatureModal = () => ({ type: CLOSE_FEATURE_MODAL });
