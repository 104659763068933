import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import { last, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { IS_PROD_ENV } from '@config';
import { dictionariesRequest, dictionaryIds } from '@dict';
import {
  dtoDateFormat,
  intl,
  organizationGuidSel,
  packRosterItemsSel,
  unitTypeIdForUnitTypeName,
  userDataSel,
} from '@shared';
import { toastService } from '@toasts';
import { unitInfoSel } from '@unit';
import '@utils/rxjs.add.operator.catchAndReport';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  COUNTRY_IDS,
  defaultRecharterTermValue,
  nationalCouncilNum,
  nonUnit,
  paymentMethods,
  paymentMethodsValues,
  paymentProductName,
  productName,
  recharterSetup,
} from '../constants';
import {
  getAgreementBody,
  getCompleteFileInfo,
  getItemizedReceipt,
  getPersonInfo,
  getPersonInfoWithPaperApplication,
  getUploadDocumentPayload,
} from '../utils';
import {
  ACH_VERIFICATION_REQUEST,
  ACH_VERIFICATION_RESPONSE,
  ADD_BACK_MEMBERS_REQUEST,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_RESPONSE,
  BATCHES_REQUEST,
  CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST,
  CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE,
  EDIT_TERM_REQUEST,
  EDIT_TERM_RESPONSE,
  GENERATE_RECHARTER_PDF_REQUEST,
  GET_ADJUSTED_OLR_SETTINGS_REQUEST,
  GET_COUNCILS_REQUEST,
  GET_COUNCIL_UNITS_REQUEST,
  GET_FUTURE_CHARTER_REQUEST,
  GET_ORG_ADULTS_REQUEST,
  GET_PAPERWORK_LINK_REQUEST,
  GET_UNIT_BASIC_REQUIREMENTS_REQUEST,
  GET_UNIT_PAYMENT_SUMMARY_REQUEST,
  GET_UNIT_POSITIONS_REQUEST,
  HARD_DELETE_PENDING_PERSON_REQUEST,
  HARD_DELETE_PENDING_PERSON_RESPONSE,
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_RESPONSE,
  MARK_AS_MULTIPLE_REQUEST,
  MARK_AS_MULTIPLE_RESPONSE,
  PAYMENT_PROCESS_REQUEST,
  RECHARTER_BATCH_INFO_REQUEST,
  RECHARTER_ROSTER_REFRESH_REQUEST,
  RECHARTER_ROSTER_REQUEST,
  RECHARTER_ROSTER_RESPONSE,
  RECHARTER_VALIDATE_ROSTER_REQUEST,
  REMOVE_MEMBERS_REQUEST,
  REMOVE_PENDING_MEMBERS_REQUEST,
  SEND_REMINDERS_REQUEST,
  SEND_REMINDERS_RESPONSE,
  SUBMIT_RECHARTER_REQUEST,
  UNMARK_AS_MULTIPLE_REQUEST,
  UNMARK_AS_MULTIPLE_RESPONSE,
  UPDATE_POSITION_REQUEST,
  UPDATE_SCOUTS_LIFE_REQUEST,
  UPDATE_SCOUTS_LIFE_RESPONSE,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_RESPONSE,
  achVerificationError,
  achVerificationResponse,
  addBackMembersError,
  addBackMembersResponse,
  addMemberError,
  addMemberResponse,
  batchesError,
  batchesResponse,
  closeAchVerificationModal,
  closeAddMembersModal,
  closeAddToRecharterModal,
  closeEditTermModal,
  closeInviteMembersModal,
  closeMarkAsMultipleModal,
  closePaymentModal,
  closeRemoveMembersModal,
  closeUnmarkAsMultipleModal,
  closeUpdatePositionModal,
  closeUploadDocumentModal,
  createTransientDocumentWithAgreementError,
  createTransientDocumentWithAgreementRequest,
  createTransientDocumentWithAgreementResponse,
  editTermError,
  editTermResponse,
  generateRecharterPDFError,
  generateRecharterPDFRequest,
  generateRecharterPDFResponse,
  getAdjustedOLRSettingsError,
  getAdjustedOLRSettingsResponse,
  getCouncilUnitsError,
  getCouncilUnitsResponse,
  getCouncilsError,
  getCouncilsResponse,
  getFutureCharterError,
  getFutureCharterResponse,
  getOrgAdultsError,
  getOrgAdultsResponse,
  getPaperworkLinkError,
  getPaperworkLinkResponse,
  getUnitBasicRequirementsError,
  getUnitBasicRequirementsResponse,
  getUnitPaymentSummaryError,
  getUnitPaymentSummaryRequest,
  getUnitPaymentSummaryResponse,
  getUnitPositionsError,
  getUnitPositionsResponse,
  hardDeletePendingPersonError,
  hardDeletePendingPersonResponse,
  inviteMembersError,
  inviteMembersResponse,
  markAsMultipleError,
  markAsMultipleResponse,
  openAdobeErrorModal,
  openPaymentModal,
  paymentProcessError,
  paymentProcessResponse,
  recharterBatchInfoError,
  recharterBatchInfoRequest,
  recharterBatchInfoResponse,
  recharterRosterError,
  recharterRosterRequest,
  recharterRosterResponse,
  recharterValidateRosterError,
  recharterValidateRosterResponse,
  refreshRecharterRosterError,
  refreshRecharterRosterResponse,
  removeMembersError,
  removeMembersResponse,
  removePendingMembersError,
  removePendingMembersResponse,
  sendRemindersError,
  sendRemindersResponse,
  setSelectedCouncil,
  setShowSelector,
  submitRecharterError,
  submitRecharterResponse,
  unmarkAsMultipleError,
  unmarkAsMultipleResponse,
  updatePositionError,
  updatePositionResponse,
  updateScoutsLifeError,
  updateScoutsLifeResponse,
  uploadDocumentError,
  uploadDocumentResponse,
} from './actions';
import {
  batchApplicationIdSel,
  batchInformationSel,
  councilsSel,
  generateFileInfoSel,
  key3CCPersonEmails,
  pendingMembersSel,
  pendingMembersSelectedSel,
  recharterRosterWithNewMembersSel,
  selectedCouncilSel,
  selectedCouncilUnitSel,
  selectedCouncilUnitTypeSel,
  selectedPaymentMethodSel,
  selectedTermSel,
  unitPaymentSummarySel,
} from './selectors';
import services from './services';

const fetchFormDicts$ = action$ =>
  action$
    .ofType(RECHARTER_ROSTER_REQUEST)
    .mapTo(
      dictionariesRequest(
        dictionaryIds.COUNTRIES,
        dictionaryIds.PHONE_COUNTRIES,
      ),
    );

const getBatchesEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(BATCHES_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);

      return services.getAllBatches$(orgGuid).pipe(
        mergeMap(res => {
          const showTermSelector = res.length === 0;
          return Observable.concat(
            of(setShowSelector(showTermSelector)),
            of(batchesResponse(res)),
          );
        }),
        catchAndReport(err => of(batchesError(err))),
      );
    }),
  );

const fetchRecharterRoster$ = (action$, state$) =>
  action$.pipe(
    ofType(RECHARTER_ROSTER_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const { charter } = unitInfoSel(state);
      const selectedTerm = selectedTermSel(state);
      const { paymentTypeId, batchApplicationTypeId } = recharterSetup;
      const { organizationGUID, programId, expiryDate } = charter;
      const communityGuid = organizationGUID;

      return services.viewBatch$(orgGuid).switchMap(resBatch => {
        if (resBatch.length === 0) {
          return services.viewFutureCharter$(orgGuid).switchMap(resCharter => {
            if (resCharter.length === 0) {
              const newEffectiveDt = moment(expiryDate)
                .add(1, 'days')
                .format(dtoDateFormat);

              const newExpiryDate = moment(expiryDate)
                .add(
                  selectedTerm ? selectedTerm : defaultRecharterTermValue,
                  'months',
                )
                .endOf('month')
                .format(dtoDateFormat);
              return services
                .createFutureCharter$(
                  orgGuid,
                  communityGuid,
                  programId,
                  selectedTerm ? selectedTerm : defaultRecharterTermValue,
                  newEffectiveDt,
                  newExpiryDate,
                  paymentTypeId,
                )
                .switchMap(() =>
                  services.viewFutureCharter$(orgGuid).switchMap(([{ id }]) =>
                    services
                      .addBatch$(orgGuid, id, batchApplicationTypeId)
                      .switchMap(({ batchApplicationId }) =>
                        services
                          .initRecharterRoster$(orgGuid, batchApplicationId)
                          .pipe(
                            mergeMap(() =>
                              Observable.concat(of(recharterRosterRequest())),
                            ),
                            catchAndReport(() => of(recharterRosterError())),
                          ),
                      ),
                  ),
                );
            } else {
              const [{ id }] = resCharter;
              return services
                .addBatch$(orgGuid, id, batchApplicationTypeId)
                .switchMap(({ batchApplicationId }) =>
                  services
                    .initRecharterRoster$(orgGuid, batchApplicationId)
                    .pipe(
                      mergeMap(() =>
                        Observable.concat(of(recharterRosterRequest())),
                      ),
                      catchAndReport(() => of(recharterRosterError())),
                    ),
                );
            }
          });
        } else {
          const [{ batchApplicationId }] = resBatch;
          return services
            .getRecharterRoster$(orgGuid, batchApplicationId)
            .switchMap(resRoster => {
              if (resRoster.length === 0) {
                return services
                  .initRecharterRoster$(orgGuid, batchApplicationId)
                  .pipe(
                    mergeMap(() =>
                      Observable.concat(of(recharterRosterRequest())),
                    ),
                    catchAndReport(() => of(recharterRosterError())),
                  );
              } else {
                return services
                  .getRecharterRoster$(orgGuid, batchApplicationId)
                  .mergeMap(roster =>
                    Observable.concat(
                      of(recharterRosterResponse(roster)),
                      of(getUnitPaymentSummaryRequest(batchApplicationId)),
                    ),
                  )
                  .catchAndReport(() => of(recharterRosterError()));
              }
            });
        }
      });
    }),
  );

const uploadDocumentEpic$ = action$ =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENT_REQUEST),
    switchMap(({ payload }) =>
      from(payload)
        .mergeMap(
          ({ orgGuid, batchApplicationId, batchApplicationPersonId, file }) =>
            services.uploadDocument$(
              orgGuid,
              batchApplicationId,
              batchApplicationPersonId,
              getUploadDocumentPayload(file),
            ),
        )
        .pipe(
          last(),
          mergeMap(() =>
            Observable.concat(
              of(uploadDocumentResponse()),
              of(closeUploadDocumentModal()),
              of(recharterRosterRequest()),
            ),
          ),
          tap(
            ({ type }) =>
              type === UPLOAD_DOCUMENT_RESPONSE &&
              toastService.success(
                intl.formatMessage({
                  id: 'recharter.RecharterNotification.uploadDocumentSuccess',
                }),
              ),
          ),
          catchAndReport(err => {
            const errorMessage = get(err, 'response.errorDesc');
            if (
              errorMessage ===
              'Found duplicated cbcAuthorizationImageUrl(s) in the batch'
            ) {
              toastService.error(
                intl.formatMessage({
                  id: 'recharter.RecharterNotification.uploadDocumentError',
                }),
              );
            } else {
              toastService.error(errorMessage);
            }
            return of(uploadDocumentError());
          }),
        ),
    ),
  );

const addMemberEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADD_MEMBER_REQUEST),
    switchMap(({ payload: { memberInfo, isNewMemberApplication } }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const { isCouncilPaid = false } = unitInfoSel(state);
      const personInfo = { ...memberInfo, isCouncilPaid };

      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) =>
          isNewMemberApplication
            ? services
                .addPersonToBatchAndUploadPaperApplication$(
                  orgGuid,
                  batchApplicationId,
                  getPersonInfoWithPaperApplication(state, personInfo, term),
                )
                .pipe(
                  mergeMap(() =>
                    Observable.concat(
                      of(addMemberResponse()),
                      of(closeAddMembersModal()),
                      of(recharterRosterRequest()),
                    ),
                  ),
                  tap(
                    ({ type }) =>
                      type === ADD_MEMBER_RESPONSE &&
                      toastService.success(
                        intl.formatMessage(
                          {
                            id: 'recharter.RecharterNotification.addMemberSuccess',
                          },
                          {
                            name: `${memberInfo.firstName} ${memberInfo.lastName}`,
                          },
                        ),
                      ),
                  ),
                  catchAndReport(err => {
                    const errorMessage = get(err, 'response.errorDesc');
                    if (
                      errorMessage === 'Position is not allowed to multiple.'
                    ) {
                      toastService.error(
                        intl.formatMessage({
                          id: 'recharterModals.addMembersModal.multiplePositionError',
                        }),
                      );
                    } else {
                      toastService.error(errorMessage);
                    }
                    return of(addMemberError());
                  }),
                )
            : services
                .addExistingPersonToBatch(
                  orgGuid,
                  batchApplicationId,
                  getPersonInfo(personInfo, term, orgGuid),
                )
                .pipe(
                  mergeMap(() =>
                    Observable.concat(
                      of(addMemberResponse()),
                      of(closeAddMembersModal()),
                      of(recharterRosterRequest()),
                    ),
                  ),
                  tap(
                    ({ type }) =>
                      type === ADD_MEMBER_RESPONSE &&
                      toastService.success(
                        intl.formatMessage(
                          {
                            id: 'recharter.RecharterNotification.addMemberSuccess',
                          },
                          {
                            name: `${memberInfo.firstName} ${memberInfo.lastName}`,
                          },
                        ),
                      ),
                  ),
                  catchAndReport(err => {
                    const errorMessage = get(err, 'response.errorDesc');
                    if (
                      errorMessage === 'Adult or Participant should have SSN'
                    ) {
                      toastService.error(
                        intl.formatMessage({
                          id: 'recharterModals.addMembersModal.customSSNError',
                        }),
                      );
                    } else if (
                      errorMessage === 'Position is not allowed to multiple.'
                    ) {
                      toastService.error(
                        intl.formatMessage({
                          id: 'recharterModals.addMembersModal.multiplePositionError',
                        }),
                      );
                    } else {
                      toastService.error(errorMessage);
                    }
                    return of(addMemberError(err));
                  }),
                ),
        );
    }),
  );

const inviteMemberEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(INVITE_MEMBERS_REQUEST),
    switchMap(
      ({
        payload: {
          firstName,
          pgFirstName,
          lastName,
          pgLastName,
          memberType: memberTypeId,
          email: pgEmail,
        },
      }) => {
        const state = state$.value;
        const orgGuid = organizationGuidSel(state);
        const { tinyUrl: tinyURL } = unitInfoSel(state);

        return services
          .viewBatch$(orgGuid)
          .switchMap(([{ batchApplicationId }]) =>
            services
              .invitePersonToBatch$(orgGuid, batchApplicationId, [
                {
                  firstName,
                  pgFirstName,
                  lastName,
                  pgLastName,
                  memberTypeId,
                  pgEmail,
                  tinyURL,
                },
              ])
              .pipe(
                mergeMap(() =>
                  Observable.concat(
                    of(inviteMembersResponse()),
                    of(closeInviteMembersModal()),
                    of(recharterRosterRequest()),
                  ),
                ),
                tap(
                  ({ type }) =>
                    type === INVITE_MEMBERS_RESPONSE &&
                    toastService.success(
                      intl.formatMessage(
                        {
                          id: 'recharter.RecharterNotification.inviteMemberSuccess',
                        },
                        { name: `${firstName} ${lastName}` },
                      ),
                    ),
                ),
                catchAndReport(() => of(inviteMembersError())),
              ),
          );
      },
    ),
  );

const getUnitPositionsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_UNIT_POSITIONS_REQUEST, RECHARTER_ROSTER_RESPONSE),
    switchMap(() => {
      const state = state$.value;
      const { type } = unitInfoSel(state);

      return services.getUnitPositions$(type).pipe(
        map(({ Positions }) => getUnitPositionsResponse(Positions)),
        catchAndReport(() => of(getUnitPositionsError())),
      );
    }),
  );

const getUnitBasicRequirementsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_UNIT_BASIC_REQUIREMENTS_REQUEST, RECHARTER_ROSTER_RESPONSE),
    switchMap(() => {
      const state = state$.value;
      const { type } = unitInfoSel(state);
      return services.getUnitBasicRequirements$(type.toLowerCase()).pipe(
        map(getUnitBasicRequirementsResponse),
        catchAndReport(() => of(getUnitBasicRequirementsError())),
      );
    }),
  );

const getFutureCharterRequestEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_FUTURE_CHARTER_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);

      return services.viewFutureCharter$(orgGuid).pipe(
        map(getFutureCharterResponse),
        catchAndReport(() => of(getFutureCharterError())),
      );
    }),
  );

const getUnitPaymentSummaryEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_UNIT_PAYMENT_SUMMARY_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const batchApplicationId = payload;
      const orgGuid = organizationGuidSel(state);
      return services.getUnitPaymentSummary$(orgGuid, batchApplicationId).pipe(
        map(getUnitPaymentSummaryResponse),
        catchAndReport(() => of(getUnitPaymentSummaryError())),
      );
    }),
  );

const getOrgAdultsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ORG_ADULTS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      return services.getOrgAdults$(orgGuid).pipe(
        map(getOrgAdultsResponse),
        catchAndReport(err => of(getOrgAdultsError(err))),
      );
    }),
  );

const updateMemberPositionEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_POSITION_REQUEST),
    switchMap(({ payload: { personId, positionId, memberTypeId } }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);

      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) => {
          const param = [
            {
              term,
              USAddress: true,
              requestedFkPositionId: positionId,
              batchApplicationPersonId: personId,
              memberTypeId: memberTypeId,
            },
          ];
          return services
            .bulkUpdateRecharterRoster$(orgGuid, batchApplicationId, param)
            .pipe(
              mergeMap(() => {
                toastService.success(
                  intl.formatMessage({
                    id: 'recharter.RecharterNotification.updatePositionSuccess',
                  }),
                );
                return Observable.concat(
                  of(updatePositionResponse()),
                  of(closeUpdatePositionModal()),
                  of(recharterRosterRequest()),
                );
              }),
            );
        })
        .catchAndReport(() => of(updatePositionError()));
    }),
  );

const validateRosterEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RECHARTER_VALIDATE_ROSTER_REQUEST),
    switchMap(({ payload }) => {
      const { orgGuid, batchId } = payload;
      const state = state$.value;
      const { typeId, isCouncilPaid } = unitInfoSel(state);
      const unitType = unitTypeIdForUnitTypeName[typeId];
      return services
        .validateRoster$(orgGuid, +batchId, unitType, isCouncilPaid)
        .pipe(
          map(recharterValidateRosterResponse),
          catchAndReport(() => of(recharterValidateRosterError())),
        );
    }),
  );

const refreshRecharterRosterEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(
      RECHARTER_ROSTER_REFRESH_REQUEST,
      SEND_REMINDERS_RESPONSE,
      HARD_DELETE_PENDING_PERSON_RESPONSE,
    ),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId }]) =>
          services.refreshRecharterRoster$(orgGuid, batchApplicationId).pipe(
            mergeMap(() =>
              Observable.concat(
                of(refreshRecharterRosterResponse()),
                of(recharterRosterRequest()),
              ),
            ),
            catchAndReport(() => of(refreshRecharterRosterError())),
          ),
        );
    }),
  );

const removeMembersEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(REMOVE_MEMBERS_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);

      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) => {
          const param = payload.map(batchApplicationPersonId => ({
            term,
            USAddress: true,
            isRemoved: true,
            batchApplicationPersonId,
          }));
          return services
            .bulkUpdateRecharterRoster$(orgGuid, batchApplicationId, param)
            .pipe(
              mergeMap(() => {
                toastService.success(
                  intl.formatMessage({
                    id: 'recharter.RecharterNotification.removeMembersSuccess',
                  }),
                );
                return Observable.concat(
                  of(removeMembersResponse()),
                  of(closeRemoveMembersModal()),
                  of(recharterRosterRequest()),
                );
              }),
            );
        })
        .catchAndReport(() => of(removeMembersError()));
    }),
  );

const addBackMembersEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADD_BACK_MEMBERS_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);

      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) => {
          const param = payload.map(batchApplicationPersonId => ({
            term,
            USAddress: true,
            isRemoved: false,
            batchApplicationPersonId,
          }));
          return services
            .bulkUpdateRecharterRoster$(orgGuid, batchApplicationId, param)
            .pipe(
              mergeMap(() => {
                toastService.success(
                  intl.formatMessage({
                    id: 'recharter.RecharterNotification.addBackMembersSuccess',
                  }),
                );
                return Observable.concat(
                  of(addBackMembersResponse()),
                  of(closeAddToRecharterModal()),
                  of(recharterRosterRequest()),
                );
              }),
              catchAndReport(() => of(addBackMembersError())),
            );
        });
    }),
  );

const getBatchInformationEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RECHARTER_BATCH_INFO_REQUEST, RECHARTER_ROSTER_RESPONSE),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      return services.viewBatch$(orgGuid).pipe(
        map(recharterBatchInfoResponse),
        catchAndReport(() => of(recharterBatchInfoError())),
      );
    }),
  );

const paymentProcessEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(PAYMENT_PROCESS_REQUEST),
    switchMap(({ payload }) => {
      const {
        token = null,
        uniqueId = '',
        firstName,
        lastName,
        phoneCountry,
        phone,
        email,
        address,
        city,
        state: recharterState,
        country,
        zip,
      } = payload;

      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const selectedPaymentMethod = selectedPaymentMethodSel(state);
      const unitPaymentSummary = unitPaymentSummarySel(state);
      const batchId = batchApplicationIdSel(state);
      const { batchTotalFee = 0, councilFee = 0 } = unitPaymentSummary;

      /** @type {import('@appTypes/esb').PostRegistrationPaymentRequest} */
      const paymentPayload = {
        //null for PAY_AT_COUNCIL only, for other methods will be set in the following functions
        accountId: null,
        amount: batchTotalFee,
        uniqueId: uniqueId,
        paymentMethod: paymentMethodsValues[selectedPaymentMethod],
        paymentId: token,
        paymentContext: {},
        firstName: firstName,
        lastName: lastName,
        ProductName: productName,
        itemizedReceipt: getItemizedReceipt(unitPaymentSummary),
        note: 'Payment Rercharter Process',
        phone: phone ? `+${phoneCountry}${phone}` : undefined,
        email,
        address1: address,
        city,
        state: recharterState,
        country,
        zip,
        productName: paymentProductName,
      };

      const proceedToCheckout = payload =>
        services
          .processRecharterPayment$({
            batchId,
            payload,
            organizationGuid: orgGuid,
          })
          .mergeMap(response =>
            Observable.concat(
              of(paymentProcessResponse(response)),
              of(
                generateRecharterPDFRequest({
                  onSuccess: () => openPaymentModal(),
                  onFailure: () => openAdobeErrorModal(),
                  fromPayment: true,
                }),
              ),
            ),
          )
          .pipe(
            catchAndReport(err => {
              toastService.error(
                intl.formatMessage({
                  id: 'recharter.RecharterNotification.errorPaymentFailed',
                }),
              );

              return of(paymentProcessError(err));
            }),
          );

      const checkoutWithCouncil = councilNum =>
        services
          .getMerchantByCouncilNum$(councilNum)
          .switchMap(({ data }) =>
            proceedToCheckout({
              ...paymentPayload,
              accountId: !isEmpty(data) ? +data[0].account_id : 0,
            }),
          )
          .pipe(catchAndReport(err => of(paymentProcessError(err))));

      if (selectedPaymentMethod === paymentMethods.PAY_AT_COUNCIL) {
        return proceedToCheckout(paymentPayload);
      }

      if (councilFee === 0) {
        return checkoutWithCouncil(nationalCouncilNum);
      }

      return services
        .getAdjustedOLRSettings$(orgGuid)
        .switchMap(({ councilNum = 0 }) => checkoutWithCouncil(councilNum))
        .pipe(catchAndReport(err => of(paymentProcessError(err))));
    }),
  );

const achVerificationEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ACH_VERIFICATION_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const batchId = batchApplicationIdSel(state);
      return services.verifyACHPayment$({ orgGuid, batchId, payload }).pipe(
        mergeMap(() =>
          Observable.concat(
            of(achVerificationResponse()),
            of(recharterBatchInfoRequest()),
            of(closeAchVerificationModal()),
          ),
        ),
        tap(
          ({ type }) =>
            type === ACH_VERIFICATION_RESPONSE &&
            toastService.info(
              intl.formatMessage({
                id: 'recharter.RecharterNotification.achVerificationSuccess',
              }),
            ),
        ),
        catchAndReport(err => of(achVerificationError(err))),
      );
    }),
  );

const getAdjustedOLRSettingsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ADJUSTED_OLR_SETTINGS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const { councilGuid } = unitInfoSel(state);
      return services.getAdjustedOLRSettings$(councilGuid).pipe(
        map(getAdjustedOLRSettingsResponse),
        catchAndReport(err => of(getAdjustedOLRSettingsError(err))),
      );
    }),
  );

const updateMemberScoutsLifeEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_SCOUTS_LIFE_REQUEST),
    switchMap(({ payload: { personId, isBoysLife, countryId } }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);

      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) => {
          const param = [
            {
              term,
              USAddress: !countryId ? true : countryId === COUNTRY_IDS.USA,
              isBoysLife,
              batchApplicationPersonId: personId,
            },
          ];

          return services
            .bulkUpdateRecharterRoster$(orgGuid, batchApplicationId, param)
            .pipe(
              mergeMap(response =>
                Observable.concat(
                  of(updateScoutsLifeResponse(response)),
                  of(recharterRosterRequest()),
                ),
              ),
              tap(
                ({ type }) =>
                  type === UPDATE_SCOUTS_LIFE_RESPONSE &&
                  toastService.success(
                    intl.formatMessage({
                      id: 'recharter.RecharterNotification.updatedScoutsLifeSuccess',
                    }),
                  ),
              ),
            );
        })
        .catchAndReport(err => of(updateScoutsLifeError(err)));
    }),
  );

const sendRemindersEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SEND_REMINDERS_REQUEST),
    switchMap(() => {
      const state = state$.value;

      const organizationGuid = organizationGuidSel(state);
      const batchId = batchApplicationIdSel(state);
      const pendingMembers = pendingMembersSelectedSel(state);
      const { tinyUrl: tinyURL } = unitInfoSel(state);
      const members = pendingMembers.map(member => {
        const [firstName, lastName] = member.personFullName.split(' ');
        return {
          batchApplicationPersonId: member.batchApplicationPersonId,
          firstName,
          lastName,
          pgEmail: member.pgEmail,
          tinyURL,
        };
      });
      return services
        .sendReminders$({
          organizationGuid,
          batchId,
          payload: members,
        })
        .pipe(
          map(sendRemindersResponse),
          tap(() =>
            toastService.success(
              intl.formatMessage({
                id: 'recharter.RecharterNotification.reminderMembersSuccess',
              }),
            ),
          ),
          catchAndReport(err => of(sendRemindersError(err))),
        );
    }),
  );

const getCouncilsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_COUNCILS_REQUEST, RECHARTER_ROSTER_RESPONSE),
    switchMap(({ type }) => {
      const state = state$.value;
      const { councilGuid } = unitInfoSel(state);
      return services.getCouncils$().pipe(
        type === GET_COUNCILS_REQUEST
          ? mergeMap(response =>
              Observable.concat(
                of(getCouncilsResponse(response)),
                of(setSelectedCouncil(councilGuid)),
              ),
            )
          : map(getCouncilsResponse),
        catchAndReport(err => of(getCouncilsError(err))),
      );
    }),
  );

const getCouncilUnitsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_COUNCIL_UNITS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const councilGuid = selectedCouncilSel(state);
      const selectedCouncilUnitType = selectedCouncilUnitTypeSel(state);
      return services
        .getCounciUnits$(
          {
            organizationGuid: councilGuid,
            unitTypeId: selectedCouncilUnitType,
          },
          'all',
        )
        .pipe(
          map(units =>
            (units || []).filter(({ unitStatus }) => {
              const currentUnitStatus = unitStatus.toLowerCase();
              return (
                currentUnitStatus === 'active' ||
                currentUnitStatus === 'unposted' ||
                currentUnitStatus === 'lapsed'
              );
            }),
          ),
          map(getCouncilUnitsResponse),
          catchAndReport(err => of(getCouncilUnitsError(err))),
        );
    }),
  );

const editTermEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(EDIT_TERM_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const [batchInfo] = batchInformationSel(state);
      return services.deleteBatch$(orgGuid, batchInfo.batchApplicationId).pipe(
        mergeMap(res =>
          Observable.concat(
            of(editTermResponse(res)),
            of(recharterRosterRequest()),
            of(closeEditTermModal()),
          ),
        ),
        tap(
          ({ type }) =>
            type === EDIT_TERM_RESPONSE &&
            toastService.success(
              intl.formatMessage({
                id: 'recharter.RecharterNotification.editTermSuccess',
              }),
            ),
        ),
        catchAndReport(err => of(editTermError(err))),
      );
    }),
  );

const markAsMultipleEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(MARK_AS_MULTIPLE_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const selectedCouncil = selectedCouncilSel(state);
      const selectedCouncilUnitType = selectedCouncilUnitTypeSel(state);
      const selectedCouncilUnit = selectedCouncilUnitSel(state);
      const fromOrgGuid =
        selectedCouncilUnitType === nonUnit.id
          ? selectedCouncil
          : selectedCouncilUnit;
      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) => {
          const unsubscribe = payload
            .filter(({ isBoysLife }) => isBoysLife)
            .map(({ batchApplicationPersonId }) => ({
              term,
              USAddress: true,
              isBoysLife: false,
              batchApplicationPersonId,
            }));

          const markAsMultiple = () => {
            const param = payload.map(({ batchApplicationPersonId }) => ({
              term,
              USAddress: true,
              isMultiple: true,
              batchApplicationPersonId,
              fromOrgGuid,
            }));
            return services
              .bulkUpdateRecharterRoster$(orgGuid, batchApplicationId, param)
              .pipe(
                mergeMap(response =>
                  Observable.concat(
                    of(markAsMultipleResponse(response)),
                    of(closeMarkAsMultipleModal()),
                    of(recharterRosterRequest()),
                  ),
                ),
                tap(
                  ({ type }) =>
                    type === MARK_AS_MULTIPLE_RESPONSE &&
                    toastService.success(
                      intl.formatMessage({
                        id: 'recharter.RecharterNotification.markAsMultipleSuccess',
                      }),
                    ),
                ),
              );
          };

          if (isEmpty(unsubscribe)) {
            return markAsMultiple();
          }

          return services
            .bulkUpdateRecharterRoster$(
              orgGuid,
              batchApplicationId,
              unsubscribe,
            )
            .switchMap(() => markAsMultiple());
        })
        .pipe(catchAndReport(err => of(markAsMultipleError(err))));
    }),
  );

const unmarkAsMultipleEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UNMARK_AS_MULTIPLE_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      return services
        .viewBatch$(orgGuid)
        .switchMap(([{ batchApplicationId, term }]) => {
          const param = payload.map(({ batchApplicationPersonId }) => ({
            term,
            USAddress: true,
            isMultiple: false,
            batchApplicationPersonId,
          }));

          return services
            .bulkUpdateRecharterRoster$(orgGuid, batchApplicationId, param)
            .pipe(
              mergeMap(response =>
                Observable.concat(
                  of(unmarkAsMultipleResponse(response)),
                  of(closeUnmarkAsMultipleModal()),
                  of(recharterRosterRequest()),
                ),
              ),
              tap(
                ({ type }) =>
                  type === UNMARK_AS_MULTIPLE_RESPONSE &&
                  toastService.success(
                    intl.formatMessage({
                      id: 'recharter.RecharterNotification.unmarkAsMultipleSuccess',
                    }),
                  ),
              ),
            );
        })
        .pipe(catchAndReport(err => of(unmarkAsMultipleError(err))));
    }),
  );

const generateRecharterPDFEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GENERATE_RECHARTER_PDF_REQUEST),
    switchMap(({ payload: { onSuccess = () => {}, onFailure = () => {} } }) => {
      const state = state$.value;
      const fileInfo = cloneDeep(generateFileInfoSel(state));
      const unitInfo = cloneDeep(unitInfoSel(state));
      const [batchInfo] = batchInformationSel(state);
      const councils = cloneDeep(councilsSel(state));
      const unitPaymentSummary = cloneDeep(unitPaymentSummarySel(state));
      const selectedPaymentMethod = selectedPaymentMethodSel(state);
      const userData = [cloneDeep(userDataSel(state))];
      const completeFileInfo = getCompleteFileInfo({
        batchId: batchInfo.batchApplicationId,
        batchCreatedByName: batchInfo.createdByName,
        fileInfo,
        unitInfo,
        unitStatus: batchInfo.unitStatus,
        councils,
        unitPaymentSummary,
        selectedPaymentMethod,
        userData,
      });
      const { name } = unitInfoSel(state);

      return services
        .generateRecharterPDF$(completeFileInfo)
        .mergeMap(file => {
          const formData = new FormData();
          // convert arraybuffer into blob with new Response()
          new Response(file).blob().then(blob => {
            formData.append('File', blob);
            formData.append('name', 'File');
            formData.append('File-Name', `${name}_Charter.pdf`);
          });

          return Observable.concat(
            of(generateRecharterPDFResponse()),
            of(
              createTransientDocumentWithAgreementRequest({
                transientDocument: formData,
                onSuccess,
                onFailure,
              }),
            ),
          );
        })
        .pipe(
          catchAndReport(err => {
            err.request.body = JSON.stringify(
              JSON.parse(err.request.body).persons.slice(0, 5),
            );

            return Observable.concat(
              of(generateRecharterPDFError(err)),
              of(onFailure()),
            );
          }),
        );
    }),
  );

const getPaperworkLinkEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_PAPERWORK_LINK_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const batchApplicationId = batchApplicationIdSel(state);
      const orgGuid = organizationGuidSel(state);

      return services.getPaperworkLink$(orgGuid, batchApplicationId).pipe(
        map(response => getPaperworkLinkResponse(batchApplicationId, response)),
        catchAndReport(() => Observable.concat(of(getPaperworkLinkError()))),
      );
    }),
  );

const createTransientDocumentWithAgreementEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST),
    switchMap(
      ({
        payload: {
          transientDocument,
          onSuccess = () => {},
          onFailure = () => {},
        },
      }) =>
        services
          .getAdobeSignBaseUris$()
          .switchMap(({ apiAccessPoint }) => {
            const state = state$.value;
            const batchApplicationId = batchApplicationIdSel(state);
            const unitRoster = recharterRosterWithNewMembersSel(state);
            const packRoster = packRosterItemsSel(state);
            const key3DelegateEmails = key3CCPersonEmails(state);
            const ccs = key3DelegateEmails.map(email => ({ email }));
            const { name, acceptGender } = unitInfoSel(state);
            const rosters = { unitRoster, packRoster };

            return services
              .createTransientDocument$(apiAccessPoint, transientDocument)
              .switchMap(({ transientDocumentId }) =>
                services
                  .createAgreement$(
                    apiAccessPoint,
                    getAgreementBody({
                      transientDocumentId,
                      name,
                      acceptGender,
                      rosters,
                      batchApplicationId,
                      ccs,
                    }),
                    {
                      headers: {
                        // this will isolate the webhook events per env, because they have a group scope (PD & QA)
                        // depending on the sender, one of the webhooks will trigger
                        // this header allows us to send the agreement on behalf of any user
                        'x-api-user': `email:${
                          IS_PROD_ENV
                            ? 'recharter.support@scouting.org' // this account is part of the pd group
                            : 'rechartersupportqa@scouting.org' // this account is part of the qa group
                        }`,
                      },
                    },
                  )
                  .pipe(
                    mergeMap(() =>
                      Observable.concat(
                        of(createTransientDocumentWithAgreementResponse()),
                        of(onSuccess()),
                      ),
                    ),
                    tap(
                      ({ type }) =>
                        type ===
                          CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE &&
                        toastService.success(
                          intl.formatMessage({
                            id: 'recharter.RecharterNotification.createTransientDocumentWithAgreementSuccess',
                          }),
                        ),
                    ),
                  ),
              )
              .pipe(
                catchAndReport(() =>
                  Observable.concat(
                    of(createTransientDocumentWithAgreementError()),
                    of(onFailure()),
                  ),
                ),
              );
          })
          .pipe(
            catchAndReport(() =>
              Observable.concat(
                of(createTransientDocumentWithAgreementError()),
                of(onFailure()),
              ),
            ),
          ),
    ),
  );

const submitRecharterEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_RECHARTER_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const orgGuid = organizationGuidSel(state);
      const batchId = batchApplicationIdSel(state);
      return services.submitRecharter$(orgGuid, batchId).pipe(
        mergeMap(res =>
          Observable.concat(
            of(submitRecharterResponse(res)),
            of(recharterBatchInfoRequest()),
            of(closePaymentModal()),
          ),
        ),
        catchAndReport(err => of(submitRecharterError(err))),
      );
    }),
  );

const removePendingMembersEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(REMOVE_PENDING_MEMBERS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const pendingMembers = pendingMembersSel(state);
      const batchId = batchApplicationIdSel(state);
      const orgGuid = organizationGuidSel(state);
      const [info] = batchInformationSel(state);
      const payload = pendingMembers.map(member => ({
        term: info.term || 12,
        USAddress: !member.countryId
          ? true
          : member.countryId === COUNTRY_IDS.USA,
        isRemoved: true,
        batchApplicationPersonId: member.personId,
        memberTypeId: member.memberTypeId,
      }));
      return services
        .bulkUpdateRecharterRoster$(orgGuid, batchId, payload)
        .pipe(
          map(removePendingMembersResponse),
          catchAndReport(err => of(removePendingMembersError(err))),
        );
    }),
  );

const hardDeletePendingMemberEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(HARD_DELETE_PENDING_PERSON_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const batchId = batchApplicationIdSel(state);
      const orgGuid = organizationGuidSel(state);
      const batchApplicationPersonId = payload;
      return services
        .hardDeletePendingPerson$(batchApplicationPersonId, orgGuid, batchId)
        .pipe(
          map(hardDeletePendingPersonResponse),
          tap(
            ({ type }) =>
              type === HARD_DELETE_PENDING_PERSON_RESPONSE &&
              toastService.success(
                intl.formatMessage({
                  id: 'recharter.RecharterNotification.deletePerson',
                }),
              ),
          ),
          catchAndReport(err => of(hardDeletePendingPersonError(err))),
        );
    }),
  );

export default combineEpics(
  fetchFormDicts$,
  fetchRecharterRoster$,
  uploadDocumentEpic$,
  addMemberEpic$,
  inviteMemberEpic$,
  getUnitPositionsEpic$,
  getUnitBasicRequirementsEpic$,
  getUnitPaymentSummaryEpic$,
  updateMemberPositionEpic$,
  validateRosterEpic$,
  refreshRecharterRosterEpic$,
  getBatchInformationEpic$,
  getFutureCharterRequestEpic$,
  removeMembersEpic$,
  paymentProcessEpic$,
  achVerificationEpic$,
  getAdjustedOLRSettingsEpic$,
  updateMemberScoutsLifeEpic$,
  addBackMembersEpic$,
  sendRemindersEpic$,
  getCouncilsEpic$,
  getCouncilUnitsEpic$,
  markAsMultipleEpic$,
  unmarkAsMultipleEpic$,
  generateRecharterPDFEpic$,
  createTransientDocumentWithAgreementEpic$,
  submitRecharterEpic$,
  removePendingMembersEpic$,
  getBatchesEpic$,
  hardDeletePendingMemberEpic$,
  editTermEpic$,
  getOrgAdultsEpic$,
  getPaperworkLinkEpic$,
);
