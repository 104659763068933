import { isEmpty } from 'lodash';

import { RECHARTER_DATE_RESTRICTION } from '@config';
import { navigateToRoster } from '@shared/duck';
import { isRecharterPeriod } from '@shared/utils';
import { unitInfoRequestIfNotLoaded, unitInfoSel } from '@unit';

import { RecharterPage } from './components';
import { ROUTE_RECHARTER } from './duck';

const routes = {
  [ROUTE_RECHARTER]: {
    path: '/recharter',
    Component: RecharterPage,
    titleId: 'page.startRecharter',
    thunk: (dispatch, getState) => {
      const state = getState();
      if (isEmpty(unitInfoSel(state))) {
        dispatch(unitInfoRequestIfNotLoaded());
      }
      if (!isRecharterPeriod(unitInfoSel(state), RECHARTER_DATE_RESTRICTION)) {
        dispatch(navigateToRoster());
      }
    },
  },
};

export default routes;
