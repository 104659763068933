// helper functions for WebscriptReportPage
import { intl } from '@shared/localization';

const waitForDocumentLoadEvent = tab =>
  new Promise(resolve => {
    // load event already happened
    if (tab.document.readyState === 'complete') {
      return resolve();
    }
    tab.addEventListener('load', resolve);
  });

const waitForDocument = async tab => {
  if (tab.addEventListener) {
    return waitForDocumentLoadEvent(tab);
  }
  return new Promise(resolve =>
    setTimeout(() => resolve(waitForDocument(tab)), 500),
  );
};

const openReportPage = async contentPromise => {
  const reportTab = window.open('/reports/output', '_blank');

  try {
    const content = await contentPromise;

    reportTab.document.open();
    reportTab.document.write(content);
    reportTab.document.close();
  } catch (e) {
    await waitForDocument(reportTab);

    const errorMsgNode = reportTab.document.createElement('div');
    errorMsgNode.setAttribute('class', 'error');
    errorMsgNode.innerHTML = intl.formatHTMLMessage({
      id: 'reports.output.error',
    });
    const contentNode = reportTab.document.getElementById('content');
    contentNode.replaceChild(errorMsgNode, contentNode.firstElementChild);

    throw e;
  }
};

export default {
  openReportPage,
};
