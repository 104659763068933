import { ranksIds } from '@modules/shared/constants';

import { DenType } from '../../../types/enums';
import type { BulkEntryState } from './types';

export const initialState: BulkEntryState = {
  // Shared
  entryType: undefined,
  markAsApproved: false,
  completionDate: undefined,
  validationResults: [],
  selectedVersion: undefined,
  requirements: [],
  selectedScouts: [],
  selectedRequirements: [],
  comment: '',
  // pre-selection (before hits apply)
  preselectedInfo: {
    rank: undefined,
    adventure: undefined,
    meritBadge: undefined,
    version: undefined,
  },
  // Rank Bulk Entry
  selectedRank: undefined,
  scoutsRankVersionInfo: [],
  // Merit Badge
  selectedMeritBadge: undefined,
  mbEntryType: 'single',
  selectedMeritBadges: [],
  meritBadgeUserRequirements: [],
  // Adventures
  selectedAdventure: undefined,
  adventureUserRequirements: [],
};

// Make sure values
export const MapDenTypesRankId:
  | Record<DenType, number> & Record<number, DenType> = {
  lions: ranksIds.LION,
  bobcat: ranksIds.BOBCAT,
  tigers: ranksIds.TIGER,
  bears: ranksIds.BEAR,
  wolves: ranksIds.WOLF,
  webelos: ranksIds.WEBELOS,
  aol: ranksIds.ARROW_OF_LIGHT,
  // reverse
  [ranksIds.LION]: DenType.LION,
  [ranksIds.BOBCAT]: DenType.BOBCAT,
  [ranksIds.TIGER]: DenType.TIGER,
  [ranksIds.BEAR]: DenType.BEAR,
  [ranksIds.WOLF]: DenType.WOLF,
  [ranksIds.WEBELOS]: DenType.WEBELOS,
  [ranksIds.ARROW_OF_LIGHT]: DenType.AOL,
};

export const COMMENT_SUBJECT = 'Bulk Entry Comment';
