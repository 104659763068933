import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  FilterButton,
  PaginationTotal,
  TableMobile,
  FlexSpacer,
  TableFilterCard,
  SortSelect,
  sorterPropTypes,
} from '@shared';

const { StickyTitle } = TableMobile;
const { Option } = SortSelect;
const { Collapse, Panel } = TableFilterCard;

const UnitsListHeader = ({ items, sorter, onSorterChange }) => {
  const [tempSorter, setTempSorter] = useState(sorter);

  const handleSorterChange = useCallback(sorter => {
    setTempSorter(sorter);
  }, []);
  const handleOpenChange = useCallback(
    open => {
      if (open) {
        setTempSorter(sorter);
      }
    },
    [sorter],
  );
  const handleSubmit = useCallback(() => {
    onSorterChange(tempSorter);
  }, [onSorterChange, tempSorter]);

  return (
    <StickyTitle key="header">
      <PaginationTotal total={items.length} />
      <FlexSpacer />
      <TableFilterCard
        content={
          <Collapse>
            <Panel
              key="sorter"
              header={<FormattedMessage id="shared.sortBy" />}
              mobileOnly
            >
              <SortSelect sorter={tempSorter} onChange={handleSorterChange}>
                <Option name="type" type="string">
                  <FormattedMessage id="councilUnits.CouncilUnitsPage.column.type" />
                </Option>
                <Option name="unitNumber" type="number">
                  <FormattedMessage id="councilUnits.CouncilUnitsPage.column.unitNumber" />
                </Option>
                <Option name="charteredOrg" type="string">
                  <FormattedMessage id="councilUnits.CouncilUnitsPage.column.charteredOrg" />
                </Option>
              </SortSelect>
            </Panel>
          </Collapse>
        }
        onOpenChange={handleOpenChange}
        onSubmit={handleSubmit}
      >
        <FilterButton />
      </TableFilterCard>
    </StickyTitle>
  );
};

UnitsListHeader.propTypes = {
  items: PropTypes.array.isRequired,
  sorter: sorterPropTypes,
  onSorterChange: PropTypes.func.isRequired,
};

export default UnitsListHeader;
