import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Button from '../Button';
import FabItem from './FabItem';
import FabDivider from './FabDivider';
import styles from './Fab.less';

class Fab extends React.Component {
  state = {
    open: !!(this.props.content && (this.props.defaultOpen || this.props.open)),
  };

  handleToggleOpen = e => {
    const { content, onOpenChange, onClick } = this.props;
    const open = this.isOpen();

    if (onClick) {
      onClick(e);
    }
    if (content) {
      if (onOpenChange) {
        onOpenChange(!open);
      }

      this.setState({ open: !open });
    }
  };

  handleClose = () => {
    if (this.props.onOpenChange) {
      this.props.onOpenChange(false);
    }
    this.setState({ open: false });
  };

  isOpen = () => {
    const { content, visible, open } = this.props;
    return content && visible && (this.isControlled() ? open : this.state.open);
  };

  isControlled = () => this.props.open != null;

  render() {
    const {
      visible,
      children,
      className,
      containerClassName,
      content,
      color,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onOpenChange,
      ...rest
    } = this.props;
    const open = this.isOpen();
    const classNames = cn(className, styles.fab, {
      [styles.visible]: visible,
      [styles.open]: open,
    });
    const itemsContainerClassNames = cn(styles.itemsContainer, {
      [styles.visible]: visible,
      [styles.open]: open,
    });
    const containerClassNames = cn(styles.container, containerClassName);

    return (
      <React.Fragment>
        <CSSTransition in={open} timeout={200} classNames="fade" appear>
          {transitionState => (
            <div
              className={cn(styles.mask, transitionState)}
              onClick={this.handleClose}
            />
          )}
        </CSSTransition>
        <div className={containerClassNames}>
          <div className={itemsContainerClassNames} onClick={this.handleClose}>
            {content}
          </div>
          <Button
            className={classNames}
            color={color}
            fitText
            shadow
            size="large"
            type="primary"
            {...rest}
            onClick={this.handleToggleOpen}
          >
            {children}
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

Fab.propTypes = {
  visible: PropTypes.bool,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node,
  color: PropTypes.string,
  containerClassName: PropTypes.string,
  onOpenChange: PropTypes.func,
  onClick: PropTypes.func,
};

Fab.defaultProps = {
  visible: true,
  color: 'scouting-red',
};

Fab.Item = FabItem;
Fab.Divider = FabDivider;

export default Fab;
