import { lazier } from '@shared';

export const CalendarPage = lazier(() => import('./CalendarPage'));

export const CalendarActivityDetails = lazier(() =>
  import('./CalendarActivityDetails'),
);

export const CalendarEventDetails = lazier(() =>
  import('./CalendarEventDetails'),
);

export const CalendarCreateActivity = lazier(() =>
  import('./CalendarCreateActivity'),
);

export const CalendarEditActivity = lazier(() =>
  import('./CalendarEditActivity'),
);
