import {
  MyPaymentLogsPage,
  UnitPaymentLogsPage,
  YouthPaymentLogsPage,
} from './components';
import {
  ROUTE_MY_PAYMENT_LOGS,
  ROUTE_UNIT_PAYMENT_LOGS,
  ROUTE_YOUTH_PAYMENT_LOGS,
} from './duck';

const routes = {
  [ROUTE_MY_PAYMENT_LOGS]: {
    path: '/myPaymentLogs',
    Component: MyPaymentLogsPage,
    titleId: 'page.myPaymentLogs',
  },
  [ROUTE_UNIT_PAYMENT_LOGS]: {
    path: '/unitPaymentLogs',
    Component: UnitPaymentLogsPage,
    titleId: 'page.unitPaymentLogs',
  },
  [ROUTE_YOUTH_PAYMENT_LOGS]: {
    path: '/paymentLogs/:userId',
    Component: YouthPaymentLogsPage,
    titleId: 'page.youthPaymentLogs',
  },
};

export default routes;
