import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import NavigateBeforeIcon from 'material-ui-icons/NavigateBefore';
import NavigateNextIcon from 'material-ui-icons/NavigateNext';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { organizationGuidSel } from '@context';
import { Button, Calendar, S, SingleConfirmationModal, Spin } from '@shared';

import {
  activitiesSel,
  deleteActivityRequest,
  getActivitiesRequest,
  loadingCalendarSel,
} from '../../duck';
import styles from './ActivityCalendar.less';
import ActivityList from './ActivityList';
import CalendarCell from './CalendarCell';

const getActivitiesForDate = (date, activities) =>
  activities.filter(({ startDateTime, endDateTime }) =>
    date.isBetween(startDateTime, endDateTime, 'day', '[]'),
  );

const hasActivities = (...args) => {
  const activitiesInDate = getActivitiesForDate(...args);
  return activitiesInDate.length > 0;
};

const ActivityCalendar = ({
  activityTypeId,
  defaultDate,
  disableFutureDates,
  onCreateNew,
  onSelectActivity,
  onCloneActivity,
  onJoinActivity,
  openNewIfNoActivity,
  disableGetActivities,
}) => {
  const dispatch = useDispatch();
  const confirmModalRef = useRef();
  const organizationGuid = useSelector(organizationGuidSel);
  const isCalendarLoading = useSelector(loadingCalendarSel);
  const activities = useSelector(activitiesSel);
  const [selectedDate, setSelectedDate] = useState(defaultDate || moment());

  const selectedMonth = moment(selectedDate).startOf('month').format('YYYY-MM');
  const handleSelectedDateChange = useCallback(
    momentDate => {
      setSelectedDate(momentDate);
      const activitiesForDate = getActivitiesForDate(momentDate, activities);
      if (openNewIfNoActivity && activitiesForDate.length === 0) {
        onCreateNew(momentDate);
      }
    },
    [activities, onCreateNew, openNewIfNoActivity],
  );
  const handleCreateNewActivity = (isPersonalActivity = false) => {
    onCreateNew(selectedDate, isPersonalActivity);
  };
  const handlePrev = useCallback(() => {
    setSelectedDate(moment(selectedDate).subtract(1, 'month'));
  }, [selectedDate]);
  const handleToday = useCallback(() => {
    setSelectedDate(moment());
  }, []);
  const handleNext = useCallback(() => {
    setSelectedDate(moment(selectedDate).add(1, 'month'));
  }, [selectedDate]);
  const isDateDisabled = useCallback(
    momentDate => {
      if (isCalendarLoading) {
        return true;
      }
      return disableFutureDates && moment().isBefore(momentDate, 'day');
    },
    [disableFutureDates, isCalendarLoading],
  );

  const getActivities = useCallback(() => {
    if (!disableGetActivities) {
      const startDateTime = moment(selectedMonth, 'YYYY-MM').startOf('month');
      const endDateTime = moment(selectedMonth, 'YYYY-MM').endOf('month');
      const payload = {
        activityTypeId,
        organizationGuid,
        startDateTime,
        endDateTime,
      };
      dispatch(getActivitiesRequest(payload));
    }
  }, [
    dispatch,
    disableGetActivities,
    activityTypeId,
    organizationGuid,
    selectedMonth,
  ]);

  const handleOnSuccessRemove = useCallback(() => {
    getActivities();
  }, [getActivities]);

  const onDeleteActivity = useCallback(
    activity => {
      confirmModalRef.current.openConfirmationModal({
        confirmData: activity.id,
      });
    },
    [confirmModalRef],
  );

  const handleConfrimDelete = useCallback(
    activityId => {
      dispatch(
        deleteActivityRequest({
          activityId,
          callbackSuccess: handleOnSuccessRemove,
        }),
      );
    },
    [dispatch, handleOnSuccessRemove],
  );

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  const activitiesForSelectedDate = useMemo(
    () => getActivitiesForDate(selectedDate, activities),
    [selectedDate, activities],
  );

  return (
    <div>
      <Spin spinning={isCalendarLoading} delay={200}>
        <div className={styles.calendarWrapper}>
          <Calendar
            mode="month"
            value={selectedDate}
            headerRender={() => (
              <div className={styles.calendarHeader}>
                <Button
                  ghost
                  noBorder
                  fitText
                  className={styles.navButton}
                  onClick={handlePrev}
                >
                  <NavigateBeforeIcon />
                </Button>
                <S size="3">{selectedDate.format('MMM YYYY')}</S>
                <Button
                  ghost
                  noBorder
                  fitText
                  className={styles.navButton}
                  onClick={handleNext}
                >
                  <NavigateNextIcon />
                </Button>
              </div>
            )}
            dateFullCellRender={date => (
              <CalendarCell
                disabled={isDateDisabled(date)}
                selectedDate={selectedDate}
                cellDate={date}
                hasActivities={
                  !disableGetActivities && hasActivities(date, activities)
                }
                onClick={handleSelectedDateChange}
              />
            )}
          />
          <Button ghost noBorder fitText onClick={handleToday}>
            today
          </Button>
        </div>
      </Spin>
      {!isCalendarLoading && (
        <ActivityList
          items={activitiesForSelectedDate}
          onSelect={onSelectActivity}
          onClone={onCloneActivity}
          onJoin={onJoinActivity}
          onDelete={onDeleteActivity}
          onNew={handleCreateNewActivity}
          disableGetActivities={disableGetActivities}
        />
      )}
      <SingleConfirmationModal
        ref={confirmModalRef}
        confirmBtnText={<FormattedMessage id="shared.confirm" />}
        message={
          <FormattedHTMLMessage id="progress.common.ActivityCalendar.deleteMessage" />
        }
        onConfirm={handleConfrimDelete}
      />
    </div>
  );
};

ActivityCalendar.propTypes = {
  // from parent
  activityTypeId: PropTypes.number.isRequired,
  defaultDate: PropTypes.object,
  disableFutureDates: PropTypes.bool,
  onCreateNew: PropTypes.func.isRequired,
  onSelectActivity: PropTypes.func.isRequired,
  onCloneActivity: PropTypes.func.isRequired,
  onJoinActivity: PropTypes.func.isRequired,
  openNewIfNoActivity: PropTypes.bool,
  disableGetActivities: PropTypes.bool,
};

ActivityCalendar.defaultProps = {
  openNewIfNoActivity: false,
};

export default ActivityCalendar;
