import { of } from 'rxjs';
import { switchMap, map, mapTo, tap, filter } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { catchAndReport } from '@utils/rxjs/operators';
import { tablePageSizeSel, SET_TABLE_PAGE_SIZE, intl } from '@shared';
import { isMobileSel } from '@screen';
import { toastService } from '@toasts';
import { isCurrentPageSel } from '@location';
import { getMbcs$ } from '../../common';
import {
  ROUTE_ASSIGN_MBC,
  MBC_REQUEST,
  mbcRequest,
  mbcResponse,
  mbcError,
  ASSIGN_MBC_REQUEST,
  ASSIGN_MBC_RESPONSE,
  assignMbcResponse,
  assignMbcError,
  SET_PAGE,
  SET_PAGE_SIZE_MOBILE,
  SET_FILTER,
  SET_SEARCH,
} from './actions';
import { pageSel, pageSizeMobileSel, filtersSel } from './selectors';
import { assignMbc$ } from './services';

const loadMbcsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(MBC_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const isMobile = isMobileSel(state);
      const page = isMobile ? 1 : pageSel(state);
      const pageSize = isMobile
        ? pageSizeMobileSel(state)
        : tablePageSizeSel(state);
      const filter = filtersSel(state);

      return getMbcs$({ page, pageSize, filter }).pipe(
        map(mbcResponse),
        catchAndReport(err => of(mbcError(err))),
      );
    }),
  );

const reloadMbcsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(
      SET_PAGE,
      SET_PAGE_SIZE_MOBILE,
      SET_TABLE_PAGE_SIZE,
      SET_FILTER,
      SET_SEARCH,
      ASSIGN_MBC_RESPONSE,
    ),
    filter(() => isCurrentPageSel(state$.value, ROUTE_ASSIGN_MBC)),
    mapTo(mbcRequest()),
  );

const assignMbcEpic$ = action$ =>
  action$.pipe(
    ofType(ASSIGN_MBC_REQUEST),
    switchMap(({ payload }) =>
      assignMbc$(payload).pipe(
        map(assignMbcResponse),
        tap(() =>
          toastService.success(
            intl.formatMessage({ id: 'mbcAssign.saveSuccess' }),
          ),
        ),
        catchAndReport(err => of(assignMbcError(err))),
      ),
    ),
  );

export default combineEpics(loadMbcsEpic$, reloadMbcsEpic$, assignMbcEpic$);
