import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { locales } from '../../constants';
import { setLocale, localeSel } from '../../duck';

const LanguagePicker = ({ locale, onSetLocale }) => {
  const localeOptions = Object.keys(locales).map(loc => (
    <button
      key={loc}
      disabled={loc === locale}
      onClick={() => onSetLocale(loc)}
    >
      {loc}
    </button>
  ));

  return <div>{localeOptions}</div>;
};

LanguagePicker.propTypes = {
  locale: PropTypes.string.isRequired,
  onSetLocale: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  locale: localeSel(state),
});

const mapDispatchToProps = dispatch => ({
  onSetLocale: locale => dispatch(setLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
