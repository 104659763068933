import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { appName } from '@shared/constants';
import toastService from '@toasts/service';
import { setOffline } from '../../duck/actions';

class OfflineNotification extends React.PureComponent {
  componentDidMount = () => {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  };

  componentWillUnmount = () => {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  };

  handleOnline = () => {
    this.handleDismiss();
    this.toastId = toastService.success(
      '',
      <FormattedMessage id="offline.OfflineNotification.online" />,
      {
        onUserDismiss: this.handleDismiss,
      },
    );
    this.props.onSetOffline(false);
  };

  handleOffline = () => {
    this.handleDismiss();
    this.toastId = toastService.error(
      <FormattedMessage
        id="offline.OfflineNotification.offlineDesc"
        values={{ appName }}
      />,
      <FormattedMessage id="offline.OfflineNotification.offline" />,
      {
        onUserDismiss: this.handleDismiss,
      },
    );
    this.props.onSetOffline(true);
  };

  handleDismiss = () => {
    if (this.toastId) {
      toastService.dismiss(this.toastId);
      this.toastId = null;
    }
  };

  render() {
    return '';
  }
}

OfflineNotification.propTypes = {
  onSetOffline: PropTypes.func.isRequired,
};

const mapDispatch = dispatch => ({
  onSetOffline: isOffline => dispatch(setOffline(isOffline)),
});

export default connect(null, mapDispatch)(OfflineNotification);
