import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { ALLOW_DELETE_ACTIVITIES } from '@config';
import { isCurrentPageSel } from '@location';
import { useCanAddAnyPeple } from '@modules/activities/duck/hooks';
import { isMobileSel } from '@screen';
import {
  Card,
  Form,
  ROUTE_OWN_ACTIVITY_LOGS,
  SingleConfirmationModal,
  Vr,
  emptyObj,
  intl,
  isParentOrYouthMemberSel,
  packRosterItemsSel,
} from '@shared';
import { hasPermissionSel, permissions } from '@user';
import { validateForm } from '@utils';

import {
  ActionButtons,
  ActivitySelector,
  AddPersonButton,
  ImportText,
  ModalMode,
  PersonsList,
  SelectMultiplePersonsButton,
  activityTypeIds,
  deleteActivityRequest,
  deselectPerson,
  getFilteredFormValues,
  isFutureStartDate,
  personsAndIdsActivitySel,
  personsToRemoveSel,
  selectedActivitySel,
  setActionButtonSel,
  setInitialFormState,
  setPersonsToRemove,
} from '../../../common';
import { detailModes } from '../../constants';
import {
  allowEditPersonsSel,
  allowRemovePersonsSel,
  closeLongCruiseModal,
  detailModeSel,
  disableFutureDaysSel,
  disableGetActivitiesSel,
  loadingSel,
  personsSel,
  recordLongCruiseRequest,
  setDetailMode,
} from '../../duck';
import BackButton from './BackButton';
import LongCruiseDaysTabs from './LongCruiseDaysTabs';
import styles from './LongCruiseForm.less';
import LongCruiseInfo from './LongCruiseInfo';
import LongCruiseStats from './LongCruiseStats';

const LongCruiseForm = ({ modalMode, form, setGetFieldsValue }) => {
  const dispatch = useDispatch();
  const [isRemoving, setIsRemoving] = useState(false);
  const detailMode = useSelector(detailModeSel);
  const isMobile = useSelector(isMobileSel);
  const actionButton = useSelector(setActionButtonSel);
  const canAddAnyPeople = useCanAddAnyPeple();
  const loading = useSelector(loadingSel);
  const availablePersons = useSelector(packRosterItemsSel);
  const selectedPersonsRoster = useSelector(personsSel);
  const personsAndIdsActivity = useSelector(personsAndIdsActivitySel);
  const removedPersons = useSelector(personsToRemoveSel);
  const allowEditPersons = useSelector(allowEditPersonsSel);
  const disableGetActivities = useSelector(disableGetActivitiesSel);
  const disableFutureDays = useSelector(disableFutureDaysSel);
  const allowRemovePersons = useSelector(allowRemovePersonsSel);
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );
  const allPersonsSelected = useMemo(
    () => selectedPersonsRoster.length === availablePersons.length,
    [selectedPersonsRoster, availablePersons],
  );
  const canEdit = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_DATA, permissions.EDIT_ACTIVITIES),
  );
  const activityValues = useSelector(selectedActivitySel);
  const isParentOrYouthMember = useSelector(isParentOrYouthMemberSel);
  const isOwnLogsPage = useSelector(state =>
    isCurrentPageSel(state, ROUTE_OWN_ACTIVITY_LOGS),
  );
  const hasLimitedActions = isParentOrYouthMember || isOwnLogsPage;
  const DeletePersonRef = useRef();
  const { personsInActivity, userIds: notRemovableUserIds } =
    personsAndIdsActivity;

  const selectedActivity =
    detailMode !== detailModes.SUGGEST ? activityValues : emptyObj;
  const { id: activityId, isPersonalActivity } = selectedActivity;
  const { setFieldsValue, getFieldsValue } = form;

  const handleOnSuccessRemove = useCallback(() => {
    setIsRemoving(false);
    dispatch(closeLongCruiseModal());
  }, [dispatch, setIsRemoving]);

  const handleOnErrorRemove = useCallback(() => {
    setIsRemoving(false);
  }, [setIsRemoving]);

  const handleRemoveActivity = useCallback(() => {
    setIsRemoving(true);
    const removeIds = personsInActivity.reduce((acc, person) => {
      const activityValueIds = person.activityValues.map(
        activityValue => activityValue.id,
      );
      return acc.concat(activityValueIds);
    }, []);
    dispatch(
      deleteActivityRequest(
        {
          activityId,
          activityValueIds: removeIds,
          callbackSuccess: handleOnSuccessRemove,
          callbackError: handleOnErrorRemove,
        },
        handleOnSuccessRemove,
        handleOnErrorRemove,
      ),
    );
  }, [
    dispatch,
    activityId,
    personsInActivity,
    handleOnSuccessRemove,
    handleOnErrorRemove,
  ]);

  const setActivityValues = useCallback(
    activity => {
      const allowedValues = pick(activity, Object.keys(getFieldsValue()));
      const isFuture = isFutureStartDate(allowedValues.startDate);
      const setFormValues = isFuture
        ? getFilteredFormValues(activityTypeIds.LONG_CRUISE, allowedValues)
        : allowedValues;
      setFieldsValue(setFormValues);
      dispatch(setInitialFormState(setFormValues));
    },
    [dispatch, setFieldsValue, getFieldsValue],
  );

  useLayoutEffect(() => {
    if (modalMode === ModalMode.EDIT || selectedActivity) {
      setActivityValues(selectedActivity);
    }
  }, [modalMode, setActivityValues, selectedActivity]);

  const validate = useCallback(() => validateForm(form), [form]);

  const saveFormData = useCallback(async () => {
    const activityData = await validate();
    if (activityData) {
      dispatch(recordLongCruiseRequest({ activityData, closeOnSuccess: true }));
    }
  }, [validate, dispatch]);

  const startDate = form.getFieldValue('startDate');
  const isFutureDate = isFutureStartDate(startDate);
  const isTabsCardVisible =
    (modalMode === ModalMode.RECORD && detailMode !== detailModes.SUGGEST) ||
    allowEditPersons;
  const isEditMode = detailMode === detailModes.EDIT;
  const persons = useMemo(() => {
    const mergePersons = isEditMode
      ? uniqBy([...selectedPersonsRoster, ...personsInActivity], 'userId')
      : selectedPersonsRoster;
    return isEditMode
      ? mergePersons.filter(person => !removedPersons.includes(person.userId))
      : mergePersons;
  }, [isEditMode, selectedPersonsRoster, personsInActivity, removedPersons]);
  const personIds = useMemo(
    () => (persons ? persons.map(person => person.userId) : []),
    [persons],
  );
  const rosterPersonIds = useMemo(
    () =>
      selectedPersonsRoster
        ? selectedPersonsRoster.map(person => person.userId)
        : [],
    [selectedPersonsRoster],
  );

  const handleConfirm = useCallback(
    id => {
      dispatch(deselectPerson(id));
      dispatch(setPersonsToRemove(id));
    },
    [dispatch],
  );

  const onDeselectPerson = useCallback(
    (id, personInfo) => {
      if (ALLOW_DELETE_ACTIVITIES && personIds.length === 1 && isEditMode) {
        DeletePersonRef.current.openConfirmationModal({
          confirmData: id,
          customeMessage: (
            <FormattedMessage
              id="progress.common.deleteActivity"
              values={{ name: personInfo.personShortFullName }}
            />
          ),
          customExtraButtons: [
            {
              key: 'deleteActivity',
              onClick: handleRemoveActivity,
              label: (
                <FormattedMessage id="progress.common.deleteActivityConfirm" />
              ),
            },
          ],
          customConfirmBtnText: (
            <FormattedMessage id="progress.common.deleteActivityReject" />
          ),
        });
      } else if (notRemovableUserIds.includes(id) && isEditMode) {
        DeletePersonRef.current.openConfirmationModal({
          confirmData: id,
          customeMessage: (
            <FormattedMessage
              id="progress.common.deletePersonConfirm"
              values={{ name: personInfo.personShortFullName }}
            />
          ),
        });
      } else {
        dispatch(deselectPerson(id));
      }
    },
    [
      dispatch,
      notRemovableUserIds,
      DeletePersonRef,
      handleRemoveActivity,
      personIds,
      isEditMode,
    ],
  );

  useEffect(() => {
    setGetFieldsValue(getFieldsValue);
  }, [setGetFieldsValue, getFieldsValue]);

  return (
    <React.Fragment>
      {!isParentOrYouthMember && (
        <SingleConfirmationModal
          ref={DeletePersonRef}
          onConfirm={handleConfirm}
          loading={isRemoving}
        />
      )}
      {detailMode !== detailModes.SUGGEST && !isMobile && (
        <LongCruiseStats form={form} persons={persons} />
      )}
      {detailMode === detailModes.EDIT && <ImportText />}
      <div className={styles.mainContentWrapper}>
        <Form>
          <Card noPadding={!isMobile} shadow={isMobile}>
            <div className={styles.inputsWrapper}>
              <div
                className={cn({
                  [styles.noDisplay]: detailMode !== detailModes.SUGGEST,
                })}
              >
                <ActivitySelector
                  form={form}
                  selectedUserIds={rosterPersonIds}
                  modalMode={modalMode}
                  disableFutureDays={hasLimitedActions || disableFutureDays}
                  disableGetActivities={disableGetActivities}
                  activityTypeId={activityTypeIds.LONG_CRUISE}
                  setDetailMode={setDetailMode}
                  sectionTitle={
                    <FormattedMessage id="LongCruiseForm.information" />
                  }
                />
              </div>
              <div
                className={cn({
                  [styles.noDisplay]: detailMode === detailModes.SUGGEST,
                })}
              >
                {modalMode !== ModalMode.EDIT && !isMobile && (
                  <BackButton getFieldsValue={getFieldsValue} />
                )}
                <LongCruiseInfo
                  disableFutureDays={hasLimitedActions || disableFutureDays}
                  loading={loading}
                  form={form}
                  modalMode={modalMode}
                  detailMode={detailMode}
                />
              </div>
            </div>
          </Card>
          <Card
            className={cn({
              [styles.noDisplay]: !isTabsCardVisible,
            })}
            noPadding={!isMobile}
            shadow={isMobile}
          >
            {persons.length > 0 && detailMode !== detailModes.SUGGEST && (
              <div className={styles.inputsWrapper}>
                <div className={styles.inputsForm}>
                  {isTabsCardVisible && !isFutureDate && (
                    <LongCruiseDaysTabs
                      form={form}
                      persons={persons}
                      activityTypeId={activityTypeIds.LONG_CRUISE}
                      showTabDescription={!hasLimitedActions}
                    />
                  )}
                  {isTabsCardVisible && isFutureDate && (
                    <i>
                      {intl.formatMessage({
                        id: 'LongCruiseForm.cantRecordFuture',
                      })}{' '}
                    </i>
                  )}
                </div>
              </div>
            )}
          </Card>
        </Form>
        {(modalMode === ModalMode.RECORD || allowEditPersons) && (
          <React.Fragment>
            <Vr className={styles.inputsWrapperVr} />
            <div className={styles.personsList}>
              {!hasLimitedActions && !selectedActivity.isPersonalActivity && (
                <Fragment>
                  <AddPersonButton
                    persons={availablePersons}
                    selectedPersons={personIds}
                    onDeselectPerson={onDeselectPerson}
                    notAllowedToRemoveIds={notRemovableUserIds}
                    canRemove={selectedPersonsRoster.length > 1 && isEditMode}
                  />
                  {canAddAnyPeople && (
                    <SelectMultiplePersonsButton
                      persons={availablePersons}
                      allPersonsSelected={allPersonsSelected}
                      notAllowedToRemoveIds={notRemovableUserIds}
                      canRemove={selectedPersonsRoster.length > 1}
                    />
                  )}
                </Fragment>
              )}
              <PersonsList
                notAllowedToRemove={!canApprove && notRemovableUserIds}
                stats={<LongCruiseStats form={form} persons={persons} />}
                persons={persons}
                type="longCruise"
                onDeselectPerson={onDeselectPerson}
                allowRemoveYouth={
                  allowRemovePersons &&
                  !isParentOrYouthMember &&
                  !isPersonalActivity
                }
                allowRemoveAdults={
                  allowRemovePersons &&
                  !isParentOrYouthMember &&
                  !isPersonalActivity
                }
                allowEligibleYouth
                allowEligibleAdults
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <ActionButtons
        form={form}
        modalMode={modalMode}
        actionButton={actionButton}
        mainButtonsActive={detailMode !== detailModes.SUGGEST}
        disabled={!canEdit}
        loading={loading}
        onSave={saveFormData}
      />
    </React.Fragment>
  );
};

LongCruiseForm.propTypes = {
  modalMode: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  setGetFieldsValue: PropTypes.func.isRequired,
};

export default Form.create()(LongCruiseForm);
