import { advancementTypes, syncOperations } from '@shared/constants';
import { esbApiService } from '@utils';

const getUnapproveAdvancementType = type => {
  switch (type) {
    case advancementTypes.AWARDS: {
      return 'Award';
    }
    case advancementTypes.ADVENTURES: {
      return 'Adventure';
    }
    case advancementTypes.RANKS: {
      return 'Rank';
    }
    case advancementTypes.MERIT_BADGES: {
      return 'Merit Badge';
    }
    default: {
      throw new Error(`Advancement Type ${type} not valid`);
    }
  }
};

const unapproveAdvancementSerialize =
  ({
    date,
    organizationGuid,
    userId,
    advancementType,
    advancementId,
    userAwardId,
  }) =>
  () => ({
    operation: syncOperations.UNAPPROVE_ADVANCEMENT,
    date,
    organizationGuid,
    userId,
    advancementType,
    advancementId,
    userAwardId,
  });

const unapproveAdvancement$ = ({
  userId,
  organizationGuid,
  advancementType,
  advancementId,
  userAwardId,
  newStatus,
}) =>
  esbApiService.postOrDefer$(
    '/advancements/unapprove',
    [
      {
        userId: Number(userId),
        organizationGuid,
        advancementId: Number(advancementId),
        userAwardId: userAwardId ? userAwardId : null,
        advancementType: getUnapproveAdvancementType(advancementType),
        newStatus: newStatus ? newStatus : 'Not Complete',
      },
    ],
    {
      gtm: {
        label: '/advancements/unapprove',
      },
      serialize: unapproveAdvancementSerialize({
        organizationGuid,
        userId,
        advancementType,
        advancementId,
        userAwardId,
      }),
    },
  );

export default {
  unapproveAdvancement$,
};
