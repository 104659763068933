import {
  cloneDeep,
  flatten,
  groupBy,
  isEmpty,
  map,
  orderBy,
  partition,
  some,
} from 'lodash';
import moment from 'moment-timezone';
import { PDFDocument, TextAlignment } from 'pdf-lib';

import { getIsDenChief } from '@modules/advancement/packRoster/utilsTyped';
import { intl } from '@modules/shared';
import { ProgramId, dobDateFormatShort, timeFormat } from '@shared/constants';
import { isValueTruthy } from '@shared/utils';
import { momentWithOffset, sorters } from '@utils';

import templatePdfUrl from '../../assets/bsapermissionslip2014.pdf';
import {
  attendeesTypes,
  eventTypes,
  recurrenceTypesIds,
  rsvpTypes,
  sortTags,
} from './constants';

export const getAttendeeType = rsvp => {
  const key = Object.keys(rsvpTypes).find(key => {
    const rsvpParsed = rsvp === '' || rsvp === undefined ? null : rsvp;
    if (typeof rsvpParsed === 'string' && rsvpTypes[key] !== null) {
      return rsvpTypes[key].toString() === rsvpParsed;
    }
    return rsvpTypes[key] === rsvpParsed;
  });
  return attendeesTypes[key];
};

export const getUserRsvp = (userId, invitedUsers) => {
  const existingUser = invitedUsers.find(user => userId === user.userId) || {};
  return existingUser.rsvp;
};

export const changeRsvpStatus = (users, userId, status) =>
  users.map(user =>
    user.userId === userId ? { ...user, status: status } : user,
  );

export const updateRsvpStatus = (users, person) =>
  users.map(user =>
    user.userId === person.userId ? { ...user, ...person } : user,
  );

export const updateGuestRsvpStatus = (users, person) =>
  users.map(user =>
    person.calendarEventGuestId
      ? user.calendarEventGuestId === person.calendarEventGuestId
      : user.email === person.email
      ? { ...user, ...person }
      : user,
  );

const getChildRequirements = (requirements, parentId) =>
  requirements
    .filter(req => req.parentRequirementId === parentId)
    .map((req, index) => ({
      ...req,
      position: index + 1,
      childRequirements: getChildRequirements(requirements, req.id),
    }));

export const mapRequirementsDtoToModel = (
  dto,
  advancementType,
  advancementId,
) => {
  const isNote = ({ listNumber, parentRequirementId }) =>
    !listNumber && !parentRequirementId;
  let [notes, requirements] = partition(dto.requirements, isNote);
  requirements = requirements
    .sort(sorters.string('listNumber'))
    .map((req, index) => ({
      ...req,
      position: index + 1,
      active: isValueTruthy(req.active),
      required: isValueTruthy(req.required),
      optional: isValueTruthy(req.optional),
      checked: false,
      isHomeSelected: false,
    }));

  const withChilds =
    requirements &&
    requirements.some(({ parentRequirementId }) => parentRequirementId === '');

  requirements = withChilds
    ? getChildRequirements(requirements, '')
    : requirements;

  return {
    ...dto,
    advancementId,
    advancementType,
    imgUrl: dto.imageUrl100,
    notes,
    requirements,
  };
};

export const groupRequirementsByChildren = requirements => {
  const { withChildren = [], withoutChildren = [] } = groupBy(
    requirements,
    req =>
      !isEmpty(req.childRequirements) ? 'withChildren' : 'withoutChildren',
  );
  return { withChildren, withoutChildren };
};

export const getChildrenRequirementSelections = item =>
  !isEmpty(item.childRequirements)
    ? {
        ids: item.childRequirements.map(item => item.id),
        childrenRequired: +item.childrenRequired,
      }
    : {};

const fillInitArray = requirements =>
  requirements.map(({ id, isHomeSelected }) => ({
    id,
    isHomeSelected: isHomeSelected || false,
  }));

export const initSelectedItems = requirements => {
  const { withChildren, withoutChildren } =
    groupRequirementsByChildren(requirements);

  let initItmes = fillInitArray(withoutChildren);
  withChildren.forEach(item => {
    const filtered = item.childRequirements.slice(0, +item.childrenRequired);
    initItmes = initItmes.concat(fillInitArray(filtered));
  });

  return initItmes;
};

export const getSelectedRequirements = (requirements, selectedItems) =>
  requirements
    .filter(req => selectedItems.find(item => item.id === req.id))
    .map(req => {
      const selected = selectedItems.find(item => item.id === req.id);
      return {
        ...req,
        isHomeSelected: selected.isHomeSelected || false,
      };
    });

export const formatSelectedRequirements = (requirements, selectedItems) => {
  const { withChildren, withoutChildren } =
    groupRequirementsByChildren(requirements);

  const seletectedWithoutChildren = getSelectedRequirements(
    withoutChildren,
    selectedItems,
  );
  const seletectedWithChildren = withChildren
    .map(req => {
      const childRequirements = getSelectedRequirements(
        req.childRequirements,
        selectedItems,
      );
      return { ...req, childRequirements };
    })
    .filter(({ childRequirements }) => !isEmpty(childRequirements));
  return seletectedWithoutChildren.concat(seletectedWithChildren);
};

export const cleanRequirementsIds = str =>
  str.replace(/^\s*,+\s*|\s*,+\s*$/g, '');

export const getDefaultsEventUnits = (selectedUnits = []) => {
  if (!selectedUnits.length) return {};
  // Get unit id (not subunit)
  const foundUnit = selectedUnits.find(
    unit => !unit.unitId || unit.unitId === unit.id,
  );
  const defaultEventUnit = foundUnit
    ? { ...foundUnit }
    : { ...selectedUnits[0] };
  defaultEventUnit.patrolId =
    defaultEventUnit.unitId && !defaultEventUnit.isDen
      ? defaultEventUnit.id
      : undefined;
  defaultEventUnit.denId =
    defaultEventUnit.unitId && defaultEventUnit.isDen
      ? defaultEventUnit.id
      : undefined;
  defaultEventUnit.unitId = defaultEventUnit.unitId || defaultEventUnit.id;

  // Remove unit from list
  const updatedSelectedUnits = selectedUnits.filter(
    unit => unit.id !== defaultEventUnit.id,
  );

  return { defaultEventUnit, updatedSelectedUnits };
};

export const getRecurrentEventUnits = (selectedUnits = []) => {
  if (!selectedUnits.length) return [];

  const formattedUnits = [];

  selectedUnits.forEach(unit => {
    if (unit.unitId) {
      const foundUnitIndex = formattedUnits.findIndex(
        item => item.unitId === unit.unitId,
      );

      if (foundUnitIndex !== -1) {
        formattedUnits[foundUnitIndex].subUnits
          ? formattedUnits[foundUnitIndex].subUnits.push({ subUnitId: unit.id })
          : (formattedUnits[foundUnitIndex].subUnits = [
              { subUnitId: unit.id },
            ]);
        return;
      }

      formattedUnits.push({
        unitId: unit.unitId,
        subUnits: [{ subUnitId: unit.id }],
      });
      return;
    }

    const foundUnitIndex = formattedUnits.findIndex(
      item => item.unitId === unit.id,
    );

    if (foundUnitIndex === -1) {
      formattedUnits.push({
        unitId: unit.id,
      });
    }
  });

  return formattedUnits;
};

export const getRecurrentEventInvitees = (
  allRosters = [],
  selectedUsersIds = [],
  formattedRecurrentUnits = [],
) => {
  const clonedRosters = cloneDeep(allRosters);
  const formattedUsers = [];
  selectedUsersIds.forEach(userId => {
    const foundUser = clonedRosters.find(
      rosterUser => rosterUser.userId === userId,
    );
    if (foundUser) {
      formattedUsers.push(foundUser);
    }
  });

  const recurrentUnitsUsers = cloneDeep(formattedRecurrentUnits);

  formattedUsers.forEach(user => {
    // Sort to put subUnits firsts, untis last
    const userUnits = user.units.sort(a => (a.denId || a.patrolId ? -1 : 1));

    let foundUnitIndex = false;

    userUnits.forEach(userUnit => {
      if (foundUnitIndex) {
        return;
      }

      recurrentUnitsUsers.forEach((recurrentUnit, index) => {
        if (foundUnitIndex) {
          return;
        }

        // Check if matches a unit
        if (userUnit.id === recurrentUnit.unitId) {
          foundUnitIndex = true;

          const unit = recurrentUnitsUsers[index];
          recurrentUnitsUsers[index].userIds =
            unit.userIds && unit.userIds.length
              ? [...unit.userIds, user.userId]
              : [user.userId];

          return;
        }

        // Avoid error if no subUnits
        // Parents don't belong to subunits
        if (!recurrentUnit.subUnits || user.isParent) {
          return;
        }

        // Check if matches a subUnit
        const foundIndex = recurrentUnit.subUnits.findIndex(
          sub => sub.subUnitId === userUnit.id,
        );
        if (foundIndex > -1) {
          foundUnitIndex = true;

          const subUnit = recurrentUnitsUsers[index].subUnits[foundIndex];
          recurrentUnitsUsers[index].subUnits[foundIndex].userIds =
            subUnit.userIds && subUnit.userIds.length
              ? [...subUnit.userIds, user.userId]
              : [user.userId];
        }
      });
    });
  });

  // Remove units that don't have users in them
  const depuredOfEmptyUnits = [];
  recurrentUnitsUsers.forEach((unit, index) => {
    if (unit.subUnits && unit.subUnits.length) {
      const validSubUnits = unit.subUnits.filter(
        sub => sub.userIds && sub.userIds.length,
      );
      recurrentUnitsUsers[index].subUnits = validSubUnits.length
        ? validSubUnits
        : undefined;

      if (
        recurrentUnitsUsers[index].subUnits ||
        recurrentUnitsUsers[index].userIds
      ) {
        depuredOfEmptyUnits.push(recurrentUnitsUsers[index]);
      }
    } else if (unit.userIds && unit.userIds.length) {
      depuredOfEmptyUnits.push(recurrentUnitsUsers[index]);
    }
  });

  return depuredOfEmptyUnits;
};

/**
 * @returns {import('./duck/types').CalendarSubUnit}
 */
export const formatEventUnit = unit => ({
  id: +unit.unitId,
  name: `${unit.organizationName}`,
  unitGender: unit.acceptGender || '',
  isDen: false,
  organizationGuid: unit.organizationGuid,
  groupName: unit.organizationName,
  isSubUnit: false,
  program: unit.program,
  // this prop gets computed based on sub units list
  showDlEvents: false,
});

const convertDate = date => {
  const convertedDate = moment.utc(date).second(0).toISOString();
  return convertedDate.split('.')[0].replaceAll('-', '').replaceAll(':', '');
};

export const createIcsFile = event => {
  let icsFile = null;

  const eventFile = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//advancements.scouting.org///NONSGML v1.0//EN\nCALSCALE:GREGORIAN\nX-WR-CALNAME:${
    event.name
  }\n\nBEGIN:VEVENT\nUID:${event.id}\nDTSTAMP:${convertDate(
    event.dateCreated,
  )}Z\nORGANIZER:${event.firstName} ${event.lastName}\nDTSTART:${convertDate(
    event.startDate,
  )}Z\nDTEND:${convertDate(event.endDate)}Z\nSUMMARY:${event.name}\nLOCATION:${
    event.location
  }\nEND:VEVENT\nEND:VCALENDAR`;

  const data = new File([eventFile], { type: 'text/plain' });

  // If we are replacing a previously generated file we need to
  // manually revoke the object URL to avoid memory leaks.
  if (icsFile !== null) {
    window.URL.revokeObjectURL(icsFile);
  }

  icsFile = window.URL.createObjectURL(data);

  return icsFile;
};

export const getPreviewEventValues = eventDetails => {
  const name = `${eventDetails.name}`;
  const startDate = `${moment(eventDetails.startDate).format('MMM DD, Y')}`;
  const endDate = `${moment(eventDetails.endDate).format('MMM DD, Y')}`;
  const startTime = `${moment(eventDetails.startDate).format('hh:mm A')}`;
  const endTime = `${moment(eventDetails.endDate).format('hh:mm A')}`;
  const location = `${eventDetails.location || ''}`;
  const mapUrl = `${eventDetails.mapUrl || ''}`;
  const description = `${eventDetails.description || ''}`;

  return {
    name,
    startDate,
    endDate,
    startTime,
    endTime,
    location,
    mapUrl,
    description,
  };
};

export const getYouthWithAddress = (eventInvitees, users) => {
  // get all youth of the event
  const filteredRoster = users
    ? users.filter(person => person.isAdult === false)
    : [];
  const filteredYouths = eventInvitees.filter(
    person => person.isAdult === false,
  );

  const allYouths = filteredYouths.map(youth => {
    const otherDetails = filteredRoster.find(
      details => details.userId === youth.userId,
    );

    return otherDetails
      ? {
          ...youth,
          address: otherDetails.address1,
          state: otherDetails.state,
          city: otherDetails.city,
          zip: otherDetails.zip,
        }
      : {
          ...youth,
        };
  });

  return allYouths;
};

export const getMyChildWithAddress = (eventInvitees, myChildren) => {
  const filteredYouths = eventInvitees.filter(
    person => person.isAdult === false,
  );

  const allYouths = filteredYouths.map(youth => {
    const otherDetails = myChildren.find(
      details => details.userId === youth.userId,
    );

    if (otherDetails) {
      const [addresses] = otherDetails.addresses;
      return {
        ...youth,
        address: addresses.address1,
        state: addresses.state,
        city: addresses.city,
        zip: addresses.zipCode,
      };
    } else {
      return { ...youth };
    }
  });

  return allYouths;
};

export const permissionSlipDownload = async (
  name,
  startDate,
  endDate,
  allYouths,
) => {
  const finalPdf = await PDFDocument.create();

  const existingPdfBytes = await fetch(templatePdfUrl).then(response =>
    response.arrayBuffer(),
  );
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const sortedYouth = orderBy(
    allYouths,
    ['lastName', 'firstName'],
    ['desc', 'desc'],
  );

  for (const youth of sortedYouth) {
    // copy of the original template
    const templateCopy = await pdfDoc.save();
    const pdfCopy = await PDFDocument.load(templateCopy);
    const form = pdfCopy.getForm(0);

    // prepare fields
    const firstNameField = form.getTextField('first name');
    const middleInitialField = form.getTextField('middle initial');
    const lastNameField = form.getTextField('last name');
    const birthDateMonthField = form.getTextField('birth date month');
    const birthDateDayField = form.getTextField('birth date day');
    const birthDateYearField = form.getTextField('birth date year');
    const ageField = form.getTextField('age');
    const addressField = form.getTextField('address');
    const cityField = form.getTextField('city');
    const stateField = form.getTextField('state');
    const zipField = form.getTextField('zip');
    const activityField = form.getTextField('activity');
    const startDateField = form.getTextField('start date');
    const endDateField = form.getTextField('end date');

    // populate fields and update field settings
    firstNameField.setText(youth.firstName || '');
    firstNameField.setAlignment(TextAlignment.Center);
    middleInitialField.setText(youth.middleName.charAt(0) || '');
    middleInitialField.setAlignment(TextAlignment.Center);
    lastNameField.setText(youth.lastName || '');
    lastNameField.setAlignment(TextAlignment.Center);
    birthDateMonthField.setText(
      (moment(youth.dateOfBirth).month() + 1).toString(),
    );
    birthDateMonthField.setAlignment(TextAlignment.Center);
    birthDateDayField.setText(moment(youth.dateOfBirth).date().toString());
    birthDateDayField.setAlignment(TextAlignment.Center);
    birthDateYearField.setText(moment(youth.dateOfBirth).year().toString());
    birthDateYearField.setAlignment(TextAlignment.Center);
    ageField.setText(moment().diff(youth.dateOfBirth, 'years').toString());
    ageField.setAlignment(TextAlignment.Center);
    addressField.setText(youth.address || '');
    addressField.setAlignment(TextAlignment.Center);
    cityField.setText(youth.city || '');
    cityField.setAlignment(TextAlignment.Center);
    stateField.setText(youth.state || '');
    stateField.setAlignment(TextAlignment.Center);
    zipField.setText(youth.zip || '');
    zipField.setAlignment(TextAlignment.Center);
    activityField.setText(name || '');
    activityField.setFontSize(8);
    activityField.setAlignment(TextAlignment.Center);
    startDateField.setText(startDate.format('L').toLocaleString());
    startDateField.setFontSize(8);
    endDateField.setText(endDate.format('L').toLocaleString());
    endDateField.setFontSize(8);

    // make fields read only
    form.getFields().forEach(f => f.enableReadOnly());
    form.flatten();
    const saved = await pdfCopy.saveAsBase64();
    const newLoad = await PDFDocument.load(saved);

    // insert the populated pdf to the final pdf
    const copied = await finalPdf.copyPages(newLoad, [0]);
    finalPdf.insertPage(0, copied[0]);
  }

  // save the final PDF
  const newPdfBytes = await finalPdf.save();

  // Download PDF
  const modifiedPdfBlob = new Blob([newPdfBytes], {
    type: 'application/pdf',
  });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(modifiedPdfBlob);
  link.download = 'permission_slips.pdf';
  link.click();

  // Clear
  URL.revokeObjectURL(link.href);
};

export const hasMultipleUnits = (units = []) => {
  const unitsList = [];
  const subUnitsList = [];

  units.forEach(u => {
    if (u.denId || u.patrolId) {
      subUnitsList.push(u);
      return;
    }
    unitsList.push(u);
  });

  if (unitsList.length > 1) {
    return true;
  }

  if (subUnitsList.length) {
    const hasSubsFromAnotherUnit = subUnitsList.some(
      sub => sub.unitId !== subUnitsList[0].unitId,
    );
    return hasSubsFromAnotherUnit;
  }

  return false;
};

export const getUnitFilteredMembers = (unitRoster, unitId) =>
  unitRoster.filter(user =>
    user.units.some(unit => {
      const idDenPatrol = unit.denId || unit.patrolId;
      return idDenPatrol === unitId;
    }),
  );

export const formatRecurrentEvent = eventData => {
  const { recurrenceId, nthDay, daysOfTheWeek, monthOfTheYear } = eventData;

  if (
    recurrenceId === recurrenceTypesIds.MONTHLY_NTH_DAY ||
    recurrenceId === recurrenceTypesIds.YEARLY_NTH_DAY
  ) {
    return {
      ...eventData,
      daysOfTheWeek: undefined,
      monthOfTheYear: undefined,
      recurrenceIdOptions: {
        nthDay,
        weekday: daysOfTheWeek[0],
        month: monthOfTheYear,
      },
    };
  }

  return eventData;
};

export const getAttendeeBasedOnEventType = (
  eventType,
  filteredUsers,
  unitTypeId,
) => {
  const youths = filteredUsers.filter(person => person.isAdult === false);
  const parents = filteredUsers.filter(
    person => person.isParent === true && !person.isLeader,
  );
  const leaders = filteredUsers.filter(person => person.isLeader === true);

  if (unitTypeId == ProgramId.CUB_SCOUT) {
    switch (eventType) {
      case eventTypes.CAMPOUT:
      case eventTypes.OPEN_HOUSE:
      case eventTypes.PACK_MEETING:
      case eventTypes.RECRUITING:
      case eventTypes.DAY_RESIDENT_FAMILY_CAMP:
      case eventTypes.DEN_MEETING:
      case eventTypes.PATROL_MEETING:
        return [...youths, ...parents, ...leaders];
      case eventTypes.COMMITTEE_MEETING:
      case eventTypes.TRAINING:
        return [...parents, ...leaders];
      default:
        return [];
    }
  }
  if (unitTypeId == ProgramId.BOY_SCOUT) {
    switch (eventType) {
      case eventTypes.COURT_OF_HONOR:
      case eventTypes.OPEN_HOUSE:
      case eventTypes.RECRUITING:
      case eventTypes.TROOP_MEETING:
        return [...youths, ...parents, ...leaders];
      case eventTypes.CAMPOUT:
      case eventTypes.DEN_MEETING:
      case eventTypes.PATROL_MEETING:
        return [...youths, ...leaders];
      case eventTypes.COMMITTEE_MEETING:
      case eventTypes.TRAINING:
        return [...parents, ...leaders];
      default:
        return [];
    }
  }
  if (unitTypeId == ProgramId.VENTURING) {
    switch (eventType) {
      case eventTypes.CAMPOUT:
      case eventTypes.CREW_MEETING:
      case eventTypes.OPEN_HOUSE:
        return [...youths, ...parents, ...leaders];
      case eventTypes.COMMITTEE_MEETING:
      case eventTypes.TRAINING:
        return [...parents, ...leaders];
      default:
        return [];
    }
  }
  if (unitTypeId == ProgramId.SEA_SCOUT) {
    switch (eventType) {
      case eventTypes.CAMPOUT:
      case eventTypes.SHIP_MEETING:
      case eventTypes.RECRUITING:
        return [...youths, ...parents, ...leaders];
      case eventTypes.COMMITTEE_MEETING:
      case eventTypes.TRAINING:
        return [...parents, ...leaders];
      default:
        return [];
    }
  }
  return [];
};

export const getAdjustedRecurrentDate = (startDate, endDate, recurrenceId) => {
  if (recurrenceId === recurrenceTypesIds.MONTHLY_NTH_DAY) {
    return {
      adjustedStartDate: startDate.startOf('month'),
      adjustedEndDate: endDate.endOf('month'),
    };
  }

  if (recurrenceId === recurrenceTypesIds.YEARLY_NTH_DAY) {
    const yearsBeetween = moment(endDate).diff(startDate, 'years');

    const adjustedStartDate = moment(startDate).startOf('month');
    const adjustedEndDate = moment(startDate)
      .add(yearsBeetween + 1, 'years')
      .subtract(1, 'day');

    return { adjustedStartDate, adjustedEndDate };
  }

  return { adjustedStartDate: startDate, adjustedEndDate: endDate };
};

export const hasEventEditPerms = (
  eventUnits = [],
  userUnitsSubUnitsWithPerms = [],
) =>
  eventUnits.every(({ unitId, denId, patrolId }) => {
    const id = denId || patrolId || unitId;
    return userUnitsSubUnitsWithPerms.find(permUnit => permUnit.id === id);
  });

export const unitIsInEvent = (eventUnits = [], selectedUnitId = []) =>
  eventUnits.some(({ unitId, denId, patrolId }) => {
    const id = denId || patrolId || unitId;
    return id === Number(selectedUnitId);
  });

export const selectedUnitHasEventPerms = (
  eventUnits = [],
  userUnitsSubUnitsWithPerms = [],
  selectedUnitId = [],
) =>
  eventUnits.some(({ unitId, denId, patrolId }) => {
    const id = denId || patrolId || unitId;
    if (id === Number(selectedUnitId))
      return userUnitsSubUnitsWithPerms.find(permUnit => permUnit.id === id);
  });

export const isOneHourBeforeOnwards = (startDate, tzCode) => {
  const timezoneCode = tzCode || 'America/Chicago';
  const currentDate = moment().add(1, 'hours');
  const convertedCurrent = momentWithOffset(currentDate, timezoneCode);

  const isOneHour = convertedCurrent.isSameOrAfter(startDate);

  return isOneHour;
};

export const sortArrayOfListbyLnFnNick = (type, arrayOfList) =>
  arrayOfList.map(list =>
    list.sort((a, b) => {
      let sortByA = sortTags.LAST_NAME;
      let sortByB = sortTags.LAST_NAME;

      if (type === sortTags.FIRST_NAME) {
        sortByA = a.nickName ? sortTags.NICKNAME : sortTags.FIRST_NAME;
        sortByB = b.nickName ? sortTags.NICKNAME : sortTags.FIRST_NAME;
      }

      return a[sortByA].localeCompare(b[sortByB]);
    }),
  );

export const addIsDenChief = (person, filteredRostersSel) => {
  if (filteredRostersSel) {
    const withDetails = filteredRostersSel.find(
      details => details.userId === person.userId,
    );
    return {
      ...person,
      isDenChief: withDetails ? getIsDenChief(withDetails) : false,
    };
  }
  return person;
};

export const transformGetEventCommentsResponse = (
  comments = [],
  loggedInUserId,
) =>
  comments.map(
    ({
      body: message,
      commenterUserId,
      dateCreated: date,
      firstName,
      id,
      lastName,
      middleName,
      photos,
      pictureURL,
    }) => ({
      author: [firstName, middleName, lastName]
        .filter(name => !isEmpty(name))
        .join(' '),
      authorImage: pictureURL,
      canEdit: loggedInUserId === commenterUserId,
      date,
      id,
      message,
      photos,
      userId: commenterUserId,
    }),
  );

export const containsPendingComments = comments =>
  some(
    flatten(
      map(comments, ({ photos }) => map(photos, ({ pending }) => pending)),
    ),
  );

export const boolValue = value => value === 'true' || value === true;

export const getTimeOptions = () => {
  const beginning = moment('12:00 am', timeFormat);
  const ending = moment('11:45 pm', timeFormat);
  let current = beginning;
  const options = [];

  while (current.isSameOrBefore(ending)) {
    options.push(current.format(timeFormat));
    current = current.add(15, 'minute');
  }
  return options;
};

export const getTimeAdjusted = time => {
  const inputTime = moment(time, timeFormat);
  let current = moment(inputTime).startOf('hour');

  while (current.isSameOrBefore(inputTime)) {
    current = current.add(15, 'minute');
  }
  return current.format(timeFormat);
};

export const getStartEndInitialTime = () => {
  const start = moment();
  const end = moment().add(1, 'hour');

  return {
    startTime: getTimeAdjusted(start.format(timeFormat)),
    endTime: getTimeAdjusted(end.format(timeFormat)),
  };
};

const formatEventTime = time => {
  const validTime = moment.isMoment(time) ? time : moment(time);
  return validTime.format('HH:mm');
};

export const isEventAllDay = (startTime, endTime) => {
  const validStartTime = formatEventTime(startTime);
  const validEndTime = formatEventTime(endTime);

  if (validStartTime && validEndTime) {
    const result = validStartTime === '00:00' && validEndTime === '23:45';

    return result;
  }

  return false;
};

export const getDateAllDayText = (date, timezoneCode) => {
  const allDayStart =
    momentWithOffset(date, timezoneCode).format(dobDateFormatShort) +
    ' @ ' +
    intl.formatMessage({ id: 'shared.allDay' });

  return allDayStart;
};
