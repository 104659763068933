import { createApi } from '@reduxjs/toolkit/query/react';
import type { BaseQueryApi } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

import {
  UnapproveAwardPayload,
  UpdateAwardsPayload,
} from '@appTypes/esb/advancements/youth/awards';
import { API_ENV, ESB_API_URLS } from '@config';
import type { ConfigState } from '@config/types';
import { transformOrgProfile } from '@unit/duck/utils';

import { BSAEnv } from '../../types/enums';
import type {
  AdvancementHistoryItem,
  AdvancementHistoryRequest,
  GetAdventureRequirementsReq,
  GetAdventureRequirementsRes,
  GetAdventuresReq,
  GetAdventuresRes,
  GetMeritBadgeRequirementsReq,
  GetMeritBadgeRequirementsRes,
  GetRankRequirementsReq,
  GetRankRequirementsRes,
  GetRanksReq,
  GetRanksRes,
  GetRenewalRelationshipsRequest,
  GetRenewalRelationshipsResponse,
  GetUserAdventureRequirementsReq,
  GetUserAdventureRequirementsRes,
  GetUserMeritBadgeRequirementsReq,
  GetUserMeritBadgeRequirementsRes,
  GetUserRankVersionRequirementsRes,
  GetYouthMeritBadgeDetailsRes,
  OrganizationAdvDashboard,
  PostBulkMeritBadgeRequirementsReq,
  PostBulkMeritBadgeRequirementsRes,
  PostBulkYouthRankRequirementsReq,
  PostBulkYouthRankRequirementsRes,
  PostYouthAdventuresRequirementsRequest,
  PostYouthAdventuresRequirementsResponse,
  PostYouthMeritBadgesReq,
  PostYouthMeritBadgesRes,
} from '../../types/esb';
import { tagTypes } from './constants';
import { dynamicBaseQuery } from './utils';

function getESBBaseUrl(_args: unknown, api: BaseQueryApi): string {
  const state = api.getState() as Record<string, unknown>;

  const stateApiEnv = (state?.config as ConfigState)?.apiEnv as string;
  const finalApiEnv = (stateApiEnv ? stateApiEnv : API_ENV) as BSAEnv;

  const baseUrl = ESB_API_URLS[finalApiEnv];
  return baseUrl;
}

export const esbApi = createApi({
  reducerPath: 'esbApi',
  tagTypes: Object.keys(tagTypes),
  baseQuery: dynamicBaseQuery(getESBBaseUrl),
  endpoints: builder => ({
    /**
     * @esbEndpoint GET /organizations/v2/:orgGuid/profile
     */
    getOrganizationProfile: builder.query({
      query: ({ orgGuid }) => ({
        url: `/organizations/v2/${orgGuid}/profile`,
        method: 'GET',
      }),
      transformResponse: result => transformOrgProfile(result),
      providesTags: (result, _error, { orgGuid }) =>
        result ? [{ type: tagTypes.OrganizationProfile, id: orgGuid }] : [],
    }),

    getOrgAdvancementsDashboard: builder.query<
      OrganizationAdvDashboard,
      {
        organizationGuid: string;
      }
    >({
      query: arg => ({
        url: `/organizations/v2/${arg.organizationGuid}/advancementDashboard`,
        method: 'GET',
      }),
      providesTags: (_result, error, arg) =>
        error
          ? []
          : [
              {
                type: tagTypes.OrganizationAdvDashboard,
                id: arg.organizationGuid,
              },
            ],
    }),

    /**
     * @esbEndpoint GET /advancements/v2/organization/{organizationGuid}/ranks/{rankId}/userRequirements
     */
    getBulkUserRankRequirements: builder.query<
      GetUserRankVersionRequirementsRes,
      {
        orgGuid: string;
        rankId: number;
        memberIds: number[];
      }
    >({
      query: arg => {
        const { orgGuid, rankId, memberIds } = arg;
        return {
          url: `/advancements/v2/organization/${orgGuid}/ranks/${rankId}/userRequirements`,
          method: 'GET',
          params: {
            memberId: memberIds,
          },
        };
      },
      providesTags: (result, error) =>
        result && !error
          ? result.map(rankInfo => ({
              type: tagTypes.YouthRankVersionDetails,
              id: `${rankInfo.userId}-${rankInfo.rankId}`,
            }))
          : [],
    }),

    /**
     * @esbEndpoint GET /advancements/v2/organization/{organizationGuid}/adventures/{adventureId}/userRequirements
     */
    getBulkUserAdventureRequirements: builder.query<
      GetUserAdventureRequirementsRes,
      {
        orgGuid: string;
        adventureId: number;
        params: GetUserAdventureRequirementsReq;
      }
    >({
      query: arg => ({
        url: `/advancements/v2/organization/${arg.orgGuid}/adventures/${arg.adventureId}/userRequirements`,
        method: 'GET',
        params: {
          memberId: arg.params.memberId,
        },
      }),
      providesTags: (result, error) =>
        result && !error
          ? result.map(adventureInfo => ({
              type: tagTypes.AdventureUserRequirements,
              id: `${adventureInfo.userId}`,
            }))
          : [],
    }),

    /**
     * @esbEndpoint GET /advancements/v2/organization/{organizationGuid}/meritBadges/{meritBadgeId}/userRequirements
     */
    getBulkUserMeritBadgeRequirements: builder.query<
      GetUserMeritBadgeRequirementsRes,
      {
        orgGuid: string;
        meritBadgeId: number;
        params: GetUserMeritBadgeRequirementsReq;
      }
    >({
      query: arg => ({
        url: `/advancements/v2/organization/${arg.orgGuid}/meritBadges/${arg.meritBadgeId}/userRequirements`,
        method: 'GET',
        params: arg.params,
      }),
      providesTags: (result, error) =>
        result && !error
          ? result.map(meritBadgeInfo => ({
              type: tagTypes.MeritBadgeUserRequirements,
              id: `${meritBadgeInfo.userId}`,
            }))
          : [],
    }),

    /**
     * @esbEndpoint GET /advancements/v2/youth/:userId/meritBadges/:meritBadgeId
     */
    getYouthMeritBadgeDetails: builder.query<
      GetYouthMeritBadgeDetailsRes,
      {
        userId: number;
        meritBadgeId: number;
      }
    >({
      query: arg => ({
        url: `/advancements/v2/youth/${arg.userId}/meritBadges/${arg.meritBadgeId}`,
        method: 'GET',
      }),
      providesTags: (_result, error, arg) =>
        error
          ? []
          : [
              {
                type: tagTypes.YouthMeritBadge,
                id: `${arg.userId}-${arg.meritBadgeId}`,
              },
            ],
    }),

    /**
     * === Advancements V1
     */
    /**
     * @esbEndpoint GET /advancements/adventures/{adventureId}/requirements
     */
    getAdventureRequirements: builder.query<
      GetAdventureRequirementsRes,
      { adventureId: number; params: GetAdventureRequirementsReq }
    >({
      query: arg => ({
        url: `/advancements/adventures/${arg.adventureId}/requirements`,
        method: 'GET',
        params: arg.params,
      }),
      providesTags: (result, _error, { adventureId }) =>
        result?.requirements
          ? [
              {
                type: tagTypes.AdventureRequirements,
                id: adventureId,
              },
            ]
          : [],
    }),
    /**
     * @esbEndpoint GET /advancements/meritBadges/{meritBadgeId}/requirements
     */
    getMeritBadgeRequirements: builder.query<
      GetMeritBadgeRequirementsRes,
      { meritBadgeId: number; params: GetMeritBadgeRequirementsReq }
    >({
      query: arg => ({
        url: `/advancements/meritBadges/${arg.meritBadgeId}/requirements`,
        method: 'GET',
        params: arg.params,
      }),
    }),

    /**
     * @esbEndpoint POST /advancements/youth/meritBadges
     */
    postYouthMeritBadges: builder.mutation<
      PostYouthMeritBadgesRes,
      PostYouthMeritBadgesReq
    >({
      query: body => ({
        url: `/advancements/youth/meritBadges`,
        method: 'POST',
        body,
      }),
    }),

    /**
     * @esbEndpoint POST /advancements/v2/youth/adventures/{adventureId}/requirements
     */
    postYouthAdventuresRequirements: builder.mutation<
      PostYouthAdventuresRequirementsResponse,
      {
        adventureId: number;
        payload: PostYouthAdventuresRequirementsRequest;
      }
    >({
      query: arg => ({
        url: `/advancements/v2/youth/adventures/${arg.adventureId}/requirements`,
        method: 'POST',
        body: arg.payload,
      }),
    }),

    /**
     * === Advancements V2
     */

    /**
     * @esbEndpoint GET /persons/v2/:userId/personprofile
     */
    getPersonProfile: builder.query({
      query: ({ userId }) => ({
        url: `/persons/v2/${userId}/personprofile`,
        method: 'GET',
      }),
      providesTags: (result, _error, { userId }) =>
        result ? [{ type: tagTypes.PersonProfile, id: userId }] : [],
    }),

    /**
     * @esbEndpoint POST /advancements/v2/youth/:userId/ranks/:rankId
     */
    getYouthRankDetails: builder.query({
      query: ({ rankId, userId }) => ({
        url: `/advancements/v2/youth/${userId}/ranks/${rankId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { userId, rankId }) =>
        result
          ? [{ type: tagTypes.YouthRankDetails, id: `${userId}-${rankId}` }]
          : [],
    }),

    /**
     * @esbEndpoint POST /advancements/v2/youth/meritBadges/{meritBadgeId}/requirements
     */
    postBulkYouthMeritBadgeRequirements: builder.mutation<
      PostBulkMeritBadgeRequirementsRes,
      { meritBadgeId: number; payload: PostBulkMeritBadgeRequirementsReq }
    >({
      query: ({ meritBadgeId, payload }) => ({
        url: `/advancements/v2/youth/meritBadges/${meritBadgeId}/requirements`,
        method: 'POST',
        body: payload,
      }),
    }),

    /**
     * @esbEndpoint POST /advancements/v2/youth/ranks/:rankId/requirements
     */
    postBulkYouthRankRequirements: builder.mutation<
      PostBulkYouthRankRequirementsRes,
      { rankId: number; payload: PostBulkYouthRankRequirementsReq }
    >({
      queryFn: async (arg, _api, _opts, baseQuery) => {
        const promises = _.chain(arg.payload)
          .groupBy(item => item.userId)
          .map(youthPayload => {
            const payload: PostBulkYouthRankRequirementsReq = youthPayload;
            return baseQuery({
              url: `/advancements/v2/youth/ranks/${arg.rankId}/requirements`,
              method: 'POST',
              body: payload,
            });
          })
          .value();

        try {
          const results = await Promise.all(promises);
          const data = _.flatMap(
            results.map(res => {
              if ('error' in res) {
                throw res.error;
              } else {
                const result = res.data as PostBulkYouthRankRequirementsRes;
                return result;
              }
            }),
          );

          const errors = data.filter(item =>
            item.requirements.some(item => item.status === 'Fail'),
          );

          if (errors.length > 0) {
            throw { data: errors, status: 400 };
          }

          return {
            data,
          };
        } catch (err) {
          return { error: err };
        }
      },
    }),
    /**
     * @esbEndpoint POST /advancements/v2/youth/ranks/:rankId/requirements
     */
    postYouthRankRequirements: builder.mutation<
      PostBulkYouthRankRequirementsRes,
      { rankId: number; payload: PostBulkYouthRankRequirementsReq }
    >({
      query: ({ rankId, payload }) => ({
        url: `/advancements/v2/youth/ranks/${rankId}/requirements`,
        method: 'POST',
        body: payload,
      }),
    }),

    /**
     * @esbEndpoint GET /advancements/v2/youth/:userId/ranks/:rankId/requirements
     */
    getYouthRankRequirements: builder.query({
      query: ({ rankId, userId }) => ({
        url: `/advancements/v2/youth/${userId}/ranks/${rankId}/requirements`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { userId, rankId }) => [
        { type: tagTypes.YouthRankRequirements, id: `${userId}-${rankId}` },
      ],
    }),

    // PUBLIC ENDPOINTS (don't require authentication)
    /**
     * @esbEndpoint GET /advancements/v2/ranks
     */
    getRanks: builder.query<
      GetRanksRes,
      | {
          params: GetRanksReq;
        }
      | undefined
    >({
      query: () => ({
        url: `/advancements/v2/ranks`,
        method: 'GET',
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result, _error, _arg) =>
        result
          ? [
              ...result.map(rank => ({
                type: tagTypes.Rank,
                id: rank.id,
              })),
              { type: tagTypes.Rank, id: 'Ranks-List' },
            ]
          : [],
    }),

    /**
     * @esbEndpoint POST /advancements/advancementHistory
     */
    getAdvancementHistory: builder.query<
      AdvancementHistoryItem[],
      AdvancementHistoryRequest
    >({
      query: arg => ({
        url: '/advancements/advancementHistory',
        method: 'POST',
        body: arg.body,
        params: arg.params,
      }),
      providesTags: (result, error, arg) => {
        if (error || !result) return [];

        return [
          {
            type: tagTypes.AdvancementHistory,
            id: `Unit-List-${arg.body.organizationGuid}`,
          },
        ];
      },
    }),

    /**
     * @esbEndpoint GET /advancements/v2/adventures
     */
    getAdventures: builder.query<
      GetAdventuresRes,
      { params: GetAdventuresReq } | undefined
    >({
      query: () => ({
        url: `/advancements/v2/adventures`,
        method: 'GET',
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      providesTags: (result, _error, _arg) =>
        result?.adventures
          ? [
              ...result.adventures.map(adventure => ({
                type: tagTypes.Adventure,
                id: adventure.id,
              })),
              { type: tagTypes.Adventure, id: 'Adventures-List' },
            ]
          : [],
    }),

    /**
     * @esbEndpoint GET /advancements/v2/ranks/:rankId/requirements?versionId=:versionId
     */
    getRankDetails: builder.query({
      query: ({ rankId }) => ({
        url: `/advancements/v2/ranks/${rankId}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { rankId }) =>
        result ? [{ type: tagTypes.RankRequirements, id: rankId }] : [],
    }),

    /**
     * @esbEndpoint GET /advancements/v2/ranks/:rankId/requirements?versionId=:versionId
     */
    getRankRequirements: builder.query<
      GetRankRequirementsRes,
      {
        rankId: number;
        params: GetRankRequirementsReq;
      }
    >({
      query: ({ rankId, params }) => ({
        url: `/advancements/v2/ranks/${rankId}/requirements`,
        params,
        method: 'GET',
        validateStatus: (
          response: { status: number },
          result: Record<string, unknown>,
        ) => response.status === 200 && !result.errorCode,
      }),
      providesTags: (result, error, arg) => {
        const versionId = arg.params?.versionId;
        const rankId = arg.rankId;

        return result && !error
          ? [{ type: tagTypes.RankRequirements, id: `${rankId}-${versionId}` }]
          : [];
      },
    }),

    /**
     * @esbEndpoint GET /persons/{personGuid}/renewalRelationships
     */
    getPersonRenewalRelationships: builder.query<
      GetRenewalRelationshipsResponse,
      {
        params?: GetRenewalRelationshipsRequest;
        personGuid: string;
      }
    >({
      query: ({ params, personGuid }) => ({
        url: `/persons/${personGuid}/renewalRelationships`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) =>
        result && !error
          ? [
              {
                type: tagTypes.PersonRenewalRelationships,
                id: arg.personGuid,
              },
            ]
          : [],
    }),

    /**
     * @esbEndpoint PUT /advancements/youth/{userId}/awards/{awardId}/{userAwardId}
     */
    updateYouthAward: builder.mutation<
      void,
      {
        userId: number;
        awardId: number;
        userAwardId: number;
        body: UpdateAwardsPayload;
      }
    >({
      query: arg => ({
        url: `/advancements/youth/${arg.userId}/awards/${arg.awardId}/${arg.userAwardId}`,
        method: 'PUT',
        body: arg.body,
      }),
    }),

    /**
     * @esbEndpoint PUT /advancements/unapprove
     */
    unapproveYouthAward: builder.mutation<
      void,
      {
        body: UnapproveAwardPayload;
      }
    >({
      query: arg => ({
        url: `/advancements/unapprove`,
        method: 'POST',
        body: arg.body,
      }),
    }),
  }),
});

export const endpoints = esbApi.endpoints;

export const {
  // Org Dashboard
  useGetOrgAdvancementsDashboardQuery,
  useLazyGetOrgAdvancementsDashboardQuery,
  // Advancements
  useGetRankRequirementsQuery,
  useGetRankDetailsQuery,
  useGetYouthRankDetailsQuery,
  useGetYouthRankRequirementsQuery,
  useGetOrganizationProfileQuery,
  useGetBulkUserRankRequirementsQuery,
  // Merit Badges
  useGetMeritBadgeRequirementsQuery,
  useGetBulkUserMeritBadgeRequirementsQuery,
  useLazyGetBulkUserMeritBadgeRequirementsQuery,
  useGetYouthMeritBadgeDetailsQuery,
  // Collections
  useGetRanksQuery,
  useLazyGetRanksQuery,
  useGetAdventuresQuery,
  useLazyGetAdventuresQuery,
  // AdvancementHistory
  useGetAdvancementHistoryQuery,
  // Adventures
  useGetAdventureRequirementsQuery,
  useLazyGetAdventureRequirementsQuery,
  useGetBulkUserAdventureRequirementsQuery,
  useLazyGetBulkUserAdventureRequirementsQuery,
  usePostYouthAdventuresRequirementsMutation,
  // Persons
  useGetPersonRenewalRelationshipsQuery,
  useLazyGetPersonRenewalRelationshipsQuery,
  useLazyGetOrganizationProfileQuery,
  useLazyGetPersonProfileQuery,
  // Mutations
  usePostBulkYouthRankRequirementsMutation,
  usePostYouthMeritBadgesMutation,
  usePostBulkYouthMeritBadgeRequirementsMutation,
  usePostYouthRankRequirementsMutation,
  // Awards
  useUpdateYouthAwardMutation,
  useUnapproveYouthAwardMutation,
} = esbApi;
