import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { ReturnButton, SingleConfirmationModal } from '@shared';
import { initialFormStateSel, isFormChanged } from '../../../../common';
import { detailModes } from '../../../constants';
import { setDetailMode } from '../../../duck';

const BackButton = ({ getFieldsValue }) => {
  const dispatch = useDispatch();
  const initialFormState = useSelector(initialFormStateSel);

  const closeModalRef = useRef();

  const onBack = useCallback(
    () => dispatch(setDetailMode(detailModes.SUGGEST)),
    [dispatch],
  );

  const handleBackConfirm = useCallback(() => {
    if (isFormChanged(initialFormState, getFieldsValue())) {
      closeModalRef.current.openConfirmationModal();
    } else {
      onBack();
    }
  }, [initialFormState, getFieldsValue, onBack]);

  return (
    <React.Fragment>
      <SingleConfirmationModal
        ref={closeModalRef}
        message={<FormattedMessage id="progress.common.closeConfirm" />}
        onConfirm={onBack}
      />
      <ReturnButton modal onClick={handleBackConfirm}>
        <FormattedMessage id="LongCruiseForm.BackButton.backTo" />
      </ReturnButton>
    </React.Fragment>
  );
};

BackButton.propTypes = {
  getFieldsValue: PropTypes.func.isRequired,
};

export default BackButton;
