import { Dispatch } from 'react';

import { isNil } from 'lodash';

import { RootState } from '../../../root/store';
import type {
  GetEventRes,
  GetEventsRes,
  GetUserPaymentLogsRes,
  PostUserPaymentLogReq,
  PutUserPaymentLogReq,
} from '../../../types/esb';
import { moduleName } from '../constants';
import { Unit } from '../types';
import { currentUserIdSel } from './selectors';

export const ROUTE_MY_PAYMENT_LOGS = `${moduleName}/ROUTE_MY_PAYMENT_LOGS`;
export const goToMyPaymentLogs = () => ({
  type: ROUTE_MY_PAYMENT_LOGS,
});

export const ROUTE_UNIT_PAYMENT_LOGS = `${moduleName}/ROUTE_UNIT_PAYMENT_LOGS`;
export const goToUnitPaymentLogs = () => ({
  type: ROUTE_UNIT_PAYMENT_LOGS,
});

type GoToYouthLogs = Dispatch<{
  type: string;
  payload: { userId: number | undefined };
}>;
export const ROUTE_YOUTH_PAYMENT_LOGS = `${moduleName}/ROUTE_YOUTH_PAYMENT_LOGS`;
export const goToYouthPaymentLogs =
  (providedUserId?: number) =>
  (dispatch: GoToYouthLogs, getState: () => RootState) => {
    let userId = providedUserId;

    if (isNil(providedUserId)) {
      const state = getState();
      userId = currentUserIdSel(state);
    }

    return dispatch({
      type: ROUTE_YOUTH_PAYMENT_LOGS,
      payload: { userId },
    });
  };

/*
 * GET event
 */

export const GET_EVENT_REQUEST = `${moduleName}/GET_EVENT_REQUEST`;
export const getEventRequest = (eventId: number) => ({
  type: GET_EVENT_REQUEST,
  eventId,
});

export const GET_EVENT_RESPONSE = `${moduleName}/GET_EVENT_RESPONSE`;
export const getEventResponse = (event: GetEventRes) => ({
  type: GET_EVENT_RESPONSE,
  event,
});

export const GET_EVENT_ERROR = `${moduleName}/GET_EVENT_ERROR`;
export const getEventError = (error: Error) => ({
  type: GET_EVENT_ERROR,
  payload: error,
});

/*
 * GET unit events
 */

export const GET_UNIT_EVENTS_REQUEST = `${moduleName}/GET_UNIT_EVENTS_REQUEST`;
export const getUnitEventsRequest = (unitId: number) => ({
  type: GET_UNIT_EVENTS_REQUEST,
  unitId,
});

export const GET_UNIT_EVENTS_RESPONSE = `${moduleName}/GET_UNIT_EVENTS_RESPONSE`;
export const getUnitEventsResponse = (unitEvents: GetEventsRes) => ({
  type: GET_UNIT_EVENTS_RESPONSE,
  unitEvents,
});

export const GET_UNIT_EVENTS_ERROR = `${moduleName}/GET_UNIT_EVENTS_ERROR`;
export const getUnitEventsError = (error: Error) => ({
  type: GET_UNIT_EVENTS_ERROR,
  payload: error,
});

/*
 * GET person units
 */

export const GET_PERSON_UNITS_REQUEST = `${moduleName}/GET_PERSON_UNITS_REQUEST`;
export const getPersonUnitsRequest = (userId: number) => ({
  type: GET_PERSON_UNITS_REQUEST,
  userId,
});

export const GET_PERSON_UNITS_RESPONSE = `${moduleName}/GET_PERSON_UNITS_RESPONSE`;
export const getPersonUnitsResponse = (units: Unit[]) => ({
  type: GET_PERSON_UNITS_RESPONSE,
  units,
});

export const GET_PERSON_UNITS_ERROR = `${moduleName}/GET_PERSON_UNITS_ERROR`;
export const getPersonUnitsError = (error: Error) => ({
  type: GET_PERSON_UNITS_ERROR,
  payload: error,
});

/*
 * GET user payment logs
 */

export const USER_PAYMENT_LOGS_REQUEST = `${moduleName}/USER_PAYMENT_LOGS_REQUEST`;
export const getUserPaymentLogsRequest = () => ({
  type: USER_PAYMENT_LOGS_REQUEST,
});

export const USER_PAYMENT_LOGS_RESPONSE = `${moduleName}/USER_PAYMENT_LOGS_RESPONSE`;
export const getUserPaymentLogsResponse = (
  paymentLogs: GetUserPaymentLogsRes,
) => ({
  type: USER_PAYMENT_LOGS_RESPONSE,
  payload: paymentLogs,
});

export const USER_PAYMENT_LOGS_ERROR = `${moduleName}/USER_PAYMENT_LOGS_ERROR`;
export const getUserPaymentLogsError = (error: Error) => ({
  type: USER_PAYMENT_LOGS_ERROR,
  payload: error,
});

/*
 * POST user payment logs
 */

type PostUserPaymentLogDispatch = Dispatch<{
  type: string;
  userId: number | undefined;
  requestBody: PostUserPaymentLogReq;
}>;

export const POST_USER_PAYMENT_LOGS_REQUEST = `${moduleName}/POST_USER_PAYMENT_LOGS_REQUEST`;
export const postUserPaymentLogsRequest =
  (requestBody: PostUserPaymentLogReq) =>
  (dispatch: PostUserPaymentLogDispatch, getState: () => RootState) => {
    const state = getState();
    const userId = currentUserIdSel(state);

    return dispatch({
      type: POST_USER_PAYMENT_LOGS_REQUEST,
      userId,
      requestBody,
    });
  };

export const POST_USER_PAYMENT_LOGS_RESPONSE = `${moduleName}/POST_USER_PAYMENT_LOGS_RESPONSE`;
export const postUserPaymentLogsResponse = () => ({
  type: POST_USER_PAYMENT_LOGS_RESPONSE,
});

export const POST_USER_PAYMENT_LOGS_ERROR = `${moduleName}/POST_USER_PAYMENT_LOGS_ERROR`;
export const postUserPaymentLogsError = (error: Error) => ({
  type: POST_USER_PAYMENT_LOGS_ERROR,
  payload: error,
});

/*
 * PUT user payment logs
 */

export const PUT_USER_PAYMENT_LOGS_REQUEST = `${moduleName}/PUT_USER_PAYMENT_LOGS_REQUEST`;
export const putUserPaymentLogsRequest = (
  userId: number,
  logId: number,
  requestBody: PutUserPaymentLogReq,
) => ({
  type: PUT_USER_PAYMENT_LOGS_REQUEST,
  userId,
  logId,
  requestBody,
});

export const PUT_USER_PAYMENT_LOGS_RESPONSE = `${moduleName}/PUT_USER_PAYMENT_LOGS_RESPONSE`;
export const putUserPaymentLogsResponse = () => ({
  type: PUT_USER_PAYMENT_LOGS_RESPONSE,
});

export const PUT_USER_PAYMENT_LOGS_ERROR = `${moduleName}/PUT_USER_PAYMENT_LOGS_ERROR`;
export const putUserPaymentLogsError = (error: Error) => ({
  type: PUT_USER_PAYMENT_LOGS_ERROR,
  payload: error,
});
