import 'rxjs/add/operator/share';
import isEqual from 'lodash/isEqual';

/**
 * Memoize-one with a twist:
 * It's only for Observables and calls `share()` on it.
 * The intended use case is for HTTP calls that can be invoked again before first request finishes.
 * In that case only 1 call will be made, instead of making a new one for each subscriber.
 * Once the request is done, new subscribers will trigger a new request (as one would expect).
 *
 * Epics should invoke this via `mergeMap` instead of `switchMap`
 * because the latter will cancel any existing request and start a new one,
 * which defeats the purpose.
 *
 * @see https://www.learnrxjs.io/operators/multicasting/share.html
 *
 * @example
 *  // in some service
 *  const myRequest$ = memoizeShare(url => myService.get$(url))
 *
 *  // and then in epic usage is the same as any other service
 *  // just remember about `mergeMap`
 *  action$
 *   .ofType(MY_ACTION)
 *   .mergeMap(() => myRequest$())
 *   .map(...)
 *
 * @param {function} observableFactory - typically an Observable.ajax call
 * @param {function} [argComparator=lodash.isEqual] - determines param equality
 *
 */
export default (observableFactory, argComparator = isEqual) => {
  let memoizedArgs = [];
  let memoizedObservable;

  return (...args) => {
    if (
      argComparator(memoizedArgs, args) &&
      memoizedObservable &&
      !window.sblOffline
    ) {
      return memoizedObservable;
    }

    memoizedArgs = args;
    memoizedObservable = observableFactory(...args).share();

    return memoizedObservable;
  };
};
