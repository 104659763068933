import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { payloadSel } from '@location';
import { Button, T } from '@shared';

import { routeHome } from '../../../dashboard';
import styles from './ErrorPage.less';
// workaround for url('path/to/image') not working in .css files with webpack
// this is a configuration issue that I couldn't figure out
// PRs welcome
import notAuthorizedImgUrl from './assets/NotAuthorized.png';
import notFoundImgUrl from './assets/NotFound.png';
import uiErrorImgUrl from './assets/UIError.png';

const getBackgroundUrl = code => {
  switch (code) {
    case 403:
      return notAuthorizedImgUrl;
    case 'UI':
      return '';
    default:
      return notFoundImgUrl;
  }
};

const getTitleId = code => {
  switch (code) {
    case 403:
      return 'core.ErrorPage.notAuthorized.title';
    case 'UI':
      return 'core.ErrorPage.ui.title';
    default:
      return 'core.ErrorPage.notFound.title';
  }
};

class ErrorPage extends React.PureComponent {
  handleGoBack = () => {
    window.location.assign(window.location.origin);
  };

  render() {
    const { code } = this.props;
    const isUIError = code === 'UI';

    return (
      <div
        style={{
          backgroundImage: `url(${getBackgroundUrl(code)})`,
        }}
        className={cn(styles.container, {
          [styles.uiErrorBackground]: isUIError,
        })}
      >
        <div className={styles.content}>
          {isUIError && (
            <img src={uiErrorImgUrl} className={styles.errorImage} />
          )}
          <T size="1" serif className={styles.title}>
            <FormattedMessage id={getTitleId(code)} />
          </T>
          {isUIError && (
            <T size="4" className={styles.subtitle}>
              <FormattedMessage id="core.ErrorPage.ui.subtitle" />
            </T>
          )}
          <Button
            ghost
            fitText
            color="white"
            size="large"
            uppercase={false}
            className={styles.button}
            onClick={this.handleGoBack}
          >
            <FormattedMessage id="core.ErrorPage.goBack" />
          </Button>
        </div>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  code: PropTypes.oneOf([403, 404, 'UI']),
  onNavigateHome: PropTypes.func.isRequired,
};

const mapState = state => ({
  code: payloadSel(state).code,
});

const mapDispatch = dispatch => ({
  onNavigateHome: () => dispatch(routeHome()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapState, mapDispatch, mergeProps)(ErrorPage);
