import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { filter, ignoreElements, mapTo, switchMap, tap } from 'rxjs/operators';

import { organizationGuidSel } from '@context';
import { advancementSel } from '@dict';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  START_ADVANCEMENT_REQUEST,
  startAdvancementError,
  startAdvancementResponse,
} from './actions';
import { unstartAdvancementRequest } from './actionsTyped';
import services from './services';

const startAdvancementEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(START_ADVANCEMENT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      const { userId, advancementType, advancementId } = payload;

      return services
        .startAdvancement$({
          organizationGuid,
          userId,
          advancementType,
          advancementId,
        })
        .pipe(
          mapTo(startAdvancementResponse()),
          tap(() => {
            const advancementName = advancementSel({
              state,
              advancementType,
              advancementId,
            }).name;

            toastService.info(
              intl.formatMessage(
                {
                  id: 'startAdvancement.startSuccess',
                },
                { advancementName },
              ),
            );
          }),
          catchAndReport(err => of(startAdvancementError(err))),
        );
    }),
  );

const unstartAdvancementEpic$ = action$ =>
  action$.pipe(
    filter(unstartAdvancementRequest.match),
    tap(() => {
      alert('Implement me!');
    }),
    ignoreElements(),
  );

export default combineEpics(startAdvancementEpic$, unstartAdvancementEpic$);
