import React from 'react';
import PropTypes from 'prop-types';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import { Dropdown as BsaDropdown } from 'bsa-ui';

import { ModalContextContainerConsumer } from '../Modal';

const Dropdown = ({ getPopupContainer = null, ...rest }) => (
  <ModalContextContainerConsumer>
    {getModalPopupContainer => (
      <BsaDropdown
        getPopupContainer={
          getPopupContainer ? getPopupContainer : getModalPopupContainer
        }
        {...rest}
      />
    )}
  </ModalContextContainerConsumer>
);

Dropdown.propTypes = {
  ...BsaDropdown.propTypes,
  getPopupContainer: PropTypes.func,
};

Dropdown.defaultProps = {
  ...BsaDropdown.defaultProps,
};

Dropdown.Button = BsaDropdown.Button;

export default Dropdown;
