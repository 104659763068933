import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isNil, isEmpty } from 'lodash';
import { Tooltip } from 'bsa-ui';
import styles from './CarouselBottomNavigation.less';

const propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  activeIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

function CarouselBottomNavigation({
  items,
  activeIndex,
  onChange,
  className = null,
}) {
  return (
    <div className={cn(styles.carouselNav, className)}>
      {items.map((item, index) => {
        const isActiveIndex = index === activeIndex;
        return (
          <div
            key={item.id}
            role="button"
            onClick={() => {
              if (!isActiveIndex) {
                onChange(index);
              }
            }}
            disabled={isActiveIndex}
          >
            {!isNil(item.tooltip) && !isEmpty(item.tooltip) ? (
              <Tooltip title={item.tooltip} aria-label={item.tooltip}>
                <div
                  className={cn(styles.circle, {
                    [styles.active]: isActiveIndex,
                  })}
                />
              </Tooltip>
            ) : (
              <div
                className={cn(styles.circle, {
                  [styles.active]: isActiveIndex,
                })}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

CarouselBottomNavigation.propTypes = propTypes;

export default CarouselBottomNavigation;
