import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { TableMobile, sorterPropTypes } from '@shared';

import UnitsListHeader from './UnitsListHeader';
import columns from './unitsListRows';

const UnitsList = ({
  items,
  loading,
  sorter,
  hasSearchedItems,
  onSorterChange,
  onSelectItem,
  onRemoveItem,
}) => {
  const handleItemClick = useCallback(
    unit => onSelectItem(unit),
    [onSelectItem],
  );
  return (
    <TableMobile
      key="items"
      items={items}
      loading={loading}
      title={
        <UnitsListHeader
          items={items}
          sorter={sorter}
          onSorterChange={onSorterChange}
        />
      }
      rowId="organizationGuid"
      rows={columns({ hasSearchedItems, onRemoveItem })}
      onItemClick={handleItemClick}
    />
  );
};

UnitsList.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  sorter: sorterPropTypes,
  hasSearchedItems: PropTypes.bool,
  onSorterChange: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
};

export default UnitsList;
