import React from 'react';

import isFunc from 'lodash/isFunction';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isMobileSel } from '@screen';

import { personsShape, validTypesByPerson } from '../../constants';
import { groupYouthsAdults } from '../../utils';
import PersonsListDesktop from './PersonsListDesktop';
import PersonsListMobile from './PersonsListMobile';
import WarningMessage from './WarningMessage';

const getYouthsAdults = memoize(persons => groupYouthsAdults(persons));

class PersonsList extends React.PureComponent {
  state = {
    open: false,
  };

  componentDidMount() {
    if (this.shouldDisplayAnyWarningMessage()) {
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({ open: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { adultsWarningMessage, youthWarningMessage, persons } = this.props;

    const areNewWarningMessages =
      prevProps.adultsWarningMessage !== adultsWarningMessage ||
      prevProps.youthWarningMessage !== youthWarningMessage;

    const wereSuggestedAdded = prevProps.persons.length !== persons.length;

    if (areNewWarningMessages && this.shouldDisplayAnyWarningMessage()) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ open: true });
    }

    if (wereSuggestedAdded) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ open: true });
    }
  }

  shouldDisplayAnyWarningMessage = () => {
    const { persons, adultsWarningMessage, youthWarningMessage } = this.props;
    const { adults, youths } = getYouthsAdults(persons);

    return !!(
      (adults.length && adultsWarningMessage) ||
      (youths.length && youthWarningMessage)
    );
  };

  handleListToggleClick = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  getIneligibleYouthUsersIds = memoize(youth => {
    const { ineligibleYouthUsersIds, allowEligibleYouth } = this.props;
    return [
      ...ineligibleYouthUsersIds,
      ...(allowEligibleYouth ? [] : youth.map(youth => youth.userId)),
    ];
  });

  getIneligibleAdultsUsersIds = memoize((adults, allowEligibleAdults) =>
    allowEligibleAdults ? [] : adults.map(adult => adult.userId),
  );

  render() {
    const {
      persons,
      onDeselectPerson,
      className,
      adultsWarningMessage,
      youthWarningMessage,
      allowEligibleYouth,
      allowEligibleAdults,
      stats,
      counter,
      fullWidth,
      noPadding,
      allowRemoveAdults,
      allowRemoveYouth,
      type,
      mobileListId,
      youthListId,
      adultListId,
      personClassName,
      showAdvStatus,
      notAllowedToRemove,
      isMobile,
      selectedAdvStatus,
    } = this.props;
    const { open } = this.state;
    const { youths, adults } = getYouthsAdults(persons);
    const ineligibleAdultsUsersIds = this.getIneligibleAdultsUsersIds(
      adults,
      allowEligibleAdults,
    );
    const ineligibleYouthUsersIds = this.getIneligibleYouthUsersIds(youths);
    const removeYouths = isFunc(allowRemoveYouth)
      ? allowRemoveYouth(youths, persons)
      : allowRemoveYouth;
    const removeAdults = isFunc(allowRemoveAdults)
      ? allowRemoveAdults(adults, persons)
      : allowRemoveAdults;
    const eligibleYouth = allowEligibleYouth ? youths.length : 0;
    const eligibleAdults = allowEligibleAdults ? adults.length : 0;
    const eligibleMobileCount = eligibleYouth + eligibleAdults;
    return (
      <div className={className}>
        {isMobile && (
          <PersonsListMobile
            open={open}
            personsNotAllowedToRemove={notAllowedToRemove}
            eligibleCount={eligibleMobileCount}
            ineligibleAdultsUsersIds={ineligibleAdultsUsersIds}
            ineligibleYouthUsersIds={ineligibleYouthUsersIds}
            adultsWarningMessage={adultsWarningMessage}
            youthWarningMessage={youthWarningMessage}
            persons={persons}
            stats={stats}
            type={type}
            handleListToggleClick={this.handleListToggleClick}
            onRemove={onDeselectPerson}
            counter={counter}
            fullWidth={fullWidth}
            noPadding={noPadding}
            listId={mobileListId}
            personClassName={personClassName}
            showAdvStatus={showAdvStatus}
          />
        )}
        {!isMobile && (
          <React.Fragment>
            <PersonsListDesktop
              type="youth"
              personsNotAllowedToRemove={notAllowedToRemove}
              allowRemove={removeYouths}
              eligibleCount={eligibleYouth}
              ineligibleUsersIds={ineligibleYouthUsersIds}
              warningMessage={youthWarningMessage}
              persons={youths}
              listId={youthListId}
              onRemove={onDeselectPerson}
              personClassName={personClassName}
              showAdvStatus={showAdvStatus}
              selectedAdvStatus={selectedAdvStatus}
            />
            <PersonsListDesktop
              type="adults"
              personsNotAllowedToRemove={notAllowedToRemove}
              allowRemove={removeAdults}
              eligibleCount={eligibleAdults}
              ineligibleUsersIds={ineligibleAdultsUsersIds}
              warningMessage={adultsWarningMessage}
              persons={adults}
              listId={adultListId}
              onRemove={onDeselectPerson}
              personClassName={personClassName}
              selectedAdvStatus={selectedAdvStatus}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

PersonsList.propTypes = {
  notAllowedToRemove: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  persons: personsShape.isRequired,
  className: PropTypes.string,
  adultsWarningMessage: PropTypes.node,
  youthWarningMessage: PropTypes.node,
  type: PropTypes.oneOf(validTypesByPerson).isRequired,
  allowEligibleYouth: PropTypes.bool,
  allowEligibleAdults: PropTypes.bool,
  ineligibleYouthUsersIds: PropTypes.array,
  stats: PropTypes.node,
  mobileListId: PropTypes.string,
  youthListId: PropTypes.string,
  adultListId: PropTypes.string,
  counter: PropTypes.bool,
  fullWidth: PropTypes.bool,
  noPadding: PropTypes.bool,
  onDeselectPerson: PropTypes.func,
  personClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  allowRemoveYouth: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  allowRemoveAdults: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  showAdvStatus: PropTypes.bool,
  selectedAdvStatus: PropTypes.string,
  // from redux
  isMobile: PropTypes.bool,
};

PersonsList.defaultProps = {
  ineligibleYouthUsersIds: [],
  showAdvStatus: false,
};

PersonsList.WarningMessage = WarningMessage;

const mapSate = state => ({
  isMobile: isMobileSel(state),
});

export default connect(mapSate)(PersonsList);
