import React from 'react';
import PropTypes from 'prop-types';

import { TimePicker, timeFormat } from '@shared';
import { getTimePlaceholder, getDefaultDate } from '../../../../../common';
const ActivityTimeInput = ({
  allDay,
  fieldName,
  initialValue,
  rules,
  disabled,
  form,
  ...rest
}) => {
  const { getFieldDecorator } = form;

  return getFieldDecorator(fieldName, {
    initialValue: getDefaultDate(initialValue),
    rules,
  })(
    <TimePicker
      fluid
      disabled={disabled}
      size="large"
      format={timeFormat}
      placeholder={getTimePlaceholder({ allDay, type: fieldName })}
      {...rest}
    />,
  );
};

ActivityTimeInput.propTypes = {
  allDay: PropTypes.bool,
  fieldTranslationKey: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.object,
  rules: PropTypes.array,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

export default ActivityTimeInput;
