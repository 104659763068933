import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import {
  S,
  DateView,
  PersonAvatar,
  PhoneView,
  devMockHighlightClassName,
} from '@shared';
import styles from './MbcDetailsDesktop.less';

const MbcDetailsDesktop = ({ mbc }) => {
  const {
    memberId,
    name,
    address = {},
    phone = {},
    email,
    yptExpireDate,
    workingWithScoutsCount,
  } = mbc;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PersonAvatar
          className={cn(devMockHighlightClassName, styles.avatar)}
          isAdult
        />
        <div className={devMockHighlightClassName}>
          <S size="3" colored bold>
            {name}
          </S>
          <S size="4" colored colon inlineBlock>
            <FormattedMessage id="mbc.column.memberId" />{' '}
          </S>{' '}
          {memberId}
        </div>
      </div>
      <div
        className={devMockHighlightClassName}
      >{`${address.city}, ${address.state} ${address.zip}`}</div>
      <div className={devMockHighlightClassName}>
        <PhoneView phone={phone} />
      </div>
      <div className={devMockHighlightClassName}>{email}</div>
      <div className={devMockHighlightClassName}>
        <S size="4" colored colon inlineBlock>
          <FormattedMessage id="mbc.detailColumn.yptExpiration" />{' '}
        </S>{' '}
        <DateView value={yptExpireDate} />
      </div>
      <S size="4" className={devMockHighlightClassName}>
        <FormattedMessage
          id="mbc.workingWithScouts"
          values={{
            count: (
              <S size="4" bold inlineBlock>
                {workingWithScoutsCount}
              </S>
            ),
          }}
        />
      </S>
      <S size="4" bold className={devMockHighlightClassName}>
        <FormattedMessage id="mbc.approvedByCouncil" />
      </S>
      <S size="4" bold className={devMockHighlightClassName}>
        <FormattedMessage id="mbc.counselorFor" />
      </S>
    </div>
  );
};

MbcDetailsDesktop.propTypes = {
  mbc: PropTypes.object.isRequired,
};

export default MbcDetailsDesktop;
