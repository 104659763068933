import { EditAdvancementReportPage } from './components';
import { ROUTE_EDIT_ADVANCEMENT_REPORT } from './duck';

const routes = {
  [ROUTE_EDIT_ADVANCEMENT_REPORT]: {
    path: '/edit-advancement-report',
    Component: EditAdvancementReportPage,
    titleId: 'page.advancementReport',
  },
};

export default routes;
