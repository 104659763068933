import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PROMPT_TIMEOUT_MINUTES } from '@modules/userInactivity/constants';
import { Button, T } from '@shared/components';

import styles from './TimeoutModal.less';

const modalTime = 60 * PROMPT_TIMEOUT_MINUTES;
/**
 * This modal contains a prompt for the user to select either keep signed in
 * or log out. User must reply within 5 minutes.
 */
class TimeoutModalContent extends React.PureComponent {
  state = {
    timer: modalTime,
  };

  componentDidMount() {
    this.timerInterval = setInterval(() => {
      this.setState(({ timer }) => ({ timer: timer > 0 ? timer - 1 : 0 }));
    }, 1000);
  }

  componentWillUnmount() {
    this.onStopTimer();
  }

  timerInterval = null;

  onStopTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  };

  handleContinueSession = () => {
    this.props.onCloseTimeoutModal();
  };

  handleLogout = () => this.props.onLogout();

  render() {
    const { isMobile } = this.props;
    const { timer } = this.state;

    const buttonSize = isMobile ? 'default' : 'large';
    const timeLeft = moment.unix(timer);

    return (
      <React.Fragment>
        <div className={styles.header}>
          <ErrorOutlineIcon className={styles.icon} />
          <T size="4">
            <FormattedMessage id="user.TimeoutModal.title" />
          </T>
        </div>
        <FormattedMessage
          id="user.TimeoutModal.description"
          values={{ countDown: timeLeft.format('m:ss'), br: <br /> }}
        />
        <div className={styles.buttonsWrapper}>
          <Button
            uppercase={false}
            type="primary"
            color="scouting-warm-gray"
            fitText
            size={buttonSize}
            onClick={this.handleLogout}
          >
            <FormattedMessage id="user.TimeoutModal.logMeOut" />
          </Button>
          <Button
            uppercase={false}
            color="success"
            type="primary"
            size={buttonSize}
            fitText
            onClick={this.handleContinueSession}
          >
            <FormattedMessage id="user.TimeoutModal.staySignedIn" />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

TimeoutModalContent.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onCloseTimeoutModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default TimeoutModalContent;
