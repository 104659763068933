import {
  ROUTE_ADVANCEMENT,
  ROUTE_PURCHASE_ORDER_DETAILS,
  ROUTE_QE_HEALTH,
  ROUTE_QE_OA,
  ROUTE_QE_RANK,
  ROUTE_QE_SCHOOL,
  ROUTE_QE_SCOUTLEADERSHIP,
  ROUTE_QE_SWIMMING,
  navigateToQuickEntryHealth,
  navigateToQuickEntryOA,
  navigateToQuickEntryRank,
  navigateToQuickEntrySchool,
  navigateToQuickEntrySwimming,
  navigateToRoster,
  navigateToRosterTab,
  organizationGuidSel,
} from '@shared';
import { openBasicReport } from '@utils';

import { reportsSel } from '../../reports';
import {
  advancementModuleName,
  advancementUploadModuleNamespace,
  packRosterModuleNamespace,
} from './constants';
import { selectedYouthSel } from './selectors';

export {
  SET_SELECTED_ROSTER_KEYS as SET_SELECTED_KEYS,
  setSelectedRosterKeys as setSelectedKeys,
} from '@shared';

export {
  ROUTE_ADVANCEMENT,
  ROUTE_QE_HEALTH,
  ROUTE_QE_RANK,
  ROUTE_QE_SWIMMING,
  ROUTE_QE_SCHOOL,
  ROUTE_QE_SCOUTLEADERSHIP,
  ROUTE_QE_OA,
  ROUTE_PURCHASE_ORDER_DETAILS,
  navigateToRoster,
  navigateToQuickEntryHealth,
  navigateToQuickEntrySwimming,
  navigateToQuickEntrySchool,
  navigateToQuickEntryOA,
  navigateToQuickEntryRank,
};
export const navigateToTab = navigateToRosterTab;

export const PACK_ROSTER_REQUEST_IF_NOT_LOADED = `${packRosterModuleNamespace}/PACK_ROSTER_REQUEST_IF_NOT_LOADED`;
export const packRosterRequestIfNotLoaded = () => ({
  type: PACK_ROSTER_REQUEST_IF_NOT_LOADED,
});
export const PACK_ROSTER_REQUEST = `${packRosterModuleNamespace}/PACK_ROSTER_REQUEST`;
export const PACK_ROSTER_RESPONSE = `${packRosterModuleNamespace}/PACK_ROSTER_RESPONSE`;
export const PACK_ROSTER_ERROR = `${packRosterModuleNamespace}/PACK_ROSTER_ERROR`;
export const packRosterRequest = () => ({ type: PACK_ROSTER_REQUEST });
export const packRosterResponse = roster => ({
  type: PACK_ROSTER_RESPONSE,
  payload: roster,
});
export const packRosterError = error => ({
  type: PACK_ROSTER_ERROR,
  payload: error,
});
export const PACK_ROSTER_ALREADY_LOADED = `${packRosterModuleNamespace}/PACK_ROSTER_ALREADY_LOADED`;
export const packRosterAlreadyLoaded = () => ({
  type: PACK_ROSTER_ALREADY_LOADED,
});

export const EXTENDED_ROSTER_REQUEST = `${packRosterModuleNamespace}/EXTENDED_ROSTER_REQUEST`;
export const EXTENDED_YOUTH_ROSTER_RESPONSE = `${packRosterModuleNamespace}/EXTENDED_YOUTH_ROSTER_RESPONSE`;
export const EXTENDED_ADULT_ROSTER_REQUEST = `${packRosterModuleNamespace}/EXTENDED_ADULT_ROSTER_REQUEST`;
export const EXTENDED_ADULT_ROSTER_RESPONSE = `${packRosterModuleNamespace}/EXTENDED_ADULT_ROSTER_RESPONSE`;
export const EXTENDED_ROSTER_ERROR = `${packRosterModuleNamespace}/EXTENDED_ROSTER_ERROR`;
export const extendedRosterRequest = memberIds => ({
  type: EXTENDED_ROSTER_REQUEST,
  payload: { memberIds },
});
export const extendedYouthRosterResponse = roster => ({
  type: EXTENDED_YOUTH_ROSTER_RESPONSE,
  payload: roster,
});
export const extendedAdultRosterRequest = memberIds => ({
  type: EXTENDED_ADULT_ROSTER_REQUEST,
  payload: { memberIds },
});
export const extendedAdultRosterResponse = roster => ({
  type: EXTENDED_ADULT_ROSTER_RESPONSE,
  payload: roster,
});
export const extendedRosterError = error => ({
  type: EXTENDED_ROSTER_ERROR,
  payload: error,
});

export const EXTENDED_PARENT_ROSTER_REQUEST = `${packRosterModuleNamespace}/EXTENDED_PARENT_ROSTER_REQUEST`;
export const PARENT_ROSTER_RESPONSE = `${packRosterModuleNamespace}/PARENT_ROSTER_RESPONSE`;
export const EXTENDED_PARENT_ROSTER_RESPONSE = `${packRosterModuleNamespace}/EXTENDED_PARENT_ROSTER_RESPONSE`;
export const EXTENDED_PARENT_ROSTER_ERROR = `${packRosterModuleNamespace}/EXTENDED_PARENT_ROSTER_ERROR`;
export const extendedParentRosterRequest = () => ({
  type: EXTENDED_PARENT_ROSTER_REQUEST,
});
export const extendedParentRosterResponse = roster => ({
  type: EXTENDED_PARENT_ROSTER_RESPONSE,
  payload: roster,
});
export const parentRosterResponse = roster => ({
  type: PARENT_ROSTER_RESPONSE,
  payload: roster,
});
export const extendedParentRosterError = error => ({
  type: EXTENDED_PARENT_ROSTER_ERROR,
  payload: error,
});

export const ORG_ROSTER_RESPONSE = `${packRosterModuleNamespace}/ORG_ROSTER_RESPONSE`;
export const ORG_ROSTER_ERROR = `${packRosterModuleNamespace}/ORG_ROSTER_ERROR`;
export const orgRosterResponse = orgInfo => ({
  type: ORG_ROSTER_RESPONSE,
  payload: orgInfo,
});
export const orgRosterError = error => ({
  type: ORG_ROSTER_ERROR,
  payload: error,
});

export const SEARCH_SCHOOLS_REQUEST = `${packRosterModuleNamespace}/SEARCH_SCHOOLS_REQUEST`;
export const SEARCH_SCHOOLS_RESPONSE = `${packRosterModuleNamespace}/SEARCH_SCHOOLS_RESPONSE`;
export const SEARCH_SCHOOLS_ERROR = `${packRosterModuleNamespace}/SEARCH_SCHOOLS_ERROR`;
export const searchSchoolsRequest = (
  name = '',
  city = '',
  state = '',
  zip = '',
) => ({
  type: SEARCH_SCHOOLS_REQUEST,
  payload: { name, city, state, zip },
});
export const searchSchoolsResponse = schools => ({
  type: SEARCH_SCHOOLS_RESPONSE,
  payload: schools,
});
export const searchSchoolsError = error => ({
  type: SEARCH_SCHOOLS_ERROR,
  payload: error,
});

export const EDUCATION_RECORDS_REQUEST = `${packRosterModuleNamespace}/EDUCATION_RECORDS_REQUEST`;
export const EDUCATION_RECORDS_RESPONSE = `${packRosterModuleNamespace}/EDUCATION_RECORDS_RESPONSE`;
export const EDUCATION_RECORDS_ERROR = `${packRosterModuleNamespace}/EDUCATION_RECORDS_ERROR`;
export const educationRecordsRequest = memberIds => ({
  type: EDUCATION_RECORDS_REQUEST,
  payload: { memberIds },
});
export const educationRecordsResponse = records => ({
  type: EDUCATION_RECORDS_RESPONSE,
  payload: records,
});
export const educationRecordsError = error => ({
  type: EDUCATION_RECORDS_ERROR,
  payload: error,
});

export const QE_SCHOOL_REQUEST = `${packRosterModuleNamespace}/QE_SCHOOL_REQUEST`;
export const QE_SCHOOL_RESPONSE = `${packRosterModuleNamespace}/QE_SCHOOL_RESPONSE`;
export const QE_SCHOOL_ERROR = `${packRosterModuleNamespace}/QE_SCHOOL_ERROR`;
export const saveQESchoolsRequest = (data, toastText) => ({
  type: QE_SCHOOL_REQUEST,
  payload: { data, toastText },
});
export const saveQESchoolsResponse = schools => ({
  type: QE_SCHOOL_RESPONSE,
  payload: schools,
});
export const saveQESchoolsError = error => ({
  type: QE_SCHOOL_ERROR,
  payload: error,
});

export const PACK_ROSTER_IGNORE_SUB_UNITS = `${packRosterModuleNamespace}/PACK_ROSTER_IGNORE_SUB_UNITS`;
export const packRosterIgnoreSubUnits = () => ({
  type: PACK_ROSTER_IGNORE_SUB_UNITS,
});

export const UPLOAD_FILE_RESPONSE = `${advancementUploadModuleNamespace}/UPLOAD_FILE_RESPONSE`;
export const uploadFileResponse = file => ({
  type: UPLOAD_FILE_RESPONSE,
  payload: file,
});

export const UNIT_ROSTER_REPORT_LOADING = `${advancementModuleName}/UNIT_ROSTER_REPORT_LOADING`;
export const setUnitRosterReportLoading = isLoading => ({
  type: UNIT_ROSTER_REPORT_LOADING,
  payload: isLoading,
});

export const OPEN_UNIT_REPORT_MODAL = `${advancementModuleName}/OPEN_UNIT_REPORT_MODAL`;
export const CLOSE_UNIT_REPORT_MODAL = `${advancementModuleName}/CLOSE_UNIT_REPORT_MODAL`;
export const openUnitReportModal = reportCode => (dispatch, getState) => {
  const state = getState();
  const selectedYouth = selectedYouthSel(state);

  if (selectedYouth.length === 1) {
    const organizationGuid = organizationGuidSel(state);
    const { ReportURI } = reportsSel(state).find(
      report => report.reportCode === reportCode,
    );
    const { personGuid } = selectedYouth[0];
    return openBasicReport(ReportURI, { organizationGuid, personGuid });
  }
  dispatch({
    type: OPEN_UNIT_REPORT_MODAL,
    payload: reportCode,
  });
};
export const closeUnitReportModal = () => ({ type: CLOSE_UNIT_REPORT_MODAL });
