import { gtm } from '@shared';

import {
  UPLOAD_FILE_RESPONSE,
  advancementUploadModuleNamespace as moduleNamespace,
  uploadFileResponse,
} from '../../common';

export { UPLOAD_FILE_RESPONSE, uploadFileResponse };

export const OPEN_UPLOAD_MODAL = `${moduleNamespace}/OPEN_UPLOAD_MODAL`;
export const CLOSE_UPLOAD_MODAL = `${moduleNamespace}/CLOSE_UPLOAD_MODAL`;
export const openUploadModal = () => ({ type: OPEN_UPLOAD_MODAL });
export const closeUploadModal = () => ({ type: CLOSE_UPLOAD_MODAL });

export const UPLOAD_FILE_REQUEST = `${moduleNamespace}/UPLOAD_FILE_REQUEST`;
export const UPLOAD_FILE_ERROR = `${moduleNamespace}/UPLOAD_FILE_ERROR`;
export const UPLOAD_FILE_CANCEL = `${moduleNamespace}/UPLOAD_FILE_CANCEL`;
export const UPLOAD_FILE_PROGRESS = `${moduleNamespace}/UPLOAD_FILE_PROGRESS`;
export const uploadFileRequest = file => ({
  type: UPLOAD_FILE_REQUEST,
  payload: file,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const uploadFileError = err => ({
  type: UPLOAD_FILE_ERROR,
  payload: err,
});
export const uploadFileCancel = () => ({ type: UPLOAD_FILE_CANCEL });
export const uploadFileProgress = progress => ({
  type: UPLOAD_FILE_PROGRESS,
  payload: progress,
});

export const CANCEL_FILE_REQUEST = `${moduleNamespace}/CANCEL_FILE_REQUEST`;
export const CANCEL_FILE_RESPONSE = `${moduleNamespace}/CANCEL_FILE_RESPONSE`;
export const CANCEL_FILE_ERROR = `${moduleNamespace}/CANCEL_FILE_ERROR`;
export const cancelFileRequest = ({ id, successMsg }) => ({
  type: CANCEL_FILE_REQUEST,
  payload: {
    id,
    successMsg,
  },
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const cancelFileResponse = id => ({
  type: CANCEL_FILE_RESPONSE,
  payload: id,
});
export const cancelFileError = id => ({
  type: CANCEL_FILE_ERROR,
  payload: id,
});

export const OPEN_ERRORS_MODAL = `${moduleNamespace}/OPEN_ERRORS_MODAL`;
export const CLOSE_ERRORS_MODAL = `${moduleNamespace}/CLOSE_ERRORS_MODAL`;
export const openErrorsModal = () => ({ type: OPEN_ERRORS_MODAL });
export const closeErrorsModal = () => ({ type: CLOSE_ERRORS_MODAL });
