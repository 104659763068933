import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

import { openApproveAdvancements } from '../../duck';

function ApproveAdvancementsAction({
  className,
  children,
  onOpenApproveAdvancements,
}) {
  return (
    <div className={className} onClick={onOpenApproveAdvancements}>
      {children || (
        <React.Fragment>
          <TrackChangesIcon />
          <FormattedMessage
            id="progress.ApproveAdvancementsButton.title"
            values={{ count: 1 }}
          />
        </React.Fragment>
      )}
    </div>
  );
}

ApproveAdvancementsAction.propTypes = {
  // provided by parent
  className: PropTypes.string,
  children: PropTypes.node,
  personsAdvancements: PropTypes.array.isRequired,
  // provided by component itself
  onOpenApproveAdvancements: PropTypes.func.isRequired,
};

const mapDispatch = (dispatch, { personsAdvancements }) => ({
  onOpenApproveAdvancements: () =>
    dispatch(openApproveAdvancements(personsAdvancements)),
});

export default connect(null, mapDispatch)(ApproveAdvancementsAction);
