/* eslint-disable react/display-name */

/* eslint-disable react/no-multi-comp */
import React from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getPreferredName } from '@modules/shared/utils/preferredName';
import { Button, PersonAvatar, S, devMockHighlightClassName } from '@shared';

import { visibilityFields } from '../../../../constants';
import EditSubUnits from '../../EditSubUnits/EditSubUnits';
import OtherRanksTooltip from '../../OtherRanksTooltip';
import PersonWarning from '../../PersonWarning';
import styles from './packRosterListRows.less';

function rows({
  onGoToYouthProfile,
  onGoToAdultProfile,
  fieldsVisibility,
  subUnitType,
  isSubUnitAllowed,
  approvedSubUnits,
  isOffline,
  mySubUnits,
  canEditSubUnits,
  showSubUnits,
  hasSubUnitOnlyPerms,
  defaultSubUnitList,
}) {
  return function packRosterListRows(item) {
    const {
      isAdult,
      age,
      rosterCurrentHighestRankApproved = {},
      rosterOtherHighestRanksApproved: otherRanks,
      userId,
      subUnitName = '',
    } = item;
    const preferredName = getPreferredName(item);

    const isDisabled = !userId;

    const subUnitData = isSubUnitAllowed
      ? [
          {
            rowId: 'subUnit',
            className: cn({ [styles.disabled]: isDisabled }),
            omit: !fieldsVisibility[visibilityFields.SUB_UNIT],
            label: (
              <S size="4" bold>
                <FormattedMessage
                  id={`advancement.PackRoster.column.${subUnitType}`}
                />
              </S>
            ),
            content: (
              <>
                <S size="4" className={styles.subUnitName}>
                  {subUnitName}
                </S>
                <EditSubUnits
                  data={item}
                  defaultSubUnitList={defaultSubUnitList}
                  approvedSubUnits={approvedSubUnits}
                  hasSubUnitOnlyPerms={hasSubUnitOnlyPerms}
                  canEditSubUnits={canEditSubUnits}
                  mySubUnits={mySubUnits}
                  showSubUnits={showSubUnits}
                />
              </>
            ),
          },
        ]
      : [];

    const viewMoreButton = [
      {
        rowId: 'viewMore',
        omit: isOffline,
        className: styles.viewMore,
        content: (
          <Button
            color="info"
            ghost
            noBorder
            size="small"
            className={styles.button}
            onClick={
              item.isAdult ? onGoToAdultProfile(item) : onGoToYouthProfile(item)
            }
            disabled={isDisabled}
          >
            <FormattedMessage id="advancement.PackRosterList.viewProfile" />
          </Button>
        ),
      },
    ];

    return [].concat(
      [
        {
          rowId: 'header',
          className: styles.header,
          label: (
            <React.Fragment>
              <PersonAvatar
                isAdult={isAdult}
                className={cn(devMockHighlightClassName, {
                  [styles.disabled]: isDisabled,
                })}
              />
              <PersonWarning person={item} />
            </React.Fragment>
          ),
          content: (
            <React.Fragment>
              <S
                size="4"
                bold
                colored
                inlineBlock
                className={cn({ [styles.disabled]: isDisabled })}
              >
                {preferredName}
              </S>
            </React.Fragment>
          ),
        },
        {
          rowId: 'personId',
          omit: !fieldsVisibility[visibilityFields.MEMBER_ID],
          className: cn({ [styles.disabled]: isDisabled }),
          label: (
            <S size="4" bold>
              <FormattedMessage id="advancement.PackRoster.column.memberId" />
            </S>
          ),
          content: <S size="4">{item.personId}</S>,
        },
        {
          rowId: 'age',
          className: cn({ [styles.disabled]: isDisabled }),
          omit: !fieldsVisibility[visibilityFields.AGE],
          label: (
            <S size="4" bold>
              <FormattedMessage id="advancement.PackRoster.column.age" />
            </S>
          ),
          content: age,
        },
        {
          rowId: 'currentHighestRankApproved',
          className: cn({ [styles.disabled]: isDisabled }),
          omit: !fieldsVisibility[visibilityFields.LAST_RANK_EARNED],
          label: (
            <S size="4" bold>
              <FormattedMessage id="advancement.PackRoster.column.highestRankApproved" />
            </S>
          ),
          content: (
            <OtherRanksTooltip
              currentRank={rosterCurrentHighestRankApproved}
              otherRanks={otherRanks}
            />
          ),
        },
      ],
      subUnitData,
      viewMoreButton,
    );
  };
}

export default rows;
