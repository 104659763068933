import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { LOGIN_RESPONSE, SET_LAST_ONLINE } from '@shared';
import {
  TEST_REQUEST,
  TEST_RESPONSE,
  TEST_DEFERRED,
  TEST_ERROR,
  SET_OFFLINE,
  SET_MANUAL_OFFLINE,
  SET_PENDING_REQUESTS,
  OPEN_LOGBACKIN_MODAL,
  CLOSE_LOGBACKIN_MODAL,
  OPEN_SYNC_MODAL,
  CLOSE_SYNC_MODAL,
  SELECT_YOUTH,
  SYNC_ALL,
  SYNC_ITEM,
  SYNC_COMPLETED,
  VERIFY_PENDING_ADVANCEMENTS_REQUEST,
  VERIFY_PENDING_ADVANCEMENTS_RESPONSE,
  VERIFY_PENDING_ADVANCEMENTS_ERROR,
  SET_FAILED_ITEMS,
  SET_FAILED_ITEM,
} from './actions';

const testLoading = (state = false, { type }) => {
  switch (type) {
    case TEST_REQUEST: {
      return true;
    }
    case TEST_RESPONSE:
    case TEST_DEFERRED:
    case TEST_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const defaultOffline = !navigator.onLine;
const offline = (state = defaultOffline, { type, payload }) => {
  switch (type) {
    case SET_OFFLINE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const manualOffline = (state = false, { type, payload }) => {
  switch (type) {
    case SET_MANUAL_OFFLINE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const pendingRequests = (state = [], { type, payload }) => {
  switch (type) {
    case SET_PENDING_REQUESTS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const loginModalOpen = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_LOGBACKIN_MODAL: {
      return true;
    }
    case LOGIN_RESPONSE:
    case CLOSE_LOGBACKIN_MODAL: {
      return false;
    }
    case SET_OFFLINE:
    case SET_MANUAL_OFFLINE: {
      return payload ? false : state;
    }
    default: {
      return state;
    }
  }
};

const syncModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_SYNC_MODAL: {
      return true;
    }
    case CLOSE_SYNC_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedYouthId = (state = null, { type, payload }) => {
  switch (type) {
    case SELECT_YOUTH: {
      return payload;
    }
    case CLOSE_SYNC_MODAL: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const lastOnline = (state = '', { type, payload }) => {
  switch (type) {
    case SET_LAST_ONLINE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const syncLoading = (state = false, { type }) => {
  switch (type) {
    case SYNC_ALL:
    case SYNC_ITEM: {
      return true;
    }
    case SYNC_COMPLETED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const advancementVerificationLoading = (state = false, { type }) => {
  switch (type) {
    case VERIFY_PENDING_ADVANCEMENTS_REQUEST: {
      return true;
    }
    case VERIFY_PENDING_ADVANCEMENTS_ERROR:
    case VERIFY_PENDING_ADVANCEMENTS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const verifiedPendingItems = (state = [], { type, payload }) => {
  switch (type) {
    case VERIFY_PENDING_ADVANCEMENTS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const failedPendingItemIds = (state = [], { type, payload }) => {
  switch (type) {
    case SET_FAILED_ITEMS: {
      return payload;
    }
    case SET_FAILED_ITEM: {
      return [...new Set([...state, payload])];
    }
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  testLoading,
  offline,
  manualOffline,
  pendingRequests,
  loginModalOpen,
  syncModalOpen,
  selectedYouthId,
  lastOnline,
  syncLoading,
  advancementVerificationLoading,
  verifiedPendingItems,
  failedPendingItemIds,
});

export default resetOnLogoutReducer(reducers);
