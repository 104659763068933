import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Row, S } from '@shared/components';

import {
  isLoadingSendRemindersSel,
  pendingMembersSelectedSel,
  removePendingSelectedKey,
  sendRemindersRequest,
} from '../../../../duck';
import { groupPersonsByIsAdult } from '../../../../utils';
import { PersonTag } from '../../../common';
import styles from './ResendInvite.less';

const ResendInvite = ({ onCancel }) => {
  const dispatch = useDispatch();
  const pendingMembersSelected = useSelector(pendingMembersSelectedSel);
  const isLoadingSendReminders = useSelector(isLoadingSendRemindersSel);
  const { adults, youths } = useMemo(
    () => groupPersonsByIsAdult(pendingMembersSelected),
    [pendingMembersSelected],
  );

  const handleOnResend = () => {
    dispatch(sendRemindersRequest());
  };

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onClickRemoveSelectedKey = id => {
    dispatch(removePendingSelectedKey(id));
  };

  return (
    <React.Fragment>
      <Row type="flex">
        <S size="4">
          <FormattedMessage id="recharterModals.resendInviteModal.message" />
        </S>
      </Row>
      <Row type="flex" className={styles.personsContainer}>
        {adults.length > 0 && (
          <React.Fragment>
            <S size={'4'} className={styles.title}>
              <FormattedMessage id="recharterModals.resendInviteModal.adults" />
              {` (${adults.length})`}
            </S>
            <div className={styles.tagsContainer}>
              {adults.map(({ personId, personFullName }) => (
                <PersonTag
                  key={`person_tag_${personId}`}
                  id={personId}
                  isAdult={true}
                  name={personFullName}
                  onCloseAction={onClickRemoveSelectedKey}
                />
              ))}
            </div>
          </React.Fragment>
        )}
      </Row>
      <Row type="flex">
        {youths.length > 0 && (
          <React.Fragment>
            <S size={'4'}>
              <FormattedMessage id="recharterModals.resendInviteModal.youths" />
              {` (${youths.length})`}
            </S>
            <div className={styles.tagsContainer}>
              {youths.map(({ personId, personFullName }) => (
                <PersonTag
                  key={`person_tag_${personId}`}
                  id={personId}
                  isAdult={false}
                  name={personFullName}
                  onCloseAction={onClickRemoveSelectedKey}
                />
              ))}
            </div>
          </React.Fragment>
        )}
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button
            className={styles.button}
            color="scouting-warm-gray"
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={true}
            onClick={handleOnCancel}
          >
            <FormattedMessage id="recharterModals.resendInviteModal.cancel" />
          </Button>
        </Col>
        <Col className={styles.lastButton}>
          <Button
            loading={isLoadingSendReminders}
            className={styles.button}
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={true}
            disabled={pendingMembersSelected.length === 0}
            onClick={handleOnResend}
          >
            <FormattedMessage id="recharterModals.resendInviteModal.resend" />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ResendInvite.propTypes = {
  onCancel: PropTypes.func,
};

ResendInvite.defaultProps = {
  onCancel: () => {},
};

export default ResendInvite;
