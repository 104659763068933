import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './T.less';

function ResponsiveTitle({ children, className }) {
  const sizeClassName = 'title-5';
  return (
    <span
      className={cn(
        styles.block,
        sizeClassName,
        'text-primary',
        styles.responsiveTitle,
        className,
      )}
    >
      {children}
    </span>
  );
}

ResponsiveTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ResponsiveTitle;
