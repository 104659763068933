import { from } from 'rxjs';
import stable from 'stable';

const fetchCounseledYouth$ = () =>
  from(
    import('./counseledYouthWithMeritBadges.json').then(
      imported => imported.default,
    ),
  )
    // TODO: Remove the delay bellow when we have an API
    .delay(1000)
    .map(counseledYouth =>
      stable(
        counseledYouth.map(person => ({
          ...person,
          memberId: person.userId,
        })),
      ),
    );

export default {
  fetchCounseledYouth$,
};
