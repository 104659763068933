import flatten from 'lodash/flatten';
import lowerCase from 'lodash/lowerCase';
import omit from 'lodash/omit';
import moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import progressServices from '@progress/duck/services';
import {
  AdvancementFileStatus,
  AdvancementStatus,
  activityTypeForActivityTypeId,
  advancementFileStatuses,
  advancementStatus,
  advancementStatusDate,
  advancementStatuses,
  dtoDateFormat,
  intl,
  normalizeAdvancementType,
} from '@shared';
import {
  esbApiService,
  getAdvancementDescription,
  personNameBuilder,
} from '@utils';

import {
  advancementStatusFilters,
  historyItemsTypes,
  updatedDateFilters,
} from '../constants';
import { getLastUpdatedDate } from '../helpers';

const unnecesaryContentReg = /import_\d+_/;

const isPendingItem = status =>
  status === advancementStatuses.COMPLETED ||
  status === advancementStatuses.COUNSELOR_APPROVED;

const buildUnitHistoryParams = ({ organizationGuid, people }) => ({
  ...people,
  organizationGuid,
});

const filterableAdvancementStatusDict = {
  [advancementStatuses.STARTED]: advancementStatusFilters.STARTED,
  [advancementStatuses.COMPLETED]: advancementStatusFilters.COMPLETED,
  [advancementStatuses.COUNSELOR_APPROVED]:
    advancementStatusFilters.COUNSELOR_APPROVED,
  [advancementStatuses.APPROVED]: advancementStatusFilters.APPROVED,
  [advancementStatuses.AWARDED]: advancementStatusFilters.AWARDED,
};

const getFilterableAdvancementStatus = status =>
  filterableAdvancementStatusDict[status];

const denormalizeAdvancementHistoryItem = person => {
  const { recordedInformation } = person;

  return (
    recordedInformation
      // there's a bunch of essentially empty rows that we can filter out
      // simplest way is to check if status is truthy
      .filter(advancement => advancement.status)
      .map(advancement => {
        const { recordedBy, name: advancementName } = advancement;
        const { firstName, lastName } = recordedBy;
        const advancementType = normalizeAdvancementType(
          advancement.advancementType,
        );
        const description = getAdvancementDescription(
          advancementName,
          advancement.advancementType,
        );

        const status = advancementStatus.getStatus(advancement);
        const isAdvancementPending = isPendingItem(status);
        return {
          ...person,
          ...advancement,
          status,
          description,
          advancementType,
          advancementName,
          isAdvancementPending,
          date: advancementStatusDate.getCompleted(advancement),
          statusLabel: AdvancementStatus.getTranslation(status),
          filterableStatus: getFilterableAdvancementStatus(status),
          recordedInformation: undefined,
          recordedBy: undefined,
          recordedByFirstName: firstName,
          recordedByLastName: lastName,
          recordedByName: personNameBuilder.short(recordedBy),
        };
      })
  );
};

const mapAdvancementHistoryDtoToModel = dto =>
  flatten(dto.map(item => denormalizeAdvancementHistoryItem(item))).map(
    row => ({
      ...row,
      type: historyItemsTypes.ADVANCEMENT,
      name: personNameBuilder.short(row),
    }),
  );

const advancementHistoryCall$ = ({
  organizationGuid,
  updatedDate,
  people,
  rosterItemsCount,
}) => {
  const pendingAdvancementsParams = {
    ...buildUnitHistoryParams({
      organizationGuid,
      people,
    }),
    status: ['Completed'],
  };

  const otherAdvancementsParams = {
    ...buildUnitHistoryParams({
      organizationGuid,
      updatedDate,
      people,
    }),
    status: [
      'Started',
      'LeaderSigned',
      'CounselorApproved',
      'LeaderApproved',
      'Awarded',
    ],
  };

  return forkJoin(
    esbApiService.post$(
      `/advancements/advancementHistory?perPage=${rosterItemsCount}`,
      pendingAdvancementsParams,
      {
        gtm: {
          label: '/advancements/advancementHistory?pending',
        },
        swCache: true,
      },
    ),
    esbApiService.post$(
      `/advancements/advancementHistory?perPage=${rosterItemsCount}`,
      otherAdvancementsParams,
      {
        gtm: {
          label: '/advancements/advancementHistory',
        },
        swCache: true,
      },
    ),
  ).pipe(
    map(flatten),
    map(res => mapAdvancementHistoryDtoToModel(res)),
  );
};

const getShortFileName = fileName => fileName.replace(unnecesaryContentReg, '');
const getDateFromName = fileName => fileName.substring(0, 10);
const getRecordedBy = (importPersonGuid = '', adults = []) => {
  if (!importPersonGuid || !adults) return {};

  return (
    adults.find(
      ({ personGuid = '' }) =>
        lowerCase(importPersonGuid) === lowerCase(personGuid),
    ) || {}
  );
};

const mapAdvancementImportDtoToModel = (dto, adults) =>
  dto.map(
    ({
      fileName,
      totalRecords,
      totalErrors,
      processedDate,
      status,
      ...item
    }) => {
      status = (status || '').toLowerCase();
      const name = getShortFileName(fileName);
      const date =
        status == advancementFileStatuses.CANCELLED
          ? getDateFromName(name)
          : processedDate;
      const recordedBy = item.personGuid
        ? getRecordedBy(item.personGuid, adults)
        : {};
      const { firstName, lastName } = recordedBy;

      return {
        ...item,
        name,
        date,
        status,
        statusLabel: AdvancementFileStatus.getTranslation(status),
        type: historyItemsTypes.ADVANCEMENT_FILE,
        filterableStatus: status,
        totalRecords,
        recordsImported: totalRecords - totalErrors,
        recordsSkipped: totalErrors,
        recordedByFirstName: firstName,
        recordedByLastName: lastName,
        recordedByName: personNameBuilder.short(recordedBy),
      };
    },
  );

const advancementImportCall$ = (organizationGuid, adults) =>
  esbApiService
    .get$(`/persons/advancementImports?organizationGuid=${organizationGuid}`, {
      gtm: {
        label: '/persons/advancementImports',
      },
    })
    .map(res => mapAdvancementImportDtoToModel(res, adults));

const buildCompletionDateParams = updatedDate => {
  const completionDateEnd = moment().format(dtoDateFormat);
  const updatedDateFilter =
    updatedDate === updatedDateFilters.ALL
      ? updatedDateFilters.ALL
      : updatedDateFilters.LAST_365_DAYS;
  const completionDateStart =
    getLastUpdatedDate(updatedDateFilter).format(dtoDateFormat);

  return {
    completionDateStart,
    completionDateEnd,
  };
};

const getActivityStatus = ({ isApproved }) =>
  isApproved ? advancementStatuses.APPROVED : advancementStatuses.COMPLETED;

const getActivityDescription = (name, activityType) =>
  intl.formatMessage(
    {
      id: `advancement.AdvancementHistory.activityItemDescription.${activityType}`,
    },
    { name },
  );

const getActivityAdvancementName = activityType =>
  intl.formatMessage({
    id: `advancement.PendingItems.activity.${activityType}`,
  });

const createMemberItems = (
  registeredMembers,
  activityType,
  activity,
  activityId,
  activityName,
) =>
  registeredMembers.map(({ activityValues, ...member }) => {
    const leaderApprovedUserId = member.leaderApprovedId;
    const status = getActivityStatus(member);
    const isAdvancementPending = isPendingItem(status);
    return {
      ...member,
      ...activity,
      leaderApprovedUserId,
      activityRecords: [...activityValues],
      type: historyItemsTypes.ACTIVITY,
      isAdvancementPending,
      activityId,
      activityName,
      activityType,
      name: personNameBuilder.short(member),
      status,
      statusLabel: AdvancementStatus.getTranslation(status),
      filterableStatus: getFilterableAdvancementStatus(status),
      date: activity.startDateTime,
      description: getActivityDescription(activityName, activityType),
      advancementName: getActivityAdvancementName(activityType),
    };
  });

const denormalizeActivityItems = activityDto => {
  const {
    registeredYouths,
    registeredAdults,
    id: activityId,
    name: activityName,
    ...activity
  } = omit(activityDto, 'nonRegisteredOrgParticipants');
  const activityType = activityTypeForActivityTypeId[activity.activityTypeId];
  const youthItems = createMemberItems(
    registeredYouths,
    activityType,
    activity,
    activityId,
    activityName,
  );
  const adultItems = createMemberItems(
    registeredAdults,
    activityType,
    activity,
    activityId,
    activityName,
  );

  return [...youthItems, ...adultItems];
};

const mapActivityItemsDtoToModel = dto =>
  flatten(dto.map(denormalizeActivityItems));

const activitiesHistoryCall$ = ({ organizationGuid, updatedDate, adults }) => {
  const { completionDateStart, completionDateEnd } =
    buildCompletionDateParams(updatedDate);

  return progressServices
    .getActivities$({
      organizationGuid,
      startDateTime: completionDateStart,
      endDateTime: completionDateEnd,
      showAll: true,
      perPage: 1000,
    })
    .pipe(
      catchError(() => of([])),
      map(mapActivityItemsDtoToModel),
      map(activities =>
        activities.map(activityItem => {
          const { leaderApprovedUserId } = activityItem;
          const recordedBy =
            adults.find(({ userId }) => leaderApprovedUserId == userId) || {};
          const { firstName, lastName } = recordedBy;
          return {
            ...activityItem,
            recordedByFirstName: firstName,
            recordedByLastName: lastName,
            recordedByName: personNameBuilder.short(recordedBy),
          };
        }),
      ),
    );
};

const getUnitHistory$ = ({
  organizationGuid,
  updatedDate,
  people,
  adults,
  rosterItemsCount,
}) =>
  forkJoin(
    advancementHistoryCall$({
      organizationGuid,
      updatedDate,
      people,
      rosterItemsCount,
    }),
    advancementImportCall$(organizationGuid, adults),
    activitiesHistoryCall$({ organizationGuid, updatedDate, adults }),
  ).pipe(
    map(flatten),
    map(res =>
      res.map((row, i) => ({
        ...row,
        key: i,
      })),
    ),
  );

export default {
  getUnitHistory$,
  mapActivityItemsDtoToModel,
};
