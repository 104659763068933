import { gtm } from '@shared/utils';
import { basicActionCreator } from '@utils';

import { moduleName } from '../constants';

export { ROUTE_PERMISSIONS_MANAGER } from '@shared';

const namespace = `${moduleName}`;

export const UNITS_ADULT_REQUEST = `${namespace}/UNITS_ADULT_REQUEST`;
export const UNITS_ADULT_RESPONSE = `${namespace}/UNITS_ADULT_RESPONSE`;
export const UNITS_ADULT_ERROR = `${namespace}/UNITS_ADULT_ERROR`;

export const unitsAdultsRequest = () => ({
  type: UNITS_ADULT_REQUEST,
});

export const unitsAdultsResponse = payload => ({
  type: UNITS_ADULT_RESPONSE,
  payload: payload,
});

export const unitsAdultsError = error => ({
  type: UNITS_ADULT_ERROR,
  payload: error,
});

export const UNITS_YOUTH_REQUEST = `${namespace}/UNITS_YOUTH_REQUEST`;
export const UNITS_YOUTH_RESPONSE = `${namespace}/UNITS_YOUTH_RESPONSE`;
export const UNITS_YOUTH_ERROR = `${namespace}/UNITS_YOUTH_ERROR`;

export const unitsYouthsRequest = () => ({
  type: UNITS_YOUTH_REQUEST,
});

export const unitsYouthsResponse = payload => ({
  type: UNITS_YOUTH_RESPONSE,
  payload: payload,
});

export const unitsYouthsError = error => ({
  type: UNITS_YOUTH_ERROR,
  payload: error,
});

export const OPEN_EDIT_PERMISSIONS_MODAL = `${namespace}/OPEN_EDIT_PERMISSIONS_MODAL`;
export const openEditPermissionsModal = () => ({
  type: OPEN_EDIT_PERMISSIONS_MODAL,
});

export const CLOSE_EDIT_PERMISSIONS_MODAL = `${namespace}/CLOSE_EDIT_PERMISSIONS_MODAL`;
export const closeEditPermissionsModal = () => ({
  type: CLOSE_EDIT_PERMISSIONS_MODAL,
});

export const UPDATE_PERMISSION_REQUEST = `${namespace}/UPDATE_PERMISSION_REQUEST`;
export const UPDATE_PERMISSION_RESPONSE = `${namespace}/UPDATE_PERMISSION_RESPONSE`;
export const UPDATE_PERMISSION_ERROR = `${namespace}/UPDATE_PERMISSION_ERROR`;
export const updatePermissionRequest = data => ({
  type: UPDATE_PERMISSION_REQUEST,
  payload: data,
  gtm: {
    category: gtm.categories.PERMISSIONS,
  },
});
export const updatePermissionResponse = () => ({
  type: UPDATE_PERMISSION_RESPONSE,
});
export const updatePermissionError = err => ({
  type: UPDATE_PERMISSION_ERROR,
  payload: err,
});

export const GET_ALL_SUBUNITS_REQUEST = `${namespace}/GET_ALL_SUBUNITS_REQUEST`;
export const getAllSubUnitsRequest = () =>
  basicActionCreator(GET_ALL_SUBUNITS_REQUEST);

export const GET_ALL_SUBUNITS_RESPONSE = `${namespace}/GET_ALL_SUBUNITS_RESPONSE`;
export const getAllSubUnitsResponse = orgGuid =>
  basicActionCreator(GET_ALL_SUBUNITS_RESPONSE, orgGuid);

export const GET_ALL_SUBUNITS_ERROR = `${namespace}/GET_ALL_SUBUNITS_ERROR`;
export const getAllSubUnitsError = err =>
  basicActionCreator(GET_ALL_SUBUNITS_ERROR, err);

export const ASSIGN_FUNCTIONAL_REQUEST = `${namespace}/ASSIGN_FUNCTIONAL_REQUEST`;
export const assignFunctionalRequest = payload =>
  basicActionCreator(ASSIGN_FUNCTIONAL_REQUEST, payload);

export const ASSIGN_FUNCTIONAL_RESPONSE = `${namespace}/ASSIGN_FUNCTIONAL_RESPONSE`;
export const assignFunctionalResponse = () =>
  basicActionCreator(ASSIGN_FUNCTIONAL_RESPONSE);

export const ASSIGN_FUNCTIONAL_ERROR = `${namespace}/ASSIGN_FUNCTIONAL_ERROR`;
export const assignFunctionalError = err =>
  basicActionCreator(ASSIGN_FUNCTIONAL_ERROR, err);

export const EXPIRE_FUNCTIONAL_REQUEST = `${namespace}/EXPIRE_FUNCTIONAL_REQUEST`;
export const expireFunctionalRequest = payload =>
  basicActionCreator(EXPIRE_FUNCTIONAL_REQUEST, payload);

export const EXPIRE_FUNCTIONAL_RESPONSE = `${namespace}/EXPIRE_FUNCTIONAL_RESPONSE`;
export const expireFunctionalResponse = () =>
  basicActionCreator(EXPIRE_FUNCTIONAL_RESPONSE);

export const EXPIRE_FUNCTIONAL_ERROR = `${namespace}/EXPIRE_FUNCTIONAL_ERROR`;
export const expireFunctionalError = err =>
  basicActionCreator(EXPIRE_FUNCTIONAL_ERROR, err);

export const EXPIRE_ASSIGN_FUNCTIONAL_REQUEST = `${namespace}/EXPIRE_ASSIGN_FUNCTIONAL_REQUEST`;
export const expireAssignFunctionalRequest = payload =>
  basicActionCreator(EXPIRE_ASSIGN_FUNCTIONAL_REQUEST, payload);

export const EXPIRE_ASSIGN_FUNCTIONAL_RESPONSE = `${namespace}/EXPIRE_ASSIGN_FUNCTIONAL_RESPONSE`;
export const expireAssignFunctionalResponse = () =>
  basicActionCreator(EXPIRE_ASSIGN_FUNCTIONAL_RESPONSE);

export const EXPIRE_ASSIGN_FUNCTIONAL_ERROR = `${namespace}/EXPIRE_ASSIGN_FUNCTIONAL_ERROR`;
export const expireAssignFunctionalError = err =>
  basicActionCreator(EXPIRE_ASSIGN_FUNCTIONAL_ERROR, err);

export const GET_ORG_POSITIONS_REQUEST = `${namespace}/GET_ORG_POSITIONS_REQUEST`;
export const getOrgPositionsRequest = () =>
  basicActionCreator(GET_ORG_POSITIONS_REQUEST);

export const GET_ORG_POSITIONS_RESPOSNE = `${namespace}/GET_ORG_POSITIONS_RESPOSNE`;
export const getOrgPositionsResponse = payload =>
  basicActionCreator(GET_ORG_POSITIONS_RESPOSNE, payload);

export const GET_ORG_POSITIONS_ERROR = `${namespace}/GET_ORG_POSITIONS_ERROR`;
export const getOrgPositionsError = err =>
  basicActionCreator(GET_ORG_POSITIONS_ERROR, err);

export const SET_SELECTED_PERSON = `${namespace}/SET_SELECTED_PERSON`;
export const setSelectedPerson = payload =>
  basicActionCreator(SET_SELECTED_PERSON, payload);

export const SET_FROM_POSITION = `${namespace}/SET_FROM_POSITION`;
export const setFromPosition = payload =>
  basicActionCreator(SET_FROM_POSITION, payload);

export const SET_TO_POSITION = `${namespace}/SET_TO_POSITION`;
export const setToPosition = payload =>
  basicActionCreator(SET_TO_POSITION, payload);

export const SET_IS_DROP = `${namespace}/SET_IS_DROP`;
export const setIsDrop = payload => basicActionCreator(SET_IS_DROP, payload);

export const CLEAR = `${namespace}/CLEAR`;
export const setClear = () => basicActionCreator(CLEAR);

export const SET_REG_POSITIONS = `${namespace}/SET_REG_POSITIONS`;
export const setRegPositions = payload =>
  basicActionCreator(SET_REG_POSITIONS, payload);

export const REASSIGN_POSITIONS_REQUEST = `${namespace}/REASSIGN_POSITIONS_REQUEST`;
export const reassignPositionsRequest = payload =>
  basicActionCreator(REASSIGN_POSITIONS_REQUEST, payload);

export const REASSIGN_POSITIONS_RESPONSE = `${namespace}/REASSIGN_POSITIONS_RESPONSE`;
export const reassignPositionsResponse = () =>
  basicActionCreator(REASSIGN_POSITIONS_RESPONSE);

export const REASSIGN_POSITIONS_ERROR = `${namespace}/REASSIGN_POSITIONS_ERROR`;
export const reassignPositionsError = err =>
  basicActionCreator(REASSIGN_POSITIONS_ERROR, err);
