import { combineEpics } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mapTo';
import { mergeMap, switchMap, tap } from 'rxjs/operators';

import { SHOW_AKELA_PROFILE } from '@config';
import { SET_ORGANIZATION } from '@context';
import { dictionariesRequest, dictionaryIds } from '@dict';
import { typeSel } from '@location';
import {
  DELETE_PROFILE_BIO_REQUEST,
  SAVE_PROFILE_BIO_REQUEST,
  intl,
  setIsLoadingDeleteEmailModal,
  setShowDeleteAddressModal,
  setShowDeleteEmailModal,
  setShowDeletePhoneModal,
} from '@shared';
import { toastService } from '@toasts';
import { unitInfoRequest } from '@unit';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  USER_DATA_ERROR,
  USER_DATA_RESPONSE,
  personGuidSel,
  userDataRequest,
  userIdSel,
} from '../../user';
import {
  formatProfileAddress,
  formatProfileEmail,
  formatProfilePhone,
} from '../utils';
import {
  DELETE_AKELA_EMAIL_REQUEST,
  ROUTE_PROFILE,
  SAVE_AKELA_PROFILE_REQUEST,
  SAVE_PROFILE_REQUEST,
  SAVE_UNIT_REQUEST,
  SET_EDIT_PROFILE,
  SET_EDIT_UNIT,
  deleteAkelaEmailError,
  saveAkelaProfileError,
  saveAkelaProfileResponse,
  saveProfileError,
  saveProfileResponse,
  saveUnitResponse,
} from './actions';
import { hobbiesSel } from './selectors';
import services from './services';

const fetchFormDicts$ = action$ =>
  action$
    .ofType(SET_EDIT_PROFILE, SET_EDIT_UNIT)
    .filter(({ payload }) => payload)
    .mapTo(
      dictionariesRequest(dictionaryIds.STATES, dictionaryIds.PHONE_COUNTRIES),
    );

const saveProfile$ = (action$, state$) =>
  action$.ofType(SAVE_PROFILE_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const personGuid = personGuidSel(state);
    const userId = userIdSel(state);
    const { imageFile } = payload;
    const imgType = imageFile.type;

    if (SHOW_AKELA_PROFILE) {
      if (
        payload.dateJoinedBoyScouts ||
        payload.dateJoinedVenturing ||
        payload.dateJoinedSeaScouts ||
        payload.dateJoinedBoyScouts == '' ||
        payload.dateJoinedVenturing == '' ||
        payload.dateJoinedSeaScouts == ''
      ) {
        return services
          .saveProfileJoinedDate$({ payload, userId })
          .mapTo(userDataRequest())
          .catchAndReport(err => Observable.of(saveProfileError(err)));
      } else {
        // SBL-3868

        return forkJoin([
          services.getPreSignedUrl$(userId, imgType).pipe(
            switchMap(response =>
              services.imageUploadS3$(userId, imgType, payload, response),
            ),
            tap(() =>
              toastService.info(
                intl.formatMessage({
                  id: 'profile.ProfileInfo.save.profileSaveDisclaimer',
                }),
              ),
            ),
            catchAndReport(err => Observable.of(saveProfileError(err))),
          ),
        ]).pipe(
          mergeMap(() => Observable.concat(of(userDataRequest()))),
          catchAndReport(err => Observable.of(saveProfileError(err))),
        );
      }
    } else {
      const phone = formatProfilePhone(payload.phone);
      const profile = {
        nickName: payload.nickName,
        addresses: [formatProfileAddress(payload.address)],
        phones: phone ? [phone] : [],
        emails: [formatProfileEmail(payload.email)],
      };

      const currentHobby = hobbiesSel(state);
      const hobbyId = currentHobby ? currentHobby.extendedProfileId : '';

      return services
        .saveProfile$({ ...payload, personGuid, hobbyId, profile })
        .mapTo(userDataRequest())
        .catchAndReport(err => Observable.of(saveProfileError(err)));
    }
  });

const saveProfileBio$ = action$ =>
  action$.ofType(SAVE_PROFILE_BIO_REQUEST).switchMap(({ payload }) => {
    const { profile, personGuid } = payload;

    return services
      .saveProfile$({ personGuid, profile })
      .mapTo(userDataRequest())
      .pipe(
        tap(() =>
          toastService.success(
            intl.formatMessage({
              id: 'shared.Bio.update.success',
            }),
          ),
        ),
      )
      .catchAndReport(err => Observable.of(saveProfileError(err)));
  });

const deleteProfileBio$ = action$ =>
  action$.ofType(DELETE_PROFILE_BIO_REQUEST).switchMap(({ payload }) => {
    const { bio, personGuid } = payload;
    return services
      .deleteProfileItem$(bio, personGuid)
      .mapTo(userDataRequest())
      .pipe(
        tap(() =>
          toastService.success(
            intl.formatMessage({
              id: 'shared.Bio.delete.success',
            }),
          ),
        ),
        catchAndReport(err => Observable.of(saveProfileError(err))),
      );
  });

const saveProfileSibscriptions = (subs = [], personGuid) => {
  if (!subs.length) {
    return [of({})];
  }

  return subs.map(sub =>
    sub.id
      ? services.updateUsersubscriptions$(sub, personGuid)
      : services.createUsersubscriptions$(sub, personGuid),
  );
};

const saveAkelaProfile$ = (action$, state$) =>
  action$.ofType(SAVE_AKELA_PROFILE_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const personGuid = personGuidSel(state);
    const userId = userIdSel(state);
    const { akelaProfile, sbProfile } = payload;

    return forkJoin([
      services.updateSBProfile$(sbProfile, userId),
      services.updateProfile$(akelaProfile, personGuid),
      ...saveProfileSibscriptions(akelaProfile.subs, personGuid),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'profile.ProfileInfo.save.success',
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(saveAkelaProfileResponse()),
          of(userDataRequest()),
        ),
      ),
      catchAndReport(err => Observable.of(saveAkelaProfileError(err))),
    );
  });

const deleteAkelaEmail$ = (action$, state$) =>
  action$.ofType(DELETE_AKELA_EMAIL_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const personGuid = personGuidSel(state);
    const userId = userIdSel(state);
    const { sbPhones = {} } = payload;
    const deleteSBPhone =
      sbPhones.homePhone === '' ||
      sbPhones.mobilePhone === '' ||
      sbPhones.workPhone === '';

    return forkJoin([
      services.deleteProfileItem$(payload, personGuid),
      deleteSBPhone ? services.updateSBProfile$(sbPhones, userId) : of({}),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: `shared.contactInfo.delete${payload.type}.success`,
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(userDataRequest()),
          of(setShowDeleteEmailModal(false)),
          of(setShowDeleteAddressModal(false)),
          of(setShowDeletePhoneModal(false)),
          of(setIsLoadingDeleteEmailModal(false)),
        ),
      ),
      catchAndReport(err =>
        Observable.concat(
          of(deleteAkelaEmailError(err)),
          of(setShowDeleteEmailModal(false)),
          of(setShowDeleteAddressModal(false)),
          of(setShowDeletePhoneModal(false)),
          of(setIsLoadingDeleteEmailModal(false)),
        ),
      ),
    );
  });

const loadProfileResponseEpic$ = action$ =>
  action$.ofType(SAVE_PROFILE_REQUEST).switchMap(() =>
    action$
      .ofType(USER_DATA_RESPONSE)
      .take(1)
      .mapTo(saveProfileResponse())
      .takeUntil(action$.ofType(USER_DATA_ERROR))
      .do(() =>
        toastService.success(
          intl.formatMessage({
            id: 'profile.ProfileInfo.save.success',
          }),
        ),
      ),
  );

const refetchUnitInfo$ = (action$, state$) =>
  action$
    .ofType(SET_ORGANIZATION)
    .filter(() => typeSel(state$.value) === ROUTE_PROFILE)
    .mapTo(unitInfoRequest());

const saveUnit$ = action$ =>
  action$
    .ofType(SAVE_UNIT_REQUEST)
    .switchMap(() => services.saveUnit$())
    .mapTo(saveUnitResponse());

export default combineEpics(
  fetchFormDicts$,
  saveProfile$,
  saveAkelaProfile$,
  saveProfileBio$,
  deleteProfileBio$,
  deleteAkelaEmail$,
  loadProfileResponseEpic$,
  refetchUnitInfo$,
  saveUnit$,
);
