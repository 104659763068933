import keyBy from 'lodash/keyBy';

import * as configVars from '@config/config';

import {
  LSTORAGE_KEY,
  OMIT_REGEX,
  OMIT_SET,
  STATIC_FLAGS,
} from '../registered';
import type { FeatureFlag } from '../types';

type ConfigKey = keyof typeof configVars;

const VALID_CONFIGS = Object.fromEntries(
  Object.keys(configVars)
    .filter(key => {
      // eslint-disable-next-line import/namespace
      const value = configVars[key as ConfigKey];

      const isValidType =
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean';

      if (!isValidType) return false;

      const notAllUpperCase = String(key).toUpperCase() === key;

      if (!notAllUpperCase) return false;

      if (OMIT_SET.has(key)) return false;

      const ignore = OMIT_REGEX.some(reg => reg.test(key));
      if (ignore) return false;

      return true;
    })
    // eslint-disable-next-line arrow-body-style
    .map(key => {
      // eslint-disable-next-line import/namespace
      return [key, configVars[key as ConfigKey]];
    }),
);

class FeatureFlagsManager<T, K extends keyof T> {
  private _overrides: FeatureFlag[];
  private registeredFlags: Record<K, FeatureFlag>;
  private _storageKey = LSTORAGE_KEY;

  constructor(registeredFlags: Record<K, Pick<FeatureFlag, 'description'>>) {
    const toRegister = Object.keys(registeredFlags).map(key => {
      const entry = registeredFlags[key as K] as FeatureFlag;
      const defaultValue =
        // eslint-disable-next-line import/namespace
        configVars[key as unknown as ConfigKey];
      return [key, { ...entry, name: key, defaultValue }];
    });

    this.registeredFlags = Object.fromEntries(toRegister);
  }

  isOverriding(): boolean {
    const flags = this._overrides;

    return flags && flags.length !== 0;
  }

  saveInLocalStorage(overrides: Record<string, boolean | string>) {
    try {
      const store = window.localStorage;
      const flags = Object.entries(overrides).map(entry => {
        const [key, value] = entry;
        return { name: key, value: value, setOn: new Date() };
      });
      store.setItem(this._storageKey, JSON.stringify(flags));
    } catch {
      // contineu
    }
  }

  getFlag(name: K): FeatureFlag['value'] {
    const flag = this.getFlags().find(flag => flag.name === name);
    const emptyFlag: FeatureFlag = {
      name: name as string,
      value: false,
      defaultValue: false,
      override: false,
      description: `Feature flag ${name as string} not found`,
    };
    return (flag || emptyFlag).value;
  }

  getFlags(): FeatureFlag[] {
    const registeredByName = keyBy(this.registeredFlags, 'name');
    const overridesByName = keyBy(this._overrides, 'name');

    const flags = Object.keys(registeredByName).map(key => {
      const flag = registeredByName[key];
      const value =
        key in overridesByName ? overridesByName[key].value : flag.defaultValue;
      return {
        ...flag,
        override: key in overridesByName,
        value,
      } as FeatureFlag;
    });
    return flags || [];
  }

  getUnregisteredFlags(): FeatureFlag[] {
    const filtered = Object.keys(VALID_CONFIGS).filter(key => {
      if (this.registeredFlags[key as K]) return false;

      return true;
    });

    const flags: FeatureFlag[] = filtered.map(featureName => {
      // eslint-disable-next-line import/namespace
      const value = configVars[featureName as ConfigKey] as string;
      return {
        name: featureName,
        value,
        defaultValue: value,
        description: '(no description)',
      };
    });

    return flags;
  }

  resetOverrides() {
    const store = window?.localStorage;
    store?.removeItem(this._storageKey);
  }

  loadFlags() {
    const store = window?.localStorage;

    try {
      const data = store?.getItem(this._storageKey) || '[]';
      this._overrides = JSON.parse(data);

      const flags = this.getFlags();
      return flags;
    } catch {
      return [];
    }
  }
}

export const featureFlags = new FeatureFlagsManager(STATIC_FLAGS);
