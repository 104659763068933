import moment from 'moment';
import 'rxjs/add/operator/map';

import { advancementStatusDate, dtoDateFormat, gtm } from '@shared';
import { personNameBuilder, webscriptReportPageHelper } from '@utils';
import esbApiService from '@utils/http/esbApiService';
import webscriptApiService from '@utils/http/webscriptApiService';

const getUsersGuids = users => users.map(({ personGuid }) => personGuid);

const denormalizeAdvancement = (advancement, advancementType) => {
  const { id, members } = advancement;
  return members.map((youth, i) => ({
    key: `${advancementType}.${id}.${youth.userId}.${i}`,
    ...advancement,
    advancementId: id,
    advancementType: `${advancementType}s`,
    members: undefined,
    youth: {
      ...youth,
      dateCompletedEarned:
        advancementStatusDate.getCompleted(youth) ||
        advancementStatusDate.getApproved(youth),
      shortName: personNameBuilder.short(youth),
    },
  }));
};

const getItems = (advancement, advancementType) => {
  if (Array.isArray(advancement)) {
    return advancement.reduce(
      (acc, checkAdvancement) => [
        ...acc,
        ...getItems(checkAdvancement, advancementType),
      ],
      [],
    );
  } else {
    return denormalizeAdvancement(advancement, advancementType);
  }
};

const mapAdvancementReportDtoToModel = report =>
  report.reduce(
    (acc, { advancementType, advancements }) => [
      ...acc,
      ...getItems(advancements, advancementType),
    ],
    [],
  );

/**
 * Error codes:
  -1 - Empty OrganizationGUID
  -2 - Failed to load unit data from SB
  -3 - SB unit record not found by Akela Unit UD
  -4 - Failed to load unit data from Akela
  -5 - Failed to load user list
  -8 - Failed to load rank data
  -9 - Failed to load adventure data
  -10 - Failed to load award data
  -11 - Failed to load activity data
  -12 - Failed to load belt data
 */
export const openAdvancementSummaryReportWindow = async ({
  organizationGuid,
  startDate,
  endDate,
  users,
}) => {
  const reportContentPromise = webscriptApiService.post(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLUnitAdvancementSummary&ScriptCode=ReportPresentationByUserList&OrganizationGUID=${organizationGuid}`,
    {
      PersonGUIDList: getUsersGuids(users),
      StartDate: startDate,
      FinishDate: endDate,
    },
    {
      responseType: 'text',
      gtm: {
        label:
          '/WebScript/SBLUnitAdvancementSummary&OrganizationGUID={organizationGuid}',
      },
    },
  );

  webscriptReportPageHelper.openReportPage(reportContentPromise);

  gtm.pushEvent({
    event: 'UNIT_ADVANCEMENT_SUMMARY_REPORT',
    category: gtm.categories.REPORTS,
  });
};

export const openActivitiesSummaryReportWindow = async ({
  organizationGuid,
  startDate,
  endDate,
  users,
}) => {
  const reportContentPromise = webscriptApiService.post(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLActivitiesSummaryReport&ScriptCode=ReportPresentation&OrganizationGUID=${organizationGuid}`,
    {
      PersonGUIDList: getUsersGuids(users),
      StartDate: startDate,
      FinishDate: endDate,
    },
    {
      responseType: 'text',
      gtm: {
        label:
          '/WebScript/SBLActivitiesSummaryReport&OrganizationGUID={organizationGuid}',
      },
    },
  );

  webscriptReportPageHelper.openReportPage(reportContentPromise);

  gtm.pushEvent({
    event: 'UNIT_ACTIVITIES_SUMMARY_REPORT',
    category: gtm.categories.REPORTS,
  });
};

/**
 * Error codes:
  -1 - Empty OrganizationGUID
  -2 - Failed to load unit data (from AKELA)
  -3 - Failed to load unit data (from SB)
  -4 - Unit not found
  -5 - SB unit record not found by AkelaUnitID
  -6 - Failed to load user list
  -7 - Failed to generate adult user list
  -8 - Failed to generate youth user list
 */
export const openRosterReportWindow = async ({
  organizationGuid,
  programId,
  users,
}) => {
  const reportContentPromise = webscriptApiService.post(
    `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLUnitRosterReport&ScriptCode=ReportPresentation&OrganizationGUID=${organizationGuid}&ProgramID=${programId}`,
    {
      PersonGUIDList: getUsersGuids(users),
    },
    {
      responseType: 'text',
      gtm: {
        label:
          '/WebScript/SBLUnitRosterReport&OrganizationGUID={organizationGuid}',
      },
    },
  );

  webscriptReportPageHelper.openReportPage(reportContentPromise);

  gtm.pushEvent({
    event: 'UNIT_ROSTER_REPORT',
    category: gtm.categories.REPORTS,
  });
};

const getAdvancementReport$ = organizationGuid =>
  esbApiService
    .post$(
      `/organizations/${organizationGuid}/advancementsReadyToBeAwarded?orderBy=advancement&advancementType=all`,
      [],
      {
        gtm: {
          label:
            '/organizations/{organizationGuid}/advancementsReadyToBeAwarded',
        },
        swCache: true,
      },
    )
    .map(mapAdvancementReportDtoToModel);

const mapApprovedAdvancementsModelToDto = (advancements, loggedInUserId) => {
  const today = moment().format(dtoDateFormat);

  return advancements.map(({ advancementType, advancementId, youth }) => {
    const { userId, userAwardId, leaderApprovedDate, leaderApprovedUserId } =
      youth;
    const dto = {
      userId,
      userAwardId,
      advancementType: advancementType.slice(0, -1),
      advancementId,
      leaderApprovedDate: leaderApprovedDate || today,
      leaderApprovedUserId: leaderApprovedUserId || loggedInUserId,
    };

    return dto;
  });
};

const setAdvancementsToLeaderApproved = ({
  advancements,
  organizationGuid,
  loggedInUserId,
}) => {
  const body = {
    leaderApprovedAdvancements: mapApprovedAdvancementsModelToDto(
      advancements,
      loggedInUserId,
    ),
    organizationGuid,
  };

  return esbApiService.post('/advancements/setToLeaderApproved', body, {
    gtm: {
      label: '/advancements/setToLeaderApproved',
    },
  });
};

/**
 * Error codes:
  -1 - Empty OrganizationGUID
  -2 - Empty POID
  -3 - Failed to load unit data (from AKELA)
  -4 - Unit not found
  -5 - SB unit record not found by AkelaUnitID
  -6 - Failed to load PO data
  -7 - Purchase order is open
  -8 - Failed to load unit data (from SB)
  -9 - Failed to load advancement data
  -11 - Failed to parse JSON data.
 */
export const openAdvancementReport = ({
  organizationGuid,
  poid,
  showPrice,
}) => {
  const getContentPromise = async () => {
    const resolvedPOID = await poid;
    let url = `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLAdvancementReport&ScriptCode=ReportPresentation&OrganizationGUID=${organizationGuid}&POID=${resolvedPOID}`;
    if (showPrice) {
      url += '&Option=@Price';
    }

    return webscriptApiService.get(url, {
      responseType: 'text',
      gtm: {
        label:
          '/WebScript/SBLAdvancementReport&OrganizationGUID={organizationGuid}&POID={POID}',
      },
    });
  };

  webscriptReportPageHelper.openReportPage(getContentPromise());
};

export const generateAdvancementReport = ({
  advancements,
  organizationGuid,
  showPrice,
  loggedInUserId,
}) => {
  const getPoidPromise = async () => {
    const res = await setAdvancementsToLeaderApproved({
      advancements,
      organizationGuid,
      loggedInUserId,
    });
    let poid;
    if (Array.isArray(res)) {
      poid = (res.find(({ poid }) => poid) || {}).poid;
    } else {
      poid = res.poid;
    }

    if (!poid) {
      throw new Error('No POID found in the response');
    }

    return poid;
  };
  const poidPromise = getPoidPromise();

  openAdvancementReport({
    organizationGuid,
    poid: poidPromise,
    showPrice,
  });

  return poidPromise;
};

export default {
  getAdvancementReport$,
};
