/* eslint-disable react/no-multi-comp */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import {
  intl,
  TableHeadingSort,
  ProgramLogo,
  UnitType,
  Button,
  UnitGenderPill,
} from '@shared';
import { tableSortOrder } from '@utils';
import { expireDateFormat } from '../../../constants';

const columns = ({ sorter, hasSearchedItems, onRemoveItem }) =>
  [].concat(
    [
      {
        key: 'programLogo',
        dataIndex: 'unitTypeId',
        width: '7rem',
        render(unitTypeId) {
          return <ProgramLogo programId={unitTypeId} />;
        },
      },
      {
        key: 'unitType',
        width: '16rem',
        sorter: true,
        sortOrder: tableSortOrder(sorter, 'unitType'),
        title: (
          <TableHeadingSort name="unitType" sorter={sorter}>
            <FormattedMessage id="councilUnits.CouncilUnitsPage.column.type" />
          </TableHeadingSort>
        ),
        // eslint-disable-next-line react/prop-types
        render({ unitTypeId, unitGender }) {
          return (
            <div>
              <UnitType programId={unitTypeId} />{' '}
              <UnitGenderPill gender={unitGender} />
            </div>
          );
        },
      },
      {
        key: 'unitNumber',
        dataIndex: 'unitNumber',
        width: '10rem',
        sorter: true,
        sortOrder: tableSortOrder(sorter, 'unitNumber'),
        title: (
          <TableHeadingSort name="unitNumber" sorter={sorter}>
            <FormattedMessage id="councilUnits.CouncilUnitsPage.column.unitNumber" />
          </TableHeadingSort>
        ),
      },
      {
        key: 'charteredOrganizationName',
        dataIndex: 'charteredOrganizationName',
        width: '25rem',
        sorter: true,
        sortOrder: tableSortOrder(sorter, 'charteredOrganizationName'),
        title: (
          <TableHeadingSort name="charteredOrganizationName" sorter={sorter}>
            <FormattedMessage id="councilUnits.CouncilUnitsPage.column.charteredOrg" />
          </TableHeadingSort>
        ),
      },
      {
        dataIndex: 'districtName',
        width: '20rem',
        title: intl.formatMessage({
          id: 'councilUnits.CouncilUnitsPage.column.district',
        }),
      },
      {
        dataIndex: 'expireDate',
        title: intl.formatMessage({
          id: 'councilUnits.CouncilUnitsPage.column.expireDate',
        }),
        render(expireDate) {
          return moment(expireDate).format(expireDateFormat);
        },
      },
    ],
    hasSearchedItems
      ? [
          {
            key: 'searchDate',
            dataIndex: 'searchDate',
            sorter: true,
            sortOrder: tableSortOrder(sorter, 'searchDate'),
            title: (
              <TableHeadingSort name="searchDate" sorter={sorter}>
                <FormattedMessage id="councilUnits.CouncilUnitsPage.column.searchDate" />
              </TableHeadingSort>
            ),
            render: searchDate => moment(searchDate).format(expireDateFormat),
          },
          {
            key: 'removeItem',
            dataIndex: 'organizationGuid',
            render(organizationGuid) {
              return (
                <Button
                  ghost
                  noBorder
                  fitText
                  onClick={e => {
                    e.stopPropagation();
                    onRemoveItem(organizationGuid);
                  }}
                >
                  <FormattedMessage id="councilUnits.CouncilUnitsPage.remove" />
                </Button>
              );
            },
          },
        ]
      : [],
  );

export default columns;
