import React, { useCallback } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { SELECTABLE_PEOPLE_LIMIT } from '../../../constants';
import styles from './PackRosterTable.less';

const CustomSelectionTitle = React.memo(function CustomSelectionTitle({
  selectedItemsAmount,
  itemsAmount,
  onSelectAll,
  onDeselectAll,
}) {
  let checkboxClassName = '';
  if (
    selectedItemsAmount === itemsAmount ||
    selectedItemsAmount === SELECTABLE_PEOPLE_LIMIT
  ) {
    checkboxClassName = 'ant-checkbox-checked';
  }

  const classNames = cn(
    'ant-checkbox',
    checkboxClassName,
    styles.checkboxContainer,
  );

  const onClick = useCallback(() => {
    if (selectedItemsAmount === 0) {
      onSelectAll();
    } else {
      onDeselectAll();
    }
  }, [onSelectAll, onDeselectAll, selectedItemsAmount]);

  return (
    <span id="qa_packRosterSelect" className={classNames} onClick={onClick}>
      <input type="checkbox" className="ant-checkbox-input" value="" />
      <span className="ant-checkbox-inner" />
    </span>
  );
});

CustomSelectionTitle.propTypes = {
  selectedItemsAmount: PropTypes.number.isRequired,
  itemsAmount: PropTypes.number.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired,
};

export default CustomSelectionTitle;
