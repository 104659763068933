import PropTypes from 'prop-types';

export { serviceModuleName as moduleName, detailModes } from '../common';

export const datesShape = PropTypes.shape({
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

export const detailFieldNames = [
  'name',
  'startDate',
  'startTime',
  'endTime',
  'repeatsEvery',
  'location',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'zip5',
  'categoryId',
  'collaborativeOrganizations',
  'othercollaborativeOrganizations',
  'description',
];
