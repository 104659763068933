import _, { isEmpty } from 'lodash';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import { permissions } from '@modules/user';
import { whitelistedPositionsRecordProgress } from '@modules/user/constants';
import { ProgramId } from '@shared/constants';
import { hasPermissionSel, unitTypeIdSel } from '@shared/duck';

import { selectedItemsSel } from '../../../advancement/common/selectors';
import {
  itemsPerSubUnitSel,
  itemsSel,
} from '../../../advancement/packRoster/duck/selectors';
import { userIdSel } from '../../../user/duck/selectors';

const currentUserPositions = createSelector(
  userIdSel,
  itemsSel,
  (currentUserId, rosterItems) => {
    const userItem = rosterItems.find(item => item.userId === currentUserId);
    return get(userItem, 'positions', []);
  },
);

export function hasPermissionsToRecordProgress({
  hasPermission,
  userPositions,
  selectedItems,
  unitType,
}) {
  // den or patrols
  const isSubUnitApplicable =
    unitType === ProgramId.CUB_SCOUT || unitType === ProgramId.BOY_SCOUT;

  if (!isSubUnitApplicable) return hasPermission;

  if (isEmpty(userPositions)) return false;

  const positionNames = userPositions.map(item => item.position);

  if (
    intersection(whitelistedPositionsRecordProgress, positionNames).length > 0
  ) {
    return true;
  }

  // set of den ids
  const densWithAdminPositions = new Set(
    _.chain(userPositions)
      .filter(pos => pos.denId && pos.position === 'Den Admin')
      .map(pos => pos.denId)
      .value(),
  );
  const patrolsWithAdminPositions = new Set(
    _.chain(userPositions)
      .filter(pos => pos.patrolId && pos.position === 'Patrol Admin')
      .map(pos => pos.patrolId)
      .value(),
  );

  if (unitType === ProgramId.CUB_SCOUT) {
    return selectedItems.every(item =>
      item.positions?.find(pos => densWithAdminPositions.has(pos.denId)),
    );
  } else {
    return selectedItems.every(item =>
      item.positions?.find(pos => patrolsWithAdminPositions.has(pos.patrolId)),
    );
  }
}

export const canRecordProgressForSelectedItemsSel = createSelector(
  state => hasPermissionSel(state, [permissions.APPROVE_ADVANCEMENTS]),
  currentUserPositions,
  selectedItemsSel,
  itemsPerSubUnitSel,
  unitTypeIdSel,
  (hasPermission, userPositions, selectedItems, itemsPerSubUnits, unitType) =>
    hasPermissionsToRecordProgress({
      userPositions,
      selectedItems,
      hasPermission,
      itemsPerSubUnits,
      unitType,
    }),
);
