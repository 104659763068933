import { dictionaryIds, moduleName } from '../constants';

export const DICTIONARIES_REQUEST = `${moduleName}/DICTIONARIES_REQUEST`;
export const DICTIONARIES_RESPONSE = `${moduleName}/DICTIONARIES_RESPONSE`;
export const DICTIONARIES_ERROR = `${moduleName}/DICTIONARIES_ERROR`;

export const SCHOOL_DICTIONARIES_REQUEST = `${moduleName}/SCHOOL_DICTIONARIES_REQUEST`;

export const dictionariesRequest = (...IDS) => ({
  type: DICTIONARIES_REQUEST,
  payload: IDS,
});
export const dictionariesAdvancementsRequest = () => ({
  type: DICTIONARIES_REQUEST,
  payload: [
    dictionaryIds.AWARDS,
    dictionaryIds.ADVENTURES,
    dictionaryIds.RANKS,
    dictionaryIds.MERIT_BADGES,
    dictionaryIds.SSELECTIVES,
  ],
});
export const dictionariesResponse = dictionaries => ({
  type: DICTIONARIES_RESPONSE,
  payload: dictionaries,
});

export const dictionariesError = (error, ids) => ({
  type: DICTIONARIES_ERROR,
  payload: { error, ids },
});

export const schoolDictionariesRequest = payload => ({
  type: SCHOOL_DICTIONARIES_REQUEST,
  payload: payload,
});
