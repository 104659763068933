import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from '@shared';

export default class CustomCheckbox extends React.Component {
  render() {
    const { setFieldsValue, onChange } = this.props;

    return (
      <Checkbox
        {...this.props}
        onChange={e => {
          setFieldsValue({ startTime: undefined, endTime: undefined });
          onChange(e);
        }}
      >
        <FormattedMessage id="progress.Form.field.allDayEvent.label" />
      </Checkbox>
    );
  }
}

CustomCheckbox.propTypes = {
  setFieldsValue: PropTypes.func.isRequired,
  // injected by getFieldDecorator
  onChange: PropTypes.func,
};
