import React from 'react';
import PropTypes from 'prop-types';

import Select from '../Select';
import { intl } from '../../localization';

const { Option } = Select;

class StateSelect extends React.PureComponent {
  renderOptions(states) {
    return states.map(({ name, id }) => (
      <Option key={id} value={id} text={name}>
        {name}
      </Option>
    ));
  }

  render() {
    const { statesLoaded, states, ...rest } = this.props;

    return (
      <Select
        placeholder={intl.formatMessage({
          id: 'shared.address.state.placeholder',
        })}
        disabled={!statesLoaded}
        optionFilterProp="text"
        showSearch
        {...rest}
      >
        {this.renderOptions(states)}
      </Select>
    );
  }
}

StateSelect.propTypes = {
  states: PropTypes.array.isRequired,
  statesLoaded: PropTypes.bool.isRequired,
};

StateSelect.defaultProps = {
  states: [],
};

export default StateSelect;
