import moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { advancementTypes, dtoDateFormat, syncOperations } from '@shared';
import { esbApiService } from '@utils';

import {
  activityValueTypesNameToId,
  activityValueTypesToName,
  addPersonDetails$,
  editPersonDetails$,
} from '../../common';

const { ADVENTURES, AWARDS, MERIT_BADGES, RANKS } = advancementTypes;

const formatDate = date => moment(date).format(dtoDateFormat);

const serializeFactory =
  ({
    organizationGuid,
    userId,
    advancementType,
    advancementId,
    userAwardId,
    date,
  }) =>
  () => ({
    operation: syncOperations.EDIT_ADVANCEMENT,
    organizationGuid,
    userId,
    advancementType,
    advancementId,
    userAwardId,
    date,
  });

const updateAwardDate$ = ({
  organizationGuid,
  userId,
  advancementType,
  advancementId,
  userAwardId,
  date,
}) =>
  esbApiService.putOrDefer$(
    `/advancements/youth/${userId}/awards/${advancementId}/${userAwardId}`,
    { organizationGuid, dateEarned: formatDate(date) },
    {
      gtm: {
        label: '/advancements/youth/{userId}/awards/{awardId}/{userAwardId}',
      },
      serialize: serializeFactory({
        organizationGuid,
        userId,
        advancementType,
        advancementId,
        userAwardId,
        date,
      }),
    },
  );

const updateAdventureDate$ = ({
  organizationGuid,
  userId,
  advancementType,
  advancementId,
  date,
}) =>
  esbApiService.putOrDefer$(
    `/advancements/youth/${userId}/adventures/${advancementId}`,
    { organizationGuid, dateCompleted: formatDate(date) },
    {
      gtm: {
        label: '/advancements/youth/{userId}/adventures/{adventureId}',
      },
      serialize: serializeFactory({
        organizationGuid,
        userId,
        advancementType,
        advancementId,
        date,
      }),
    },
  );

const updateMeritBadgeDate$ = ({
  organizationGuid,
  userId,
  advancementType,
  advancementId,
  date,
}) =>
  esbApiService.putOrDefer$(
    `/advancements/youth/${userId}/meritBadges/${advancementId}`,
    { organizationGuid, dateCompleted: formatDate(date) },
    {
      gtm: {
        label: '/advancements/youth/{userId}/meritBadges/{meritBadgeId}',
      },
      serialize: serializeFactory({
        organizationGuid,
        userId,
        advancementType,
        advancementId,
        date,
      }),
    },
  );

const updateRankDate$ = ({
  organizationGuid,
  userId,
  advancementType,
  advancementId,
  date,
}) =>
  esbApiService.putOrDefer$(
    `/advancements/youth/${userId}/ranks/${advancementId}`,
    {
      organizationGuid,
      dateEarned: formatDate(date),
    },
    {
      gtm: {
        label: '/advancements/youth/{userId}/ranks/{rankId}',
      },
      serialize: serializeFactory({
        organizationGuid,
        userId,
        advancementType,
        advancementId,
        date,
      }),
    },
  );

const updateAdvancementDate$ = params => {
  switch (params.advancementType) {
    case ADVENTURES:
      return updateAdventureDate$(params);
    case AWARDS:
      return updateAwardDate$(params);
    case MERIT_BADGES:
      return updateMeritBadgeDate$(params);
    case RANKS:
      return updateRankDate$(params);
    default:
      throw new Error(
        `Unsupported advancement type: ${params.advancementType}`,
      );
  }
};

const serializeAdvSummaryFactory =
  ({ userId, newAdvancement, advancementType }) =>
  () => ({
    operation: syncOperations.EDIT_ADVANCEMENT,
    userId,
    newAdvancement,
    advancementType,
  });

/**
 * @esbEndpoint PUT /advancements/youth/:userId/:advancementType/:advancementId
 */
const updateAdvSummary$ = ({
  userId,
  newAdvancement,
  advancementType,
  advancementId,
}) =>
  esbApiService.putOrDefer$(
    `/advancements/youth/${userId}/${advancementType}/${advancementId}`,
    {
      ...newAdvancement,
    },
    {
      gtm: {
        label: '/advancements/youth/{userId}/{advancementType}/{advancementId}',
      },
      serialize: serializeAdvSummaryFactory({
        userId,
        newAdvancement,
        advancementType,
      }),
    },
  );

const getUnapproveAdvancementType = type => {
  switch (type) {
    case advancementTypes.AWARDS: {
      return 'Award';
    }
    case advancementTypes.ADVENTURES: {
      return 'Adventure';
    }
    case advancementTypes.RANKS: {
      return 'Rank';
    }
    case advancementTypes.MERIT_BADGES: {
      return 'Merit Badge';
    }
    default: {
      throw new Error(`Advancement Type ${type} not valid`);
    }
  }
};

const unapproveAdvancementSerialize =
  ({ organizationGuid, userId, advancementType, advancementId, userAwardId }) =>
  () => ({
    operation: syncOperations.UNAPPROVE_ADVANCEMENT,
    organizationGuid,
    userId,
    advancementType,
    advancementId,
    userAwardId,
  });

const unapproveAdvancement$ = ({
  userId,
  organizationGuid,
  advancementType,
  advancementId,
  userAwardId,
  newStatus,
}) =>
  esbApiService.postOrDefer$(
    '/advancements/unapprove',
    [
      {
        userId: Number(userId),
        organizationGuid,
        advancementId: Number(advancementId),
        userAwardId: userAwardId ? userAwardId : null,
        advancementType: getUnapproveAdvancementType(advancementType),
        newStatus: newStatus ? newStatus : 'Not Complete',
      },
    ],
    {
      gtm: {
        label: '/advancements/unapprove',
      },
      serialize: unapproveAdvancementSerialize({
        organizationGuid,
        userId,
        advancementType,
        advancementId,
        userAwardId,
      }),
    },
  );

const recordNewActivityRecord$ = ({
  activityValueTypeId,
  activityValue,
  personActivity,
}) => {
  const {
    activityId,
    leaderApprovedUserId,
    leaderApprovedDate,
    memberId,
    userId,
    personGuid,
    organizationGuid,
  } = personActivity;

  const personDetails = {
    organizationGuid,
    userId,
    memberId,
    personGuid,
    isAdult: false,
    activityValueTypeId,
    activityValue,
    isApproved: true,
    leaderApprovedId: leaderApprovedUserId,
    leaderApprovedDate,
  };

  return addPersonDetails$({ activityId, personDetails }).pipe(
    map(res => ({ ...personDetails, id: res.id })),
  );
};

const updateActivity$ = (personActivity, updatedValues) => {
  const {
    activityId,
    activityRecords,
    organizationGuid,
    userId,
    isApproved,
    leaderApprovedId,
    leaderApprovedDate,
  } = personActivity;
  const updateRequest = [];
  const existingActivities = [];

  Object.keys(updatedValues).forEach(valueType => {
    const activityValue = Number(
      parseFloat(updatedValues[valueType]).toFixed(2),
    );

    const previousRecord = activityRecords.find(
      ({ activityValueTypeId }) =>
        activityValueTypesToName[activityValueTypeId] === valueType,
    );

    if (previousRecord) {
      if (previousRecord.activityValue === activityValue) {
        updateRequest.push(of(previousRecord));
        return;
      }

      const personDetails = {
        ...previousRecord,
        id: +previousRecord.id,
        organizationGuid,
        activityValue,
      };

      return existingActivities.push(personDetails);
    } else if (activityValue > 0) {
      const activityValueTypeId = activityValueTypesNameToId[valueType];
      return updateRequest.push(
        recordNewActivityRecord$({
          activityValueTypeId,
          activityValue,
          personActivity,
        }),
      );
    }
    return updateRequest.push(of(undefined));
  });

  if (existingActivities.length) {
    const activityDetails = [
      {
        userId: +userId,
        activityValues: existingActivities,
        note: 'N/A',
        isApproved: isApproved,
        isDeclined: false,
        leaderApprovedId: leaderApprovedId,
        leaderApprovedDate: leaderApprovedDate,
      },
    ];

    updateRequest.push(
      editPersonDetails$({
        activityId,
        personDetails: activityDetails,
      }),
    );
  }

  return forkJoin(updateRequest).pipe(map(values => values.filter(Boolean)));
};

export default {
  updateAdvancementDate$,
  updateAdvSummary$,
  updateRankDate$,
  unapproveAdvancement$,
  updateActivity$,
};
