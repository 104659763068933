import React, { useCallback, useMemo } from 'react';

import { capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Program } from '@modules/shared/constants';
import { simpleUnitFormat } from '@modules/utils';

import ProgramLogo from '../ProgramLogo/ProgramLogo';
import S from '../S';
import UnitGenderPill from '../UnitGenderPill';
import styles from './EventUnitLabel.less';
import { invertColor } from './utils';

type Unit = {
  unitId: string;
  denId: string;
  denType: string;
  denNumber: string;
  patrolId: string;
  patrolName: string;
  program: Program;
  unitFullName: string;
  organizationName: string;
  isParentGuardian: boolean;
  acceptGender: string;
  organizationGuid: string;
};

type BaseProps = {
  unit: Unit;
  userUnits: Unit[];
};

type WithBackgroundProps = BaseProps & {
  backgroundColor: string;
  mainColor: string;
};

type Props = BaseProps | WithBackgroundProps;

const EventUnitLabel: React.FC<Props> = ({ unit, userUnits, ...rest }) => {
  const { program, organizationName, isParentGuardian, acceptGender } = useMemo(
    () => simpleUnitFormat(unit, userUnits),
    [unit, userUnits],
  );

  const renderLabel = useCallback(
    (labelStyles?: React.CSSProperties) => (
      <div className={styles.titleRow}>
        <ProgramLogo program={program} />
        <S size="4" bold className={styles.unitName} style={labelStyles}>
          {capitalize(organizationName)}
        </S>
        {isParentGuardian ? (
          <div className={styles.parentPill}>
            <FormattedMessage id="calendar.parentGuardian" />
          </div>
        ) : (
          <UnitGenderPill colored gender={acceptGender} />
        )}
      </div>
    ),
    [acceptGender, isParentGuardian, organizationName, program],
  );

  if ('backgroundColor' in rest) {
    const { backgroundColor, mainColor } = rest;
    return (
      <div
        className={styles.background}
        style={{
          borderLeft: `8px solid ${mainColor}`,
          backgroundColor,
        }}
      >
        {renderLabel({ color: invertColor(backgroundColor) })}
      </div>
    );
  }

  return renderLabel();
};

export default EventUnitLabel;
