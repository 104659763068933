import React, { useCallback, useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Form, Input, Row, Select } from '@shared/components';
import { intl } from '@shared/localization';
import { validateForm } from '@utils';

import { memberTypeIds } from '../../../../constants';
import {
  filteredIniviteMemberTypesSel,
  inviteMembersRequest,
  isInviteMemberLoadingSel,
} from '../../../../duck';
import styles from './InviteMembersForm.less';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const InviteMembersForm = ({ form }) => {
  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;
  const { memberType } = getFieldsValue();
  const dispatch = useDispatch();
  const isLoading = useSelector(isInviteMemberLoadingSel);
  const filteredIniviteMemberTypes = useSelector(filteredIniviteMemberTypesSel);
  const requiredRule = useMemo(
    () => ({
      required: true,
      message: intl.formatMessage({
        id: 'shared.form.error.isRequired',
      }),
    }),
    [],
  );
  const isMemberTypeYouth = memberType === memberTypeIds.youth;

  useEffect(() => {
    setFieldsValue({
      firstName: undefined,
      pgFirstName: undefined,
      lastName: undefined,
      pgLastName: undefined,
    });
  }, [memberType, setFieldsValue]);

  const validate = useCallback(() => validateForm(form), [form]);

  const handleInviteMember = async () => {
    const inviteeInfo = await validate();

    if (inviteeInfo) {
      dispatch(inviteMembersRequest(inviteeInfo));
    }
  };

  return (
    <React.Fragment>
      <Row type="flex" justify="center">
        <Col xs={24} lg={13}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('memberType', { rules: [requiredRule] })(
              <Select
                className={styles.graySelect}
                showSearch
                filterOption
                placeholder={intl.formatMessage({
                  id: 'recharterModals.inviteMembersModal.memberType',
                })}
                size="large"
                optionFilterProp="text"
                rounded
                floatingLabel
              >
                <OptGroup
                  key="memberType"
                  label={
                    <FormattedMessage id="recharterModals.inviteMembersModal.memberType" />
                  }
                >
                  {filteredIniviteMemberTypes.map(({ id, label }, i) => (
                    <Option key={`type_${i}`} value={id} text={label}>
                      {label}
                    </Option>
                  ))}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col xs={24} lg={11}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('email', {
              rules: [
                requiredRule,
                {
                  type: 'email',
                  message: intl.formatMessage({
                    id: 'profile.ProfileInfo.contactInfo.fieldError.email.valid',
                  }),
                },
              ],
            })(
              <Input
                className={styles.grayInput}
                size="large"
                placeholder={intl.formatMessage({
                  id: isMemberTypeYouth
                    ? 'recharterModals.inviteMembersModal.pgEmail'
                    : 'recharterModals.inviteMembersModal.email',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
      </Row>
      {isMemberTypeYouth && (
        <Row type="flex" justify="center">
          <Col xs={24} lg={13}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('pgFirstName', {
                rules: [
                  {
                    required: isMemberTypeYouth,
                    message: intl.formatMessage({
                      id: 'shared.form.error.isRequired',
                    }),
                  },
                ],
              })(
                <Input
                  className={styles.grayInput}
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'recharterModals.inviteMembersModal.pgFirstName',
                  })}
                  rounded
                  floatingLabel
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={11}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('pgLastName', {
                rules: [
                  {
                    required: isMemberTypeYouth,
                    message: intl.formatMessage({
                      id: 'shared.form.error.isRequired',
                    }),
                  },
                ],
              })(
                <Input
                  className={styles.grayInput}
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'recharterModals.inviteMembersModal.pgLastName',
                  })}
                  rounded
                  floatingLabel
                />,
              )}
            </FormItem>
          </Col>
        </Row>
      )}
      <Row type="flex" justify="center">
        <Col xs={24} lg={13}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('firstName', { rules: [requiredRule] })(
              <Input
                className={styles.grayInput}
                size="large"
                placeholder={intl.formatMessage({
                  id: isMemberTypeYouth
                    ? 'recharterModals.inviteMembersModal.youthFirstName'
                    : 'recharterModals.inviteMembersModal.firstName',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
        <Col xs={24} lg={11}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('lastName', { rules: [requiredRule] })(
              <Input
                className={styles.grayInput}
                size="large"
                placeholder={intl.formatMessage({
                  id: isMemberTypeYouth
                    ? 'recharterModals.inviteMembersModal.youthLastName'
                    : 'recharterModals.inviteMembersModal.lastName',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Button
            loading={isLoading}
            className={styles.button}
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={false}
            onClick={handleInviteMember}
          >
            <FormattedMessage id="recharterModals.inviteMembersModal.invite" />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

InviteMembersForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create()(InviteMembersForm);
