import React from 'react';

import { EditActivityButton } from '../../../common';
import { openEditHike, loadAndOpenHikeModal } from '../../duck';

const EditHikeButton = props => (
  <EditActivityButton
    onLoadAction={loadAndOpenHikeModal}
    onOpenAction={openEditHike}
    {...props}
  />
);

export default EditHikeButton;
