import { activityTypeForActivityTypeId } from '@shared/constants';

export { calendarModuleName as moduleName } from '@shared/constants';

export const selectedActivityTypeFilters = Object.keys(
  activityTypeForActivityTypeId,
).reduce((acc, key) => ({ ...acc, [key]: true }), {});

export const PERSONAL_ACTIVITY = 'PERSONAL_ACTIVITY';

export const activityFilters = Object.freeze({
  ...selectedActivityTypeFilters,
});

export const calendarViews = {
  MONTH: 'month',
  WEEK: 'week',
  GRID: 'grid',
  LIST: 'list',
};

export const includesEventDescription =
  'This is an automated message from Scoutbook.com';
