import React, { useCallback, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { Avatar } from 'bsa-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { unitActivityTypesSel } from '@context';
import {
  CampoutsButton,
  RecordHikeMilesButton,
  RecordLongCruiseButton,
  ServiceHoursButton,
} from '@progress/components';
import { isMobileSel } from '@screen';
import {
  ActivityType,
  ActivityTypeIcon,
  AppMobileMenu,
  Button,
  Dropdown,
  Menu,
  Tag,
  activityTypes,
} from '@shared';

import styles from './AddActivityButton.less';

const MenuItem = Menu.Item;

const emptyFn = () => {};

const ActivitiesButtons = Object.freeze({
  [activityTypes.SERVICE_PROJECTS]: ServiceHoursButton,
  [activityTypes.CAMPOUTS]: CampoutsButton,
  [activityTypes.HIKES]: RecordHikeMilesButton,
  [activityTypes.LONG_CRUISE]: RecordLongCruiseButton,
});

const AddActivityButton = ({
  children,
  buttonLabel,
  onItemClick,
  disabledActivities,
  renderOnlyActivityMenu,
  isCalendar,
}) => {
  const isMobile = useSelector(isMobileSel);
  const [visible, setVisible] = useState(false);

  const unitActivityTypes = useSelector(unitActivityTypesSel);

  const handleCloseMenu = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleOnItemClick = useCallback(
    activityType => () => {
      onItemClick(activityType);
    },
    [onItemClick],
  );

  const activityItems = useMemo(() => {
    const activityItems = unitActivityTypes.map(activityType => {
      const CreateActivityButton = ActivitiesButtons[activityType];

      const activityIcon = (
        <Avatar
          onClick={onItemClick ? handleOnItemClick(activityType) : undefined}
          className={styles.activityIcon}
          size={45}
          disabled={disabledActivities[activityType]}
        >
          <ActivityTypeIcon activityType={activityType} />
        </Avatar>
      );

      if (isMobile) {
        return (
          <div key={activityType}>
            <Tag>{ActivityType.getTranslation(activityType)}</Tag>
            {onItemClick ? (
              activityIcon
            ) : (
              <CreateActivityButton
                onClick={handleCloseMenu}
                disabled={disabledActivities[activityType]}
                replaceButton={activityIcon}
              />
            )}
          </div>
        );
      }
      const button = (
        <Button
          onClick={onItemClick ? handleOnItemClick(activityType) : undefined}
          noBorder
          fitText
          ghost
          uppercase={false}
          className={styles.activityItemButton}
          disabled={disabledActivities[activityType]}
        >
          {activityIcon}
          <ActivityType activityType={activityType} isCalendar={isCalendar} />
        </Button>
      );
      return (
        <MenuItem key={activityType} onClick={emptyFn}>
          {onItemClick ? (
            button
          ) : (
            <CreateActivityButton
              disabled={disabledActivities[activityType]}
              onClick={handleCloseMenu}
              replaceButton={button}
            />
          )}
        </MenuItem>
      );
    });
    return (
      <React.Fragment>
        {children}
        {activityItems}
      </React.Fragment>
    );
  }, [
    isMobile,
    unitActivityTypes,
    handleCloseMenu,
    onItemClick,
    handleOnItemClick,
    children,
    disabledActivities,
    isCalendar,
  ]);

  const overlayMenu = (
    <Menu className={styles.dropDownMenu}>{activityItems}</Menu>
  );

  if (renderOnlyActivityMenu) {
    return overlayMenu;
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <AppMobileMenu
          button={
            <Avatar className={styles.toggleButton} size={55}>
              {visible ? <CloseIcon /> : <AddIcon />}
            </Avatar>
          }
          className={styles.mobileMenuContainer}
          open={visible}
          onExpandOrCollapse={setVisible}
          expandedContent={
            <div className={styles.mobileMenu}>{activityItems}</div>
          }
        />
      ) : (
        <Dropdown
          overlay={overlayMenu}
          trigger={['click']}
          placement="bottomRight"
          visible={visible}
          onVisibleChange={visible => setVisible(visible)}
        >
          <Button
            className={styles.addButton}
            shape="round"
            type="primary"
            shadow
            size="large"
            fitText
            uppercase={false}
          >
            <AddIcon /> {buttonLabel}
          </Button>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

AddActivityButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  buttonLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onItemClick: PropTypes.func,
  disabledActivities: PropTypes.object,
  renderOnlyActivityMenu: PropTypes.bool,
  isCalendar: PropTypes.bool,
};

AddActivityButton.defaultProps = {
  disabledActivities: {},
  isCalendar: false,
};

export default AddActivityButton;
