import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';

import {
  S,
  Form,
  Input,
  T,
  intl,
  activityTypeForActivityTypeId,
} from '@shared';

import {
  activityValueRule,
  fieldLessOrEqualRule,
  getCampingTimes,
} from '../../../utils';
import { basicConfig, personsShape } from '../../../constants';
import { useCalculateDays } from '../../../hooks';

import styles from './BasicTab.less';

const FormItem = Form.Item;

const BasicTab = ({
  form,
  showYouth,
  showAdults,
  youth: selectedYouths = [],
  adults: selectedAdults = [],
  isActive,
  showDescription,
  activityTypeId,
  setInitialTabValues,
}) => {
  const [isLoadedFormData, setIsLoadedFormData] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const basicInputs = basicConfig[activityTypeId];
  const activityType = activityTypeForActivityTypeId[activityTypeId];
  const mergePersons = !!basicInputs.merge;

  const { getFieldDecorator, getFieldsValue } = form;
  const dateFields = getFieldsValue();

  const diff = useCalculateDays(dateFields, form);
  const checkIfHasDiffDateProp = useCallback(
    inputsArray =>
      inputsArray.some(([, , , isDayDate]) => isDayDate !== undefined),
    [],
  );

  useLayoutEffect(() => {
    const configValues = mergePersons
      ? basicInputs.merge
      : [...basicInputs.youths, ...basicInputs.adults];
    let hasDiffDateValues = checkIfHasDiffDateProp(configValues);
    if (diff !== null && diff >= 0 && hasDiffDateValues) {
      const campingTimes = getCampingTimes(selectedYouths, selectedAdults);
      const fieldsToSet = configValues
        .filter(([, , , isDayDate]) => isDayDate !== undefined)
        .reduce(
          (acc, [inputKey, , , isDayDate]) => ({
            ...acc,
            [inputKey]:
              campingTimes[inputKey] !== undefined
                ? campingTimes[inputKey]
                : `${diff + (isDayDate ? 1 : 0) || 0}`,
          }),
          {},
        );
      const existingFieldsInForm = pick(
        fieldsToSet,
        Object.keys(getFieldsValue()),
      );
      if (!isEmpty(existingFieldsInForm)) {
        const fieldsToInitialForm = configValues
          .filter(([, , , isDayDate]) => isDayDate === undefined)
          .reduce((acc, [inputKey]) => [...acc, [inputKey]], []);

        const getInitialFields = pick(getFieldsValue(), fieldsToInitialForm);
        setInitialFormData({ ...existingFieldsInForm, ...getInitialFields });
      }
    }
  }, [
    diff,
    getFieldsValue,
    mergePersons,
    basicInputs,
    checkIfHasDiffDateProp,
    showYouth,
    showAdults,
    selectedYouths,
    selectedAdults,
  ]);

  useEffect(() => {
    if (!isLoadedFormData && initialFormData) {
      setInitialTabValues(initialFormData);
      setIsLoadedFormData(true);
    }
  }, [initialFormData, isLoadedFormData, setInitialTabValues]);

  const getRules = useCallback(
    inputsArray => {
      const rulesObject = {};
      inputsArray.forEach(
        ([inputKey, isRequired, validateFloatValue, isDayDate]) => {
          rulesObject[inputKey] = [];
          if (isDayDate !== undefined && isActive) {
            rulesObject[inputKey].push(
              ...fieldLessOrEqualRule(diff + (isDayDate ? 1 : 0), {
                isDayDate,
              }),
            );
          }
          rulesObject[inputKey].push(
            ...activityValueRule(isActive, validateFloatValue, isRequired),
          );
        },
      );
      return rulesObject;
    },
    [isActive, diff],
  );

  const getFormFields = useCallback(
    (inputsArray, type) => {
      const inputRules = getRules(inputsArray);
      return (
        <React.Fragment>
          <T size="5" bold colored colon>
            <FormattedMessage id={`progress.common.ActivityTabs.${type}`} />
          </T>
          {inputsArray.map(([inputKey, , ,], index) => (
            <FormItem
              key={inputKey}
              label={
                <FormattedMessage
                  id={`progress.common.ActivityTabs.inputTitle.${basicInputs.translationKeys[index]}`}
                />
              }
              standardLayout
            >
              {getFieldDecorator(inputKey, {
                rules: inputRules[inputKey],
              })(
                <Input
                  size="large"
                  type="number"
                  min="0"
                  step="any"
                  placeholder={intl.formatMessage({
                    id: `progress.common.ActivityTabs.inputTitle.${basicInputs.translationKeys[index]}`,
                  })}
                />,
              )}
            </FormItem>
          ))}
        </React.Fragment>
      );
    },
    [getRules, basicInputs.translationKeys, getFieldDecorator],
  );

  return (
    <React.Fragment>
      {showDescription && (
        <S size="6" className={styles.description}>
          <FormattedMessage
            id={`progress.common.ActivityTabs.${activityType}.basicDescription`}
            values={{
              tabName: (
                <span className={styles.descriptionTabName}>
                  <FormattedMessage id="progress.common.ActivityTabs.basic" />
                </span>
              ),
            }}
          />
        </S>
      )}
      {showYouth && !mergePersons && getFormFields(basicInputs.youths, 'youth')}
      {showAdults &&
        !mergePersons &&
        getFormFields(basicInputs.adults, 'adult')}
      {mergePersons &&
        getFormFields(basicInputs.merge, basicInputs.mergeTitleKey)}
    </React.Fragment>
  );
};

BasicTab.propTypes = {
  form: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  showYouth: PropTypes.bool.isRequired,
  showAdults: PropTypes.bool.isRequired,
  youth: personsShape,
  adults: personsShape,
  showDescription: PropTypes.bool.isRequired,
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setInitialTabValues: PropTypes.func.isRequired,
};

export default BasicTab;
