import { resetOnActionsReducer, resetOnLogoutReducer } from '@utils';
import { combineReducers } from 'redux';
import {
  CLEAR_ADVANCEMENTS_TO_DECLINE,
  CLOSE_DECLINE_ADVANCEMENTS_MODAL,
  DECLINE_ADVANCEMENT_ERROR,
  DECLINE_ADVANCEMENT_REQUEST,
  DECLINE_ADVANCEMENT_RESPONSE,
  OPEN_DECLINE_ADVANCEMENTS_MODAL,
  SET_ADVANCEMENTS_TO_DECLINE,
} from './actions';

const isVisibleDeclineAdvancementsModal = (state = false, { type }) => {
  switch (type) {
    case OPEN_DECLINE_ADVANCEMENTS_MODAL: {
      return true;
    }
    case CLOSE_DECLINE_ADVANCEMENTS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingAdvancementRequest = (state = false, { type }) => {
  switch (type) {
    case DECLINE_ADVANCEMENT_REQUEST: {
      return true;
    }
    case DECLINE_ADVANCEMENT_ERROR:
    case DECLINE_ADVANCEMENT_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const advancementsToDecline = (state = null, { payload, type }) => {
  switch (type) {
    case SET_ADVANCEMENTS_TO_DECLINE: {
      return payload;
    }
    case CLEAR_ADVANCEMENTS_TO_DECLINE: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  isVisibleDeclineAdvancementsModal,
  isLoadingAdvancementRequest,
  advancementsToDecline,
});

export default resetOnActionsReducer(resetOnLogoutReducer(reducer));
