import React, { useCallback } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { youthMemberPersonGuidSel } from '@context';
import { isCurrentPageSel } from '@location';
import { ROUTE_OWN_ACTIVITY_LOGS, S, activityTypeIds } from '@shared';
import { hasPermissionSel, permissions, userDataSel } from '@user';

import { ModalMode, actionButtons, detailModes } from '../../constants';
import { setActionButton, setSelectedActivity } from '../../duck/actions';
import {
  filterActivityValuesByPersonGuid,
  getEditActivityObject,
} from '../../utils';
import ActivityCalendar from '../ActivityCalendar';

const ActivitySelector = ({
  form,
  modalMode,
  activityTypeId,
  sectionTitle,
  setDetailMode,
  selectedUserIds,
  disableFutureDays,
  disableGetActivities,
  ...props
}) => {
  const dispatch = useDispatch();
  const youthPersonGuid = useSelector(youthMemberPersonGuidSel);
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );
  const isOwnLogsPage = useSelector(state =>
    isCurrentPageSel(state, ROUTE_OWN_ACTIVITY_LOGS),
  );
  const userData = useSelector(userDataSel);
  const { profile: { personGuid: loggedInPersonGuid } = {} } = userData || {};
  const personGuid = isOwnLogsPage ? loggedInPersonGuid : youthPersonGuid;
  const handleSelectActivity = useCallback(
    activity => {
      const processedActivityValues = getEditActivityObject(
        activity,
        selectedUserIds,
        canApprove,
      );
      dispatch(setDetailMode(detailModes.EDIT));
      dispatch(setSelectedActivity(processedActivityValues));
    },
    [dispatch, setDetailMode, selectedUserIds, canApprove],
  );
  const handleCloneActivity = useCallback(
    activity => {
      const { collaborativeOrganizations = [], akelaStateId = undefined } =
        activity;
      form.resetFields();
      const processedActivityValues = {
        ...activity,
        id: undefined,
        registeredYouths: [],
        registeredAdults: [],
        isApproved: canApprove,
        akelaStateId: akelaStateId && `${activity.akelaStateId}`,
        collaborativeOrganizations: collaborativeOrganizations.map(
          item => item.collabOrgId,
        ),
      };
      dispatch(setDetailMode(detailModes.CLONE));
      dispatch(setSelectedActivity(processedActivityValues));
    },
    [form, setDetailMode, dispatch, canApprove],
  );
  const handleCreateNew = useCallback(
    (date, isPersonalActivity) => {
      form.resetFields();
      const todayValue = moment().valueOf();
      dispatch(setDetailMode(detailModes.CREATE));
      dispatch(
        setSelectedActivity({
          startDate: date,
          endDate:
            (date && date.valueOf() > todayValue) ||
            activityTypeId == activityTypeIds.SERVICE_PROJECTS
              ? date
              : undefined,
          isPersonalActivity,
          isApproved: canApprove,
          personGuid: personGuid || undefined,
          allDay: activityTypeId !== activityTypeIds.SERVICE_PROJECTS,
        }),
      );
    },
    [form, dispatch, setDetailMode, personGuid, canApprove, activityTypeId],
  );
  const handleJoinActivity = useCallback(
    (activity = {}, personExists = false) => {
      // filter youth or adult to update if already exists on the activity
      const registeredYouths =
        filterActivityValuesByPersonGuid(
          activity.registeredYouths,
          personGuid,
        ) || [];
      const registeredAdults =
        filterActivityValuesByPersonGuid(
          activity.registeredAdults,
          personGuid,
        ) || [];
      const processedActivityValues = getEditActivityObject({
        ...activity,
        registeredYouths,
        registeredAdults,
        isApproved: false,
        allowUpdateDetails: false,
      });
      form.resetFields();
      dispatch(setDetailMode(detailModes.JOIN));
      dispatch(setSelectedActivity(processedActivityValues));
      dispatch(
        setActionButton(personExists ? actionButtons.EDIT : actionButtons.JOIN),
      );
    },
    [form, dispatch, setDetailMode, personGuid],
  );
  return (
    <React.Fragment>
      {sectionTitle && (
        <S size="4" bold colored colon>
          {sectionTitle}
        </S>
      )}
      <br />
      <ActivityCalendar
        {...props}
        disableFutureDates={disableFutureDays}
        disableGetActivities={
          modalMode === ModalMode.EDIT || disableGetActivities
        }
        activityTypeId={activityTypeId}
        onSelectActivity={handleSelectActivity}
        onCloneActivity={handleCloneActivity}
        onJoinActivity={handleJoinActivity}
        onCreateNew={handleCreateNew}
      />
    </React.Fragment>
  );
};

ActivitySelector.propTypes = {
  form: PropTypes.object.isRequired,
  modalMode: PropTypes.string.isRequired,
  activityTypeId: PropTypes.number.isRequired,
  setDetailMode: PropTypes.func.isRequired,
  selectedUserIds: PropTypes.array,
  disableFutureDays: PropTypes.bool,
  disableGetActivities: PropTypes.bool,
  sectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ActivitySelector;
