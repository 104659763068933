import { ROUTE_SCOUTBOOK_LEGACY } from './duck';
import { ScoutbookLegacyPage } from './components';

const routes = {
  [ROUTE_SCOUTBOOK_LEGACY]: {
    path: '/scoutbook',
    Component: ScoutbookLegacyPage,
    standalone: true,
  },
};

export default routes;
