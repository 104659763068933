import { isEmpty } from 'lodash';
import { redirect, replace } from 'redux-first-router';

import { payloadSel, querySel } from '@location';
import {
  emptyObj,
  organizationPositionsSel,
  permissions,
  setOrganization,
} from '@shared';

import {
  AddAdvancement,
  CreateEventPage,
  EditEventPage,
  RSVPPage,
} from './components';
import {
  ROUTE_EVENTS_ADD_ADVANCEMENT,
  ROUTE_EVENTS_CREATE,
  ROUTE_EVENTS_EDIT,
  ROUTE_EVENT_RSVP,
} from './duck/actions';
import { advancementTypeSel } from './duck/selectors';

export default {
  [ROUTE_EVENTS_CREATE]: {
    path: '/events/createEvent',
    Component: CreateEventPage,
    titleId: 'page.createEvent',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;

      const youthOrgGuid = organizationGuid;

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions)) {
        const foundPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        replace('/events/createEvent');
        if (foundPosition) {
          dispatch(
            setOrganization({
              guid: foundPosition.organizationGuid,
              programType: foundPosition.programType,
              parentOrgGuid: foundPosition.organizationGuid,
            }),
          );
        }
      }
    },
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_EVENTS_ADD_ADVANCEMENT]: {
    path: '/events/createEvent/add/:advancementType',
    Component: AddAdvancement,
    getTitleId: ({ advancementType }) => `page.createEvent.${advancementType}`,
    thunk: (dispatch, getState) => {
      const state = getState();
      if (!advancementTypeSel(state)) {
        dispatch(redirect({ type: ROUTE_EVENTS_CREATE }));
      }
    },
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_EVENTS_EDIT]: {
    path: '/events/editEvent/:eventId',
    Component: EditEventPage,
    titleId: 'page.editEvent',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const payload = payloadSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;

      const youthOrgGuid = organizationGuid;

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions) && !isEmpty(payload)) {
        const { eventId } = payload;
        const foundPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        replace(`/events/editEvent/${eventId}`);
        if (foundPosition) {
          dispatch(
            setOrganization({
              guid: foundPosition.organizationGuid,
              programType: foundPosition.programType,
              parentOrgGuid: foundPosition.organizationGuid,
            }),
          );
        }
      }
    },
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_EVENT_RSVP]: {
    path: '/public/rsvp',
    Component: RSVPPage,
    titleId: 'page.eventRSVP',
    unauthorizedAccess: true,
    standalone: true,
  },
};
