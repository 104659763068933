import React from 'react';

import { difference, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import type { GroupedPendingItem } from '@modules/advancement/pendingItems/types';
import { Button, Checkbox, PersonAvatar, S } from '@shared';

import styles from './PendingItemsList.less';

type Props = {
  item: GroupedPendingItem;
  isOffline: boolean;
  onGoToYouthProfile: (userId: number) => () => void;
  onSelectGroup: (key: string) => void;
  selectedRowKeys: string[];
};
export const rednerHeaderRow = ({
  isOffline,
  item,
  onGoToYouthProfile,
  onSelectGroup,
  selectedRowKeys,
}: Props) => {
  const {
    children,
    description,
    key,
    memberId,
    name,
    profilePictureUrl,
    totalAmount,
  } = item;
  const { userId } = children[0];
  const checked = isEmpty(
    difference(
      children.map(({ key }) => key),
      selectedRowKeys,
    ),
  );

  return (
    <div className={styles.headerRow}>
      <Checkbox checked={checked} onChange={() => onSelectGroup(key)} />
      <PersonAvatar isAdult={false} src={profilePictureUrl} />
      <div>
        <S size="3" bold>
          {name}
        </S>
        <S size="4">{memberId}</S>
      </div>
      <div className={styles.total}>
        <S size="4">
          {totalAmount} {description}
        </S>
      </div>
      <Button
        color="info"
        disabled={isOffline}
        fitText
        noBorder
        onClick={onGoToYouthProfile(userId)}
        shape="round"
      >
        <FormattedMessage id="advancement.PendingItems.viewYouth" />
      </Button>
    </div>
  );
};
