import errorHandler from './coreErrorHandler';
import createHttp from './httpFactory';
import jsonBodyTransformer from './jsonBodyTransformer';
import unwrapResponse from './unwrapResponse';

const optionsTransformer = () => {
  throw new Error('This should never be called again');
};

const bodyTransformer = body =>
  body instanceof FormData ? body : jsonBodyTransformer(body);

const http = createHttp({
  bodyTransformer,
  optionsTransformer,
  responseHandler: unwrapResponse,
  errorHandler,
});

const apiCall$ = (method, endpoint, body, options) =>
  method === http.get$
    ? method(endpoint, options)
    : method(endpoint, body, options);

const apiCalls = Object.freeze({
  get$: (endpoint, options) =>
    apiCall$(http.get$, endpoint, undefined, options),
  post$: (endpoint, body, options) =>
    apiCall$(http.post$, endpoint, body, options),
  put$: (endpoint, body, options) =>
    apiCall$(http.put$, endpoint, body, options),
  delete$: (endpoint, body, options) =>
    apiCall$(http.delete$, endpoint, body, options),
});

export default apiCalls;
