import { Program } from '@shared';

export { packRosterModuleName as moduleName } from '../common/constants';

export const SELECTABLE_PEOPLE_LIMIT = 30;

export const confirmationWord = 'yes';
export const UNASSIGNED = 'unassigned';

export const peopleFilters = Object.freeze({
  YOUTH: 'showYouth',
  AGED_YOUTH: 'showAgedYouth',
  ADULTS: 'showAdults',
  DEN_CHIEFS: 'showDenChiefs',
});

export const visibilityFields = Object.freeze({
  AGE: 'age',
  MEMBER_ID: 'memberId',
  LAST_RANK_EARNED: 'lastRankEarned',
  SUB_UNIT: 'subUnit',
});

export const programGrownUpAges = Object.freeze({
  [Program.VENTURING]: 18,
  [Program.EXPLORING]: 18,
  [Program.CLUB]: 18,
  [Program.BOY_SCOUT]: 18,
  [Program.SEA_SCOUT]: 18,
  //
  [Program.CUB_SCOUT]: 18,
  [Program.VARSITY]: 18,
});

export const UNIT_PARTICIPANT = 'Unit Participant';

export const YOUTH_MEMBER = 'Youth Member';

export const importantPositions = [
  'Scoutmaster',
  'Venturing Crew Advisor',
  'Skipper',
  'Cubmaster',
  'Committee Chair',
  'Committee Chairman',
  'Chartered Organization Rep.',
];

export const renewalStatuses = Object.freeze({
  OPTED_OUT: 'Opted Out',
  RENEWED: 'Renewed',
  EXPIRED: 'Expired',
  ELIGIBLE_TO_RENEW: 'Eligible to Renew',
  CURRENT: 'Current',
});
