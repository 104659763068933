import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { LeadershipPosition } from '../../../types/esb';
import {
  closeDeletePositionModal,
  closeUpdatePositionsModal,
  disableLeadershipEditMode,
  enableLeadershipEditMode,
  openDeletePositionModal,
  openUpdatePositionsModal,
} from './actionsTyped';
import reducers from './reducers';

const updatePositionsModalVisible = createReducer(false, builder => {
  builder
    .addCase(openUpdatePositionsModal, () => true)
    .addCase(closeUpdatePositionsModal, () => false);
});

const deletePositionModalVisible = createReducer(false, builder => {
  builder
    .addCase(openDeletePositionModal, () => true)
    .addCase(closeDeletePositionModal, () => false);
});

const leadershipEditMode = createReducer(false, builder => {
  builder
    .addCase(enableLeadershipEditMode, () => true)
    .addCase(disableLeadershipEditMode, () => false);
});

const positionToUpdate = createReducer<null | LeadershipPosition>(
  null,
  builder => {
    builder
      .addCase(openUpdatePositionsModal, (_state, { payload }) => payload)
      .addCase(openDeletePositionModal, (_state, { payload }) => payload)
      .addCase(closeUpdatePositionsModal, () => null)
      .addCase(closeDeletePositionModal, () => null)
      .addCase(disableLeadershipEditMode, () => null);
  },
);

export default combineReducers({
  ...reducers,
  leadershipEditMode,
  updatePositionsModalVisible,
  deletePositionModalVisible,
  positionToUpdate,
});
