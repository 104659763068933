import React from 'react';
import { RemoveRecurrentEventModal } from './RemoveRecurrentEventModal';

export function CalendarModals() {
  return (
    <React.Fragment>
      <RemoveRecurrentEventModal />
    </React.Fragment>
  );
}
