import { combineReducers } from 'redux';
import { resetOnLogoutReducer } from '@utils';

import {
  ADULT_DETAILS_ERROR,
  ADULT_DETAILS_REQUEST,
  ADULT_DETAILS_RESPONSE,
  SAVE_ADULT_PROFILE_ERROR,
  SAVE_ADULT_PROFILE_REQUEST,
  SAVE_ADULT_PROFILE_RESPONSE,
  SET_EDIT_ADULT_DETAIL_SIDEBAR,
  SAVE_ADULT_PROFILE_PICTURE_REQUEST,
  SAVE_ADULT_PROFILE_PICTURE_RESPONSE,
  SAVE_ADULT_PROFILE_PICTURE_ERROR,
} from './actions';

const isLoadingAdultProfile = (state = true, { type }) => {
  switch (type) {
    case ADULT_DETAILS_REQUEST: {
      return true;
    }
    case ADULT_DETAILS_ERROR:
    case ADULT_DETAILS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const adultProfile = (state = null, { type, payload }) => {
  switch (type) {
    case ADULT_DETAILS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isSavingProfile = (state = false, { type }) => {
  switch (type) {
    case SAVE_ADULT_PROFILE_REQUEST: {
      return true;
    }
    case SAVE_ADULT_PROFILE_ERROR:
    case SAVE_ADULT_PROFILE_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const setEditAdultDetailSidebar = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EDIT_ADULT_DETAIL_SIDEBAR: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const adultProfilePictureSaveLoading = (state = false, { type }) => {
  switch (type) {
    case SAVE_ADULT_PROFILE_PICTURE_REQUEST: {
      return true;
    }
    case SAVE_ADULT_PROFILE_PICTURE_RESPONSE:
    case SAVE_ADULT_PROFILE_PICTURE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  isSavingProfile,
  isLoadingAdultProfile,
  adultProfile,
  setEditAdultDetailSidebar,
  adultProfilePictureSaveLoading,
});

export default resetOnLogoutReducer(reducer);
