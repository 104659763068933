import React from 'react';
import { Form as BsaForm } from 'bsa-ui';
import FormItem from './FormItem';
import { FormProps } from './Form.types';

/**
 * @type {React.FC<import('./Form.types').FormProps>}
 */
const Form = (props: FormProps) => <BsaForm {...props} />;

Form.create = BsaForm.create;
Form.createFormField = BsaForm.createFormField;
Form.Item = FormItem;

export default Form;
