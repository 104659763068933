import {
  setUserPreferences,
  advancementHistoryFieldPreferencesName,
} from '@shared';
import { advancementHistoryModuleNamespace } from '../../common';
import { updatedDateFilters } from '../constants';
import { updatedDateFilterSel } from './selectors';

export {
  ADVANCEMENT_HISTORY_RESPONSE,
  ADVANCEMENT_HISTORY_ERROR,
  ADVANCEMENT_HISTORY_REQUEST_IF_NOT_LOADED,
  ADVANCEMENT_HISTORY_ALREADY_LOADED,
  advancementHistoryResponse,
  advancementHistoryError,
  advancementHistoryRequestIfNotLoaded,
  advancementHistoryAlreadyLoaded,
} from '@shared';

const namespace = `${advancementHistoryModuleNamespace}`;

export const ADVANCEMENT_HISTORY_REQUEST = `${namespace}/ADVANCEMENT_HISTORY_REQUEST`;
export const advancementHistoryRequest = options => ({
  type: ADVANCEMENT_HISTORY_REQUEST,
  payload: options,
});

export const SET_EXPANDED_KEYS = `${namespace}/SET_EXPANDED_KEYS`;
export const setExpandedKeys = keys => ({
  type: SET_EXPANDED_KEYS,
  payload: keys,
});

export const SET_SEARCH_FILTER = `${namespace}/SET_SEARCH_FILTER`;
export const setSearchFilter = term => ({
  type: SET_SEARCH_FILTER,
  payload: term,
});

export const SET_ADVANCEMENT_TYPE_FILTER = `${namespace}/SET_ADVANCEMENT_TYPE_FILTER`;
export const setAdvancementTypeFilter = types => ({
  type: SET_ADVANCEMENT_TYPE_FILTER,
  payload: types,
});

export const SET_HISTORY_TYPE_FILTER = `${namespace}/SET_HISTORY_TYPE_FILTER`;
export const setHistoryTypeFilter = types => ({
  type: SET_HISTORY_TYPE_FILTER,
  payload: types,
});

export const SET_UPDATED_DATE_FILTER = `${namespace}/SET_UPDATED_DATE_FILTER`;
export const setUpdatedDateFilter = filter => ({
  type: SET_UPDATED_DATE_FILTER,
  payload: filter,
});

export const SET_FILTER_CARD_FILTERS = `${namespace}/SET_FILTER_CARD_FILTERS`;
export const setFilterCardFilters = filters => (dispatch, getState) => {
  const previousUpdatedDateFilter = updatedDateFilterSel(getState());
  const { updatedDate } = filters;
  const fetchAllRecords =
    previousUpdatedDateFilter !== updatedDate &&
    updatedDate === updatedDateFilters.ALL;

  return dispatch({
    type: SET_FILTER_CARD_FILTERS,
    payload: { ...filters, fetchAllRecords },
  });
};

export const SET_SORTER = `${namespace}/SET_SORTER`;
export const setSorter = ({ columnKey, order }) => ({
  type: SET_SORTER,
  payload: { columnKey, order },
});

export const SET_EXPANDED_MAIN_ROW = `${namespace}/SET_EXPANDED_ROW`;
export const setExpandedMainRow = expandedRow => ({
  type: SET_EXPANDED_MAIN_ROW,
  payload: expandedRow,
});

export const SET_SELECTED_ROWS_KEYS = `${namespace}/SET_SELECTED_KEYS`;
export const setSelectedRowKeys = itemsIds => ({
  type: SET_SELECTED_ROWS_KEYS,
  payload: itemsIds,
});

export const setFieldsVisibility = fieldsVisibility =>
  setUserPreferences(fieldsVisibility, advancementHistoryFieldPreferencesName);
