import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, ExpandToggle } from '@shared';

class ToggleDetailsButton extends React.PureComponent {
  render() {
    const { className, expanded, onClick } = this.props;

    return (
      <Button
        fitText
        ghost
        noBorder
        size="small"
        color="scouting-warm-gray"
        className={className}
        onClick={onClick}
      >
        {expanded ? (
          <FormattedMessage id="progress.ToggleDetailsButton.collapse" />
        ) : (
          <FormattedMessage id="progress.ToggleDetailsButton.expand" />
        )}
        <ExpandToggle expanded={expanded} />
      </Button>
    );
  }
}

ToggleDetailsButton.propTypes = {
  // provided by parent
  expanded: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ToggleDetailsButton;
