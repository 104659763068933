export default countryCode => {
  switch (countryCode) {
    case '01': {
      return 'CA';
    }
    case '001': {
      return 'US';
    }
    case '93': {
      return 'AF';
    }
    case '355': {
      return 'AL';
    }
    case '213': {
      return 'DZ';
    }
    case '376': {
      return 'AD';
    }
    case '244': {
      return 'AO';
    }
    case '672': {
      return 'AQ';
    }
    case '54': {
      return 'AR';
    }
    case '374': {
      return 'AM';
    }
    case '297': {
      return 'AW';
    }
    case '61': {
      return 'AU';
    }
    case '43': {
      return 'AT';
    }
    case '994': {
      return 'AZ';
    }
    case '973': {
      return 'BH';
    }
    case '880': {
      return 'BD';
    }
    case '375': {
      return 'BY';
    }
    case '32': {
      return 'BE';
    }
    case '501': {
      return 'BZ';
    }
    case '229': {
      return 'BJ';
    }
    case '975': {
      return 'BT';
    }
    case '591': {
      return 'BO';
    }
    case '387': {
      return 'BA';
    }
    case '267': {
      return 'BW';
    }
    case '55': {
      return 'BR';
    }
    case '246': {
      return 'IO';
    }
    case '673': {
      return 'BN';
    }
    case '359': {
      return 'BG';
    }
    case '226': {
      return 'BF';
    }
    case '257': {
      return 'BI';
    }
    case '855': {
      return 'KH';
    }
    case '237': {
      return 'CM';
    }
    case '238': {
      return 'CV';
    }
    case '236': {
      return 'CF';
    }
    case '235': {
      return 'TD';
    }
    case '56': {
      return 'CL';
    }
    case '86': {
      return 'CN';
    }
    case '57': {
      return 'CO';
    }
    case '269': {
      return 'KM';
    }
    case '682': {
      return 'CK';
    }
    case '506': {
      return 'CR';
    }
    case '385': {
      return 'HR';
    }
    case '53': {
      return 'CU';
    }
    case '599': {
      return 'CW';
    }
    case '357': {
      return 'CY';
    }
    case '420': {
      return 'CZ';
    }
    case '243': {
      return 'CD';
    }
    case '45': {
      return 'DK';
    }
    case '253': {
      return 'DJ';
    }
    case '670': {
      return 'TL';
    }
    case '593': {
      return 'EC';
    }
    case '20': {
      return 'EG';
    }
    case '503': {
      return 'SV';
    }
    case '240': {
      return 'GQ';
    }
    case '291': {
      return 'ER';
    }
    case '372': {
      return 'EE';
    }
    case '251': {
      return 'ET';
    }
    case '500': {
      return 'FK';
    }
    case '298': {
      return 'FO';
    }
    case '679': {
      return 'FJ';
    }
    case '358': {
      return 'FI';
    }
    case '33': {
      return 'FR';
    }
    case '689': {
      return 'PF';
    }
    case '241': {
      return 'GA';
    }
    case '220': {
      return 'GM';
    }
    case '995': {
      return 'GE';
    }
    case '49': {
      return 'DE';
    }
    case '233': {
      return 'GH';
    }
    case '350': {
      return 'GI';
    }
    case '30': {
      return 'GR';
    }
    case '299': {
      return 'GL';
    }
    case '502': {
      return 'GT';
    }
    case '224': {
      return 'GN';
    }
    case '245': {
      return 'GW';
    }
    case '592': {
      return 'GY';
    }
    case '509': {
      return 'HT';
    }
    case '504': {
      return 'HN';
    }
    case '852': {
      return 'HK';
    }
    case '36': {
      return 'HU';
    }
    case '354': {
      return 'IS';
    }
    case '91': {
      return 'IN';
    }
    case '62': {
      return 'ID';
    }
    case '98': {
      return 'IR';
    }
    case '964': {
      return 'IQ';
    }
    case '353': {
      return 'IE';
    }
    case '972': {
      return 'IL';
    }
    case '39': {
      return 'IT';
    }
    case '225': {
      return 'CI';
    }
    case '81': {
      return 'JP';
    }
    case '962': {
      return 'JO';
    }
    case '254': {
      return 'KE';
    }
    case '686': {
      return 'KI';
    }
    case '383': {
      return 'XK';
    }
    case '965': {
      return 'KW';
    }
    case '996': {
      return 'KG';
    }
    case '856': {
      return 'LA';
    }
    case '371': {
      return 'LV';
    }
    case '961': {
      return 'LB';
    }
    case '266': {
      return 'LS';
    }
    case '231': {
      return 'LR';
    }
    case '218': {
      return 'LY';
    }
    case '423': {
      return 'LI';
    }
    case '370': {
      return 'LT';
    }
    case '352': {
      return 'LU';
    }
    case '853': {
      return 'MO';
    }
    case '389': {
      return 'MK';
    }
    case '261': {
      return 'MG';
    }
    case '265': {
      return 'MW';
    }
    case '60': {
      return 'MY';
    }
    case '960': {
      return 'MV';
    }
    case '223': {
      return 'ML';
    }
    case '356': {
      return 'MT';
    }
    case '692': {
      return 'MH';
    }
    case '222': {
      return 'MR';
    }
    case '230': {
      return 'MU';
    }
    case '52': {
      return 'MX';
    }
    case '691': {
      return 'FM';
    }
    case '373': {
      return 'MD';
    }
    case '377': {
      return 'MC';
    }
    case '976': {
      return 'MN';
    }
    case '382': {
      return 'ME';
    }
    case '212': {
      return 'MA';
    }
    case '258': {
      return 'MZ';
    }
    case '95': {
      return 'MM';
    }
    case '264': {
      return 'NA';
    }
    case '674': {
      return 'NR';
    }
    case '977': {
      return 'NP';
    }
    case '31': {
      return 'NL';
    }
    case '687': {
      return 'NC';
    }
    case '64': {
      return 'NZ';
    }
    case '505': {
      return 'NI';
    }
    case '227': {
      return 'NE';
    }
    case '234': {
      return 'NG';
    }
    case '683': {
      return 'NU';
    }
    case '850': {
      return 'KP';
    }
    case '47': {
      return 'NO';
    }
    case '968': {
      return 'OM';
    }
    case '92': {
      return 'PK';
    }
    case '680': {
      return 'PW';
    }
    case '970': {
      return 'PS';
    }
    case '507': {
      return 'PA';
    }
    case '675': {
      return 'PG';
    }
    case '595': {
      return 'PY';
    }
    case '51': {
      return 'PE';
    }
    case '63': {
      return 'PH';
    }
    case '48': {
      return 'PL';
    }
    case '351': {
      return 'PT';
    }
    case '974': {
      return 'QA';
    }
    case '242': {
      return 'CG';
    }
    case '262': {
      return 'RE';
    }
    case '40': {
      return 'RO';
    }
    case '7': {
      return 'RU';
    }
    case '250': {
      return 'RW';
    }
    case '290': {
      return 'SH';
    }
    case '590': {
      return 'MF';
    }
    case '508': {
      return 'PM';
    }
    case '685': {
      return 'WS';
    }
    case '378': {
      return 'SM';
    }
    case '239': {
      return 'ST';
    }
    case '966': {
      return 'SA';
    }
    case '221': {
      return 'SN';
    }
    case '381': {
      return 'RS';
    }
    case '248': {
      return 'SC';
    }
    case '232': {
      return 'SL';
    }
    case '65': {
      return 'SG';
    }
    case '421': {
      return 'SK';
    }
    case '386': {
      return 'SI';
    }
    case '677': {
      return 'SB';
    }
    case '252': {
      return 'SO';
    }
    case '27': {
      return 'ZA';
    }
    case '82': {
      return 'KR';
    }
    case '211': {
      return 'SS';
    }
    case '34': {
      return 'ES';
    }
    case '94': {
      return 'LK';
    }
    case '249': {
      return 'SD';
    }
    case '597': {
      return 'SR';
    }
    case '268': {
      return 'SZ';
    }
    case '46': {
      return 'SE';
    }
    case '41': {
      return 'CH';
    }
    case '963': {
      return 'SY';
    }
    case '886': {
      return 'TW';
    }
    case '992': {
      return 'TJ';
    }
    case '255': {
      return 'TZ';
    }
    case '66': {
      return 'TH';
    }
    case '228': {
      return 'TG';
    }
    case '690': {
      return 'TK';
    }
    case '676': {
      return 'TO';
    }
    case '216': {
      return 'TN';
    }
    case '90': {
      return 'TR';
    }
    case '993': {
      return 'TM';
    }
    case '688': {
      return 'TV';
    }
    case '256': {
      return 'UG';
    }
    case '380': {
      return 'UA';
    }
    case '971': {
      return 'AE';
    }
    case '44': {
      return 'GB';
    }
    case '598': {
      return 'UY';
    }
    case '998': {
      return 'UZ';
    }
    case '678': {
      return 'VU';
    }
    case '379': {
      return 'VA';
    }
    case '58': {
      return 'VE';
    }
    case '84': {
      return 'VN';
    }
    case '681': {
      return 'WF';
    }
    case '967': {
      return 'YE';
    }
    case '260': {
      return 'ZM';
    }
    case '263': {
      return 'ZW';
    }
    default: {
      return 'US';
    }
  }
};
