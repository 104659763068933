import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import { isMobileSel } from '../../duck';
import { searchHeightMobile } from '../../constants';
import { addPassiveEventListener } from '../../utils';
import HeadroomAffix from '../HeadroomAffix';
import Input from '../Input';
import styles from './SearchInput.less';

const enterKeyCode = 13;
const hideKeyboardEvents = ['touchend', 'touchmove'];

class SearchInput extends React.PureComponent {
  state = {
    value: this.props.value || '',
  };

  componentWillUnmount() {
    this.clearEvents();
    this.clearKeyListener();
  }

  currentTarget;

  handleChange = event => {
    let { value } = event.target;
    value = value || '';
    this.setState({ value });
    this.notifyChange(value);
  };

  notifyChange = debounce(
    value => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    },
    300,
    {
      leading: false,
      trailing: true,
    },
  );

  handleFocus = ({ target }) => {
    this.currentTarget = target;
    target.select();

    if (this.props.isMobile) {
      // Software Keyboard is open, listen for keys
      this.setKeyListener();
      this.registerEvents();
    }
  };

  handleBlur = () => {
    if (this.props.isMobile) {
      // Software Keyboard is hidden
      this.clearKeyListener();
      this.clearEvents();
    }
  };

  handlePressedKey = ({ which }) => {
    if (which == enterKeyCode && this.currentTarget) {
      this.currentTarget.blur();
    }
  };

  setKeyListener = () => {
    document.addEventListener('keydown', this.handlePressedKey);
  };

  clearKeyListener = () => {
    document.removeEventListener('keydown', this.handlePressedKey);
  };

  registerEvents = () => {
    hideKeyboardEvents.forEach(event =>
      addPassiveEventListener(document, event, this.hideSoftKeyboard),
    );
  };

  clearEvents = () => {
    hideKeyboardEvents.forEach(event =>
      document.removeEventListener(event, this.hideSoftKeyboard),
    );
  };

  hideSoftKeyboard = () => {
    if (this.inputRef) {
      this.inputRef.blur();
    }
  };

  setInputRef = element => (this.inputRef = element);

  handlePin = () => this.props.onPinChange && this.props.onPinChange(true);

  handleUnpin = () => this.props.onPinChange && this.props.onPinChange(false);

  render() {
    const {
      size,
      bordered,
      className,
      mobileSticky,
      stickyBordered,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isMobile,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dispatch,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onPinChange,
      ...rest
    } = this.props;
    const { value } = this.state;
    const classNames = cn(styles.searchWrapper, className, {
      [styles.bordered]: bordered,
      [styles.stickyBordered]: stickyBordered,
    });

    let node = (
      <div className={classNames} tabIndex="999">
        <Input
          {...rest}
          ref={this.setInputRef}
          className={styles.searchInput}
          addonBefore={
            <SearchIcon
              className={cn(styles.searchIcon, styles[`${size}Icon`])}
            />
          }
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </div>
    );

    if (mobileSticky && isMobile) {
      node = (
        <HeadroomAffix
          clearHeader
          className={styles.headroomWrapper}
          pinStart={-searchHeightMobile}
          onPin={this.handlePin}
          onUnpin={this.handleUnpin}
          onUnfix={this.handleUnpin}
        >
          {node}
        </HeadroomAffix>
      );
    }

    return node;
  }
}

SearchInput.propTypes = {
  ...Input.propTypes,
  size: PropTypes.string,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  mobileSticky: PropTypes.bool,
  stickyBordered: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onPinChange: PropTypes.func,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
});

export default connect(mapState)(SearchInput);
