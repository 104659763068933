import _ from 'lodash';
import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';

import { allUnitsSubunitsSel } from '@modules/events/duck/selectors';
import { UserOrganizationInfo } from '@modules/user/duck/types';
import { organizationPositionsSel } from '@shared';

import { FetchUnitEventsRequest } from './types';

export const calendarRequestsSel = createSelector(
  (
    _state: unknown,
    props: {
      selectedDate: string | Moment;
    },
  ) => props.selectedDate,
  organizationPositionsSel,
  allUnitsSubunitsSel,
  (selectedDate, organizationPositions, subUnits) => {
    const validOrg: UserOrganizationInfo[] = organizationPositions.filter(
      (org: UserOrganizationInfo) => org.unitId,
    );

    if (!validOrg.length) {
      return [];
    }

    return _.uniqBy(validOrg, 'unitId').map(org => {
      const { unitId } = org;
      const subUnitInfo = subUnits.find(subunit => subunit.id === +unitId);
      const showDLEEvents = !!subUnitInfo?.showDlEvents;

      const fromDate = moment(selectedDate)
        .startOf('month')
        .subtract(1, 'week')
        .format('Y-MM-DD');
      const toDate = moment(selectedDate)
        .endOf('month')
        .add(1, 'week')
        .format('Y-MM-DD');

      const payload: FetchUnitEventsRequest = {
        toDate,
        fromDate,
        unitId: Number(unitId),
        showDLEvents: showDLEEvents,
      };

      return payload;
    });
  },
);
