import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';
import cn from 'classnames';
import { ExpandToggle } from '@shared';
import styles from './TypeMainRow.less';
import { setMemberTypeSectionExpanded } from '../../../../duck';

function TypeMainRow({ name, itemsCount, expanded }) {
  const dispatch = useDispatch();

  const handleOnClick = useCallback(() => {
    dispatch(setMemberTypeSectionExpanded(name, !expanded));
  }, [dispatch, name, expanded]);

  return (
    <div className={styles.row}>
      <div className={styles.denInfoWrapper}>
        <div>
          <span>{capitalize(name)}</span>
          <span className={styles.count}>({itemsCount})</span>
        </div>
        <ExpandToggle
          className={cn(styles.chevron, {
            [styles.noDisplay]: false,
          })}
          expanded={expanded}
          onClick={handleOnClick}
        />
      </div>
    </div>
  );
}

TypeMainRow.propTypes = {
  name: PropTypes.string,
  itemsCount: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default TypeMainRow;
