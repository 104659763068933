import { lazier } from '@modules/shared/components';

export const YouthProfilePage = lazier(() => import('./YouthProfilePage'));
export const EagleProjectPage = lazier(() => import('./EagleProjectPage'));
export const AdvRequirementsPage = lazier(() =>
  import('./AdvRequirementsPage'),
);
export const AdvancementsListPage = lazier(() =>
  import('./AdvancementsListPage'),
);
export const YouthActivityLogsPage = lazier(() =>
  import('./YouthActivityLogsPage'),
);
