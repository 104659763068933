import { gtm } from '@shared';

import { moduleName } from '../constants';

const namespace = `${moduleName}`;
export const ROUTE_ROLES = `${namespace}/ROUTE_ROLES`;

export const USER_PERMISSIONS_REQUEST = `${namespace}/USER_PERMISSIONS_REQUEST`;
export const userPermissionsRequest = () => ({
  type: USER_PERMISSIONS_REQUEST,
});

export const USER_PERMISSIONS_RESPONSE = `${namespace}/USER_PERMISSIONS_RESPONSE`;
export const userPermissionsResponse = userPermissions => ({
  type: USER_PERMISSIONS_RESPONSE,
  payload: userPermissions,
});

export const USER_PERMISSIONS_ERROR = `${namespace}/USER_PERMISSIONS_ERROR`;
export const userPermissionsError = error => ({
  type: USER_PERMISSIONS_ERROR,
  payload: error,
});

export const AVAILABLE_USERS_REQUEST = `${namespace}/AVAILABLE_USERS_REQUEST`;
export const availableUsersRequest = () => ({
  type: AVAILABLE_USERS_REQUEST,
});

export const AVAILABLE_USERS_RESPONSE = `${namespace}/AVAILABLE_USERS_RESPONSE`;
export const availableUsersResponse = availableUsers => ({
  type: AVAILABLE_USERS_RESPONSE,
  payload: availableUsers,
});

export const AVAILABLE_USERS_ERROR = `${namespace}/USER_PERMISSIONS_ERROR`;
export const availableUsersError = () => ({
  type: AVAILABLE_USERS_ERROR,
});

export const AVAILABLE_ROLES_REQUEST = `${namespace}/AVAILABLE_ROLES_REQUEST`;
export const availableRolesRequest = () => ({
  type: AVAILABLE_ROLES_REQUEST,
});

export const AVAILABLE_ROLES_RESPONSE = `${namespace}/AVAILABLE_ROLES_RESPONSE`;
export const availableRolesResponse = availableRoles => ({
  type: AVAILABLE_ROLES_RESPONSE,
  payload: availableRoles,
});

export const AVAILABLE_ROLES_ERROR = `${namespace}/AVAILABLE_ROLES_REQUEST`;
export const availableRolesError = () => ({
  type: AVAILABLE_ROLES_ERROR,
});

export const DELETE_USER_PERMISSION = `${namespace}/DELETE_USER_PERMISSION`;
export const deleteUserPermission = id => ({
  type: DELETE_USER_PERMISSION,
  payload: { id },
  gtm: {
    category: gtm.categories.ROLES,
  },
});

export const UPDATE_USER_PERMISSION = `${namespace}/UPDATE_USER_PERMISSION`;
export const updateUserPermission = (id, roleType) => ({
  type: UPDATE_USER_PERMISSION,
  payload: { id, roleType },
  gtm: {
    category: gtm.categories.ROLES,
  },
});

export const ADD_USER_PERMISSION = `${namespace}/ADD_USER_PERMISSION`;
export const addUserPermission = (role, user) => ({
  type: ADD_USER_PERMISSION,
  payload: { role, user },
  gtm: {
    category: gtm.categories.ROLES,
  },
});

export const SET_SEARCH = `${namespace}/SET_SEARCH`;
export const setSearch = search => ({
  type: SET_SEARCH,
  payload: search,
});
