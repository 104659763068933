import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import InfoIcon from '@material-ui/icons/Info';
import { Alert, Button } from '@shared';
import { openSyncModal, pendingRequestsCountSel, offlineSel } from '../../duck';
import styles from './SyncPendingBanner.less';

const SyncPendingBanner = ({ itemCount, onOpenSyncModal, isOffline }) =>
  !isOffline &&
  !!itemCount && (
    <Alert
      className={styles.banner}
      type="info"
      banner
      showIcon={false}
      message={
        <React.Fragment>
          <div className={styles.alertText}>
            <InfoIcon className={styles.alertIcon} />
            <FormattedMessage id="offline.SyncPendingBanner.text" />
          </div>
          <div className={styles.alertButton}>
            <Button
              ghost
              uppercase
              noBorder
              color="info"
              size="small"
              onClick={onOpenSyncModal}
            >
              <FormattedMessage id="offline.SyncPendingBanner.button" />
            </Button>
          </div>
        </React.Fragment>
      }
    />
  );

SyncPendingBanner.propTypes = {
  itemCount: PropTypes.number.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onOpenSyncModal: PropTypes.func.isRequired,
};

const mapState = state => ({
  itemCount: pendingRequestsCountSel(state),
  isOffline: offlineSel(state),
});

const mapDispatch = dispatch => ({
  onOpenSyncModal: () => dispatch(openSyncModal()),
});

export default connect(mapState, mapDispatch)(SyncPendingBanner);
