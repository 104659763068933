import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SET_ORGANIZATION } from '@context';
import { isParentOrYouthMemberSel, parentOrgGuidSel } from '@shared/duck';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  BASIC_REPORTS_REQUEST,
  basicReportsError,
  basicReportsResponse,
} from './actions';
import { responseReportOrgGuidSel } from './selectors';
import services from './services';

const loadBasicReports$ = (action$, state$) =>
  action$.pipe(
    ofType(BASIC_REPORTS_REQUEST, SET_ORGANIZATION),
    filter(() => {
      const state = state$.value;
      const organizationGuid = parentOrgGuidSel(state, true);
      const responseReportOrgGuid = responseReportOrgGuidSel(state);
      if (organizationGuid && organizationGuid !== responseReportOrgGuid) {
        return true;
      }
      return false;
    }),
    switchMap(() => {
      const state = state$.value;
      const organizationGuid = parentOrgGuidSel(state, true);
      const isParentOrYouthMember = isParentOrYouthMemberSel(state);
      const requestReports = isParentOrYouthMember
        ? services.getPersonalReportsInfo$()
        : services.getReportsInfo$(organizationGuid);
      return requestReports.pipe(
        map(basicReportsResponse),
        catchAndReport(err => of(basicReportsError(err))),
      );
    }),
  );

export default combineEpics(loadBasicReports$);
