const callback = resolve => (err, values) => {
  if (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return resolve(false);
  }
  return resolve(values);
};

/**
 * Helpers for validating antd Forms.
 *
 * @param {Object} form - antd form prop
 * @param {Array} [fields] - optional list of fields to validate
 * @param {Object} [settings] - optional form parameters
 *
 * @returns {Promise} - false if not valid, object with form values if valid
 */
export default (form, fields, settings) =>
  new Promise(resolve => {
    const cb = callback(resolve);
    const args = [fields, settings, cb].filter(Boolean);

    form.validateFields(...args);
  });
