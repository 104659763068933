import React, { useCallback } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Row } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { FormattedMessage } from 'react-intl';

import { toastService } from '@modules/toasts';

import { dragType } from '../../constants';
import Button from '../Button';
import DragDropPerson from '../DragDropPerson';
import S from '../S';
import styles from './DragDropPersonList.less';

const DragDropPersonList = ({
  data,
  openAddPersonModal,
  onSelectPosition,
  canAddPerson,
  component,
  openExpirePositionModal,
  setFromPosition,
  setToPosition,
  setSelectedPerson,
  setIsDrop,
  personsAssigned,
  updateRegPositions,
}) => {
  const {
    positionLong,
    max,
    isRequired = false,
    personsAssigned: personList,
  } = data;
  const isFunctional = component === 'functional';
  const canIndividualExpire = component !== 'registered';
  const isMax = personList.length >= max;
  const isExceeds = personList.length > max;
  const list = personList || [];

  const addPersonToList = useCallback(
    ({ person, positionDetails }) => {
      setFromPosition(positionDetails);
      setToPosition(data);
      setSelectedPerson(person);

      updateRegPositions(positionDetails, data, person);
    },
    [
      data,
      setFromPosition,
      setSelectedPerson,
      setToPosition,
      updateRegPositions,
    ],
  );

  const onDrop = ({ person, positionDetails }) => {
    const { personsAssigned } = data;
    const { personGuid } = person;

    const exists = personsAssigned.some(info => info.personGuid === personGuid);

    if (exists) {
      toastService.error(
        <FormattedMessage id="PositionManager.AddPersonModal.exist" />,
      );
    } else {
      setFromPosition(positionDetails);
      setToPosition(data);
      setSelectedPerson(person);
      setIsDrop(true);
      openExpirePositionModal(true);
    }
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dragType.PERSON,
      drop: item => (isFunctional ? onDrop(item) : addPersonToList(item)),
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [isFunctional, addPersonToList, onDrop],
  );

  const openExpirePosition = (person, positionDetails) => {
    setFromPosition(positionDetails);
    setSelectedPerson(person);
    setIsDrop(false);
    openExpirePositionModal(true);
  };

  const onClickAdd = () => {
    setIsDrop(false);
    onSelectPosition(data);
    openAddPersonModal();
  };

  const addButton = (
    <Button
      ghost
      noBorder
      fitText
      icon={<AddCircleIcon />}
      uppercase
      rightIcon
      onClick={onClickAdd}
    >
      <FormattedMessage id={'shared.add'} />
    </Button>
  );
  return (
    <div className={styles.dragDropContainer}>
      <Row justify="start" type="flex">
        <S size={'4'} className={styles.label}>
          <FormattedMessage id={positionLong} />
          {max < 500
            ? isRequired
              ? ` (${list.length}/${max}*)`
              : ` (${list.length}/${max})`
            : ` (${list.length})`}
        </S>
      </Row>

      {isFunctional
        ? list.map(person => (
            <DragDropPerson
              key={person.personGuid}
              ref={drop}
              person={person}
              onDrop={onDrop}
              isMax={isMax}
              positionDetails={data}
              openExpirePosition={openExpirePosition}
              canIndividualExpire={canIndividualExpire}
              addPersonToList={addPersonToList}
              isFunctional={isFunctional}
            />
          ))
        : personsAssigned.map((person, index) => (
            <DragDropPerson
              key={index}
              ref={drop}
              person={person}
              onDrop={onDrop}
              isMax={false}
              positionDetails={data}
              openExpirePosition={openExpirePosition}
              canIndividualExpire={canIndividualExpire}
              addPersonToList={addPersonToList}
              isFunctional={isFunctional}
            />
          ))}

      {!!isExceeds && (
        <span className={styles.exceeds}>
          <FormattedMessage id="PositionManager.exceeds" />
        </span>
      )}

      {!!(!isFunctional || (isFunctional && !isMax)) && (
        <div
          ref={drop}
          className={cn(styles.dropArea, { [styles.isOver]: isOver })}
        >
          {!!canAddPerson && addButton}
        </div>
      )}
    </div>
  );
};

DragDropPersonList.propTypes = {
  data: PropTypes.object,
  canAddPerson: PropTypes.bool,
  openAddPersonModal: PropTypes.func,
  onSelectPosition: PropTypes.func,
  component: PropTypes.string,
  openExpirePositionModal: PropTypes.func,
  setFromPosition: PropTypes.func,
  setToPosition: PropTypes.func,
  setSelectedPerson: PropTypes.func,
  setIsDrop: PropTypes.func,
  updateRegPositions: PropTypes.func,
  personsAssigned: PropTypes.array,
};

DragDropPersonList.defaultProps = {
  canAddPerson: true,
};

export default DragDropPersonList;
