import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Menu } from '@shared';
import styles from './Nav.less';

const MenuItem = Menu.Item;

class NavMenuItem extends React.PureComponent {
  render() {
    const { className, children, ...rest } = this.props;
    const classNames = cn(styles.navOption, styles.navSection, className);
    return (
      <MenuItem className={classNames} {...rest}>
        {children}
      </MenuItem>
    );
  }
}

NavMenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default NavMenuItem;
