import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import {
  OPEN_RATING_MODAL,
  CLOSE_RATING_MODAL,
  OPEN_BUG_MODAL,
  CLOSE_BUG_MODAL,
  OPEN_FEATURE_MODAL,
  CLOSE_FEATURE_MODAL,
} from './actions';

const ratingModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_RATING_MODAL: {
      return true;
    }
    case CLOSE_RATING_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const bugModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_BUG_MODAL: {
      return true;
    }
    case CLOSE_BUG_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const featureModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_FEATURE_MODAL: {
      return true;
    }
    case CLOSE_FEATURE_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  ratingModalOpen,
  bugModalOpen,
  featureModalOpen,
});

export default resetOnLogoutReducer(reducer);
