import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import ResponsiveTitle from './ResponsiveTitle';
import styles from './T.less';

/**
 * @type {React.FC<import('./T.types').TProps>}
 */
const T = ({
  size,
  hr,
  semibold,
  bold,
  colored,
  colon,
  informative,
  inline,
  inlineBlock,
  noMargin,
  serif,
  className,
  children,
  ...rest
}) => {
  const sizeClassName = `title-${size}`;
  const classNames = cn(
    styles.block,
    sizeClassName,
    {
      'text-primary': colored,
      [styles.hr]: hr,
      [styles.semibold]: semibold,
      [styles.bold]: bold,
      [styles.inline]: inline,
      [styles.inlineBlock]: inlineBlock,
      [styles.noMargin]: noMargin,
      [styles.informativeColor]: informative,
      [styles.serif]: serif,
    },
    className,
  );

  return (
    <span className={classNames} {...rest}>
      {children}
      {colon ? ':' : null}
    </span>
  );
};

T.propTypes = {
  size: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']).isRequired,
  hr: PropTypes.bool,
  semibold: PropTypes.bool,
  bold: PropTypes.bool,
  colored: PropTypes.bool,
  informative: PropTypes.bool,
  colon: PropTypes.bool,
  inline: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  noMargin: PropTypes.bool,
  serif: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

T.defaultProps = {
  hr: false,
  semibold: false,
  bold: false,
  informative: false,
  colored: false,
  colon: false,
  inline: false,
  inlineBlock: false,
  noMargin: false,
  serif: false,
};

T.Responsive = ResponsiveTitle;

export default T;
