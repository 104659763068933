import { ROUTE_PERMISSIONS_MANAGER } from './duck';
import { TabCard } from './components';
import { permissions } from '@shared';

const routes = {
  [ROUTE_PERMISSIONS_MANAGER]: {
    path: '/permissionsManager',
    Component: TabCard,
    titleId: 'page.permissionsManager',
    permission: permissions.VIEW_PERMISSIONS_MANAGER_PAGE,
  },
};

export default routes;
