import React from 'react';

import * as Sentry from '@sentry/react';
import { render } from 'react-dom';
import 'whatwg-fetch';

import { ENABLE_MSW, ENV, VERSION } from './modules/config';
import App from './modules/core/components/App/App';
import './modules/styles/base.less';
import './polyfills';
import configureStore from './root/store';

function prepare() {
  if (ENABLE_MSW && process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser');
    return worker
      .start({
        onUnhandledRequest: 'bypass',
      })
      .then(() => {
        // eslint-disable-next-line no-console
        console.group('MSW Handlers enabled:');
        worker.printHandlers();
        // eslint-disable-next-line no-console
        console.groupEnd();
        return;
      });
  }
  return Promise.resolve();
}

if (ENV !== 'local') {
  Sentry.init({
    dsn: 'https://8e9f2703b82b4cb9852340103294d008@sentry.io/1236100',
    release: VERSION,
    environment: ENV,
    integrations: [
      Sentry.breadcrumbsIntegration({
        beacon: true,
        console: ENV !== 'local',
        dom: true,
        fetch: true,
        history: true,
        sentry: true,
        xhr: true,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      // only enable in prod
      ENV === 'pd' && Sentry.replayIntegration(),
    ].filter(Boolean),
    replaysSessionSampleRate: 0.03,
    replaysOnErrorSampleRate: 0.03,
    tracesSampleRate: 0.03,
    tracePropagationTargets: [
      'localhost',
      'https://advancementsqa.scouting.org/',
    ],
    profilesSampleRate: 0.03,
  });
}

const store = configureStore();
const renderApp = () =>
  render(<App store={store} />, document.getElementById('app'));

prepare().then(renderApp());
