/* eslint-disable react/no-multi-comp */
import React, { Fragment } from 'react';

import ViewListIcon from '@material-ui/icons/ViewList';
import cn from 'classnames';
import { uniq } from 'lodash';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { SBL_4797_SHOW_RENEWAL_ROSTER, SHOW_VALIDATE_EAGLE } from '@config';
import EditSubUnits from '@modules/advancement/packRoster/components/PackRoster/EditSubUnits/EditSubUnits';
import { getIsDenChief } from '@modules/advancement/packRoster/utilsTyped';
import { getPreferredName } from '@modules/shared/utils/preferredName';
import { formatToUIDate } from '@modules/utils/datesLuxon';
import {
  Button,
  PersonAvatar,
  PositionId,
  S,
  Select,
  Spin,
  Switch,
  TableHeadingSort,
  Tag,
  Tooltip,
  denTypesSingleNames as denNames,
  devMockHighlightClassName,
  intl,
  memberTypeLabels,
} from '@shared';
import { programNames } from '@shared/constants';
import { tableSortOrder } from '@utils';

import {
  YOUTH_MEMBER,
  importantPositions,
  renewalStatuses,
} from '../../../constants';
import OtherRanksTooltip from '../OtherRanksTooltip';
import PersonWarning from '../PersonWarning';

const columns = (
  {
    sorter,
    onGoToYouthProfile,
    onGoToAdultProfile,
    isSubUnitAllowed,
    subUnitType,
    approvedSubUnits,
    showSubUnits,
    canEditSubUnits,
    defaultSubUnitList,
    areRenewalsLoading,
    orgIsPack,
    hasSubUnitOnlyPerms,
    mySubUnits,
    organizationGuid: orgGuid,
    onSaveOptOut,
  },
  styles,
  onClickSubUnitHeader,
  onChangeAdultSubuint,
  onClickTypeHeader,
) => {
  const handleChangeSubUnit = userId => value => {
    onChangeAdultSubuint(value, userId);
  };

  const handleSwitchOptout = (personGuid, registrationId) => value => {
    onSaveOptOut({ personGuid, optOutValue: value, registrationId });
  };

  const handleSwitchClick = e => e.stopPropagation();

  const getOptions = subUnitListIds => {
    const filtered = approvedSubUnits
      .filter(subUnit => subUnitListIds.find(id => id === subUnit.subUnitId))
      .map(subUnit => ({
        ...subUnit,
        subUnitName: denNames[subUnit.denType]
          ? `${denNames[subUnit.denType]} ${subUnit.subUnitNameRaw}`
          : subUnit.subUnitName,
      }));

    return filtered;
  };

  const getFormattedData = data => {
    const defaultValues = defaultSubUnitList.find(
      item => item.userId === data.userId,
    );
    let subUnitFound;
    if (defaultValues) {
      subUnitFound = approvedSubUnits.find(
        subUnit => subUnit.subUnitId === defaultValues.subUnitId,
      );
    }

    return {
      ...data,
      ...defaultValues,
      subUnitName: subUnitFound ? subUnitFound.subUnitName : data.subUnitName,
    };
  };

  const getCanViewProfile = (showSubUnits, data, formattedData) => {
    if (hasSubUnitOnlyPerms && !canEditSubUnits) {
      const dataToUse = showSubUnits ? data : formattedData;

      if (dataToUse.subUnitId === -1) return false;

      const hasMatchingSubUnits = mySubUnits.some(sub => {
        const mySubId = sub.denId || sub.patrolId;
        return mySubId === dataToUse.subUnitId;
      });
      return hasMatchingSubUnits;
    }

    return true;
  };

  const validateEagleColumn = SHOW_VALIDATE_EAGLE
    ? [
        {
          render(_text, record) {
            if (record.currentHighestRankApproved.rank === 'Life') {
              return (
                <div className={styles.validateEagleWrapper}>
                  <Button
                    fitText
                    color="success"
                    onClick={e => e.stopPropagation()}
                  >
                    <FormattedMessage id="advancement.PackRoster.validateEagle" />
                  </Button>
                </div>
              );
            }
            return '';
          },
        },
      ]
    : [];

  const subUnitColumn =
    isSubUnitAllowed && approvedSubUnits.length > 0
      ? [
          {
            width: '18rem',
            title: (
              <div className={styles.heading} onClick={onClickSubUnitHeader}>
                <FormattedMessage
                  id={`advancement.PackRoster.column.${subUnitType}`}
                />
                <Tooltip
                  title={
                    <FormattedMessage id="advancement.PackRoster.column.subUnitMessage" />
                  }
                >
                  <ViewListIcon fontSize="small" />
                </Tooltip>
              </div>
            ),
            sorter: false,
            sortOrder: tableSortOrder(sorter, 'subUnitName'),
            dataIndex: 'subUnitName',
            render(subUnitName, data) {
              const formattedData = getFormattedData(data);
              return (
                <div className={styles.subUnitCel}>
                  {formattedData?.subUnitListIds?.length > 1 &&
                  !showSubUnits ? (
                    <Select
                      onChange={handleChangeSubUnit(formattedData.userId)}
                      defaultValue={formattedData.subUnitId}
                    >
                      <Select.OptGroup>
                        {getOptions(formattedData.subUnitListIds).map(
                          ({ subUnitId, subUnitName }) => (
                            <Select.Option
                              key={subUnitId}
                              value={subUnitId}
                              text={subUnitName}
                            >
                              {subUnitName}
                            </Select.Option>
                          ),
                        )}
                      </Select.OptGroup>
                    </Select>
                  ) : (
                    <S size="4" className={styles.subUnitName}>
                      {subUnitName}
                    </S>
                  )}
                  <EditSubUnits
                    data={data}
                    defaultSubUnitList={defaultSubUnitList}
                    approvedSubUnits={approvedSubUnits}
                    hasSubUnitOnlyPerms={hasSubUnitOnlyPerms}
                    canEditSubUnits={canEditSubUnits}
                    mySubUnits={mySubUnits}
                    showSubUnits={showSubUnits}
                  />
                </div>
              );
            },
          },
        ]
      : [];

  const renewalColumns = SBL_4797_SHOW_RENEWAL_ROSTER
    ? [
        {
          width: '14rem',
          title: intl.formatMessage({
            id: 'advancement.PackRoster.column.renewalStatus',
          }),
          dataIndex: 'renewalStatus',
          render(renewalStatus, data) {
            const { registrationId } = data;

            if (areRenewalsLoading && !registrationId) {
              return <Spin size="small" />;
            }

            const isDenChief = getIsDenChief(data);

            return isDenChief && orgIsPack ? (
              <FormattedMessage id="shared.na" />
            ) : (
              renewalStatus
            );
          },
        },
        {
          width: '7rem',
          title: intl.formatMessage({
            id: 'advancement.PackRoster.column.optOut',
          }),
          render(_, { renewalStatus, personGuid, registrationId, canOptOut }) {
            if (!registrationId && areRenewalsLoading) {
              return <Spin size="small" />;
            }

            return registrationId ? (
              <div onClick={handleSwitchClick}>
                <Switch
                  disabled={areRenewalsLoading || !canOptOut}
                  size="small"
                  checked={renewalStatus === renewalStatuses.OPTED_OUT}
                  onChange={handleSwitchOptout(personGuid, registrationId)}
                />
              </div>
            ) : null;
          },
        },
        {
          width: '14rem',
          title: intl.formatMessage({
            id: 'advancement.PackRoster.column.registrationExpireDt',
          }),
          dataIndex: 'registrationExpireDt',
          render(registrationExpireDt, record) {
            const { registrationId, isYearlyMembershipRenewalPaid } = record;

            if (isYearlyMembershipRenewalPaid)
              return (
                <FormattedMessage id="advancement.PackRoster.column.expirationDate.future" />
              );

            if (areRenewalsLoading && !registrationId) {
              return <Spin size="small" />;
            }
            return formatToUIDate(registrationExpireDt);
          },
        },
      ]
    : [];

  return [].concat(
    [
      {
        dataIndex: 'isAdult',
        width: '5rem',
        render(isAdult, { pictureUrl }) {
          return (
            <PersonAvatar
              className={devMockHighlightClassName}
              isAdult={isAdult}
              pictureUrl={pictureUrl}
            />
          );
        },
      },
      {
        key: 'name',
        width: '20rem',
        sorter: true,
        sortOrder: tableSortOrder(sorter, 'name'),
        title: (
          <TableHeadingSort name="name" sorter={sorter}>
            <FormattedMessage id="advancement.PackRoster.column.name" />
          </TableHeadingSort>
        ),
        render(person, data) {
          const formattedData = getFormattedData(data);
          const { userId, isAdult } = person;
          const personPreferredName = getPreferredName(person);
          const showLinkClass = !!userId;

          if (!getCanViewProfile(showSubUnits, data, formattedData)) {
            return (
              <S size="4" bold inlineBlock>
                {personPreferredName}
              </S>
            );
          }

          return (
            <React.Fragment>
              <S
                size="4"
                className={cn({
                  [styles.userName]: showLinkClass,
                })}
                onClick={
                  userId &&
                  (isAdult
                    ? onGoToAdultProfile(person)
                    : onGoToYouthProfile(person))
                }
                colored
                bold
                inlineBlock
              >
                {/* This anchor is to have open in a new tab right click menu */}
                <a
                  href={
                    isAdult
                      ? `/adultProfile/${userId}?organizationGuid=${orgGuid}`
                      : `/youthProfile/${userId}?organizationGuid=${orgGuid}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={e => e.preventDefault()}
                >
                  {personPreferredName}
                </a>
              </S>

              <PersonWarning person={person} />
            </React.Fragment>
          );
        },
      },
      {
        width: '10rem',
        title: intl.formatMessage({
          id: 'advancement.PackRoster.column.memberId',
        }),
        dataIndex: 'personId',
      },
      {
        width: '8rem',
        title: (
          <div className={styles.heading}>
            <FormattedMessage id="advancement.PackRoster.column.memberType" />
            <Tooltip
              title={
                <FormattedMessage id="advancement.PackRoster.column.typeMessage" />
              }
            >
              <ViewListIcon fontSize="small" onClick={onClickTypeHeader} />
            </Tooltip>
          </div>
        ),
        render(_, data) {
          let bgColor = data.age >= 18 ? '#515354' : 'green';
          let customStyle =
            data.age >= 18 ? styles.participantPill : styles.youthPill;
          let label =
            data.age >= 18
              ? memberTypeLabels.participant
              : memberTypeLabels.youth;
          // SBL-4178 V2 returns isAdult = true for youths endpoint
          if (data.isAdult && !data.isUnitParticipant) {
            bgColor = 'orange';
            customStyle = styles.adultPill;
            label =
              data.isParent && !data.isLeader
                ? memberTypeLabels.parent
                : memberTypeLabels.adult;
          }

          return (
            <Tag
              color={bgColor}
              outline={false}
              className={cn(styles.typePill, customStyle)}
            >
              <FormattedMessage id={`advancement.PackRoster.row.${label}`} />
            </Tag>
          );
        },
      },
      {
        width: '7rem',
        title: (
          <TableHeadingSort name="age" sorter={sorter}>
            <FormattedMessage id="advancement.PackRoster.column.age" />
          </TableHeadingSort>
        ),
        dataIndex: 'age',
        sorter: true,
        sortOrder: tableSortOrder(sorter, 'age'),
        render(age) {
          return age;
        },
      },
      {
        width: '18rem',
        key: 'currentHighestRankApproved',
        title: (
          <TableHeadingSort name="currentHighestRankApproved" sorter={sorter}>
            <FormattedMessage id="advancement.PackRoster.column.highestRankApproved" />
          </TableHeadingSort>
        ),
        sorter: true,
        sortOrder: tableSortOrder(sorter, 'currentHighestRankApproved'),
        render(_, data) {
          const {
            rosterCurrentHighestRankApproved = {},
            rosterOtherHighestRanksApproved: otherRanks,
          } = data;
          const isDenChief = getIsDenChief(data);
          return isDenChief && orgIsPack ? (
            <FormattedMessage id="shared.na" />
          ) : (
            <OtherRanksTooltip
              currentRank={rosterCurrentHighestRankApproved}
              otherRanks={otherRanks}
            />
          );
        },
      },
    ],
    subUnitColumn,
    validateEagleColumn,
    [
      {
        width: '14rem',
        title: intl.formatMessage({
          id: 'advancement.PackRoster.column.position',
        }),
        render(_, data) {
          const allwaysDisplay = importantPositions;
          const { positions = [] } = data;
          const isDenChief = getIsDenChief(data);
          const positionsList =
            isDenChief && orgIsPack
              ? positions
                  .filter(pos => pos.positionId === PositionId.DenChief)
                  .map(pos => pos.position)
              : uniq(positions.map(pos => pos.position).filter(pos => pos));
          const mustDisplay = positionsList.filter(pos =>
            allwaysDisplay.includes(pos),
          );
          const minimalDisplay = mustDisplay.length
            ? mustDisplay
            : positionsList.length
            ? [positionsList[positionsList.length - 1]]
            : [];
          const toolTipPositions = positionsList
            .filter(pos => !minimalDisplay.includes(pos))
            .sort();

          return (
            <div>
              {toolTipPositions.length ? (
                <Tooltip
                  placement="bottom"
                  title={toolTipPositions.map(pos => (
                    <Fragment key={pos}>
                      <FormattedHTMLMessage
                        id="advancement.PackRoster.column.positionMessage"
                        values={{
                          position: pos.replaceAll(
                            programNames.BOY_SCOUT,
                            YOUTH_MEMBER,
                          ),
                        }}
                      />
                      <br />
                    </Fragment>
                  ))}
                >
                  <span className={styles.counterContainer}>
                    <span className={styles.counter}>
                      <FormattedMessage
                        id="advancement.PackRoster.column.positionCount"
                        values={{
                          count: toolTipPositions.length,
                        }}
                      />
                    </span>
                  </span>
                </Tooltip>
              ) : null}
              <span>
                {minimalDisplay
                  .toString()
                  .replaceAll(',', ', ')
                  .replaceAll(programNames.BOY_SCOUT, YOUTH_MEMBER)}
              </span>
            </div>
          );
        },
      },
    ],
    renewalColumns,
  );
};

export default columns;
