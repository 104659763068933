import moment from 'moment';
import { combineReducers } from 'redux';

import { SET_ORGANIZATION } from '@context';
import {
  advancementFileStatuses,
  advancementTypes,
  modelDateFormat,
} from '@shared';
import { resetOnLogoutReducer } from '@utils';

import { REMOVE_PARTICIPANT_RESPONSE } from '../../../progress/activities';
import {
  APPROVE_ADVANCEMENTS_DESELECT_PERSON,
  APPROVE_ADVANCEMENTS_RESPONSE,
  DESELECT_COMPLETED_ADVANCEMENT,
  RECORD_ADVANCEMENT_RESPONSE,
  RECORD_CAMPOUTS_RESPONSE,
  RECORD_HIKE_RESPONSE,
  RECORD_SERVICE_RESPONSE,
  UNAPPROVE_ADVANCEMENT_RESPONSE,
  UPDATE_ACTIVITY_RESPONSE,
  UPDATE_ADVANCEMENT_RESPONSE,
} from '../../../progress/duck/actions';
import {
  CANCEL_FILE_ERROR,
  CANCEL_FILE_RESPONSE,
} from '../../advancementUpload';
import { activityItemsTypes } from '../../common';
import {
  activityTypeFilters,
  advancementStatusFilters,
  peopleFilters,
  updatedDateFilters,
} from '../constants';
import {
  ADVANCEMENT_HISTORY_ALREADY_LOADED,
  ADVANCEMENT_HISTORY_ERROR,
  ADVANCEMENT_HISTORY_REQUEST,
  ADVANCEMENT_HISTORY_RESPONSE,
  SET_EXPANDED_KEYS,
  SET_EXPANDED_MAIN_ROW,
  SET_FILTER_CARD_FILTERS,
  SET_SEARCH_FILTER,
  SET_SELECTED_ROWS_KEYS,
  SET_SORTER,
  SET_UPDATED_DATE_FILTER,
} from './actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case ADVANCEMENT_HISTORY_REQUEST: {
      return true;
    }
    case ADVANCEMENT_HISTORY_RESPONSE:
    case ADVANCEMENT_HISTORY_ALREADY_LOADED:
    case ADVANCEMENT_HISTORY_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const items = (state = [], { type, payload }) => {
  switch (type) {
    case ADVANCEMENT_HISTORY_RESPONSE: {
      return payload;
    }
    case ADVANCEMENT_HISTORY_ERROR:
    case RECORD_ADVANCEMENT_RESPONSE:
    case APPROVE_ADVANCEMENTS_RESPONSE:
    case RECORD_CAMPOUTS_RESPONSE:
    case RECORD_HIKE_RESPONSE:
    case RECORD_SERVICE_RESPONSE:
    case SET_ORGANIZATION:
    case UNAPPROVE_ADVANCEMENT_RESPONSE: {
      return [];
    }
    case SET_FILTER_CARD_FILTERS: {
      if (payload.fetchAllRecords) {
        return [];
      }
      return state;
    }
    case UPDATE_ADVANCEMENT_RESPONSE: {
      const date = moment(payload.completionDate).format(modelDateFormat);
      const isAward = payload.advancementType === advancementTypes.AWARDS;

      return state.map(adv =>
        adv.id == payload.id &&
        adv.advancementType == payload.advancementType &&
        adv.userId == payload.userId &&
        (!isAward || adv.userAwardId == payload.userAwardId)
          ? {
              ...adv,
              date,
              dateEarned: adv.dateEarned && date,
              dateCompleted: adv.dateCompleted && date,
            }
          : adv,
      );
    }
    case UPDATE_ACTIVITY_RESPONSE: {
      const { activityId, updatedRecords, userId } = payload;
      return state.map(item =>
        item.activityId == activityId && item.userId == userId
          ? { ...item, activityRecords: updatedRecords }
          : item,
      );
    }
    case CANCEL_FILE_RESPONSE:
    case CANCEL_FILE_ERROR: {
      return state.map(item => {
        if (item.id === payload) {
          return {
            ...item,
            status: advancementFileStatuses.CANCELLED,
          };
        }

        return item;
      });
    }
    case REMOVE_PARTICIPANT_RESPONSE: {
      const { userId, activityId } = payload;
      return state.filter(adv => {
        const removedParticipant =
          adv.userId === userId && adv.activityId === activityId;
        return !removedParticipant;
      });
    }
    default: {
      return state;
    }
  }
};

const expandedKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_EXPANDED_KEYS: {
      return payload;
    }
    case ADVANCEMENT_HISTORY_REQUEST: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const searchFilter = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH_FILTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const updatedDateFilter = (
  state = updatedDateFilters.LAST_30_DAYS,
  { type, payload },
) => {
  switch (type) {
    case SET_FILTER_CARD_FILTERS: {
      return payload.updatedDate;
    }
    case SET_UPDATED_DATE_FILTER: {
      return payload;
    }
    case SET_ORGANIZATION: {
      if (state === updatedDateFilters.ALL) {
        return updatedDateFilters.LAST_365_DAYS;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const defaultAdvancementTypes = {
  [advancementTypes.MERIT_BADGES]: true,
  [advancementTypes.ADVENTURES]: true,
  [advancementTypes.RANKS]: true,
  [advancementTypes.AWARDS]: true,
};

const advancementTypesFilter = (
  state = defaultAdvancementTypes,
  { type, payload },
) => {
  switch (type) {
    case SET_FILTER_CARD_FILTERS: {
      const { advancementTypes } = payload;
      return advancementTypes;
    }
    case SET_ORGANIZATION: {
      return defaultAdvancementTypes;
    }
    default: {
      return state;
    }
  }
};

const showAdvancementFiles = (state = true, { type, payload }) => {
  switch (type) {
    case SET_FILTER_CARD_FILTERS: {
      const { showAdvancementFiles } = payload;
      return showAdvancementFiles;
    }
    default: {
      return state;
    }
  }
};

const defaultAdvancementStatus = {
  [advancementStatusFilters.APPROVED]: true,
  [advancementStatusFilters.AWARDED]: true,
  [advancementStatusFilters.COUNSELOR_APPROVED]: true,
};

const advancementStatusFilter = (
  state = defaultAdvancementStatus,
  { type, payload },
) => {
  switch (type) {
    case SET_FILTER_CARD_FILTERS: {
      const { advancementStatus } = payload;
      return advancementStatus;
    }
    default: {
      return state;
    }
  }
};

const defaultActivityTypesFilter = {
  [activityTypeFilters.SERVICE]: true,
  [activityTypeFilters.CAMPING]: true,
  [activityTypeFilters.HIKING]: true,
  [activityTypeFilters.LONG_CRUISE]: true,
  [activityTypeFilters.EAGLE_SERVICE_PROJECT]: true,
};

const activityTypesFilter = (
  state = defaultActivityTypesFilter,
  { type, payload },
) => {
  switch (type) {
    case SET_FILTER_CARD_FILTERS: {
      const { activityTypes } = payload;
      return activityTypes;
    }
    default: {
      return state;
    }
  }
};

const defaultPeopleFilter = {
  [peopleFilters.YOUTH]: true,
  [peopleFilters.ADULTS]: true,
};

const peopleFilter = (state = defaultPeopleFilter, { type, payload }) => {
  switch (type) {
    case SET_FILTER_CARD_FILTERS: {
      const { people } = payload;
      return people;
    }
    default: {
      return state;
    }
  }
};

const sorter = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SORTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const expandedRow = {
  [activityItemsTypes.PENDING_APPROVAL]: true,
  [activityItemsTypes.HISTORY]: true,
};

const expandedMainRows = (state = expandedRow, { type, payload }) => {
  switch (type) {
    case SET_EXPANDED_MAIN_ROW: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
};

const selectedRowsKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ROWS_KEYS: {
      return payload;
    }
    case APPROVE_ADVANCEMENTS_DESELECT_PERSON: {
      const { activityRowKeys = [] } = payload;
      return state.filter(
        advancementKey => !activityRowKeys.includes(advancementKey),
      );
    }
    case DESELECT_COMPLETED_ADVANCEMENT: {
      return state.filter(rowKey => rowKey != payload.key);
    }
    case APPROVE_ADVANCEMENTS_RESPONSE:
    case UNAPPROVE_ADVANCEMENT_RESPONSE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  loading,
  items,
  expandedKeys,
  searchFilter,
  updatedDateFilter,
  advancementTypesFilter,
  showAdvancementFiles,
  advancementStatusFilter,
  activityTypesFilter,
  peopleFilter,
  sorter,
  expandedMainRows,
  selectedRowsKeys,
});

export default resetOnLogoutReducer(reducer);
