import PropTypes from 'prop-types';

import { Form } from '@shared/components';

const UploadDocumentForm = () => {
  throw new Error('Not implemented anymore');
};

UploadDocumentForm.propTypes = {
  form: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Form.create()(UploadDocumentForm);
