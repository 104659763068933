import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { toastService } from '@toasts';
import { ENABLE_APPLE_SIGN_IN } from '@config';
import { AppleId } from '@utils';

import { appleLoginValidate } from '../../duck';
import styles from './AppleSignInButton.less';

const AppleSignInButton = () => {
  const dispatch = useDispatch();

  const onAppleIdSuccess = useCallback(
    data => {
      dispatch(appleLoginValidate(data.detail.authorization.id_token));
    },
    [dispatch],
  );

  const onAppleIdFailure = useCallback(() => {
    toastService.error(
      <FormattedMessage id="user.LoginPage.error.appleLogin" />,
    );
  }, []);

  useEffect(() => {
    if (ENABLE_APPLE_SIGN_IN) {
      const { protocol, hostname } = window.location;
      const appleId = new AppleId();
      appleId.loadSDK().then(() => {
        appleId.init(
          {
            clientId: 'org.scouting.my',
            scope: 'name email',
            redirectURI: `${protocol}//${hostname}/login`,
            usePopup: true,
          },
          onAppleIdSuccess,
          onAppleIdFailure,
        );
      });
      return () => {
        appleId.cleanUp();
      };
    }
  }, [onAppleIdSuccess, onAppleIdFailure]);

  return ENABLE_APPLE_SIGN_IN ? (
    <div
      id="appleid-signin"
      className={styles.appleLoginButton}
      data-color="light"
      data-border="true"
      data-type="sign in"
    />
  ) : null;
};

export default AppleSignInButton;
