import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

import { personNameBuilder } from '@utils';
import { catchAndReport } from '@utils/rxjs/operators';
import { badgeStatus } from '../constants';
import services from './services';
import {
  COUNSELED_YOUTH_REQUEST,
  counseledYouthResponse,
  responseErr,
} from './actions';

const fetchCounseledYouthEpic$ = action$ =>
  action$.pipe(
    ofType(COUNSELED_YOUTH_REQUEST),
    switchMap(() =>
      services.fetchCounseledYouth$().pipe(
        map(data => {
          const currentData = data.reduce(
            (acc, current) => {
              current.meritBadges.forEach(badge => {
                const itemType =
                  badgeStatus.STARTED === badge.status
                    ? 'startedItems'
                    : 'pendingItems';

                const currentYouth = acc[itemType][current.userId];

                if (currentYouth) {
                  return currentYouth.meritBadges.push(badge);
                } else {
                  return (acc[itemType][current.userId] = {
                    ...current,
                    name: personNameBuilder.short(current),
                    meritBadges: [badge],
                  });
                }
              });
              return acc;
            },
            {
              pendingItems: {},
              startedItems: {},
            },
          );

          const pendingItems = Object.values(currentData.pendingItems).reduce(
            (acc, current) => [
              ...acc,
              ...current.meritBadges.map(badge => ({
                ...current,
                key: `${badge.name}-${current.personGuid}`,
                meritBadges: [badge],
              })),
            ],
            [],
          );

          return counseledYouthResponse({
            pendingItems,
            startedItems: Object.values(currentData.startedItems),
          });
        }),
        catchAndReport(err => Observable.of(responseErr(err))),
      ),
    ),
  );

export default combineEpics(fetchCounseledYouthEpic$);
