const resetOnActions =
  (reducer, ...actionTypes) =>
  (state, action = {}) => {
    if (actionTypes.includes(action.type)) {
      state = undefined;
    }

    return reducer(state, action);
  };

export default resetOnActions;
