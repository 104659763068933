import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared';

import { setSelectedActivity } from '../../duck';

const EditActivityButton = ({
  activityData,
  getEditActivityData,
  allowEditPersons,
  afterLoadCallBack,
  processBeforeOpen,
  duplicate: duplicateActivity = false,
  onClick,
  activityId,
  disabled,
  className,
  loading,
  children,
  type,
  onLoadAction,
  onOpenAction,
  ...props
}) => {
  const dispatch = useDispatch();

  const handleClick = useCallback(
    async event => {
      event.stopPropagation();
      if (activityId) {
        dispatch(
          onLoadAction({
            activityId,
            afterLoadCallBack,
            processBeforeOpen,
            duplicateActivity,
          }),
        );
      } else {
        const editActivityData = activityData || (await getEditActivityData());
        dispatch(onOpenAction(allowEditPersons));
        dispatch(setSelectedActivity(editActivityData));
      }
      onClick && onClick();
    },
    [
      dispatch,
      activityId,
      allowEditPersons,
      afterLoadCallBack,
      processBeforeOpen,
      duplicateActivity,
      onLoadAction,
      onOpenAction,
      activityData,
      getEditActivityData,
      onClick,
    ],
  );

  return (
    <Button
      type={type}
      disabled={disabled}
      loading={loading}
      className={className}
      onClick={handleClick}
      {...props}
    >
      {children || <FormattedMessage id="shared.edit" />}
    </Button>
  );
};

EditActivityButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  activityData: PropTypes.object,
  activityId: PropTypes.number,
  loading: PropTypes.bool,
  getEditActivityData: PropTypes.func,
  allowEditPersons: PropTypes.bool,
  afterLoadCallBack: PropTypes.func,
  processBeforeOpen: PropTypes.func,
  duplicate: PropTypes.bool,
  onLoadAction: PropTypes.func,
  onOpenAction: PropTypes.func,
};

EditActivityButton.defaultProps = {
  type: 'link',
  getEditActivityData: () => {},
};

export default EditActivityButton;
