import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { SET_ORGANIZATION } from '@context';
import {
  BASIC_REPORTS_REQUEST,
  BASIC_REPORTS_RESPONSE,
  BASIC_REPORTS_ERROR,
  OPEN_SELECT_YOUTH_MODAL,
  CLOSE_SELECT_YOUTH_MODAL,
} from './actions';

const reports = (state = [], { type, payload }) => {
  switch (type) {
    case BASIC_REPORTS_RESPONSE: {
      const { reports } = payload;
      return reports;
    }
    case SET_ORGANIZATION:
    case BASIC_REPORTS_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const responseReportOrgGuid = (state = null, { type, payload }) => {
  switch (type) {
    case BASIC_REPORTS_RESPONSE: {
      const { organizationGuid } = payload;
      return organizationGuid;
    }
    case SET_ORGANIZATION:
    case BASIC_REPORTS_ERROR: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case SET_ORGANIZATION:
    case BASIC_REPORTS_REQUEST: {
      return true;
    }
    case BASIC_REPORTS_RESPONSE:
    case BASIC_REPORTS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectYouthModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_SELECT_YOUTH_MODAL: {
      return true;
    }
    case CLOSE_SELECT_YOUTH_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedReportCode = (state = '', { type, payload }) => {
  switch (type) {
    case OPEN_SELECT_YOUTH_MODAL: {
      return payload;
    }
    case CLOSE_SELECT_YOUTH_MODAL: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  reports,
  loading,
  selectYouthModalOpen,
  selectedReportCode,
  responseReportOrgGuid,
});

export default resetOnLogoutReducer(reducer);
