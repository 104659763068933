/**
 * Generic Array sort functions.
 * Can also be used with the <Table> component of antd.
 * You can pass them as the 'sorter' property of column config.
 *
 * @see https://ant.design/components/table/#Column
 * @example
 * // standard usage
 * stable(myArray, sorter.text('name'))
 * @example
 * // <Table> usage
 * sort: sorter.date('date')
 */
import { clone } from 'lodash';
import get from 'lodash/get';
import moment from 'moment';

/**
 * Composes sorter functions to achieve arbitrary multi-column sorting.
 * Mix and match any sorter functions and they will be applied in order.
 *
 * @example
 * const multiColSorter = compose(text('name'), number('age'))
 * stable(myArray, multiColSorter)
 * @param {...Function} sorters - sorter functions with column key pre-applied (i.e. "text('name')", not "text")
 */
const compose =
  (...sorters) =>
  (a, b) =>
    sorters.reduce((ret, sorter) => {
      if (ret !== 0) {
        return ret;
      }

      return sorter(a, b);
    }, 0);

const text =
  (key, reverse = false) =>
  (a, b) => {
    const aText = get(a, key);
    const bText = get(b, key);

    const comparison = (aText || '').localeCompare(bText || '', undefined, {
      numeric: true,
    });

    return reverse ? -comparison : comparison;
  };

const textMulti =
  (...keys) =>
  (a, b) =>
    keys.reduce((ret, key) => {
      if (ret !== 0) {
        return ret;
      }

      return text(key)(a, b);
    }, 0);

const number =
  (key, reverse = false) =>
  (a, b) => {
    const aNumber = get(a, key);
    const bNumber = get(b, key);

    const comparison = (aNumber || 0) - (bNumber || 0);

    return reverse ? -comparison : comparison;
  };

const rosterAge =
  (key, reverse = false) =>
  (a, b) => {
    const cloneA = clone(a);
    const cloneB = clone(b);
    const aNumber = get(cloneA, key);
    const bNumber = get(cloneB, key);
    cloneA[key] =
      typeof aNumber === 'string'
        ? aNumber.includes('21+')
          ? 30
          : 29
        : aNumber;
    cloneB[key] =
      typeof bNumber === 'string'
        ? bNumber.includes('21+')
          ? 30
          : 29
        : bNumber;
    return number(key, reverse)(cloneA, cloneB);
  };

const date =
  (key, reverse = false) =>
  (a, b) => {
    const valueA = moment(get(a, key) || '1910-02-08');
    const valueB = moment(get(b, key) || '1910-02-08');
    let comparison = 0;

    if (valueA.isBefore(valueB)) {
      comparison = -1;
    } else if (valueA.isAfter(valueB)) {
      comparison = 1;
    }

    return reverse ? -comparison : comparison;
  };

export default {
  compose,
  string: text,
  stringMulti: textMulti,
  text,
  textMulti,
  number,
  date,
  rosterAge,
};
