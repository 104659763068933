import React, { useCallback, useState } from 'react';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Col,
  DetailItem,
  Form,
  Row,
  S,
  Select,
} from '@shared/components';
import { dtoDateFormat, viewDateFormat } from '@shared/constants';
import { intl } from '@shared/localization';
import { validateForm } from '@utils';

import { unitInfoIds } from '../../../../constants';
import {
  editTermRequest,
  futureCharterSel,
  isLoadingEditTermSel,
  setSelectedTerm,
} from '../../../../duck';
import styles from './EditTermForm.less';

//Options form 6 to 18
const termOptions = [];
for (let i = 0; i < 13; i++) {
  const num = i + 6;
  termOptions[i] = { label: `${num} months`, value: num };
}

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const EditTermForm = ({ form }) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const isLoadingEditTerm = useSelector(isLoadingEditTermSel);
  const futureCharter = useSelector(futureCharterSel);
  const [termSelected, setTermSelected] = useState(futureCharter.term);
  const [newExpiryDt, setNewExpiryDt] = useState(futureCharter.expiryDt);

  const requiredRule = {
    required: true,
    message: intl.formatMessage({
      id: 'shared.form.error.isRequired',
    }),
  };

  const validate = useCallback(() => validateForm(form), [form]);

  const handleSaveTerm = useCallback(async () => {
    const validData = await validate();
    if (validData) {
      const { communityGuid, effectiveDt, paymentTypeId } = futureCharter;
      const data = {
        communityGuid,
        term: termSelected,
        effectiveDt,
        expiryDt: newExpiryDt,
        paymentTypeId,
      };
      dispatch(setSelectedTerm(termSelected));
      dispatch(editTermRequest(data));
    }
  }, [dispatch, validate, futureCharter, termSelected, newExpiryDt]);

  const handleSelectTerm = useCallback(
    value => {
      setTermSelected(value);
      const calculatedExpiryDt = moment(futureCharter.effectiveDt)
        .subtract(1, 'day')
        .add(value, 'months')
        .format(dtoDateFormat);
      setNewExpiryDt(calculatedExpiryDt);
    },
    [futureCharter.effectiveDt],
  );

  return (
    <React.Fragment>
      <Row>
        <S size="5" className={styles.warningLabel}>
          <b>Warning: </b>
          <FormattedMessage id="recharterModals.editTermModal.warning" />
        </S>
      </Row>
      <Row>
        <S size="4" className={styles.greyLabel}>
          <FormattedMessage id="recharterModals.editTermModal.subTitle" />
        </S>
      </Row>

      <Row type="flex" justify="center">
        <Col span={18}>
          <FormItem>
            {getFieldDecorator('term', {
              initialValue: termSelected,
              rules: [requiredRule],
            })(
              <Select
                className={styles.graySelect}
                placeholder={intl.formatMessage({
                  id: 'recharterModals.editTermModal.term',
                })}
                size="large"
                disabled={isLoadingEditTerm}
                onSelect={handleSelectTerm}
                rounded
                floatingLabel
              >
                <OptGroup
                  key="term"
                  label={
                    <FormattedMessage id="recharterModals.editTermModal.term" />
                  }
                >
                  {termOptions.map(({ value, label }, i) => (
                    <Option key={`term_item_${i}`} value={value} text={label}>
                      {label}
                    </Option>
                  ))}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col span={18}>
          <DetailItem
            className={styles.detailLabel}
            label={<FormattedMessage id={unitInfoIds.newExpiredDate} />}
          >
            {moment(newExpiryDt).format(viewDateFormat)}
          </DetailItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Button
          loading={isLoadingEditTerm}
          className={styles.button}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={false}
          onClick={handleSaveTerm}
        >
          <FormattedMessage id="recharterModals.editTermModal.save" />
        </Button>
      </Row>
    </React.Fragment>
  );
};

EditTermForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create()(EditTermForm);
