import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeAddMembersModal,
  futureCharterSel,
  isAddMembersModalVisible,
  isNewMemberApplicationSel,
} from '../../../duck';
import AddMembersForm from './AddMembersForm/AddMembersForm';
import styles from './AddMembersModal.less';

const AddMembersModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isAddMembersModalVisible);
  const isNewMemberApplication = useSelector(isNewMemberApplicationSel);
  const { effectiveDt } = useSelector(futureCharterSel);

  const handleCloseAddMembersModal = () => dispatch(closeAddMembersModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: isNewMemberApplication
          ? 'recharterModals.addMembersModal.title'
          : 'recharterModals.addMembersModal.existingMember.title',
      })}
      onCancel={handleCloseAddMembersModal}
      onBack={handleCloseAddMembersModal}
    >
      <AddMembersForm
        effectiveDt={effectiveDt}
        isNewMemberApplication={isNewMemberApplication}
      />
    </Modal>
  );
};

export default AddMembersModal;
