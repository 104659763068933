/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { ErrorMessage } from '@hookform/error-message';

import {
  Row,
  Col,
  // constants
  emptyObj,
  // utils
  intl,
} from '@shared';

import styles from '../ActivityValueInput.less';

// eslint-disable-next-line react/display-name
const InputMemo = React.memo(
  ({
    valueName,
    className,
    rules,
    register,
    errors,
    error,
    hasError,
    setValue,
    getValues,
    noMargin,
    defaultValue,
    typeError,
    ...rest
  }) => {
    const { name, disabled } = rest;
    const addOrSubtract = useCallback(
      value => () => {
        if (!disabled) {
          const currentValue = getValues(name);
          const nextValue = Number(currentValue) + value;
          if (nextValue >= 0) {
            setValue(name, nextValue);
          }
        }
      },
      [setValue, getValues, name, disabled],
    );

    const message = useMemo(() => {
      if (hasError) {
        return error.message
          ? error.message
          : intl.formatMessage({
              id: `progress.activity.ActivityTabs.error.${typeError}`,
            });
      }
    }, [hasError, error, typeError]);

    return (
      <div
        className={cn(styles.container, className, {
          [styles.noMargin]: noMargin,
        })}
      >
        <Row
          type="flex"
          align="middle"
          className={cn(styles.rowContainer, { [styles.error]: hasError })}
        >
          {disabled && <div className={styles.disabledMask} />}
          <Col span={12}>
            <span className={styles.valueName}>{valueName}</span>
          </Col>
          <Col span={12}>
            <Row type="flex" align="middle" className={styles.inputContainer}>
              <Col span={6}>
                <span
                  className={styles.addOrSubtract}
                  onClick={addOrSubtract(-1)}
                >
                  <RemoveCircleIcon />
                </span>
              </Col>
              <Col span={12}>
                <input
                  className={styles.valueInput}
                  type="text"
                  name={name}
                  ref={register({ ...(rules || emptyObj) })}
                  defaultValue={defaultValue}
                />
              </Col>
              <Col span={6}>
                <span
                  className={styles.addOrSubtract}
                  onClick={addOrSubtract(1)}
                >
                  <AddCircleIcon />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <ErrorMessage
          as="div"
          className={styles.errorDescription}
          errors={errors}
          name={name}
          message={message}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.formState.isDirty === nextProps.formState.isDirty &&
    prevProps.defaultValue === nextProps.defaultValue &&
    prevProps.hasError === nextProps.hasError &&
    prevProps.isActive === nextProps.isActive &&
    prevProps.typeError === nextProps.typeError,
);

InputMemo.propTypes = {
  valueName: PropTypes.node.isRequired,
  hasError: PropTypes.bool,
  typeError: PropTypes.string,
  error: PropTypes.object,
  errorDescription: PropTypes.node,
  className: PropTypes.string,
  rules: PropTypes.object,
  noMargin: PropTypes.bool,
};

InputMemo.defaultProps = {
  rules: {},
};

export default InputMemo;
