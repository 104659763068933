import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Button, SingleConfirmationModal } from '@shared';
import styles from './MainRow.less';

function MainRowButtons({ deleteConfirmationMessage, onDelete, onEdit }) {
  const closeDeleteModalRef = useRef();
  const handleConfirmationDeleteModal = useCallback(() => {
    closeDeleteModalRef.current.openConfirmationModal();
  }, []);

  return (
    <div className={styles.buttonsWrapper}>
      <SingleConfirmationModal
        ref={closeDeleteModalRef}
        message={deleteConfirmationMessage}
        onConfirm={onDelete}
        roundedModal
      />
      <Button
        className={styles.button}
        fitText
        icon={<EditIcon />}
        ghost
        noBorder
        uppercase={false}
        onClick={onEdit}
      >
        <FormattedMessage id="packRoster.MainRow.editSubUnit" />
      </Button>
      <Button
        className={styles.button}
        fitText
        icon={<DeleteIcon />}
        ghost
        noBorder
        uppercase={false}
        onClick={handleConfirmationDeleteModal}
      >
        <FormattedMessage id="packRoster.MainRow.deleteSubUnit" />
      </Button>
    </div>
  );
}

MainRowButtons.propTypes = {
  deleteConfirmationMessage: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

MainRowButtons.defaultProps = {
  onDelete: () => {},
  onEdit: () => {},
};

export default MainRowButtons;
