const injectMainRow = (list, mainRows) => {
  const [firstItem, ...rest] = list;
  return [{ ...firstItem, mainRows }, ...rest];
};

const getMainRow = item => ({
  isMainRow: true,
  ...item,
});

const getSectionItems = (items, mainRowsMissing) => {
  const { subRows, ...restItem } = items;
  if (!subRows || subRows.length == 0) {
    return items.hideIfEmpty
      ? mainRowsMissing
      : [...mainRowsMissing, getMainRow(restItem)];
  }

  if (!items.expanded) {
    return [...mainRowsMissing, getMainRow(restItem)];
  }

  return injectMainRow(items.subRows, [
    ...mainRowsMissing,
    getMainRow(restItem),
  ]);
};

/**
 * Processes the items with mainRows sections
 *
 * @param {Object[]} items - list of the mainRows items with their subRows (regular items)
 * @param {string} items[].key - key used for React purposes
 * @param {boolean} items[].isMainRow - flag used to identified mainRows only
 * @param {Object[]} items[].subRows - since 'children' is already used for the ant-table Component it is renamed here
 * @param {boolean} items[].hideIfEmpty - if TRUE the mainRow won't appear when there are no 'subRows' items to render
 * @param {boolean} items[].expanded - if TRUE, the subRows items won't be displayed
 * @returns {Object[]} all items and subRows items processed into a linear list
 * with the 'mainRow' info at the beginning of each subRows subList
 *
 */
export const getItemsPresentation = items => {
  if (!items || items.length == 0) {
    return [];
  }

  return items.reduce((totalItems, itemsSection) => {
    let mainRowsMissing = [];

    while (
      totalItems.length > 0 &&
      totalItems[totalItems.length - 1].isMainRow
    ) {
      mainRowsMissing.push(totalItems.pop());
    }

    totalItems.push(
      ...getSectionItems(itemsSection, mainRowsMissing.reverse()),
    );
    return totalItems;
  }, []);
};
