export default (endpoint, params) => {
  Object.keys(params).forEach(key => {
    if (!endpoint.includes(key + '=')) {
      const value = params[key];
      const separator = endpoint.includes('?') ? '&' : '?';
      endpoint += `${separator}${key}=${value}`;
    }
  });

  return endpoint;
};
