import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared';

class CancelFileButton extends React.PureComponent {
  handleClick = () => {
    const { id, onClick } = this.props;
    onClick(id);
  };

  render = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick,
      ...rest
    } = this.props;

    return (
      <Button
        ghost
        noBorder
        fitText
        color="scouting-red"
        onClick={this.handleClick}
        {...rest}
      >
        <FormattedMessage id="shared.cancel" />
      </Button>
    );
  };
}

CancelFileButton.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CancelFileButton;
