import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeMarkAsMultipleModal,
  isMarkAsMultipleModalVisible,
} from '../../../duck';
import MarkAsMultipleForm from './MarkAsMultipleForm/MarkAsMultipleForm';
import styles from './MarkAsMultipleModal.less';

const MarkAsMultipleModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isMarkAsMultipleModalVisible);
  const handleCloseMarkAsMultipleModal = () =>
    dispatch(closeMarkAsMultipleModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.markAsMultipleModal.title',
      })}
      onCancel={handleCloseMarkAsMultipleModal}
      onBack={handleCloseMarkAsMultipleModal}
    >
      <MarkAsMultipleForm />
    </Modal>
  );
};

export default MarkAsMultipleModal;
