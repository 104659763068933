import React, { useCallback, useEffect, useMemo } from 'react';

import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { useUnitPendingLeadershipPositions } from '@modules/leadershipPositions/hooks';
import { useGetOrgAdvancementsDashboardQuery } from '@modules/rtk/esb-api';
import {
  setNotificationsVisible,
  setValue,
} from '@modules/userNotifications/duck/slice';
import { computeNotificationsCount } from '@modules/userNotifications/utils';
import { isMobileSel } from '@screen';
import { Badge, Modal, Popover } from '@shared/components';
import {
  advancementHistoryRequestIfNotLoaded,
  isParentOrYouthMemberSel,
  isScoutbookRoleSel,
  organizationGuidSel,
} from '@shared/duck';

import { badgeCountSel, notificationsVisibleSel } from '../../duck';
import styles from './Notifications.less';
import NotificationsContent from './NotificationsContent';
import NotificationsModalContent from './NotificationsModalContent';

interface NotificationsProps {
  className?: string;
  visible: boolean;
  count: number;
  isMobile: boolean;
  isParentOryouthMember: boolean;
  isScoutbookRole: boolean;
  onLoadNotifications: () => void;
  onSetVisible: (visible: boolean) => void;
}

const Notifications: React.FC<NotificationsProps> = props => {
  const {
    isMobile,
    visible,
    count,
    isParentOryouthMember,
    isScoutbookRole,
    onLoadNotifications,
  } = props;

  useEffect(() => {
    onLoadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isParentOryouthMember || isScoutbookRole) {
    return null;
  }

  const handleVisibleChange = (visible: boolean) => {
    props.onSetVisible(visible);
  };

  const handleOpen = () => {
    if (props.count > 0) {
      handleVisibleChange(true);
    }
  };

  const renderIcon = () => {
    const { count, className } = props;
    return (
      <div
        className={cn(styles.wrapper, className, {
          [styles.noNotifications]: count == 0,
        })}
        onClick={handleOpen}
      >
        <Badge count={count} className={styles.badgeCount}>
          {count > 0 ? (
            <NotificationsNoneIcon className={styles.notificationIcon} />
          ) : (
            <NotificationsOffIcon className={styles.notificationsOffIcon} />
          )}
        </Badge>
      </div>
    );
  };

  const handleClose = () => handleVisibleChange(false);

  if (!isMobile) {
    return (
      <span className={styles.popoverParent}>
        <Popover
          noArrow
          arrowPointAtCenter
          content={
            count > 0 ? (
              <NotificationsContent />
            ) : (
              <div className={styles.noNotificationsContent}>
                <FormattedMessage id="userNotifications.Notifications.noNotificationsMessage" />
              </div>
            )
          }
          trigger="click"
          placement="bottomRight"
          visible={visible}
          getPopupContainer={({ parentNode }) => parentNode as HTMLElement}
          onVisibleChange={handleVisibleChange}
        >
          {renderIcon()}
        </Popover>
      </span>
    );
  } else {
    return (
      <React.Fragment>
        <Modal mask={false} visible={visible} onCancel={handleClose}>
          <NotificationsModalContent onClose={handleClose} />
        </Modal>
        {renderIcon()}
      </React.Fragment>
    );
  }
};

Notifications.propTypes = {
  className: PropTypes.string,
  // Provided by component itself
  isMobile: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  onLoadNotifications: PropTypes.func.isRequired,
  onSetVisible: PropTypes.func.isRequired,
  isParentOryouthMember: PropTypes.bool.isRequired,
  isScoutbookRole: PropTypes.bool.isRequired,
};

const NotificationsContainer: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileSel);
  const { pendingCount: pendingPositionsCount } =
    useUnitPendingLeadershipPositions();
  const _count = useSelector(badgeCountSel);
  const count = _count + pendingPositionsCount;
  const visible = useSelector(notificationsVisibleSel);
  const isParentOryouthMember = useSelector(isParentOrYouthMemberSel);
  const isScoutbookRole = useSelector(isScoutbookRoleSel);
  const enableNewCounting = featureFlags.getFlag('SBL_5150_DASHBOARD_API');
  const onLoadNotifications = useCallback(() => {
    if (enableNewCounting) return;
    dispatch(advancementHistoryRequestIfNotLoaded());
  }, [dispatch, enableNewCounting]);
  const orgGuid = useSelector(organizationGuidSel);

  const { data: stats } = useGetOrgAdvancementsDashboardQuery(
    { organizationGuid: orgGuid },
    {
      skip: !orgGuid || !enableNewCounting,
    },
  );

  const onSetVisible = useCallback(
    (visible: boolean) => dispatch(setNotificationsVisible(visible)),
    [dispatch],
  );

  const counts = useMemo(
    () =>
      computeNotificationsCount({
        stats,
        positionsCount: pendingPositionsCount,
      }),
    [stats, pendingPositionsCount],
  );

  useEffect(() => {
    if (!enableNewCounting) return;

    dispatch(
      setValue({
        dashboardStats: stats,
        toPurchaseCount: counts.toPurchaseCount,
        toApproveCount: counts.toApproveCount,
        positionsCount: counts.positionsCount,
        totalCount: counts.totalCount,
      }),
    );
  }, [dispatch, stats, counts, enableNewCounting]);

  const finalCount = enableNewCounting ? counts.totalCount : count;

  return (
    <Notifications
      onLoadNotifications={onLoadNotifications}
      onSetVisible={onSetVisible}
      isMobile={isMobile}
      count={finalCount}
      visible={visible}
      isParentOryouthMember={isParentOryouthMember}
      isScoutbookRole={isScoutbookRole}
    />
  );
};

export default NotificationsContainer;
