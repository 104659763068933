import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeSelectAddMembersFlowModal,
  isSelectAddMembersFlowModalVisible,
  openAddMembersModal,
} from '../../../duck';
import styles from './SelectAddMembersFlowModal.less';

const SelectAddMembersFlowModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isSelectAddMembersFlowModalVisible);

  const handleCloseAddMembersModal = () =>
    dispatch(closeSelectAddMembersFlowModal());

  const handleOpenAddMembersModal = isNewMemberApplication => () =>
    dispatch(openAddMembersModal(isNewMemberApplication));

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.selectAddMembersFlowModal.title',
      })}
      onCancel={handleCloseAddMembersModal}
      onBack={handleCloseAddMembersModal}
    >
      <div className={styles.buttonsContainer}>
        <div className={styles.leftButtonContainer}>
          <Button
            type="primary"
            shape="round"
            size="large"
            shadow
            block
            onClick={handleOpenAddMembersModal(true)}
          >
            <FormattedMessage id="recharterModals.selectAddMembersFlowModal.newMember" />
          </Button>
        </div>
        <div className={styles.rightButtonContainer}>
          <Button
            type="primary"
            shape="round"
            size="large"
            shadow
            block
            onClick={handleOpenAddMembersModal(false)}
          >
            <FormattedMessage id="recharterModals.selectAddMembersFlowModal.existingMember" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectAddMembersFlowModal;
