import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { Button, S } from '@shared';
import styles from './UploadStats.less';

class UploadStats extends React.PureComponent {
  render() {
    const { className, uploadedCount, onNavigateToHistory } = this.props;

    return (
      <div className={cn(styles.container, className)}>
        <S size="3">
          {uploadedCount > 0 ? (
            <FormattedMessage
              id="advancementUpload.UploadStats.uploads"
              values={{ count: uploadedCount }}
            />
          ) : (
            <FormattedMessage id="advancementUpload.UploadStats.noUploads" />
          )}
        </S>
        <div>
          <S size="5">
            <FormattedMessage id="advancementUpload.UploadStats.processing" />
          </S>
          <S size="5">
            <FormattedMessage id="advancementUpload.UploadStats.visitTab" />
          </S>
        </div>
        <Button
          color="scouting-warm-gray"
          bold
          fitText
          uppercase={false}
          onClick={onNavigateToHistory}
        >
          <FormattedMessage id="advancementUpload.UploadStats.checkLog" />
        </Button>
      </div>
    );
  }
}

UploadStats.propTypes = {
  className: PropTypes.string,
  uploadedCount: PropTypes.number.isRequired,
  onNavigateToHistory: PropTypes.func.isRequired,
};

export default UploadStats;
