import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import cn from 'classnames';

import styles from './Currency.less';

const Currency = ({ value, symbolSpace, code, className }) => (
  <span className={cn(styles.container, className)}>
    ${symbolSpace && ' '}
    <FormattedNumber
      style="decimal"
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      value={value || 0}
    />
    {code && ' USD'}
  </span>
);

Currency.propTypes = {
  value: PropTypes.number,
  symbolSpace: PropTypes.bool,
  code: PropTypes.bool,
  className: PropTypes.string,
};

export default Currency;
