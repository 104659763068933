import { dateAndTimeToDateTime } from '@utils';

import {
  activityTypeIds,
  saveActivity$,
  mapPersonValuesToDto,
  activityValueTypes,
  normalizeAdvancedValues,
} from '../../common';
import { notApplicableCategoryId } from '../constants';

const saveHikeActivity$ = data => {
  const {
    // selectors data
    persons,
    organizationGuid,
    loggedInUserId,
    isAdvancedMode,
    isPersonalActivity = false,
    // form values
    startDate,
    startTime,
    endDate,
    endTime,
    highestPointAdult,
    highestPointYouth,
    lowestPointAdult,
    lowestPointYouth,
    // basic values
    milesPerAdult,
    milesPerYouth,
    // advanced values
    adultHighestPoint,
    adultLowestPoint,
    adultMiles,
    youthHighestPoint,
    youthLowestPoint,
    youthMiles,
    isApproved,
  } = data;
  const startDateTime = dateAndTimeToDateTime(startDate, startTime);
  const endDateTime = dateAndTimeToDateTime(endDate, endTime);
  const personValues = mapPersonValuesToDto({
    persons,
    organizationGuid,
    loggedInUserId,
    isAdvancedMode,
    isApproved,
    basicAdultValues: {
      [activityValueTypes.MILES]: milesPerAdult,
      [activityValueTypes.HIGHEST_POINT]: highestPointAdult,
      [activityValueTypes.LOWEST_POINT]: lowestPointAdult,
    },
    basicYouthValues: {
      [activityValueTypes.MILES]: milesPerYouth,
      [activityValueTypes.HIGHEST_POINT]: highestPointYouth,
      [activityValueTypes.LOWEST_POINT]: lowestPointYouth,
    },
    advancedValues: {
      [activityValueTypes.MILES]: {
        ...normalizeAdvancedValues(youthMiles),
        ...normalizeAdvancedValues(adultMiles),
      },
      [activityValueTypes.HIGHEST_POINT]: {
        ...normalizeAdvancedValues(youthHighestPoint),
        ...normalizeAdvancedValues(adultHighestPoint),
      },
      [activityValueTypes.LOWEST_POINT]: {
        ...normalizeAdvancedValues(youthLowestPoint),
        ...normalizeAdvancedValues(adultLowestPoint),
      },
    },
  });
  const processedData = {
    ...data,
    ...personValues,
    loggedInUserId,
    organizationGuid,
    activityTypeId: activityTypeIds.HIKES,
    startDateTime,
    categoryId: notApplicableCategoryId,
    endDateTime,
    hostOrganizationGuid: organizationGuid,
    isPersonalActivity,
    isEveryChildOrg: false,
  };
  return saveActivity$(processedData);
};

export { saveHikeActivity$ };
