import merge from 'lodash/merge';

const key = 'USER_PREFERENCES';

/**
 * Stores the preferences in the localStorage for a user if no featureName is provided, if so saves only the new feature's preferences
 * @param {Object} preferences - Set of preferences to save
 * @param {String} personGuid - The user's personGuid
 * @param {String} featureName - The key to save the new preferences in the corresponding `personGuid.featureName` attr.
 */
const storePreferences = ({ preferences, personGuid, featureName }) => {
  const data = JSON.parse(localStorage.getItem(key) || '{}');
  const userPreferences = featureName
    ? {
        [personGuid]: { [featureName]: preferences },
      }
    : { [personGuid]: preferences };
  const dataUpdated = merge(data, userPreferences);
  localStorage.setItem(key, JSON.stringify(dataUpdated));
};

/**
 * Retrieves the userPrerences if no featureName is provided, if so return the preferences for that `personGuid.featureName`
 * @param {String} personGuid - The user's personGuid
 * @param {String} featureName - The key to retrieve that set of preferences in specific
 */
const retrievePreferences = ({ personGuid, featureName }) => {
  const data = localStorage.getItem(key) || '{}';
  const personPreferences = JSON.parse(data)[personGuid] || {};
  return featureName ? personPreferences[featureName] : personPreferences;
};

export default { storePreferences, retrievePreferences };
