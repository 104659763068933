import React from 'react';

import { EditActivityButton } from '../../../common';
import { openEditService, loadAndOpenServiceModal } from '../../duck';

const EditServiceButton = props => (
  <EditActivityButton
    onLoadAction={loadAndOpenServiceModal}
    onOpenAction={openEditService}
    {...props}
  />
);

export default EditServiceButton;
