import _, {
  filter,
  find,
  flatten,
  isEmpty,
  isNil,
  isNull,
  isUndefined,
  map,
  sortBy,
} from 'lodash';
import moment from 'moment';

import {
  PositionId,
  advancementTypes,
  unapproveAdvancementTypes,
  unitTypeIdForUnitType,
} from '@shared/constants';
import formatDate from '@shared/utils/formatDate';

const getAdvancementId = (advancement, advancementType = '') => {
  advancementType = advancementType || advancement.advancementType;
  switch (advancementType) {
    case advancementTypes.ADVENTURES: {
      return advancement.adventureId || advancement.id;
    }
    case advancementTypes.AWARDS: {
      return advancement.awardId || advancement.id;
    }
    case advancementTypes.MERIT_BADGES: {
      return advancement.id;
    }
    case advancementTypes.RANKS: {
      return advancement.id;
    }
    case advancementTypes.SSELECTIVES: {
      return advancement.ssElectiveId;
    }
  }
};

export const getAdvancementUid = (advancement, advancementType = '') => {
  advancementType = advancementType || advancement.advancementType;
  const advancementId = getAdvancementId(advancement, advancementType);
  switch (advancementType) {
    case advancementTypes.ADVENTURES: {
      return `${advancementTypes.ADVENTURES}_${advancementId}`;
    }
    case advancementTypes.AWARDS: {
      return `${advancementTypes.AWARDS}_${advancement.userAwardId}_${advancementId}`;
    }
    case advancementTypes.MERIT_BADGES: {
      return `${advancementTypes.MERIT_BADGES}_${advancementId}`;
    }
    case advancementTypes.RANKS: {
      return `${advancementTypes.RANKS}_${advancementId}`;
    }
    case advancementTypes.SSELECTIVES: {
      return `${advancementTypes.SSELECTIVES}_${advancementId}`;
    }
  }
};

export const getEagleHours = hrs => [
  {
    name: 'Hours',
    type: 'Hrs',
    fkActTypeId: 5,
    effectiveDt: '1910-01-01',
    expiryDt: '',
    activityValueTypeId: 10,
    activityValue: Number(hrs),
  },
];

export const formatEagleDates = (startDate, endDate) => {
  const formattedStart = moment(startDate).format('MM/DD/YYYY');
  const formattedEnd = moment(endDate).format('MM/DD/YYYY');
  return `${formattedStart} - ${formattedEnd}`;
};

export const formatEagleHours = (participats = []) => {
  if (participats.length && participats[0].activityValues.length) {
    const activityValues = participats[0].activityValues;
    const hours = activityValues.find(
      value => value.activityValueTypeId === 10,
    );

    return `${hours ? hours.activityValue : '--'} HOURS`;
  }

  return '-- HOURS';
};

export const isParentOfYouthProfile = (memberDetails, adultUserId) => {
  if (
    !isEmpty(memberDetails) &&
    memberDetails.parentsGuardiansInfo.length &&
    adultUserId
  ) {
    const foundParent = memberDetails.parentsGuardiansInfo.find(
      info => info.userId === `${adultUserId}`,
    );
    return foundParent;
  }

  return false;
};

export const getValidSearchValue = input =>
  input && input.length > 2 ? input : undefined;

export const getTotalDays = (dates = []) => {
  if (!dates.length) {
    return 0;
  }

  const today = moment().format('YYYY-MM-DD');
  const sortedDates = sortBy(dates, 'startDate', 'endDate');

  const formattedIntervals = sortedDates.map(pos => {
    const startDate = pos.startDate;
    const endDate = !pos.endDate ? today : pos.endDate;
    return {
      startDate,
      endDate: moment(endDate).isAfter(today) ? today : endDate,
    };
  });

  const finalIntervals = [];
  formattedIntervals.forEach(interval => {
    if (!finalIntervals.length) {
      finalIntervals.push(interval);
      return;
    }

    const index = finalIntervals.findIndex(({ startDate, endDate }) =>
      moment(interval.startDate).isBetween(startDate, endDate, undefined, '[]'),
    );
    if (index > -1) {
      const originalEndDate = finalIntervals[index].endDate;
      finalIntervals[index].endDate = moment(interval.endDate).isAfter(
        originalEndDate,
      )
        ? interval.endDate
        : originalEndDate;
      return;
    }

    const lastFinalInterval = finalIntervals[finalIntervals.length - 1];
    if (
      moment(interval.startDate).isSame(lastFinalInterval.endDate, 'month') &&
      moment(interval.startDate)
        .subtract(1, 'day')
        .isSame(lastFinalInterval.endDate)
    ) {
      finalIntervals[finalIntervals.length - 1].endDate = interval.endDate;
      return;
    }

    finalIntervals.push(interval);
  });

  const total = finalIntervals
    .map(({ startDate, endDate }) => moment(endDate).diff(startDate, 'days'))
    .reduce(
      (totalDays, totalOfDaysInInterval) => totalDays + totalOfDaysInInterval,
      0,
    );
  return total;
};

export const transformComments = response =>
  map(
    response,
    ({
      advancementRequirementId,
      comments,
      dateCreated,
      firstName,
      id,
      lastName,
      middleName,
      photos,
      userId,
      pictureURL,
    }) => ({
      author: [firstName, middleName, lastName]
        .filter(item => !isEmpty(item))
        .join(' '),
      authorImage: pictureURL,
      date: dateCreated,
      id,
      message: comments,
      userId,
      photos: map(photos, photo => ({
        failed: photo.title === 'Incomplete Upload',
        ...photo,
      })),
      requirementId: advancementRequirementId,
    }),
  ).sort(
    (a, b) =>
      moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
  );

export const containsPendingComments = comments =>
  !isUndefined(
    find(
      flatten(map(comments, ({ photos }) => photos)),
      ({ pending }) => pending,
    ),
  );

export const filterComments = (comments, requirementId) => {
  const id = Number(requirementId);
  if (id > 0) {
    return filter(comments, comment => Number(comment.requirementId) === id);
  }

  return filter(comments, comment => isNull(comment.requirementId));
};

export function isRequirementApproved(node) {
  return (
    !isNil(node.leaderApprovedUserId) && !isEmpty(node.leaderApprovedUserId)
  );
}

export function requirementHasChildren(node) {
  return !isNil(node.requirements) && !isEmpty(node.requirements);
}

/**
 * Checks if requirement is display only, i.e. should not be updated.
 * See CUB Lion Rank, requirement "To earn the Lion rank badge..."
 *
 * @param {Object} requirement
 * @param {string | null} requirement.listNumber
 * @param {string | number | null} requirement.parentRequirementId
 */
export function shouldRequirementDisplayOnly(requirement) {
  return (
    (isNil(requirement.listNumber) || isEmpty(requirement.listNumber)) &&
    (isNil(requirement.parentRequirementId) ||
      isEmpty(requirement.parentRequirementId))
  );
}

export function getRequirementsInitSelection(requirementArray) {
  const selection = {};
  requirementArray.forEach(([, requirement]) => {
    if (
      (isNil(requirement.requirements) || isEmpty(requirement.requirements)) &&
      !shouldRequirementDisplayOnly(requirement)
    ) {
      selection[requirement.id] = false;
    }
  });
  return selection;
}

export function getMarkableRequirements(requirementArray) {
  return Object.keys(getRequirementsInitSelection(requirementArray));
}

export function requirementHasLinkedAdvancements(requirement) {
  return (
    (!isNil(requirement.totalMBRequired) &&
      !isEmpty(requirement.totalMBRequired)) ||
    (!isNil(requirement.linkedAdventure) &&
      !isEmpty(requirement.linkedAdventure)) ||
    requirement.electiveAdventure
  );
}

export function generateAdvancementBody({
  advancement,
  youthLeader,
  youthUserId,
  dateField,
  isCompletedChanged,
  isApproveChanged,
  dateForAction,
  checkmarks,
  isAwardedChanged,
  organizationGuid,
}) {
  const payloadStatusValues = {
    uncompleted: 'Not Complete',
    completed: 'Completed',
    leaderApproved: 'Leader Approved',
  };
  const newAdvancementBody = {};
  const { userId: leaderId } = youthLeader;
  const advancementId = getAdvancementId(
    advancement,
    advancement.advancementType,
  );

  if (isAwardedChanged && dateForAction) {
    if (checkmarks.award) {
      newAdvancementBody.id = advancementId;
      newAdvancementBody[dateField] = formatDate(dateForAction);
      newAdvancementBody.awarded = checkmarks.award;
      newAdvancementBody.dateAwarded = formatDate(new Date());
      newAdvancementBody.awardedUserId = +leaderId;
    } else {
      newAdvancementBody.newStatus = payloadStatusValues.leaderApproved;
    }
  }

  if (isApproveChanged && dateForAction) {
    if (checkmarks.approve) {
      newAdvancementBody.id = advancementId;
      newAdvancementBody[dateField] = formatDate(dateForAction);
      newAdvancementBody.leaderApprovedUserId = +leaderId;
      newAdvancementBody.leaderApprovedDate = formatDate(new Date());
    } else {
      newAdvancementBody.newStatus = payloadStatusValues.completed;
    }
  }

  if (isCompletedChanged && dateForAction) {
    newAdvancementBody.id = advancementId;
    newAdvancementBody[dateField] = formatDate(dateForAction);
  } else if (isCompletedChanged && !dateForAction) {
    newAdvancementBody.newStatus = payloadStatusValues.uncompleted;
  }

  // having newStatus in the payload means we will use /unapprove endpoint
  if (newAdvancementBody.newStatus) {
    newAdvancementBody.advancementId = advancementId;
    newAdvancementBody.advancementType = advancement.advancementType;
    newAdvancementBody.userId = youthUserId;
  }

  newAdvancementBody.organizationGuid = organizationGuid;

  return newAdvancementBody;
}

export const hasDenChiefPositionInPack = (
  { organizationGuid, unitTypeId },
  orgPositions = [],
) => {
  const orgIsPack = +unitTypeId === unitTypeIdForUnitType.Pack;

  if (!orgPositions.length || !organizationGuid || !orgIsPack) return false;

  const currentUnitPositions = orgPositions.find(
    positions => positions.organizationGuid === organizationGuid,
  );

  if (
    currentUnitPositions &&
    currentUnitPositions.positions &&
    currentUnitPositions.positions.length
  ) {
    return !!currentUnitPositions.positions.find(
      ({ id }) => +id === PositionId.DenChief,
    );
  }

  return false;
};

export const hasDenChiefPositionInAnyUnitAndIsPackSelected = (
  { organizationGuid, unitTypeId },
  orgPositions = [],
) => {
  const orgIsPack = +unitTypeId === unitTypeIdForUnitType.Pack;

  if (!orgPositions.length || !organizationGuid || !orgIsPack) return false;
  // Find den chief positions that can come from crews, troops, packs or ships
  const hasDenChiefPos = orgPositions.some(currentUnitPositions => {
    if (
      currentUnitPositions &&
      currentUnitPositions.positions &&
      currentUnitPositions.positions.length
    ) {
      return !!currentUnitPositions.positions.find(
        ({ id }) => +id === PositionId.DenChief,
      );
    }
    return false;
  });

  return hasDenChiefPos && orgIsPack;
};

export function generateAwardPayload({
  organizationGuid,
  dateForAction,
  isCompletedChanged,
  youthLeader,
  advancement,
  youthUserId,
  dateField,
  isApproveChanged,
  checkmarks,
  isAwardedChanged,
}) {
  const payloadStatusValues = {
    uncompleted: 'Not Complete',
    completed: 'Completed',
    leaderApproved: 'Leader Approved',
  };

  const awardPayload = {};
  const awardPayloadBody = {};

  const { userId: leaderId } = youthLeader;
  const advancementId = getAdvancementId(
    advancement,
    advancement.advancementType,
  );

  awardPayload.userId = youthUserId;
  awardPayload.awardId = advancementId;
  awardPayload.userAwardId = advancement.userAwardId;
  awardPayloadBody.organizationGuid = organizationGuid;

  // Awarded
  if (isAwardedChanged && dateForAction) {
    if (checkmarks.award) {
      awardPayloadBody.awarded = _.toString(checkmarks.award);
      awardPayloadBody.awardedDate = formatDate(new Date());
      awardPayloadBody.awardedUserId = +leaderId;
    } else {
      //handles unapproving
      awardPayloadBody.newStatus = payloadStatusValues.leaderApproved;
    }
  }

  // Approve
  if (isApproveChanged && dateForAction) {
    if (checkmarks.approve) {
      awardPayloadBody.leaderApprovedUserId = _.toString(leaderId);
      awardPayloadBody.leaderApprovedDate = formatDate(new Date());
    } else {
      //handles unapproving
      awardPayloadBody.newStatus = payloadStatusValues.completed;
    }
  }

  // Completion
  if (isCompletedChanged && dateForAction) {
    awardPayloadBody[dateField] = formatDate(dateForAction);
    awardPayloadBody.markedCompletedDate = formatDate(new Date());
    awardPayloadBody.markedCompletedUserId = leaderId;
  } else if (isCompletedChanged && !dateForAction) {
    //handles uncompleting
    awardPayloadBody.newStatus = payloadStatusValues.uncompleted;
  }

  // having newStatus in the payload means we will use /unapprove endpoint
  if (awardPayloadBody.newStatus) {
    awardPayloadBody.userId = youthUserId;
    awardPayloadBody.advancementType =
      unapproveAdvancementTypes[advancement.advancementType];
    awardPayloadBody.advancementId = advancementId;
    awardPayloadBody.userAwardId = advancement.userAwardId;
    return {
      isUnapprove: true,
      body: [{ ...awardPayloadBody }],
    };
  } else {
    return {
      isUnapprove: false,
      ...awardPayload,
      body: { ...awardPayloadBody },
    };
  }
}
