import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { apiEnvSel } from '@config';
import styles from './ApiEnvInfo.less';

class ApiEnvInfo extends React.PureComponent {
  render() {
    const { apiEnv } = this.props;
    return (
      <div title="Current API environment" className={styles[apiEnv]}>
        {apiEnv}
      </div>
    );
  }
}

ApiEnvInfo.propTypes = {
  apiEnv: PropTypes.string.isRequired,
};

const mapState = state => ({
  apiEnv: apiEnvSel(state),
});

export default connect(mapState)(ApiEnvInfo);
