import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';

import { SHOW_RECORD_ADV_STATUS } from '@config';
import { organizationGuidSel, unitTypeIdSel } from '@context';
import {
  advancementSel,
  dictionariesAdvancementsRequest,
  dictionaryIds,
  dictionarySel,
} from '@dict';
import {
  ProgramId,
  advancementTypes,
  intl,
  offlineSel,
  setSelectedRosterKeys,
} from '@shared';
import { toastService } from '@toasts';
import { userIdSel } from '@user';
import { esbApiService } from '@utils';
import '@utils/rxjs.add.operator.catchAndReport';

import { closeApproveAdvancements } from '../../approveAdvancements/duck';
import { eagleRankId } from '../constants';
import {
  RECORD_ADVANCEMENT_REQUEST,
  SET_ADDITIONAL_ADVANCEMENT,
  SET_ADDITIONAL_ADVANCEMENT_TYPE,
  SET_ADVANCEMENT,
  SET_ADVANCEMENT_TYPE,
  VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST,
  VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE,
  VERIFY_ADVANCEMENT_REQUEST,
  VERIFY_ADVANCEMENT_RESPONSE,
  recordAdvancementDeferred,
  recordAdvancementError,
  recordAdvancementResponse,
  setGeneralAdvancementStatus,
  verifyAdditionalAdvancementError,
  verifyAdditionalAdvancementRequest,
  verifyAdditionalAdvancementResponse,
  verifyAdvancementError,
  verifyAdvancementRequest,
  verifyAdvancementResponse,
} from './actions';
import {
  additionalAdvancementIdSel,
  additionalAdvancementTypeSel,
  additionalSuggestedAdvStatusSel,
  additionalSuggestedYouthSel,
  advancementIdSel,
  advancementTypeSel,
  isAdditionalTabActiveSel,
  personsSel,
  suggestedAdvStatusSel,
  suggestedYouthSel,
} from './selectors';
import services from './services';

const getRecordActions = shouldClose =>
  shouldClose ? [closeApproveAdvancements(), setSelectedRosterKeys([])] : [];

export const isAdvancementEagleRank = ({ advancementId, advancementType }) =>
  advancementType === advancementTypes.RANKS && advancementId == eagleRankId;

const verifyAdvancementBody$ = ({
  state,
  advancementTypeSel,
  advancementIdSel,
  unitTypeId,
  verifyAdvancementResponse,
  verifyAdvancementError,
  suggestedYouthSel,
}) => {
  const selectedPersons = personsSel(state);
  const suggestedYouth = suggestedYouthSel(state);
  const persons = [...selectedPersons, ...suggestedYouth];
  const advancementType = advancementTypeSel(state);
  const advancementId = advancementIdSel(state);
  const ranks = dictionarySel(state, dictionaryIds.RANKS);
  const isOffline = offlineSel(state);
  const organizationGuid = organizationGuidSel(state);

  if (isOffline || isAdvancementEagleRank({ advancementId, advancementType })) {
    return Observable.of(verifyAdvancementResponse());
  }

  return services
    .verifyUserAdvancement$({
      persons,
      ranks,
      advancementType,
      advancementId,
      unitTypeId,
      organizationGuid,
    })
    .map(verifyAdvancementResponse)
    .catchAndReport(err => Observable.of(verifyAdvancementError(err)));
};

const recordAdvancementEpic$ = (action$, state$) =>
  action$.ofType(RECORD_ADVANCEMENT_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const loggedInUserId = userIdSel(state);
    const organizationGuid = organizationGuidSel(state);
    const { users, advancementId, advancementType, date, status } = payload;
    const isAdditionalAdvancement = isAdditionalTabActiveSel(state);
    const unitTypeId = unitTypeIdSel(state);

    return services
      .recordAdvancement$({
        loggedInUserId,
        organizationGuid,
        date,
        advancementId,
        advancementType,
        users,
        unitTypeId: isAdditionalAdvancement ? ProgramId.BOY_SCOUT : unitTypeId,
        status,
      })
      .mergeMap(res => {
        const { successMsg, closeOnSuccess } = payload;
        if (res === esbApiService.DEFERRED) {
          const actions = [
            recordAdvancementDeferred(),
            ...getRecordActions(closeOnSuccess),
          ];
          const advancementName = advancementSel({
            state,
            advancementType,
            advancementId,
          }).name;

          toastService.info(
            intl.formatMessage(
              {
                id: 'recordAdvancement.RecordAdvancement.recordedOffline',
              },
              { advancementName },
            ),
          );
          return Observable.of(...actions);
        }
        toastService.success(successMsg);
        const actions = [
          recordAdvancementResponse(advancementType),
          ...getRecordActions(closeOnSuccess),
        ];
        return Observable.of(...actions);
      })
      .catchAndReport(err => Observable.of(recordAdvancementError(err)));
  });

const setAdvancementEpic$ = action$ =>
  action$
    .ofType(SET_ADVANCEMENT)
    .filter(({ payload }) => payload)
    .switchMap(({ payload: advancementId }) =>
      Observable.of(verifyAdvancementRequest({ advancementId })),
    );

const setAdditionalAdvancementEpic$ = action$ =>
  action$
    .ofType(SET_ADDITIONAL_ADVANCEMENT)
    .filter(({ payload }) => payload)
    .switchMap(({ payload: advancementId }) =>
      Observable.of(verifyAdditionalAdvancementRequest({ advancementId })),
    );

const verifyAdvancementEpic$ = (action$, state$) =>
  action$.ofType(VERIFY_ADVANCEMENT_REQUEST).switchMap(() => {
    const state = state$.value;
    return verifyAdvancementBody$({
      state,
      advancementTypeSel,
      advancementIdSel,
      verifyAdvancementResponse,
      verifyAdvancementError,
      unitTypeId: unitTypeIdSel(state),
      suggestedYouthSel,
    });
  });

const verifyAdditionalAdvancementEpic$ = (action$, state$) =>
  action$.ofType(VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST).switchMap(() => {
    const state = state$.value;
    return verifyAdvancementBody$({
      state,
      advancementTypeSel: additionalAdvancementTypeSel,
      advancementIdSel: additionalAdvancementIdSel,
      verifyAdvancementResponse: verifyAdditionalAdvancementResponse,
      verifyAdvancementError: verifyAdditionalAdvancementError,
      unitTypeId: ProgramId.BOY_SCOUT,
      suggestedYouthSel: additionalSuggestedYouthSel,
    });
  });

const fetchAdvancementDictionaryEpic$ = action$ =>
  action$
    .ofType(SET_ADVANCEMENT_TYPE, SET_ADDITIONAL_ADVANCEMENT_TYPE)
    .filter(({ payload }) => payload)
    .mapTo(dictionariesAdvancementsRequest());

const setSuggestedAdvStatusEpic$ = (action$, state$) =>
  action$
    .ofType(VERIFY_ADVANCEMENT_RESPONSE, VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE)
    .filter(() => SHOW_RECORD_ADV_STATUS)
    .map(() => {
      const state = state$.value;
      const isAdditionalAdvancement = isAdditionalTabActiveSel(state);
      const suggestedAdvStatus = (
        isAdditionalAdvancement
          ? additionalSuggestedAdvStatusSel
          : suggestedAdvStatusSel
      )(state);

      return setGeneralAdvancementStatus(suggestedAdvStatus);
    });

export default combineEpics(
  recordAdvancementEpic$,
  setAdvancementEpic$,
  setAdditionalAdvancementEpic$,
  verifyAdvancementEpic$,
  verifyAdditionalAdvancementEpic$,
  fetchAdvancementDictionaryEpic$,
  setSuggestedAdvStatusEpic$,
);
