import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Row, S } from '@shared/components';

import { paymentMethods } from '../../../../constants';
import {
  closeConfirmationPaymentModal,
  confirmationModalInfoSel,
  selectedPaymentMethodSel,
} from '../../../../duck';
import styles from './ConfirmationPayment.less';

const ConfirmationPayment = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { message, onConfirm } = useSelector(confirmationModalInfoSel);
  const paymentMethod = useSelector(selectedPaymentMethodSel);

  const isACH = paymentMethod === paymentMethods.E_CHECK;

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleOnConfirm = useCallback(() => {
    onConfirm();
    dispatch(closeConfirmationPaymentModal());
  }, [dispatch, onConfirm]);

  return (
    <React.Fragment>
      <Row type="flex">
        <Col>
          <S size="4">{message}</S>
          {isACH && (
            <React.Fragment>
              <S size="4" bold className={styles.echeckMessage}>
                <FormattedMessage id="recharter.PaymentStep.payment.messageECheckTitle" />
              </S>
              <S size="4">
                <FormattedMessage id="recharter.PaymentStep.payment.messageECheck" />
              </S>
              <S size="4">
                *{' '}
                <FormattedMessage id="recharter.PaymentStep.payment.messageECheckPayExpiration" />
              </S>
            </React.Fragment>
          )}
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Button
            className={styles.button}
            color="scouting-warm-gray"
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={true}
            onClick={handleOnCancel}
          >
            <FormattedMessage id="recharterModals.confirmationPaymentModal.cancel" />
          </Button>
        </Col>
        <Col className={styles.lastButton}>
          <Button
            className={styles.button}
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={true}
            onClick={handleOnConfirm}
          >
            <FormattedMessage id="recharterModals.confirmationPaymentModal.confirm" />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ConfirmationPayment.propTypes = {
  onCancel: PropTypes.func,
};

ConfirmationPayment.defaultProps = {
  onCancel: () => {},
};

export default ConfirmationPayment;
