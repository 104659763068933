import defaultsDeep from 'lodash/defaultsDeep';
import find from 'lodash/find';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import {
  acceptedCreditCards,
  amexRegex,
  cvvAmexRegex,
  cvvRegex,
  dtoDateFormat,
  emailRegex,
  unitTypes,
} from '@shared/constants';
import { intl } from '@shared/localization';
import { normalizeUnitGender } from '@shared/utils';
import { unitInfoSel } from '@unit';

import {
  agreementPositions,
  basicRequirements,
  nonUnit,
  paymentMethods,
  positionIdsExemptOfPaperApp,
  recharterUnitTypes,
} from './constants';

export const validateCardRule = cardNumber => {
  let accepted = false;
  let sum = 0;
  let shouldDouble = false;

  if (!cardNumber) {
    return false;
  }

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i));
    if (shouldDouble && (digit *= 2) > 9) {
      digit -= 9;
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }

  Object.keys(acceptedCreditCards).forEach(key => {
    if (acceptedCreditCards[key].test(cardNumber)) {
      accepted = true;
    }
  });
  return sum % 10 == 0 && accepted;
};

export const validateCVVRule = (creditCard, cvv) =>
  amexRegex.test(creditCard) ? cvvAmexRegex.test(cvv) : cvvRegex.test(cvv);

export const getItemizedReceipt = summary => {
  if (!isEmpty(summary)) {
    return Object.keys(summary)
      .filter(category => {
        if (category === 'batchTotalFee') {
          return false;
        }
        const item = summary[category];
        return (item instanceof Object && item.quantity > 0) || item >= 0;
      })
      .map(category => {
        const item = summary[category];
        const isObject = item instanceof Object;
        const amount = isObject ? item.fee : item;
        return {
          description: intl.formatMessage({
            id: `recharter.PaymentStep.${category}`,
          }),
          itemPrice: amount,
          quantity: isObject ? item.quantity : 1,
          amount,
          currency: 'USD',
        };
      });
  }
  return [];
};

export const getPersonInfoWithPaperApplication = (
  state,
  {
    firstName,
    lastName,
    memberType: memberTypeId,
    email,
    dateOfBirth,
    memberId,
    isCouncilPaid,
    paperApplication,
    memberPosition: requestedFkPositionId,
  },
  term,
) => {
  const originFileObj = get(paperApplication, 'file.originFileObj');
  const { type: unitType } = unitInfoSel(state);
  const dob = moment(dateOfBirth).format(dtoDateFormat);
  const personInfo = {
    firstName,
    lastName,
    dob,
    memberTypeId,
    email,
    memberId,
    unitType,
    requestedFkPositionId,
    isCouncilPaid,
    USAddress: true,
    term,
    isMultiple: false,
    isNewMember: true,
    isBoysLife: false,
    validateSSN: false,
  };

  const formData = new FormData();

  for (let key in personInfo) {
    if (personInfo[key] !== undefined) {
      formData.append(key, personInfo[key]);
    }
  }

  if (originFileObj) {
    formData.append('file', originFileObj);
  }

  return formData;
};

export const getPersonInfo = (
  {
    firstName,
    lastName,
    memberType: memberTypeId,
    email: pgEmail,
    dateOfBirth,
    memberId,
    isCouncilPaid,
    memberPosition: requestedFkPositionId,
  },
  term,
  orgGuid,
) => {
  const dob = moment(dateOfBirth).format(dtoDateFormat);

  return {
    firstName,
    lastName,
    dob,
    memberTypeId,
    pgEmail,
    memberId,
    requestedFkPositionId,
    isCouncilPaid,
    USAddress: true,
    term,
    isMultiple: true,
    isNewMember: false,
    isBoysLife: false,
    fromOrgGuid: orgGuid,
  };
};

export const groupPersonsByIsAdult = persons => {
  const { adults = [], youths = [] } = groupBy(persons, person =>
    person.isAdult ? 'adults' : 'youths',
  );
  return { adults, youths };
};

export const getUploadDocumentPayload = file => {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

export const calculateAge = dob => {
  const today = new Date();
  const birthDate = new Date(dob);

  const currentAge = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  return monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ? currentAge - 1
    : currentAge;
};

export const formatAddress = (address, city) =>
  address ? (city ? `${address}, ${city}` : address) : '';

export const isBeforeToday = date => moment(date).isBefore(moment(), 'day');

export const calculateAdminFee = (subtotal, selectedMethod) => {
  if (selectedMethod === paymentMethods.CREDIT_CARD) {
    return subtotal * 0.03;
  }

  if (selectedMethod === paymentMethods.E_CHECK) {
    return 1;
  }

  return subtotal * 0;
};

const findPrimaryPhoneOrEmail = data =>
  (data || []).find(({ isPrimary }) => isPrimary === 'True');

function getUserDataToGeneratePDF(userData = []) {
  return userData.reduce((userData, data = {}) => {
    const primaryEmail = findPrimaryPhoneOrEmail(data.emails);
    const primaryPhone = findPrimaryPhoneOrEmail(data.phones);

    const profile = data.profile || {};

    userData.email = primaryEmail ? primaryEmail.email : '';
    userData.phone = primaryPhone ? primaryPhone.fullPhoneNumber : '';
    userData.firstName = profile.firstName;
    userData.middleName = profile.middleName;
    userData.lastName = profile.lastName;
    userData.fullName = profile.fullName;
    userData.memberId = profile.memberId;

    return userData;
  }, {});
}

export const getCompleteFileInfo = ({
  batchId,
  batchCreatedByName,
  fileInfo,
  unitInfo,
  unitStatus,
  councils,
  unitPaymentSummary,
  selectedPaymentMethod,
  userData,
}) => {
  const getUnitPaymentSummaryToGeneratePDF = unitPaymentSummary => {
    const { adminFee = 0 } = unitPaymentSummary;

    const computedAdminFee = calculateAdminFee(
      unitPaymentSummary.batchTotalFee,
      selectedPaymentMethod,
    );

    const updatedUnitPaymentSummary = fromPairs(
      Object.entries(unitPaymentSummary).map(([category, value]) => {
        if (category === 'batchTotalFee' && adminFee === 0) {
          value = value + computedAdminFee;
        }
        return [category, value];
      }),
    );

    return {
      ...updatedUnitPaymentSummary,
      adminFee: adminFee === 0 ? computedAdminFee : adminFee,
      joinFee: {
        quantity: get(updatedUnitPaymentSummary, 'joinFee.quantity'),
        fee: get(updatedUnitPaymentSummary, 'joinFee.fee'),
      },
    };
  };

  const getUnitInfoToGeneratePDF = (unitInfo, councils) => {
    const council =
      councils.find(
        ({ councilGuid }) => unitInfo.councilGuid === councilGuid,
      ) || {};

    const res = [unitInfo].reduce((updatedUnitInfo, unitInfo) => {
      updatedUnitInfo.name = unitInfo.name;
      updatedUnitInfo.districtName = unitInfo.districtName;
      updatedUnitInfo.councilName = unitInfo.councilName;
      updatedUnitInfo.councilFullName = council.councilName;
      updatedUnitInfo.charter = {};
      updatedUnitInfo.charter.communityOrganizationName = get(
        'charter.communityOrganizationName',
        unitInfo,
      );
      updatedUnitInfo.primaryAddress = {};
      updatedUnitInfo.primaryAddress.addressLine1 = get(
        unitInfo,
        'primaryAddress.addressLine1',
      );
      updatedUnitInfo.primaryAddress.addressLine2 = get(
        unitInfo,
        'primaryAddress.addressLine2',
      );
      updatedUnitInfo.primaryAddress.city = get(
        unitInfo,
        'primaryAddress.city',
      );
      updatedUnitInfo.primaryAddress.state = get(
        unitInfo,
        'primaryAddress.state',
      );
      updatedUnitInfo.primaryAddress.zip = get(unitInfo, 'primaryAddress.zip5')
        ? get(unitInfo, 'primaryAddress.zip5')
        : get(unitInfo, 'primaryAddress.zip4');
      updatedUnitInfo.primaryAddress.county = get(
        unitInfo,
        'primaryAddress.county',
      );
      updatedUnitInfo.status = unitStatus;
      updatedUnitInfo.gender = normalizeUnitGender(unitInfo.acceptGender, true);

      return updatedUnitInfo;
    }, {});

    res.charter = {
      ...(res.charter || {}),
      communityOrganizationName: get(
        unitInfo,
        'charter.communityOrganizationName',
      ),
    };

    return res;
  };

  return defaultsDeep(fileInfo, {
    batchId,
    batchCreatedByName,
    unit: getUnitInfoToGeneratePDF(unitInfo, councils),
    unitPaymentSummary: getUnitPaymentSummaryToGeneratePDF(unitPaymentSummary),
    userData: getUserDataToGeneratePDF(userData),
  });
};

export const getAgreementRecipients = (unitRoster = [], packRoster = []) => {
  const memberIds = [];
  const emails = [];
  const normalizeEmail = email => email.trim().toLowerCase();

  const persons = unitRoster
    .reduce((arr, { positionLong, email, memberId, isRemoved }) => {
      const packRosterInstance = packRoster.find(
        member => Number(member.memberId) === memberId,
      );
      // We validate if the member exists in the pack roster
      // If it does, we get the email info from there instead of the unit roster
      // This is due to a data inconsistency with the positions for some members
      if (
        packRosterInstance &&
        !memberIds.includes(Number(packRosterInstance.memberId)) &&
        packRosterInstance.primaryEmailInfo &&
        packRosterInstance.primaryEmailInfo.emailAddress &&
        !emails.includes(
          normalizeEmail(packRosterInstance.primaryEmailInfo.emailAddress),
        ) &&
        agreementPositions.includes(packRosterInstance.position)
      ) {
        memberIds.push(Number(packRosterInstance.memberId));
        emails.push(
          normalizeEmail(packRosterInstance.primaryEmailInfo.emailAddress),
        );
        arr.push({
          email: normalizeEmail(
            packRosterInstance.primaryEmailInfo.emailAddress,
          ),
        });

        return arr;
      }
      // If the member is not included in the pack roster
      // We get the same info from unit roster
      if (
        !memberIds.includes(memberId) &&
        email &&
        !isRemoved &&
        !emails.includes(normalizeEmail(email)) &&
        agreementPositions.includes(positionLong)
      ) {
        memberIds.push(memberId);
        emails.push(normalizeEmail(email));
        arr.push({ email: normalizeEmail(email) });

        return arr;
      }

      return arr;
    }, [])
    .filter(({ email }) => emailRegex.test(email));

  return persons;
};

export const getAgreementBody = ({
  transientDocumentId,
  name,
  acceptGender,
  rosters: { unitRoster, packRoster },
  batchApplicationId,
  ccs = [],
}) => ({
  fileInfos: [
    {
      transientDocumentId,
    },
  ],
  name: `${name} ${normalizeUnitGender(acceptGender, true)} Charter`,
  participantSetsInfo: [
    {
      memberInfos: getAgreementRecipients(unitRoster, packRoster),
      order: 1,
      role: 'SIGNER',
    },
  ],
  signatureType: 'ESIGN',
  externalId: {
    id: batchApplicationId,
  },
  message: intl.formatMessage({
    id: 'recharter.PaymentStep.agreementMessage',
  }),
  state: 'IN_PROCESS',
  ccs,
});

export const getBasicRequirementsByType = (requirements, type) => {
  if (!isEmpty(requirements)) {
    const reqs = requirements.find(
      ({ membershipType }) => membershipType === type,
    );
    if (reqs) {
      return reqs;
    }
  }
  return basicRequirements[type];
};

export const getValidRecharterUnitTypes = councilUnitType => {
  const isTraditionalUnit =
    councilUnitType !== unitTypes.POST && councilUnitType !== unitTypes.CLUB;

  const filteredUnitTypes = recharterUnitTypes.filter(({ label }) => {
    if (isTraditionalUnit) {
      return label !== unitTypes.POST && label !== unitTypes.CLUB;
    }

    return label === unitTypes.POST || label === unitTypes.CLUB;
  });

  return isTraditionalUnit
    ? filteredUnitTypes
    : [{ value: nonUnit.id, label: nonUnit.label }, ...filteredUnitTypes];
};

export const isValidDateNewMember = (rechartedDate, userDate) => {
  const minutesDiff = moment(userDate).diff(rechartedDate, 'minutes');
  return minutesDiff > 5;
};

export const memberHasPaperApplication = (adultRosterRecharter, memberId) =>
  find(
    adultRosterRecharter,
    ({ memberId: recharterMemberId, positionId }) =>
      memberId === recharterMemberId &&
      !includes(positionIdsExemptOfPaperApp, positionId),
  );

export function shouldDisplayUnitTerm() {
  return false;
}
