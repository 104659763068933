import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import { organizationGuidSel } from '@context';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  UNIT_INFO_REQUEST,
  UNIT_INFO_REQUEST_IF_NOT_LOADED,
  UNIT_PROFILE_REQUEST,
  unitInfoError,
  unitInfoRequest,
  unitInfoResponse,
  unitProfileError,
  unitProfileResponse,
} from './actions';
import { unitInfoLoadingSel, unitInfoSel } from './selectors';
import services from './services';

const fetchUnitProfile$ = action$ =>
  action$
    .ofType(UNIT_PROFILE_REQUEST)
    .mergeMap(action => {
      const [orgGuid] = action.payload.organizationGuid.split('*');
      return services.getUnitInfo$(orgGuid);
    })
    .map(unitProfileResponse)
    .catchAndReport(err => Observable.of(unitProfileError(err)));

const fetchUnitInfoIfNotLoaded$ = (action$, state$) =>
  action$
    .ofType(UNIT_INFO_REQUEST_IF_NOT_LOADED)
    .filter(() => !unitInfoLoadingSel(state$.value))
    .filter(() => {
      const unitInfoLoaded =
        Object.keys(unitInfoSel(state$.value)).length !== 0;
      return !unitInfoLoaded;
    })
    .map(unitInfoRequest);

const fetchUnitInfo$ = (action$, state$) =>
  action$
    .ofType(UNIT_INFO_REQUEST)
    .switchMap(() => services.getUnitInfo$(organizationGuidSel(state$.value)))
    .map(unitInfoResponse)
    .catchAndReport(err => Observable.of(unitInfoError(err)));

export default combineEpics(
  fetchUnitInfoIfNotLoaded$,
  fetchUnitInfo$,
  fetchUnitProfile$,
);
