import {
  PostAddDenChiefRequest,
  PostAddDenChiefResponse,
  PostInviteDenChiefEmailRequest,
  PostSearchDenChiefRequest,
  PostSearchDenChiefResponse,
} from '@appTypes/esb';
import { esbApi } from '@modules/rtk/esb-api';

const api = esbApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * @esbEndpoint POST /advancements/v2/denChief/search
     */
    searchDenChief: builder.mutation<
      PostSearchDenChiefResponse,
      PostSearchDenChiefRequest
    >({
      query: body => ({
        url: '/advancements/v2/denChief/search',
        method: 'POST',
        body,
      }),
    }),
    /**
     * @esbEndpoint POST /advancements/v2/denChief/emailConfirmation
     */
    sendDenChiefEmail: builder.mutation<void, PostInviteDenChiefEmailRequest>({
      query: body => ({
        url: '/advancements/v2/denChief/emailConfirmation',
        method: 'POST',
        body,
      }),
    }),
    /**
     * @esbEndpoint POST /organizations/v2/:orgGuid/denChief/add
     */
    addDenChief: builder.mutation<
      PostAddDenChiefResponse,
      {
        orgGuid: string;
        payload: PostAddDenChiefRequest;
      }
    >({
      query: arg => ({
        url: `/organizations/v2/${arg.orgGuid}/denChief/add`,
        method: 'POST',
        body: arg.payload,
      }),
    }),
  }),
});

export const {
  useSendDenChiefEmailMutation,
  useSearchDenChiefMutation,
  useAddDenChiefMutation,
} = api;
