import { combineReducers } from 'redux';

import '@utils/rxjs.add.operator.catchAndReport';
import { resetOnLogoutReducer } from '@utils';
import { SET_SCREEN_META, screenXs, screenSm, screenMd } from '@screen';
import {
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_RESPONSE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_PROGRESS,
  OPEN_ERRORS_MODAL,
  CLOSE_ERRORS_MODAL,
  CANCEL_FILE_REQUEST,
  CANCEL_FILE_RESPONSE,
  CANCEL_FILE_ERROR,
} from './actions';

const modalVisible = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_UPLOAD_MODAL: {
      return true;
    }
    case CLOSE_UPLOAD_MODAL: {
      return false;
    }
    case SET_SCREEN_META: {
      const { mode } = payload;
      if ([screenXs, screenSm, screenMd].includes(mode)) {
        return false;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const uploading = (state = false, { type }) => {
  switch (type) {
    case UPLOAD_FILE_REQUEST: {
      return true;
    }
    case UPLOAD_FILE_RESPONSE:
    case UPLOAD_FILE_ERROR:
    case UPLOAD_FILE_CANCEL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const uploadProgress = (state = {}, { type, payload }) => {
  switch (type) {
    case UPLOAD_FILE_REQUEST:
    case UPLOAD_FILE_CANCEL:
    case UPLOAD_FILE_ERROR:
    case CLOSE_UPLOAD_MODAL: {
      return {};
    }
    case UPLOAD_FILE_PROGRESS: {
      return payload;
    }
    case UPLOAD_FILE_RESPONSE: {
      return { percent: 100 };
    }
    default: {
      return state;
    }
  }
};

const uploadedFiles = (state = [], { type, payload }) => {
  switch (type) {
    case UPLOAD_FILE_RESPONSE: {
      const { name } = payload;

      return [
        ...state,
        {
          name,
          uploadedDate: new Date().toISOString(),
        },
      ];
    }
    case CLOSE_UPLOAD_MODAL: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const uploadError = (state = false, { type }) => {
  switch (type) {
    case UPLOAD_FILE_REQUEST:
    case UPLOAD_FILE_RESPONSE:
    case UPLOAD_FILE_CANCEL:
    case CLOSE_UPLOAD_MODAL: {
      return false;
    }
    case UPLOAD_FILE_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const uploadErrorDesc = (state = [], { type, payload }) => {
  switch (type) {
    case UPLOAD_FILE_REQUEST:
    case UPLOAD_FILE_RESPONSE:
    case UPLOAD_FILE_CANCEL:
    case CLOSE_UPLOAD_MODAL: {
      return [];
    }
    case UPLOAD_FILE_ERROR: {
      const { errorDesc } = payload || {};
      if (Array.isArray(errorDesc)) {
        return errorDesc;
      }
      return [errorDesc];
    }
    default: {
      return state;
    }
  }
};

const errorsModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_ERRORS_MODAL: {
      return true;
    }
    case CLOSE_ERRORS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const cancelling = (state = {}, { type, payload }) => {
  switch (type) {
    case CANCEL_FILE_REQUEST: {
      return {
        ...state,
        [payload.id]: true,
      };
    }
    case CANCEL_FILE_RESPONSE:
    case CANCEL_FILE_ERROR: {
      return {
        ...state,
        [payload]: false,
      };
    }
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  modalVisible,
  uploading,
  uploadProgress,
  uploadedFiles,
  uploadError,
  uploadErrorDesc,
  errorsModalVisible,
  cancelling,
});

export default resetOnLogoutReducer(reducers);
