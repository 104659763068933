import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Headroom from 'react-headroom';
import cn from 'classnames';

import {
  headerHeight,
  headerHeightMobile,
  sidebarWidth,
  sidebarExpandedWidth,
  sidebarWidthMobile,
} from '../../constants';
import { headerVisibleSel, sidebarExpandedSel, isMobileSel } from '../../duck';
import IsElementInViewport from './IsElementInViewport';
import styles from './HeadroomAffix.less';

class HeadroomAffix extends React.PureComponent {
  state = {
    fixed: false,
    pinned: false,
    isElementInViewport: true,
  };

  wrapperElement;

  saveWrapperRef = component =>
    // wait for children to mount
    setTimeout(() => {
      try {
        // TODO: refactor and remove findDOMNode
        // eslint-disable-next-line react/no-find-dom-node
        this.wrapperElement = ReactDOM.findDOMNode(component);
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      if (this.wrapperElement) {
        this.forceUpdate();
      }
    });

  handlePin = () => {
    this.setState({ pinned: true, fixed: true });
    this.props.onPin && this.props.onPin();
  };

  handleUnpin = () => {
    this.setState({ pinned: false, fixed: true });
    this.props.onUnpin && this.props.onUnpin();
  };

  handleUnfix = () => {
    this.setState({ pinned: false, fixed: false });
    this.props.onUnfix && this.props.onUnfix();
  };

  updateElementInViewport = isElementInViewport =>
    this.setState({ isElementInViewport });

  getOffsetTop = () => {
    const { clearHeader, offsetTop, isMobile, headerVisible } = this.props;
    let offset = offsetTop;

    if (clearHeader) {
      const height = isMobile ? headerHeightMobile : headerHeight;
      offset += headerVisible ? height : 0;
    }

    return offset;
  };

  getOffsetLeft = () => {
    const { clearSidebar, isMobile, sidebarExpanded } = this.props;
    let offset = 0;

    if (clearSidebar) {
      const width = isMobile
        ? sidebarWidthMobile
        : sidebarExpanded
        ? sidebarExpandedWidth
        : sidebarWidth;
      offset += width;
    }

    return offset;
  };

  render() {
    const {
      disable,
      disablePin,
      style,
      className,
      children,
      parent,
      pinStart,
      upTolerance,
      downTolerance,
      wrapperStyle,
    } = this.props;
    const { isElementInViewport, fixed } = this.state;
    const classNames = cn(className, styles.wrapper, {
      [styles.pin]: !disablePin,
      [styles.unfixed]: isElementInViewport, // overrides the "scrolled" classNames that is causing some buggy behaviour in the StickyTitle Components
    });
    const offsetTop = this.getOffsetTop();
    const offsetLeft = this.getOffsetLeft();
    const inlineStyle = {
      left: `${offsetLeft}px`,
      ...style,
    };
    const transformWrapperStyle = {
      transform: `translateY(${fixed ? offsetTop : 0}px)`,
      transition: fixed ? 'transform 0.2s ease-in-out' : '',
    };

    return (
      <Headroom
        ref={this.saveWrapperRef}
        disableInlineStyles
        className={classNames}
        style={inlineStyle}
        disable={disable || isElementInViewport}
        pinStart={pinStart}
        parent={parent}
        upTolerance={upTolerance}
        downTolerance={downTolerance}
        wrapperStyle={wrapperStyle}
        onPin={this.handlePin}
        onUnpin={this.handleUnpin}
        onUnfix={this.handleUnfix}
      >
        <IsElementInViewport
          elementRef={this.wrapperElement}
          // Headroom's pinStart measures distance from top of the document
          // this makes it effectively measure from top of the viewport
          offsetTop={offsetTop + pinStart}
          onChange={this.updateElementInViewport}
        >
          <div
            className={styles.transformWrapper}
            style={transformWrapperStyle}
          >
            {children}
          </div>
        </IsElementInViewport>
      </Headroom>
    );
  }
}

HeadroomAffix.propTypes = {
  // provided by parent
  disable: PropTypes.bool,
  disablePin: PropTypes.bool,
  upTolerance: PropTypes.number,
  downTolerance: PropTypes.number,
  pinStart: PropTypes.number,
  offsetTop: PropTypes.number,
  clearHeader: PropTypes.bool,
  clearSidebar: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  style: PropTypes.object,
  parent: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onPin: PropTypes.func,
  onUnpin: PropTypes.func,
  onUnfix: PropTypes.func,
  // provided by component itself
  isMobile: PropTypes.bool.isRequired,
  headerVisible: PropTypes.bool.isRequired,
  sidebarExpanded: PropTypes.bool.isRequired,
};

HeadroomAffix.defaultProps = {
  offsetTop: 0,
  pinStart: 0,
};

const mapState = state => ({
  headerVisible: headerVisibleSel(state),
  sidebarExpanded: sidebarExpandedSel(state),
  isMobile: isMobileSel(state),
});

export default connect(mapState)(HeadroomAffix);
