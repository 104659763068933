import { useSelector } from 'react-redux';
import {
  memberTypeIds,
  memberTypeLabels,
  positionTypes,
  executiveOfficerId,
  parentCoordinatorId,
  tigerCubAdultId,
  lionAdultPartnerId,
} from '../constants';
import { memberUnitPositionsSel } from '../duck';

const useRegisteredUnitPositions = memberType => {
  const unitPositions = useSelector(memberUnitPositionsSel);
  const filteredUnitPositions = unitPositions
    .filter(
      ({
        isAdultPosition,
        isParticipant,
        positionLong,
        PositionType,
        PositionId,
      }) =>
        memberType === memberTypeIds.adult
          ? (isAdultPosition &&
              !isParticipant &&
              PositionType === positionTypes.registered &&
              PositionId !== parentCoordinatorId) ||
            PositionId === executiveOfficerId ||
            PositionId === tigerCubAdultId ||
            PositionId === lionAdultPartnerId
          : memberType === memberTypeIds.youth
          ? !isAdultPosition && positionLong.includes(memberTypeLabels.youth)
          : isParticipant,
    )
    .sort((a, b) => a.positionLong.localeCompare(b.positionLong));

  return [filteredUnitPositions, unitPositions];
};

export default useRegisteredUnitPositions;
