export default xhr => {
  if (xhr) {
    const { response, status } = xhr;
    let errorDesc = (response || {}).errorDesc || '';

    // errorDesc can also be an array
    if (typeof errorDesc === 'string') {
      errorDesc = errorDesc.toLowerCase();
      return (
        (status === 401 && errorDesc.includes('expired')) ||
        (status === 403 && errorDesc.includes('jwt')) ||
        (status === 422 && errorDesc.includes('expired'))
      );
    }

    return false;
  }

  return false;
};
