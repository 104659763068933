import React, { Fragment, useEffect, useState } from 'react';

import { AutoComplete, Button, Col, Radio, Row } from 'bsa-ui';
import cn from 'classnames';
import { clone } from 'lodash';
import CheckCircleIcon from 'material-ui-icons/CheckCircle';
import DeleteForeverIcon from 'material-ui-icons/DeleteForever';
import InfoIcon from 'material-ui-icons/Info';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { addressTypeIds, usAkelaCountryId } from '../../constants';
import {
  cassResultsSel,
  fetchCASSRequest,
  fetchCASSResponse,
  isLoadingDeleteEmailModalSel,
  setIsLoadingDeleteEmailModal,
  setShowDeleteAddressModal,
  showDeleteAddressModalSel,
} from '../../duck';
import { intl } from '../../localization';
import isValueTruthy from '../../utils/isValueTruthy';
import Card from '../Card/Card';
import Form from '../Form';
import Input from '../Input';
import { DeleteEmailModal } from '../ProfileContactInfoCard/DeleteEmailModal';
import radioGroupStyles from '../RadioGroupStyles/RadioGroupStyles.less';
import S from '../S';
import Select from '../Select';
import StateSelect from '../StateSelect';
import T from '../T';
import styles from './ProfileAddressCard.less';
import { filterAddresses, getNewAddress } from './utils';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Section: CardSection } = Card;
const { Item: FormItem } = Form;
const { Option } = AutoComplete;

const renderOption = (address, idx) => (
  <Option
    key={idx}
    text={address.addressLine1}
    address={address}
    value={`${address.addressLine1} ${idx}`}
  >
    {address.AddressLine1}
  </Option>
);

const zipInitialValue = (zip, zipExt) => {
  if (!zip) {
    return undefined;
  }

  return `${zip}${zipExt ? `-${zipExt}` : ''}`;
};

// eslint-disable-next-line
const ProfileAddressCard = ({
  form,
  profile,
  countries,
  states,
  isLoading = false,
  isUnregisteredUser = false,
  onConfirmDelete,
  isReadOnly,
}) => {
  const dispatch = useDispatch();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { addresses } = profile;
  const address3 = profile && profile.profile ? profile.profile.address3 : '';
  const boysLife =
    profile && profile.profile ? profile.profile.boysLife : profile.boysLife;

  const [adressTypeSelected, setAddressTypeSelected] = useState(
    addressTypeIds.HOME,
  );
  const [formAddresses, setFormAddresses] = useState([]);
  useEffect(() => {
    setFormAddresses(filterAddresses(addresses));
  }, [addresses]);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const showModal = useSelector(showDeleteAddressModalSel);
  const isDeleting = useSelector(isLoadingDeleteEmailModalSel);
  const cassResults = useSelector(cassResultsSel);
  const options = cassResults.map(renderOption);

  const handleChangeSection = ({ target: { value } }) => {
    if (cassResults.length) {
      dispatch(fetchCASSResponse([]));
    }
    setAddressTypeSelected(value);
  };

  const handleChangePrimary = addressId => () => {
    const addressesValue = getFieldValue('addresses');
    const cloneFormAddresses = clone(addressesValue);

    const hasPrimaryAddress = Object.values(addressesValue).some(
      address => address.isPrimary,
    );
    if (!hasPrimaryAddress) {
      const [key, addressValues] = Object.entries(addressesValue).find(
        ([key]) => `id_${addressId}` == key,
      );

      cloneFormAddresses[key] = { ...addressValues, isPrimary: true };
      setFieldsValue({ addresses: cloneFormAddresses });
      return;
    }

    const [key, addressValues] = Object.entries(addressesValue).find(
      ([, value]) => value.isPrimary && addressId !== value.id,
    );
    cloneFormAddresses[key] = { ...addressValues, isPrimary: false };
    setFieldsValue({ addresses: cloneFormAddresses });
  };

  const showCheckmark = typeId => {
    const currentValues = getFieldValue('addresses');
    if (currentValues) {
      const addressValues = Object.values(currentValues).find(
        value => value && value.typeId == typeId,
      );
      return addressValues ? addressValues.isPrimary : false;
    }
    return false;
  };

  const isCountryRequired = addressId => {
    const currentValues = getFieldValue('addresses')[`id_${addressId}`];

    const isPrimary = currentValues.isPrimary;
    const address1Filled = !!currentValues.addressLine1;
    const stateFilled = !!currentValues.stateId;
    const zipFilled = !!currentValues.zip;
    if (!isPrimary && !address1Filled && !stateFilled && !zipFilled) {
      return false;
    }

    return true;
  };

  const isAddress1Required = addressId => {
    const currentValues = getFieldValue('addresses')[`id_${addressId}`];

    const isPrimary = currentValues.isPrimary;
    const countrySelected = currentValues.countryId !== undefined;
    const stateFilled = !!currentValues.stateId;
    const zipFilled = !!currentValues.zip;
    if (!isPrimary && !countrySelected && !stateFilled && !zipFilled) {
      return false;
    }

    return true;
  };

  const isCityRequired = addressId => {
    const currentValues = getFieldValue('addresses')[`id_${addressId}`];

    const isPrimary = currentValues.isPrimary;
    const countrySelected = currentValues.countryId !== undefined;
    const isUSCountry = currentValues.countryId == usAkelaCountryId;
    const address1Filled = !!currentValues.addressLine1;
    const stateFilled = !!currentValues.stateId;
    const zipFilled = !!currentValues.zip;
    if (countrySelected && !isUSCountry) {
      return false;
    }

    if (
      !isPrimary &&
      !countrySelected &&
      !address1Filled &&
      !zipFilled &&
      !stateFilled
    ) {
      return false;
    }

    return true;
  };

  const isStateRequired = addressId => {
    const currentValues = getFieldValue('addresses')[`id_${addressId}`];

    const isPrimary = currentValues.isPrimary;
    const countrySelected = currentValues.countryId !== undefined;
    const isUSCountry = currentValues.countryId == usAkelaCountryId;
    const address1Filled = !!currentValues.addressLine1;
    const zipFilled = !!currentValues.zip;
    if (countrySelected && !isUSCountry) {
      return false;
    }

    if (!isPrimary && !countrySelected && !address1Filled && !zipFilled) {
      return false;
    }

    return true;
  };

  const isZipRequired = addressId => {
    const currentValues = getFieldValue('addresses')[`id_${addressId}`];

    const isPrimary = currentValues.isPrimary;
    const countrySelected = currentValues.countryId !== undefined;
    const isUSCountry = currentValues.countryId == usAkelaCountryId;
    const address1Filled = !!currentValues.addressLine1;
    const stateFilled = !!currentValues.stateId;
    if (countrySelected && !isUSCountry) {
      return false;
    }

    if (!isPrimary && !countrySelected && !address1Filled && !stateFilled) {
      return false;
    }

    return true;
  };

  const isUSASelected = addressId => {
    const currentValues = getFieldValue('addresses')[`id_${addressId}`];
    const countrySelected = currentValues.countryId !== undefined;
    const isUSCountry = currentValues.countryId == usAkelaCountryId;

    return !countrySelected || isUSCountry;
  };

  const onClickDeleteAddress = addressId => () => {
    const indexOfAddress = formAddresses.findIndex(
      address => address.id === addressId,
    );
    const isNewAddress = addressId.startsWith('id_');

    if (isNewAddress && indexOfAddress !== -1) {
      const cloneFormAddresses = clone(formAddresses);
      cloneFormAddresses[indexOfAddress] = {
        ...getNewAddress(adressTypeSelected),
        id: `id_${addressId}`,
      };
      setFormAddresses(cloneFormAddresses);
    }

    if (!isNewAddress) {
      setIdToDelete(Number(addressId));
      dispatch(setShowDeleteAddressModal(true));
    }
  };

  const handleConfirmDelete = () => {
    dispatch(setIsLoadingDeleteEmailModal(true));
    onConfirmDelete(idToDelete);
  };

  const onCancelDelete = () => {
    if (!isDeleting) {
      dispatch(setShowDeleteAddressModal(false));
    }
  };

  const showDeleteBtn = (addressId, isOriginallyPrimary) => {
    if (isOriginallyPrimary) {
      return false;
    }

    const currentAddress = getFieldValue('addresses')[`id_${addressId}`];

    if (currentAddress.isPrimary) {
      return false;
    }

    const isNewAddress = addressId.startsWith('id_');
    const savedAddresses = formAddresses.filter(
      address => !address.id.startsWith('id_'),
    );

    if (
      isNewAddress &&
      (currentAddress.countryId ||
        currentAddress.addressLine1 ||
        currentAddress.addressLine2 ||
        currentAddress.city ||
        currentAddress.stateId ||
        currentAddress.zip)
    ) {
      return true;
    }

    if (
      !isNewAddress &&
      savedAddresses.length === 1 &&
      savedAddresses[0].id == addressId
    ) {
      return false;
    }

    if (!isNewAddress) {
      return true;
    }

    return false;
  };

  const handleChangeLine1 = addressId => value => {
    if (!isUSASelected(addressId)) {
      if (cassResults.length) {
        dispatch(fetchCASSResponse([]));
      }
      return;
    }

    if (value && value.length > 4) {
      dispatch(fetchCASSRequest(value));
    }
  };

  const handleSelectAddress = addressId => (selected, option) => {
    const { AddressLine1, City, PostalCode, State } = option.props.address;

    const addressesValue = getFieldValue('addresses');
    const cloneFormAddresses = clone(addressesValue);
    const addressObj = cloneFormAddresses[`id_${addressId}`];
    const dbState = states.find(({ short }) => short === State);

    cloneFormAddresses[`id_${addressId}`] = {
      ...addressObj,
      addressLine1: AddressLine1,
      addressLine2: '',
      city: City,
      stateId: dbState ? dbState.id : dbState,
      zip: PostalCode,
    };

    setFieldsValue({ addresses: cloneFormAddresses });
  };

  return (
    <Card margin noPadding className={styles.card} loading={isLoading}>
      <CardSection
        title={
          <T size="5" colored>
            <FormattedMessage id="shared.addressInfo.title" />
          </T>
        }
      >
        {formAddresses.map(address => (
          <Fragment key={address.id}>
            <Row
              type="flex"
              justify="space-between"
              className={address.typeId != adressTypeSelected && styles.hidden}
            >
              <Col xs={24} lg={11} className={styles.col}>
                {!isUnregisteredUser && (
                  <div>
                    <RadioGroup
                      defaultValue={addressTypeIds.HOME}
                      size="large"
                      className={radioGroupStyles.group}
                      onChange={handleChangeSection}
                      value={adressTypeSelected}
                    >
                      <RadioButton value={addressTypeIds.HOME}>
                        <FormattedMessage id="shared.addressInfo.home" />
                        {showCheckmark(addressTypeIds.HOME) && (
                          <CheckCircleIcon className={styles.primaryIcon} />
                        )}
                      </RadioButton>
                      <RadioButton value={addressTypeIds.BUSINESS}>
                        <FormattedMessage id="shared.addressInfo.business" />
                        {showCheckmark(addressTypeIds.BUSINESS) && (
                          <CheckCircleIcon className={styles.primaryIcon} />
                        )}
                      </RadioButton>
                      <RadioButton value={addressTypeIds.VACATION}>
                        <FormattedMessage id="shared.addressInfo.vacation" />
                        {showCheckmark(addressTypeIds.VACATION) && (
                          <CheckCircleIcon className={styles.primaryIcon} />
                        )}
                      </RadioButton>
                    </RadioGroup>
                  </div>
                )}
              </Col>
              <Col xs={24} lg={13} className={styles.col}>
                <FormItem>
                  {getFieldDecorator(`addresses.id_${address.id}.isPrimary`, {
                    initialValue: isValueTruthy(address.isPrimary),
                  })(
                    <Radio.Group onChange={handleChangePrimary(address.id)}>
                      <Radio
                        value={true}
                        className={styles.radio}
                        disabled={isReadOnly}
                      >
                        <FormattedMessage id="shared.addressInfo.isPrimary" />
                      </Radio>
                    </Radio.Group>,
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={11} className={styles.col}>
                <FormItem className={styles.formItemNormalWidth}>
                  {getFieldDecorator(`addresses.id_${address.id}.countryId`, {
                    initialValue: address.countryId,
                    rules: [
                      {
                        required: isCountryRequired(address.id),
                        message: intl.formatMessage({
                          id: 'shared.form.error.isRequired',
                        }),
                      },
                    ],
                  })(
                    <Select
                      rounded
                      size="large"
                      floatingLabel
                      placeholder={intl.formatMessage({
                        id: 'shared.addressInfo.country',
                      })}
                      optionFilterProp="text"
                      showSearch
                      disabled={isReadOnly}
                    >
                      <Select.OptGroup>
                        {countries.map(({ id = '-', name }) => (
                          <Select.Option key={id} value={id} text={name}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={13}>
                <FormItem
                  className={cn(
                    styles.formItemNormalWidth,
                    styles.autoCompleteFormItem,
                  )}
                >
                  {getFieldDecorator(
                    `addresses.id_${address.id}.addressLine1`,
                    {
                      initialValue: address.address1,
                      rules: [
                        {
                          max: 255,
                          message: intl.formatMessage(
                            {
                              id: 'shared.maxCharacters',
                            },
                            { max: 255 },
                          ),
                        },
                        {
                          required: isAddress1Required(address.id),
                          message: intl.formatMessage({
                            id: 'shared.form.error.isRequired',
                          }),
                        },
                      ],
                    },
                  )(
                    <AutoComplete
                      dataSource={options}
                      onSelect={handleSelectAddress(address.id)}
                      optionLabelProp="text"
                      onChange={handleChangeLine1(address.id)}
                      size="large"
                      className={styles.autoComplete}
                      disabled={isReadOnly}
                    >
                      <Input
                        size="large"
                        placeholder={intl.formatMessage({
                          id: 'shared.address.address1.placeholder',
                        })}
                        rounded
                        floatingLabel
                        disabled={isReadOnly}
                      />
                    </AutoComplete>,
                  )}
                </FormItem>
              </Col>
              <Col xs={24} lg={11}>
                <FormItem className={styles.formItemNormalWidth}>
                  {getFieldDecorator(
                    `addresses.id_${address.id}.addressLine2`,
                    {
                      initialValue: address.address2,
                      rules: [
                        {
                          max: 255,
                          message: intl.formatMessage(
                            {
                              id: 'shared.maxCharacters',
                            },
                            { max: 255 },
                          ),
                        },
                      ],
                    },
                  )(
                    <Input
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.address.address2.placeholder',
                      })}
                      rounded
                      floatingLabel
                      disabled={isReadOnly}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col
                xs={24}
                lg={13}
                className={isUSASelected(address.id) && styles.hidden}
              >
                <FormItem className={styles.formItemNormalWidth}>
                  {getFieldDecorator(
                    `addresses.id_${address.id}.addressLine3`,
                    {
                      initialValue: isUnregisteredUser
                        ? address3
                        : address.address3,
                      rules: [
                        {
                          max: 255,
                          message: intl.formatMessage(
                            {
                              id: 'shared.maxCharacters',
                            },
                            { max: 255 },
                          ),
                        },
                      ],
                    },
                  )(
                    <Input
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.address.address3.placeholder',
                      })}
                      rounded
                      floatingLabel
                      disabled={isReadOnly}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col
                xs={24}
                lg={13}
                className={!isUSASelected(address.id) && styles.hidden}
              >
                <FormItem className={styles.formItemNormalWidth}>
                  {getFieldDecorator(`addresses.id_${address.id}.city`, {
                    initialValue: address.city,
                    rules: [
                      {
                        max: 50,
                        message: intl.formatMessage(
                          {
                            id: 'shared.maxCharacters',
                          },
                          { max: 50 },
                        ),
                      },
                      {
                        required: isCityRequired(address.id),
                        message: intl.formatMessage({
                          id: 'shared.form.error.isRequired',
                        }),
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.address.city.placeholder',
                      })}
                      rounded
                      floatingLabel
                      disabled={isReadOnly}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col
                xs={24}
                lg={11}
                className={!isUSASelected(address.id) && styles.hidden}
              >
                <FormItem className={styles.formItemShortWidth}>
                  {getFieldDecorator(`addresses.id_${address.id}.stateId`, {
                    initialValue: address.stateId || undefined,
                    rules: [
                      {
                        required: isStateRequired(address.id),
                        message: intl.formatMessage({
                          id: 'shared.form.error.isRequired',
                        }),
                      },
                    ],
                  })(
                    <StateSelect
                      states={states}
                      statesLoaded={true}
                      size="large"
                      rounded
                      floatingLabel
                      disabled={isReadOnly}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col
                xs={24}
                lg={13}
                className={!isUSASelected(address.id) && styles.hidden}
              >
                <FormItem className={styles.formItemShortWidth}>
                  {getFieldDecorator(`addresses.id_${address.id}.zip`, {
                    initialValue: zipInitialValue(
                      address.zipCode,
                      address.zipCodeEx,
                    ),
                    rules: [
                      {
                        pattern: '^[0-9]{5}([- /]?[0-9]{4})?$',
                        message: intl.formatMessage({
                          id: 'shared.addressInfo.zipError',
                        }),
                      },
                      {
                        required: isZipRequired(address.id),
                        message: intl.formatMessage({
                          id: 'shared.form.error.isRequired',
                        }),
                      },
                    ],
                  })(
                    <Input
                      maxLength={10}
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.address.zipCode.label',
                      })}
                      rounded
                      floatingLabel
                      disabled={isReadOnly}
                    />,
                  )}
                </FormItem>
              </Col>
              <Col xs={24}>
                <FormItem className={styles.hidden}>
                  {getFieldDecorator(`addresses.id_${address.id}.typeId`, {
                    initialValue: address.typeId,
                  })(<Input size="large" rounded floatingLabel />)}
                </FormItem>
              </Col>
            </Row>
            <Row
              type="flex"
              className={address.typeId != adressTypeSelected && styles.hidden}
            >
              {showDeleteBtn(address.id, isValueTruthy(address.isPrimary)) && (
                <Col xs={24} lg={11}>
                  <Button
                    ghost
                    className={styles.deleteAddressBtn}
                    onClick={onClickDeleteAddress(address.id)}
                  >
                    <FormattedMessage id="shared.address.delete" />
                    <DeleteForeverIcon />
                  </Button>
                </Col>
              )}
              {isValueTruthy(boysLife) ? (
                <Col xs={24}>
                  <div className={styles.noticeContainer}>
                    <CheckCircleIcon className={styles.checkIcon} />
                    <S size="4">
                      <FormattedMessage id="shared.addressInfo.scoutLife" />
                    </S>
                  </div>
                </Col>
              ) : (
                <Col xs={24}>
                  <div className={styles.noticeContainer}>
                    <S size="4">
                      <InfoIcon className={styles.infoIcon} />
                      <FormattedMessage id="shared.addressInfo.noScoutLifeText" />
                      <a
                        className={styles.scoutLifeLink}
                        href="https://simplecirc.com/subscribe/scout-life/DIRPAS"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="shared.addressInfo.noScoutLifeLink" />
                      </a>
                    </S>
                  </div>
                </Col>
              )}
            </Row>
          </Fragment>
        ))}
      </CardSection>
      <DeleteEmailModal
        visible={showModal}
        isLoading={isDeleting}
        titleId="deleteAddress"
        onCancel={onCancelDelete}
        onAccept={handleConfirmDelete}
      />
    </Card>
  );
};

ProfileAddressCard.propTypes = {
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  states: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isUnregisteredUser: PropTypes.bool,
  onConfirmDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default ProfileAddressCard;
