import { dtoDateFormat, dtoDateTimeFormat } from '../constants';
import moment from 'moment';

export default (profile, userId) => {
  const {
    talentRelease,
    swimmingClassification,
    swimmingClassificationDate,
    annualHealthRecordABDate,
    annualHealthRecordCDate,
  } = profile;

  return {
    talentReleaseUserId: talentRelease !== undefined ? userId : talentRelease,
    talentReleaseDate:
      talentRelease !== undefined
        ? talentRelease
          ? moment().format(dtoDateFormat)
          : null
        : talentRelease,
    talentReleaseDateRaw:
      talentRelease !== undefined
        ? talentRelease
          ? moment().format(dtoDateTimeFormat)
          : null
        : talentRelease,
    swimmingClassification:
      swimmingClassification !== undefined ? swimmingClassification : null,
    swimmingClassificationDate:
      swimmingClassificationDate !== undefined
        ? swimmingClassificationDate
          ? swimmingClassificationDate.format(dtoDateFormat)
          : null
        : swimmingClassificationDate,
    annualHealthRecordABDate:
      annualHealthRecordABDate !== undefined
        ? annualHealthRecordABDate
          ? annualHealthRecordABDate.format(dtoDateFormat)
          : null
        : annualHealthRecordABDate,
    annualHealthRecordCDate:
      annualHealthRecordCDate !== undefined
        ? annualHealthRecordCDate
          ? annualHealthRecordCDate.format(dtoDateFormat)
          : null
        : annualHealthRecordCDate,
  };
};
