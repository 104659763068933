import React from 'react';

import ForumIcon from '@material-ui/icons/Forum';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FORUMS_URL } from '@config';
import { OfflineTooltip } from '@offline';
import { gtm } from '@shared';

import NavMenuItem from './NavMenuItem';
import NavMenuItemContent from './NavMenuItemContent';

const openForumLink = () => {
  gtm.pushEvent({
    event: 'NAVIGATE_TO_FORUM',
    category: gtm.categories.FORUM,
  });

  window.open(FORUMS_URL, '_blank');
};

const ForumMenuItem = ({ loading, showLabels, ...rest }) => (
  <OfflineTooltip renderProps placement="right">
    {isOffline => (
      <div>
        <NavMenuItem
          key="forum"
          selectable={false}
          id="qa_forum"
          disabled={isOffline || loading}
          {...rest}
          onClick={openForumLink}
        >
          <NavMenuItemContent
            icon={<ForumIcon />}
            showLabels={showLabels}
            loading={loading}
          >
            <FormattedMessage id="core.Nav.forum" />
          </NavMenuItemContent>
        </NavMenuItem>
      </div>
    )}
  </OfflineTooltip>
);

ForumMenuItem.propTypes = {
  loading: PropTypes.bool,
  showLabels: PropTypes.bool.isRequired,
};

export default ForumMenuItem;
