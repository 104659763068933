import _ from 'lodash';
import moment from 'moment';
import type { PostBulkYouthRankRequirementsRes } from '../../../../types/esb';
import type { RosterScout } from '@modules/advancement/duck/types';
import type { RankVersion } from '../types';

export function getScoutHighestRank(scout: RosterScout, programId: number) {
  const { highestRanksApproved = [], highestRanksAwarded = [] } = scout || {};
  const scoutHighestRanks = [...highestRanksApproved, ...highestRanksAwarded];

  const scoutHighestProgramRank = _.chain(scoutHighestRanks)
    .filter(rank => Number(rank.programId) === Number(programId))
    .first()
    .value();

  return (
    scoutHighestProgramRank ||
    scout.rosterCurrentHighestRankApproved ||
    scoutHighestRanks[0]
  );
}

/**
 * Checks whether a version is expired or not.
 */
export function isVersionExpired(version: RankVersion) {
  if (!version || !version.expiredDate) return false;

  const date = version.expiredDate;
  const now = moment(Date.now());

  return moment(date).isAfter(now);
}

/**
 * Check for custom error messages that provide more useful information.
 * Will return the list of errors different from default:
 *
 * `Parent requirement should not be updated.`
 *
 */
export function getCustomErrorMessage(
  bulkUpdateResponse: PostBulkYouthRankRequirementsRes,
): string | undefined {
  const ignoredErrors = new RegExp(
    '(Parent requirement should not be updated)|(other error)',
    'i', // ignore case
  );
  const errors = new Set();
  try {
    for (const updateStatus of bulkUpdateResponse) {
      for (const item of updateStatus.requirements) {
        if (item.status !== 'Fail') continue;

        const isDefaultError = item.message.match(ignoredErrors);
        const isNotDefaultError = !isDefaultError && item.message.length > 0;

        if (isNotDefaultError) {
          errors.add(item.message);
        }
      }
    }
    if (errors.size > 0) {
      return [...errors].join('. ');
    }
  } catch {
    return;
  }
  return;
}
