import { gtm } from '@shared';

import {
  loadAndOpenActivityAction,
  serviceModuleNamespace as namespace,
} from '../../common';

export const OPEN_SERVICE_HOURS = `${namespace}/OPEN_SERVICE_HOURS`;
export const OPEN_SERVICE_PROJECT = `${namespace}/OPEN_SERVICE_PROJECT`;
export const OPEN_EDIT_SERVICE = `${namespace}/OPEN_EDIT_SERVICE`;
export const openServiceHours = (
  userIds,
  disableGetActivities = false,
  disableFutureDays = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_SERVICE_HOURS,
  payload: {
    userIds,
    disableGetActivities,
    disableFutureDays,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.SERVICE_HOURS,
  },
});
export const openServiceProject = (
  dates = {},
  allowEditOrAddPersonsPersons = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_SERVICE_PROJECT,
  payload: {
    dates,
    allowEditOrAddPersonsPersons,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.SERVICE_HOURS,
  },
});
export const openEditService = (allowEditOrAddPersonsPersons = false) => ({
  type: OPEN_EDIT_SERVICE,
  payload: {
    allowEditOrAddPersonsPersons,
  },
  gtm: {
    category: gtm.categories.SERVICE_HOURS,
  },
});

export const CLOSE_SERVICE = `${namespace}/CLOSE_SERVICE`;
export const closeService = () => ({
  type: CLOSE_SERVICE,
  gtm: {
    category: gtm.categories.SERVICE_HOURS,
  },
});

export const SET_SELECTED_PROJECT = `${namespace}/SET_SELECTED_PROJECT`;
export const setSelectedProject = project => ({
  type: SET_SELECTED_PROJECT,
  payload: project,
});

export const SET_DETAIL_MODE = `${namespace}/SET_DETAIL_MODE`;
export const setDetailMode = mode => ({ type: SET_DETAIL_MODE, payload: mode });

export const SET_ACTIVE_TAB = `${namespace}/SET_ACTIVE_TAB`;
export const setActiveTab = tab => ({ type: SET_ACTIVE_TAB, payload: tab });

export const RECORD_SERVICE_REQUEST = `${namespace}/RECORD_SERVICE_REQUEST`;
export const RECORD_SERVICE_RESPONSE = `${namespace}/RECORD_SERVICE_RESPONSE`;
export const RECORD_SERVICE_ERROR = `${namespace}/RECORD_SERVICE_ERROR`;
export const recordServiceRequest = ({ activityData, closeOnSuccess }) => ({
  type: RECORD_SERVICE_REQUEST,
  payload: {
    activityData,
    closeOnSuccess,
  },
  gtm: {
    category: gtm.categories.SERVICE_HOURS,
  },
});
export const recordServiceResponse = () => ({ type: RECORD_SERVICE_RESPONSE });
export const recordServiceError = err => ({
  type: RECORD_SERVICE_ERROR,
  payload: err,
});

export const LOAD_AND_OPEN_SERVICE_MODAL = `${namespace}/LOAD_AND_OPEN_SERVICE_MODAL`;
export const loadAndOpenServiceModal = loadAndOpenActivityAction(
  LOAD_AND_OPEN_SERVICE_MODAL,
);

export const LOAD_AND_OPEN_SERVICE_MODAL_ERROR = `${namespace}/LOAD_AND_OPEN_SERVICE_MODAL_ERROR`;
export const loadAndOpenServiceModalError = err => ({
  type: LOAD_AND_OPEN_SERVICE_MODAL_ERROR,
  payload: err,
});
