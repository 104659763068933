import min from 'lodash/min';
import { createSelector } from 'reselect';

import { organizationGuidSel } from '@context';
import {
  adventuresSel,
  allAwardsSel,
  dictionaryIds,
  meritBadgesSel,
  packRosterItemsSel,
  ranksSel,
  ssElectivesSel,
} from '@shared';
import { daysOld } from '@utils';

import { moduleName } from '../constants';

export { offlineSel } from '@shared';

const moduleSel = state => state[moduleName];

export const allPendingRequestsSel = state => moduleSel(state).pendingRequests;

export const pendingRequestsSel = createSelector(
  allPendingRequestsSel,
  organizationGuidSel,
  (pendingRequests, organizationGuid) =>
    pendingRequests.filter(req => req.organizationGuid === organizationGuid),
);

export const testLoadingSel = state => moduleSel(state).testLoading;
export const loginModalOpenSel = state => moduleSel(state).loginModalOpen;
export const syncModalOpenSel = state => moduleSel(state).syncModalOpen;
export const syncLoadingSel = state => moduleSel(state).syncLoading;
export const verifiedPendingItemsSel = state =>
  moduleSel(state).verifiedPendingItems;

export const pendingRequestsCountSel = state =>
  (pendingRequestsSel(state) || []).length;

const findAdvancement = (advancementId, dic) => {
  const advancement = dic.find(({ id }) => advancementId == id);
  return advancement || {};
};

export const pendingAdvancementsSel = createSelector(
  pendingRequestsSel,
  verifiedPendingItemsSel,
  adventuresSel,
  allAwardsSel,
  ranksSel,
  meritBadgesSel,
  ssElectivesSel,
  (
    pendingRequests,
    verifiedItems,
    adventures,
    awards,
    ranks,
    meritBadges,
    ssElectives,
  ) =>
    pendingRequests.map(pendingAdvancement => {
      const verification = verifiedItems.find(
        ({ id }) => pendingAdvancement.id == id,
      );
      pendingAdvancement = { ...pendingAdvancement, verification };

      switch (pendingAdvancement.advancementType) {
        case dictionaryIds.ADVENTURES: {
          const advancement = findAdvancement(
            pendingAdvancement.advancementId,
            adventures,
          );
          return { ...pendingAdvancement, advancement };
        }
        case dictionaryIds.AWARDS: {
          const advancement = findAdvancement(
            pendingAdvancement.advancementId,
            awards,
          );
          return { ...pendingAdvancement, advancement };
        }
        case dictionaryIds.RANKS: {
          const advancement = findAdvancement(
            pendingAdvancement.advancementId,
            ranks,
          );
          return { ...pendingAdvancement, advancement };
        }
        case dictionaryIds.SSELECTIVES: {
          const advancement = findAdvancement(
            pendingAdvancement.advancementId,
            ssElectives,
          );
          return { ...pendingAdvancement, advancement };
        }
        case dictionaryIds.MERIT_BADGES: {
          const advancement = findAdvancement(
            pendingAdvancement.advancementId,
            meritBadges,
          );
          return { ...pendingAdvancement, advancement };
        }
        default: {
          throw new Error('Not matching advancement type');
        }
      }
    }),
);

export const validPendingRequestsSel = createSelector(
  pendingRequestsSel,
  verifiedPendingItemsSel,
  (pendingRequests, verifiedItems) =>
    pendingRequests.filter(request => {
      const verification = verifiedItems.find(({ id }) => id == request.id);
      return verification ? verification.isEligible : true;
    }),
);

export const validPendingRequestsCountSel = state =>
  validPendingRequestsSel(state).length;

export const pendingYouthUserIdsSel = createSelector(
  pendingRequestsSel,
  pendingRequests => [...new Set(pendingRequests.map(({ userId }) => +userId))],
);
export const pendingYouthsSel = createSelector(
  pendingYouthUserIdsSel,
  packRosterItemsSel,
  pendingRequestsSel,
  (pendingYouthUserIds, packRoster, pendingRequests) =>
    pendingYouthUserIds.map(youthUserId => {
      const user = packRoster.find(({ userId }) => userId == youthUserId);
      const advancements = pendingRequests.filter(
        ({ userId }) => userId == youthUserId,
      );
      return { ...user, advancements };
    }),
);

export const selectedYouthIdSel = state =>
  moduleSel(state).selectedYouthId || pendingYouthUserIdsSel(state)[0];

export const selectedYouthAdvancementsSel = createSelector(
  selectedYouthIdSel,
  pendingAdvancementsSel,
  (selectedYouthId, pendingAdvancements) =>
    pendingAdvancements.filter(({ userId }) => userId == selectedYouthId),
);

export const advancementVerificationLoadingSel = state =>
  moduleSel(state).advancementVerificationLoading;
export const lastOnlineSel = state => moduleSel(state).lastOnline;

export const daysLeftToSyncSel = createSelector(
  pendingRequestsSel,
  pendingRequests => {
    const maxRequestAgeDays = 14;
    const requestTimestamps = pendingRequests.map(({ timestamp }) => timestamp);
    const oldestRequestTimestamp = min(requestTimestamps) || Date.now();

    const oldestRequesrAgeDays = daysOld(oldestRequestTimestamp);
    const daysLeft = maxRequestAgeDays - oldestRequesrAgeDays;
    return daysLeft > 0 ? daysLeft : 0;
  },
);

export const failedPendingItemIdsSel = state =>
  moduleSel(state).failedPendingItemIds;
