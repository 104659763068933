import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './TablePlaceholder.less';

const TablePlaceholderItem = () => (
  <ContentLoader width={500} height={50} className={styles.item}>
    <circle cx="25" cy="25" r="15" />
    <rect x="80" y="17" rx="3" ry="3" width="80" height="15" />
    <rect x="200" y="17" rx="3" ry="3" width="100" height="15" />
    <rect x="340" y="17" rx="3" ry="3" width="100" height="15" />
  </ContentLoader>
);

export default TablePlaceholderItem;
