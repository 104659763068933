import React from 'react';

import cn from 'classnames';

import Avatar from '../Avatar';
import styles from './PersonAvatar.less';
import Placeholder from './components/Placeholder';

type Props = {
  className?: string;
  bordered?: boolean;
  isAdult: boolean;
  transparent?: boolean;
  pictureUrl?: string;
  src?: string;
};

const PersonAvatar: React.FC<Props> = ({
  isAdult,
  className,
  bordered = true,
  transparent,
  pictureUrl,
  ...rest
}) => {
  const title = isAdult ? 'Adult' : 'Youth';
  const classNames = cn(
    styles.avatar,
    {
      [styles.transparent]: transparent,
      [styles.bordered]: bordered,
      [styles.adult]: isAdult,
    },
    className,
  );

  return (
    <Avatar
      className={classNames}
      title={title}
      bordered={bordered}
      placeholder={<Placeholder isAdult={isAdult} pictureUrl={pictureUrl} />}
      {...rest}
    />
  );
};

export default PersonAvatar;
