import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { offlineSel } from '../../duck';
import Card from '../Card';
import T from '../T';
import Button from '../Button';
import styles from './Fallback.less';

function Fallback({
  useOfflineMsg,
  notFoundMessage,
  showReturnButton,
  onReturnClick,
  returnLabel,
}) {
  const isOffline = useSelector(offlineSel);
  return (
    <Card className={styles.card} margin>
      <T size="3" bold colored>
        {isOffline && useOfflineMsg ? (
          <FormattedMessage id="shared.Fallback.offline" />
        ) : (
          notFoundMessage || <FormattedMessage id="shared.Fallback.notFound" />
        )}
      </T>
      {showReturnButton && (
        <Button
          className={styles.button}
          type="primary"
          noBorder
          onClick={onReturnClick}
        >
          {returnLabel || <FormattedMessage id={'shared.Fallback.return'} />}
        </Button>
      )}
    </Card>
  );
}

Fallback.propTypes = {
  useOfflineMsg: PropTypes.bool,
  notFoundMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  returnLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showReturnButton: PropTypes.bool,
  onReturnClick: PropTypes.func,
};

Fallback.defaultProps = {
  onReturnClick: () => {},
};

export default Fallback;
