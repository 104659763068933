import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import { FormattedMessage } from 'react-intl';

import { SingleConfirmationModal } from '@shared';

import { ProgressButton } from '../../../common';
import { openRecordAdvancement } from '../../duck';

class RecordAdvancementButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.confirmModalRef = React.createRef();
  }

  handleClick = () => {
    const { askForConfirmation } = this.props;
    if (askForConfirmation) {
      this.openConfirmationModal();
    } else {
      this.onRecordAdvancement();
    }
  };

  onRecordAdvancement = () => {
    const { onRecordAdvancement, onClick } = this.props;
    onRecordAdvancement();
    onClick && onClick();
  };

  openConfirmationModal = () => {
    this.confirmModalRef.current.openConfirmationModal();
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    this.onRecordAdvancement();
    onConfirm && onConfirm();
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  render() {
    const {
      disabled,
      className,
      children,
      askForConfirmation,
      disableConfirmBtn,
      confirmationMessage,
      ...rest
    } = this.props;

    return (
      <React.Fragment>
        {askForConfirmation && (
          <SingleConfirmationModal
            ref={this.confirmModalRef}
            disableConfirm={disableConfirmBtn}
            confirmBtnText={<FormattedMessage id="shared.confirm" />}
            onCancel={this.handleCancel}
            message={confirmationMessage}
            onConfirm={this.handleConfirm}
          />
        )}
        <ProgressButton
          color="scouting-warm-gray"
          noBorder
          fitText
          disabled={disabled}
          icon={<TrackChangesIcon />}
          id="qa_recordAdvancement"
          className={className}
          {...rest}
          onClick={this.handleClick}
        >
          {children || (
            <FormattedMessage id="advancement.PackRoster.recordAdvancement" />
          )}
        </ProgressButton>
      </React.Fragment>
    );
  }
}

const validateIfAskForConfirm =
  validator =>
  (props, ...args) => {
    if (props.askForConfirmation) {
      return validator(props, ...args);
    }
  };

RecordAdvancementButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  userIds: PropTypes.array.isRequired,
  onClick: PropTypes.func,

  onRecordAdvancement: PropTypes.func.isRequired,
  askForConfirmation: PropTypes.bool,
  disableConfirmBtn: validateIfAskForConfirm(PropTypes.bool),
  confirmationMessage: validateIfAskForConfirm(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  ),
  onCancel: validateIfAskForConfirm(PropTypes.func),
  onConfirm: validateIfAskForConfirm(PropTypes.func),
};

const mapDispatch = (dispatch, { userIds }) => ({
  onRecordAdvancement: () => dispatch(openRecordAdvancement(userIds)),
});

export default connect(null, mapDispatch)(RecordAdvancementButton);
