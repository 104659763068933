import React, { useCallback, useEffect, useMemo } from 'react';

import { FormComponentProps } from 'antd/lib/form';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { subUnitTypes } from '@modules/advancement/subUnits';
import { editSubUnitRequest } from '@modules/advancement/subUnits/duck/actions';
import {
  approvedSubUnitsSel,
  createSubUnitInProgressSel,
  subUnitTypeSel,
} from '@modules/advancement/subUnits/duck/selectors';
import {
  mapSubUnitAdults,
  mapSubUnitScouts,
} from '@modules/advancement/utilsTyped';
import { setShouldMemberDetailsReload } from '@modules/youthProfile/duck/actions';
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  hasPermissionSel,
  intl,
  permissions,
  selectedOrganizationSel,
} from '@shared';
import { validateForm } from '@utils';

import {
  currentUserSubunitsSel,
  setSubUnitUserData,
  subUnitUserDataSel,
} from '../../duck';
import styles from './AddToSubUnitForm.less';

const { Item: FormItem } = Form;

type Props = FormComponentProps;

const AddToSubUnitForm: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const subUnitType = useSelector(subUnitTypeSel);
  const isLoadingCreateSubUnit = useSelector(createSubUnitInProgressSel);
  const subUnitsList = useSelector(approvedSubUnitsSel);
  const userData = useSelector(subUnitUserDataSel);
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );
  const mySubUnits = useSelector(currentUserSubunitsSel);
  const { canEditSubCalendar: hasSubUnitOnlyPerms } = useSelector(
    selectedOrganizationSel,
  );
  const { getFieldDecorator } = form;
  const isDen = subUnitType === subUnitTypes.DEN;

  const filteredSubUnitList = useMemo(() => {
    if (hasSubUnitOnlyPerms && !canEditSubUnits) {
      return subUnitsList.filter(sub =>
        mySubUnits.find(mySub => {
          const mySubId = mySub.denId || mySub.patrolId;
          return mySubId === sub.subUnitId;
        }),
      );
    }

    return subUnitsList;
  }, [mySubUnits, hasSubUnitOnlyPerms, subUnitsList, canEditSubUnits]);

  useEffect(
    () => () => {
      dispatch(setSubUnitUserData({ subUnitId: 0, subUnitName: '' }));
    },
    // eslint-disable-next-line
    [dispatch],
  );

  const validate = useCallback(() => validateForm(form), [form]);

  const handleSaveSubUnit = useCallback(async () => {
    const subUnitData = await validate();
    if (subUnitData) {
      dispatch(setShouldMemberDetailsReload(true));
      const isAdult = userData.isAdult;

      const selectedSubUnit = subUnitsList.find(
        subUnit => subUnit.subUnitId === Number(subUnitData.subUnitId),
      );

      dispatch(
        editSubUnitRequest({
          subUnitName: selectedSubUnit?.subUnitNameRaw as string,
          selectedUsers: isAdult
            ? []
            : [mapSubUnitScouts(userData, isDen, selectedSubUnit?.denType)],
          selectedAdults: isAdult ? [mapSubUnitAdults(userData, isDen)] : [],
          subUnitId: Number(subUnitData.subUnitId),
          denType:
            isDen && selectedSubUnit?.denType
              ? selectedSubUnit.denType
              : undefined,
        }),
      );
    }
  }, [validate, isDen, userData, subUnitsList, dispatch]);

  return (
    <Row type="flex" justify="space-between">
      <Col span={24}>
        <FormattedHTMLMessage
          id={'packRoster.AddSubUnit.AddToSubUnitForm.Instruction'}
          values={{ personFullName: userData.personFullName }}
        />
      </Col>
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('subUnitId', {
            initialValue: filteredSubUnitList[0].subUnitId,
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'shared.form.error.isRequired',
                }),
              },
            ],
          })(
            <Select size="large">
              <Select.OptGroup>
                {filteredSubUnitList.map(({ subUnitId, subUnitName }) => (
                  <Select.Option key={subUnitId} value={subUnitId}>
                    {subUnitName}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Select>,
          )}
        </FormItem>
      </Col>
      <Col span={24} className={styles.saveButton}>
        <Button onClick={handleSaveSubUnit} disabled={isLoadingCreateSubUnit}>
          <FormattedMessage id="shared.save" />
        </Button>
      </Col>
    </Row>
  );
};

export default Form.create()(AddToSubUnitForm);
