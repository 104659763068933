import { gtm } from '@shared';
import { moduleName } from '../constants';

export const COUNSELED_YOUTH_ROUTE = `${moduleName}/COUNSELED_YOUTH_ROUTE`;
export const navigateToCounseledYouth = () => ({
  type: COUNSELED_YOUTH_ROUTE,
});

export const COUNSELED_YOUTH_REQUEST = `${moduleName}/COUNSELED_YOUTH_REQUEST`;
export const counseledYouthRequest = () => ({
  type: COUNSELED_YOUTH_REQUEST,
  gtm: {
    category: gtm.categories.COUNSELED_YOUTH,
  },
});

export const COUNSELED_YOUTH_RESPONSE = `${moduleName}/COUNSELED_YOUTH_RESPONSE`;
export const counseledYouthResponse = payload => ({
  type: COUNSELED_YOUTH_RESPONSE,
  payload,
});

export const switchToTab = activeTab => ({
  type: COUNSELED_YOUTH_ROUTE,
  payload: { hash: activeTab },
});

export const COUNSELED_YOUTH_SEARCH = `${moduleName}/COUNSELED_YOUTH_SEARCH`;
export const counseledYouthSearch = text => ({
  type: COUNSELED_YOUTH_SEARCH,
  payload: text,
});

export const COUNSELED_YOUTH_RESPONSE_ERROR = `${moduleName}/COUNSELED_YOUTH_RESPONSE_ERROR`;
export const responseErr = err => ({
  type: COUNSELED_YOUTH_RESPONSE_ERROR,
  payload: err,
});
