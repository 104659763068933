import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { PersonAvatar } from '@shared';
import { historyItemsTypes } from '../../../constants';
import styles from './HistoryItemAvatar.less';

const HistoryItemAvatar = ({ type, isAdult, profilePictureUrl, className }) => {
  switch (type) {
    case historyItemsTypes.ADVANCEMENT:
    case historyItemsTypes.ACTIVITY: {
      return (
        <div className={cn(styles.avatarWrapper, className)}>
          <PersonAvatar pictureUrl={profilePictureUrl} isAdult={isAdult} />
        </div>
      );
    }
    case historyItemsTypes.ADVANCEMENT_FILE: {
      return '';
    }
    default: {
      throw new Error(`Unsupported type: ${type}`);
    }
  }
};

HistoryItemAvatar.propTypes = {
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  isAdult: PropTypes.bool,
  profilePictureUrl: PropTypes.string,
  className: PropTypes.string,
};

export default HistoryItemAvatar;
