import { reduce } from 'lodash';

export const trimField = field =>
  typeof field === 'string' ? field.trim() : field;

export const trimObject = object =>
  reduce(
    object,
    (memo, value, key) => {
      memo[key] = trimField(value);
      return memo;
    },
    object,
  );
