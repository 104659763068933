import { toastService } from '@toasts';

export default ({ content, status, persistent, toastType = 'error' }) => {
  const isServerError = Number(status) >= 500;
  persistent = persistent || isServerError;
  const toastFn = toastService[toastType];

  return toastFn(content, undefined, {
    withCloseButton: persistent,
    autoClose: !persistent,
    closeOnClick: !persistent,
  });
};
