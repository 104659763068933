import React from 'react';
import PropTypes from 'prop-types';

import { AdvancementType, ActivityType, historyItemsTypes } from '@shared';

const PendingItemType = ({ item }) => {
  const { type, advancementType, activityType } = item;

  return type === historyItemsTypes.ADVANCEMENT ? (
    <AdvancementType type={advancementType} />
  ) : (
    <ActivityType activityType={activityType} />
  );
};

PendingItemType.propTypes = {
  item: PropTypes.object,
};

export default PendingItemType;
