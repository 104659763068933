import { Program, advancementTypes } from '@shared';

export { eventsModuleName as moduleName } from '@shared';

export const cloneEventFields = [
  'eventType',
  'location',
  'startTime',
  'endTime',
  'mapUrl',
  'description',
];

export const recurrenceTypesIds = Object.freeze({
  DAILY: 1,
  DAILY_WEEKDAYS: 2,
  WEEKLY_ONE_DAY: 3,
  WEEKLY_MULTIPLE_DAYS: 4,
  MONTHLY: 5,
  YEARLY: 6,
  /** Monthly (day) */
  MONTHLY_NTH_DAY: 7,
  /** Yearly (day) */
  YEARLY_NTH_DAY: 8,
});

export const recurrenceTypes = [
  {
    id: recurrenceTypesIds.DAILY,
    short: 'Daily',
    long: 'Occurs Daily',
  },
  {
    id: recurrenceTypesIds.DAILY_WEEKDAYS,
    short: 'Daily weekdays',
    long: 'Occurs daily, only on Weekdays',
  },
  {
    id: recurrenceTypesIds.WEEKLY_ONE_DAY,
    short: 'Weekly one day',
    long: 'Occurs once a Week',
  },
  {
    id: recurrenceTypesIds.WEEKLY_MULTIPLE_DAYS,
    short: 'Weekly multiple days',
    long: 'Occurs on selected days in a Week',
  },
  {
    id: recurrenceTypesIds.MONTHLY,
    short: 'Monthly (date)',
    long: 'Occurs once a Month',
  },
  {
    id: recurrenceTypesIds.YEARLY,
    short: 'Yearly (date)',
    long: 'Occurs once a Year',
  },
  {
    id: recurrenceTypesIds.MONTHLY_NTH_DAY,
    short: 'Monthly (day)',
    long: 'Occurs on the nth Weekday of a month',
  },
  {
    id: recurrenceTypesIds.YEARLY_NTH_DAY,
    short: 'Yearly (day)',
    long: 'Occurs on the nth Weekday of one month in the year',
  },
];

export const recurrenceDays = [
  { id: 1, text: 'Su', label: 'Sunday' },
  { id: 2, text: 'M', label: 'Monday' },
  { id: 3, text: 'Tu', label: 'Tuesday' },
  { id: 4, text: 'W', label: 'Wednesday' },
  { id: 5, text: 'Th', label: 'Thursday' },
  { id: 6, text: 'F', label: 'Friday' },
  { id: 7, text: 'Sa', label: 'Saturday' },
];

export const recurrenceMonths = [
  { id: 1, name: 'January', maxDays: 31 },
  { id: 2, name: 'February', maxDays: 28 },
  { id: 3, name: 'March', maxDays: 31 },
  { id: 4, name: 'April', maxDays: 30 },
  { id: 5, name: 'May', maxDays: 31 },
  { id: 6, name: 'Jun', maxDays: 30 },
  { id: 7, name: 'July', maxDays: 31 },
  { id: 8, name: 'August', maxDays: 31 },
  { id: 9, name: 'September', maxDays: 30 },
  { id: 10, name: 'October', maxDays: 31 },
  { id: 11, name: 'November', maxDays: 30 },
  { id: 12, name: 'December', maxDays: 31 },
];

export const recurrenceNthDays = [
  { value: 'first', label: 'First' },
  { value: 'second', label: 'Second' },
  { value: 'third', label: 'Third' },
  { value: 'fourth', label: 'Fourth' },
  { value: 'last', label: 'Last' },
];

export const userEventStatus = Object.freeze({
  NEW: 'new',
  OLD: 'old',
  DELETED: 'deleted',
});

export const defaultRsvpUser = Object.freeze({
  attended: false,
  primaryLeader: false,
  status: userEventStatus.NEW,
});

export const defaultRsvpEventUser = Object.freeze({
  primaryLeader: false,
  status: userEventStatus.NEW,
});

export const attendeesTypes = Object.freeze({
  ATTENDING: 'attending',
  NOT_ATTENDING: 'notAttending',
  MAYBE: 'maybe',
});

export const attendeesTypesAction = Object.freeze({
  ATTENDING: 'attendingAction',
  NOT_ATTENDING: 'notAttendingAction',
  MAYBE: 'maybeAction',
});

export const typesAction = Object.freeze({
  [attendeesTypes.ATTENDING]: 'attendingAction',
  [attendeesTypes.NOT_ATTENDING]: 'notAttendingAction',
  [attendeesTypes.MAYBE]: 'maybeAction',
});

export const rsvpTypes = Object.freeze({
  ATTENDING: true,
  NOT_ATTENDING: false,
  MAYBE: null,
});

export const rsvpOtherActions = Object.freeze({
  ATTENDED: 'attended',
  NOT_ATTENDED: 'notAttended',
  REMOVE: 'remove',
});

export const dividerKey = Object.freeze({
  ATTENDED: 'attendedKey',
  NOT_ATTENDED: 'notAttendedKey',
  REMOVE: 'removeKey',
  ATTENDING: 'attendingKey',
  NOT_ATTENDING: 'notAttendingKey',
  MAYBE: 'maybeKey',
});

export const attendedActions = Object.freeze({
  [rsvpOtherActions.ATTENDED]: true,
  [rsvpOtherActions.NOT_ATTENDED]: false,
});

export const reminderRsvpSetting = Object.freeze({
  YES: 'yes',
  NO: 'no',
  ALL: 'all',
  MAYBE: 'maybe',
  YES_N_MAYBE: 'yes and maybe',
});

export const customEvenTypeProgram = Object.freeze({
  PATROL: 'PATROL',
  DEN: 'DEN',
});

export const eventTypesNames = Object.freeze({
  [Program.CUB_SCOUT]: 'pack',
  [Program.BOY_SCOUT]: 'troop',
  [Program.VENTURING]: 'crew',
  [Program.SEA_SCOUT]: 'ship',
  [Program.EXPLORING]: 'post',
  [Program.CLUB]: 'club',
  [customEvenTypeProgram.PATROL]: 'patrol',
  [customEvenTypeProgram.DEN]: 'den',
});

export const requirementsTypesNames = Object.freeze({
  [advancementTypes.MERIT_BADGES]: 'Merit Badges',
  [advancementTypes.RANKS]: 'Ranks',
  [advancementTypes.AWARDS]: 'Awards',
  [advancementTypes.ADVENTURES]: 'Adventures',
});

export const requirementsTypesValues = Object.freeze({
  [advancementTypes.MERIT_BADGES]: 'Merit Badge',
  [advancementTypes.RANKS]: 'Rank',
  [advancementTypes.AWARDS]: 'Award',
  [advancementTypes.ADVENTURES]: 'Adventure',
});

export const requirementsTypesIds = Object.freeze({
  'Merit Badge': advancementTypes.MERIT_BADGES,
  Rank: advancementTypes.RANKS,
  Award: advancementTypes.AWARDS,
  Adventure: advancementTypes.ADVENTURES,
});

export const advancementsSteps = Object.freeze({
  SELECT_ADVANCEMENT: 'selectAdvancements',
  SELECT_REQUIREMENTS: 'selectRequirements',
});

export const advancementsStepsOrder = [
  advancementsSteps.SELECT_ADVANCEMENT,
  advancementsSteps.SELECT_REQUIREMENTS,
];

export const attendeeTypeToRsvpValues = Object.freeze({
  [attendeesTypes.ATTENDING]: rsvpTypes.ATTENDING,
  [attendeesTypes.NOT_ATTENDING]: rsvpTypes.NOT_ATTENDING,
  [attendeesTypes.MAYBE]: rsvpTypes.MAYBE,
});

export const sortTags = Object.freeze({
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  NICKNAME: 'nickName',
});

export const sortTranslations = Object.freeze({
  [sortTags.FIRST_NAME]: 'firstName',
  [sortTags.LAST_NAME]: 'lastName',
  [sortTags.NICKNAME]: 'lastName',
});

export const sortTypeLabel = Object.freeze({
  firstName: 'First Name',
  lastName: 'Last Name',
});

export const groupTags = Object.freeze({
  IS_ADULT: 'isAdult',
  NONE: 'none',
  RESERVATION_STATUS: 'rsvp',
});

export const groupTranslations = Object.freeze({
  [groupTags.IS_ADULT]: 'memberType',
  [groupTags.PRIMARY_LEADER]: 'roleType',
  [groupTags.NONE]: 'noFilter',
  [groupTags.RESERVATION_STATUS]: 'reservationStatus',
});

export const partitionNames = Object.freeze({
  [groupTags.NONE]: ['none', 'all'],
  [groupTags.PRIMARY_LEADER]: ['leadership', 'nonLeaders'],
  [groupTags.RESERVATION_STATUS]: ['confirmed', 'maybe', 'declined'],
  [groupTags.IS_ADULT]: ['adults', 'youths', 'denChiefs'],
});

// groups and sorters work in function of
// group and sort tags respectively
export const groups = Object.keys(groupTags).map(tag => ({
  value: groupTags[tag],
  translation: groupTranslations[groupTags[tag]],
}));

export const sorters = Object.keys(sortTags).map(tag => ({
  value: sortTags[tag],
  translation: sortTranslations[sortTags[tag]],
}));

export const fieldState = Object.freeze({
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  REQUIRED: 'Required',
});

export const eventTypes = Object.freeze({
  CAMPOUT: 'Campout',
  OPEN_HOUSE: 'Open House',
  PACK_MEETING: 'Pack Meeting',
  RECRUITING: 'Recruiting',
  DAY_RESIDENT_FAMILY_CAMP: 'Day/Resident/Family Camp',
  COMMITTEE_MEETING: 'Committee Meeting',
  TRAINING: 'Training',
  COURT_OF_HONOR: 'Court of Honor',
  TROOP_MEETING: 'Troop Meeting',
  CREW_MEETING: 'Crew Meeting',
  SHIP_MEETING: 'Ship Meeting',
  PATROL_MEETING: 'Patrol Meeting',
  DEN_MEETING: 'Den Meeting',
});

export const camelColName = Object.freeze({
  UNIT_ID: 'unitId',
});

export const denSortOrder = Object.freeze([
  'lions',
  'tigers',
  'wolves',
  'bears',
  'webelos',
  'aol',
]);

const AdminPositions = Object.freeze({
  DEN_ADMIN: 151,
  PATROL_ADMIN: 152,
  PACK_ADMIN: 153,
  TROOP_ADMIN: 154,
  TEAM_ADMIN: 157,
  CREW_ADMIN: 158,
  COUNCIL_ADMIN: 206,
  SHIP_ADMIN: 213,
});

export const extraType = Object.freeze({
  UNASSIGNED: 'Unassigned',
});

export const AdminPositionIds = Object.values(AdminPositions);

export const personSelectOptions = [
  { value: 'SCOUTS', label: 'All Youth' },
  { value: 'LEADERS', label: 'Leaders' },
  { value: 'PARENTS', label: 'Parents/Guardians' },
];

export const personSelectOptionsType = Object.freeze({
  SCOUTS: 'SCOUTS',
  LEADERS: 'LEADERS',
  PARENTS: 'PARENTS',
});

export const commentLength = 500;
