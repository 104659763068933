import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeUnmarkAsMultipleModal,
  isUnmarkAsMultipleModalVisible,
} from '../../../duck';
import UnmarkAsMultiple from './UnmarkAsMultiple/UnmarkAsMultiple';
import styles from './UnmarkAsMultipleModal.less';

const UnmarkAsMultipleModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isUnmarkAsMultipleModalVisible);
  const handleCloseUnmarkAsMultipleModal = () =>
    dispatch(closeUnmarkAsMultipleModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.unmarkAsMultipleModal.title',
      })}
      onCancel={handleCloseUnmarkAsMultipleModal}
      onBack={handleCloseUnmarkAsMultipleModal}
    >
      <UnmarkAsMultiple />
    </Modal>
  );
};

export default UnmarkAsMultipleModal;
