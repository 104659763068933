import _, { isEmpty } from 'lodash';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';

import { denSortOrder } from '@modules/events/constants';
import {
  ProgramId,
  firstClassRankOrder,
  memberTypeIds,
  objElementName,
  ranksOrder,
} from '@shared/constants';

import personNameBuilder from '../../utils/personNameBuilder';
import { renewalStatuses } from './constants';
import { findRegistrationRecord } from './utilsTyped';

export const normalizeRankData = rank => ({
  ...rank,
  ...(rank.rank ? {} : { rank: rank.name }),
  level: Number(rank.level || 0),
  rankOrder: Number(rank.rankOrder || 0),
  programId: Number(rank.programId || 0),
  unitTypeId: Number(rank.unitTypeId || 0),
});

export const adultPackRosterDtoToModel = roster =>
  roster['users'].map(member => ({
    userId: Number(member.userId),
    isAdult: true,
    isLeader: true,
    isParent: false,
    ...member,
    memberTypeId: memberTypeIds.adult,
    key3: member.key3 === 'True',
    personId: member.memberId,
    personShortFullName: personNameBuilder.short(member),
    age: member.age > 21 ? '(21+)' : '(18-21)',
    currentHighestRankApproved: { rank: '' },
    otherHighestRanksApproved: [],
    rosterOtherHighestRanksApproved: [],
  }));

const mergeHighestRanks = (...arrays) =>
  orderBy(
    uniqBy(orderBy(flatten(arrays), 'level').reverse(), 'unitTypeId'),
    'unitTypeId',
  );

const getRankData = (
  { highestRanksApproved = [], highestRanksAwarded = [] },
  currentUnitTypeId,
) => {
  const highestRanks = mergeHighestRanks(
    highestRanksApproved,
    highestRanksAwarded,
  ).map(rank => ({
    ...rank,
    isActiveUnit: rank.unitTypeId == currentUnitTypeId,
  }));
  const currentHighestRank = highestRanks.find(
    ({ unitTypeId }) => unitTypeId == currentUnitTypeId,
  );
  const boyScoutHighestRank = highestRanks.find(
    ({ unitTypeId }) => unitTypeId == ProgramId.BOY_SCOUT,
  );
  const cubScoutHighestRank = highestRanks.find(
    ({ unitTypeId }) => unitTypeId == ProgramId.CUB_SCOUT,
  );
  const rosterCurrentHighestRank = currentHighestRank ||
    boyScoutHighestRank ||
    cubScoutHighestRank || { programId: Infinity };
  const currentRankOrder = ranksOrder[(currentHighestRank || {}).rank] || 0;
  const rosterCurrentRankOrder = ranksOrder[rosterCurrentHighestRank.rank] || 0;
  const hasMinFirstClass =
    ranksOrder[(boyScoutHighestRank || {}).rank] >= firstClassRankOrder;

  return {
    hasMinFirstClass,
    currentHighestRankApproved: normalizeRankData({
      rankOrder: currentRankOrder,
      programId: currentUnitTypeId,
      unitTypeId: currentUnitTypeId,
      ...currentHighestRank,
    }),
    otherHighestRanksApproved: highestRanks
      .filter(({ unitTypeId }) => unitTypeId != currentUnitTypeId)
      .map(normalizeRankData),
    rosterCurrentHighestRankApproved: normalizeRankData({
      rankOrder: rosterCurrentRankOrder,
      ...rosterCurrentHighestRank,
    }),
    rosterOtherHighestRanksApproved: highestRanks
      .filter(({ id }) => id != rosterCurrentHighestRank.id)
      .map(normalizeRankData),
  };
};

export const youthPackRosterDtoToModel = (
  roster,
  currentUnitTypeId,
  unitParticipants = [],
) => {
  const members = roster['users'];
  const allMembers = [...members, ...unitParticipants];

  return allMembers.map(member => {
    const age = member.age
      ? member.age
      : member.dateOfBirth && moment().diff(member.dateOfBirth, 'years');

    const memberTypeId =
      age < 18 ? memberTypeIds.youth : memberTypeIds.participant;
    const isUnitParticipant = memberTypeId === memberTypeIds.participant;

    return {
      userId: Number(member.userId),
      ...member,
      isAdult: false,
      isParent: false,
      isLeader: false,
      isUnitParticipant,
      memberTypeId,
      personShortFullName: personNameBuilder.short(member),
      personId: member.memberId,
      age,
      ...getRankData(member, currentUnitTypeId),
    };
  });
};

const getRenewalStatus = registrarInfo => {
  const {
    isAutoRenewalOptedOut,
    isYearlyMembershipRenewalPaid,
    registrationExpireDt,
  } = registrarInfo;
  if (isAutoRenewalOptedOut) return renewalStatuses.OPTED_OUT;
  if (isYearlyMembershipRenewalPaid) return renewalStatuses.RENEWED;
  if (moment().isAfter(moment(registrationExpireDt)))
    return renewalStatuses.EXPIRED;
  if (moment(registrationExpireDt).isBefore(moment().add(2, 'months')))
    return renewalStatuses.ELIGIBLE_TO_RENEW;

  return renewalStatuses.CURRENT;
};

export const getMembersRenewalObject = orgInfo =>
  orgInfo.members.map(({ registrarInfo, personFullName, personGuid }) => {
    const renewalStatus = getRenewalStatus(registrarInfo);

    return {
      personFullName,
      personGuid,
      renewalStatus,
      isYearlyMembershipRenewalPaid:
        registrarInfo.isYearlyMembershipRenewalPaid,
      registrationExpireDt: moment(registrarInfo.registrationExpireDt),
      registrationEffectiveDt: moment(registrarInfo.registrationEffectiveDt),
      registrationId: registrarInfo.registrationId,
    };
  });

const canOptOut = (renewalStatus, registrationExpireDt) => {
  if (renewalStatus === renewalStatuses.OPTED_OUT) return false;

  const exprDt = moment(registrationExpireDt);

  if (moment().isAfter(exprDt) && moment().isBefore(exprDt.add(1, 'month')))
    return true;

  if (
    moment().isBefore(exprDt) &&
    moment().isAfter(exprDt.subtract(3, 'months'))
  )
    return true;

  return false;
};

export const getPersonRenewalStatus = (personItem, renewalsInfo) => {
  const emptyStatus = {
    renewalStatus: 'Needs to Register',
    registrationExpireDt: '',
  };

  if (isEmpty(renewalsInfo)) return emptyStatus;

  const pickData = record => {
    const baseProps = _.pick(record, [
      'renewalStatus',
      'isYearlyMembershipRenewalPaid',
      'registrationExpireDt',
      'registrationId',
    ]);

    return {
      ...baseProps,
      canOptOut: canOptOut(record.renewalStatus, record.registrationExpireDt),
    };
  };

  const { isAdult, isLeader } = personItem;

  if (!isAdult && renewalsInfo.youthMembers.length) {
    // We can get multiple registrations. Get last with registrationEffectiveDt
    const foundPerson = findRegistrationRecord(
      renewalsInfo.youthMembers,
      personItem.personGuid,
    );
    return foundPerson ? pickData(foundPerson) : emptyStatus;
  }

  if (isLeader && renewalsInfo.adultMembers.length) {
    // We can get multiple registrations. Get last with registrationEffectiveDt
    const foundPerson = findRegistrationRecord(
      renewalsInfo.adultMembers,
      personItem.personGuid,
    );
    return foundPerson ? pickData(foundPerson) : emptyStatus;
  }

  return '';
};

export function sortSubUnitsBy(foundSubUnits, name) {
  if (foundSubUnits.some(sub => !!sub.denType)) {
    return _.sortBy(foundSubUnits, [
      den => (den[name] === objElementName.UNASSIGNED ? 1 : 0),
      den => _.indexOf(denSortOrder, den.denType?.toLowerCase()),
      den => den[name],
    ]);
  }

  return _.sortBy(foundSubUnits, [
    sub => (sub[name] === objElementName.UNASSIGNED ? 1 : 0),
    sub => sub[name].toLowerCase(),
  ]);
}
