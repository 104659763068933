import { ENABLE_VENTURING_RANKS } from '@config';
import { isEagleScoutRank } from '@modules/shared/utils';
import { Program, ProgramId, advancementTypes } from '@shared/constants';

const isCubScoutAdvancement = item => item.programId == ProgramId.CUB_SCOUT;

const canEditCubScoutAdvancement = ({ item }) => {
  if (item.programId && item.programId != ProgramId.CUB_SCOUT) {
    return false;
  }
  return [
    advancementTypes.ADVENTURES,
    advancementTypes.RANKS,
    advancementTypes.AWARDS,
  ].includes(item.advancementType);
};

const canEditBoyScoutAdvancement = ({ item }) => {
  if (
    [advancementTypes.AWARDS, advancementTypes.MERIT_BADGES].includes(
      item.advancementType,
    )
  ) {
    return true;
  }

  return (
    item.advancementType === advancementTypes.RANKS &&
    item.programId == ProgramId.BOY_SCOUT
  );
};

const hasMinFirstClass = ({ item, youthItems }) =>
  (
    youthItems.find(
      ({ personGuid, userId }) =>
        (personGuid && personGuid === item.personGuid) ||
        (userId && userId == item.userId),
    ) || {}
  ).hasMinFirstClass;

const canEditVenturingAdvancement = ({ item, youthItems }) => {
  const firstClass = hasMinFirstClass({ item, youthItems });
  if (firstClass) {
    if (
      [advancementTypes.AWARDS, advancementTypes.MERIT_BADGES].includes(
        item.advancementType,
      )
    ) {
      return true;
    }
    const canEdit =
      item.advancementType === advancementTypes.RANKS &&
      (item.programId == ProgramId.BOY_SCOUT ||
        item.programId == ProgramId.VENTURING);

    return canEdit;
  }

  return !ENABLE_VENTURING_RANKS
    ? item.advancementType === advancementTypes.AWARDS
    : item.advancementType === advancementTypes.AWARDS ||
        (item.advancementType === advancementTypes.RANKS &&
          item.programId == ProgramId.VENTURING);
};

const canEditSeaScoutAdvancement = ({ item, youthItems }) => {
  const isSeaScoutRank =
    item.advancementType === advancementTypes.RANKS &&
    item.programId == ProgramId.SEA_SCOUT;

  if (item.advancementType === advancementTypes.AWARDS) {
    return true;
  }
  if (hasMinFirstClass({ item, youthItems })) {
    if (
      [advancementTypes.AWARDS, advancementTypes.MERIT_BADGES].includes(
        item.advancementType,
      )
    ) {
      return true;
    }

    const isBoyScoutRank =
      item.advancementType === advancementTypes.RANKS &&
      item.programId == ProgramId.BOY_SCOUT;

    return isBoyScoutRank || isSeaScoutRank;
  }

  return isSeaScoutRank;
};

const canEditExploringAdvancement = ({ item }) =>
  item.advancementType === advancementTypes.AWARDS &&
  item.programId == ProgramId.EXPLORING;

const canEditClubAdvancement = ({ item }) =>
  item.advancementType === advancementTypes.AWARDS &&
  item.programId == ProgramId.CLUB;

export default ({ item, program, youthItems }) => {
  if (item.isAdvancementPending) {
    return false;
  }
  if (program != Program.CUB_SCOUT && isCubScoutAdvancement(item)) {
    return false;
  }

  if (isEagleScoutRank(item)) {
    return false;
  }

  switch (program) {
    case Program.CUB_SCOUT: {
      return canEditCubScoutAdvancement({ item });
    }
    case Program.BOY_SCOUT: {
      return canEditBoyScoutAdvancement({ item });
    }
    case Program.VENTURING: {
      return canEditVenturingAdvancement({
        item,
        youthItems,
      });
    }
    case Program.SEA_SCOUT: {
      return canEditSeaScoutAdvancement({
        item,
        youthItems,
      });
    }
    case Program.EXPLORING: {
      return canEditExploringAdvancement({ item });
    }
    case Program.CLUB: {
      return canEditClubAdvancement({ item });
    }
  }
};
