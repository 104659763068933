import { ROUTE_FEEDBACK } from './duck';
import { FeedbackPage } from './components';

const routes = {
  [ROUTE_FEEDBACK]: {
    path: '/feedback',
    Component: FeedbackPage,
    titleId: 'page.feedback',
  },
};

export default routes;
