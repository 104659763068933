import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { normalizeUnitGender } from '@shared/utils';

import {
  adminAkelaRoles,
  organizationRoles,
  scoutbookRoles,
} from './constants';

const isYouthMember = (role, roleTypes = []) =>
  organizationRoles.YOUTH_MEMBER.includes(role) ||
  roleTypes.some(({ roleType }) =>
    organizationRoles.YOUTH_MEMBER.includes(roleType),
  );

const isParentGuardian = (role, roleTypes = []) =>
  organizationRoles.PARENT_GUARDIAN.includes(role) ||
  roleTypes.some(({ roleType }) =>
    organizationRoles.PARENT_GUARDIAN.includes(roleType),
  );

const isAdvancementChair = role =>
  role === organizationRoles.ADVANCEMENT_COORDINATOR;

const isMeritBadgeCounselor = role =>
  organizationRoles.MERIT_BADGE_COUNSELOR === role;

const isScoutbookRole = (role, roleTypes = []) =>
  scoutbookRoles.includes(role) ||
  roleTypes.some(({ roleType }) => scoutbookRoles.includes(roleType));

const isKey3 = role => adminAkelaRoles.includes(role);

const isDistrictExecutive = role =>
  organizationRoles.DISTRICT_EXECUTIVES.includes(role);

const isCouncilAdmin = role => organizationRoles.COUNCIL_ADMINS.includes(role);

// From least to most important
const roleLevels = [
  isMeritBadgeCounselor,
  isAdvancementChair,
  isScoutbookRole,
  isKey3,
  isDistrictExecutive,
  isCouncilAdmin,
];

// council admin > District Executive > key3 > unit advancement chair > everything else
const getRoleLevel = (role, roleTypes) => {
  if (!role) {
    return -1;
  }
  const roleLevelsLength = roleLevels.length;
  for (let i = roleLevelsLength; i > 0; i--) {
    const roleLevelFn = roleLevels[i - 1];
    if (roleLevelFn(role, roleTypes)) {
      return i;
    }
  }
  return 0;
};

const getHighestRole = (roleA, roleB, roleTypes) => {
  const roleALevel = getRoleLevel(roleA);
  const roleBLevel = getRoleLevel(roleB, roleTypes);
  if (roleALevel >= roleBLevel) {
    return roleA;
  }
  return roleB;
};

export const dedupeRoleTypes = roles => {
  // Makes sure roles not from scoutbook are first
  roles.sort((x, y) =>
    x.isScoutbookRole === y.isScoutbookRole ? 0 : x.isScoutbookRole ? 1 : -1,
  );
  const parentAndYouthRoles = roles
    .filter(
      ({ role, roleTypes }) =>
        isYouthMember(role, roleTypes) || isParentGuardian(role, roleTypes),
    )
    .map(organization => ({
      ...organization,
      unitGender: normalizeUnitGender(organization.acceptGender),
    }));
  const groups = groupBy(roles, 'organizationGuid');
  const unitRoles = Object.keys(groups)
    .map(organizationGuid => {
      const group = groups[organizationGuid];
      let isAkelaAdvChair;
      return group.reduce((acc, node) => {
        const {
          role,
          roleTypes = [],
          programTypeId,
          programType,
          organizationName,
          organizationTypeId,
          isScoutbookRole,
          acceptGender,
          denDetails = {},
        } = node;
        const denDetailsArr = acc.denDetailsArr || [];
        const isParentOrYouth =
          isYouthMember(role) || isParentGuardian(role, roleTypes);
        // to handle only unit advancement chair from akela and scoutbook since they both use the same string
        if (isAdvancementChair(role) && acc.isAkelaAdvChair === undefined) {
          isAkelaAdvChair = !isScoutbookRole || undefined;
        }
        return {
          ...acc,
          ...node,
          organizationName: acc.organizationName || organizationName,
          organizationTypeId: acc.organizationTypeId || organizationTypeId,
          isAkelaAdvChair,
          role: isParentOrYouth
            ? acc.role
            : getHighestRole(acc.role, role, roleTypes),
          roleTypes: isParentOrYouth
            ? acc.roleTypes || []
            : [...(acc.roleTypes || []), ...roleTypes],
          programTypeId: acc.programTypeId || programTypeId,
          programType: acc.programType || programType,
          allRoles: uniq([...(acc.allRoles || []), role]),
          unitGender: acc.unitGender || normalizeUnitGender(acceptGender),
          denDetailsArr: isEmpty(denDetails)
            ? denDetailsArr
            : [...denDetailsArr, denDetails],
        };
      }, {});
    })
    .filter(item => !isEmpty(item));

  const parentAndYouthRolesUpdated = parentAndYouthRoles.map(record => {
    const { allRoles } = unitRoles.find(
      ({ organizationGuid }) => organizationGuid === record.organizationGuid,
    );

    return {
      ...record,
      allRoles: allRoles,
    };
  });

  return [...parentAndYouthRolesUpdated, ...unitRoles];
};
