import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Table, PaginationTotal, sorterPropTypes } from '@shared';
import columns from './unitsTableColumns';

const UnitsTable = ({
  items,
  loading,
  sorter,
  hasSearchedItems,
  onSelectItem,
  onSorterChange,
  onRemoveItem,
}) => {
  const handleTableChange = useCallback(
    (pagination, filters, sorter) => onSorterChange(sorter),
    [onSorterChange],
  );
  const paginationConfig = useMemo(
    () => ({
      // eslint-disable-next-line
      showTotal: total => <PaginationTotal total={total} />,
      pageSizeOptions: ['10', '20'],
    }),
    [],
  );
  const handleRowAction = useCallback(
    unit => ({ onClick: () => onSelectItem(unit) }),
    [onSelectItem],
  );
  return (
    <Table
      size="middle"
      headerColor="scouting-warm-gray"
      fixedLayout
      clickableRows
      loading={loading}
      rowKey="organizationGuid"
      columns={columns({ sorter, hasSearchedItems, onRemoveItem })}
      dataSource={items}
      sorter={sorter}
      onRow={handleRowAction}
      pagination={paginationConfig}
      onChange={handleTableChange}
    />
  );
};

UnitsTable.propTypes = {
  //Provided by parent
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  sorter: sorterPropTypes,
  hasSearchedItems: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired,
  onSorterChange: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func,
};

export default UnitsTable;
