import { createSelector } from 'reselect';

import {
  advancementHistoryModuleSel,
  advancementModuleSel,
  advancementTabNames,
  allNotAwardedAdvancementsCountSel,
  isCouncilOrgActiveSel,
  notAwardedAdvancementsCountSel,
  organizationGuidSel,
  packRosterItemsSel,
  packRosterLoadingSel,
  packRosterModuleSel,
  parentOrgGuidSel,
} from '@shared';

import { reportsSel } from '../../reports';
import {
  advancementReportModuleName,
  advancementUploadModuleName,
  pendingItemsModuleName,
  purchaseOrdersModuleName,
} from './constants';

export {
  advancementModuleSel,
  advancementHistoryModuleSel,
  packRosterModuleSel,
  packRosterLoadingSel,
  packRosterItemsSel,
  notAwardedAdvancementsCountSel,
  allNotAwardedAdvancementsCountSel,
};

export const isValidOrganizationSel = state => {
  const isCouncil = isCouncilOrgActiveSel(state);
  if (isCouncil) {
    return parentOrgGuidSel(state) !== organizationGuidSel(state);
  }
  return true;
};

export const advancementReportModuleSel = state =>
  advancementModuleSel(state)[advancementReportModuleName];

export const advancementUploadModuleSel = state =>
  advancementModuleSel(state)[advancementUploadModuleName];

export const activeTabSel = state => advancementModuleSel(state).activeTab;
export const unitRosterReportLoadingSel = state =>
  advancementModuleSel(state).unitRosterReportLoading;

export const pendingItemsModuleSel = state =>
  advancementModuleSel(state)[pendingItemsModuleName];

export const purchaseOrdersModuleSel = state =>
  advancementModuleSel(state)[purchaseOrdersModuleName];

export const youthItemsSel = createSelector(packRosterItemsSel, items =>
  items.filter(item => !item.isAdult),
);
export const adultItemsSel = createSelector(packRosterItemsSel, items =>
  items.filter(item => item.isAdult),
);

export const leaderItemsSel = createSelector(packRosterItemsSel, items =>
  items.filter(item => item.isLeader),
);

export const selectedKeysSel = state => packRosterModuleSel(state).selectedKeys;
export const selectedItemsSel = createSelector(
  packRosterItemsSel,
  selectedKeysSel,
  (items, selectedKeys) =>
    items.filter(item => selectedKeys.includes(item.userId)),
);

export const isMissingSomeMemberIdSel = createSelector(
  selectedItemsSel,
  selectedItems => selectedItems.some(item => !item.memberId),
);

export const reportsSelectedItemsSel = createSelector(
  selectedItemsSel,
  activeTabSel,
  (selectedItems, activeTab) =>
    activeTab === advancementTabNames.ROSTER ? selectedItems : [],
);

export const advancementHistoryLoadingSel = state =>
  advancementHistoryModuleSel(state).loading;

export const selectedYouthSel = createSelector(
  selectedItemsSel,
  selectedItems => selectedItems.filter(({ isAdult }) => !isAdult),
);
export const selectedUnitReportCodeSel = state =>
  advancementModuleSel(state).selectedUnitReportCode;
export const selectedUnitReportSel = createSelector(
  selectedUnitReportCodeSel,
  reportsSel,
  (selectedReportCode, reports) =>
    reports.find(({ reportCode }) => reportCode == selectedReportCode) || {},
);
