import { RosterScout } from '@modules/advancement/duck/types';

import type {
  Adventure,
  AdventureRequirement,
  AdventureUserRequirements,
  RankRequirement as ESBRankRequirement,
  GetUserAdventureRequirementsRes,
  MeritBadgeRequirement,
  MeritBadgeUserRequirements,
  MeritBadgeV1,
  RankV1,
  UserRankVersionRequirementInfo,
} from '../../../types/esb';
import { QEValidationResult } from './utils/validators';

type MeritBadgeVersion = MeritBadgeV1['versions'][0];

// TODO
export interface RankVersion {
  version: string;
  versionEffectiveDt?: string;
  expiredDate?: string;
  versionExpiryDt?: string;
  versionId: string;
}

export type AdventureVersion = Adventure['versions'][0];

export type AdvancementVersion =
  | RankVersion
  | MeritBadgeVersion
  | AdventureVersion;

export type RankRequirement = ESBRankRequirement;

export type AdvancementRequirement =
  | MeritBadgeRequirement
  | RankRequirement
  | AdventureRequirement;

export type Rank = RankV1;

export enum BulkEntryType {
  MERIT_BADGES = 'merit-badges',
  RANKS = 'ranks',
  ADVENTURES = 'adventures',
}

/**
 * Redux slice for quickEntryRank
 */
export interface BulkEntryState {
  // START common
  entryType?: BulkEntryType;
  selectedVersion?: AdvancementVersion;
  requirements: AdvancementRequirement[];
  preselectedInfo: {
    rank?: Rank;
    meritBadge?: MeritBadgeV1;
    adventure?: Adventure;
    version?: AdvancementVersion;
  };
  comment: string;
  /** User IDs of selected Scouts */
  selectedScouts: number[];
  /** Requirement IDs of selected Requiremetns */
  selectedRequirements: number[];
  markAsApproved?: boolean;
  completionDate?: Date;
  /**
   * List of validation errors for the Scouts indexed by user id.
   *
   *  `{ [userId]: QEValidationResult[] }`
   **/
  validationResults: Record<number, QEValidationResult[]>;
  // END common

  // Adventures
  selectedAdventure?: Adventure;
  adventureUserRequirements: AdventureUserRequirements[];

  // Merit Badge specific
  selectedMeritBadge?: MeritBadgeV1;
  /** If user is selecting one or multiple Merit Badges to apply */
  mbEntryType: 'single' | 'multiple';
  selectedMeritBadges: MeritBadgeV1['id'][];
  meritBadgeUserRequirements: MeritBadgeUserRequirements[];

  // Ranks specific
  selectedRank?: Rank;
  scoutsRankVersionInfo: UserRankVersionRequirementInfo[];
}

// Used in the store state to save data for all bulk entries

export interface CommonValidatorParams {
  scout: RosterScout;
  selectedVersion?: AdvancementVersion;
  selectedRank?: Rank;
  // adventures
  selectedAdventure?: Adventure;
  userAdventureRequirements?: GetUserAdventureRequirementsRes;
}

export interface CommonErrorParams extends CommonValidatorParams {
  message: string;
}
