import React from 'react';

import PropTypes from 'prop-types';

import { Program, ProgramId } from '../../constants';

const programNames = {
  [Program.CUB_SCOUT]: 'Cub Scout',
  [ProgramId.CUB_SCOUT]: 'Cub Scout',
  [Program.BOY_SCOUT]: 'Scouts BSA',
  [ProgramId.BOY_SCOUT]: 'Scouts BSA',
  [Program.VARSITY]: 'Varsity Team',
  [ProgramId.VARSITY]: 'Varsity Team',
  [Program.VENTURING]: 'Venturing Crew',
  [ProgramId.VENTURING]: 'Venturing Crew',
  [Program.SEA_SCOUT]: 'Sea Scout',
  [ProgramId.SEA_SCOUT]: 'Sea Scout',
  [Program.EXPLORING]: 'Exploring',
  [ProgramId.EXPLORING]: 'Exploring',
  [Program.CLUB]: 'Club',
  [ProgramId.CLUB]: 'Club',
};

const getTranslation = (program, programId) =>
  programNames[program] || programNames[+programId];

const ProgramName = ({ program, programId, ...props }) => {
  const name = getTranslation(program, programId);

  return <span {...props}>{name}</span>;
};

ProgramName.propTypes = {
  program: PropTypes.string,
  programId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProgramName.getTranslation = getTranslation;

export default ProgramName;
