import React from 'react';

import { FormattedMessage } from 'react-intl';

import type { PendingItem } from '@modules/advancement/pendingItems/types';
import { DateView, S } from '@shared';

import { RequirementAdvancementType } from '../PendingItemsTable/PendingRequirementsColumns';
import styles from './PendingItemsList.less';

export const renderDefaultRows = () => (item: PendingItem) => {
  const { date, requirementName } = item;
  const div = document.createElement('p');
  div.innerHTML = requirementName;
  const clean = div.textContent || div.innerText || '';

  return [
    {
      rowId: 'requirementName',
      label: <RequirementAdvancementType row={item} />,
      content: <p className={styles.requirementName}>{clean}</p>,
      className: styles.requirementNameContainer,
    },
    {
      rowId: 'completionDate',
      label: (
        <S size="4" bold>
          <FormattedMessage id="advancement.PendingItems.column.itemDate" />
        </S>
      ),
      content: (
        <S size="4">
          <DateView value={date} />
        </S>
      ),
    },
  ];
};
