import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Modal, Row, S } from '@shared/components';

import { stepOrder } from '../../../constants';
import {
  closePrivacyModal,
  currentStepSel,
  isPrivacyModalVisibleSel,
  setCurrentStep,
} from '../../../duck';
import styles from './DocumentPrivacyModal.less';

const DocumentPrivacyModal = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(currentStepSel);
  const isVisible = useSelector(isPrivacyModalVisibleSel);
  const proceedToNextStep = () => {
    const currentIndex = stepOrder.indexOf(currentStep);
    dispatch(setCurrentStep(stepOrder[currentIndex + 1]));
    dispatch(closePrivacyModal());
  };
  return (
    <Modal visible={isVisible}>
      <React.Fragment>
        <Row type="flex" justify="center">
          <Col>
            <S size="4" className={styles.text}>
              <FormattedMessage id="recharterModals.validation.privacy" />
            </S>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col>
            <Button
              className={styles.button}
              type="primary"
              shape="round"
              size="default"
              shadow
              block
              uppercase={false}
              onClick={proceedToNextStep}
            >
              <FormattedMessage id="recharterModals.paymentModal.ok" />
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    </Modal>
  );
};

export default DocumentPrivacyModal;
