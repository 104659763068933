import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, intl, StateSelect } from '@shared';
import {
  dictionaryIds,
  dictionarySel,
  dictionaryLoadedSel,
  dictionariesRequest,
} from '@dict';

const FormItem = Form.Item;

class AddressState extends React.Component {
  componentDidMount() {
    this.props.onLoadStates();
  }

  render() {
    const { form, disabled, loaded, states } = this.props;
    const { getFieldDecorator } = form;

    return (
      <FormItem
        colon={false}
        label={intl.formatMessage({ id: 'shared.address.state.label' })}
        validateStatus={loaded ? null : 'validating'}
        standardLayout
      >
        {getFieldDecorator('akelaStateId')(
          <StateSelect
            disabled={disabled}
            size="large"
            states={states}
            statesLoaded={loaded}
          />,
        )}
      </FormItem>
    );
  }
}

AddressState.propTypes = {
  // provided by parent
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  // provided by component itself
  loaded: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  onLoadStates: PropTypes.func.isRequired,
};

const mapState = state => ({
  loaded: dictionaryLoadedSel(state, dictionaryIds.STATES),
  states: dictionarySel(state, dictionaryIds.STATES) || [],
});

const mapDispatch = dispatch => ({
  onLoadStates: () => dispatch(dictionariesRequest(dictionaryIds.STATES)),
});

export default connect(mapState, mapDispatch)(AddressState);
