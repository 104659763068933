import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { esbApiService } from '@utils';

export const fetchCalendarEvents$ = ({
  unitId,
  invitedUserId,
  fromDate,
  toDate,
  showDLEEvents = true,
  // withActivities = false,
}) =>
  esbApiService
    .post$(
      '/advancements/events',
      {
        unitId,
        invitedUserId,
        fromDate,
        toDate,
        showDLEvents: showDLEEvents, // TODO: ESB typo
      },
      {
        gtm: {
          label: '/advancements/events',
        },
        swCache: true,
        suppressErrorToasts: [401],
        // TODO: test if this is causing the CORS issue when calling events
        // headers: {
        //   'json-filter': `{ activityId activityType activityTypeId dateCreated dateCreated description endDate eventType firstName id isActivityMeeting isAdvancementMeeting isAttended lastName location ${
        //     withActivities ? 'linkedActivities' : ''
        //   } name recurringEvent {*} rsvp rsvpCode startDate units {*} userId userRsvp }`,
        // },
      },
    )
    .pipe(
      map(events => events),
      catchError(err => {
        if (err.status !== 401) {
          throw err;
        }
        return of([]);
      }),
    );
