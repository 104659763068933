import localforage from 'localforage';

const key = 'SBL_PENDING_REQUESTS';
let subscriptions = [];
let counter = 1;

const store = localforage.createInstance({
  name: 'sbl-pending-requests',
  storeName: 'requests',
});

const getAllRequests = async () => {
  const requests = await store.getItem(key);
  return requests || {};
};

const getRequests = async personGuid => {
  const allRequests = await getAllRequests();
  return allRequests[personGuid] || [];
};

const notify = data => subscriptions.forEach(s => s(data));

const setAllRequests = async requests => {
  await store.setItem(key, requests);
  notify(requests);
};

const setRequests = async (personGuid, requests) => {
  const allRequests = await getAllRequests();
  allRequests[personGuid] = requests;
  await setAllRequests(allRequests);
};

const addRequest = async (personGuid, request) => {
  request.timestamp = Date.now();
  request.id = `${counter++}_${request.timestamp}`;
  let requests = await getRequests(personGuid);
  requests = [...requests, request];
  await setRequests(personGuid, requests);
};

const notifyNewSubscriber = async sub => {
  const requests = await getAllRequests();
  sub && sub(requests);
};

const subscribe = sub => {
  subscriptions = [...subscriptions, sub];
  notifyNewSubscriber(sub);

  return () => {
    subscriptions = subscriptions.filter(s => s !== sub);
  };
};

export default {
  getRequests,
  setRequests,
  addRequest,
  subscribe,
};
