import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'bsa-ui';
import InfoIcon from 'material-ui-icons/Info';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  activityTypeForActivityTypeId,
  activityTypeIds,
  activityValuesTabs,
} from '../../constants';
import useCalculateDays from '../../hooks/useCalculateDays';
import Form from '../Form';
import Input from '../Input';
import PersonAvatar from '../PersonAvatar';
import S from '../S';
import styles from './ActivitiesFormModal.less';
import {
  daysLessOrEqualRule,
  getSetFormActivityValues,
  positiveNumRule,
  requiredRule,
} from './utils';

const FormItem = Form.Item;

export const IndividualTab = ({
  form,
  youths,
  leaders,
  activityTypeId,
  isActive,
  initialTab,
  activityData,
}) => {
  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;
  const diff = useCalculateDays(getFieldsValue(), form);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (activityData && !isInitialized && (youths.length || leaders.length)) {
      const { registeredAdults = [], registeredYouths = [] } = activityData;
      const youthFieldsToSet = getSetFormActivityValues(
        activityTypeId,
        initialTab,
        activityValuesTabs.ADVANCED,
        'Youth',
        registeredYouths,
      );
      const adultFieldsToSet = getSetFormActivityValues(
        activityTypeId,
        initialTab,
        activityValuesTabs.ADVANCED,
        'Adult',
        registeredAdults,
      );
      setFieldsValue({ ...youthFieldsToSet, ...adultFieldsToSet });
      setIsInitialized(true);
    }
  }, [
    activityData,
    activityTypeId,
    youths,
    leaders,
    isInitialized,
    initialTab,
    setFieldsValue,
  ]);

  const getInputs = useCallback(
    (userType, userId) => (
      <Fragment>
        {activityTypeId === activityTypeIds.CAMPOUTS && (
          <Fragment>
            <Col xs={24} lg={5}>
              <FormItem>
                {getFieldDecorator(`days${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive
                    ? [
                        requiredRule(),
                        positiveNumRule(),
                        daysLessOrEqualRule(diff + 1, true),
                      ]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Days" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={5}>
              <FormItem>
                {getFieldDecorator(`nights${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive
                    ? [
                        requiredRule(),
                        positiveNumRule(),
                        daysLessOrEqualRule(diff + 0, false),
                      ]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Nights" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={5}>
              <FormItem>
                {getFieldDecorator(`frostPoints${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive ? [positiveNumRule()] : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.FrostPoints" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Fragment>
        )}
        {activityTypeId === activityTypeIds.HIKES && (
          <Fragment>
            <Col xs={24} lg={4}>
              <FormItem>
                {getFieldDecorator(`miles${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive
                    ? [requiredRule(), positiveNumRule()]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Miles" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={4}>
              <FormItem>
                {getFieldDecorator(`lowestPoint${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive ? [positiveNumRule()] : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.LowestPoint" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={4}>
              <FormItem>
                {getFieldDecorator(`highestPoint${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive ? [positiveNumRule()] : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.HighestPoint" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Fragment>
        )}

        {activityTypeId === activityTypeIds.SERVICE_PROJECTS && (
          <Fragment>
            <Col xs={24} lg={4}>
              <FormItem>
                {getFieldDecorator(`hours${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive
                    ? [requiredRule(), positiveNumRule()]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Hours" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Fragment>
        )}

        {activityTypeId === activityTypeIds.LONG_CRUISE && (
          <Fragment>
            <Col xs={24} lg={5}>
              <FormItem>
                {getFieldDecorator(`days${userType}_${userId}`, {
                  initialValue: '0',
                  rules: isActive
                    ? [
                        requiredRule(),
                        positiveNumRule(),
                        daysLessOrEqualRule(diff + 1, true),
                      ]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Days" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Fragment>
        )}
      </Fragment>
    ),
    [getFieldDecorator, activityTypeId, isActive, diff],
  );

  return (
    <Fragment>
      <Row>
        <Col>
          <div className={styles.tabDescription}>
            <InfoIcon color="info" />
            <S size="5">
              <FormattedMessage
                id={`progress.common.ActivityTabs.${activityTypeForActivityTypeId[activityTypeId]}.advancedDescription`}
                values={{
                  tabName: (
                    <b>
                      <FormattedMessage id="progress.common.ActivityTabs.advanced" />
                    </b>
                  ),
                }}
              />
            </S>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.tabUsersTitle}>
          <S size={'4'} colored>
            <FormattedMessage id="shared.youths" />({youths.length})
          </S>
        </Col>
        <Col>
          {youths.map(youth => (
            <Row key={`indTav-${youth.userId}`} type="flex" gutter={8}>
              <Col xs={24} lg={9}>
                <div className={styles.tabUser}>
                  <PersonAvatar
                    bordered={false}
                    isAdult={youth.isAdult}
                    className={styles.tabAvatar}
                    pictureUrl={youth.pictureUrl}
                  />
                  <S size={'5'} className={styles.personName}>
                    {youth.personFullName}
                  </S>
                </div>
              </Col>
              {getInputs('Youth', youth.userId)}
            </Row>
          ))}
        </Col>
      </Row>
      <Row>
        <Col className={styles.tabUsersTitle}>
          <S size={'4'} colored>
            <FormattedMessage id="shared.adults" />({leaders.length})
          </S>
        </Col>
        <Col>
          {leaders.map(lead => (
            <Row key={`indTav-${lead.userId}`} type="flex" gutter={8}>
              <Col xs={24} lg={9}>
                <div className={styles.tabUser}>
                  <PersonAvatar
                    bordered={false}
                    isAdult={lead.isAdult}
                    className={styles.tabAvatar}
                    pictureUrl={lead.pictureUrl}
                  />
                  <S size={'5'} className={styles.personName}>
                    {lead.personFullName}
                  </S>
                </div>
              </Col>
              {getInputs('Adult', lead.userId)}
            </Row>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

IndividualTab.propTypes = {
  youths: PropTypes.array.isRequired,
  leaders: PropTypes.array.isRequired,
  activityTypeId: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  initialTab: PropTypes.string.isRequired,
  activityData: PropTypes.object,
};
