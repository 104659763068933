import React from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import { Row } from 'bsa-ui';
import { FormattedMessage } from 'react-intl';

import { RosterScout } from '@modules/advancement/duck/types';
import { AddPersonButton } from '@progress/components';
import { S, T } from '@shared';

import { MoveUserPerson } from '../../MoveUsersList';
import parentStyles from '../AddSubUnitForm.less';
import styles from './AddSubUnitMobile.less';

type Props = {
  attendeeLabel: 'adults' | 'youths' | 'denChiefs';
  availableUsers: RosterScout[];
  subUnitTypeName: string;
  selectedUserIds: Set<number>;
  onAddPerson: (userId: number) => void;
  onRemovePerson: (userId: number) => void;
};

const AddSubUnitMobile: React.FC<Props> = ({
  attendeeLabel,
  availableUsers,
  onAddPerson,
  onRemovePerson,
  selectedUserIds,
  subUnitTypeName,
}) => {
  const selectedUsers = availableUsers.filter(({ userId }) =>
    selectedUserIds.has(userId),
  );

  return (
    <Row className={styles.wrapper}>
      <Row>
        <T colored size="3">
          <FormattedMessage
            id={`packRoster.AddSubUnit.AddSubUnitForm.selected.${attendeeLabel}.${subUnitTypeName}`}
            values={{ availableScouts: selectedUserIds.size }}
          />
        </T>
        <AddPersonButton
          persons={availableUsers}
          selectedPersons={selectedUsers.map(({ userId }) => userId)}
          onDeselectPerson={onRemovePerson}
          onSelectPerson={onAddPerson}
          label={
            <FormattedMessage
              id={`packRoster.AddSubUnit.AddSubUnitForm.available.${attendeeLabel}`}
            />
          }
          canRemove
        />
      </Row>
      <Row>
        {selectedUsers.map(person => (
          <MoveUserPerson
            key={person.userId}
            isAdult={person.isAdult}
            personName={person.personShortFullName}
            onSelect={onRemovePerson}
            selectIcon={<CancelIcon />}
            userId={person.userId}
          />
        ))}
        {selectedUserIds.size === 0 && (
          <div className={parentStyles.emptySubUnitDsc}>
            <T size="4" bold>
              <FormattedMessage
                id={`packRoster.AddSubUnit.AddSubUnitForm.emptyTitle.${subUnitTypeName}`}
              />
            </T>
            <S size="5">
              <FormattedMessage
                id={`packRoster.AddSubUnit.AddSubUnitForm.emptyDeselectDsc.${attendeeLabel}`}
              />
            </S>
          </div>
        )}
      </Row>
    </Row>
  );
};

export default AddSubUnitMobile;
