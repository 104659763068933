import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider as ReactIntlProvider, addLocaleData } from 'react-intl';
import moment from 'moment';
import 'moment/locale/en-ie';
import 'moment/locale/es';

// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
// eslint-disable-next-line import/named
import { LocaleProvider as BsaLocaleProvider } from 'bsa-ui';
import { updateIntl, Spin } from '@shared';
import { locales } from '../../constants';
import { localeSel, services } from '../../duck';

const bsaLocales = {
  en: () => import('bsa-ui/src/locale-provider/en_US'),
  es: () => import('bsa-ui/src/locale-provider/es_ES'),
};

const reactIntlLocales = {
  en: () => import('react-intl/locale-data/en'),
  es: () => import('react-intl/locale-data/es'),
};

export class IntlProvider extends React.Component {
  state = {
    ready: false,
    messages: {},
    bsaLocaleData: {},
  };

  componentDidMount() {
    if (!this.state.ready) {
      this.refreshMessages(this.props.locale);
    }
  }

  componentDidUpdate(prevProps) {
    const { locale } = this.props;
    if (prevProps.locale !== locale) {
      this.refreshMessages(locale);
    }
  }

  refreshMessages = async locale => {
    const [messages, bsaLocaleData, reactIntlLocaleData] = await Promise.all([
      services.getLocaleData(locales[locale]),
      bsaLocales[locale](),
      reactIntlLocales[locale](),
    ]);

    addLocaleData([...reactIntlLocaleData.default]);
    moment.locale(locale);
    updateIntl(locale, messages);

    this.setState({
      ready: true,
      messages,
      bsaLocaleData: bsaLocaleData.default,
    });
  };

  render() {
    const { children, locale } = this.props;
    const { ready, messages, bsaLocaleData } = this.state;

    return (
      <ReactIntlProvider locale={locale} messages={messages}>
        <BsaLocaleProvider locale={bsaLocaleData}>
          {ready ? children : <Spin size="large" centeredInViewport />}
        </BsaLocaleProvider>
      </ReactIntlProvider>
    );
  }
}

IntlProvider.propTypes = {
  children: PropTypes.object,
  locale: PropTypes.string,
};

const mapStateToProps = state => ({
  locale: localeSel(state),
});

export default connect(mapStateToProps)(IntlProvider);
