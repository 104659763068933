import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import stable from 'stable';

import {
  //components
  Form,
  Input,
  Select,
  Row,
  Col,
  // utils
  intl,
} from '@shared';

import {
  categoriesAndOrganizationsRequest,
  categoriesSel,
} from '../../../../common';
import { maxDescriptionLength } from '../../../constants';

import styles from './ActivityDetails.less';

const { TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;

const ActivityDetails = ({
  form,
  activityTypeId,
  disabled,
  showCategorySelector,
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(categoriesSel);
  const { getFieldDecorator } = form;

  const sortedCategories = useMemo(
    () =>
      stable(
        categories.filter(
          category => Number(activityTypeId) === Number(category.actTypeId),
        ),
        (a, b) => String(a.name).localeCompare(b.name),
      ),
    [categories, activityTypeId],
  );

  const categoryItems = useMemo(
    () =>
      sortedCategories.map(({ id, name }) => (
        <Option key={id} text={name} value={id}>
          {name}
        </Option>
      )),
    [sortedCategories],
  );

  useEffect(() => {
    dispatch(categoriesAndOrganizationsRequest());
  }, [dispatch]);

  return (
    <Row className={styles.container} type="flex" justify="space-between">
      {showCategorySelector && (
        <Col xs={24} lg={11}>
          <FormItem>
            {getFieldDecorator('categoryId', {
              rules: [{ required: true, message: 'This field is required' }],
            })(
              <Select
                disabled={disabled}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'activity.ActivityDetails.field.category.placeholder',
                })}
                label={intl.formatMessage({
                  id: 'activity.ActivityDetails.field.category',
                })}
                optionFilterProp="text"
                showSearch
                floatingLabel
                rounded
              >
                {categoryItems}
              </Select>,
            )}
          </FormItem>
        </Col>
      )}
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('description')(
            <TextArea
              disabled={disabled}
              placeholder={intl.formatMessage({ id: 'shared.description' })}
              maxLength={maxDescriptionLength}
              autosize={{ minRows: 3 }}
              rounded
            />,
          )}
        </FormItem>
      </Col>
    </Row>
  );
};

ActivityDetails.propTypes = {
  activityTypeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  showCategorySelector: PropTypes.bool.isRequired,
};

ActivityDetails.defaultProps = {
  showCategorySelector: false,
};

export default ActivityDetails;
