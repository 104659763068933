import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, Checkbox } from '@shared';

class AdvancementsFilesSection extends React.PureComponent {
  handleChange = () => {
    const { value, onChange } = this.props;

    onChange(!value);
  };

  render() {
    const { value } = this.props;

    return (
      <Checkbox
        id="qa_advancementsFiles"
        checked={value}
        name="showAdvancementFiles"
        onChange={this.handleChange}
      >
        <S size="4" colored inline>
          <FormattedMessage id="shared.show" />
        </S>
      </Checkbox>
    );
  }
}

AdvancementsFilesSection.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdvancementsFilesSection;
