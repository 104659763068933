import React, { useCallback, useMemo } from 'react';

import { difference, startsWith, xor } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectedRowKeysSel,
  setSelectedRowKeys,
} from '@modules/advancement/pendingItems/duck';
import type {
  GroupedPendingItem,
  GroupedPendingItemChild,
  PendingItem,
} from '@modules/advancement/pendingItems/types';
import { groupPendingItems } from '@modules/advancement/pendingItems/utils';
import { useGetAdvancementHistoryQuery } from '@modules/rtk/esb-api';
import {
  TableMobile,
  offlineSel,
  packRosterItemsCountSel,
  selectedOrganizationSel,
} from '@modules/shared';

import { PendingRequirementsListFab } from './PendingRequirementsListFab';
import { rednerHeaderRow } from './PendingRequirementsListHeaderRow';
import { renderDefaultRows } from './PendingRequirementsListRows';

export type FlatListItem =
  | GroupedPendingItemChild
  | {
      row: JSX.Element;
      key: string;
      type: string;
    };

interface PendingRequirementsListProps {
  onGoToYouthProfile: (userId: number, activityType?: string) => () => void;
  search: string;
}

export const PendingRequirementsListOriginal: React.FC<
  PendingRequirementsListProps
> = props => {
  const { onGoToYouthProfile, search } = props;
  const dispatch = useDispatch();
  const selectedRowKeys = useSelector(selectedRowKeysSel) as string[];
  const selectedOrg = useSelector(selectedOrganizationSel);
  const rosterItemsCount = useSelector(packRosterItemsCountSel);
  const isOffline = useSelector(offlineSel);
  const {
    data: pendingItemsData,
    isLoading,
    isFetching,
  } = useGetAdvancementHistoryQuery(
    {
      params: { perPage: rosterItemsCount },
      body: {
        status: ['Started', 'Completed'],
        showAdults: true,
        showYouths: true,
        organizationGuid: selectedOrg?.organizationGuid,
      },
    },
    { skip: !selectedOrg?.organizationGuid },
  );

  const items = useMemo(() => {
    if (!pendingItemsData) return [];

    return groupPendingItems(pendingItemsData).filter(
      ({ memberId, name }) =>
        name.includes(search) || startsWith(memberId.toString(), search),
    );
  }, [pendingItemsData, search]);

  const onSelectItem = useCallback(
    (record: GroupedPendingItem | PendingItem) => {
      const selectedItems = [record.key];

      dispatch(setSelectedRowKeys(xor(selectedRowKeys, selectedItems)));
    },
    [dispatch, selectedRowKeys],
  );

  const onSelectGroup = useCallback(
    (groupKey: string) => {
      const group = items.find(({ key }) => key === groupKey);
      const groupItems = group?.children.map(({ key }) => key);
      const selectedInGroup = selectedRowKeys.filter(item =>
        groupItems?.includes(item),
      );
      const diff = difference(groupItems, selectedRowKeys);

      if (groupItems?.length === selectedInGroup.length) {
        return dispatch(setSelectedRowKeys(xor(selectedRowKeys, groupItems)));
      }

      return dispatch(setSelectedRowKeys(xor(selectedRowKeys, diff)));
    },
    [dispatch, items, selectedRowKeys],
  );

  const flatItems = useMemo(
    () =>
      items.reduce<FlatListItem[]>((result, item) => {
        result.push({
          row: rednerHeaderRow({
            isOffline,
            item,
            onGoToYouthProfile,
            onSelectGroup,
            selectedRowKeys,
          }),
          key: item.key,
          type: 'custom',
        });

        item.children.forEach(row => {
          result.push(row);
        });

        return result;
      }, []),
    [isOffline, items, onGoToYouthProfile, onSelectGroup, selectedRowKeys],
  );

  return (
    <>
      <TableMobile
        items={flatItems}
        loading={isLoading || isFetching}
        onItemClick={onSelectItem}
        rowId="key"
        rows={renderDefaultRows()}
        selectedKeys={selectedRowKeys}
      />
      <PendingRequirementsListFab isOffline={isOffline} items={flatItems} />
    </>
  );
};

export const PendingRequirementsList = React.memo(
  PendingRequirementsListOriginal,
);
