import { sorters } from '@utils';

const firstNameSorter = sorters.string('firstName');
const lastNameSorter = sorters.string('lastName');
export const defaultSorter = sorters.compose(firstNameSorter, lastNameSorter);

export default {
  firstName: firstNameSorter,
  lastName: lastNameSorter,
  default: defaultSorter,
};
