import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Card from '../Card/Card';
import { Col, Row } from 'bsa-ui';
import { intl } from '../../localization';
import Form from '../Form';
import T from '../T';
import styles from './ProfileScoutingCard.less';
import DatePicker from '../DatePicker';
import { shortViewDateFormat } from '../../constants';
import Checkbox from '../Checkbox';
import S from '../S';
import moment from 'moment';
import InfoIcon from 'material-ui-icons/Info';

const { Section: CardSection } = Card;
const { Item: FormItem } = Form;

const ProfileScoutingCard = ({ form, profile = {}, isLoading, isReadOnly }) => {
  const {
    oaMember,
    oaActive,
    oaElectionDate,
    oaOrdealDate,
    oaBrotherhoodDate,
    oaVigilDate,
  } = profile;
  const { getFieldDecorator, getFieldValue } = form;

  const isActiveDisabled = () => {
    const isOaMember = getFieldValue('oaMember');

    return !isOaMember.length;
  };

  return (
    <Card margin noPadding className={styles.card} loading={isLoading}>
      <CardSection
        title={
          <T size="5" colored>
            <FormattedMessage id="shared.scoutInfo.title" />
          </T>
        }
      >
        <Row type="flex" justify="space-between">
          <Col xs={24} lg={11}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('oaMember', {
                initialValue: oaMember ? ['True'] : [],
              })(
                <Checkbox.Group
                  className={styles.notificationContainer}
                  disabled={isReadOnly}
                >
                  <div className={styles.checkboxContainer}>
                    <Checkbox value="True" />
                  </div>
                  <S size="4" inline>
                    <FormattedMessage id={'shared.scoutInfo.oaMember'} />
                  </S>
                </Checkbox.Group>,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={13}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('oaActive', {
                initialValue: oaActive ? ['True'] : [],
              })(
                <Checkbox.Group
                  className={styles.notificationContainer}
                  disabled={isReadOnly}
                >
                  <div className={styles.checkboxContainer}>
                    <Checkbox value="True" disabled={isActiveDisabled()} />
                  </div>
                  <S size="4" inline>
                    <FormattedMessage id={'shared.scoutInfo.oaActive'} />
                  </S>
                </Checkbox.Group>,
              )}
            </FormItem>
          </Col>
          <Col xs={24}>
            <div className={styles.noticeContainer}>
              <S size="4">
                <InfoIcon className={styles.infoIcon} />
                <FormattedMessage id="shared.addressInfo.oaIdReplaceNote" />
              </S>
            </div>
          </Col>
          <Col xs={24} lg={13}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('oaElectionDate', {
                initialValue: oaElectionDate
                  ? moment(oaElectionDate)
                  : oaElectionDate,
              })(
                <DatePicker
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.scoutInfo.oaElectionDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                  disabled={isReadOnly}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={11}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('oaOrdealDate', {
                initialValue: oaOrdealDate
                  ? moment(oaOrdealDate)
                  : oaOrdealDate,
              })(
                <DatePicker
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.scoutInfo.oaOrdealDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                  disabled={isReadOnly}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={13}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('oaBrotherhoodDate', {
                initialValue: oaBrotherhoodDate
                  ? moment(oaBrotherhoodDate)
                  : oaBrotherhoodDate,
              })(
                <DatePicker
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.scoutInfo.oaBrotherhoodDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                  disabled={isReadOnly}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={11}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('oaVigilDate', {
                initialValue: oaVigilDate ? moment(oaVigilDate) : oaVigilDate,
              })(
                <DatePicker
                  className={styles.datePicker}
                  size="small"
                  fluid
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.scoutInfo.oaVigilDate',
                  })}
                  format={shortViewDateFormat}
                  disableDates="future"
                  disabled={isReadOnly}
                />,
              )}
            </FormItem>
          </Col>
        </Row>
      </CardSection>
    </Card>
  );
};

ProfileScoutingCard.propTypes = {
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
};

export default ProfileScoutingCard;
