import { activityTypeIds } from '@shared';

import { CampoutsCreateButton, EditCampoutButton } from './campouts/components';
import { CreateHikeButton, EditHikeButton } from './hikes';
import { CreateLongCruiseButton, EditLongCruiseButton } from './longCruise';
import { EditServiceButton, ServiceProjectButton } from './service';

export const moduleName = 'progress';

export const editButtonsForActivityTypes = {
  [activityTypeIds.SERVICE_PROJECTS]: EditServiceButton,
  [activityTypeIds.CAMPOUTS]: EditCampoutButton,
  [activityTypeIds.HIKES]: EditHikeButton,
  [activityTypeIds.LONG_CRUISE]: EditLongCruiseButton,
};

export const addButtonsForActivityTypes = {
  [activityTypeIds.SERVICE_PROJECTS]: ServiceProjectButton,
  [activityTypeIds.CAMPOUTS]: CampoutsCreateButton,
  [activityTypeIds.HIKES]: CreateHikeButton,
  [activityTypeIds.LONG_CRUISE]: CreateLongCruiseButton,
};
