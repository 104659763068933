import React from 'react';

import CheckIcon from '@material-ui/icons/OfflinePinOutlined';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, OfflineTooltip, intl } from '@shared';

import { openApproveAdvancements } from '../../duck';

class ApproveAdvancementsButton extends React.PureComponent {
  handleClick = e => {
    const { onClick, onOpenApproveAdvancements } = this.props;

    if (onClick) {
      onClick(e);
    }

    onOpenApproveAdvancements();
  };

  render() {
    const {
      disabled,
      className,
      children,
      uppercase,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      personsAdvancements,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onOpenApproveAdvancements,
      replaceButton,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      advancementStatus,
      ...rest
    } = this.props;
    return replaceButton ? (
      React.cloneElement(replaceButton, { onClick: this.handleClick })
    ) : (
      <OfflineTooltip placement="top">
        <Button
          id="qa_approveAdvancement"
          className={className}
          disabled={disabled}
          color="info"
          ghost
          noBorder
          fitText
          icon={<CheckIcon />}
          uppercase={uppercase}
          {...rest}
          onClick={this.handleClick}
        >
          {children ||
            intl.formatMessage(
              {
                id: 'progress.ApproveAdvancementsButton.title',
              },
              { count: personsAdvancements.length },
            )}
        </Button>
      </OfflineTooltip>
    );
  }
}

ApproveAdvancementsButton.propTypes = {
  // provided by parent
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  advancementStatus: PropTypes.string,
  personsAdvancements: PropTypes.array.isRequired,
  leadershipPositions: PropTypes.array,
  onClick: PropTypes.func,
  uppercase: PropTypes.bool,
  replaceButton: PropTypes.node,
  // provided by component itself
  onOpenApproveAdvancements: PropTypes.func.isRequired,
};

ApproveAdvancementsButton.defaultProps = {
  uppercase: false,
};

const mapDispatch = (dispatch, { personsAdvancements, advancementStatus }) => ({
  onOpenApproveAdvancements: () =>
    dispatch(openApproveAdvancements(personsAdvancements, advancementStatus)),
});

export default connect(null, mapDispatch)(ApproveAdvancementsButton);
