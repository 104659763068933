import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import S from '../S';
import styles from './ActivityFieldInfo.styles.less';

function LinkValue({ href, label }) {
  const cleanUrl = new URL(href);
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className={styles.linkField}
      href={cleanUrl}
    >
      {label}
    </a>
  );
}
LinkValue.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
};

const ActivityFieldInfo = ({
  title,
  translationKey,
  value,
  className,
  nextLine,
  asLink,
  link,
}) => {
  const renderLink = link && asLink;

  return (
    <div className={className}>
      <S size="4" inline bold colon>
        {translationKey ? (
          <FormattedMessage id={`shared.ActivityFieldInfo.${translationKey}`} />
        ) : (
          title
        )}
      </S>
      {nextLine && <br />}
      &nbsp;
      {renderLink ? (
        <LinkValue href={link} label={value} />
      ) : (
        <div
          className={styles.html}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      )}
    </div>
  );
};

ActivityFieldInfo.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  translationKey: PropTypes.oneOf(['location', 'starts', 'ends']),
  className: PropTypes.string,
  nextLine: PropTypes.bool,
  asLink: PropTypes.bool,
  link: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ActivityFieldInfo.defaultProps = {
  title: '',
};

export default ActivityFieldInfo;
