import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cn from 'classnames';

import { Card } from '@shared';
import SettingsOptions from '../../SettingsOptions';
import styles from './SettingsMenu.less';

function SettingsMenu({ visible }) {
  return ReactDOM.createPortal(
    <Card
      noPadding
      shadow
      className={cn(styles.dropdownCard, { [styles.open]: visible })}
    >
      <SettingsOptions />
    </Card>,
    document.querySelector('body'),
  );
}

SettingsMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default SettingsMenu;
