import { COMMISSIONER_ACCESS } from '@config/config';

export const moduleName = 'user';

export { roles, permissions, rolePermissions } from '@shared/permissions';

export const logoutMsgs = Object.freeze({
  INIT_FAILED: 'INIT_FAILED',
});

export const GOOGLE_SIGN_IN_BUTTON_ID = 'ia-google-sign-in';

// TODO: list all roles here
export const rolesObj = Object.freeze({
  TROOP_ADMIN: 'Troop Admin',
  PACK_ADMIN: 'Pack Admin',
  UNIT_COMMISIONER: 'Unit Commissioner',
  MERIT_BADGE_COUNSELOR: 'Merit Badge Counselor',
});

export const specialLogInRoles = [
  rolesObj.UNIT_COMMISIONER,
  rolesObj.MERIT_BADGE_COUNSELOR,
];

export const key3Roles = ['Key 3', 'Unit Key 3'];

export const subUnitSBAdminRoles = [rolesObj.PACK_ADMIN, rolesObj.TROOP_ADMIN];

export const subUnitSBMasterRoles = [
  'Cubmaster',
  'Scoutmaster',
  'Explorer Post Advisor',
  'Exploring Sponsor',
];

export const canEditCalendarRoles = [
  rolesObj.TROOP_ADMIN,
  rolesObj.PACK_ADMIN,
  'Post Admin',
  'Key 3 Delegate',
  'Chartered Organization Rep.',
  'Council Unit Representative',
  'Chartered Organization Rep Delegate',
  'Committee Chairman',
  'Unit Outdoors / Activities Chair',
  'Unit Outdoors',
  'Activities Chair',
  'Committee Secretary',
  'Scoutmaster',
  'Crew Admin',
  'Venturing Crew Advisor',
  'Ship Admin',
  'Skipper',
  'Cubmaster',
  'Explorer Club Admin',
  'Exploring Advisor',
  'Exploring Sponsor',
  'Explorer Post Advisor',
];

export const canEditPermissionsManagerRoles = [
  'Unit Admin',
  'Chartered Organization Rep.',
  'Chartered Organization Rep Delegate',
  'Key 3 Delegate',
  'Key 3',
  'Unit Key 3',
];

export const canEditSubCalendarRoles = [
  'Patrol Admin',
  'Patrol Admin.',
  'Assistant Scoutmaster',
  'Den Leader',
  'Den Admin',
  'Assistant Den Leader',
  'Asst. Den Leader',
  'Assistant Webelos Den Leader',
  'Webelos Asst. Den Leader',
  'Webelos Assistant Den Leader',
  'Webelos Den Leader',
  'Tiger Den Leader',
];

export const canApproveSBRoles = [
  ...subUnitSBAdminRoles,
  'Assistant Scoutmaster',
  'Assistant Cubmaster',
  'Associate Crew Advisor',
  'Associate Sponsor',
  'Post Associate Advisor',
  'Post - Explorer Post Associate Advisor',
  'Mate',
  'Venturing Crew Assoc. Advisor',
  'Venturing Crew Advisor',
  'Unit Admin',
  'Den Admin',
  'Den Administration',
  'Patrol Admin',
  'Den Leader',
  'Ship Admin',
  'Crew Admin',
  'Post Admin',
  'Explorer Post Admin',
];

export const scoutbookRoles = [
  ...canApproveSBRoles,
  ...subUnitSBMasterRoles,
  'Skipper',
  'Committee Chairman',
  'Post Committee Chairman',
  'Chartered Organization Rep.',
  'Internet Advancement',
  'Committee Member',
  'New Member Coordinator',
  'Unit Scouter Reserve',
  'Unit College Scouter Reserve',
];

const commissionerRoles = [
  'National Commissioner Service',
  'National Scout Commissioner',
  'Regional Commissioner',
  'Assistant Regional Commissioner',
  'Area Commissioner',
  'Area Committee Chairman',
  'Council Commissioner',
  'Assistant Council Commissioner',
  'District Commissioner',
  'Asst. District Commissioner',
  'Roundtable Commissioner',
  'Asst Roundtable Commissioner',
  rolesObj.UNIT_COMMISIONER,
  'District Service Team Chair',
];

export const canEditYouthProfileRoles = [
  'Troop Admin',
  'Pack Admin',
  'Post Admin',
  'Key 3 Delegate',
  'Chartered Organization Rep.',
  'Council Unit Representative',
  'Chartered Organization Rep Delegate',
  'Committee Chairman',
  'Scoutmaster',
  'Crew Admin',
  'Venturing Crew Advisor',
  'Ship Admin',
  'Skipper',
  'Cubmaster',
  'Explorer Club Admin',
  'Exploring Advisor',
  'Exploring Sponsor',
  'Explorer Post Advisor',
  'New Member Coordinator',
  'COR Delegate',
];

export const adminAkelaRoles = [...key3Roles, 'Key 3 Delegate', 'Advancement'];

export const subUnitRoles = [
  ...adminAkelaRoles,
  rolesObj.PACK_ADMIN,
  'Troop Admin',
  'Committee Chairman',
  'Committee Chair',
  'COR Delegate',
  'Cubmaster',
  'Scoutmaster',
  'Chartered Organization Rep.',
  'New Member Coordinator',
];

export const mBCounselorRoles = [
  ...adminAkelaRoles,
  'COR Delegate',
  'Unit Advancement Chair',
  'Unit Merit Badge Administrator',
  'Council Key 3',
  'Council Admin',
  'Assistant Scout Executive',
  'Assistant Director of Field Service',
  'Director of Field Service',
  'Registration Support',
  'Council Merit Badge Administrator',
  'District Executive',
  'Assistant District Executive',
  'District Director',
  'District Advancement Chair',
  'District Merit Badge Administrator',
];

export const whitelistedPositionsRecordProgress = [
  'Troop Admin',
  'Scoutmaster',
  'Council Admin',
  'Cubmaster',
  'Committee Chair',
  'Committee Chairman',
  'Chartered Organization Rep.',
  'Key 3 Delegate',
  'COR Delegate',
  'Unit Advancement Chair',
  'Assistant Scoutmaster',
];

export const canEditPaymentLogsRoles = [
  'Chartered Organization Rep Delegate',
  'Chartered Organization Rep.',
  'Committee Chairman',
  'Council Unit Representative',
  'Crew Admin',
  'Cubmaster',
  'Key 3 Delegate',
  'Pack Admin',
  'Scoutmaster',
  'Ship Admin',
  'Skipper',
  'Troop Admin',
  'Unit Treasurer',
  'Venturing Crew Advisor',
];

export const organizationRoles = Object.freeze({
  ADMIN: [...adminAkelaRoles, ...scoutbookRoles],
  ADVANCEMENT_COORDINATOR: 'Unit Advancement Chair',
  COUNCIL_ADMINS: ['Scout Executive', 'Council Admin'],
  DISTRICT_EXECUTIVES: [
    'District Executive',
    'District Director',
    'Senior District Executive',
    'Field Director',
    'Director Field Service',
    // TODO: move this role to COUNCIL_ADMINS when it's allowed to edit data on the backend
    'Assistant Scout Executive',
    ...(COMMISSIONER_ACCESS ? commissionerRoles : []),
  ],
  MERIT_BADGE_COUNSELOR: rolesObj.MERIT_BADGE_COUNSELOR,
  YOUTH_MEMBER: ['Youth', 'Youth Member'],
  PARENT_GUARDIAN: ['Parent/Guardian', 'Parent'],
  CALENDAR_EDITOR: ['IA Edit Calendar', 'Calendar Editor'],
  PAYMENT_LOGS: ['Unit Admin', 'Chartered Organization Rep.', 'Key 3 Delegate'],
  EDIT_PAYMENT_LOGS: canEditPaymentLogsRoles,
  PERMISSIONS_MANAGER: [
    'Unit Admin',
    'Chartered Organization Rep.',
    'Chartered Organization Rep Delegate',
    'Key 3 Delegate',
    'Key 3',
    'Unit Key 3',
  ],
});

export const DEFAULT_APPS = Object.freeze({
  SB_LEGACY: 'SB_LEGACY',
  SBL: 'SBL',
});

export default {
  logoutMsgs,
};
