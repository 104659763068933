import { getRequirementsTree } from '@bsa/scouting-js-utils/src/advancements';
import { NOT_STARTED } from '@bsa/scouting-js-utils/src/constants/advancements';
import { addMeritBadgeRequirementsStatus } from '@bsa/scouting-js-utils/src/merit-badges';
import addSSElectiveRequirementsStatus from '@bsa/scouting-js-utils/src/ss-electives';
import { filter, isEmpty, isNil, uniqBy } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { zip } from 'rxjs/observable/zip';
import { catchError, map, switchMap } from 'rxjs/operators';
import stable from 'stable';

import {
  advancementStatus,
  advancementStatusDate,
  isValueTruthy,
  sentryReport,
} from '@modules/shared/utils';
import {
  ProgramId,
  advancementTypes,
  historyItemsTypes,
  venturingCoreAwardsIds,
} from '@shared/constants';
import { getAdvancementTypeTranslation } from '@shared/localization';
import { unitsAvailableAdvancements } from '@unit';
import { esbApiService } from '@utils';

import { adventureIdsToExpireIn2024, rankIdsVersion2024 } from '../constants';
import { getAdvancementUid } from '../utils';

const ADVANCEMENTS_URI = '/advancements/v2';
const ADVANCEMENTS_URI_OLD = '/advancements';

const savePicture$ = (personGuid, oldPicture, newPicture) => {
  if (oldPicture === newPicture) {
    return Observable.of(oldPicture);
  }

  const method = oldPicture ? esbApiService.put$ : esbApiService.post$;

  return method(
    `/persons/${personGuid}/profilePicture`,
    {
      picture: newPicture,
    },
    {
      gtm: {
        label: '/persons/{personGuid}/profilePicture',
      },
    },
  ).mapTo(newPicture);
};

const saveProfilePicture$ = ({ personGuid, oldPicture, newPicture }) =>
  zip(savePicture$(personGuid, oldPicture, newPicture));

/**
 * @esbEndpoint GET /persons/v2/:userId/personprofile
 */
const getYouthProfile$ = userId =>
  esbApiService.get$(`/persons/v2/${userId}/personprofile`, {
    gtm: {
      label: '/persons/v2/{userId}/personprofile',
    },
  });

/**
 * @esbEndpoint GET /persons/v2/:personGuid/trainings/ypt
 */
const getUserYPTrainingRequest$ = personGuid =>
  esbApiService.get$(`/persons/v2/${personGuid}/trainings/ypt`, {
    suppressErrorToasts: true,
    gtm: {
      label: '/persons/v2/{personGuid}/trainings/ypt',
    },
  });

/**
 * @esbEndpoint PUT /persons/v2/:personGuid/profile
 */
const saveProfile$ = (data, personGuid) =>
  esbApiService.put$(`/persons/v2/${personGuid}/profile`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/profile',
    },
  });

/**
 * @esbEndpoint PUT /advancements/users/:userId
 */
const updateSBProfile$ = (data, userId) =>
  esbApiService.put$(`/advancements/users/${userId}`, data, {
    gtm: {
      label: '/advancements/users/{userId}',
    },
  });

/**
 * @esbEndpoint POST /persons/v2/:personGuid/deleteProfileInfo
 */
const deleteProfileItem$ = (data, personGuid) =>
  esbApiService.post$(`/persons/v2/${personGuid}/deleteProfileInfo`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/deleteProfileInfo',
    },
  });

const getEligibleAdvancementTypes = (eligibleAdvancementTypes, unitTypeId) => {
  if (![ProgramId.VENTURING, ProgramId.SEA_SCOUT].includes(unitTypeId)) {
    return eligibleAdvancementTypes;
  }
  return [
    ...eligibleAdvancementTypes,
    ...unitsAvailableAdvancements[ProgramId.BOY_SCOUT],
  ];
};

const fallBackFactory =
  ({ eligibleAdvancementTypes, userId, unitTypeId }) =>
  (getAdvancements$, type) => {
    if (!eligibleAdvancementTypes.includes(type)) {
      return of([]);
    }
    return getAdvancements$(userId, unitTypeId).pipe(
      map(advancements =>
        advancements.map(adv => ({
          ...adv,
          type: historyItemsTypes.ADVANCEMENT,
        })),
      ),
    );
  };

/**
 * @esbEndpoint POST /advancements/youth/:userId/meritBadges
 */
const startYouthMeritBadge$ = ({
  userId,
  organizationGuid,
  id,
  versionId,
  dateStarted,
}) =>
  esbApiService.post$(
    `${ADVANCEMENTS_URI_OLD}/youth/${userId}/meritBadges`,
    {
      organizationGuid: organizationGuid,
      id: id,
      versionId: versionId,
      dateStarted: dateStarted,
    },
    {
      gtm: {
        label: `${ADVANCEMENTS_URI_OLD}/youth/{userId}/meritBadges`,
      },
    },
  );

const getAddtionalScoutsBSARanks = (program, unitTypeId) => {
  if (![ProgramId.VENTURING, ProgramId.SEA_SCOUT].includes(unitTypeId)) {
    return [];
  }
  const scoutsBSAProgram =
    program.find(({ programId }) => programId == ProgramId.BOY_SCOUT) || {};

  return (scoutsBSAProgram.ranks || []).map(rank => ({
    ...rank,
    programId: ProgramId.BOY_SCOUT,
    unitTypeId: ProgramId.BOY_SCOUT,
  }));
};

const dedupeAdvancements = advs => {
  const advancementsObj = {};
  for (const adv of advs) {
    const id = adv.id;
    if (!advancementsObj[id]) {
      advancementsObj[id] = adv;
      continue;
    }
    const statusCompareVal = advancementStatus.statusCompare(
      adv.status,
      advancementsObj[id].status,
    );
    if (
      statusCompareVal > 0 ||
      (statusCompareVal == 0 &&
        Number(adv.versionId) > Number(advancementsObj[id][adv.versionId]))
    ) {
      advancementsObj[id] = adv;
    }
  }

  return Object.values(advancementsObj);
};

/**
 * @esbEndpoint GET /advancements/v2/ranks/:advancementId/requirements
 * @esbEndpoint GET /advancements/meritBadges/:advancementId/requirements
 * @esbEndpoint GET /advancements/adventures/:advancementId/requirements
 * @esbEndpoint GET /advancements/awards/:advancementId/requirements
 * @esbEndpoint GET /advancements/ssElectives/:advancementId/requirements
 */
const fetchAdvRequirements$ = ({
  advancementType,
  advancementId,
  versionId,
}) => {
  const uri =
    advancementType === advancementTypes.RANKS
      ? ADVANCEMENTS_URI
      : ADVANCEMENTS_URI_OLD;
  const versionParam = versionId ? `?versionId=${versionId}` : '';
  const endpoint = `${uri}/${advancementType}/${advancementId}/requirements${versionParam}`;

  return esbApiService.get$(endpoint, {
    gtm: {
      label: `${ADVANCEMENTS_URI}/${advancementType}/${advancementId}/requirements?versionId=${versionId}`,
    },
  });
};

// only the two most recent versions are needed
const cleanupVersionsResponse = response => {
  const versions = response.versions;
  return versions.slice(0, 2);
};

/**
 * @esbEndpoint GET /advancements/v2/:advancementType/:advancementId
 */
const fetchAdvancementVersions$ = ({ advancementType, advancementId }) => {
  const endpoint = `${ADVANCEMENTS_URI}/${advancementType}/${advancementId}`;

  return esbApiService
    .get$(endpoint, {
      gtm: {
        label: `${ADVANCEMENTS_URI}/${advancementType}/${advancementId}`,
      },
    })
    .pipe(map(response => cleanupVersionsResponse(response)));
};

/**
 * @esbEndpoint PUT /advancements/v2/{userId}/{advancementType}/{rankId}/version
 */
const updateActiveVersion$ = ({
  userId,
  advancementType,
  advancementId,
  versionId,
}) =>
  esbApiService.put$(
    `${ADVANCEMENTS_URI}/${userId}/${advancementType}/${advancementId}/version`,
    { versionId: versionId },
    {
      gtm: {
        label: `${ADVANCEMENTS_URI}/${userId}/${advancementType}/${advancementId}/version`,
      },
    },
  );

const fetchYouthAdvRequirements$ = ({
  userId,
  advancementType,
  advancementId,
  userAwardId,
  omitYouthRequirement,
}) => {
  const endpoint = `${ADVANCEMENTS_URI}/youth/${userId}/${advancementType}/${advancementId}${
    userAwardId ? `/${userAwardId}` : ''
  }/requirements`;
  const label = `${ADVANCEMENTS_URI}/youth/{userId}/${advancementType}${
    userAwardId ? '/{userAwardId}' : ''
  }/{advancementId}/requirements`;

  // Youth adv record might not exist if the youth has not started working on the advancement yet
  // so there's no need to retrieve if it not started can be identified
  if (omitYouthRequirement) {
    return of({ status: NOT_STARTED });
  }

  // Ignore error 404 if requirement not existing
  return esbApiService
    .get$(endpoint, {
      suppressErrorToasts: [404],
      suppressSentry: [404],
      gtm: {
        label,
      },
    })
    .pipe(
      catchError(err => {
        if (err.status !== 404) {
          throw err;
        }
        return of({ status: NOT_STARTED });
      }),
    );
};

const mergeAdvRequirements = (
  advReqs = {},
  youthAdvReqs = {},
  advancementId,
) => {
  const { requirements: reqDict = [], rankInformation = {} } = advReqs;
  const { requirements: youthReqs = {}, versionId: rankVersionid = null } =
    youthAdvReqs;
  const requirements = reqDict.map(req => {
    const reqId = req.id || req.requirementId || req.ssElectiveRequirementId;
    const youthReq = youthReqs.find(youthReq => +youthReq.id === +reqId) || {};
    const mergedReq = {
      ...req,
      ...youthReq,
      id: +reqId,
    };
    if (
      !isNil(req.linkedElectiveAdventures) &&
      !isEmpty(req.linkedElectiveAdventures)
    ) {
      mergedReq.linkedElectiveAdventures = filter(
        uniqBy(
          [
            ...(youthReq.linkedElectiveAdventures || []),
            ...req.linkedElectiveAdventures,
          ],
          'id',
        ),
        // // TODO: SBL-5228 Remove this on 9/1/2024
        ({ id }) => {
          if (rankIdsVersion2024.includes(rankVersionid)) {
            return !adventureIdsToExpireIn2024.includes(id);
          }
          return true;
        },
      );
    }
    if (req.requiresSSElective) {
      mergedReq.mergedSSElectives = uniqBy(
        [...(mergedReq.linkedSSElective || []), ...mergedReq.ssElectives],
        'ssElectiveId',
      );
    }
    return mergedReq;
  });

  return {
    id: advancementId,
    ...rankInformation,
    ...advReqs,
    ...youthAdvReqs,
    requirements,
  };
};

const mapAdvRequirementsDtoToModel = (dto, advancementType) => {
  const advStatus = advancementStatus.getStatus(dto);
  const requirements = getRequirementsTree(
    addMeritBadgeRequirementsStatus(dto.requirements),
  );
  return {
    ...dto,
    advancementType,
    percentCompleted: Math.floor(Number(dto.percentCompleted) * 100),
    eagleRequired: isValueTruthy(dto.eagleRequired),
    status: advStatus,
    date: advancementStatusDate.getCompleted(dto),
    imgUrl: dto.imageUrl100,
    description: `${getAdvancementTypeTranslation(advancementType)}: ${
      dto.name
    }`,
    requirements,
  };
};

const editAdvRequirements$ = ({
  userId,
  advancementType,
  advancementId,
  requirements,
  organizationGuid = null,
}) => {
  const endpoint = `${ADVANCEMENTS_URI}/youth/${advancementType}/${advancementId}/requirements`;
  const label = `${ADVANCEMENTS_URI}/youth/${advancementType}/{advancementId}/requirements`;
  const body = [
    {
      userId,
      organizationGuid,
      requirements,
    },
  ];

  return esbApiService.post$(endpoint, body, {
    gtm: {
      label,
    },
    suppressErrorToasts: true,
  });
};

/**
 * @esbEndpoint POST /advancements/youth/ssElectives
 */
const editSSEElectiveRequirements$ = ({ userId, requirements }) => {
  const endpoint = `${ADVANCEMENTS_URI_OLD}/youth/ssElectives`;
  const label = `${ADVANCEMENTS_URI_OLD}/youth/ssElectives`;
  const body = [
    {
      userId,
      ssElectives: requirements,
    },
  ];
  return esbApiService.post$(endpoint, body, {
    gtm: {
      label,
    },
    suppressErrorToasts: true,
  });
};

const extractAdvancementsPayload = response => response || [];

/**
 * @esbEndpoint GET /advancements/v2/:userId/userActivitySummary
 */
const getPersonalActivitiesSummary$ = userId =>
  esbApiService.get$(`/advancements/v2/${userId}/userActivitySummary`, {
    gtm: {
      label: '/advancements/v2/{userId}/userActivitySummary',
    },
  });

const createEagleActivity$ = data =>
  esbApiService.post$(
    '/advancements/v2/activities/add',
    {
      ...data,
    },
    {
      gtm: {
        label: '/advancements/v2/activities/add',
      },
    },
  );

/**
 * @esbEndpoint PUT /advancements/v2/activities/:activityId
 */
const updateEagleActivity$ = (id, data) =>
  esbApiService.put$(`/advancements/v2/activities/${id}`, data, {
    gtm: {
      label: '/advancements/activities/{id}',
    },
  });

/**
 * @esbEndpoint PUT /advancements/activities/:activityId/registeredParticipants
 */
const updateEagleParticipants$ = (activityId, payload) =>
  esbApiService.put$(
    `/advancements/activities/${activityId}/registeredParticipants`,
    payload,
    {
      gtm: {
        label: '/advancements/activities/{activityId}/registeredParticipants',
      },
    },
  );

/**
 * @esbEndpoint GET /advancements/activities/:id
 */
const getEagleActivity$ = id =>
  esbApiService.get$(`/advancements/activities/${id}`, {
    gtm: {
      label: '/advancements/activities/{id}',
    },
  });

/**
 * @esbEndpoint GET /persons/:personGuid/subscriptions
 */
const getUsersubscriptionsRequest$ = personGuid =>
  esbApiService.get$(`/persons/${personGuid}/subscriptions`, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions',
    },
  });

/**
 * @esbEndpoint POST /persons/:personGuid/subscriptions
 */
const createUsersubscriptions$ = (data, personGuid) =>
  esbApiService.post$(`/persons/${personGuid}/subscriptions`, data, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions',
    },
  });

/**
 * @esbEndpoint PUT /persons/:personGuid/subscriptions/:id
 */
const updateUsersubscriptions$ = (data, personGuid) =>
  esbApiService.put$(`/persons/${personGuid}/subscriptions/${data.id}`, data, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions/{id}',
    },
  });

/**
 * @esbEndpoint POST /advancements/:youthUserId/pictureUpload
 */
const getPreSignedUrl$ = (userId, imgType) =>
  esbApiService.post$(
    `/advancements/${userId}/pictureUpload`,
    {
      contentType: imgType,
    },
    {
      gtm: {
        label: '/advancements/{userId}/pictureUpload',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/users/:userId/comments
 */
const getAdvComments$ = (userId, body) =>
  esbApiService.post$(`/advancements/v2/users/${userId}/comments`, body, {
    gtm: {
      label: '/advancements/v2/users/${userId}/comments',
    },
  });

/**
 * @esbEndpoint POST /advancements/v2/users/:userId/comments/add
 */
const addAdvComments$ = (userId, body) =>
  esbApiService.post$(`/advancements/v2/users/${userId}/comments/add`, body, {
    gtm: {
      label: '/advancements/v2/users/${userId}/comments/add',
    },
  });

/**
 * @esbEndpoint DELETE /advancements/v2/users/:userId/comments/:commentId
 */
const deleteAdvComments$ = (userId, commentId) =>
  esbApiService.delete$(
    `/advancements/v2/users/${userId}/comments/${commentId}`,
    {
      gtm: {
        label: '/advancements/v2/users/${userId}/comments/{commentId}',
      },
    },
  );

/**
 * @esbEndpoint PUT /advancements/v2/users/:userId/comments/:commentId
 */
const updateAdvComments$ = (userId, commentId, body) =>
  esbApiService.put$(
    `/advancements/v2/users/${userId}/comments/${commentId}`,
    body,
    {
      gtm: {
        label: '/advancements/v2/users/${userId}/comments/{commentId}',
      },
    },
  );
/**
 * @esbEndpoint POST /advancements/v2/users/:userId/comments/addFile
 */

const addAdvCommentsFile$ = (userId, body) =>
  esbApiService.post$(
    `/advancements/v2/users/${userId}/comments/addFile`,
    body,
    {
      gtm: {
        label: '/advancements/v2/users/${userId}/comments/addFile',
      },
    },
  );

/**
 * @esbEndpoint DELETE /advancements/v2/users/:userId/comments/:commentId/photos/:photoId
 */
const deleteAdvCommentsPhoto$ = (userId, commentId, photoId) =>
  esbApiService.delete$(
    `/advancements/v2/users/${userId}/comments/${commentId}/photos/${photoId}`,
    {
      gtm: {
        label:
          '/advancements/v2/users/${userId}/comments/${commentId}/photos/${photoId}',
      },
    },
  );

const commentImageUploadS3$ = (photo, preSignedUrl) =>
  Observable.fromPromise(
    fetch(preSignedUrl, {
      body: photo,
      headers: { 'Content-Type': photo.type },
      method: 'PUT',
    }),
  );

/**
 * @esbEndpoint GET /advancements/v2/youth/:userId/ranks
 */
const getYouthRanks$ = (userId, unitTypeId) =>
  esbApiService
    .get$(`${ADVANCEMENTS_URI}/youth/${userId}/ranks`, {
      gtm: {
        label: `${ADVANCEMENTS_URI}/youth/{userId}/ranks`,
      },
    })
    .pipe(
      map(({ program }) => {
        const currentProgram =
          program.find(({ programId }) => programId == unitTypeId) || {};
        const currentRanks = (currentProgram.ranks || []).map(rank => ({
          ...rank,
          programId: unitTypeId,
        }));
        const additionalScoutsBSARanks = getAddtionalScoutsBSARanks(
          program,
          unitTypeId,
        );
        const ranks = dedupeAdvancements(
          [...currentRanks, ...additionalScoutsBSARanks].map(rank => {
            const advancementType = advancementTypes.RANKS;
            return {
              ...rank,
              percentCompleted: Math.floor(Number(rank.percentCompleted) * 100),
              advancementType,
              userId,
              uid: getAdvancementUid(rank, advancementType),
              status: advancementStatus.getStatus({ ...rank, advancementType }),
              date: advancementStatusDate.getCompleted(rank),
            };
          }),
        );

        const sortedRanks = stable(
          ranks,
          (a, b) => Number(a.level) > Number(b.level),
        );
        return sortedRanks;
      }),
    );

/**
 * @esbEndpoint GET /advancements/v2/youth/:userId/adventures
 */
const getYouthAdventures$ = userId =>
  esbApiService
    .get$(`${ADVANCEMENTS_URI}/youth/${userId}/adventures`, {
      gtm: {
        label: `${ADVANCEMENTS_URI}/youth/{userId}/adventures`,
      },
    })
    .pipe(
      map(response => {
        const userAdventures = extractAdvancementsPayload(response);

        return dedupeAdvancements(
          userAdventures.map(adventure => ({
            // API v2 mappings 🤷🏻‍♂️
            name: adventure.adventureName,
            id: adventure.adventureId,
            ...adventure,
            percentCompleted: Math.floor(
              Number(adventure.percentCompleted) * 100,
            ),
            advancementType: advancementTypes.ADVENTURES,
            uid: getAdvancementUid(adventure, advancementTypes.ADVENTURES),
            status: advancementStatus.getStatus(adventure),
            date: advancementStatusDate.getCompleted(adventure),
          })),
        );
      }),
    );

const getYouthAwards$ = userId =>
  esbApiService
    .get$(`${ADVANCEMENTS_URI}/youth/${userId}/awards`, {
      gtm: {
        label: `${ADVANCEMENTS_URI}/youth/{userId}/awards`,
      },
    })
    .pipe(
      map(response => {
        const userAwards = extractAdvancementsPayload(response);

        return userAwards.map(award => ({
          ...award,
          userId,
          percentCompleted: Math.floor(Number(award.percentCompleted) * 100),
          advancementType: advancementTypes.AWARDS,
          id: award.awardId,
          userAwardId: Number(award.userAwardId),
          uid: getAdvancementUid(award, advancementTypes.AWARDS),
          status: advancementStatus.getStatus(award),
          date: advancementStatusDate.getCompleted(award),
          programId: award.programId || award.unitTypeId,
          level: venturingCoreAwardsIds.includes(`${award.awardId}`)
            ? award.awardId
            : undefined,
        }));
      }),
    );

/**
 * @esbEndpoint GET /advancements/v2/youth/:userId/meritBadges
 */
const getYouthMeritBadges$ = userId =>
  esbApiService
    .get$(`${ADVANCEMENTS_URI}/youth/${userId}/meritBadges`, {
      gtm: {
        label: `${ADVANCEMENTS_URI}/youth/{userId}/meritBadges`,
      },
    })
    .pipe(
      map(response => {
        const meritBadges = extractAdvancementsPayload(response);

        return meritBadges.map(meritBadge => ({
          ...meritBadge,
          userId,
          percentCompleted: Math.floor(
            Number(meritBadge.percentCompleted) * 100,
          ),
          isEagleScoutRequired: meritBadge.eagleRequired == 'True',
          advancementType: advancementTypes.MERIT_BADGES,
          uid: getAdvancementUid(meritBadge, advancementTypes.MERIT_BADGES),
          status: advancementStatus.getStatus(meritBadge),
          date: advancementStatusDate.getCompleted(meritBadge),
        }));
      }),
    );

/**
 * @esbEndpoint GET /advancements/youth/:userId/ssElectives
 */
const getYouthSSElectives$ = userId =>
  esbApiService
    .get$(`${ADVANCEMENTS_URI_OLD}/youth/${userId}/ssElectives`, {
      gtm: {
        label: `${ADVANCEMENTS_URI_OLD}/youth/{userId}/ssElectives`,
      },
    })
    .pipe(
      map(response => {
        const ssElectives = extractAdvancementsPayload(response);
        return ssElectives.map(ssElective => ({
          ...ssElective,
          id: ssElective.ssElectiveId,
          name: ssElective.ssElectiveName,
          level: ssElective.ssElectiveLevel,
          userId,
          advancementType: advancementTypes.SSELECTIVES,
          uid: getAdvancementUid(ssElective, advancementTypes.SSELECTIVES),
          status: advancementStatus.getStatus(ssElective),
          date: advancementStatusDate.getCompleted(ssElective),
        }));
      }),
    );

const getYouthAdvancements$ = ({
  eligibleAdvancementTypes,
  userId,
  unitTypeId,
}) => {
  const allEligibleAdvancementTypes = getEligibleAdvancementTypes(
    eligibleAdvancementTypes,
    unitTypeId,
  );
  const getAdvancements$ = fallBackFactory({
    eligibleAdvancementTypes: allEligibleAdvancementTypes,
    userId,
    unitTypeId,
  });

  return forkJoin(
    getAdvancements$(getYouthAdventures$, 'adventures'),
    getAdvancements$(getYouthAwards$, 'awards'),
    getAdvancements$(getYouthMeritBadges$, 'meritBadges'),
    getAdvancements$(getYouthRanks$, 'ranks'),
    getAdvancements$(getYouthSSElectives$, 'ssElectives'),
  ).pipe(
    map(([adventures, awards, meritBadges, ranks, ssElectives]) => ({
      userId,
      adventures,
      awards,
      meritBadges,
      ranks,
      ssElectives,
    })),
  );
};

const getYouthData$ = ({
  userId,
  eligibleAdvancementTypes,
  unitTypeId,
  isParentOrYouthMember,
}) =>
  forkJoin(
    getYouthProfile$(userId),
    getYouthAdvancements$({
      eligibleAdvancementTypes,
      userId,
      unitTypeId,
    }),
  ).pipe(
    map(([youthProfile, youthAdvancements]) => {
      const { profile: { memberId } = {} } = youthProfile;
      // Report to sentry to debug if memberId is missing or some youths
      if (isParentOrYouthMember && !memberId) {
        sentryReport.info({
          message: `Missing member id ${userId}`,
          responseData: youthProfile,
        });
      }
      return {
        ...youthProfile,
        ...youthAdvancements,
        isParentOrYouthMember,
      };
    }),
  );

const getYouthAdvRequirements$ = ({
  userId,
  advancementType,
  advancementId,
  userAwardId,
  omitYouthRequirement,
}) =>
  // first fetch requirement for current youth
  fetchYouthAdvRequirements$({
    userId,
    advancementType,
    advancementId,
    userAwardId,
    omitYouthRequirement,
  }).pipe(
    switchMap(youthAdvReqs => {
      // then fetch full requirements based on active version for current youth
      const versionId = youthAdvReqs.versionId;
      return forkJoin([
        fetchAdvRequirements$({
          advancementType,
          advancementId,
          versionId,
        }),
        of(youthAdvReqs),
      ]);
    }),
    map(([advReqs, youthAdvReqs]) => {
      let youthReqsList = youthAdvReqs.requirements || [];
      const advancementInfo = {};
      if (advancementType === advancementTypes.SSELECTIVES) {
        youthReqsList = addSSElectiveRequirementsStatus(youthReqsList);
      }
      if (advancementType === advancementTypes.RANKS) {
        advancementInfo.name = advReqs.rankInformation.name;
        advancementInfo.id = advReqs.rankInformation.rankId;
      }
      const transformedYouthReqs = {
        ...advancementInfo,
        ...youthAdvReqs,
        requirements: youthReqsList.map(youthReq => ({
          id:
            youthReq.id ||
            youthReq.requirementId ||
            youthReq.ssElectiveRequirementId,
          name:
            youthReq.name ||
            youthReq.requirementName ||
            youthReq.ssElectiveRequirementName,
          ...youthReq,
        })),
      };
      return mergeAdvRequirements(advReqs, transformedYouthReqs, advancementId);
    }),
    map(reqs => mapAdvRequirementsDtoToModel(reqs, advancementType)),
  );
export default {
  getYouthData$,
  getYouthAdvancements$,
  getYouthAdvRequirements$,
  editAdvRequirements$,
  editSSEElectiveRequirements$,
  getPersonalActivitiesSummary$,
  saveProfilePicture$,
  getYouthProfile$,
  getUserYPTrainingRequest$,
  saveProfile$,
  updateSBProfile$,
  deleteProfileItem$,
  createEagleActivity$,
  updateEagleActivity$,
  updateEagleParticipants$,
  getEagleActivity$,
  getUsersubscriptionsRequest$,
  createUsersubscriptions$,
  updateUsersubscriptions$,
  getPreSignedUrl$,
  fetchAdvancementVersions$,
  updateActiveVersion$,
  getAdvComments$,
  addAdvComments$,
  deleteAdvComments$,
  updateAdvComments$,
  deleteAdvCommentsPhoto$,
  addAdvCommentsFile$,
  commentImageUploadS3$,
  startYouthMeritBadge$,
};
