import React, { Fragment, useCallback, useState } from 'react';

import cn from 'classnames';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  useSearchDenChiefMutation,
  useSendDenChiefEmailMutation,
} from '@modules/advancement/api';
import { toastService } from '@modules/toasts';
import { isMobileSel } from '@screen';
import {
  Button,
  Modal,
  ProgramId,
  Spin,
  intl,
  organizationGuidSel,
} from '@shared';

import { hideSearchDenChiefModal } from '../../duck/actions';
import styles from './AddDenChiefModal.less';
import DenChiefSearch from './components/AddDenChiefForm';
import Confirmation from './components/Confirmation';
import NotFound from './components/NotFound';
import SearchResults from './components/SearchResults';

export const AddDenChiefModal: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileSel);
  const currentOrganizationGuid = useSelector(organizationGuidSel);

  const [searchDenChief, searchDenChiefResult] = useSearchDenChiefMutation();

  const foundDenChief = searchDenChiefResult.data?.denChief.find(
    ({ programId, organizationGuid }) =>
      programId !== ProgramId.CUB_SCOUT &&
      organizationGuid !== currentOrganizationGuid,
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [sendEmail, { isLoading: sendEmailInProgress }] =
    useSendDenChiefEmailMutation();

  const handleCloseModal = useCallback(() => {
    dispatch(hideSearchDenChiefModal());
    setShowConfirmation(false);
  }, [dispatch]);

  const handleAdd = () => {
    setShowConfirmation(true);
  };

  const handleConfirmAndEmail = async () => {
    if (!foundDenChief) return null;

    const { lastName, memberId, organizationGuid } = foundDenChief;

    try {
      await sendEmail({
        lastName,
        memberId: memberId.toString(),
        sourceOrganizationGuid: organizationGuid,
        targetOrganizationGuid: currentOrganizationGuid,
      }).unwrap();

      toastService.success(
        <FormattedMessage id="packRoster.addIndividual.addDenChiefModal.successMessage" />,
      );

      setShowConfirmation(false);
      dispatch(hideSearchDenChiefModal());
    } catch (error) {
      const errorMessage = get(error, ['data', 'errorDesc']);
      if (errorMessage) {
        toastService.error(errorMessage);
      }
    }
  };

  return (
    <Modal
      className={styles.roundedModal}
      mask={!isMobile}
      headerColor="scouting-warm-gray"
      visible
      title={intl.formatMessage({ id: 'packRoster.AddDenChiefModal.title' })}
      onCancel={handleCloseModal}
    >
      <Spin
        spinning={searchDenChiefResult.isLoading || sendEmailInProgress}
        size="large"
        delay={200}
      >
        <div className={cn({ [styles.modalMobileContainer]: isMobile })}>
          {showConfirmation ? (
            <Confirmation onConfirm={handleConfirmAndEmail} />
          ) : (
            <Fragment>
              <DenChiefSearch onSearch={searchDenChief} />

              {foundDenChief ? (
                <Fragment>
                  {searchDenChiefResult.isSuccess && (
                    <SearchResults
                      onAdd={handleAdd}
                      foundDenChief={foundDenChief}
                    />
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {(searchDenChiefResult.isSuccess ||
                    searchDenChiefResult.isError) && (
                    <NotFound
                      hasNotEligibleResults={
                        !!searchDenChiefResult.data?.denChief.length
                      }
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          <Button
            color="scouting-red"
            fitText
            onClick={handleCloseModal}
            shadow
            shape="round"
            size="large"
            uppercase={true}
          >
            <FormattedMessage
              id={'packRoster.addIndividual.addDenChiefModal.close'}
            />
          </Button>
        </div>
      </Spin>
    </Modal>
  );
};
