import { initApiEnv } from '@config/config';
import { moduleName as configModuleName } from '@config/constants';
import { moduleName as localizationModuleName } from '@localization/constants';
import localizationServices from '@localization/duck/services';
import { retrieveCurrentUnit } from '@context/duck/services';
import { contextModuleName, screenModuleName } from '@shared/constants';
import { getScreenMeta } from '@screen/duck/services';

const currentUnit = retrieveCurrentUnit();

export default {
  [configModuleName]: {
    apiEnv: initApiEnv(),
  },
  [localizationModuleName]: {
    locale: localizationServices.retrieveLocale() || undefined,
  },
  [screenModuleName]: getScreenMeta(),
  [contextModuleName]: {
    organizationGuid: currentUnit,
  },
};
