import React from 'react';

import cn from 'classnames';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';

import Spin from '../Spin';
import TableCard from '../TableCard';
import StickyTitle from './StickyTitle';
import styles from './TableMobile.less';

const infiniteScrollDefault = {
  initial: 0,
  step: 20,
  windowSize: 20,
  total: null,
};

class TableMobile extends React.PureComponent {
  state = {
    windowSize:
      this.props.infiniteScroll.windowSize || infiniteScrollDefault.windowSize,
  };

  getInfiniteScroll = () => ({
    ...infiniteScrollDefault,
    windowSize: this.state.windowSize,
    ...this.props.infiniteScroll,
  });

  handleLoadMoreItems = throttle(
    () => {
      if (!this.props.loading) {
        const infiniteScroll = this.getInfiniteScroll();
        const windowSize = infiniteScroll.windowSize + infiniteScroll.step;
        this.setState({ windowSize });
        if (infiniteScroll.onChange) {
          infiniteScroll.onChange(windowSize);
        }
      }
    },
    300,
    {
      leading: true,
      trailing: false,
    },
  );

  renderCard({ item, className, disabled, onClick }) {
    const { rowId, rows } = this.props;

    return (
      <TableCard
        key={item[rowId]}
        className={className}
        item={item}
        rows={rows}
        disabled={disabled}
        onClick={onClick}
      />
    );
  }

  renderItemsNodes = items => {
    const {
      selectedKeys,
      itemsClassName,
      rowId,
      onItemClick,
      getItemClassName,
      getItemDisabled,
    } = this.props;

    const { initial, windowSize } = this.getInfiniteScroll();

    return items.slice(initial, windowSize).map(item => {
      const className = cn(
        itemsClassName,
        getItemClassName ? getItemClassName(item) : '',
        {
          [styles.selected]: selectedKeys.includes(item[rowId]),
        },
      );
      const onClick = onItemClick ? () => onItemClick(item) : undefined;

      if (item.type === 'custom') {
        return (
          <div className={styles.customRow} key={item.key}>
            {item.row}
          </div>
        );
      }

      return this.renderCard({
        item,
        className,
        onClick,
        disabled: getItemDisabled ? getItemDisabled(item) : false,
      });
    });
  };

  render() {
    const { items, loading, className, title } = this.props;
    const itemNodes = this.renderItemsNodes(items);
    const { total, windowSize } = this.getInfiniteScroll();
    const itemCount = items.length;
    const hasMoreItems =
      total != null ? total > itemCount : windowSize < itemCount;

    return (
      <Spin spinning={loading} delay={100} size="large">
        {title}
        <InfiniteScroll
          useWindow
          hasMore={hasMoreItems}
          loadMore={this.handleLoadMoreItems}
        >
          <div className={className}>{itemNodes}</div>
        </InfiniteScroll>
        {items.length === 0 && (
          <div className={styles.noData}>
            <FormattedMessage id="shared.Table.noData" />
          </div>
        )}
      </Spin>
    );
  }
}

TableMobile.StickyTitle = StickyTitle;

TableMobile.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  selectedKeys: PropTypes.array,
  infiniteScroll: PropTypes.shape({
    initial: PropTypes.number,
    step: PropTypes.number,
    windowSize: PropTypes.number,
    total: PropTypes.number,
    onChange: PropTypes.func,
  }),
  rowId: PropTypes.string.isRequired,
  title: PropTypes.node,
  className: PropTypes.string,
  itemsClassName: PropTypes.string,
  rows: PropTypes.func.isRequired,
  getItemClassName: PropTypes.func,
  getItemDisabled: PropTypes.func,
  onItemClick: PropTypes.func,
};

TableMobile.defaultProps = {
  selectedKeys: [],
  infiniteScroll: {},
};

export default TableMobile;
