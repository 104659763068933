import React from 'react';

import { useSelector } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { ProgramLogo } from '@shared';

import { childOrganizationSel } from '../../duck';

const CurrentProgramLogo = props => {
  const program = useSelector(programSel);
  const childOrganization = useSelector(childOrganizationSel);
  return (
    <ProgramLogo
      program={program || (childOrganization && childOrganization.unitTypeId)}
      {...props}
    />
  );
};

export default CurrentProgramLogo;
