import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import { combineReducers } from 'redux';

import { moduleName } from './constants';
import { API_ENV, setApiEnvConfig } from './config';

//
// actions
//
export const SET_API_ENV = `${moduleName}/SET_API_ENV`;
export const setApiEnv = env => dispatch => {
  setApiEnvConfig(env);
  dispatch({ type: SET_API_ENV, payload: env });
};

//
// reducers
//
const apiEnv = (state = API_ENV, { type, payload }) => {
  switch (type) {
    case SET_API_ENV: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  apiEnv,
});

export default reducers;

//
// selectors
//
const moduleSel = state => state[moduleName];
export const apiEnvSel = state => moduleSel(state).apiEnv;
