import { combineReducers } from 'redux';

import { ROUTE_REPORTS, locationHash } from '@modules/shared';
import { resetOnLogoutReducer } from '@utils';

import {
  moduleName as basicReportsModuleName,
  reducers as basicReportsReducers,
} from '../basicReports';
import { reportsTabs } from '../constants';
import {
  moduleName as historyReportsModuleName,
  reducers as historyReportsReducers,
} from '../historyReports';
import {
  CLOSE_REPORT_PARAMS_MODAL,
  OPEN_REPORT_PARAMS_MODAL,
  SET_ACTIVE_TAB,
} from './actions';

const validTabs = [
  reportsTabs.HISTORY,
  reportsTabs.COUNCIL,
  reportsTabs.BASIC,
].filter(Boolean);
const defaultTab = locationHash.getValid(validTabs, reportsTabs.HISTORY);

const activeTab = (state = defaultTab, { type, payload = {} }) => {
  switch (type) {
    case SET_ACTIVE_TAB: {
      return payload;
    }
    case ROUTE_REPORTS: {
      const { hash } = payload;
      return hash || state;
    }
    default: {
      return state;
    }
  }
};

const reportParamsVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_REPORT_PARAMS_MODAL: {
      return true;
    }
    case CLOSE_REPORT_PARAMS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reportParamsDataInitialState = {
  organizationGuid: null,
  users: [],
  reportType: null,
};

const reportParamsData = (
  state = reportParamsDataInitialState,
  { type, payload },
) => {
  switch (type) {
    case OPEN_REPORT_PARAMS_MODAL: {
      return payload;
    }
    case CLOSE_REPORT_PARAMS_MODAL: {
      return reportParamsDataInitialState;
    }
    default: {
      return state;
    }
  }
};

const reducers = {
  reportParamsVisible,
  reportParamsData,
  activeTab,
  [historyReportsModuleName]: historyReportsReducers,
  [basicReportsModuleName]: basicReportsReducers,
};

const reducer = combineReducers(reducers);

export default resetOnLogoutReducer(reducer);
