import React from 'react';
import { FormattedMessage } from 'react-intl';

import T from '../T';
import UnderConstructionSvg from './assets/UnderConstruction.svg';
import styles from './UnderConstruction.less';

const UnderConstruction = () => (
  <div className={styles.container}>
    <UnderConstructionSvg className={styles.image} />
    <T size="1" bold colored serif className={styles.title}>
      <FormattedMessage id="shared.UnderConstruction.title" />
    </T>
  </div>
);

export default UnderConstruction;
