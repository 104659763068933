import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeUploadDocumentModal,
  isUploadDocumentModalVisible,
} from '../../../duck';
import AddApplicationForm from './UploadDocumentForm';
import styles from './UploadDocumentModal.less';

const UploadDocumentModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isUploadDocumentModalVisible);

  const handleCloseUploadDocumentModal = () =>
    dispatch(closeUploadDocumentModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.uploadDocumentModal.title',
      })}
      onCancel={handleCloseUploadDocumentModal}
      onBack={handleCloseUploadDocumentModal}
    >
      <div>
        <span>
          To ensure you are uploading the correct documents, please review the
          following:
        </span>
        <ul>
          <li>
            <a href="https://filestore.scouting.org/filestore/pdf/524-406.pdf">
              Youth application
            </a>
          </li>
          <li>
            <a href="https://filestore.scouting.org/filestore/pdf/524-501.pdf">
              Adult application
            </a>
          </li>
          <li>
            <a href="https://www.scouting.org/training/youth-protection/">
              Youth Protection
            </a>
          </li>
        </ul>
      </div>
      <AddApplicationForm onCancel={handleCloseUploadDocumentModal} />
    </Modal>
  );
};

export default UploadDocumentModal;
