import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Form, Input, Row, S } from '@shared/components';
import { intl } from '@shared/localization';
import { validateForm } from '@utils';

import {
  achVerificationRequest,
  isLoadingAchVerificationSel,
} from '../../../../duck';
import styles from './AchVerificationForm.less';

const FormItem = Form.Item;
const keys = ['md_amount1', 'md_amount2'];
const verticalItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AchVerificationForm = ({ form }) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const isLoadingAchVerification = useSelector(isLoadingAchVerificationSel);

  const validate = useCallback(() => validateForm(form), [form]);

  const handleSubmitAchVerification = useCallback(async () => {
    const validData = await validate();
    if (validData) {
      const payload = {
        mdAmount1: Math.round(parseFloat(validData.md_amount1) * 100),
        mdAmount2: Math.round(parseFloat(validData.md_amount2) * 100),
      };

      dispatch(achVerificationRequest(payload));
    }
  }, [dispatch, validate]);

  const formItemsNodes = keys.map(key => (
    <FormItem
      key={key}
      label={intl.formatMessage({
        id: `achVerification.form.field.${key}`,
      })}
      className={styles.formItem}
      {...verticalItemLayout}
    >
      {getFieldDecorator(key, {
        rules: [
          {
            type: 'number',
            transform: value => parseFloat(value),
            required: true,
            max: 0.99,
            min: 0.01,
            message: intl.formatMessage({
              id: 'achVerification.form.validation.inRange',
            }),
          },
          {
            pattern: /^\d*(\.\d{0,2})?$/,
            message: intl.formatMessage({
              id: 'achVerification.form.validation.maxDecimals',
            }),
          },
        ],
      })(<Input type="number" min="0.01" max="0.99" step="0.01" />)}
    </FormItem>
  ));

  return (
    <Form>
      <Row>
        <S size="4" className="mb-2">
          <FormattedMessage id="achVerification.form.subtitle" />
        </S>
      </Row>

      {formItemsNodes}

      <Row type="flex" justify="end">
        <Col xs={{ span: 12, offset: 12 }} lg={{ span: 8, offset: 8 }}>
          <Button
            loading={isLoadingAchVerification}
            type="primary"
            shadow
            shape="round"
            block
            onClick={handleSubmitAchVerification}
          >
            <FormattedMessage id="achVerification.form.submit" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

AchVerificationForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create()(AchVerificationForm);
