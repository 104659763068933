import React from 'react';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import { DatePicker } from 'bsa-ui';

import BasePicker, { basePropTypes } from './BasePicker';

class MonthPicker extends React.PureComponent {
  render() {
    return <BasePicker Picker={DatePicker.MonthPicker} {...this.props} />;
  }
}

MonthPicker.propTypes = basePropTypes;

MonthPicker.defaultProps = {
  format: 'MMM YYYY',
};

export default MonthPicker;
