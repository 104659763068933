import React from 'react';

import Switch from '../Switch';

const highlightClassName = 'dev-highlight';

class DevMockHighlightToggle extends React.PureComponent {
  toggleHighlight = () => document.body.classList.toggle(highlightClassName);

  render() {
    return (
      <Switch
        size="small"
        title="Toggle highlight of mocked data"
        defaultChecked={false}
        onChange={this.toggleHighlight}
      />
    );
  }
}

export default DevMockHighlightToggle;
