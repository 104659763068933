import { combineReducers } from 'redux';

import { ROUTE_PURCHASE_ORDER_DETAILS } from '@modules/shared';

import { cartStatusTypes } from '../constants';
import {
  ADD_ITEMS_TO_CART_ERROR,
  ADD_ITEMS_TO_CART_REQUEST,
  ADD_ITEMS_TO_CART_RESPONSE,
  CREATE_PURCHASE_ORDER_RESPONSE,
  FETCH_ALL_PURCHASE_ORDERS_RESPONSE,
  FETCH_PURCHASE_ORDER_DETAILS_RESPONSE,
  HIDE_ADD_ITEMS_MODAL,
  HIDE_ADD_TO_CART_MODAL,
  HIDE_CLOSED_PO_MODAL,
  SET_CART_TOKEN,
  SET_PURCHASE_ORDER_LOADING,
  SET_UPDATE_PURCHASE_ORDER_LOADING,
  SHOW_ADD_ITEMS_MODAL,
  SHOW_ADD_TO_CART_MODAL,
  SHOW_CLOSED_PO_MODAL,
} from './actions';

const closedPOModalVisible = (state = false, { type }) => {
  switch (type) {
    case SHOW_CLOSED_PO_MODAL: {
      return true;
    }
    case HIDE_CLOSED_PO_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const addToCartModalVisible = (state = false, { type }) => {
  switch (type) {
    case SHOW_ADD_TO_CART_MODAL: {
      return true;
    }
    case HIDE_ADD_TO_CART_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const addItemsModalVisible = (state = false, { type }) => {
  switch (type) {
    case SHOW_ADD_ITEMS_MODAL: {
      return true;
    }
    case HIDE_ADD_ITEMS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const cartStatus = (state = cartStatusTypes.REVIEWING, { type }) => {
  switch (type) {
    case ADD_ITEMS_TO_CART_REQUEST: {
      return cartStatusTypes.REVIEWING;
    }
    case ADD_ITEMS_TO_CART_RESPONSE: {
      return cartStatusTypes.AVAILABLE;
    }
    case ADD_ITEMS_TO_CART_ERROR: {
      return cartStatusTypes.ERROR;
    }
    default: {
      return state;
    }
  }
};

const cartToken = (state = null, { type, payload }) => {
  switch (type) {
    case SET_CART_TOKEN: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const cartProblems = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_ITEMS_TO_CART_ERROR: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const allPurchaseOrders = (state = [], { type, payload }) => {
  switch (type) {
    case FETCH_ALL_PURCHASE_ORDERS_RESPONSE: {
      // return [{
      //   "status": "Closed",  // this is only required field
      //   "notes": "test notes",
      //   "showSkus": true,
      //   "showPrices": true,
      //   "showNotes": true,
      //   "groupBy": "Item",
      //   "showDenPatrol": false
      // }];
      return payload;
    }
    default: {
      return state;
      // return [
      //   {
      //     "poid": 45321,
      //     "dateCreated": "2018-03-07",
      //     "userId": 122786,
      //     "unitId": 234389,
      //     "organizationGuid": "1202D9F5-8338-4C7D-B3C4-88F3169F431D",
      //     "status": "closed",
      //     "notes": "test notes",
      //     "showSkus": true,
      //     "showPrices": true,
      //     "showNotes": true,
      //     "groupBy": "item",
      //     "showDenPatrol": false
      //   }
      // ];
    }
  }
};

const currentPurchaseOrderDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_PURCHASE_ORDER_DETAILS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const currentPurchaseOrderId = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_PURCHASE_ORDER_RESPONSE: {
      return payload.id;
    }
    case ROUTE_PURCHASE_ORDER_DETAILS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const updatePurchaseOrderLoading = (state = false, { type, payload }) => {
  switch (type) {
    case SET_UPDATE_PURCHASE_ORDER_LOADING: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const createPurchaseOrderLoading = (state = false, { type, payload }) => {
  switch (type) {
    case SET_PURCHASE_ORDER_LOADING: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  closedPOModalVisible,
  addToCartModalVisible,
  addItemsModalVisible,
  cartStatus,
  cartToken,
  allPurchaseOrders,
  currentPurchaseOrderDetails,
  currentPurchaseOrderId,
  cartProblems,
  updatePurchaseOrderLoading,
  createPurchaseOrderLoading,
});

export default reducers;
