import uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

import { SBL_5102_MY_SCOUT } from '@config/config';
import { payloadSel } from '@location';
import {
  ParticipantPositionIdsList,
  hasPermissionSel,
  isValueTruthy,
  permissions,
  selectedOrganizationSel,
} from '@shared';
import { userDataSel as _userDataSel, userIdSel } from '@user';
import { personNameBuilder } from '@utils';

import {
  rosterAdultsSel,
  rosterLoadingSel,
  rosterYouthSel,
} from '../../advancement';
import { childrenSel } from '../../parentGuardian';
import {
  memberDetailsSel,
  youthInfoSel,
} from '../../youthProfile/duck/selectors';
import { moduleName } from '../constants';

const moduleSel = state => state[moduleName];

export const hobbiesSel = createSelector(_userDataSel, userData => {
  const { extendedProfile } = userData;
  return extendedProfile.find(attr => attr.classification === 'MyHobbies');
});

export const userBioSel = createSelector(_userDataSel, userData => {
  const { extendedProfile } = userData;
  return extendedProfile.find(attr => attr.classification === 'MyBiography');
});

export const activityTypeIdSel = state => payloadSel(state).activityType;

export const parentsGuardiansSel = createSelector(
  memberDetailsSel,
  memberDetails => {
    const { parentsGuardiansInfo } = memberDetails;
    if (parentsGuardiansInfo) {
      return parentsGuardiansInfo.map(adult => ({
        ...adult,
        personShortFullName: personNameBuilder.short(adult),
      }));
    }
    return [];
  },
);

export const youthProfileInfoSel = createSelector(
  youthInfoSel,
  memberDetailsSel,
  (youthInfo, fetchedInfo) => ({ ...youthInfo, ...fetchedInfo }),
);

export const userDataSel = createSelector(
  _userDataSel,
  hobbiesSel,
  memberDetailsSel,
  selectedOrganizationSel,
  (userData, hobbies, details, selectedOrganization) => {
    const {
      profile = {},
      addresses = [],
      phones = [],
      emails = [],
      ypTraining = {},
      subscriptions = [],
      extendedProfile = [],
      sbExtendedProfile = {},
      education = [],
      sbProfile,
    } = userData;
    const address = addresses.find(address => address.isPrimary);
    const phone = phones.find(phone => phone.isPrimary);
    const email = emails.find(email => email.isPrimary);

    let isAdult = isValueTruthy(profile.isAdult);

    if (isAdult && sbProfile?.organizationPositions.length) {
      selectedOrganization.organizationGuid;
      const foundOrg = sbProfile.organizationPositions.find(
        org => org.organizationGuid === selectedOrganization.organizationGuid,
      );
      if (foundOrg && foundOrg.positions?.length) {
        const hasParticipantPosition = foundOrg.positions.some(pos =>
          ParticipantPositionIdsList.includes(Number(pos.id)),
        );
        isAdult = !hasParticipantPosition;
      }
    }

    return {
      isAdult: isAdult,
      boysLife: profile.boysLife,
      cbcCompleted: profile.cbcCompleted,
      isCbcAuthorized: profile.isCbcAuthorized,
      addresses,
      emails,
      phones,
      firstName: profile.firstName,
      middleName: profile.middleName,
      lastName: profile.lastName,
      shortName: profile.shortName,
      memberId: profile.memberId,
      imagePath: profile.imagePath,
      address,
      phone,
      email,
      pictureUrl: profile.pictureUrl,
      hobbies,
      personGuid: profile.personGuid,
      ypTraining,
      subscriptions,
      nickName: profile.nickName,
      dateOfBirth: profile.dateOfBirth,
      gender: profile.gender,
      title: profile.title,
      nameSuffix: profile.nameSuffix,
      sbExtendedProfile,
      dateJoinedBoyScouts: profile.dateJoinedBoyScouts,
      dateJoinedVenturing: profile.dateJoinedVenturing,
      dateJoinedSeaScouts: profile.dateJoinedSeaScouts,
      extendedProfile,
      education,
      currentProgramsAndRanks: details.currentProgramsAndRanks,
      parentsGuardiansInfo: details.parentsGuardiansInfo,
      memberDetails: details, // temporary
    };
  },
);

export const profilePictureSel = createSelector(
  userDataSel,
  userData => userData.imagePath,
);

export const profileEditModeSel = state => moduleSel(state).profileEditMode;

export const profileSaveLoadingSel = state =>
  moduleSel(state).profileSaveLoading;

export const isSavingProfileSel = state => moduleSel(state).isSavingProfile;

export { rosterLoadingSel, rosterAdultsSel };
export const rosterYouthCountSel = state => rosterYouthSel(state).length;

export const rosterAdultsCountSel = state => rosterAdultsSel(state).length;

export const unitEditModeSel = state => moduleSel(state).unitEditMode;

export const unitSaveLoadingSel = state => moduleSel(state).unitSaveLoading;

export const unitPreviewSel = state => moduleSel(state).unitPreview;

export const unitDisplayOptionsSel = state =>
  moduleSel(state).unitDisplayOptions;

export const canEditJoinedDateFieldsSel = state =>
  hasPermissionSel(state, permissions.VIEW_EDIT_JOINED_DATE_PROFILE);

const getRelatedChildrenSelV1 = createSelector(
  childrenSel,
  userIdSel,
  (children, userIdSel) =>
    children.map(child => ({
      ...child,
      relationshipToYouth: child.parentsGuardiansInfo
        .filter(({ userId }) => userId == userIdSel)
        .map(value => value.relationshipToYouth),
    })),
);

const getRelatedChildrenSelV2 = createSelector(
  childrenSel,
  userIdSel,
  children =>
    // Unique list because a child can be on more than 1 unit
    uniqBy(
      children.map(child => ({
        ...child,
        relationshipToYouth: child.relationship,
      })),
      'userId',
    ),
);

export const getRelatedChildrenSel = SBL_5102_MY_SCOUT
  ? getRelatedChildrenSelV2
  : getRelatedChildrenSelV1;
