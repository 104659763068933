import React, { useCallback } from 'react';

import CancelIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

import { Col, PersonAvatar, Row, S, Tag } from '@shared/components';

import styles from './PersonTag.less';

const PersonTag = ({ id, isAdult, name, onCloseAction, closeAction }) => {
  const handleClose = useCallback(() => onCloseAction(id), [onCloseAction, id]);
  return (
    <Tag className={styles.tag} outline={false}>
      <Row type="flex" align="middle">
        <Col className={styles.avatarContainer}>
          <PersonAvatar isAdult={isAdult} className={styles.avatar} />
        </Col>
        <Col className={styles.personName}>
          <S size={'3'} className={styles.text}>
            {name}
          </S>
        </Col>
        {closeAction && (
          <Col className={styles.closeIconContainer}>
            <div onClick={handleClose}>
              <CancelIcon className={styles.closeIcon} />
            </div>
          </Col>
        )}
      </Row>
    </Tag>
  );
};

PersonTag.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAdult: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onCloseAction: PropTypes.func,
  closeAction: PropTypes.bool,
};

PersonTag.defaultProps = {
  onCloseAction: () => {},
  closeAction: true,
};

export default PersonTag;
