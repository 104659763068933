import { createSelector } from 'reselect';

import { applySort } from '@utils';
import { historyReportsModuleSel } from '../../common';
import historyReportsSorters from '../historyReportsSorters';

export const reportsSel = state => historyReportsModuleSel(state).items;
export const loadingSel = state => historyReportsModuleSel(state).loading;
export const searchSel = state => historyReportsModuleSel(state).search;
export const sorterSel = state => historyReportsModuleSel(state).sorter;
export const filteredReportsSel = createSelector(
  reportsSel,
  searchSel,
  sorterSel,
  (reports, search, sorter) => {
    const searchLowerCase = search.toLowerCase();
    const filteredItems = reports.filter(({ ReportName }) =>
      ReportName.toLowerCase().includes(searchLowerCase),
    );
    const sortedItems = applySort({
      sorter,
      items: filteredItems,
      sorters: historyReportsSorters,
    });

    return sortedItems;
  },
);
export const showPriceSel = state => historyReportsModuleSel(state).showPrice;
