import React from 'react';

import TodayIcon from '@material-ui/icons/Today';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import FlexSpacer from '../FlexSpacer';
import S from '../S';
import styles from './BasePicker.less';
import disableDatesShape from './disableDatesShape';

const disablePastDates = pivotDate => selectedDate =>
  pivotDate && selectedDate && moment(selectedDate).isBefore(pivotDate, 'day');

const disableFutureDates = pivotDate => selectedDate =>
  pivotDate && selectedDate && moment(selectedDate).isAfter(pivotDate, 'day');

const disableDatesRange =
  ({ minDate, maxDate }) =>
  selectedDate =>
    disablePastDates(minDate)(selectedDate) ||
    disableFutureDates(maxDate)(selectedDate);

class BasePicker extends React.PureComponent {
  state = {
    open: this.props.open,
  };

  handleClose = () => {
    this.handleOpenChange(false);
  };

  handleOpenChange = open => {
    if (!this.isOpenControlled()) {
      this.setState({ open });
    }
    if (this.props.onOpenChange) {
      this.props.onOpenChange(open);
    }
  };

  isOpen = () => (this.isOpenControlled() ? this.props.open : this.state.open);

  isOpenControlled = () => this.props.open != null;

  getDisabledDate = () => {
    const { disableDates, pivotDate } = this.props;
    let disabledDate;

    if (typeof disableDates === 'object') {
      return disableDatesRange(disableDates);
    } else if (disableDates === 'future') {
      disabledDate = disableFutureDates;
    } else if (disableDates === 'past') {
      disabledDate = disablePastDates;
    }

    return (
      (disabledDate && disabledDate(pivotDate || moment())) ||
      this.props.disabledDate
    );
  };

  renderExtraFooter = () => {
    const { label } = this.props;
    return (
      <div className={styles.header}>
        <S size="3">{label}</S>
        <FlexSpacer />
        <Button ghost color="white" noBorder fitText onClick={this.handleClose}>
          <FormattedMessage id="shared.BasePicker.cancel" />
        </Button>
      </div>
    );
  };

  render() {
    const {
      Picker,
      className,
      fluid,
      rounded,
      materialMode,
      floatingLabel,
      suffixIcon,
      ...rest
    } = this.props;
    const open = this.isOpen();
    const classNames = cn(
      {
        [styles.fluid]: fluid,
        [styles.materialModeInput]:
          (floatingLabel || materialMode) && !!rest.value,
        [styles.rounded]: rounded,
      },
      styles.picker,
      className,
    );
    const dropdownClassNames = cn(
      {
        [styles.hollowCurrentDay]: !this.props.value,
      },
      className,
    );

    const pickerNode = (
      <Picker
        {...rest}
        className={classNames}
        dropdownClassName={dropdownClassNames}
        open={open}
        renderExtraFooter={this.renderExtraFooter}
        disabledDate={this.getDisabledDate()}
        suffixIcon={
          suffixIcon ? suffixIcon : <TodayIcon className={styles.icon} />
        }
        onOpenChange={this.handleOpenChange}
      />
    );

    return floatingLabel || materialMode ? (
      <div
        className={cn(styles.materialModeWrap, {
          [styles.floatingLabelWrap]: floatingLabel,
        })}
      >
        {rest.value && floatingLabel && (
          <label className={styles.floatingLabel}>{rest.placeholder}</label>
        )}
        {pickerNode}
      </div>
    ) : (
      pickerNode
    );
  }
}

export const basePropTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  fluid: PropTypes.bool,
  className: PropTypes.string,
  format: PropTypes.string,
  disabledDate: PropTypes.func,
  disableDates: disableDatesShape,
  pivotDate: PropTypes.object,
  label: PropTypes.node,
  open: PropTypes.bool,
  suffixIcon: PropTypes.any,
  onChange: PropTypes.func,
  onOpenChange: PropTypes.func,
  materialMode: PropTypes.bool,
  floatingLabel: PropTypes.bool,
  rounded: PropTypes.bool,
};

BasePicker.propTypes = {
  ...basePropTypes,
  Picker: PropTypes.func.isRequired,
};

BasePicker.defaultProps = {
  fluid: false,
};

export default BasePicker;
