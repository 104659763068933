import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Button } from '@shared';
import styles from './ProgressButton.less';

const ProgressButton = ({
  children,
  icon,
  disabled,
  title,
  id,
  className,
  color,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
}) => (
  <Button
    color={color}
    noBorder
    fitText
    disabled={disabled}
    id={id}
    className={cn(styles.progressButton, className)}
    title={title}
    onClick={onClick}
    ghost
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
  >
    {icon && <span className={styles.iconWrapper}>{icon}</span>}
    {children}
  </Button>
);

ProgressButton.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onFocus: PropTypes.func,
};

ProgressButton.defaultProps = {
  color: 'scouting-warm-gray',
};

export default ProgressButton;
