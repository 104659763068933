import { createSelector } from 'reselect';

import { makeSearch } from '@utils';
import { moduleName } from '../constants';

const moduleSel = state => state[moduleName];

export const loadingSel = state => moduleSel(state).loading;

export const counseledYouthSel = state => moduleSel(state).counseledYouth;

export const activeTabSel = state => moduleSel(state).activeTab;

export const searchSel = state => moduleSel(state).search;

export const startedItemsSel = state => counseledYouthSel(state).startedItems;

export const pendingItemsSel = state => counseledYouthSel(state).pendingItems;

export const makeFilteredItemsSelector = youthSel =>
  createSelector(searchSel, youthSel, (search, counseledYouth) => {
    const isSearched = makeSearch(search);
    return counseledYouth.filter(
      scout =>
        isSearched(scout.name) ||
        scout.meritBadges.find(badge => isSearched(badge.name)) ||
        isSearched(scout.memberId),
    );
  });

export const filteredCounseledYouthSel =
  makeFilteredItemsSelector(startedItemsSel);
export const filteredPendingItemsSel =
  makeFilteredItemsSelector(pendingItemsSel);
