import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { hideSearchDenChiefModal, showSearchDenChiefModal } from './actions';

const isSearchDenChiefModalVisible = createReducer(false, builder => {
  builder
    .addCase(showSearchDenChiefModal, () => true)
    .addCase(hideSearchDenChiefModal, () => false);
});

export default combineReducers({
  isSearchDenChiefModalVisible,
});
