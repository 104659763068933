import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { offlineSel } from '@offline';
import { Col, Row, S } from '@shared';
import { userIdSel as loggedInUserIdSel } from '@user';

import { attendeeTypeToRsvpValues, attendeesTypes } from '../../constants';
import { confirmInviteRequest } from '../../duck/actions';
import { isLoadingConfirmInviteSel } from '../../duck/selectors';
import { getAttendeeType } from '../../utils';
import RsvpCircleButtons from './RsvpCircleButtons';
import RsvpDropdownButton from './RsvpDropdownButton';
import styles from './RsvpEventButton.less';

const RsvpEventButton = ({
  eventId,
  rsvp,
  large,
  hideText,
  userId,
  label,
  userName,
}) => {
  const loggedInUserId = useSelector(loggedInUserIdSel);
  const offline = useSelector(offlineSel);
  const isLoadingConfirmInvite = useSelector(isLoadingConfirmInviteSel);
  const dispatch = useDispatch();

  const [attendeeType, setAttendeeType] = useState(attendeesTypes.MAYBE);
  const [disabled, setDisabled] = useState(false);
  const [isInvited, setIsInvited] = useState(false);

  useEffect(() => {
    setAttendeeType(getAttendeeType(rsvp));
    setIsInvited(rsvp !== undefined);
  }, [rsvp]);

  useEffect(() => {
    if (disabled && !isLoadingConfirmInvite) {
      setDisabled(false);
    }
  }, [disabled, isLoadingConfirmInvite]);

  const dimensions = useMemo(() => {
    const dimensions = {
      textSpan: 4,
      textCol: 2,
      buttonSpan: 19,
      buttonOffset: 1,
      buttonCol: 21,
    };

    if ((isInvited && large) || hideText) {
      dimensions.textSpan = 0;
      dimensions.textCol = 0;
      dimensions.buttonSpan = 24;
      dimensions.buttonOffset = 0;
      dimensions.buttonCol = 24;
    }
    return dimensions;
  }, [isInvited, large, hideText]);

  const handleAttendingEventConfirm = useCallback(
    type => {
      const payload = {
        eventId,
        attendeeType: type,
        isInvited,
        atendeeName: userName && userId ? userName : '',
        data: {
          userId: userId || loggedInUserId,
          rsvp: attendeeTypeToRsvpValues[type],
        },
        successCallback: type => {
          setAttendeeType(type);
          setDisabled(false);
        },
      };
      setDisabled(true);
      dispatch(confirmInviteRequest(payload));
    },
    [dispatch, eventId, isInvited, userId, loggedInUserId, userName],
  );

  return (
    <React.Fragment>
      <Row type="flex" className={cn({ [styles.large]: large })}>
        <Col
          span={dimensions.textSpan}
          md={dimensions.textCol}
          className={styles.rsvpText}
        >
          <S size="3" bold>
            {label || <FormattedMessage id="events.rsvp" />}
          </S>
        </Col>
        <Col
          span={dimensions.buttonSpan}
          offset={dimensions.buttonOffset}
          md={dimensions.buttonCol}
          className={styles.rsvpButton}
        >
          {!isInvited ? (
            <RsvpCircleButtons
              onClick={handleAttendingEventConfirm}
              large={large}
              disabled={disabled}
              offline={offline}
            />
          ) : (
            <Fragment>
              {userName && (
                <S size="5" inline className={styles.userName}>
                  {`${userName} rsvp:`}
                </S>
              )}
              <RsvpDropdownButton
                onClick={handleAttendingEventConfirm}
                attendeeType={attendeeType}
                disabled={disabled}
                large={large}
                offline={offline}
              />
            </Fragment>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

RsvpEventButton.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rsvp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  large: PropTypes.bool,
  hideText: PropTypes.bool,
  userId: PropTypes.number,
  label: PropTypes.node,
  userName: PropTypes.string,
};

RsvpEventButton.defaultProps = {
  large: false,
  hideText: false,
  userName: '',
};

export default RsvpEventButton;
