import React from 'react';

import { Avatar, Tooltip } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import CalendarIcon from '@assets/images/Calendar-Icon.svg';
import CampingIcon from '@assets/images/Camping.svg';
import CampingIconJTE from '@assets/images/CampingJTE.svg';
import HikingIcon from '@assets/images/Hiking.svg';
import HikingIconJTE from '@assets/images/HikingJTE.svg';
import LongCruiseIcon from '@assets/images/LongCruise.svg';
import LongCruiseJTE from '@assets/images/LongCruiseJTE.svg';
import ServiceHoursIcon from '@assets/images/ServiceHours.svg';
import ServiceHoursIconJTE from '@assets/images/ServiceHoursJTE.svg';

import { activityTypeIds, activityTypes } from '../../constants';
import styles from './ActivityTypeIcon.less';

const JteIcons = {
  [activityTypeIds.CAMPOUTS]: CampingIconJTE,
  [activityTypes.CAMPOUTS]: CampingIconJTE,
  [activityTypeIds.HIKES]: HikingIconJTE,
  [activityTypes.HIKES]: HikingIconJTE,
  [activityTypeIds.SERVICE_PROJECTS]: ServiceHoursIconJTE,
  [activityTypes.SERVICE_PROJECTS]: ServiceHoursIconJTE,
  [activityTypeIds.LONG_CRUISE]: LongCruiseJTE,
  [activityTypes.LONG_CRUISE]: LongCruiseJTE,
};

const activityIcons = {
  [activityTypeIds.CAMPOUTS]: CampingIcon,
  [activityTypes.CAMPOUTS]: CampingIcon,
  [activityTypeIds.HIKES]: HikingIcon,
  [activityTypes.HIKES]: HikingIcon,
  [activityTypeIds.SERVICE_PROJECTS]: ServiceHoursIcon,
  [activityTypes.SERVICE_PROJECTS]: ServiceHoursIcon,
  [activityTypeIds.LONG_CRUISE]: LongCruiseIcon,
  [activityTypes.LONG_CRUISE]: LongCruiseIcon,
};

const ActivityTypeIcon = ({
  activityType,
  activityTypeId,
  className,
  isJTE,
  isEvent,
  wrapInCircle,
  circleSize,
  ...props
}) => {
  const Icon =
    (isJTE
      ? JteIcons[+activityTypeId || activityType]
      : activityIcons[+activityTypeId || activityType]) || (() => '');
  const circleStyle = {
    width: circleSize,
    height: circleSize,
    lineHeight: `${circleSize}px`,
  };
  const content = isEvent ? (
    <CalendarIcon className={styles.icon} />
  ) : (
    <React.Fragment>
      {isJTE ? (
        <Tooltip title={<FormattedMessage id="progress.common.gftaImport" />}>
          <Icon className={styles.icon} />
        </Tooltip>
      ) : (
        <Icon className={styles.icon} />
      )}
    </React.Fragment>
  );
  return (
    <span className={cn(styles.wrapper, className)} {...props}>
      {wrapInCircle ? <Avatar style={circleStyle}>{content}</Avatar> : content}
    </span>
  );
};

ActivityTypeIcon.propTypes = {
  activityType: PropTypes.string,
  isJTE: PropTypes.bool,
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEvent: PropTypes.bool,
  className: PropTypes.string,
  wrapInCircle: PropTypes.bool,
  circleSize: PropTypes.number,
};

export default ActivityTypeIcon;
