import React from 'react';

import { FormattedMessage } from 'react-intl';

import { S, T } from '@modules/shared';
import Button from '@shared/components/Button';
import Modal from '@shared/components/Modal';

import styles from './UpdateNotificationContent.less';

const UpdateNotificationContent: React.FC<{ visible: boolean }> = props => {
  const isVisible = props.visible;

  return (
    <Modal
      visible={isVisible}
      size="small"
      className={styles.updateModal}
      useNewStyle
      centered
      closable={false}
      footer={
        <Button
          type="primary"
          size="large"
          shape="round"
          data-testid="app-refresh-btn"
          className={styles.refreshButton}
          onClick={() => {
            window.location.reload();
          }}
        >
          <FormattedMessage id="core.UpdateApp.refresh" />
        </Button>
      }
    >
      <div className={styles.updateModalBody}>
        <T size="2" colored>
          <FormattedMessage
            id="core.UpdateApp.title"
            defaultMessage="Update Available"
          />
        </T>
        <p>
          <S size="2">
            <FormattedMessage id="core.UpdateApp.newVersionAvailable" />
          </S>
        </p>
      </div>
    </Modal>
  );
};

export default UpdateNotificationContent;
