import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Fab, BottomScrollListener } from '@shared';
import ScrollFab from './ScrollFab';
import styles from './AdvancementReportButtons.less';

class AdvancementReportButtons extends React.PureComponent {
  state = {
    isAtBottom: false,
    isMounted: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isMounted: true });
    });
  }

  handleClick = () => {
    this.props.onSubmit();
  };

  handleScroll = () => {
    document.querySelector('#modal-end').scrollIntoView({ behavior: 'smooth' });
  };

  handleBottomChange = isAtBottom => {
    this.setState({ isAtBottom });
  };

  getMessageId = () => {
    const { isAlreadyGenerated } = this.props;
    if (isAlreadyGenerated) {
      return 'packRoster.AdvancementReportButtons.open';
    } else {
      return 'packRoster.AdvancementReportButtons.generate';
    }
  };

  getSubmitButtonColor = () => {
    const { isAlreadyGenerated } = this.props;
    if (isAlreadyGenerated) {
      return 'scouting-blue';
    } else {
      return 'scouting-red';
    }
  };

  renderOpenFab = () => {
    const { isOffline } = this.props;

    return ReactDOM.createPortal(
      <Fab
        containerClassName={styles.fabContainer}
        color="scouting-blue"
        disabled={this.props.loading || isOffline}
        onClick={this.handleClick}
      >
        <FormattedMessage id="packRoster.AdvancementReportButtons.open" />
      </Fab>,
      document.querySelector('body'),
    );
  };

  renderMobile() {
    const { isMounted, isAtBottom } = this.state;
    const { isOffline, isAlreadyGenerated, loading, disabled } = this.props;
    if (!isMounted) {
      return '';
    }

    return isAlreadyGenerated ? (
      this.renderOpenFab()
    ) : (
      <div>
        <Button
          className={styles.generateButton}
          type="primary"
          color="scouting-red"
          disabled={disabled || loading || isOffline}
          onClick={this.handleClick}
        >
          <FormattedMessage id="packRoster.AdvancementReportButtons.generate" />
        </Button>
        <ScrollFab isAtBottom={isAtBottom} onClick={this.handleScroll} />
      </div>
    );
  }

  render() {
    const { isMobile, isOffline, loading, disabled } = this.props;

    return (
      <React.Fragment>
        <BottomScrollListener
          customScrollSelector=".ant-modal-content"
          customBodySelector=".ant-modal-body"
          onBottomChange={this.handleBottomChange}
          offset={250}
        />
        {isMobile ? (
          this.renderMobile()
        ) : (
          <div className={styles.buttonContainer}>
            <Button
              type="primary"
              size="large"
              color={this.getSubmitButtonColor()}
              shadow
              disabled={disabled || loading || isOffline}
              onClick={this.handleClick}
            >
              <FormattedMessage id={this.getMessageId()} />
            </Button>
          </div>
        )}
        <div id="modal-end" />
      </React.Fragment>
    );
  }
}

AdvancementReportButtons.propTypes = {
  // provided by parent
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isAlreadyGenerated: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AdvancementReportButtons;
