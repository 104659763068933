import { phoneTypeIds, usAkelaCountryId } from '../constants';
import { trimField, trimObject } from './trimFields';

const getCountryName = (countryId, countries) => {
  if (countryId == usAkelaCountryId) {
    return 'US';
  }

  const foundCountry = countries.find(c => c.id == countryId);
  return foundCountry ? foundCountry.short : foundCountry;
};

const getStateName = (stateId, states) => {
  const foundState = states.find(s => s.id == stateId);
  return foundState ? foundState.short : '';
};

const getSuffixName = (nameSuffixId, suffixes) => {
  const founduffix = suffixes.find(s => s.id == nameSuffixId);
  return founduffix ? founduffix.short : '';
};

const getPhoneFormatted = phone => {
  const { areaCode, prefix, lineNumber } = phone;
  return `${areaCode}${prefix}${lineNumber}`;
};

export default (profile, countries = [], states = [], suffixes = []) => {
  const {
    firstName,
    lastName,
    middleName,
    nickName,
    nameSuffixId,
    addresses,
    emails,
    phones,
    sbExtendedProfile,
    schoolName,
  } = profile;

  const {
    oaActive,
    oaMember,
    oaMemberNumber,
    oaElectionDate,
    oaOrdealDate,
    oaBrotherhoodDate,
    oaVigilDate,
    talentReleaseUserId,
    talentReleaseDateRaw,
    swimmingClassification,
    swimmingClassificationDate,
    annualHealthRecordABDate,
    annualHealthRecordCDate,
  } = sbExtendedProfile;

  const foundEmail = emails.find(email => email.isPrimary);

  const foundAddress = addresses.find(address => address.isPrimary);
  const addressObj = foundAddress
    ? {
        address1: foundAddress.addressLine1,
        address2: foundAddress.addressLine2,
        address3: foundAddress.addressLine3,
        city: foundAddress.city,
        state: getStateName(foundAddress.stateId, states),
        zip: foundAddress.zip,
        country: getCountryName(foundAddress.countryId, countries),
      }
    : {};
  const foundHomePhone = phones.find(
    ({ typeId }) => Number(typeId) === phoneTypeIds.HOME,
  );
  const foundMobilePhone = phones.find(
    ({ typeId }) => Number(typeId) === phoneTypeIds.MOBILE,
  );
  const foundWorkPhone = phones.find(
    ({ typeId }) => Number(typeId) === phoneTypeIds.WORK,
  );

  return {
    firstName: trimField(firstName),
    lastName: trimField(lastName),
    middleName: trimField(middleName),
    nickName: trimField(nickName),
    suffix: getSuffixName(nameSuffixId, suffixes),
    email: trimField(foundEmail ? foundEmail.email : foundEmail),
    schoolName: trimField(schoolName),
    homePhone: foundHomePhone ? getPhoneFormatted(foundHomePhone) : '',
    mobilePhone: foundMobilePhone ? getPhoneFormatted(foundMobilePhone) : '',
    workPhone: foundWorkPhone ? getPhoneFormatted(foundWorkPhone) : '',
    mobilePhoneCarrier:
      foundMobilePhone && foundMobilePhone.mobilePhoneCarrier
        ? foundMobilePhone.mobilePhoneCarrier
        : '',
    ...trimObject(addressObj),
    oaActive,
    oaMember,
    oaMemberNumber: oaMemberNumber || '',
    oaElectionDate: oaElectionDate || '',
    oaOrdealDate: oaOrdealDate || '',
    oaBrotherhoodDate: oaBrotherhoodDate || '',
    oaVigilDate: oaVigilDate || '',
    talentReleaseUserId: talentReleaseUserId
      ? Number(talentReleaseUserId)
      : undefined,
    talentReleaseDate:
      talentReleaseDateRaw === null ? '' : talentReleaseDateRaw,
    swimmingClassification,
    swimmingClassificationDate:
      swimmingClassificationDate === null ? '' : swimmingClassificationDate,
    annualHealthRecordABDate:
      annualHealthRecordABDate === null ? '' : annualHealthRecordABDate,
    annualHealthRecordCDate:
      annualHealthRecordCDate === null ? '' : annualHealthRecordCDate,
  };
};
