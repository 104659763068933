import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import {
  activityTypeForActivityTypeId,
  advancementStatuses,
} from '@shared/constants';

import personNameBuilder from './personNameBuilder';

const getActivityStatus = ({ isApproved }) =>
  isApproved ? advancementStatuses.APPROVED : advancementStatuses.COMPLETED;

const isPendingItem = status =>
  status === advancementStatuses.COMPLETED ||
  status === advancementStatuses.COUNSELOR_APPROVED;

const denormalizePersons = (persons, activity) => {
  const { id: activityId, name: activityName } = activity;
  const activityType = activityTypeForActivityTypeId[activity.activityTypeId];
  return persons.map(({ activityValues, ...person }) => {
    const leaderApprovedUserId = person.leaderApprovedId;
    const status = getActivityStatus(person);
    const isAdvancementPending = isPendingItem(status);
    return {
      ...person,
      ...activity,
      leaderApprovedUserId,
      activityRecords: [...activityValues],
      isAdvancementPending,
      activityId,
      activityName,
      activityType,
      name: personNameBuilder.short(person),
      status,
      date: activity.startDateTime,
    };
  });
};

const denormalizeActivityItems = activityDto => {
  const { registeredYouths, registeredAdults, ...activity } = omit(
    activityDto,
    'nonRegisteredOrgParticipants',
  );
  const youths = denormalizePersons(registeredYouths, activity);
  const adults = denormalizePersons(registeredAdults, activity);
  return [...adults, ...youths];
};

const mapActivityItemsDtoToModel = dto =>
  flatten(dto.map(denormalizeActivityItems));

export default mapActivityItemsDtoToModel;
