import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { EDIT_SUB_UNITS } from '@config';
import { Button } from '@shared';
import { hasPermissionSel, permissions } from '@user';

import {
  setShowMemberTypes,
  setShowSubUnits,
} from '../../../packRoster/duck/actions';
import { showSubUnitsSel } from '../../../packRoster/duck/selectors';
import {
  isSubUnitAllowedSel, // selectors
  subUnitTypeNameSel,
} from '../../duck';

const GroupSubUnitButton = () => {
  const dispatch = useDispatch();
  const showSubUnits = useSelector(showSubUnitsSel);
  const subUnitTypeName = useSelector(subUnitTypeNameSel);
  const isSubUnitAllowed = useSelector(isSubUnitAllowedSel);
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );

  const handleOnClick = useCallback(() => {
    dispatch(setShowMemberTypes(false));
    dispatch(setShowSubUnits(!showSubUnits));
  }, [dispatch, showSubUnits]);

  return !!EDIT_SUB_UNITS && isSubUnitAllowed && canEditSubUnits ? (
    <Button
      color="white"
      ghost
      noBorder
      onClick={handleOnClick}
      size="small"
      uppercase={false}
    >
      <FormattedMessage
        id={`packRoster.GroupSubUnitButton.${subUnitTypeName}`}
      />
    </Button>
  ) : null;
};

export default GroupSubUnitButton;
