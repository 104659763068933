/* eslint-disable react/no-multi-comp */
import React from 'react';

import { Col, Row } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { intl } from '../../localization';
import Form from '../Form';
import Input from '../Input';
import T from '../T';
import styles from './ProfileOtherInfoCard.less';

const { Item: FormItem } = Form;

export const SchoolForm = ({ form, profile }) => {
  const { getFieldDecorator } = form;
  const schoolName = profile?.profile?.schoolName;

  return (
    <div>
      <div className={styles.subTitleContainer}>
        <T size="5" colored>
          <FormattedMessage id="shared.otherInfo.schoolTitle" />
        </T>
      </div>
      <Row type="flex" align="middle">
        <Col className={cn(styles.schoolCol)} xs={23} lg={17}>
          <FormItem className={cn(styles.autoCompleteFormItem)}>
            {getFieldDecorator('schoolName', {
              initialValue: schoolName,
            })(
              <Input
                size="large"
                placeholder={intl.formatMessage({
                  id: 'shared.otherInfo.school',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
      </Row>
    </div>
  );
};

SchoolForm.propTypes = {
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};
