import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SCOUTBOOK_LEGACY_URL } from '@config';
import { OfflineTooltip } from '@offline';
import { gtm } from '@shared';

import styles from './Nav.less';
import NavMenuItem from './NavMenuItem';
import NavMenuItemContent from './NavMenuItemContent';

const openForumLink = () => {
  gtm.pushEvent({
    event: 'NAVIGATE_TO_SCOUTBOOK_LEGACY',
    category: gtm.categories.SCOUTBOOK_LEGACY,
  });

  window.open(SCOUTBOOK_LEGACY_URL, '_blank');
};

const ScoutbookLegacyMenuItem = ({ loading, showLabels, ...rest }) => (
  <OfflineTooltip renderProps placement="right">
    {isOffline => (
      <div>
        <NavMenuItem
          key="scoutbookLegacy"
          selectable={false}
          id="qa_scoutbookLegacy"
          disabled={isOffline || loading}
          {...rest}
          onClick={openForumLink}
        >
          <NavMenuItemContent
            icon={<span className={styles.scoutbookLegacyIcon}>SB</span>}
            showLabels={showLabels}
            loading={loading}
          >
            <FormattedMessage id="core.Nav.scoutbookLegacy" />
          </NavMenuItemContent>
        </NavMenuItem>
      </div>
    )}
  </OfflineTooltip>
);

ScoutbookLegacyMenuItem.propTypes = {
  loading: PropTypes.bool,
  showLabels: PropTypes.bool.isRequired,
};

export default ScoutbookLegacyMenuItem;
