import { combineReducers } from 'redux';

import { SET_SHOULD_MEMBER_DETAILS_RELOAD } from '@shared/duck';
import { resetOnActionsReducer, resetOnLogoutReducer } from '@utils';

import {
  CLOSE_UNAPPROVE_MODAL,
  OPEN_UNAPPROVE_MODAL,
  UNAPPROVE_ADVANCEMENT_DEFERRED,
  UNAPPROVE_ADVANCEMENT_ERROR,
  UNAPPROVE_ADVANCEMENT_REQUEST,
  UNAPPROVE_ADVANCEMENT_RESPONSE,
} from './actions';

const visible = (state = false, { type }) => {
  switch (type) {
    case OPEN_UNAPPROVE_MODAL: {
      return true;
    }
    case CLOSE_UNAPPROVE_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case UNAPPROVE_ADVANCEMENT_REQUEST: {
      return true;
    }
    case UNAPPROVE_ADVANCEMENT_RESPONSE:
    case UNAPPROVE_ADVANCEMENT_ERROR:
    case UNAPPROVE_ADVANCEMENT_DEFERRED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const advancement = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_UNAPPROVE_MODAL: {
      return payload.advancement;
    }
    default: {
      return state;
    }
  }
};

const youthName = (state = '', { type, payload }) => {
  switch (type) {
    case OPEN_UNAPPROVE_MODAL: {
      return payload.youthName;
    }
    default: {
      return state;
    }
  }
};

const shouldMemberDetailsReload = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SHOULD_MEMBER_DETAILS_RELOAD: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  visible,
  loading,
  advancement,
  youthName,
  shouldMemberDetailsReload,
});

export default resetOnActionsReducer(
  resetOnLogoutReducer(reducer),
  UNAPPROVE_ADVANCEMENT_RESPONSE,
  UNAPPROVE_ADVANCEMENT_DEFERRED,
);
