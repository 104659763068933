import React, { useState } from 'react';
import Switch from '../../../Switch';
import PropTypes from 'prop-types';

const PersonSwitch = ({
  person,
  onAttendedToggle,
  isAttendedToggleLoading,
}) => {
  const { attended } = person;
  const [attend, setAttended] = useState(attended);
  const handleAttendedSwitch = status => {
    setAttended(status);
    onAttendedToggle(person, status);
  };

  return (
    <div>
      <Switch
        id="qa_priceToggle"
        checkedColor={'default'}
        checked={attend}
        onChange={() => handleAttendedSwitch(!attend)}
        disabled={isAttendedToggleLoading}
      />
    </div>
  );
};

PersonSwitch.propTypes = {
  person: PropTypes.object,
  onAttendedToggle: PropTypes.func,
  isAttendedToggleLoading: PropTypes.bool,
};

PersonSwitch.defaultProps = {
  persons: [],
  isAttendedToggleLoading: false,
  onAttendedToggle: () => {},
};

export default PersonSwitch;
