import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import styles from './CalendarCell.less';

const CalendarCell = ({
  selectedDate,
  cellDate,
  hasActivities,
  onClick,
  disabled,
}) => {
  const isSelected = cellDate.isSame(selectedDate, 'day');
  const isActiveMonth = cellDate.isSame(selectedDate, 'month');
  const classNames = cn('ant-calendar-date', {
    [styles.isSelected]: isSelected,
    [styles.otherMonth]: !isActiveMonth,
    [styles.hasActivities]: hasActivities,
    [styles.isDisabled]: disabled,
  });
  const handleClick = useCallback(
    () => !disabled && onClick(cellDate),
    [cellDate, onClick, disabled],
  );

  return (
    <div className={classNames} onClick={handleClick}>
      {moment(cellDate).format('D')}
    </div>
  );
};

CalendarCell.propTypes = {
  selectedDate: PropTypes.object.isRequired,
  cellDate: PropTypes.object.isRequired,
  hasActivities: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default CalendarCell;
