import React, { useCallback } from 'react';

import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';

import { getPreferredName } from '@modules/shared/utils/preferredName';

import Checkbox from '../../Checkbox/Checkbox';
import PersonAvatar from '../../PersonAvatar';
import S from '../../S';
import { Col, Row, Tag } from '../../bsaComponents';
import styles from './Person.less';

const Person = ({
  data,
  onCloseAction,
  closeAction,
  onRestoreAction,
  onCheckAction,
  isRestore,
}) => {
  const { isAdult, userId, isGuest } = data;
  const preferredName = getPreferredName(data);
  const handleClose = useCallback(
    () => onCloseAction(userId, data),
    [onCloseAction, userId, data],
  );
  const handleRestore = useCallback(
    () => onRestoreAction(userId, data),
    [onRestoreAction, userId, data],
  );

  return (
    <Tag className={styles.tag} outline={false}>
      <Row type="flex" align="middle" wrap={false}>
        {isRestore && (
          <Col className={styles.restoreCheckbox}>
            <Checkbox data={data} value={userId} onChange={onCheckAction} />
          </Col>
        )}
        <Col>
          <PersonAvatar isAdult={isAdult || isGuest} />
        </Col>
        <Col className={styles.personName}>
          <S size={'3'}>{preferredName}</S>
        </Col>
        {closeAction && (
          <Col className={styles.closeIcon}>
            {!isRestore && (
              <div onClick={handleClose}>
                <CancelIcon />
              </div>
            )}
            {isRestore && (
              <div onClick={handleRestore}>
                <AutorenewIcon color="primary" />
              </div>
            )}
          </Col>
        )}
      </Row>
    </Tag>
  );
};

Person.propTypes = {
  data: PropTypes.object.isRequired,
  onCloseAction: PropTypes.func,
  onRestoreAction: PropTypes.func,
  onCheckAction: PropTypes.func,
  closeAction: PropTypes.bool,
  isRestore: PropTypes.bool,
};

Person.defaultProps = {
  onCloseAction: () => {},
  onRestoreAction: () => {},
  onCheckAction: () => {},
  closeAction: false,
  isRestore: false,
};

export default Person;
