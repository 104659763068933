import React from 'react';

import { FormattedMessage } from 'react-intl';

import { PostSearchDenChiefResponse } from '@appTypes/esb';
import { Button, T } from '@modules/shared/components';

import styles from './SearchResults.less';

type Props = {
  onAdd: () => void;
  foundDenChief: PostSearchDenChiefResponse['denChief'][number];
};

const SearchResults: React.FC<Props> = ({ onAdd, foundDenChief }) => (
  <div className={styles.successfulSearchContainer}>
    <T className={styles.resultsTitle}>
      <FormattedMessage
        id={'packRoster.addIndividual.addDenChiefModal.results'}
      />
    </T>
    <div className={styles.resultsDetailsContainer}>
      <div className={styles.resultsDetailsColumn}>
        <FormattedMessage
          id={'packRoster.addIndividual.addDenChiefModal.firstName'}
          values={foundDenChief}
        />
        <FormattedMessage
          id={'packRoster.addIndividual.addDenChiefModal.lastName'}
          values={foundDenChief}
        />
        <FormattedMessage
          id={'packRoster.addIndividual.addDenChiefModal.memberId'}
          values={foundDenChief}
        />
      </div>
      <div className={styles.resultsDetailsColumn}>
        <FormattedMessage
          id={'packRoster.addIndividual.addDenChiefModal.unitType'}
          values={foundDenChief}
        />
        <FormattedMessage
          id={'packRoster.addIndividual.addDenChiefModal.unitNumber'}
          values={foundDenChief}
        />
      </div>
    </div>
    <Button
      className={styles.addButton}
      ghost
      shape="round"
      size="large"
      fitText
      uppercase={true}
      onClick={onAdd}
    >
      <FormattedMessage id={'packRoster.addIndividual.addDenChiefModal.add'} />
    </Button>
  </div>
);

export default SearchResults;
