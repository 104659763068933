import { createAction } from '@reduxjs/toolkit';

import { OrgSubUnit } from '@appTypes/esb';
import {
  FormattedSubUnitAdult,
  FormattedSubUnitYouth,
  SubUnitAdultPosition,
  SubUnitYouthPosition,
} from '@modules/advancement/utilsTyped';
import { createModuleNameSpace } from '@utils/misc';

import { modulePath } from '../constants';

export const moduleNamespace = createModuleNameSpace(modulePath);

export const showSetSubUnitModal = createAction(
  `${moduleNamespace}/SHOW_SET_SUB_UNIT_MODAL`,
);
export const hideSetSubUnitModal = createAction(
  `${moduleNamespace}/HIDE_SET_SUB_UNIT_MODAL`,
);

export const showAddToSubUnitModal = createAction(
  `${moduleNamespace}/SHOW_ADD_TO_SUB_UNIT_MODAL`,
);
export const hideAddToSubUnitModal = createAction(
  `${moduleNamespace}/HIDE_ADD_TO_SUB_UNIT_MODAL`,
);

export const setSelectedSubUnitId = createAction<number | null>(
  `${moduleNamespace}/SET_SELECTED_SUB_UNIT_ID`,
);

export const saveSubUnitRequest = createAction<{
  subUnitName: string;
  denType: string;
  selectedUsers: FormattedSubUnitYouth[];
  selectedAdults: FormattedSubUnitAdult[];
}>(`${moduleNamespace}/SAVE_SUB_UNIT_REQUEST`);

export const editSubUnitMembers = createAction<{
  subUnit: OrgSubUnit;
  addedYouths: SubUnitYouthPosition[];
  addedAdults: SubUnitAdultPosition[];
  deletedMembers?: number[];
  changedName?: boolean;
}>(`${moduleNamespace}/EDIT_SUB_UNIT_MEMBERS`);

export const saveSubUnitSuccess = createAction<{
  subUnit: OrgSubUnit;
  orgGuid: string;
}>(`${moduleNamespace}/SAVE_SUB_UNIT_SUCCESS`);

export const saveSubUnitFailure = createAction<Error>(
  `${moduleNamespace}/SAVE_SUB_UNIT_FAILURE`,
);

export const editSubUnitRequest = createAction<{
  subUnitName: string;
  denType?: string;
  selectedUsers: FormattedSubUnitYouth[];
  selectedAdults: FormattedSubUnitAdult[];
  subUnitId: number;
  membersToDelete?: number[];
}>(`${moduleNamespace}/EDIT_SUB_UNIT_REQUEST`);

export const editSubUnitSuccess = createAction<{
  subUnit: OrgSubUnit;
  orgGuid: string;
}>(`${moduleNamespace}/EDIT_SUB_UNIT_SUCCESS`);

export const editSubUnitFailure = createAction<Error>(
  `${moduleNamespace}/EDIT_SUB_UNIT_FAILURE`,
);

export const getSubUnitsResponse = createAction<{
  subUnits: OrgSubUnit[];
  orgGuid: string;
}>(`${moduleNamespace}/GET_SUB_UNITS_RESPONSE`);
export const getSubUnitsError = createAction<{
  orgGuid: string;
}>(`${moduleNamespace}/GET_SUB_UNITS_ERROR`);

export const deleteSubUnitRequest = createAction<number>(
  `${moduleNamespace}/DELETE_SUB_UNIT_REQUEST`,
);
export const deleteSubUnitResponse = createAction<{
  orgGuid: string;
  subUnitId: number;
  isDen?: boolean;
}>(`${moduleNamespace}/DELETE_SUB_UNIT_RESPONSE`);
export const deleteSubUnitError = createAction<Error>(
  `${moduleNamespace}/DELETE_SUB_UNIT_ERROR`,
);
