import React from 'react';

import Brightness3Icon from '@material-ui/icons/Brightness3';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SingleConfirmationModal } from '@shared';

import { ProgressButton } from '../../../common';
import { openCampouts } from '../../duck/actions';
import styles from './CampoutsButton.less';

class CampoutsButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.confirmModalRef = React.createRef();
  }

  handleClick = () => {
    const { askForConfirmation } = this.props;
    if (askForConfirmation) {
      this.openConfirmationModal();
    } else {
      this.onOpenCampouts();
    }
  };

  onOpenCampouts = () => {
    const { onOpenCampouts, onClick } = this.props;
    onOpenCampouts();
    onClick && onClick();
  };

  openConfirmationModal = () => {
    this.confirmModalRef.current.openConfirmationModal();
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    this.onOpenCampouts();
    onConfirm && onConfirm();
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  render() {
    const {
      disabled,
      className,
      children,
      askForConfirmation,
      disableConfirmBtn,
      confirmationMessage,
      replaceButton,
    } = this.props;
    const button = replaceButton ? (
      React.cloneElement(replaceButton, { onClick: this.handleClick })
    ) : (
      <ProgressButton
        color="scouting-warm-gray"
        noBorder
        fitText
        disabled={disabled}
        icon={<Brightness3Icon className={styles.icon} />}
        className={className}
        onClick={this.handleClick}
      >
        {children || <FormattedMessage id="campouts.CampoutsButton.label" />}
      </ProgressButton>
    );
    return (
      <React.Fragment>
        {askForConfirmation && (
          <SingleConfirmationModal
            ref={this.confirmModalRef}
            disableConfirm={disableConfirmBtn}
            confirmBtnText={<FormattedMessage id="shared.confirm" />}
            onCancel={this.handleCancel}
            message={confirmationMessage}
            onConfirm={this.handleConfirm}
          />
        )}
        {button}
      </React.Fragment>
    );
  }
}

const validateIfAskForConfirm =
  validator =>
  (props, ...args) => {
    if (props.askForConfirmation) {
      return validator(props, ...args);
    }
  };

CampoutsButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  userIds: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onOpenCampouts: PropTypes.func.isRequired,
  askForConfirmation: PropTypes.bool,
  disableGetActivities: PropTypes.bool,
  disableFutureDays: PropTypes.bool,
  allowRemovePersons: PropTypes.bool,
  replaceButton: PropTypes.node,
  disableConfirmBtn: validateIfAskForConfirm(PropTypes.bool),
  confirmationMessage: validateIfAskForConfirm(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  ),
  onCancel: validateIfAskForConfirm(PropTypes.func),
  onConfirm: validateIfAskForConfirm(PropTypes.func),
};

const mapDispatch = (
  dispatch,
  { userIds, disableGetActivities, disableFutureDays, allowRemovePersons },
) => ({
  onOpenCampouts: () =>
    dispatch(
      openCampouts(
        userIds,
        disableGetActivities,
        disableFutureDays,
        allowRemovePersons,
      ),
    ),
});

export default connect(null, mapDispatch)(CampoutsButton);
