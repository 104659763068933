import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { catchAndReport } from '@utils/rxjs/operators';

import {
  FETCH_POSITIONS_REQUEST,
  fetchPositionsError,
  fetchPositionsResponse,
} from './actions';
import { getYouthLeadershipPositions$ } from './services';

const getPositions$ = action$ =>
  action$.pipe(
    ofType(FETCH_POSITIONS_REQUEST),
    switchMap(() =>
      getYouthLeadershipPositions$().pipe(
        map(data => fetchPositionsResponse(data)),
        catchAndReport(err => of(fetchPositionsError(err))),
      ),
    ),
  );

export default combineEpics(getPositions$);
