import React from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';

const ToastCloseButton = ({ onClick }) => (
  <ClearIcon className="toast-close-icon" onClick={onClick} />
);

ToastCloseButton.defaultProps = {
  onClick: () => {},
};

ToastCloseButton.propTypes = {
  onClick: PropTypes.func,
};

export default ToastCloseButton;
