import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/takeUntil';
import { mergeMap, tap } from 'rxjs/operators';

import { SBL_4797_SHOW_RENEWAL_ROSTER } from '@config';
import { SET_ORGANIZATION, organizationGuidSel, unitTypeIdSel } from '@context';
import { dictionariesAdvancementsRequest } from '@dict';
import { memoizeShareObservable } from '@modules/utils';
import { editPreferedName } from '@modules/youthProfile/duck/actionsTyped';
import {
  LOGIN_RESPONSE,
  SELFSESSION_RESPONSE,
  advancementTypes,
  hasPermissionSel,
  intl,
  packRosterFieldPreferencesName,
  permissions,
  personGuidSel,
  userPreferencesServices,
} from '@shared';
import { toastService } from '@toasts';
import '@utils/rxjs.add.operator.catchAndReport';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  APPROVE_ADVANCEMENTS_RESPONSE,
  RECORD_ADVANCEMENT_RESPONSE,
  UNAPPROVE_ADVANCEMENT_RESPONSE,
} from '../../../progress/duck/actions';
import { userDataRequest } from '../../../user';
import {
  adultItemsSel,
  extendedAdultRosterRequest,
  extendedParentRosterRequest,
  isValidOrganizationSel,
  orgRosterError,
  orgRosterResponse,
  packRosterItemsSel,
} from '../../common';
import {
  isSubUnitAllowedSel, //selectors
} from '../../subUnits';
import { resetToast } from '../nullUserIdsWarn';
import { getMembersRenewalObject } from '../utils';
import {
  PACK_ROSTER_ALREADY_LOADED,
  PACK_ROSTER_ERROR,
  PACK_ROSTER_IGNORE_SUB_UNITS,
  PACK_ROSTER_REQUEST,
  PACK_ROSTER_REQUEST_IF_NOT_LOADED,
  PACK_ROSTER_RESPONSE,
  SAVE_OPTOUT,
  SAVE_OPTOUT_RESPONSE,
  SAVE_SBEXTENDED_PROFILE,
  packRosterAlreadyLoaded,
  packRosterError,
  packRosterIgnoreSubUnits,
  packRosterRequest,
  packRosterResponse,
  saveOptoutProfileError,
  saveOptoutProfileResponse,
  saveSBExtendedProfileError,
  saveSBExtendedProfileResponse,
  setFieldsVisibility,
} from './actions';
import { loadingSel } from './selectors';
import services from './services';

const loadPackRosterIfNotLoaded$ = (action$, state$) =>
  action$.ofType(PACK_ROSTER_REQUEST_IF_NOT_LOADED).switchMap(() => {
    const state = state$.value;
    const isRosterLoading = loadingSel(state);

    if (isRosterLoading) {
      return action$
        .ofType(PACK_ROSTER_RESPONSE, PACK_ROSTER_ALREADY_LOADED)
        .take(1)
        .map(() => packRosterAlreadyLoaded())
        .takeUntil(action$.ofType(PACK_ROSTER_ERROR));
    }

    const isRosterLoaded = packRosterItemsSel(state).length > 0;
    const action = isRosterLoaded
      ? packRosterAlreadyLoaded()
      : packRosterRequest();

    return Observable.of(action);
  });

const fetchAdvancementsDictsOnLoadEpic$ = action$ =>
  action$
    .ofType(PACK_ROSTER_RESPONSE)
    .map(() => dictionariesAdvancementsRequest());

const loadPackRoster$ = (action$, state$) =>
  action$
    .ofType(PACK_ROSTER_REQUEST)
    .filter(() => isValidOrganizationSel(state$.value))
    .mergeMap(() => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      const currentUnitTypeId = unitTypeIdSel(state);
      const isSubUnitAllowed = isSubUnitAllowedSel(state);
      resetToast();

      if (!currentUnitTypeId) {
        return Observable.of(packRosterError());
      }

      return services
        .getPackRoster$({
          organizationGuid,
          currentUnitTypeId,
          isSubUnitAllowed,
        })
        .map(packRosterResponse)
        .catchAndReport(err => {
          let action = packRosterError(err);
          const errorCode = err.response.errorCode || '';
          if (isSubUnitAllowed && errorCode === 'C404') {
            action = packRosterIgnoreSubUnits();
          }
          return Observable.of(action);
        });
    });

//TODO: REMOVE when sub units endpoint is fixed
const packRosterIgnoreSubUnit$ = (action$, state$) =>
  action$
    .ofType(PACK_ROSTER_IGNORE_SUB_UNITS)
    .filter(() => isValidOrganizationSel(state$.value))
    .mergeMap(() => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      const currentUnitTypeId = unitTypeIdSel(state);
      const isSubUnitAllowed = isSubUnitAllowedSel(state);
      const ignoreSubUnits = true;

      return services
        .getPackRoster$({
          organizationGuid,
          currentUnitTypeId,
          isSubUnitAllowed,
          ignoreSubUnits,
        })
        .map(packRosterResponse)
        .catchAndReport(err => Observable.of(packRosterError(err)));
    });

const reloadPackRoster$ = (action$, state$) =>
  action$
    .filter(() =>
      hasPermissionSel(
        state$.value,
        permissions.VIEW_ROSTER_PAGE,
        permissions.EDIT_DATA,
      ),
    )
    .ofType(
      editPreferedName.type,
      SET_ORGANIZATION,
      RECORD_ADVANCEMENT_RESPONSE,
      APPROVE_ADVANCEMENTS_RESPONSE,
      UNAPPROVE_ADVANCEMENT_RESPONSE,
    )
    .filter(
      ({ type, payload }) =>
        type == SET_ORGANIZATION ||
        type == editPreferedName.type ||
        payload === advancementTypes.RANKS ||
        (Array.isArray(payload) && payload.includes(advancementTypes.RANKS)),
    )
    .mapTo(packRosterRequest());

const loadPackRosterFieldsVisibility$ = (action$, state$) =>
  action$.ofType(LOGIN_RESPONSE, SELFSESSION_RESPONSE).map(() => {
    const personGuid = personGuidSel(state$.value);
    const fieldsVisiblity = userPreferencesServices.retrievePreferences({
      personGuid,
      featureName: packRosterFieldPreferencesName,
    });
    return setFieldsVisibility(fieldsVisiblity);
  });

const saveSBExtendedProfile$ = (action$, state$) =>
  action$.ofType(SAVE_SBEXTENDED_PROFILE).switchMap(({ payload }) => {
    const state = state$.value;
    const personGuid = personGuidSel(state);
    const { data, toastText } = payload;

    return services
      .saveSBExtendedProfile$(data, organizationGuidSel(state))
      .pipe(
        tap(() =>
          toastService.success(
            intl.formatMessage({
              id: toastText,
            }),
          ),
        ),
        mergeMap(() => {
          const hasLoggedInUser = data.find(
            item => item.personGuid === personGuid,
          );
          return hasLoggedInUser
            ? Observable.concat(
                of(saveSBExtendedProfileResponse()),
                of(userDataRequest()),
              )
            : Observable.of(saveSBExtendedProfileResponse());
        }),
        mergeMap(() => {
          const rosterItems = adultItemsSel(state);
          const memberIds = rosterItems
            .filter(({ memberId }) => memberId)
            .map(({ memberId }) => Number(memberId));
          return Observable.concat(
            of(extendedParentRosterRequest()),
            of(extendedAdultRosterRequest(memberIds)),
          );
        }),
        catchAndReport(err => Observable.of(saveSBExtendedProfileError(err))),
      );
  });

const getRosterOrgInfo$ = memoizeShareObservable(organizationGuid =>
  Observable.forkJoin([
    services.getOrgYouths$(
      { includeRegistrationDetails: true, includeExpired: true },
      organizationGuid,
    ),

    services.getOrgAdults$(
      { includeRegistrationDetails: true, includeExpired: true },
      organizationGuid,
    ),
  ]),
);

const loadRosterOrgInfo$ = (action$, state$) =>
  action$
    .ofType(PACK_ROSTER_REQUEST, SAVE_OPTOUT_RESPONSE)
    .filter(() => SBL_4797_SHOW_RENEWAL_ROSTER)
    .filter(() => isValidOrganizationSel(state$.value))
    .mergeMap(() => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      resetToast();

      return getRosterOrgInfo$(organizationGuid)
        .mergeMap(([youths, adults]) => {
          const youthMembers = getMembersRenewalObject(youths);
          const adultMembers = getMembersRenewalObject(adults);

          return Observable.of(
            orgRosterResponse({
              organizationInfo: youths.organizationInfo,
              youthMembers,
              adultMembers,
            }),
          );
        })
        .catchAndReport(err => Observable.of(orgRosterError(err)));
    });

const saveOptoutEpic$ = action$ =>
  action$.ofType(SAVE_OPTOUT).switchMap(({ payload }) => {
    const { personGuid, optOutValue, registrationId } = payload;

    const data = { isAutoRenewalOptedOut: optOutValue };

    return services
      .saveMembershipRegistration$(data, personGuid, registrationId)

      .pipe(
        tap(() =>
          toastService.success(
            intl.formatMessage({
              id: 'advancement.PackRoster.optOutsuccess',
            }),
          ),
        ),
        mergeMap(() => of(saveOptoutProfileResponse())),
        catchAndReport(err => Observable.of(saveOptoutProfileError(err))),
      );
  });

export default combineEpics(
  loadPackRoster$,
  packRosterIgnoreSubUnit$,
  reloadPackRoster$,
  loadPackRosterIfNotLoaded$,
  loadPackRosterFieldsVisibility$,
  fetchAdvancementsDictsOnLoadEpic$,
  saveSBExtendedProfile$,
  loadRosterOrgInfo$,
  saveOptoutEpic$,
);
