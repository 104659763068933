import { getActivities$, getActivityById$ } from '../common';
import { services as advancementServices } from '../advancement';
import { services as approveAdvancementsServices } from '../approveAdvancements';
import { services as declineAdvancementsServices } from '../declineAdvancements';
import { services as editAdvancementServices } from '../editAdvancement';

export default {
  getActivities$,
  getActivityById$,
  ...advancementServices,
  ...declineAdvancementsServices,
  ...approveAdvancementsServices,
  ...editAdvancementServices,
};
