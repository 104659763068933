import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  ProgramColors,
  ProgramLogo,
  ProgramName,
  S,
  UnitGenderPill,
} from '@shared';

import styles from './RolePickerRole.less';
import RolePickerBaseRole from './components/RolePickerBaseRole';

function RolePickerUnitRole({ active, disabled, org, onProfileChange }) {
  const positionNames = org.role;
  const extraPositions = _.chain(org)
    .get('allRoles')
    .uniq()
    .filter(position => position !== positionNames)
    .value();
  const extraPositionsLabel = extraPositions.join('\n');
  const hasExtraPositions = extraPositions.length > 0;

  return (
    <RolePickerBaseRole
      org={org}
      active={active}
      disabled={disabled}
      onProfileChange={onProfileChange}
    >
      <ProgramColors program={org.program}>
        <ProgramLogo program={org.program} className={styles.logo} />
      </ProgramColors>
      <div className={styles.description}>
        <S size="4">
          <ProgramName program={org.program} />
        </S>
        <S size="5">
          {org.organizationName}{' '}
          <UnitGenderPill gender={org.acceptGender} colored splitFamiliLabel />
        </S>
        <S size="5" title={extraPositions}>
          <FormattedMessage id="context.RolePicker.position" />: {positionNames}
        </S>
        {hasExtraPositions && (
          <S size="6" title={extraPositionsLabel}>
            <FormattedMessage
              id="context.RolePicker.extraPositions"
              values={{ count: extraPositions.length }}
            />
          </S>
        )}
      </div>
    </RolePickerBaseRole>
  );
}

RolePickerUnitRole.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  org: PropTypes.object.isRequired,
  onProfileChange: PropTypes.func.isRequired,
};

export default RolePickerUnitRole;
