import React, { useCallback, useMemo } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import {
  Col, //components
  DatePicker,
  Form,
  Input,
  Row, //utils
  intl,
  pageUI, //constants
  shortViewDateFormat,
} from '@shared';

import {
  AllDayCheckbox,
  getDefaultDate,
  getStartDateRule,
  makeEndDateDisabler,
  useAllDayCheckbox,
} from '../../../../common';
import styles from './ActivityInformation.less';
import ActivityTimeInput from './ActivityTimeInput';

const { Item: FormItem } = Form;
const { Group: InputGroup } = Input;

const ActivityInformation = ({
  isDisabled,
  disableFutureDays,
  preselectedStartDate,
  preselectedEndDate,
  showAllDayCheckbox,
  showStartDate,
  showEndDate,
  showTime,
  form,
}) => {
  const { getFieldDecorator, getFieldsValue, getFieldValue, setFieldsValue } =
    form;
  const formValues = getFieldsValue();
  const startDate = formValues.startDate;
  const isMobile = useSelector(isMobileSel);

  const allDay = useAllDayCheckbox({
    form,
    start: startDate,
    end: formValues.endTime,
    page: pageUI.ACTIVITY,
  });

  const requiredRule = useMemo(
    () => ({
      required: true,
      message: intl.formatMessage({
        id: 'shared.form.error.isRequired',
      }),
    }),
    [],
  );

  const shouldDisableEndDate = useCallback(
    endDate => makeEndDateDisabler(startDate)(endDate, disableFutureDays),
    [startDate, disableFutureDays],
  );
  const handleChangeStartDate = useCallback(
    formStartDate => {
      if (showEndDate) {
        const formEndDate = getFieldValue('endDate');
        const endDate = formEndDate && formEndDate.clone();
        if (formStartDate && !endDate) {
          const startDate = formStartDate.clone();
          setFieldsValue({ endDate: startDate });
        } else if (formStartDate && endDate) {
          const startDate = formStartDate.clone();
          const isEndBeforeStart = endDate.isBefore(startDate, 'day');
          setFieldsValue({ endDate: isEndBeforeStart ? startDate : endDate });
        }
      }
    },
    [getFieldValue, setFieldsValue, showEndDate],
  );

  const disabledTimePicker = isDisabled || allDay;
  const timePickerRules = disabledTimePicker
    ? [
        {
          required: false,
        },
      ]
    : [requiredRule];
  const disableMobileDates = useMemo(() => {
    if (isMobile) {
      return {
        minDate: startDate,
        maxDate: disableFutureDays ? 'today' : null,
      };
    }
  }, [isMobile, startDate, disableFutureDays]);

  return (
    <Row className={styles.container} type="flex" justify="space-between">
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('name', {
            rules: [
              requiredRule,
              {
                required: true,
                max: 50,
                message: intl.formatMessage(
                  {
                    id: 'shared.maxCharacters',
                  },
                  {
                    max: 50,
                  },
                ),
              },
            ],
          })(
            <Input
              disabled={isDisabled}
              size="large"
              placeholder={intl.formatMessage({
                id: 'shared.activityName',
              })}
              rounded
              floatingLabel
            />,
          )}
        </FormItem>
      </Col>
      <Col xs={0} lg={11} />
      {showStartDate && (
        <Col xs={24} lg={11}>
          <FormItem>
            {getFieldDecorator('startDate', {
              rules: [requiredRule, ...getStartDateRule(disableFutureDays)],
              validateStatus: false,
              initialValue: getDefaultDate(preselectedStartDate),
            })(
              <DatePicker
                disabled={isDisabled}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'shared.date.startDate',
                })}
                onChange={handleChangeStartDate}
                disableDates={disableFutureDays ? 'future' : undefined}
                format={shortViewDateFormat}
                fluid
                floatingLabel
                rounded
              />,
            )}
          </FormItem>
        </Col>
      )}
      <Col xs={24} lg={11}>
        <FormItem>
          {showEndDate &&
            getFieldDecorator('endDate', {
              rules: [requiredRule],
              validateStatus: false,
              initialValue: getDefaultDate(preselectedEndDate),
            })(
              <DatePicker
                disabled={isDisabled}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'shared.date.endDate',
                })}
                disabledDate={isMobile ? undefined : shouldDisableEndDate}
                disableDates={disableMobileDates}
                format={shortViewDateFormat}
                fluid
                floatingLabel
                rounded
              />,
            )}
        </FormItem>
      </Col>
      <Col xs={24} lg={11}>
        {showAllDayCheckbox && (
          <FormItem>
            <AllDayCheckbox disabled={isDisabled} form={form} />
          </FormItem>
        )}
      </Col>
      {showTime && (
        <Col xs={24} lg={11}>
          <FormItem>
            <InputGroup compact>
              <ActivityTimeInput
                className={cn(styles.timePicker, styles.timePickerLeft)}
                allDay={allDay}
                fieldName="startTime"
                fieldTranslationKey="shared.date.startTime"
                initialValue={preselectedStartDate}
                rules={timePickerRules}
                disabled={disabledTimePicker}
                form={form}
              />
              <ActivityTimeInput
                className={cn(styles.timePicker, styles.timePickerRight)}
                allDay={allDay}
                fieldName="endTime"
                fieldTranslationKey="shared.date.endTime"
                initialValue={preselectedStartDate}
                rules={timePickerRules}
                disabled={disabledTimePicker}
                form={form}
              />
            </InputGroup>
          </FormItem>
        </Col>
      )}
    </Row>
  );
};

ActivityInformation.propTypes = {
  form: PropTypes.object.isRequired,
  preselectedStartDate: PropTypes.object,
  preselectedEndDate: PropTypes.object,
  showAllDayCheckbox: PropTypes.bool,
  showStartDate: PropTypes.bool,
  showEndDate: PropTypes.bool,
  showTime: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disableFutureDays: PropTypes.bool,
};

ActivityInformation.defaultProps = {
  showAllDayCheckbox: true,
  showStartDate: true,
  showEndDate: true,
  showTime: true,
};

export default ActivityInformation;
