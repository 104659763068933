import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './RequiredIcon.less';

const RequiredIcon = ({ className }) => (
  <div className={cn(styles.icon, className)}>R</div>
);

RequiredIcon.propTypes = {
  className: PropTypes.string,
};

export default RequiredIcon;
