import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import S from '../S';
import { Collapse } from '../bsaComponents';
import styles from './TableFilterPanel.less';

const { Panel } = Collapse;

class TableFilterPanel extends React.Component {
  render() {
    const { header, children, className, mobileOnly, padding, ...rest } =
      this.props;

    return (
      <Panel
        {...rest}
        className={cn(className, styles.panel, {
          [styles.mobileOnly]: mobileOnly,
          [styles.noPadding]: !padding,
        })}
        header={
          <S size="3" bold colored>
            {header}
          </S>
        }
      >
        {children}
      </Panel>
    );
  }
}

TableFilterPanel.propTypes = {
  ...Panel.propTypes,
  mobileOnly: PropTypes.bool,
  padding: PropTypes.bool,
};

TableFilterPanel.defaultProps = {
  forceRender: true,
};

export default TableFilterPanel;
