import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Modal, Row, S } from '@shared/components';
import { intl } from '@shared/localization';

import { memberTypeIds } from '../../../constants';
import {
  closeUpdatePositionModal,
  isUpdatePositionModalVisible,
  memberPositionToUpdateSel,
} from '../../../duck';
import UpdatePositionForm from './UpdatePositionForm/UpdatePositionForm';
import styles from './UpdatePositionModal.less';

const UpdatePositionModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isUpdatePositionModalVisible);
  const { personFullName, memberTypeId } = useSelector(
    memberPositionToUpdateSel,
  );
  const handleCloseUpdatePositionModal = () =>
    dispatch(closeUpdatePositionModal());
  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.updatePositionModal.title',
      })}
      onCancel={handleCloseUpdatePositionModal}
      onBack={handleCloseUpdatePositionModal}
    >
      <div>
        <S size="4" className={styles.greyLabel}>
          {memberTypeId === memberTypeIds.youth && (
            <S size="4" className={styles.greyLabel}>
              <FormattedMessage id="recharterModals.updatePositionModal.note" />
            </S>
          )}
          <FormattedMessage
            id="recharterModals.updatePositionModal.subTitle"
            values={{
              name: <span className={styles.memberName}>{personFullName}</span>,
            }}
          />
        </S>
      </div>
      <Row type="flex" justify="center">
        <Col>
          <UpdatePositionForm />
        </Col>
      </Row>
    </Modal>
  );
};

export default UpdatePositionModal;
