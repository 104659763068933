import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { catchError } from 'rxjs/operators';

import { default as manualOfflineHelper } from '../../../shared/components/ManualOffline/manualOfflineHelper';
import { logout } from '../../../shared/duck/actions';
import { default as sentryReport } from '../../../shared/utils/sentryReport';
import isHttpSessionExpirationError from '../../isHttpSessionExpirationError';
import { Observable, of } from 'rxjs';
import { Action } from 'redux';

type Options = {
  reportAjaxErrors?: boolean;
};

type Handler = (err: Error, caught: Observable<Action>) => Observable<Action>;

/**
 * Exact same behavior as RxJS's `catchError`
 * but with extra bells and whistles:
 * - reports the error to Sentry and to the console
 * - dispatches LOGOUT action on session expiration error from HTTP API
 *
 * @example
 *
 * someObservable$.pipe(
 *  catchAndReport(handler)
 * )
 *
 * @param {function} handler your error handler
 * @param {object} [options] extra options
 * @param {boolean} [options.reportAjaxErrors] useful when you know HTTP layer won't report AjaxErrors
 */
export default (handler: Handler, options: Options = {}) =>
  (obs$: Observable<Action>) =>
    obs$.pipe(
      catchError((err: Error, caught) => {
        // AjaxErrors are normally handled in the http layer
        // but in some cases (e.g. when using progressSubscriber) that won't work
        // so we can optionally report those errors here
        if (err instanceof AjaxError) {
          if (options.reportAjaxErrors) {
            sentryReport.api(err);
          }
          manualOfflineHelper.checkApiError(err);
        } else {
          // eslint-disable-next-line no-console
          console.error(err);
          sentryReport.rxjs(err);
        }

        if (isHttpSessionExpirationError(err)) {
          return of(logout());
        }

        return handler(err, caught);
      }),
    );
