import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Collapse } from '../bsaComponents';
import styles from './CollapseDetailsCustom.less';

const { Panel } = Collapse;

const CollapseDetailsCustom = ({
  panelId,
  children,
  className,
  panelClassName,
  header,
  destroyInactivePanel,
  forceRender,
  withSeeMore = false,
  showArrow,
  disabled,
  padding,
  expandedKeys,
  panelKeyNew,
  onChange,
}) => {
  const handleChange = key => {
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <Collapse
      className={className}
      bordered={false}
      activeKey={expandedKeys}
      destroyInactivePanel={destroyInactivePanel}
      onChange={handleChange}
    >
      <Panel
        id={panelId}
        key={panelKeyNew}
        className={cn(
          styles.panel,
          {
            [styles.noPadding]: !padding,
            [styles.panelWithSeeMore]: withSeeMore,
          },
          panelClassName,
        )}
        header={header}
        forceRender={forceRender}
        showArrow={showArrow}
        extra={
          withSeeMore ? (
            <span className={cn(styles.seeMoreExpand)}>See more</span>
          ) : undefined
        }
        disabled={disabled}
      >
        {children}
      </Panel>
    </Collapse>
  );
};

CollapseDetailsCustom.propTypes = {
  disabled: PropTypes.bool,
  styleDisabled: PropTypes.bool,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onChange: PropTypes.func,
  header: PropTypes.node,
  showArrow: PropTypes.bool,
  forceRender: PropTypes.bool,
  destroyInactivePanel: PropTypes.bool,
  padding: PropTypes.bool,
  withSeeMore: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  panelClassName: PropTypes.string,
  panelId: PropTypes.string,
  expandedKeys: PropTypes.array,
  panelKeyNew: PropTypes.string,
};

CollapseDetailsCustom.defaultProps = {
  showArrow: true,
};

export default CollapseDetailsCustom;
