import { SET_SCREEN_META } from './actions';

const screen = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SCREEN_META: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export default screen;
