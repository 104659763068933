import React, { memo, useMemo } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { selectedRowKeysSel } from '@modules/advancement/pendingItems/duck';
import { ApproveAdvancementsButton } from '@modules/progress/approveAdvancements';
import { Fab, hasPermissionSel, permissions } from '@modules/shared';

import styles from './PendingItemsList.less';
import type { FlatListItem } from './PendingRequirementsList';

interface PendingRequirementsListFabProps {
  items: FlatListItem[];
  isOffline: boolean;
}

export const PendingRequirementsListFabOriginal: React.FC<
  PendingRequirementsListFabProps
> = ({ isOffline, items }) => {
  const selectedRowKeys = useSelector(selectedRowKeysSel);
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );
  const selectedAdvancements = useMemo(
    () => items.filter(({ key }) => selectedRowKeys.includes(key)),
    [items, selectedRowKeys],
  );

  if (canApprove && !isOffline && !isEmpty(selectedRowKeys)) {
    return (
      <Fab className="Joyride__ReportFab">
        <ApproveAdvancementsButton
          advancementStatus={null}
          className={styles.approveAdvancements}
          personsAdvancements={selectedAdvancements}
        />
      </Fab>
    );
  }

  return null;
};

export const PendingRequirementsListFab = memo(
  PendingRequirementsListFabOriginal,
);
