import { clone, orderBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { SBL_4797_SHOW_RENEWAL_ROSTER } from '@config';
import { denIdsSel, isDenOrAssistantSel } from '@context';
import { programSel } from '@context/duck/selectorsTyped';
import { ranksSel } from '@dict';
import {
  approvedSubUnitsSel,
  subUnitTypeKeysSel,
} from '@modules/advancement/subUnits/duck/selectors';
import { userIdSel } from '@modules/user';
import {
  denTypesSingleNames as denNames,
  memberTypes,
  objElementName,
  packRosterFieldPreferencesName,
  selectedOrganizationSel,
  userPreferencesSel,
} from '@shared';
import { applySort, isIncludedIn } from '@utils';

import {
  packRosterItemsSel as itemsSel,
  packRosterModuleSel,
} from '../../common/selectors';
import {
  UNASSIGNED,
  peopleFilters,
  programGrownUpAges,
  renewalStatuses,
  visibilityFields,
} from '../constants';
import packRosterSorters from '../packRosterSorters';
import { getPersonRenewalStatus, sortSubUnitsBy } from '../utils';
import { getIsDenChief } from '../utilsTyped';

export {
  selectedKeysSel,
  unitRosterReportLoadingSel,
  isMissingSomeMemberIdSel,
} from '../../common/selectors';
export {
  subUnitTypeNameSel,
  isSubUnitAllowedSel,
} from '../../subUnits/duck/selectors';
export { itemsSel };

const defaultFieldsVisibility = {
  [visibilityFields.AGE]: true,
  [visibilityFields.MEMBER_ID]: false,
  [visibilityFields.LAST_RANK_EARNED]: true,
  [visibilityFields.SUB_UNIT]: true,
};

const packRosterFieldsVisibilitySel = state =>
  userPreferencesSel(state, packRosterFieldPreferencesName);

export const fieldsVisibilitySel = createSelector(
  packRosterFieldsVisibilitySel,
  fieldsVisibility => ({
    ...defaultFieldsVisibility,
    ...fieldsVisibility,
  }),
);

const moduleSel = state => packRosterModuleSel(state);

export const loadingSel = state => moduleSel(state).loading;
export const searchSel = state => moduleSel(state).search;
export const sorterSel = state => moduleSel(state).sorter;
export const filterSel = state => moduleSel(state).filter;
export const showSubUnitsSel = state => moduleSel(state).showSubUnits;
export const showMemberTypesSel = state => moduleSel(state).showMemberTypes;
export const subUnitsFilterTagsSel = state =>
  moduleSel(state).subUnitsFilterTags;

export const subUnitsSectionExpandedSel = state =>
  moduleSel(state).subUnitsSectionExpanded;

export const memberTypeSectionExpandedSel = state =>
  moduleSel(state).memberTypeSectionExpanded;

export const subUnitUserDataSel = state => moduleSel(state).subUnitUserData;

export const renewalRosterInfoSel = state => moduleSel(state).renewalRosterInfo;
export const loadingRenewalSel = state => moduleSel(state).loadingRenewal;

export const subUnitsFilteredTagsSel = createSelector(
  subUnitsFilterTagsSel,
  isDenOrAssistantSel,
  denIdsSel,
  (subUnitsFilterTags, isDenOrAssistant, denDetails) => {
    if (isDenOrAssistant) {
      const sortedSubUnitsFilterTags = sortSubUnitsBy(
        subUnitsFilterTags,
        objElementName.NAME,
      );
      return sortedSubUnitsFilterTags.filter(subUnitFilterTag =>
        denDetails.includes(subUnitFilterTag.id),
      );
    }
    const sortedSubUnitsFilterTags = sortSubUnitsBy(
      subUnitsFilterTags,
      objElementName.NAME,
    );
    return sortedSubUnitsFilterTags;
  },
);

const isValidPosition = (positionId, subUnits) =>
  subUnits.find(subUnit => subUnit.subUnitId === positionId);

const getSubUnitPosition = (positions, subUnits, keys) => {
  if (positions && subUnits) {
    const positionsByExistingSubUnits = positions.filter(position =>
      isValidPosition(position[keys.id], subUnits),
    );

    const sortedPositions = orderBy(
      positionsByExistingSubUnits,
      'dateStarted',
      'desc',
    );
    const position = sortedPositions.find(
      position => position[keys.id] !== null,
    );
    if (position) {
      const posName = position[keys.name];
      const mappedPosName = denNames[posName] ? denNames[posName] : posName;
      const denNumber = position[keys.number]
        ? ` ${position[keys.number]}`
        : '';
      return {
        subUnitId: position[keys.id],
        subUnitName: `${mappedPosName}${denNumber}`,
      };
    }
  }
  return { subUnitId: -1, subUnitName: UNASSIGNED };
};

const getSubUnitsList = (positions, subUnits, keys, isAdult) => {
  if (positions && subUnits && isAdult) {
    const positionsByExistingSubUnits = positions.filter(position =>
      isValidPosition(position[keys.id], subUnits),
    );

    const sortedPositions = orderBy(
      positionsByExistingSubUnits,
      'dateStarted',
      'desc',
    );
    return sortedPositions.length
      ? sortedPositions.map(position => position[keys.id])
      : [];
  }
  return [];
};

const filteredEnhancedItemsSel = createSelector(
  itemsSel,
  approvedSubUnitsSel,
  subUnitTypeKeysSel,
  isDenOrAssistantSel,
  denIdsSel,
  (items, approvedSubUnits, subUnitTypeKeys, isDenOrAssistant, denIds) => {
    const itemsWithPosition = items.map(item => ({
      ...item,
      ...getSubUnitPosition(
        item.positions || [],
        approvedSubUnits,
        subUnitTypeKeys,
      ),
      subUnitListIds: getSubUnitsList(
        item.positions || [],
        approvedSubUnits,
        subUnitTypeKeys,
        item.isAdult,
      ),
    }));

    if (isDenOrAssistant && !isEmpty(denIds)) {
      return itemsWithPosition.filter(item => denIds.includes(item.subUnitId));
    }
    return itemsWithPosition;
  },
);

const enhancedItemsSel = createSelector(
  filteredEnhancedItemsSel,
  ranksSel,
  renewalRosterInfoSel,
  (filteredEnhancedItems, ranksList, renewalsInfo) =>
    filteredEnhancedItems.map(item => {
      const { rosterCurrentHighestRankApproved: rosterRank = {} } = item;
      const renewalStatusFields = getPersonRenewalStatus(item, renewalsInfo);
      return {
        ...item,
        ...renewalStatusFields,
        rosterCurrentHighestRankApproved: {
          ...rosterRank,
          imageUrl: (ranksList.find(({ id }) => id == rosterRank.id) || {})
            .imageUrl100,
        },
      };
    }),
);

const isMemberOf = ({
  filter,
  isLeader,
  isAdult,
  age,
  oldAge = 18,
  isUnitParticipant = false,
  isDenChief,
  renewalStatus,
  hasARenewalStatusFilter,
}) => {
  // filter out parents from roster table
  if (isAdult && !isLeader) {
    return false;
  }

  // filter or include adults
  if (filter[peopleFilters.ADULTS] && isAdult && !isUnitParticipant) {
    if (
      SBL_4797_SHOW_RENEWAL_ROSTER &&
      hasARenewalStatusFilter &&
      renewalStatus
    ) {
      return filter[renewalStatus];
    }

    return true;
  }

  // filter or include aged youth
  if (filter[peopleFilters.AGED_YOUTH] && !isAdult && age >= oldAge) {
    if (
      SBL_4797_SHOW_RENEWAL_ROSTER &&
      hasARenewalStatusFilter &&
      renewalStatus
    ) {
      return filter[renewalStatus];
    }

    return true;
  }

  // filter or include youth
  if (filter[peopleFilters.YOUTH] && age < oldAge) {
    if (
      SBL_4797_SHOW_RENEWAL_ROSTER &&
      hasARenewalStatusFilter &&
      renewalStatus
    ) {
      return filter[renewalStatus];
    }

    return true;
  }

  // filter or include den chiefs
  if (filter[peopleFilters.DEN_CHIEFS] && isDenChief) {
    if (
      SBL_4797_SHOW_RENEWAL_ROSTER &&
      hasARenewalStatusFilter &&
      renewalStatus
    ) {
      return filter[renewalStatus];
    }

    return true;
  }

  // fallback if no filters match
  return false;
};

const isIncludedInSubUnitFilters = (scout, filters) => {
  if (filters) {
    const scoutSubUnitFilter = filters.find(
      filter => filter.name === scout.subUnitName,
    ) || { status: true };
    return scoutSubUnitFilter.status;
  }
  return true;
};

export const filteredItemsSel = createSelector(
  enhancedItemsSel,
  searchSel,
  sorterSel,
  filterSel,
  programSel,
  subUnitsFilteredTagsSel,
  (items, search, sorter, filter, program, subUnitsFilteredTags) => {
    const oldAge = programGrownUpAges[program];

    const filteredItems = items.filter(scout => {
      const isDenChief = getIsDenChief(scout);
      const hasARenewalStatusFilter = Object.values(renewalStatuses).some(
        value => filter[value],
      );
      const valid =
        isMemberOf({
          filter,
          isLeader: scout.isLeader,
          isAdult: scout.isAdult,
          age: scout.age,
          oldAge,
          isUnitParticipant: scout.isUnitParticipant,
          isDenChief,
          renewalStatus: scout.renewalStatus,
          hasARenewalStatusFilter,
        }) &&
        isIncludedInSubUnitFilters(scout, subUnitsFilteredTags) &&
        (isIncludedIn(scout.personShortFullName, search) ||
          isIncludedIn(scout.personId, search) ||
          isIncludedIn(scout.nickName, search) ||
          isIncludedIn(scout.currentHighestRankApproved.rank, search));

      return valid;
    });

    const sortedItems = applySort({
      sorter,
      items: filteredItems,
      sorters: packRosterSorters,
    });

    return sortedItems;
  },
);

const isSubUnitVisible = (subUnit, filters) => {
  const filter = filters.find(filter => filter.id === subUnit.subUnitId);
  return filter?.status;
};

export const itemsPerSubUnitSel = createSelector(
  filteredItemsSel,
  approvedSubUnitsSel,
  subUnitsSectionExpandedSel,
  subUnitsFilteredTagsSel,
  (items, approvedSubUnits, subUnitsSectionExpanded, subUnitsFilteredTags) => {
    const subUnitSection = (approvedSubUnits || [])
      .filter(subUnit => isSubUnitVisible(subUnit, subUnitsFilteredTags))
      .map(subUnit => ({
        ...subUnit,
        key: subUnit.subUnitId,
        isMainRow: true,
        expanded: subUnitsSectionExpanded[subUnit.subUnitId],
        subRows: items
          .filter(({ subUnitId, subUnitListIds, isAdult }) => {
            if (isAdult && subUnitListIds.length) {
              return subUnitListIds.some(
                itemId => itemId === subUnit.subUnitId,
              );
            }
            return subUnitId === subUnit.subUnitId;
          })
          .map(item => {
            if (item.isAdult) {
              const currentSubUnitId = item.subUnitListIds.find(
                itemId => itemId === subUnit.subUnitId,
              );
              if (currentSubUnitId) {
                const clonedItem = clone(item);
                clonedItem.subUnitId = currentSubUnitId;
                const mappedSubUnitName = denNames[subUnit?.denType]
                  ? `${denNames[subUnit?.denType]} ${subUnit?.subUnitNameRaw}`
                  : subUnit.subUnitName;
                clonedItem.subUnitName = mappedSubUnitName;
                return clonedItem;
              }
            }

            return item;
          }),
      }));
    const nonSubUnitSection = {
      key: UNASSIGNED,
      isMainRow: true,
      expanded: subUnitsSectionExpanded[UNASSIGNED],
      subRows: items.filter(({ subUnitId }) => subUnitId === -1),
    };

    const sortedSubUnitSection = sortSubUnitsBy(
      subUnitSection,
      objElementName.SUB_UNIT_NAME,
    );

    const unassignedFilter = subUnitsFilteredTags?.find(
      filter => filter.id === -1,
    );

    const completeSubUnitSection = unassignedFilter?.status
      ? [...sortedSubUnitSection, nonSubUnitSection]
      : [...sortedSubUnitSection];

    return completeSubUnitSection;
  },
);

export const itemsPerMemberTypeSel = createSelector(
  filteredItemsSel,
  memberTypeSectionExpandedSel,
  (items, memberTypeSectionExpanded) => {
    const memberTypeSection = memberTypes.map(memberType => {
      const subRows = items.filter(
        ({ memberTypeId }) => memberTypeId === memberType.id,
      );
      return {
        key: memberType.label,
        name: memberType.label,
        itemsCount: subRows.length,
        isMainRow: true,
        expanded: memberTypeSectionExpanded[memberType.label],
        subRows,
      };
    });
    return memberTypeSection;
  },
);

export const currentUserSubunitsSel = createSelector(
  itemsSel,
  userIdSel,
  selectedOrganizationSel,
  (items, userId, { canEditSubCalendar: hasSubUnitOnlyPerms }) => {
    // We don't need to know the user subunits if they have full access to the unit
    if (!hasSubUnitOnlyPerms) return [];

    const foundInRoster = items.find(item => item.userId === userId);
    if (foundInRoster) {
      const { positions = [] } = foundInRoster;
      return positions.filter(pos => pos.denId || pos.patrolId);
    }

    return [];
  },
);

export const userSubUnitMembersSel = createSelector(
  itemsSel,
  currentUserSubunitsSel,
  (roster, userSubUnits) => {
    const filteredMembers = roster.filter(member => {
      const belongsToSubUnit = member.positions?.some(pos =>
        userSubUnits.some(mySub => {
          const mySubId = mySub.patrolId || mySub.denId;
          const posSubId = pos.patrolId || pos.denId;
          return mySubId === posSubId;
        }),
      );
      return belongsToSubUnit;
    });
    return filteredMembers;
  },
);
