import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './TablePlaceholder.less';

const TablePlaceholderItemMobile = () => (
  <ContentLoader width={240} height={150} className={styles.itemMobile}>
    <circle cx="25" cy="35" r="15" />
    <rect x="130" y="27" rx="3" ry="3" width="100" height="15" />

    <rect x="10" y="67" rx="3" ry="3" width="90" height="15" />
    <rect x="170" y="67" rx="3" ry="3" width="60" height="15" />

    <rect x="10" y="107" rx="3" ry="3" width="70" height="15" />
    <rect x="150" y="107" rx="3" ry="3" width="80" height="15" />
  </ContentLoader>
);

export default TablePlaceholderItemMobile;
