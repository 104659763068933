import { advancementStatuses } from '../constants';
import isValueTruthy from './isValueTruthy';

const isAwarded = ({ status, awarded, awardedDate, awardedUserId }) =>
  status === 'Awarded' ||
  !!(isValueTruthy(awarded) || awardedDate || awardedUserId);

const isApproved = ({ leaderApprovedDate, leaderApprovedUserId }) =>
  !!(leaderApprovedDate || leaderApprovedUserId);
const isAtLeastApproved = item => isApproved(item) || isAwarded(item);

const isCounselorApproved = ({ counselorApprovedUserId }) =>
  !!counselorApprovedUserId;
const isAtLeastCounselorApproved = item =>
  isCounselorApproved(item) || isAtLeastApproved(item);

const isCompleted = ({
  dateCompleted,
  dateEarned,
  markedCompletedDate,
  status,
  markedCompletedUserId,
}) =>
  !!(
    status === 'Completed' ||
    dateCompleted ||
    dateEarned ||
    markedCompletedDate ||
    markedCompletedUserId
  );
const isAtLeastCompleted = item =>
  isCompleted(item) || isAtLeastCounselorApproved(item);

const isStarted = ({ dateStarted, status, percentCompleted }) =>
  !!dateStarted || status == 'Started' || percentCompleted > 0;
const isAtLeastStarted = item => isStarted(item) || isAtLeastCompleted(item);

const advancementStatusValues = Object.freeze({
  [advancementStatuses.AWARDED]: 5,
  [advancementStatuses.COUNSELOR_APPROVED]: 4,
  [advancementStatuses.APPROVED]: 3,
  [advancementStatuses.COMPLETED]: 2,
  [advancementStatuses.STARTED]: 1,
});

const getStatus = item => {
  if (isAwarded(item)) {
    return advancementStatuses.AWARDED;
  }
  if (isApproved(item)) {
    return advancementStatuses.APPROVED;
  }
  if (isCounselorApproved(item)) {
    return advancementStatuses.COUNSELOR_APPROVED;
  }
  if (isCompleted(item)) {
    return advancementStatuses.COMPLETED;
  }
  if (isStarted(item)) {
    return advancementStatuses.STARTED;
  }

  return advancementStatuses.NOT_STARTED;
};

const statusCompare = (status1, status2) =>
  advancementStatusValues[status1] - advancementStatusValues[status2];

export default {
  statusCompare,
  isStarted,
  isAtLeastStarted,
  isCompleted,
  isAtLeastCompleted,
  isCounselorApproved,
  isAtLeastCounselorApproved,
  isApproved,
  isAtLeastApproved,
  isAwarded,
  getStatus,
};
