import { sentryReport } from '@shared/utils';
import { toastService } from '@toasts';

const successResponseCode = 1;
const errorMessage = 'Request failed';

const isTextBusinessError = (responseText = '') =>
  responseText.toLowerCase().includes('system error occurred');

const isJsonBusinessError = responseJson => {
  const { ResponseCode } = responseJson || {};
  return ResponseCode != null && ResponseCode != successResponseCode;
};

const isBusinessError = (unwrappedResponse, response) => {
  const { responseType } = response || {};
  return responseType === 'text'
    ? isTextBusinessError(unwrappedResponse)
    : isJsonBusinessError(unwrappedResponse);
};

const handleBusinessError = (response, request, { suppressErrorToasts }) => {
  const error = new Error(errorMessage);
  error.response = response;
  error.request = request;
  sentryReport.api(error);

  if (!suppressErrorToasts) {
    toastService.error(errorMessage);
  }
  throw error;
};

const handlePotentialBusinessError = (
  unwrappedResponse,
  response,
  request,
  options,
) => {
  if (isBusinessError(unwrappedResponse, response)) {
    return handleBusinessError(response, request, options);
  }
  return unwrappedResponse;
};

export default handlePotentialBusinessError;
