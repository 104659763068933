import React, { useMemo } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import PersonItem from '../../PersonItem';
import styles from './PersonsListMobile.less';

// eslint-disable-next-line react/display-name
const PersonsList = React.memo(
  ({
    persons,
    onRemove,
    ineligibleYouthUsersIds,
    ineligibleAdultsUsersIds,
    listId,
    personClassName,
    showAdvStatus,
    personsNotAllowedToRemove,
  }) => {
    const ineligibleUsersIds = useMemo(
      () => [...ineligibleAdultsUsersIds, ...ineligibleYouthUsersIds],
      [ineligibleAdultsUsersIds, ineligibleYouthUsersIds],
    );
    return (
      <div className={styles.list} id={listId}>
        {persons.map(person => {
          const {
            userId,
            personShortFullName,
            isAdult,
            isLeader,
            isParent,
            advancementStatus,
          } = person;
          const isAllowedToRemove = personsNotAllowedToRemove
            ? !personsNotAllowedToRemove.includes(userId)
            : true;
          return (
            <PersonItem
              key={userId}
              personName={personShortFullName}
              isAdult={isAdult}
              isLeader={isLeader}
              isParent={isParent}
              color={ineligibleUsersIds.includes(userId) ? 'warn' : undefined}
              showAdvStatus={showAdvStatus}
              advancementStatus={advancementStatus}
              className={
                typeof personClassName === 'function'
                  ? personClassName(person)
                  : personClassName
              }
            >
              {isAllowedToRemove && (
                <CloseIcon
                  className={styles.deselect}
                  onClick={() => onRemove && onRemove(userId, person)}
                />
              )}
            </PersonItem>
          );
        })}
      </div>
    );
  },
);

PersonsList.propTypes = {
  persons: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  ineligibleYouthUsersIds: PropTypes.array,
  ineligibleAdultsUsersIds: PropTypes.array,
  listId: PropTypes.string,
  personClassName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  showAdvStatus: PropTypes.bool.isRequired,
  personsNotAllowedToRemove: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
};

export default PersonsList;
