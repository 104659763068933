import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared';

class DownloadFileErrorLogButton extends React.PureComponent {
  handleClick = () => {
    const { item, onClick } = this.props;
    onClick(item);
  };

  render = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      item,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick,
      ...rest
    } = this.props;

    return (
      <Button
        id="qa_downloadButton"
        ghost
        noBorder
        fitText
        color="scouting-warm-gray"
        onClick={this.handleClick}
        {...rest}
      >
        <FormattedMessage id="advancement.AdvancementHistory.downloadLogFile" />
      </Button>
    );
  };
}

DownloadFileErrorLogButton.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DownloadFileErrorLogButton;
