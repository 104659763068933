import React from 'react';
import PropTypes from 'prop-types';

import AdvancementFileRecordsInfo from '../../AdvancementFileRecordsInfo';
import HistoryItemStatus from '../../HistoryItemStatus';
import styles from './ExpandedRow.less';

const ExpandedRow = ({ item }) => {
  switch (item.type) {
    case 'advancementFile': {
      return <AdvancementFileRecordsInfo item={item} />;
    }
    default: {
      return (
        <div className={styles.advancementDescription}>
          {HistoryItemStatus.getUnSyncedFileMessage()}
        </div>
      );
    }
  }
};

ExpandedRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ExpandedRow;
