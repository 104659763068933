import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Moment } from 'moment';

import type {
  AdventureUserRequirements,
  MeritBadgeUserRequirements,
  MeritBadgeV1,
} from '../../../types/esb';
import { initialState } from './constants';
import { AdvancementRequirement, BulkEntryState, BulkEntryType } from './types';
import { isUpdateableRequirement } from './utils/requirementUtils';
import { QEValidationResult } from './utils/validators';

export const quickEntryRankSlice = createSlice({
  name: 'quickEntryRank',
  initialState,
  extraReducers: builder => {
    builder.addCase(
      'context/SET_ORGANIZATION',
      () =>
        // reset state when changing unit
        initialState,
    );
  },
  reducers: {
    // START common
    setPreselectedInfo: (
      state,
      action: PayloadAction<{ selection: BulkEntryState['preselectedInfo'] }>,
    ) => {
      state.preselectedInfo = {
        ...state.preselectedInfo,
        ...action.payload.selection,
      };
    },
    clearAdvancementSelection: state => {
      state.selectedAdventure = undefined;
      state.selectedMeritBadge = undefined;
      state.selectedRank = undefined;
      state.selectedVersion = undefined;
    },
    applyAdvancementSelection: (
      state,
      action: PayloadAction<{ type: 'adventure' | 'meritBadge' | 'rank' }>,
    ) => {
      switch (action.payload.type) {
        case 'adventure':
          state.selectedAdventure = state.preselectedInfo.adventure;
          break;
        case 'meritBadge':
          state.selectedMeritBadge = state.preselectedInfo.meritBadge;
          break;
        default:
          break;
      }
      state.selectedVersion = state.preselectedInfo.version;
    },
    // END common
    setRankInfo: (state, { payload: { rank, version } }) => {
      state.selectedRank = rank;
      state.selectedVersion = version;
    },
    // Adventure Actions
    setAdventureInfo: state => {
      state.selectedAdventure = state.preselectedInfo.adventure;
    },
    setAdventureUserRequirements: (
      state,
      action: PayloadAction<{ userRequirements: AdventureUserRequirements[] }>,
    ) => {
      state.adventureUserRequirements = action.payload.userRequirements;
    },
    // Merit Badge Actions
    setMeritBadgeInfo: (
      state,
      action: PayloadAction<
        | {
            meritBadge: MeritBadgeV1;
          }
        | {
            version: MeritBadgeV1['versions'][0];
          }
      >,
    ) => {
      // this avoids to set the property as null or undefined if not present
      if ('meritBadge' in action.payload) {
        state.selectedMeritBadge = action.payload.meritBadge;
      }
      if ('version' in action.payload) {
        state.selectedVersion = action.payload.version;
      }
    },

    setMBEntryType: (
      state,
      action: PayloadAction<{ mbEntryType: BulkEntryState['mbEntryType'] }>,
    ) => {
      state.mbEntryType = action.payload.mbEntryType;
    },

    /**
     * Toggles selection of merit badge during multiple entry
     *
     */
    toggleMeritBadge: (
      state,
      action: PayloadAction<{ meritBadgeId: MeritBadgeV1['id'] }>,
    ) => {
      const { meritBadgeId } = action.payload;
      const selectedIds = new Set(state.selectedMeritBadges);
      if (selectedIds.has(meritBadgeId)) {
        selectedIds.delete(meritBadgeId);
      } else {
        selectedIds.add(meritBadgeId);
      }
      const newData = Array.from(selectedIds);
      state.selectedMeritBadges = newData;
    },

    setMeritBadgeUserRequirements: (
      state,
      action: PayloadAction<{ userRequirements: MeritBadgeUserRequirements[] }>,
    ) => {
      state.meritBadgeUserRequirements = action.payload.userRequirements;
    },

    // Scout actions
    deselectAllScouts: state => {
      state.selectedScouts = [];
    },

    bulkToggleYouth: (
      state,
      action: PayloadAction<{ userIds: number[]; toggle: boolean }>,
    ) => {
      const { toggle, userIds } = action.payload;
      if (toggle) {
        const newScouts = Array.from(
          new Set([...userIds, ...state.selectedScouts]),
        );
        state.selectedScouts = newScouts;
      } else {
        const userIdsSet = new Set(userIds);
        const newScouts = state.selectedScouts.filter(
          userId => !userIdsSet.has(userId),
        );
        state.selectedScouts = newScouts;
      }
    },

    toggleYouth: (
      state,
      action: PayloadAction<{ userId: number; toggle: boolean }>,
    ) => {
      const { toggle, userId } = action.payload;
      const newUsers = new Set(state.selectedScouts);

      if (toggle) {
        newUsers.add(userId);
      } else {
        newUsers.delete(userId);
      }

      state.selectedScouts = [...newUsers.values()];
    },

    setYouthRankVersionInfo: (state, { payload }) => {
      state.scoutsRankVersionInfo = payload;
    },

    resetQESelectData: (
      _state,
      action: PayloadAction<undefined | Partial<BulkEntryState>>,
    ) => {
      const newState = { ...initialState, ...action.payload };
      return newState;
    },

    resetBulkEntryAdventures: () => {
      const newState: BulkEntryState = {
        ...initialState,
        entryType: BulkEntryType.ADVENTURES,
      };

      return newState;
    },
    resetBulkEntryMBs: () => {
      const newState: BulkEntryState = {
        ...initialState,
        entryType: BulkEntryType.MERIT_BADGES,
      };

      return newState;
    },
    resetBulkEntryRanks: () => {
      const newState: BulkEntryState = {
        ...initialState,
        entryType: BulkEntryType.RANKS,
      };

      return newState;
    },

    setValidationResults: (
      state,
      action: PayloadAction<{
        validationResults: Record<number, QEValidationResult[]>;
      }>,
    ) => {
      const { payload } = action;
      const { validationResults } = payload;
      state.validationResults = validationResults;
      // reset scout selection
      state.selectedScouts = [];
    },

    // requirements
    setAdvancementRequirements: (
      state,
      action: PayloadAction<{ requirements: AdvancementRequirement[] }>,
    ) => {
      state.requirements = action.payload.requirements;
    },

    toggleRequirement: (state, { payload: { requirementId, toggle } }) => {
      const newReqs = new Set(state.selectedRequirements);

      if (toggle) {
        newReqs.add(requirementId);
      } else {
        newReqs.delete(requirementId);
      }

      state.selectedRequirements = [...newReqs.values()];
    },

    toggleAllRequirements: (
      state,
      action: PayloadAction<{ toggle: boolean }>,
    ) => {
      const { toggle } = action.payload;

      if (!toggle) {
        state.selectedRequirements = [];
      } else {
        state.selectedRequirements = state.requirements
          .filter(req => isUpdateableRequirement(req))
          .map(req => Number(req.id));
      }
    },

    setMarkAsApproved: (state, action: PayloadAction<{ checked: boolean }>) => {
      const { checked } = action.payload;
      state.markAsApproved = checked;
    },

    setCompletionDate: (
      state,
      action: PayloadAction<{
        date: BulkEntryState['completionDate'] | Moment;
      }>,
    ) => {
      const { date } = action.payload;
      state.completionDate = date as unknown as Date;
    },

    setComment: (state, action: PayloadAction<{ comment: string }>) => {
      state.comment = action.payload.comment;
    },

    setEntryType: (
      state,
      action: PayloadAction<{ entryType: BulkEntryState['entryType'] }>,
    ) => {
      state.entryType = action.payload.entryType;
    },
  },
});

const { reducer, actions } = quickEntryRankSlice;

export { reducer };

export const {
  // Common
  setEntryType,
  resetQESelectData,
  setCompletionDate,
  setMarkAsApproved,
  setValidationResults,
  setPreselectedInfo,
  applyAdvancementSelection,
  clearAdvancementSelection,
  setComment,
  // Youth
  toggleYouth,
  bulkToggleYouth,
  deselectAllScouts,
  // Requirements
  toggleAllRequirements,
  toggleRequirement,
  // Ranks
  setRankInfo,
  setAdvancementRequirements,
  setYouthRankVersionInfo,
  resetBulkEntryRanks,
  // Merit Badges
  setMeritBadgeInfo,
  setMBEntryType,
  toggleMeritBadge,
  setMeritBadgeUserRequirements,
  resetBulkEntryMBs,
  // Adventures
  setAdventureInfo,
  setAdventureUserRequirements,
  resetBulkEntryAdventures,
} = actions;

export default quickEntryRankSlice;
