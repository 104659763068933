import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { isMobileSel } from '@screen';
import { organizationGuidSel } from '@context';
import { Spin, offlineSel } from '@shared';
import { permissions, hasPermissionSel } from '@user';
import {
  generateAdvancementReportRequest,
  isEditEnabledSel,
  reportInfoSel,
  routeEditAdvancementReport,
  showPriceSel,
  setShowPrice,
  editReportLoadingSel,
  poidSel,
  generatingReportSel,
} from '../../../duck';
import AdvancementReportSummary from './AdvancementReportSummary';
import AdvancementReportTable from './AdvancementReportTable';
import AdvancementReportList from './AdvancementReportList';
import AdvancementReportButtons from './AdvancementReportButtons';
import styles from './AdvancementReportForm.less';
import { openReportWithQueryParams } from '@modules/utils/openBasicReport';
import {
  getCustomReportCode,
  getCustomReportUrl,
} from '@modules/reports/basicReports/duck/utils';
import { CustomReports } from '@modules/reports/constants';

class AdvancementReportForm extends React.PureComponent {
  state = {
    generating: false,
    poid: null,
  };

  handleToggleShowPrice = () => {
    this.props.onSetShowPrice(!this.props.showPrice);
  };

  handleSubmit = async () => {
    const { isEditEnabled, organizationGuid, showPrice, poid } = this.props;
    if (poid) {
      const queryParams = {
        organizationGuid,
        POID: await poid,
        Option: '@Price',
      };
      if (!showPrice) {
        delete queryParams.Option;
      }
      openReportWithQueryParams(
        getCustomReportUrl(
          getCustomReportCode(CustomReports.AdvancementReport),
        ),
        queryParams,
      );
    } else if (isEditEnabled) {
      this.handleGenerate();
    }
  };

  handleGenerate = () => {
    const { items, organizationGuid, showPrice } = this.props;
    this.props.onGenerateReportRequest({
      advancements: items,
      organizationGuid,
      showPrice,
    });
  };

  render() {
    const {
      canEdit,
      loading,
      items,
      onNavigateToEdit,
      isEditEnabled,
      isMobile,
      showPrice,
      generating,
      poid,
      canEditMultipleItems,
      isOffline,
    } = this.props;
    const onEdit = isEditEnabled && !poid ? onNavigateToEdit : null;

    return (
      <Spin size="large" spinning={this.props.editReportLoading}>
        <AdvancementReportSummary
          isMobile={isMobile}
          loading={loading}
          items={items}
          showPrice={showPrice}
          canEditMultipleItems={canEditMultipleItems}
          onEdit={onEdit}
          onToggle={this.handleToggleShowPrice}
        />
        {isMobile ? (
          <AdvancementReportList
            loading={loading}
            items={items}
            onEdit={onEdit}
            showPrice={showPrice}
          />
        ) : (
          <AdvancementReportTable
            loading={loading}
            items={items}
            showPrice={showPrice}
          />
        )}
        <div className={styles.generateInfo}>
          {!poid && (
            <FormattedMessage id="advancementReport.AdvancementReport.extraReportDescription" />
          )}
        </div>
        <AdvancementReportButtons
          isMobile={isMobile}
          isOffline={isOffline}
          disabled={!canEdit}
          loading={loading || generating}
          isAlreadyGenerated={!!poid}
          onSubmit={this.handleSubmit}
        />
      </Spin>
    );
  }
}

AdvancementReportForm.propTypes = {
  // provided by parent
  items: PropTypes.array.isRequired,
  canEditMultipleItems: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  // provided by component itself
  canEdit: PropTypes.bool.isRequired,
  poid: PropTypes.number,
  generating: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  editReportLoading: PropTypes.bool.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  organizationGuid: PropTypes.string.isRequired,
  reportInfo: PropTypes.object.isRequired,
  showPrice: PropTypes.bool.isRequired,
  onSetShowPrice: PropTypes.func.isRequired,
  onNavigateToEdit: PropTypes.func.isRequired,
  onGenerateReportRequest: PropTypes.func.isRequired,
};

const mapState = state => ({
  isEditEnabled: isEditEnabledSel(state),
  isMobile: isMobileSel(state),
  isOffline: offlineSel(state),
  organizationGuid: organizationGuidSel(state),
  reportInfo: reportInfoSel(state),
  showPrice: showPriceSel(state),
  editReportLoading: editReportLoadingSel(state),
  poid: poidSel(state),
  generating: generatingReportSel(state),
  canEdit: hasPermissionSel(state, permissions.EDIT_DATA),
});

const mapDispatch = dispatch => ({
  onNavigateToEdit: () => dispatch(routeEditAdvancementReport()),
  onSetShowPrice: showPrice => dispatch(setShowPrice(showPrice)),
  onGenerateReportRequest: settings =>
    dispatch(generateAdvancementReportRequest(settings)),
});

export default connect(mapState, mapDispatch)(AdvancementReportForm);
