import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from '@shared';
import { loginModalOpenSel } from '../../duck';
import SessionExpiredContent from './SessionExpiredContent';
import styles from './SessionExpiredModal.less';

class SessionExpiredModal extends React.PureComponent {
  render() {
    const { visible } = this.props;

    return (
      <Modal
        size="small"
        visible={visible}
        closable={false}
        className={styles.modalContainer}
      >
        <SessionExpiredContent />
      </Modal>
    );
  }
}

SessionExpiredModal.propTypes = {
  visible: PropTypes.bool.isRequired,
};

const mapState = state => ({
  visible: loginModalOpenSel(state),
});

export default connect(mapState)(SessionExpiredModal);
