import { API_ENV, GENERATE_RECHARTER_PDF_URLS } from '@config';
import {
  adobeSignService,
  esbApiService,
  paymentsApiService,
  personNameBuilder,
} from '@utils';

import { memberTypeIds } from '../constants';
import { calculateAge, formatAddress } from '../utils';

/**
 * @esbEndpoint GET /registrations/v2/:orgGuid/profile
 */
const getUnitProfile$ = guid =>
  esbApiService.get$(`/registrations/v2/${guid}/profile`);

/**
 * @esbEndpoint DELETE /registrations/v2/:orgGuid/batch/:batchId
 */
const deleteBatch$ = (guid, batchId) =>
  esbApiService.delete$(`/registrations/v2/${guid}/batch/${batchId}`);

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/loadRoster
 */
const initRecharterRoster$ = (guid, batchId) =>
  esbApiService.post$(`/registrations/v2/${guid}/batch/${batchId}/loadRoster`);

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/refreshRoster
 */
const refreshRecharterRoster$ = (guid, batchId) =>
  esbApiService.post$(
    `/registrations/v2/${guid}/batch/${batchId}/refreshRoster`,
  );

const mapRecharterRosterDtoToModel = dto =>
  dto.map(
    ({
      firstName,
      lastName,
      batchApplicationPersonId,
      addressLine1,
      city,
      memberId,
      dob,
      memberTypeId,
      ...item
    }) => {
      memberId = memberId ? memberId : '';
      const fullName = { firstName, lastName };
      const personId = batchApplicationPersonId;
      const address = formatAddress(addressLine1, city);
      const isAdult = memberTypeId === memberTypeIds.adult;
      const isParticipant = memberTypeId === memberTypeIds.participant;
      return {
        ...item,
        firstName,
        lastName,
        memberId,
        batchApplicationPersonId,
        personId,
        address,
        addressLine1,
        city,
        personFullName: personNameBuilder.short(fullName),
        dob,
        age: calculateAge(dob),
        memberTypeId,
        isAdult,
        isParticipant,
      };
    },
  );

/**
 * @esbEndpoint GET /registrations/v2/:orgGuid/batch/:batchId/persons
 */
const getRecharterRoster$ = (guid, batchId) =>
  esbApiService
    .get$(`/registrations/v2/${guid}/batch/${batchId}/persons`, {
      gtm: {
        label: '/registrations/v2/{guid}/batch/{batchId}/persons',
      },
    })
    .map(res => mapRecharterRosterDtoToModel(res));

const viewFutureCharter$ = guid =>
  esbApiService.get$(`/organizations/v2/${guid}/charters?status=future`);

const bulkUpdateRecharterRoster$ = (guid, batchId, payload) =>
  esbApiService.post$(
    `/registrations/v2/${guid}/batch/${batchId}/persons`,
    payload,
    {
      gtm: {
        label: '/registrations/v2/{guid}/batch/{batchId}/persons',
      },
    },
  );

const getOrgAdults$ = orgGuid =>
  esbApiService.post$(`/organizations/v2/${orgGuid}/orgAdults`, {
    includeAddressPhoneEmail: true,
    includeRegistrationDetails: false,
  });

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch
 */
const viewBatch$ = (orgGuid, body = { batchType: 1 }) =>
  esbApiService.post$(`/registrations/v2/${orgGuid}/batch`, body);

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch
 */
const getAllBatches$ = (orgGuid, body = {}) =>
  esbApiService.post$(`/registrations/v2/${orgGuid}/batch`, body);

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/persons/sendInvite
 */
const invitePersonToBatch$ = (orgGuid, batchId, personInfo) =>
  esbApiService.post$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/persons/sendInvite`,
    personInfo,
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/persons/uploadNew
 */
const addPersonToBatchAndUploadPaperApplication$ = (
  orgGuid,
  batchId,
  paperApplication,
) =>
  esbApiService.post$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/persons/uploadNew`,
    paperApplication,
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/persons/add
 */
const addExistingPersonToBatch = (orgGuid, batchId, memberInfo) =>
  esbApiService.post$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/persons/add`,
    memberInfo,
    {
      suppressErrorToasts: true,
      gtm: {
        label: '/registrations/v2/:orgGuid/batch/:batchId/persons/add',
      },
    },
  );

const getUnitPositions$ = program =>
  esbApiService.get$(`/organizations/orgTypePositions/${program}`);

const getUnitBasicRequirements$ = unitType =>
  esbApiService.get$(`/registrations/unitType/${unitType}/basicRequirements`);

const getUnitPaymentSummary$ = (orgGuid, batchId) =>
  esbApiService.get$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/feeSummary`,
    {
      gtm: {
        label: '/registrations/v2/{orgGuid}/batch/{batchId}/feeSummary',
      },
      suppressErrorToasts: true,
    },
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/validateRoster
 */
const validateRoster$ = (organizationGuid, batchId, typeId, isCouncilPaid) =>
  esbApiService.post$(
    `/registrations/v2/${organizationGuid}/batch/${+batchId}/validateRoster`,
    {
      unitType: typeId,
      isCouncilPaid,
    },
    {
      gtm: {
        label:
          '/registrations/v2/{organizationGuid}/batch/{batchId}/validateRoster',
      },
    },
  );

const createFutureCharter$ = (
  orgGuid,
  communityGuid,
  programId,
  term,
  effectiveDate,
  expiryDate,
  paymentTypeId,
) =>
  esbApiService.post$(
    `/organizations/v2/${orgGuid}/charters`,
    {
      communityGuid,
      programId,
      term,
      effectiveDt: effectiveDate,
      expiryDt: expiryDate,
      paymentTypeId,
    },
    {
      gtm: {
        label: '/organizations/v2/{orgGuid}/charters',
      },
    },
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/addBatch
 */
const addBatch$ = (orgGuid, charterId, batchApplicationTypeId) =>
  esbApiService.post$(
    `/registrations/v2/${orgGuid}/addBatch`,
    {
      charterId,
      batchApplicationTypeId,
    },
    {
      gtm: {
        label: '/registrations/v2/{orgGuid}/addBatch',
      },
    },
  );

const getAdjustedOLRSettings$ = organizationGuid =>
  esbApiService.get$(`/organizations/${organizationGuid}/adjustedOLRSettings`, {
    gtm: {
      label: '/organizations/{organizationGuid}/adjustedOLRSettings',
    },
  });

const getMerchantByCouncilNum$ = councilNum =>
  paymentsApiService.post$(
    '/payment/getMerchantByCouncilNum',
    { council_num: councilNum },
    { gtm: { label: '/payment/getMerchantByCouncilNum' } },
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/processPayment
 */
const processRecharterPayment$ = ({ organizationGuid, batchId, payload }) =>
  esbApiService.post$(
    `/registrations/v2/${organizationGuid}/batch/${batchId}/processPayment`,
    payload,
    {
      gtm: {
        label:
          '/registrations/v2/${organizationGuid}/batch/{batchId}/processPayment',
      },
      suppressErrorToasts: true,
    },
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/verifyACHPayment
 */
const verifyACHPayment$ = ({ orgGuid, batchId, payload }) =>
  esbApiService.post$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/verifyACHPayment`,
    payload,
  );

/**
 * @esbEndpoint POST /registrations/v2/:orgGuid/batch/:batchId/persons/sendReminder
 */
const sendReminders$ = ({ organizationGuid, batchId, payload }) =>
  esbApiService.post$(
    `/registrations/v2/${organizationGuid}/batch/${batchId}/persons/sendReminder`,
    payload,
    {
      gtm: {
        label:
          '/registrations/v2/{organizationGuid}/batch/{batchId}/persons/sendReminder',
      },
    },
  );

const uploadDocument$ = (orgGuid, batchId, batchPersonId, payload) =>
  esbApiService.post$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/persons/${batchPersonId}/uploadCBCAuth`,
    payload,
    {
      gtm: {
        label:
          '/registrations/v2/{orgGuid}/batch/{batchId}/persons/{batchPersonId}/uploadCBCAuth',
      },
      suppressErrorToasts: true,
    },
  );

const getCouncils$ = () =>
  esbApiService.get$('/organizations/councils?sortOrder=nameAsc', {
    gtm: { label: '/organizations/councils' },
  });

const getCounciUnits$ = (payload, unitStatusParam = undefined) =>
  esbApiService.post$(
    `/organizations/units/search${
      unitStatusParam ? `?unitStatus=${unitStatusParam}` : ''
    }`,
    payload,
    {
      gtm: { label: '/organizations/units/search' },
    },
  );

const generateRecharterPDF$ = fileInfo =>
  adobeSignService.post$(GENERATE_RECHARTER_PDF_URLS[API_ENV], fileInfo, {
    useSessionToken: true,
    responseType: 'arraybuffer',
  });

const getAdobeSignBaseUris$ = () =>
  adobeSignService.get$('https://api.adobesign.com/api/rest/v6/baseUris');

const createTransientDocument$ = (baseUri, body) =>
  adobeSignService.post$(`${baseUri}api/rest/v6/transientDocuments`, body);

const createAgreement$ = (baseUri, body, options) =>
  adobeSignService.post$(`${baseUri}api/rest/v6/agreements`, body, options);

const submitRecharter$ = (organizationGuid, batchId) =>
  esbApiService.post$(
    `/registrations/v2/${organizationGuid}/batch/${batchId}/submit`,
    {
      gtm: {
        label: '/registrations/v2/{organizationGuid}/batch/{batchId}/submit',
      },
    },
  );

const hardDeletePendingPerson$ = (batchApplicationPersonId, orgGuid, batchId) =>
  esbApiService.delete$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/persons/${batchApplicationPersonId}`,
    {
      gtm: {
        label:
          '/registrations/v2/{organizationGuid}/batch/{batchId}/persons/{batchPersonId}',
      },
    },
  );

/** Response:
 * { "agreementUri": string }
 */
const getPaperworkLink$ = (orgGuid, batchId) =>
  esbApiService.get$(
    `/registrations/v2/${orgGuid}/batch/${batchId}/agreementUri`,
    {
      gtm: {
        label: '/registrations/v2/{orgGuid}/batch/{batchId}/agreementUri',
      },
      // agreement could have not been signed yet
      suppressErrorToasts: true,
    },
  );

export default {
  getUnitProfile$,
  deleteBatch$,
  viewFutureCharter$,
  getRecharterRoster$,
  bulkUpdateRecharterRoster$,
  viewBatch$,
  getAllBatches$,
  invitePersonToBatch$,
  addPersonToBatchAndUploadPaperApplication$,
  addExistingPersonToBatch,
  getUnitPositions$,
  getUnitBasicRequirements$,
  getUnitPaymentSummary$,
  validateRoster$,
  createFutureCharter$,
  addBatch$,
  initRecharterRoster$,
  refreshRecharterRoster$,
  getAdjustedOLRSettings$,
  getMerchantByCouncilNum$,
  processRecharterPayment$,
  verifyACHPayment$,
  sendReminders$,
  uploadDocument$,
  getCouncils$,
  getCounciUnits$,
  generateRecharterPDF$,
  getAdobeSignBaseUris$,
  createTransientDocument$,
  createAgreement$,
  submitRecharter$,
  hardDeletePendingPerson$,
  getOrgAdults$,
  getPaperworkLink$,
};
