import React from 'react';

import InfoIcon from '@material-ui/icons/Info';
import cn from 'classnames';
import ContentPasteIcon from 'material-ui-icons/ContentPaste';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { organizationGuidSel, unitTypeIdSel } from '@context';
import { reportCodes } from '@modules/reports/basicReports/constants';
import { allReportsSel } from '@modules/reports/basicReports/duck/selectors';
import {
  getCustomReportCode,
  getCustomReportUrl,
} from '@modules/reports/basicReports/duck/utils';
import {
  CustomReports,
  MAX_USERS_SELECT_ACTIVITIES_REPORT,
} from '@modules/reports/constants';
import { ROUTE_REPORTS } from '@modules/shared';
import { openReportWithQueryParams } from '@modules/utils/openBasicReport';
import { OfflineTooltip } from '@offline';
import {
  Badge,
  Button,
  Dropdown,
  ExpandToggle,
  Menu,
  Tooltip,
} from '@shared/components';
import { isScoutbookRoleSel } from '@shared/duck/selectors/general.selectors';
import { canRecordProgressForSelectedItemsSel } from '@shared/duck/selectors/roster.selectors';

import { openAdvancementReportModal } from '../../../../advancementReport';
import { openReportParamsModal, reportsSel } from '../../../../reports';
import { reportTypes } from '../../../constants';
import { openUnitReportModal, setUnitRosterReportLoading } from '../../actions';
import {
  allNotAwardedAdvancementsCountSel,
  notAwardedAdvancementsCountSel,
  reportsSelectedItemsSel,
  selectedYouthSel,
} from '../../selectors';
import styles from './RunReportDropdown.less';

const advancementReportSelectedPeople = 'advancementReportSelectedPeople';
const navigateToReports = 'navigateToReports';
const basicReport = 'basicReport';

const MenuItem = Menu.Item;
const MenuDivider = Menu.Divider;

class RunReportDropdown extends React.PureComponent {
  state = {
    visible: false,
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  handleMenuClick = async ({ key }) => {
    this.setState({ visible: false });
    const {
      organizationGuid,
      programId,
      selectedItems,
      showAllUsers,
      allReports,
    } = this.props;

    const selectedPersonsGuidListParam = selectedItems
      .map(
        (
          /** @type {import('@modules/advancement/duck/types').RosterScout} */
          item,
        ) => item.personGuid,
      )
      .filter(Boolean)
      .join(',');

    switch (key) {
      case CustomReports.AdvancementReport: {
        return this.props.onOpenAdvancementReportModal({
          showAllUsers,
        });
      }
      case CustomReports.AdvancementHistory: {
        return this.props.onOpenReportModal({
          organizationGuid,
          users: selectedItems,
          reportType: CustomReports.AdvancementHistory,
          reportCode: reportCodes[CustomReports.AdvancementHistory],
        });
      }
      case CustomReports.ActivitiesSummary: {
        const report = allReports.find(
          report => report.reportCode === reportCodes.ACTIVITIES_SUMMARY,
        );
        // See SBL-4493
        return this.props.onOpenReportModal({
          organizationGuid,
          users: selectedItems,
          reportType: reportTypes.ACTIVITIES_SUMMARY,
          report,
        });
      }
      case CustomReports.UnitRoster: {
        this.props.onSetUnitRosterReportLoading(true);
        openReportWithQueryParams(
          getCustomReportUrl(getCustomReportCode(CustomReports.UnitRoster)),
          {
            organizationGuid,
            ProgramID: programId,
            PersonGUIDList: selectedPersonsGuidListParam,
          },
        );
        this.props.onSetUnitRosterReportLoading(false);
        return;
      }
      case navigateToReports: {
        return this.props.onNavigateToReports();
      }
      case advancementReportSelectedPeople: {
        return;
      }

      default: {
        if (key.includes(basicReport)) {
          const reportCode = key.split('_')[1];
          this.props.onOpenUnitReportModal(reportCode);
          return;
        }
        throw new Error(`Unknown report type: ${key}`);
      }
    }
  };

  renderUnitRepors = memoize((unitReports, hasSelectedYouth) =>
    unitReports.map(({ ReportName, reportCode }) => [
      <MenuDivider key={`div_${reportCode}`} />,
      <MenuItem
        key={`${basicReport}_${reportCode}`}
        disabled={!hasSelectedYouth}
      >
        {ReportName}{' '}
        {!hasSelectedYouth && (
          <FormattedMessage id="packRoster.RunReportDropdown.selectYouth" />
        )}
      </MenuItem>,
    ]),
  );

  render() {
    const {
      disabled,
      notAwardedAdvancementsCount,
      allNotAwardedAdvancementsCount,
      selectedItems,
      canRecordAdvancement,
      isScoutbookRole,
    } = this.props;
    const { visible } = this.state;
    const selectedCount = selectedItems.length;
    const hasSelectedPeople = selectedCount > 0;
    const readyToBeAwardedAdvancements = hasSelectedPeople
      ? notAwardedAdvancementsCount
      : allNotAwardedAdvancementsCount;

    const shouldDisableActivitiesReport =
      selectedCount > MAX_USERS_SELECT_ACTIVITIES_REPORT;

    const buttons = (
      <Menu onClick={this.handleMenuClick}>
        {!!hasSelectedPeople && [
          // Menu items don't work properly inside React.Fragments
          // https://github.com/ant-design/ant-design/issues/10688
          <MenuItem
            key={advancementReportSelectedPeople}
            className={styles.selectedPeopleMenuItem}
          >
            <span className={styles.selectedPeople}>
              <InfoIcon className={styles.infoIcon} />
              <FormattedMessage id="packRoster.RunReportDropdown.selectedPeople" />
            </span>
          </MenuItem>,
          <MenuDivider key="div1" />,
        ]}
        {!!(!isScoutbookRole || canRecordAdvancement) && [
          <MenuItem
            key={CustomReports.AdvancementReport}
            id="qa_advancementReport"
            className={styles.advancementReport}
          >
            <FormattedMessage id="packRoster.RunReportDropdown.scoutShopReport" />
            <Badge
              count={readyToBeAwardedAdvancements}
              className={styles.notAwardedBadge}
            />
          </MenuItem>,
          <MenuDivider key="divider-reports" />,
        ]}
        <MenuItem
          key={CustomReports.AdvancementHistory}
          id="qa_advancementSummary"
        >
          <FormattedMessage id="packRoster.RunReportDropdown.advancementHistory" />
        </MenuItem>
        <MenuDivider />
        {[
          <MenuItem
            disabled={shouldDisableActivitiesReport}
            key={CustomReports.ActivitiesSummary}
            id="qa_activitiesSummary"
          >
            <div className={styles.dropdownMenuItem}>
              <FormattedMessage id="packRoster.RunReportDropdown.activitiesSummary" />
              {!!shouldDisableActivitiesReport && (
                <Tooltip
                  title={
                    <FormattedMessage
                      id="packRoster.RunReportDropdown.activitiesSummary.selectionDisabled"
                      defaultMessage="Disabled when you select more than 25 items"
                    />
                  }
                >
                  <InfoIcon
                    width={'10px'}
                    className={styles.dropdownMenuItemIcon}
                  />
                </Tooltip>
              )}
            </div>
          </MenuItem>,
          <MenuDivider key="div3" />,
        ]}
        <MenuItem key={CustomReports.UnitRoster} id="qa_unitRoster">
          <FormattedMessage id="packRoster.RunReportDropdown.unitRoster" />
        </MenuItem>
        {[
          ...this.renderUnitRepors(
            this.props.unitReports,
            this.props.selectedYouthCount > 0,
          ),
          <MenuDivider key="div2" />,
          <MenuItem key={navigateToReports}>
            <FormattedMessage id="packRoster.RunReportDropdown.more" />
          </MenuItem>,
        ]}
      </Menu>
    );

    return (
      <OfflineTooltip placement="top">
        <Dropdown
          overlay={buttons}
          trigger={['click']}
          placement="bottomRight"
          visible={visible}
          disabled={disabled}
          onVisibleChange={this.handleVisibleChange}
        >
          <Button
            id="qa_runReport"
            className={cn(
              'Joyride__RosterRunReportButton',
              {
                [styles.disabled]: disabled,
              },
              styles.toggleButton,
            )}
            ghost
            noBorder
            color="white"
            size="small"
            uppercase={false}
          >
            <ContentPasteIcon className={styles.reportIcon} />
            <FormattedMessage id="packRoster.RunReportDropdown.runReport" />
            <Badge
              count={readyToBeAwardedAdvancements}
              className={styles.reportBadge}
            />
            <ExpandToggle
              className={styles.arrow}
              // eslint-disable-next-line react/jsx-no-leaked-render
              expanded={visible && !disabled}
            />
          </Button>
        </Dropdown>
      </OfflineTooltip>
    );
  }
}

RunReportDropdown.propTypes = {
  // provided by parent
  disabled: PropTypes.bool.isRequired,
  showAllUsers: PropTypes.bool.isRequired,
  // provided by component itself
  programId: PropTypes.number.isRequired,
  canRecordAdvancement: PropTypes.bool,
  selectedItems: PropTypes.array.isRequired,
  selectedYouthCount: PropTypes.number.isRequired,
  notAwardedAdvancementsCount: PropTypes.number.isRequired,
  allNotAwardedAdvancementsCount: PropTypes.number.isRequired,
  organizationGuid: PropTypes.string.isRequired,
  unitReports: PropTypes.array.isRequired,
  onOpenReportModal: PropTypes.func.isRequired,
  onNavigateToReports: PropTypes.func.isRequired,
  onSetUnitRosterReportLoading: PropTypes.func.isRequired,
  onOpenAdvancementReportModal: PropTypes.func.isRequired,
  onOpenUnitReportModal: PropTypes.func.isRequired,
  isScoutbookRole: PropTypes.bool.isRequired,
  allReports: PropTypes.array.isRequired,
};

const mapState = state => ({
  organizationGuid: organizationGuidSel(state),
  programId: unitTypeIdSel(state),
  selectedItems: reportsSelectedItemsSel(state),
  selectedYouthCount: selectedYouthSel(state).length,
  notAwardedAdvancementsCount: notAwardedAdvancementsCountSel(state),
  allReports: allReportsSel(state),
  allNotAwardedAdvancementsCount: allNotAwardedAdvancementsCountSel(state),
  unitReports: reportsSel(state),
  isScoutbookRole: isScoutbookRoleSel(state),
  canRecordAdvancement: canRecordProgressForSelectedItemsSel(state),
});

const mapDispatch = dispatch => ({
  onOpenAdvancementReportModal: ({ showAllUsers }) =>
    dispatch(openAdvancementReportModal({ showAllUsers })),
  onOpenReportModal: payload => dispatch(openReportParamsModal(payload)),
  onNavigateToReports: () => dispatch({ type: ROUTE_REPORTS }),
  onSetUnitRosterReportLoading: isLoading =>
    dispatch(setUnitRosterReportLoading(isLoading)),
  onOpenUnitReportModal: reportCode =>
    dispatch(openUnitReportModal(reportCode)),
});

export default connect(mapState, mapDispatch)(RunReportDropdown);
