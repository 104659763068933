export const STATIC_FLAGS = {
  SBL_5102_MY_SCOUT: {
    description:
      'Used on optimization changes to use /myScout data instead of fetching person profiles',
  },
  SBL_3435_SHOW_BULK_ENTRY_MB: {
    description: 'Used to enable bulk entry of MBs on the /roster page',
  },
  SBL_4358_SHOW_BULK_ENTRY_ADVENTURE: {
    description: 'Used to enable bulk entry of adventures on the /roster page',
  },
  MAINTENANCE_MESSAGE: {
    description: 'Maintenance message on the login page',
  },
  MAINTENANCE_END_DATE: {
    description: 'Maintenance message on the login page',
  },
  SHOW_YOUTH_ADV_REQUIREMENTS: {
    description:
      'Allows the user to go to the rank/MB/adventure/etc. details and complete requirements',
  },
  SBL_5198_CUB_PROFILE_VIEWMORE: {
    description:
      'Allows CUB program users to see advancements and requirements. This overrides SHOW_YOUTH_ADV_REQUIREMENTS',
  },
  SBL_4848_PENDING_LEADERSHIP_POSITIONS: {
    description: 'Enables Leadership Positions on the Pending Items tabs',
  },
  SBL_5138_OPTIMIZE_CALENDAR_EVENTS: {
    description:
      'Enables optimization on fetching calendar events using RTK Query',
  },
  SBL_5154_UNIT_SEARCH_UPDATE: {
    description: 'Removes fetching all units',
  },
  SBL_5372_NEW_ADV_HIST_REPORT: {
    description: 'New way to call advancements history report from roster',
  },
  SBL_4463_ADVANCEMENTS_TAB: {
    description: 'This will show the Advancements Tab on the /roster page',
  },
  SBL_4724_PENDING_REQUIREMENTS: {
    description: 'This will show the Pending Items Tab on the /roster page',
  },
  SBL_5399_SKIP_UNSTARTED_REQUIREMENTS: {
    description: 'This will skip unstarted advancements request',
  },
  SBL_5150_DASHBOARD_API: {
    description: 'Use new API to get pending advancements stats',
  },
  SBL_4688_ADD_INDIVIDUAL: {
    description: 'Add Den Chief button on the Roster table head',
  },
};

// Env vars hidden from feature flag manager
export const OMIT_REGEX = [/ADOBE.*/, /WEPAY.*/, /_DEV$/, /API.*/, /NODE.*/];
export const OMIT_SET = new Set([
  'ENABLE_MSW',
  'APP_VERSION',
  'COMMITHASH',
  'ENV',
  'DEV_WARNINGS',
  'GOOGLE_SIGN_IN_CLIENT_ID',
]);

// A random string to not give clue
export const LSTORAGE_KEY = 'ov2_VrK3';
