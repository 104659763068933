import { ProgramId } from '@shared';

import { moduleName as parentModuleName } from '../constants';

export const moduleName = 'subUnits';
export const modulePath = [parentModuleName, moduleName];

export const subUnitTypes = Object.freeze({
  PATROL: 'patrol',
  DEN: 'den',
});

export const subUnitDen = Object.freeze({
  id: 'denId',
  name: 'denType',
  number: 'denNumber',
});

export const subUnitPatrol = Object.freeze({
  id: 'patrolId',
  name: 'patrolName',
});

export const programTypeIdToSubUnitName = Object.freeze({
  [ProgramId.BOY_SCOUT]: 'patrols',
  [ProgramId.CUB_SCOUT]: 'dens',
});

export const programTypeIdTosubUnitType = Object.freeze({
  [ProgramId.BOY_SCOUT]: subUnitTypes.PATROL,
  [ProgramId.CUB_SCOUT]: subUnitTypes.DEN,
});
