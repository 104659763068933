import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Card from '../Card/Card';
import T from '../T';
import styles from './ProfileContactInfoCard.less';
import { EmailForm } from './EmailForm';
import { PhoneForm } from './PhoneForm';

const { Section: CardSection } = Card;

const ProfileContactInfoCard = ({
  form,
  profile,
  comunicationTypes,
  phoneCountries,
  phoneCarriers,
  isLoading = false,
  isUnregisteredUser = false,
  isYouth = false,
  onConfirmDeleteEmail,
  onConfirmDeletePhone,
  isReadOnly,
}) => (
  <Card margin noPadding className={styles.card} loading={isLoading}>
    <CardSection
      title={
        <T size="5" colored>
          <FormattedMessage id="shared.contactInfo.title" />
        </T>
      }
    >
      <EmailForm
        form={form}
        profile={profile}
        isUnregisteredUser={isUnregisteredUser}
        onConfirmDelete={onConfirmDeleteEmail}
        isYouth={isYouth}
        isReadOnly={isReadOnly}
      />
    </CardSection>
    <CardSection>
      <PhoneForm
        form={form}
        profile={profile}
        comunicationTypes={comunicationTypes}
        phoneCountries={phoneCountries}
        phoneCarriers={phoneCarriers}
        isUnregisteredUser={isUnregisteredUser}
        onConfirmDelete={onConfirmDeletePhone}
        isReadOnly={isReadOnly}
      />
    </CardSection>
  </Card>
);

ProfileContactInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  comunicationTypes: PropTypes.array.isRequired,
  phoneCountries: PropTypes.array.isRequired,
  phoneCarriers: PropTypes.array.isRequired,
  onConfirmDeleteEmail: PropTypes.func.isRequired,
  onConfirmDeletePhone: PropTypes.func.isRequired,
  isUnregisteredUser: PropTypes.bool,
  isYouth: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default ProfileContactInfoCard;
