import { activityTypeForActivityTypeId } from '@shared/constants';

export const moduleName = 'activities';

export const selectedActivityTypeFilters = Object.keys(
  activityTypeForActivityTypeId,
).reduce((acc, key) => ({ ...acc, [key]: true }), {});

export const PERSONAL_ACTIVITY = 'PERSONAL_ACTIVITY';

export const UNIT_ACTIVITY = 'UNIT_ACTIVITY';

export const selectedActivityFilters = Object.freeze({
  ...selectedActivityTypeFilters,
  [UNIT_ACTIVITY]: true,
  [PERSONAL_ACTIVITY]: true,
});

export const ITEMS_PER_PAGE_OPTIONS = [16, 36, 56];

/**
 * For users without any of these positions, they can only add people
 * that belong to any of their subunits to the activities.
 */
export const positionsAllowedToAddAnyPeople = [
  'Cubmaster',
  'Charter Organization Representative',
  'Council Unit Representative',
  'Committee Chairman',
  'Scoutmaster',
  'Venturing Crew Advisor',
  'Skipper',
  'Pack Admin',
  'Key 3 Delegate',
  'Chartered Organization Rep Delegate',
  'Unit Advancement Chair',
  'Troop Admin',
  'Crew Admin',
  'Ship Admin',
];
