import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { personsShape, ActivityTabs } from '../../../../common';
import { activeTabSel, setActiveTab } from '../../../duck';

const ServiceHoursTabs = ({
  form,
  persons,
  activityTypeId,
  showTabDescription,
}) => {
  const dispatch = useDispatch();

  const activeTab = useSelector(activeTabSel);

  const handleTabChange = useCallback(
    tab => {
      dispatch(setActiveTab(tab));
    },
    [dispatch],
  );

  return (
    <ActivityTabs
      form={form}
      persons={persons}
      activeTab={activeTab}
      onTabChange={handleTabChange}
      activityTypeId={activityTypeId}
      showTabDescription={showTabDescription}
    />
  );
};

ServiceHoursTabs.propTypes = {
  form: PropTypes.object.isRequired,
  showTabDescription: PropTypes.bool.isRequired,
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  persons: personsShape,
};

export default ServiceHoursTabs;
