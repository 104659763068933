import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Spin } from '@shared/components';

import {
  isCurrentRouteProtectedSel,
  loginDataInitializedSel,
  selfsessionRequest,
  userDataInitializedSel,
} from '../duck';

export function LoginDataInit(props) {
  const { children } = props;
  const dispatch = useDispatch();
  const loginDataInitialized = useSelector(loginDataInitializedSel);
  const userDataInitialized = useSelector(userDataInitializedSel);
  const isCurrentRouteProtected = useSelector(isCurrentRouteProtectedSel);
  const appBootstrapped = useSelector(state => state.core.appBootstrapped);

  const [ready, setReady] = useState(false);

  useEffect(() => {
    dispatch(selfsessionRequest());
  }, [dispatch]);

  useEffect(() => {
    if (appBootstrapped && !isCurrentRouteProtected) {
      setReady(true);
      return;
    }
    if (loginDataInitialized && userDataInitialized && !ready) {
      setReady(true);
    }
  }, [
    ready,
    loginDataInitialized,
    userDataInitialized,
    appBootstrapped,
    isCurrentRouteProtected,
  ]);

  return ready ? children : <Spin size="large" centeredInViewport />;
}

LoginDataInit.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginDataInit;
