import { configureStore as rtkConfigureStore } from '@reduxjs/toolkit';
import { Action, combineReducers } from 'redux';
import { connectRoutes } from 'redux-first-router';
import {
  ActionsObservable,
  StateObservable,
  createEpicMiddleware,
} from 'redux-observable';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { resetOnLogoutReducer } from '@utils';

import { esbApi } from '../modules/rtk/esb-api';
import rootEpics from './epics';
import gtmMiddleware from './gtmMiddleware';
import initialState from './initialState';
import reducers from './reducers';
import options from './routerOptions';
import routes from './routes';
import sentryMiddleware from './sentryMiddleware';

const isDev = process.env.NODE_ENV === 'development';
const isProd = process.env.NODE_ENV === 'production';

const epic$ = new BehaviorSubject(rootEpics);
// Every time a new epic is given to epic$ it
// will unsubscribe from the previous one then
// call and subscribe to the new one because of
// how switchMap works
const hotReloadingEpic = (
  ...args: [ActionsObservable<Action>, StateObservable<void>, unknown]
) => epic$.pipe(switchMap(epic => epic(...args))) as Observable<Action>;

const configureStoreDev = (preloadedState?: Record<string, unknown>) => {
  const {
    reducer: routerReducer,
    middleware: routerMiddleware,
    enhancer: routerEnhancer,
  } = connectRoutes(routes, options);

  const epicMiddleware = createEpicMiddleware();

  const rootReducer = combineReducers({
    ...reducers,
    location: resetOnLogoutReducer(routerReducer),
  });

  const newStore = rtkConfigureStore({
    reducer: rootReducer,
    preloadedState: preloadedState || initialState,
    enhancers: [routerEnhancer],
    devTools: isDev,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // TODO: remove "Component" prop from redux action on routes since they are getting serialized and saved in the store
        serializableCheck: false,
        // TODO: disabled due to many warnings about high time spent in the checks
        immutableCheck: false,
      }).concat(
        ...[
          isProd && sentryMiddleware,
          isProd && gtmMiddleware,
          routerMiddleware,
          epicMiddleware,
          esbApi.middleware,
        ].filter(Boolean),
      ),
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => newStore.replaceReducer(rootReducer));
  }

  epicMiddleware.run((action$, state$) => hotReloadingEpic(action$, state$, { store: newStore }));

  // see: https://redux-observable.js.org/docs/recipes/HotModuleReplacement.html
  if (module.hot) {
    module.hot.accept('./epics', () => {
      const nextRootEpic = require('./epics').default;
      epic$.next(nextRootEpic);
    });
    module.hot.accept();
  }

  return newStore;
};

const configureStore = (() => configureStoreDev)();

export type RootState = ReturnType<
  ReturnType<typeof configureStore>['getState']
>;

export default configureStore;
