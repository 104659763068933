import { ENABLE_FORUM_URL } from '@config';
import { userServices } from '@shared/duck';
import { esbApiService, http } from '@utils';

const getLoginData = () => userServices.retrieveLoginData() || {};

const enableForumFactory = disable => unitGuid => {
  const { token, sessionToken, userProfile } = getLoginData();
  const body = { token, sessionToken, userProfile, unitGuid };
  const options = {
    headers: {
      Authorization: token ? `bearer ${token}` : '',
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    gtm: {
      label: `${disable ? 'disable' : 'enable'}-discourse-forum`,
    },
  };

  const httpMethod$ = disable ? http.delete$ : http.post$;

  return httpMethod$(ENABLE_FORUM_URL, body, options).map(
    ({ response }) => response,
  );
};

const enableForum$ = enableForumFactory();
const disableForum$ = enableForumFactory(true);

/**
 * @esbEndpoint GET /lookups/advancements/unitTimezone
 */

const getTimezones$ = () =>
  esbApiService.get$('/lookups/advancements/unitTimezone', {
    gtm: {
      label: '/lookups/advancements/unitTimezone',
    },
  });

/**
 * @esbEndpoint GET /organizations/v2/:organizationGuid/profile
 */

const getUnitDetails$ = organizationGuid =>
  esbApiService.get$(`/organizations/v2/${organizationGuid}/profile`, {
    gtm: {
      label: '/organizations/v2/${organizationGuid}/profile',
    },
  });

/**
 * @esbEndpoint GET /advancements/v2/unit/:unitId
 */

const setTimezone$ = (data, unitId) =>
  esbApiService.put$(`/advancements/v2/unit/${unitId}`, data, {
    gtm: {
      label: '/advancements/v2/unit/{unitId}',
    },
  });

export default {
  enableForum$,
  disableForum$,
  getTimezones$,
  getUnitDetails$,
  setTimezone$,
};
