// expected format XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXX*1460882

const extractOrgGuidAndUserId = userAndOrgGuid => {
  let orgGuid = userAndOrgGuid;
  let userId = false;
  if (/\*/.test(userAndOrgGuid)) {
    orgGuid = userAndOrgGuid.match(/(.*)\*/)[1];
    userId = Number(userAndOrgGuid.match(/\*(.*)/)[1]);
  }
  return { orgGuid, userId };
};

export default extractOrgGuidAndUserId;
