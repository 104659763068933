import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';

import Button from '../../Button';
import CollapseDetailsCustom from '../../CollapseDetailsCustom';
import S from '../../S';
import { Col, Row } from '../../bsaComponents';
import Person from './Person';
import PersonSwitch from './PersonSwitch/PersonSwitch';

type PersonData = {
  userId: number;
  firstName: string;
  lastName: string;
  nickName: string;
  isAdult: boolean;
  isGuest: boolean;
  isLeader: boolean;
  isParent: boolean;
  isParticipant: boolean;
};
type PersonsList = {
  youths: PersonData[];
  denChiefs: PersonData[];
  adults: PersonData[];
  guests: PersonData[];
};

interface PersonListProps {
  persons: PersonsList;
  rsvpKey: string;
  onAttendedToggle: (userId: string) => void;
  isAttendedToggleLoading: boolean;
  onRemoveAction: () => void;
  onRSVPAction: () => void;
  innerExpandedKeys: string[];
  handleInnerPanelChange: (keys: string[]) => void;
  isRSVP: boolean;
  onOpenModal: () => void;
  recurringEventId: number | null;
  isCreateEvent: boolean;
  enableAttendance: boolean;
  hasPackOrDen: boolean;
  myYouthsInEvent?: { [key: string]: string };
}

const PersonListOriginal: React.FC<PersonListProps> = ({
  persons = {
    youths: [],
    denChiefs: [],
    adults: [],
    guests: [],
  },
  rsvpKey,
  onAttendedToggle = () => {},
  isAttendedToggleLoading = false,
  onRemoveAction = () => {},
  onRSVPAction = () => {},
  innerExpandedKeys,
  handleInnerPanelChange,
  isRSVP,
  onOpenModal,
  recurringEventId,
  isCreateEvent,
  enableAttendance,
  hasPackOrDen = false,
  myYouthsInEvent = {},
}) => {
  const { youths, denChiefs, adults, guests } = persons;
  const showAttendance =
    !recurringEventId && enableAttendance && !isCreateEvent;

  const collapseDetails = useCallback(
    (personsToMap: PersonData[], translationKey: string) => (
      <CollapseDetailsCustom
        header={
          <S size={'4'}>
            <FormattedMessage id={translationKey} /> ({personsToMap.length})
          </S>
        }
        onChange={handleInnerPanelChange}
        expandedKeys={innerExpandedKeys}
        panelKeyNew={
          isRSVP ? `${rsvpKey}.${translationKey}` : `${translationKey}`
        }
      >
        {personsToMap.length > 0 && showAttendance && (
          <Row type="flex" align="middle">
            <Col span={24}>
              <S>
                <FormattedMessage id="shared.switch.header" />
              </S>
            </Col>
          </Row>
        )}

        {personsToMap.map((person: PersonData, index: number) => {
          const { userId } = person;

          return (
            <Row key={`${index}-${userId}`} type="flex" align="middle">
              <Col span={showAttendance ? 18 : 24}>
                <Person
                  key={userId}
                  data={person}
                  rsvpKey={rsvpKey}
                  onRemoveAction={onRemoveAction}
                  onRSVPAction={onRSVPAction}
                  isRSVP={isRSVP}
                  isMyYouth={!!myYouthsInEvent?.[`${userId}`]}
                />
              </Col>
              {showAttendance && (
                <Col span={6}>
                  <PersonSwitch
                    key={userId}
                    person={person}
                    onAttendedToggle={onAttendedToggle}
                    isAttendedToggleLoading={isAttendedToggleLoading}
                  />
                </Col>
              )}
            </Row>
          );
        })}
        {!isRSVP && translationKey === 'shared.guests' && (
          <Row justify="center">
            <Button
              ghost
              noBorder
              color="info"
              size="small"
              uppercase={false}
              block
              style={{
                textAlign: 'center',
                textDecoration: 'underline blue',
              }}
              onClick={onOpenModal}
              disabled={!!recurringEventId}
            >
              <FormattedMessage id="shared.guests.addButton" />
            </Button>
          </Row>
        )}
      </CollapseDetailsCustom>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      onRemoveAction,
      rsvpKey,
      onAttendedToggle,
      isAttendedToggleLoading,
      onRSVPAction,
      innerExpandedKeys,
      handleInnerPanelChange,
      isRSVP,
      onOpenModal,
    ],
  );

  return (
    <React.Fragment>
      {collapseDetails(youths, 'shared.youths')}
      {hasPackOrDen && collapseDetails(denChiefs, 'shared.denChiefs')}
      {collapseDetails(adults, 'shared.adults')}
      {collapseDetails(guests, 'shared.guests')}
    </React.Fragment>
  );
};

const PersonList = React.memo(PersonListOriginal);

export default PersonList;
