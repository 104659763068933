import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { useUnitPendingLeadershipPositions } from '@modules/leadershipPositions/hooks';
import { daysLeftToSyncSel, pendingRequestsCountSel } from '@offline';

import {
  allNotAwardedAdvancementsCountSel,
  goToActivity,
  hasNotificationsToShowSel,
  openAdvancementReportModal,
  openSyncModal,
  pendingAdvancementsSel,
} from '../../duck';
import BadgeNotificationItem from './BadgeNotificationItem';
import styles from './Notifications.less';
import { NotificationsModalContentV2 } from './NotificationsContentV2';

const NotificationsContent = ({
  advancements,
  events,
  notAwardedAdvancementsCount,
  hasNotificationsToShow,
  pendingRequestsCount,
  daysLeft,
  onOpenAdvancementReportModal,
  onOpenSyncModal,
  onOpenAdvancementItem,
}) => {
  const { pendingCount } = useUnitPendingLeadershipPositions();

  if (!hasNotificationsToShow && !pendingCount) {
    return <FormattedMessage id="userNotifications.Notifications.empty" />;
  }

  const advencementsLength = advancements.length + pendingCount;
  const eventsLength = events.length;

  return (
    <div className={styles.notificationsWrapper}>
      {pendingRequestsCount > 0 && (
        <BadgeNotificationItem
          title={
            <FormattedMessage id="userNotifications.NotificationsModalContent.itemsToSync" />
          }
          description={
            <FormattedMessage
              id="userNotifications.NotificationsModalContent.syncDaysLeft"
              values={{ daysLeft }}
            />
          }
          count={pendingRequestsCount}
          onClick={onOpenSyncModal}
        />
      )}
      {advencementsLength > 0 && (
        <BadgeNotificationItem
          count={advencementsLength}
          onClick={onOpenAdvancementItem}
          title={
            <FormattedMessage id="userNotifications.Notifications.itemsToApprove" />
          }
          description={
            <FormattedMessage
              id="userNotifications.Notifications.itemsToApproveDescription"
              values={{ pendingItemsToApprove: advencementsLength }}
            />
          }
        />
      )}
      {eventsLength > 0 && (
        <BadgeNotificationItem
          count={eventsLength}
          // onClick={() => {}}
          title={
            <FormattedMessage id="userNotifications.Notifications.reminders" />
          }
          description={
            <FormattedMessage
              id="userNotifications.Notifications.remindersDescription"
              values={{ activities: eventsLength }}
            />
          }
        />
      )}
      {notAwardedAdvancementsCount > 0 && (
        <BadgeNotificationItem
          id="qa_notificationAdvReport"
          title={
            <FormattedMessage id="userNotifications.NotificationsModalContent.needsPurchasing" />
          }
          description={
            <FormattedMessage id="userNotifications.NotificationsModalContent.viewScoutShopReport" />
          }
          count={notAwardedAdvancementsCount}
          onClick={onOpenAdvancementReportModal}
        />
      )}
    </div>
  );
};

NotificationsContent.propTypes = {
  // Provided by component itself
  advancements: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
  onOpenAdvancementItem: PropTypes.func.isRequired,
  notAwardedAdvancementsCount: PropTypes.number.isRequired,
  hasNotificationsToShow: PropTypes.bool.isRequired,
  onOpenAdvancementReportModal: PropTypes.func.isRequired,
  pendingRequestsCount: PropTypes.number.isRequired,
  onOpenSyncModal: PropTypes.func.isRequired,
  daysLeft: PropTypes.number.isRequired,
};

const mapState = state => ({
  advancements: pendingAdvancementsSel(state),
  notAwardedAdvancementsCount: allNotAwardedAdvancementsCountSel(state),
  events: [],
  hasNotificationsToShow: hasNotificationsToShowSel(state),
  pendingRequestsCount: pendingRequestsCountSel(state),
  daysLeft: daysLeftToSyncSel(state),
});

const mapDispatch = dispatch => ({
  onOpenAdvancementItem: () => dispatch(goToActivity()),
  onOpenAdvancementReportModal: () => dispatch(openAdvancementReportModal()),
  onOpenSyncModal: () => dispatch(openSyncModal()),
});

const ComponentV1 = connect(mapState, mapDispatch)(NotificationsContent);

const ComponentContainer = () => {
  if (featureFlags.getFlag('SBL_5150_DASHBOARD_API')) {
    return <NotificationsModalContentV2 />;
  }
  return <ComponentV1 />;
};

export default ComponentContainer;
