import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Input } from 'bsa-ui';

import S from '../S';
import styles from './TextArea.less';

const { TextArea: BsaTextArea } = Input;

/**
 * @type {React.FC<import('./TextArea.types').TextAreaProps>}
 */
class TextArea extends React.Component {
  state = {
    value: this.props.defaultValue,
  };

  getValue = () => (this.isControlled() ? this.props.value : this.state.value);

  isControlled = () => this.props.value != null;

  handleChange = e => {
    if (!this.isControlled()) {
      this.setState({ value: e.target.value });
    }
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { charCounter, charCounterBelow, maxLength, rounded, ...rest } =
      this.props;
    const value = this.getValue();
    const charCount = (value || '').length;
    const textAreaNode = (
      <BsaTextArea
        maxLength={maxLength}
        value={value}
        onChange={this.handleChange}
        className={cn({ [styles.rounded]: rounded })}
        {...rest}
      />
    );

    if (charCounter) {
      return (
        <div className={styles.container}>
          <S size="5" className={styles.charCount}>
            {charCount}/{maxLength}
          </S>
          {textAreaNode}
        </div>
      );
    } else if (charCounterBelow) {
      return (
        <div className={styles.container}>
          {textAreaNode}
          <S size="5" className={styles.charCountBelow}>
            {charCount}/{maxLength}
          </S>
        </div>
      );
    }

    return textAreaNode;
  }
}

TextArea.propTypes = {
  ...BsaTextArea.propTypes,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  charCounter: PropTypes.bool,
  charCounterBelow: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  rounded: PropTypes.bool,
};

export default TextArea;
