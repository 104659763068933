import React from 'react';

import { Popover as BsaPopover } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Popover.less';

/**
 * @type {React.FC<import('./Popover.types').PopoverProps>}
 */
const Popover = ({
  headerColor,
  noArrow,
  noPadding,
  overlayClassName,
  ...rest
}) => {
  const overlayClassNames = cn(
    overlayClassName,
    [styles[headerColor]],
    { [styles.noArrow]: noArrow },
    { [styles.noPadding]: noPadding },
  );

  return <BsaPopover overlayClassName={overlayClassNames} {...rest} />;
};

Popover.propTypes = {
  headerColor: PropTypes.oneOf(['default', 'scouting-red']),
  noArrow: PropTypes.bool,
  noPadding: PropTypes.bool,
  overlayClassName: PropTypes.string,
};

Popover.defaultProps = {
  headerColor: 'default',
  noArrow: false,
  noPadding: false,
};

export default Popover;
