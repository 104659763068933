import { basicActionCreator } from '@modules/utils';
import { gtm } from '@shared';

import {
  loadAndOpenActivityAction,
  campoutsModuleNamespace as namespace,
} from '../../common';

export const OPEN_CAMPOUTS = `${namespace}/OPEN_CAMPOUTS`;
export const OPEN_CAMPOUTS_CREATE = `${namespace}/OPEN_CAMPOUTS_CREATE`;
export const OPEN_EDIT_CAMPOUT = `${namespace}/OPEN_EDIT_CAMPOUT`;
export const CLOSE_CAMPOUTS = `${namespace}/CLOSE_CAMPOUTS`;
export const openCampouts = (
  userIds,
  disableGetActivities = false,
  disableFutureDays = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_CAMPOUTS,
  payload: {
    userIds,
    disableGetActivities,
    disableFutureDays,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.CAMPOUTS,
  },
});
export const openCampoutsCreate = (
  dates = {},
  allowEditOrAddPersonsPersons = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_CAMPOUTS_CREATE,
  payload: {
    dates,
    allowEditOrAddPersonsPersons,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.CAMPOUTS,
  },
});
export const openEditCampout = (allowEditOrAddPersonsPersons = false) => ({
  type: OPEN_EDIT_CAMPOUT,
  payload: {
    allowEditOrAddPersonsPersons,
  },
  gtm: {
    category: gtm.categories.HIKES,
  },
});

export const closeCampouts = () => ({
  type: CLOSE_CAMPOUTS,
  gtm: {
    category: gtm.categories.CAMPOUTS,
  },
});

export const RECORD_CAMPOUTS_REQUEST = `${namespace}/RECORD_CAMPOUTS_REQUEST`;
export const RECORD_CAMPOUTS_RESPONSE = `${namespace}/RECORD_CAMPOUTS_RESPONSE`;
export const RECORD_CAMPOUTS_ERROR = `${namespace}/RECORD_CAMPOUTS_ERROR`;
export const recordCampoutsRequest = ({ activityData, closeOnSuccess }) => ({
  type: RECORD_CAMPOUTS_REQUEST,
  payload: {
    activityData,
    closeOnSuccess,
  },
  gtm: {
    category: gtm.categories.CAMPOUTS,
  },
});
export const recordCampoutsResponse = () => ({
  type: RECORD_CAMPOUTS_RESPONSE,
});
export const recordCampoutsError = err => ({
  type: RECORD_CAMPOUTS_ERROR,
  payload: err,
});

export const SET_SELECTED_CAMPOUTS = `${namespace}/SET_SELECTED_CAMPOUT`;
export const setSelectedCampout = campout => ({
  type: SET_SELECTED_CAMPOUTS,
  payload: campout,
});

export const SET_DETAIL_MODE = `${namespace}/SET_DETAIL_MODE`;
export const setDetailMode = mode => ({
  type: SET_DETAIL_MODE,
  payload: mode,
});

export const SET_ACTIVE_TAB = `${namespace}/SET_ACTIVE_TAB`;
export const setActiveTab = tab => ({ type: SET_ACTIVE_TAB, payload: tab });

export const LOAD_AND_OPEN_CAMPOUT_MODAL = `${namespace}/LOAD_AND_OPEN_CAMPOUT_MODAL`;
export const loadAndOpenCampoutModal = loadAndOpenActivityAction(
  LOAD_AND_OPEN_CAMPOUT_MODAL,
);

export const LOAD_AND_OPEN_CAMPOUT_MODAL_ERROR = `${namespace}/LOAD_AND_OPEN_CAMPOUT_MODAL_ERROR`;
export const loadAndOpenCampoutModalError = err => ({
  type: LOAD_AND_OPEN_CAMPOUT_MODAL_ERROR,
  payload: err,
});

// CAMPOUTS MODAL
export const OPEN_CAMPOUTS_MODAL_RESTORE = `${namespace}/OPEN_CAMPOUTS_MODAL_RESTORE`;
export const openCampoutsModalRestore = () =>
  basicActionCreator(OPEN_CAMPOUTS_MODAL_RESTORE);

export const CLOSE_CAMPOUTS_MODAL_RESTORE = `${namespace}/CLOSE_CAMPOUTS_MODAL_RESTORE`;
export const closeCampoutsModalRestore = () =>
  basicActionCreator(CLOSE_CAMPOUTS_MODAL_RESTORE);
