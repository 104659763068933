import { combineReducers } from 'redux';

import {
  DICTIONARIES_REQUEST,
  DICTIONARIES_RESPONSE,
  DICTIONARIES_ERROR,
} from './actions';

const loading = (state = {}, { type, payload }) => {
  switch (type) {
    case DICTIONARIES_REQUEST: {
      const started = payload.reduce((acc, id) => ({ ...acc, [id]: true }), {});
      return {
        ...state,
        ...started,
      };
    }
    case DICTIONARIES_RESPONSE: {
      const finished = Object.keys(payload).reduce(
        (acc, id) => ({
          ...acc,
          [id]: false,
        }),
        {},
      );
      return {
        ...state,
        ...finished,
      };
    }
    case DICTIONARIES_ERROR: {
      const { ids = [] } = payload;
      const finished = ids.reduce((acc, id) => ({ ...acc, [id]: false }), {});
      return {
        ...state,
        ...finished,
      };
    }
    default: {
      return state;
    }
  }
};

const data = (state = {}, { type, payload }) => {
  switch (type) {
    case DICTIONARIES_RESPONSE: {
      return {
        ...state,
        ...payload,
      };
    }
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  loading,
  data,
});

export default reducers;
