import { gtm } from '@shared';

import {
  loadAndOpenActivityAction,
  hikesModuleNamespace as namespace,
} from '../../common';

export const OPEN_CREATE_HIKE = `${namespace}/OPEN_CREATE_HIKE`;
export const OPEN_RECORD_MILES = `${namespace}/OPEN_RECORD_MILES`;
export const OPEN_EDIT_HIKE = `${namespace}/OPEN_EDIT_HIKE`;
export const openCreateHike = (
  dates = {},
  allowEditOrAddPersonsPersons = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_CREATE_HIKE,
  payload: {
    dates,
    allowEditOrAddPersonsPersons,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.HIKES,
  },
});
export const openRecordMiles = (
  userIds,
  disableGetActivities = false,
  disableFutureDays = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_RECORD_MILES,
  payload: {
    userIds,
    disableGetActivities,
    disableFutureDays,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.HIKES,
  },
});
export const openEditHike = (allowEditOrAddPersonsPersons = false) => ({
  type: OPEN_EDIT_HIKE,
  payload: {
    allowEditOrAddPersonsPersons,
  },
  gtm: {
    category: gtm.categories.HIKES,
  },
});

export const CLOSE_HIKE = `${namespace}/CLOSE_HIKE`;
export const closeHike = () => ({
  type: CLOSE_HIKE,
  gtm: {
    category: gtm.categories.HIKES,
  },
});

export const SET_SELECTED_HIKE = `${namespace}/SET_SELECTED_HIKE`;
export const setSelectedHike = hike => ({
  type: SET_SELECTED_HIKE,
  payload: hike,
});

export const SET_DETAIL_MODE = `${namespace}/SET_DETAIL_MODE`;
export const setDetailMode = mode => ({ type: SET_DETAIL_MODE, payload: mode });

export const SET_ACTIVE_TAB = `${namespace}/SET_ACTIVE_TAB`;
export const setActiveTab = tab => ({ type: SET_ACTIVE_TAB, payload: tab });

export const RECORD_HIKE_REQUEST = `${namespace}/RECORD_HIKE_REQUEST`;
export const RECORD_HIKE_RESPONSE = `${namespace}/RECORD_HIKE_RESPONSE`;
export const RECORD_HIKE_ERROR = `${namespace}/RECORD_HIKE_ERROR`;
export const recordHikeRequest = ({ activityData, closeOnSuccess }) => ({
  type: RECORD_HIKE_REQUEST,
  payload: {
    activityData,
    closeOnSuccess,
  },
  gtm: {
    category: gtm.categories.HIKES,
  },
});
export const recordHikeResponse = () => ({ type: RECORD_HIKE_RESPONSE });
export const recordHikeError = err => ({
  type: RECORD_HIKE_ERROR,
  payload: err,
});

export const LOAD_AND_OPEN_HIKE_MODAL = `${namespace}/LOAD_AND_OPEN_HIKE_MODAL`;
export const loadAndOpenHikeModal = loadAndOpenActivityAction(
  LOAD_AND_OPEN_HIKE_MODAL,
);

export const LOAD_AND_OPEN_HIKE_MODAL_ERROR = `${namespace}/LOAD_AND_OPEN_HIKE_MODAL_ERROR`;
export const loadAndOpenHikeModalError = err => ({
  type: LOAD_AND_OPEN_HIKE_MODAL_ERROR,
  payload: err,
});
