import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const rawBaseQuery = (baseUrl: string, ...args: unknown[]) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, api) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const jwt = (api.getState() as any).user?.loginData?.token;
      if (jwt) {
        headers.set('Authorization', `Bearer ${jwt}`);
      }
      return headers;
    },
    ...args,
  });

export const dynamicBaseQuery =
  (
    getBaseUrl: (...args: BaseQueryFn['arguments']) => string,
  ): ReturnType<typeof fetchBaseQuery> =>
  async (args, api, extraOptions) => {
    const baseUrl = getBaseUrl(args, api, extraOptions);

    if (!baseUrl) {
      return {
        error: {
          status: 400,
          statusText: 'Bad Request',
          data: `No baseUrl found ${baseUrl}`,
        },
      };
    }

    return rawBaseQuery(baseUrl)(args, api, extraOptions);
  };
