import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { SBL_4797_SHOW_RENEWAL_ROSTER } from '@config';
import { programSel } from '@context/duck/selectorsTyped';
import { Checkbox, Program, S } from '@shared';

import {
  peopleFilters,
  programGrownUpAges,
  renewalStatuses,
} from '../../../../constants';
import styles from '../PackRosterFilterCard.less';

class FilterSection extends React.PureComponent {
  handleChange = e => {
    const { name } = e.target;
    const { values, onChange } = this.props;
    const newValues = {
      ...values,
      [name]: !values[name],
    };

    onChange(newValues);
  };

  render() {
    const { values, program } = this.props;

    return (
      <div className={styles.rosterFiltersContainer}>
        <div>
          <S size="3" colored>
            <FormattedMessage id="packRoster.PackRosterFilterCard.type" />
          </S>
          <Checkbox
            name={peopleFilters.YOUTH}
            checked={values[peopleFilters.YOUTH]}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage id="advancement.PackRoster.showYouth" />
            </S>
          </Checkbox>
          {program !== Program.CUB_SCOUT && (
            <Checkbox
              name={peopleFilters.AGED_YOUTH}
              checked={values[peopleFilters.AGED_YOUTH]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                <FormattedMessage
                  id="advancement.PackRoster.showAgedYouth"
                  values={{ age: programGrownUpAges[program] }}
                />
              </S>
            </Checkbox>
          )}
          <Checkbox
            name={peopleFilters.ADULTS}
            checked={values[peopleFilters.ADULTS]}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage id="advancement.PackRoster.showAdults" />
            </S>
          </Checkbox>
          {program == Program.CUB_SCOUT && (
            <Checkbox
              name={peopleFilters.DEN_CHIEFS}
              checked={values[peopleFilters.DEN_CHIEFS]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                <FormattedMessage id="advancement.PackRoster.showDenChiefs" />
              </S>
            </Checkbox>
          )}
        </div>
        {!!SBL_4797_SHOW_RENEWAL_ROSTER && (
          <div>
            <S size="3" colored>
              <FormattedMessage id="packRoster.PackRosterFilterCard.renewalStatus" />
            </S>
            <Checkbox
              name={renewalStatuses.OPTED_OUT}
              checked={values[renewalStatuses.OPTED_OUT]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                {renewalStatuses.OPTED_OUT}
              </S>
            </Checkbox>
            <Checkbox
              name={renewalStatuses.RENEWED}
              checked={values[renewalStatuses.RENEWED]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                {renewalStatuses.RENEWED}
              </S>
            </Checkbox>
            <Checkbox
              name={renewalStatuses.EXPIRED}
              checked={values[renewalStatuses.EXPIRED]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                {renewalStatuses.EXPIRED}
              </S>
            </Checkbox>
            <Checkbox
              name={renewalStatuses.ELIGIBLE_TO_RENEW}
              checked={values[renewalStatuses.ELIGIBLE_TO_RENEW]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                {renewalStatuses.ELIGIBLE_TO_RENEW}
              </S>
            </Checkbox>
            <Checkbox
              name={renewalStatuses.CURRENT}
              checked={values[renewalStatuses.CURRENT]}
              onChange={this.handleChange}
            >
              <S size="4" colored inline>
                {renewalStatuses.CURRENT}
              </S>
            </Checkbox>
          </div>
        )}
      </div>
    );
  }
}

FilterSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  // Provided by component itself
  program: PropTypes.string.isRequired,
};

const mapState = state => ({
  program: programSel(state),
});

export default connect(mapState)(FilterSection);
