import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ExpandIcon from '@material-ui/icons/ExpandMore';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import RsvpAttendingIcon from '@assets/images/RsvpAttendingIcon.svg';
import RsvpMaybeIcon from '@assets/images/RsvpMaybeIcon.svg';
import RsvpNotAttendingIcon from '@assets/images/RsvpNotAttendingIcon.svg';
import { Button, Dropdown, Menu } from '@shared';

import { attendeesTypes } from '../../../constants';
import { isVisiblePopoverSel } from '../../../duck/selectors';
import styles from './RsvpDropdownButton.less';

const MenuItem = Menu.Item;

const icons = Object.freeze({
  [attendeesTypes.ATTENDING]: (
    <RsvpAttendingIcon className={styles.attendingIcon} />
  ),
  [attendeesTypes.NOT_ATTENDING]: (
    <RsvpNotAttendingIcon className={styles.notAttendingIcon} />
  ),
  [attendeesTypes.MAYBE]: <RsvpMaybeIcon className={styles.maybeIcon} />,
});

const largeStyles = Object.freeze({
  [attendeesTypes.NOT_ATTENDING]: styles.notAttendingLargeButton,
  [attendeesTypes.ATTENDING]: styles.attendingLargeButton,
  [attendeesTypes.MAYBE]: styles.maybeLargeButton,
});

const RsvpDropdownButton = ({
  onClick,
  attendeeType,
  large,
  disabled,
  offline,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const isVisiblePopover = useSelector(isVisiblePopoverSel);

  useEffect(() => {
    if (!isVisiblePopover) {
      setVisible(isVisiblePopover);
    }
  }, [isVisiblePopover]);

  const getLargeStyle = useCallback(
    attendeeType => {
      const largeStyle = largeStyles[attendeeType];
      return cn(styles.rsvpDropdownButton, {
        [styles.largeButton]: large,
        [largeStyle]: large,
      });
    },
    [large],
  );

  const handleButtonClick = useCallback(
    key => event => {
      event.stopPropagation();
      setVisible(false);
      onClick && onClick(attendeesTypes[key]);
    },
    [onClick, setVisible],
  );

  const attendingButtons = useMemo(
    () =>
      Object.keys(attendeesTypes)
        .filter(key => attendeesTypes[key] !== attendeeType)
        .map(key => (
          <MenuItem key={attendeesTypes[key]} className={styles.menuItemButton}>
            <Button
              className={styles.dropDownButton}
              noBorder
              uppercase={false}
              fitText
              ghost
              icon={icons[attendeesTypes[key]]}
              onClick={handleButtonClick(key)}
            >
              <FormattedMessage
                id={`events.rsvpEventButton.${attendeesTypes[key]}`}
              />
            </Button>
          </MenuItem>
        )),
    [attendeeType, handleButtonClick],
  );

  return (
    <Dropdown
      overlay={<Menu>{attendingButtons}</Menu>}
      trigger={['click']}
      placement="bottomRight"
      visible={visible}
      disabled={disabled || offline}
      onVisibleChange={visible => setVisible(visible)}
    >
      {children || (
        <Button
          className={getLargeStyle(attendeeType)}
          noBorder={!large}
          uppercase={large}
          fitText
          disabled={disabled || offline}
          icon={icons[attendeeType]}
        >
          <FormattedMessage id={`events.rsvpEventButton.${attendeeType}`} />
          <ExpandIcon />
        </Button>
      )}
    </Dropdown>
  );
};

RsvpDropdownButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  attendeeType: PropTypes.string.isRequired,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  offline: PropTypes.bool,
  children: PropTypes.node,
};

RsvpDropdownButton.defaultProps = {
  offline: false,
};

export default RsvpDropdownButton;
