import * as Sentry from '@sentry/react';

import createSentryMiddleware from 'redux-sentry-middleware';

import { organizationGuidSel } from '../modules/context';
import { loginDataSel, masqueradingSel } from '../modules/user';

export default createSentryMiddleware(Sentry, {
  getUserContext: state => {
    const loginData = loginDataSel(state);
    if (loginData) {
      const organizationGuid = organizationGuidSel(state);
      const masquerading = masqueradingSel(state);
      const { account = {}, membership = {} } = loginData;
      const { username, userId } = account;
      const { memberId } = membership;
      const { personGuid } = loginData;

      return {
        username,
        userId,
        memberId,
        personGuid,
        organizationGuid,
        masquerading,
      };
    }
  },
  stateTransformer: ({ context }) => ({ ...context }),
  actionTransformer: (action) => ({ type: action?.type }),
});
