import { sorters } from '@utils';

const unitTypeSorter = sorters.text('unitType');
const unitNumberSorter = sorters.number('unitNumber');
const charteredOrganizationSorter = sorters.text('charteredOrganizationName');
// Recent units
const searchDateSorter = sorters.date('searchDate', true);

export const councilUnitsSorters = {
  unitType: unitTypeSorter,
  unitNumber: unitNumberSorter,
  charteredOrganizationName: charteredOrganizationSorter,
  default: unitNumberSorter,
};

export const recentUnitsSorters = {
  unitType: unitTypeSorter,
  unitNumber: unitNumberSorter,
  charteredOrganizationName: charteredOrganizationSorter,
  searchDate: searchDateSorter,
  default: searchDateSorter,
};
