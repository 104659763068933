import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cn from 'classnames';

import styles from './ExpandToggle.less';

class ExpandToggle extends React.PureComponent {
  state = {
    expanded: this.props.defaultExpanded || false,
  };

  handleClick = e => {
    const { onClick, onChange } = this.props;

    if (onClick) {
      onClick(e);
    }
    if (onChange) {
      onChange(!this.isExpanded());
    }
    if (!this.isControlled()) {
      this.setState(({ expanded }) => ({ expanded: !expanded }));
    }
  };

  isExpanded = () => {
    const { expanded } = this.props;
    const { expanded: stateExpanded } = this.state;
    return this.isControlled() ? expanded : stateExpanded;
  };

  isControlled = () => this.props.expanded != null;

  render() {
    const { className } = this.props;
    const classNames = cn(
      styles.icon,
      { [styles.expanded]: this.isExpanded() },
      className,
    );

    return <ExpandMoreIcon className={classNames} onClick={this.handleClick} />;
  }
}

ExpandToggle.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  defaultExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default ExpandToggle;
