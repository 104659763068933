import React from 'react';
import PropTypes from 'prop-types';
import { Card as BsaCard } from 'bsa-ui';
import cn from 'classnames';

import CardSection from './CardSection';
import styles from './Card.less';

class Card extends React.PureComponent {
  render() {
    const { margin, rounded, bordered, hoverable, className, ...rest } =
      this.props;
    const classNames = cn(
      styles.card,
      {
        [styles.margin]: margin,
        [styles.rounded]: rounded,
      },
      className,
    );

    return (
      <BsaCard
        className={classNames}
        bordered={bordered}
        hoverable={hoverable}
        {...rest}
      />
    );
  }
}

Card.propTypes = {
  margin: PropTypes.bool,
  rounded: PropTypes.bool,
  bordered: PropTypes.bool,
  hoverable: PropTypes.bool,
  className: PropTypes.string,
};

Card.defaultProps = {
  margin: false,
  rounded: false,
  bordered: false,
  hoverable: false,
};

Card.Section = CardSection;

export default Card;
