import React, { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from '@shared';

import styles from './ClearPersonsConfirmationModal.less';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  isVisible: boolean;
};

const ClearPersonsConfirmationModal: React.FC<Props> = ({
  isVisible,
  onConfirm,
  onCancel,
  ...props
}) => {
  const primaryButtonProps = useMemo(
    () => ({
      id: 'clearPersonsConfirmationModal',
      color: 'scouting-red',
      disabled: false,
    }),
    [],
  );

  return (
    <ConfirmationModal
      visible={isVisible}
      onCancel={onCancel}
      primaryButtonProps={primaryButtonProps}
      onConfirm={onConfirm}
      extraButtons={[]}
      title={<FormattedMessage id="shared.confirmation" />}
      fullscreenMobile
      {...props}
    >
      <p className={styles.message}>
        <FormattedMessage id="progress.common.deleteAllPersonConfirm" />
      </p>
    </ConfirmationModal>
  );
};

export default ClearPersonsConfirmationModal;
