import { combineReducers } from 'redux';

import { locationHash } from '@modules/shared';
import { resetOnLogoutReducer } from '@utils';

import { ROUTE_PERMISSIONS, tabNames } from '../common';
import {
  ASSIGN_FUNCTIONAL_ERROR,
  ASSIGN_FUNCTIONAL_REQUEST,
  ASSIGN_FUNCTIONAL_RESPONSE,
  CLOSE_EDIT_PERMISSIONS_MODAL,
  EXPIRE_ASSIGN_FUNCTIONAL_ERROR,
  EXPIRE_ASSIGN_FUNCTIONAL_REQUEST,
  EXPIRE_ASSIGN_FUNCTIONAL_RESPONSE,
  EXPIRE_FUNCTIONAL_ERROR,
  EXPIRE_FUNCTIONAL_REQUEST,
  EXPIRE_FUNCTIONAL_RESPONSE,
  GET_ALL_SUBUNITS_RESPONSE,
  GET_ORG_POSITIONS_ERROR,
  GET_ORG_POSITIONS_REQUEST,
  GET_ORG_POSITIONS_RESPOSNE,
  OPEN_EDIT_PERMISSIONS_MODAL,
  REASSIGN_POSITIONS_ERROR,
  REASSIGN_POSITIONS_REQUEST,
  REASSIGN_POSITIONS_RESPONSE,
  SET_FROM_POSITION,
  SET_IS_DROP,
  SET_REG_POSITIONS,
  SET_SELECTED_PERSON,
  SET_TO_POSITION,
  UNITS_ADULT_ERROR,
  UNITS_ADULT_REQUEST,
  UNITS_ADULT_RESPONSE,
  UNITS_YOUTH_ERROR,
  UNITS_YOUTH_REQUEST,
  UNITS_YOUTH_RESPONSE,
} from './actions';

const validTabs = Object.values(tabNames);
const defaultTab = locationHash.getValid(validTabs, tabNames.POSITIONS);
const activeTab = (state = defaultTab, { type, payload = {} }) => {
  switch (type) {
    case ROUTE_PERMISSIONS: {
      const { hash } = payload;
      return hash || state;
    }
    default: {
      return state;
    }
  }
};

const unitsAdults = (state = [], { type, payload }) => {
  switch (type) {
    case UNITS_ADULT_REQUEST: {
      return state;
    }
    case UNITS_ADULT_RESPONSE: {
      return payload;
    }
    case UNITS_ADULT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitsYouths = (state = [], { type, payload }) => {
  switch (type) {
    case UNITS_YOUTH_REQUEST: {
      return state;
    }
    case UNITS_YOUTH_RESPONSE: {
      return payload;
    }
    case UNITS_YOUTH_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const permissionsEditModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_PERMISSIONS_MODAL: {
      return true;
    }
    case CLOSE_EDIT_PERMISSIONS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const allSubUnits = (state = null, { type, payload }) => {
  switch (type) {
    case GET_ALL_SUBUNITS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const orgPositions = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_ORG_POSITIONS_RESPOSNE:
      return payload;
    case GET_ORG_POSITIONS_REQUEST:
    case GET_ORG_POSITIONS_ERROR: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const tempRegisteredPositionsList = (state = [], { type, payload }) => {
  switch (type) {
    case SET_REG_POSITIONS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingAssignFunctional = (state = false, { type }) => {
  switch (type) {
    case ASSIGN_FUNCTIONAL_REQUEST: {
      return true;
    }
    case ASSIGN_FUNCTIONAL_ERROR:
    case ASSIGN_FUNCTIONAL_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingExpireFunctional = (state = false, { type }) => {
  switch (type) {
    case EXPIRE_FUNCTIONAL_REQUEST: {
      return true;
    }
    case EXPIRE_FUNCTIONAL_ERROR:
    case EXPIRE_FUNCTIONAL_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingExpireAssignFunctional = (state = false, { type }) => {
  switch (type) {
    case EXPIRE_ASSIGN_FUNCTIONAL_REQUEST: {
      return true;
    }
    case EXPIRE_ASSIGN_FUNCTIONAL_RESPONSE:
    case EXPIRE_ASSIGN_FUNCTIONAL_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingGetOrgPositions = (state = false, { type }) => {
  switch (type) {
    case GET_ORG_POSITIONS_REQUEST: {
      return true;
    }
    case GET_ORG_POSITIONS_ERROR:
    case GET_ORG_POSITIONS_RESPOSNE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingReassignPositions = (state = false, { type }) => {
  switch (type) {
    case REASSIGN_POSITIONS_REQUEST: {
      return true;
    }
    case REASSIGN_POSITIONS_ERROR:
    case REASSIGN_POSITIONS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedPerson = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_PERSON:
      return payload;
    default: {
      return state;
    }
  }
};

const fromPosition = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_FROM_POSITION:
      return payload;
    default: {
      return state;
    }
  }
};

const toPosition = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_TO_POSITION:
      return payload;
    default: {
      return state;
    }
  }
};

const isDrop = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_DROP:
      return payload;
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  unitsAdults,
  unitsYouths,
  permissionsEditModalOpen,
  activeTab,
  allSubUnits,
  orgPositions,
  isLoadingAssignFunctional,
  isLoadingExpireFunctional,
  isLoadingExpireAssignFunctional,
  isLoadingGetOrgPositions,
  selectedPerson,
  fromPosition,
  toPosition,
  isDrop,
  tempRegisteredPositionsList,
  isLoadingReassignPositions,
});

export default resetOnLogoutReducer(reducer);
