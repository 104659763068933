import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import { viewDateFormat } from '../../constants';
import styles from './DateView.less';

const DateView = ({ value, format, utc, className, uppercase }) => {
  const date =
    value && (utc ? moment.utc(value) : moment(value)).format(format);
  const shownDate = uppercase ? date.toUpperCase() : date;
  return <span className={cn(styles.date, className)}>{shownDate}</span>;
};

DateView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.string,
  utc: PropTypes.bool,
  className: PropTypes.string,
  uppercase: PropTypes.bool,
};

DateView.defaultProps = {
  format: viewDateFormat,
};

export default DateView;
