import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { OfflineTooltip, S } from '@shared';

import parentStyles from '../../RolePickerRole.less';
import styles from './RolePickerBaseRole.less';

const stopPropagation = e => e.stopPropagation();
const noop = () => {};

function RolePickerBaseRole({
  active,
  disabled,
  org,
  children,
  className,
  onProfileChange,
}) {
  return (
    <OfflineTooltip
      disabled={!disabled}
      onClick={disabled ? stopPropagation : noop}
    >
      {active && (
        <S size="4" className={styles.currentLabel}>
          <FormattedMessage id="context.RolePicker.currentLabel" />
        </S>
      )}
      <li
        className={cn(
          styles.item,
          {
            [parentStyles.active]: active,
            [parentStyles.disabled]: disabled,
          },
          className,
        )}
        onClick={() => !disabled && onProfileChange(org.organizationGuid)}
      >
        {children}
        {active && <CheckCircleIcon className={parentStyles.activeIcon} />}
      </li>
    </OfflineTooltip>
  );
}

RolePickerBaseRole.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  org: PropTypes.object.isRequired,
  className: PropTypes.string,
  onProfileChange: PropTypes.func.isRequired,
};

export default RolePickerBaseRole;
