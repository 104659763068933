import { memberTypeIds, MIDExemptPositions } from '../../../../constants';

export function shouldDisplayMemberId({
  isNewMemberApplication,
  memberType,
  positionId,
}) {
  if (memberType === -1 || !memberType) return false;
  if (!isNewMemberApplication) return true;

  if (MIDExemptPositions.has(positionId) && memberType === memberTypeIds.adult)
    return false;

  if (memberType === memberTypeIds.youth) return false;

  return true;
}

export function isExistingMember({ adultsInBatch = [], memberId }) {
  if (!memberId) return false;

  return adultsInBatch.find(adult => adult.memberId === memberId);
}
