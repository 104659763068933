import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { T, intl, isParentOrYouthMemberSel } from '@shared';

import {
  ActivityDescription,
  ActivityFormDate,
  ActivityLocation,
  selectedActivitySel,
} from '../../../../common';
import { detailModes } from '../../../constants';
import { datesSel } from '../../../duck';
import EditButton from '../EditButton';
import EditInfo from '../EditInfo';
import LongCruiseName from '../LongCruiseName';

const LongCruiseDetails = ({
  form,
  detailMode,
  loading,
  disableFutureDays,
}) => {
  const preselectedDates = useSelector(datesSel);
  const selectedActivity = useSelector(selectedActivitySel);
  const isParentOrYouthMember = useSelector(isParentOrYouthMemberSel);

  const isDisabled =
    loading ||
    detailMode === detailModes.RECORD ||
    detailMode === detailModes.JOIN ||
    (isParentOrYouthMember && !selectedActivity.isPersonalActivity);

  return (
    <div>
      <div>
        <T.Responsive>
          <FormattedMessage id="progress.longCruise.label" />
        </T.Responsive>
        {detailMode === detailModes.RECORD ? (
          <EditButton />
        ) : (
          <EditInfo detailMode={detailMode} />
        )}
      </div>
      <br />
      <LongCruiseName
        form={form}
        disabled={isDisabled}
        detailMode={detailMode}
      />
      <div>
        <ActivityFormDate
          form={form}
          preselectedStartDate={preselectedDates.start}
          preselectedEndDate={preselectedDates.end}
          isDisabled={isDisabled}
          disableFutureDays={disableFutureDays}
          activityTypeName={intl.formatMessage({
            id: 'LongCruiseForm.baseName',
          })}
        />
        <br />
        <ActivityLocation
          isDisabled={isDisabled}
          form={form}
          detailMode={detailMode}
          sectionTitle={intl.formatMessage({
            id: 'LongCruiseForm.location',
          })}
        />
        <br />
        <T.Responsive>
          <FormattedMessage id="LongCruiseForm.details" />
        </T.Responsive>
        <ActivityDescription form={form} disabled={isDisabled} />
      </div>
    </div>
  );
};

LongCruiseDetails.propTypes = {
  loading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  detailMode: PropTypes.string.isRequired,
  disableFutureDays: PropTypes.bool,
};

export default LongCruiseDetails;
