import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PersonIcon from '@material-ui/icons/Person';
import cn from 'classnames';

import { isMobileSel } from '@screen';
import { isSettingsRouteSel } from '../../../duck';
import SettingsMenu from './SettingsMenu';
import styles from './Settings.less';

class Settings extends React.PureComponent {
  state = {
    isMenuVisible: false,
  };

  componentWillUnmount() {
    this.removeMenuCloseListener();
  }

  handleClick = () => {
    this.setState(({ isMenuVisible }) => {
      if (!isMenuVisible) {
        this.addMenuCloseListener();
      } else {
        this.removeMenuCloseListener();
      }
      return { isMenuVisible: !isMenuVisible };
    });
  };

  addMenuCloseListener = () => {
    this.removeMenuCloseListener();
    setTimeout(() => window.addEventListener('click', this.closeMenu));
  };

  removeMenuCloseListener = () => {
    window.removeEventListener('click', this.closeMenu);
  };

  closeMenu = () => {
    this.setState({ isMenuVisible: false });
    this.removeMenuCloseListener();
  };

  render() {
    const { isMobile } = this.props;
    const { isMenuVisible } = this.state;

    return (
      <div
        id="qa_settingsMenu"
        className={cn({ Joyride__SettingsMenu: !isMobile }, styles.settings)}
        onClick={this.handleClick}
      >
        <PersonIcon />
        <SettingsMenu visible={isMenuVisible} />
      </div>
    );
  }
}

Settings.propTypes = {
  active: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapState = state => ({
  active: isSettingsRouteSel(state),
  isMobile: isMobileSel(state),
});

export default connect(mapState)(Settings);
