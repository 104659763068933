import { useLayoutEffect } from 'react';

const useAllDayCheckbox = ({ start, end, form }) => {
  const { setFieldsValue, getFieldsValue } = form;
  const allDay = getFieldsValue().allDay;

  useLayoutEffect(() => {
    if (start && end) {
      const formattedStartTime = start.format('HH:mm');
      const formattedEndTime = end.format('HH:mm');
      const result =
        formattedEndTime === '23:59' && formattedStartTime === '00:00';

      if (result !== allDay) {
        setFieldsValue({ allDay: result });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, setFieldsValue]);

  return allDay;
};

export default useAllDayCheckbox;
