import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import NavigateNextIcon from 'material-ui-icons/NavigateNext';
import NavigateBeforeIcon from 'material-ui-icons/NavigateBefore';

import { Row, Col } from '../bsaComponents';
import Button from '../Button';
import S from '../S';

import styles from './CalendarDateHeader.less';

const CalendarDateHeader = ({
  handleLeft,
  handleRight,
  leftSpan,
  rightHeader,
  title,
  className,
}) => {
  const spanChild = 24 - leftSpan;

  return (
    <Row className={cn(styles.controlsWrapper, className)}>
      <Col span={rightHeader ? leftSpan : 24} className="d-flex items-center">
        <Button
          ghost
          noBorder
          fitText
          className={styles.arrowButton}
          onClick={handleLeft}
        >
          <NavigateBeforeIcon />
        </Button>
        <S size="3" colored className={styles.titleFormat}>
          {title}
        </S>
        <Button
          ghost
          noBorder
          fitText
          className={styles.arrowButton}
          onClick={handleRight}
        >
          <NavigateNextIcon />
        </Button>
      </Col>
      {rightHeader && <Col span={spanChild}>{rightHeader}</Col>}
    </Row>
  );
};

CalendarDateHeader.propTypes = {
  handleLeft: PropTypes.func,
  handleRight: PropTypes.func,
  leftSpan: PropTypes.number,
  rightHeader: PropTypes.element,
  title: PropTypes.string,
  className: PropTypes.string,
};

CalendarDateHeader.defaultProps = {
  handleLeft: () => {},
  handleRight: () => {},
  leftSpan: 22,
};

export default CalendarDateHeader;
