import { createSelector } from 'reselect';

import { adventuresSel, allAwardsSel, meritBadgesSel, ranksSel } from '@dict';
import {
  advancementHistoryItemsSel,
  advancementStatusDate,
  advancementTypes,
  findPreviousHierarchicalAdvancement,
  historyItemsTypes,
  packRosterItemsSel,
} from '@shared';

import { approveAdvancementsModuleSel } from '../../common';

export { approveAdvancementsVisibleSel as visibleSel } from '../../common';

const moduleSel = state => approveAdvancementsModuleSel(state);

export const isVisibleDeclineAdvancementsSel = state =>
  moduleSel(state).isVisibleDeclineMultipleAdvancementsModal;
export const activeUserIdSel = state => moduleSel(state).activeUserId;
export const loadingSel = state => moduleSel(state).loading;
export const approvalErrorSel = state => moduleSel(state).approvalError;
export const advancementStatusSel = state => moduleSel(state).advancementStatus;
export const fromActivityLogsSel = state => moduleSel(state).fromActivityLogs;
const _personsAdvancementsSel = state => moduleSel(state).personsAdvancements;

const getAdvancementImageUrl = (advancement, dicts) => {
  if (advancement.type === historyItemsTypes.ADVANCEMENT) {
    const advancementDict =
      dicts[advancement.advancementType].find(
        ({ id }) => id == advancement.id,
      ) || {};
    return advancement.imageUrl || advancementDict.imageUrl100;
  }
};

export const personsAdvancementsSel = createSelector(
  _personsAdvancementsSel,
  advancementHistoryItemsSel,
  adventuresSel,
  allAwardsSel,
  ranksSel,
  meritBadgesSel,
  packRosterItemsSel,
  fromActivityLogsSel,
  (
    personsAdvancements,
    advancementsHistory,
    adventures,
    awards,
    ranks,
    meritBadges,
    packRosterItems,
    fromActivityLogs,
  ) => {
    const dicts = {
      [advancementTypes.ADVENTURES]: adventures,
      [advancementTypes.AWARDS]: awards,
      [advancementTypes.RANKS]: ranks,
      [advancementTypes.MERIT_BADGES]: meritBadges,
    };

    return personsAdvancements.map(pa => {
      const packRosterYouth =
        packRosterItems.find(({ userId }) => userId == pa.userId) || {};
      const { age, dateOfBirth } = packRosterYouth;

      const imageUrl = getAdvancementImageUrl(pa, dicts);

      const previousHistoryItem = findPreviousHierarchicalAdvancement({
        item: pa,
        itemsHistory: advancementsHistory,
      });

      const previousAdvancementCompletionDate =
        advancementStatusDate.getCompleted(previousHistoryItem);

      return {
        ...pa,
        userId: Number(pa.userId),
        age,
        dateOfBirth,
        imageUrl,
        fromActivityLogs,
        previousAdvancementCompletionDate,
        unitTypeId: pa.unitTypeId || pa.programId,
      };
    });
  },
);
