import { createAction } from '@reduxjs/toolkit';

import { advRequirementOperations, moduleName } from '../constants';

export const editAdvRqmtsRequest = createAction<{
  requirements: {
    id: string;
    dateCompleted: string;
  }[];
  dateCompleted?: string;
  operation?: advRequirementOperations;
}>(`${moduleName}/EDIT_ADV_RQMTS_REQUEST`);

export const editAdvRqmtsResponse = createAction(
  `${moduleName}/EDIT_ADV_RQMTS_RESPONSE`,
);
export const editAdvRqmtsError = createAction<Error>(
  `${moduleName}/EDIT_ADV_RQMTS_ERROR`,
);
export const editPreferedName = createAction(
  `${moduleName}/EDIT_PREFERED_NAME`,
);
