import React, { useEffect } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Col, Progress, Row, S, Spin } from '@shared/components';
import { organizationGuidSel } from '@shared/duck';

import { validationTypes } from '../../../../constants';
import {
  batchApplicationIdSel,
  recharterValidateRosterRequest,
  rosterMissingPositionsSel,
  rosterValidationProgressSel,
  rosterValidationRejectionSel,
  rosterVisibleValidationsSel,
} from '../../../../duck';
import { SwitchIcon } from '../../../common';
import ValidationItem from '../ValidationItem';
import styles from './ValidationContent.less';

const ValidationContent = ({ isValidationLoading }) => {
  const dispatch = useDispatch();
  const orgGuid = useSelector(organizationGuidSel);
  const batchId = useSelector(batchApplicationIdSel);
  const rosterVisibleValidations = useSelector(rosterVisibleValidationsSel);
  const recharterValidationFail = useSelector(rosterValidationRejectionSel);
  const recharterValidationProgress = useSelector(rosterValidationProgressSel);
  const missingPositions = useSelector(rosterMissingPositionsSel);

  useEffect(() => {
    dispatch(recharterValidateRosterRequest(orgGuid, batchId));
  }, [batchId, dispatch, orgGuid]);

  const getProgressStatus = () =>
    recharterValidationFail ? 'exception' : 'active';

  const alertTitle = recharterValidationFail
    ? 'recharterModals.validationModal.validations.status.failTitle'
    : 'recharterModals.validationModal.validations.status.successTitle';
  const alertDescription = recharterValidationFail
    ? 'recharterModals.validationModal.validations.status.fail'
    : 'recharterModals.validationModal.validations.status.success';

  const alertType = recharterValidationFail ? 'error' : 'success';

  return (
    <div className={styles.ValidationContentContainer}>
      {isValidationLoading ? (
        <div className={styles.SpinnerContainer}>
          <Spin />
        </div>
      ) : (
        <React.Fragment>
          <Row>
            <Col span={24}>
              <div>
                <Alert
                  message={<FormattedMessage id={alertTitle} />}
                  description={<FormattedMessage id={alertDescription} />}
                  type={alertType}
                  showIcon
                />
              </div>
              <Progress
                strokeWidth={5}
                percent={recharterValidationProgress}
                showInfo={false}
                status={getProgressStatus()}
              />
            </Col>
            <Col span={24}>
              <div className={styles.HelpInfoContainer}>
                <S
                  className={styles.LegendLabel}
                  bold
                  colon
                  title="Meaning of the status icons"
                >
                  LEGEND
                </S>
                <div className={cn(styles.IconHelp)}>
                  <SwitchIcon
                    validation={true}
                    validationType={validationTypes.WARNING}
                  />
                  <FormattedMessage id="recharterModals.validationModal.iconHelp.warning" />
                </div>
                <div className={cn(styles.IconHelp)}>
                  <SwitchIcon
                    validation={true}
                    validationType={validationTypes.ERROR}
                  />
                  <FormattedMessage id="recharterModals.validationModal.iconHelp.error" />
                </div>
              </div>
            </Col>
          </Row>
          <Row type="flex">
            <Col span={24}>
              {isValidationLoading ? (
                <div className={styles.SpinnerContainer}>
                  <Spin />
                </div>
              ) : (
                rosterVisibleValidations.map(validation => {
                  const {
                    validationId,
                    validationFlag,
                    validationCategoryUnit,
                    validationDetails,
                  } = validation;
                  return (
                    <ValidationItem
                      key={`flag${validationId}`}
                      validation={validationFlag}
                      validationType={validationCategoryUnit}
                      validationId={validationId}
                      validationDetails={validationDetails}
                      missingPositions={
                        validationId === 6 ? missingPositions : []
                      }
                    />
                  );
                })
              )}
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};

ValidationContent.propTypes = {
  isValidationLoading: PropTypes.bool.isRequired,
};

export default ValidationContent;
