import { gtm } from '@shared';

import {
  APPROVE_ADVANCEMENTS_RESPONSE,
  CLOSE_APPROVE_ADVANCEMENTS,
  closeApproveAdvancements,
  approveAdvancementsModuleNamespace as namespace,
} from '../../common';
import { personsAdvancementsSel } from './selectors';

export {
  CLOSE_APPROVE_ADVANCEMENTS,
  closeApproveAdvancements,
  APPROVE_ADVANCEMENTS_RESPONSE,
};

export const OPEN_APPROVE_ADVANCEMENTS = `${namespace}/OPEN_APPROVE_ADVANCEMENTS`;
export const openApproveAdvancements = (
  personsAdvancements,
  advancementStatus,
) => ({
  type: OPEN_APPROVE_ADVANCEMENTS,
  payload: { personsAdvancements, advancementStatus },
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const SET_FROM_ACTIVITY_LOGS = `${namespace}/SET_FROM_ACTIVITY_LOGS`;
export const setFromActivityLogs = fromActivityLogs => ({
  type: SET_FROM_ACTIVITY_LOGS,
  payload: fromActivityLogs,
});

export const SET_ACTIVE_YOUTH = `${namespace}/SET_ACTIVE_YOUTH`;
export const setActiveYouth = userId => ({
  type: SET_ACTIVE_YOUTH,
  payload: userId,
});

export const DESELECT_PERSON = `${namespace}/DESELECT_PERSON`;
export const deselectPerson = userId => (dispatch, getState) => {
  const personsAdvancements = personsAdvancementsSel(getState());
  const activityRowKeys = personsAdvancements
    .filter(personAdv => personAdv.userId == userId)
    .map(({ key }) => key);
  dispatch({ type: DESELECT_PERSON, payload: { userId, activityRowKeys } });
};

export const DESELECT_COMPLETED_ADVANCEMENT = `${namespace}/DESELECT_COMPLETED_ADVANCEMENT`;
export const deselectAdvancement = ({ key, id }) => ({
  type: DESELECT_COMPLETED_ADVANCEMENT,
  payload: {
    key,
    id,
  },
});

export const APPROVE_ADVANCEMENTS_REQUEST = `${namespace}/APPROVE_ADVANCEMENTS_REQUEST`;
export const APPROVE_ADVANCEMENTS_DEFERRED = `${namespace}/APPROVE_ADVANCEMENTS_DEFERRED`;
export const APPROVE_ADVANCEMENTS_ERROR = `${namespace}/APPROVE_ADVANCEMENTS_ERROR`;
export const approveAdvancementsRequest = payload => ({
  type: APPROVE_ADVANCEMENTS_REQUEST,
  payload: payload,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const approveAdvancementsResponse = advancementTypes => ({
  type: APPROVE_ADVANCEMENTS_RESPONSE,
  payload: advancementTypes,
});
export const approveAdvancementsDeferred = () => ({
  type: APPROVE_ADVANCEMENTS_DEFERRED,
});
export const approveAdvancementsError = error => ({
  type: APPROVE_ADVANCEMENTS_ERROR,
  payload: error,
});

export const OPEN_DECLINE_MULTIPLE_ADVANCEMENTS = `${namespace}/OPEN_DECLINE_MULTIPLE_ADVANCEMENTS`;
export const openDeclineMultipleAdvancements = (
  personsAdvancements,
  advancementStatus,
) => ({
  type: OPEN_DECLINE_MULTIPLE_ADVANCEMENTS,
  payload: { personsAdvancements, advancementStatus },
});

export const CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS = `${namespace}/CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS`;
export const closeDeclineMultipleAdvancements = () => ({
  type: CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS,
});

export const DECLINE_ADVANCEMENTS_REQUEST = `${namespace}/DECLINE_ADVANCEMENTS_REQUEST`;
export const DECLINE_ADVANCEMENTS_DEFERRED = `${namespace}/DECLINE_ADVANCEMENTS_DEFERRED`;
export const DECLINE_ADVANCEMENTS_ERROR = `${namespace}/DECLINE_ADVANCEMENTS_ERROR`;
export const declineAdvancementsRequest = payload => ({
  type: DECLINE_ADVANCEMENTS_REQUEST,
  payload: payload,
});
export const declineAdvancementsResponse = advancementTypes => ({
  type: APPROVE_ADVANCEMENTS_RESPONSE,
  payload: advancementTypes,
});
export const declineAdvancementsDeferred = () => ({
  type: DECLINE_ADVANCEMENTS_DEFERRED,
});
export const declineAdvancementsError = error => ({
  type: DECLINE_ADVANCEMENTS_ERROR,
  payload: error,
});
