import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Card, S, Button } from '@shared';
import styles from './FallbackMessage.less';

function FallbackMessage({ youthCount, onGoToReports }) {
  return (
    <Card margin className={styles.card}>
      <div className={styles.messageWrapper}>
        <S size="2" bold colored>
          {youthCount == 0 ? (
            <FormattedMessage id="advancementReport.FallbackMessage.noAdvancementReports" />
          ) : (
            <FormattedMessage
              id="advancementReport.FallbackMessage.noAdvancementsForYouth"
              values={{ youthCount }}
            />
          )}
        </S>
      </div>
      <Button color="info" uppercase={false} onClick={onGoToReports}>
        <FormattedMessage id="advancementReport.FallbackMessage.viewPreviousReports" />
      </Button>
    </Card>
  );
}

FallbackMessage.propTypes = {
  youthCount: PropTypes.number.isRequired,
  onGoToReports: PropTypes.func.isRequired,
};

export default FallbackMessage;
