import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Collapse } from '../bsaComponents';
import { getChildrenKeys } from './utils';
import styles from './TableFilterPanel.less';

const { Panel } = Collapse;

class TableFilterPanelGroup extends React.Component {
  static isPanelGroup = true;

  render() {
    const { children, className, ...rest } = this.props;

    const activeKey = getChildrenKeys(children);

    return (
      <Panel
        {...rest}
        className={cn(
          styles.panel,
          styles.noPadding,
          styles.panelGroup,
          className,
        )}
        activeKey={activeKey}
      >
        <Collapse activeKey={activeKey} bordered={false}>
          {children}
        </Collapse>
      </Panel>
    );
  }
}

TableFilterPanelGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default TableFilterPanelGroup;
