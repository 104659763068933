import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, T } from '@modules/shared';

import styles from './Confirmation.less';

type Props = {
  onConfirm: () => void;
};

const Confirmation: React.FC<Props> = ({ onConfirm }) => (
  <div>
    <T className={styles.confirmationTitle}>
      <FormattedMessage
        id={'packRoster.addIndividual.addDenChiefModal.request'}
      />
    </T>
    <FormattedMessage
      id={'packRoster.addIndividual.addDenChiefModal.emailNotifications'}
    />
    <br />
    <br />
    <FormattedMessage
      id={'packRoster.addIndividual.addDenChiefModal.expiredOrUnapproved'}
    />
    <br />
    <br />
    <FormattedMessage
      id={'packRoster.addIndividual.addDenChiefModal.enteringEndDate'}
    />
    <Button
      className={styles.addButton}
      type="primary"
      shape="round"
      size="large"
      fitText
      uppercase={true}
      onClick={onConfirm}
    >
      <FormattedMessage
        id={'packRoster.addIndividual.addDenChiefModal.understand'}
      />
    </Button>
  </div>
);

export default Confirmation;
