import React from 'react';
import { Card, TablePlaceholder } from '@shared';

const CounseledYouthPlaceholder = () => (
  <div>
    <Card margin noPadding shadow>
      <TablePlaceholder />
    </Card>
  </div>
);

export default CounseledYouthPlaceholder;
