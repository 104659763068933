import PropTypes from 'prop-types';

import { ENABLE_VENTURING_RANKS } from '@config';
import { Program, advancementTypes } from '@shared/constants';

export {
  eagleScoutRankId as eagleRankId,
  advancementsExpiredKeys,
} from '@shared';

export { advancementModuleName as moduleName } from '../common';

export const actions = Object.freeze({
  RECORD_AND_FINISH: 'RECORD_AND_FINISH',
  RECORD_AND_CONTINUE: 'RECORD_AND_CONTINUE',
});
export const eagleScoutName = 'Eagle Scout';

export const advancementFormDataPropTypes = Object.freeze({
  completionDate: PropTypes.string,
  advancementType: PropTypes.string.isRequired,
  advancementId: PropTypes.string,
  eligibleAdvancementTypes: PropTypes.array.isRequired,
  availableAdvancements: PropTypes.array,
  eagleRankSelected: PropTypes.bool.isRequired,
  advancementVerificationLoading: PropTypes.bool.isRequired,
  verifyTimeoutError: PropTypes.bool.isRequired,
  eligiblePersons: PropTypes.array.isRequired,
  suggestedYouth: PropTypes.array.isRequired,
  selectedSuggestedYouthIds: PropTypes.array.isRequired,
  selectedAdvancementStatus: PropTypes.string.isRequired,
});

export const warningsCodes = Object.freeze({
  ADVANCEMENT_EARNED: 'ADVANCEMENT_EARNED',
  PREVIOUS_RANK_ERROR: 'PREVIOUS_RANK_ERROR',
  BOBCAT_RANK_REQUIRED: 'BOBCAT_RANK_REQUIRED',
  PREVIOUS_RANK_WARNING: 'PREVIOUS_RANK_WARNING',
  ADVANCEMENT_COMPLETED: 'ADVANCEMENT_COMPLETED',
  PREVIOUS_CORE_AWARD: 'PREVIOUS_CORE_AWARD',
  ADVANCEMENT_APPROVED: 'ADVANCEMENT_APPROVED',
  ADVANCEMENT_AWARDED: 'ADVANCEMENT_AWARDED',
  INCORRECT_UNIT: 'INCORRECT_UNIT',
  AGE_PROBLEM: 'AGE_PROBLEM',
});

const extraVenturingPrograms = ENABLE_VENTURING_RANKS
  ? [advancementTypes.RANKS]
  : [];

export const unitAvailableRecordAdvancements = Object.freeze({
  [Program.CUB_SCOUT]: [advancementTypes.AWARDS],
  [Program.BOY_SCOUT]: [
    advancementTypes.MERIT_BADGES,
    advancementTypes.AWARDS,
    advancementTypes.RANKS,
  ],
  [Program.VARSITY]: [],
  [Program.VENTURING]: [advancementTypes.AWARDS, ...extraVenturingPrograms],
  [Program.SEA_SCOUT]: [advancementTypes.AWARDS, advancementTypes.RANKS],
  [Program.EXPLORING]: [advancementTypes.AWARDS],
  [Program.CLUB]: [advancementTypes.AWARDS],
});

// These ids where provided by SB team
// LDS awards are should not be available anymore
export const LDSAwards = Object.freeze({
  77: true, // Duty to God (Deacon)
  78: true, // Duty to God (Teacher)
  79: true, // Duty to God (Priest)
  80: true, // On My Honor (Youth)
  81: true, // On My Honor (Adult)
  82: true, // Religious Square Knot (LDS Cub Scouting)
  83: true, // Religious Square Knot (LDS Boy Scouting)
  84: true, // Religious Square Knot (LDS Adult)
  85: true, // Faith In God (LDS)
});
// Temporal Hardcoded
export const disableAwards = Object.freeze({
  55: true,
  // Cub Scout Outdoor Activity Pin
});
export const oldVersionAward = Object.freeze({
  19: true, // Cub Scout Outdoor Activity Award
  56: true, // Cub Scoutings Leave No Trace Awareness Award (for Leaders)
  57: true, // Emergency Preparedness BSA (Retired) (Tiger Cub)
  59: true, // Emergency Preparedness BSA (Retired) (Bear)
  60: true, // Emergency Preparedness BSA (Retired) (Webelos)
  62: true, // Cub Scout World Conservation Award (Bear)
  63: true, // Cub Scout World Conservation Award (Webelos)
  69: true, // Cub Scout Nova Award - Science Everywhere (pre-2015)
  70: true, // Cub Scout Nova Award - Tech Talk (pre-2015)
  71: true, // Cub Scout Nova Award - Swing! (pre-2015)
  72: true, // Cub Scout Nova Award - 1-2-3 Go! (pre-2015)
  113: true, // Cub Scout Supernova Award (pre-2015)
  114: true, // Webelos Scout Supernova Award (pre-2015)
  159: true, // Cub Scout Supernova Award
  178: true, // Webelos Scout Supernova Award,
  3394: true, // Cub Scout Outdoor Activity Award v2010 (Tiger)
  3395: true, // Cub Scout Outdoor Activity Award v2010 (Wolf)
  3396: true, // Cub Scout Outdoor Activity Award v2010 (Bear)
  3397: true, // Cub Scout Outdoor Activity Award v2010 (Webelos)
});
