import { groupBy, isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, forkJoin, interval, of } from 'rxjs';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/throttle';
import 'rxjs/add/operator/throttleTime';
import {
  debounce,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import {
  SET_ORGANIZATION,
  organizationGuidSel,
  organizationPositionSel,
  selectedOrganizationSel,
} from '@context';
import {
  deleteRecurrentEventInfoSel,
  eventsSel,
  getEventsRequest,
  selectedDateSel,
  setDeleteRecurrentEventInfo,
} from '@modules/calendar/duck';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { tagTypes } from '@modules/rtk/constants';
import { esbApi } from '@modules/rtk/esb-api';
import { unitInfoSel } from '@modules/unit';
import progressServices from '@progress/duck/services';
import {
  APP_BOOTSTRAP_DONE,
  DELETE_EVENTS_REQ,
  DELETE_RECURRENT_EVENTS_REQ,
  deleteEventsError,
  emptyArr,
  extractOrgGuidAndUserId,
  goToCalendarPage,
  intl,
  isParentOrYouthMemberSel,
  organizationPositionsSel,
  remindersTypeIds,
  triggerCalendarRequest,
  unitTypeIdForUnitType,
} from '@shared';
import { toastService } from '@toasts';
import { userIdSel as loggedInUserIdSel } from '@user';
import {
  dateAndTimeToDateTime,
  dateAndTimeToDtoDateTime,
  dateAndTimeToDtoToDateToTime,
  sorters,
} from '@utils';
import { catchAndReport } from '@utils/rxjs/operators';

import { services as rosterServices } from '../../advancement/packRoster';
import { services as subUntisServices } from '../../advancement/subUnits';
import { payloadSel, querySel } from '../../location/duck';
import {
  AdminPositionIds,
  attendeeTypeToRsvpValues,
  reminderRsvpSetting,
  requirementsTypesIds,
} from '../constants';
import {
  cleanRequirementsIds,
  containsPendingComments,
  formatRecurrentEvent,
  getAdjustedRecurrentDate,
  getDefaultsEventUnits,
  getRecurrentEventInvitees,
  getRecurrentEventUnits,
  getUnitFilteredMembers,
  groupRequirementsByChildren,
  transformGetEventCommentsResponse,
} from '../utils';
import {
  ADD_GUESTS_REQUEST,
  ATTENDED_TOGGLE_GUEST_REQUEST,
  ATTENDED_TOGGLE_REQUEST,
  CONFIRM_INVITE_REQUEST,
  CONFIRM_MULTI_INVITE_REQUEST,
  DELETE_ACTIVITY_EVENT,
  DELETE_EVENT_COMMENT_PHOTO_REQ,
  DELETE_EVENT_COMMENT_REQ,
  DELETE_EVENT_REMINDER_REQUEST,
  DELETE_MULTI_INVITEE_REQUEST,
  GET_ACTIVITIES_EVENT,
  GET_ACTIVITIES_TO_SELECT_REQUEST,
  GET_ACTIVITY_BY_ID_REQUEST,
  GET_ALL_SUBUNITS_REQUEST,
  GET_CALENDAR_RECORDS_REQ,
  GET_EVENT_BY_ID_REQUEST,
  GET_EVENT_COMMENTS_CANCEL,
  GET_EVENT_COMMENTS_REQ,
  GET_EVENT_REQUIREMENTS_REQUEST,
  GET_EVENT_TYPES_REQUEST,
  GET_EXTERNAL_GUESTS_REQUEST,
  GET_REQUIREMENTS_REQUEST,
  GET_UNIT_ROSTER_REQUEST,
  POST_EVENT_COMMENT_REQ,
  POST_EVENT_COMMENT_WITH_FILES_REQ,
  PUT_EVENT_COMMENT_REQ,
  SAVE_ACTIVITY_EVENT,
  SAVE_CALENDAR_RECORDS_REQ,
  SAVE_EVENT_REQUEST,
  SAVE_PUBLIC_RSVP_REQ,
  SAVE_RECURRENT_EVENT_REQUEST,
  SEND_PUBLIC_RSVP_REQ,
  UPDATE_GUEST_RSVP_REQUEST,
  UPDATE_PERSON_RSVP_REQUEST,
  UPDATE_RECURRENT_EVENT_BY_RECREATION_REQUEST,
  UPDATE_RECURRENT_EVENT_REQUEST,
  addGuestsError,
  attendedToggleError,
  attendedToggleGuestError,
  attendedToggleGuestResponse,
  attendedToggleResponse,
  closeQuickEntryRSVPModal,
  confirmInviteError,
  confirmInviteResponse,
  confirmMultiInviteError,
  deleteActivityEventError,
  deleteActivityEventResponse,
  deleteEventCommentError,
  deleteEventCommentPhotoError,
  deleteEventCommentPhotoResponse,
  deleteEventCommentResponse,
  deleteEventReminderError,
  deleteEventReminderResponse,
  deleteMultiInviteeError,
  getActivitiesEventError,
  getActivitiesEventResponse,
  getActivitiesToSelectError,
  getActivitiesToSelectResponse,
  getActivityByIdError,
  getActivityByIdResponse,
  getAllSubunitsError,
  getAllSubunitsResponse,
  getCalendarRecordsError,
  getCalendarRecordsRequest,
  getCalendarRecordsResponse,
  getEventByIdError,
  getEventByIdRequest,
  getEventByIdResponse,
  getEventCommentsCancel,
  getEventCommentsError,
  getEventCommentsRequest,
  getEventCommentsResponse,
  getEventInviteesResponse,
  getEventRemindersResponse,
  getEventRequirementsError,
  getEventRequirementsResponse,
  getEventTypesError,
  getEventTypesResponse,
  getExternalGuestsError,
  getExternalGuestsRequest,
  getExternalGuestsResponse,
  getRequirementsError,
  getRequirementsResponse,
  getUnitRosterError,
  getUnitRosterResponse,
  postEventCommentError,
  postEventCommentResponse,
  postEventCommentWithFilesError,
  postEventCommentWithFilesResponse,
  putEventCommentError,
  putEventCommentResponse,
  saveActivityEventError,
  saveActivityEventResponse,
  saveCalendarRecordsError,
  saveCalendarRecordsResponse,
  saveEventError,
  saveEventResponse,
  savePublicRSVPError,
  savePublicRSVPResponse,
  saveRecurrentEventRequest,
  selectAllSubAttendeesEvent,
  sendPublicRSVPError,
  sendPublicRSVPResponse,
  setEventQELoading,
  setEventReload,
  updateGuestRSVPError,
  updatePersonRSVPError,
} from './actions';
import { deleteEventsResponse, rtkRefetchUnitEvents } from './actionsTyped';
import { DeleteRecurrentEventOption } from './enums';
import {
  advancementTypeSel,
  allRostersFetchedGuidsSel,
  allRostersSel,
  allUnitsSubunitsSel,
  eventDetailsSel,
  filteredEventTypesSel,
  isEventRouteSel,
  removedGuestListSel,
  selectedAdvancementSel,
  selectedEventAdvancementsSel,
  selectedEventGuestsByStatusSel,
  selectedEventUnitsSel,
  selectedEventUsersByStatusSel,
  selectedEventUsersSel,
} from './selectors';
import services from './services';

const redirectToCalendarEpic$ = (action$, state$) =>
  action$
    .skipUntil(action$.ofType(APP_BOOTSTRAP_DONE))
    .ofType(SET_ORGANIZATION)
    .filter(() => isEventRouteSel(state$.value))
    .mapTo(goToCalendarPage());

const getEventByIdEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_EVENT_BY_ID_REQUEST),
    switchMap(({ payload: id }) => {
      const state = state$.value;
      const { clone } = querySel(state);
      return services.getEventById$(id).pipe(
        mergeMap(res => {
          const { reminders } = res;
          const remindersWithIndex = reminders.map(reminder => ({
            ...reminder,
            calendarEventId: +id,
            index: uuid(),
          }));
          return Observable.concat(
            of(getEventByIdResponse(res, !!clone)),
            of(getEventRemindersResponse(!clone ? remindersWithIndex : [])),
          );
        }),
        catchAndReport(err => of(getEventByIdError(err))),
      );
    }),
  );

const mixRequirements = (requirementRes, requirements) => {
  const { requirementIds, homeRequirementIds } = requirementRes;
  return requirements
    .filter(req => requirementIds.includes(+req.id))
    .map(req => ({
      ...req,
      isHomeSelected: homeRequirementIds.includes(+req.id),
    }));
};

const formatAdvancements = (response, advancements) =>
  advancements.map(advancement => {
    const { advancementId, advancementType, requirements } = advancement;

    const reqResponse = response.find(
      ({ advancementTypeId }) => advancementTypeId === advancementId,
    );

    const { withChildren, withoutChildren } =
      groupRequirementsByChildren(requirements);
    const requirementsWith = withChildren
      .map(req => ({
        ...req,
        childRequirements: mixRequirements(reqResponse, req.childRequirements),
      }))
      .filter(req => !isEmpty(req.childRequirements));

    const requirementsWithout = mixRequirements(reqResponse, withoutChildren);

    const selectedRequirements = requirementsWith
      .concat(requirementsWithout)
      .sort(sorters.string('listNumber'));

    return {
      ...advancement,
      ...reqResponse,
      selectedRequirements,
      id: advancementId,
      type: advancementType,
    };
  });

const getEventRequirementsEpic$ = action$ =>
  action$.pipe(
    ofType(GET_EVENT_REQUIREMENTS_REQUEST),
    switchMap(({ payload: id }) =>
      services.getEventRequirements$(id).pipe(
        switchMap(response =>
          forkJoin([
            of(response),
            !isEmpty(response)
              ? forkJoin(
                  response.map(
                    ({ advancementType: advType, advancementTypeId }) => {
                      const advancementType = requirementsTypesIds[advType];
                      return services.getAdvancementRequirements$({
                        advancementType,
                        advancementId: advancementTypeId,
                      });
                    },
                  ),
                )
              : Observable.of([]),
          ]),
        ),
        map(([response, advancements]) =>
          getEventRequirementsResponse(
            formatAdvancements(response, advancements),
          ),
        ),
        catchAndReport(err => of(getEventRequirementsError(err))),
      ),
    ),
  );

const getActivitiesEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ACTIVITIES_TO_SELECT_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const todayDate = moment();
      return progressServices
        .getActivities$({
          startDateTime: todayDate.clone().subtract(0, 'months'),
          endDateTime: todayDate.clone().add(4, 'months'),
          organizationGuid: organizationGuidSel(state),
        })
        .pipe(
          map(getActivitiesToSelectResponse),
          catchAndReport(err => of(getActivitiesToSelectError(err))),
        );
    }),
  );

const getActivityByIdEpic$ = action$ =>
  action$.pipe(
    ofType(GET_ACTIVITY_BY_ID_REQUEST),
    switchMap(({ payload: id }) =>
      progressServices.getActivityById$(id).pipe(
        switchMap(response => {
          const { calendarEventId } = response;
          return forkJoin([
            of(response),
            calendarEventId ? services.getEventById$(calendarEventId) : of({}),
          ]);
        }),
        mergeMap(([activity, event]) =>
          Observable.concat(
            of(
              getActivityByIdResponse({
                ...activity,
                canRsvp: !!event.canRsvp,
              }),
            ),
            of(
              getEventInviteesResponse(
                [...activity.registeredAdults, ...activity.registeredYouths] ||
                  emptyArr,
              ),
            ),
          ),
        ),
        catchAndReport(err => of(getActivityByIdError(err))),
      ),
    ),
  );

const confirmEventInviteEpic$ = action$ =>
  action$.pipe(
    ofType(CONFIRM_INVITE_REQUEST),
    switchMap(
      ({
        payload: {
          eventId,
          data,
          isInvited,
          attendeeType,
          atendeeName,
          successCallback = () => {},
        },
      }) => {
        let updated = true;
        let service = services.editEventInvitee$;
        let payload = { users: [data] };

        if (data.rsvp) {
          data.rsvpCode = uuid().substring(0, 4);
        }

        if (!isInvited) {
          service = services.addEventInvitee$;
          updated = false;
          data.attended = false;
          data.primaryLeader = false;
          payload = [data];
        }
        return service(eventId, payload).pipe(
          map(() => {
            successCallback(attendeeType);
            return confirmInviteResponse({ eventId, data, updated });
          }),
          tap(() =>
            toastService.success(
              intl.formatMessage(
                {
                  id: `events.${attendeeType}.${
                    atendeeName ? 'successAtendee' : 'success'
                  }`,
                },
                { name: atendeeName },
              ),
            ),
          ),
          catchAndReport(err => {
            toastService.error(
              intl.formatMessage({ id: 'events.attendee.error' }),
            );
            return of(confirmInviteError(err));
          }),
        );
      },
    ),
  );

const confirmEventMultiInviteEpic$ = action$ =>
  action$.pipe(
    ofType(CONFIRM_MULTI_INVITE_REQUEST),
    switchMap(
      ({ payload: { eventId, invitees, rsvpType, isQuickEntry = false } }) => {
        let service = services.editEventInvitee$;

        const payload = {
          users: invitees.map(person => {
            const invitee = {
              rsvp: attendeeTypeToRsvpValues[rsvpType],
              userId: person.userId,
            };

            if (attendeeTypeToRsvpValues[rsvpType]) {
              invitee.rsvpCode = ' ';
            }
            return invitee;
          }),
        };

        return service(eventId, payload).pipe(
          tap(() =>
            toastService.success(
              intl.formatMessage({
                id: `events.success.${rsvpType}`,
              }),
            ),
          ),
          mergeMap(() =>
            isQuickEntry
              ? Observable.concat(
                  of(getEventByIdRequest(eventId)),
                  of(closeQuickEntryRSVPModal()),
                  of(setEventQELoading(false)),
                )
              : Observable.concat(
                  of(getEventByIdRequest(eventId)),
                  of(setEventQELoading(false)),
                ),
          ),
          catchAndReport(err => {
            toastService.error(
              intl.formatMessage({ id: 'events.attendee.error' }),
            );
            return of(confirmMultiInviteError(err));
          }),
        );
      },
    ),
  );

const updateInviteeAttendedStatusEpic$ = action$ =>
  action$.pipe(
    ofType(ATTENDED_TOGGLE_REQUEST),
    switchMap(
      ({
        payload: { eventId, person, viewEvent = false, isQuickEntry = false },
      }) => {
        let service = services.editEventInvitee$;

        const payload = {
          users: person,
        };

        return service(eventId, payload).pipe(
          tap(() =>
            toastService.success(
              intl.formatMessage({ id: 'events.attended.success' }),
              '',
              {
                autoClose: 1000,
              },
            ),
          ),
          mergeMap(() =>
            isQuickEntry
              ? Observable.concat(
                  of(attendedToggleResponse()),
                  of(closeQuickEntryRSVPModal()),
                  of(getEventByIdRequest(eventId)),
                )
              : viewEvent
              ? Observable.concat(
                  of(attendedToggleResponse()),
                  of(setEventReload(true)),
                )
              : Observable.concat(
                  of(getEventByIdRequest(eventId)),
                  of(attendedToggleResponse()),
                ),
          ),
          catchAndReport(err => {
            toastService.error(
              intl.formatMessage({ id: 'events.attended.error' }),
            );
            return of(attendedToggleError(err));
          }),
        );
      },
    ),
  );

const updateGuestAttendedStatusEpic$ = action$ =>
  action$.pipe(
    ofType(ATTENDED_TOGGLE_GUEST_REQUEST),
    switchMap(({ payload: { eventId, body, calendarEventGuestId } }) => {
      let service = services.updateGuest$;

      const payload = body;

      return service(eventId, calendarEventGuestId, payload).pipe(
        map(attendedToggleGuestResponse),
        tap(() =>
          toastService.success(
            intl.formatMessage({ id: 'events.attended.success' }),
          ),
        ),
        catchAndReport(err => {
          toastService.error(
            intl.formatMessage({ id: 'events.attended.error' }),
          );
          return of(attendedToggleGuestError(err));
        }),
      );
    }),
  );

const updateAntendeeRSVPEpic$ = action$ =>
  action$.pipe(
    ofType(UPDATE_PERSON_RSVP_REQUEST),
    switchMap(({ payload: { eventId, person } }) => {
      let service = services.editEventInvitee$;

      const payload = {
        users: [
          {
            ...person,
          },
        ],
      };

      return service(eventId, payload)
        .mapTo(getEventByIdRequest(eventId))
        .pipe(
          tap(() =>
            toastService.success(
              intl.formatMessage({ id: 'events.rsvp.success' }),
            ),
          ),
          catchAndReport(err => {
            toastService.error(intl.formatMessage({ id: 'events.rsvp.error' }));
            return of(updatePersonRSVPError(err));
          }),
        );
    }),
  );

const updateGuestRSVPEpic$ = action$ =>
  action$.pipe(
    ofType(UPDATE_GUEST_RSVP_REQUEST),
    switchMap(({ payload: { eventId, body, calendarEventGuestId } }) => {
      let service = services.updateGuest$;

      const payload = body;

      return service(eventId, calendarEventGuestId, payload)
        .mapTo(getExternalGuestsRequest(eventId))
        .pipe(
          tap(() =>
            toastService.success(
              intl.formatMessage({ id: 'events.rsvp.success' }),
            ),
          ),
          catchAndReport(err => {
            toastService.error(intl.formatMessage({ id: 'events.rsvp.error' }));
            return of(updateGuestRSVPError(err));
          }),
        );
    }),
  );

const returnParsedTime = (
  time,
  timeType,
  startDate,
  startTime,
  eventId,
  timezoneCode,
) => {
  switch (timeType) {
    case 'days':
      return time * 24;
    case 'weeks':
      return time * 24 * 7;
    case 'months':
      return time * 24 * 30;
    case 'asap': {
      if (eventId) {
        const currentDate = dateAndTimeToDateTime(startDate, startTime);

        const addHour = currentDate.add(1, 'hours');
        return addHour.diff(moment(), 'hours');
      }
      const utcEventTime = moment(
        dateAndTimeToDtoDateTime(startDate, startTime, timezoneCode),
      );

      const addHour = utcEventTime.add(1, 'hours');
      return addHour.diff(moment(), 'hours');
    }
    default:
      return +time;
  }
};

const splitEventDataFromReminders = (event, timezoneCode) => {
  let eventData = {};
  let reminderTimeTypes = [];
  let reminderTimes = [];
  let reminderIds = [];
  let reminders = [];

  const rsvpSetting =
    !event.sendToRSVPYes && !event.sendToRSVPYesNMaybe
      ? reminderRsvpSetting.ALL
      : event.sendToRSVPYes
      ? reminderRsvpSetting.YES
      : reminderRsvpSetting.YES_N_MAYBE;

  Object.keys(event).forEach(key => {
    if (key.split('_')[0] === 'reminderTime') {
      reminderTimes = [...reminderTimes, event[key]];
    }
    if (key.includes('reminderId')) {
      reminderIds = [...reminderIds, event[key]];
    }
    if (key.includes('reminderTimeType')) {
      reminderTimeTypes = [...reminderTimeTypes, event[key]];
    } else {
      eventData = { ...eventData, [key]: event[key] };
    }
  });

  reminders = reminderTimes.map((time, i) => {
    let reminder = {
      hours: 0,
      message: '',
    };

    if (reminderIds[i]) {
      reminder.reminderId = reminderIds[i];
    }

    reminder.hours = returnParsedTime(
      time,
      reminderTimeTypes[i],
      eventData.startDate,
      eventData.startTime,
      eventData.eventId,
      timezoneCode,
    );

    reminder.message = eventData.reminderMessage;
    reminder.reminderScheduleLookUpId =
      remindersTypeIds[reminderTimeTypes[i].toUpperCase()];
    reminder.reminderScheduleValue = Number(time);
    reminder.rsvp = rsvpSetting;
    return reminder;
  });

  const { news = [], olds = [] } = groupBy(reminders, ({ reminderId }) =>
    reminderId ? 'olds' : 'news',
  );

  return {
    eventData,
    reminders: { newReminders: news, oldReminders: olds },
  };
};

const getRequirementsIds = requirements =>
  requirements
    .map(requirement => requirement.id)
    .reduce(
      (prev, curr, i) =>
        i === 0 || i === requirements.length ? curr : prev + ',' + curr,
      '',
    );

const getRequirementsAtHome = requirements =>
  requirements
    .filter(({ isHomeSelected }) => isHomeSelected)
    .map(requirement => requirement.id)
    .reduce(
      (prev, curr, i) =>
        i === 0 || i === requirements.length ? curr : prev + ',' + curr,
      '',
    );

const formatRequirements = advancements =>
  advancements.map(({ id, requirementType, selectedRequirements }) => {
    const { withChildren, withoutChildren } =
      groupRequirementsByChildren(selectedRequirements);

    let formatedRequirement = {
      requirementType: requirementType,
      requirementTypeId: +id,
      isAdvancementComplete: false,
    };

    let requirementIds = getRequirementsIds(withoutChildren);
    let homeRequirementIds = getRequirementsAtHome(withoutChildren);

    withChildren.forEach(({ childRequirements }) => {
      requirementIds = cleanRequirementsIds(
        `${requirementIds},${getRequirementsIds(childRequirements)}`,
      );
      homeRequirementIds = cleanRequirementsIds(
        `${homeRequirementIds},${getRequirementsAtHome(childRequirements)}`,
      );
    });

    requirementIds = cleanRequirementsIds(requirementIds);
    if (requirementIds) {
      formatedRequirement = { ...formatedRequirement, requirementIds };
    }

    homeRequirementIds = cleanRequirementsIds(homeRequirementIds);
    if (homeRequirementIds) {
      formatedRequirement = { ...formatedRequirement, homeRequirementIds };
    }
    return formatedRequirement;
  });

const getRequirementsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_REQUIREMENTS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const selectedAdvancement = selectedAdvancementSel(state);
      const advancementType = advancementTypeSel(state);
      return services
        .getAdvancementRequirements$({
          advancementType,
          advancementId: selectedAdvancement.id || 0,
        })
        .pipe(
          map(getRequirementsResponse),
          catchAndReport(err => of(getRequirementsError(err))),
        );
    }),
  );

const deleteMultiInviteeEpic$ = action$ =>
  action$.pipe(
    ofType(DELETE_MULTI_INVITEE_REQUEST),
    switchMap(({ payload }) => {
      const { eventId, deleteUsers = [], isQuickEntry = false } = payload;

      return forkJoin(
        deleteUsers.map(({ userId }) =>
          services.deleteEventInvitee$(eventId, userId),
        ),
      ).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.rsvp.remove.success' }),
          );
        }),
        mergeMap(() =>
          isQuickEntry
            ? Observable.concat(
                of(getEventByIdRequest(eventId)),
                of(closeQuickEntryRSVPModal()),
                of(setEventQELoading(false)),
              )
            : Observable.concat(
                of(getEventByIdRequest(eventId)),
                of(setEventQELoading(false)),
              ),
        ),
        catchAndReport(err => of(deleteMultiInviteeError(err))),
      );
    }),
  );

const formatOldUsersForCloning = oldUsers => {
  const formattedUsers = oldUsers.map(user => ({
    userId: user.userId,
    rsvp: user.rsvp,
    attended: user.attended,
    primaryLeader: user.primaryLeader,
  }));
  return formattedUsers;
};

const getAttendeesObservables = (eventId, users, clone) => {
  const {
    new: newUsers = [],
    deleted: deletedUsers = [],
    old: oldUsers = [],
  } = users;

  const usersToAdd = !clone
    ? newUsers
    : [...newUsers, ...formatOldUsersForCloning(oldUsers)];
  const usersToUpdate = !clone ? oldUsers : [];
  const usersToDelete = !clone ? deletedUsers : [];

  //add new invitees
  // remove rsvp property if null,
  const addUsersPayload = usersToAdd.map(person => {
    if (person.rsvp === null) {
      // eslint-disable-next-line
      const { rsvp, ...rest } = person;
      return rest;
    }
    return person;
  });
  const newInviteesObservable = !isEmpty(usersToAdd)
    ? services.addEventInvitee$(eventId, addUsersPayload)
    : Observable.of([]);

  //delete existing invitees
  const deleteInviteesObservable = !isEmpty(usersToDelete)
    ? forkJoin(
        usersToDelete.map(({ userId }) =>
          services.deleteEventInvitee$(eventId, userId),
        ),
      )
    : Observable.of([]);

  //update rsvp and attended
  const payload = {
    users: usersToUpdate.map(person => ({
      userId: person.userId,
      attended: person.attended,
      rsvp: person.rsvp,
      rsvpCode: person.rsvpCode || ' ',
    })),
  };

  const oldInviteesObservable = !isEmpty(usersToUpdate)
    ? services.editEventInvitee$(eventId, payload)
    : Observable.of([]);

  return [
    newInviteesObservable,
    deleteInviteesObservable,
    oldInviteesObservable,
  ];
};

const getGuestsObservables = (eventId, users, deletedGuests) => {
  const { new: newGuests = [] } = users;

  //add new guest
  // remove rsvp property if null due to API
  const newGuestsPayload = newGuests.map(person => {
    if (person.rsvp === 'null') {
      // eslint-disable-next-line
      const { rsvp, ...rest } = person;
      return rest;
    }
    return person;
  });

  const newInviteesObservable = !isEmpty(newGuests)
    ? services.addGuests$(eventId, newGuestsPayload)
    : Observable.of([]);

  //delete existing invitees
  const deleteInviteesObservable = !isEmpty(deletedGuests)
    ? forkJoin(
        deletedGuests.map(({ calendarEventGuestId }) =>
          services.deleteGuest$(eventId, calendarEventGuestId),
        ),
      )
    : Observable.of([]);

  return [newInviteesObservable, deleteInviteesObservable];
};

const getRemindersObservables = (
  eventId,
  areRemindersDisabled,
  reminders,
  originalReminders = [],
) => {
  const { newReminders, oldReminders } = reminders;

  if (areRemindersDisabled && !isEmpty(oldReminders)) {
    return forkJoin(
      oldReminders.map(reminder =>
        services.deleteEventReminder$(eventId, reminder.reminderId),
      ),
    );
  }

  //add new reminders
  const newRemindersObservable =
    !areRemindersDisabled && !isEmpty(newReminders)
      ? forkJoin(
          newReminders.map(reminder =>
            reminder.reminderScheduleLookUpId === remindersTypeIds.ASAP
              ? services.sendEventReminder$(eventId, reminder)
              : services.addEventReminder$(eventId, reminder),
          ),
        )
      : Observable.of([]);

  //update old reminders
  const oldRemindersObservable =
    !areRemindersDisabled && !isEmpty(oldReminders)
      ? forkJoin(
          oldReminders.map(reminder => {
            // ASAP reminders have their own API req that is POST only
            if (reminder.reminderScheduleLookUpId === remindersTypeIds.ASAP) {
              const foundReminder = originalReminders.find(
                ({ reminderId }) => reminderId === reminder.reminderId,
              );
              const ogWasAsap = foundReminder
                ? foundReminder.reminderScheduleLookUpId ===
                  remindersTypeIds.ASAP
                : false;

              // If it was ASAP before, do nothing, otherwise send the POST and
              // the old remider will be deleted in removedReminders bellow
              return ogWasAsap
                ? Observable.of({})
                : services.sendEventReminder$(eventId, reminder);
            }

            return services.editEventReminder$(eventId, reminder);
          }),
        )
      : Observable.of([]);

  // delete removed reminders
  const removedReminders = originalReminders.filter(
    ({ reminderId, reminderScheduleLookUpId }) => {
      const foundReminder = oldReminders.find(
        reminder => reminder.reminderId === reminderId,
      );

      // If it was not ASAP and now it is, we want to delete it to avoid duplicates.
      if (foundReminder && reminderScheduleLookUpId !== remindersTypeIds.ASAP) {
        const foundIsAsap =
          foundReminder.reminderScheduleLookUpId === remindersTypeIds.ASAP;
        return foundIsAsap;
      }

      return !foundReminder;
    },
  );
  const removeRemindersObservable =
    !originalReminders.length || !removedReminders.length
      ? Observable.of([])
      : forkJoin(
          removedReminders.map(reminder =>
            services.deleteEventReminder$(eventId, reminder.reminderId),
          ),
        );

  return [
    newRemindersObservable,
    oldRemindersObservable,
    removeRemindersObservable,
  ];
};

const getEventUnitsObservables = (
  eventId,
  defaultEventUnitId,
  units = [],
  isEdit,
  originalEventUnits = [],
) => {
  let unitsToAdd = [];
  let unitsToDelete = [];

  if (isEdit) {
    units.forEach(unit => {
      const foundInOriginal = originalEventUnits.find(ogUnit => {
        const id = ogUnit.denId || ogUnit.patrolId || ogUnit.unitId;

        return unit.id === id;
      });

      if (!foundInOriginal) unitsToAdd.push(unit);
    });

    originalEventUnits.forEach(ogUnit => {
      const id = ogUnit.denId || ogUnit.patrolId || ogUnit.unitId;

      if (defaultEventUnitId === id) {
        return;
      }

      const foundInUpdatedUnits = units.find(unit => unit.id === id);

      if (!foundInUpdatedUnits) unitsToDelete.push(ogUnit);
    });
  } else {
    unitsToAdd = units;
  }

  const formattedUnits = unitsToAdd.map(unit =>
    // NOTE: only subunits (den, patrol) have unitId
    ({
      unitId: unit.unitId ? unit.unitId : unit.id,
      denId: unit.unitId && unit.isDen ? unit.id : undefined,
      patrolId: unit.unitId && !unit.isDen ? unit.id : undefined,
    }),
  );

  //add new units
  const newUnitsObservable = !isEmpty(formattedUnits)
    ? forkJoin(
        formattedUnits.map(formattedUnit =>
          services.addEventUnits$(eventId, formattedUnit),
        ),
      )
    : Observable.of([]);

  const deleteUnitsObservable = !isEmpty(unitsToDelete)
    ? forkJoin(
        unitsToDelete.map(({ denId, patrolId, unitId }) =>
          services.deleteEventUnit$(eventId, denId || patrolId || unitId),
        ),
      )
    : Observable.of([]);

  return [newUnitsObservable, deleteUnitsObservable];
};

const saveEventEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_EVENT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const {
        event: principalEvent,
        onSuccess,
        onFailure,
        clone = false,
      } = payload;
      const originalEvent = eventDetailsSel(state);
      const { areRemindersDisabled } = principalEvent;
      const loggedInUserId = loggedInUserIdSel(state);
      const { unitId } = selectedOrganizationSel(state);
      const usersByStatus = selectedEventUsersByStatusSel(state);
      const selectedUnits = selectedEventUnitsSel(state);
      const removedGuestList = removedGuestListSel(state);
      const guestsByStatus = selectedEventGuestsByStatusSel(state);
      const { defaultEventUnit, updatedSelectedUnits } =
        getDefaultsEventUnits(selectedUnits);
      const { unitTimeZoneCode: timezoneCode } = unitInfoSel(state);

      const { eventData, reminders } = splitEventDataFromReminders(
        principalEvent,
        timezoneCode,
      );

      const events = [
        {
          userId: loggedInUserId,
          unitId: defaultEventUnit?.unitId || +unitId,
          denId: defaultEventUnit?.denId || undefined,
          patrolId: defaultEventUnit?.patrolId || undefined,
          ...eventData,
        },
      ];

      const selectedEventAdvancements = selectedEventAdvancementsSel(state);
      const requirements = formatRequirements(selectedEventAdvancements) || [];
      const createdEvents = [];
      const addGuestsObservable = [];
      let resEventId;

      return forkJoin(
        events.map(event =>
          services.saveEvent$(event, timezoneCode).pipe(
            switchMap(response => {
              resEventId = event.eventId || response.eventId;
              const eventId = event.eventId || response.eventId;

              if (!principalEvent.id) {
                // if it's a new event
                createdEvents.push({ eventId, ...event });
              }

              const forDeleteList = removedGuestList.filter(
                person => 'calendarEventGuestId' in person,
              );
              const guestsObservables = getGuestsObservables(
                eventId,
                guestsByStatus,
                forDeleteList,
              );

              const attendeesObservables = getAttendeesObservables(
                eventId,
                usersByStatus,
                clone,
              );

              const eventUnitsObservables = getEventUnitsObservables(
                eventId,
                defaultEventUnit.id,
                event.eventId ? selectedUnits : updatedSelectedUnits,
                !!event.eventId,
                originalEvent.units,
              );

              //temporary disabled on UI
              const requirementsObservable = !isEmpty(requirements)
                ? services.addEventRequirements$(eventId, requirements)
                : Observable.of([]);

              return forkJoin(
                [requirementsObservable].concat(
                  attendeesObservables,
                  eventUnitsObservables,
                  addGuestsObservable,
                  guestsObservables,
                ),
              );
            }),
            switchMap(() => {
              const remindersObservable = getRemindersObservables(
                resEventId,
                areRemindersDisabled,
                reminders,
                originalEvent.reminders,
              );
              return forkJoin(remindersObservable);
            }),
          ),
        ),
      ).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.saveEvent.success' }),
          );
          onSuccess(resEventId);
        }),
        mergeMap(() =>
          Observable.concat(
            of(rtkRefetchUnitEvents({ unitIds: [unitId] })),
            of(saveEventResponse()),
            of(getEventByIdRequest(resEventId)),
          ),
        ),
        catchAndReport(err => {
          onFailure(resEventId);
          return of(saveEventError(err));
        }),
      );
    }),
  );

const rtkUnitEventsRefetchEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(rtkRefetchUnitEvents.type),
    filter(() =>
      Boolean(featureFlags.getFlag('SBL_5138_OPTIMIZE_CALENDAR_EVENTS')),
    ),
    switchMap(response => {
      const state = state$.value;
      const { unitIds } = response?.payload || [];

      const toInvalidate = unitIds.map(unitId => ({
        id: `${unitId}`,
        type: tagTypes.UnitEvents,
      }));

      const invalidateAction = esbApi.util.invalidateTags([
        { type: tagTypes.UnitEvents },
        // TODO: improve per-unit invalidation
        ...toInvalidate,
      ]);
      const selectedDate = selectedDateSel(state);

      return Observable.concat(
        of(invalidateAction),
        of(getEventsRequest({ selectedDate })),
      );
    }),
  );

const getEventTypesEpic$ = action$ =>
  action$.pipe(
    ofType(GET_EVENT_TYPES_REQUEST),
    switchMap(() =>
      services.getEventTypes$().pipe(
        map(getEventTypesResponse),
        catchAndReport(err => of(getEventTypesError(err))),
      ),
    ),
  );

const deleteEventReminderEpic$ = action$ =>
  action$.pipe(
    ofType(DELETE_EVENT_REMINDER_REQUEST),
    switchMap(({ payload }) => {
      const { eventId, reminderId } = payload;
      return services.deleteEventReminder$(eventId, reminderId).pipe(
        map(() => deleteEventReminderResponse(reminderId)),
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.deleteReminder.success' }),
          );
        }),
        catchAndReport(err => of(deleteEventReminderError(err))),
      );
    }),
  );

const deleteEventEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_EVENTS_REQ),
    switchMap(({ payload }) => {
      const state = state$.value;
      const events = eventsSel(state);
      const event = events?.find(item => item.id === payload);
      const unitIds = event?.resource?.units?.map(item => item.unitId);
      return services.deleteEvent$(payload).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.deleteEvent.success' }),
          );
        }),
        mergeMap(() =>
          Observable.concat(
            of(deleteEventReminderResponse()),
            of(rtkRefetchUnitEvents({ unitIds })),
            of(deleteEventsResponse()),
            of(goToCalendarPage()),
          ),
        ),
        catchAndReport(err => of(deleteEventsError(err))),
      );
    }),
  );

const saveRecurrentEventEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_RECURRENT_EVENT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { event: eventPayload, onSuccess, onFailure } = payload;
      const selectedUsersIds = selectedEventUsersSel(state).map(
        ({ userId }) => userId,
      );
      const loggedInUserId = loggedInUserIdSel(state);
      const { unitId } = selectedOrganizationSel(state);
      const filteredEventTypes = filteredEventTypesSel(state);
      const selectedUnits = selectedEventUnitsSel(state);
      const allRosters = allRostersSel(state);
      const { unitTimeZoneCode: timezoneCode } = unitInfoSel(state);
      const updatedSelectedUnits = getRecurrentEventUnits(selectedUnits);
      const updatedSelectedUnitsWithInvitees = getRecurrentEventInvitees(
        allRosters,
        selectedUsersIds,
        updatedSelectedUnits,
      );
      const { eventData, reminders } =
        splitEventDataFromReminders(eventPayload);
      const event = formatRecurrentEvent(eventData);
      const remindersHours = reminders.newReminders
        .concat(reminders.oldReminders)
        .map(
          ({
            hours,
            message,
            reminderScheduleLookUpId,
            reminderScheduleValue,
          }) => ({
            hours,
            reminderScheduleLookUpId,
            reminderScheduleValue,
            reminderText: message || 'Placeholder', //TODO: remove placeholder when message reminders is reworked
          }),
        );
      event.description =
        event.description === null ? undefined : event.description;
      event.notes = event.notes === null ? undefined : event.notes;

      event.eventTypeId = filteredEventTypes.find(
        type => type.name === event.eventType,
      ).id;

      const { adjustedStartDate, adjustedEndDate } = getAdjustedRecurrentDate(
        event.startDate,
        event.endDate,
        eventData.recurrenceId,
      );

      const { fromDate, fromTime, toDate, toTime } =
        dateAndTimeToDtoToDateToTime(
          adjustedStartDate,
          event.startTime,
          adjustedEndDate,
          event.endTime,
          timezoneCode,
        );

      event.fromDate = fromDate;
      event.toDate = toDate;
      event.fromTime = fromTime;
      event.toTime = toTime;
      event.units = updatedSelectedUnitsWithInvitees.length
        ? updatedSelectedUnitsWithInvitees
        : [
            {
              unitId: +unitId,
              userIds: [loggedInUserId],
            },
          ];
      event.reminders =
        !eventPayload.areRemindersDisabled && remindersHours.length
          ? remindersHours
          : undefined;

      const unitIds = updatedSelectedUnitsWithInvitees?.map(
        item => item?.unitId,
      ) || [unitId];

      return services.createRecurrentEvent$(event).pipe(
        map(response => saveEventResponse(response)),
        map(() => rtkRefetchUnitEvents({ unitIds })),
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.saveEvent.success' }),
          );
          onSuccess(undefined, true);
        }),
        catchAndReport(err => {
          onFailure(err);
          return of(saveEventError(err));
        }),
      );
    }),
  );

const updateRecurrentEventEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_RECURRENT_EVENT_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { recurrentId, event, onSuccess, onFailure } = payload;
      const { new: newUsers = [], deleted: deletedUsers = [] } =
        selectedEventUsersByStatusSel(state);
      const data = {
        ...event,
        addUsers: newUsers.length
          ? newUsers.map(({ userId }) => +userId)
          : undefined,
        removeUsers: deletedUsers.length
          ? deletedUsers.map(({ userId }) => +userId)
          : undefined,
      };

      return services.updateRecurrentEvent$(recurrentId, data).pipe(
        map(response => saveEventResponse(response)),
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.saveEvent.success' }),
          );
          onSuccess(undefined, true);
        }),
        catchAndReport(err => {
          onFailure(err);
          return of(saveEventError(err));
        }),
      );
    }),
  );

const updateRecurrentEventByRecreationEpic$ = action$ =>
  action$.pipe(
    ofType(UPDATE_RECURRENT_EVENT_BY_RECREATION_REQUEST),
    switchMap(({ payload }) => {
      const { recurrentId, event, onSuccess, onFailure } = payload;

      return services
        .deleteRecurrentEvent$({
          cancellationDate: moment().format('YYYY-MM-DD'),
          recurringEventId: recurrentId,
        })
        .pipe(
          map(() => saveRecurrentEventRequest(event, onSuccess, onFailure)),
          catchAndReport(err => {
            onFailure(err);
            return of(saveEventError(err));
          }),
        );
    }),
  );

const deleteRecurrentEventEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_RECURRENT_EVENTS_REQ),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { dateFrom, deleteOption } = deleteRecurrentEventInfoSel(state);

      const cancellationDate =
        deleteOption === DeleteRecurrentEventOption.AllSinceToday
          ? moment().format('YYYY-MM-DD')
          : moment(dateFrom).format('YYYY-MM-DD');

      return services
        .deleteRecurrentEvent$({
          cancellationDate,
          recurringEventId: payload,
        })
        .pipe(
          tap(() => {
            toastService.success(
              intl.formatMessage({ id: 'events.deleteEvent.success' }),
            );
          }),
          mergeMap(() =>
            Observable.concat(
              of(rtkRefetchUnitEvents({ unitIds: [] })),
              of(deleteEventReminderResponse()),
              of(setDeleteRecurrentEventInfo({ modalVisible: false })),
              of(goToCalendarPage()),
            ),
          ),
          catchAndReport(err => of(deleteEventsError(err))),
        );
    }),
  );

const getAllSubUnitsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ALL_SUBUNITS_REQUEST),
    debounce(() => interval(2000)),
    throttleTime(3000),
    take(5),
    switchMap(() => {
      const state = state$.value;
      const units = organizationPositionsSel(state);

      const troopsAndPaksUnits = units
        .filter(
          ({ unitTypeId }) =>
            +unitTypeId === unitTypeIdForUnitType.Pack ||
            +unitTypeId === unitTypeIdForUnitType.Troop,
        )
        .map(unit => {
          const [orgGuid] = unit.organizationGuid.split('*');
          return {
            ...unit,
            organizationGuid: orgGuid,
          };
        });

      const uniqTroopsAndPaksUnits = uniqBy(
        troopsAndPaksUnits,
        'organizationGuid',
      );

      if (!uniqTroopsAndPaksUnits.length) {
        return Observable.of(getAllSubunitsResponse([]));
      }

      return forkJoin(
        uniqTroopsAndPaksUnits.map(({ organizationGuid }) => {
          const [orgGuid] = organizationGuid.split('*');
          return subUntisServices.getSubUnits$(orgGuid);
        }),
      ).pipe(
        switchMap(orgs =>
          Observable.concat(
            of(getAllSubunitsResponse(orgs)),
            of(triggerCalendarRequest(false)),
          ),
        ),
        catchAndReport(err =>
          Observable.concat(
            of(getAllSubunitsError(err)),
            of(triggerCalendarRequest(false)),
          ),
        ),
      );
    }),
  );

const getUnitUsersObvservable$ = organizationGuid =>
  forkJoin(
    rosterServices.getYouths$(organizationGuid, [404]),
    rosterServices.getAdults$(organizationGuid, [404]),
    rosterServices.getParents$(organizationGuid, [400, 404]),
  ).mergeMap(([youths, adults, parents]) => {
    const defaultUnit = { id: youths.id, unitId: youths.id };

    const formattedYouths = youths.users.map(youth => {
      const {
        userId,
        memberId,
        personFullName,
        firstName,
        lastName,
        positions,
        pictureUrl,
        nickName,
        address1,
        city,
        state,
        zip,
      } = youth;

      const unitsByPositions = positions.map(pos => ({
        id: pos.denId || pos.patrolId || defaultUnit.id,
        unitId: defaultUnit.id,
        patrolId: pos.patrolId,
        denId: pos.denId,
      }));

      const deduppedUnits = uniqBy([defaultUnit, ...unitsByPositions], 'id');

      return {
        userId,
        memberId,
        personFullName,
        firstName,
        lastName,
        isAdult: false,
        units: deduppedUnits,
        pictureUrl,
        nickName,
        positions,
        address1,
        city,
        state,
        zip,
      };
    });
    const formattedAdults = adults.users
      .filter(
        user =>
          !(
            user.positions.length === 1 &&
            user.positions.some(position =>
              AdminPositionIds.includes(position.positionId),
            )
          ),
      )
      .map(
        ({
          userId,
          memberId,
          personFullName,
          firstName,
          lastName,
          nickName,
          positions,
          pictureUrl,
        }) => {
          const unitsByPositions = positions.map(pos => ({
            id: pos.denId || pos.patrolId || defaultUnit.id,
            unitId: defaultUnit.id,
            patrolId: pos.patrolId,
            denId: pos.denId,
          }));

          const deduppedUnits = uniqBy(
            [defaultUnit, ...unitsByPositions],
            'id',
          );

          return {
            userId,
            memberId,
            personFullName,
            firstName,
            lastName,
            nickName,
            isAdult: true,
            isLeader: true,
            units: deduppedUnits,
            pictureUrl,
            positions,
          };
        },
      );
    const formattedParents = parents
      .map(
        ({
          parentInformation,
          parentUserId: userId,
          youthUserId: childUserId,
        }) => {
          // Parents don't have positions, I am giving them their child units for easy linking to units/subunits
          const foundChild = formattedYouths.find(
            child => child.userId === childUserId,
          );

          return {
            userId,
            memberId: parentInformation.memberId,
            personFullName: parentInformation.personFullName,
            firstName: parentInformation.firstName,
            lastName: parentInformation.lastName,
            nickName: parentInformation.nickName,
            isAdult: true,
            isParent: true,
            childUserId,
            units: foundChild
              ? foundChild.units.map(u => ({ ...u, isChildUnit: true }))
              : [{ ...defaultUnit, isChildUnit: true }],
          };
        },
      )
      .filter(parent => parent.userId);

    const listLeadersParents = formattedAdults
      .filter(adult =>
        formattedParents.some(parent => adult.userId === parent.userId),
      )
      .map(adult => adult.userId);

    const updatedFormattedAdults = formattedAdults.map(adult => {
      if (listLeadersParents.some(userId => adult.userId === userId))
        return { ...adult, isParent: true };
      return adult;
    });

    const updatedFormattedParents = formattedParents.map(parent => {
      if (listLeadersParents.some(userId => parent.userId === userId))
        return { ...parent, isLeader: true };
      return parent;
    });

    return Observable.of([
      ...formattedYouths,
      ...updatedFormattedAdults,
      ...updatedFormattedParents,
    ]);
  });

const getUnitMembersEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_UNIT_ROSTER_REQUEST),
    switchMap(() => {
      const state = state$.value;

      const isParentOrYouth = isParentOrYouthMemberSel(state);
      const { canEditCalendar } = organizationPositionSel(state);
      if (isParentOrYouth && !canEditCalendar) {
        return Observable.of(
          getUnitRosterResponse({ unitGuids: [], roster: [] }),
        );
      }

      const selectedUnits = selectedEventUnitsSel(state);
      const alreadyFetchedUnitsGuids = allRostersFetchedGuidsSel(state);
      const allUnitsSubunits = allUnitsSubunitsSel(state);
      const allUnitsRosters = allRostersSel(state);

      const lastUnit = selectedUnits[selectedUnits.length - 1];
      const lastUnitId = lastUnit?.id;
      let selectedUnitFilteredMembers = [];

      // If selected unit includes subunits, retrieve main unit and add to valid units
      const validUnits = uniqBy(
        selectedUnits
          .reduce((curr, unit) => {
            if (!unit.isSubUnit) return [...curr, unit];

            let mainUnit = allUnitsSubunits.find(
              ({ id }) => unit.unitId === id,
            );
            if (mainUnit?.organizationGuid?.includes('*')) {
              const { orgGuid } = extractOrgGuidAndUserId(
                mainUnit.organizationGuid,
              );
              mainUnit = {
                ...mainUnit,
                organizationGuid: orgGuid,
              };
            }

            return [...curr, mainUnit];
          }, [])
          .filter(
            unit => !alreadyFetchedUnitsGuids.includes(unit.organizationGuid),
          ),
        'id',
      );

      if (!validUnits.length) {
        if (lastUnit?.isSubUnit) {
          selectedUnitFilteredMembers = getUnitFilteredMembers(
            allUnitsRosters,
            lastUnitId,
          );
        }

        return Observable.concat(
          of(getUnitRosterResponse({ unitGuids: [], roster: [] })),
          of(selectAllSubAttendeesEvent(selectedUnitFilteredMembers)),
        );
      }
      const validUnitsGuids = validUnits.map(unit => unit.organizationGuid);

      return forkJoin(
        validUnits.map(({ organizationGuid }) =>
          getUnitUsersObvservable$(organizationGuid),
        ),
      ).pipe(
        switchMap(unitUsers => {
          const reducedUsers = unitUsers.flat().reduce((acc, current) => {
            if (!current.memberId) {
              return acc;
            }

            if (!acc?.length) {
              return [current];
            }

            const foundUserIndex = acc.findIndex(
              user => user.userId === current.userId,
            );

            if (foundUserIndex > -1) {
              const updatedUnits = uniqBy(
                [...acc[foundUserIndex].units, ...current.units],
                'id',
              );
              acc[foundUserIndex].units = updatedUnits;
              return acc;
            }

            acc.push(current);
            return acc;
          }, []);

          if (lastUnit?.isSubUnit) {
            const completeUnitRoster = [...allUnitsRosters, ...reducedUsers];
            selectedUnitFilteredMembers = getUnitFilteredMembers(
              completeUnitRoster,
              lastUnitId,
            );
          }
          return Observable.concat(
            of(
              getUnitRosterResponse({
                unitGuids: validUnitsGuids,
                roster: reducedUsers,
              }),
            ),
            of(selectAllSubAttendeesEvent(selectedUnitFilteredMembers)),
          );
        }),
        catchAndReport(err => Observable.of(getUnitRosterError(err))),
      );
    }),
  );

const getExternalGuestsEpic$ = action$ =>
  action$.pipe(
    ofType(GET_EXTERNAL_GUESTS_REQUEST),
    switchMap(({ payload: eventId }) =>
      services.getGuests$(eventId).pipe(
        map(getExternalGuestsResponse),
        catchAndReport(err => of(getExternalGuestsError(err))),
      ),
    ),
  );

const addGuestsEpic$ = action$ =>
  action$.pipe(
    ofType(ADD_GUESTS_REQUEST),
    switchMap(({ payload }) => {
      const { eventId, data } = payload;

      return services
        .addGuests$(eventId, data)
        .mapTo(getExternalGuestsRequest(eventId))
        .pipe(
          tap(() => {
            toastService.success(
              intl.formatMessage({ id: 'events.addGuestModal.success' }),
            );
          }),
          catchAndReport(err => of(addGuestsError(err))),
        );
    }),
  );

const saveActivityEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_ACTIVITY_EVENT),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { unitTimeZoneCode: timezoneCode } = unitInfoSel(state);
      const { activityFormValues, onSuccess } = payload;
      const {
        formData,
        usersToAdd,
        usersToUpdate,
        usersToDelete,
        usersToAddToEvent,
      } = activityFormValues;
      const { startDate, startTime, endDate, endTime } = formData;
      const startDateTime = dateAndTimeToDtoDateTime(
        startDate,
        startTime,
        timezoneCode,
      );
      const endDateTime = dateAndTimeToDtoDateTime(
        endDate,
        endTime,
        timezoneCode,
      );
      const eventDetails = eventDetailsSel(state);
      const { eventId } = payloadSel(state);
      let validActivityData = {
        ...formData,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
      };

      const saveService = formData.id
        ? services.updateActivity$(formData.id, validActivityData)
        : services.createActivity$(validActivityData);

      return saveService.pipe(
        mergeMap(response => {
          if (formData.id) {
            return Observable.of({});
          }

          const { activityId } = response;
          const { startDate, endDate, linkedActivities = [] } = eventDetails;
          return services.editEvent$(+eventId, {
            startDate: startDate.utc().format(),
            endDate: endDate.utc().format(),
            linkedActivities: [...linkedActivities, +activityId],
          });
        }),
        switchMap(() => {
          if (!formData.id) {
            return Observable.of({});
          }

          const addObserver = usersToAdd.length
            ? services.addActivityRecords$(formData.id, usersToAdd)
            : Observable.of({});
          const updateObserver = usersToUpdate.length
            ? services.editActivityRecords$(formData.id, usersToUpdate)
            : Observable.of({});
          const deleteObserver = usersToDelete.length
            ? usersToDelete.map(record =>
                services.deleteActivityRecord$(formData.id, record.id),
              )
            : [Observable.of({})];
          const updateUnregistered = formData.nonRegisteredOrgParticipants
            ? services.editNonRegisteredOrgParticipants$(
                formData.id,
                formData.nonRegisteredOrgParticipants.id,
                formData.nonRegisteredOrgParticipants,
              )
            : Observable.of({});

          return forkJoin(
            addObserver,
            updateObserver,
            ...deleteObserver,
            updateUnregistered,
          );
        }),
        mergeMap(() =>
          usersToAddToEvent.length
            ? services.addEventInvitee$(eventId, usersToAddToEvent)
            : Observable.of({}),
        ),
        tap(() => {
          onSuccess();
          toastService.success(
            intl.formatMessage({ id: 'activitiesModal.saveSuccess' }),
          );
        }),
        mergeMap(() =>
          Observable.concat(
            of(saveActivityEventResponse()),
            of(getEventByIdRequest(eventId)),
          ),
        ),
        catchAndReport(err => of(saveActivityEventError(err))),
      );
    }),
  );

const getActivitesEpic$ = action$ =>
  action$.pipe(
    ofType(GET_ACTIVITIES_EVENT),
    switchMap(({ payload }) =>
      forkJoin(
        payload.map(activityId => services.getEventActivityById$(activityId)),
      ).pipe(
        map(getActivitiesEventResponse),
        catchAndReport(err => of(getActivitiesEventError(err))),
      ),
    ),
  );

const deleteActivityEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ACTIVITY_EVENT),
    switchMap(({ payload: activityId }) => {
      const state = state$.value;
      const { eventId } = payloadSel(state);

      return services.deleteActivity$(+activityId).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'activitiesModal.saveSuccess' }),
          );
        }),
        mergeMap(() =>
          Observable.concat(
            of(deleteActivityEventResponse()),
            of(getEventByIdRequest(eventId)),
          ),
        ),
        catchAndReport(err => of(deleteActivityEventError(err))),
      );
    }),
  );

const getCalendarRecordsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_CALENDAR_RECORDS_REQ),
    switchMap(() => {
      const state = state$.value;
      const loggedInUserId = +loggedInUserIdSel(state);

      return services.getCalendarRecords$(loggedInUserId).pipe(
        map(response => getCalendarRecordsResponse(response)),
        catchAndReport(err => of(getCalendarRecordsError(err))),
      );
    }),
  );

const saveCalendarRecordsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_CALENDAR_RECORDS_REQ),
    mergeMap(({ payload }) => {
      const { data, onSuccess = () => {}, onFailure = () => {} } = payload;
      const state = state$.value;
      const loggedInUserId = +loggedInUserIdSel(state);

      return (
        data.userCalendarId
          ? services.updateCalendarRecord$(loggedInUserId, data)
          : services.createCalendarRecord$(loggedInUserId, data)
      ).pipe(
        mergeMap(() => {
          onSuccess();
          return Observable.concat(
            of(saveCalendarRecordsResponse()),
            of(getCalendarRecordsRequest()),
          );
        }),
        catchAndReport(err => {
          onFailure();
          return of(saveCalendarRecordsError(err));
        }),
      );
    }),
  );

const savePublicRSVPEpic$ = action$ =>
  action$.pipe(
    ofType(SAVE_PUBLIC_RSVP_REQ),
    switchMap(({ payload }) => {
      const {
        eventId,
        data,
        onSuccess = () => {},
        onFailure = () => {},
      } = payload;

      return services.postPublicEventRSVP$(eventId, data).pipe(
        mergeMap(() => {
          onSuccess();
          return Observable.concat(of(savePublicRSVPResponse()));
        }),
        catchAndReport(err => {
          onFailure();
          return of(savePublicRSVPError(err));
        }),
      );
    }),
  );

const sendPublicRSVPEpic$ = action$ =>
  action$.pipe(
    ofType(SEND_PUBLIC_RSVP_REQ),
    switchMap(({ payload }) => {
      const {
        eventId,
        data,
        onSuccess = () => {},
        onFailure = () => {},
      } = payload;

      return forkJoin(
        data.recipients.map((_, index) =>
          services.sendPublicEventRSVP$(eventId, {
            ...data,
            recipients: data.recipients[index],
          }),
        ),
      ).pipe(
        tap(() => {
          toastService.success(
            intl.formatMessage({ id: 'events.publicRsvp.saveSuccess' }),
          );
        }),
        mergeMap(() => {
          onSuccess();
          return Observable.concat(of(sendPublicRSVPResponse()));
        }),
        catchAndReport(err => {
          onFailure();
          return of(sendPublicRSVPError(err));
        }),
      );
    }),
  );

// Get event comments

const getEventComments$ = (action$, state$) =>
  action$.ofType(GET_EVENT_COMMENTS_REQ).switchMap(({ payload }) =>
    Observable.timer(0, 10000)
      .switchMap(() =>
        services
          .getEventComments$(payload)
          .mergeMap(response => {
            const state = state$.value;
            const loggedInUserId = loggedInUserIdSel(state);
            const comments = transformGetEventCommentsResponse(
              response,
              loggedInUserId,
            );
            const pending = containsPendingComments(comments);

            if (pending) {
              return Observable.concat(of(getEventCommentsResponse(comments)));
            }

            return Observable.concat(
              of(getEventCommentsResponse(comments)),
              of(getEventCommentsCancel()),
            );
          })
          .catchAndReport(error => {
            toastService.dismiss(undefined);

            return of(getEventCommentsError(error));
          }),
      )
      .takeUntil(action$.ofType(GET_EVENT_COMMENTS_CANCEL)),
  );

// Post event comment

const postEventComment$ = (action$, state$) =>
  action$
    .ofType(POST_EVENT_COMMENT_REQ)
    .switchMap(({ payload }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const requestBody = {
        ...payload,
        scoutUserId: loggedInUserId,
        subject: 'comment-subject',
      };

      return services
        .postEventComment$(requestBody)
        .map(() => payload.calendarEventId);
    })
    .mergeMap(eventId => {
      toastService.success(
        intl.formatMessage({ id: 'EventComments.addCommentSuccess' }),
      );

      return Observable.concat(
        of(postEventCommentResponse()),
        of(getEventCommentsRequest(eventId)),
      );
    })
    .catchAndReport(error => {
      toastService.dismiss(undefined);
      toastService.error(
        intl.formatMessage({ id: 'EventComments.addCommentFailed' }),
      );

      return of(postEventCommentError(error));
    });

// Post event comment with files

const postEventCommentWithFiles$ = (action$, state$) =>
  action$
    .ofType(POST_EVENT_COMMENT_WITH_FILES_REQ)
    .switchMap(
      ({ payload: { body, photo, calendarEventId, calendarEventType } }) => {
        const state = state$.value;
        const loggedInUserId = loggedInUserIdSel(state);
        const requestBody = {
          body,
          calendarEventId,
          calendarEventType,
          fileContentType: photo.type,
          scoutUserId: Number(loggedInUserId),
          subject: 'comment-subject',
        };

        return services
          .postEventCommentWithFiles$(loggedInUserId, requestBody)
          .map(({ preSignedUrl }) =>
            services.uploadCommentImageToS3$(photo, preSignedUrl),
          )
          .map(() => calendarEventId);
      },
    )
    .mergeMap(eventId => {
      toastService.success(
        intl.formatMessage({ id: 'EventComments.addCommentSuccess' }),
      );

      return Observable.concat(
        of(postEventCommentWithFilesResponse()),
        of(getEventCommentsRequest(eventId)),
      );
    })
    .catchAndReport(error => {
      toastService.dismiss(undefined);
      toastService.error(
        intl.formatMessage({ id: 'EventComments.addCommentFailed' }),
      );

      return of(postEventCommentWithFilesError(error));
    });

// Put event comment

const putEventComment$ = (action$, state$) =>
  action$
    .ofType(PUT_EVENT_COMMENT_REQ)
    .switchMap(({ payload }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const { commentId, comments } = payload;

      return services
        .putEventComment$(loggedInUserId, commentId, { comments })
        .map(() => payload.eventId);
    })
    .mergeMap(eventId => {
      toastService.success(
        intl.formatMessage({ id: 'EventComments.updateCommentSuccess' }),
      );

      return Observable.concat(
        of(putEventCommentResponse()),
        of(getEventCommentsRequest(eventId)),
      );
    })
    .catchAndReport(error => {
      toastService.dismiss(undefined);
      toastService.error(
        intl.formatMessage({ id: 'EventComments.updateCommentFailed' }),
      );

      return of(putEventCommentError(error));
    });

// Delete event comment

const deleteEventComment$ = action$ =>
  action$
    .ofType(DELETE_EVENT_COMMENT_REQ)
    .switchMap(({ payload }) =>
      services
        .deleteEventComment$(payload.commentId)
        .map(() => payload.commentId),
    )
    .map(commentId => {
      toastService.success(
        intl.formatMessage({ id: 'EventComments.deleteCommentSuccess' }),
      );

      return deleteEventCommentResponse(commentId);
    })
    .catchAndReport(error => of(deleteEventCommentError(error)));

// Delete event comment photo

const deleteEventCommentPhoto$ = (action$, state$) =>
  action$
    .ofType(DELETE_EVENT_COMMENT_PHOTO_REQ)
    .switchMap(({ payload: { commentId, eventId, photoId } }) => {
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);

      return services
        .deleteEventCommentPhoto$(loggedInUserId, commentId, photoId)
        .map(() => ({
          commentId,
          eventId,
          photoId,
        }));
    })
    .mergeMap(({ commentId, eventId, photoId }) => {
      toastService.success(
        intl.formatMessage({ id: 'EventComments.deleteCommentPhotoSuccess' }),
      );

      return Observable.concat(
        of(deleteEventCommentPhotoResponse(commentId, photoId)),
        of(getEventCommentsRequest(eventId)),
      );
    })
    .catchAndReport(error => of(deleteEventCommentPhotoError(error)));

export default combineEpics(
  redirectToCalendarEpic$,
  getEventByIdEpic$,
  getEventRequirementsEpic$,
  getActivitiesEpic$,
  getActivityByIdEpic$,
  confirmEventInviteEpic$,
  getRequirementsEpic$,
  saveEventEpic$,
  getEventTypesEpic$,
  deleteEventReminderEpic$,
  deleteEventEpic$,
  saveRecurrentEventEpic$,
  updateRecurrentEventEpic$,
  updateRecurrentEventByRecreationEpic$,
  deleteRecurrentEventEpic$,
  getAllSubUnitsEpic$,
  getUnitMembersEpic$,
  getExternalGuestsEpic$,
  addGuestsEpic$,
  confirmEventMultiInviteEpic$,
  deleteMultiInviteeEpic$,
  updateInviteeAttendedStatusEpic$,
  updateAntendeeRSVPEpic$,
  updateGuestRSVPEpic$,
  updateGuestAttendedStatusEpic$,
  saveActivityEpic$,
  getActivitesEpic$,
  deleteActivityEpic$,
  getCalendarRecordsEpic$,
  saveCalendarRecordsEpic$,
  savePublicRSVPEpic$,
  sendPublicRSVPEpic$,
  deleteEventComment$,
  deleteEventCommentPhoto$,
  getEventComments$,
  postEventComment$,
  postEventCommentWithFiles$,
  rtkUnitEventsRefetchEpic$,
  putEventComment$,
);
