import { createSelector } from 'reselect';

import { activityTypeIds, packRosterItemsSel } from '@shared';

import {
  activitiesModuleName,
  advancementModuleName,
  approveAdvancementsModuleName,
  campoutsModuleName,
  commonModuleName,
  declineAdvancementModuleName,
  eagleProjectModuleName,
  eagleScoutApplicationModuleName,
  editAdvancementModuleName,
  hikesModuleName,
  longCruiseModuleName,
  progressModuleName,
  serviceModuleName,
  unapproveAdvancementModuleName,
} from '../constants';
import {
  addPersonsMissingInfo,
  formatActivityTime,
  getPersonsInActivity,
} from '../utils';

export const progressModuleSel = state => state[progressModuleName];
export const commonModuleSel = state =>
  progressModuleSel(state)[commonModuleName];
export const activitiesModuleSel = state =>
  progressModuleSel(state)[activitiesModuleName];
export const advancementModuleSel = state =>
  progressModuleSel(state)[advancementModuleName];
export const serviceModuleSel = state =>
  progressModuleSel(state)[serviceModuleName];
export const campoutsModuleSel = state =>
  progressModuleSel(state)[campoutsModuleName];
export const eagleProjectModuleSel = state =>
  progressModuleSel(state)[eagleProjectModuleName];
export const hikesModuleSel = state =>
  progressModuleSel(state)[hikesModuleName];
export const longCruiseModuleSel = state =>
  progressModuleSel(state)[longCruiseModuleName];
export const eagleScoutApplicationModuleSel = state =>
  progressModuleSel(state)[eagleScoutApplicationModuleName];
export const editAdvancementModuleSel = state =>
  progressModuleSel(state)[editAdvancementModuleName];
export const approveAdvancementsModuleSel = state =>
  progressModuleSel(state)[approveAdvancementsModuleName];
export const declineAdvancementModuleSel = state =>
  progressModuleSel(state)[declineAdvancementModuleName];
export const unapproveAdvancementModuleSel = state =>
  progressModuleSel(state)[unapproveAdvancementModuleName];
export const approveAdvancementsVisibleSel = state =>
  approveAdvancementsModuleSel(state).visible;
export const declineAdvancementVisibleSel = state =>
  declineAdvancementModuleSel(state).isVisibleDeclineAdvancementsModal;

export const categoriesSel = state => commonModuleSel(state).categories;
export const organizationsSel = state => commonModuleSel(state).organizations;
export const loadingModalSel = state => commonModuleSel(state).loadingModal;
export const loadingCalendarSel = state =>
  commonModuleSel(state).loadingCalendar;
export const setActionButtonSel = state =>
  commonModuleSel(state).setActionButton;

export const activitiesSel = createSelector(
  state => commonModuleSel(state).activities,
  packRosterItemsSel,
  (activities, rosterItems) =>
    activities.map(activity => ({
      ...activity,
      registeredYouths: addPersonsMissingInfo(
        activity.registeredYouths,
        rosterItems,
      ),
      registeredAdults: addPersonsMissingInfo(
        activity.registeredAdults,
        rosterItems,
      ),
    })),
);

export const selectedActivitySel = createSelector(
  state => commonModuleSel(state).selectedActivity,
  selectedActivity => {
    const { startTime, endTime, activityTypeId } = selectedActivity;
    let activity = { ...selectedActivity };
    const isServiceProject =
      activityTypeId === activityTypeIds.SERVICE_PROJECTS;

    if (isServiceProject) {
      return selectedActivity;
    }

    const formattedStartTime = formatActivityTime(startTime);
    const formattedEndTime = formatActivityTime(endTime);
    const allDay =
      formattedEndTime === formattedStartTime && formattedEndTime === '00:00';

    if (allDay) {
      activity.endTime = undefined;
      activity.startTime = undefined;
    }

    return activity;
  },
);

export const personsToRemoveSel = state =>
  commonModuleSel(state).personsToRemove;

export const activityImportedFromSel = state =>
  commonModuleSel(state).activityImportedFrom;

export const personsAndIdsActivitySel = createSelector(
  selectedActivitySel,
  activity => {
    const { personsInActivity, userIds } = getPersonsInActivity(activity);
    return { personsInActivity, userIds };
  },
);

export const localLocationsSel = state =>
  commonModuleSel(state).acitityLocalLocations;

export const localLocationsListSel = createSelector(
  localLocationsSel,
  (locationsObject = {}) => locationsObject.locations || [],
);

export const localLocationsZipSel = createSelector(
  localLocationsSel,
  (locationsObject = {}) => locationsObject.zip || undefined,
);

export const searchLocationListSel = state =>
  commonModuleSel(state).searchLocationList || [];

export const isLoadingLocationsSel = state =>
  commonModuleSel(state).isLoadingLocations;

export const initialFormStateSel = state =>
  commonModuleSel(state).initialFormState;

export const deleteActivityLoadingSel = state =>
  commonModuleSel(state).deleteActivityLoading;

const activitiesModule = state => state[activitiesModuleName];
export const eventsSel = state => activitiesModule(state).events;

export const selectedActivityEventSel = createSelector(
  eventsSel,
  selectedActivitySel,
  (events, selectedActivity) => {
    const { id } = selectedActivity;

    return events.filter(event => event.activityId === id);
  },
);
