import { sorters } from '@utils';

const nameSorter = sorters.stringMulti('lastName', 'firstName');
const ageSorter = sorters.rosterAge('age');
const highestRankApprovedSorter = sorters.compose(
  sorters.number('rosterCurrentHighestRankApproved.isActiveUnit'),
  sorters.number('rosterCurrentHighestRankApproved.programId', true),
  sorters.number('rosterCurrentHighestRankApproved.rankOrder'),
);
const subUnitSorter = sorters.string('subUnitName');
export const defaultSorter = sorters.compose(
  nameSorter,
  highestRankApprovedSorter,
);

export default {
  name: nameSorter,
  age: ageSorter,
  currentHighestRankApproved: highestRankApprovedSorter,
  default: defaultSorter,
  subUnitName: subUnitSorter,
};
