import merge from 'lodash/merge';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mapTo';

import { API_ENV, ESB_API_URLS } from '@config';

import { default as userServices } from '../../shared/duck/userServices';
import requestStorage from '../requestStorage';
import addQueryParams from './addQueryParams';
import errorHandler from './esbErrorHandler';
import getTargetUrlHeader from './getTargetUrlHeader';
import getUrlForEnv from './getUrlForEnv';
import createHttp from './httpFactory';
import jsonBodyTransformer from './jsonBodyTransformer';
import unwrapResponse from './unwrapResponse';

const selfApiPrefix = '/apis/esb';

const getLoginData = () => userServices.retrieveLoginData() || {};

const addSwCacheParam = endpoint => addQueryParams(endpoint, { swCache: true });

const urlTransformer = (endpoint, { swCache, skipSw }) => {
  if (swCache) {
    endpoint = addSwCacheParam(endpoint);
  }
  const externalUrl = ESB_API_URLS[API_ENV] + endpoint;
  const selfUrl = skipSw ? externalUrl : selfApiPrefix + endpoint;

  return getUrlForEnv(selfUrl, externalUrl);
};

const freeAccessDomains = ['/organizations/'];

const optionsTransformer = ({
  options = {},
  body,
  url: endpoint,
  transformedUrl,
}) => {
  const { token } = getLoginData();

  const isFormData = body instanceof FormData;
  let externalUrl = ESB_API_URLS[API_ENV] + endpoint;
  if (options.swCache) {
    externalUrl = addSwCacheParam(externalUrl);
  }
  const headers = merge(
    {
      Authorization: token ? `bearer ${token}` : '',
      Accept: options.v2 ? 'application/json; version = 2' : 'application/json',
    },
    options.skipSw ? {} : getTargetUrlHeader(externalUrl),
    isFormData ? {} : { 'Content-Type': 'application/json' },
  );

  const isFreeAccessDomain = freeAccessDomains.some(
    domain =>
      transformedUrl.includes(selfApiPrefix + domain) ||
      new RegExp(`\\.([a-z])+${domain}`, 'ig').test(transformedUrl),
  );

  const extraOptions = {
    withCredentials: !isFreeAccessDomain,
    headers,
  };

  return merge({}, options, extraOptions);
};

const bodyTransformer = body =>
  body instanceof FormData ? body : jsonBodyTransformer(body);

const http = createHttp({
  urlTransformer,
  bodyTransformer,
  optionsTransformer,
  responseHandler: unwrapResponse,
  errorHandler,
});

const apiCall$ = (method, endpoint, body, options) => {
  if (window.sblOffline) {
    endpoint = addQueryParams(endpoint, { offline: true });
  }

  return method === http.get$
    ? method(endpoint, options)
    : method(endpoint, body, options);
};

const validateSerializePresent = options => {
  if (typeof options.serialize !== 'function') {
    throw new Error(
      'Deferrable request must have a "serialize" function in "options"',
    );
  }
};

const requiredFields = [
  'operation',
  'userId',
  'organizationGuid',
  'advancementId',
  'advancementType',
  'date',
];

const validateSerializedRequests = reqs =>
  reqs.forEach(req => {
    const keys = Object.keys(req);
    requiredFields.forEach(field => {
      if (!keys.includes(field)) {
        throw new Error(`"${field}" missing in serialized request`);
      }
    });
  });

const storeRequests = async (personGuid, reqs) => {
  for (let req of reqs) {
    await requestStorage.addRequest(personGuid, req);
  }
};

const apiCallOrDefer$ = (method, endpoint, body, options) => {
  validateSerializePresent(options);

  if (navigator.onLine && !window.sblOffline) {
    return apiCall$(method, endpoint, body, options);
  }

  let serializedRequests = options.serialize();
  serializedRequests = Array.isArray(serializedRequests)
    ? serializedRequests
    : [serializedRequests];

  validateSerializedRequests(serializedRequests);
  const { personGuid } = getLoginData();

  return Observable.fromPromise(
    storeRequests(personGuid, serializedRequests),
  ).mapTo('DEFERRED');
};

const apiCall = async (method, endpoint, body, options) =>
  apiCall$(method, endpoint, body, options).toPromise();

const apiCallOrDefer = async (method, endpoint, body, options) =>
  apiCallOrDefer$(method, endpoint, body, options).toPromise();

const apiCalls = Object.freeze({
  get$: (endpoint, options) =>
    apiCall$(http.get$, endpoint, undefined, options),
  post$: (endpoint, body, options) =>
    apiCall$(http.post$, endpoint, body, options),
  put$: (endpoint, body, options) =>
    apiCall$(http.put$, endpoint, body, options),
  patch$: (endpoint, body, options) =>
    apiCall$(http.patch$, endpoint, body, options),
  delete$: (endpoint, body, options) =>
    apiCall$(http.delete$, endpoint, body, options),

  postOrDefer$: (endpoint, body, options) =>
    apiCallOrDefer$(http.post$, endpoint, body, options),
  putOrDefer$: (endpoint, body, options) =>
    apiCallOrDefer$(http.put$, endpoint, body, options),
  patchOrDefer$: (endpoint, body, options) =>
    apiCallOrDefer$(http.patch$, endpoint, body, options),
  deleteOrDefer$: (endpoint, body, options) =>
    apiCallOrDefer$(http.delete$, endpoint, body, options),

  get: (endpoint, options) => apiCall(http.get$, endpoint, undefined, options),
  post: (endpoint, body, options) =>
    apiCall(http.post$, endpoint, body, options),
  put: (endpoint, body, options) => apiCall(http.put$, endpoint, body, options),
  patch: (endpoint, body, options) =>
    apiCall(http.patch$, endpoint, body, options),
  delete: (endpoint, body, options) =>
    apiCall(http.delete$, endpoint, body, options),

  postOrDefer: (endpoint, body, options) =>
    apiCallOrDefer(http.post$, endpoint, body, options),
  putOrDefer: (endpoint, body, options) =>
    apiCallOrDefer(http.put$, endpoint, body, options),
  patchOrDefer: (endpoint, body, options) =>
    apiCallOrDefer(http.patch$, endpoint, body, options),
  deleteOrDefer: (endpoint, body, options) =>
    apiCallOrDefer(http.delete$, endpoint, body, options),

  DEFERRED: 'DEFERRED',
});

export default apiCalls;
