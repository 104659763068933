import React from 'react';

import PropTypes from 'prop-types';

import { POCard } from '../POCard';
import styles from './ClosedPOList.less';

export const ClosedPOList = ({ orders = [] }) => (
  <div>
    {orders.map((order, index) => (
      <div key={index} className={styles.listItem}>
        <POCard order={order} />
      </div>
    ))}
  </div>
);

ClosedPOList.propTypes = {
  orders: PropTypes.array,
};
