import uniqBy from 'lodash/uniqBy';
import { combineEpics } from 'redux-observable';
import { concat, forkJoin, of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import { SBL_4832_EXPIRED_MEMBERSHIP_ACCESS, SBL_5102_MY_SCOUT } from '@config';
import { parentGuardian, programForUnitTypeId } from '@shared/constants';
import {
  FETCH_RELATED_CHILREN,
  continueLogin,
  triggerAllRostersRequest,
  triggerCalendarRequest,
} from '@shared/duck/actions';
import { catchAndReport } from '@utils/rxjs/operators';

import { personNameBuilder } from '../../utils';
import youthProfileServices from '../../youthProfile/duck/services';
import { adultPositionsForParticipant } from '../constants';
import {
  getOrganizationsFromChildren,
  removeEmptyOrganizations,
  removeParentGuardiansFromOrganizations,
} from '../utils';
import { addChildren } from './actions';
import services from './services';

function transformYouthProfiles(resChildren, response, userData) {
  const computedChildrenData = resChildren.reduce(
    (acc, child, idx) => {
      const { currentProgramsAndRanks, organizationPositions = [] } = child;
      // TODO: Due to inconsistent data from the API I need to do this validation
      // https://jira.scouting.org/browse/SBL-2030
      const programs = Array.isArray(currentProgramsAndRanks)
        ? currentProgramsAndRanks
        : null;

      // Remove organizations where youth participant have adult positions
      const filteredOrgPositions = organizationPositions.filter(
        ({ positions = [] }) =>
          !positions.some(pos =>
            adultPositionsForParticipant.includes(pos.name),
          ),
      );

      const { organizations, children } = acc;

      if (!programs || filteredOrgPositions.length === 0) {
        return {
          children,
          organizations,
        };
      }
      const profile = child.profile || {};
      const userId = Number(response[idx].userId);

      filteredOrgPositions.forEach((currentOrgPosition, orgIndex) => {
        const currentProgram = programs[orgIndex] || null;
        if (currentProgram) {
          const organizationName = `${currentProgram.unitType} ${currentProgram.unitNumber}`;
          const programId = +currentProgram.programId;
          organizations.push({
            userId,
            organizationName,
            isParentGuardian: true,
            personGuid: profile.personGuid,
            connection: personNameBuilder.short(profile),
            position: parentGuardian,
            organizationGuid: `${currentOrgPosition.organizationGuid}*${userId}`,
            // program: firstProgram.program,
            // TODO: Find a way with to get acceptGender with less requests
            // acceptGender: 'FAMILY',
            key: String(userId),
            unitTypeId: currentProgram.programId,
            organizationTypeId: currentOrgPosition.organizationTypeId,
            programTypeId: currentOrgPosition.programTypeId,
            programId,
            program: programForUnitTypeId[programId],
            unitId: currentOrgPosition.organizationId,
          });
        }
      });
      children.push({
        ...child,
        ...profile,
        personShortFullName: personNameBuilder.short(profile),
        userId,
      });

      return {
        organizations,
        children,
      };
    },
    {
      organizations: [],
      children: [],
    },
  );

  let filteredOrgs;
  if (SBL_4832_EXPIRED_MEMBERSHIP_ACCESS) {
    filteredOrgs = removeEmptyOrganizations(userData.organizations);
  } else {
    // Remove Parent/Guardian, we assume they don't have children because this come straight from userData
    filteredOrgs = removeParentGuardiansFromOrganizations(
      userData.organizations,
    );
  }

  const newUserData = {
    ...userData,
    organizations: [...filteredOrgs, ...computedChildrenData.organizations],
  };
  return { newUserData, computedChildrenData };
}

// Before the app is ready, we start fetching all the information from that parentGuardian to display valid information in the context tab;
const fetchRelatedChildrenEpic$ = action$ =>
  action$.ofType(FETCH_RELATED_CHILREN).pipe(
    switchMap(({ payload }) => {
      const { userId, userData, canMasquerade } = payload;
      return services.getRelatedChildren$(userId).pipe(
        switchMap(response => {
          const childrenWithYouthPositions = response.filter(
            child => !adultPositionsForParticipant.includes(child.position),
          );
          const uniqueChildren = uniqBy(childrenWithYouthPositions, 'userId');
          const childs = SBL_5102_MY_SCOUT
            ? of([])
            : childrenWithYouthPositions.length
            ? uniqueChildren.map(({ userId }) =>
                youthProfileServices.getYouthProfile$(userId),
              )
            : of([]);
          return forkJoin(childs).pipe(
            mergeMap(resChildren => {
              if (SBL_5102_MY_SCOUT) {
                const extraOrgs = getOrganizationsFromChildren({
                  myScout: childrenWithYouthPositions,
                });
                const newOrganizations = removeEmptyOrganizations([
                  ...removeParentGuardiansFromOrganizations(
                    userData.organizations,
                  ),
                  ...extraOrgs,
                ]);
                return concat(
                  of(
                    continueLogin({
                      userData: {
                        ...userData,
                        organizations: newOrganizations,
                      },
                      canMasquerade,
                    }),
                  ),
                  // myScout response
                  of(addChildren(extraOrgs)),
                  of(triggerCalendarRequest(true)),
                  of(triggerAllRostersRequest(true)),
                );
              } else {
                const { computedChildrenData, newUserData } =
                  transformYouthProfiles(resChildren, uniqueChildren, userData);
                return concat(
                  of(continueLogin({ userData: newUserData, canMasquerade })),
                  of(addChildren(computedChildrenData.children)),
                  of(triggerCalendarRequest(true)),
                  of(triggerAllRostersRequest(true)),
                );
              }
            }),
            catchAndReport(() => {
              // continue login even if some youth request fail
              const newUserData = {
                ...userData,
              };
              if (!SBL_4832_EXPIRED_MEMBERSHIP_ACCESS) {
                newUserData.organizations =
                  removeParentGuardiansFromOrganizations(
                    userData.organizations,
                  );
              }
              return of(
                continueLogin({ userData: newUserData, canMasquerade }),
              );
            }),
          );
        }),
      );
    }),
  );

export default combineEpics(fetchRelatedChildrenEpic$);
