import _, { chain, groupBy, isEmpty, partition, uniqBy } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import stable from 'stable';

import {
  organizationGuidSel,
  organizationPositionsSel,
  unitActivityTypeIdsSel,
} from '@context';
import { isCurrentPageSel, querySel } from '@location';
import { getIsDenChief } from '@modules/advancement/packRoster/utilsTyped';
import { sortFoundSubUnits } from '@modules/calendar/utils';
import {
  createPersonsSelector,
  groupByRSVP,
  groupYouthsAdultsParents,
} from '@progress/common/utils';
import {
  Program,
  advancementTypes,
  adventuresSel,
  awardsSel,
  emptyArr,
  extractOrgGuidAndUserId,
  meritBadgesSel,
  ranksSel,
  unitTypeIdSel,
} from '@shared';
import { userIdSel as loggedInUserIdSel } from '@user';
import {
  getTimeZoneData,
  isIncludedIn,
  momentWithOffset,
  sorters,
} from '@utils';

import { unitInfoSel } from '../../unit/duck';
import {
  camelColName,
  customEvenTypeProgram,
  denSortOrder as customOrder,
  eventTypesNames,
  extraType,
  groupTags,
  moduleName,
  userEventStatus,
} from '../constants';
import { addIsDenChief, formatEventUnit, getUserRsvp } from '../utils';
import {
  ROUTE_EVENTS_ADD_ADVANCEMENT,
  ROUTE_EVENTS_CREATE,
  ROUTE_EVENTS_EDIT,
} from './actions';

const moduleSel = state => state[moduleName];

export const isEventRouteSel = state =>
  isCurrentPageSel(
    state,
    ...[ROUTE_EVENTS_CREATE, ROUTE_EVENTS_ADD_ADVANCEMENT, ROUTE_EVENTS_EDIT],
  );
export const isVisibleAddGuestModalSel = state =>
  moduleSel(state).isVisibleAddGuestModal;
export const eventTypesSel = state => moduleSel(state).eventTypes;
export const eventTypesLoadingSel = state => moduleSel(state).eventTypesLoading;
export const calendarRecordsSel = state => moduleSel(state).calendarRecords;
export const isLoadingCalendarRecordsSel = state =>
  moduleSel(state).isLoadingCalendarRecords;

export const unitCalendarOffsetSel = createSelector(unitInfoSel, unitInfo => {
  const timezoneCode = unitInfo.unitTimeZoneCode;
  return getTimeZoneData(timezoneCode);
});

export const eventDetailsSel = createSelector(
  state => moduleSel(state).eventDetails,
  unitInfoSel,
  querySel,
  (selectedEvent, { unitTimeZoneCode: timezoneCode }, { clone }) => {
    if (isEmpty(selectedEvent)) return {};
    const { startDate, endDate, reminders, location } = selectedEvent;
    return {
      ...selectedEvent,
      startDate: momentWithOffset(startDate, timezoneCode),
      startTime: momentWithOffset(startDate, timezoneCode),
      endDate: momentWithOffset(endDate, timezoneCode),
      endTime: momentWithOffset(endDate, timezoneCode),
      rawStart: selectedEvent.startDate,
      rawEnd: selectedEvent.endDate,
      location: location ? location : '',
      areRemindersDisabled: clone ? true : isEmpty(reminders),
      reminders: clone ? [] : reminders,
    };
  },
);

export const searchAdvancementSel = state => moduleSel(state).searchAdvancement;

export const isLoadingRequirementsSel = state =>
  moduleSel(state).isLoadingRequirements;
export const advancementRequirementsSel = state =>
  moduleSel(state).advancementRequirements;

export const advancementTypeSel = state => moduleSel(state).advancementType;

export const eventRemindersSel = state => moduleSel(state).eventReminders;
export const eventRequirementsSel = state => moduleSel(state).eventRequirements;
export const eventRequirementsLoadingSel = state =>
  moduleSel(state).eventRequirementsLoading;

/** @returns {Array<import('../../../types/esb').OrgSubUnit[]>} */
export const allSubUnitsSel = state => moduleSel(state).allSubUnits || [];

export const selectedEventUnitsSel = state =>
  moduleSel(state).selectedEventUnits;

export const selectedEventUnitsOnlySel = createSelector(
  selectedEventUnitsSel,
  organizationPositionsSel,
  (selectedEventUnits, units) => {
    const selectedUnitIds = _.uniq(
      selectedEventUnits.map(unit => (unit.isSubUnit ? unit.unitId : unit.id)),
    );

    const validUnits = uniqBy(
      units.filter(unit => unit.unitId),
      'unitId',
    );

    const filteredUnitsWithInfo = validUnits
      .filter(unit => selectedUnitIds.includes(Number(unit.unitId)))
      .map(unit => formatEventUnit(unit));

    return filteredUnitsWithInfo;
  },
);

export const isSavingActivityEventSel = state =>
  moduleSel(state).isSavingActivityEvent;

export const isLoadingActivitiesEventSel = state =>
  moduleSel(state).isLoadingActivitiesEvent;

export const activitiesEventSel = state =>
  moduleSel(state).activitiesEvent || emptyArr;

const sortAllUnitsSubunits = list => {
  const unitList = list.filter(sub => !sub.isDen && !sub.isSubUnit);
  const sortedUnitList = _.sortBy(unitList, sub => sub.name.toLowerCase());

  const patrolList = list.filter(sub => !sub.isDen && sub.isSubUnit);
  const sortedPatrolList = _.sortBy(patrolList, sub => sub.name.toLowerCase());

  const denList = list.filter(sub => sub.isDen);
  const sortedDenList = _.sortBy(denList, den => [
    _.indexOf(customOrder, den.extra.denType),
    den.name,
  ]);

  return sortedUnitList.concat(sortedPatrolList, sortedDenList);
};

export const allUnitsSubunitsSel = createSelector(
  organizationPositionsSel,
  allSubUnitsSel,
  (units, allSubUnits) => {
    /** @type {import('./types').CalendarSubUnit[]} */
    const formattedUnitsSubunits = [];

    const validUnits = uniqBy(
      units.filter(unit => unit.unitId),
      'unitId',
    );

    validUnits.forEach(unit => {
      const foundSubUnits = allSubUnits.find(
        subUnits =>
          subUnits && subUnits.length && subUnits[0].unitId === +unit.unitId,
      );

      const unitInfo = formatEventUnit(unit);
      if (foundSubUnits) {
        // According to SB queries, they grab the first sub unit as source of truth
        // See https://jira.scouting.org/browse/SBL-4784
        unitInfo.showDlEvents = foundSubUnits[0]?.showDlEvents;
      }
      // If the unit contains sub units with DLE events enabled, set flag as true
      formattedUnitsSubunits.push(unitInfo);

      if (foundSubUnits) {
        const sortedFoundSubUnits = sortFoundSubUnits(foundSubUnits);

        sortedFoundSubUnits.forEach(subUnit => {
          const isDen = !!subUnit.denType;

          /** @type {import('./types').CalendarSubUnit} */
          const value = {
            id: subUnit.subUnitId,
            name: isDen
              ? `${subUnit.denType} ${subUnit.subUnitName}`
              : subUnit.subUnitName,
            isDen,
            unitId: subUnit.unitId,
            extra: subUnit,
            program: unit.program,
            groupName: unit.organizationName,
            isSubUnit: true,
            showDlEvents: !!subUnit.showDlEvents,
            organizationGuid: unit.organizationGuid,
          };
          formattedUnitsSubunits.push(value);
        });
      }
    });

    return formattedUnitsSubunits;
  },
);

export const allUnitsWithColorsSel = createSelector(
  allUnitsSubunitsSel,
  calendarRecordsSel,
  (allUnits, colorRecords) => {
    const updatedUnits = allUnits.map(unit => {
      const foundRecord = colorRecords.find(record => {
        const id = record.patrolId || record.denId || record.unitId;

        return unit.id === id;
      });

      const resultRecord = foundRecord
        ? foundRecord
        : {
            unitId: unit.isSubUnit ? unit.unitId : unit.id,
            denId: unit.isSubUnit && unit.isDen ? unit.id : undefined,
            patrolId: unit.isSubUnit && !unit.isDen ? unit.id : undefined,
            subUnitId: unit.isSubUnit ? unit.id : undefined,
            color: '#c2c0c0',
            showCalendar: true,
            calendarCode: `${
              unit.isSubUnit ? (unit.isDen ? 'Den' : 'Patrol') : 'Unit'
            }ID${unit.id}`,
          };
      return { ...unit, colorRecord: resultRecord };
    });

    return sortAllUnitsSubunits(updatedUnits);
  },
);

const sortSelectedUnitsSubUnits = list => {
  const patrolList = list.filter(sub => !sub.denType);
  const sortedPatrolList = _.sortBy(patrolList, item =>
    item.subUnitName.toLowerCase(),
  );

  const denList = list.filter(sub => sub.denType);
  const sortedDenList = _.sortBy(denList, den => [
    _.indexOf(customOrder, den.denType),
    den.subUnitName,
  ]);

  return sortedPatrolList.concat(sortedDenList);
};

export const selectedUnitsSubUnitsSel = createSelector(
  selectedEventUnitsSel,
  allSubUnitsSel,
  (selectedEventUnitsSel, allSubUnits) => {
    const unitIds = selectedEventUnitsSel
      .filter(unit => unit.unitId === undefined)
      .map(unit => unit.id);
    const subUnitIds = selectedEventUnitsSel
      .filter(unit => unit.unitId !== undefined)
      .map(unit => unit.id);

    const concatSubUnits = allSubUnits.reduce(
      (accumulator, currentArray) => accumulator.concat(currentArray),
      [],
    );

    const includedSubUnits = concatSubUnits.filter(sub =>
      unitIds.includes(sub.unitId),
    );

    const individualSubUnits = concatSubUnits.filter(
      sub =>
        subUnitIds.includes(sub.subUnitId) && !unitIds.includes(sub.unitId),
    );

    const concatList = includedSubUnits.concat(individualSubUnits);

    return sortSelectedUnitsSubUnits(concatList);
  },
);

export const selectedUnitsHasDenSel = createSelector(
  selectedUnitsSubUnitsSel,
  selectedUnitsSubUnits =>
    _.isArray(selectedUnitsSubUnits)
      ? selectedUnitsSubUnits.some(list => list.denType)
      : false,
);

export const selectedUnitsSubUnitsWithUnassignedSel = createSelector(
  selectedUnitsSubUnitsSel,
  selectedEventUnitsSel => {
    const unassigned = {
      subUnitId: 0,
      subUnitName: extraType.UNASSIGNED,
    };

    return selectedEventUnitsSel.concat(unassigned);
  },
);

export const allRostersSel = state => moduleSel(state).allRosters;
export const allRostersFetchedGuidsSel = state =>
  moduleSel(state).allRostersFetchedGuids;

export const allGuestsSel = state => moduleSel(state).allGuests;
export const selectedGuestListSel = state => moduleSel(state).selectedGuestList;
export const removedGuestListSel = state => moduleSel(state).removedGuestList;
export const isAddGuestLoadingSel = state => moduleSel(state).isAddGuestLoading;
export const isAttendedToggleLoadingSel = state =>
  moduleSel(state).isAttendedToggleLoading;
export const isEventQELoadingSel = state => moduleSel(state).isEventQELoading;
export const isEventUnitRosterLoadingSel = state =>
  moduleSel(state).isEventUnitRosterLoading;

export const selectedUnitsRostersSel = createSelector(
  selectedEventUnitsSel,
  allRostersSel,
  (selectedUnits = [], rostersUsers) => {
    let filteredUsersByUnit = [];

    selectedUnits.forEach(unit => {
      const usersOfUnit = rostersUsers.filter(user => {
        // all member within the related unit
        if (
          _.has(unit, camelColName.UNIT_ID) &&
          user.units.some(userUnit => userUnit.id === unit.unitId)
        ) {
          return user;
        }

        return false;
      });

      filteredUsersByUnit = filteredUsersByUnit.concat(usersOfUnit);
    });

    return uniqBy(filteredUsersByUnit, 'userId');
  },
);

export const newMemberRosterSel = createSelector(
  selectedEventUnitsSel,
  allRostersSel,
  eventDetailsSel,
  (selectedUnits = [], rostersUsers, eventDetails) => {
    let filteredUsers = [];

    if (!eventDetails.startDate) {
      // no event details no need to iterate
      return [];
    }

    selectedUnits.forEach(unit => {
      const usersOfUnit = rostersUsers?.filter(user => {
        if (
          _.has(unit, camelColName.UNIT_ID) &&
          user.units?.some(userUnit => userUnit.id === unit.unitId) &&
          user.positions?.some(
            details =>
              moment(details.dateStarted) >
              eventDetails.startDate.format('YYYY-MM-DD'),
          )
        ) {
          // for sub unit level
          return user;
        }

        if (
          user.units?.some(userUnit => userUnit.id === unit.id) &&
          user.positions?.some(details =>
            moment(details.dateStarted).isAfter(eventDetails.startDate),
          )
        ) {
          // for unit level
          return user;
        }

        return false;
      });

      filteredUsers = filteredUsers.concat(usersOfUnit);
    });

    return uniqBy(filteredUsers, 'userId');
  },
);

export const filteredRostersSel = createSelector(
  selectedEventUnitsSel,
  allRostersSel,
  (selectedUnits = [], rostersUsers) => {
    let filteredUsersByUnit = [];
    selectedUnits.forEach(unit => {
      const usersOfUnit = rostersUsers?.filter(user => {
        // includes all leaders
        if (
          _.has(unit, camelColName.UNIT_ID) &&
          user.units.some(userUnit => userUnit.id === unit.unitId) &&
          user.isLeader
        ) {
          return user;
        }

        if (user.units.some(userUnit => userUnit.id === unit.id)) {
          return user;
        }

        return false;
      });

      filteredUsersByUnit = filteredUsersByUnit.concat(usersOfUnit);
    });

    return uniqBy(filteredUsersByUnit, 'userId');
  },
);

export const filteredRostersDenChiefSel = createSelector(
  filteredRostersSel,
  filteredRosters => {
    if (filteredRosters.length > 0) {
      return filteredRosters.filter(person => getIsDenChief(person));
    }

    return [];
  },
);

export const filteredEventRequirementsSel = createSelector(
  eventRequirementsSel,
  meritBadgesSel,
  ranksSel,
  awardsSel,
  adventuresSel,
  (eventRequirements, meritBadges, ranks, awards, adventures) =>
    !isEmpty(eventRequirements)
      ? eventRequirements.map(requirement => {
          let advancements = [];
          switch (requirement.type) {
            case advancementTypes.MERIT_BADGES:
              advancements = meritBadges;
              break;
            case advancementTypes.RANKS:
              advancements = ranks;
              break;
            case advancementTypes.AWARDS:
              advancements = awards;
              break;
            case advancementTypes.ADVENTURES:
              advancements = adventures;
              break;
          }
          const selectedAdvancement = advancements.find(
            advancement => +advancement.id === requirement.id,
          );

          return {
            ...selectedAdvancement,
            ...requirement,
          };
        })
      : [],
);

export const allYouthsSel = createSelector(
  allRostersSel,
  (rostersUsers = []) => {
    const youths = rostersUsers.filter(member => !member.isAdult);
    return youths;
  },
);

export const filteredAdvancementTypeSel = createSelector(
  meritBadgesSel,
  ranksSel,
  awardsSel,
  adventuresSel,
  advancementTypeSel,
  unitTypeIdSel,
  (meritBadges, ranks, awards, adventures, advancementType, unitTypeId) => {
    const filteredRanks = ranks.filter(rank => unitTypeId === +rank.programId);
    const filteredAwards = awards.filter(
      award => unitTypeId === +award.unitTypeId,
    );
    switch (advancementType) {
      case advancementTypes.MERIT_BADGES:
        return meritBadges;
      case advancementTypes.RANKS:
        return filteredRanks;
      case advancementTypes.AWARDS:
        return filteredAwards;
      case advancementTypes.ADVENTURES:
        return adventures;
    }
  },
);

export const selectedEventAdvancementsSel = state =>
  moduleSel(state).selectedEventAdvancements;

export const selectedGroupedAdvancementsSel = createSelector(
  selectedEventAdvancementsSel,
  selectedEventAdvancements => groupBy(selectedEventAdvancements, 'type'),
);

export const filteredAdvancementSel = createSelector(
  filteredAdvancementTypeSel,
  searchAdvancementSel,
  selectedEventAdvancementsSel,
  (filteredAdvancementType, searchAdvancement, selectedEventAdvancements) => {
    const filteredAdvancement = filteredAdvancementType.filter(
      advancement =>
        isIncludedIn(advancement.name, searchAdvancement) &&
        !selectedEventAdvancements.some(
          selected => advancement.id === selected.id,
        ),
    );
    return filteredAdvancement;
  },
);

export const eventDetailsLoadingSel = state =>
  moduleSel(state).eventDetailsLoading;

export const eventInviteesSel = state => moduleSel(state).eventInvitees;

export const isLoadingConfirmInviteSel = state =>
  moduleSel(state).isLoadingConfirmInvite;

export const activityDetailsSel = state => moduleSel(state).activityDetails;

export const activityDetailsLoadingSel = state =>
  moduleSel(state).activityDetailsLoading;

export const selectedAdvancementSel = state =>
  moduleSel(state).selectedAdvancement;

export const advancementCurrentStepSel = state =>
  moduleSel(state).advancementCurrentStep;

export const isVisiblePopoverSel = state => moduleSel(state).isVisiblePopover;

export const isVisibleQuickEntryRSVPModalSel = state =>
  moduleSel(state).isVisibleQuickEntryRSVPModal;

export const showFilterPopoverSel = state => moduleSel(state).showFilterPopover;

export const groupBySel = state => moduleSel(state).setAttendeeGroupFilter;

export const sortBySel = state => moduleSel(state).setAttendeeSortOrder;

export const isEventReloadSel = state => moduleSel(state).isEventReload;

const sortNames = (invitees, sortBy) => {
  const sorter = sorters.text(sortBy);
  return invitees.map(partition => stable(partition, sorter));
};

export const processedInviteesSel = createSelector(
  eventInviteesSel,
  groupBySel,
  sortBySel,
  filteredRostersDenChiefSel,
  selectedUnitsHasDenSel,
  (invitees, groupBy, sortBy, denChiefList, selectedUnitsHasDen) => {
    let separatedInvitees;
    const groups = [[], [], []];

    if (invitees) {
      if (groupBy === groupTags.RESERVATION_STATUS) {
        let [notAttending, attendeesWithRSVP] = partition(invitees, [
          groupBy,
          false,
        ]);
        separatedInvitees = partition(attendeesWithRSVP, groupBy);
        separatedInvitees = [...separatedInvitees, notAttending];
      } else if (groupBy === groupTags.IS_ADULT) {
        invitees.forEach(p => {
          if (p.isAdult && !p.isParticipant) {
            groups[0].push(p);
          } else {
            if (selectedUnitsHasDen) {
              const addedIsDenChief =
                denChiefList.length > 0 ? addIsDenChief(p, denChiefList) : p;

              if (addedIsDenChief.isDenChief) {
                groups[2].push(addedIsDenChief);
              } else {
                groups[1].push(addedIsDenChief);
              }
            } else {
              groups[1].push(p);
            }
          }
        });
        separatedInvitees = selectedUnitsHasDen ? groups : groups.slice(0, -1);
      } else {
        separatedInvitees = partition(invitees, groupBy);
      }
      separatedInvitees = sortNames(separatedInvitees, sortBy);
    }
    return separatedInvitees;
  },
);

export const loggedInUserRsvpSel = createSelector(
  eventInviteesSel,
  loggedInUserIdSel,
  (invitees, loggedInUserId) => getUserRsvp(loggedInUserId, invitees),
);

export const youthsWithinOrgSel = createSelector(
  organizationPositionsSel,
  organizationGuidSel,
  eventInviteesSel,
  (units, selectedOrgGuid, eventInvitees) => {
    const youthsWithinOrg = units.filter(unit => {
      const { isParentGuardian } = unit;
      const { orgGuid: unitOrgGuid } = extractOrgGuidAndUserId(
        unit.organizationGuid,
      );
      return selectedOrgGuid === unitOrgGuid && isParentGuardian;
    });

    return youthsWithinOrg.map(youthInfo => {
      const foundConnection =
        eventInvitees.find(invitee => invitee.userId == youthInfo.userId) || {};
      return {
        isAdult: false,
        userId: youthInfo.userId,
        rsvp: foundConnection.rsvp,
        attended: foundConnection.attended,
        shortFullName: youthInfo.connection,
      };
    });
  },
);

export const myChildrenSel = createSelector(organizationPositionsSel, units => {
  const youthsWithinOrg = units.filter(unit => unit.isParentGuardian);

  return youthsWithinOrg.map(youthInfo => ({
    userId: youthInfo.userId,
    shortFullName: youthInfo.connection,
  }));
});

export const activitiesToSelectSel = state =>
  moduleSel(state).activitiesToSelect;

export const groupedActivitiesToSelectSel = createSelector(
  unitActivityTypeIdsSel,
  activitiesToSelectSel,
  (unitActivityTypeIds, activitiesToSelect) => {
    const activities = activitiesToSelect.map(activity => ({
      id: activity.id,
      name: activity.name,
      activityTypeId: activity.activityTypeId,
      startDateTime: activity.startDateTime,
      endDateTime: activity.endDateTime,
    }));
    return unitActivityTypeIds.reduce(
      (acc, activityTypeId) => ({
        ...acc,
        [activityTypeId]: activities.filter(
          activity => activity.activityTypeId === activityTypeId,
        ),
      }),
      {},
    );
  },
);

export const selectedEventActivitiesIdsSel = state =>
  moduleSel(state).selectedEventActivitiesIds;
export const isLoadingSaveEventSel = state =>
  moduleSel(state).isLoadingSaveEvent;
export const isRSVPEventSel = state => moduleSel(state).isRSVPEvent;

export const updatePersonRSVPLoadingSel = state =>
  moduleSel(state).updatePersonRSVPLoading;

export const selectedEventUsersSel = state =>
  moduleSel(state).selectedEventUsers;

export const registeredSelectedEventUsersSel = createSelector(
  selectedEventUsersSel,
  allRostersSel,
  (selectedEventUsers, rosterItems) =>
    selectedEventUsers.filter(person =>
      rosterItems.find(active => active.userId === person.userId),
    ),
);

export const selectedEventUsersByStatusSel = createSelector(
  registeredSelectedEventUsersSel,
  registeredSelectedEventUsers =>
    groupBy(registeredSelectedEventUsers, 'status'),
);

export const selectedEventGuestsByStatusSel = createSelector(
  selectedGuestListSel,
  selectedGuestList => groupBy(selectedGuestList, 'status'),
);

export const selectedEventUserIdsSel = createSelector(
  registeredSelectedEventUsersSel,
  registeredSelectedEventUsers =>
    registeredSelectedEventUsers
      .filter(({ status }) => status !== userEventStatus.DELETED)
      .map(({ userId }) => userId),
);

export const selectedEventPersonsSel = createPersonsSelector(
  selectedEventUserIdsSel,
  true,
);

export const formatPersonDetailsSel = createSelector(
  selectedEventUsersSel,
  allRostersSel,
  selectedGuestListSel,
  filteredRostersDenChiefSel,
  selectedEventUnitsSel,
  (
    selectedEventUsers,
    rosterItems,
    selectedGuest,
    denChiefList,
    selectedEventUnits,
  ) => {
    let selectedRosterItems;

    const selectedUnitIds = selectedEventUnits.map(({ id }) => id);
    const filteredRosterItems = rosterItems.filter(person =>
      person.units.some(unit => selectedUnitIds.includes(unit.id)),
    );
    // to check if its from Creation or Edit
    if (selectedEventUsers.some(person => person.firstName)) {
      // means there are record that doesn't have other details.

      selectedRosterItems = selectedEventUsers
        .filter(person =>
          filteredRosterItems.find(active => active.userId === person.userId),
        )
        .map(record => {
          // no firstname
          if (!record.firstName) {
            const withDetails = filteredRosterItems.find(
              details => details.userId == record.userId,
            );

            return { ...record, ...withDetails };
          }

          // with firstname
          return record;
        });
    } else {
      selectedRosterItems = selectedEventUsers.map(person => {
        const otherDetails = filteredRosterItems.find(
          details => details.userId === person.userId,
        );

        if (otherDetails) {
          return { ...person, ...otherDetails };
        }

        return person;
      });
    }

    const eventUsers = selectedRosterItems
      .filter(({ status }) => status !== userEventStatus.DELETED)
      .map(selectedUser => ({
        ...selectedUser,
        personFullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
      }));

    const updatedEventUsers =
      denChiefList.length > 0
        ? eventUsers.map(person => {
            const withDetails = denChiefList.find(
              details => details.userId === person.userId,
            );

            if (withDetails)
              return { ...person, positions: withDetails.positions };

            return person;
          })
        : eventUsers;

    return updatedEventUsers.concat(selectedGuest);
  },
);

export const youthsAndAdultsEditEventSel = createSelector(
  formatPersonDetailsSel,
  selectedUnitsHasDenSel,
  (persons, selectedUnitsHasDen) =>
    groupYouthsAdultsParents(persons, selectedUnitsHasDen),
);

export const attendeesRSVPEditEventSel = createSelector(
  formatPersonDetailsSel,
  persons => groupByRSVP(persons),
);

export const filteredEventTypesSel = createSelector(
  eventTypesSel,
  selectedEventUnitsSel,
  organizationPositionsSel,
  (eventTypes, selectedEventUnits, organizations) => {
    let filteredEventTypes = [];
    selectedEventUnits.forEach(({ id, unitId, isDen }) => {
      let programTypeKey = '';

      if (unitId && isDen) {
        programTypeKey = customEvenTypeProgram.DEN;
      }

      if (unitId && !isDen) {
        programTypeKey = customEvenTypeProgram.PATROL;
      }

      if (!unitId) {
        const foundOrg = organizations.find(org => +org.unitId === id);
        programTypeKey = foundOrg ? foundOrg.program : '';
      }

      if (programTypeKey) {
        const eventTypeByUnitType = eventTypes.filter(
          eventType => eventType[eventTypesNames[programTypeKey]],
        );
        filteredEventTypes = [...filteredEventTypes, ...eventTypeByUnitType];
      }
    });
    const uniqFilteredEventTypes = chain(filteredEventTypes)
      .uniqBy('id')
      .sortBy('id')
      .value();

    const unexpiredEventTypes = uniqFilteredEventTypes.filter(
      ({ expiryDt, name }) => {
        if (expiryDt) {
          return moment(expiryDt).isAfter();
        }

        return name !== 'None';
      },
    );

    const hasPack = selectedEventUnits.some(
      unit => unit.program === Program.CUB_SCOUT,
    );
    const hasDen = selectedEventUnits.some(unit => unit.isDen);
    if (hasPack && !hasDen) {
      return unexpiredEventTypes.filter(({ id }) => id !== 12);
    }

    return unexpiredEventTypes;
  },
);

export const calendarUnitsWithPermsSel = createSelector(
  organizationPositionsSel,
  organizations => organizations.filter(org => org.canEditCalendar),
);

export const calendarUnitsWithSubOnlyPermsSel = createSelector(
  organizationPositionsSel,
  organizations =>
    organizations.filter(org => !org.canEditCalendar && org.canEditSubCalendar),
);

export const allUserSubUnitsSel = createSelector(
  allRostersSel,
  organizationPositionsSel,
  loggedInUserIdSel,
  (rostersUsers, orgPositions, loggedInUserId) => {
    const foundUser = rostersUsers.find(user => user.userId === loggedInUserId);
    if (foundUser) {
      return foundUser.units.filter(
        unit =>
          orgPositions.find(pos => unit.unitId === Number(pos.unitId)) &&
          (unit.patrolId || unit.denId) &&
          !unit.isChildUnit,
      );
    }

    return [];
  },
);

export const userUnitsSubunitsWithPermsSel = createSelector(
  allUnitsSubunitsSel,
  allUserSubUnitsSel,
  calendarUnitsWithPermsSel,
  calendarUnitsWithSubOnlyPermsSel,
  (
    allUnitsSubunits,
    allUserSubUnits,
    userUnitsWithPerms,
    userUnitsWithSubOnlyPerms,
  ) => {
    let resultSelectOptions = [];

    if (userUnitsWithPerms.length) {
      const resultUnits = allUnitsSubunits.filter(({ id, unitId }) =>
        userUnitsWithPerms.find(
          unit => +unit.unitId === id || +unit.unitId === unitId,
        ),
      );
      resultSelectOptions = resultSelectOptions.concat(resultUnits);
    }

    if (userUnitsWithSubOnlyPerms.length && allUserSubUnits.length) {
      const subUnitsWithPerms = allUserSubUnits.filter(({ unitId }) =>
        userUnitsWithSubOnlyPerms.some(unit => +unit.unitId === unitId),
      );
      const resultSubUnits = allUnitsSubunits.filter(({ id }) =>
        subUnitsWithPerms.some(sub => sub.id === id),
      );
      const subUnitsWithUnitInfo = resultSubUnits.map(sub => {
        const foundUnit = allUnitsSubunits.find(({ id }) => sub.unitId === id);
        if (foundUnit) {
          return { ...sub, unitInfo: foundUnit };
        }

        return sub;
      });
      resultSelectOptions = resultSelectOptions.concat(subUnitsWithUnitInfo);
    }

    return resultSelectOptions;
  },
);

export const userHasAnyCalendarPermSel = createSelector(
  userUnitsSubunitsWithPermsSel,
  userUnitsSubunitsWithPerms => !!userUnitsSubunitsWithPerms.length,
);

export const eventCommentsLoadingSel = state =>
  moduleSel(state).eventCommentsLoading || false;

export const eventCommentsSel = state => moduleSel(state).eventComments || [];

export const eventCommentsErrorSel = state =>
  moduleSel(state).eventCommentsError || null;

export const postingEventCommentSel = state =>
  moduleSel(state).postingEventComment || false;

export const updatingEventCommentSel = state =>
  moduleSel(state).updatingEventComment || false;
