const loginDataStorageKey = 'LOGIN_DATA';
const logoutFlagStorageKey = 'LOGGED_OUT';

const storeLoginData = loginData =>
  localStorage.setItem(loginDataStorageKey, JSON.stringify(loginData));
const retrieveLoginData = () =>
  JSON.parse(localStorage.getItem(loginDataStorageKey) || null);
const removeLoginData = () => localStorage.removeItem(loginDataStorageKey);

const storeLogoutFlag = flag =>
  localStorage.setItem(logoutFlagStorageKey, JSON.stringify(flag));
const retrieveLogoutFlag = () =>
  JSON.parse(localStorage.getItem(logoutFlagStorageKey) || false);

export default {
  storeLoginData,
  retrieveLoginData,
  removeLoginData,
  storeLogoutFlag,
  retrieveLogoutFlag,
};
