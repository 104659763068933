import partition from 'lodash/partition';
import moment from 'moment';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import { catchError } from 'rxjs/operators';

import {
  esbApiService,
  memoizeShareObservable,
  personNameBuilder,
} from '@utils';

import { UNIT_PARTICIPANT } from '../constants';
import { adultPackRosterDtoToModel, youthPackRosterDtoToModel } from '../utils';
import { dedupeMembers } from '../utilsTyped';

/**
 * @esbEndpoint GET /organizations/v2/units/:organizationGuid/adults
 */
const getAdults$ = (organizationGuid, suppressErrorToasts) =>
  esbApiService
    .get$(`/organizations/v2/units/${organizationGuid}/adults`, {
      gtm: {
        label: '/organizations/v2/units/{organizationGuid}/adults',
      },
      swCache: true,
      suppressErrorToasts,
      suppressSentry: suppressErrorToasts,
    })
    .pipe(
      catchError(err => {
        if (err.status !== 404) {
          throw err;
        }
        return of({ users: [] });
      }),
    );

/**
 * @esbEndpoint GET /organizations/v2/units/:organizationGuid/youths
 */
const getYouths$ = (organizationGuid, suppressErrorToasts) =>
  esbApiService
    .get$(`/organizations/v2/units/${organizationGuid}/youths`, {
      gtm: {
        label: '/organizations/v2/units/{organizationGuid}/youths',
      },
      swCache: true,
      suppressErrorToasts,
      suppressSentry: suppressErrorToasts,
    })
    .pipe(
      catchError(err => {
        if (err.status !== 404) {
          throw err;
        }
        return of({ users: [] });
      }),
    );

/**
 * @esbEndpoint GET /organizations/v2/units/:organizationGuid/parents
 */
const getParents$ = (organizationGuid, suppressErrorToasts) =>
  esbApiService
    .get$(`/organizations/v2/units/${organizationGuid}/parents`, {
      gtm: {
        label: '/organizations/v2/units/{organizationGuid}/parents',
      },
      swCache: true,
      suppressErrorToasts,
      suppressSentry: suppressErrorToasts,
    })
    .pipe(
      catchError(err => {
        if (err.status !== 400 && err.status !== 404) {
          throw err;
        }
        return of([]);
      }),
    );

const parentPackRosterDtoToModel = roster => {
  const getParentsOnly = roster.map(({ parentUserId, parentInformation }) => ({
    parentUserId,
    parentInformation,
  }));

  return getParentsOnly
    .filter(member => Number(member.parentUserId) > 0)
    .map(member => ({
      userId: Number(member.parentUserId),
      isAdult: true,
      isParent: true,
      isLeader: false,
      ...member.parentInformation,
      key3: member.parentInformation.key3 === 'True',
      personId: member.parentInformation.memberId,
      personShortFullName: personNameBuilder.short(member.parentInformation),
      age: member.parentInformation.age
        ? member.parentInformation.age
        : member.parentInformation.dateOfBirth &&
          moment().diff(member.parentInformation.dateOfBirth, 'years'),
      currentHighestRankApproved: { rank: '' },
      otherHighestRanksApproved: [],
      rosterOtherHighestRanksApproved: [],
    }));
};

const getPackRoster$ = memoizeShareObservable(
  ({
    organizationGuid,
    currentUnitTypeId,
    isSubUnitAllowed,
    ignoreSubUnits = false,
  }) => {
    const isSubUnitRequest = isSubUnitAllowed && !ignoreSubUnits;
    const suppressErrorToasts = isSubUnitRequest;

    return getAdults$(organizationGuid, suppressErrorToasts)
      .map(members => adultPackRosterDtoToModel(members))
      .switchMap(adultsResponse => {
        const [unitParticipants, adults] = partition(
          adultsResponse,
          adult => adult.position === UNIT_PARTICIPANT,
        );
        return Observable.forkJoin([
          getYouths$(organizationGuid, suppressErrorToasts).map(members =>
            youthPackRosterDtoToModel(
              members,
              currentUnitTypeId,
              unitParticipants,
            ),
          ),

          getParents$(organizationGuid, suppressErrorToasts).map(members =>
            parentPackRosterDtoToModel(members),
          ),

          Observable.of(adults),
        ]);
      })
      .map(([youths, adults, parents]) =>
        dedupeMembers([...youths, ...adults, ...parents]),
      );
  },
);

/**
 * @esbEndpoint POST /advancements/v2/:organizationGuid/sbExtendedProfile
 */
const saveSBExtendedProfile$ = (data, organizationGuid) =>
  esbApiService.post$(
    `/advancements/v2/${organizationGuid}/sbExtendedProfile`,
    data,
    {
      gtm: {
        label: '/advancements/v2/{organizationGuid}/sbExtendedProfile',
      },
    },
  );

/**
 * @esbEndpoint GET /organizations/v2/:organizationGuid/orgYouths
 */
const getOrgYouths$ = (data, organizationGuid) =>
  esbApiService.post$(`/organizations/v2/${organizationGuid}/orgYouths`, data, {
    gtm: {
      label: '/organizations/v2/{organizationGuid}/orgYouths',
    },
    swCache: true,
    suppressErrorToasts: true,
  });

/**
 * @esbEndpoint GET /organizations/v2/:organizationGuid/orgAdults
 */
const getOrgAdults$ = (data, organizationGuid) =>
  esbApiService.post$(`/organizations/v2/${organizationGuid}/orgAdults`, data, {
    gtm: {
      label: '/organizations/v2/{organizationGuid}/orgAdults',
    },
    swCache: true,
    suppressErrorToasts: true,
  });

/**
 * @esbEndpoint PUT /persons/:personGuid/membershipRegistrations/:registrationId
 */
const saveMembershipRegistration$ = (data, personGuid, registrationId) =>
  esbApiService.put$(
    `/persons/${personGuid}/membershipRegistrations/${registrationId}`,
    data,
    {
      gtm: {
        label: '/persons/{personGuid}/membershipRegistrations/{registrationId}',
      },
    },
  );

export default {
  getPackRoster$,
  saveSBExtendedProfile$,
  getYouths$,
  getParents$,
  getAdults$,
  getOrgYouths$,
  getOrgAdults$,
  saveMembershipRegistration$,
};
