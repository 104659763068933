/* eslint-disable arrow-body-style */
import _, { isEmpty } from 'lodash';

import type { AdvancementHistoryItem } from '@appTypes/esb';
import { denormalizeAdvancementHistoryItem } from '@modules/advancement/advancementHistory/utils';
import type {
  GroupedPendingItem,
  PendingItem,
} from '@modules/advancement/pendingItems/types';
import { historyItemsTypes } from '@modules/shared/constants';
import { getPreferredName } from '@modules/shared/utils/preferredName';

function hasPendingRequirement(item: AdvancementHistoryItem) {
  if (isEmpty(item.recordedInformation)) return false;

  if (item.recordedInformation.some(info => !isEmpty(info.requirements)))
    return true;

  return false;
}

export function groupPendingItems(
  pendingItems: AdvancementHistoryItem[],
): GroupedPendingItem[] {
  const result = _.chain(pendingItems)
    .defaultTo([])
    .filter(hasPendingRequirement)
    .map(item => {
      return {
        ...item,
        recordedInformation: item.recordedInformation.filter(adv => {
          return !isEmpty(adv.requirements);
        }),
      };
    })
    .flatMap(denormalizeAdvancementHistoryItem)
    .flatMap(item => {
      return item.requirements
        .filter(req => req.isCompleted && !req.childrenRequired)
        .map(req => ({
          ...item,
          ...req,
          name: getPreferredName({
            nickName: item.nickName || '',
            firstName: item.firstName,
            lastName: item.lastName,
          }),
          advancementId: item.id,
          advancement: item,
          key: `${item.memberId}-${req.id}`,
          isAdvancementPendingApprovable: true,
          isAdvancementPending: true,
          type: historyItemsTypes.REQUIREMENT,
        }));
    })
    .groupBy('memberId')
    .mapValues(pendingItems => {
      const first = pendingItems[0] || {};
      const totalAmount = pendingItems.length;
      const itemTypes = Array.from(
        new Set(pendingItems.map(item => item.advancementType)),
      );
      const description = itemTypes.map(_.startCase).join(', ');

      const groupId = `group-${first.memberId}`;

      const groupItem = {
        children: pendingItems as unknown as Array<
          Omit<PendingItem, 'key'> & { key: string }
        >,
        name: `${first.firstName} ${first.lastName}`,
        firstName: first.firstName,
        key: groupId,
        lastName: first.lastName,
        profilePictureUrl: first.profilePictureUrl,
        groupId: `group-${first.memberId}`,
        advancementType: first.advancementType,
        memberId: first.memberId,
        totalAmount,
        description,
      } as GroupedPendingItem;

      return groupItem;
    })
    .values()
    .value();

  return result;
}
