import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Modal, Row } from '@shared/components';
import { intl } from '@shared/localization';

import { stepOrder } from '../../../constants';
import {
  closeValidationModal,
  currentStepSel,
  isValidationModalVisibleSel,
  openPrivacyModal,
  pendingMembersSel,
  removePendingMembersRequest,
  rosterHasPaperApplicationsSel,
  rosterValidationLoadingSel,
  rosterValidationRejectionSel,
  setCurrentStep,
} from '../../../duck';
import ValidationContent from './ValidationContent';
import styles from './ValidationModal.less';

const ValidationModal = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(currentStepSel);
  const isVisible = useSelector(isValidationModalVisibleSel);
  const rejected = useSelector(rosterValidationRejectionSel);
  const validationLoading = useSelector(rosterValidationLoadingSel);
  const pendingMembers = useSelector(pendingMembersSel);
  const hasPaperApplications = useSelector(rosterHasPaperApplicationsSel);

  const handleCloseValidationModal = () => {
    dispatch(closeValidationModal());
  };

  const proceedToNextStep = () => {
    const currentIndex = stepOrder.indexOf(currentStep);
    dispatch(setCurrentStep(stepOrder[currentIndex + 1]));
    dispatch(closeValidationModal());
  };

  const goBack = () => {
    dispatch(setCurrentStep(currentStep));
    handleCloseValidationModal();
  };

  const openPrivacyAgreement = () => {
    handleCloseValidationModal();
    if (!isEmpty(pendingMembers)) {
      dispatch(removePendingMembersRequest());
    }
    dispatch(openPrivacyModal());
  };

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.validationModal.title',
      })}
      onCancel={goBack}
      onBack={goBack}
    >
      <ValidationContent isValidationLoading={validationLoading} />
      <Row>
        <Col offset={4}>
          <div style={{ float: 'right' }}>
            <Button
              type="default"
              color="scouting-warm-gray"
              onClick={goBack}
              className={styles.EditButton}
            >
              <FormattedMessage id="recharterModals.validationModal.edit" />
            </Button>
            <Button
              type="primary"
              disabled={validationLoading || rejected}
              onClick={
                hasPaperApplications
                  ? hasPaperApplications.validationFlag
                    ? openPrivacyAgreement
                    : proceedToNextStep
                  : proceedToNextStep
              }
            >
              <FormattedMessage id="recharterModals.validationModal.proceed" />
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default ValidationModal;
