import { isEmpty } from 'lodash';
import { replace } from 'redux-first-router';

import { payloadSel, querySel } from '@location';
import {
  emptyObj,
  organizationPositionsSel,
  permissions,
  setOrganization,
} from '@shared';

import {
  CalendarActivityDetails,
  CalendarCreateActivity,
  CalendarEditActivity,
  CalendarEventDetails,
  CalendarPage,
} from './components';
import {
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_ACTIVITY_DETAILS,
  ROUTE_CALENDAR_CREATE_ACTIVITY,
  ROUTE_CALENDAR_EDIT_ACTIVITY,
  ROUTE_CALENDAR_EVENT_DETAILS,
} from './duck';

const routes = {
  [ROUTE_CALENDAR]: {
    path: '/calendar',
    Component: CalendarPage,
    titleId: 'page.calendar',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;

      const youthOrgGuid = organizationGuid;

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions)) {
        const foundPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        replace('/calendar');
        if (foundPosition) {
          dispatch(
            setOrganization({
              guid: foundPosition.organizationGuid,
              programType: foundPosition.programType,
              parentOrgGuid: foundPosition.organizationGuid,
            }),
          );
        }
      }
    },
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_CALENDAR_ACTIVITY_DETAILS]: {
    path: '/calendar/activity/:id',
    Component: CalendarActivityDetails,
    titleId: 'page.activityDetails',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;

      const youthOrgGuid = organizationGuid;

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions)) {
        const foundPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        if (foundPosition) {
          dispatch(
            setOrganization({
              guid: foundPosition.organizationGuid,
              programType: foundPosition.programType,
              parentOrgGuid: foundPosition.organizationGuid,
            }),
          );
        }
      }
    },
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_CALENDAR_EVENT_DETAILS]: {
    path: '/calendar/event/:id',
    Component: CalendarEventDetails,
    titleId: 'page.eventDetails',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;
      const payload = payloadSel(state);
      const youthOrgGuid = organizationGuid;

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions) && !isEmpty(payload)) {
        const { id } = payload;
        const foundPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        replace(`/calendar/event/${id}`);
        if (foundPosition) {
          dispatch(
            setOrganization({
              guid: foundPosition.organizationGuid,
              programType: foundPosition.programType,
              parentOrgGuid: foundPosition.organizationGuid,
            }),
          );
        }
      }
    },
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_CALENDAR_CREATE_ACTIVITY]: {
    path: '/calendar/createActivity/:activityTypeId',
    Component: CalendarCreateActivity,
    titleId: 'page.newActivity',
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
  [ROUTE_CALENDAR_EDIT_ACTIVITY]: {
    path: '/calendar/editActivity/:activityId',
    Component: CalendarEditActivity,
    titleId: 'page.editActivity',
    permission: permissions.VIEW_CALENDAR_PAGE,
  },
};

export default routes;
