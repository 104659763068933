import React from 'react';
import PropTypes from 'prop-types';
import stable from 'stable';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@material-ui/icons/Info';

import { S, Tooltip, ProgramName, Img } from '@shared';
import styles from './OtherRanksTooltip.less';

const trigger = ['hover', 'click'];
const stopPropagation = e => e.stopPropagation();

const OtherRanksTooltip = React.memo(function OtherRanksTooltip({
  currentRank,
  otherRanks = [],
}) {
  const { rank, unitTypeId, imageUrl } = currentRank;
  const hasOtherRanks = otherRanks.length > 0;
  let returnNode = (
    <S size="4">
      <Img className={styles.img} src={imageUrl} />
      {rank}
      {hasOtherRanks && <InfoIcon className={styles.icon} />}
    </S>
  );

  if (hasOtherRanks) {
    const orderedHighestApprovedRanks = stable(
      otherRanks,
      ({ unitTypeId: idA }, { unitTypeId: idB }) => idA > idB,
    );
    const highestApprovedRanks = [
      { rank, unitTypeId },
      ...orderedHighestApprovedRanks,
    ];
    const tooltipContent = (
      <div>
        <div>
          <FormattedMessage id="advancement.OtherRanksTooltip.highestRanksApproved" />
        </div>
        {highestApprovedRanks.map(({ rank, unitTypeId }) => (
          <div key={unitTypeId}>
            <ProgramName programId={unitTypeId} />: {rank}
          </div>
        ))}
      </div>
    );

    returnNode = (
      <div className={styles.wrapper} onClick={stopPropagation}>
        <Tooltip placement={'top'} trigger={trigger} title={tooltipContent}>
          {returnNode}
        </Tooltip>
      </div>
    );
  }

  return returnNode;
});

OtherRanksTooltip.propTypes = {
  currentRank: PropTypes.object.isRequired,
  otherRanks: PropTypes.array.isRequired,
};

export default OtherRanksTooltip;
