import React from 'react';
import ContentLoader from 'react-content-loader';

const LoadingPlaceholder: React.FC = () => (
  <ContentLoader width={100} height={100}>
    <circle cx="50" cy="50" r="50" />
  </ContentLoader>
);

export default LoadingPlaceholder;
