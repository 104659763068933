import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { OrgSubUnit } from '@appTypes/esb';
import {
  FormattedSubUnitAdult,
  FormattedSubUnitYouth,
} from '@modules/advancement/utilsTyped';
import { esbApiService } from '@modules/utils';

/**
 * @esbEndpoint GET /organizations/v2/units/:organizationGuid/subUnits
 */
const getSubUnits$ = (organizationGuid: string): Observable<OrgSubUnit[]> =>
  esbApiService
    .get$(`/organizations/v2/units/${organizationGuid}/subUnits`, {
      gtm: {
        label: 'organizations/v2/units/{organizationGuid}/subUnits',
      },
      suppressErrorToasts: true,
      suppressSentry: [400, 404, 401],
      swCache: true,
    })
    .pipe(
      catchError(err => {
        if (err.status !== 400 && err.status !== 404 && err.status !== 401) {
          throw err;
        }
        return of([]);
      }),
    );

/**
 * @esbEndpoint POST /organizations/v2/units/:organizationGuid/:subUnitType/add
 */
const createSubUnit$ = ({
  organizationGuid,
  subUnitType,
  subUnitData,
}: {
  organizationGuid: string;
  subUnitType: string;
  subUnitData: {
    subUnitName: string;
    subUnitApproved: boolean;
    forumEnabled: boolean;
    denType?: string;
    showDlEvents?: boolean;
  };
}): Observable<{ id: number }> =>
  esbApiService.post$(
    `/organizations/v2/units/${organizationGuid}/${subUnitType}/add`,
    subUnitData,
    {
      gtm: {
        label: 'organizations/v2/units/{organizationGuid}/{subUnit}/add',
      },
    },
  );

/**
 * @esbEndpoint DELETE /organizations/v2/units/:organizationGuid/:subUnitType/:subUnitId
 */
const deleteSubUnit$ = (
  organizationGuid: string,
  subUnitType: string,
  subUnitId: number,
): Observable<unknown> =>
  esbApiService.delete$(
    `/organizations/v2/units/${organizationGuid}/${subUnitType}/${subUnitId}`,
    {
      gtm: {
        label:
          'organizations/v2/units/{organizationGuid}/{subUnit}/{subUnitId}',
      },
    },
  );
/**
 * @esbEndpoint POST /organizations/v2/units/:organizationGuid/:subUnitType/:subUnitId/youths
 */
const addSubUnitYouths$ = (
  organizationGuid: string,
  subUnitType: string,
  subUnitId: number,
  data: FormattedSubUnitYouth[],
): Observable<{ message: string }> =>
  esbApiService.post$(
    `/organizations/v2/units/${organizationGuid}/${subUnitType}/${subUnitId}/youths`,
    data,
    {
      gtm: {
        label:
          'organizations/v2/units/{organizationGuid}/{subUnit}/{subUnitId}/youths',
      },
    },
  );

/**
 * @esbEndpoint POST /organizations/v2/units/:organizationGuid/:subUnitType/:subUnitId/adults
 */
const addSubUnitAdults$ = (
  organizationGuid: string,
  subUnitType: string,
  subUnitId: number,
  data: FormattedSubUnitAdult[],
): Observable<{ message: string }> =>
  esbApiService.post$(
    `/organizations/v2/units/${organizationGuid}/${subUnitType}/${subUnitId}/adults`,
    data,
    {
      gtm: {
        label:
          'organizations/v2/units/{organizationGuid}/{subUnit}/{subUnitId}/adults',
      },
    },
  );
/**
 * @esbEndpoint PUT /organizations/v2/units/:organizationGuid/:subUnitType/:subUnitId
 */
const editSubUnit$ = ({
  organizationGuid,
  subUnitType,
  subUnitId,
  subUnitData,
}: {
  organizationGuid: string;
  subUnitType: string;
  subUnitId: number;
  subUnitData: {
    subUnitName: string;
    denType?: string;
  };
}) =>
  esbApiService.put$(
    `/organizations/v2/units/${organizationGuid}/${subUnitType}/${subUnitId}`,
    subUnitData,
    {
      gtm: {
        label:
          'organizations/v2/units/{organizationGuid}/{subUnit}/{subUnitId}',
      },
    },
  );

/**
 * @esbEndpoint DELETE /organizations/v2/units/:organizationGuid/:subUnitType/:subUnitId/users
 */

const deleteSubUnitUsers$ = (
  organizationGuid: string,
  subUnitType: string,
  subUnitId: number,
  usersToDelete: number[],
) =>
  esbApiService.delete$(
    `/organizations/v2/units/${organizationGuid}/${subUnitType}/${subUnitId}/users?userId=${usersToDelete.toString()}`,
    {
      gtm: {
        label:
          'organizations/v2/units/{organizationGuid}/{subUnit}/{subUnitId}/users',
      },
    },
  );

export default {
  getSubUnits$,
  createSubUnit$,
  deleteSubUnit$,
  addSubUnitYouths$,
  addSubUnitAdults$,
  editSubUnit$,
  deleteSubUnitUsers$,
};
