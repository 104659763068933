import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
import cn from 'classnames';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { S, Button } from '@shared';
import { toastService } from '@toasts';
import styles from './UploadDropzone.less';

class UploadDropzone extends React.PureComponent {
  handleDrop = ([acceptedFile]) => {
    if (acceptedFile) {
      this.props.onDrop(acceptedFile);
    } else {
      toastService.error(
        <React.Fragment>
          <FormattedMessage id="advancementUpload.UploadDropzone.invalidAction" />
        </React.Fragment>,
      );
    }
  };

  render() {
    const { className, disabled } = this.props;

    return (
      <Dropzone
        className={cn(styles.container, className)}
        activeClassName={styles.active}
        disabledClassName={styles.disabled}
        accept=".txt"
        multiple={false}
        disabled={disabled}
        onDrop={this.handleDrop}
      >
        <div className={styles.content}>
          <InsertDriveFileIcon className={styles.icon} />
          <div className={styles.instructions}>
            <S size="2">
              <FormattedMessage id="advancementUpload.UploadDropzone.importLimit" />
            </S>
            <S size="2">
              <FormattedMessage id="advancementUpload.UploadDropzone.dragHere" />
            </S>
            <S size="2">
              <FormattedMessage id="advancementUpload.UploadDropzone.or" />
            </S>
          </div>
          <Button color="info" disabled={disabled} bold>
            <FormattedMessage id="advancementUpload.UploadDropzone.browse" />
          </Button>
        </div>
      </Dropzone>
    );
  }
}

UploadDropzone.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default UploadDropzone;
