import { sorters } from '@utils';

const nameSorter = sorters.stringMulti('lastName', 'firstName');
const advancementItemSorter = sorters.string('filterableName');
const completionDateSorter = sorters.date('date');
const defaultSorter = sorters.compose(
  sorters.date('date', true),
  nameSorter,
  advancementItemSorter,
);

export default {
  name: nameSorter,
  advancementItem: advancementItemSorter,
  completionDate: completionDateSorter,
  default: defaultSorter,
};
