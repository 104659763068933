import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Avatar from '../Avatar';
import styles from './IconAvatar.less';

const IconAvatar = ({ children, className }) => {
  const classNames = cn(styles.container, className);
  const decoratedChildren = React.cloneElement(children, {
    className: cn(children.props.className, styles.icon),
  });

  return <Avatar className={classNames} placeholder={decoratedChildren} />;
};

IconAvatar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default IconAvatar;
