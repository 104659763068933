import { toNumber } from 'lodash';

import { gtm } from '@modules/shared';
import { basicActionCreator } from '@utils';

import { moduleName } from '../constants';

export const ROUTE_EVENT_RSVP = `${moduleName}/ROUTE_EVENT_RSVP`;
export const ROUTE_EVENTS_CREATE = `${moduleName}/ROUTE_EVENTS_CREATE`;
export const goToEventsCreate = payload =>
  basicActionCreator(ROUTE_EVENTS_CREATE, payload);

export const ROUTE_EVENTS_ADD_ADVANCEMENT = `${moduleName}/ROUTE_EVENTS_ADD_ADVANCEMENT`;
export const goToEventsAddAdvancement = advancementType => ({
  type: ROUTE_EVENTS_ADD_ADVANCEMENT,
  payload: {
    advancementType,
  },
});

export const ROUTE_EVENTS_EDIT = `${moduleName}/ROUTE_EVENTS_EDIT`;
export const goToEventsEdit = (
  /** @type {import('@modules/events/duck/types').GoToEventsEditState} */
  { eventId, clone, recurringEventId },
) => {
  const query = {};
  query.clone = clone ? clone : undefined;
  query.recurringEventId = recurringEventId;
  return {
    type: ROUTE_EVENTS_EDIT,
    payload: { eventId },
    query,
  };
};

export const DELETE_EVENT_RESPONSE = `${moduleName}/DELETE_EVENT_RESPONSE`;
export const deleteEventResponse = () =>
  basicActionCreator(DELETE_EVENT_RESPONSE);

export const GET_EVENT_TYPES_REQUEST = `${moduleName}/GET_EVENT_TYPES_REQUEST`;
export const getEventTypesRequest = () =>
  basicActionCreator(GET_EVENT_TYPES_REQUEST);

export const GET_EVENT_TYPES_RESPONSE = `${moduleName}/GET_EVENT_TYPES_RESPONSE`;
export const getEventTypesResponse = payload =>
  basicActionCreator(GET_EVENT_TYPES_RESPONSE, payload);

export const GET_EVENT_TYPES_ERROR = `${moduleName}/GET_EVENT_TYPES_ERROR`;
export const getEventTypesError = payload =>
  basicActionCreator(GET_EVENT_TYPES_ERROR, payload);

export const RECORD_EVENT_REQUEST = `${moduleName}/RECORD_EVENT_REQUEST`;

export const ADD_EVENT_ADVANCEMENT = `${moduleName}/ADD_EVENT_ADVANCEMENT`;
export const addEventAdvancement = payload =>
  basicActionCreator(ADD_EVENT_ADVANCEMENT, payload);

export const DELETE_EVENT_ADVANCEMENT = `${moduleName}/DELETE_EVENT_ADVANCEMENT`;
export const deleteEventAdvancement = payload =>
  basicActionCreator(DELETE_EVENT_ADVANCEMENT, payload);

export const SET_SELECTED_ADVANCEMENT = `${moduleName}/SET_SELECTED_ADVANCEMENT`;
export const setSelectedAdvancement = payload =>
  basicActionCreator(SET_SELECTED_ADVANCEMENT, payload, {
    gtm: gtm.categories.SET_SELECTED_ADVANCEMENT,
  });

export const recordEventRequest = payload =>
  basicActionCreator(RECORD_EVENT_REQUEST, payload, {
    gtm: gtm.categories.CALENDAR_EVENT,
  });

export const RECORD_EVENT_RESPONSE = `${moduleName}/RECORD_EVENT_RESPONSE`;
export const recordEventResponse = () =>
  basicActionCreator(RECORD_EVENT_RESPONSE);

export const RECORD_EVENT_ERROR = `${moduleName}/RECORD_EVENT_ERROR`;
export const recordEventError = err =>
  basicActionCreator(RECORD_EVENT_ERROR, err);

export const GET_EVENT_BY_ID_REQUEST = `${moduleName}/GET_EVENT_BY_ID_REQUEST`;
export const getEventByIdRequest = eventId =>
  basicActionCreator(GET_EVENT_BY_ID_REQUEST, eventId, {
    gtm: gtm.categories.CALENDAR_EVENT,
  });

export const GET_EVENT_BY_ID_RESPONSE = `${moduleName}/GET_EVENT_BY_ID_RESPONSE`;
export const getEventByIdResponse = (data, clone = false) =>
  basicActionCreator(GET_EVENT_BY_ID_RESPONSE, { data, clone });

export const GET_EVENT_BY_ID_ERROR = `${moduleName}/GET_EVENT_BY_ID_ERROR`;
export const getEventByIdError = err =>
  basicActionCreator(GET_EVENT_BY_ID_ERROR, err);

export const GET_ACTIVITIES_TO_SELECT_REQUEST = `${moduleName}/GET_ACTIVITIES_TO_SELECT_REQUEST`;
export const getActivitiesToSelectRequest = payload =>
  basicActionCreator(GET_ACTIVITIES_TO_SELECT_REQUEST, payload);

export const GET_ACTIVITIES_TO_SELECT_RESPONSE = `${moduleName}/GET_ACTIVITIES_TO_SELECT_RESPONSE`;
export const getActivitiesToSelectResponse = payload =>
  basicActionCreator(GET_ACTIVITIES_TO_SELECT_RESPONSE, payload);

export const GET_ACTIVITIES_TO_SELECT_ERROR = `${moduleName}/GET_ACTIVITIES_TO_SELECT_ERROR`;
export const getActivitiesToSelectError = err =>
  basicActionCreator(GET_ACTIVITIES_TO_SELECT_ERROR, err);

export const SELECT_EVENT_ACTIVITY = `${moduleName}/SELECT_EVENT_ACTIVITY`;
export const selectEventActivity = err =>
  basicActionCreator(SELECT_EVENT_ACTIVITY, err);

export const DESELECT_EVENT_ACTIVITY = `${moduleName}/DESELECT_EVENT_ACTIVITY`;
export const deselectEventActivity = err =>
  basicActionCreator(DESELECT_EVENT_ACTIVITY, err);

export const GET_ACTIVITY_BY_ID_REQUEST = `${moduleName}/GET_ACTIVITY_BY_ID_REQUEST`;
export const getActivityByIdRequest = id =>
  basicActionCreator(GET_ACTIVITY_BY_ID_REQUEST, id);

export const GET_ACTIVITY_BY_ID_ERROR = `${moduleName}/GET_ACTIVITY_BY_ID_ERROR`;
export const getActivityByIdError = err =>
  basicActionCreator(GET_ACTIVITY_BY_ID_ERROR, err);

export const GET_ACTIVITY_BY_ID_RESPONSE = `${moduleName}/GET_ACTIVITY_BY_ID_RESPONSE`;
export const getActivityByIdResponse = payload =>
  basicActionCreator(GET_ACTIVITY_BY_ID_RESPONSE, payload);

export const GET_EVENT_INVITEES_RESPONSE = `${moduleName}/GET_EVENT_INVITEES_RESPONSE`;
export const getEventInviteesResponse = payload =>
  basicActionCreator(GET_EVENT_INVITEES_RESPONSE, payload);

export const CONFIRM_INVITE_REQUEST = `${moduleName}/CONFIRM_INVITE_REQUEST`;
export const confirmInviteRequest = payload =>
  basicActionCreator(CONFIRM_INVITE_REQUEST, payload);

export const CONFIRM_INVITE_ERROR = `${moduleName}/CONFIRM_INVITE_ERROR`;
export const confirmInviteError = err =>
  basicActionCreator(CONFIRM_INVITE_ERROR, err);

export const CONFIRM_INVITE_RESPONSE = `${moduleName}/CONFIRM_INVITE_RESPONSE`;
export const confirmInviteResponse = payload =>
  basicActionCreator(CONFIRM_INVITE_RESPONSE, payload);

export const CHANGE_EVENT_INVITEE_RSVP = `${moduleName}/CHANGE_EVENT_INVITEE_RSVP`;
export const changeEventInviteeRsvp = (data, updateRsvp) =>
  basicActionCreator(CHANGE_EVENT_INVITEE_RSVP, {
    data,
    updateRsvp,
  });

export const IS_VISIBLE_POPOVER = `${moduleName}/IS_VISIBLE_POPOVER`;
export const isVisiblePopover = visible =>
  basicActionCreator(IS_VISIBLE_POPOVER, visible);

export const SET_ADVANCEMENT_CURRENT_STEP = `${moduleName}/SET_ADVANCEMENT_CURRENT_STEP`;
export const setAdvancementCurrentStep = currentStep =>
  basicActionCreator(SET_ADVANCEMENT_CURRENT_STEP, currentStep);

export const SEARCH_ADVANCEMENT = `${moduleName}/SEARCH_ADVANCEMENT`;
export const setSearchAdvancement = (searchAdvancement = '') =>
  basicActionCreator(SEARCH_ADVANCEMENT, searchAdvancement);

export const SET_ADVANCEMENT_TYPE = `${moduleName}/SET_ADVANCEMENT_TYPE`;
export const setAdvancementType = advancementType =>
  basicActionCreator(SET_ADVANCEMENT_TYPE, advancementType);

export const SET_SHOW_FILTER_POPOVER = `${moduleName}/SET_SHOW_FILTER_POPOVER`;
export const setShowFilterPopover = showPopover =>
  basicActionCreator(SET_SHOW_FILTER_POPOVER, showPopover);

export const SET_ATTENDEE_GROUP_FILTER = `${moduleName}/SET_ATTENDEE_GROUP_FILTER`;
export const setAttendeeGroupFilter = groupBy =>
  basicActionCreator(SET_ATTENDEE_GROUP_FILTER, groupBy);

export const SET_ATTENDEE_SORT_ORDER = `${moduleName}/SET_ATTENDEE_SORT_ORDER`;
export const setAttendeeSortOrder = sortBy =>
  basicActionCreator(SET_ATTENDEE_SORT_ORDER, sortBy);

export const GET_REQUIREMENTS_REQUEST = `${moduleName}/GET_REQUIREMENTS_REQUEST`;
export const getRequirementsRequest = () =>
  basicActionCreator(GET_REQUIREMENTS_REQUEST);

export const GET_REQUIREMENTS_RESPONSE = `${moduleName}/GET_REQUIREMENTS_RESPONSE`;
export const getRequirementsResponse = requirements =>
  basicActionCreator(GET_REQUIREMENTS_RESPONSE, requirements);

export const GET_REQUIREMENTS_ERROR = `${moduleName}/GET_REQUIREMENTS_ERROR`;
export const getRequirementsError = error =>
  basicActionCreator(GET_REQUIREMENTS_ERROR, error);

export const SET_ADVANCEMENT_REQUIREMENTS = `${moduleName}/SET_ADVANCEMENT_REQUIREMENTS`;
export const setAdvancementRequirements = requirements =>
  basicActionCreator(SET_ADVANCEMENT_REQUIREMENTS, requirements);

export const SAVE_EVENT_REQUEST = `${moduleName}/SAVE_EVENT_REQUEST`;
export const saveEventRequest = (event, onSuccess, onFailure, clone) =>
  basicActionCreator(SAVE_EVENT_REQUEST, {
    event,
    onSuccess,
    onFailure,
    clone,
  });

export const SAVE_EVENT_RESPONSE = `${moduleName}/SAVE_EVENT_RESPONSE`;
export const saveEventResponse = payload =>
  basicActionCreator(SAVE_EVENT_RESPONSE, payload);

export const SAVE_EVENT_ERROR = `${moduleName}/SAVE_EVENT_ERROR`;
export const saveEventError = error =>
  basicActionCreator(SAVE_EVENT_ERROR, error);

export const SAVE_RECURRENT_EVENT_REQUEST = `${moduleName}/SAVE_RECURRENT_EVENT_REQUEST`;
export const saveRecurrentEventRequest = (event, onSuccess, onFailure) => ({
  type: SAVE_RECURRENT_EVENT_REQUEST,
  payload: {
    event,
    onSuccess,
    onFailure,
  },
});

export const UPDATE_RECURRENT_EVENT_REQUEST = `${moduleName}/UPDATE_RECURRENT_EVENT_REQUEST`;
export const updateRecurrentEventRequest = (
  recurrentId,
  event,
  onSuccess,
  onFailure,
) =>
  basicActionCreator(UPDATE_RECURRENT_EVENT_REQUEST, {
    recurrentId,
    event,
    onSuccess,
    onFailure,
  });

export const UPDATE_RECURRENT_EVENTS_RESPONSE = `${moduleName}/UPDATE_RECURRENT_EVENTS_RESPONSE`;
export const updateRecurrentEventsResponse = payload =>
  basicActionCreator(UPDATE_RECURRENT_EVENTS_RESPONSE, payload);

export const UPDATE_RECURRENT_EVENTS_ERROR = `${moduleName}/UPDATE_RECURRENT_EVENTS_ERROR`;
export const updateRecurrentEventsError = error =>
  basicActionCreator(UPDATE_RECURRENT_EVENTS_ERROR, error);

export const UPDATE_RECURRENT_EVENT_BY_RECREATION_REQUEST = `${moduleName}/UPDATE_RECURRENT_EVENT_BY_RECREATION_REQUEST`;
export const updateRecurrentEventByRecreationRequest = (
  recurrentId,
  event,
  onSuccess,
  onFailure,
) =>
  basicActionCreator(UPDATE_RECURRENT_EVENT_BY_RECREATION_REQUEST, {
    recurrentId,
    event,
    onSuccess,
    onFailure,
  });

export const UPDATE_RSVP_EVENT_PERSON = `${moduleName}/UPDATE_RSVP_EVENT_PERSON`;
export const updateRSVPEventPerson = data =>
  basicActionCreator(UPDATE_RSVP_EVENT_PERSON, data);

export const DESELECT_EVENT_PERSON = `${moduleName}/DESELECT_EVENT_PERSON`;
export const deselectEventPerson = userId =>
  basicActionCreator(DESELECT_EVENT_PERSON, userId);

export const DESELECT_ALL_UNIT_ATTENDEES = `${moduleName}/DESELECT_ALL_UNIT_ATTENDEES`;
export const deselectAllUnitAttendees = allUnitAttendees =>
  basicActionCreator(DESELECT_ALL_UNIT_ATTENDEES, allUnitAttendees);

export const SELECT_EVENT_PERSON = `${moduleName}/SELECT_EVENT_PERSON`;
export const selectEventPerson = userId =>
  basicActionCreator(SELECT_EVENT_PERSON, userId);

export const GET_EVENT_REMINDERS_RESPONSE = `${moduleName}/GET_EVENT_REMINDERS_RESPONSE`;
export const getEventRemindersResponse = eventId =>
  basicActionCreator(GET_EVENT_REMINDERS_RESPONSE, eventId);

export const ADD_EVENT_REMINDER = `${moduleName}/ADD_EVENT_REMINDER`;
export const addEventReminder = () => basicActionCreator(ADD_EVENT_REMINDER);

export const DELETE_EVENT_REMINDER_LOCAL_ONLY = `${moduleName}/DELETE_EVENT_REMINDER_LOCAL_ONLY`;
export const deleteEventReminderLocalOnly = index =>
  basicActionCreator(DELETE_EVENT_REMINDER_LOCAL_ONLY, index);

export const DELETE_EVENT_REMINDER_REQUEST = `${moduleName}/DELETE_EVENT_REMINDER_REQUEST`;
export const deleteEventReminderRequest = (eventId, reminderId) =>
  basicActionCreator(DELETE_EVENT_REMINDER_REQUEST, {
    eventId,
    reminderId,
  });

export const DELETE_EVENT_REMINDER_RESPONSE = `${moduleName}/DELETE_EVENT_REMINDER_RESPONSE`;
export const deleteEventReminderResponse = id =>
  basicActionCreator(DELETE_EVENT_REMINDER_RESPONSE, id);

export const DELETE_EVENT_REMINDER_ERROR = `${moduleName}/DELETE_EVENT_REMINDER_ERROR`;
export const deleteEventReminderError = err =>
  basicActionCreator(DELETE_EVENT_REMINDER_ERROR, err);

export const GET_EVENT_REQUIREMENTS_REQUEST = `${moduleName}/GET_EVENT_REQUIREMENTS_REQUEST`;
export const getEventRequirementsRequest = eventId =>
  basicActionCreator(GET_EVENT_REQUIREMENTS_REQUEST, eventId);

export const GET_EVENT_REQUIREMENTS_RESPONSE = `${moduleName}/GET_EVENT_REQUIREMENTS_RESPONSE`;
export const getEventRequirementsResponse = eventId =>
  basicActionCreator(GET_EVENT_REQUIREMENTS_RESPONSE, eventId);

export const GET_EVENT_REQUIREMENTS_ERROR = `${moduleName}/GET_EVENT_REQUIREMENTS_ERROR`;
export const getEventRequirementsError = err =>
  basicActionCreator(GET_EVENT_REQUIREMENTS_ERROR, err);

export const GET_ALL_SUBUNITS_REQUEST = `${moduleName}/GET_ALL_SUBUNITS_REQUEST`;
export const getAllSubunitsRequest = () =>
  basicActionCreator(GET_ALL_SUBUNITS_REQUEST);

export const GET_ALL_SUBUNITS_RESPONSE = `${moduleName}/GET_ALL_SUBUNITS_RESPONSE`;
export const getAllSubunitsResponse = subUnits =>
  basicActionCreator(GET_ALL_SUBUNITS_RESPONSE, subUnits);

export const GET_ALL_SUBUNITS_ERROR = `${moduleName}/GET_ALL_SUBUNITS_ERROR`;
export const getAllSubunitsError = err =>
  basicActionCreator(GET_ALL_SUBUNITS_ERROR, err);

export const GET_UNIT_ROSTER_REQUEST = `${moduleName}/GET_UNIT_ROSTER_REQUEST`;
export const getUnitRosterRequest = data =>
  basicActionCreator(GET_UNIT_ROSTER_REQUEST, data);

export const GET_UNIT_ROSTER_RESPONSE = `${moduleName}/GET_UNIT_ROSTER_RESPONSE`;
export const getUnitRosterResponse = data =>
  basicActionCreator(GET_UNIT_ROSTER_RESPONSE, data);

export const GET_UNIT_ROSTER_ERROR = `${moduleName}/GET_UNIT_ROSTER_ERROR`;
export const getUnitRosterError = err =>
  basicActionCreator(GET_UNIT_ROSTER_ERROR, err);

export const SET_EVENT_UNITS = `${moduleName}/SET_EVENT_UNITS`;
export const setEventUnits = eventUnits =>
  basicActionCreator(SET_EVENT_UNITS, eventUnits);

export const CLEAR_EVENT_DATA = `${moduleName}/CLEAR_EVENT_DATA`;
export const clearEventData = () => basicActionCreator(CLEAR_EVENT_DATA);

export const SELECT_ALL_ATTENDEES_EVENT = `${moduleName}/SELECT_ALL_ATTENDEES_EVENT`;
export const selectAllAttendeesEvent = allAttendees =>
  basicActionCreator(SELECT_ALL_ATTENDEES_EVENT, allAttendees);

export const SELECT_ALL_LEADERS_ATTENDEES_EVENT = `${moduleName}/SELECT_ALL_LEADERS_ATTENDEES_EVENT`;
export const selectAllLeadersAttendeesEvent = allAttendees =>
  basicActionCreator(SELECT_ALL_LEADERS_ATTENDEES_EVENT, allAttendees);

export const SELECT_ALL_PARENTS_ATTENDEES_EVENT = `${moduleName}/SELECT_ALL_PARENTS_ATTENDEES_EVENT`;
export const selectAllParentsAttendeesEvent = allAttendees =>
  basicActionCreator(SELECT_ALL_PARENTS_ATTENDEES_EVENT, allAttendees);

export const SELECT_LEADERS_PARENTS_ATTENDEES_EVENT = `${moduleName}/SELECT_LEADERS_PARENTS_ATTENDEES_EVENT`;
export const selectLeadersParentsAttendeesEvent = allAttendees =>
  basicActionCreator(SELECT_LEADERS_PARENTS_ATTENDEES_EVENT, allAttendees);

// EXTERNAL GUEST
export const GET_EXTERNAL_GUESTS_REQUEST = `${moduleName}/GET_EXTERNAL_GUESTS_REQUEST`;
export const getExternalGuestsRequest = eventId =>
  basicActionCreator(GET_EXTERNAL_GUESTS_REQUEST, eventId);

export const GET_EXTERNAL_GUESTS_RESPONSE = `${moduleName}/GET_EXTERNAL_GUESTS_RESPONSE`;
export const getExternalGuestsResponse = data =>
  basicActionCreator(GET_EXTERNAL_GUESTS_RESPONSE, data);

export const GET_EXTERNAL_GUESTS_ERROR = `${moduleName}/GET_EXTERNAL_GUESTS_ERROR`;
export const getExternalGuestsError = err =>
  basicActionCreator(GET_EXTERNAL_GUESTS_ERROR, err);

// EXTERNAL GUEST MODAL
export const OPEN_ADD_GUEST_MODAL_VISIBLE = `${moduleName}/OPEN_ADD_GUEST_MODAL_VISIBLE`;
export const openAddGuestModalVisible = () =>
  basicActionCreator(OPEN_ADD_GUEST_MODAL_VISIBLE);

export const CLOSE_ADD_GUEST_MODAL_VISIBLE = `${moduleName}/CLOSE_ADD_GUEST_MODAL_VISIBLE`;
export const closeAddGuestModalVisible = () =>
  basicActionCreator(CLOSE_ADD_GUEST_MODAL_VISIBLE);

// EXTERNAL GUEST ADD
export const ADD_GUESTS_REQUEST = `${moduleName}/ADD_GUESTS_REQUEST`;
export const addGuestsRequest = (eventId, data) =>
  basicActionCreator(ADD_GUESTS_REQUEST, {
    eventId,
    data,
  });

export const ADD_GUESTS_RESPONSE = `${moduleName}/ADD_GUESTS_RESPONSE`;
export const addGuestsResponse = data =>
  basicActionCreator(ADD_GUESTS_RESPONSE, data);

export const ADD_GUESTS_ERROR = `${moduleName}/ADD_GUESTS_ERROR`;
export const addGuestsError = err => basicActionCreator(ADD_GUESTS_ERROR, err);

// EXTERNAL GUEST ADD TEMP
export const ADD_GUEST_TEMP_REQUEST = `${moduleName}/ADD_GUEST_TEMP_REQUEST`;
export const addGuestTempRequest = data =>
  basicActionCreator(ADD_GUEST_TEMP_REQUEST, data);

export const ADD_GUEST_TEMP_RESPONSE = `${moduleName}/ADD_GUEST_TEMP_RESPONSE`;
export const addGuestTempResponse = data =>
  basicActionCreator(ADD_GUEST_TEMP_RESPONSE, data);

export const ADD_GUESTS_TEMP_ERROR = `${moduleName}/ADD_GUESTS_TEMP_ERROR`;
export const addGuestTempError = err =>
  basicActionCreator(ADD_GUESTS_TEMP_ERROR, err);

// EXTERNAL GUEST REMOVE TEMP
export const REMOVE_GUEST_TEMP_REQUEST = `${moduleName}/REMOVE_GUEST_TEMP_REQUEST`;
export const removeGuestTempRequest = data =>
  basicActionCreator(REMOVE_GUEST_TEMP_REQUEST, data);

export const REMOVE_GUEST_TEMP_RESPONSE = `${moduleName}/REMOVE_GUEST_TEMP_RESPONSE`;
export const removeGuestTempResponse = data =>
  basicActionCreator(REMOVE_GUEST_TEMP_RESPONSE, data);

export const REMOVE_GUESTS_TEMP_ERROR = `${moduleName}/REMOVE_GUESTS_TEMP_ERROR`;
export const removeGuestTempError = err =>
  basicActionCreator(REMOVE_GUESTS_TEMP_ERROR, err);

// QE RSVP MODAL
export const OPEN_QE_RSVP_MODAL = `${moduleName}/OPEN_QE_RSVP_MODAL`;
export const openQuickEntryRSVPModal = () =>
  basicActionCreator(OPEN_QE_RSVP_MODAL);

export const CLOSE_QE_RSVP_MODAL = `${moduleName}/CLOSE_QE_RSVP_MODAL`;
export const closeQuickEntryRSVPModal = () =>
  basicActionCreator(CLOSE_QE_RSVP_MODAL);

export const CONFIRM_MULTI_INVITE_REQUEST = `${moduleName}/CONFIRM_MULTI_INVITE_REQUEST`;
export const confirmMultiInviteRequest = payload =>
  basicActionCreator(CONFIRM_MULTI_INVITE_REQUEST, payload);

export const CONFIRM_MULTI_INVITE_ERROR = `${moduleName}/CONFIRM_MULTI_INVITE_ERROR`;
export const confirmMultiInviteError = err =>
  basicActionCreator(CONFIRM_MULTI_INVITE_ERROR, err);

export const CONFIRM_MULTI_INVITE_RESPONSE = `${moduleName}/CONFIRM_MULTI_INVITE_RESPONSE`;
export const confirmMultiInviteResponse = payload =>
  basicActionCreator(CONFIRM_MULTI_INVITE_RESPONSE, payload);

export const DELETE_MULTI_INVITEE_REQUEST = `${moduleName}/DELETE_MULTI_INVITEE_REQUEST`;
export const deleteMultiInviteeRequest = payload =>
  basicActionCreator(DELETE_MULTI_INVITEE_REQUEST, payload);

export const DELETE_MULTI_INVITEE_ERROR = `${moduleName}/DELETE_MULTI_INVITEE_ERROR`;
export const deleteMultiInviteeError = err =>
  basicActionCreator(DELETE_MULTI_INVITEE_ERROR, err);

export const DELETE_MULTI_INVITEE_RESPONSE = `${moduleName}/DELETE_MULTI_INVITEE_RESPONSE`;
export const deleteMultiInviteeResponse = payload =>
  basicActionCreator(DELETE_MULTI_INVITEE_RESPONSE, payload);

// ATTENDED SWITCH TOGGLE
export const ATTENDED_TOGGLE_REQUEST = `${moduleName}/ATTENDED_TOGGLE_REQUEST`;
export const attendedToggleRequest = payload =>
  basicActionCreator(ATTENDED_TOGGLE_REQUEST, payload);

export const ATTENDED_TOGGLE_RESPONSE = `${moduleName}/ATTENDED_TOGGLE_RESPONSE`;
export const attendedToggleResponse = data =>
  basicActionCreator(ATTENDED_TOGGLE_RESPONSE, data);

export const ATTENDED_TOGGLE_ERROR = `${moduleName}/ATTENDED_TOGGLE_ERROR`;
export const attendedToggleError = err =>
  basicActionCreator(ATTENDED_TOGGLE_ERROR, err);

// ATTENDED SWITCH TOGGLE GUEST
export const ATTENDED_TOGGLE_GUEST_REQUEST = `${moduleName}/ATTENDED_TOGGLE_GUEST_REQUEST`;
export const attendedToggleGuestRequest = payload =>
  basicActionCreator(ATTENDED_TOGGLE_GUEST_REQUEST, payload);

export const ATTENDED_TOGGLE_GUEST_RESPONSE = `${moduleName}/ATTENDED_TOGGLE_GUEST_RESPONSE`;
export const attendedToggleGuestResponse = data =>
  basicActionCreator(ATTENDED_TOGGLE_GUEST_RESPONSE, data);

export const ATTENDED_TOGGLE_GUEST_ERROR = `${moduleName}/ATTENDED_TOGGLE_GUEST_ERROR`;
export const attendedToggleGuestError = err =>
  basicActionCreator(ATTENDED_TOGGLE_GUEST_ERROR, err);

// EDIT EVENT RSVP
export const UPDATE_PERSON_RSVP_REQUEST = `${moduleName}/UPDATE_PERSON_RSVP_REQUEST`;
export const updatePersonRSVPRequest = payload =>
  basicActionCreator(UPDATE_PERSON_RSVP_REQUEST, payload);

export const UPDATE_PERSON_RSVP_RESPONSE = `${moduleName}/UPDATE_PERSON_RSVP_RESPONSE`;
export const updatePersonRSVPResponse = () =>
  basicActionCreator(UPDATE_PERSON_RSVP_RESPONSE);

export const UPDATE_PERSON_RSVP_ERROR = `${moduleName}/UPDATE_PERSON_RSVP_ERROR`;
export const updatePersonRSVPError = err =>
  basicActionCreator(UPDATE_PERSON_RSVP_ERROR, err);

// EDIT EVENT RSVP GUEST
export const UPDATE_GUEST_RSVP_REQUEST = `${moduleName}/UPDATE_GUEST_RSVP_REQUEST`;
export const updateGuestRSVPRequest = payload =>
  basicActionCreator(UPDATE_GUEST_RSVP_REQUEST, payload);

export const UPDATE_GUEST_RSVP_RESPONSE = `${moduleName}/UPDATE_GUEST_RSVP_RESPONSE`;
export const updateGuestRSVPResponse = () =>
  basicActionCreator(UPDATE_GUEST_RSVP_RESPONSE);

export const UPDATE_GUEST_RSVP_ERROR = `${moduleName}/UPDATE_GUEST_RSVP_ERROR`;
export const updateGuestRSVPError = err =>
  basicActionCreator(UPDATE_GUEST_RSVP_ERROR, err);
// Activity Event

export const SAVE_ACTIVITY_EVENT = `${moduleName}/SAVE_ACTIVITY_EVENT`;
export const SAVE_ACTIVITY_EVENT_RESPONSE = `${moduleName}/SAVE_ACTIVITY_EVENT_RESPONSE`;
export const SAVE_ACTIVITY_EVENT_ERROR = `${moduleName}/SAVE_ACTIVITY_EVENT_ERROR`;
export const saveActivityEvent = ({ activityFormValues, onSuccess }) => ({
  type: SAVE_ACTIVITY_EVENT,
  payload: {
    activityFormValues,
    onSuccess,
  },
});
export const saveActivityEventResponse = () =>
  basicActionCreator(SAVE_ACTIVITY_EVENT_RESPONSE);
export const saveActivityEventError = err => ({
  type: SAVE_ACTIVITY_EVENT_ERROR,
  payload: err,
});

export const GET_ACTIVITIES_EVENT = `${moduleName}/GET_ACTIVITIES_EVENT`;
export const GET_ACTIVITIES_EVENT_RESPONSE = `${moduleName}/GET_ACTIVITIES_EVENT_RESPONSE`;
export const GET_ACTIVITIES_EVENT_ERROR = `${moduleName}/GET_ACTIVITIES_EVENT_ERROR`;
export const getActivitiesEvent = activitiesIds => ({
  type: GET_ACTIVITIES_EVENT,
  payload: activitiesIds,
});
export const getActivitiesEventResponse = data => ({
  type: GET_ACTIVITIES_EVENT_RESPONSE,
  payload: data,
});
export const getActivitiesEventError = err => ({
  type: GET_ACTIVITIES_EVENT_ERROR,
  payload: err,
});

export const DELETE_ACTIVITY_EVENT = `${moduleName}/DELETE_ACTIVITY_EVENT`;
export const DELETE_ACTIVITY_EVENT_RESPONSE = `${moduleName}/DELETE_ACTIVITY_EVENT_RESPONSE`;
export const DELETE_ACTIVITY_EVENT_ERROR = `${moduleName}/DELETE_ACTIVITY_EVENT_ERROR`;
export const deleteActivityEvent = activityId => ({
  type: DELETE_ACTIVITY_EVENT,
  payload: activityId,
});
export const deleteActivityEventResponse = () =>
  basicActionCreator(DELETE_ACTIVITY_EVENT_RESPONSE);
export const deleteActivityEventError = err => ({
  type: DELETE_ACTIVITY_EVENT_ERROR,
  payload: err,
});

export const SET_RSVP_EVENT = `${moduleName}/SET_RSVP_EVENT`;
export const setRSVPEvent = data => basicActionCreator(SET_RSVP_EVENT, data);

// SELECT SUB
export const SELECT_ALL_SUB_ATTENDEES_EVENT = `${moduleName}/SELECT_ALL_SUB_ATTENDEES_EVENT`;
export const selectAllSubAttendeesEvent = allAttendees =>
  basicActionCreator(SELECT_ALL_SUB_ATTENDEES_EVENT, allAttendees);

// DESELECT ALL PERSON IN EVENT
export const DESELECT_ALL_EVENT_PERSON = `${moduleName}/DESELECT_ALL_EVENT_PERSON`;
export const deselectAllEventPerson = allAttendees =>
  basicActionCreator(DESELECT_ALL_EVENT_PERSON, allAttendees);

export const DESELECT_GROUP_PERSON_EVENT = `${moduleName}/DESELECT_GROUP_PERSON_EVENT`;
export const deselectGroupPersonEvent = attendees =>
  basicActionCreator(DESELECT_GROUP_PERSON_EVENT, attendees);

// SELECT SUB
export const SELECT_ATTENDEES_BY_EVENT_TYPE = `${moduleName}/SELECT_ATTENDEES_BY_EVENT_TYPE`;
export const selectAttendeesByEventType = allAttendees =>
  basicActionCreator(SELECT_ATTENDEES_BY_EVENT_TYPE, allAttendees);

export const SET_EVENT_RELOAD = `${moduleName}/SET_EVENT_RELOAD`;
export const setEventReload = status =>
  basicActionCreator(SET_EVENT_RELOAD, status);

export const SET_EVENT_QE_LOADING = `${moduleName}/SET_EVENT_QE_LOADING`;
export const setEventQELoading = status =>
  basicActionCreator(SET_EVENT_QE_LOADING, status);

export const GET_CALENDAR_RECORDS_REQ = `${moduleName}/GET_CALENDAR_RECORDS_REQ`;
export const GET_CALENDAR_RECORDS_RESPONSE = `${moduleName}/GET_CALENDAR_RECORDS_RESPONSE`;
export const GET_CALENDAR_RECORDS_ERROR = `${moduleName}/GET_CALENDAR_RECORDS_ERROR`;
export const getCalendarRecordsRequest = () => ({
  type: GET_CALENDAR_RECORDS_REQ,
});

export const getCalendarRecordsResponse = (records = []) => ({
  type: GET_CALENDAR_RECORDS_RESPONSE,
  payload: records,
});

export const getCalendarRecordsError = err => ({
  type: GET_CALENDAR_RECORDS_ERROR,
  payload: err,
});

export const SAVE_CALENDAR_RECORDS_REQ = `${moduleName}/SAVE_CALENDAR_RECORDS_REQ`;
export const SAVE_CALENDAR_RECORDS_RESPONSE = `${moduleName}/SAVE_CALENDAR_RECORDS_RESPONSE`;
export const SAVE_CALENDAR_RECORDS_ERROR = `${moduleName}/SAVE_CALENDAR_RECORDS_ERROR`;
export const saveCalendarRecordsRequest = (data, onSuccess, onFailure) => ({
  type: SAVE_CALENDAR_RECORDS_REQ,
  payload: { data, onSuccess, onFailure },
});

export const saveCalendarRecordsResponse = records => ({
  type: SAVE_CALENDAR_RECORDS_RESPONSE,
  payload: records,
});

export const saveCalendarRecordsError = err => ({
  type: SAVE_CALENDAR_RECORDS_ERROR,
  payload: err,
});

export const SAVE_PUBLIC_RSVP_REQ = `${moduleName}/SAVE_PUBLIC_RSVP_REQ`;
export const SAVE_PUBLIC_RSVP_RESPONSE = `${moduleName}/SAVE_PUBLIC_RSVP_RESPONSE`;
export const SAVE_PUBLIC_RSVP_ERROR = `${moduleName}/SAVE_PUBLIC_RSVP_ERROR`;
export const savePublicRSVPRequest = (eventId, data, onSuccess, onFailure) => ({
  type: SAVE_PUBLIC_RSVP_REQ,
  payload: { eventId, data, onSuccess, onFailure },
});

export const savePublicRSVPResponse = data => ({
  type: SAVE_PUBLIC_RSVP_RESPONSE,
  payload: data,
});

export const savePublicRSVPError = err => ({
  type: SAVE_PUBLIC_RSVP_ERROR,
  payload: err,
});

export const SEND_PUBLIC_RSVP_REQ = `${moduleName}/SEND_PUBLIC_RSVP_REQ`;
export const SEND_PUBLIC_RSVP_RESPONSE = `${moduleName}/SEND_PUBLIC_RSVP_RESPONSE`;
export const SEND_PUBLIC_RSVP_ERROR = `${moduleName}/SEND_PUBLIC_RSVP_ERROR`;
export const sendPublicRSVPRequest = (eventId, data, onSuccess, onFailure) => ({
  type: SEND_PUBLIC_RSVP_REQ,
  payload: { eventId, data, onSuccess, onFailure },
});

export const sendPublicRSVPResponse = data => ({
  type: SEND_PUBLIC_RSVP_RESPONSE,
  payload: data,
});

export const sendPublicRSVPError = err => ({
  type: SEND_PUBLIC_RSVP_ERROR,
  payload: err,
});

// GET EVENT COMMENTS

export const GET_EVENT_COMMENTS_REQ = `${moduleName}/GET_EVENT_COMMENTS_REQ`;
export const GET_EVENT_COMMENTS_RESPONSE = `${moduleName}/GET_EVENT_COMMENTS_RESPONSE`;
export const GET_EVENT_COMMENTS_ERROR = `${moduleName}/GET_EVENT_COMMENTS_ERROR`;
export const GET_EVENT_COMMENTS_CANCEL = `${moduleName}/GET_EVENT_COMMENTS_CANCEL`;
export const getEventCommentsRequest = (
  eventId,
  pending = false,
  deleted = false,
) => ({
  type: GET_EVENT_COMMENTS_REQ,
  payload: {
    calendarEventId: toNumber(eventId),
    pending,
    deleted,
  },
});

export const getEventCommentsResponse = data => ({
  type: GET_EVENT_COMMENTS_RESPONSE,
  payload: data,
});

export const getEventCommentsError = error => ({
  type: GET_EVENT_COMMENTS_ERROR,
  payload: error,
});

export const getEventCommentsCancel = error => ({
  type: GET_EVENT_COMMENTS_CANCEL,
  payload: error,
});

// POST EVENT COMMENT

export const POST_EVENT_COMMENT_REQ = `${moduleName}/POST_EVENT_COMMENT_REQ`;
export const POST_EVENT_COMMENT_RESPONSE = `${moduleName}/POST_EVENT_COMMENT_RESPONSE`;
export const POST_EVENT_COMMENT_ERROR = `${moduleName}/POST_EVENT_COMMENT_ERROR`;
export const postEventCommentRequest = (eventId, body) => ({
  type: POST_EVENT_COMMENT_REQ,
  payload: {
    calendarEventId: toNumber(eventId),
    body,
  },
});

export const postEventCommentResponse = () => ({
  type: POST_EVENT_COMMENT_RESPONSE,
});

export const postEventCommentError = error => ({
  type: POST_EVENT_COMMENT_ERROR,
  payload: error,
});

// POST EVENT COMMENT WITH FILES

export const POST_EVENT_COMMENT_WITH_FILES_REQ = `${moduleName}/POST_EVENT_COMMENT_WITH_FILES_REQ`;
export const POST_EVENT_COMMENT_WITH_FILES_RESPONSE = `${moduleName}/POST_EVENT_COMMENT_WITH_FILES_RESPONSE`;
export const POST_EVENT_COMMENT_WITH_FILES_ERROR = `${moduleName}/POST_EVENT_COMMENT_WITH_FILES_ERROR`;
export const postEventCommentWithFilesRequest = (
  eventId,
  eventType,
  message,
  photo,
) => ({
  type: POST_EVENT_COMMENT_WITH_FILES_REQ,
  payload: {
    calendarEventId: toNumber(eventId),
    calendarEventType: eventType,
    body: message,
    photo,
  },
});

export const postEventCommentWithFilesResponse = () => ({
  type: POST_EVENT_COMMENT_WITH_FILES_RESPONSE,
});

export const postEventCommentWithFilesError = error => ({
  type: POST_EVENT_COMMENT_WITH_FILES_ERROR,
  payload: error,
});

// PUT EVENT COMMENT

export const PUT_EVENT_COMMENT_REQ = `${moduleName}/PUT_EVENT_COMMENT_REQ`;
export const PUT_EVENT_COMMENT_RESPONSE = `${moduleName}/PUT_EVENT_COMMENT_RESPONSE`;
export const PUT_EVENT_COMMENT_ERROR = `${moduleName}/PUT_EVENT_COMMENT_ERROR`;
export const putEventCommentRequest = (eventId, commentId, comments) => ({
  type: PUT_EVENT_COMMENT_REQ,
  payload: {
    commentId,
    comments,
    eventId,
  },
});

export const putEventCommentResponse = () => ({
  type: PUT_EVENT_COMMENT_RESPONSE,
});

export const putEventCommentError = error => ({
  type: PUT_EVENT_COMMENT_ERROR,
  payload: error,
});

// DELETE EVENT COMMENT

export const DELETE_EVENT_COMMENT_REQ = `${moduleName}/DELETE_EVENT_COMMENT_REQ`;
export const DELETE_EVENT_COMMENT_RESPONSE = `${moduleName}/DELETE_EVENT_COMMENT_RESPONSE`;
export const DELETE_EVENT_COMMENT_ERROR = `${moduleName}/DELETE_EVENT_COMMENT_ERROR`;
export const deleteEventCommentRequest = commentId => ({
  type: DELETE_EVENT_COMMENT_REQ,
  payload: { commentId },
});

export const deleteEventCommentResponse = commentId => ({
  type: DELETE_EVENT_COMMENT_RESPONSE,
  payload: { commentId },
});

export const deleteEventCommentError = error => ({
  type: DELETE_EVENT_COMMENT_ERROR,
  payload: error,
});

// DELETE EVENT COMMENT PHOTO

export const DELETE_EVENT_COMMENT_PHOTO_REQ = `${moduleName}/DELETE_EVENT_COMMENT_PHOTO_REQ`;
export const DELETE_EVENT_COMMENT_PHOTO_RESPONSE = `${moduleName}/DELETE_EVENT_COMMENT_PHOTO_RESPONSE`;
export const DELETE_EVENT_COMMENT_PHOTO_ERROR = `${moduleName}/DELETE_EVENT_COMMENT_PHOTO_ERROR`;
export const deleteEventCommentPhotoRequest = (
  eventId,
  commentId,
  photoId,
) => ({
  type: DELETE_EVENT_COMMENT_PHOTO_REQ,
  payload: {
    commentId,
    eventId,
    photoId,
  },
});

export const deleteEventCommentPhotoResponse = (commentId, photoId) => ({
  type: DELETE_EVENT_COMMENT_PHOTO_RESPONSE,
  payload: {
    commentId,
    photoId,
  },
});

export const deleteEventCommentPhotoError = error => ({
  type: DELETE_EVENT_COMMENT_PHOTO_ERROR,
  payload: error,
});
