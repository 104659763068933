import React, { useEffect } from 'react';

import { FormProps } from 'antd/lib/form';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';

import { UnitStatus } from '@appTypes/enums';
import { useLazyGetCouncilUnitsQuery } from '@modules/councilUnits/api';
import { transformUnitsResponse } from '@modules/councilUnits/duck/services';
import {
  Button,
  Card,
  CouncilDistrictBadge,
  DatePicker,
  Form,
  Input,
  ProgramId,
  Select,
  UnitType,
  dtoDateFormat,
  intl,
  parentOrgGuidSel,
} from '@shared';
import { toastService } from '@toasts';
import { validateForm } from '@utils';

import {
  districtsLoadingSel,
  districtsSel,
  loadDistrictsRequest,
  searchLoadingSel,
  unitsSearchResponse,
} from '../../../duck';
import styles from './UnitSearch.less';

const FormItem = Form.Item;
const { Option } = Select;

const validUnitTypes = [
  ProgramId.CUB_SCOUT,
  ProgramId.BOY_SCOUT,
  ProgramId.VENTURING,
  ProgramId.SEA_SCOUT,
  ProgramId.EXPLORING,
  ProgramId.CLUB,
];
const MIN_REQUIRED_VALUES = 3;

const unitTypeOptions = validUnitTypes.map(programId => (
  <Option key={programId} value={programId}>
    <UnitType programId={programId} />
  </Option>
));

const removeEmpty = (obj: Record<string, unknown>) =>
  Object.keys(obj)
    .filter(key => !!obj[key])
    .reduce(
      (newObj, key) => ({
        ...newObj,
        [key]: obj[key],
      }),
      {},
    );

interface District {
  organizationFullName: string;
  organizationGuid: string;
  organizationTypeId: number;
  councilStructureTypeId: number;
}

interface UnitSearchProps {
  //Provided by component itself
  form: NonNullable<FormProps['form']>;
  districtsLoading: boolean;
  districts: District[];
  searchLoading: boolean;
  onSearch: (filter: unknown) => void;
}

const UnitSearch: React.FC<UnitSearchProps> = props => {
  const { form, districtsLoading, districts, searchLoading, onSearch } = props;
  const { getFieldDecorator } = form;

  const handleSearch = async () => {
    const filter = await validateForm(form);
    const validValues = removeEmpty(filter);
    if (Object.keys(validValues).length >= MIN_REQUIRED_VALUES) {
      onSearch(validValues);
    } else {
      toastService.error(
        <FormattedMessage id="councilUnits.CouncilUnitsPage.emptyFormError" />,
      );
    }
  };

  const districtOptions = districts.map(
    ({
      organizationFullName,
      organizationGuid,
      organizationTypeId,
      councilStructureTypeId,
    }) => (
      <Option
        key={organizationGuid}
        value={organizationGuid}
        // @ts-expect-error Option component needs to update typedef to include text
        text={organizationFullName}
      >
        {organizationFullName}{' '}
        <CouncilDistrictBadge
          organizationTypeId={organizationTypeId}
          councilStructureTypeId={councilStructureTypeId}
        />
      </Option>
    ),
  );

  const unitStatusOptions = [
    UnitStatus.Active,
    UnitStatus.Lapsed,
    UnitStatus.Unposted,
    UnitStatus.All,
  ].map(status => (
    <Option key={status} value={status}>
      {_.startCase(status)}
    </Option>
  ));

  const hasOnlyOneDistrict = districts.length === 1;

  return (
    <Card
      title={<FormattedMessage id="councilUnits.CouncilUnitsPage.unitSearch" />}
      margin
      shadow
    >
      <div className={styles.row}>
        <FormItem
          className={styles.formItem}
          label={
            <FormattedMessage id="councilUnits.CouncilUnitsPage.unitType" />
          }
          colon={false}
        >
          {getFieldDecorator('unitTypeId')(
            <Select
              size="large"
              allowClear
              placeholder={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.unitType.placeholder',
              })}
            >
              {unitTypeOptions}
            </Select>,
          )}
        </FormItem>

        <FormItem
          className={styles.formItem}
          label={
            <FormattedMessage id="councilUnits.CouncilUnitsPage.unitNumber" />
          }
          colon={false}
        >
          {getFieldDecorator('unitNumber', {
            rules: [
              {
                pattern: /^([0-9]{4})$/,
                message: intl.formatMessage({
                  id: 'councilUnits.CouncilUnitsPage.unitNumber.formatError',
                }),
              },
            ],
          })(
            <Input
              size="large"
              maxLength={4}
              placeholder={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.unitNumber.placeholder',
              })}
            />,
          )}
        </FormItem>
        <FormItem
          className={styles.formItem}
          label={
            <FormattedMessage id="councilUnits.CouncilUnitsPage.district" />
          }
          colon={false}
        >
          {getFieldDecorator('districtOrgGuid', {
            initialValue: hasOnlyOneDistrict
              ? districts[0].organizationGuid
              : undefined,
          })(
            <Select
              size="large"
              showSearch
              allowClear
              placeholder={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.district.placeholder',
              })}
              showArrow={!hasOnlyOneDistrict}
              loading={districtsLoading}
              optionFilterProp="text"
            >
              {districtOptions}
            </Select>,
          )}
        </FormItem>
      </div>
      <div className={styles.row}>
        <FormItem
          className={styles.formItem}
          label={
            <FormattedMessage id="councilUnits.CouncilUnitsPage.expirationDate" />
          }
          colon={false}
        >
          {getFieldDecorator('expirationDate')(
            <DatePicker
              size="large"
              label={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.expirationDate',
              })}
            />,
          )}
        </FormItem>
        <FormItem
          className={styles.formItem}
          label={
            <FormattedMessage id="councilUnits.CouncilUnitsPage.unitStatus" />
          }
          colon={true}
        >
          {getFieldDecorator('unitStatus', {
            initialValue: [UnitStatus.Active],
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'councilUnits.CouncilUnitsPage.unitStatus.required',
                }),
              },
            ],
          })(
            <Select
              mode="multiple"
              size="large"
              placeholder={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.unitStatus',
              })}
              showArrow={!hasOnlyOneDistrict}
              loading={districtsLoading}
            >
              {unitStatusOptions}
            </Select>,
          )}
        </FormItem>
        <FormItem
          className={cn(styles.formItem, styles.searchItem)}
          label={' '}
          colon={false}
        >
          <Button type="primary" loading={searchLoading} onClick={handleSearch}>
            <FormattedMessage id="councilUnits.CouncilUnitsPage.search" />
          </Button>
        </FormItem>
      </div>
    </Card>
  );
};

interface UnitSearchForm {
  unitStatus: string[];
  unitTypeId: number;
  districtOrgGuid: string;
  expirationDate: string;
}

const UnitSearchWithForm = compose(Form.create())(
  UnitSearch,
) as unknown as React.FC<Omit<UnitSearchProps, 'form'>>;

export default function UnitSearchContainer() {
  const dispatch = useDispatch();
  const organizationGuid = useSelector(parentOrgGuidSel);

  const [request] = useLazyGetCouncilUnitsQuery();

  const onSearch = async (payload: UnitSearchForm) => {
    const statuses = payload.unitStatus;

    const responses = statuses.map(async status => {
      const requestPayload = {
        ..._.omit(payload, ['expirationDate', 'districtOrgGuid', 'unitStatus']),
        organizationGuid: payload.districtOrgGuid || organizationGuid,
        expireDate: payload.expirationDate
          ? moment(payload.expirationDate).format(dtoDateFormat)
          : undefined,
      };
      const data = await request(
        {
          payload: requestPayload,
          params: {
            unitStatus: status as UnitStatus,
          },
        },
        true, // caches it
      ).unwrap();

      return transformUnitsResponse({
        units: data,
        unitTypeId: payload.unitTypeId,
      });
    });

    const finalData = await Promise.all(responses);

    const cleanData = _.chain(finalData)
      .flatten()
      .uniqBy('organizationGuid')
      .value();

    dispatch(unitsSearchResponse(cleanData));
  };

  const districtsLoading = useSelector(districtsLoadingSel);
  const districts = useSelector(districtsSel);
  const searchLoading = useSelector(searchLoadingSel);

  useEffect(() => {
    dispatch(loadDistrictsRequest());
  }, [dispatch]);

  return (
    <UnitSearchWithForm
      onSearch={onSearch}
      districts={districts}
      searchLoading={searchLoading}
      districtsLoading={districtsLoading}
    />
  );
}
