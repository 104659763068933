import React from 'react';

import PropTypes from 'prop-types';

import { Table } from '@shared';

import columns from './advancementReportTableColumns';

class AdvancementReportTable extends React.PureComponent {
  render() {
    const { items, showPrice, loading } = this.props;

    return (
      <Table
        size="small"
        headerColor="gray-1"
        rowKey="key"
        columns={columns(showPrice)}
        dataSource={items}
        loading={loading}
        pagination={false}
        border={false}
      />
    );
  }
}

AdvancementReportTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  showPrice: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
};

export default AdvancementReportTable;
