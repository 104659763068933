import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DEV_WARNINGS } from '@config';
import AddDenChiefModal from '@modules/advancement/denChiefSearch/components/AddDenChiefModal';
import { isVisibleAddDenChiefModalSel } from '@modules/advancement/denChiefSearch/duck/selectors';
import { isMobileSel } from '@screen';
import {
  SearchInput,
  goToAdultProfile,
  goToUnregisteredYouthProfile,
  goToYouthProfile,
  intl,
  offlineSel,
  sorterPropTypes,
} from '@shared';

import { AddSubUnitModal } from '../../../subUnits';
import {
  approvedSubUnitsSel,
  subUnitTypeSel,
} from '../../../subUnits/duck/selectors';
import {
  filterSel,
  filteredItemsSel,
  isSubUnitAllowedSel,
  itemsPerMemberTypeSel,
  itemsPerSubUnitSel,
  itemsSel,
  loadingSel,
  packRosterFilter,
  packRosterRequestIfNotLoaded,
  packRosterSearch,
  packRosterSort,
  searchSel,
  selectedKeysSel,
  setSelectedKeys,
  showSubUnitsSel,
  sorterSel,
  unitRosterReportLoadingSel,
} from '../../duck';
import { hasToastLoaded, loadNullUserIdsWarning } from '../../nullUserIdsWarn';
import AddToSubUnitModal from '../AddToSubUnitModal';
import PackRosterList from './PackRosterList';
import PackRosterTable from './PackRosterTable';

class PackRoster extends React.PureComponent {
  state = {
    searchPinned: false,
  };

  componentDidMount() {
    const { items } = this.props;
    this.props.onFetchRoster();

    if (DEV_WARNINGS && !hasToastLoaded && items.length !== 0) {
      loadNullUserIdsWarning(items);
    }
  }

  componentDidUpdate() {
    const { items } = this.props;
    if (DEV_WARNINGS && !hasToastLoaded && items.length !== 0) {
      loadNullUserIdsWarning(items);
    }
  }

  handleSearch = search => {
    this.props.onSetSearch(search);
  };

  handleSorterChange = sorter => {
    this.props.onSetSorter(sorter);
  };

  handleSelectedRowKeysChange = selectedRowKeys => {
    this.props.onSetSelectedKeys(selectedRowKeys);
  };

  handleSearchPinChange = searchPinned => this.setState({ searchPinned });

  handleDeselectPerson = userId => {
    const { selectedKeys } = this.props;
    const newKeys = selectedKeys.filter(key => key !== userId);

    this.handleSelectedRowKeysChange(newKeys);
  };

  handleGoToYouthProfile = youth => event => {
    const { userId, isAdult, memberId } = youth;
    if (!isAdult && !this.props.isOffline) {
      event.stopPropagation();
      memberId
        ? this.props.onGoToYouthProfile(userId)
        : this.props.onGoToUnregisteredYouthProfile(userId);
    }
  };

  handleGoToAdultProfile =
    ({ userId, isAdult }) =>
    event => {
      if (isAdult && !this.props.isOffline) {
        event.stopPropagation();
        this.props.onGoToAdultProfile(userId);
      }
    };

  renderSearch() {
    const { search } = this.props;

    return (
      <SearchInput
        key="search"
        id="qa_inputSearchRoster"
        size="large"
        mobileSticky
        placeholder={intl.formatMessage({
          id: 'advancement.PackRoster.search',
        })}
        value={search}
        onChange={this.handleSearch}
        onPinChange={this.handleSearchPinChange}
      />
    );
  }

  renderTable() {
    const {
      loading,
      unitRosterReportLoading,
      isMobile,
      filteredItems,
      items,
      selectedKeys,
      filter,
      sorter,
      isOffline,
      itemsPerSubUnit,
      itemsPerMemberType,
      subUnitViewActive,
      subUnitType,
      isSubUnitAllowed,
      approvedSubUnits,
    } = this.props;
    const { searchPinned } = this.state;
    const rosterOrReportLoading = loading || unitRosterReportLoading;

    return isMobile ? (
      <PackRosterList
        key="list"
        items={filteredItems}
        itemsPerSubUnit={itemsPerSubUnit}
        selectedItems={selectedKeys}
        rosterReady={!rosterOrReportLoading && items.length > 0}
        loading={rosterOrReportLoading}
        selectedRowKeys={selectedKeys}
        searchPinned={searchPinned}
        sorter={sorter}
        subUnitViewActive={subUnitViewActive}
        filter={filter}
        isOffline={isOffline}
        onSelectedRowKeysChange={this.handleSelectedRowKeysChange}
        onGoToYouthProfile={this.handleGoToYouthProfile}
        onGoToAdultProfile={this.handleGoToAdultProfile}
        subUnitType={subUnitType}
        isSubUnitAllowed={isSubUnitAllowed}
        approvedSubUnits={approvedSubUnits}
      />
    ) : (
      <PackRosterTable
        key="table"
        items={filteredItems}
        itemsPerSubUnit={itemsPerSubUnit}
        itemsPerMemberType={itemsPerMemberType}
        subUnitViewActive={subUnitViewActive}
        selectedItems={selectedKeys}
        selectedRowKeys={selectedKeys}
        loading={rosterOrReportLoading}
        sorter={sorter}
        isOffline={isOffline}
        filter={filter}
        onSorterChange={this.handleSorterChange}
        onSelectedRowKeysChange={this.handleSelectedRowKeysChange}
        onGoToYouthProfile={this.handleGoToYouthProfile}
        onGoToAdultProfile={this.handleGoToAdultProfile}
        subUnitType={subUnitType}
        isSubUnitAllowed={isSubUnitAllowed}
        approvedSubUnits={approvedSubUnits}
      />
    );
  }

  render() {
    const { isVisibleAddDenChiefModal } = this.props;
    return (
      <React.Fragment>
        <AddSubUnitModal />
        <AddToSubUnitModal />
        {isVisibleAddDenChiefModal && <AddDenChiefModal />}
        {this.renderSearch()}
        {this.renderTable()}
      </React.Fragment>
    );
  }
}

PackRoster.propTypes = {
  items: PropTypes.array.isRequired,
  filteredItems: PropTypes.array.isRequired,
  itemsPerSubUnit: PropTypes.array.isRequired,
  itemsPerMemberType: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  unitRosterReportLoading: PropTypes.bool.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  sorter: sorterPropTypes,
  isOffline: PropTypes.bool.isRequired,
  subUnitViewActive: PropTypes.bool.isRequired,
  onFetchRoster: PropTypes.func.isRequired,
  onSetSelectedKeys: PropTypes.func.isRequired,
  onSelectFilter: PropTypes.func.isRequired,
  onSetSearch: PropTypes.func.isRequired,
  onSetSorter: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  onGoToUnregisteredYouthProfile: PropTypes.func.isRequired,
  onGoToAdultProfile: PropTypes.func.isRequired,
  subUnitType: PropTypes.string,
  isSubUnitAllowed: PropTypes.bool.isRequired,
  approvedSubUnits: PropTypes.array,
  isVisibleAddDenChiefModal: PropTypes.bool.isRequired,
};

const mapState = state => ({
  items: itemsSel(state),
  filteredItems: filteredItemsSel(state),
  itemsPerSubUnit: itemsPerSubUnitSel(state),
  itemsPerMemberType: itemsPerMemberTypeSel(state),
  loading: loadingSel(state),
  unitRosterReportLoading: unitRosterReportLoadingSel(state),
  selectedKeys: selectedKeysSel(state),
  isMobile: isMobileSel(state),
  filter: filterSel(state),
  search: searchSel(state),
  sorter: sorterSel(state),
  isOffline: offlineSel(state),
  subUnitViewActive: showSubUnitsSel(state),
  subUnitType: subUnitTypeSel(state),
  isSubUnitAllowed: isSubUnitAllowedSel(state),
  approvedSubUnits: approvedSubUnitsSel(state),
  isVisibleAddDenChiefModal: isVisibleAddDenChiefModalSel(state),
});

const mapDispatch = dispatch => ({
  onFetchRoster: () => dispatch(packRosterRequestIfNotLoaded()),
  onSetSelectedKeys: keys => dispatch(setSelectedKeys(keys)),
  onSelectFilter: filter => dispatch(packRosterFilter(filter)),
  onSetSearch: search => dispatch(packRosterSearch(search)),
  onSetSorter: sorter => dispatch(packRosterSort(sorter)),
  onGoToYouthProfile: userId => dispatch(goToYouthProfile(userId)),
  onGoToUnregisteredYouthProfile: userId =>
    dispatch(goToUnregisteredYouthProfile(userId)),
  onGoToAdultProfile: userId => dispatch(goToAdultProfile(userId)),
});

export default connect(mapState, mapDispatch)(PackRoster);
