import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';

import { Input, intl } from '@shared';
import { maxProfiles, maxProfilesMobile } from '../../../constants';

function RolePickerSearch({ rolesNumber, styles, isMobile, search, onSearch }) {
  const currentMaxProfiles = isMobile ? maxProfilesMobile : maxProfiles;

  if (rolesNumber <= currentMaxProfiles) {
    return <span />;
  }

  return (
    <div
      className={cn(styles.item, styles.searchItem)}
      onClick={e => e.stopPropagation()}
    >
      <Input
        size="small"
        addonAfter={<SearchIcon className={styles.searchIcon} />}
        value={search}
        onChange={onSearch}
        placeholder={intl.formatMessage({
          id: 'context.RolePicker.search',
        })}
      />
    </div>
  );
}

RolePickerSearch.propTypes = {
  rolesNumber: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default RolePickerSearch;
