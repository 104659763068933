import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';

const getLongDate = dateValue => {
  if (dateValue) {
    return moment(dateValue);
  }
  return false;
};

const useCalculateDays = ({ startDate, endDate }, form) => {
  const [diff, setDiff] = useState(null);
  const { getFieldValue } = form;

  const startDateValue = startDate;

  const start = useMemo(() => getLongDate(startDateValue), [startDateValue]);

  const endDateValue = endDate;

  const end = useMemo(() => getLongDate(endDateValue), [endDateValue]);

  useEffect(() => {
    if (start && end) {
      const nextDiff = end.startOf('day').diff(start.startOf('day'), 'days');
      if (diff !== nextDiff) {
        setDiff(nextDiff);
      }
    }
  }, [start, end, getFieldValue, diff]);

  return diff;
};

export default useCalculateDays;
