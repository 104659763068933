import React from 'react';

import cn from 'classnames';
import partition from 'lodash/partition';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import stable from 'stable';
import { v4 as uuid } from 'uuid';

import { Card, ProgramName, S, permissions, permissionsSel } from '@shared';

import { maxCouncilUnitsSuggestions } from '../../../constants';
import styles from './RolePickerDropdown.less';
import {
  RolePickerCouncilRole,
  RolePickerParentGuardianRole,
  RolePickerUnitRole,
  RolePickerUnitSuggestion,
} from './RolePickerRole';
import RolePickerSearch from './RolePickerSearch';

const getPartitionedRoles = (roles, currentOrganizationGuid) => {
  const [councilRoles, notCouncilRoles] = partition(
    roles,
    ({ isCouncil }) => isCouncil,
  );
  const [activeUnitRole, otherRoles] = partition(
    notCouncilRoles,
    ({ organizationGuid }) => organizationGuid === currentOrganizationGuid,
  );
  const [parentGuardianRoles, unitRoles] = partition(
    otherRoles,
    ({ isParentGuardian }) => isParentGuardian,
  );

  return {
    councilRoles,
    activeUnitRole,
    parentGuardianRoles,
    otherRoles: unitRoles,
  };
};

class RolePickerDropdown extends React.PureComponent {
  getRecentActiveUnitRoleNodes = recentActiveItems => {
    const { onUnitChange, onViewMoreRecentCouncilUnits, userPermissions } =
      this.props;
    if (
      recentActiveItems.length > 0 &&
      userPermissions[permissions.VIEW_COUNCIL_UNITS_PAGE]
    ) {
      const recentActiveItemsNodes = recentActiveItems
        .slice(0, maxCouncilUnitsSuggestions)
        .map(
          this.renderRolePickerMenuItem(RolePickerUnitSuggestion, onUnitChange),
        );

      return (
        <React.Fragment key="recent-units-roles">
          <S
            size="4"
            className={cn(styles.categoryLabel, styles.suggestionHeader)}
          >
            <FormattedMessage id="context.RolePicker.recentUnits" />
          </S>
          {recentActiveItemsNodes}
          {recentActiveItems.length > maxCouncilUnitsSuggestions && (
            <div
              className={cn(
                styles.item,
                styles.suggestionMenu,
                styles.showMore,
              )}
              onClick={onViewMoreRecentCouncilUnits}
            >
              <S size="4">
                <FormattedMessage id="shared.more" />
              </S>
            </div>
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  renderRolePickerMenuItem =
    (RolePickerRole, onProfileChange = this.props.onProfileChange) =>
    // eslint-disable-next-line react/display-name
    org => {
      const { parentOrgGuid, childOrganization, isOffline } = this.props;

      const active = parentOrgGuid === org.organizationGuid;
      const key = uuid();
      return (
        <RolePickerRole
          key={key}
          unitRole={active ? childOrganization : null}
          active={active}
          disabled={!active && isOffline}
          org={org}
          onProfileChange={onProfileChange}
        />
      );
    };

  renderUnitRoles = ({
    councilRoles,
    activeUnitRole,
    otherRoles,
    parentGuardianRoles,
  }) => {
    const { search } = this.props;

    const filteredRoles = stable(
      otherRoles.filter(
        ({ program, organizationName = '' }) =>
          (ProgramName.getTranslation(program) || '')
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          organizationName.toLowerCase().includes(search.toLowerCase()),
      ),
      ({ unitTypeId: aUnitTypeId }, { unitTypeId: bUnitTypeId }) =>
        aUnitTypeId > bUnitTypeId,
    );

    const councilRolesNodes = councilRoles.map(
      this.renderRolePickerMenuItem(RolePickerCouncilRole),
    );
    const activeUnitRoleNode = activeUnitRole.map(role => {
      const rolePicker = role.isParentGuardian
        ? RolePickerParentGuardianRole
        : RolePickerUnitRole;
      return this.renderRolePickerMenuItem(rolePicker)(role);
    });
    const unitRolesNodes = filteredRoles.map(
      this.renderRolePickerMenuItem(RolePickerUnitRole),
    );

    const parentGuardianRoleNodes = parentGuardianRoles.map(
      this.renderRolePickerMenuItem(RolePickerParentGuardianRole),
    );

    return [
      ...councilRolesNodes,
      ...activeUnitRoleNode,
      ...unitRolesNodes,
      ...parentGuardianRoleNodes,
    ];
  };

  render() {
    const {
      open,
      roles,
      isMobile,
      search,
      onSearch,
      onLogout,
      parentOrgGuid,
      recentActiveUnitRoles,
      allRolesLength,
    } = this.props;

    const unitRolesNodes = this.renderUnitRoles(
      getPartitionedRoles(roles, parentOrgGuid),
    );

    const recentActiveNode = this.getRecentActiveUnitRoleNodes(
      recentActiveUnitRoles,
    );

    const rolesNodes = [...unitRolesNodes, recentActiveNode];

    const classNames = cn('Joyride__RolePickerDropdown', styles.dropdownCard, {
      [styles.open]: open,
      'Joyride__RolePickerDropdown--open': open,
    });

    return ReactDOM.createPortal(
      <Card noPadding shadow className={classNames}>
        <div>
          <RolePickerSearch
            rolesNumber={allRolesLength}
            styles={styles}
            isMobile={isMobile}
            search={search}
            onSearch={onSearch}
          />
          <ul className={styles.roles}>{rolesNodes}</ul>
          {isMobile && (
            <div
              id="qa_logout"
              className={cn(styles.item, styles.logout)}
              onClick={onLogout}
            >
              <FormattedMessage id="context.RolePicker.logOut" />
            </div>
          )}
        </div>
      </Card>,
      document.querySelector('body'),
    );
  }
}

RolePickerDropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  currentOrganizationGuid: PropTypes.string.isRequired,
  parentOrgGuid: PropTypes.string.isRequired,
  roles: PropTypes.array,
  recentActiveUnitRoles: PropTypes.array.isRequired,
  childOrganization: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onProfileChange: PropTypes.func.isRequired,
  onUnitChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onViewMoreRecentCouncilUnits: PropTypes.func.isRequired,
  userPermissions: PropTypes.object,
  allRolesLength: PropTypes.number.isRequired,
};

RolePickerDropdown.defaultProps = {
  search: '',
};

const mapState = state => ({
  userPermissions: permissionsSel(state),
});

export default connect(mapState)(RolePickerDropdown);
