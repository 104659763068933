import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, forkJoin, from, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { SET_ORGANIZATION, organizationGuidSel } from '@context';
import { eventsAPI } from '@modules/events/api';
import {
  CONFIRM_INVITE_RESPONSE,
  DELETE_EVENT_REMINDER_RESPONSE,
  GET_ALL_SUBUNITS_RESPONSE,
  changeEventInviteeRsvp,
} from '@modules/events/duck/actions';
import { allUnitsSubunitsSel } from '@modules/events/duck/selectors';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { unitInfoSel } from '@modules/unit';
import {
  DELETE_ACTIVITIY_RESPONSE,
  RECORD_CAMPOUTS_RESPONSE,
  RECORD_HIKE_RESPONSE,
  RECORD_LONG_CRUISE_RESPONSE,
  RECORD_SERVICE_RESPONSE,
} from '@progress/duck/actions';
import progressServices from '@progress/duck/services';
import {
  APP_BOOTSTRAP_DONE,
  goToCalendarPage,
  organizationPositionsSel,
} from '@shared';
import { userIdSel as loggedInUserIdSel } from '@user';
import { catchAndReport } from '@utils/rxjs/operators';

import { transformFetchEvents } from '../utilsTyped';
import {
  GET_EVENTS_REQUEST,
  GET_PERSONAL_ACTIVITIES_REQUEST,
  getEventsError,
  getEventsRequest,
  getEventsResponse,
  getPersonalActivitiesError,
  getPersonalActivitiesResponse,
  updateEvents,
} from './actions';
import {
  eventsSel,
  isActivityEventRouteSel,
  isCalendarPageRouteSel,
  isCalendarRouteSel,
  isEventRouteSel,
  selectedDateSel,
} from './selectors';
import { calendarRequestsSel } from './selectorsTyped';
import * as services from './services';

const redirectToCalendarEpic$ = (action$, state$) =>
  action$
    .skipUntil(action$.ofType(APP_BOOTSTRAP_DONE))
    .ofType(SET_ORGANIZATION)
    .filter(() => isCalendarPageRouteSel(state$.value))
    .mapTo(goToCalendarPage());

const getPersonalActivitiesEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_PERSONAL_ACTIVITIES_REQUEST),
    switchMap(({ payload: selectedDate }) => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      return progressServices
        .getActivities$({
          startDateTime: moment(selectedDate)
            .startOf('month')
            .format('Y-MM-DD'),
          endDateTime: moment(selectedDate)
            .endOf('month')
            .add(1, 'months')
            .format('Y-MM-DD'),
          organizationGuid,
          showPersonalActivities: true,
        })
        .pipe(
          map(activities => {
            const processedActivities = activities.map(activity => {
              const startDateTime = moment(activity.startDateTime);
              const endDateTime = moment(activity.endDateTime);
              const startTime = startDateTime.format('HH:mm');
              const endTime = endDateTime.format('HH:mm');
              const isAllDay = startTime === '00:00' && endTime === '23:59';
              return {
                title: activity.name,
                start: startDateTime.toDate(),
                end: endDateTime.toDate(),
                allDay: isAllDay,
                resource: {
                  ...activity,
                  activityId: activity.id,
                  isEvent: !activity.activityTypeId,
                  startDate: activity.startDateTime,
                  endDate: activity.endDateTime,
                },
              };
            });
            return getPersonalActivitiesResponse(processedActivities);
          }),
          catchAndReport(err => of(getPersonalActivitiesError(err))),
        );
    }),
  );

const getCalendarEventsV2$ = (action$, state$, { store }) =>
  action$.pipe(
    ofType(GET_EVENTS_REQUEST),
    switchMap(({ payload: getEventData }) => {
      const state = state$.value;
      const { selectedDate } = getEventData;
      const orgs = calendarRequestsSel(state, { selectedDate });
      const { unitTimeZoneCode: timezoneCode } = unitInfoSel(state);
      const loggedInUserId = loggedInUserIdSel(state);

      if (orgs.length === 0) {
        return Observable.of(getEventsResponse());
      }

      return forkJoin(
        orgs.map(payload => {
          const thunk = eventsAPI.endpoints.getCalendarEvents.initiate({
            payload,
          });

          const dispatch = store.dispatch;
          return from(
            dispatch(thunk)?.then(result => {
              const events = result.data;
              return transformFetchEvents(events, {
                timezoneCode,
                loggedInUserId,
              });
            }),
          );
        }),
      ).pipe(
        map(events => getEventsResponse(uniqBy(events.flat(), 'id'))),
        catchAndReport(err => of(getEventsError(err))),
      );
    }),
  );

const getCalendarEventsV1$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_EVENTS_REQUEST),
    switchMap(({ payload: getEventData }) => {
      const { selectedDate } = getEventData;
      const state = state$.value;
      const loggedInUserId = loggedInUserIdSel(state);
      const organizationPositions = organizationPositionsSel(state);
      const { unitTimeZoneCode: timezoneCode } = unitInfoSel(state);
      const subUnits = allUnitsSubunitsSel(state);

      const validOrg = organizationPositions.filter(org => org.unitId);

      if (!validOrg.length) {
        return Observable.of(getEventsResponse());
      }

      return forkJoin(
        validOrg.map(org => {
          const { unitId } = org;
          const subUnitInfo = subUnits.find(subunit => subunit.id === +unitId);
          const showDLEEvents = !!subUnitInfo?.showDlEvents;

          return services
            .fetchCalendarEvents$({
              unitId: Number(unitId),
              fromDate: moment(selectedDate)
                .startOf('month')
                .subtract(1, 'week')
                .format('Y-MM-DD'),
              toDate: moment(selectedDate)
                .endOf('month')
                .add(1, 'week')
                .format('Y-MM-DD'),
              withActivities: false,
              showDLEEvents,
            })
            .pipe(
              map(events =>
                transformFetchEvents(events, { loggedInUserId, timezoneCode }),
              ),
            );
        }),
      ).pipe(
        map(events => getEventsResponse(uniqBy(events.flat(), 'id'))),
        catchAndReport(err => of(getEventsError(err))),
      );
    }),
  );

const getCalendarEvents$ = (action$, state$, deps) => {
  if (featureFlags.getFlag('SBL_5138_OPTIMIZE_CALENDAR_EVENTS')) {
    return getCalendarEventsV2$(action$, state$, deps);
  } else {
    return getCalendarEventsV1$(action$, state$);
  }
};

const updateCalendarEvent$ = (action$, state$) =>
  action$.pipe(
    ofType(CONFIRM_INVITE_RESPONSE),
    filter(() => {
      const state = state$.value;
      return isCalendarRouteSel(state);
    }),
    map(({ payload: { eventId, data } }) => {
      const state = state$.value;
      const events = eventsSel(state);
      return updateEvents(
        events.map(event =>
          event.resource.id === eventId && event.resource.userId === data.userId
            ? {
                ...event,
                resource: {
                  ...event.resource,
                  userRsvp: data.rsvp,
                },
              }
            : event,
        ),
      );
    }),
  );

const afterRemoveCalendarEventActivity$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ACTIVITIY_RESPONSE),
    filter(() => {
      const state = state$.value;
      return isCalendarRouteSel(state);
    }),
    map(({ payload: deletedActivityId }) => {
      const state = state$.value;
      const events = eventsSel(state);
      return updateEvents(
        events.filter(event => deletedActivityId !== event.resource.activityId),
      );
    }),
  );

const afterInviteeChange$ = (action$, state$) =>
  action$.pipe(
    ofType(CONFIRM_INVITE_RESPONSE),
    filter(() => {
      const state = state$.value;
      return isEventRouteSel(state) || isActivityEventRouteSel(state);
    }),
    map(({ payload }) => {
      const { updated, data } = payload;
      return changeEventInviteeRsvp(data, updated);
    }),
  );

const reloadCalendarEventsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(
      RECORD_CAMPOUTS_RESPONSE,
      RECORD_SERVICE_RESPONSE,
      RECORD_LONG_CRUISE_RESPONSE,
      RECORD_HIKE_RESPONSE,
      GET_ALL_SUBUNITS_RESPONSE,
      DELETE_EVENT_REMINDER_RESPONSE,
    ),
    filter(() => {
      const state = state$.value;
      return isCalendarRouteSel(state);
    }),
    map(() => {
      const state = state$.value;
      const selectedDate = selectedDateSel(state);
      return getEventsRequest({ selectedDate });
    }),
  );

export default combineEpics(
  redirectToCalendarEpic$,
  getPersonalActivitiesEpic$,
  getCalendarEvents$,
  updateCalendarEvent$,
  afterRemoveCalendarEventActivity$,
  reloadCalendarEventsEpic$,
  afterInviteeChange$,
);
