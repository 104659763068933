import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  // components
  Dropdown,
  Menu,
  offlineSel,
  Button,
  // actions
  goToCalendarEditActivity,
} from '@shared';
import { permissions, hasPermissionSel } from '@user';
import { ALLOW_DELETE_ACTIVITIES } from '@config';

import { DeleteActivityButton } from '../../../common';
import styles from './ActivityActionsMenu.less';

const { Item: MenuItem } = Menu;

const ActivityActionsMenu = ({
  activityId,
  hideEdit,
  hideDuplicate,
  hideDelete,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const isOffline = useSelector(offlineSel);
  const canEdit = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_DATA),
  );

  const handleVisibleChange = useCallback(
    visible => {
      setIsVisible(visible);
    },
    [setIsVisible],
  );

  const handleItemClick = useCallback(
    (clone = false) =>
      () => {
        dispatch(
          goToCalendarEditActivity({
            activityId,
            clone,
          }),
        );
      },
    [dispatch, activityId],
  );

  const handleDeleteToggle = event => {
    event.stopPropagation();
    setIsVisible(false);
  };

  if (!canEdit || (hideEdit && hideDuplicate && hideDelete)) {
    return null;
  }

  return (
    <Dropdown
      onVisibleChange={handleVisibleChange}
      visible={isVisible}
      overlay={
        <Menu className={styles.actionsMenu}>
          {!hideEdit && (
            <MenuItem>
              <Button
                disabled={isOffline}
                onClick={handleItemClick()}
                type="link"
                size="small"
                block
              >
                <EditIcon /> <FormattedMessage id="shared.edit" />
              </Button>
            </MenuItem>
          )}
          {!hideDuplicate && (
            <MenuItem>
              <Button
                disabled={isOffline}
                onClick={handleItemClick(true)}
                type="link"
                size="small"
                block
              >
                <FileCopyIcon /> <FormattedMessage id="shared.duplicte" />
              </Button>
            </MenuItem>
          )}
          {ALLOW_DELETE_ACTIVITIES && canEdit && !hideDelete && (
            <MenuItem>
              <div
                className={styles.deleteButton}
                onClick={event => handleDeleteToggle(event)}
              >
                <DeleteActivityButton
                  id={activityId}
                  askForConfirmation
                  size="small"
                  showTooltip={false}
                  className={styles.deleteButton}
                >
                  <DeleteIcon /> <FormattedMessage id="shared.delete" />
                </DeleteActivityButton>
              </div>
            </MenuItem>
          )}
        </Menu>
      }
      trigger={['click']}
      placement="bottomRight"
      {...props}
    >
      <span className={styles.moreIcon}>
        <MoreVertIcon />
      </span>
    </Dropdown>
  );
};

ActivityActionsMenu.propTypes = {
  activityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onLoadCallback: PropTypes.func,
  onItemClick: PropTypes.func,
  hideEdit: PropTypes.bool,
  hideDuplicate: PropTypes.bool,
  hideDelete: PropTypes.bool,
};

ActivityActionsMenu.defaultProps = {
  onLoadCallback: () => {},
  onItemClick: () => {},
};

export default ActivityActionsMenu;
