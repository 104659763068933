import React, { useCallback, useState } from 'react';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { setDeleteRecurrentEventInfo } from '@modules/calendar/duck';
import {
  Button, // components
  Dropdown,
  Menu,
  deleteEventReq,
  offlineSel,
} from '@shared';

import { goToEventsEdit } from '../../duck/actions';
import styles from './EventActionsMenu.less';

const { Item: MenuItem } = Menu;

const EventActionsMenu = ({
  eventId,
  recurrentId,
  hideEdit,
  hideDeleteSeries,
  hideDuplicate,
  ...props
}) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const isOffline = useSelector(offlineSel);

  const handleVisibleChange = useCallback(
    visible => {
      setIsVisible(visible);
    },
    [setIsVisible],
  );

  const handleItemClick = useCallback(
    (clone = false, recurringEventId) =>
      e => {
        setIsVisible(false);
        dispatch(goToEventsEdit({ eventId, clone, recurringEventId }));
        e.stopPropagation();
      },
    [dispatch, eventId, setIsVisible],
  );

  const handleDeleteClick = useCallback(
    () => e => {
      setIsVisible(false);
      dispatch(deleteEventReq(eventId));
      e.stopPropagation();
    },
    [dispatch, eventId, setIsVisible],
  );

  const handleDeleteRecurrenceClick = useCallback(
    () => e => {
      setIsVisible(false);
      dispatch(
        setDeleteRecurrentEventInfo({
          modalVisible: true,
          eventId,
          recurrentId,
        }),
      );
      e.stopPropagation();
    },
    [dispatch, eventId, recurrentId],
  );

  const onClickMenuIcon = e => {
    e.stopPropagation();
  };

  if (hideEdit && hideDuplicate) {
    return null;
  }

  return (
    <div className={styles.menuContainer}>
      <Button
        block
        size="large"
        color="info"
        uppercase={false}
        shape="round"
        className={styles.leftBtn}
        onClick={handleItemClick()}
      >
        <EditIcon className={styles.btnIcon} />
        <FormattedMessage id="page.editEvent" />
      </Button>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        visible={isVisible}
        overlay={
          <Menu className={styles.actionsMenu}>
            {!!(!hideEdit && recurrentId) && (
              <MenuItem>
                <Button
                  disabled={isOffline}
                  onClick={handleItemClick(false, recurrentId)}
                  type="link"
                  size="small"
                  block
                >
                  <EditIcon />
                  <FormattedMessage id="events.editSeries" />
                </Button>
              </MenuItem>
            )}
            {!hideDuplicate && (
              <MenuItem>
                <Button
                  disabled={isOffline}
                  onClick={handleItemClick(true)}
                  type="link"
                  size="small"
                  block
                >
                  <FileCopyIcon />{' '}
                  <FormattedMessage id="events.duplicateEvent" />
                </Button>
              </MenuItem>
            )}
            {!hideEdit && (
              <MenuItem>
                <Button
                  disabled={isOffline}
                  onClick={handleDeleteClick()}
                  type="link"
                  size="small"
                  block
                >
                  <DeleteForeverIcon />{' '}
                  <FormattedMessage id="events.deleteEvent" />
                </Button>
              </MenuItem>
            )}

            {!!(!hideEdit && recurrentId && !hideDeleteSeries) && (
              <MenuItem>
                <Button
                  disabled={isOffline}
                  onClick={handleDeleteRecurrenceClick()}
                  type="link"
                  size="small"
                  block
                >
                  <DeleteForeverIcon />
                  <FormattedMessage id="events.deleteSeries" />
                </Button>
              </MenuItem>
            )}
          </Menu>
        }
        trigger={['click']}
        placement="bottomRight"
        onClick={onClickMenuIcon}
        {...props}
      >
        <Button
          size="large"
          color="info"
          uppercase={false}
          shape="round"
          className={styles.rightBtn}
        >
          {isVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
      </Dropdown>
    </div>
  );
};

EventActionsMenu.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recurrentId: PropTypes.number,
  onLoadCallback: PropTypes.func,
  onItemClick: PropTypes.func,
  hideEdit: PropTypes.bool,
  hideDeleteSeries: PropTypes.bool,
  hideDuplicate: PropTypes.bool,
};

EventActionsMenu.defaultProps = {
  onLoadCallback: () => {},
  onItemClick: () => {},
  hideEdit: false,
  hideDuplicate: false,
};
export default EventActionsMenu;
