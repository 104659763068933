import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { UnitType as SharedUnitType } from '@shared';

const UnitType = ({ program, ...props }) => (
  <SharedUnitType program={program} {...props} />
);

UnitType.propTypes = {
  program: PropTypes.string.isRequired,
};

const mapState = state => ({
  program: programSel(state),
});

export default connect(mapState)(UnitType);
