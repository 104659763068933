export { profileModuleName as moduleName } from '@shared';

export const displayOptions = Object.freeze({
  ADDRESS: 'address',
  PRIMARY_CONTACT: 'primaryContact',
  ADDITIONAL_INFO: 'additionalInfo',
});

export const unitPacks = Object.freeze({
  BOYS: 'boyPack',
  GIRLS: 'girlPack',
  DENS: 'dens',
});
