import { gtm } from '@shared';

import { loadAndOpenActivityAction } from '../../common';
import { namespace } from '../constants';

export const CLOSE_LONG_CRUISE_MODAL = `${namespace}/CLOSE_LONG_CRUISE_MODAL`;
export const closeLongCruiseModal = () => ({
  type: CLOSE_LONG_CRUISE_MODAL,
});

export const OPEN_RECORD_DAYS = `${namespace}/OPEN_RECORD_DAYS`;
export const openRecordDays = (
  userIds,
  disableGetActivities = false,
  disableFutureDays = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_RECORD_DAYS,
  payload: {
    userIds,
    disableGetActivities,
    disableFutureDays,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.LONG_CRUISE,
  },
});

export const OPEN_CREATE_LONG_CRUISE = `${namespace}/OPEN_CREATE_LONG_CRUISE`;
export const openCreateLongCruise = (
  dates = {},
  allowEditOrAddPersonsPersons = false,
  allowRemovePersons = true,
) => ({
  type: OPEN_CREATE_LONG_CRUISE,
  payload: {
    dates,
    allowEditOrAddPersonsPersons,
    allowRemovePersons,
  },
  gtm: {
    category: gtm.categories.LONG_CRUISE,
  },
});

export const OPEN_EDIT_LONG_CRUISE = `${namespace}/OPEN_EDIT_LONG_CRUISE`;
export const openEditLongCruise = (allowEditOrAddPersonsPersons = false) => ({
  type: OPEN_EDIT_LONG_CRUISE,
  payload: {
    allowEditOrAddPersonsPersons,
  },
  gtm: {
    category: gtm.categories.LONG_CRUISE,
  },
});

export const SET_SELECTED_LONG_CRUISE = `${namespace}/SET_SELECTED_LONG_CRUISE`;
export const setSelectedLongCruise = payload => ({
  type: SET_SELECTED_LONG_CRUISE,
  payload,
});

export const SET_DETAIL_MODE = `${namespace}/SET_DETAIL_MODE`;
export const setDetailMode = mode => ({ type: SET_DETAIL_MODE, payload: mode });

export const SET_ACTIVE_TAB = `${namespace}/SET_ACTIVE_TAB`;
export const setActiveTab = tab => ({ type: SET_ACTIVE_TAB, payload: tab });

export const RECORD_LONG_CRUISE_REQUEST = `${namespace}/RECORD_LONG_CRUISE_REQUEST`;
export const recordLongCruiseRequest = ({ activityData, closeOnSuccess }) => ({
  type: RECORD_LONG_CRUISE_REQUEST,
  payload: {
    activityData,
    closeOnSuccess,
  },
  gtm: {
    category: gtm.categories.LONG_CRUISE,
  },
});

export const RECORD_LONG_CRUISE_RESPONSE = `${namespace}/RECORD_LONG_CRUISE_RESPONSE`;

export const recordLongCruiseResponse = () => ({
  type: RECORD_LONG_CRUISE_RESPONSE,
});

export const RECORD_LONG_CRUISE_ERROR = `${namespace}/RECORD_LONG_CRUISE_ERROR`;
export const recordLongCruiseError = err => ({
  type: RECORD_LONG_CRUISE_ERROR,
  payload: err,
});

export const LOAD_AND_OPEN_LONG_CRUISE_MODAL = `${namespace}/LOAD_AND_OPEN_LONG_CRUISE_MODAL`;
export const loadAndOpenLongCruiseModal = loadAndOpenActivityAction(
  LOAD_AND_OPEN_LONG_CRUISE_MODAL,
);

export const LOAD_AND_OPEN_LONG_CRUISE_MODAL_ERROR = `${namespace}/LOAD_AND_OPEN_LONG_CRUISE_MODAL`;
export const loadAndOpenLongCruiseModalError = err => ({
  type: LOAD_AND_OPEN_LONG_CRUISE_MODAL_ERROR,
  payload: err,
});
