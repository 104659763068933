import React, { useCallback } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { unitTypeIdSel } from '@context';
import { isAddToSubUnitModalVisibleSel } from '@modules/advancement/subUnits/duck/selectors';
import { isMobileSel } from '@screen';
import { Modal, intl } from '@shared';

import {
  hideAddToSubUnitModal,
  programTypeIdToSubUnitName,
} from '../../../subUnits';
import AddToSubUnitForm from '../AddToSubUnitForm';
import styles from './AddToSubUnitModal.less';

const AddToSubUnitModal: React.FC = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileSel);
  const isVisible = useSelector(isAddToSubUnitModalVisibleSel);
  const unitTypeId = useSelector(unitTypeIdSel);

  const handleCloseModal = useCallback(() => {
    dispatch(hideAddToSubUnitModal());
  }, [dispatch]);

  return (
    <Modal
      className={styles.roundedModal}
      mask={!isMobile}
      headerColor="scouting-warm-gray"
      visible={isVisible}
      title={intl.formatMessage({
        id: `packRoster.AddSubUnit.AddToSubUnitModal.${
          programTypeIdToSubUnitName[unitTypeId as 1 | 2]
        }`,
      })}
      onCancel={handleCloseModal}
      backTitle={intl.formatMessage({
        id: 'packRoster.AddSubUnit.AddSubUnitModal.returnToRoster',
      })}
      onBack={handleCloseModal}
    >
      <div className={cn({ [styles.modalMobileContainer]: isMobile })}>
        <AddToSubUnitForm />
      </div>
    </Modal>
  );
};

export default AddToSubUnitModal;
