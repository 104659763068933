import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { ALLOW_DELETE_ACTIVITIES } from '@config';
import { permissions, hasPermissionSel } from '@user';
import { S, Card, Dropdown, Menu, Button, intl, Tooltip } from '@shared';

import { filterActivityValuesByPersonGuid } from '../../../../utils';
import styles from './ActivityItem.less';

const MenuItem = Menu.Item;

const ActivityItem = ({
  data,
  onSelect,
  onClone,
  onDelete,
  onJoin,
  showJoinButton,
  showDropDownMenu,
  personGuid,
}) => {
  const [isApproved, setIsApproved] = useState(false);
  const { totalAdults, totalYouths } = data;
  const noPersonsInActivity = !totalAdults && !totalYouths;
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );
  const personExists = useMemo(() => {
    if (showJoinButton) {
      const { registeredYouths, registeredAdults } = data;
      const existingYouth = filterActivityValuesByPersonGuid(
        registeredYouths,
        personGuid,
      );
      const existingAdult = filterActivityValuesByPersonGuid(
        registeredAdults,
        personGuid,
      );
      const [youthInfo] = existingYouth || [];
      const [adultInfo] = existingAdult || [];
      if (youthInfo) {
        setIsApproved(youthInfo.isApproved);
        return !!youthInfo;
      } else if (adultInfo) {
        setIsApproved(adultInfo.isApproved);
        return !!adultInfo;
      } else {
        setIsApproved(false);
        return false;
      }
    }
  }, [data, showJoinButton, personGuid]);

  const dropdown = showDropDownMenu && (
    <div onClick={e => e.stopPropagation()}>
      <Dropdown
        placement="bottomRight"
        overlay={
          <Menu>
            <MenuItem>
              <div onClick={onClone}>
                <FormattedMessage id="progress.common.ActivityItem.duplicate" />
              </div>
            </MenuItem>
            {ALLOW_DELETE_ACTIVITIES && canApprove && noPersonsInActivity && (
              <MenuItem>
                <div onClick={onDelete}>
                  <FormattedMessage id="progress.common.ActivityItem.delete" />
                </div>
              </MenuItem>
            )}
          </Menu>
        }
        trigger={['click']}
      >
        <KeyboardArrowDownIcon className={styles.icon} />
      </Dropdown>
    </div>
  );
  const joinButton = showJoinButton && (
    <div>
      {!isApproved && (
        <Button
          className={styles.joinButton}
          type="primary"
          shadow
          shape="round"
          onClick={() => onJoin(personExists)}
        >
          {personExists ? (
            <FormattedMessage id="shared.edit" />
          ) : (
            <FormattedMessage id="progress.common.ActivityItem.join" />
          )}
        </Button>
      )}
      {personExists && isApproved && (
        <Tooltip
          title={intl.formatMessage({
            id: 'progress.common.ActivityItem.approved',
          })}
        >
          <DoneAllIcon className={styles.approved} />
        </Tooltip>
      )}
    </div>
  );
  const { name, location, addressLine1 } = data || {};
  return (
    <Card noPadding hoverable shadow className={styles.item} onClick={onSelect}>
      <S size="3" bold colored className={styles.header}>
        {name} {joinButton} {dropdown}
      </S>
      {location && <S size="3">{location}</S>}
      {addressLine1 && <S size="3">{addressLine1}</S>}
    </Card>
  );
};

ActivityItem.defaultProps = {
  showJoinButton: false,
  showDropDownMenu: true,
};

ActivityItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showDropDownMenu: PropTypes.bool,
  showJoinButton: PropTypes.bool,
  personGuid: PropTypes.string,
};

export default ActivityItem;
