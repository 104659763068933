import { ROUTE_MESSAGING } from './duck';
import { MessagingPage } from './components';
import { permissions } from '@shared';

const routes = {
  [ROUTE_MESSAGING]: {
    path: '/messaging',
    Component: MessagingPage,
    titleId: 'page.messaging',
    permission: permissions.VIEW_MESSAGING,
  },
};

export default routes;
