import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SearchInput, Tabs, Card, intl } from '@shared';
import { isMobileSel } from '@screen';
import {
  searchLoadingSel,
  filteredUnitsSel,
  searchSel,
  setSearch,
  setRecentUnit,
  sorterSel,
  recentUnitsSorterSel,
  setSorter,
  setRecentUnitsSorter,
  filteredRecentUnitsSel,
  setRecentUnitsSearch,
  recentUnitsSearchSel,
  removeRecentUnit,
  activeTabSel,
  setActiveTab,
} from '../../duck';
import { tabNames } from '../../constants';
import UnitSearch from './UnitSearch';
import UnitsTable from './UnitsTable';
import UnitsList from './UnitsList';

const { TabPane } = Tabs;

class CouncilUnitsPage extends React.PureComponent {
  handleSearch = search => {
    this.props.onSetSearch(search);
  };

  handleRecentUnitsSearch = search => {
    this.props.onSetRecentUnitsSearch(search);
  };

  handleSelectUnit = unit => {
    this.props.onSetUnit(unit);
  };

  handleSorterChange = tabName => sorter => {
    const { onSetSorter, onSetRecentUnitsSorter } = this.props;
    tabName === tabNames.UNITS
      ? onSetSorter(sorter)
      : onSetRecentUnitsSorter(sorter);
  };

  handleRemoveRecentUnit = organizationGuid => {
    this.props.onRemoveRecentUnit(organizationGuid);
  };

  render() {
    const {
      isMobile,
      items,
      loading,
      search,
      sorter,
      recentUnitsSorter,
      recentUnits,
      recentUnitsSearch,
      activeTab,
      onSetActiveTab,
    } = this.props;

    return (
      <div>
        <UnitSearch />
        <Card margin noPadding shadow>
          <Tabs size="small" activeKey={activeTab} onChange={onSetActiveTab}>
            <TabPane
              tab={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.unitsTab',
              })}
              key={tabNames.UNITS}
            >
              <SearchInput
                size="large"
                placeholder={intl.formatMessage({
                  id: 'councilUnits.CouncilUnitsPage.searchBy',
                })}
                value={search}
                onChange={this.handleSearch}
              />
              {isMobile ? (
                <UnitsList
                  items={items}
                  loading={loading}
                  sorter={sorter}
                  onSelectItem={this.handleSelectUnit}
                  onSorterChange={this.handleSorterChange(tabNames.UNITS)}
                />
              ) : (
                <UnitsTable
                  items={items}
                  loading={loading}
                  sorter={sorter}
                  onSelectItem={this.handleSelectUnit}
                  onSorterChange={this.handleSorterChange(tabNames.UNITS)}
                />
              )}
            </TabPane>
            <TabPane
              tab={intl.formatMessage({
                id: 'councilUnits.CouncilUnitsPage.recentSearchTab',
              })}
              key={tabNames.RECENT_UNITS}
            >
              <SearchInput
                size="large"
                placeholder={intl.formatMessage({
                  id: 'councilUnits.CouncilUnitsPage.recentUnits.searchBy',
                })}
                value={recentUnitsSearch}
                onChange={this.handleRecentUnitsSearch}
              />
              {isMobile ? (
                <UnitsList
                  items={recentUnits}
                  hasSearchedItems
                  loading={loading}
                  sorter={sorter}
                  onSelectItem={this.handleSelectUnit}
                  onSorterChange={this.handleSorterChange(
                    tabNames.RECENT_UNITS,
                  )}
                  onRemoveItem={this.handleRemoveRecentUnit}
                />
              ) : (
                <UnitsTable
                  items={recentUnits}
                  hasSearchedItems
                  sorter={recentUnitsSorter}
                  onSelectItem={this.handleSelectUnit}
                  onSorterChange={this.handleSorterChange(
                    tabNames.RECENT_UNITS,
                  )}
                  onRemoveItem={this.handleRemoveRecentUnit}
                />
              )}
            </TabPane>
          </Tabs>
        </Card>
      </div>
    );
  }
}

CouncilUnitsPage.propTypes = {
  //Provided by parent
  //Provided by component itself
  isMobile: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  sorter: PropTypes.object.isRequired,
  recentUnitsSorter: PropTypes.object.isRequired,
  recentUnits: PropTypes.array.isRequired,
  recentUnitsSearch: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  onSetSearch: PropTypes.func.isRequired,
  onSetUnit: PropTypes.func.isRequired,
  onSetSorter: PropTypes.func.isRequired,
  onSetRecentUnitsSorter: PropTypes.func.isRequired,
  onSetRecentUnitsSearch: PropTypes.func.isRequired,
  onRemoveRecentUnit: PropTypes.func.isRequired,
  onSetActiveTab: PropTypes.func.isRequired,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
  items: filteredUnitsSel(state),
  loading: searchLoadingSel(state),
  search: searchSel(state),
  sorter: sorterSel(state),
  recentUnitsSorter: recentUnitsSorterSel(state),
  recentUnits: filteredRecentUnitsSel(state),
  recentUnitsSearch: recentUnitsSearchSel(state),
  activeTab: activeTabSel(state),
});

const mapDispatch = dispatch => ({
  onSetSearch: search => dispatch(setSearch(search)),
  onSetUnit: unit => dispatch(setRecentUnit(unit)),
  onSetSorter: sorter => dispatch(setSorter(sorter)),
  onSetRecentUnitsSorter: sorter => dispatch(setRecentUnitsSorter(sorter)),
  onSetRecentUnitsSearch: search => dispatch(setRecentUnitsSearch(search)),
  onRemoveRecentUnit: organizationGuid =>
    dispatch(removeRecentUnit(organizationGuid)),
  onSetActiveTab: tabName => dispatch(setActiveTab(tabName)),
});

export default connect(mapState, mapDispatch)(CouncilUnitsPage);
