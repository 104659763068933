// using createAction as backward compatibility, but slice is prefered
import { advancementModuleName } from '@modules/shared/constants';
import { createAction } from '@reduxjs/toolkit';

export const ROUTE_BULK_ENTRY_MB = createAction<{ orgGuid: string }>(
  `${advancementModuleName}/ROUTE_BULK_ENTRY_MB`,
);

export const ROUTE_BULK_ENTRY_ADVENTURE = createAction<{ orgGuid: string }>(
  `${advancementModuleName}/ROUTE_BULK_ENTRY_ADVENTURE`,
);

export const navigateToBulkEntryMB = ROUTE_BULK_ENTRY_MB;
export const navigateToBulkEntryAdventure = ROUTE_BULK_ENTRY_ADVENTURE;
