import httpErrorToast from './httpErrorToast';

const accountLockedErrorCode = 'idp-102';

export default ({ xhr }, { suppressErrorToasts }) => {
  const { response, statusText } = xhr;
  const { code, message } = response || {};
  const content = message || statusText;
  const isAccountLockedError = code === accountLockedErrorCode;

  if (!suppressErrorToasts) {
    return httpErrorToast({
      content,
      status,
      persistent: isAccountLockedError,
    });
  }
};
