import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { isCurrentPageSel } from '@location';
import {
  allSubUnitsSel,
  allUnitsSubunitsSel,
  allUnitsWithColorsSel,
  calendarRecordsSel,
} from '@modules/events/duck/selectors';
import { organizationPositionsSel } from '@shared';
import { userIdSel } from '@user';
import { allowUserChangeRsvp, simpleUnitFormat, sorters } from '@utils';

import { PERSONAL_ACTIVITY, calendarViews, moduleName } from '../constants';
import { sortFoundSubUnits } from '../utils';
import {
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_ACTIVITY_DETAILS,
  ROUTE_CALENDAR_CREATE_ACTIVITY,
  ROUTE_CALENDAR_EDIT_ACTIVITY,
  ROUTE_CALENDAR_EVENT_DETAILS,
} from './actions';

const moduleSel = state => state[moduleName];

export const isCalendarPageRouteSel = state =>
  isCurrentPageSel(
    state,
    ...[
      ROUTE_CALENDAR_EVENT_DETAILS,
      ROUTE_CALENDAR_ACTIVITY_DETAILS,
      ROUTE_CALENDAR_CREATE_ACTIVITY,
      ROUTE_CALENDAR_EDIT_ACTIVITY,
    ],
  );

export const selectedDateSel = state => moduleSel(state).selectedDate;
export const miniCalendarSelectedDateSel = state =>
  moduleSel(state).miniCalendarSelectedDate || moduleSel(state).selectedDate;
export const selectedMonthSel = state => moduleSel(state).selectedMonth;
export const calendarViewSel = state => moduleSel(state).calendarView;

export const addEventDatesSel = state => moduleSel(state).addEventDates;
export const addEventPopoverVisibleSel = state =>
  moduleSel(state).addEventPopoverVisible;

export const activitiesSel = state => moduleSel(state).activities;

export const eventsSel = state => moduleSel(state).events;

export const personalActivitiesSel = state =>
  moduleSel(state).personalActivities;

export const calendarRangeSel = state => moduleSel(state).selectedCalendarRange;

export const activityTypeFiltersSel = state =>
  moduleSel(state).activityTypeFilters;

export const isEventsLoadingSel = state => moduleSel(state).isEventsLoading;

export const isFeedEventsLoadingSel = state =>
  moduleSel(state).isFeedEventsLoading;

export const isPersonalActivitiesLoadingSel = state =>
  moduleSel(state).isPersonalActivitiesLoading;

export const calendarsToHideSel = state => moduleSel(state).calendarsToHide;
export const searchEventNameSel = state => moduleSel(state).searchEventName;

export const eventsWithFormatedUnits = createSelector(
  eventsSel,
  organizationPositionsSel,
  (events, positions) =>
    events.map(event => {
      const {
        resource: { units = [], invitedUsers = [], userId: eventUserId },
      } = event;
      const formattedUnits = units.map(unit => {
        const foundUnitPosition = positions.find(({ unitId, userId }) =>
          userId
            ? invitedUsers.find(
                ({ userId: invitedId }) => userId === invitedId,
              ) || eventUserId === userId
            : +unit.unitId === +unitId,
        );

        return foundUnitPosition
          ? simpleUnitFormat(foundUnitPosition, positions)
          : simpleUnitFormat(unit, positions);
      });

      return {
        ...event,
        resource: {
          ...event.resource,
          validUnits: formattedUnits,
        },
      };
    }),
);

export const unitsFilterOptionsSel = createSelector(
  organizationPositionsSel,
  allSubUnitsSel,
  (units, allSubUnits) => {
    /** @type {Record<string, import('@modules/user/duck/types').UserOrganizationInfo & {id: number; isSubUnit: boolean;}>} */
    const formattedUnitsSubunits = {};

    const validUnits = _.uniqBy(
      units.filter(unit => unit.unitId),
      'unitId',
    );

    validUnits.forEach(unit => {
      const {
        unitId,
        organizationName,
        acceptGender,
        program,
        isParentGuardian,
      } = unit;
      formattedUnitsSubunits[`${unit.unitId}-`] = {
        id: unitId,
        organizationName,
        acceptGender,
        program,
        isParentGuardian,
        active: true,
      };

      const foundSubUnits = allSubUnits.find(
        subUnits =>
          subUnits && subUnits.length && subUnits[0].unitId === +unit.unitId,
      );

      if (foundSubUnits) {
        const sortedFoundSubUnits = sortFoundSubUnits(foundSubUnits);

        sortedFoundSubUnits.forEach(subUnit => {
          const isDen = !!subUnit.denType;
          formattedUnitsSubunits[`${subUnit.subUnitId}-`] = {
            id: subUnit.subUnitId,
            organizationName: isDen
              ? `${subUnit.denType} ${subUnit.subUnitName}`
              : subUnit.subUnitName,
            isSubUnit: true,
            unitId: subUnit.unitId,
            active: true,
          };
        });
      }
    });

    return formattedUnitsSubunits;
  },
);

export const calendarFiltersSel = createSelector(
  unitsFilterOptionsSel,
  calendarsToHideSel,
  allUnitsWithColorsSel,
  (options, calendarsToHide, defaultValues) => {
    const filter = {};
    const calendarRecordById = _.keyBy(defaultValues, 'id');
    Object.keys(options).forEach(option => {
      const rawId = `${option}`.replace('-', '');
      // get the enabled/disabled setting from calendar record
      const calendarRecord = calendarRecordById[rawId];
      filter[option] = calendarRecord
        ? calendarRecord?.colorRecord?.showCalendar
        : !calendarsToHide.has(option);
    });

    return filter;
  },
);
export const defaultActiveCalendarsSel = createSelector(
  allUnitsWithColorsSel,
  items => {
    const calendarRecordById = _.chain(items || [])
      .map(item => [item.id, !!item.colorRecord?.showCalendar])
      .fromPairs()
      .value();
    return calendarRecordById;
  },
);

export const calendarKeysSel = createSelector(unitsFilterOptionsSel, keys =>
  Object.keys(keys),
);

export const filteredEventsSel = createSelector(
  eventsWithFormatedUnits,
  activityTypeFiltersSel,
  (events, activityFilters) =>
    events.filter(
      ({ resource: { activityTypeId } }) =>
        !activityTypeId || activityFilters[activityTypeId],
    ),
);

export const eventListSel = createSelector(
  filteredEventsSel,
  userIdSel,
  (filteredEvents, userId) =>
    filteredEvents.map(event => ({
      ...event,
      resource: {
        ...event.resource,
        rsvpAllowed: allowUserChangeRsvp({
          invitedUsers: event.resource.invitedUsers,
          userId,
          canRsvp: event.resource.canRsvp,
        }),
      },
    })),
);

export const eventsDuplicatedByUnitSel = createSelector(
  eventListSel,
  calendarRecordsSel,
  allUnitsSubunitsSel,
  calendarFiltersSel,
  (events, colorRecords, userUnitsSubunits, filters) => {
    const duplicatedEvents = [];
    events.forEach(event => {
      const { resource } = event;
      resource.units.forEach(unit => {
        const foundUnit = userUnitsSubunits.find(userUnit => {
          const eventUnitId = unit.patrolId || unit.denId || unit.unitId;
          return filters[`${eventUnitId}-`] && eventUnitId === userUnit.id;
        });

        if (!foundUnit) {
          return;
        }

        const foundRecord = colorRecords.find(record => {
          const colorUnitId = record.patrolId || record.denId || record.unitId;
          const eventUnitId = unit.patrolId || unit.denId || unit.unitId;

          return eventUnitId === colorUnitId;
        });

        const resultColor = foundRecord ? foundRecord.color : '#c2c0c0';

        const clonedEvent = _.cloneDeep(event);
        clonedEvent.color = resultColor;
        clonedEvent.resource.primaryUnit = { ...unit, color: resultColor };
        duplicatedEvents.push(clonedEvent);
      });
    });

    return duplicatedEvents;
  },
);

export const filteredPersonalActivitiesSel = createSelector(
  selectedMonthSel,
  personalActivitiesSel,
  activityTypeFiltersSel,
  (selectedMonth, personalActivities, activityFilters) => {
    if (!activityFilters[PERSONAL_ACTIVITY]) {
      return [];
    }
    return personalActivities.filter(
      ({ resource: { activityTypeId, startDate } }) => {
        if (selectedMonth !== moment(startDate).format('MM-YYYY')) {
          return false;
        }
        return !activityTypeId || activityFilters[activityTypeId];
      },
    );
  },
);

export const calendarItemsSel = createSelector(
  eventsDuplicatedByUnitSel,
  events =>
    events.sort(
      sorters.compose(
        sorters.date('resource.startDate'),
        sorters.stringMulti('resource.activityType', 'title'),
      ),
    ),
);

export const calendarItemsWithSearchSel = createSelector(
  eventsDuplicatedByUnitSel,
  searchEventNameSel,
  (events, searchEventName) =>
    events
      .filter(event => event.title.toLowerCase().includes(searchEventName))
      .sort(
        sorters.compose(
          sorters.date('resource.startDate'),
          sorters.stringMulti('resource.activityType', 'title'),
        ),
      ),
);

export const gridItemsSel = createSelector(
  eventsDuplicatedByUnitSel,
  filteredPersonalActivitiesSel,
  selectedDateSel,
  searchEventNameSel,
  (events, personalActivities, selectedDate, searchEventName) => {
    const date = moment(selectedDate);
    return events
      .concat(personalActivities)
      .filter(event => event.title.toLowerCase().includes(searchEventName))
      .filter(({ start }) => date.startOf('month').isBefore(start))
      .sort(
        sorters.compose(
          sorters.date('resource.startDate'),
          sorters.stringMulti('resource.activityType', 'title'),
        ),
      );
  },
);

export const eventsBySelectedDateSel = createSelector(
  selectedDateSel,
  eventsDuplicatedByUnitSel,
  (selectedDate, events) => {
    const date = moment(selectedDate);
    const eventsInDate = events
      .filter(({ start, end }) => date.isBetween(start, end, 'day', '[]'))
      .map(({ resource }) => resource);
    return eventsInDate;
  },
);

export const isCalendarRouteSel = state =>
  isCurrentPageSel(state, ROUTE_CALENDAR);

export const isEventRouteSel = state =>
  isCurrentPageSel(state, ROUTE_CALENDAR_EVENT_DETAILS);

export const isActivityEventRouteSel = state =>
  isCurrentPageSel(state, ROUTE_CALENDAR_ACTIVITY_DETAILS);

export const viewCalendarUnitSel = createSelector(
  calendarViewSel,
  calendarView =>
    calendarView === calendarViews.WEEK
      ? calendarViews.WEEK
      : calendarViews.MONTH,
);

export const deleteRecurrentEventInfoSel = createSelector(moduleSel, state => {
  /** @type {import('@modules/events/duck/types').DeleteRecurrentEventState} */
  const info = state.deleteRecurrentEventInfo;
  return info;
});
