import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, AdvancementStatus } from '@shared';
import styles from './SuccessMessage.less';
import { getAdvancementTypeTranslation } from "@shared/localization";

function SuccessMessage({
  advancementType,
  persons,
  advancementName,
  onViewApproved,
  advancementStatus,
}) {
  const names = persons.map(p => p.personFullName.trim()).join(', ');
  const advancementTypeName = getAdvancementTypeTranslation(advancementType);
  const status = AdvancementStatus.getTranslation(advancementStatus);

  return (
    <div className={styles.successMsg}>
      <span className={styles.successMsgText}>
        <FormattedMessage
          id="recordAdvancement.RecordAdvancement.approvedToMsg"
          values={{
            advancementTypeName,
            advancementName,
            names,
            status,
          }}
        />
      </span>
      {onViewApproved && (
        <Button
          size="small"
          noBorder
          fitText
          color="info"
          onClick={onViewApproved}
        >
          <FormattedMessage id="recordAdvancement.RecordAdvancement.viewApproved" />
        </Button>
      )}
    </div>
  );
}

SuccessMessage.propTypes = {
  advancementType: PropTypes.string.isRequired,
  advancementName: PropTypes.string.isRequired,
  persons: PropTypes.array.isRequired,
  advancementStatus: PropTypes.string.isRequired,
  onViewApproved: PropTypes.func,
};

export default SuccessMessage;
