import { isCurrentPageSel } from '@location';
import { ROUTE_ROLES } from '../../roles';
import { ROUTE_SETTINGS } from '../../settings';
import { ROUTE_FEEDBACK } from '../../feedback';
import { moduleName } from '../constants';

const settingsRoutes = [ROUTE_ROLES, ROUTE_SETTINGS, ROUTE_FEEDBACK];

export const isSettingsRouteSel = state =>
  isCurrentPageSel(state, ...settingsRoutes);

const moduleSel = state => state[moduleName];

export const updateAppSel = state => moduleSel(state).updateApp;
export const updatingAppSel = state => moduleSel(state).updatingApp;

export {
  appBootstrappedSel,
  sidebarOpenSel,
  sidebarExpandedSel,
  headerVisibleSel,
} from '@shared';
