import { locationHash } from '@modules/shared';

import { moduleName } from '../constants';

export const SET_ACTIVE_TAB = `${moduleName}/SET_ACTIVE_TAB`;
export const setActiveTab = tab => dispatch => {
  locationHash.set(tab);
  dispatch({
    type: SET_ACTIVE_TAB,
    payload: tab,
  });
};

export const OPEN_REPORT_PARAMS_MODAL = `${moduleName}/OPEN_REPORT_PARAMS_MODAL`;
export const openReportParamsModal = ({
  organizationGuid,
  report,
  users = [],
  reportType,
  reportCode,
}) => ({
  type: OPEN_REPORT_PARAMS_MODAL,
  payload: {
    organizationGuid,
    users,
    report,
    reportType,
    reportCode,
  },
});

export const CLOSE_REPORT_PARAMS_MODAL = `${moduleName}/CLOSE_REPORT_PARAMS_MODAL`;
export const closeReportParamsModal = () => ({
  type: CLOSE_REPORT_PARAMS_MODAL,
});
