import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  DateView,
  S,
  viewDateFormat,
  AdvancementImage,
  ActivityTypeIcon,
  historyItemsTypes,
} from '@shared';

import RemoveParticipantModal from '../../../../activities/components/ActivityLogs/RemoveParticipantModal';
import { closeEditAdvancement } from '../../../duck';
import styles from './AdvancementInfo.less';

const AdvancementInfo = ({ advancement }) => {
  const dispatch = useDispatch();
  const { description, date, imgUrl, type, activityType } = advancement;
  const isActivity = type === historyItemsTypes.ACTIVITY;
  const ImageComponent = isActivity ? ActivityTypeIcon : AdvancementImage;

  const handleCloseEditModal = useCallback(() => {
    dispatch(closeEditAdvancement());
  }, [dispatch]);

  return (
    <div className={styles.advancementContainer}>
      <RemoveParticipantModal
        onRemoveSuccess={handleCloseEditModal}
        advancement={advancement}
        titleText="editAdvancement.EditAdvancementForm.removeFromActivity"
        bodyText="editAdvancement.EditAdvancementForm.confirmRemoval"
        buttonText="shared.remove"
      />
      <div className={styles.advancementImage}>
        <ImageComponent
          className={styles.advancementImage}
          src={imgUrl}
          activityType={activityType}
        />
      </div>
      <div className={styles.advancementInfo}>
        <S size="4">{description}</S>
        <S size="4" className={styles.completionDate}>
          <DateView value={date} format={viewDateFormat} />
        </S>
      </div>
    </div>
  );
};

AdvancementInfo.propTypes = {
  advancement: PropTypes.object,
};

export default AdvancementInfo;
