import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import {
  START_ADVANCEMENT_ERROR,
  START_ADVANCEMENT_REQUEST,
  START_ADVANCEMENT_RESPONSE,
} from './actions';
import {
  unstartAdvancementError,
  unstartAdvancementRequest,
  unstartAdvancementResponse,
} from './actionsTyped';

const loading = createReducer(false, builder => {
  builder
    .addCase(START_ADVANCEMENT_REQUEST, () => true)
    .addCase(START_ADVANCEMENT_RESPONSE, () => false)
    .addCase(START_ADVANCEMENT_ERROR, () => false)
    .addCase(unstartAdvancementRequest, () => true)
    .addCase(unstartAdvancementResponse, () => false)
    .addCase(unstartAdvancementError, () => false);
});

export default combineReducers({
  loading,
});
