import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal } from '@shared/components';

import {
  closeMasqueradeModal,
  masqueradeLoadingSel,
  masqueradeModalOpenSel,
  masqueradeRequest,
  userDataLoadingSel,
} from '../../duck';
import styles from './MasqueradeModal.less';
import MasqueradeModalContent from './MasqueradeModalContent';

class MasqueradeModal extends React.PureComponent {
  render() {
    const { isOpen, loading, onClose, onMasquerade } = this.props;

    return (
      <Modal
        className={styles.modal}
        fullscreenMobile={false}
        visible={isOpen}
        onCancel={onClose}
      >
        <MasqueradeModalContent loading={loading} onMasquerade={onMasquerade} />
      </Modal>
    );
  }
}

MasqueradeModal.propTypes = {
  // Provided by component itself
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onMasquerade: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapState = state => ({
  isOpen: masqueradeModalOpenSel(state),
  loading: masqueradeLoadingSel(state) || userDataLoadingSel(state),
});

const mapDispatch = dispatch => ({
  onMasquerade: (memberId, editWhileMasquerading) =>
    dispatch(masqueradeRequest(memberId, false, editWhileMasquerading)),
  onClose: () => dispatch(closeMasqueradeModal()),
});

export default connect(mapState, mapDispatch)(MasqueradeModal);
