/**
 * SortOrder helper for the <Table> component of antd.
 * You can pass it as the 'sortOrder' property of column config.
 * This will tell Table which column is being sorted and which way (asc/desc)
 * so that it can apply proper classes to column headers.
 *
 * @see https://ant.design/components/table/#Column
 * @example
 * sortOrder: sortOrder(this.state.sorter, 'name')
 * @param {Object} sorter - current sorting information
 * @param {String} [sorter.columnKey] - key of column to be sorted
 * @param {String} [sorter.order] - order of sorting ('ascend'/'descend')
 */
export default (sorter, key) => sorter.columnKey === key && sorter.order;
