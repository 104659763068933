import React, { useCallback, useLayoutEffect } from 'react';

import { useDispatch } from 'react-redux';

import { ENABLE_GOOGLE_SIGN_IN } from '@config';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { googleSignIn } from '@utils';

import { GOOGLE_SIGN_IN_BUTTON_ID } from '../../constants';
import { googleLoginValidate } from '../../duck';
import styles from './GoogleSignInButton.less';

const GoogleSignInButton = () => {
  const dispatch = useDispatch();

  const handleOnSuccess = useCallback(
    token => {
      dispatch(googleLoginValidate(token));
    },
    [dispatch],
  );

  const handleOnFailure = useCallback(() => {
    toastService.error(
      intl.formatMessage({
        id: 'user.LoginPage.error.googleLogin',
      }),
    );
  }, []);

  useLayoutEffect(() => {
    if (ENABLE_GOOGLE_SIGN_IN) {
      googleSignIn.init(handleOnSuccess, handleOnFailure);
    }
  }, [handleOnSuccess, handleOnFailure]);

  return ENABLE_GOOGLE_SIGN_IN ? (
    <div id={GOOGLE_SIGN_IN_BUTTON_ID} className={styles.googleLoginButton} />
  ) : null;
};

export default GoogleSignInButton;
