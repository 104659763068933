import React from 'react';

import { Avatar } from 'bsa-ui';
import PropTypes from 'prop-types';

import { activityTypeForActivityTypeId } from '../../constants';
import ActivityType from '../ActivityType';
import ActivityTypeIcon from '../ActivityTypeIcon';
import Button from '../Button';
import { Col, Row } from '../bsaComponents';
import styles from './ActivityTypeLogLink.less';

const ActivityTypeLogLink = ({ activityTypeId, onClick, detailInfo }) => (
  <Row
    onClick={onClick}
    className={styles.activityLink}
    type="flex"
    align="middle"
  >
    <Col>
      <Avatar className={styles.activityIconContainer} size={60}>
        <ActivityTypeIcon activityTypeId={activityTypeId} />
      </Avatar>
    </Col>
    <Col className={styles.activityName}>
      <div>
        <Button type="link">
          <ActivityType
            activityType={activityTypeForActivityTypeId[activityTypeId]}
          />
        </Button>
        {detailInfo && <div className={styles.summary}>{detailInfo}</div>}
      </div>
    </Col>
  </Row>
);

ActivityTypeLogLink.propTypes = {
  activityTypeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
  detailInfo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.bool,
  ]),
  onClick: PropTypes.func,
};

ActivityTypeLogLink.defaultProps = {
  onClick: () => {},
  detailInfo: undefined,
};

export default ActivityTypeLogLink;
