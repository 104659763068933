import { moduleName } from '../constants';

export const FETCH_POSITIONS_REQUEST = `${moduleName}/FETCH_POSITIONS_REQUEST`;
export const fetchPositionsRequest = () => ({
  type: FETCH_POSITIONS_REQUEST,
});
export const FETCH_POSITIONS_RESPONSE = `${moduleName}/FETCH_POSITIONS_RESPONSE`;
export const fetchPositionsResponse = payload => ({
  type: FETCH_POSITIONS_RESPONSE,
  payload,
});
export const FETCH_POSITIONS_ERROR = `${moduleName}/FETCH_POSITIONS_ERROR`;
export const fetchPositionsError = err => ({
  type: FETCH_POSITIONS_ERROR,
  payload: err,
});

export const ROUTE_DEN_CHIEF_CONFIRMATION = `${moduleName}/ROUTE_DEN_CHIEF_CONFIRMATION`;
export const goToDenChiefConfirmation = () => ({
  type: ROUTE_DEN_CHIEF_CONFIRMATION,
});
