import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { SET_ORGANIZATION } from '@context';
import { GENERATE_ADVANCEMENT_REPORT_RESPONSE } from '../../../advancementReport';
import {
  HISTORY_REPORTS_REQUEST,
  HISTORY_REPORTS_RESPONSE,
  HISTORY_REPORTS_ERROR,
  HISTORY_REPORTS_SEARCH,
  SET_SORTER,
  SET_SHOW_PRICE,
} from './actions';

const items = (state = [], { type, payload }) => {
  switch (type) {
    case HISTORY_REPORTS_RESPONSE: {
      return payload;
    }
    case SET_ORGANIZATION:
    case HISTORY_REPORTS_ERROR:
    case GENERATE_ADVANCEMENT_REPORT_RESPONSE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case HISTORY_REPORTS_REQUEST: {
      return true;
    }
    case HISTORY_REPORTS_RESPONSE:
    case HISTORY_REPORTS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case HISTORY_REPORTS_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const sorter = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SORTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showPrice = (state = true, { type, payload }) => {
  switch (type) {
    case SET_SHOW_PRICE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  items,
  loading,
  search,
  sorter,
  showPrice,
});

export default resetOnLogoutReducer(reducer);
