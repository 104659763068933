import React from 'react';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import cn from 'classnames';

import { ActivityTypeIcon, AdvancementImage } from '@shared';
import { historyItemsTypes } from '../../../constants';
import styles from './HistoryItemIcon.less';

const HistoryItemIcon = ({ type, className, item }) => {
  const computedClassNames = cn(styles.icon, className);
  switch (type) {
    case historyItemsTypes.ADVANCEMENT_FILE: {
      return <InsertDriveFileIcon className={computedClassNames} />;
    }
    case historyItemsTypes.ACTIVITY: {
      return (
        <ActivityTypeIcon
          activityTypeId={item.activityTypeId}
          isJTE={item.isJTE}
          className={computedClassNames}
        />
      );
    }
    default: {
      return (
        <AdvancementImage className={computedClassNames} src={item.imgUrl} />
      );
    }
  }
};

HistoryItemIcon.propTypes = {
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  className: PropTypes.string,
  item: PropTypes.object,
};

HistoryItemIcon.defaultProps = {
  item: {},
};

export default HistoryItemIcon;
