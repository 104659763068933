import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import styles from './ProText.less';

const ProText = ({ pro, className, ...props }) => {
  if (!pro) {
    const classNames = cn(styles.text, className);
    return (
      <span className={classNames} {...props}>
        {' '}
        (<FormattedMessage id="shared.ProText" />)
      </span>
    );
  }
  return '';
};

ProText.propTypes = {
  pro: PropTypes.bool,
  className: PropTypes.string,
};

ProText.defaultProps = {
  pro: false,
  className: '',
};

export default ProText;
