import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button } from '@shared/components';

import styles from './ReportParamsButtons.less';

class ReportParamsButtons extends React.PureComponent {
  render() {
    const { loading, onSubmit } = this.props;

    return (
      <div className={styles.buttons}>
        <Button
          type="primary"
          shadow
          color="success"
          size="large"
          disabled={loading}
          loading={loading}
          onClick={onSubmit}
        >
          <FormattedMessage id="packRoster.ReportParamsButtons.runReport" />
        </Button>
      </div>
    );
  }
}

ReportParamsButtons.propTypes = {
  // provided by parent
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReportParamsButtons;
