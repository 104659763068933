import _ from 'lodash';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, forkJoin, of } from 'rxjs';
import { flatMap, map, switchMap, tap } from 'rxjs/operators';

import { ALLOW_DELETE_ACTIVITIES } from '@config';
import { intl, packRosterItemsSel } from '@shared';
import { toastService } from '@toasts';
import { catchAndReport } from '@utils/rxjs/operators';

import { getEditActivityObject } from '../utils';
import {
  // action constant
  CATEGORIES_AND_ORGANIZATIONS_REQUEST,
  DELETE_ACTIVITIY_REQUEST,
  GET_ACTIVITIES_REQUEST,
  GET_LOCAL_LOCATIONS_REQUEST,
  LOAD_AND_OPEN_ACTIVITY,
  SEARCH_LOCATIONS_REQUEST,
  categoriesAndOrganizationsError,
  categoriesAndOrganizationsResponse,
  deleteActivityError,
  deleteActivityResponse,
  getActivitiesError, // action creators
  getActivitiesResponse,
  getLocalLocationsError,
  getLocalLocationsResponse,
  searchLocationsError,
  searchLocationsResponse,
  setSelectedActivity,
} from './actions';
import {
  fetchActivityCategories$,
  fetchCollaborativeOrganizations$,
  getActivities$,
  getActivityById$,
  getLocations$,
  removeActivity$,
  removeRegisteredParticipant$,
} from './services';

const fetchActivitiesEpic$ = action$ =>
  action$.pipe(
    ofType(GET_ACTIVITIES_REQUEST),
    switchMap(({ payload }) =>
      getActivities$(payload).pipe(
        map(getActivitiesResponse),
        catchAndReport(err => of(getActivitiesError(err))),
      ),
    ),
  );

const fetchCategoriesAndOrganizationsEpic$ = action$ =>
  action$.pipe(
    ofType(CATEGORIES_AND_ORGANIZATIONS_REQUEST),
    switchMap(() =>
      forkJoin(fetchActivityCategories$(), fetchCollaborativeOrganizations$()),
    ),
    map(([categories, organizations]) =>
      categoriesAndOrganizationsResponse({ categories, organizations }),
    ),
    catchAndReport(err => of(categoriesAndOrganizationsError(err))),
  );

const fetchLocalLocationsEpic$ = action$ =>
  action$.pipe(
    ofType(GET_LOCAL_LOCATIONS_REQUEST),
    switchMap(({ payload: zip }) =>
      getLocations$({
        zip,
        radiusInMiles: '200',
      }).pipe(
        map(locations => getLocalLocationsResponse({ zip, locations })),
        catchAndReport(err => of(getLocalLocationsError(err))),
      ),
    ),
  );

const fetchLocationsByNameEpic$ = action$ =>
  action$.pipe(
    ofType(SEARCH_LOCATIONS_REQUEST),
    switchMap(({ payload: name }) => {
      if (name.length >= 2) {
        return getLocations$({
          name: `${name}%`,
        }).pipe(
          map(searchLocationsResponse),
          catchAndReport(err => of(searchLocationsError(err))),
        );
      } else {
        return of(searchLocationsResponse([]));
      }
    }),
  );

const loadAndOpenActivityEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_AND_OPEN_ACTIVITY),
    switchMap(
      ({
        payload: {
          successActionFn,
          errorActionFn,
          activityId,
          processBeforeOpen,
          afterLoadCallBack = () => {},
          duplicateActivity,
        },
      }) =>
        getActivityById$(activityId).pipe(
          flatMap(activityData => {
            let processedActivity;
            if (duplicateActivity) {
              const {
                collaborativeOrganizations = [],
                akelaStateId = undefined,
              } = activityData;
              processedActivity = {
                ...activityData,
                id: undefined,
                name: `Copy of ${activityData.name}`,
                registeredYouths: [],
                registeredAdults: [],
                akelaStateId: akelaStateId && `${activityData.akelaStateId}`,
                collaborativeOrganizations: collaborativeOrganizations.map(
                  item => item.collabOrgId,
                ),
              };
            } else {
              // Inject roster isLeader and isParent
              const rosterItems = packRosterItemsSel(state$.value);
              const adultsOnActivity = _.intersectionBy(
                rosterItems,
                activityData?.registeredAdults,
                'userId',
              );
              const activityAdultsDictionary =
                adultsOnActivity?.reduce(
                  (curr, el) => ({
                    ...curr,
                    [`${el.userId}`]: {
                      isParent: el.isParent,
                      isLeader: el.isLeader,
                    },
                  }),
                  {},
                ) || {};
              const extendedActivityData = {
                ...activityData,
                ...(adultsOnActivity.length && {
                  registeredAdults: activityData?.registeredAdults.map(el => ({
                    ...el,
                    isParent:
                      activityAdultsDictionary[`${el.userId}`]?.isParent,
                    isLeader:
                      activityAdultsDictionary[`${el.userId}`]?.isLeader,
                  })),
                }),
              };

              const editObject = processBeforeOpen
                ? processBeforeOpen(extendedActivityData)
                : extendedActivityData;
              processedActivity = getEditActivityObject(editObject);
            }
            afterLoadCallBack();
            return Observable.concat(
              Observable.of(setSelectedActivity(processedActivity)),
              Observable.of(successActionFn(true)),
            );
          }),
          catchAndReport(err => of(errorActionFn(err))),
        ),
    ),
  );

const conditionalEpics = [];
if (ALLOW_DELETE_ACTIVITIES) {
  conditionalEpics.push(action$ =>
    action$.pipe(
      ofType(DELETE_ACTIVITIY_REQUEST),
      switchMap(
        ({
          payload: {
            activityId,
            activityValueIds = [],
            callbackSuccess = () => {},
            callbackError = () => {},
          },
        }) => {
          const removeValuesOnDelete = [of({})];
          activityValueIds.forEach(id => {
            removeValuesOnDelete.push(
              removeRegisteredParticipant$({
                activityId,
                activityRecordId: id,
              }),
            );
          });
          return forkJoin(removeValuesOnDelete).pipe(
            switchMap(() =>
              removeActivity$({ activityId }).pipe(
                map(() => {
                  callbackSuccess();
                  return deleteActivityResponse(activityId);
                }),
                tap(() => {
                  toastService.success(
                    intl.formatMessage({
                      id: 'progress.common.deleteSuccess',
                    }),
                  );
                }),
                catchAndReport(err => {
                  if (err.status === 422) {
                    if (err.response.errorDesc.includes('Metric')) {
                      toastService.error(
                        intl.formatMessage({
                          id: 'progress.common.deleteMetricError',
                        }),
                      );
                    }
                  }
                  callbackError();
                  return of(deleteActivityError(err));
                }),
              ),
            ),
            catchAndReport(err => {
              if (err.status === 422) {
                if (err.response.errorDesc.includes('Metric')) {
                  toastService.error(
                    intl.formatMessage({
                      id: 'progress.common.deleteMetricError',
                    }),
                  );
                }
              }
              callbackError();
              return of(deleteActivityError(err));
            }),
          );
        },
      ),
    ),
  );
}

export default combineEpics(
  fetchActivitiesEpic$,
  fetchCategoriesAndOrganizationsEpic$,
  fetchLocalLocationsEpic$,
  fetchLocationsByNameEpic$,
  loadAndOpenActivityEpic$,
  ...conditionalEpics,
);
