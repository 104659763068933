import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './CircleLabel.less';

const propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  bold: PropTypes.bool,
};

const defaultProps = {
  bold: false,
};

const CircleLabel = React.memo(function CircleLabel({
  label,
  className = {},
  bold,
}) {
  const classNames = cn(styles.circle, className, {
    fontWeight: bold ? '500' : 'normal',
  });
  return <div className={classNames}>{label}</div>;
});

CircleLabel.propTypes = propTypes;
CircleLabel.defaultProps = defaultProps;

export default CircleLabel;
