import React from 'react';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import S from '../S';
import styles from './ReturnButton.less';
import { ReturnButtonProps } from './ReturnButton.types';

const ReturnButton: React.FC<ReturnButtonProps> = ({
  onClick,
  children,
  extra,
  className,
  wrapClassName,
  modal,
  ...rest
}) => {
  const text = children || <FormattedMessage id="shared.ReturnButton.text" />;

  return (
    <div
      className={cn(styles.wrapper, wrapClassName, {
        [styles.modal]: modal,
      })}
    >
      <Button
        noBorder
        fitText
        ghost
        uppercase={false}
        size="small"
        color="info"
        className={cn(styles.button, className)}
        onClick={onClick}
        {...rest}
      >
        <KeyboardArrowLeftIcon className={styles.arrow} />{' '}
        <S size="3" inline>
          {' '}
          {text}{' '}
        </S>
      </Button>
      {extra}
    </div>
  );
};

export default ReturnButton;
