import React, { Fragment, useCallback, useMemo, useState } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { RosterScout } from '@modules/advancement/duck/types';
import { Button, S } from '@shared';

import {
  clearPeople,
  selectMultiplePerson,
  setMultiplePersonsToRemove,
} from '../../duck';
import ClearPersonsConfirmationModal from '../ClearPersonsConfirmationModal';
import styles from './SelectMultiplePersonsButton.less';

interface Props {
  className?: string;
  persons: RosterScout[];
  notAllowedToRemoveIds?: number[];
  canRemove: boolean;
  allPersonsSelected: boolean;
  onSelectMultiplePerson?: (userIds: number[]) => void;
  onDeselectMultiplePerson?: (userIds: number[]) => void;
}

const SelectMultiplePersonsButton: React.FC<Props> = ({
  className = '',
  persons,
  onSelectMultiplePerson,
  onDeselectMultiplePerson,
  notAllowedToRemoveIds,
  canRemove = false,
  allPersonsSelected,
}) => {
  const [isClearModalOpen, setIsClearModalOpen] = useState(false);
  const dispatch = useDispatch();
  const allUserIds = useMemo(
    () => persons.map(person => person.userId),
    [persons],
  );

  const handleSelectAllPersons = useCallback(() => {
    if (onSelectMultiplePerson) {
      onSelectMultiplePerson(allUserIds);
    } else {
      dispatch(selectMultiplePerson(allUserIds));
    }
  }, [dispatch, allUserIds, onSelectMultiplePerson]);

  const handleDeselectAllPersons = useCallback(() => {
    if (canRemove) {
      if (notAllowedToRemoveIds?.length) {
        setIsClearModalOpen(true);
      } else if (onDeselectMultiplePerson) {
        onDeselectMultiplePerson(allUserIds);
      } else {
        dispatch(clearPeople());
      }
    }
  }, [
    canRemove,
    notAllowedToRemoveIds,
    onDeselectMultiplePerson,
    allUserIds,
    dispatch,
  ]);

  const handleCancel = () => {
    setIsClearModalOpen(false);
  };

  const handleConfirm = () => {
    dispatch(clearPeople());
    dispatch(setMultiplePersonsToRemove(notAllowedToRemoveIds));
    setIsClearModalOpen(false);
  };

  return (
    <Fragment>
      <div
        className={cn(styles.container, {
          [className]: !!className,
        })}
      >
        <Button
          noBorder
          className={cn(styles.addPersonButton)}
          onClick={
            allPersonsSelected
              ? handleDeselectAllPersons
              : handleSelectAllPersons
          }
        >
          {!allPersonsSelected ? (
            <S size="4" serif informative bold>
              <FormattedMessage id="shared.selectAll" />
            </S>
          ) : (
            <S size="4" serif informative bold>
              <FormattedMessage
                id="shared.clearAll"
                defaultMessage="Clear All"
              />
            </S>
          )}
        </Button>
      </div>
      <ClearPersonsConfirmationModal
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        isVisible={isClearModalOpen}
      />
    </Fragment>
  );
};

export default SelectMultiplePersonsButton;
