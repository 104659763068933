import { resetOnLogoutReducer } from '@modules/utils';

import {
  moduleName as activitiesModuleName,
  reducers as activityReducers,
} from '../modules/activities';
import {
  moduleName as adultProfileModuleName,
  reducers as adultProfileReducers,
} from '../modules/adultProfile';
import {
  moduleName as advancementModuleName,
  reducers as advancementReducers,
} from '../modules/advancement';
import {
  moduleName as advancementReportModuleName,
  reducers as advancementReportReducers,
} from '../modules/advancementReport';
import { quickEntryRankSlice } from '../modules/bulkEntry/duck/slice';
import { moduleName as calendarModuleName } from '../modules/calendar/constants';
import calendarReducers from '../modules/calendar/duck/reducers';
import {
  ALLOW_MBC_LOGIN,
  SHOW_COUNSELED_YOUTH,
  SHOW_USER_PERMISSIONS,
  moduleName as configModuleName,
  reducers as configReducers,
} from '../modules/config';
import {
  moduleName as contextModuleName,
  reducers as contextReducers,
} from '../modules/context';
import {
  moduleName as coreModuleName,
  reducers as coreReducers,
} from '../modules/core';
import {
  moduleName as councilUnitsModuleName,
  reducers as councilUnitsReducers,
} from '../modules/councilUnits';
import {
  moduleName as dictModuleName,
  reducers as dictReducers,
} from '../modules/dict';
import { moduleName as eventsModuleName } from '../modules/events/constants';
import eventsReducers from '../modules/events/duck/reducers';
import {
  moduleName as feedbackModuleName,
  reducers as feedbackReducers,
} from '../modules/feedback';
import { moduleName as leadershipPositionsModuleName } from '../modules/leadershipPositions/constants';
import leadershipPositionsReducers from '../modules/leadershipPositions/duck/reducersTyped';
import { moduleName as localizationModuleName } from '../modules/localization/constants';
import localizationReducers from '../modules/localization/duck';
import {
  moduleName as meritBadgeCounselorsModuleName,
  reducers as meritBadgeCounselorsReducers,
} from '../modules/meritBadgeCounselors';
import {
  moduleName as messagingModuleName,
  reducers as messagingReducers,
} from '../modules/messaging';
import {
  moduleName as offlineModuleName,
  reducers as offlineReducers,
} from '../modules/offline';
import {
  moduleName as parentGuardianModuleName,
  reducers as parentGuardianReducers,
} from '../modules/parentGuardian';
import {
  moduleName as paymentLogsModuleName,
  reducers as paymentLogsReducers,
} from '../modules/paymentLogs';
import {
  moduleName as permissionsManagerModuleName,
  reducers as permissionsManagerReducers,
} from '../modules/permissionsManager';
import {
  moduleName as profileModuleName,
  reducers as profileReducers,
} from '../modules/profile';
import { moduleName as progressModuleName } from '../modules/progress/constants';
import progressReducers from '../modules/progress/duck/reducers';
import {
  moduleName as recharterModuleName,
  reducers as recharterReducers,
} from '../modules/recharter';
import {
  moduleName as reportsModuleName,
  reducers as reportsReducers,
} from '../modules/reports';
import { esbApi } from '../modules/rtk/esb-api';
import {
  moduleName as screenModuleName,
  reducers as screenReducers,
} from '../modules/screen';
import {
  moduleName as settingsModuleName,
  reducers as settingsReducers,
} from '../modules/settings';
import {
  moduleName as sharedModuleName,
  reducers as sharedReducers,
} from '../modules/shared';
import {
  moduleName as unitModuleName,
  reducers as unitReducers,
} from '../modules/unit';
import {
  moduleName as userModuleName,
  reducers as userReducers,
} from '../modules/user';
import notificationsSlice from '../modules/userNotifications/duck/slice';
import { moduleName as youthProfileModuleName } from '../modules/youthProfile';
import youthProfileReducers from '../modules/youthProfile/duck/reducersTyped';

const getRolesReducers = () => {
  if (!SHOW_USER_PERMISSIONS) return {};

  const { moduleName, reducers: rolesReducers } = require('../modules/roles');
  return { [moduleName]: rolesReducers };
};

const getCounseledYouthReducers = () => {
  if (!SHOW_COUNSELED_YOUTH && !ALLOW_MBC_LOGIN) return {};

  const { moduleName } = require('../modules/counseledYouth/constants');
  const {
    reducers: counseledYouthReducers,
  } = require('../modules/counseledYouth/duck');
  return { [moduleName]: counseledYouthReducers };
};

const reducers = {
  // Regular
  [coreModuleName]: coreReducers,
  [configModuleName]: configReducers,
  [calendarModuleName]: calendarReducers,
  [eventsModuleName]: eventsReducers,
  [leadershipPositionsModuleName]: resetOnLogoutReducer(
    leadershipPositionsReducers,
  ),
  [sharedModuleName]: sharedReducers,
  [contextModuleName]: contextReducers,
  [localizationModuleName]: localizationReducers,
  [userModuleName]: userReducers,
  [dictModuleName]: dictReducers,
  [screenModuleName]: screenReducers,
  [reportsModuleName]: reportsReducers,
  [advancementModuleName]: advancementReducers,
  [activitiesModuleName]: activityReducers,
  [profileModuleName]: profileReducers,
  [unitModuleName]: unitReducers,
  [progressModuleName]: progressReducers,
  [offlineModuleName]: offlineReducers,
  [advancementReportModuleName]: advancementReportReducers,
  [feedbackModuleName]: feedbackReducers,
  [youthProfileModuleName]: resetOnLogoutReducer(youthProfileReducers),
  [notificationsSlice.name]: resetOnLogoutReducer(notificationsSlice.reducer),
  [settingsModuleName]: settingsReducers,
  [councilUnitsModuleName]: councilUnitsReducers,
  [meritBadgeCounselorsModuleName]: meritBadgeCounselorsReducers,
  [parentGuardianModuleName]: parentGuardianReducers,
  [adultProfileModuleName]: adultProfileReducers,
  [paymentLogsModuleName]: paymentLogsReducers,
  [quickEntryRankSlice.name]: quickEntryRankSlice.reducer,
  [permissionsManagerModuleName]: permissionsManagerReducers,
  [recharterModuleName]: recharterReducers,
  // RTK API
  [esbApi.reducerPath]: esbApi.reducer,
  [messagingModuleName]: messagingReducers,

  ...getRolesReducers(),
  ...getCounseledYouthReducers(),
};

export default reducers;
