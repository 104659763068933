import React from 'react';
import PropTypes from 'prop-types';

class AsyncLoadComponent extends React.Component {
  state = {
    Component: 'div',
  };

  componentDidMount = async () => {
    const { default: Component } = await this.props.fn();
    this.setState({ Component });
  };

  render() {
    const { Component } = this.state;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fn, ...rest } = this.props;

    return <Component {...rest} />;
  }
}

AsyncLoadComponent.propTypes = {
  fn: PropTypes.func.isRequired,
};

export default AsyncLoadComponent;
