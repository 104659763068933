import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';

import { messagingRoles } from './constants';

export const permissions = Object.freeze({
  EDIT_DATA: 'EDIT_DATA', // catch-all 'edit' permission - allows to edit/(un)approve advancements, generate reports etc...
  EDIT_ACTIVITIES: 'EDIT_ACTIVITIES', // put edit activities persmission separated since some scoutbook roles, are only able to edit activities, but not approve them
  EDIT_SUB_UNITS: 'EDIT_SUB_UNITS',
  EDIT_ADVANCEMENTS: 'EDIT_ADVANCEMENTS',
  EDIT_MBC: 'EDIT_MBC',
  EDIT_CALENDAR: 'EDIT_CALENDAR',
  EDIT_SUBCALENDAR: 'EDIT_SUBCALENDAR',
  APPROVE_ADVANCEMENTS: 'APPROVE_ADVANCEMENTS',
  VIEW_COUNCIL_UNITS_PAGE: 'VIEW_COUNCIL_UNITS_PAGE',
  VIEW_MERIT_BADGE_COUNSELORS_PAGE: 'VIEW_MERIT_BADGE_COUNSELORS_PAGE',
  VIEW_ROSTER_PAGE: 'VIEW_ROSTER_PAGE',
  VIEW_PROFILE_PAGE: 'VIEW_PROFILE_PAGE',
  VIEW_REPORTS_PAGE: 'VIEW_REPORTS_PAGE',
  VIEW_CALENDAR_PAGE: 'VIEW_CALENDAR_PAGE',
  VIEW_CALENDAR_CREATE_ACTIVITY: 'VIEW_CALENDAR_CREATE_ACTIVITY',
  MASQUERADE: 'MASQUERADE',
  VIEW_COUNSELED_YOUTH: 'VIEW_COUNSELED_YOUTH',
  VIEW_SELF_YOUTH_PROFILE: 'VIEW_SELF_YOUTH_PROFILE',
  VIEW_YOUTH_PROFILE_PAGE: 'VIEW_YOUTH_PROFILE_PAGE',
  VIEW_ADULT_PROFILE_PAGE: 'VIEW_ADULT_PROFILE_PAGE',
  SHOW_FORUM_LINK: 'SHOW_FORUM_LINK',
  SHOW_SCOUTBOOK_LINK: 'SHOW_SCOUTBOOK_LINK',
  SHOW_RECHARTER_LINK: 'SHOW_RECHARTER_LINK',
  SUBMIT_EAGLE_PROJECT: 'SUBMIT_EAGLE_PROJECT',
  INVITE_MEMBERS_TO_RECHARTER: 'INVITE_MEMBERS_TO_RECHARTER',
  VIEW_EDIT_SWIM_HEALTH_PROFILE: 'VIEW_EDIT_SWIM_HEALTH_PROFILE',
  VIEW_EDIT_JOINED_DATE_PROFILE: 'VIEW_EDIT_JOINED_DATE_PROFILE',
  VIEW_UNIT_PAYMENT_LOGS: 'VIEW_UNIT_PAYMENT_LOGS',
  VIEW_YOUTH_PAYMENT_LOGS: 'VIEW_YOUTH_PAYMENT_LOGS',
  EDIT_YOUTH_PAYMENT_LOGS: 'EDIT_YOUTH_PAYMENT_LOGS',
  VIEW_PERMISSIONS_MANAGER: 'VIEW_PERMISSIONS_MANAGER_PAGE',
  EDIT_PERMISSIONS_MANAGER: 'EDIT_PERMISSIONS_MANAGER_PAGE',
  VIEW_MESSAGING: 'VIEW_MESSAGING',
});

export const roles = Object.freeze({
  UNIT_LEADER: 'UNIT_LEADER',
  COUNCIL_ADMIN: 'COUNCIL_ADMIN',
  PROFILE_EDITOR: 'PROFILE_EDITOR',
  ACTIVITY_EDITOR: 'ACTIVITY_EDITOR',
  SUB_UNIT_EDITOR: 'SUB_UNIT_EDITOR',
  MBC_VIEWER: 'MBC_VIEWER',
  CALENDARS_EDITOR: 'CALENDARS_EDITOR',
  SUBCALENDAR_EDITOR: 'SUBCALENDAR_EDITOR',
  ADVANCEMENTS_APPROVER: 'ADVANCEMENTS_APPROVER',
  DATA_EDITOR: 'DATA_EDITOR',
  MASQUERADER: 'MASQUERADER',
  MERIT_BADGE_COUNSELOR: 'MERIT_BADGE_COUNSELOR',
  REPORT_VIEWER: 'REPORT_VIEWER',
  YOUTH_MEMBER: 'YOUTH_MEMBER',
  PARENT_GUARDIAN: 'PARENT_GUARDIAN',
  BASIC_USER: 'BASIC_USER',
  PAYMENT_LOGS: 'PAYMENT_LOGS',
  EDIT_PAYMENT_LOGS: 'EDIT_PAYMENT_LOGS',
  MESSAGE_VIEWER: 'MESSAGE_VIEWER',
});

export const organizationRoles = Object.freeze({
  CALENDAR_EDITOR: ['IA Edit Calendar', 'Calendar Editor'],
});

// every permission MUST be TRUE (NO negative permissions)!!11oneone
export const rolePermissions = Object.freeze({
  [roles.UNIT_LEADER]: Object.freeze({
    [permissions.VIEW_ROSTER_PAGE]: true,
    [permissions.VIEW_CALENDAR_PAGE]: true,
    [permissions.VIEW_YOUTH_PROFILE_PAGE]: true,
    [permissions.VIEW_ADULT_PROFILE_PAGE]: true,
    [permissions.VIEW_CALENDAR_CREATE_ACTIVITY]: true,
    [permissions.SUBMIT_EAGLE_PROJECT]: true,
    [permissions.INVITE_MEMBERS_TO_RECHARTER]: true,
    [permissions.VIEW_EDIT_SWIM_HEALTH_PROFILE]: true,
    [permissions.VIEW_EDIT_JOINED_DATE_PROFILE]: true,
    [permissions.EDIT_ADVANCEMENTS]: true,
    [permissions.APPROVE_ADVANCEMENTS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
    [permissions.EDIT_PERMISSIONS_MANAGER]: true,
    [permissions.VIEW_MESSAGING]: true,
  }),
  [roles.ACTIVITY_EDITOR]: Object.freeze({
    [permissions.EDIT_ACTIVITIES]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.SUB_UNIT_EDITOR]: Object.freeze({
    [permissions.EDIT_SUB_UNITS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.MBC_VIEWER]: Object.freeze({
    [permissions.VIEW_MERIT_BADGE_COUNSELORS_PAGE]: true,
  }),
  [roles.CALENDARS_EDITOR]: Object.freeze({
    [permissions.VIEW_CALENDAR_PAGE]: true,
    [permissions.EDIT_CALENDAR]: true,
  }),
  [roles.SUBCALENDAR_EDITOR]: Object.freeze({
    [permissions.VIEW_CALENDAR_PAGE]: true,
    [permissions.EDIT_SUBCALENDAR]: true,
  }),
  [roles.COUNCIL_ADMIN]: Object.freeze({
    [permissions.VIEW_COUNCIL_UNITS_PAGE]: true,
    [permissions.VIEW_MERIT_BADGE_COUNSELORS_PAGE]: true,
    [permissions.EDIT_MBC]: true,
    [permissions.VIEW_PROFILE_PAGE]: true,
    [permissions.VIEW_EDIT_SWIM_HEALTH_PROFILE]: true,
    [permissions.VIEW_ROSTER_PAGE]: true,
  }),
  [roles.PROFILE_EDITOR]: Object.freeze({
    [permissions.VIEW_PROFILE_PAGE]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.ADVANCEMENTS_APPROVER]: Object.freeze({
    [permissions.APPROVE_ADVANCEMENTS]: true,
    [permissions.EDIT_ADVANCEMENTS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.DATA_EDITOR]: Object.freeze({
    [permissions.EDIT_DATA]: true,
    [permissions.EDIT_ADVANCEMENTS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.MASQUERADER]: Object.freeze({
    [permissions.MASQUERADE]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.MERIT_BADGE_COUNSELOR]: Object.freeze({
    [permissions.VIEW_COUNSELED_YOUTH]: true,
    [permissions.VIEW_YOUTH_PROFILE_PAGE]: true,
    [permissions.VIEW_PROFILE_PAGE]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.REPORT_VIEWER]: Object.freeze({
    [permissions.VIEW_REPORTS_PAGE]: true,
  }),
  [roles.YOUTH_MEMBER]: Object.freeze({
    [permissions.VIEW_SELF_YOUTH_PROFILE]: true,
    [permissions.VIEW_YOUTH_PROFILE_PAGE]: true,
    [permissions.SHOW_SCOUTBOOK_LINK]: true,
    [permissions.VIEW_CALENDAR_PAGE]: true,
    [permissions.SUBMIT_EAGLE_PROJECT]: true,
    [permissions.VIEW_ADULT_PROFILE_PAGE]: true,
    [permissions.EDIT_ADVANCEMENTS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
    [permissions.VIEW_MESSAGING]: true,
  }),
  [roles.PARENT_GUARDIAN]: Object.freeze({
    [permissions.VIEW_YOUTH_PROFILE_PAGE]: true,
    [permissions.SHOW_SCOUTBOOK_LINK]: true,
    [permissions.VIEW_CALENDAR_PAGE]: true,
    [permissions.VIEW_EDIT_JOINED_DATE_PROFILE]: true,
    [permissions.VIEW_ADULT_PROFILE_PAGE]: true,
    [permissions.EDIT_ADVANCEMENTS]: true,
    [permissions.VIEW_YOUTH_PAYMENT_LOGS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
    [permissions.VIEW_MESSAGING]: true,
  }),
  [roles.BASIC_USER]: Object.freeze({
    [permissions.SHOW_FORUM_LINK]: true,
    [permissions.SHOW_SCOUTBOOK_LINK]: true,
    [permissions.SHOW_RECHARTER_LINK]: true,
  }),
  [roles.PAYMENT_LOGS]: Object.freeze({
    [permissions.VIEW_UNIT_PAYMENT_LOGS]: true,
    [permissions.VIEW_YOUTH_PAYMENT_LOGS]: true,
    [permissions.VIEW_PERMISSIONS_MANAGER]: true,
  }),
  [roles.EDIT_PAYMENT_LOGS]: Object.freeze({
    [permissions.EDIT_YOUTH_PAYMENT_LOGS]: true,
  }),
  [roles.MESSAGE_VIEWER]: Object.freeze({
    [permissions.VIEW_MESSAGING]: true,
  }),
});

const getScoutbookPermissions = activeOrg => {
  const scoutbokRoles = [roles.ACTIVITY_EDITOR];
  if (activeOrg && activeOrg.canAproveAdvancements) {
    scoutbokRoles.push(roles.ADVANCEMENTS_APPROVER);
  }
  return scoutbokRoles;
};

const getMasqueradePermissions = activeOrg => {
  if (activeOrg && activeOrg.isScoutbookRole) {
    return getScoutbookPermissions(activeOrg);
  } else if (
    (activeOrg && activeOrg.isYouthMember) ||
    (activeOrg && activeOrg.isParentGuardian)
  ) {
    return [roles.ACTIVITY_EDITOR];
  } else {
    return [roles.DATA_EDITOR, roles.ADVANCEMENTS_APPROVER];
  }
};

const getUserRoles = ({
  organizations,
  canMasquerade,
  activeOrganization: activeOrg = {},
  isMasquerading,
  editWhileMasquerading,
  isSelectedOrganizationValid,
}) => {
  const userRoles = [];
  if (organizations && organizations.length > 0) {
    if (activeOrg.isCouncil) {
      userRoles.push(roles.COUNCIL_ADMIN);
      userRoles.push(roles.REPORT_VIEWER);
      if (isSelectedOrganizationValid) {
        userRoles.push(roles.UNIT_LEADER, roles.BASIC_USER);
      }
      if (activeOrg.hasCouncilEditRights && !isMasquerading) {
        userRoles.push(roles.DATA_EDITOR, roles.ADVANCEMENTS_APPROVER);
      }
    }

    if (activeOrg.canEditCalendar) {
      userRoles.push(roles.CALENDARS_EDITOR);
    }

    if (activeOrg.canEditPermissionsManager) {
      userRoles.push(roles.EDIT_PERMISSIONS_MANAGER);
    }

    if (activeOrg.canEditSubCalendar) {
      userRoles.push(roles.SUBCALENDAR_EDITOR);
    }

    if (activeOrg.isMeritBadgeCounselor) {
      userRoles.push(roles.MERIT_BADGE_COUNSELOR, roles.BASIC_USER);
    }

    if (activeOrg.isYouthMember) {
      userRoles.push(roles.YOUTH_MEMBER);
    }

    if (activeOrg.isParentGuardian) {
      userRoles.push(roles.PARENT_GUARDIAN);
    }

    if (activeOrg.canEditSubUnits) {
      userRoles.push(roles.SUB_UNIT_EDITOR);
    }

    if (activeOrg.canSeeMBCs) {
      userRoles.push(roles.MBC_VIEWER);
    }

    if (
      (activeOrg.isYouthMember || activeOrg.isParentGuardian) &&
      !isMasquerading
    ) {
      userRoles.push(roles.ACTIVITY_EDITOR);
    }

    if (
      !activeOrg.isCouncil &&
      !activeOrg.isMeritBadgeCounselor &&
      !activeOrg.isYouthMember &&
      !activeOrg.isParentGuardian
    ) {
      userRoles.push(roles.UNIT_LEADER, roles.REPORT_VIEWER, roles.BASIC_USER);
      if (!isMasquerading && !activeOrg.isScoutbookRole) {
        userRoles.push(roles.DATA_EDITOR, roles.ADVANCEMENTS_APPROVER);
      }
    }

    if (!activeOrg.isCouncil) {
      userRoles.push(roles.PROFILE_EDITOR);
    }

    if (!isMasquerading && activeOrg.isScoutbookRole) {
      userRoles.push(...getScoutbookPermissions(activeOrg));
    }

    if (activeOrg.canEditPaymentLogs) {
      userRoles.push(roles.EDIT_PAYMENT_LOGS);
    }
  }

  if (intersection(messagingRoles, activeOrg.allRoles).length > 0) {
    userRoles.push(roles.MESSAGE_VIEWER);
  }

  if (canMasquerade) {
    userRoles.push(roles.MASQUERADER);
  }

  if (isMasquerading && editWhileMasquerading) {
    userRoles.push(...getMasqueradePermissions(activeOrg));
  }

  return uniq(userRoles);
};

export const getUserPermissions = ({
  organizations,
  hasCouncilPosition,
  canMasquerade,
  activeOrganization,
  isMasquerading,
  editWhileMasquerading,
  isSelectedOrganizationValid,
  canEditPaymentLogs,
} = {}) => {
  const roles = getUserRoles({
    organizations,
    hasCouncilPosition,
    canMasquerade,
    activeOrganization,
    isMasquerading,
    editWhileMasquerading,
    isSelectedOrganizationValid,
    canEditPaymentLogs,
  });
  const final = roles.reduce(
    (acc, role) => ({ ...acc, ...rolePermissions[role] }),
    {},
  );

  return final;
};
