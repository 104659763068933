import { ENV } from '@config';
import serviceWorkerAvailable from './serviceWorkerAvailable';

/**
 * Returns x-target-url header (if applicable in the current environment)
 * that tells the proxy the 'real' URL to call.
 */
export default url => {
  if (serviceWorkerAvailable() || ENV === 'local') {
    return {
      'x-target-url': url,
    };
  }
  return {};
};
