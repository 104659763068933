import toast from '@toasts';

export let hasToastLoaded = false;

export const resetToast = () => (hasToastLoaded = false);

const getNamesList = persons =>
  persons.map(person => person.personShortFullName).join(', ');

export const loadNullUserIdsWarning = roster => {
  const personsNoUserId = roster.filter(({ userId }) => !userId);
  const personsYouthNames = getNamesList(
    personsNoUserId.filter(({ isAdult }) => !isAdult),
  );
  const personsAdultsName = getNamesList(
    personsNoUserId.filter(({ isAdult }) => isAdult),
  );
  const youthMessage =
    personsYouthNames.length > 0 ? `Youth: ${personsYouthNames}` : '';
  const adultMessage =
    personsAdultsName.length > 0 ? `Adults: ${personsAdultsName}` : '';
  const shouldDisplayWarn = youthMessage || adultMessage;

  if (shouldDisplayWarn) {
    toast.devWarn(`${youthMessage} *** ${adultMessage}`, 'No userId');
  }
  hasToastLoaded = true;
};
