import PropTypes from 'prop-types';

export {
  longCruiseModuleName as moduleName,
  longCruiseModuleNamespace as namespace,
  detailModes,
} from '../common';

export const datesShape = PropTypes.shape({
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

export const notApplicableCategoryId = 9; // add fixed categoryId since is required for create activity endpoint the Id corresponds to "not applicable"
