/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import type {
  SelectProps as AntdSelectProps,
  SelectValue,
} from 'antd/lib/select';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed

/* eslint-disable import/named */
import { Select as BsaSelect } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isMobileSel } from '../../duck';
import { ModalContextContainerConsumer } from '../Modal';
import styles from './Select.less';
import SelectMobile from './SelectMobile';

export interface SelectProps extends AntdSelectProps {
  className?: string;
  rounded?: boolean;
  onChange?: (option: SelectValue) => void;
  floatingLabel?: boolean;
  hideSelections?: boolean;
  useCustomMobile?: boolean;
  label?: string;
}

const Select = React.forwardRef(function Select(
  {
    defaultValue,
    getPopupContainer,
    onSelect,
    onChange,
    className,
    rounded,
    floatingLabel,
    hideSelections,
    useCustomMobile = true,
    ...props
  }: SelectProps,
  ref,
) {
  const [value, setValue] = useState(defaultValue || props.value);
  const isMobile = useSelector(isMobileSel);

  const handleSelect: SelectProps['onSelect'] = (...selectValues) => {
    if (onSelect) {
      onSelect(...selectValues);
    }
    setValue(selectValues[0]);
  };

  const handleChange = (option: SelectValue) => {
    if (onChange) {
      onChange(option);
    }
    setValue(option);
  };

  const getValue = () => ('value' in props ? props.value : value);

  const classNames = cn(className, styles.selectContainer, {
    [styles.rounded]: rounded,
    [styles.floatingLabel]: floatingLabel,
    [styles.showPlaceholder]: floatingLabel && !!props.value && !hideSelections,
    [styles.selectedValue]: !props.value,
    [styles.hideSelections]: hideSelections,
  });

  const Select = isMobile && useCustomMobile ? SelectMobile : BsaSelect;

  return (
    <ModalContextContainerConsumer>
      {getModalPopupContainer => (
        <Select
          getPopupContainer={
            getPopupContainer ? getPopupContainer : getModalPopupContainer
          }
          {...props}
          className={classNames}
          onSelect={handleSelect}
          onChange={handleChange}
          value={getValue()}
          ref={ref as any}
        />
      )}
    </ModalContextContainerConsumer>
  );
}) as unknown as React.FC<SelectProps> & {
  Option: typeof BsaSelect.Option;
  OptGroup: typeof BsaSelect.OptGroup;
};

Select.propTypes = {
  ...SelectMobile.propTypes,
  getPopupContainer: PropTypes.func,
};

Select.defaultProps = {
  ...BsaSelect.defaultProps,
};

Select.Option = BsaSelect.Option;
Select.OptGroup = BsaSelect.OptGroup;

export default Select;
