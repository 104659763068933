import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { toastService } from '@toasts';
import { setManualOffline, offlineSel } from '../../duck';
import Button from '../Button';
import manualOfflineHelper from './manualOfflineHelper';
import styles from './ManualOffline.less';

let isToastActive;

const ManualOffline = () => {
  const dispatch = useDispatch();
  const isOffline = useSelector(offlineSel);
  const handleOffline = useCallback(() => {
    dispatch(setManualOffline(true));
    toastService.dismiss();
  }, [dispatch]);
  const handleDismiss = useCallback(() => {
    manualOfflineHelper.clearErrors();
    toastService.dismiss();
  }, []);

  useEffect(() => {
    const subscription = manualOfflineHelper.apiDown$.subscribe(apiDown => {
      if (apiDown && !isOffline && !isToastActive) {
        toastService.info(
          <div className={styles.container}>
            <FormattedMessage id="shared.ManualOffline.description" />
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                size="default"
                color="success"
                noBorder
                fitText
                onClick={handleOffline}
              >
                <FormattedMessage id="shared.yes" />
              </Button>
              <Button
                className={styles.button}
                size="default"
                noBorder
                fitText
                onClick={handleDismiss}
              >
                <FormattedMessage id="shared.no" />
              </Button>
            </div>
          </div>,
          undefined,
          {
            autoClose: false,
            closeOnClick: false,
            onClose: () => (isToastActive = false),
          },
        );
        isToastActive = true;
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch, handleDismiss, handleOffline, isOffline]);

  return null;
};

export default ManualOffline;
