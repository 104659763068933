import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import S from '../S';
import T from '../T';
import UnderMaintenanceSvg from './assets/UnderMaintenance.svg';
import styles from './UnderMaintenance.less';

let showMaintenanceMessage = () => {};

class UnderMaintenance extends React.PureComponent {
  state = {
    msg: <FormattedMessage id="shared.UnderMaintenance.defaultMessage" />,
    maintenance: false,
  };

  componentDidMount() {
    showMaintenanceMessage = this.showMaintenanceMessage;
  }

  showMaintenanceMessage = msg => {
    this.setState({ msg: msg || this.state.msg, maintenance: true });
  };

  renderMaintenanceScreen = () => {
    const { msg } = this.state;

    return (
      <div className={styles.container}>
        <UnderMaintenanceSvg className={styles.image} />
        <div className={styles.divider} />
        <T size="1" bold serif className={styles.title}>
          <FormattedMessage id="shared.UnderMaintenance.title" />
        </T>
        <S size="2" className={styles.message}>
          {msg}
        </S>
      </div>
    );
  };

  render() {
    if (this.state.maintenance) {
      return this.renderMaintenanceScreen();
    }
    return this.props.children;
  }
}

UnderMaintenance.propTypes = {
  children: PropTypes.node,
};

export default UnderMaintenance;
export { showMaintenanceMessage };
