import {
  MAX_RECENT_UNITS,
  PermissionsTabNames,
  adultProfileModuleName,
  advancementHistoryModuleNamespace,
  advancementModuleName,
  advancementTabNames,
  calendarModuleName,
  contextModuleName,
  coreModuleName,
  councilUnitsModuleName,
  dashboardModuleName,
  eventsModuleName,
  messagingModuleName,
  moduleName,
  offlineModuleName,
  parentGuardianModuleName,
  permissionsManagerModuleName,
  personProfileModuleName,
  reportsModuleName,
  settingsModuleName,
  userModuleName,
  youthProfileModuleName,
} from '../constants';
import { gtm, locationHash } from '../utils';
import { storeRecentUnit, storeUserRecentUnits } from './councilUnitsServices';
import {
  isYouthMemberSel,
  parentOrgGuidSel,
  personGuidSel,
  recentUnitsSel,
} from './selectors';
import { storeLastOnlineDate, storeUserTableConfig } from './services';
import userPreferencesServices from './userPreferencesServices';

export {
  ROUTE_CALENDAR_EVENT_DETAILS,
  goToYouthProfile,
  ROUTE_YOUTH_PROFILE,
  goToCalendarEventDetails,
} from './actionsTyped.ts';

export const ROUTE_HOME = `${dashboardModuleName}/ROUTE_HOME`;
export const ROUTE_SETTINGS = `${settingsModuleName}/ROUTE_SETTINGS`;

export const ROUTE_PERMISSIONS = `${permissionsManagerModuleName}/ROUTE_PERMISSIONS`;
export const navigateToPermissions = () => ({ type: ROUTE_PERMISSIONS });
export const navigateToPermissionsTab =
  (activeTab = PermissionsTabNames.CONNECTIONS) =>
  dispatch => {
    locationHash.set(activeTab);
    gtm.pushEvent({
      event: 'PERMISSIONS_TAB_CHANGE',
    });
    dispatch({ type: ROUTE_PERMISSIONS, payload: { hash: activeTab } });
  };

export const ROUTE_ADVANCEMENT = `${advancementModuleName}/ROUTE_ADVANCEMENT`;
export const navigateToRoster = () => ({ type: ROUTE_ADVANCEMENT });
export const navigateToRosterTab = activeTab => {
  const tab = activeTab || advancementTabNames.ROSTER;
  return dispatch => {
    locationHash.set(tab);
    gtm.pushEvent({
      event: 'ROSTER_TAB_CHANGE',
      category: gtm.categories.ROSTER,
      label: tab,
    });
    dispatch({ type: ROUTE_ADVANCEMENT, payload: { hash: tab } });
  };
};

export const ROUTE_QE_HEALTH = `${advancementModuleName}/ROUTE_QE_HEALTH`;
export const navigateToQuickEntryHealth = () => ({ type: ROUTE_QE_HEALTH });

export const ROUTE_QE_RANK = `${advancementModuleName}/ROUTE_QE_RANK`;
export const navigateToQuickEntryRank = ({ unitGuid }) => ({
  type: ROUTE_QE_RANK,
  payload: { unitGuid },
});

export const ROUTE_QE_SWIMMING = `${advancementModuleName}/ROUTE_QE_SWIMMING`;
export const navigateToQuickEntrySwimming = () => ({ type: ROUTE_QE_SWIMMING });

export const ROUTE_QE_SCHOOL = `${advancementModuleName}/ROUTE_QE_SCHOOL`;
export const navigateToQuickEntrySchool = () => ({ type: ROUTE_QE_SCHOOL });

export const ROUTE_QE_SCOUTLEADERSHIP = `${advancementModuleName}/ROUTE_QE_SCOUTLEADERSHIP`;
export const navigateToQuickEntryScoutLeadership = () => ({
  type: ROUTE_QE_SCOUTLEADERSHIP,
});

export const ROUTE_QE_OA = `${advancementModuleName}/ROUTE_QE_OA`;
export const navigateToQuickEntryOA = () => ({ type: ROUTE_QE_OA });

export const ROUTE_ACTIVITIES = `${advancementModuleName}/ROUTE_ACTIVITIES`;
export const navigateToActivities = () => ({ type: ROUTE_ACTIVITIES });

export const ROUTE_REPORTS = `${reportsModuleName}/ROUTE_REPORTS`;
export const navigateToReports = () => ({ type: ROUTE_REPORTS });

export const ROUTE_PURCHASE_ORDER_DETAILS = `${advancementModuleName}/ROUTE_PURCHASE_ORDER_DETAILS`;
export const navigateToPurchaseOrderDetails = poid => ({
  type: ROUTE_PURCHASE_ORDER_DETAILS,
  payload: poid,
});

// Calendar

export const ROUTE_CALENDAR = `${calendarModuleName}/ROUTE_CALENDAR`;
export const goToCalendarPage = () => ({
  type: ROUTE_CALENDAR,
  payload: {},
});

export const ROUTE_CALENDAR_ACTIVITY_DETAILS = `${calendarModuleName}/ROUTE_CALENDAR_ACTIVITY_DETAILS`;
export const goToCalendarActivityDetails = ({ activity, id }) => ({
  type: ROUTE_CALENDAR_ACTIVITY_DETAILS,
  payload: {
    activity,
    id,
  },
});

export const ROUTE_EVENTS_EDIT = `${eventsModuleName}/ROUTE_EVENTS_EDIT`;
export const goToEventsEdit = ({ event, id }) => ({
  type: ROUTE_EVENTS_EDIT,
  payload: {
    event,
    id,
  },
});

export const ROUTE_CALENDAR_EDIT_ACTIVITY = `${moduleName}/ROUTE_CALENDAR_EDIT_ACTIVITY`;
export const goToCalendarEditActivity = ({
  activityId,
  activityData,
  clone,
}) => ({
  type: ROUTE_CALENDAR_EDIT_ACTIVITY,
  payload: {
    activityId,
    activityData,
  },
  query: clone ? { clone: true } : {},
});

export const DELETE_EVENTS_REQ = `${moduleName}/DELETE_EVENTS_REQ`;
export const deleteEventReq = eventId => ({
  type: DELETE_EVENTS_REQ,
  payload: eventId,
});

export const DELETE_RECURRENT_EVENTS_REQ = `${moduleName}/DELETE_RECURRENT_EVENTS_REQ`;
export const deleteRecurrentEventReq = eventId => ({
  type: DELETE_RECURRENT_EVENTS_REQ,
  payload: eventId,
});

export const DELETE_EVENTS_ERROR = `${moduleName}/DELETE_EVENTS_ERROR`;
export const deleteEventsError = payload => ({
  type: DELETE_EVENTS_ERROR,
  payload,
});

export const TRIGGER_CALENDAR_REQUESTS = `${moduleName}/TRIGGER_CALENDAR_REQUESTS`;
export const triggerCalendarRequest = payload => ({
  type: TRIGGER_CALENDAR_REQUESTS,
  payload,
});

export const TRIGGER_ALL_ROSTERS_REQUESTS = `${moduleName}/TRIGGER_ALL_ROSTERS_REQUESTS`;
export const triggerAllRostersRequest = payload => ({
  type: TRIGGER_ALL_ROSTERS_REQUESTS,
  payload,
});

// user profile
export const ROUTE_PROFILE = `${moduleName}/ROUTE_PROFILE`;

export const ROUTE_OWN_ACTIVITY_LOGS = `${adultProfileModuleName}/ROUTE_OWN_ACTIVITY_LOGS`;
export const goToOwnActivityLogs = ({ activityType }) => ({
  type: ROUTE_OWN_ACTIVITY_LOGS,
  payload: { activityType },
});

export const SET_SHOW_DELETE_EMAIL_MODAL = `${moduleName}/SET_SHOW_DELETE_EMAIL_MODAL`;
export const setShowDeleteEmailModal = showModal => ({
  type: SET_SHOW_DELETE_EMAIL_MODAL,
  payload: showModal,
});

export const SET_SHOW_DELETE_ADDRESS_MODAL = `${moduleName}/SET_SHOW_DELETE_ADDRESS_MODAL`;
export const setShowDeleteAddressModal = showModal => ({
  type: SET_SHOW_DELETE_ADDRESS_MODAL,
  payload: showModal,
});

export const SET_SHOW_DELETE_PHONE_MODAL = `${moduleName}/SET_SHOW_DELETE_PHONE_MODAL`;
export const setShowDeletePhoneModal = showModal => ({
  type: SET_SHOW_DELETE_PHONE_MODAL,
  payload: showModal,
});

export const SET_IS_LOADING_DELETE_EMAIL_MODAL = `${moduleName}/SET_IS_LOADING_DELETE_EMAIL_MODAL`;
export const setIsLoadingDeleteEmailModal = isLoading => ({
  type: SET_IS_LOADING_DELETE_EMAIL_MODAL,
  payload: isLoading,
});

// Person profile used to merge adults and youths into a single url
export const ROUTE_PERSON_PROFILE = `${personProfileModuleName}/ROUTE_PERSON_PROFILE`;
export const goToPersonProfile = userId => ({
  type: ROUTE_PERSON_PROFILE,
  payload: { userId },
});

export const ROUTE_PERSON_ACTIVITY_LOGS = `${personProfileModuleName}/ROUTE_PERSON_ACTIVITY_LOGS`;
export const goToPersonActivityLogs = ({ userId, activityType }) => ({
  type: ROUTE_PERSON_ACTIVITY_LOGS,
  payload: { userId, activityType },
});

// Common Profile

export const MEMBER_DETAILS_REFRESH = `${moduleName}/MEMBER_DETAILS_REFRESH`;
export const memberDetailsRefresh = () => ({
  type: MEMBER_DETAILS_REFRESH,
});

export const SET_SHOULD_MEMBER_DETAILS_RELOAD = `${moduleName}/SET_SHOULD_MEMBER_DETAILS_RELOAD`;
export const setShouldMemberDetailsReload = shouldMemberDetailsReload => ({
  type: SET_SHOULD_MEMBER_DETAILS_RELOAD,
  payload: shouldMemberDetailsReload,
});

// Adult Profile

export const ROUTE_ADULT_PROFILE = `${adultProfileModuleName}/ROUTE_ADULT_PROFILE`;
export const goToAdultProfile = userId => ({
  type: ROUTE_ADULT_PROFILE,
  payload: { userId },
});

export const ROUTE_ADULT_ACTIVITY_LOGS = `${adultProfileModuleName}/ROUTE_ADULT_ACTIVITY_LOGS`;
export const goToAdultActivityLogs = ({ userId, activityType }) => ({
  type: ROUTE_ADULT_ACTIVITY_LOGS,
  payload: { userId, activityType },
});

// Youth Profile

export const ROUTE_READ_ONLY_YOUTH_PROFILE = `${youthProfileModuleName}/ROUTE_READ_ONLY_YOUTH_PROFILE`;
export const goToReadOnlyYouthProfile = userId => ({
  type: ROUTE_READ_ONLY_YOUTH_PROFILE,
  payload: { userId },
});

export const ROUTE_UNREGISTERED_YOUTH_PROFILE = `${youthProfileModuleName}/ROUTE_ROUTE_UNREGISTERED_YOUTH_PROFILE_YOUTH_PROFILE`;
export const goToUnregisteredYouthProfile = userId => (dispatch, getState) => {
  if (isYouthMemberSel(getState())) {
    return dispatch(goToReadOnlyYouthProfile());
  }
  dispatch({
    type: ROUTE_UNREGISTERED_YOUTH_PROFILE,
    payload: { userId },
  });
};

export const ROUTE_YOUTH_ADV_REQUIREMENTS = `${youthProfileModuleName}/ROUTE_YOUTH_ADV_REQUIREMENTS`;
export const goToYouthAdvRequirements = ({
  userId,
  advancementType,
  advancementId,
  userAwardId,
}) => ({
  type: ROUTE_YOUTH_ADV_REQUIREMENTS,
  payload: { userId, advancementType, advancementId, userAwardId },
});

export const ROUTE_READ_ONLY_YOUTH_ACTIVITY_LOGS = `${youthProfileModuleName}/ROUTE_READ_ONLY_YOUTH_ACTIVITY_LOGS`;
export const goToReadOnlyYouthActivityLogs = ({ activityType }) => ({
  type: ROUTE_READ_ONLY_YOUTH_ACTIVITY_LOGS,
  payload: {
    activityType,
  },
});

export const ROUTE_YOUTH_ACTIVITY_LOGS = `${youthProfileModuleName}/ROUTE_YOUTH_ACTIVITY_LOGS`;
export const goToActivityLogs =
  ({ userId, activityType }) =>
  (dispatch, getState) => {
    if (isYouthMemberSel(getState())) {
      return dispatch(goToReadOnlyYouthActivityLogs({ activityType }));
    }
    dispatch({
      type: ROUTE_YOUTH_ACTIVITY_LOGS,
      payload: { userId, activityType },
    });
  };

// Advancements Actions
export const SET_SELECTED_ROSTER_KEYS = `${advancementModuleName}/SET_SELECTED_ROSTER_KEYS`;
export const setSelectedRosterKeys = keys => ({
  type: SET_SELECTED_ROSTER_KEYS,
  payload: keys,
});

export const LOGIN_RESPONSE = `${userModuleName}/LOGIN_RESPONSE`;
export const loginResponse = userInfo => ({
  type: LOGIN_RESPONSE,
  payload: userInfo,
});

export const SELFSESSION_RESPONSE = `${userModuleName}/SELFSESSION_RESPONSE`;
export const selfsessionResponse = loginData => ({
  type: SELFSESSION_RESPONSE,
  payload: loginData,
  gtm: {
    category: gtm.categories.USER,
  },
});

export const MASQUERADE_RESPONSE = `${userModuleName}/MASQUERADE_RESPONSE`;
export const masqueradeResponse = (loginData, editWhileMasquerading) => ({
  type: MASQUERADE_RESPONSE,
  payload: loginData,
  editWhileMasquerading,
  gtm: {
    category: gtm.categories.USER,
  },
});

export const LOGOUT = `${userModuleName}/LOGOUT`;
export const logout = (msg, extra = {}) => ({
  type: LOGOUT,
  payload: msg,
  gtm: { category: gtm.categories.USER },
  meta: extra,
});

export const CONTINUE_LOGIN = `${userModuleName}/CONTINUE_LOGIN`;
export const continueLogin = ({ userData, canMasquerade }) => ({
  type: CONTINUE_LOGIN,
  payload: { userData, canMasquerade },
});

// core
export const APP_BOOTSTRAP_DONE = `${coreModuleName}/APP_BOOTSTRAP_DONE`;
export const appBootstrapDone = () => ({ type: APP_BOOTSTRAP_DONE });

export const MODAL_OPEN = `${coreModuleName}/MODAL_OPEN`;
export const MODAL_CLOSE = `${coreModuleName}/MODAL_CLOSE`;
export const modalOpen = () => ({ type: MODAL_OPEN });
export const modalClose = () => ({ type: MODAL_CLOSE });

export const SET_TABLE_PAGE_SIZE = `${moduleName}/SET_TABLE_PAGE_SIZE`;
export const setTablePageSize = (size, personGuid, skipGtm) => dispatch => {
  const pageSize = size;

  storeUserTableConfig({ pageSize }, personGuid);
  dispatch({
    type: SET_TABLE_PAGE_SIZE,
    payload: pageSize,
    gtm: skipGtm
      ? null
      : {
          category: gtm.categories.SETTINGS,
          label: pageSize,
          value: +pageSize,
        },
  });
};
export const ADVANCEMENT_HISTORY_RESPONSE = `${advancementHistoryModuleNamespace}/ADVANCEMENT_HISTORY_RESPONSE`;
export const advancementHistoryResponse = history => ({
  type: ADVANCEMENT_HISTORY_RESPONSE,
  payload: history,
});
export const ADVANCEMENT_HISTORY_ERROR = `${advancementHistoryModuleNamespace}/ADVANCEMENT_HISTORY_ERROR`;
export const advancementHistoryError = error => ({
  type: ADVANCEMENT_HISTORY_ERROR,
  payload: error,
});
export const ADVANCEMENT_HISTORY_REQUEST_IF_NOT_LOADED = `${advancementHistoryModuleNamespace}/ADVANCEMENT_HISTORY_REQUEST_IF_NOT_LOADED`;
export const advancementHistoryRequestIfNotLoaded = () => ({
  type: ADVANCEMENT_HISTORY_REQUEST_IF_NOT_LOADED,
});
export const ADVANCEMENT_HISTORY_ALREADY_LOADED = `${advancementHistoryModuleNamespace}/ADVANCEMENT_HISTORY_ALREADY_LOADED`;
export const advancementHistoryAlreadyLoaded = () => ({
  type: ADVANCEMENT_HISTORY_ALREADY_LOADED,
});

// user preferences
export const SET_USER_PREFERENCES = `${moduleName}/SET_USER_PREFERENCES`;
const _setUserPreferences = (preferences, featureName) => ({
  type: SET_USER_PREFERENCES,
  payload: { preferences, featureName },
});
export const setUserPreferences =
  (preferences, featureName) => (dispatch, getState) => {
    const personGuid = personGuidSel(getState());
    userPreferencesServices.storePreferences({
      personGuid,
      preferences,
      featureName,
    });
    dispatch(_setUserPreferences(preferences, featureName));
  };

// offline
export const SET_OFFLINE = `${offlineModuleName}/SET_OFFLINE`;
export const setOffline = isOffline => ({
  type: SET_OFFLINE,
  payload: isOffline,
});
export const SET_MANUAL_OFFLINE = `${offlineModuleName}/SET_MANUAL_OFFLINE`;
export const setManualOffline = isOffline => {
  window.sblOffline = isOffline;
  return {
    type: SET_MANUAL_OFFLINE,
    payload: isOffline,
  };
};
export const SET_PENDING_REQUESTS = `${offlineModuleName}/SET_PENDING_REQUESTS`;
export const setPendingRequests = pendingRequests => ({
  type: SET_PENDING_REQUESTS,
  payload: pendingRequests,
});
export const SET_LAST_ONLINE = `${offlineModuleName}/SET_LAST_ONLINE`;
export const setLastOnline = date => dispatch => {
  storeLastOnlineDate(date);
  dispatch({ type: SET_LAST_ONLINE, payload: date });
};
export const UPDATE_REQUESTS = `${moduleName}/UPDATE_REQUESTS`;
export const updateRequests = () => ({ type: UPDATE_REQUESTS });

// context
export const SET_ORGANIZATION = `${contextModuleName}/SET_ORGANIZATION`;
export const setOrganization = ({
  guid,
  parentOrgGuid,
  programType,
  skipGtm,
}) => ({
  type: SET_ORGANIZATION,
  payload: { guid, parentOrgGuid },
  gtm: skipGtm
    ? null
    : {
        category: gtm.categories.CONTEXT,
        label: programType,
      },
});

//councilUnits

export const SET_UNIT = `${councilUnitsModuleName}/SET_UNIT`;
export const setUnit = unit => (dispatch, getState) => {
  const parentOrgGuid = parentOrgGuidSel(getState());
  if (unit) {
    const { organizationGuid, programType } = unit;
    dispatch(
      setOrganization({ guid: organizationGuid, programType, parentOrgGuid }),
    );
    dispatch({
      type: SET_UNIT,
      payload: unit,
    });
    dispatch(navigateToRoster());
  }
};

export const SET_RECENT_UNITS = `${councilUnitsModuleName}/SET_RECENT_UNITS`;
export const setRecentUnits = recentUnits => ({
  type: SET_RECENT_UNITS,
  payload: recentUnits,
});

export const setRecentUnit = unit => (dispatch, getState) => {
  const state = getState();
  const personGuid = personGuidSel(state);
  const prevUnits = recentUnitsSel(state).filter(
    ({ organizationGuid }) => organizationGuid !== unit.organizationGuid,
  );
  const searchDate = new Date().toISOString();
  const newUnits = [{ ...unit, searchDate }, ...prevUnits].splice(
    0,
    MAX_RECENT_UNITS,
  );

  storeRecentUnit(unit, personGuid);
  storeUserRecentUnits(newUnits, personGuid);
  dispatch(setUnit(unit));
  dispatch(setRecentUnits(newUnits));
};

export const removeRecentUnit = organizationGuid => (dispatch, getState) => {
  const state = getState();
  const personGuid = personGuidSel(state);

  const units = recentUnitsSel(state).filter(
    unit => unit.organizationGuid !== organizationGuid,
  );
  storeUserRecentUnits(units, personGuid);
  dispatch(setRecentUnits(units));
};

// parentGuardian
export const FETCH_RELATED_CHILREN = `${parentGuardianModuleName}/FETCH_RELATED_CHILDREN`;
export const fetchRelatedChildren = payload => ({
  type: FETCH_RELATED_CHILREN,
  payload,
});

export const CASS_REQUEST = `${moduleName}/CASS_REQUEST`;
export const fetchCASSRequest = line1 => ({
  type: CASS_REQUEST,
  payload: line1,
});

export const CASS_RESPONSE = `${moduleName}/CASS_RESPONSE`;
export const fetchCASSResponse = payload => ({
  type: CASS_RESPONSE,
  payload,
});

// bio
export const SAVE_PROFILE_BIO_REQUEST = `${moduleName}/SAVE_PROFILE_BIO_REQUEST`;
export const saveProfileBioRequest = (profile, personGuid) => ({
  type: SAVE_PROFILE_BIO_REQUEST,
  payload: { profile, personGuid },
  gtm: {
    category: gtm.categories.PROFILE,
  },
});

export const DELETE_PROFILE_BIO_REQUEST = `${moduleName}/DELETE_PROFILE_BIO_REQUEST`;
export const deleteProfileBioRequest = (bio, personGuid) => ({
  type: DELETE_PROFILE_BIO_REQUEST,
  payload: { bio, personGuid },
  gtm: {
    category: gtm.categories.PROFILE,
  },
});

// permissions manager
export const ROUTE_PERMISSIONS_MANAGER = `${permissionsManagerModuleName}/ROUTE_PERMISSIONS_MANAGER`;
export const goToPermissionsManager = () => ({
  type: ROUTE_PERMISSIONS_MANAGER,
});

export const ROUTE_MESSAGING = `${messagingModuleName}/ROUTE_MESSAGING`;
