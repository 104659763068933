import React from 'react';

import cn from 'classnames';

import PersonTag from '@modules/shared/components/PersonTag';
import type { AdvancementStatus as AdvStatusType } from '@modules/shared/constants';
import {
  AdvancementStatus,
  FlexSpacer,
  PersonAvatar,
  devMockHighlightClassName,
  personTagType,
} from '@shared';

import styles from './PersonItem.less';

interface PersonItemProps {
  personName: React.ReactNode;
  isAdult?: boolean;
  isLeader?: boolean;
  isParent?: boolean;
  isParticipant?: boolean;
  className?: string;
  children?: React.ReactNode;
  color?: 'success' | 'warn' | 'info' | 'error';
  transparent?: boolean;
  simpleAvatarMobile?: boolean;
  childrenClassName?: string;
  advancementStatus?: AdvStatusType;
  showAdvStatus?: boolean;
}

const PersonItem: React.FC<PersonItemProps> = ({
  personName,
  isAdult = false,
  isLeader = false,
  isParent = false,
  isParticipant = false,
  className,
  children,
  color,
  transparent,
  childrenClassName,
  advancementStatus,
  simpleAvatarMobile = true,
  showAdvStatus = false,
  ...rest
}) => {
  const classNames = cn(
    styles.wrapper,
    color && styles[color],
    {
      [styles.transparent]: transparent,
      [styles.simpleAvatarMobile]: simpleAvatarMobile,
    },
    className,
  );
  const showLeaderTag = isLeader && !isParticipant;
  const shouldShowAdvStatus = showAdvStatus && !!advancementStatus;

  return (
    <div className={classNames} {...rest}>
      <PersonAvatar
        className={cn(styles.avatar, devMockHighlightClassName)}
        isAdult={isAdult && !isParticipant}
      />
      <div data-isadult={isAdult} className={styles.circle} />
      <div>
        <div className={styles.name}>{personName}</div>
        {isAdult && (
          <div className={styles.tags}>
            {showLeaderTag && (
              <PersonTag size="5" personType={personTagType.LEADER} />
            )}
            {isParent && (
              <PersonTag size="5" personType={personTagType.PARENT} />
            )}
          </div>
        )}
      </div>
      {shouldShowAdvStatus && (
        <AdvancementStatus status={advancementStatus} icon text={false} />
      )}
      <FlexSpacer />
      {children && <div className={childrenClassName}>{children}</div>}
    </div>
  );
};

export default PersonItem;
