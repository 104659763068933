const serviceWorkersSupported = 'serviceWorker' in navigator;
let serviceWorkerActivated = false;

const checkController = () => {
  const ctrl = navigator.serviceWorker.controller;
  if (ctrl && ctrl.state === 'activated') {
    serviceWorkerActivated = true;
  }
  return serviceWorkerActivated;
};

export default () => {
  if (serviceWorkersSupported) {
    return serviceWorkerActivated || checkController();
  }

  return false;
};
