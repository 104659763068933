import { combineReducers } from 'redux';

import { advancementStatuses } from '@shared';
import { resetOnLogoutReducer, resetOnActionsReducer } from '@utils';
import {
  DESELECT_PERSON,
  CLOSE_APPROVE_ADVANCEMENTS,
  APPROVE_ADVANCEMENTS_RESPONSE,
} from '../../common';
import {
  SET_ADVANCEMENT_TYPE,
  SET_ADVANCEMENT,
  RESET_ADVANCEMENT,
  SET_COMPLETION_DATE,
  SET_ADDITIONAL_COMPLETION_DATE,
  SET_ADDITIONAL_ADVANCEMENT_TYPE,
  SET_ADDITIONAL_ADVANCEMENT,
  RESET_ADDITIONAL_ADVANCEMENT,
  OPEN_RECORD_ADVANCEMENT,
  CLOSE_RECORD_ADVANCEMENT,
  RECORD_ADVANCEMENT_REQUEST,
  RECORD_ADVANCEMENT_RESPONSE,
  RECORD_ADVANCEMENT_DEFERRED,
  RECORD_ADVANCEMENT_ERROR,
  VERIFY_ADVANCEMENT_REQUEST,
  VERIFY_ADVANCEMENT_RESPONSE,
  VERIFY_ADVANCEMENT_ERROR,
  VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST,
  VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE,
  VERIFY_ADDITIONAL_ADVANCEMENT_ERROR,
  SET_FORM_TAB,
  ADD_SUGGESTED,
  ADD_ADDITIONAL_SUGGESTED,
  SET_ADVANCEMENT_STATUS,
  SET_ADDITIONAL_ADVANCEMENT_STATUS,
} from './actions';

const visible = (state = false, { type }) => {
  switch (type) {
    case OPEN_RECORD_ADVANCEMENT: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case RECORD_ADVANCEMENT_REQUEST: {
      return true;
    }
    case RECORD_ADVANCEMENT_RESPONSE:
    case RECORD_ADVANCEMENT_DEFERRED:
    case RECORD_ADVANCEMENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const error = (state = null, { type }) => {
  switch (type) {
    case RECORD_ADVANCEMENT_REQUEST:
    case RECORD_ADVANCEMENT_RESPONSE: {
      return null;
    }
    case RECORD_ADVANCEMENT_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const verifyTimeoutError = (state = false, { type, payload }) => {
  switch (type) {
    case VERIFY_ADVANCEMENT_REQUEST:
    case SET_ADVANCEMENT_TYPE:
    case SET_ADVANCEMENT:
    case RESET_ADVANCEMENT: {
      return false;
    }
    case VERIFY_ADVANCEMENT_ERROR: {
      if (payload.status === 408) {
        return true;
      }
      return false;
    }
    default: {
      return state;
    }
  }
};

const additionalVerifyTimeoutError = (state = false, { type, payload }) => {
  switch (type) {
    case VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST:
    case SET_ADDITIONAL_ADVANCEMENT_TYPE:
    case SET_ADDITIONAL_ADVANCEMENT:
    case RESET_ADDITIONAL_ADVANCEMENT: {
      return false;
    }
    case VERIFY_ADDITIONAL_ADVANCEMENT_ERROR: {
      if (payload.status === 408) {
        return true;
      }
      return false;
    }
    default: {
      return state;
    }
  }
};

const userIds = (state = [], { type, payload }) => {
  switch (type) {
    case OPEN_RECORD_ADVANCEMENT: {
      return payload || [];
    }
    case DESELECT_PERSON: {
      return state.filter(userId => userId != payload);
    }
    default: {
      return state;
    }
  }
};

const completionDate = (state = null, { type, payload }) => {
  switch (type) {
    case SET_COMPLETION_DATE: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const additionalCompletionDate = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ADDITIONAL_COMPLETION_DATE: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const advancementType = (state = '', { type, payload }) => {
  switch (type) {
    case SET_ADVANCEMENT_TYPE: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT: {
      return '';
    }
    case RESET_ADVANCEMENT: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const additionalAdvancementType = (state = '', { type, payload }) => {
  switch (type) {
    case SET_ADDITIONAL_ADVANCEMENT_TYPE: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT: {
      return '';
    }
    case RESET_ADDITIONAL_ADVANCEMENT: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const advancementId = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ADVANCEMENT: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT: {
      return null;
    }
    case RESET_ADVANCEMENT: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const additionalAdvancementId = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ADDITIONAL_ADVANCEMENT: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT: {
      return null;
    }
    case RESET_ADDITIONAL_ADVANCEMENT: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const advancementVerificationLoading = (state = false, { type, payload }) => {
  switch (type) {
    case SET_ADVANCEMENT: {
      return !!payload;
    }
    case VERIFY_ADVANCEMENT_REQUEST: {
      return true;
    }
    case VERIFY_ADVANCEMENT_RESPONSE:
    case VERIFY_ADVANCEMENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const advancementVerification = (state = [], { type, payload }) => {
  switch (type) {
    case SET_ADVANCEMENT_TYPE:
    case SET_ADVANCEMENT:
    case RECORD_ADVANCEMENT_RESPONSE:
    case VERIFY_ADVANCEMENT_REQUEST:
    case VERIFY_ADVANCEMENT_ERROR: {
      return [];
    }
    case VERIFY_ADVANCEMENT_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const additionalAdvancementVerificationLoading = (
  state = false,
  { type, payload },
) => {
  switch (type) {
    case SET_ADDITIONAL_ADVANCEMENT: {
      return !!payload;
    }
    case VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST: {
      return true;
    }
    case VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE:
    case VERIFY_ADDITIONAL_ADVANCEMENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const additionalAdvancementVerification = (state = [], { type, payload }) => {
  switch (type) {
    case SET_ADDITIONAL_ADVANCEMENT_TYPE:
    case SET_ADDITIONAL_ADVANCEMENT:
    case RECORD_ADVANCEMENT_RESPONSE:
    case VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST:
    case VERIFY_ADDITIONAL_ADVANCEMENT_ERROR: {
      return [];
    }
    case VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const formTab = (state = '', { type, payload }) => {
  switch (type) {
    case SET_FORM_TAB: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedSuggestedYouthIds = (state = [], { type, payload }) => {
  switch (type) {
    case RECORD_ADVANCEMENT_RESPONSE:
    case SET_ADVANCEMENT_TYPE:
    case SET_ADVANCEMENT: {
      return [];
    }
    case ADD_SUGGESTED: {
      return [...state, payload];
    }
    case DESELECT_PERSON: {
      return state.filter(userId => userId != payload);
    }
    default: {
      return state;
    }
  }
};
const selectedAdditionalSuggestedYouthIds = (state = [], { type, payload }) => {
  switch (type) {
    case RECORD_ADVANCEMENT_RESPONSE:
    case SET_ADDITIONAL_ADVANCEMENT_TYPE:
    case SET_ADDITIONAL_ADVANCEMENT: {
      return [];
    }
    case ADD_ADDITIONAL_SUGGESTED: {
      return [...state, payload];
    }
    case DESELECT_PERSON: {
      return state.filter(userId => userId != payload);
    }
    default: {
      return state;
    }
  }
};

const defaultAdvancementStatus = advancementStatuses.APPROVED;
const selectedAdvancementStatus = (
  state = defaultAdvancementStatus,
  { type, payload },
) => {
  switch (type) {
    case SET_ADVANCEMENT_STATUS: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT:
    case RESET_ADVANCEMENT: {
      return defaultAdvancementStatus;
    }
    default: {
      return state;
    }
  }
};

const selectedAdditionalAdvancementStatus = (
  state = defaultAdvancementStatus,
  { type, payload },
) => {
  switch (type) {
    case SET_ADDITIONAL_ADVANCEMENT_STATUS: {
      return payload;
    }
    case OPEN_RECORD_ADVANCEMENT:
    case RESET_ADDITIONAL_ADVANCEMENT: {
      return defaultAdvancementStatus;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  visible,
  loading,
  error,
  verifyTimeoutError,
  additionalVerifyTimeoutError,
  userIds,
  completionDate,
  advancementType,
  advancementId,
  additionalCompletionDate,
  additionalAdvancementType,
  additionalAdvancementId,
  advancementVerificationLoading,
  advancementVerification,
  additionalAdvancementVerificationLoading,
  additionalAdvancementVerification,
  formTab,
  selectedSuggestedYouthIds,
  selectedAdditionalSuggestedYouthIds,
  selectedAdvancementStatus,
  selectedAdditionalAdvancementStatus,
});

export default resetOnActionsReducer(
  resetOnLogoutReducer(reducer),
  CLOSE_RECORD_ADVANCEMENT,
  CLOSE_APPROVE_ADVANCEMENTS,
  APPROVE_ADVANCEMENTS_RESPONSE,
);
