import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FilterButton, TableMobile, sorterPropTypes } from '@shared';
import AdvancementHistoryFilterCard from '../../AdvancementHistoryFilterCard';
import styles from './AdvancementHistoryListHeader.less';

const { StickyTitle } = TableMobile;

class AdvancementHistoryListHeader extends React.PureComponent {
  renderFilterButton = () => <FilterButton id="qa_filters" />;

  render() {
    const { itemsLength, searchPinned, sorter, onSorterChange } = this.props;

    return (
      <StickyTitle key="header" clearSearch={searchPinned}>
        <div>
          <FormattedMessage
            id="advancement.AdvancementHistory.historyRecorded"
            values={{
              count: (
                <span className={styles.rowHeaderNumber}>{itemsLength} </span>
              ),
            }}
          />
        </div>
        <AdvancementHistoryFilterCard
          sorter={sorter}
          onSorterChange={onSorterChange}
        >
          {this.renderFilterButton}
        </AdvancementHistoryFilterCard>
      </StickyTitle>
    );
  }
}

AdvancementHistoryListHeader.propTypes = {
  itemsLength: PropTypes.number.isRequired,
  searchPinned: PropTypes.bool.isRequired,
  sorter: sorterPropTypes,
  onSorterChange: PropTypes.func.isRequired,
};

export default AdvancementHistoryListHeader;
