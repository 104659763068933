import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { ConfirmationModal, S, intl } from '@shared';
import {
  removeParticipant,
  showRemoveParticipantModal,
  showRemoveParticipantModalSel,
  removingParticipantSel,
} from '../../../duck';
import styles from './RemoveParticipantModal.less';

const RemoveParticipantModal = ({
  advancement,
  onRemoveSuccess,
  titleText,
  bodyText,
  buttonText,
}) => {
  const {
    activityId,
    activityRecords,
    activityName,
    advancementName,
    name: personName,
    userId,
    isPersonalActivity,
  } = advancement;
  const isModalVisible = useSelector(showRemoveParticipantModalSel);
  const removingParticipant = useSelector(removingParticipantSel);
  const dispatch = useDispatch();

  const handleOnCancel = useCallback(
    () => dispatch(showRemoveParticipantModal(false)),
    [dispatch],
  );
  const handleRemoveParticipant = useCallback(
    () =>
      dispatch(
        removeParticipant({
          activityId,
          activityRecords,
          userId,
          isPersonalActivity,
          onRemoveSuccess,
        }),
      ),
    [
      dispatch,
      activityId,
      activityRecords,
      userId,
      isPersonalActivity,
      onRemoveSuccess,
    ],
  );

  return (
    <ConfirmationModal
      onCancel={handleOnCancel}
      visible={isModalVisible}
      title={intl.formatMessage({
        id: titleText,
      })}
      onConfirm={handleRemoveParticipant}
      primaryButtonProps={{
        color: 'error',
        loading: removingParticipant,
        children: <FormattedMessage id={buttonText} />,
      }}
    >
      <S size="4" className={styles.question}>
        <FormattedHTMLMessage
          id={bodyText}
          values={{
            person: personName,
            activityType: advancementName,
            activityName: activityName,
          }}
        />
      </S>
    </ConfirmationModal>
  );
};

RemoveParticipantModal.propTypes = {
  advancement: PropTypes.object.isRequired,
  fromActivityLogs: PropTypes.bool,
  onRemoveSuccess: PropTypes.func,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default RemoveParticipantModal;
