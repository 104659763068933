import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { S } from '@shared';
import { historyItemsTypes } from '../../../constants';
import styles from './HistoryItemName.less';

const noop = () => {};

const HistoryItemName = ({ type, name, onGoToProfilePage, isOffline }) => {
  const isFile = type === historyItemsTypes.ADVANCEMENT_FILE;

  return (
    <S
      size="4"
      bold
      inline
      colored={!isFile}
      className={cn({
        [styles.filename]: isFile,
        [styles.youthName]: !isFile && !isOffline,
      })}
      title={name}
      onClick={onGoToProfilePage}
    >
      {name}
    </S>
  );
};

HistoryItemName.propTypes = {
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  isOffline: PropTypes.bool,
  onGoToProfilePage: PropTypes.func,
};

HistoryItemName.defaultProps = {
  onGoToProfilePage: noop,
};

export default HistoryItemName;
