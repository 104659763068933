import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { PREVENT_STORE_SB_PREFERENCE, SCOUTBOOK_LEGACY_URL } from '@config';
import { isScreenXsSel } from '@screen';
import {
  Button,
  Card,
  FlexSpacer,
  Popconfirm,
  Spin,
  T,
} from '@shared/components';
import { navigateToRoster } from '@shared/duck';

import FleurDeLisIcon from '../../../../assets/images/FleurDeLis.svg';
import { navigateToScoutbookLegacy } from '../../../scoutbookLegacy';
import { DEFAULT_APPS } from '../../constants';
import { redirectingSel, setDefaultApp } from '../../duck';
import styles from './ChooseScoutbookPage.less';

class ChooseScoutbookPage extends React.PureComponent {
  handleLegacyScoutbookClick = () => {
    window.location.href = SCOUTBOOK_LEGACY_URL;
    // use this if legacy SB needs to be iframed inside SBL:
    // this.props.onNavigateToScoutbookLegacy();
  };

  handleNewScoutbookClick = () => {
    this.props.onNavigateToRoster();
  };

  withSaveDefaultApp = (node, action, app) => {
    const okAction = () => {
      this.props.onSetDefaultApp(app);
      action();
    };
    return (
      <Popconfirm
        overlayClassName={cn(styles.popconfirm, 'qa_rememberDefaultAppModal')}
        title={
          <FormattedMessage id="user.ChooseScoutbookPage.savePreference" />
        }
        onConfirm={okAction}
        onCancel={action}
        okText={<FormattedMessage id="shared.yes" />}
        cancelText={<FormattedMessage id="shared.no" />}
      >
        {node}
      </Popconfirm>
    );
  };

  renderLegacyScoutbookButton = onClick => (
    <Button ghost uppercase={false} className={styles.button} onClick={onClick}>
      <FormattedMessage id="user.ChooseScoutbookPage.scoutbook" />
    </Button>
  );

  renderNewScoutbookButton = onClick => {
    const { isScreenXs } = this.props;
    return (
      <Button
        type="primary"
        color="scouting-blue"
        shadow
        uppercase={false}
        className={styles.button}
        onClick={onClick}
      >
        {isScreenXs ? (
          <FormattedMessage id="user.ChooseScoutbookPage.advancementQuickEntry.singleLine" />
        ) : (
          <FormattedHTMLMessage id="user.ChooseScoutbookPage.advancementQuickEntry.doubleLine" />
        )}
      </Button>
    );
  };

  render() {
    const { isRedirecting } = this.props;

    if (isRedirecting) {
      return <Spin size="large" centeredInViewport />;
    }

    return (
      <div className={styles.bg}>
        <Card className={styles.card} shadow>
          <div className={styles.header}>
            <FleurDeLisIcon className={styles.logoIcon} />
            <T size="2" bold className={styles.title}>
              <FormattedMessage id="user.ChooseScoutbookPage.title" />
            </T>
            <div className={cn(styles.ieFix, styles.welcomeText)}>
              <FormattedMessage id="user.ChooseScoutbookPage.welcomeText" />
            </div>
          </div>
          <div className={styles.options}>
            <div className={styles.option}>
              <T size="3" bold colored className={styles.title}>
                <FormattedMessage id="user.ChooseScoutbookPage.scoutbook" />
              </T>
              <div>
                <FormattedMessage id="user.ChooseScoutbookPage.scoutbookDescription" />
              </div>
              <FlexSpacer />
              {PREVENT_STORE_SB_PREFERENCE
                ? this.withSaveDefaultApp(
                    this.renderLegacyScoutbookButton(),
                    this.handleLegacyScoutbookClick,
                    DEFAULT_APPS.SB_LEGACY,
                  )
                : this.renderLegacyScoutbookButton(
                    this.handleLegacyScoutbookClick,
                  )}
            </div>
            <div className={styles.option}>
              <T size="3" bold colored className={styles.title}>
                <FormattedMessage id="user.ChooseScoutbookPage.advancementQuickEntry.singleLine" />
              </T>
              <div>
                <FormattedMessage id="user.ChooseScoutbookPage.sblDescription" />
              </div>
              {PREVENT_STORE_SB_PREFERENCE
                ? this.withSaveDefaultApp(
                    this.renderNewScoutbookButton(),
                    this.handleNewScoutbookClick,
                    DEFAULT_APPS.SBL,
                  )
                : this.renderNewScoutbookButton(this.handleNewScoutbookClick)}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

ChooseScoutbookPage.propTypes = {
  isScreenXs: PropTypes.bool.isRequired,
  isRedirecting: PropTypes.bool.isRequired,
  onNavigateToRoster: PropTypes.func.isRequired,
  onNavigateToScoutbookLegacy: PropTypes.func.isRequired,
  onSetDefaultApp: PropTypes.func.isRequired,
};

const mapState = state => ({
  isScreenXs: isScreenXsSel(state),
  isRedirecting: redirectingSel(state),
});

const mapDispatch = dispatch => ({
  onNavigateToRoster: () => dispatch(navigateToRoster()),
  onNavigateToScoutbookLegacy: () => dispatch(navigateToScoutbookLegacy()),
  onSetDefaultApp: app => dispatch(setDefaultApp(app)),
});

export default connect(mapState, mapDispatch)(ChooseScoutbookPage);
