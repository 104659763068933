import DenChiefConfirmationPage from './components/DenChiefConfirmationPage';
import { ROUTE_DEN_CHIEF_CONFIRMATION } from './duck/actions';

const routes = {
  [ROUTE_DEN_CHIEF_CONFIRMATION]: {
    path: '/den-chief-confirmation',
    Component: DenChiefConfirmationPage,
    titleId: 'page.confirmation',
  },
};

export default routes;
