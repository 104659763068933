import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

import { services, moduleSel, setScreenMeta } from '../../duck';

class ResizeWatcher extends React.PureComponent {
  componentDidMount() {
    window.removeEventListener('resize', this.updateMeta);
    window.addEventListener('resize', this.updateMeta);
  }

  updateMeta = throttle(() => {
    const { meta } = this.props;
    const newMeta = services.getScreenMeta();

    if (JSON.stringify(meta) !== JSON.stringify(newMeta)) {
      this.props.onSetMeta(newMeta);
    }
  }, 100);

  render() {
    return '';
  }
}

ResizeWatcher.propTypes = {
  meta: PropTypes.object.isRequired,
  onSetMeta: PropTypes.func.isRequired,
};

const mapState = state => ({
  meta: moduleSel(state),
});

const mapDispatch = dispatch => ({
  onSetMeta: meta => dispatch(setScreenMeta(meta)),
});

export default connect(mapState, mapDispatch)(ResizeWatcher);
