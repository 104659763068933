import { createSelector } from 'reselect';
import { moduleName } from '../constants';

const rolesModuleSel = state => state[moduleName];

export const userPermissionsSel = state =>
  rolesModuleSel(state).userPermissions;
export const availableRolesSel = state => rolesModuleSel(state).availableRoles;
export const availableUsersSel = state => rolesModuleSel(state).availableUsers;
export const searchSel = state => rolesModuleSel(state).search;

export const filteredPersonsSel = createSelector(
  availableUsersSel,
  searchSel,
  (persons, search) =>
    persons.filter(({ name }) =>
      name.toUpperCase().includes(search.toUpperCase()),
    ),
);
