import { activityTypeIds } from '@shared';

export { activitiesModuleName as moduleName, detailModes } from '../common';

export const activityLogsItemsLimit = 30;

export const activitiesConfig = Object.freeze({
  [activityTypeIds.SERVICE_PROJECTS]: {
    activityDetails: {
      showCategorySelector: true,
    },
    activityTabs: {
      showNonRegisteredParticipants: true,
    },
  },
});

export const maxDescriptionLength = 1500;
