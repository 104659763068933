import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Col, Row } from 'bsa-ui';
import InfoIcon from 'material-ui-icons/Info';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  activityTypeForActivityTypeId,
  activityTypeIds,
  activityValuesTabs,
} from '../../constants';
import useCalculateDays from '../../hooks/useCalculateDays';
import Form from '../Form';
import Input from '../Input';
import S from '../S';
import styles from './ActivitiesFormModal.less';
import {
  daysLessOrEqualRule,
  getEventLength,
  getSetFormActivityValues,
  positiveNumRule,
  requiredRule,
} from './utils';

const FormItem = Form.Item;

export const GroupTab = ({
  activityData,
  activityTypeId,
  eventDetails,
  form,
  initialTab,
  isActive,
  leaders,
  youths,
}) => {
  const { getFieldDecorator, getFieldsValue, setFieldsValue } = form;
  const diff = useCalculateDays(getFieldsValue(), form);
  const [isInitialized, setIsInitialized] = useState(false);
  const { days, hours, nights } = getEventLength(
    eventDetails.startDate,
    eventDetails.endDate,
  );

  useEffect(() => {
    if (activityData && !isInitialized && (youths.length || leaders.length)) {
      const { registeredAdults = [], registeredYouths = [] } = activityData;
      const youthFieldsToSet = getSetFormActivityValues(
        activityTypeId,
        initialTab,
        activityValuesTabs.BASIC,
        'Youth',
        registeredYouths,
      );
      const adultFieldsToSet = getSetFormActivityValues(
        activityTypeId,
        initialTab,
        activityValuesTabs.BASIC,
        'Adult',
        registeredAdults,
      );
      setFieldsValue({ ...youthFieldsToSet, ...adultFieldsToSet });
      setIsInitialized(true);
    }
  }, [
    activityData,
    activityTypeId,
    youths,
    leaders,
    isInitialized,
    initialTab,
    setFieldsValue,
  ]);

  const getInputs = useCallback(
    (userType, usersLength) => (
      <Fragment>
        {activityTypeId === activityTypeIds.CAMPOUTS && (
          <Row type="flex" gutter={8}>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`days${userType}Basic`, {
                  initialValue: days,
                  rules: isActive
                    ? [
                        requiredRule(),
                        positiveNumRule(),
                        daysLessOrEqualRule(diff + 1, true),
                      ]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Days" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`nights${userType}Basic`, {
                  initialValue: nights,
                  rules: isActive
                    ? [
                        requiredRule(),
                        positiveNumRule(),
                        daysLessOrEqualRule(diff + 0, false),
                      ]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Nights" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`frostPoints${userType}Basic`, {
                  initialValue: '0',
                  rules:
                    isActive && usersLength ? [positiveNumRule()] : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.FrostPoints" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        {activityTypeId === activityTypeIds.HIKES && (
          <Row type="flex" gutter={8}>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`miles${userType}Basic`, {
                  initialValue: '0',
                  rules:
                    isActive && usersLength
                      ? [requiredRule(), positiveNumRule()]
                      : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Miles" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`lowestPoint${userType}Basic`, {
                  initialValue: '0',
                  rules:
                    isActive && usersLength ? [positiveNumRule()] : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.LowestPoint" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`highestPoint${userType}Basic`, {
                  initialValue: '0',
                  rules:
                    isActive && usersLength ? [positiveNumRule()] : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.HighestPoint" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        )}

        {activityTypeId === activityTypeIds.SERVICE_PROJECTS && (
          <Row type="flex" gutter={8}>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`hours${userType}Basic`, {
                  initialValue: hours,
                  rules:
                    isActive && usersLength
                      ? [requiredRule(), positiveNumRule()]
                      : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Hours" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        )}

        {activityTypeId === activityTypeIds.LONG_CRUISE && (
          <Row type="flex" gutter={8}>
            <Col xs={24} lg={8}>
              <FormItem>
                {getFieldDecorator(`days${userType}Basic`, {
                  initialValue: days,
                  rules: isActive
                    ? [
                        requiredRule(),
                        positiveNumRule(),
                        daysLessOrEqualRule(diff + 1, true),
                      ]
                    : undefined,
                })(
                  <Input
                    className={styles.removeSuffix}
                    type="number"
                    min="0"
                    size="large"
                    placeholder={
                      <FormattedMessage id="progress.common.ActivityTabs.inputTitle.Days" />
                    }
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
        )}
      </Fragment>
    ),
    [activityTypeId, days, diff, getFieldDecorator, hours, isActive, nights],
  );

  return (
    <Fragment>
      <Row>
        <Col>
          <div className={styles.tabDescription}>
            <InfoIcon color="info" />
            <S size="5">
              <FormattedMessage
                id={`progress.common.ActivityTabs.${activityTypeForActivityTypeId[activityTypeId]}.basicDescription`}
                values={{
                  tabName: (
                    <b>
                      <FormattedMessage id="progress.common.ActivityTabs.basic" />
                    </b>
                  ),
                }}
              />
            </S>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.tabUsersTitle}>
          <S size={'4'} colored>
            <FormattedMessage id="shared.youths" />({youths.length})
          </S>
        </Col>
        <Col>{getInputs('Youth', youths.length)}</Col>
      </Row>
      <Row>
        <Col className={styles.tabUsersTitle}>
          <S size={'4'} colored>
            <FormattedMessage id="shared.adults" />({leaders.length})
          </S>
        </Col>
        <Col>{getInputs('Adult', leaders.length)}</Col>
      </Row>
    </Fragment>
  );
};

GroupTab.propTypes = {
  youths: PropTypes.array.isRequired,
  leaders: PropTypes.array.isRequired,
  activityTypeId: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  initialTab: PropTypes.string.isRequired,
  activityData: PropTypes.object,
  eventDetails: PropTypes.object,
};
