import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Spin, T, userDataSel } from '@shared';
import { organizationGuidSel } from '@context';
import { FormattedMessage } from 'react-intl';
import CoinIcon from '@assets/images/Coin.svg';
import { isAllowedOrganization } from '../../utils';
import {
  balanceDetailsSel,
  loadingSel,
  getUserPaymentLogsRequest,
  goToYouthPaymentLogs,
} from '../../duck';
import Amount from '../common/Amount';
import styles from './PaymentLogWidget.less';

const PaymentLogWidget = () => {
  const dispatch = useDispatch();
  const { balance = 0 } = useSelector(balanceDetailsSel);
  const userData = useSelector(userDataSel);
  const organizationGuid = useSelector(organizationGuidSel);
  const isAllowed = isAllowedOrganization(userData, organizationGuid);
  const loading = useSelector(loadingSel);

  const onPress = () => {
    dispatch(goToYouthPaymentLogs());
  };

  useEffect(() => {
    dispatch(getUserPaymentLogsRequest());
  }, [dispatch]);

  if (!isAllowed) {
    return null;
  }

  return (
    <Card className={styles.card} margin noPadding shadow>
      <Spin delay={100} size="large" spinning={loading}>
        <T bold colored size="4">
          <FormattedMessage id="paymentLogWidget.title" />
        </T>
        <div className={styles.content}>
          <div className={styles.cointContainer}>
            <CoinIcon className={styles.coin} />
          </div>
          <div className={styles.amountContainer}>
            <T size="3">
              <FormattedMessage id="paymentLogWidget.balance" />
            </T>
            <Amount amount={balance} bold errorIndicator size="1" />
          </div>
          <Button
            color="info"
            onClick={onPress}
            shape="round"
            size="small"
            uppercase
          >
            <FormattedMessage id="paymentLogWidget.viewPaymentLogs" />
          </Button>
        </div>
      </Spin>
    </Card>
  );
};

export default PaymentLogWidget;
