import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { advancementFileStatuses, Tooltip } from '@shared';
import { historyItemsTypes } from '../../../constants';
import HistoryItemStatus from '../HistoryItemStatus';
import styles from './HistoryItemDescription.less';

const trigger = ['click'];
const stopPropagation = e => e.stopPropagation();

const HistoryItemDescription = ({
  type,
  status,
  statusLabel,
  totalRecords,
  recordsImported,
  recordsSkipped,
  children,
  className,
}) => {
  switch (type) {
    case historyItemsTypes.ADVANCEMENT_FILE: {
      let statusLabelNode;
      let statusText;

      if (status === advancementFileStatuses.PROCESSED) {
        const extraStatus = recordsSkipped > 0 ? 'partially' : 'fully';
        status = extraStatus + status;
        statusText = (
          <FormattedMessage
            id="advancementHistory.HistoryItemDescription.importedRecords"
            values={{ imported: recordsImported, total: totalRecords }}
          />
        );
        statusLabelNode = statusText;
      } else {
        statusText = statusLabel;
        statusLabelNode = (
          <HistoryItemStatus type={type} status={statusLabel} />
        );
      }

      return (
        <Tooltip title={statusText} trigger={trigger}>
          <div
            className={cn(
              styles.fileStatus,
              styles[`${status}File`],
              className,
            )}
            onClick={stopPropagation}
          >
            {statusLabelNode}
          </div>
        </Tooltip>
      );
    }
    default: {
      return children;
    }
  }
};

HistoryItemDescription.propTypes = {
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  status: PropTypes.string,
  statusLabel: PropTypes.string,
  totalRecords: PropTypes.number,
  recordsImported: PropTypes.number,
  recordsSkipped: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default HistoryItemDescription;
