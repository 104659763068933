import {
  mbcManageModuleName as moduleName,
  mbcManageModuleNamespace as moduleNamespace,
} from '../common';

export { moduleName, moduleNamespace };

export const mbcFilters = Object.freeze({
  MERIT_BADGES: 'meritBadges',
  PROXIMITY: 'proximity',
  AVAILABILITY: 'availability',
});

export const availabilityFilterOptions = Object.freeze({
  AVAILABLE: 'Available',
  BUSY: 'Busy',
  BOTH: 'Both',
});

export const proximityFilterOptions = Object.freeze({
  MILES_5: 'miles5',
  MILES_10: 'miles10',
  MILES_25: 'miles25',
  MILES_50: 'miles50',
  ANY: 'any',
});

export const defaultFilterOptions = Object.freeze({
  [mbcFilters.MERIT_BADGES]: [],
  [mbcFilters.PROXIMITY]: proximityFilterOptions.ANY,
  [mbcFilters.AVAILABILITY]: availabilityFilterOptions.AVAILABLE,
});
