import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'rxjs-compat';

import smoothScroll from 'smoothscroll-polyfill';

// older version of Edge and IE don't support 'blur' on SVG element
if (typeof SVGElement.prototype.blur == 'undefined') {
  SVGElement.prototype.blur = function () {};
}

smoothScroll.polyfill(); // setups the polyfill if needed
