import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeAchVerificationModal,
  isAchVerificationModalVisible,
} from '../../../duck';
import AchVerificationForm from './AchVerificationForm';
import styles from './AchVerificationModal.less';

const AchVerificationModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isAchVerificationModalVisible);

  const handleCloseAchVerificationModal = () =>
    dispatch(closeAchVerificationModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'achVerification.form.title',
      })}
      onCancel={handleCloseAchVerificationModal}
      onBack={handleCloseAchVerificationModal}
    >
      <AchVerificationForm />
    </Modal>
  );
};

export default AchVerificationModal;
