import partition from 'lodash/partition';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { createSelector } from 'reselect';
import stable from 'stable';

import { ENABLE_VENTURING_RANKS } from '@config';
import { unitTypeIdSel } from '@context';
import { dictionaryIds, dictionarySel } from '@dict';
import {
  ProgramId,
  emptyArr,
  ranksOrder,
  venturingCoreAwardsIds,
} from '@shared';

import {
  LDSAwards,
  disableAwards,
  eagleScoutName,
  oldVersionAward,
} from '../../constants';
import {
  additionalAdvancementTypeSel,
  advancementTypeSel,
  personsRanksSel,
  shouldDisplayTabsSel,
} from './core.selectors';
import { personsHighestBoyScoutRankSel } from './helpers.selectors';

const eaglePalmPinName = 'Eagle Palm';

export const advancementDictionarySel = state => {
  const type = advancementTypeSel(state);

  return dictionarySel(state, type) || emptyArr;
};

export const additionalAdvancementDictionarySel = state => {
  const type = additionalAdvancementTypeSel(state);

  return dictionarySel(state, type) || emptyArr;
};

const isOldVersionAward = ({ id }) => oldVersionAward[id] || false;
const isLSDAward = ({ id }) => LDSAwards[id] || false;
const isDisabledAward = ({ id }) => disableAwards[id] || false;

const filterPalms =
  ({ areAllRanksEagleScout, isBoyScout }) =>
  ({ name }) => {
    const isEaglePalmPin = name.includes(eaglePalmPinName);

    return (
      (isBoyScout && isEaglePalmPin && areAllRanksEagleScout) || !isEaglePalmPin
    );
  };

const sortAlphabetically = ({ name: nameA }, { name: nameB }) =>
  nameA.localeCompare(nameB, undefined, { numeric: true });

const getAwards = ({ validAwards, unitTypeId }) => {
  if (unitTypeId == ProgramId.VENTURING) {
    const [coreAwards, scoutingAwards] = partition(validAwards, ({ id }) =>
      venturingCoreAwardsIds.includes(id),
    );
    return ENABLE_VENTURING_RANKS
      ? stable(scoutingAwards, sortAlphabetically)
      : [coreAwards, stable(scoutingAwards, sortAlphabetically)];
  }
  return stable(validAwards, sortAlphabetically);
};

const sortRanks = ({ ranks, unitTypeId }) => {
  switch (Number(unitTypeId)) {
    case ProgramId.CUB_SCOUT: {
      const ranksSorted = stable(
        ranks,
        ({ level: aLevel }, { level: bLevel }) => aLevel.localeCompare(bLevel),
      );
      return ranksSorted;
    }
    default: {
      return ranks;
    }
  }
};

const getLastRanksEarned = ({ rankNames, validRanks }) =>
  rankNames.map(rankName => validRanks.find(({ name }) => name === rankName));

const getLowerAndUpperRanks = ({ rankLevel, validRanks }) => {
  const [lowerLevelRanks, upperLevelRanks] = partition(
    validRanks,
    ({ level }) => Number(level) <= rankLevel,
  );

  return [
    lowerLevelRanks.filter(({ level }) => level != rankLevel),
    upperLevelRanks,
  ];
};

const getMinimunRankLevel = ({ lastRanksEarned }) => {
  const peopleLevels = lastRanksEarned.map(rank => {
    const rankLevel = Number((rank || {}).level);
    return !rankLevel && rankLevel !== 0 ? -1 : rankLevel;
  });

  return peopleLevels.length > 0 ? Math.min(...peopleLevels) : -1;
};

const getAvailableAdvancements = ({ type, dictionary, ranks, unitTypeId }) => {
  const rankNames = ranks.map(({ rank }) => rank);

  switch (type) {
    case dictionaryIds.ADVENTURES: {
      return stable(
        dictionary,
        ({ rank: rankA, name: nameA }, { rank: rankB, name: nameB }) =>
          rankA === rankB
            ? nameA.localeCompare(nameB)
            : ranksOrder[rankA] > ranksOrder[rankB],
      );
    }
    case dictionaryIds.AWARDS: {
      const areAllRanksEagleScout = rankNames.every(
        rankName => rankName === eagleScoutName,
      );
      const isBoyScout = unitTypeId == ProgramId.BOY_SCOUT;

      const validAwards = dictionary
        .filter(
          item =>
            (rankNames.includes(item.rank) || !item.rank) &&
            (item.unitTypeId == unitTypeId || !item.unitTypeId) &&
            item.adultAward !== 'True' &&
            !isOldVersionAward(item) &&
            !isLSDAward(item) &&
            !isDisabledAward(item),
        )
        .filter(filterPalms({ areAllRanksEagleScout, isBoyScout }));

      return getAwards({ validAwards, unitTypeId });
    }
    case dictionaryIds.MERIT_BADGES: {
      const today = moment();
      return stable(
        dictionary.filter(
          ({ dateDiscontinued }) =>
            !(
              dateDiscontinued && moment(dateDiscontinued).isSameOrBefore(today)
            ),
        ),
        sortAlphabetically,
      );
    }
    case dictionaryIds.RANKS: {
      if (!ENABLE_VENTURING_RANKS) {
        unitTypeId =
          unitTypeId == ProgramId.VENTURING ? ProgramId.BOY_SCOUT : unitTypeId;
      }
      const validRanks = dictionary.filter(
        item => item.programId == unitTypeId,
      );

      const currentProgramRanks = ranks
        .map(item => {
          if (item.programId == null) {
            return {
              ...item,
              programId: unitTypeId,
              level: -1,
            };
          }
          return item;
        })
        .filter(item => item.programId == unitTypeId);
      const currentProgramRankNames = currentProgramRanks.map(
        ({ rank }) => rank,
      );

      const hasDifferentRanks = currentProgramRanks.some(({ level }) =>
        currentProgramRanks.find(
          ({ level: otherRankLevel }) => otherRankLevel != level,
        ),
      );

      if (hasDifferentRanks) {
        return sortRanks({ ranks: validRanks, unitTypeId });
      }

      const lastRanksEarned = getLastRanksEarned({
        rankNames: currentProgramRankNames,
        validRanks,
      });
      const uniqueRanksByLevel = uniqBy(lastRanksEarned, 'level');

      if (
        uniqueRanksByLevel.length === 1 &&
        uniqueRanksByLevel[0] &&
        uniqueRanksByLevel[0].level != null
      ) {
        const sortedValidRanks = sortRanks({ unitTypeId, ranks: validRanks });

        return uniqueRanksByLevel[0].name === eagleScoutName
          ? []
          : getLowerAndUpperRanks({
              rankLevel: Number(uniqueRanksByLevel[0].level),
              validRanks: sortedValidRanks,
            });
      }

      const minimumLevel = getMinimunRankLevel({ lastRanksEarned });

      return sortRanks({
        ranks: validRanks.filter(rank => rank.level > minimumLevel),
        unitTypeId,
      });
    }
    case dictionaryIds.SSELECTIVES: {
      return stable(dictionary, sortAlphabetically);
    }
  }
};

export const availableAdvancementsSel = createSelector(
  advancementTypeSel,
  advancementDictionarySel,
  personsRanksSel,
  unitTypeIdSel,
  (type, dictionary, ranks, unitTypeId) => {
    if (!type || !dictionary || dictionary.length === 0) {
      return [];
    }

    return getAvailableAdvancements({
      type,
      dictionary,
      ranks,
      unitTypeId,
    });
  },
);

export const additionalAvailableAdvancementsSel = createSelector(
  additionalAdvancementTypeSel,
  additionalAdvancementDictionarySel,
  personsHighestBoyScoutRankSel,
  shouldDisplayTabsSel,
  (type, dictionary, personsBoyScoutRanks, shouldDisplayTabs) => {
    if (!shouldDisplayTabs || !type || !dictionary || dictionary.length === 0) {
      return [];
    }

    return getAvailableAdvancements({
      type,
      dictionary,
      ranks: personsBoyScoutRanks.map(({ rank }) => rank),
      unitTypeId: ProgramId.BOY_SCOUT,
    });
  },
);
