import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { gtm } from '@modules/shared/utils';
import { isMobileSel } from '@screen';
import { SearchInput, Switch } from '@shared/components';
import { appName, sorterPropTypes } from '@shared/constants';
import { intl } from '@shared/localization';

import {
  filteredReportsSel,
  historyReportsRequest,
  historyReportsSearch,
  loadingSel,
  reportsSel,
  searchSel,
  setShowPrice,
  setSorter,
  showPriceSel,
  sorterSel,
} from '../duck';
import styles from './HistoryReports.less';
import HistoryReportsList from './HistoryReportsList';
import HistoryReportsTable from './HistoryReportsTable';

const showPriceUriParam = '&Option=@Price';

class HistoryReports extends React.PureComponent {
  componentDidMount() {
    if (this.props.reports.length === 0) {
      this.props.onFetchReports();
    }
  }

  handleShowPriceChange = showPrice => this.props.onSetShowPrice(showPrice);

  handleSearch = search => this.props.onSetSearch(search);

  handleSorterChange = sorter => this.props.onSetSorter(sorter);

  handleOpenLink = uri => {
    const { showPrice } = this.props;
    uri = uri.replace(showPriceUriParam, '');
    if (showPrice) {
      uri += showPriceUriParam;
    }

    window.open(uri, '_blank');

    gtm.pushEvent({
      event: 'HISTORY_REPORT_DOWNLOAD',
      category: gtm.categories.REPORTS,
    });
  };

  renderToolbar = () => {
    const { showPrice } = this.props;
    return (
      <div className={styles.toolbar}>
        {this.renderSearch()}
        <div className={styles.showPriceContainer}>
          <Switch
            id="qa_priceToggle"
            className={styles.showPriceSwitch}
            checked={showPrice}
            onChange={this.handleShowPriceChange}
          />
          <FormattedMessage id="reports.HistoryReports.showPrice" />
        </div>
      </div>
    );
  };

  renderSearch() {
    const { search } = this.props;

    return (
      <SearchInput
        key="search"
        id="qa_inputSearch"
        className={styles.searchInput}
        size="large"
        mobileSticky
        stickyBordered
        placeholder={intl.formatMessage({
          id: 'reports.HistoryReports.search',
        })}
        value={search}
        onChange={this.handleSearch}
      />
    );
  }

  renderTable() {
    const { filteredReports: reports, loading, sorter, isMobile } = this.props;

    return isMobile ? (
      <HistoryReportsList
        items={reports}
        loading={loading}
        onOpenLink={this.handleOpenLink}
      />
    ) : (
      <HistoryReportsTable
        items={reports}
        sorter={sorter}
        onSorterChange={this.handleSorterChange}
        loading={loading}
        onOpenLink={this.handleOpenLink}
      />
    );
  }

  render() {
    const { loading, reports } = this.props;

    if (!reports.length && !loading) {
      return (
        <div className={styles.noRecordsContainer}>
          <FormattedMessage
            id="reports.HistoryReports.noRecords"
            values={{ appName }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderTable()}
      </React.Fragment>
    );
  }
}

HistoryReports.propTypes = {
  reports: PropTypes.array.isRequired,
  filteredReports: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  sorter: sorterPropTypes.isRequired,
  showPrice: PropTypes.bool.isRequired,
  onSetSearch: PropTypes.func.isRequired,
  onSetSorter: PropTypes.func.isRequired,
  onSetShowPrice: PropTypes.func.isRequired,
  onFetchReports: PropTypes.func.isRequired,
};

const mapState = state => ({
  reports: reportsSel(state),
  filteredReports: filteredReportsSel(state),
  loading: loadingSel(state),
  search: searchSel(state),
  sorter: sorterSel(state),
  showPrice: showPriceSel(state),
  isMobile: isMobileSel(state),
});

const mapDispatch = dispatch => ({
  onSetSearch: search => dispatch(historyReportsSearch(search)),
  onSetSorter: sorter => dispatch(setSorter(sorter)),
  onSetShowPrice: showPrice => dispatch(setShowPrice(showPrice)),
  onFetchReports: () => dispatch(historyReportsRequest()),
});

export default connect(mapState, mapDispatch)(HistoryReports);
