import { subscriptionIds } from '../constants';
import moment from 'moment';

const getSub = (currentSubs, subId, wantsSubscription) => {
  const isOptOut = !wantsSubscription;

  const sub = currentSubs.find(({ topicId }) => Number(topicId) === subId);

  if (!sub) {
    return {
      topicId: subId,
      optOutFlag: isOptOut,
      effectiveDt: moment().format('YYYY-MM-DD'),
    };
  }

  if (sub && sub.optOutFlag !== isOptOut) {
    return {
      ...sub,
      optOutFlag: isOptOut,
      expiryDt: sub.expiryDt ? sub.expiryDt : undefined,
    };
  }
  return undefined;
};

export default (formSubs, currentSubs = []) => {
  const result = [];

  const wantsSubscription = !!formSubs.length;

  const reminderSub = getSub(
    currentSubs,
    subscriptionIds.TEXT,
    wantsSubscription,
  );
  if (reminderSub) {
    result.push(reminderSub);
  }

  return result;
};
