import { trimObject } from './trimFields';

export default (formEmails = {}, profileEmails = []) => {
  const formattedEmails = [];
  Object.entries(formEmails).forEach(([key, email]) => {
    const foundProfileEmail = profileEmails.find(
      profileEmail => profileEmail.id == key.split('_')[1],
    );

    if (email.email === '') {
      return;
    }

    if (foundProfileEmail) {
      formattedEmails.push({
        ...trimObject(foundProfileEmail),
        ...trimObject(email),
        typeId: Number(foundProfileEmail.typeId),
        id: Number(foundProfileEmail.id),
      });
      return;
    }

    formattedEmails.push({ ...email, typeId: 1, isUndeliverable: false });
  });
  return formattedEmails;
};
