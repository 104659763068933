import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/mapTo';
import { zip } from 'rxjs/observable/zip';

import { esbApiService } from '@utils';

import { imageUploadS3$ } from '../../shared/duck/services';

const saveHobby$ = (hobby = '', personGuid, hobbyId = '') => {
  if (!hobby) {
    return Observable.of({});
  }
  const method$ = hobbyId
    ? esbApiService.putOrDefer$
    : esbApiService.postOrDefer$;
  return method$(
    `/persons/${hobbyId ? 'v2/' : ''}${personGuid}/profile/hobbies/${hobbyId}`,
    {
      hobby,
    },
    {
      gtm: {
        label: `/persons/${
          hobbyId ? 'v2/' : ''
        }{personGuid}/profile/hobbies/{hobbyId}`,
      },
    },
  );
};

const updateProfile$ = (data, personGuid) =>
  esbApiService.put$(`/persons/v2/${personGuid}/profile`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/profile',
    },
  });

const savePicture$ = (personGuid, oldPicture, newPicture) => {
  if (oldPicture === newPicture) {
    return Observable.of(oldPicture);
  }

  const method = oldPicture ? esbApiService.put$ : esbApiService.post$;

  return method(
    `/persons/${personGuid}/profilePicture`,
    {
      picture: newPicture,
    },
    {
      gtm: {
        label: '/persons/{personGuid}/profilePicture',
      },
    },
  ).mapTo(newPicture);
};

const saveProfile$ = ({
  personGuid,
  hobbyId,
  profile,
  hobby,
  oldPicture,
  newPicture,
}) =>
  zip(
    saveHobby$(hobby, personGuid, hobbyId),
    updateProfile$(profile, personGuid),
    savePicture$(personGuid, oldPicture, newPicture),
    (hobby, address, email, phone) => ({ hobby, address, email, phone }),
  );

const saveProfilePicture$ = ({ personGuid, oldPicture, newPicture }) =>
  zip(savePicture$(personGuid, oldPicture, newPicture));

const updateSBProfile$ = (data, userId) =>
  esbApiService.put$(`/advancements/users/${userId}`, data, {
    gtm: {
      label: '/advancements/users/{userId}',
    },
  });

const saveProfileJoinedDate$ = ({ payload, userId }) =>
  zip(updateSBProfile$(payload, userId));

const deleteProfileItem$ = (data, personGuid) =>
  esbApiService.post$(`/persons/v2/${personGuid}/deleteProfileInfo`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/deleteProfileInfo',
    },
  });

const createUsersubscriptions$ = (data, personGuid) =>
  esbApiService.post$(`/persons/${personGuid}/subscriptions`, data, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions',
    },
  });

const updateUsersubscriptions$ = (data, personGuid) =>
  esbApiService.put$(`/persons/${personGuid}/subscriptions/${data.id}`, data, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions/{id}',
    },
  });

// SBL-3868
/**
 * @esbEndpoint POST /advancements/:userId/pictureUpload
 */
const getPreSignedUrl$ = (userId, imgType) =>
  esbApiService.post$(
    `/advancements/${userId}/pictureUpload`,
    {
      contentType: imgType,
    },
    {
      gtm: {
        label: '/advancements/{userId}/pictureUpload',
      },
    },
  );

const saveUnit$ = () => Observable.of({}).delay(1000);

export default {
  deleteProfileItem$,
  updateProfile$,
  updateSBProfile$,
  saveProfile$,
  saveProfilePicture$,
  saveProfileJoinedDate$,
  createUsersubscriptions$,
  updateUsersubscriptions$,
  saveUnit$,
  getPreSignedUrl$,
  imageUploadS3$,
};
