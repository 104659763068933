import React from 'react';

export const getChildrenKeys = children => {
  const keys = [];
  React.Children.forEach(children, c => {
    if (c) {
      keys.push(c.key);
    }
  });
  return keys;
};
