import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Hr } from '@shared';

import { ModalMode, ProjectSummary } from '../../../../common';
import { detailModes } from '../../../constants';
import { setDetailMode } from '../../../duck';
import LongCruiseDetails from '../LongCruiseDetails';
import styles from './LongCruiseInfo.less';

class LongCruiseInfo extends React.PureComponent {
  state = {
    showDetails: this.props.modalMode === ModalMode.CREATE,
  };

  handleToggleDetails = () => {
    this.setState(({ showDetails }) => ({ showDetails: !showDetails }));
  };

  render() {
    const { form, modalMode, detailMode, loading, disableFutureDays } =
      this.props;
    const { showDetails } = this.state;
    const showSummary =
      detailMode !== detailModes.CREATE && detailMode !== detailModes.CLONE;
    const isEditView = modalMode === ModalMode.EDIT;
    return (
      <div>
        <div
          className={cn(styles.summary, {
            [styles.noDisplay]: isEditView || !showSummary,
          })}
        >
          <div className={styles.summaryColumns}>
            <ProjectSummary
              type="longCruise"
              className={styles.toggleDetailsButton}
              expanded={showDetails}
              onClick={this.handleToggleDetails}
            />
          </div>
          {showDetails && <Hr className={styles.hr} />}
        </div>
        <div
          className={cn({
            [styles.noDisplay]: !isEditView && showSummary && !showDetails,
          })}
        >
          <LongCruiseDetails
            disableFutureDays={disableFutureDays}
            loading={loading}
            form={form}
            modalMode={modalMode}
            detailMode={detailMode}
          />
        </div>
      </div>
    );
  }
}

LongCruiseInfo.propTypes = {
  // provided by parent
  loading: PropTypes.bool,
  form: PropTypes.object.isRequired,
  modalMode: PropTypes.string.isRequired,
  detailMode: PropTypes.string.isRequired,
  disableFutureDays: PropTypes.bool,
  // provided by component itself
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
};

const mapDispatch = dispatch => ({
  onExpand: () => dispatch(setDetailMode(detailModes.RECORD)),
  onCollapse: () => dispatch(setDetailMode(detailModes.PREVIEW)),
});

export default connect(null, mapDispatch)(LongCruiseInfo);
