import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialState, moduleName } from '../constants';
import type { NotificationsState } from '../types';

const notificationsSlice = createSlice({
  name: moduleName,
  initialState: initialState,
  reducers: {
    setNotificationsVisible: (state, action: PayloadAction<boolean>) => {
      state.notificationsVisible = action.payload;
    },
    // generic update
    setValue: (state, action: PayloadAction<Partial<NotificationsState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});
const { actions } = notificationsSlice;

export const { setValue, setNotificationsVisible } = actions;

export default notificationsSlice;
