import React from 'react';

import { Switch as BsaSwitch } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './PersonToggle.less';

const PersonToggle = props => {
  const { size = 'default', className, checkedColor, ...rest } = props;

  const classNames = cn(
    styles[checkedColor],
    {
      [styles.large]: size === 'large',
    },
    className,
  );

  return (
    <span>
      <BsaSwitch
        className={classNames}
        size={size === 'large' ? 'default' : size}
        {...rest}
      />
    </span>
  );
};

PersonToggle.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  checkedColor: PropTypes.oneOf(['green-status', 'default']),
  size: PropTypes.oneOf(['large', 'default', 'small']),
};

PersonToggle.defaultProps = {
  checkedColor: 'scouting-red',
};

export default PersonToggle;
