import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';

import { organizationGuidSel } from '@context';
import { goToYouthProfile, setShouldMemberDetailsReload } from '@shared/duck';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { esbApiService } from '@utils';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  UNAPPROVE_ADVANCEMENT_REQUEST,
  unapproveAdvancementDeferred,
  unapproveAdvancementError,
  unapproveAdvancementResponse,
} from './actions';
import { modalActionSel } from './selectors';
import services from './services';

const unapproveAdvancementRequestEpic$ = (action$, state$) =>
  action$
    .ofType(UNAPPROVE_ADVANCEMENT_REQUEST)
    .switchMap(({ payload: advancement }) => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      const action = modalActionSel(state);

      const { date, advancementType, userAwardId, id, userId, adventureId } =
        advancement;

      return services
        .unapproveAdvancement$({
          date,
          userId,
          userAwardId,
          advancementId: id || adventureId,
          advancementType,
          organizationGuid,
        })
        .mergeMap(res => {
          if (res === esbApiService.DEFERRED) {
            return Observable.of(unapproveAdvancementDeferred());
          }

          const successMsg = intl.formatMessage({
            id: `unapproveAdvancement.UnapproveAdvancementModal.${action}Advancement.successMsg`,
          });
          toastService.success(successMsg);
          return Observable.concat(
            of(unapproveAdvancementResponse(advancementType)),
            of(setShouldMemberDetailsReload(true)),
            of(goToYouthProfile(userId)),
          );
        })
        .catchAndReport(err => Observable.of(unapproveAdvancementError(err)));
    });

export default combineEpics(unapproveAdvancementRequestEpic$);
