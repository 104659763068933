import { gtm as gtmUtil } from '@shared';

export default () => next => action => {
  if (action && action.gtm) {
    const { gtm = {} } = action;
    const { category, label, value } = gtm;

    gtmUtil.pushEvent({
      event: action.type,
      category,
      label,
      value,
    });
  }
  return next(action);
};
