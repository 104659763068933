import { basicActionCreator } from '@utils';
import { moduleName } from '../constants';

import { ROUTE_ACTIVITIES } from '@shared';

export { ROUTE_ACTIVITIES };

export const GET_ACTIVITIES_RESPONSE = `${moduleName}/GET_ACTIVITIES_RESPONSE`;
export const getActivitiesResponse = (activities = []) =>
  basicActionCreator(GET_ACTIVITIES_RESPONSE, { activities });

export const GET_ACTIVITIES_ERROR = `${moduleName}/GET_ACTIVITIES_ERROR`;
export const getActivitiesError = err =>
  basicActionCreator(GET_ACTIVITIES_ERROR, err);

export const GET_ACTIVITIES_REQUEST = `${moduleName}/GET_ACTIVITIES_REQUEST`;
export const getActivitiesRequest = selectedDate =>
  basicActionCreator(GET_ACTIVITIES_REQUEST, selectedDate);

export const GET_ACTIVITIES_EVENTS_RESPONSE = `${moduleName}/GET_ACTIVITIES_EVENTS_RESPONSE`;
export const getActivitiesEventResponse = (events = []) =>
  basicActionCreator(GET_ACTIVITIES_EVENTS_RESPONSE, events);

export const GET_ACTIVITIES_EVENTS_ERROR = `${moduleName}/GET_ACTIVITIES_EVENTS_ERROR`;
export const getActivitiesEventsError = err =>
  basicActionCreator(GET_ACTIVITIES_EVENTS_ERROR, err);

export const SET_NEW_DATE = `${moduleName}/SET_NEW_DATE`;
export const setNewDate = selectedDate =>
  basicActionCreator(SET_NEW_DATE, selectedDate);

export const SET_ACTIVITY_TYPE_FILTERS = `${moduleName}/SET_ACTIVITY_TYPE_FILTERS`;
export const setActivityTypeFilter = (key, value) =>
  basicActionCreator(SET_ACTIVITY_TYPE_FILTERS, { key, value });

export const FILTER_ACTIVITY_TEXT = `${moduleName}/FILTER_ACTIVITY_TEXT`;
export const setFilterActivityText = text =>
  basicActionCreator(FILTER_ACTIVITY_TEXT, text);

export const SET_ITEMS_PER_PAGE = `${moduleName}/SET_ITEMS_PER_PAGE`;
export const setItemsPerPage = itemsPerPage =>
  basicActionCreator(SET_ITEMS_PER_PAGE, itemsPerPage);

export const SET_CURRENT_PAGE = `${moduleName}/SET_CURRENT_PAGE`;
export const setCurrentPage = page =>
  basicActionCreator(SET_CURRENT_PAGE, page);
