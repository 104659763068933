import React from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSelectedSubUnitId,
  showAddToSubUnitModal,
  showSetSubUnitModal,
} from '@modules/advancement/subUnits/duck/actions';
import { subUnitTypeSel } from '@modules/advancement/subUnits/duck/selectors';
import { Menu } from '@shared';

import { setSubUnitUserData } from '../../../../duck';
import styles from './SubUnitMenu.less';

export const SubUnitMenu = ({ data }) => {
  const subUnitTypeName = useSelector(subUnitTypeSel);
  const dispatch = useDispatch();

  const handleClickMenuDelete = e => {
    e.domEvent.stopPropagation();
    dispatch(setSubUnitUserData(data));
  };

  const handleClickMenuEdit = e => {
    e.domEvent.stopPropagation();
    dispatch(setSelectedSubUnitId(data.subUnitId));
    dispatch(showSetSubUnitModal());
  };

  const handleClickMenuAdd = e => {
    e.domEvent.stopPropagation();
    dispatch(setSubUnitUserData(data));
    dispatch(showAddToSubUnitModal());
  };

  return data.subUnitId && data.subUnitId > 0 ? (
    <Menu selectable={false} className={styles.menu} subMenuCloseDelay={0}>
      <Menu.Item onClick={handleClickMenuEdit}>
        <EditIcon className={styles.menuIcon} />
        <FormattedMessage
          id="advancement.PackRoster.subUnitMenu.editSubUnit"
          values={{
            subUnitType: capitalize(subUnitTypeName),
          }}
        />
      </Menu.Item>
      <hr />
      <Menu.Item onClick={handleClickMenuDelete}>
        <DeleteIcon className={styles.menuIcon} />
        <FormattedMessage
          id="advancement.PackRoster.subUnitMenu.deleteSubUnit"
          values={{
            subUnitType: capitalize(subUnitTypeName),
          }}
        />
      </Menu.Item>
    </Menu>
  ) : (
    <Menu selectable={false} className={styles.menu} subMenuCloseDelay={0}>
      <Menu.Item onClick={handleClickMenuAdd}>
        <AddCircleIcon className={styles.menuIcon} />
        <FormattedMessage
          id="advancement.PackRoster.subUnitMenu.addToSubUnit"
          values={{
            subUnitType: capitalize(subUnitTypeName),
          }}
        />
      </Menu.Item>
    </Menu>
  );
};

SubUnitMenu.propTypes = {
  data: PropTypes.object.isRequired,
};
