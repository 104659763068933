import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { PAYMENT_LOG_TABS, initialSliceState } from '../constants';
import { MemberTypes, SorterColumnKeys, SorterColumnOrder } from '../types';

export const paymentLogsSlice = createSlice({
  name: 'paymentLogs',
  initialState: initialSliceState,

  reducers: {
    setSearchFilter: (
      state,
      action: PayloadAction<{
        searchTerm: string;
      }>,
    ) => {
      const { searchTerm } = action.payload;
      state.searchFilter = searchTerm;
    },
    setFilter: (
      state,
      action: PayloadAction<{
        memberType: MemberTypes;
      }>,
    ) => {
      const { memberType } = action.payload;
      state.filters = { memberType };
    },
    setSorter: (
      state,
      action: PayloadAction<{
        columnKey: SorterColumnKeys;
        order: SorterColumnOrder;
      }>,
    ) => {
      const { columnKey, order } = action.payload;
      state.sorter.columnKey = columnKey;
      state.sorter.order = order;
    },
    setSelectedRowKeys: (
      state,
      action: PayloadAction<{
        items: number[];
      }>,
    ) => {
      const { items } = action.payload;
      state.selectedRowKeys = items;
    },
    removeMultipleSelectedRowKeys: (
      state,
      action: PayloadAction<{
        removedItems: number[];
      }>,
    ) => {
      const { removedItems } = action.payload;
      state.selectedRowKeys = state.selectedRowKeys.filter(
        item => !removedItems.includes(item),
      );
    },
    setPaymentLogsActiveTab: (
      state,
      action: PayloadAction<{
        activeTab: PAYMENT_LOG_TABS;
      }>,
    ) => {
      const { activeTab } = action.payload;
      state.paymentLogsActiveTab = activeTab;
    },
  },
});

const { reducer: sliceReducer, actions } = paymentLogsSlice;

export const {
  setSearchFilter,
  setFilter,
  setSorter,
  setSelectedRowKeys,
  removeMultipleSelectedRowKeys,
  setPaymentLogsActiveTab,
} = actions;
export { sliceReducer };
