import flatten from 'lodash/flatten';
import { createSelector } from 'reselect';

import { unitTypeIdSel } from '@context';
import {
  ProgramId,
  advancementStatuses,
  advancementTypes,
  bobcatRankOrder,
  ranksOrder,
} from '@shared';

import { getWarningCode } from '../../helpers';
import {
  additionalAvailableAdvancementsSel,
  availableAdvancementsSel,
} from './availableAdvancements.selectors';
import {
  additionalAdvancementIdSel,
  additionalAdvancementTypeSel,
  advancementIdSel,
  advancementTypeSel,
  personsSel,
  selectedAdditionalAdvStatusSel,
  selectedAdvancementStatusSel,
} from './core.selectors';
import {
  selectedAdditionalVerifiedPersonsSel,
  selectedVerifiedPersonsSel,
  suggestedAdditionalVerifiedYouthSel,
  suggestedVerifiedYouthSel,
} from './general.selectors';

export const getEligiblePersons = ({
  persons,
  advancementType,
  selectedAdvStatus,
}) =>
  persons.filter(
    ({
      isAdult,
      isPreviousRankEarned,
      isPreviousCoreAwardEarned,
      isInInvalidUnit,
      hasAdvancementAwardedToday,
      isApproved,
      isAwarded,
    }) => {
      const hasEarnedStatus =
        selectedAdvStatus === advancementStatuses.AWARDED
          ? isAwarded
          : isApproved;
      return (
        !isAdult &&
        isPreviousCoreAwardEarned !== false &&
        !isInInvalidUnit &&
        !hasAdvancementAwardedToday &&
        ((!hasEarnedStatus && isPreviousRankEarned !== false) ||
          advancementType === advancementTypes.AWARDS)
      );
    },
  );

export const eligiblePersonsSel = createSelector(
  selectedVerifiedPersonsSel,
  advancementTypeSel,
  selectedAdvancementStatusSel,
  (persons, advancementType, selectedAdvStatus) =>
    getEligiblePersons({
      persons,
      advancementType,
      selectedAdvStatus,
    }),
);

export const additionalEligiblePersonsSel = createSelector(
  selectedAdditionalVerifiedPersonsSel,
  additionalAdvancementTypeSel,
  selectedAdditionalAdvStatusSel,
  (persons, advancementType, selectedAdvStatus) =>
    getEligiblePersons({
      persons,
      advancementType,
      selectedAdvStatus,
    }),
);

export const ineligiblePersonsSel = createSelector(
  personsSel,
  eligiblePersonsSel,
  (persons, eligiblePersons) =>
    persons.filter(
      person =>
        !eligiblePersons.find(eligible => eligible.userId === person.userId),
    ),
);

export const additionalIneligiblePersonsSel = createSelector(
  personsSel,
  additionalEligiblePersonsSel,
  (persons, eligiblePersons) =>
    persons.filter(
      person =>
        !eligiblePersons.find(eligible => eligible.userId === person.userId),
    ),
);

const isSelectedCubScoutRankNext = ({
  advancementType,
  advancementId,
  currentRank,
}) => {
  if (advancementTypes.RANKS != advancementType || !advancementId) {
    return true;
  }
  return !(
    ranksOrder[advancementId] > bobcatRankOrder &&
    ranksOrder[advancementId] != ranksOrder[currentRank.id] + 1
  );
};

const hasWarnings = ({
  person,
  advancementType,
  advancementId,
  unitTypeId,
  availableAdvancements,
  ineligiblePersons = [],
  selectedAdvancementStatus,
}) => {
  const {
    userId,
    isApproved,
    isAwarded,
    hasValidAge,
    currentHighestRankApproved,
  } = person;
  const alreadyHasStatus =
    selectedAdvancementStatus === advancementStatuses.AWARDED
      ? isAwarded
      : isApproved;

  return (
    alreadyHasStatus ||
    !hasValidAge ||
    (unitTypeId == ProgramId.CUB_SCOUT &&
      !isSelectedCubScoutRankNext({
        advancementType,
        advancementId,
        advancements: flatten(availableAdvancements),
        currentRank: currentHighestRankApproved,
      })) ||
    ineligiblePersons.find(person => person.userId === userId)
  );
};

const getPersonsWithWarnings = ({
  persons,
  ineligiblePersons,
  unitTypeId,
  advancementId,
  advancementType,
  availableAdvancements,
  selectedAdvancementStatus,
}) =>
  persons.map(person => {
    if (
      !hasWarnings({
        person,
        advancementType,
        advancementId,
        unitTypeId,
        availableAdvancements,
        ineligiblePersons,
        selectedAdvancementStatus,
      })
    ) {
      return person;
    }

    const warningCode = getWarningCode({
      dateCompleted: person.dateCompleted,
      isPreviousRankEarned: person.isPreviousRankEarned,
      isAtLeastApproved: person.isAtLeastApproved,
      isPreviousCoreAwardEarned: person.isPreviousCoreAwardEarned,
      isInInvalidUnit: person.isInInvalidUnit,
      hasValidAge: person.hasValidAge,
      unitTypeId: person.unitTypeId,
      isAwarded: person.isAwarded,
      selectedAdvancementStatus,
    });
    return { ...person, warningCode };
  });

export const personsWithWarningsSel = createSelector(
  selectedVerifiedPersonsSel,
  ineligiblePersonsSel,
  unitTypeIdSel,
  advancementIdSel,
  advancementTypeSel,
  availableAdvancementsSel,
  selectedAdvancementStatusSel,
  (
    persons,
    ineligiblePersons,
    unitTypeId,
    advancementId,
    advancementType,
    availableAdvancements,
    selectedAdvancementStatus,
  ) =>
    getPersonsWithWarnings({
      persons,
      ineligiblePersons,
      unitTypeId,
      advancementId,
      advancementType,
      availableAdvancements,
      selectedAdvancementStatus,
    }),
);

export const additionalpersonsWithWarningsSel = createSelector(
  selectedAdditionalVerifiedPersonsSel,
  additionalIneligiblePersonsSel,
  additionalAdvancementIdSel,
  additionalAdvancementTypeSel,
  additionalAvailableAdvancementsSel,
  selectedAdditionalAdvStatusSel,
  (
    persons,
    ineligiblePersons,
    advancementId,
    advancementType,
    availableAdvancements,
    selectedAdvancementStatus,
  ) =>
    getPersonsWithWarnings({
      persons,
      ineligiblePersons,
      unitTypeId: ProgramId.BOY_SCOUT,
      advancementId,
      advancementType,
      availableAdvancements,
      selectedAdvancementStatus,
    }),
);

const getSuggestedWithWarnings = ({
  persons,
  unitTypeId,
  advancementId,
  advancementType,
  availableAdvancements,
  selectedAdvancementStatus,
}) =>
  persons.map(person => {
    if (
      !hasWarnings({
        person,
        unitTypeId,
        advancementId,
        advancementType,
        availableAdvancements,
        selectedAdvancementStatus,
      })
    ) {
      return person;
    }

    const warningCode = getWarningCode({
      isPreviousRankEarned: person.isPreviousRankEarned,
      isAtLeastApproved: person.isAtLeastApproved,
      isPreviousCoreAwardEarned: person.isPreviousCoreAwardEarned,
      isInInvalidUnit: person.isInInvalidUnit,
      hasValidAge: person.hasValidAge,
      unitTypeId: person.unitTypeId,
      selectedAdvancementStatus,
    });

    return { ...person, warningCode };
  });

export const suggestedWithWarningsSel = createSelector(
  suggestedVerifiedYouthSel,
  unitTypeIdSel,
  advancementIdSel,
  advancementTypeSel,
  availableAdvancementsSel,
  selectedAdvancementStatusSel,
  (
    persons,
    unitTypeId,
    advancementId,
    advancementType,
    availableAdvancements,
    selectedAdvancementStatus,
  ) =>
    getSuggestedWithWarnings({
      persons,
      unitTypeId,
      advancementId,
      advancementType,
      availableAdvancements,
      selectedAdvancementStatus,
    }),
);

export const additionalSuggestedWithWarningsSel = createSelector(
  suggestedAdditionalVerifiedYouthSel,
  additionalAdvancementIdSel,
  additionalAdvancementTypeSel,
  additionalAvailableAdvancementsSel,
  selectedAdditionalAdvStatusSel,
  (
    persons,
    advancementId,
    advancementType,
    availableAdvancements,
    selectedAdvancementStatus,
  ) =>
    getSuggestedWithWarnings({
      persons,
      unitTypeId: ProgramId.BOY_SCOUT,
      advancementId,
      advancementType,
      availableAdvancements,
      selectedAdvancementStatus,
    }),
);
