import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isSeaScoutSel } from '@context/duck/selectorsTyped';
import { Fab, Input } from '@shared';

import {
  CampoutsButton,
  RecordAdvancementButton,
  RecordHikeMilesButton,
  RecordLongCruiseButton,
  ServiceHoursButton,
} from '../../../../../../../progress/components';
import { SELECTABLE_PEOPLE_LIMIT } from '../../../../../constants';
import { isMissingSomeMemberIdSel } from '../../../../../duck';
import { checkIsConfirmed } from '../../../../../helpers';
import styles from './ProgressFab.less';

const { Divider } = Fab;

const ProgressFab = ({
  visible,
  open,
  selectedItems,
  onOpenChange,
  isScoutbookRole,
  canRecordAdvancement,
}) => {
  const isSeaScout = useSelector(isSeaScoutSel);
  const disableCreateActivity = useSelector(isMissingSomeMemberIdSel);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const askForConfirmation = selectedItems.length > SELECTABLE_PEOPLE_LIMIT;

  const handleChange = useCallback(
    event => {
      const { value: inputText } = event.target;
      if (checkIsConfirmed(inputText)) {
        setDisableConfirm(false);
      } else {
        setDisableConfirm(true);
      }
    },
    [setDisableConfirm],
  );

  const disableConfirmBtn = useCallback(() => {
    setDisableConfirm(true);
  }, [setDisableConfirm]);

  const confirmationMessage = useMemo(
    () => (
      <React.Fragment>
        <FormattedMessage
          id="advancement.PackRoster.confirmSelection"
          values={{ records: selectedItems.length }}
        />
        <Input type="text" onChange={handleChange} />
      </React.Fragment>
    ),
    [selectedItems, handleChange],
  );

  const showRecordAdvancement = !isScoutbookRole || canRecordAdvancement;

  return (
    <Fab
      content={
        <React.Fragment>
          {showRecordAdvancement && (
            <React.Fragment>
              <RecordAdvancementButton
                userIds={selectedItems}
                className={styles.progressButton}
                askForConfirmation={askForConfirmation}
                onConfirm={disableConfirmBtn}
                onCancel={disableConfirmBtn}
                disableConfirmBtn={disableConfirm}
                confirmationMessage={confirmationMessage}
              />
              <Divider />
            </React.Fragment>
          )}
          <ServiceHoursButton
            disabled={disableCreateActivity}
            userIds={selectedItems}
            className={styles.progressButton}
            askForConfirmation={askForConfirmation}
            onConfirm={disableConfirmBtn}
            onCancel={disableConfirmBtn}
            disableConfirmBtn={disableConfirm}
            confirmationMessage={confirmationMessage}
            disableFutureDays
          />
          <Divider />
          <CampoutsButton
            disabled={disableCreateActivity}
            userIds={selectedItems}
            className={styles.progressButton}
            askForConfirmation={askForConfirmation}
            onConfirm={disableConfirmBtn}
            onCancel={disableConfirmBtn}
            disableConfirmBtn={disableConfirm}
            confirmationMessage={confirmationMessage}
            disableFutureDays
          />
          <Divider />
          <RecordHikeMilesButton
            disabled={disableCreateActivity}
            userIds={selectedItems}
            className={styles.progressButton}
            askForConfirmation={askForConfirmation}
            onConfirm={disableConfirmBtn}
            onCancel={disableConfirmBtn}
            disableConfirmBtn={disableConfirm}
            confirmationMessage={confirmationMessage}
            disableFutureDays
          />
          {isSeaScout && (
            <React.Fragment>
              <Divider />
              <RecordLongCruiseButton
                disabled={disableCreateActivity}
                userIds={selectedItems}
                className={styles.progressButton}
                askForConfirmation={askForConfirmation}
                onConfirm={disableConfirmBtn}
                onCancel={disableConfirmBtn}
                disableConfirmBtn={disableConfirm}
                confirmationMessage={confirmationMessage}
                disableFutureDays
              />
            </React.Fragment>
          )}
        </React.Fragment>
      }
      visible={visible}
      open={open}
      onOpenChange={onOpenChange}
    >
      <FormattedMessage id="packRoster.ProgressFab.title" />
    </Fab>
  );
};

ProgressFab.propTypes = {
  visible: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  isScoutbookRole: PropTypes.bool.isRequired,
  canRecordAdvancement: PropTypes.bool,
};

export default ProgressFab;
