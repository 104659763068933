import moment from 'moment';

import { updatedDateFilters, lastUpdatedDateAll } from './constants';

const { TODAY, LAST_365_DAYS, LAST_30_DAYS, ALL } = updatedDateFilters;

export const getLastUpdatedDate = updatedDateFilter => {
  const today = moment();
  switch (updatedDateFilter) {
    case TODAY: {
      return today;
    }
    case LAST_30_DAYS: {
      return today.subtract(30, 'days');
    }
    case LAST_365_DAYS: {
      return today.subtract(365, 'days');
    }
    case ALL: {
      return moment(lastUpdatedDateAll);
    }
    default: {
      throw new Error(`Unknown updated date code: ${updatedDateFilter}`);
    }
  }
};

export const getFallbackDateRange = ({
  allItems,
  filteredItems,
  currentDateFilter,
}) => {
  if (filteredItems.length !== 0 || currentDateFilter === ALL) {
    return currentDateFilter;
  }

  if (currentDateFilter !== TODAY) {
    return LAST_365_DAYS;
  }

  const monthDayLimit = getLastUpdatedDate(LAST_30_DAYS);
  const hasItemsWithin30Days =
    allItems.filter(item => !moment(item.date).isBefore(monthDayLimit)).length >
    0;

  return hasItemsWithin30Days ? LAST_30_DAYS : LAST_365_DAYS;
};
