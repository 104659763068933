import { ProgramId, memberTypeLabels, unitTypes } from '@shared/constants';

export {
  memberTypes,
  memberTypeIds,
  memberTypeLabels,
} from '@shared/constants';

export const moduleName = 'recharter';

export const defaultRecharterTermValue = 12;

export const recharterRestriction = '2021-12-31';

export const billingInputs = [
  'firstName',
  'lastName',
  'phoneCountry',
  'phone',
  'email',
  'country',
  'address',
  'city',
  'state',
  'zip',
];

export const payAtCouncilInputs = ['firstName', 'lastName'];

export const disabledBillingInputs = [
  'phoneCountry',
  'phone',
  'email',
  'country',
  'address',
  'city',
  'state',
  'zip',
];

export const creditCardInputs = [
  'cardType',
  'cardHoldersName',
  'cardNumber',
  'expDateMonth',
  'expDateYear',
  'cardCvv',
];
export const nationalCouncilNum = '000';
export const blockedCouncilsOnPayAtCouncil = ['802'];
export const blockedCouncilsOnWepay = ['803'];

export const steps = Object.freeze({
  RECHARTER_INFO: 'RECHARTER_INFO',
  PAYMENT_CONFIRMATION: 'PAYMENT_CONFIRMATION',
  POST_RENEWAL: 'POST_RENEWAL',
});

export const stepOrder = [steps.RECHARTER_INFO, steps.PAYMENT_CONFIRMATION];

export const confirmationSteps = Object.freeze({
  WAITING: 'waiting',
  PAYMENT_AND_SIGNATURE: 'payment_and_signature',
  POSTED: 'posted',
});

export const confirmationStepOrder = [
  confirmationSteps.WAITING,
  confirmationSteps.PAYMENT_AND_SIGNATURE,
  confirmationSteps.POSTED,
];

export const paymentMethods = Object.freeze({
  CREDIT_CARD: 'CREDIT_CARD',
  E_CHECK: 'E_CHECK',
  PAY_AT_COUNCIL: 'PAY_AT_COUNCIL',
});

export const paymentMethodsValues = Object.freeze({
  [paymentMethods.CREDIT_CARD]: 'credit_card',
  [paymentMethods.E_CHECK]: 'ACH', //For IA is E-CHECK, for Back-end is ACH.
  [paymentMethods.PAY_AT_COUNCIL]: 'MANUAL',
});

// Values returned by /batch API for paymentType field
export const paymentTypes = Object.freeze({
  CREDIT_CARD: 'Credit Card',
  ACH: 'ACH Payment', // Equivalent of E-Check for IA
  MANUAL_PAY: 'Manual Pay', // Same as Pay at Council
});

export const paymentGatewayStatuses = Object.freeze({
  AUTHORIZED: 'Authorized',
  RELEASED: 'Released',
});

export const productName = 'RECHARTER';
export const wepayErrors = Object.freeze({
  WINDOW_CLOSED: 'window_closed',
});

export const unitInfoIds = Object.freeze({
  type: 'recharter.UnitInfo.type',
  number: 'recharter.UnitInfo.unitNumber',
  tenure: 'recharter.UnitInfo.tenure',
  expiredDate: 'recharter.UnitInfo.expireDate',
  charteredOrg: 'recharter.UnitInfo.charteredOrg',
  district: 'recharter.UnitInfo.district',
  council: 'recharter.UnitInfo.council',
  term: 'recharter.UnitInfo.term',
  newExpiredDate: 'recharter.UnitInfo.newExpireDate',
});

export const charteredOrgInfoIds = Object.freeze({
  name: 'recharter.CharteredOrgInfo.name',
  addressLine1: 'recharter.CharteredOrgInfo.addressLine1',
  addressLine2: 'recharter.CharteredOrgInfo.addressLine2',
  city: 'recharter.CharteredOrgInfo.city',
  state: 'recharter.CharteredOrgInfo.state',
  zipCode: 'recharter.CharteredOrgInfo.zipCode',
  phone: 'recharter.CharteredOrgInfo.phone',
});

export const recharterStatusTypes = Object.freeze({
  INITIATED: 'initiated',
  HOLD: 'hold',
  POST: 'post',
  RELEASED: 'released',
  LOCK: 'lock',
  TRANSMITTED: 'transmitted',
  COMPLETED: 'completed',
  WAITING: 'waiting',
});

export const confirmationStatusTypes = [
  recharterStatusTypes.WAITING,
  recharterStatusTypes.HOLD,
  recharterStatusTypes.LOCK,
  recharterStatusTypes.POST,
];

export const recharterStatusTypesForIds = Object.freeze({
  1: recharterStatusTypes.INITIATED,
  2: recharterStatusTypes.HOLD,
  3: recharterStatusTypes.POST,
  4: recharterStatusTypes.RELEASED,
  5: recharterStatusTypes.TRANSMITTED,
  6: recharterStatusTypes.COMPLETED,
  7: recharterStatusTypes.LOCK,
  8: recharterStatusTypes.WAITING,
});

export const executiveOfficerId = 328;
export const lionAdultPartnerId = 662;
export const tigerCubAdultId = 314;
export const parentCoordinatorId = 477;

export const nonUnit = Object.freeze({
  id: 0,
  label: 'Non Unit Position',
});

export const recharterUnitTypes = [
  { value: ProgramId.VENTURING, label: unitTypes.CREW },
  { value: nonUnit.id, label: nonUnit.label },
  { value: ProgramId.CUB_SCOUT, label: unitTypes.PACK },
  { value: ProgramId.EXPLORING, label: unitTypes.POST },
  { value: ProgramId.CLUB, label: unitTypes.CLUB },
  { value: ProgramId.SEA_SCOUT, label: unitTypes.SHIP },
  { value: ProgramId.BOY_SCOUT, label: unitTypes.TROOP },
];

export const yptExempt = [
  { position: 'Lion Adult Partner' },
  { position: 'Tiger Adult Partner' },
  { position: 'Tiger Cub Adult' },
];

export const cbcExempt = [
  { position: 'Lion Adult Partner' },
  { position: 'Tiger Adult Partner' },
  { position: 'Tiger Cub Adult' },
];

export const MIDExemptPositions = new Set([
  662, // Lion Adult Partner
  314, // Tiger Cub Adult
  328, // Executive Officer,
]);

export const positionIdsNotAllowedToBeAdded = new Set([
  662, // Lion Adult Partner
  314, // Tiger Cub Adult
]);

export const positionIdsExemptOfPaperApp = [
  314, //Tiger Parent
  662, //Lion Adult
  328, //Executive Officer
];

export const positionTypes = Object.freeze({
  registered: 'Registered',
  functional: 'Functional',
  noFee: 'No-fee',
});

export const agreementPositions = Object.freeze([
  'Chartered Organization Rep.',
  'Council Unit Representative',
  'Committee Chair',
  'Committee Chairman',
  'Executive Officer',
  'Institutional Head',
  'Key 3 Delegate',
  'COR Delegate',
  'Cubmaster',
  'Scoutmaster',
  'Venturing Crew Advisor',
  'Skipper',
  'Explorer Post Advisor',
  'Exploring Sponsor',
  'Post Committee Chairman',
  'Post Committee Chair',
]);

export const ccEmailDelegatePositions = Object.freeze([
  'Key 3 Delegate',
  'COR Delegate',
]);

export const recharterSetup = Object.freeze({
  paymentTypeId: 1766,
  batchApplicationTypeId: 1,
});

export const manageMembersMenuKeys = Object.freeze({
  addNewMember: 'addNewMember',
  inviteNewMember: 'inviteNewMember',
  markAsMultiple: 'markAsMultiple',
  unmarkAsMultiple: 'unmarkAsMultiple',
  removeFromRecharter: 'removeFromRecharter',
  promoteScouts: 'promoteScouts',
});

export const validationTypes = Object.freeze({
  WARNING: 'warning',
  ERROR: 'error',
});

export const batchTypeIds = Object.freeze({
  internetRechartering: 1,
  manualRechartering: 2,
});

const defaultYouthsRequirements = Object.freeze({
  minAge: 5,
  maxAge: 17,
});

const defaultParticipantsRequirements = Object.freeze({
  minAge: 18,
  maxAge: 20,
});

const defaultAdultsRequirements = Object.freeze({
  minAge: 18,
});

export const basicRequirements = Object.freeze({
  [memberTypeLabels.youth]: defaultYouthsRequirements,
  [memberTypeLabels.adult]: defaultAdultsRequirements,
  [memberTypeLabels.participant]: defaultParticipantsRequirements,
});

export const COUNTRY_IDS = Object.freeze({
  USA: 616,
});

export const BATCH_VALIDATIONS = Object.freeze({
  YouthNoParentGuardians: {
    validationId: 18,
  },
  UnitMoreAHundred: {
    validationId: 1,
    validationText: 'Unit has more than 100 youth',
  },
  UnitLessThanFive: {
    validationId: 2,
    validationText: 'Unit has less than 5 youth',
  },
  UnitCouncilFunded: {
    validationId: 3,
    validationText: 'Unit is funded/supported by the council',
  },
  RosterPaidYouthDropped: {
    validationId: 4,
    validationText: 'Roster dropped at least 25 paid youth',
  },
  LeadershipPositions: {
    validationId: 6,
    validationText:
      'Unit does not have required number of leadership positions',
  },
  NoYouthChanges: {
    validationId: 7,
    validationText: 'Unit has no youth changes from previous charter',
  },
  HasPaperApplications: {
    validationId: 8,
    validationText: 'Unit has paper applications attached',
  },
  NoYPTLeaders: {
    validationId: 9,
    validationText: 'Leaders do not have current Youth Protection Training',
  },
  NoCBCLeaders: {
    validationId: 10,
    validationText: 'Leaders do not have completed CBC Authorizations',
  },
  LeadersLess18YearsOld: {
    validationId: 11,
    validationText: 'Leaders are less than 18 years old',
  },
  YouthsAgeRequirement: {
    validationId: 12,
    validationText:
      'Youth do not meet the age/grade requirement for the program',
  },
  MultipleRegistrations: {
    validationId: 13,
    validationText: 'Multiple-Registration validation is required',
  },
  UnitPayAtCouncil: {
    validationId: 14,
    validationText: 'Unit will pay at Council',
  },
  AdultsNoSSN: {
    validationId: 15,
    validationText: 'Adults do not have SSN',
  },
  UnitNumberPaidYouths: {
    validationId: 16,
    validationText: 'Unit has less than 2 paid youth',
  },
  UnitPendingApplications: {
    validationId: 17,
    validationText: 'Unit has pending application/s.',
  },
});

export const COUNCIL_802_GUID = '2FAF122E-791A-4B5B-8DB7-D4A7F30A7854';

export const paymentProductName = 'RECHARTER';
