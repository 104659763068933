import { clone, get, keyBy, uniqBy } from 'lodash';
import uniqWith from 'lodash/uniqWith';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import stable from 'stable';

import {
  ALLOW_MBC_LOGIN,
  ENABLE_GOOGLE_SIGN_IN,
  ENV,
  SHOW_COUNSELED_YOUTH,
  USE_ROLETYPES_PARAMS,
  USE_UNITS_ROLETYPES,
} from '@config';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import {
  programForUnitTypeId,
  programTypeNameForProgramTypeId,
  unitTypeIdForProgramTypeId,
} from '@shared/constants';
import { userPreferencesServices, userServices } from '@shared/duck';
import {
  checkBrowser,
  coreApiService,
  esbApiService,
  googleSignIn,
  jwtDecode,
  personNameBuilder,
  removeLeadingZeros,
  webscriptApiService,
} from '@utils';

import unitSrv from '../../unit/duck/services';
import {
  adminAkelaRoles,
  canApproveSBRoles,
  canEditCalendarRoles,
  canEditPaymentLogsRoles,
  canEditPermissionsManagerRoles,
  canEditSubCalendarRoles,
  canEditYouthProfileRoles,
  key3Roles,
  mBCounselorRoles,
  organizationRoles,
  scoutbookRoles,
  specialLogInRoles,
  subUnitRoles,
} from '../constants';
import { dedupeRoleTypes } from '../helpers';

const defaultAppStorageName = 'DEFAULT_APP';

const noop = () => {};

const googleSignOut$ = (...action) => {
  if (ENABLE_GOOGLE_SIGN_IN) {
    return Observable.create(observer => {
      googleSignIn.signOut();
      return observer.next(...action);
    });
  }
  return Observable.of(...action);
};

const addSessionExp = loginData => {
  const { token } = loginData || {};
  const { exp } = jwtDecode(token);
  return {
    ...loginData,
    exp,
  };
};

const login$ = (login, password) =>
  coreApiService
    .post$(
      `/users/${login}/authenticate`,
      { password },
      {
        gtm: {
          label: '/users/{login}/authenticate',
        },
        suppressSentry: [403],
        transformBodyForSentry: () => ({ password: '********' }),
        acceptVersion: '2',
        // temporary workaround
        // conditionally bypass SW if on Safari for non-localhost environments
        skipSw: ENV !== 'local' && checkBrowser.isSafari,
      },
    )
    .pipe(map(addSessionExp));

const pointlessSelfsessionGuid = 'A1A1A1A1-B2B2-C3C3-D4D4-E5E5E5E5E5E5';

const selfsession$ = (extraParams = {}, isExternalLogin = false) =>
  coreApiService
    .get$(`/users/self_${pointlessSelfsessionGuid}/sessions/current`, {
      suppressErrorToasts: true,
      suppressSentry: !isExternalLogin ? [401] : [],
      gtm: {
        label: '/users/selfsession',
      },
      ...extraParams,
    })
    .pipe(map(addSessionExp));

/**
 * @esbEndpoint GET /persons/v2/:personGuid/personprofile
 */
const getUserProfileRequest$ = id =>
  esbApiService.get$(`/persons/v2/${id}/personprofile`, {
    gtm: {
      label: '/persons/v2/{personGuid}/personprofile',
    },
  });

const masquerade$ = (memberId, suppressErrors) =>
  coreApiService
    .get$(`/users/${memberId}/sessions/masquerade`, {
      suppressErrorToasts: suppressErrors,
      suppressSentry: suppressErrors,
      gtm: {
        label: '/users/{memberId}/masquerade',
      },
    })
    .pipe(map(addSessionExp));

const getUserProfile$ = (personGuid, userId) =>
  forkJoin(
    getUserProfileRequest$(personGuid),
    userId ? getUserProfileRequest$(userId) : Observable.of({}),
  ).pipe(
    map(([akelaProfile, sbProfile]) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { organizationPositions = [], ...userProfileData } = akelaProfile;
      const imagePath = get(sbProfile, 'profile.imagePath');
      const {
        organizationPositions: sbOrganizationPositions = [],
        profile: profileFromSB = {},
      } = sbProfile;
      userProfileData.profile = {
        ...userProfileData.profile,
        shortName: personNameBuilder.short(userProfileData.profile),
        fullName: personNameBuilder.full(userProfileData.profile),
        isAdult: profileFromSB.isAdult,
        imagePath,
      };
      userProfileData.sbProfile = sbProfile;
      return {
        ...userProfileData,
        sbOrganizationIds: sbOrganizationPositions.reduce(
          (acc, { organizationGuid, organizationId }) => {
            acc[organizationGuid] = organizationId;
            return acc;
          },
          {},
        ),
      };
    }),
  );

/**
 * @esbEndpoint GET /persons/:personGuid/roleTypes
 */
const getUserRolesRequest$ = id => {
  const params = USE_ROLETYPES_PARAMS
    ? '?includeScoutbookRoles=true&includeParentRoles=true'
    : '';
  return esbApiService.get$(`/persons/${id}/roleTypes${params}`, {
    gtm: {
      label: '/persons/{personGuid}/roleTypes',
    },
  });
};

const hasAdminRights = ({ role, roleTypes }) =>
  organizationRoles.ADMIN.includes(role) ||
  roleTypes.some(({ roleType }) => organizationRoles.ADMIN.includes(roleType));

const hasSpecialLoginRole = ({ role }) => specialLogInRoles.includes(role);

const hasAdvancementsCoordinatorRights = org =>
  org.role === organizationRoles.ADVANCEMENT_COORDINATOR;

const getCouncilRights = ({ role }) => {
  const hasCouncilEditRights = organizationRoles.COUNCIL_ADMINS.includes(role);
  const isCouncil =
    hasCouncilEditRights ||
    organizationRoles.DISTRICT_EXECUTIVES.includes(role);

  return {
    isCouncil,
    hasCouncilEditRights,
  };
};

const hasCouncilRights = org => getCouncilRights(org).isCouncil;

const hasMeritBadgeCounselRights = ({ role }) =>
  organizationRoles.MERIT_BADGE_COUNSELOR === role;

const hasYouthMemberRights = ({ role, roleTypes }) =>
  organizationRoles.YOUTH_MEMBER.includes(role) ||
  roleTypes.some(({ roleType }) =>
    organizationRoles.YOUTH_MEMBER.includes(roleType),
  );

const hasParentGuardianRights = ({ role, roleTypes }) =>
  organizationRoles.PARENT_GUARDIAN.includes(role) ||
  roleTypes.some(({ roleType }) =>
    organizationRoles.PARENT_GUARDIAN.includes(roleType),
  );

const hasCalendarEditorRights = ({ role, roleTypes }) =>
  organizationRoles.CALENDAR_EDITOR.includes(role) ||
  roleTypes.some(({ roleType }) =>
    organizationRoles.CALENDAR_EDITOR.includes(roleType),
  );

const hasAdminAkelaRole = ({ role, roleTypes }) =>
  adminAkelaRoles.includes(role) ||
  roleTypes.some(({ roleType }) => adminAkelaRoles.includes(roleType));

/**
 * @esbEndpoint GET /organizations/:orgGuid/units
 */
const getOrgUnitsRequest$ = organizationGuid =>
  esbApiService.get$(`/organizations/${organizationGuid}/units`, {
    gtm: {
      label: '/organizations/{organizationGuid}/units',
    },
  });

const getMissingUnitsInfo$ = roles => {
  // Get all units from councils
  const rolesByCouncil = uniqBy(roles, 'councilguid');
  const reqUnits = rolesByCouncil
    .filter(rol => !rol.programType || !rol.programTypeId)
    .map(rol => {
      if (rol.councilguid) {
        return getOrgUnitsRequest$(rol.councilguid);
      }
      return Observable.of({});
    });

  if (!reqUnits.length) {
    return Observable.of(roles);
  }

  return forkJoin(...reqUnits).pipe(
    map(res => {
      // Merge lists of units into one
      const unitGroups = [];
      if (res.length) {
        res.forEach(item => {
          if (item && item.units) {
            unitGroups.push(item.units);
          }
        });
      }
      const unitList = unitGroups.flat();

      // Update units with missing data
      const clonedRoles = clone(roles);
      const updatedRoles = clonedRoles.map(rol => {
        if (!rol.programType || !rol.programTypeId) {
          const foundUnit = unitList.find(
            unit => unit.unitGuid === rol.organizationGuid,
          );

          if (foundUnit) {
            return {
              ...rol,
              programType: foundUnit.programType,
              programTypeId: `${foundUnit.programId}`,
              acceptGender: foundUnit.acceptGender,
            };
          }
          return rol;
        }
        return rol;
      });

      return updatedRoles;
    }),
  );
};

const hasApproverSBRole = ({ role, roleTypes }) =>
  canApproveSBRoles.includes(role) ||
  roleTypes.some(({ roleType }) => canApproveSBRoles.includes(roleType));

const hasScoutbookRole = ({ role, roleTypes }) =>
  scoutbookRoles.includes(role) ||
  roleTypes.some(({ roleType }) => scoutbookRoles.includes(roleType));

const hasSubUnitsRights = ({ role, roleTypes, allRoles = [] }) =>
  subUnitRoles.includes(role) ||
  roleTypes.some(({ roleType }) => subUnitRoles.includes(roleType)) ||
  allRoles.some(rolName => subUnitRoles.includes(rolName));

const hasMBCRights = ({ role, roleTypes, allRoles = [] }) =>
  mBCounselorRoles.includes(role) ||
  roleTypes.some(({ roleType }) => mBCounselorRoles.includes(roleType)) ||
  allRoles.some(rolName => mBCounselorRoles.includes(rolName));

const hasCalendarPerms = ({ role, roleTypes, allRoles = [] }) =>
  canEditCalendarRoles.includes(role) ||
  organizationRoles.CALENDAR_EDITOR.includes(role) ||
  roleTypes.some(({ roleType }) => canEditCalendarRoles.includes(roleType)) ||
  allRoles.some(rolName => canEditCalendarRoles.includes(rolName));

const hasSubCalendarPerms = ({ role, roleTypes, allRoles = [] }) =>
  canEditSubCalendarRoles.includes(role) ||
  roleTypes.some(({ roleType }) =>
    canEditSubCalendarRoles.includes(roleType),
  ) ||
  allRoles.some(rolName => canEditSubCalendarRoles.includes(rolName));

const hasEditYouthProfileRolesPerms = ({ role, roleTypes, allRoles }) =>
  canEditYouthProfileRoles.includes(role) ||
  roleTypes.some(({ roleType }) =>
    canEditYouthProfileRoles.includes(roleType),
  ) ||
  allRoles.some(rolName => canEditYouthProfileRoles.includes(rolName));

const hasEditPaymentLogsPerms = ({ allRoles }) =>
  allRoles.some(name => canEditPaymentLogsRoles.includes(name));

const hasPermissionsManagerRights = ({ role, roleTypes, allRoles = [] }) =>
  canEditPermissionsManagerRoles.includes(role) ||
  organizationRoles.PERMISSIONS_MANAGER.includes(role) ||
  roleTypes.some(({ roleType }) =>
    canEditPermissionsManagerRoles.includes(roleType),
  ) ||
  allRoles.some(rolName => canEditPermissionsManagerRoles.includes(rolName));

const getUserRoles$ = (personGuid, userId) =>
  forkJoin(
    getUserRolesRequest$(personGuid),
    userId && !USE_ROLETYPES_PARAMS
      ? getUserRolesRequest$(userId)
      : Observable.of([]),
  ).pipe(
    map(([personGuidRoles, userIdRoles]) =>
      [
        ...personGuidRoles.map(role => ({
          ...role,
          isScoutbookRole: !!role.sbRoleId,
        })),
        ...userIdRoles.map(uir => ({ ...uir, isScoutbookRole: true })),
      ].filter(({ sbRoleId }) => (USE_ROLETYPES_PARAMS ? true : !sbRoleId)),
    ),
    map(dedupeRoleTypes),
    switchMap(roles =>
      forkJoin(
        Observable.of(roles),
        ...roles
          .filter(role => role.organizationGuid && !role.programTypeId)
          .map(role => unitSrv.getUnitInfo$(role.organizationGuid)),
      )
        // eslint-disable-next-line no-console
        .catch(err => console.error('err', err))
        .pipe(
          map(([roles, ...profiles]) => {
            const profilesByOrgId = keyBy(profiles, 'organizationGuid');
            return roles.map(role => {
              const org = profilesByOrgId[role.organizationGuid];
              if (!org) return role;
              return {
                ...role,
                programType:
                  role.programType || get(org, 'charter.programName'),
                programTypeId:
                  role.programTypeId || get(org, 'charter.programId'),
                acceptGender: role.acceptGender || org.acceptGender,
              };
            });
          }),
          map(roles => {
            const validOrganizations = roles.filter(
              org =>
                hasAdminRights(org) ||
                hasSpecialLoginRole(org) ||
                hasAdvancementsCoordinatorRights(org) ||
                hasCouncilRights(org) ||
                hasMeritBadgeCounselRights(org) ||
                hasYouthMemberRights(org) ||
                hasParentGuardianRights(org) ||
                hasCalendarEditorRights(org),
            );

            const validOrganizationsOrdered = stable(validOrganizations, org =>
              org.role === organizationRoles.ADVANCEMENT_COORDINATOR ? 1 : -1,
            );

            const compactOrganizationsSet = uniqWith(
              validOrganizationsOrdered,
              (organization, orgCompare) =>
                organization.organizationGuid === orgCompare.organizationGuid &&
                hasParentGuardianRights(organization) ===
                  hasParentGuardianRights(orgCompare),
            );

            return compactOrganizationsSet.map(compactOrganizations => ({
              ...compactOrganizations,
              isCalendarEditor: validOrganizationsOrdered.some(
                ({ role, organizationGuid }) =>
                  organizationRoles.CALENDAR_EDITOR.includes(role) &&
                  compactOrganizations.organizationGuid === organizationGuid,
              ),
            }));
          }),
          switchMap(roles => {
            if (
              !featureFlags.getFlag('SBL_5154_UNIT_SEARCH_UPDATE') &&
              USE_UNITS_ROLETYPES &&
              roles.length
            ) {
              return getMissingUnitsInfo$(roles);
            }

            return Observable.of(roles);
          }),
          map(roles =>
            roles
              .map(role => {
                const {
                  organizationName,
                  organizationNumber,
                  programTypeId: roleProgramTypeId,
                  programType,
                  isAkelaAdvChair,
                  ...rest
                } = role;
                const programTypeId =
                  roleProgramTypeId ||
                  programTypeNameForProgramTypeId[programType];
                const formattedOrganizationName =
                  removeLeadingZeros(organizationName);
                const formattedOrganizationNumber =
                  removeLeadingZeros(organizationNumber);
                const unitTypeId = unitTypeIdForProgramTypeId[+programTypeId];
                const isKey3 = role.roleTypes.some(({ roleType }) =>
                  key3Roles.includes(roleType),
                );
                const { isCouncil, hasCouncilEditRights } =
                  getCouncilRights(role);
                const isMeritBadgeCounselor = hasMeritBadgeCounselRights(role);
                const isYouthMember = hasYouthMemberRights(role);
                const isParentGuardian = hasParentGuardianRights(role);
                const isAdminAkelaRole = hasAdminAkelaRole(role);
                const isScoutbookRole =
                  !isAdminAkelaRole &&
                  !isAkelaAdvChair &&
                  (hasScoutbookRole(role) ||
                    hasAdvancementsCoordinatorRights(role));

                const canAproveAdvancements =
                  isScoutbookRole &&
                  (hasApproverSBRole(role) ||
                    hasAdvancementsCoordinatorRights(role));
                const canEditSubUnits = hasSubUnitsRights(role);
                const canSeeMBCs = hasMBCRights(role);
                const canEditCalendar =
                  hasCalendarPerms(role) || role.isCalendarEditor;
                // NOTE: canEditSubCalendar is being used for logic in calendar and roster
                // it's used for users that has limited access to only their subunits
                const canEditSubCalendar = hasSubCalendarPerms(role);
                const canEditYouthProfilePerms =
                  hasEditYouthProfileRolesPerms(role);

                const isCouncilAdmin = hasCouncilRights(role);
                const hasLogInRole = hasSpecialLoginRole(role);
                const canEditPaymentLogs = hasEditPaymentLogsPerms(role);
                const canEditPermissionsManager =
                  hasPermissionsManagerRights(role);

                return {
                  ...rest,
                  programType,
                  unitTypeId,
                  programTypeId,
                  isKey3,
                  isCouncil,
                  isCouncilAdmin,
                  hasCouncilEditRights,
                  isMeritBadgeCounselor,
                  isYouthMember,
                  isParentGuardian,
                  isScoutbookRole,
                  canAproveAdvancements,
                  canEditSubUnits,
                  canSeeMBCs,
                  canEditCalendar,
                  canEditSubCalendar,
                  hasLogInRole,
                  canEditPaymentLogs,
                  canEditPermissionsManager,
                  canEditYouthProfilePerms,
                  personGuid: isYouthMember && personGuid,
                  program: programForUnitTypeId[unitTypeId],
                  organizationName: formattedOrganizationName,
                  organizationNumber: formattedOrganizationNumber,
                };
              })
              .filter(
                ({
                  unitTypeId,
                  isCouncil,
                  isMeritBadgeCounselor,
                  isYouthMember,
                  isParentGuardian,
                  hasLogInRole,
                  organizationGuid,
                }) => {
                  if (!isCouncil && !organizationGuid && !isParentGuardian) {
                    return false;
                  }
                  return (
                    unitTypeId ||
                    isCouncil ||
                    hasLogInRole ||
                    ((SHOW_COUNSELED_YOUTH || ALLOW_MBC_LOGIN) &&
                      isMeritBadgeCounselor) ||
                    isYouthMember ||
                    isParentGuardian
                  );
                },
              ),
          ),
        ),
    ),
  );

/**
 * @esbEndpoint GET /persons/v2/:personGuid/trainings/ypt
 */
const getUserYPTrainingRequest$ = id =>
  esbApiService.get$(`/persons/v2/${id}/trainings/ypt`, {
    gtm: {
      label: '/persons/v2/{personGuid}/trainings/ypt',
    },
  });

/**
 * @esbEndpoint GET /persons/:personGuid/subscriptions
 */
const getUsersubscriptionsRequest$ = personGuid =>
  esbApiService.get$(`/persons/${personGuid}/subscriptions`, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions',
    },
  });

const getUserData$ = (personGuid, userId) =>
  forkJoin(
    getUserProfile$(personGuid, userId),
    getUserRoles$(personGuid, userId),
    getUserYPTrainingRequest$(personGuid),
    getUsersubscriptionsRequest$(personGuid),
  ).pipe(
    map(([profile, organizations, ypTraining, subscriptions]) => {
      const { sbOrganizationIds, ...profileData } = profile;
      return {
        ...profileData,
        ypTraining,
        subscriptions,
        hasCouncilPosition: organizations.some(({ isCouncil }) => isCouncil),
        hasCouncilEditRights: organizations.some(
          ({ hasCouncilEditRights }) => hasCouncilEditRights,
        ),
        organizations: organizations.map(org => {
          const organizationId = sbOrganizationIds[org.organizationGuid];
          return {
            ...org,
            unitId: organizationId,
          };
        }),
      };
    }),
  );

const logout = async (params = { isDiscourseSessionPreserved: true }) => {
  const isDiscourseSessionPreserved = !!params?.isDiscourseSessionPreserved;

  await webscriptApiService
    .get(
      `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=DiscourseSSOAgent&ScriptCode=ProcessSSOLogout&IsDiscourseSessionPreserved=${
        isDiscourseSessionPreserved ? 1 : 0
      }`,
      {
        suppressErrorToasts: true,
        suppressSentry: true,
        gtm: {
          label: '/logout-discourse',
        },
      },
    )
    .catch(noop);

  await coreApiService
    .get('/users/logout', {
      suppressErrorToasts: true,
      suppressSentry: true,
      gtm: {
        label: '/logout',
      },
      acceptVersion: false,
    })
    .catch(noop);
};

const storeDefaultApp = (personGuid, app) =>
  userPreferencesServices.storePreferences({
    featureName: defaultAppStorageName,
    personGuid,
    preferences: app,
  });

const retrieveDefaultApp = personGuid =>
  userPreferencesServices.retrievePreferences({
    personGuid,
    featureName: defaultAppStorageName,
  });

export default {
  login$,
  // googleSignInToken$,
  googleSignOut$,
  selfsession$,
  masquerade$,
  logout,
  getUserData$,
  getUserRoles$,
  storeDefaultApp,
  retrieveDefaultApp,
  ...userServices,
};
