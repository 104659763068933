import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setApiEnv, apiEnvSel } from '@config';
import { Radio } from '../bsaComponents';
import S from '../S';
import styles from './ApiSelector.less';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class ApiSelector extends React.PureComponent {
  handleChange = e => {
    const { value: env } = e.target;
    this.props.onSetApiEnv(env);
  };

  render() {
    const { apiEnv } = this.props;

    return (
      <div className={styles.container}>
        <S size="4" className={styles.title}>
          Select API type
        </S>
        <RadioGroup
          className={styles.radio}
          value={apiEnv}
          onChange={this.handleChange}
        >
          <RadioButton value="dv" className={styles.dv}>
            DV
          </RadioButton>
          <RadioButton value="qa" className={styles.qa}>
            QA
          </RadioButton>
          <RadioButton value="pp" className={styles.pp}>
            PP
          </RadioButton>
        </RadioGroup>
      </div>
    );
  }
}

ApiSelector.propTypes = {
  apiEnv: PropTypes.string.isRequired,
  onSetApiEnv: PropTypes.func.isRequired,
};

const mapState = state => ({
  apiEnv: apiEnvSel(state),
});

const mapDispatch = dispatch => ({
  onSetApiEnv: env => dispatch(setApiEnv(env)),
});

export default connect(mapState, mapDispatch)(ApiSelector);
