import { dateAndTimeToDateTime } from '@utils';
import { activityTypeIds, notApplicableCategories } from '@shared';

import {
  saveActivity$,
  mapPersonValuesToDto,
  activityValueTypes,
  normalizeAdvancedValues,
} from '../../common';

const getStartDateTime = (activityTypeId, data) => {
  const { startDate, startTime, endDate, endTime } = data;
  return {
    startDateTime: dateAndTimeToDateTime(startDate, startTime),
    endDateTime: dateAndTimeToDateTime(endDate, endTime),
  };
};

const getPersonValues = (activityTypeId, data) => {
  const {
    persons,
    organizationGuid,
    loggedInUserId,
    isAdvancedMode,
    isApproved,
  } = data;
  let personValuesConfig;
  switch (Number(activityTypeId)) {
    case activityTypeIds.SERVICE_PROJECTS: {
      const { youthHours, adultHours, hoursServed } = data;
      const basicValues = {
        [activityValueTypes.HOURS]: hoursServed,
      };
      personValuesConfig = {
        basicAdultValues: basicValues,
        basicYouthValues: basicValues,
        advancedValues: {
          [activityValueTypes.HOURS]: {
            ...normalizeAdvancedValues(youthHours),
            ...normalizeAdvancedValues(adultHours),
          },
        },
      };
      break;
    }
    case activityTypeIds.CAMPOUTS: {
      const {
        basicAdultDays,
        basicAdultNights,
        basicAdultFrostPoints,
        basicYouthDays,
        basicYouthNights,
        basicYouthFrostPoints,
        adultDays,
        youthDays,
        adultNights,
        youthNights,
        youthFrostPoints,
        adultFrostPoints,
      } = data;
      personValuesConfig = {
        basicAdultValues: {
          [activityValueTypes.DAYS]: basicAdultDays,
          [activityValueTypes.NIGHTS]: basicAdultNights,
          [activityValueTypes.FROST_POINTS]: basicAdultFrostPoints,
        },
        basicYouthValues: {
          [activityValueTypes.DAYS]: basicYouthDays,
          [activityValueTypes.NIGHTS]: basicYouthNights,
          [activityValueTypes.FROST_POINTS]: basicYouthFrostPoints,
        },
        advancedValues: {
          [activityValueTypes.DAYS]: {
            ...normalizeAdvancedValues(adultDays),
            ...normalizeAdvancedValues(youthDays),
          },
          [activityValueTypes.NIGHTS]: {
            ...normalizeAdvancedValues(adultNights),
            ...normalizeAdvancedValues(youthNights),
          },
          [activityValueTypes.FROST_POINTS]: {
            ...normalizeAdvancedValues(youthFrostPoints),
            ...normalizeAdvancedValues(adultFrostPoints),
          },
        },
      };
      break;
    }
    case activityTypeIds.LONG_CRUISE: {
      const { basicAdultDays, basicYouthDays, adultDays, youthDays } = data;
      personValuesConfig = {
        basicAdultValues: {
          [activityValueTypes.LONG_CRUISE_DAYS]: basicAdultDays,
        },
        basicYouthValues: {
          [activityValueTypes.LONG_CRUISE_DAYS]: basicYouthDays,
        },
        advancedValues: {
          [activityValueTypes.LONG_CRUISE_DAYS]: {
            ...normalizeAdvancedValues(adultDays),
            ...normalizeAdvancedValues(youthDays),
          },
        },
      };
      break;
    }
    case activityTypeIds.HIKES: {
      const {
        milesPerAdult,
        highestPointAdult,
        lowestPointAdult,
        milesPerYouth,
        highestPointYouth,
        lowestPointYouth,
        youthMiles,
        adultMiles,
        youthHighestPoint,
        adultHighestPoint,
        youthLowestPoint,
        adultLowestPoint,
      } = data;
      personValuesConfig = {
        basicAdultValues: {
          [activityValueTypes.MILES]: milesPerAdult,
          [activityValueTypes.HIGHEST_POINT]: highestPointAdult,
          [activityValueTypes.LOWEST_POINT]: lowestPointAdult,
        },
        basicYouthValues: {
          [activityValueTypes.MILES]: milesPerYouth,
          [activityValueTypes.HIGHEST_POINT]: highestPointYouth,
          [activityValueTypes.LOWEST_POINT]: lowestPointYouth,
        },
        advancedValues: {
          [activityValueTypes.MILES]: {
            ...normalizeAdvancedValues(youthMiles),
            ...normalizeAdvancedValues(adultMiles),
          },
          [activityValueTypes.HIGHEST_POINT]: {
            ...normalizeAdvancedValues(youthHighestPoint),
            ...normalizeAdvancedValues(adultHighestPoint),
          },
          [activityValueTypes.LOWEST_POINT]: {
            ...normalizeAdvancedValues(youthLowestPoint),
            ...normalizeAdvancedValues(adultLowestPoint),
          },
        },
      };
      break;
    }
  }
  personValuesConfig = {
    persons,
    organizationGuid,
    loggedInUserId,
    isAdvancedMode,
    isApproved,
    ...personValuesConfig,
  };
  return mapPersonValuesToDto(personValuesConfig);
};

const saveActivityForm$ = data => {
  const {
    activityTypeId,
    organizationGuid,
    isPersonalActivity = false,
    categoryId,
    isCalendarActivity,
  } = data;

  const { startDateTime, endDateTime } = getStartDateTime(activityTypeId, data);
  const personValues = getPersonValues(activityTypeId, data);

  const processedData = {
    ...data,
    ...personValues,
    activityTypeId: Number(activityTypeId),
    categoryId: notApplicableCategories[activityTypeId] || categoryId,
    startDateTime,
    endDateTime,
    hostOrganizationGuid: organizationGuid,
    isPersonalActivity,
    isEveryChildOrg: false,
    isCalendarActivity,
  };
  return saveActivity$(processedData);
};

export default {
  saveActivityForm$,
};
