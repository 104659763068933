import React from 'react';

import { ToastContainer as ToastifyContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import SlideFadeTransition from '../SlideFadeTransition';
import toastStyles from '../Toast/Toast.less';
import styles from './ToastContainer.less';

const ToastContainer = () => (
  <ToastifyContainer
    position={toast.POSITION.TOP_CENTER}
    type={toast.TYPE.DEFAULT}
    transition={SlideFadeTransition}
    autoClose={15000}
    hideProgressBar
    newestOnTop
    closeOnClick
    pauseOnHover
    className={styles.toastContainer}
    toastClassName={toastStyles.toast}
    bodyClassName={toastStyles.body}
  />
);

export default ToastContainer;
