export default uri => {
  const uriParams = uri.split('?')[1] || '';
  const params = new URLSearchParams(uriParams);
  if (typeof Object.fromEntries === 'undefined') {
    const outputObject = {};
    for (const pair of params.entries()) {
      outputObject[pair[0]] = pair[1];
    }
    return outputObject;
  }
  return Object.fromEntries(params.entries());
};
