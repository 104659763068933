import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { S } from '@shared';
import { detailModes } from '../../../constants';
import { selectedLongCruiseSel } from '../../../duck';
import styles from './EditInfo.less';

class EditInfo extends React.PureComponent {
  getTranslationId = () => {
    const { recurring, detailMode } = this.props;

    switch (detailMode) {
      case detailModes.CLONE: {
        return 'cloning';
      }
      case detailModes.EDIT: {
        if (recurring) {
          return 'editingOccurrence';
        }
        return 'editing';
      }
      case detailModes.EDIT_SERIES: {
        return 'editingSeries';
      }
      default:
        return false;
    }
  };

  render() {
    const translationId = this.getTranslationId();

    return (
      translationId && (
        <S size="4" className={styles.infoText}>
          <FormattedMessage id={`campouts.EditInfo.${translationId}`} />
        </S>
      )
    );
  }
}

EditInfo.propTypes = {
  // provided by parent
  detailMode: PropTypes.string.isRequired,
  // provided by component itself
  recurring: PropTypes.bool,
};

const mapState = state => ({
  recurring: (selectedLongCruiseSel(state) || {}).recurring,
});

export default connect(mapState)(EditInfo);
