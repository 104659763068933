/* eslint-disable react/display-name */

/* eslint-disable react/no-multi-comp */
import React from 'react';

import { FormattedMessage } from 'react-intl';

import {
  DateView,
  ExpandToggle,
  PersonAvatar,
  PhoneView,
  S,
  devMockHighlightClassName,
} from '@shared';

function rows({ extra, isMoreInfoOpen, onToggleMoreInfo }) {
  return function mbCounselorRows(mbc) {
    const {
      memberId,
      name,
      gender,
      address = {},
      phone = {},
      email,
      yptExpireDate,
      workingWithScoutsCount,
    } = mbc;

    return [
      {
        rowId: 'header',
        label: <PersonAvatar isAdult className={devMockHighlightClassName} />,
        content: (
          <S size="4" bold colored className={devMockHighlightClassName}>
            {name} ({gender})
          </S>
        ),
      },
      {
        rowId: 'memberId',
        label: (
          <S size="4" bold>
            <FormattedMessage id="mbc.column.memberId" />
          </S>
        ),
        content: (
          <S size="4" className={devMockHighlightClassName}>
            {memberId}
          </S>
        ),
      },
      {
        rowId: 'address',
        label: (
          <S size="4" bold>
            <FormattedMessage id="mbc.column.address" />
          </S>
        ),
        content: (
          <S size="4" className={devMockHighlightClassName}>
            {`${address.city}, ${address.state} ${address.zip}`}
          </S>
        ),
      },
      {
        rowId: 'email',
        label: (
          <S size="4" bold>
            <FormattedMessage id="mbc.column.email" />
          </S>
        ),
        content: (
          <S className={devMockHighlightClassName} size="4">
            {email}
          </S>
        ),
      },
      {
        rowId: 'phone',
        label: (
          <S size="4" bold>
            <FormattedMessage id="mbc.detailColumn.phone" />
          </S>
        ),
        content: (
          <S className={devMockHighlightClassName} size="4">
            <PhoneView phone={phone} />
          </S>
        ),
      },
      {
        rowId: 'yptExpires',
        label: (
          <S size="4" bold>
            <FormattedMessage id="mbc.detailColumn.yptExpiration" />
          </S>
        ),
        content: (
          <S className={devMockHighlightClassName} size="4">
            <DateView value={yptExpireDate} />
          </S>
        ),
      },
      {
        rowId: 'moreInfo',
        label: (
          <S size="4" bold>
            <FormattedMessage id="mbc.moreInfo" />
          </S>
        ),
        content: (
          <ExpandToggle expanded={isMoreInfoOpen} onChange={onToggleMoreInfo} />
        ),
        child: {
          open: isMoreInfoOpen,
          content: (
            <div>
              <S size="4" className={devMockHighlightClassName}>
                <FormattedMessage
                  id="mbc.workingWithScouts"
                  values={{
                    count: (
                      <S size="4" bold inlineBlock>
                        {workingWithScoutsCount}
                      </S>
                    ),
                  }}
                />
              </S>
              <S size="4" bold className={devMockHighlightClassName}>
                <FormattedMessage id="mbc.approvedByCouncil" />
              </S>
              <S size="4" bold className={devMockHighlightClassName}>
                <FormattedMessage id="mbc.counselorFor" />
              </S>
            </div>
          ),
        },
      },
      {
        rowId: 'extra',
        content: extra,
      },
    ];
  };
}

export default rows;
