import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { T } from '@shared';

import { activityImportedFromSel } from '../../duck';
import { activityImportedFromTypes } from '../../constants';

const getImportedTranslation = importType => {
  let translationId;
  switch (importType) {
    case activityImportedFromTypes.SCOUTBOOK_IMPORT:
      translationId = 'logImport';
      break;
    case activityImportedFromTypes.GTFA_IMPORT:
      translationId = 'gftaImport';
      break;
    default:
      return null;
  }
  return (
    <T size="5" bold>
      <i>
        <FormattedMessage id={`progress.common.${translationId}`} />
      </i>
    </T>
  );
};

const ImportText = () => {
  const activityImportedFrom = useSelector(activityImportedFromSel);
  return getImportedTranslation(activityImportedFrom);
};

export default ImportText;
