import get from 'lodash/get';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  hasPermissionSel,
  loginDataSel,
  masqueradeLoadingSel,
  masqueradingSel,
  permissionsSel,
  personGuidSel,
  safeLoginDataSel,
  safeUserDataSel,
  userDataSel,
} from '@shared/duck/selectors/general.selectors';

import { moduleName, permissions } from '../constants';

export {
  loginDataSel,
  personGuidSel,
  permissionsSel,
  hasPermissionSel,
  masqueradeLoadingSel,
  masqueradingSel,
};

const moduleSel = state => state[moduleName];

export const loginLoadingSel = state => moduleSel(state).loginLoading;
export const loginDataInitializedSel = state =>
  moduleSel(state).loginDataInitialized;

export const isLoggedInSel = createSelector(
  loginDataSel,
  loginData => !!(loginData && Object.keys(loginData).length > 0),
);
export const userDataInitializedSel = state =>
  moduleSel(state).userDataInitialized;
export const userDataPopulatedSel = createSelector(
  userDataSel,
  userData => !!(userData && Object.keys(userData).length > 0),
);

export const isCurrentRouteProtectedSel = createSelector(
  state => {
    const locationType = get(state, 'location.type');
    const currentRoute = get(state, ['location', 'routesMap', locationType]);
    return currentRoute;
  },
  currentRoute => {
    const isProtected = !currentRoute ? true : !currentRoute.unauthorizedAccess;
    return isProtected;
  },
);

export const userDataLoadingSel = state => moduleSel(state).userDataLoading;
export const tokenSel = state => safeLoginDataSel(state).token;
export const sessionTokenSel = state => safeLoginDataSel(state).sessionToken;
export const usernameSel = state =>
  safeLoginDataSel(state).account && safeLoginDataSel(state).account.username;
export const userIdSel = state =>
  safeLoginDataSel(state).account && safeLoginDataSel(state).account.userId;
export const profileSel = state => safeUserDataSel(state).profile || {};
export const shortNameSel = state => profileSel(state).shortName;

const tokenExpSel = state => moduleSel(state).tokenExp;
export const tokenExpDateSel = createSelector(
  tokenExpSel,
  tokenExp => tokenExp && moment.unix(tokenExp).toDate(),
);
export const isSessionValidSel = state => {
  const tokenExpDate = tokenExpDateSel(state);
  if (tokenExpDate) {
    return moment().isBefore(tokenExpDate);
  }
  return false;
};

export const lastSessionRefreshSel = state =>
  moduleSel(state).lastSessionRefresh || '';

export const defaultAppSel = state => moduleSel(state).defaultApp;

export const redirectingSel = state => moduleSel(state).redirecting;

export { safeLoginDataSel };
export { userDataSel };

export const timeoutModalOpenSel = state => moduleSel(state).timeoutModalOpen;

export const masqueradeModalOpenSel = state =>
  moduleSel(state).masqueradeModalOpen;

export const hasEditPermissionSel = state =>
  hasPermissionSel(state, permissions.EDIT_DATA);

export const canViewRosterSel = state =>
  hasPermissionSel(state, permissions.VIEW_ROSTER_PAGE);
