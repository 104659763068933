export {
  screenModuleName as moduleName,
  screenMd,
  screenLg,
  screenXl,
  mdWidth,
  lgWidth,
} from '@shared/constants';

export const phone = 'phone';
export const tablet = 'tablet';
export const desktop = 'desktop';

export const screenXs = 'xs';
export const screenSm = 'sm';
export const screenXxl = 'xxl';

export const xsWidth = 480;
export const smWidth = 768;
export const xlWidth = 1600;

export const phoneMaxWidth = 600;
export const tabletMaxWidth = 1000;
