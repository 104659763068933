import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import '@utils/rxjs.add.operator.catchAndReport';

import { dictionaryIds } from '../constants';
import {
  DICTIONARIES_REQUEST,
  SCHOOL_DICTIONARIES_REQUEST,
  dictionariesError,
  dictionariesResponse,
} from './actions';
import { dictionarySel } from './selectors';
import services from './services';

const mapDictionaryIdsToRequests = (ids, state) =>
  ids.map(id => {
    const dictionary = dictionarySel(state, id);
    if (dictionary) {
      return Observable.of({ [id]: dictionary });
    }
    switch (id) {
      case dictionaryIds.ADVENTURES: {
        return services.fetchAdventures$();
      }
      case dictionaryIds.AWARDS: {
        return services.fetchAwards$();
      }
      case dictionaryIds.MERIT_BADGES: {
        return services.fetchMeritBadges$();
      }
      case dictionaryIds.RANKS: {
        return services.fetchRanks$();
      }
      case dictionaryIds.SSELECTIVES: {
        return services.fetchSSElectives$();
      }
      case dictionaryIds.STATES: {
        return services.fetchStates$();
      }
      case dictionaryIds.COUNTRIES: {
        return services.fetchCountries$();
      }
      case dictionaryIds.PHONE_COUNTRIES: {
        return services.fetchPhoneCountries$();
      }
      case dictionaryIds.COMUNICATION_TYPES: {
        return services.fetchComunicationTypes$();
      }
      case dictionaryIds.PHONE_CARRIERS: {
        return services.fetchPhoneCarriers$();
      }
      case dictionaryIds.PREFIXES: {
        return services.fetchPrefixes$();
      }
      case dictionaryIds.SUFFIXES: {
        return services.fetchSuffixes$();
      }
      case dictionaryIds.SCHOOLS: {
        return services.fetchSchools$();
      }
      case dictionaryIds.GRADES: {
        return services.fetchGrades$();
      }
      case dictionaryIds.SWIMMIN_CLASSIFICATION: {
        return services.fetchSwimmingClassifications$();
      }
      default: {
        throw new Error(
          `Invalid dictionary key: ${id}. Accepted keys: ${Object.values(
            dictionaryIds,
          )}`,
        );
      }
    }
  });

const fetchDictionariesEpic$ = (action$, state$) =>
  action$.ofType(DICTIONARIES_REQUEST).switchMap(({ payload: ids }) => {
    const requests = mapDictionaryIdsToRequests(ids, state$.value);
    return Observable.forkJoin(...requests)
      .map(dictionaries =>
        dictionaries.reduce(
          (acc, dict) => ({
            ...acc,
            ...dict,
          }),
          {},
        ),
      )
      .map(dictionariesResponse)
      .catchAndReport(err => Observable.of(dictionariesError(err, ids)));
  });

const fetchSchoolDictionariesEpic$ = action$ =>
  action$.ofType(SCHOOL_DICTIONARIES_REQUEST).switchMap(({ payload }) =>
    services
      .fetchSchools$(payload)
      .map(dictionariesResponse)
      .catchAndReport(err =>
        Observable.of(dictionariesError(err, [dictionaryIds.SCHOOLS])),
      ),
  );

export default combineEpics(
  fetchDictionariesEpic$,
  fetchSchoolDictionariesEpic$,
);
