import React, { useMemo } from 'react';

import { formatNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';

const usCountryCode = 1;

const isUsCountryCode = countryCode => +countryCode === usCountryCode;

const PhoneView = ({ phone, className }) => {
  const {
    countryCode = usCountryCode,
    areaCode,
    prefix,
    lineNumber,
    extension = '',
  } = phone || {};

  const formatted = useMemo(() => {
    if (!areaCode || !prefix || !lineNumber) {
      return '';
    }
    const format = isUsCountryCode(countryCode) ? 'National' : 'International';
    return formatNumber(
      {
        phone: `+${+countryCode}${areaCode}${prefix}${lineNumber}`,
        ext: extension,
      },
      format,
    );
  }, [areaCode, countryCode, extension, lineNumber, prefix]);

  return <span className={className}>{formatted}</span>;
};

PhoneView.propTypes = {
  phone: PropTypes.shape({
    countryCode: PropTypes.string,
    areaCode: PropTypes.string,
    prefix: PropTypes.string,
    lineNumber: PropTypes.string,
    extension: PropTypes.string,
  }),
  countryCode: PropTypes.bool,
  className: PropTypes.string,
};

export default PhoneView;
