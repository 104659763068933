import { gtm } from '@modules/shared/utils';

export default (uri, { organizationGuid = '', personGuid = '' }) => {
  const url = new URL(uri);
  const { searchParams } = url;

  organizationGuid && searchParams.set('OrganizationGUID', organizationGuid);
  personGuid && searchParams.set('PersonGUID', personGuid);

  url.search = searchParams.toString();

  window.open(url.toString(), '_blank');

  gtm.pushEvent({
    event: 'BASIC_REPORT_DOWNLOAD',
    category: gtm.categories.REPORTS,
  });
};

export function openReportWithQueryParams(
  uri,
  { organizationGuid = '', ...extraParams },
) {
  const url = new URL(uri);
  const { searchParams } = url;

  if (organizationGuid) {
    searchParams.set('OrganizationGUID', organizationGuid);
  }

  if (extraParams) {
    for (const key in extraParams) {
      const value = extraParams[key];
      searchParams.set(key, value);
    }
  }

  const finalUrl = url.toString();
  window.open(finalUrl, '_blank');
}
