import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import { AjaxError } from 'rxjs-compat';

interface SentryReportAPIType {
  request: {
    url?: string;
    body?: unknown;
    responseURL?: string;
    gtm?: {
      label: string;
    };
    transformBodyForSentry?: () => unknown;
  };
  status: string;
}

interface SentryReportGenericType {
  message: string;
}

const api = (error: SentryReportAPIType | AjaxError) => {
  const request = error.request || {};
  const status = error.status || '';
  const {
    url = '',
    responseURL = '',
    gtm,
    transformBodyForSentry,
  } = request as SentryReportAPIType['request'];
  const message = `[API] ${status} ${gtm?.label || url || responseURL}`;

  if (transformBodyForSentry) {
    request.body = transformBodyForSentry();
  }

  if (url.indexOf('adobe') !== -1) {
    Sentry.withScope(function (scope) {
      const prefix = `[API][Adobe] ${status}`;
      scope.setTag('service', 'adobe');
      scope.setTransactionName(
        url.indexOf('transientDocuments') !== -1
          ? 'transientDocuments'
          : url.indexOf('baseUris') !== -1
          ? 'baseUris'
          : 'adobe-endpoint',
      );
      scope.setTag('logger', 'api');
      scope.setExtra('error', error);
      const level = 'error' as Sentry.SeverityLevel;
      scope.setLevel(level);
      const title =
        get(error, 'response.code') || get(error, 'response.message');

      Sentry.captureEvent({
        logger: 'api',
        request: request as SentryReportAPIType['request'],
        message: title
          ? `${prefix} ${title}`
          : `${prefix} ${url || responseURL}`,
      });
    });
  } else {
    Sentry.captureEvent({
      message,
      logger: 'api',
      extra: {
        error,
      },
    });
  }
};

const ui = (error: SentryReportGenericType) => {
  const { message } = error || {};

  Sentry.captureEvent({
    message: `[UI] ${message}`,
    logger: 'ui',
    extra: {
      error,
    },
  });
};

const rxjs = (error: SentryReportGenericType) => {
  const { message } = error;

  Sentry.captureEvent({
    message: `[RXJS] ${message}`,
    logger: 'rxjs',
    extra: {
      error,
    },
  });
};

const feedback = (title: string, feedback: Record<string, unknown>) => {
  title = `[F] ${title}`;

  if (title.length > 200) {
    title = `${title.substring(0, 200)}...`;
  }

  Sentry.captureEvent({
    message: title,
    logger: 'feedback',
    level: 'info',
    extra: {
      ...feedback,
    },
  });
};

const info = (data: SentryReportGenericType) => {
  const { message } = data || {};

  Sentry.captureEvent({
    message: `[INFO] ${message}`,
    logger: 'debug',
    extra: {
      data,
    },
  });
};

export default {
  api,
  ui,
  rxjs,
  feedback,
  info,
};
