import React from 'react';
import PropTypes from 'prop-types';
import { default as ReactPhoneInput } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import cn from 'classnames';
import metadata from 'libphonenumber-js/metadata.min';
import { parseNumber } from 'libphonenumber-js';

import CountrySelect from './CountrySelect';
import countryFinder from './countryFinder';
import styles from './PhoneInput.less';

const fixCountryCode = code => {
  if (!code) {
    return '';
  }

  if (code == '01' || code == '001') {
    return '+1 ';
  }
  return code.startsWith('+') ? code : `+${code}`;
};

//To map geaorgia with south georgia
const mapCountryCode = code => {
  if (code === '500') {
    return '995';
  }
  return code;
};

class PhoneInput extends React.PureComponent {
  static getDerivedStateFromProps(props, prevState) {
    if (props.value) {
      const {
        countryCode,
        areaCode = '',
        prefix = '',
        lineNumber = '',
      } = props.value;
      const updatedCountryCode = mapCountryCode(countryCode);
      const defaultCountry = countryFinder(updatedCountryCode);
      const fullPhone = `${fixCountryCode(
        updatedCountryCode,
      )}${areaCode}${prefix}${lineNumber}`;

      if (fullPhone !== prevState.fullPhone) {
        return { fullPhone, defaultCountry };
      } else {
        return { defaultCountry };
      }
    }
    return null;
  }

  state = {
    selectOpen: false,
    fullPhone: '',
    defaultCountry: 'US',
  };

  inputRef;

  extractPhoneCountryId = (countryCode, country) => {
    const { phoneCountryCodes } = this.props;
    let bsaCountryCode = (+countryCode).toString();
    if (bsaCountryCode === '1') {
      // Canada and US have the same country code "+1"
      // BSA database has 01 and 001 respectively for those countries
      // This is a small fix
      bsaCountryCode = country === 'US' ? '001' : '01';
    }

    // 995 is Georgia, but for some reason in phoneCountryCodes only appears South Georgia with code 500
    if (bsaCountryCode == 995) {
      bsaCountryCode = '500';
    }
    const bsaPhoneCountry = phoneCountryCodes.find(
      country => country.short === bsaCountryCode,
    );
    return bsaPhoneCountry ? bsaPhoneCountry.id : '';
  };

  phoneToObject = (phoneNumber = '') => {
    const internationalPhone = phoneNumber.startsWith('+')
      ? phoneNumber
      : `+1${phoneNumber}`;
    const { phone = '', country = 'US' } = parseNumber(internationalPhone, {
      defaultCountry: 'US',
      extended: true,
    });

    const countryCode = internationalPhone.slice(
      0,
      internationalPhone.indexOf(phone),
    );

    const countryId = this.extractPhoneCountryId(countryCode, country);

    if (!phone) {
      return;
    }

    return {
      countryCode,
      country,
      countryId,
      areaCode: phone.slice(0, 3),
      prefix: phone.slice(3, 6),
      lineNumber: phone.slice(6, 10),
    };
  };

  handleOpenSelect = open => {
    this.setState({ selectOpen: open });
  };

  handleChange = value => {
    const phoneObject = this.phoneToObject(value);
    this.props.onChange(phoneObject);
  };

  handleCountryChange = () => {
    setTimeout(() => this.inputRef.focus());
    this.setState({ selectOpen: false });
  };

  storeInputRef = el => (this.inputRef = el);

  render() {
    const { className, disabled, id, size } = this.props;
    const { selectOpen, fullPhone, defaultCountry } = this.state;

    const inputClassNames = cn('ant-input', styles.phoneInput, className, {
      [styles.hidden]: selectOpen,
      [styles.phoneInputLg]: size === 'large',
    });

    return (
      <ReactPhoneInput
        id={id}
        flags={flags}
        placeholder="(555) 555-5555"
        country={defaultCountry}
        value={fullPhone}
        onChange={this.handleChange}
        className={styles.container}
        inputClassName={inputClassNames}
        countrySelectComponent={CountrySelect}
        metadata={metadata}
        onOpenSelect={this.handleOpenSelect}
        size={size}
        disabled={disabled}
        selectOpen={selectOpen}
        onCountryChange={this.handleCountryChange}
        ref={this.storeInputRef}
      />
    );
  }
}

PhoneInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.oneOf(['default', 'large']),
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  phoneCountryCodes: PropTypes.array.isRequired,
};

PhoneInput.defaultProps = {
  size: 'default',
};

export default PhoneInput;
