import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Input, S, T, intl } from '@shared';
import { loginLoadingSel, loginRequest, usernameSel } from '@user';

import styles from './SessionExpiredModal.less';

class SessionExpiredContent extends React.PureComponent {
  state = {
    password: '',
  };

  isValidForm = () => this.state.password;

  handleSubmit = e => {
    e.preventDefault();
    const { onLogin, currentUsername } = this.props;
    const { password } = this.state;
    if (this.isValidForm()) {
      onLogin(currentUsername, password);
    }
  };

  handlePasswordInputChange = event => {
    this.setState({
      password: event.target.value,
    });
  };

  render() {
    const { loading, currentUsername } = this.props;
    const { password } = this.state;
    const isValidForm = this.isValidForm();
    const disabled = loading || !isValidForm;

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <ErrorOutlineIcon className={styles.icon} />
          <T size="4">
            <FormattedMessage id="offline.SessionExpiredModal.title" />
          </T>
        </div>
        <S size="4" className={styles.description}>
          <FormattedMessage id="offline.SessionExpiredModal.description" />
        </S>
        <form className={styles.loginForm} onSubmit={this.handleSubmit}>
          <Input
            type="text"
            name="login"
            size="large"
            disabled
            placeholder={intl.formatMessage({
              id: 'offline.SessionExpiredModal.username.placeholder',
            })}
            className={styles.loginInput}
            value={currentUsername}
          />
          <Input
            type="password"
            name="password"
            size="large"
            disabled={loading}
            placeholder={intl.formatMessage({
              id: 'offline.SessionExpiredModal.password.placeholder',
            })}
            className={styles.passwordInput}
            value={password}
            onChange={this.handlePasswordInputChange}
          />
          <Button
            htmlType="submit"
            type="primary"
            size="large"
            shadow
            disabled={disabled}
            loading={loading}
          >
            <FormattedMessage id="offline.SessionExpiredModal.button" />
          </Button>
        </form>
      </div>
    );
  }
}

SessionExpiredContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  currentUsername: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
};

const mapState = state => ({
  currentUsername: usernameSel(state),
  loading: loginLoadingSel(state),
});

const mapDispatch = dispatch => ({
  onLogin: (login, password) => dispatch(loginRequest(login, password)),
});

export default connect(mapState, mapDispatch)(SessionExpiredContent);
