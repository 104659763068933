import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { searchHeightMobile } from '../../constants';
import HeadroomAffix from '../HeadroomAffix';
import styles from './TableMobile.less';

function StickyTitle({ children, offsetTop, clearSearch, className }) {
  offsetTop += clearSearch ? searchHeightMobile : 0;

  return (
    <HeadroomAffix clearHeader offsetTop={offsetTop} disablePin>
      <div className={cn(styles.rowTitle, className)}> {children} </div>
    </HeadroomAffix>
  );
}

StickyTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  offsetTop: PropTypes.number,
  clearSearch: PropTypes.bool,
};

StickyTitle.defaultProps = {
  offsetTop: 0,
};

export default StickyTitle;
