import uniqBy from 'lodash/uniqBy';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { organizationGuidSel, organizationTypeIdSel } from '@context';
import { setSelectedRosterKeys } from '@shared/duck';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { userIdSel } from '@user';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  loadAndOpenActivity,
  personsAndIdsActivitySel,
  personsToRemoveSel,
  savingAction,
  selectedActivitySel,
  setTime,
} from '../../common';
import {
  LOAD_AND_OPEN_SERVICE_MODAL,
  RECORD_SERVICE_REQUEST,
  RECORD_SERVICE_RESPONSE,
  closeService,
  loadAndOpenServiceModalError,
  openEditService,
  recordServiceError,
  recordServiceResponse,
} from './actions';
import { activeTabSel, personsSel } from './selectors';
import services from './services';

const recordServiceEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RECORD_SERVICE_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { activityData } = payload;
      const persons = personsSel(state);
      const personsToRemove = personsToRemoveSel(state);
      const loggedInUserId = userIdSel(state);
      const organizationGuid = organizationGuidSel(state);
      const organizationTypeId = organizationTypeIdSel(state);
      const selectedActivity = selectedActivitySel(state);
      const isAdvancedMode = activeTabSel(state) == 'advanced';
      const { personsInActivity } = personsAndIdsActivitySel(state);
      let validActivityData = { ...activityData };
      if (activityData.allDay) {
        validActivityData.startTime = setTime();
        validActivityData.endTime = setTime(23, 59);
      }

      return services
        .saveServiceActivity$({
          ...selectedActivity,
          ...validActivityData,
          personsToRemove,
          loggedInUserId,
          organizationGuid,
          organizationTypeId: +organizationTypeId,
          persons: uniqBy([...personsInActivity, ...persons], 'userId'),
          isAdvancedMode,
        })
        .pipe(
          mergeMap(() => {
            const { closeOnSuccess } = payload;
            const actions = [recordServiceResponse()].concat(
              closeOnSuccess ? [closeService(), setSelectedRosterKeys([])] : [],
            );

            return of(...actions);
          }),
          tap(({ type }) => {
            if (type === RECORD_SERVICE_RESPONSE) {
              toastService.success(
                intl.formatMessage(
                  {
                    id: `progress.common.${
                      persons.length ? 'saveSuccessParticipants' : 'saveSuccess'
                    }`,
                  },
                  {
                    name: activityData.name,
                    type: selectedActivity.id
                      ? savingAction.UPDATED
                      : savingAction.CREATED,
                    count: persons.length,
                    participants: persons
                      .map(({ personShortFullName }) => personShortFullName)
                      .join(', '),
                  },
                ),
              );
            }
          }),
          catchAndReport(err => of(recordServiceError(err))),
        );
    }),
  );

const loadLongCruiseByIdEpic$ = action$ =>
  action$.pipe(
    ofType(LOAD_AND_OPEN_SERVICE_MODAL),
    map(({ payload }) =>
      loadAndOpenActivity({
        ...payload,
        successActionFn: openEditService,
        errorActionFn: loadAndOpenServiceModalError,
      }),
    ),
  );

export default combineEpics(recordServiceEpic$, loadLongCruiseByIdEpic$);
