import React from 'react';

import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { organizationGuidSel } from '@context';
import { useUnitPendingLeadershipPositions } from '@modules/leadershipPositions/hooks';
import { useGetOrgAdvancementsDashboardQuery } from '@modules/rtk/esb-api';
import { Tooltip, intl } from '@modules/shared';
import {
  goToActivity,
  hasNotificationsToShowSel,
  notificationCountsSel,
  openAdvancementReportModal,
} from '@modules/userNotifications/duck';

import BadgeNotificationItem from './BadgeNotificationItem/BadgeNotificationItem';
import styles from './Notifications.less';

export const NotificationsModalContentV2: React.FC<{
  onClose: () => void;
}> = () => {
  const dispatch = useDispatch();
  const { pendingCount: positionsCount } = useUnitPendingLeadershipPositions();
  const hasNotificationsToShow = useSelector(hasNotificationsToShowSel);
  const orgGuid = useSelector(organizationGuidSel);
  const counts = useSelector(notificationCountsSel);
  const { data: stats } = useGetOrgAdvancementsDashboardQuery(
    { organizationGuid: orgGuid },
    {
      skip: !orgGuid,
    },
  );

  if (!hasNotificationsToShow && !positionsCount && !counts.totalCounts) {
    return <FormattedMessage id="userNotifications.Notifications.empty" />;
  }

  const onOpenAdvancementItem = () => dispatch(goToActivity());
  const onOpenAdvancementReportModal = () =>
    dispatch(openAdvancementReportModal());

  const toApproveItems = [
    [
      stats?.completed?.ranks,
      'userNotifications.Notifications.toApprove.ranksCount',
      { count: Number(stats?.completed?.ranks) },
    ],
    [
      stats?.completed?.adventures,
      'userNotifications.Notifications.toApprove.adventuresCount',
      { count: Number(stats?.completed?.adventures) },
    ],
    [
      stats?.completed?.meritBadges,
      'userNotifications.Notifications.toApprove.mbsCount',
      { count: Number(stats?.completed?.meritBadges) },
    ],
    [
      stats?.completed?.awards,
      'userNotifications.Notifications.toApprove.awardsCount',
      { count: Number(stats?.completed?.awards) },
    ],
    [
      _.sum(Object.values(stats?.completed?.requirements || {})),
      'userNotifications.Notifications.toApprove.reqsCount',
      { count: _.sum(Object.values(stats?.completed?.requirements || {})) },
    ],
    [
      positionsCount,
      'userNotifications.Notifications.toApprove.positionsCount',
      { count: positionsCount || 0 },
    ],
  ] as const;

  const tooltip = toApproveItems
    .map(item => {
      const [num, id, values] = item;
      if (!num) return null;

      const msg = intl.formatMessage(
        {
          id: id,
        },
        values,
      );
      return msg;
    })
    .filter(Boolean)
    .join(', ');

  return (
    <div className={styles.notificationsWrapper}>
      {counts.toApproveCount > 0 && (
        <Tooltip title={tooltip} placement="right">
          <div>
            <BadgeNotificationItem
              count={counts.toApproveCount}
              onClick={onOpenAdvancementItem}
              title={
                <FormattedMessage id="userNotifications.Notifications.itemsToApprove" />
              }
              description={
                <FormattedMessage
                  id="userNotifications.Notifications.itemsToApproveDescription"
                  values={{ pendingItemsToApprove: counts.toApproveCount }}
                />
              }
            />
          </div>
        </Tooltip>
      )}

      {counts.toPurchaseCount > 0 && (
        <BadgeNotificationItem
          id="qa_notificationAdvReport"
          title={
            <FormattedMessage id="userNotifications.NotificationsModalContent.needsPurchasing" />
          }
          description={
            <FormattedMessage id="userNotifications.NotificationsModalContent.viewScoutShopReport" />
          }
          count={counts.toPurchaseCount}
          onClick={onOpenAdvancementReportModal}
        />
      )}
    </div>
  );
};
