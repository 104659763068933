import { combineReducers } from 'redux';

import { advancementStatuses } from '@shared';
import { resetOnLogoutReducer, resetOnActionsReducer } from '@utils';
import {
  OPEN_APPROVE_ADVANCEMENTS,
  OPEN_DECLINE_MULTIPLE_ADVANCEMENTS,
  CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS,
  CLOSE_APPROVE_ADVANCEMENTS,
  SET_ACTIVE_YOUTH,
  DESELECT_PERSON,
  DESELECT_COMPLETED_ADVANCEMENT,
  APPROVE_ADVANCEMENTS_REQUEST,
  APPROVE_ADVANCEMENTS_RESPONSE,
  APPROVE_ADVANCEMENTS_ERROR,
  APPROVE_ADVANCEMENTS_DEFERRED,
  SET_FROM_ACTIVITY_LOGS,
  DECLINE_ADVANCEMENTS_REQUEST,
  DECLINE_ADVANCEMENTS_DEFERRED,
  DECLINE_ADVANCEMENTS_ERROR,
} from './actions';

import {
  RECORD_ADVANCEMENT_RESPONSE,
  RECORD_ADVANCEMENT_DEFERRED,
  RECORD_ADVANCEMENT_ERROR,
} from '../../advancement/duck/actions';

const visible = (state = false, { type }) => {
  switch (type) {
    case OPEN_APPROVE_ADVANCEMENTS: {
      return true;
    }
    case APPROVE_ADVANCEMENTS_DEFERRED:
    case CLOSE_APPROVE_ADVANCEMENTS: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const fromActivityLogs = (state = false, { payload, type }) => {
  switch (type) {
    case SET_FROM_ACTIVITY_LOGS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const activeUserId = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_YOUTH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case DECLINE_ADVANCEMENTS_REQUEST:
    case APPROVE_ADVANCEMENTS_REQUEST: {
      return true;
    }
    case DECLINE_ADVANCEMENTS_DEFERRED:
    case DECLINE_ADVANCEMENTS_ERROR:
    case RECORD_ADVANCEMENT_RESPONSE:
    case RECORD_ADVANCEMENT_DEFERRED:
    case RECORD_ADVANCEMENT_ERROR:
    case APPROVE_ADVANCEMENTS_DEFERRED:
    case APPROVE_ADVANCEMENTS_ERROR:
    case APPROVE_ADVANCEMENTS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const approvalError = (state = null, { type }) => {
  switch (type) {
    case APPROVE_ADVANCEMENTS_REQUEST: {
      return null;
    }
    case APPROVE_ADVANCEMENTS_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const personsAdvancements = (state = [], { type, payload }) => {
  switch (type) {
    case OPEN_DECLINE_MULTIPLE_ADVANCEMENTS:
    case OPEN_APPROVE_ADVANCEMENTS: {
      return payload.personsAdvancements;
    }
    case CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS:
    case CLOSE_APPROVE_ADVANCEMENTS: {
      return [];
    }
    case DESELECT_COMPLETED_ADVANCEMENT: {
      return state.filter(({ id }) => id != payload.id);
    }
    case DESELECT_PERSON: {
      return state.filter(({ userId }) => userId != payload.userId);
    }
    default: {
      return state;
    }
  }
};

const advancementStatus = (
  state = advancementStatuses.APPROVED,
  { type, payload },
) => {
  switch (type) {
    case OPEN_DECLINE_MULTIPLE_ADVANCEMENTS:
    case OPEN_APPROVE_ADVANCEMENTS: {
      return payload.advancementStatus || advancementStatuses.APPROVED;
    }
    case CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS:
    case CLOSE_APPROVE_ADVANCEMENTS: {
      return advancementStatuses.APPROVED;
    }
    default: {
      return state;
    }
  }
};

const isVisibleDeclineMultipleAdvancementsModal = (state = false, { type }) => {
  switch (type) {
    case OPEN_DECLINE_MULTIPLE_ADVANCEMENTS: {
      return true;
    }
    case APPROVE_ADVANCEMENTS_RESPONSE:
    case CLOSE_DECLINE_MULTIPLE_ADVANCEMENTS: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  isVisibleDeclineMultipleAdvancementsModal,
  visible,
  activeUserId,
  loading,
  approvalError,
  personsAdvancements,
  advancementStatus,
  fromActivityLogs,
});

export default resetOnActionsReducer(
  resetOnLogoutReducer(reducer),
  APPROVE_ADVANCEMENTS_RESPONSE,
);
