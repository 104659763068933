import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Vr.less';

// vertical rule
const Vr = ({ className }) => {
  const classNames = cn(styles.vr, className);

  return <div className={classNames} />;
};

Vr.propTypes = {
  className: PropTypes.string,
};

export default Vr;
