import { createSelector } from 'reselect';

import { Program, selectedOrganizationSel } from '@modules/shared';

export const programSel = createSelector(
  selectedOrganizationSel,
  org => org?.program,
);

export const isSeaScoutSel = createSelector(
  programSel,
  program => program === Program.SEA_SCOUT,
);

export const isScoutsBsaUnitSel = createSelector(
  programSel,
  program => program === Program.BOY_SCOUT,
);

export const isVenturingUnitSel = createSelector(
  programSel,
  program => program === Program.VENTURING,
);

export const isCubScoutsUnitSel = createSelector(
  programSel,
  program => program === Program.CUB_SCOUT,
);
