import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { defaultFilterOptions } from '../constants';
import {
  MBC_REQUEST,
  MBC_RESPONSE,
  MBC_ERROR,
  SET_PAGE,
  SET_PAGE_SIZE_MOBILE,
  SET_EXPANDED_ROW,
  SET_FILTER,
  SET_SEARCH,
  OPEN_EDIT_MODAL,
  CLOSE_EDIT_MODAL,
  SAVE_MBC_REQUEST,
  SAVE_MBC_RESPONSE,
  SAVE_MBC_ERROR,
} from './actions';

const items = (state = [], { type, payload }) => {
  switch (type) {
    case MBC_RESPONSE: {
      return payload.items;
    }
    case MBC_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const total = (state = 0, { type, payload }) => {
  switch (type) {
    case MBC_RESPONSE: {
      return payload.total;
    }
    case MBC_ERROR: {
      return 0;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case MBC_REQUEST: {
      return true;
    }
    case MBC_RESPONSE:
    case MBC_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const page = (state = 1, { type, payload }) => {
  switch (type) {
    case SET_PAGE: {
      return payload;
    }
    case MBC_RESPONSE: {
      if (Math.ceil(payload.total / payload.pageSize) >= state) {
        return state;
      }
      return 1;
    }
    case MBC_ERROR: {
      return 1;
    }
    default: {
      return state;
    }
  }
};

const defaultPageSizeMobile = 20;
const pageSizeMobile = (state = defaultPageSizeMobile, { type, payload }) => {
  switch (type) {
    case SET_PAGE_SIZE_MOBILE: {
      return payload;
    }
    case SET_FILTER:
    case SET_SEARCH:
      return defaultPageSizeMobile;
    default: {
      return state;
    }
  }
};

const expandedRow = (state = '', { type, payload }) => {
  switch (type) {
    case SET_EXPANDED_ROW: {
      if (state == payload) {
        return '';
      }
      return payload;
    }
    case MBC_RESPONSE: {
      if (payload.items.some(({ key }) => key === state)) {
        return state;
      }
      return '';
    }
    default: {
      return state;
    }
  }
};

const filter = (state = defaultFilterOptions, { type, payload }) => {
  switch (type) {
    case SET_FILTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const mbcToEdit = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_MODAL: {
      return payload;
    }
    case CLOSE_EDIT_MODAL:
    case SAVE_MBC_RESPONSE: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const editModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_MODAL: {
      return true;
    }
    case CLOSE_EDIT_MODAL:
    case SAVE_MBC_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const editLoading = (state = false, { type }) => {
  switch (type) {
    case SAVE_MBC_REQUEST: {
      return true;
    }
    case SAVE_MBC_RESPONSE:
    case SAVE_MBC_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  items,
  total,
  loading,
  page,
  pageSizeMobile,
  expandedRow,
  filter,
  search,
  mbcToEdit,
  editModalOpen,
  editLoading,
});

export default resetOnLogoutReducer(reducer);
