import { gtm } from '@modules/shared';
import { basicActionCreator, getAdvancementDescription } from '@utils';

import {
  openApproveAdvancements as _openApproveAdvancements,
  openEditAdvancement as _openEditAdvancement,
  openEagleScoutApplication,
  openUnapproveModal,
} from '../../progress/duck/actions';
import { UPDATE_ADV_SUMMARY_REQUEST } from '../../progress/editAdvancement/duck';
import { moduleName } from '../constants';
import { youthInfoSel } from './selectors';

export {
  ROUTE_YOUTH_ADV_REQUIREMENTS,
  goToYouthAdvRequirements,
  ROUTE_YOUTH_ACTIVITY_LOGS,
  goToActivityLogs,
  ROUTE_YOUTH_PROFILE,
  goToYouthProfile,
  ROUTE_UNREGISTERED_YOUTH_PROFILE,
  goToUnregisteredYouthProfile,
} from '@shared/duck';

export const MEMBER_DETAILS_REQUEST = `${moduleName}/MEMBER_DETAILS_REQUEST`;
export const memberDetailsRequest = () => ({
  type: MEMBER_DETAILS_REQUEST,
});
export const MEMBER_DETAILS_RESPONSE = `${moduleName}/MEMBER_DETAILS_RESPONSE`;
export const memberDetailsResponse = additionalInfo => ({
  type: MEMBER_DETAILS_RESPONSE,
  payload: additionalInfo,
});
export const MEMBER_DETAILS_ERROR = `${moduleName}/MEMBER_DETAILS_ERROR`;
export const memberDetailsError = err => ({
  type: MEMBER_DETAILS_ERROR,
  payload: err,
});

export const YOUTH_YPT_REQUEST = `${moduleName}/YOUTH_YPT_REQUEST`;
export const youthYPTRequest = personGuid => ({
  type: YOUTH_YPT_REQUEST,
  payload: personGuid,
});
export const YOUTH_YPT_RESPONSE = `${moduleName}/YOUTH_YPT_RESPONSE`;
export const youthYPTResponse = yptInfo => ({
  type: YOUTH_YPT_RESPONSE,
  payload: yptInfo,
});
export const YOUTH_YPT_ERROR = `${moduleName}/YOUTH_YPT_ERROR`;
export const youthYPTError = err => ({
  type: YOUTH_YPT_ERROR,
  payload: err,
});

export const YOUTH_AKELA_PROFILE_REQUEST = `${moduleName}/YOUTH_AKELA_PROFILE_REQUEST`;
export const youthAkelaProfileRequest = personGuid => ({
  type: YOUTH_AKELA_PROFILE_REQUEST,
  payload: personGuid,
});
export const YOUTH_AKELA_PROFILE_RESPONSE = `${moduleName}/YOUTH_AKELA_PROFILE_RESPONSE`;
export const youthAkelaProfileResponse = akelaProfileInfo => ({
  type: YOUTH_AKELA_PROFILE_RESPONSE,
  payload: akelaProfileInfo,
});
export const YOUTH_AKELA_PROFILE_ERROR = `${moduleName}/YOUTH_AKELA_PROFILE_ERROR`;
export const youthAkelaProfileError = err => ({
  type: YOUTH_AKELA_PROFILE_ERROR,
  payload: err,
});

export const DELETE_AKELA_EMAIL_REQUEST = `${moduleName}/DELETE_AKELA_EMAI_REQUEST`;
export const DELETE_AKELA_EMAI_RESPONSE = `${moduleName}/DELETE_AKELA_EMAI_RESPONSE`;
export const DELETE_AKELA_EMAI_ERROR = `${moduleName}/DELETE_AKELA_EMAI_ERROR`;
export const deleteAkelaEmailRequest = data => ({
  type: DELETE_AKELA_EMAIL_REQUEST,
  payload: data,
});
export const deleteAkelaEmailResponse = () => ({
  type: DELETE_AKELA_EMAI_RESPONSE,
});
export const deleteAkelaEmailError = err => ({
  type: DELETE_AKELA_EMAI_ERROR,
  payload: err,
});

export const EAGLE_ACTIVITY_REQUEST = `${moduleName}/EAGLE_ACTIVITY_REQUEST`;
export const eagleActivityRequest = id => ({
  type: EAGLE_ACTIVITY_REQUEST,
  payload: id,
});
export const EAGLE_ACTIVITY_RESPONSE = `${moduleName}/EAGLE_ACTIVITY_RESPONSE`;
export const eagleActivityResponse = additionalInfo => ({
  type: EAGLE_ACTIVITY_RESPONSE,
  payload: additionalInfo,
});
export const EAGLE_ACTIVITY_ERROR = `${moduleName}/EAGLE_ACTIVITY_ERROR`;
export const eagleActivityError = err => ({
  type: EAGLE_ACTIVITY_ERROR,
  payload: err,
});

export const CLEAR_EAGLE_ACTIVITY = `${moduleName}/CLEAR_EAGLE_ACTIVITY`;
export const clearEagleActivity = () => ({
  type: CLEAR_EAGLE_ACTIVITY,
});

export const SET_SHOULD_MEMBER_DETAILS_RELOAD = `${moduleName}/SET_SHOULD_MEMBER_DETAILS_RELOAD`;
export const setShouldMemberDetailsReload = shouldMemberDetailsReload => ({
  type: SET_SHOULD_MEMBER_DETAILS_RELOAD,
  payload: shouldMemberDetailsReload,
});

export const SET_EDIT_YOUTH_DETAIL_SIDEBAR = `${moduleName}/SET_EDIT_YOUTH_DETAIL_SIDEBAR`;
export const setEditYouthDetailSidebar = setEditYouthDetailSidebar => ({
  type: SET_EDIT_YOUTH_DETAIL_SIDEBAR,
  payload: setEditYouthDetailSidebar,
});

export const SAVE_YOUTH_PROFILE_REQUEST = `${moduleName}/SAVE_YOUTH_PROFILE_REQUEST`;
export const saveYouthProfileRequest = profile => ({
  type: SAVE_YOUTH_PROFILE_REQUEST,
  payload: profile,
});

export const SAVE_YOUTH_PROFILE_RESPONSE = `${moduleName}/SAVE_YOUTH_PROFILE_RESPONSE`;
export const saveYouthProfileResponse = () => ({
  type: SAVE_YOUTH_PROFILE_RESPONSE,
});

export const SAVE_YOUTH_PROFILE_ERROR = `${moduleName}/SAVE_YOUTH_PROFILE_ERROR`;
export const saveYouthProfileError = err => ({
  type: SAVE_YOUTH_PROFILE_ERROR,
  payload: err,
});

export const SAVE_YOUTH_DETAILS_REQUEST = `${moduleName}/SAVE_YOUTH_DETAILS_REQUEST`;
export const saveYouthDetailsRequest = profile => ({
  type: SAVE_YOUTH_DETAILS_REQUEST,
  payload: profile,
});

export const SAVE_YOUTH_DETAILS_RESPONSE = `${moduleName}/SAVE_YOUTH_DETAILS_RESPONSE`;
export const saveYouthDetailsResponse = () => ({
  type: SAVE_YOUTH_DETAILS_RESPONSE,
});

export const SAVE_YOUTH_DETAILS_ERROR = `${moduleName}/SAVE_YOUTH_DETAILS_ERROR`;
export const saveYouthDetailsError = err => ({
  type: SAVE_YOUTH_DETAILS_ERROR,
  payload: err,
});

export const SAVE_YOUTH_PROFILE_PICTURE_REQUEST = `${moduleName}/SAVE_YOUTH_PROFILE_PICTURE_REQUEST`;
export const saveYouthProfilePictureRequest = profile => ({
  type: SAVE_YOUTH_PROFILE_PICTURE_REQUEST,
  payload: profile,
});

export const SAVE_YOUTH_PROFILE_PICTURE_RESPONSE = `${moduleName}/SAVE_YOUTH_PROFILE_PICTURE_RESPONSE`;
export const saveYouthProfilePictureResponse = () => ({
  type: SAVE_YOUTH_PROFILE_PICTURE_RESPONSE,
});

export const SAVE_YOUTH_PROFILE_PICTURE_ERROR = `${moduleName}/SAVE_YOUTH_PROFILE_PICTURE_ERROR`;
export const saveYouthProfilePictureError = err => ({
  type: SAVE_YOUTH_PROFILE_PICTURE_ERROR,
  payload: err,
});

export const SET_EDIT_MODE = `${moduleName}/SET_EDIT_MODE`;
export const setEditMode = isEditMode => ({
  type: SET_EDIT_MODE,
  payload: isEditMode,
});

export const UPDATE_INFO_REQUEST = `${moduleName}/UPDATE_INFO_REQUEST`;
export const updateInfoRequest = youthData => ({
  type: UPDATE_INFO_REQUEST,
  payload: youthData,
});
export const UPDATE_INFO_RESPONSE = `${moduleName}/UPDATE_INFO_RESPONSE`;
export const updateInfoResponse = () => ({
  type: UPDATE_INFO_RESPONSE,
});
export const UPDATE_INFO_ERROR = `${moduleName}/UPDATE_INFO_ERROR`;
export const updateInfoError = error => ({
  type: UPDATE_INFO_ERROR,
  payload: error,
});

export const openApproveAdvancements =
  _advancements => (dispatch, getState) => {
    const { personShortFullName: name, userId } = youthInfoSel(getState());
    const advancements = _advancements.map(adv => ({
      ...adv,
      name,
      userId,
      advancementName: adv.name,
      description: getAdvancementDescription(adv.name, adv.advancementType),
    }));
    dispatch(_openApproveAdvancements(advancements));
  };

export const openEditAdvancement = adv => (dispatch, getState) => {
  const { personShortFullName: name, userId } = youthInfoSel(getState());
  const person = { name, userId };
  const advancement = {
    ...adv,
    imgUrl: adv.imageUrl100,
    advancementName: adv.name,
    description: getAdvancementDescription(adv.name, adv.advancementType),
  };
  dispatch(_openEditAdvancement(person, advancement));
};

export const openUnapproveAdvancement = adv => (dispatch, getState) => {
  const { personShortFullName } = youthInfoSel(getState());
  dispatch(openUnapproveModal(adv, personShortFullName));
};

export const openEagleScoutApplicationModal = () => (dispatch, getState) => {
  const { personShortFullName: youthName, userId: userId } = youthInfoSel(
    getState(),
  );
  dispatch(openEagleScoutApplication({ youthName, userId }));
};

export const YOUTH_ADV_REQUIREMENTS_REQUEST = `${moduleName}/YOUTH_ADV_REQUIREMENTS_REQUEST`;
export const YOUTH_ADV_REQUIREMENTS_RESPONSE = `${moduleName}/YOUTH_ADV_REQUIREMENTS_RESPONSE`;
export const YOUTH_ADV_REQUIREMENTS_ERROR = `${moduleName}/YOUTH_ADV_REQUIREMENTS_ERROR`;
export const youthAdvRequirementsRequest = payload => ({
  type: YOUTH_ADV_REQUIREMENTS_REQUEST,
  payload,
});
export const youthAdvRequirementsResponse = payload => ({
  type: YOUTH_ADV_REQUIREMENTS_RESPONSE,
  payload,
});
export const youthAdvRequirementsError = error => ({
  type: YOUTH_ADV_REQUIREMENTS_ERROR,
  payload: error,
});

export const ADV_VERSIONS_REQUEST = `${moduleName}/ADV_VERSIONS_REQUEST`;
export const ADV_VERSIONS_RESPONSE = `${moduleName}/ADV_VERSIONS_RESPONSE`;
export const ADV_VERSIONS_ERROR = `${moduleName}/ADV_VERSIONS_ERROR`;
export const advancementVersionsRequest = payload => ({
  type: ADV_VERSIONS_REQUEST,
  payload,
});
export const advancementVersionsResponse = payload => ({
  type: ADV_VERSIONS_RESPONSE,
  payload,
});
export const advancementVersionsError = error => ({
  type: ADV_VERSIONS_ERROR,
  payload: error,
});

export const UPDATE_ACTIVE_VERSION_REQUEST = `${moduleName}/UPDATE_ACTIVE_VERSION_REQUEST`;
export const UPDATE_ACTIVE_VERSION_RESPONSE = `${moduleName}/UPDATE_ACTIVE_VERSION_RESPONSE`;
export const UPDATE_ACTIVE_VERSION_ERROR = `${moduleName}/UPDATE_ACTIVE_VERSION_ERROR`;
export const updateActiveVersionRequest = payload => ({
  type: UPDATE_ACTIVE_VERSION_REQUEST,
  payload,
});
export const updateActiveVersionResponse = () => ({
  type: UPDATE_ACTIVE_VERSION_RESPONSE,
});
export const updateActiveVersionError = error => ({
  type: UPDATE_ACTIVE_VERSION_ERROR,
  payload: error,
});

export const SET_ADV_LIST_FILTER = `${moduleName}/SET_ADV_LIST_FILTER`;
export const SET_ADV_LIST_SORTER = `${moduleName}/SET_ADV_LIST_SORTER`;
export const SET_ADV_LIST_SEARCH = `${moduleName}/SET_ADV_LIST_SEARCH`;
export const setAdvListFilter = filter => ({
  type: SET_ADV_LIST_FILTER,
  payload: filter,
});
export const setAdvListSorter = sorter => ({
  type: SET_ADV_LIST_SORTER,
  payload: sorter,
});
export const setAdvListSearch = query => ({
  type: SET_ADV_LIST_SEARCH,
  payload: query,
});

export const YOUTH_ACTIVITIES_SUMMARY_REQUEST = `${moduleName}/YOUTH_ACTIVITIES_SUMMARY_REQUEST`;
export const youthActivitySummaryRequest = userId => ({
  type: YOUTH_ACTIVITIES_SUMMARY_REQUEST,
  payload: userId,
});
export const YOUTH_ACTIVITIES_SUMMARY_RESPONSE = `${moduleName}/YOUTH_ACTIVITIES_SUMMARY_RESPONSE`;
export const youthActivitySummaryResponse = summaryInfo => ({
  type: YOUTH_ACTIVITIES_SUMMARY_RESPONSE,
  payload: summaryInfo,
});
export const YOUTH_ACTIVITIES_SUMMARY_ERROR = `${moduleName}/YOUTH_ACTIVITIES_SUMMARY_ERROR`;
export const youthActivitySummaryError = error => ({
  type: YOUTH_ACTIVITIES_SUMMARY_ERROR,
  payload: error,
});

export const OPEN_EDIT_ADV_RQMT_MODAL = `${moduleName}/OPEN_EDIT_ADV_RQMT_MODAL`;
export const CLOSE_EDIT_ADV_RQMT_MODAL = `${moduleName}/CLOSE_EDIT_ADV_RQMT_MODAL`;
export const openEditAdvRqmtModal = requirement => ({
  type: OPEN_EDIT_ADV_RQMT_MODAL,
  payload: requirement,
});

export const closeEditAdvRqmtModal = () => ({
  type: CLOSE_EDIT_ADV_RQMT_MODAL,
});

export const ROUTE_EAGLE_PROJECT = `${moduleName}/ROUTE_EAGLE_PROJECT`;
export const goToEagleProject = userId => ({
  type: ROUTE_EAGLE_PROJECT,
  payload: { userId },
});

export const SUBMIT_EAGLE_PROJECT_REQUEST = `${moduleName}/SUBMIT_EAGLE_PROJECT_REQUEST`;
export const submitEagleProjectRequest = data =>
  basicActionCreator(SUBMIT_EAGLE_PROJECT_REQUEST, data);

export const SUBMIT_EAGLE_PROJECT_RESPONSE = `${moduleName}/SUBMIT_EAGLE_PROJECT_RESPONSE`;
export const submitEagleProjectResponse = () => ({
  type: SUBMIT_EAGLE_PROJECT_RESPONSE,
});

export const SUBMIT_EAGLE_PROJECT_ERROR = `${moduleName}/SUBMIT_EAGLE_PROJECT_ERROR`;
export const submitEagleProjectError = error => ({
  type: SUBMIT_EAGLE_PROJECT_ERROR,
  payload: error,
});

export const EDIT_EAGLE_PROJECT_REQUEST = `${moduleName}/EDIT_EAGLE_PROJECT_REQUEST`;
export const editEagleProjectRequest = (id, data) => ({
  type: EDIT_EAGLE_PROJECT_REQUEST,
  payload: { id, data },
});

export const ADV_COMMENTS_REQUEST = `${moduleName}/ADV_COMMENTS_REQUEST`;
export const ADV_COMMENTS_RESPONSE = `${moduleName}/ADV_COMMENTS_RESPONSE`;
export const ADV_COMMENTS_ERROR = `${moduleName}/ADV_COMMENTS_ERROR`;
export const advCommentsRequest = () => ({ type: ADV_COMMENTS_REQUEST });
export const advCommentsResponse = payload => ({
  type: ADV_COMMENTS_RESPONSE,
  payload,
});
export const advCommentsError = error => ({
  type: ADV_COMMENTS_ERROR,
  payload: error,
});

export const ADD_ADV_COMMENTS_REQUEST = `${moduleName}/ADD_ADV_COMMENTS_REQUEST`;
export const ADD_ADV_COMMENTS_RESPONSE = `${moduleName}/ADD_ADV_COMMENTS_RESPONSE`;
export const ADD_ADV_COMMENTS_ERROR = `${moduleName}/ADD_ADV_COMMENTS_ERROR`;
export const ADD_ADV_COMMENTS_CANCEL = `${moduleName}/ADD_ADV_COMMENTS_CANCEL`;
export const addAdvCommentsRequest = payload => ({
  type: ADD_ADV_COMMENTS_REQUEST,
  payload,
});
export const addAdvCommentsResponse = payload => ({
  type: ADD_ADV_COMMENTS_RESPONSE,
  payload,
});
export const addAdvCommentsError = error => ({
  type: ADD_ADV_COMMENTS_ERROR,
  payload: error,
});
export const addAdvCommentsCancel = () => ({
  type: ADD_ADV_COMMENTS_CANCEL,
});

export const ADD_BATCH_ADV_COMMENTS_REQUEST = `${moduleName}/ADD_BATCH_ADV_COMMENTS_REQUEST`;
export const ADD_BATCH_ADV_COMMENTS_RESPONSE = `${moduleName}/ADD_BATCH_ADV_COMMENTS_RESPONSE`;
export const ADD_BATCH_ADV_COMMENTS_ERROR = `${moduleName}/ADD_BATCH_ADV_COMMENTS_ERROR`;
export const addBatchAdvCommentsRequest = payload => ({
  type: ADD_BATCH_ADV_COMMENTS_REQUEST,
  payload,
});
export const addBatchAdvCommentsResponse = payload => ({
  type: ADD_BATCH_ADV_COMMENTS_RESPONSE,
  payload,
});
export const addBatchAdvCommentsError = error => ({
  type: ADD_BATCH_ADV_COMMENTS_ERROR,
  payload: error,
});

export const DELETE_ADV_COMMENTS_REQUEST = `${moduleName}/DELETE_ADV_COMMENTS_REQUEST`;
export const DELETE_ADV_COMMENTS_RESPONSE = `${moduleName}/DELETE_ADV_COMMENTS_RESPONSE`;
export const DELETE_ADV_COMMENTS_ERROR = `${moduleName}/DELETE_ADV_COMMENTS_ERROR`;
export const deleteAdvCommentsRequest = payload => ({
  type: DELETE_ADV_COMMENTS_REQUEST,
  payload,
});
export const deleteAdvCommentsResponse = payload => ({
  type: DELETE_ADV_COMMENTS_RESPONSE,
  payload,
});
export const deleteAdvCommentsError = error => ({
  type: DELETE_ADV_COMMENTS_ERROR,
  payload: error,
});

export const UPDATE_ADV_COMMENTS_REQUEST = `${moduleName}/UPDATE_ADV_COMMENTS_REQUEST`;
export const UPDATE_ADV_COMMENTS_RESPONSE = `${moduleName}/UPDATE_ADV_COMMENTS_RESPONSE`;
export const UPDATE_ADV_COMMENTS_ERROR = `${moduleName}/UPDATE_ADV_COMMENTS_ERROR`;
export const updateAdvCommentsRequest = payload => ({
  type: UPDATE_ADV_COMMENTS_REQUEST,
  payload,
});
export const updateAdvCommentsResponse = payload => ({
  type: UPDATE_ADV_COMMENTS_RESPONSE,
  payload,
});
export const updateAdvCommentsError = error => ({
  type: UPDATE_ADV_COMMENTS_ERROR,
  payload: error,
});

export const ADD_ADV_COMMENTS_PHOTO_REQUEST = `${moduleName}/ADD_ADV_COMMENTS_PHOTO_REQUEST`;
export const ADD_ADV_COMMENTS_PHOTO_RESPONSE = `${moduleName}/ADD_ADV_COMMENTS_PHOTO_RESPONSE`;
export const ADD_ADV_COMMENTS_PHOTO_ERROR = `${moduleName}/ADD_ADV_COMMENTS_PHOTO_ERROR`;
export const addAdvCommentsPhotoRequest = payload => ({
  type: ADD_ADV_COMMENTS_PHOTO_REQUEST,
  payload,
});
export const addAdvCommentsPhotoResponse = payload => ({
  type: ADD_ADV_COMMENTS_PHOTO_RESPONSE,
  payload,
});
export const addAdvCommentsPhotoError = error => ({
  type: ADD_ADV_COMMENTS_PHOTO_ERROR,
  payload: error,
});

export const DELETE_ADV_COMMENTS_PHOTO_REQUEST = `${moduleName}/DELETE_ADV_COMMENTS_PHOTO_REQUEST`;
export const DELETE_ADV_COMMENTS_PHOTO_RESPONSE = `${moduleName}/DELETE_ADV_COMMENTS_PHOTO_RESPONSE`;
export const DELETE_ADV_COMMENTS_PHOTO_ERROR = `${moduleName}/DELETE_ADV_COMMENTS_PHOTO_ERROR`;
export const deleteAdvCommentsPhotoRequest = payload => ({
  type: DELETE_ADV_COMMENTS_PHOTO_REQUEST,
  payload,
});
export const deleteAdvCommentsPhotoResponse = payload => ({
  type: DELETE_ADV_COMMENTS_PHOTO_RESPONSE,
  payload,
});
export const deleteAdvCommentsPhotoError = error => ({
  type: DELETE_ADV_COMMENTS_PHOTO_ERROR,
  payload: error,
});

export const updateAdvSummary = payload => ({
  type: UPDATE_ADV_SUMMARY_REQUEST,
  payload: payload,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
