import moment from 'moment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';

import { ENABLE_VENTURING_RANKS } from '@config';
import {
  ProgramId,
  advancementStatuses,
  advancementTypes,
  bobcatRankId,
  bobcatRankOrder,
  dtoDateFormat,
  ranksOrder,
  syncOperations,
  venturingCoreAwardsIds,
} from '@shared';
import { esbApiService } from '@utils';

const scoutRankId = '1';
const apprenticeRankId = '15';
const venturingRankId = '19';

const { ADVENTURES, AWARDS, MERIT_BADGES, RANKS } = advancementTypes;

const bodyFactory = (
  { loggedInUserId, advancementId, users, date, organizationGuid, status },
  type,
) => {
  const getDtoDate = (date, userId) =>
    typeof date === 'string'
      ? moment(date).format(dtoDateFormat)
      : moment(date[`${userId}_${advancementId}`]).format(dtoDateFormat);
  loggedInUserId = loggedInUserId ? Number(loggedInUserId) : undefined;
  const awarded = status === advancementStatuses.AWARDED;

  switch (type) {
    case ADVENTURES:
      return users.map(user => ({
        organizationGuid,
        userId: Number(user.userId),
        [type]: [
          {
            id: Number(advancementId),
            dateCompleted: getDtoDate(date, user.userId),
            markedCompletedUserId: loggedInUserId,
            leaderApprovedUserId: loggedInUserId,
            awarded,
          },
        ],
      }));
    case AWARDS:
      return users.map(user => ({
        organizationGuid,
        userId: Number(user.userId),
        [type]: [
          {
            id: Number(advancementId),
            dateEarned: getDtoDate(date, user.userId),
            leaderApprovedUserId: loggedInUserId,
            awarded,
          },
        ],
      }));
    case RANKS:
      return users.map(user => ({
        organizationGuid,
        userId: Number(user.userId),
        [type]: [
          {
            id: Number(advancementId),
            dateEarned: getDtoDate(date, user.userId),
            markedCompletedUserId: loggedInUserId,
            leaderApprovedUserId: loggedInUserId,
            awarded,
          },
        ],
      }));
    case MERIT_BADGES:
      return users.map(user => {
        const payload = {
          organizationGuid,
          userId: Number(user.userId),
          [type]: [
            {
              id: Number(advancementId),
              dateCompleted: getDtoDate(date, user.userId),
              markedCompletedUserId: loggedInUserId,
              leaderApprovedUserId: loggedInUserId,
            },
          ],
        };
        if (awarded) {
          payload[type][0].dateAwarded = moment().format(dtoDateFormat);
        }
        return payload;
      });
    default:
      return {};
  }
};

const serializeFactory =
  (
    { organizationGuid, advancementId, users, date, unitTypeId, status },
    advancementType,
  ) =>
  () =>
    users.map(({ userId }) => ({
      operation: syncOperations.RECORD_ADVANCEMENT,
      organizationGuid,
      userId,
      advancementType,
      advancementId,
      date:
        typeof date === 'string' ? date : date[`${userId}_${advancementId}`],
      unitTypeId,
      status,
    }));

const youthsAdventures$ = data =>
  esbApiService.postOrDefer$(
    '/advancements/youth/adventures',
    bodyFactory(data, ADVENTURES),
    {
      gtm: {
        label: '/advancements/youth/adventures',
      },
      serialize: serializeFactory(data, ADVENTURES),
    },
  );

const youthsAwards$ = data =>
  esbApiService.postOrDefer$(
    '/advancements/youth/awards',
    bodyFactory(data, AWARDS),
    {
      gtm: {
        label: '/advancements/youth/awards',
      },
      serialize: serializeFactory(data, AWARDS),
    },
  );

const youthsMeritBadges$ = data =>
  esbApiService.postOrDefer$(
    '/advancements/youth/meritBadges',
    bodyFactory(data, MERIT_BADGES),
    {
      gtm: {
        label: '/advancements/youth/meritBadges',
      },
      serialize: serializeFactory(data, MERIT_BADGES),
    },
  );

const youthsRanks$ = data =>
  esbApiService.postOrDefer$(
    '/advancements/youth/ranks',
    bodyFactory(data, RANKS),
    {
      gtm: {
        label: '/advancements/youth/ranks',
      },
      serialize: serializeFactory(data, RANKS),
    },
  );

const recordRequests$ = {
  [ADVENTURES]: youthsAdventures$,
  [AWARDS]: youthsAwards$,
  [MERIT_BADGES]: youthsMeritBadges$,
  [RANKS]: youthsRanks$,
};

const recordAdvancement$ = data => recordRequests$[data.advancementType](data);

const mapPersonsToRankVerification = (persons, unitTypeId) =>
  persons
    .filter(
      ({ currentHighestRankApproved = {}, otherHighestRanksApproved = [] }) =>
        Object.keys(currentHighestRankApproved).length > 0 ||
        otherHighestRanksApproved.length > 0,
    )
    .map(
      ({
        userId,
        currentHighestRankApproved = {},
        otherHighestRanksApproved = [],
      }) =>
        [currentHighestRankApproved, ...otherHighestRanksApproved].map(
          item => ({
            ...item,
            dateCompleted: item.dateEarned || item.dateCompleted,
            dateAwarded: item.awardedDate,
            userId,
          }),
        ),
    )
    .map(verification =>
      verification.filter(item => item.unitTypeId == unitTypeId),
    )
    .filter(verification => verification.length > 0)
    .map(([item]) => item);

const isSelectedHigherRank = ({
  ranks,
  unitTypeId,
  selectedAdvancementId,
  userHighestRankLevel,
}) => {
  const { level: selectedRankLevel } =
    ranks.find(
      rank => rank.programId == unitTypeId && rank.id == selectedAdvancementId,
    ) || {};
  return selectedRankLevel > userHighestRankLevel;
};

const verifyRankLocally = ({
  userIds,
  advancementId,
  verificationDict,
  unitTypeId,
  ranks,
}) => {
  const verification = [];
  const unverifiedUserIds = [];

  userIds.forEach(userId => {
    let userVerification = verificationDict.find(
      item => item.userId == userId && item.id == advancementId,
    );
    const { level: userHighestRankLevel = -1 } =
      verificationDict.find(item => item.userId == userId) || {};
    if (
      isSelectedHigherRank({
        ranks,
        unitTypeId,
        selectedAdvancementId: advancementId,
        userHighestRankLevel,
      })
    ) {
      userVerification = { userId };
    }
    if (userVerification) {
      verification.push(userVerification);
    } else {
      unverifiedUserIds.push(userId);
    }
  });

  return {
    verification,
    unverifiedUserIds,
  };
};

const removePluralForm = advancementType => advancementType.slice(0, -1);

const verifyAdvancements$ = ({
  advancementType,
  advancementId,
  youthMemberUserId,
  organizationGuid,
}) =>
  esbApiService.post$(
    '/advancements/verifyUserAdvancement',
    {
      advancementType: removePluralForm(advancementType),
      advancementId: Number(advancementId),
      youthMemberUserId,
      organizationGuid,
    },
    {
      suppressErrorToasts: true,
      gtm: {
        label: '/advancements/verifyUserAdvancement',
      },
    },
  );

const mergeDoubleVerification = ([currentRanks, previousRanks]) =>
  currentRanks.map((item, i) => ({
    ...item,
    isPreviousRankEarned: !!(
      previousRanks[i].dateEarned || previousRanks[i].leaderApprovedUserId
    ),
  }));

const verifyRanks$ = ({
  advancementType,
  advancementId,
  youthMemberUserId,
  unitTypeId,
  organizationGuid,
  persons,
  ranks,
  fallbackResponse,
}) => {
  const isLowestBoyScoutRank = advancementId == scoutRankId;
  const isLowestSeaScoutRank = advancementId == apprenticeRankId;
  const isLowestVenturingRank = advancementId == venturingRankId;
  const isAboveBobcatRank = ranksOrder[advancementId] > bobcatRankOrder;
  const localRankVerificationDict = mapPersonsToRankVerification(
    persons,
    unitTypeId,
  );
  const { verification, unverifiedUserIds } = verifyRankLocally({
    userIds: youthMemberUserId,
    advancementId,
    verificationDict: localRankVerificationDict,
    ranks,
    unitTypeId,
  });

  const makeRankVerificationCall = ({
    advancementType,
    advancementId,
    verification,
    unverifiedUserIds,
    fallbackResponse,
    organizationGuid,
  }) =>
    Observable.forkJoin(
      Observable.of(verification),
      unverifiedUserIds.length > 0
        ? verifyAdvancements$({
            advancementType,
            advancementId,
            youthMemberUserId: unverifiedUserIds,
            organizationGuid,
          }).catch(() => Observable.of(fallbackResponse))
        : Observable.of([]),
    ).map(([a, b]) => [...a, ...b]);

  const verificationCall$ = makeRankVerificationCall({
    advancementType,
    advancementId,
    verification,
    unverifiedUserIds,
    fallbackResponse,
    organizationGuid,
  });
  if (
    (!isLowestSeaScoutRank && unitTypeId == ProgramId.SEA_SCOUT) ||
    (!isLowestBoyScoutRank && unitTypeId == ProgramId.BOY_SCOUT) ||
    (ENABLE_VENTURING_RANKS &&
      !isLowestVenturingRank &&
      unitTypeId == ProgramId.VENTURING) ||
    (isAboveBobcatRank && unitTypeId == ProgramId.CUB_SCOUT)
  ) {
    const previousRankId =
      unitTypeId != ProgramId.CUB_SCOUT
        ? Number(advancementId) - 1
        : bobcatRankId;

    const { verification, unverifiedUserIds } = verifyRankLocally({
      userIds: youthMemberUserId,
      advancementId: previousRankId,
      verificationDict: localRankVerificationDict,
      ranks,
      unitTypeId,
    });

    const previousRankVerificationCall$ = makeRankVerificationCall({
      advancementType,
      advancementId: previousRankId,
      verification,
      unverifiedUserIds,
      fallbackResponse,
      organizationGuid,
    });

    return Observable.forkJoin(
      verificationCall$,
      previousRankVerificationCall$,
    ).map(mergeDoubleVerification);
  }

  return verificationCall$;
};

const mergeDoubleVenturingAwardsResponses = ([
  currentCoreAwards,
  previousCoreAwards,
]) =>
  currentCoreAwards.map((item, i) => ({
    ...item,
    isPreviousCoreAwardEarned: !!(
      previousCoreAwards[i].dateEarned ||
      previousCoreAwards[i].leaderApprovedUserId
    ),
  }));

const verifyVenturingCoreAwards$ = ({
  advancementId,
  advancementType,
  youthMemberUserId,
  organizationGuid,
  fallbackResponse,
}) =>
  Observable.forkJoin(
    verifyAdvancements$({
      advancementId,
      advancementType,
      youthMemberUserId,
      organizationGuid,
    }).catch(() => Observable.of(fallbackResponse)),
    verifyAdvancements$({
      advancementId: advancementId - 1,
      advancementType,
      youthMemberUserId,
      organizationGuid,
    }).catch(() => Observable.of(fallbackResponse)),
  ).map(mergeDoubleVenturingAwardsResponses);

const verifyUserAdvancement$ = ({
  advancementType,
  advancementId,
  unitTypeId,
  organizationGuid,
  persons = [],
  ranks = [],
}) => {
  const youthMemberUserId = persons.map(({ userId }) => userId);
  const isRank = advancementType === advancementTypes.RANKS;
  const shouldCheckAwardsOrder =
    advancementType === advancementTypes.AWARDS &&
    unitTypeId == ProgramId.VENTURING &&
    venturingCoreAwardsIds.slice(1).includes(advancementId);
  const fallbackResponse = youthMemberUserId.map(userId => ({ userId }));
  return isRank
    ? verifyRanks$({
        advancementType,
        advancementId,
        youthMemberUserId,
        unitTypeId,
        organizationGuid,
        persons,
        ranks,
        fallbackResponse,
      })
    : shouldCheckAwardsOrder
    ? verifyVenturingCoreAwards$({
        advancementId,
        youthMemberUserId,
        advancementType,
        organizationGuid,
        fallbackResponse,
      })
    : verifyAdvancements$({
        advancementType,
        advancementId,
        youthMemberUserId,
        organizationGuid,
      });
};

const retrieveUsersAdvancements$ = advancementType =>
  esbApiService.get$(advancementType);

export default {
  recordAdvancement$,
  retrieveUsersAdvancements$,
  verifyUserAdvancement$,
};
