import moment from 'moment';

import { modelDateFormat } from '@shared/constants';

const boyScoutAdultAge = 18;
const everyOtherUnitAdultAge = 21;

const getNthBirthday = ({ age, dob, currentDay, adultAge }) => {
  if (!dob || !age) return '';
  const referenceDay = currentDay || moment();
  const dateOfBirthNoYear = dob?.slice(4);
  const dateOfBirth = moment(dateOfBirthNoYear, 'MM-DD').year(
    referenceDay.year(),
  );
  const hasBirthdayPassed = dateOfBirth.dayOfYear() >= referenceDay.dayOfYear();
  return dateOfBirth
    .add(adultAge - age - (hasBirthdayPassed ? 1 : 0), 'years')
    .format(modelDateFormat);
};

const get18th = ({ age, dob, currentDay }) =>
  getNthBirthday({ age, dob, currentDay, adultAge: boyScoutAdultAge });

const get21st = ({ age, dob, currentDay }) =>
  getNthBirthday({
    age,
    dob,
    currentDay,
    adultAge: everyOtherUnitAdultAge,
  });

export default { get21st, get18th };
