import { createAction } from '@reduxjs/toolkit';

import { ROUTE_CALENDAR } from '@shared';
import { gtm, locationHash } from '@shared/utils';

import { calendarViews, moduleName } from '../constants';

export {
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_ACTIVITY_DETAILS,
  ROUTE_CALENDAR_EVENT_DETAILS,
  ROUTE_EVENTS_EDIT,
  ROUTE_CALENDAR_EDIT_ACTIVITY,
} from '@shared';

export const ROUTE_CALENDAR_CREATE_ACTIVITY = `${moduleName}/ROUTE_CALENDAR_CREATE_ACTIVITY`;
export const goToCalendarCreateActivity = (activityTypeId, activityData) => ({
  type: ROUTE_CALENDAR_CREATE_ACTIVITY,
  payload: {
    activityTypeId,
    activityData,
  },
});

export const SET_SELECTED_DATE = `${moduleName}/SET_SELECTED_DATE`;
export const setSelectedDate = isoString => ({
  type: SET_SELECTED_DATE,
  payload: isoString,
});

export const setMiniCalendarSelectedDate = createAction(
  `${moduleName}/SET_MINI_CALENDAR_DATE`,
);

export const goToCalendarView =
  (activeView = calendarViews.MONTH) =>
  dispatch => {
    locationHash.set(activeView);
    gtm.pushEvent({
      event: 'CALENDAR_VIEW_CHANGE',
      category: gtm.categories.CALENDAR,
      label: activeView,
    });
    dispatch({ type: ROUTE_CALENDAR, payload: { hash: activeView } });
  };

export const OPEN_ADD_EVENT_POPOVER = `${moduleName}/OPEN_ADD_EVENT_POPOVER`;
export const openAddEventPopover = dates => ({
  type: OPEN_ADD_EVENT_POPOVER,
  payload: dates,
  gtm: {
    category: gtm.categories.CALENDAR,
  },
});

export const CLOSE_ADD_EVENT_POPOVER = `${moduleName}/CLOSE_ADD_EVENT_POPOVER`;
export const closeAddEventPopover = () => ({
  type: CLOSE_ADD_EVENT_POPOVER,
  gtm: {
    category: gtm.categories.CALENDAR,
  },
});

export const UPDATE_EVENTS = `${moduleName}/UPDATE_EVENTS`;
export const updateEvents = (events = []) => ({
  type: UPDATE_EVENTS,
  payload: events,
});

export const GET_EVENTS_RESPONSE = `${moduleName}/GET_EVENTS_RESPONSE`;
export const getEventsResponse = (events = []) => ({
  type: GET_EVENTS_RESPONSE,
  payload: events,
});

export const GET_EVENTS_ERROR = `${moduleName}/GET_EVENTS_ERROR`;
export const getEventsError = err => ({
  type: GET_EVENTS_ERROR,
  payload: err,
});

export const GET_EVENTS_REQUEST = `${moduleName}/GET_EVENTS_REQUEST`;
export const getEventsRequest = payload => ({
  type: GET_EVENTS_REQUEST,
  payload,
});

export const GET_PERSONAL_ACTIVITIES_RESPONSE = `${moduleName}/GET_PERSONAL_ACTIVITIES_RESPONSE`;
export const getPersonalActivitiesResponse = (personalActivities = []) => ({
  type: GET_PERSONAL_ACTIVITIES_RESPONSE,
  payload: personalActivities,
});

export const GET_PERSONAL_ACTIVITIES_ERROR = `${moduleName}/GET_PERSONAL_ACTIVITIES_ERROR`;
export const getPersonalActivitiesError = err => ({
  type: GET_PERSONAL_ACTIVITIES_ERROR,
  payload: err,
});

export const GET_PERSONAL_ACTIVITIES_REQUEST = `${moduleName}/GET_PERSONAL_ACTIVITIES_REQUEST`;
export const getPersonalActivitiesRequest = selectedDate => ({
  type: GET_PERSONAL_ACTIVITIES_REQUEST,
  payload: selectedDate,
});

export const SET_CALENDAR_RANGE = `${moduleName}/SET_CALENDAR_RANGE`;
export const setCalendarRange = selectedRange => ({
  type: SET_CALENDAR_RANGE,
  payload: selectedRange,
});

export const SET_ACTIVITY_TYPE_FILTERS = `${moduleName}/SET_ACTIVITY_TYPE_FILTERS`;
export const setActivityTypeFilter = (key, value) => ({
  type: SET_ACTIVITY_TYPE_FILTERS,
  payload: { key, value },
});

export const SET_CALENDARS_TO_HIDE = `${moduleName}/SELECT_CALENDARS_TO_HIDE`;
export const setCalendarsToHide = calendarsToHide => ({
  type: SET_CALENDARS_TO_HIDE,
  payload: calendarsToHide,
});

export const SET_SEARCH_EVENT_NAME = `${moduleName}/SET_SEARCH_EVENT_NAME`;
export const setSearchEventName = searchEventName => ({
  type: SET_SEARCH_EVENT_NAME,
  payload: searchEventName,
});

export const SET_DELETE_RECURRENT_EVENT_INFO = `${moduleName}/SET_DELETE_RECURRENT_EVENT_INFO`;
export const setDeleteRecurrentEventInfo = (
  /** @type {import('@modules/events/duck/types').DeleteRecurrentEventState} */
  payload,
) => ({
  type: SET_DELETE_RECURRENT_EVENT_INFO,
  payload: payload,
});
