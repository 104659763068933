import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ReactJoyride from 'react-joyride';
import { ACTIONS, EVENTS } from 'react-joyride/es/constants';

import JoyrideTooltip from './JoyrideTooltip';
import StepImage from './StepImage';

const defaultLocale = {
  back: <FormattedMessage id="shared.Joyride.back" />,
  close: <FormattedMessage id="shared.Joyride.close" />,
  last: <FormattedMessage id="shared.Joyride.last" />,
  next: <FormattedMessage id="shared.Joyride.next" />,
  skip: <FormattedMessage id="shared.Joyride.skip" />,
};
const joyrideStyles = {
  options: {
    zIndex: 10000,
  },
};
const spotlightPadding = 5;
const spotlightPositionErrorMargin = 10;

/**
 * This is a modified abstraction of react-joyride.
 * Main difference is that you can have async actions before/after steps,
 * to e.g. wait for a node to show up or fetch some data (and clean up after yourself).
 * This is done by adding 'before' and/or 'after' callbacks (sync or Promise) to steps.
 */
class Joyride extends React.PureComponent {
  state = {
    run: true,
    stepIndex: 0,
  };

  doneOrSkipped = false;

  handleCallback = async ({ action, type, index }) => {
    const { steps } = this.props;

    if (this.doneOrSkipped) {
      return;
    }
    if (action === ACTIONS.START && type === EVENTS.TOUR_START) {
      this.setState({ run: false });
      await this.runBefore(0);
      this.setState({ run: true });
    } else if (
      action === ACTIONS.CLOSE ||
      action === ACTIONS.SKIP ||
      (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER)
    ) {
      this.setState({ run: false });
      const { stepIndex } = this.state;
      await this.runAfter(stepIndex);
      const isLastStep = index + 1 === steps.length;

      if (isLastStep || action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
        this.wizardHasFinished({
          isTourSkipped: !isLastStep || action === ACTIONS.SKIP,
          stepIndex,
          stepsAmount: steps.length,
        });
      } else {
        this.nextStep(index + 1);
      }
    } else if (
      action === ACTIONS.UPDATE &&
      type === EVENTS.TOOLTIP &&
      steps[index].patchSpotlightPosition
    ) {
      this.patchSpotlightPosition(steps[index].target);
    }
  };

  nextStep = index => {
    this.setState({ run: false, stepIndex: index }, async () => {
      await this.runBefore(index);
      this.setState({ run: true });
    });
  };

  runBefore = async index => {
    const { before = () => {} } = this.props.steps[index];
    await before();
  };

  runAfter = async index => {
    const { after = () => {} } = this.props.steps[index];
    await after();
  };

  wizardHasFinished = ({ isTourSkipped, stepIndex }) => {
    const { onDone, onSkipped } = this.props;
    this.doneOrSkipped = true;

    isTourSkipped ? onSkipped && onSkipped(stepIndex) : onDone && onDone();
  };

  // https://github.com/gilbarbara/react-joyride/issues/376
  // seems that in some browsers the spotlight does not take page scroll into account
  // so this is a temporary fix
  patchSpotlightPosition = async currentTarget => {
    const spotlight = await this.getSpotlight();
    const currentElement = await this.getCurrentElement(currentTarget);
    if (currentElement) {
      const { top: currentElementTop } = currentElement.getBoundingClientRect();
      const { top: spotlightTop } = spotlight.getBoundingClientRect();

      if (
        Math.abs(currentElementTop - spotlightTop) >
        spotlightPositionErrorMargin
      ) {
        const pageYOffset = window.pageYOffset;
        spotlight.style.transform = `translateY(${pageYOffset}px)`;
      }
    }
  };

  getSpotlight = async () => {
    const spotlight = document.querySelector('.joyride-spotlight');
    if (spotlight) {
      return spotlight;
    }
    return new Promise(resolve =>
      setTimeout(() => resolve(this.getSpotlight()), 5),
    );
  };

  getCurrentElement = async (currentTarget, tries = 3) => {
    const currentElement = document.querySelector(currentTarget);
    if (currentElement || tries == 0) {
      return currentElement;
    }
    return new Promise(resolve =>
      setTimeout(
        () => resolve(this.getCurrentElement(currentTarget, tries - 1)),
        50,
      ),
    );
  };

  render() {
    const { continuous, steps, locale } = this.props;
    const { run, stepIndex } = this.state;
    // Joyride's `isEqual` internal function works in such a way
    // that could end up in an infinite loop when comparing two components.
    // Converting them to functions seems to work well enough
    const safeSteps = steps.map(step => {
      const { title, content } = step;

      return {
        ...step,
        title: typeof title === 'function' ? title : () => title,
        content: typeof content === 'function' ? content : () => content,
      };
    });

    return (
      <ReactJoyride
        continuous={continuous}
        scrollToFirstStep
        disableOverlayClose={true}
        run={run}
        stepIndex={stepIndex}
        steps={safeSteps}
        locale={{
          ...defaultLocale,
          ...locale,
        }}
        styles={joyrideStyles}
        spotlightPadding={spotlightPadding}
        callback={this.handleCallback}
        tooltipComponent={JoyrideTooltip}
      />
    );
  }
}

Joyride.propTypes = {
  continuous: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  run: PropTypes.bool,
  locale: PropTypes.object,
  onDone: PropTypes.func,
  onSkipped: PropTypes.func,
};

Joyride.defaultProps = {
  continuous: true,
  run: true,
};

Joyride.Image = StepImage;

export default Joyride;
