import React from 'react';

import Brightness3Icon from '@material-ui/icons/Brightness3';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  ProgressButton,
  datesShape,
  setSelectedActivity,
} from '../../../common';
import { openCampoutsCreate } from '../../duck/actions';
import styles from './CampoutsButton.less';

class CampoutsCreateButton extends React.PureComponent {
  handleClick = () => {
    const { onOpenCampouts, onClick } = this.props;
    onOpenCampouts();
    if (onClick) {
      onClick();
    }
  };

  render() {
    const { disabled, className, children, replaceButton } = this.props;
    const button = replaceButton ? (
      React.cloneElement(replaceButton, { onClick: this.handleClick })
    ) : (
      <ProgressButton
        color="scouting-warm-gray"
        noBorder
        fitText
        disabled={disabled}
        icon={<Brightness3Icon className={styles.icon} />}
        className={className}
        onClick={this.handleClick}
      >
        {children || <FormattedMessage id="campouts.CampoutsButton.label" />}
      </ProgressButton>
    );
    return button;
  }
}

CampoutsCreateButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  allowRemovePersons: PropTypes.bool,
  className: PropTypes.string,
  dates: datesShape,
  onClick: PropTypes.func,
  onOpenCampouts: PropTypes.func.isRequired,
  replaceButton: PropTypes.node,
  allowAddPersons: PropTypes.bool,
};

const mapDispatch = (
  dispatch,
  { dates, allowAddPersons, activityData, allowRemovePersons },
) => ({
  onOpenCampouts: () => {
    dispatch(openCampoutsCreate(dates, allowAddPersons, allowRemovePersons));
    activityData && dispatch(setSelectedActivity(activityData));
  },
});

export default connect(null, mapDispatch)(CampoutsCreateButton);
