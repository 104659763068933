import React, { useCallback, useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { REPORTS_ALT_BASE_URL } from '@config';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { getCustomReportUrl } from '@modules/reports/basicReports/duck/utils';
import { formatToReportsDate } from '@modules/utils/dates';
import { openReportWithQueryParams } from '@modules/utils/openBasicReport';
import { isMobileSel } from '@screen';
import { Card, Checkbox, DatePicker, Form, S, T } from '@shared/components';
import { viewDateFormat } from '@shared/constants';
import { validateForm } from '@utils';

import { ADV_HISTORY_CTX_API, CustomReports } from '../../../constants';
import { areAllYouthSelected, reportParamsDataSel } from '../../../duck';
import ReportParamsButtons from './ReportParamsButtons';
import styles from './ReportParamsForm.less';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const dateRangeLayout = {
  labelCol: {
    sm: { span: 24 },
  },
  wrapperCol: {
    sm: { span: 24 },
    lg: { span: 20 },
  },
};

const ReportParamsForm = ({ form, title, onCancel }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [disableRange, setDisableRange] = useState(false);
  const { organizationGuid, users, report, reportCode, reportType } =
    useSelector(reportParamsDataSel);
  const isMobile = useSelector(isMobileSel);

  const allYouthReport = useSelector(areAllYouthSelected);

  const isAllDatesSelected = form.getFieldValue('allDates');

  const today = useMemo(() => moment(), []);

  const handleReportDone = useCallback(() => {
    setLoading(false);
    onCancel();
  }, [onCancel]);

  const runReport = useCallback(async () => {
    setLoading(true);
    const [startDate, endDate] = form.getFieldValue('dateRange');

    const isCustomReport = CustomReports[reportType];

    // See SBL-4493 TODO: update with new ticket
    const personGuids = users
      .map(item => item.personGuid)
      .filter(Boolean)
      .join(',');

    const params = {
      organizationGuid,
      StartDate: formatToReportsDate(startDate),
      FinishDate: formatToReportsDate(endDate),
      PersonGUIDList: personGuids,
    };

    if (allYouthReport || users.length > 25) {
      delete params.PersonGUIDList;
    }

    if (
      featureFlags.getFlag('SBL_5372_NEW_ADV_HIST_REPORT') &&
      reportType === CustomReports.AdvancementHistory
    ) {
      const targetUrl = `${REPORTS_ALT_BASE_URL}${ADV_HISTORY_CTX_API}`;
      const postData = new FormData();
      postData.set('ScoutList', personGuids);
      const response = await fetch(targetUrl, {
        method: 'POST',
        body: postData,
        credentials: 'include',
      });
      const { ContextToken } = await response.json();
      params.ContextToken = ContextToken;
      delete params.PersonGUIDList;
    }

    openReportWithQueryParams(
      isCustomReport ? getCustomReportUrl(reportCode) : report?.ReportURI,
      params,
    );
    handleReportDone();
  }, [
    form,
    reportCode,
    reportType,
    report?.ReportURI,
    allYouthReport,
    organizationGuid,
    users,
    handleReportDone,
  ]);

  const handleSubmit = useCallback(async () => {
    if (await validateForm(form)) {
      runReport();
    }
  }, [form, runReport]);

  const [startDate, endDate] = useMemo(() => {
    const dateRange = form.getFieldValue('dateRange');
    if (!dateRange) {
      return [today.clone().subtract(30, 'days'), today];
    }
    const [startDate, endDate] = dateRange;
    return [startDate || today.clone().subtract(30, 'days'), endDate || today];
  }, [form, today]);

  useEffect(() => {
    if (isAllDatesSelected) {
      setDisableRange(true);
      form.setFieldsValue({
        dateRange: [today.clone().subtract(15, 'years'), today],
      });
    } else if (isAllDatesSelected !== undefined) {
      setDisableRange(false);
      form.setFieldsValue({
        dateRange: [today, today],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [today, isAllDatesSelected]);

  const startDateFormatted = startDate.format('MM/DD/YYYY');
  const endDateFormatted = endDate.format('MM/DD/YYYY');

  return (
    <React.Fragment>
      <Card
        className={styles.formContainer}
        noPadding={!isMobile}
        shadow={isMobile}
      >
        {!!isMobile && (
          <T size="5" colored hr serif noMargin>
            {title}
          </T>
        )}
        <div className={styles.message}>
          <FormattedMessage
            id={`reports.ReportParamsForm.${
              users.length ? 'usersSelected' : 'noUsersSelected'
            }`}
            values={{
              youthAmount: users.length,
              startDate: startDateFormatted,
              endDate: endDateFormatted,
            }}
          />
        </div>
        <FormItem>
          {getFieldDecorator('allDates')(
            <Checkbox id="qa_allDatesCheckbox" name={'test'}>
              <S size="4" colored inline>
                <FormattedMessage id="reports.ReportParamsForm.allDates" />
              </S>
            </Checkbox>,
          )}
        </FormItem>
        <FormItem className={styles.dateRangeContainer} {...dateRangeLayout}>
          {getFieldDecorator('dateRange', {
            initialValue: [startDate, endDate],
          })(
            <RangePicker
              size="large"
              format={viewDateFormat}
              className={styles.dateRangeSelector}
              disableDates="future"
              disabled={disableRange}
              label={
                <FormattedMessage id="reports.ReportParamsForm.dateRange" />
              }
            />,
          )}
        </FormItem>
      </Card>
      <ReportParamsButtons
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </React.Fragment>
  );
};

ReportParamsForm.propTypes = {
  // provided by parent
  title: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  // provided by component itself
  form: PropTypes.object.isRequired,
};

ReportParamsForm.defaultProps = {
  users: [],
};

export default Form.create()(ReportParamsForm);
