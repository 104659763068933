import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SortSelect } from '@shared';

const { Option } = SortSelect;

const SortSection = ({ sorter, onChange }) => (
  <SortSelect sorter={sorter} onChange={onChange}>
    <Option name="name" type="string">
      <FormattedMessage id="advancement.PendingItems.column.name" />
    </Option>
    <Option name="advancementItem" type="string">
      <FormattedMessage id="advancement.PendingItems.column.itemDescription" />
    </Option>
    <Option name="completionDate" type="date">
      <FormattedMessage id="advancement.PendingItems.column.itemDate" />
    </Option>
  </SortSelect>
);

SortSection.propTypes = {
  sorter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SortSection;
