export enum BSAEnv {
  DV = 'dv',
  QA = 'qa',
  PP = 'pp',
  PD = 'pd',
}

export enum RankStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum UnparsedBoolean {
  False = 'False',
  True = 'True',
}

/**
 * Used in some den endpoints where the person is inside a Den subunit
 */
export enum DenType {
  LION = 'lions',
  BOBCAT = 'bobcat',
  TIGER = 'tigers',
  WOLF = 'wolves',
  BEAR = 'bears',
  WEBELOS = 'webelos',
  AOL = 'aol',
}

/**
 * Payment logs lookup types
 */
export enum PaymentTypes {
  CREDIT = 'Credit',
  PAYMENT = 'Payment',
  CHARGE = 'Charge',
  DEBIT = 'Debit',
}

/**
 * Payment logs status types
 */
export enum PaymentStatus {
  DUE = 'due',
  PAST_DUE = 'past-due',
}

/**
 * Payment logs sources for create request
 */
export enum PaymentSources {
  USER = 'User',
  UNIT = 'Unit',
}

/**
 * IDs of Relationship types got from /lookups/person/relationshipTypes
 */
export enum PersonRelationShipType {
  FatherOf = 1,
  MotherOf = 2,
  SonOf = 3,
}

export enum UnitStatus {
  Active = 'active',
  Lapsed = 'lapsed',
  Expired = 'expired',
  All = 'all',
  Unposted = 'Unposted',
}
