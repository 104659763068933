import { combineEpics } from 'redux-observable';
import { concat, from, of } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import { organizationGuidSel } from '@context';
import {
  advancementTypes,
  historyItemsTypes,
  intl,
  personGuidSel,
  updateRequests,
} from '@shared';
import { toastService } from '@toasts';
import { esbApiService, requestStorage } from '@utils';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  UPDATE_ADVANCEMENT_REQUEST,
  UPDATE_ADV_SUMMARY_REQUEST,
  updateActivityResponse,
  updateAdvancementDeferred,
  updateAdvancementError,
  updateAdvancementResponse,
  updateOfflineAdvancement,
} from './actions';
import { advancementSel, isOfflineOperationSel, personSel } from './selectors';
import services from './services';

const updateOfflineItem = async ({ itemId, date }, state) => {
  const personGuid = personGuidSel(state);
  const requests = (await requestStorage.getRequests(personGuid)).map(item =>
    item.id == itemId ? { ...item, date } : item,
  );
  await requestStorage.setRequests(personGuid, requests);
};

const showSuccessToast = isActivity => {
  const successMsg = intl.formatMessage({
    id: `editAdvancement.EditAdvancementForm.successMsg.${
      isActivity ? 'activity' : 'advancement'
    }`,
  });
  toastService.success(successMsg);
};

const updateOfflineAdvancementDate$ = ({ advancement }, state) => {
  const { completionDate: date } = advancement;

  return from(
    updateOfflineItem(
      {
        itemId: advancement.key,
        date,
      },
      state,
    ),
  ).pipe(
    mergeMap(() => {
      showSuccessToast();
      return concat(of(updateOfflineAdvancement()), of(updateRequests()));
    }),
  );
};

const updateAdvancementDate$ = ({ advancement, person }, state) => {
  const organizationGuid = organizationGuidSel(state);
  const { userId } = person;
  const {
    id: advancementId,
    completionDate: date,
    userAwardId,
    advancementType,
  } = advancement;

  return services
    .updateAdvancementDate$({
      organizationGuid,
      userId,
      advancementType,
      advancementId,
      userAwardId,
      date,
    })
    .pipe(
      mergeMap(res => {
        if (res === esbApiService.DEFERRED) {
          return of(updateAdvancementDeferred());
        }
        showSuccessToast();
        return of(
          updateAdvancementResponse({
            ...advancement,
            userId,
          }),
        );
      }),
    );
};

const updateActivityEpic$ = (personActiviy, updatedValues) =>
  services.updateActivity$(personActiviy, updatedValues).pipe(
    mergeMap(updatedResponse => {
      // extract activity values from update or create response, if is update, the response come in an array
      const activityValues = updatedResponse.reduce((acc, updatedValue) => {
        if (Array.isArray(updatedValue)) {
          const [{ activityValues }] = updatedValue;
          return [...acc, ...activityValues];
        } else if (updatedValue) {
          return [...acc, updatedValue];
        }
        return acc;
      }, []);
      const { userId, activityId } = personActiviy;
      showSuccessToast(true);
      return of(
        updateActivityResponse({
          activityId,
          userId,
          updatedRecords: activityValues,
        }),
      );
    }),
  );

const updateAdvancementRequestEpic$ = (action$, state$) =>
  action$.ofType(UPDATE_ADVANCEMENT_REQUEST).pipe(
    switchMap(({ payload }) => {
      const state = state$.value;
      const isRecordedOffline = isOfflineOperationSel(state);
      const advancement = advancementSel(state);
      const person = personSel(state);

      if (historyItemsTypes.ADVANCEMENT === advancement.type) {
        const updatedAdvancement = { ...advancement, ...payload };
        const updateEpic$ = isRecordedOffline
          ? updateOfflineAdvancementDate$
          : updateAdvancementDate$;

        if (
          updatedAdvancement.advancementType === advancementTypes.ADVENTURES &&
          !updatedAdvancement.id
        ) {
          updatedAdvancement.id = updatedAdvancement.adventureId;
        }

        return updateEpic$(
          { advancement: updatedAdvancement, person },
          state,
        ).pipe(catchAndReport(err => of(updateAdvancementError(err))));
      }

      const { personGuid } = person;
      return updateActivityEpic$({ ...advancement, personGuid }, payload).pipe(
        catchAndReport(err => of(updateAdvancementError(err))),
      );
    }),
  );

const showSuccessAdvSummaryToast = () => {
  const successMsg = intl.formatMessage({
    id: 'editAdvancement.EditAdvancementForm.successMsg.advSummary',
  });
  toastService.success(successMsg);
};

const updateAdvSummaryEpic$ = (action$, state$) =>
  action$.ofType(UPDATE_ADV_SUMMARY_REQUEST).pipe(
    switchMap(({ payload }) => {
      const state = state$.value;
      const { newAdvancement, advancementType, advancementId, onFail } =
        payload;
      const youth = state.youthProfile.memberDetails;
      const { userId } = youth;
      // having newStatus in the payload means we will use /unapprove endpoint
      const serviceCall = newAdvancement.newStatus
        ? services.unapproveAdvancement$({ ...newAdvancement })
        : services.updateAdvSummary$({
            userId,
            newAdvancement,
            advancementType,
            advancementId,
          });
      return serviceCall.pipe(
        mergeMap(res => {
          if (res === esbApiService.DEFERRED) {
            return of(updateAdvancementDeferred());
          }
          showSuccessAdvSummaryToast();
          return of(
            updateAdvancementResponse({
              ...payload,
              userId,
            }),
          );
        }),
        catchAndReport(err => {
          if (onFail) {
            onFail();
          }
          return of(updateAdvancementError(err));
        }),
      );
    }),
  );

export default combineEpics(
  updateAdvSummaryEpic$,
  updateAdvancementRequestEpic$,
);
