import { Program } from '@modules/shared';

type Unit = {
  unitId: string;
  denId: string;
  denType: string;
  denNumber: string;
  patrolId: string;
  patrolName: string;
  program: Program;
  unitFullName: string;
  organizationName: string;
  isParentGuardian: boolean;
  acceptGender: string;
  organizationGuid: string;
};

export default (unit: Unit, userUnits: Unit[] = []) => {
  const foundUnit = userUnits.find(item => +item.unitId === +unit.unitId);
  const program =
    unit.denId || unit.patrolId || !foundUnit ? undefined : foundUnit.program;

  let organizationName = unit.unitFullName;
  let isParentGuardian, acceptGender, organizationGuid;

  if (unit.denId || unit.patrolId) {
    organizationName = `${
      unit.denId ? `${unit.denType} ${unit.denNumber}` : ''
    }${unit.patrolId ? `${unit.patrolName}` : ''}`;
  } else if (foundUnit) {
    organizationName = foundUnit.organizationName;
    isParentGuardian = foundUnit.isParentGuardian;
    acceptGender = foundUnit.acceptGender;
    organizationGuid = foundUnit.organizationGuid;
  }

  return {
    program,
    organizationName,
    isParentGuardian,
    acceptGender,
    organizationGuid,
  };
};
