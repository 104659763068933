import { combineReducers, createReducer } from '@reduxjs/toolkit';

import {
  editAdvRqmtsError,
  editAdvRqmtsRequest,
  editAdvRqmtsResponse,
} from './actionsTyped';
import reducers from './reducers';

const advReqEditLoading = createReducer(false, builder => {
  builder
    .addCase(editAdvRqmtsRequest, () => true)
    .addCase(editAdvRqmtsResponse, () => false)
    .addCase(editAdvRqmtsError, () => false);
});

export default combineReducers({
  ...reducers,
  advReqEditLoading,
});
