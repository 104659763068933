import { ENV } from '@config';
import serviceWorkerAvailable from './serviceWorkerAvailable';

/**
 * Returns either 'self' URL (calling own domain)
 * or 'external' URL (CORS call)
 * depending on the current environment.
 */
export default (
  self, // url to use when calling self
  external, // url to use when calling external API directly
) => {
  if (serviceWorkerAvailable() || ENV === 'local') {
    return self;
  }
  return external;
};
