import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Link, { NavLink } from 'redux-first-router-link';

import {
  HELPDESK_URL,
  IA_HELP_URL,
  IS_LOCAL_ENV,
  SHOW_IN_APP_FEEDBACK,
  SHOW_USER_PERMISSIONS,
} from '@config';
import { ROUTE_FEATURE_FLAGS } from '@modules/featureFlags/duck/actions';
import { Menu, isYouthMemberSel, logout } from '@shared';
import { hasPermissionSel, openMasqueradeModal, permissions } from '@user';

import styles from './SettingsOptions.less';

const MenuItem = Menu.Item;
const MenuDivider = Menu.Divider;

const SettingsOptions = ({
  className,
  isMobile,
  onClick,
  onLogout,
  canMasquerade,
  onOpenMasqueradeModal,
  isYouthMember,
}) => {
  const idSuffix = isMobile ? 'Mobile' : '';
  const feedbackId = `qa_sendFeedback${idSuffix}`;
  const notYouthMember = !isYouthMember;
  return (
    <Menu
      className={className}
      onClick={onClick}
      inlineCollapsed={false}
      mode="inline"
    >
      {SHOW_USER_PERMISSIONS && notYouthMember && (
        <React.Fragment>
          <MenuDivider className={styles.divider} />
          <MenuItem key="/user-permissions" className={styles.item}>
            <NavLink
              to="/user-permissions"
              className={styles.link}
              activeClassName={styles.activeLink}
              exact
            >
              <FormattedMessage id="core.Header.Settings.userPermissions" />
            </NavLink>
          </MenuItem>
        </React.Fragment>
      )}
      <MenuDivider className={styles.divider} />
      <MenuItem key="/feedback" className={styles.item}>
        {SHOW_IN_APP_FEEDBACK ? (
          <NavLink
            to="/feedback"
            id={feedbackId}
            className={styles.link}
            activeClassName={styles.activeLink}
            exact
          >
            <FormattedMessage id="core.Header.Settings.feedback" />
          </NavLink>
        ) : (
          <Link
            to={HELPDESK_URL}
            target="_blank"
            id={feedbackId}
            className={styles.link}
          >
            <FormattedMessage id="core.Header.Settings.feedback" />
          </Link>
        )}
      </MenuItem>
      <MenuDivider className={styles.divider} />
      <MenuItem key="/help" className={styles.item}>
        <Link to={IA_HELP_URL} target="_blank" className={styles.link}>
          <FormattedMessage id="core.Header.Setting.help" />
        </Link>
      </MenuItem>
      <MenuDivider className={styles.divider} />
      <MenuItem key="/settings" className={styles.item}>
        <NavLink
          to="/settings"
          className={styles.link}
          activeClassName={styles.activeLink}
          exact
        >
          <FormattedMessage id="core.Header.Settings.settings" />
        </NavLink>
      </MenuItem>
      {canMasquerade && (
        <React.Fragment>
          <MenuDivider className={styles.divider} />
          <MenuItem key="/masquerade" className={styles.item}>
            <div className={styles.link} onClick={onOpenMasqueradeModal}>
              Masquerade
            </div>
          </MenuItem>
        </React.Fragment>
      )}
      {IS_LOCAL_ENV && (
        <React.Fragment>
          <MenuDivider className={styles.divider} />
          <MenuItem
            key="/feature-flags"
            className={styles.item}
            onClick={() => null}
          >
            <Link className={styles.link} to={ROUTE_FEATURE_FLAGS()}>
              Feature flags
            </Link>
          </MenuItem>
        </React.Fragment>
      )}
      {!isMobile && [
        <MenuDivider key="logout-divider" className={styles.divider} />,
        <MenuItem key="logout" className={styles.item}>
          <div id="qa_logout" className={styles.link} onClick={onLogout}>
            <FormattedMessage id="core.Header.Settings.logOut" />
          </div>
        </MenuItem>,
      ]}
    </Menu>
  );
};

SettingsOptions.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  onClick: PropTypes.func,
  // Provided by Component itself
  canMasquerade: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  onOpenMasqueradeModal: PropTypes.func.isRequired,
  isYouthMember: PropTypes.bool.isRequired,
};

const mapState = state => ({
  canMasquerade: hasPermissionSel(state, permissions.MASQUERADE),
  isYouthMember: isYouthMemberSel(state),
});

const mapDispatch = dispatch => ({
  onLogout: () => dispatch(logout()),
  onOpenMasqueradeModal: () => dispatch(openMasqueradeModal()),
});

export default connect(mapState, mapDispatch)(SettingsOptions);
