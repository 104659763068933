const RECENT_UNITS_STORAGE_KEY = 'RECENT_UNITS';

const retrieveRecentUnits = () => {
  const rawRecentUnits = localStorage.getItem(RECENT_UNITS_STORAGE_KEY) || '{}';
  return JSON.parse(rawRecentUnits);
};

export const retrieveUserRecentUnits = personGuid => {
  const recentUnits = retrieveRecentUnits();
  return recentUnits[personGuid] || [];
};

const storeRecentUnits = units => {
  const prevUnits = retrieveRecentUnits();
  localStorage.setItem(
    RECENT_UNITS_STORAGE_KEY,
    JSON.stringify({ ...prevUnits, ...units }),
  );
};

export const storeUserRecentUnits = (units, personGuid) => {
  storeRecentUnits({ [personGuid]: units });
};

const ACTIVE_CHILD_UNIT_KEY = 'ACTIVE_CHILD_UNIT';

export const retrieveLastActiveChildUnit = personGuid => {
  const rawData = localStorage.getItem(ACTIVE_CHILD_UNIT_KEY) || '{}';
  return JSON.parse(rawData)[personGuid] || null;
};
export const storeRecentUnit = (unit, personGuid) => {
  localStorage.setItem(
    ACTIVE_CHILD_UNIT_KEY,
    JSON.stringify({ [personGuid]: unit }),
  );
};
export const removeRecentUnit = () => {
  localStorage.setItem(ACTIVE_CHILD_UNIT_KEY, '{}');
};

export default {
  retrieveUserRecentUnits,
  storeUserRecentUnits,
  retrieveLastActiveChildUnit,
  storeRecentUnit,
  removeRecentUnit,
};
