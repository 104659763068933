import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import { Button } from 'bsa-ui';
import styles from './ProfileContactInfoCard.less';

export const DeleteEmailModal = ({
  visible,
  isLoading,
  titleId = 'deleteEmail',
  onCancel,
  onAccept,
}) => (
  <Modal
    headerColor="scouting-warm-gray"
    visible={visible}
    title={<FormattedMessage id={`shared.contactInfo.${titleId}.title`} />}
    onCancel={onCancel}
  >
    <div className={styles.deleteModalButtonsContainer}>
      <Button disabled={isLoading} onClick={onCancel}>
        {<FormattedMessage id="shared.cancel" />}
      </Button>
      <Button loading={isLoading} type="primary" onClick={onAccept}>
        {<FormattedMessage id="shared.delete" />}
      </Button>
    </div>
  </Modal>
);

DeleteEmailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  titleId: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};
