import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  packRosterItemsSel,
  organizationPositionSel,
} from '@shared/duck/selectors/general.selectors';
import { unitTypeIdSel } from '@context';
import { basicReportsModuleSel } from '../../common';
import {
  attendanceReportPositions,
  reportCodes,
  validReports,
} from '../constants';
import type { BasicReport } from './types';
import { emptyArr } from '@modules/shared/constants';
import { RootState } from '../../../../root/store';

export const allReportsSel = (state: RootState) =>
  basicReportsModuleSel(state).reports ||
  (emptyArr as unknown as BasicReport[]);

export const canUseAttendanceReportSel = createSelector(
  organizationPositionSel,
  positions => {
    const allRoles: string[] = positions?.allRoles || [];
    const permitted = attendanceReportPositions;
    const allowed = _.intersection(allRoles, permitted);
    return allowed.length > 0;
  },
);

/**
 * Used to list reports for Units in "Reports" tab
 */
export const reportsSel = createSelector(
  allReportsSel,
  unitTypeIdSel,
  canUseAttendanceReportSel,
  (reports, unitTypeId: number, canSeeAttendance) => {
    const validReportCodes: string[] =
      validReports[Number(unitTypeId) as keyof typeof validReports];

    if (!validReportCodes) {
      return reports as BasicReport[];
    }

    const result = reports
      .filter(({ reportCode }) => validReportCodes.includes(reportCode))
      .filter(item => {
        const isAttendance = item.reportCode === reportCodes.ATTENDANCE;
        if (!isAttendance) return true;

        return canSeeAttendance;
      });

    return result;
  },
);

export const reportByCodeSel = createSelector(
  (_state: RootState, props: { reportCode: string }) => props.reportCode,
  allReportsSel,
  (reportCode, reports) =>
    reports.find(report => report.reportCode === reportCode),
);

export const reportsByCodesSel = (reportCodes: string[] | null) =>
  createSelector(allReportsSel, reports => {
    if (Array.isArray(reportCodes)) {
      return reports.filter(({ reportCode }) =>
        reportCodes.includes(reportCode),
      );
    }
    return null;
  });

export const responseReportOrgGuidSel = createSelector(
  basicReportsModuleSel,
  ({ responseReportOrgGuid }) => responseReportOrgGuid,
);

export const loadingSel = createSelector(
  basicReportsModuleSel,
  ({ loading }) => loading,
);

export const selectYouthModalOpenSel = createSelector(
  basicReportsModuleSel,
  ({ selectYouthModalOpen }) => selectYouthModalOpen,
);

export const selectedReportCodeSel = createSelector(
  basicReportsModuleSel,
  ({ selectedReportCode }) => selectedReportCode,
);

export const selectedReportSel = createSelector(
  selectedReportCodeSel,
  reportsSel,
  (selectedCode, reports) =>
    reports.find(({ reportCode }) => selectedCode == reportCode) || {},
);
export const availableYouthSel = createSelector(
  packRosterItemsSel,
  packRoster => packRoster.filter(({ isAdult }) => !isAdult),
);
