import { createSelector } from 'reselect';

import { recentUnitsSel } from '@shared';
import { applySort } from '@utils';

import { moduleName } from '../constants';
import {
  councilUnitsSorters,
  recentUnitsSorters,
} from '../councilUnitsSorters';

const moduleSel = state => state[moduleName];
export const activeTabSel = state => moduleSel(state).activeTab;
export const searchLoadingSel = state => moduleSel(state).searchLoading;
export const unitsSel = state => moduleSel(state).units;
export const searchSel = state => moduleSel(state).search;
export const recentUnitsSearchSel = state => moduleSel(state).recentUnitsSearch;
export const sorterSel = state => moduleSel(state).sorter;
export const recentUnitsSorterSel = state => moduleSel(state).recentUnitsSorter;

const makeSearch = rawQuery => {
  const query = `${rawQuery || ''}`.toLowerCase();
  return rawValue => {
    const value = `${rawValue || ''}`.toLowerCase();
    return value.includes(query);
  };
};

export const filteredUnitsSel = createSelector(
  unitsSel,
  searchSel,
  sorterSel,
  (units, search, sorter) => {
    const searchIncludes = makeSearch(search);
    const filteredItems = units.filter(
      ({ charteredOrganizationName, active }) =>
        active && searchIncludes(charteredOrganizationName),
    );

    return applySort({
      sorter,
      items: filteredItems,
      sorters: councilUnitsSorters,
    });
  },
);
export const selectedUnitSel = state => moduleSel(state).selectedUnit;
export const districtsLoadingSel = state => moduleSel(state).districtsLoading;
export const districtsSel = state => moduleSel(state).districts;
export const filteredRecentUnitsSel = createSelector(
  recentUnitsSel,
  recentUnitsSearchSel,
  recentUnitsSorterSel,
  (recentUnits, search, sorter) => {
    const searchIncludes = makeSearch(search);
    const filteredItems = recentUnits.filter(
      unit =>
        searchIncludes(unit.charteredOrganizationName) ||
        searchIncludes(unit.districtName) ||
        searchIncludes(unit.unitNumber) ||
        searchIncludes(unit.unitType),
    );
    return applySort({
      sorter,
      items: filteredItems,
      sorters: recentUnitsSorters,
    });
  },
);
