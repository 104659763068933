/* eslint-disable react/no-multi-comp */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Currency,
  DateView,
  PersonAvatar,
  AdvancementType,
  Img,
} from '@shared';
import styles from './AdvancementReportTable.less';

const advancementReportTableColumns = showPrice => [
  {
    dataIndex: 'youth.shortName',
    title: (
      <FormattedMessage id="advancementReport.AdvancementReport.column.name" />
    ),
    render(name) {
      return (
        <div className={styles.avatarAndName}>
          <PersonAvatar isAdult={false} className={styles.avatar} />
          {name}
        </div>
      );
    },
  },
  {
    dataIndex: 'youth.dateCompletedEarned',
    title: (
      <FormattedMessage id="advancementReport.AdvancementReport.column.dateCompleted" />
    ),
    render(dateApproved) {
      return <DateView value={dateApproved} />;
    },
  },
  {
    dataIndex: 'advancementType',
    title: (
      <FormattedMessage id="advancementReport.AdvancementReport.column.type" />
    ),
    render(advancementType) {
      return <AdvancementType type={advancementType} />;
    },
  },
  {
    dataIndex: 'imageUrl100',
    width: '3rem',
    render(imageUrl) {
      return (
        imageUrl && <Img src={imageUrl} className={styles.advancementImage} />
      );
    },
  },
  {
    dataIndex: 'name',
    title: (
      <FormattedMessage id="advancementReport.AdvancementReport.column.item" />
    ),
    render(advancementName) {
      return advancementName;
    },
  },
  ...(showPrice
    ? [
        {
          dataIndex: 'price',
          title: (
            <FormattedMessage id="advancementReport.AdvancementReport.column.price" />
          ),
          render(price) {
            return <Currency value={price} />;
          },
        },
      ]
    : []),
];

export default advancementReportTableColumns;
