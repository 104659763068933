import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@material-ui/icons/Info';

// import Avatar from '../Avatar';
import Button from '../Button';
import S from '../S';
import styles from './GoProAdMobile.less';

const GoProAdMobile = () => (
  <div className={styles.container}>
    <InfoIcon />
    <S size="4" bold className={styles.goPro}>
      <FormattedMessage id="shared.GoProAdMobile.goPro" />
    </S>
    <S size="6">
      <FormattedMessage id="shared.GoProAdMobile.title" />
      <br />
      <FormattedMessage id="shared.GoProAdMobile.subtitle" />
    </S>
    <div className={styles.spacer} />
    <Button className={styles.button} shadow noBorder fitText color="error">
      <FormattedMessage id="shared.GoProAdMobile.go" />
    </Button>
  </div>
);

export default GoProAdMobile;
