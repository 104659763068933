import { lazier } from '@shared';

import ProfilePagePlaceholder from './ProfilePagePlaceholder';

export { ProfilePagePlaceholder };

export const ProfilePage = lazier(
  () => import('./ProfilePage'),
  ProfilePagePlaceholder,
);

export const OwnActivityLogsPage = lazier(
  () => import('./OwnActivityLogsPage'),
  ProfilePagePlaceholder,
);
