/* eslint-disable react/no-multi-comp */
import React from 'react';

import { statusMapParser } from '@bsa/scouting-js-utils/src/constants/advancements';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { advancementStatuses } from '../../constants';
import { intl } from '../../localization';
import styles from './AdvancementStatus.less';
import AdvancementStatusIcon from './AdvancementStatusIcon';

const supportedPPForms = [
  advancementStatuses.APPROVED,
  advancementStatuses.AWARDED,
];

const getTranslationId = (
  status,
  { completedPending, startedInProgress, presentProgressive },
) => {
  if (status === advancementStatuses.COMPLETED && completedPending) {
    status += '.pending';
  } else if (status === advancementStatuses.STARTED && startedInProgress) {
    status += '.inProgress';
  } else if (presentProgressive && supportedPPForms.includes(status)) {
    status += '.pp';
  }
  return `shared.AdvancementStatus.${status}`;
};

// eslint-disable-next-line react/prop-types
const Text = ({ status, startedInProgress, completedPending, className }) => {
  const translationId = getTranslationId(status, {
    startedInProgress,
    completedPending,
  });
  return (
    <span className={className}>
      <FormattedMessage id={translationId} />
    </span>
  );
};

const AdvancementStatus = ({
  status,
  startedInProgress,
  completedPending,
  text,
  icon,
  reversed,
  className,
}) => {
  let textNode = '';
  let iconNode = '';
  if (icon) {
    iconNode = (
      <AdvancementStatusIcon
        status={statusMapParser[status]}
        className={className}
      />
    );
  }
  if (text) {
    textNode = (
      <Text
        status={status}
        startedInProgress={startedInProgress}
        completedPending={completedPending}
        className={className}
      />
    );
  }

  return (
    <div
      className={cn(
        styles.container,
        { [styles.reversed]: reversed },
        className,
      )}
    >
      {iconNode}
      {iconNode && textNode && <span className={styles.spacer} />}
      {textNode}
    </div>
  );
};

const getTranslation = (
  status,
  { completedPending, startedInProgress, presentProgressive } = {},
) =>
  intl.formatMessage({
    id: getTranslationId(status, {
      completedPending,
      startedInProgress,
      presentProgressive,
    }),
  });

AdvancementStatus.propTypes = {
  status: PropTypes.string.isRequired,
  startedInProgress: PropTypes.bool,
  completedPending: PropTypes.bool,
  text: PropTypes.bool,
  icon: PropTypes.bool,
  reversed: PropTypes.bool,
  className: PropTypes.string,
};

AdvancementStatus.defaultProps = {
  text: true,
};

AdvancementStatus.getTranslation = getTranslation;
AdvancementStatus.Icon = AdvancementStatusIcon;
AdvancementStatus.Text = Text;

export default AdvancementStatus;
