const get = () => (window.location.hash || '').substr(1);
const set = hash => (window.location.hash = hash);
const getValid = (validHashes, defaultHash) => {
  const hash = get();
  if (validHashes.includes(hash)) {
    return hash;
  }
  return defaultHash;
};

export default {
  get,
  set,
  getValid,
};
