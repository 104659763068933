import { combineReducers } from 'redux';

import {
  LOAD_AND_OPEN_CAMPOUT_MODAL,
  OPEN_EDIT_CAMPOUT,
} from '@modules/progress/campouts/duck/actions';
import {
  LOAD_AND_OPEN_HIKE_MODAL,
  OPEN_EDIT_HIKE,
} from '@modules/progress/hikes';
import {
  LOAD_AND_OPEN_LONG_CRUISE_MODAL,
  OPEN_EDIT_LONG_CRUISE,
} from '@modules/progress/longCruise';
import {
  LOAD_AND_OPEN_SERVICE_MODAL,
  OPEN_EDIT_SERVICE,
} from '@modules/progress/service';
import { resetOnLogoutReducer } from '@utils';

import { ITEMS_PER_PAGE_OPTIONS, selectedActivityFilters } from '../constants';
import {
  FILTER_ACTIVITY_TEXT,
  GET_ACTIVITIES_ERROR,
  GET_ACTIVITIES_EVENTS_ERROR,
  GET_ACTIVITIES_EVENTS_RESPONSE,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_RESPONSE,
  SET_ACTIVITY_TYPE_FILTERS,
  SET_CURRENT_PAGE,
  SET_ITEMS_PER_PAGE,
  SET_NEW_DATE,
} from './actions';

const activities = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ACTIVITIES_RESPONSE:
      return payload.activities || [];
    case GET_ACTIVITIES_ERROR:
      return [];
    default:
      return state;
  }
};

const events = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ACTIVITIES_EVENTS_RESPONSE:
      return payload || [];
    case GET_ACTIVITIES_EVENTS_ERROR:
      return [];
    default:
      return state;
  }
};

const isActivitiesLoading = (state = false, { type }) => {
  switch (type) {
    case GET_ACTIVITIES_REQUEST:
      return true;
    case GET_ACTIVITIES_ERROR:
    case GET_ACTIVITIES_RESPONSE:
      return false;
    default:
      return state;
  }
};

const isEditActivitiesLoading = (state = false, { type }) => {
  switch (type) {
    case LOAD_AND_OPEN_SERVICE_MODAL:
    case LOAD_AND_OPEN_CAMPOUT_MODAL:
    case LOAD_AND_OPEN_HIKE_MODAL:
    case LOAD_AND_OPEN_LONG_CRUISE_MODAL:
      return true;
    case OPEN_EDIT_SERVICE:
    case OPEN_EDIT_CAMPOUT:
    case OPEN_EDIT_HIKE:
    case OPEN_EDIT_LONG_CRUISE:
      return false;
    default:
      return state;
  }
};

const isEventsLoading = (state = false, { type }) => {
  switch (type) {
    case GET_ACTIVITIES_REQUEST:
      return true;
    case GET_ACTIVITIES_EVENTS_ERROR:
    case GET_ACTIVITIES_EVENTS_RESPONSE:
      return false;
    default:
      return state;
  }
};

const activitiesDate = (state = null, { type, payload: selectedDate }) => {
  switch (type) {
    case GET_ACTIVITIES_REQUEST:
    case SET_NEW_DATE:
      return selectedDate;
    default:
      return state;
  }
};

const activityFilters = (
  state = selectedActivityFilters,
  { type, payload },
) => {
  switch (type) {
    case SET_ACTIVITY_TYPE_FILTERS:
      return { ...state, [payload.key]: payload.value };
    default:
      return state;
  }
};

const filterActivityText = (state = '', { type, payload }) => {
  switch (type) {
    case FILTER_ACTIVITY_TEXT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const [defaultOption] = ITEMS_PER_PAGE_OPTIONS;
const itemsPerPage = (state = defaultOption, { type, payload }) => {
  switch (type) {
    case SET_ITEMS_PER_PAGE:
      return payload;
    default:
      return state;
  }
};

const currentActivityPage = (state = 1, { type, payload }) => {
  switch (type) {
    case FILTER_ACTIVITY_TEXT:
    case GET_ACTIVITIES_REQUEST:
    case SET_NEW_DATE:
    case SET_ITEMS_PER_PAGE:
      return 1;
    case SET_CURRENT_PAGE:
      return payload;
    default:
      return state;
  }
};

const reducer = combineReducers({
  activities,
  activitiesDate,
  isActivitiesLoading,
  isEditActivitiesLoading,
  activityFilters,
  filterActivityText,
  itemsPerPage,
  currentActivityPage,
  events,
  isEventsLoading,
});

export default resetOnLogoutReducer(reducer);
