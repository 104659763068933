import React from 'react';

import SyncIcon from '@material-ui/icons/Sync';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, Button } from '@shared';

import { offlineSel, openSyncModal, pendingRequestsCountSel } from '../../duck';
import styles from './SyncPendingButton.less';

const SyncPendingButton = ({ className, uppercase }) => {
  const dispatch = useDispatch();
  const itemCount = useSelector(pendingRequestsCountSel);
  const isOffline = useSelector(offlineSel);

  const onOpenSyncModal = () => {
    dispatch(openSyncModal());
  };

  if (itemCount) {
    return (
      <Button
        onClick={onOpenSyncModal}
        className={className}
        icon={<SyncIcon />}
        uppercase={uppercase}
        fitText
        ghost
        color="info"
        noBorder
      >
        <FormattedMessage
          id={
            isOffline
              ? 'offline.SyncPendingButton.readyToUpload'
              : 'offline.SyncPendingButton.text'
          }
          values={{ count: itemCount }}
        />
        <Badge count={itemCount} className={styles.badge} />
      </Button>
    );
  }
  return <></>;
};

SyncPendingButton.propTypes = {
  className: PropTypes.string,
  uppercase: PropTypes.bool,
};

SyncPendingButton.defaultProps = {
  className: '',
  uppercase: false,
};

export default SyncPendingButton;
