import isFunc from 'lodash/isFunction';
import { createSelector } from 'reselect';

import { emptyObj } from '../../shared/constants';
import { moduleName } from '../constants';

const moduleSel = state => state[moduleName];
export const pathnameSel = state => moduleSel(state).pathname;
export const typeSel = state => moduleSel(state).type;
export const isCurrentPageSel = (state, ...pageActionTypes) =>
  pageActionTypes.includes(typeSel(state));
export const payloadSel = state => moduleSel(state).payload || emptyObj;
export const querySel = state => moduleSel(state).query || emptyObj;
export const prevSel = state => moduleSel(state).prev;
export const kindSel = state => moduleSel(state).kind;
export const routesMapSel = state => moduleSel(state).routesMap;
export const pageTitleIdSel = createSelector(
  routesMapSel,
  typeSel,
  payloadSel,
  (routesMap, type, routeParams) => {
    const route = routesMap[type];
    return route.getTitleId && isFunc(route.getTitleId)
      ? route.getTitleId(routeParams)
      : route.titleId;
  },
);
