import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { ProgramColors } from '@shared';

const CurrentProgramColors = ({
  program,
  bg,
  text,
  // this param is just so that dispatch is not passed down
  // eslint-disable-next-line
  dispatch,
  ...props
}) => <ProgramColors program={program} bg={bg} text={text} {...props} />;

CurrentProgramColors.propTypes = {
  program: PropTypes.string,
  bg: PropTypes.bool,
  text: PropTypes.bool,
};

CurrentProgramColors.defaultProps = {
  program: 'DEFAULT',
  bg: true,
  text: true,
};

const mapState = state => ({
  program: programSel(state),
});

export default connect(mapState)(CurrentProgramColors);
