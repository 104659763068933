import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mapTo';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { SET_ORGANIZATION, organizationGuidSel } from '@context';
import { isCurrentPageSel } from '@location';
import { intl, personGuidSel } from '@shared';
import { toastService } from '@toasts';
import { unitInfoRequest } from '@unit';
import { catchAndReport } from '@utils/rxjs/operators';

import { userDataRequest } from '../../user';
import { epics as advancementHistoryEpics } from '../advancementHistory';
import { epics as advancementUploadEpics } from '../advancementUpload';
import {
  EDUCATION_RECORDS_REQUEST,
  EXTENDED_ADULT_ROSTER_REQUEST,
  EXTENDED_PARENT_ROSTER_REQUEST,
  EXTENDED_ROSTER_REQUEST,
  QE_SCHOOL_REQUEST,
  ROUTE_ADVANCEMENT,
  SEARCH_SCHOOLS_REQUEST,
  educationRecordsError,
  educationRecordsResponse,
  extendedAdultRosterResponse,
  extendedParentRosterError,
  extendedParentRosterResponse,
  extendedRosterError,
  extendedYouthRosterResponse,
  parentRosterResponse,
  saveQESchoolsError,
  saveQESchoolsResponse,
  searchSchoolsError,
  searchSchoolsResponse,
} from '../common';
import { epics as packRosterEpics } from '../packRoster';
import purchaseOrderEpics from '../purchaseOrder/duck/epics';
import subUnitEpics from '../subUnits/duck/epics';
import {
  BULK_REQUIREMENT_ENTRY_REQUEST,
  REQUIREMENTS_BY_RANK_AND_VERSION_REQUEST,
  VERSIONS_BY_RANK_REQUEST,
  bulkReqEntryError,
  bulkReqEntryResponse,
  requirementsByRankAndVersionError,
  requirementsByRankAndVersionResponse,
  versionsByRankError,
  versionsByRankResponse,
} from './actions';
import services from './services';

const refetchUnitInfo$ = (action$, state$) =>
  action$
    .ofType(SET_ORGANIZATION)
    .filter(() => isCurrentPageSel(state$.value, ROUTE_ADVANCEMENT))
    .mapTo(unitInfoRequest());

const sbExtendedYouthProfile$ = (action$, state$) =>
  action$.ofType(EXTENDED_ROSTER_REQUEST).switchMap(({ payload }) => {
    const { memberIds } = payload;

    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);

    return services
      .getSBExtendedProfile$(memberIds, organizationGuid)
      .map(extendedYouthRosterResponse)
      .catchAndReport(err => Observable.of(extendedRosterError(err)));
  });

const sbExtendedAdultProfile$ = (action$, state$) =>
  action$.ofType(EXTENDED_ADULT_ROSTER_REQUEST).switchMap(({ payload }) => {
    const { memberIds } = payload;

    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);

    return services
      .getSBExtendedProfile$(memberIds, organizationGuid)
      .map(extendedAdultRosterResponse)
      .catchAndReport(err => Observable.of(extendedRosterError(err)));
  });

const sbExtendedParentProfile$ = (action$, state$) =>
  action$.ofType(EXTENDED_PARENT_ROSTER_REQUEST).switchMap(() => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);

    return services
      .getParentProfile$(organizationGuid)
      .switchMap(res => {
        const memberIds = res
          .filter(parent => parent.parentInformation.memberId)
          .map(parent => parent.parentInformation.memberId);
        return forkJoin([
          Observable.of(res),
          services.getSBExtendedProfile$(memberIds, organizationGuid),
        ]);
      })
      .switchMap(([parentsProfile, extendedProfiles]) =>
        Observable.concat(
          of(parentRosterResponse(parentsProfile)),
          of(extendedParentRosterResponse(extendedProfiles)),
        ),
      )
      .catchAndReport(err => Observable.of(extendedParentRosterError(err)));
  });

const searchSchools$ = action$ =>
  action$.ofType(SEARCH_SCHOOLS_REQUEST).switchMap(({ payload }) => {
    const { name, city, state, zip } = payload;

    return services
      .fetchQESchools$(name, city, state, zip)
      .map(searchSchoolsResponse)
      .catchAndReport(err => Observable.of(searchSchoolsError(err)));
  });

const educationRecords$ = (action$, state$) =>
  action$.ofType(EDUCATION_RECORDS_REQUEST).switchMap(({ payload }) => {
    const { memberIds } = payload;
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);

    return services
      .getSBEducationRecords$(memberIds, organizationGuid)
      .map(educationRecordsResponse)
      .catchAndReport(err => Observable.of(educationRecordsError(err)));
  });

const saveQESchools$ = (action$, state$) =>
  action$.ofType(QE_SCHOOL_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const personGuid = personGuidSel(state);
    const { data, toastText } = payload;

    return services
      .saveSBSchoolInformation$(data, organizationGuidSel(state))
      .pipe(
        tap(() =>
          toastService.success(
            intl.formatMessage({
              id: toastText,
            }),
          ),
        ),
        mergeMap(() => {
          const hasLoggedInUser = data.find(
            item => item.personGuid === personGuid,
          );
          return hasLoggedInUser
            ? Observable.concat(
                of(saveQESchoolsResponse()),
                of(userDataRequest()),
              )
            : Observable.of(saveQESchoolsResponse());
        }),
        catchAndReport(err => Observable.of(saveQESchoolsError(err))),
      );
  });

// only the three most recent versions are needed
const cleanupVersionsResponse = response => {
  const { versions } = response;
  return versions.slice(0, 3);
};

const loadRankVersionsEpic$ = action$ =>
  action$.pipe(
    ofType(VERSIONS_BY_RANK_REQUEST),
    switchMap(({ payload }) => {
      const rankId = payload;
      return services.fetchRankVersions$(rankId).pipe(
        map(response => cleanupVersionsResponse(response)),
        map(versionsByRankResponse),
        catchAndReport(err => of(versionsByRankError(err))),
      );
    }),
  );
const loadRequirementsByRankdAndVersionEpic$ = action$ =>
  action$.pipe(
    ofType(REQUIREMENTS_BY_RANK_AND_VERSION_REQUEST),
    switchMap(({ payload }) => {
      const { rankId, versionId } = payload;
      return services
        .fetchRequirementsByRankAndVersion$(rankId, versionId)
        .pipe(
          map(requirementsByRankAndVersionResponse),
          catchAndReport(err => of(requirementsByRankAndVersionError(err))),
        );
    }),
  );
const bulkUpdateRequirementsEpic$ = action$ =>
  action$.pipe(
    ofType(BULK_REQUIREMENT_ENTRY_REQUEST),
    switchMap(({ payload }) => {
      const { rankId, data } = payload;
      return services.bulkUpdateRequirements$(rankId, data).pipe(
        map(bulkReqEntryResponse),
        catchAndReport(err => of(bulkReqEntryError(err))),
      );
    }),
  );

export default combineEpics(
  refetchUnitInfo$,
  sbExtendedYouthProfile$,
  sbExtendedAdultProfile$,
  sbExtendedParentProfile$,
  searchSchools$,
  educationRecords$,
  saveQESchools$,
  advancementHistoryEpics,
  advancementUploadEpics,
  packRosterEpics,
  subUnitEpics,
  purchaseOrderEpics,
  loadRankVersionsEpic$,
  loadRequirementsByRankdAndVersionEpic$,
  bulkUpdateRequirementsEpic$,
);
