import React from 'react';

import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { gtm, sentryReport } from '@shared/utils';

import ErrorPage from './ErrorPage';

const FallbackComponent = () => <ErrorPage code="UI" />;

// eslint-disable-next-line react/no-multi-comp, react/prop-types
function ErrorBoundary({ children }) {
  const handleError = error => {
    sentryReport.ui(error);
    gtm.pushEvent({
      event: 'APP_CRASH',
      category: gtm.categories.APP_CRASH,
    });
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={handleError}
    >
      {children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
