import { gtm } from '@shared';

import { moduleName } from '../constants';
import service from './services';

export const SET_LOCALE = `${moduleName}/SET_LOCALE`;

export const setLocale = locale => () => {
  service.storeLocale(locale);
  gtm.pushEvent({
    event: SET_LOCALE,
    category: gtm.categories.CONTEXT,
    label: locale,
  });

  window.location.reload();
};
