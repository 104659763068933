// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener

let passiveSupported = false;

try {
  const options = Object.defineProperty({}, 'passive', {
    // TODO: check this
    // eslint-disable-next-line getter-return
    get: function () {
      passiveSupported = true;
    },
  });

  window.addEventListener('test', options, options);
  window.removeEventListener('test', options, options);
} catch (err) {
  passiveSupported = false;
}

export default passiveSupported;
