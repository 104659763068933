import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import PersonAvatar from '../PersonAvatar';
import S from '../S';
import { Col, Row, Tag } from '../bsaComponents';
import styles from './PermissionsPerson.less';
import PersonToggle from './PersonToggle';

const PermissionsPerson = ({
  data,
  onCloseAction,
  permissionType,
  onToggle,
  isMultiple,
  isMultipleChecked,
  isMultipleUpdate,
}) => {
  const { personShortFullName, isAdult, userId, permissions, pictureUrl } =
    data;

  const currentStatus = permissions.some(
    perm => perm.permissionID === permissionType,
  );

  const [checked, setchecked] = useState(currentStatus);

  const handleClose = useCallback(
    () => onCloseAction(userId),
    [onCloseAction, userId],
  );

  const handleSwitch = e => {
    setchecked(e);
    onToggle(e, userId);
  };

  return (
    <Tag className={styles.tag} outline={false}>
      <Row type="flex" align="middle" wrap={false}>
        <Col>
          <PersonAvatar isAdult={isAdult} src={pictureUrl} />
        </Col>
        <Col className={styles.personName}>
          <S size={'3'}>{personShortFullName}</S>
        </Col>
        <Col className={styles.closeIcon}>
          <div onClick={handleClose}>
            <PersonToggle
              checked={
                isMultipleUpdate && isMultiple ? isMultipleChecked : checked
              }
              text="Is Active"
              size={'default'}
              offstyle="btn-danger"
              onstyle="btn-success"
              id="qa_priceToggle"
              onChange={handleSwitch}
              disabled={isMultiple}
            />
          </div>
        </Col>
      </Row>
    </Tag>
  );
};

PermissionsPerson.propTypes = {
  data: PropTypes.object.isRequired,
  onCloseAction: PropTypes.func,
  closeAction: PropTypes.bool,
  permissionType: PropTypes.number,
  onToggle: PropTypes.func,
  isMultiple: PropTypes.bool,
  isMultipleChecked: PropTypes.array,
  isMultipleUpdate: PropTypes.bool,
};

PermissionsPerson.defaultProps = {
  onCloseAction: () => {},
  closeAction: false,
};

export default PermissionsPerson;
