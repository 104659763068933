import React from 'react';

import PropTypes from 'prop-types';

import { TableMobile } from '@shared/components';

import { qaRowClassName } from '../../helpers';
import columns from './historyReportsRows';

const HistoryReportsList = ({ items, loading, onOpenLink }) => (
  <TableMobile
    key="items"
    getItemClassName={qaRowClassName}
    items={items}
    loading={loading}
    rowId="POID"
    rows={columns(onOpenLink)}
  />
);

HistoryReportsList.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onOpenLink: PropTypes.func.isRequired,
};

export default HistoryReportsList;
