import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/takeUntil';
import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { catchAndReport } from '@utils/rxjs/operators';
import '@utils/rxjs.add.operator.catchAndReport';
import { uniqBy } from 'lodash';
import { isCurrentPageSel } from '@location';
import { organizationGuidSel, SET_ORGANIZATION } from '@context';
import { intl, organizationPositionsSel, unitTypeIdForUnitType } from '@shared';
import { toastService } from '@toasts';
import { services as subUntisServices } from '../../advancement/subUnits';
import {
  UNITS_ADULT_REQUEST,
  unitsAdultsResponse,
  unitsAdultsError,
  UNITS_YOUTH_REQUEST,
  GET_ALL_SUBUNITS_REQUEST,
  getAllSubUnitsResponse,
  getAllSubUnitsError,
  unitsYouthsResponse,
  unitsYouthsError,
  UPDATE_PERMISSION_REQUEST,
  updatePermissionResponse,
  updatePermissionError,
  ASSIGN_FUNCTIONAL_REQUEST,
  assignFunctionalError,
  assignFunctionalResponse,
  EXPIRE_FUNCTIONAL_REQUEST,
  expireFunctionalError,
  expireFunctionalResponse,
  getOrgPositionsResponse,
  getOrgPositionsError,
  GET_ORG_POSITIONS_REQUEST,
  getOrgPositionsRequest,
  EXPIRE_ASSIGN_FUNCTIONAL_REQUEST,
  expireAssignFunctionalError,
  expireAssignFunctionalResponse,
  ROUTE_PERMISSIONS_MANAGER,
  REASSIGN_POSITIONS_REQUEST,
  reassignPositionsResponse,
  reassignPositionsError,
} from './actions';
import services from './services';

const getUnitsAdults$ = (action$, state$) =>
  action$.ofType(UNITS_ADULT_REQUEST).mergeMap(() => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);
    const rosterUrl = 'organizations/v2/units';
    const mapRosterId = 'users';

    return services
      .adultsCall$(organizationGuid, rosterUrl, mapRosterId)
      .map(unitsAdultsResponse)
      .catchAndReport(err => Observable.of(unitsAdultsError(err)));
  });

const getUnitsYouths$ = (action$, state$) =>
  action$.ofType(UNITS_YOUTH_REQUEST).mergeMap(() => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);
    const rosterUrl = 'organizations/v2/units';
    const mapRosterId = 'users';

    return services
      .youthsCall$(organizationGuid, rosterUrl, mapRosterId)
      .map(unitsYouthsResponse)
      .catchAndReport(err => Observable.of(unitsYouthsError(err)));
  });

const getAllSubUnitsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ALL_SUBUNITS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const units = organizationPositionsSel(state);

      const troopsAndPaksUnits = units
        .filter(
          ({ unitTypeId }) =>
            +unitTypeId === unitTypeIdForUnitType.Pack ||
            +unitTypeId === unitTypeIdForUnitType.Troop,
        )
        .map(unit => {
          const [orgGuid] = unit.organizationGuid.split('*');
          return {
            ...unit,
            organizationGuid: orgGuid,
          };
        });

      const uniqTroopsAndPaksUnits = uniqBy(
        troopsAndPaksUnits,
        'organizationGuid',
      );

      if (!uniqTroopsAndPaksUnits.length) {
        return Observable.of(getAllSubUnitsResponse([]));
      }

      return forkJoin(
        uniqTroopsAndPaksUnits.map(({ organizationGuid }) =>
          subUntisServices.getSubUnits$(organizationGuid),
        ),
      ).pipe(
        switchMap(orgs => Observable.concat(of(getAllSubUnitsResponse(orgs)))),
        catchAndReport(err => Observable.concat(of(getAllSubUnitsError(err)))),
      );
    }),
  );

const updatePermission$ = action$ =>
  action$.ofType(UPDATE_PERMISSION_REQUEST).switchMap(({ payload }) => {
    const { youthEnabled, youthDisabled } = payload;

    return forkJoin([
      services.updatePermission$(youthEnabled, youthDisabled),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'permissionsManager.EditPermissionsModal.save.success',
          }),
        ),
      ),
      mergeMap(() => Observable.concat(of(updatePermissionResponse()))),
      catchAndReport(err => Observable.of(updatePermissionError(err))),
    );
  });

const assignFunctionalEpic$ = action$ =>
  action$.ofType(ASSIGN_FUNCTIONAL_REQUEST).switchMap(({ payload }) => {
    const { personGuid, assignPayload, expirePayload } = payload;

    return forkJoin([
      services.assignFunctional$(assignPayload, personGuid),
    ]).pipe(
      tap(
        () =>
          !expirePayload &&
          toastService.success(
            intl.formatMessage({
              id: 'PositionManager.AddPersonModal.save.success',
            }),
          ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(assignFunctionalResponse()),
          of(getOrgPositionsRequest()),
        ),
      ),
      catchAndReport(err => Observable.of(assignFunctionalError(err))),
    );
  });

const expireFunctionalEpic$ = action$ =>
  action$.ofType(EXPIRE_FUNCTIONAL_REQUEST).switchMap(({ payload }) => {
    const { personGuid, expirePayload } = payload;

    return forkJoin([
      services.expireFunctional$(expirePayload, personGuid),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'PositionManager.AddPersonModal.expire.success',
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(expireFunctionalResponse()),
          of(getOrgPositionsRequest()),
        ),
      ),
      catchAndReport(err => Observable.of(expireFunctionalError(err))),
    );
  });

const expireAssignFunctionalEpic$ = action$ =>
  action$.ofType(EXPIRE_ASSIGN_FUNCTIONAL_REQUEST).switchMap(({ payload }) => {
    const { personGuid, assignPayload, expirePayload } = payload;

    return forkJoin([
      services.expireFunctional$(expirePayload, personGuid),
      services.assignFunctional$(assignPayload, personGuid),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'PositionManager.AddPersonModal.expire.success',
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(expireFunctionalResponse()),
          of(assignFunctionalResponse()),
          of(getOrgPositionsRequest()),
          of(expireAssignFunctionalResponse()),
        ),
      ),
      catchAndReport(err =>
        Observable.concat(
          of(expireFunctionalError(err)),
          of(assignFunctionalError(err)),
          of(expireAssignFunctionalError(err)),
        ),
      ),
    );
  });

const getOrgPositionsEpic$ = (action$, state$) =>
  action$.ofType(GET_ORG_POSITIONS_REQUEST).mergeMap(() => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);

    return services
      .getOrgPositions$(organizationGuid)
      .map(payload => getOrgPositionsResponse(payload))
      .catchAndReport(err => Observable.of(getOrgPositionsError(err)));
  });

const refetchPositions$ = (action$, state$) =>
  action$
    .ofType(SET_ORGANIZATION)
    .filter(() => isCurrentPageSel(state$.value, ROUTE_PERMISSIONS_MANAGER))
    .mapTo(getOrgPositionsRequest());

const reassignPositionsEpic$ = (action$, state$) =>
  action$.ofType(REASSIGN_POSITIONS_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const organizationGuid = organizationGuidSel(state);

    return forkJoin([
      services.reassignPositions$(payload, organizationGuid),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'PositionManager.RegisteredPositions.reassign.success',
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(reassignPositionsResponse()),
          of(getOrgPositionsRequest()),
        ),
      ),
      catchAndReport(err => Observable.of(reassignPositionsError(err))),
    );
  });

export default combineEpics(
  getUnitsAdults$,
  getUnitsYouths$,
  updatePermission$,
  getAllSubUnitsEpic$,
  assignFunctionalEpic$,
  expireFunctionalEpic$,
  getOrgPositionsEpic$,
  expireAssignFunctionalEpic$,
  refetchPositions$,
  reassignPositionsEpic$,
);
