import { advancementUploadModuleSel as moduleSel } from '../../common';

export const modalVisibleSel = state => moduleSel(state).modalVisible;
export const uploadingSel = state => moduleSel(state).uploading;
export const uploadProgressSel = state => moduleSel(state).uploadProgress;
export const uploadedFilesSel = state => moduleSel(state).uploadedFiles;
export const uploadErrorSel = state => moduleSel(state).uploadError;
export const uploadErrorDescSel = state => moduleSel(state).uploadErrorDesc;
export const errorsModalVisibleSel = state =>
  moduleSel(state).errorsModalVisible;
export const cancellingSel = state => moduleSel(state).cancelling;
