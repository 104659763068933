import { NOT_FOUND } from 'redux-first-router';

import { routes as adultProfileRoutes } from '../adultProfile';
import { routes as advancementRoutes } from '../advancement';
import { routes as editAdvancementRoutes } from '../advancementReport';
import calendarRoutes from '../calendar/routes';
import {
  SHOW_ACTIVITIES_MENU,
  SHOW_COUNSELED_YOUTH,
  SHOW_DASHBOARD,
  SHOW_MB_COUNSELORS,
  SHOW_USER_PERMISSIONS,
} from '../config';
import { routes as councilUnitsRoutes } from '../councilUnits';
import eventsRoutes from '../events/routes';
import featureFlagRoutes from '../featureFlags/routes';
import { routes as feedbackRoutes } from '../feedback';
import PositionsRoutes from '../leadershipPositions/routes';
import { routes as MessagingRoutes } from '../messaging';
import { TestOffline } from '../offline';
import { routes as paymentLogsRoutes } from '../paymentLogs';
import { routes as permissionsManagerRoutes } from '../permissionsManager';
import { routes as personProfileRoutes } from '../personProfile';
import { routes as profileRoutes } from '../profile';
import { routes as reportsRoutes } from '../reports';
import { routes as settingsRoutes } from '../settings';
import { UnderConstruction } from '../shared';
import { routes as userRoutes } from '../user';
import { routes as youthProfileRoutes } from '../youthProfile';
import ErrorPage from './components/ErrorPage';

const dashboardRoutes = SHOW_DASHBOARD ? require('../dashboard').routes : {};
const activitiesRoutes = SHOW_ACTIVITIES_MENU
  ? require('../activities').routes
  : {};
const rolesRoutes = SHOW_USER_PERMISSIONS ? require('../roles').routes : {};
const recharterRoutes = require('../recharter').routes;
const meritBadgeCounselorsRoutes = SHOW_MB_COUNSELORS
  ? require('../meritBadgeCounselors').routes
  : {};
const counseledYouth = SHOW_COUNSELED_YOUTH
  ? require('../counseledYouth/routes')
  : {};

export default {
  ...featureFlagRoutes,
  ...dashboardRoutes,
  ...userRoutes,
  ...profileRoutes,
  ...advancementRoutes,
  ...activitiesRoutes,
  ...eventsRoutes,
  ...reportsRoutes,
  ...calendarRoutes,
  ...rolesRoutes,
  ...recharterRoutes,
  ...settingsRoutes,
  ...editAdvancementRoutes,
  ...feedbackRoutes,
  ...youthProfileRoutes,
  ...adultProfileRoutes,
  ...personProfileRoutes,
  ...councilUnitsRoutes,
  ...meritBadgeCounselorsRoutes,
  ...counseledYouth,
  ...paymentLogsRoutes,
  ...permissionsManagerRoutes,
  ...MessagingRoutes,
  ...PositionsRoutes,
  [NOT_FOUND]: {
    Component: ErrorPage,
    standalone: true,
  },
  // TODO: remove these once their respective modules have been created
  serviceHoursPlaceholder: {
    path: '/service-hours',
    Component: UnderConstruction,
    titleId: 'page.serviceHours',
  },
  upgradePlanPlaceholder: {
    path: '/upgrade-plan',
    Component: UnderConstruction,
    titleId: 'page.upgradePlan',
  },
  offline: {
    path: '/offline',
    Component: TestOffline,
    titleId: 'page.myAccount',
  },
};
