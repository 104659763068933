import React from 'react';
import PropTypes from 'prop-types';

import { TimePicker, Form, intl } from '@shared';
import { getTimePlaceholder, getDefaultDate } from '../../utils';
const FormItem = Form.Item;
const ActivityTimeInput = ({
  allDay,
  fieldName,
  fieldTranslationKey,
  initialValue,
  rules,
  disabled,
  form,
}) => {
  const { getFieldDecorator } = form;

  return (
    <React.Fragment>
      <FormItem
        label={intl.formatMessage({
          id: fieldTranslationKey,
        })}
        standardLayout
      >
        {getFieldDecorator(fieldName, {
          initialValue: getDefaultDate(initialValue),
          rules,
        })(
          <TimePicker
            fluid
            disabled={disabled}
            size="large"
            placeholder={getTimePlaceholder({ allDay, type: fieldName })}
          />,
        )}
      </FormItem>
    </React.Fragment>
  );
};

ActivityTimeInput.propTypes = {
  allDay: PropTypes.bool,
  fieldTranslationKey: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.object,
  rules: PropTypes.array,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
};

export default ActivityTimeInput;
