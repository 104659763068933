import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Hr from '../Hr';
import styles from './ButtonDrawerDivider.less';

const ButtonDrawerDivider = ({ className }) => (
  <Hr className={cn(styles.divider, className)} />
);

ButtonDrawerDivider.propTypes = {
  className: PropTypes.string,
};

export default ButtonDrawerDivider;
