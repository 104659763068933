import moment from 'moment';

import { dtoDateFormat } from '@shared/constants';
import { esbApiService } from '@utils';

const startAdvancement$ = ({
  organizationGuid,
  userId,
  advancementType,
  advancementId,
}) => {
  const body = {
    organizationGuid,
    id: advancementId,
    dateStarted: moment().format(dtoDateFormat),
  };

  const endpoint = `/advancements/youth/${userId}/${advancementType}`;
  const label = `/advancements/youth/{userId}/${advancementType}`;

  return esbApiService.post$(endpoint, body, {
    gtm: {
      label,
    },
  });
};

export default {
  startAdvancement$,
};
