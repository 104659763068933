import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, Checkbox, advancementTypes } from '@shared';
import { advancementTypeFilters } from '../../constants';

class AdvancementTypesFilterSection extends React.PureComponent {
  handleChange = e => {
    const { name } = e.target;
    const { values, onChange } = this.props;
    const newValues = {
      ...values,
      [name]: !values[name],
    };

    onChange(newValues);
  };

  render() {
    const { program, values } = this.props;
    const currentAdvancementTypes = advancementTypeFilters[program];

    return (
      <div>
        {currentAdvancementTypes.includes(advancementTypes.MERIT_BADGES) && (
          <Checkbox
            id="qa_meritBadges"
            checked={values[advancementTypes.MERIT_BADGES]}
            name={advancementTypes.MERIT_BADGES}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage id="advancement.AdvancementHistory.filter.option.meritBadges" />
            </S>
          </Checkbox>
        )}
        {currentAdvancementTypes.includes(advancementTypes.ADVENTURES) && (
          <Checkbox
            id="qa_adventures"
            checked={values[advancementTypes.ADVENTURES]}
            name={advancementTypes.ADVENTURES}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage id="advancement.AdvancementHistory.filter.option.adventures" />
            </S>
          </Checkbox>
        )}
        {currentAdvancementTypes.includes(advancementTypes.RANKS) && (
          <Checkbox
            id="qa_ranks"
            checked={values[advancementTypes.RANKS]}
            name={advancementTypes.RANKS}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage id="advancement.AdvancementHistory.filter.option.ranks" />
            </S>
          </Checkbox>
        )}
        {currentAdvancementTypes.includes(advancementTypes.AWARDS) && (
          <Checkbox
            id="qa_awards"
            checked={values[advancementTypes.AWARDS]}
            name={advancementTypes.AWARDS}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage id="advancement.AdvancementHistory.filter.option.awards" />
            </S>
          </Checkbox>
        )}
      </div>
    );
  }
}

AdvancementTypesFilterSection.propTypes = {
  program: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdvancementTypesFilterSection;
