import React, { useCallback, useEffect, useMemo } from 'react';

import { AddCircle, Info } from '@material-ui/icons';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import {
  useDispatch,
  useSelector, // useSelector,
} from 'react-redux';

import { PendingItem } from '@modules/advancement/pendingItems/types';
import {
  Button,
  OfflineTooltip,
  PaginationTotal,
  T,
  Table,
  Tooltip,
  offlineSel,
} from '@modules/shared';
import { toggleArrayItem } from '@utils';

import columns from '../../pendingItems/components/PendingItems/PendingItemsTable/pendingItemsTableColumns';
import {
  loadingSel,
  selectedRowKeysSel,
  sorterSel,
  withoutPOApprovedItemsSel,
} from '../../pendingItems/duck/selectors';
import { POStatusTypes } from '../constants';
import {
  addToPurchaseOrderRequest,
  closedOrdersSel,
  createPurchaseOrderLoadingSel,
  currentOpenOrderSel,
  currentOrderDetailsSel, // currentOrderIdSel,
  fetchAllPurchaseOrdersRequest,
  fetchPurchaseOrderDetailsRequest,
  setUpdatePurchaseOrderLoading,
  showClosedPOModal,
  updatePurchaseOrderLoadingSel,
} from '../duck';
import { ClosedPOModal } from './ClosedPOModal';
// import { CreatePOButton } from './CreatePOButton';
import { POCard } from './POCard';
import { POCardPlaceholder } from './POCardPlaceholder';
import styles from './ToPurchaseTab.less';

type SelectedItemKey = string | number;

interface ToPurchaseTab {
  handleGoToYouthProfile: (userId: number, activityType: string) => void;
  handleGoToAdultProfile: (userId: number, activityType: string) => void;
  handleSetSorter: (columnKey: string, order?: string) => void;
  handleSetSelectedRowKeys: (items: SelectedItemKey[]) => void;

  rowSelection?: {
    selectedRowKeys: string[];
    onChange: (rowKeys: string) => void;
    onSelectAll: (isSelectAll: boolean) => void;
  };
}

const ToPurchaseTab: React.FC<ToPurchaseTab> = props => {
  const {
    handleGoToYouthProfile: onGoToYouthProfile,
    handleGoToAdultProfile: onGoToAdultProfile,
    handleSetSorter: onSorterChange,
    handleSetSelectedRowKeys: onSetSelectedRowKeys,
  } = props;

  const dispatch = useDispatch();

  const isOffline = useSelector(offlineSel);
  const isLoading = useSelector(loadingSel);
  const updateLoading = useSelector(updatePurchaseOrderLoadingSel);
  const items = useSelector(withoutPOApprovedItemsSel);
  const sorter = useSelector(sorterSel);
  const selectedRowKeys = useSelector(selectedRowKeysSel);
  const areAnyAdvancementsSelected = selectedRowKeys.length > 0;

  const selectedAdvancements = useMemo(
    () => items.filter(({ key }) => selectedRowKeys.includes(key)),
    [items, selectedRowKeys],
  );

  const handleShowClosedPOModal = () => {
    dispatch(showClosedPOModal());
  };

  useEffect(() => {
    dispatch(fetchAllPurchaseOrdersRequest());
  }, [dispatch]);

  const closedOrders = useSelector(closedOrdersSel);
  const openOrder = useSelector(currentOpenOrderSel); // fetch from all POs
  const currentPODetails = useSelector(currentOrderDetailsSel);
  // const coPoid = useSelector(currentOrderIdSel);
  // const poid = !openOrder ? coPoid : openOrder.poid;
  const { poid } = openOrder;
  const createPurchaseOrderLoading = useSelector(createPurchaseOrderLoadingSel);

  const isPOLoading = createPurchaseOrderLoading;

  useEffect(() => {
    if (_.isEmpty(currentPODetails) && poid) {
      dispatch(fetchPurchaseOrderDetailsRequest(poid));
    }
  }, [dispatch, poid, currentPODetails]);

  const handleTableChange = useCallback(
    (_pagination, _filters, sorter) => {
      onSorterChange(sorter);
    },
    [onSorterChange],
  );

  const handleSelectRow = useCallback(
    record => {
      const newSelectedKeys = toggleArrayItem(selectedRowKeys, record.key);
      onSetSelectedRowKeys(newSelectedKeys);
    },
    [onSetSelectedRowKeys, selectedRowKeys],
  );

  const handleSelectAll = useCallback(
    isSelectAll => {
      const selectedItemsKeys = isSelectAll ? items.map(({ key }) => key) : [];
      onSetSelectedRowKeys(selectedItemsKeys);
    },
    [items, onSetSelectedRowKeys],
  );

  const renderPaginationTotal = useCallback(
    total => (
      <div className={styles.pendinTablePaginationContainer}>
        <Tooltip
          title={
            <FormattedMessage id="advancement.PendingItems.totalDisclaimer" />
          }
        >
          <Info className={styles.infoIcon} />
        </Tooltip>
        <PaginationTotal total={total} selected={selectedRowKeys.length} />
      </div>
    ),
    [selectedRowKeys],
  );

  const pagination = {
    showTotal: renderPaginationTotal,
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSetSelectedRowKeys,
    onSelectAll: handleSelectAll,
  };

  const handleAddItemPO = () => {
    // loading
    dispatch(setUpdatePurchaseOrderLoading(true));
    // add item action
    dispatch(
      addToPurchaseOrderRequest({ poid, selectedItems: selectedAdvancements }),
    );
    // clear selection
    dispatch(onSetSelectedRowKeys([]));
  };

  const renderTitle = () => (
    <React.Fragment>
      <OfflineTooltip placement="top">
        <Button
          id="create_purchase_order"
          // className={className}
          disabled={
            isOffline || !areAnyAdvancementsSelected || !currentPODetails
          }
          color="white"
          ghost
          noBorder
          fitText
          icon={<AddCircle />}
          onClick={handleAddItemPO}
          uppercase={false}
        >
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.addItemsButton" />
        </Button>
      </OfflineTooltip>
      {/* <CreatePOButton
        className={styles.createPObtn}
        disabled={isOffline || !areAnyAdvancementsSelected}
        personsAdvancements={selectedAdvancements}
      /> */}
    </React.Fragment>
  );

  return (
    <div className={styles.purchaseOrderPage}>
      <div className={styles.step}>
        <T className={styles.stepTitle} size="4">
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step1" />
        </T>
        <p className={styles.stepDescription}>
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step1Description" />
        </p>
        <div className={styles.itemTableContainer}>
          <Table
            className={styles.table}
            size="small"
            fixedLayout
            dataSource={items}
            loading={isLoading || updateLoading}
            rowKey="key"
            title={renderTitle}
            columns={columns({
              sorter,
              onGoToYouthProfile,
              onGoToAdultProfile,
            })}
            pagination={pagination}
            sorter={sorter}
            rowSelection={rowSelection}
            onRow={(record: PendingItem) => ({
              onClick: () => handleSelectRow(record),
            })}
            rowClassName={() => styles.rowItem}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <div className={styles.step}>
        <T className={styles.stepTitle}>
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step2" />
        </T>
        <p className={styles.stepDescription}>
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step2Description" />
        </p>
        {!_.isEmpty(currentPODetails) && poid ? (
          <POCard
            order={{ ...currentPODetails, poid: poid }}
            isLoading={isPOLoading || updateLoading}
          />
        ) : (
          <POCardPlaceholder
            status={POStatusTypes.OPEN}
            selectedAdvancements={selectedAdvancements}
            createDisabled={!areAnyAdvancementsSelected}
            isLoading={isPOLoading || updateLoading}
          />
        )}
      </div>
      <div className={styles.step}>
        <T className={styles.stepTitle}>
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step3" />
        </T>
        <p className={styles.stepDescription}>
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step3Description" />
        </p>
        <div className={styles.step3InfoContainer}>
          <Info className={styles.infoIcon} />
          <FormattedMessage id="PurchaseOrder.ToPurchaseTab.step3Info" />
        </div>
        <div className={styles.closedButtonContainer}>
          <Button
            onClick={handleShowClosedPOModal}
            uppercase={false}
            size="large"
            shape="round"
            ghost
            className={styles.closedPOButton}
          >
            <FormattedMessage
              id="PurchaseOrder.ToPurchaseTab.closedPOButton"
              values={{ count: closedOrders.length }}
            />
          </Button>
        </div>
        <ClosedPOModal />
      </div>
    </div>
  );
};

export default ToPurchaseTab;
