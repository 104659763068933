import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import { UnitType } from '@context';
import { isCurrentPageSel } from '@location';
import { DevMockHighlight, FlexSpacer, S } from '@shared/components';
import { toastService } from '@toasts';

import { ROUTE_RECHARTER } from '../../duck';
import styles from './RecharterNotification.less';

class RecharterNotification extends React.PureComponent {
  state = {
    userDismissed: false,
  };

  componentDidMount = () => {
    this.updateNotification();
  };

  componentDidUpdate = () => {
    this.updateNotification();
  };

  updateNotification = () => {
    const { isRecharterPage } = this.props;
    const { userDismissed } = this.state;

    if (!userDismissed) {
      if (isRecharterPage) {
        this.handleDismiss();
      } else {
        this.handleDismiss();
        this.toastId = toastService.warn(this.renderNotificationBody(), '', {
          className: styles.container,
          autoClose: false,
          withCloseButton: true,
          onUserDismiss: this.handleUserDismiss,
        });
      }
    }
  };

  renderNotificationBody = () => (
    <div className={styles.notificationBody}>
      <S size="4" bold>
        <FormattedMessage
          id="recharter.RecharterNotification.expirationCountdown"
          values={{
            // this is dummy data
            days: <DevMockHighlight>90</DevMockHighlight>,
            unit: <UnitType />,
          }}
        />
      </S>
      <FlexSpacer />
      <Link
        to="/recharter"
        className={styles.recharterLink}
        onClick={this.handleUserDismiss}
      >
        <FormattedMessage id="recharter.RecharterNotification.startRecharter" />
      </Link>
    </div>
  );

  handleUserDismiss = () => {
    this.handleDismiss();
    this.setState({ userDismissed: true });
  };

  handleDismiss = () => {
    if (this.toastId) {
      toastService.dismiss(this.toastId);
      this.toastId = null;
    }
  };

  render() {
    return '';
  }
}

RecharterNotification.propTypes = {
  isRecharterPage: PropTypes.bool.isRequired,
};

const mapState = state => ({
  isRecharterPage: isCurrentPageSel(state, ROUTE_RECHARTER),
});

export default connect(mapState)(RecharterNotification);
