import React from 'react';
import PropTypes from 'prop-types';

import styles from './DevMockHighlight.less';

const DevMockHighlight = ({ children }) => {
  if (process.env.NODE_ENV === 'production') {
    return children;
  }

  return (
    <span title="this is mocked data" className={styles.highlight}>
      {children}
    </span>
  );
};

DevMockHighlight.propTypes = {
  children: PropTypes.node,
};

const devMockHighlightClassName = styles.highlight;

export { devMockHighlightClassName };

export default DevMockHighlight;
