import React from 'react';

import classnames from 'classnames';
import { toast } from 'react-toastify';

import { DEV_WARNINGS } from '@config/config';

import { Toast, ToastCloseButton } from './components';
import toastStyles from './components/Toast/Toast.less';

const genericToast = (
  content,
  title,
  withCloseButton,
  solid,
  onUserDismiss,
  toastifyOpts,
) => {
  const className = classnames(toastStyles.toast, toastifyOpts.className, {
    solid,
    vCenter: toastifyOpts.vCenter,
  });
  let toastId;
  const handleCloseToast = () => {
    toast.dismiss(toastId);
    if (onUserDismiss) {
      onUserDismiss(toastId);
    }
  };

  const opts = {
    closeButton: withCloseButton ? (
      <ToastCloseButton onClick={handleCloseToast} />
    ) : (
      false
    ),
    ...toastifyOpts,
    className,
  };

  toastId = toast(
    <Toast title={title} type={toastifyOpts.type}>
      {content}
    </Toast>,
    opts,
  );

  return toastId;
};

const success = (
  content,
  title = 'Success',
  {
    withCloseButton = false,
    solid = false,
    onUserDismiss,
    ...toastifyOpts
  } = {},
) =>
  genericToast(content, title, withCloseButton, solid, onUserDismiss, {
    type: toast.TYPE.SUCCESS,
    ...toastifyOpts,
  });

const info = (
  content,
  title = 'Info',
  {
    withCloseButton = false,
    solid = false,
    onUserDismiss,
    ...toastifyOpts
  } = {},
) =>
  genericToast(content, title, withCloseButton, solid, onUserDismiss, {
    type: toast.TYPE.INFO,
    ...toastifyOpts,
  });

const error = (
  content,
  title = 'Error',
  {
    withCloseButton = false,
    solid = false,
    onUserDismiss,
    ...toastifyOpts
  } = {},
) =>
  genericToast(content, title, withCloseButton, solid, onUserDismiss, {
    type: toast.TYPE.ERROR,
    ...toastifyOpts,
  });

const warn = (
  content,
  title = 'Warning',
  {
    withCloseButton = false,
    solid = false,
    onUserDismiss,
    ...toastifyOpts
  } = {},
) =>
  genericToast(content, title, withCloseButton, solid, onUserDismiss, {
    type: toast.TYPE.WARNING,
    ...toastifyOpts,
  });

const devWarn = (content, title) => {
  if (!DEV_WARNINGS) {
    return null;
  }
  // eslint-disable-next-line no-console
  console.log(
    '%cDev Warning',
    'color: red; font-size: 16px; font-weight: bold;',
  );
  // eslint-disable-next-line no-console
  console.log(content);
  return warn(content, title, { withCloseButton: true, autoClose: false });
};

export default {
  success,
  info,
  error,
  warn,
  devWarn,
  /**
   * When `toastId = undefined`, it removes all notifications. Otherwise it dismisses only
   * the one with the given ID.
   *
   * @type {(toastId: number | undefined) => void} See react-toastify dismiss
   */
  dismiss: toast.dismiss,
  isActive: toast.isActive,
};
