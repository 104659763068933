import React from 'react';

import CalendarIcon from '@material-ui/icons/Today';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Calendar, withRange } from 'react-infinite-calendar';

import BaseDatePicker from './BaseDatePicker';
import styles from './DatePicker.less';

const CalendarWithRange = withRange(Calendar);

const formatDate = (date, format) => moment(date).format(format);

class RangePicker extends React.PureComponent {
  state = { value: null };

  handleChange = value => {
    this.setState({ value });
  };

  handleOpenCalendar = () => {
    if (this.props.onOpenChange && !this.props.disabled) {
      this.props.onOpenChange(true);
    }
  };

  handleConfirm = () => {
    this.handleCloseCalendar();
    if (this.props.onChange) {
      const { format } = this.props;
      const { start, end } = this.getValues();
      this.props.onChange(
        [moment(start), moment(end)],
        [formatDate(start, format), formatDate(end, format)],
      );
    }
  };

  handleCloseCalendar = () => {
    if (this.props.onOpenChange) {
      this.props.onOpenChange(false);
    }
  };

  getValues = () => {
    if (this.state.value) {
      return this.state.value;
    }
    const rawDates = this.props.value || this.props.defaultValue;
    if (rawDates) {
      const [start, end] = rawDates.map(date => date && moment(date).toDate());
      return { start, end };
    }
  };

  render() {
    const { disabled, format, ...rest } = this.props;

    const selectedValues = this.getValues();
    const { start, end } = selectedValues;
    return (
      <React.Fragment>
        <span
          className={cn('ant-calendar-picker-input ant-input ant-input-lg', {
            [styles.disabled]: disabled,
          })}
          onClick={this.handleOpenCalendar}
        >
          <input
            disabled={disabled}
            readOnly
            value={(start && formatDate(start, format)) || ''}
            className="ant-calendar-range-picker-input"
            tabIndex={-1}
          />
          <span className={'ant-calendar-range-picker-separator'}> ~ </span>
          <input
            disabled={disabled}
            readOnly
            value={(end && formatDate(end, format)) || ''}
            className="ant-calendar-range-picker-input"
            tabIndex={-1}
          />
          <CalendarIcon
            className={cn('ant-calendar-picker-icon', styles.calendarIcon)}
          />
        </span>
        <BaseDatePicker
          Component={CalendarWithRange}
          onDismiss={this.handleCloseCalendar}
          onConfirm={this.handleConfirm}
          selected={selectedValues}
          onSelect={this.handleChange}
          locale={{
            headerFormat: 'MMM Do',
          }}
          {...rest}
        />
      </React.Fragment>
    );
  }
}

RangePicker.propTypes = {
  ...BaseDatePicker.propTypes,
  onOpenChange: PropTypes.func,
  onChange: PropTypes.func,
  format: PropTypes.string,
  value: PropTypes.array,
  defaultValue: PropTypes.array,
};

export default RangePicker;
