import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isCurrentPageSel } from '@location';
import { Button, ROUTE_OWN_ACTIVITY_LOGS } from '@shared';
import { hasPermissionSel, permissions } from '@user';

import { RootState } from '../../../../../../src/root/store';
import styles from './ActionButtons.less';

type Props = {
  modalMode: string;
  actionButton: string;
  disabled: boolean;
  loading: boolean;
  mainButtonsActive: boolean;
  onSave: () => void;
};

const ActionButtons: React.FC<Props> = ({
  modalMode,
  actionButton,
  disabled,
  loading,
  onSave,
  mainButtonsActive,
}) => {
  const [clickedButton, setClickedButton] = useState<'save' | null>(null);
  const isOwnLogsPage = useSelector((state: RootState) =>
    isCurrentPageSel(state, ROUTE_OWN_ACTIVITY_LOGS),
  );
  const canApprove = useSelector((state: RootState) =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );

  const onSaveClick = () => {
    setClickedButton('save');
    onSave();
  };

  const getButtonFormatted = () => {
    if (actionButton) {
      return `progress.ActionButtons.${actionButton.toLowerCase()}.save`;
    }
    if (!isOwnLogsPage && !canApprove) {
      return 'progress.ActionButtons.complete';
    }
    if (modalMode) {
      return `progress.ActionButtons.${modalMode.toLowerCase()}.save`;
    }
    return 'progress.ActionButtons.create.save';
  };

  return (
    <div className={styles.buttons}>
      {mainButtonsActive && (
        <Button
          type="primary"
          shadow
          color="success"
          size="large"
          className={styles.button}
          disabled={loading || disabled}
          loading={loading && clickedButton === 'save'}
          onClick={onSaveClick}
        >
          <FormattedMessage id={getButtonFormatted()} />
        </Button>
      )}
    </div>
  );
};

export default ActionButtons;
