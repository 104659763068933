import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, Checkbox } from '@shared';
import { visibilityFields } from '../../../../constants';

class VisibilitySection extends React.PureComponent {
  handleChange = e => {
    const { name } = e.target;
    const { values, onChange } = this.props;
    const newValues = {
      ...values,
      [name]: !values[name],
    };

    onChange(newValues);
  };

  render() {
    const { values, subUnitType, isSubUnitAllowed } = this.props;

    return (
      <div>
        <Checkbox
          name={visibilityFields.AGE}
          checked={values[visibilityFields.AGE]}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.VisibilitySection.age" />
          </S>
        </Checkbox>
        <Checkbox
          name={visibilityFields.MEMBER_ID}
          checked={values[visibilityFields.MEMBER_ID]}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.VisibilitySection.memberId" />
          </S>
        </Checkbox>
        <Checkbox
          name={visibilityFields.LAST_RANK_EARNED}
          checked={values[visibilityFields.LAST_RANK_EARNED]}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.VisibilitySection.lastRankEarned" />
          </S>
        </Checkbox>
        {isSubUnitAllowed && (
          <Checkbox
            name={visibilityFields.SUB_UNIT}
            checked={values[visibilityFields.SUB_UNIT]}
            onChange={this.handleChange}
          >
            <S size="4" colored inline>
              <FormattedMessage
                id={`advancement.PackRoster.column.${subUnitType}`}
              />
            </S>
          </Checkbox>
        )}
      </div>
    );
  }
}

VisibilitySection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  subUnitType: PropTypes.string,
  isSubUnitAllowed: PropTypes.bool.isRequired,
};

export default VisibilitySection;
