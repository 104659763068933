import React from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { ENV, SHOW_HEADER_CREATE_BUTTON } from '@config';
import { PageName, RolePicker } from '@context';
import { OfflineIcon, SyncPendingBanner } from '@offline';
import { isMobileSel } from '@screen';
import {
  Avatar,
  FlexSpacer,
  HeadroomAffix,
  S,
  T,
  appName,
  modalOpenSel,
} from '@shared';
import { shortNameSel, userDataInitializedSel } from '@user';

import { Notifications } from '../../../userNotifications';
import {
  headerVisibleSel,
  setHeaderVisible,
  setSidebarOpen,
  sidebarOpenSel,
} from '../../duck';
import CreateProgressDropdown from './CreateProgressDropdown';
import styles from './Header.less';
import Settings from './Settings';

class Header extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { isMobile, headerVisible } = this.props;
    if (!headerVisible && !isMobile && prevProps.isMobile) {
      this.props.onSetHeaderVisible(true);
    }
  }

  handleHeaderPin = () => {
    if (!this.props.headerVisible) {
      this.props.onSetHeaderVisible(true);
    }
  };
  handleHeaderUnpin = () => {
    if (this.props.headerVisible) {
      this.props.onSetHeaderVisible(false);
    }
  };

  handleToggleSidebarOpen = () => {
    const { sidebarOpen, onSetSidebarOpen } = this.props;
    onSetSidebarOpen(!sidebarOpen);
  };

  render() {
    const { loading, headerVisible, sidebarOpen, modalOpen, isMobile } =
      this.props;

    return (
      <HeadroomAffix
        disable={!isMobile || sidebarOpen}
        upTolerance={40}
        className={cn(styles.headerWrapper, { [styles.modalOpen]: modalOpen })}
        clearSidebar
        onPin={this.handleHeaderPin}
        onUnfix={this.handleHeaderPin}
        onUnpin={this.handleHeaderUnpin}
      >
        <div className={cn(styles.header, ENV === 'qa' && styles.headerQA)}>
          <div className={styles.left}>
            <div
              className={cn('Joyride__MenuButton', styles.menuIconContainer)}
              onClick={this.handleToggleSidebarOpen}
            >
              <MenuIcon />
            </div>
            <OfflineIcon className={styles.offlineIconLeft} />
          </div>
          <div className={styles.middle}>
            <T size="2" bold serif className={styles.appName}>
              {appName}
            </T>
            <S size="3" className={styles.pageName}>
              <PageName />
            </S>
            {SHOW_HEADER_CREATE_BUTTON && !loading && (
              <CreateProgressDropdown
                className={styles.createProgressDropdown}
              />
            )}
          </div>
          <FlexSpacer className={styles.spacer} />
          <div className={styles.right}>
            <OfflineIcon className={styles.offlineIconRight} />
            {loading ? (
              <Avatar loading className={styles.userNotificationsPlaceholder} />
            ) : (
              <Notifications
                className={cn(styles.userNotifications, {
                  [styles.hidden]: isMobile && sidebarOpen,
                })}
              />
            )}
            {loading ? (
              <Avatar loading className={styles.rolePickerPlaceholder} />
            ) : (
              <React.Fragment>
                {!isMobile && (
                  <S size="4" className={styles.currentlyViewing}>
                    <FormattedMessage id="core.Header.currentlyViewing" />
                  </S>
                )}
                <RolePicker
                  hidden={(isMobile && sidebarOpen) || !headerVisible}
                />
              </React.Fragment>
            )}
            {!isMobile &&
              (loading ? (
                <Avatar loading className={styles.settingsPlaceholder} />
              ) : (
                <Settings loading={loading} />
              ))}
          </div>
        </div>
        <SyncPendingBanner />
      </HeadroomAffix>
    );
  }
}

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
  shortName: PropTypes.string,
  headerVisible: PropTypes.bool.isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onSetHeaderVisible: PropTypes.func.isRequired,
  onSetSidebarOpen: PropTypes.func.isRequired,
};

const mapState = state => ({
  loading: !userDataInitializedSel(state),
  shortName: shortNameSel(state),
  headerVisible: headerVisibleSel(state),
  sidebarOpen: sidebarOpenSel(state),
  modalOpen: modalOpenSel(state),
  isMobile: isMobileSel(state),
});

const mapDispatch = dispatch => ({
  onSetHeaderVisible: hidden => dispatch(setHeaderVisible(hidden)),
  onSetSidebarOpen: open => dispatch(setSidebarOpen(open)),
});

export default connect(mapState, mapDispatch)(Header);
