import React, { useCallback } from 'react';

import ErrorIcon from '@material-ui/icons/Error';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Modal, Row, S } from '@shared/components';

import {
  closeAdobeErrorModal,
  generateRecharterPDFRequest,
  isAdobeErrorModalVisible,
  isAdobeFlowLoadingSel,
  isGeneratePDFFromPaymentSel,
  openAdobeErrorModal,
  openPaymentModal,
} from '../../../duck';
import styles from './AdobeErrorModal.less';

const AdobeErrorModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isAdobeErrorModalVisible);
  const isAdobeFlowLoading = useSelector(isAdobeFlowLoadingSel);
  const isGeneratePDFFromPayment = useSelector(isGeneratePDFFromPaymentSel);

  const handleCloseModal = useCallback(() => {
    dispatch(closeAdobeErrorModal());
    if (isGeneratePDFFromPayment) {
      dispatch(openPaymentModal());
    }
  }, [dispatch, isGeneratePDFFromPayment]);

  const handleOnContinue = useCallback(() => {
    dispatch(
      generateRecharterPDFRequest({
        onSuccess: () => closeAdobeErrorModal(),
        onFailure: () => openAdobeErrorModal(),
        fromPayment: isGeneratePDFFromPayment,
      }),
    );
  }, [dispatch, isGeneratePDFFromPayment]);

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      onCancel={handleCloseModal}
      onBack={handleCloseModal}
    >
      <React.Fragment>
        <Row type="flex" justify="center">
          <Col>
            <ErrorIcon className={styles.icon} />
          </Col>
          <Col>
            <S size="4" className={styles.text}>
              <FormattedMessage id="recharterModals.adobeErrorModal.message" />
            </S>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              loading={isAdobeFlowLoading}
              className={styles.button}
              color="scouting-warm-gray"
              type="primary"
              shape="round"
              size="default"
              shadow
              block
              uppercase={true}
              onClick={handleOnContinue}
            >
              <FormattedMessage id="recharterModals.adobeErrorModal.resend" />
            </Button>
          </Col>
          <Col className={styles.lastButton}>
            <Button
              loading={isAdobeFlowLoading}
              className={styles.button}
              type="primary"
              shape="round"
              size="default"
              shadow
              block
              uppercase={true}
              onClick={handleCloseModal}
            >
              <FormattedMessage id="recharterModals.adobeErrorModal.continue" />
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    </Modal>
  );
};

export default AdobeErrorModal;
