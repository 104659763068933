import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

import { resetOnLogoutReducer, combineReducersWithState } from '@utils';
import { ROUTE_ADVANCEMENT, SET_SELECTED_ROSTER_KEYS } from '@shared';
import { SET_ORGANIZATION } from '@context';
import { groupReport } from '../helpers';
import {
  ROUTE_EDIT_ADVANCEMENT_REPORT,
  OPEN_ADVANCEMENT_REPORT_MODAL,
  CLOSE_ADVANCEMENT_REPORT_MODAL,
  ADVANCEMENT_REPORT_REQUEST,
  ADVANCEMENT_REPORT_RESPONSE,
  ADVANCEMENT_REPORT_ERROR,
  SET_SELECTED_ADVANCEMENTS,
  SET_SELECTED_YOUTH,
  SET_AWARDED_YOUTH,
  SET_SEARCH,
  SET_SHOW_PRICE,
  SET_NOTES,
  SET_EDITED_REPORT,
  GENERATE_ADVANCEMENT_REPORT_RESPONSE,
  GENERATE_ADVANCEMENT_REPORT_REQUEST,
  GENERATE_ADVANCEMENT_REPORT_ERROR,
  LOAD_ADVANCEMENT_REPORT,
} from './advancementReport.actions';

const advancementReportModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADVANCEMENT_REPORT_MODAL: {
      return true;
    }
    case CLOSE_ADVANCEMENT_REPORT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const poid = (state = null, { type, payload }) => {
  switch (type) {
    case GENERATE_ADVANCEMENT_REPORT_RESPONSE: {
      return payload;
    }
    case ROUTE_ADVANCEMENT: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const userIds = (state = [], { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ROSTER_KEYS: {
      return payload;
    }
    case SET_ORGANIZATION: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const showAllUsers = (state = true, { type, payload }) => {
  switch (type) {
    case OPEN_ADVANCEMENT_REPORT_MODAL: {
      return payload ? payload.showAllUsers : state;
    }
    default: {
      return state;
    }
  }
};

const generatingReport = (state = false, { type }) => {
  switch (type) {
    case GENERATE_ADVANCEMENT_REPORT_REQUEST: {
      return true;
    }
    case GENERATE_ADVANCEMENT_REPORT_RESPONSE:
    case GENERATE_ADVANCEMENT_REPORT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reportInfo = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_ADVANCEMENT_REPORT_MODAL: {
      return payload || state;
    }
    case CLOSE_ADVANCEMENT_REPORT_MODAL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const isEditEnabled = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADVANCEMENT_REPORT_MODAL: {
      return true;
    }
    case ROUTE_EDIT_ADVANCEMENT_REPORT:
    case CLOSE_ADVANCEMENT_REPORT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case ADVANCEMENT_REPORT_REQUEST: {
      return true;
    }
    case ADVANCEMENT_REPORT_RESPONSE:
    case ADVANCEMENT_REPORT_ERROR:
    case CLOSE_ADVANCEMENT_REPORT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const editReportLoading = (state = false, { type }) => {
  switch (type) {
    case ROUTE_EDIT_ADVANCEMENT_REPORT: {
      return true;
    }
    case OPEN_ADVANCEMENT_REPORT_MODAL:
    case CLOSE_ADVANCEMENT_REPORT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const allReport = (state = [], { type, payload }) => {
  switch (type) {
    case ADVANCEMENT_REPORT_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const report = (state = [], { type, payload }) => {
  switch (type) {
    case LOAD_ADVANCEMENT_REPORT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const editedReport = (state = [], { type, payload }) => {
  switch (type) {
    case LOAD_ADVANCEMENT_REPORT:
    case SET_EDITED_REPORT: {
      return payload;
    }
    case CLOSE_ADVANCEMENT_REPORT_MODAL: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showPrice = (state = true, { type, payload }) => {
  switch (type) {
    case SET_SHOW_PRICE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const notes = (state = '', { type, payload }) => {
  switch (type) {
    case SET_NOTES: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedAdvancements = (state = {}, { type, payload }) => {
  switch (type) {
    case LOAD_ADVANCEMENT_REPORT: {
      const groups = groupBy(payload, 'advancementType');
      Object.keys(groups).forEach(type => {
        groups[type] = uniqBy(groups[type], 'advancementId').map(
          ({ advancementId }) => advancementId,
        );
      });

      return groups;
    }
    case SET_SELECTED_ADVANCEMENTS: {
      const { advancementType, keys } = payload;
      return {
        ...state,
        [advancementType]: keys,
      };
    }
    case SET_SELECTED_YOUTH: {
      const { advancementType, advancementId, keys: userIds } = payload;
      const peopleSelected = userIds.length > 0;
      if (peopleSelected) {
        const advancementAlreadySelected =
          state[advancementType].includes(advancementId);
        if (advancementAlreadySelected) {
          return state;
        } else {
          return {
            ...state,
            [advancementType]: [...state[advancementType], advancementId],
          };
        }
      } else {
        return {
          ...state,
          [advancementType]: state[advancementType].filter(
            id => id !== advancementId,
          ),
        };
      }
    }
    default: {
      return state;
    }
  }
};

const selectedYouth = (
  state = {},
  { type, payload },
  { report, selectedAdvancements },
) => {
  switch (type) {
    case LOAD_ADVANCEMENT_REPORT: {
      return payload.reduce(
        (selections, { advancementId, advancementType, youth }) => {
          selections[advancementType] = selections[advancementType] || {};
          selections[advancementType][advancementId] =
            selections[advancementType][advancementId] || [];
          selections[advancementType][advancementId].push(youth.userId);

          return selections;
        },
        {},
      );
    }
    case SET_SELECTED_YOUTH: {
      const { advancementType, advancementId, keys: userIds } = payload;
      return {
        ...state,
        [advancementType]: {
          ...state[advancementType],
          [advancementId]: userIds,
        },
      };
    }
    case SET_SELECTED_ADVANCEMENTS: {
      const { advancementType, keys: advancementIds } = payload;
      const groupedReport = groupReport(report);
      const reportForType = groupedReport[advancementType];
      const prevKeys = selectedAdvancements[advancementType];

      const selections = reportForType.reduce(
        (acc, { id: advancementId, youth }) => {
          const prevSelected = prevKeys.includes(advancementId);
          const nextSelected = advancementIds.includes(advancementId);
          const changed =
            (prevSelected && !nextSelected) || (!prevSelected && nextSelected);
          if (changed) {
            const added = nextSelected;
            acc[advancementId] = added ? youth.map(({ userId }) => userId) : [];
          } else {
            acc[advancementId] = state[advancementType][advancementId];
          }

          return acc;
        },
        {},
      );

      return {
        ...state,
        [advancementType]: selections,
      };
    }
    default: {
      return state;
    }
  }
};

const awardedYouthDefault = {
  meritBadges: {},
  awards: {},
  ranks: {},
  adventures: {},
};

const awardedYouth = (state = awardedYouthDefault, { type, payload }) => {
  switch (type) {
    case SET_AWARDED_YOUTH: {
      const { advancementType, advancementId, keys: userIds } = payload;
      return {
        ...state,
        [advancementType]: {
          ...state[advancementType],
          [advancementId]: userIds,
        },
      };
    }
    case SET_SELECTED_YOUTH: {
      const { advancementType, advancementId, keys: userIds } = payload;
      const awardedUserIds = state[advancementType][advancementId] || [];
      return {
        ...state,
        [advancementType]: {
          ...state[advancementType],
          [advancementId]: awardedUserIds.filter(
            userId => !userIds.includes(userId),
          ),
        },
      };
    }
    case SET_SELECTED_ADVANCEMENTS: {
      const { advancementType, keys: advancementIds } = payload;
      const selectionsForType = Object.keys(state[advancementType]).reduce(
        (acc, advancementId) => {
          acc[advancementId] = advancementIds.includes(+advancementId)
            ? []
            : state[advancementType][advancementId] || [];
          return acc;
        },
        {},
      );

      return {
        ...state,
        [advancementType]: selectionsForType,
      };
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducersWithState({
  advancementReportModalOpen,
  poid,
  showAllUsers,
  userIds,
  generatingReport,
  reportInfo,
  isEditEnabled,
  loading,
  editReportLoading,
  allReport,
  report,
  editedReport,
  search,
  showPrice,
  notes,
  selectedAdvancements,
  selectedYouth,
  awardedYouth,
});

export default resetOnLogoutReducer(reducer);
