import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { T, S } from '@shared';

import styles from './AdvancedTabTitles.less';

const AdvancedTabTitles = ({ type, titles }) => (
  <div className={styles.advancedTitle}>
    <T size="5" bold colored colon>
      <FormattedMessage id={`progress.Form.AdvancedTab.${type}`} />
    </T>
    {titles.map(translation => {
      const [translationKey, isRequired] = Array.isArray(translation)
        ? translation
        : [translation, true];
      return (
        <S key={translationKey} size="5" colored className={styles.titleLabel}>
          {isRequired && '*'}
          <FormattedMessage id={translationKey} />
        </S>
      );
    })}
  </div>
);

AdvancedTabTitles.propTypes = {
  type: PropTypes.string.isRequired,
  titles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ),
};

export default AdvancedTabTitles;
