import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';

import {
  closeSelectYouthModal,
  selectYouthModalOpenSel,
  selectedReportSel,
} from '../../duck';
import SelectYouthModalContent from './SelectYouthModalContent';

const SelectYouthModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectYouthModalOpenSel);
  const selectedReport = useSelector(selectedReportSel);

  const handleCancel = () => dispatch(closeSelectYouthModal());

  return (
    <Modal
      visible={isOpen}
      title={
        <FormattedMessage
          id="reports.BasicReport.reportSelectYouth"
          values={{ reportName: selectedReport.ReportName }}
        />
      }
      onCancel={handleCancel}
      size="small"
    >
      <SelectYouthModalContent />
    </Modal>
  );
};

export default SelectYouthModal;
