import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';

import { dictionaryIds, dictionarySel } from '@dict';
import {
  intl,
  packRosterItemsSel,
  personGuidSel,
  syncOperations,
} from '@shared';
import { toastService } from '@toasts';
import { userIdSel } from '@user';
import { esbApiService, requestStorage } from '@utils';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  APPROVE_ADVANCEMENTS_DEFERRED,
  RECORD_ADVANCEMENT_DEFERRED,
  UNAPPROVE_ADVANCEMENT_DEFERRED,
  UPDATE_ADVANCEMENT_DEFERRED,
} from '../../progress/duck/actions';
import {
  getEligiblePersons,
  getVerifiedPersons,
} from '../../progress/duck/selectors';
import progressServices from '../../progress/duck/services';
import {
  EDIT_ITEM,
  REMOVE_ITEM,
  SYNC_ALL,
  SYNC_COMPLETED,
  SYNC_ITEM,
  TEST_REQUEST,
  UPDATE_REQUESTS,
  VERIFY_PENDING_ADVANCEMENTS_REQUEST,
  setPendingRequests,
  syncCompleted,
  testDeferred,
  testError,
  testResponse,
  verifyPendingAdvancementsError,
  verifyPendingAdvancementsResponse,
} from './actions';
import { pendingRequestsSel, validPendingRequestsSel } from './selectors';

const refreshPendingRequestsEpic$ = (action$, state$) =>
  action$
    .ofType(
      RECORD_ADVANCEMENT_DEFERRED,
      UPDATE_ADVANCEMENT_DEFERRED,
      APPROVE_ADVANCEMENTS_DEFERRED,
      UNAPPROVE_ADVANCEMENT_DEFERRED,
      SYNC_COMPLETED,
      REMOVE_ITEM,
      EDIT_ITEM,
      UPDATE_REQUESTS,
    )
    .switchMap(() => {
      const personGuid = personGuidSel(state$.value);
      return requestStorage.getRequests(personGuid).then(setPendingRequests);
    });

const testRequest$ = () =>
  esbApiService.postOrDefer$('/organizations/organizationsWithinRadius', {
    userLat: '32.892265',
    userLng: '-96.980477',
    radiusInMeters: '5000',
    radiusInMiles: '2',
    organizationType: 'unit',
  });

const testRequestEpic$ = action$ =>
  action$.ofType(TEST_REQUEST).switchMap(() =>
    testRequest$()
      .map(res => {
        if (res === esbApiService.DEFERRED) {
          return testDeferred();
        }
        return testResponse(res);
      })
      .catchAndReport(err => Observable.of(testError(err))),
  );

const getRequestOperation = loggedInUserId => request => {
  const { verification } = request;

  if (verification && !verification.isEligible) {
    return Observable.of(request);
  }

  switch (request.operation) {
    case syncOperations.RECORD_ADVANCEMENT: {
      const { userId } = request;
      return progressServices
        .recordAdvancement$({
          ...request,
          loggedInUserId,
          users: [{ userId }],
        })
        .map(() => ({ ...request, sync: true }))
        .catch(() => Observable.of(request));
    }
    case syncOperations.APPROVE_ADVANCEMENTS: {
      const {
        userId,
        advancementId: id,
        userAwardId,
        advancementType,
        date,
        organizationGuid,
        status,
      } = request;
      const completionDates = { [`${userId}_${id}`]: date };
      const personsAdvancements = [
        { userId, id, userAwardId, advancementType },
      ];
      return progressServices
        .approveAdvancements$({
          loggedInUserId,
          organizationGuid,
          personsAdvancements,
          completionDates,
          status,
        })
        .map(() => ({ ...request, sync: true }))
        .catch(() => Observable.of(request));
    }
    case syncOperations.EDIT_ADVANCEMENT: {
      return progressServices
        .updateAdvancementDate$(request)
        .map(() => ({ ...request, sync: true }))
        .catch(() => Observable.of(request));
    }
    case syncOperations.UNAPPROVE_ADVANCEMENT: {
      const {
        userId,
        awardUserId,
        advancementId,
        advancementType,
        organizationGuid,
      } = request;
      return progressServices
        .unapproveAdvancement$({
          userId,
          awardUserId,
          advancementId,
          advancementType,
          organizationGuid,
        })
        .map(() => ({ ...request, sync: true }))
        .catch(() => Observable.of(request));
    }
    default: {
      throw new Error('Not valid operation.');
    }
  }
};

const verifyPerson = ({
  id,
  advancementVerification,
  persons,
  completionDate,
  unitTypeId,
  advancementType,
}) => {
  const verified = getVerifiedPersons({
    persons,
    advancementVerification,
    completionDate,
    unitTypeId,
  });

  const isEligible = !!getEligiblePersons({
    persons: verified,
    advancementType,
  })[0];

  const {
    userId,
    dateCompleted,
    isPreviousRankEarned,
    isPreviousCoreAwardEarned,
    isAtLeastApproved,
    isInInvalidUnit,
    hasValidAge,
  } = verified[0];

  return {
    id,
    userId,
    dateCompleted,
    isPreviousRankEarned,
    isPreviousCoreAwardEarned,
    isAtLeastApproved,
    isInInvalidUnit,
    hasValidAge,
    isEligible,
  };
};

const verifyAdvancement =
  ({ packRoster, ranks }) =>
  ({
    id,
    userId: youthId,
    advancementId,
    advancementType,
    organizationGuid,
    date: completionDate,
    unitTypeId,
  }) => {
    const person = packRoster.find(({ userId }) => userId == youthId);
    const persons = [person];

    return progressServices
      .verifyUserAdvancement$({
        persons,
        ranks,
        advancementType,
        advancementId,
        unitTypeId,
        organizationGuid,
      })
      .catch(() => Observable.of([]))
      .map(advancementVerification =>
        verifyPerson({
          id,
          advancementVerification,
          persons,
          completionDate,
          unitTypeId,
          advancementType,
        }),
      );
  };

const syncAllPendingRequestsEpic$ = (action$, state$) =>
  action$.ofType(SYNC_ALL).mergeMap(() => {
    const state = state$.value;
    const validPendingRequests = validPendingRequestsSel(state);
    const loggedInUserId = userIdSel(state);

    return Observable.forkJoin(
      validPendingRequests.map(getRequestOperation(loggedInUserId)),
    ).switchMap(requests => {
      const personGuid = personGuidSel(state);

      const pendingRequests = validPendingRequests.filter(
        pendingRequest =>
          !(requests.find(({ id }) => pendingRequest.id == id) || {}).sync,
      );

      const failCount = pendingRequests.length;
      const successCount = validPendingRequests.length - failCount;
      const successMsg = intl.formatMessage(
        {
          id: 'offline.SyncPendingModal.syncSuccess',
        },
        { successCount, failCount },
      );

      toastService.success(successMsg);

      return Observable.fromPromise(
        requestStorage.setRequests(personGuid, pendingRequests),
      ).map(syncCompleted);
    });
  });

const syncPendingRequestEpic$ = (action$, state$) =>
  action$.ofType(SYNC_ITEM).switchMap(({ payload }) => {
    const state = state$.value;

    let pendingRequests = pendingRequestsSel(state);
    const pendingRequest = pendingRequests.find(({ id }) => id == payload);
    const loggedInUserId = userIdSel(state);

    return getRequestOperation(loggedInUserId)(pendingRequest)
      .switchMap(request => {
        if (request.sync) {
          pendingRequests = pendingRequests.filter(
            ({ id }) => id != request.id,
          );
        }
        const personGuid = personGuidSel(state);
        return Observable.fromPromise(
          requestStorage.setRequests(personGuid, pendingRequests),
        );
      })
      .map(syncCompleted);
  });

const verifyPendingItemsEpic$ = (action$, state$) =>
  action$.ofType(VERIFY_PENDING_ADVANCEMENTS_REQUEST).mergeMap(() => {
    const state = state$.value;
    const pendingRequests = pendingRequestsSel(state).filter(
      ({ operation }) => operation === syncOperations.RECORD_ADVANCEMENT,
    );
    const ranks = dictionarySel(state, dictionaryIds.RANKS);
    const packRoster = packRosterItemsSel(state);

    return Observable.forkJoin(
      pendingRequests.map(verifyAdvancement({ packRoster, ranks })),
    )
      .map(verifyPendingAdvancementsResponse)
      .catchAndReport(() => Observable.of(verifyPendingAdvancementsError()));
  });

export const epics = combineEpics(
  testRequestEpic$,
  refreshPendingRequestsEpic$,
  syncAllPendingRequestsEpic$,
  syncPendingRequestEpic$,
  verifyPendingItemsEpic$,
);
