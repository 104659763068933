import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { TableCard } from '@shared';
import mbcRows from './mbcRows';
import styles from './MbcDetailsMobile.less';

const MbcDetailsMobile = ({ mbc, extra }) => {
  const [isMoreInfoOpen, setMoreInfoOpen] = useState(false);

  const handleToggleMoreInfo = useCallback(() => {
    setMoreInfoOpen(!isMoreInfoOpen);
  }, [isMoreInfoOpen]);

  return (
    <TableCard
      className={styles.container}
      item={mbc}
      rows={mbcRows({
        extra,
        isMoreInfoOpen,
        onToggleMoreInfo: handleToggleMoreInfo,
      })}
    />
  );
};

MbcDetailsMobile.propTypes = {
  mbc: PropTypes.object.isRequired,
  extra: PropTypes.node,
};

export default MbcDetailsMobile;
