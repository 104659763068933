import { permissions } from '@shared';
import { ROUTE_COUNCIL_UNITS } from './duck';
import { CouncilUnitsPage } from './components';

const routes = {
  [ROUTE_COUNCIL_UNITS]: {
    path: '/units',
    Component: CouncilUnitsPage,
    titleId: 'page.units',
    permission: permissions.VIEW_COUNCIL_UNITS_PAGE,
  },
};

export default routes;
