import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  EDIT_SUB_UNITS,
  SBL_5198_CUB_BULK_ENTRY,
  SHOW_OFFLINE_FEATURES,
  SHOW_QUICK_ENTRY,
} from '@config';
import { programSel } from '@context/duck/selectorsTyped';
import { AddIndividualDropdown } from '@modules/advancement/common/components/AddIndividualDropdown';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { SyncPendingButton } from '@offline';
import { FilterButton, Program, permissions } from '@shared';
import { hasPermissionSel } from '@shared/duck/selectors';

import { QuickEntryDropdown, RunReportDropdown } from '../../../../common';
import { AddSubUnitButton, GroupSubUnitButton } from '../../../../subUnits';
import PackRosterFilterCard from '../PackRosterFilterCard';
import styles from './PackRosterTable.less';
import RecordProgressDropdown from './RecordProgressDropdown';

interface PackRosterListTopHeaderProps {
  selectedItems: number[];
  selectedRowKeys: number[];
  isOffline: boolean;
}

const PackRosterTableHeader = ({
  selectedItems,
  selectedRowKeys,
  isOffline,
}: PackRosterListTopHeaderProps) => {
  const selectedLength = selectedRowKeys.length;
  const unitProgram = useSelector(programSel);
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );

  return (
    <React.Fragment>
      {unitProgram === Program.CUB_SCOUT &&
        canEditSubUnits &&
        featureFlags.getFlag('SBL_4688_ADD_INDIVIDUAL') && (
          <AddIndividualDropdown disabled={isOffline} />
        )}
      {!!EDIT_SUB_UNITS && canEditSubUnits && (
        <AddSubUnitButton size="small" color="white" ghost noBorder />
      )}
      {(!!SHOW_QUICK_ENTRY || SBL_5198_CUB_BULK_ENTRY) && (
        <QuickEntryDropdown disabled={isOffline} />
      )}
      <RunReportDropdown disabled={isOffline} showAllUsers={false} />
      <RecordProgressDropdown
        disabled={selectedLength === 0 || (!SHOW_OFFLINE_FEATURES && isOffline)}
        selectedItems={selectedItems}
        recordProgressEnabled
      />
      <GroupSubUnitButton />
      {!!SHOW_OFFLINE_FEATURES && (
        <SyncPendingButton className={styles.syncPending} />
      )}
      <PackRosterFilterCard>
        {filterName => (
          <FilterButton className="" rightPadding={false}>
            <span className={styles.filterShow}>
              <FormattedMessage id="advancement.PackRoster.show" />:
            </span>
            <FormattedMessage id={`advancement.PackRoster.${filterName}`} />
          </FilterButton>
        )}
      </PackRosterFilterCard>
    </React.Fragment>
  );
};

PackRosterTableHeader.propTypes = {
  selectedItems: PropTypes.array.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  isOffline: PropTypes.bool.isRequired,
};

export default PackRosterTableHeader;
