import { isEmpty } from 'lodash';

const toNumber = data => (data ? Number(data) : undefined);
const stringToBoolean = data => (data ? data === 'True' : undefined);

export const formatProfileAddress = adressData => ({
  ...adressData,
  addressLine1: adressData.address1,
  addressLine2: adressData.address2,
  addressLine3: adressData.address3,
  zip: adressData.zipCode,
  zipExtension: adressData.zipCodeEx ? adressData.zipCodeEx : '',
  countryId: toNumber(adressData.countryId),
  id: toNumber(adressData.id),
  isPrimary: stringToBoolean(adressData.isPrimary),
  stateId: toNumber(adressData.stateId),
  typeId: toNumber(adressData.typeId),
});

export const formatProfileEmail = email => ({
  ...email,
  id: toNumber(email.id),
  isPrimary: stringToBoolean(email.isPrimary),
  typeId: toNumber(email.typeId),
});

export const formatProfilePhone = phone => {
  if (isEmpty(phone) || !phone.areaCode || !phone.lineNumber || !phone.prefix) {
    return;
  }

  return {
    ...phone,
    phoneCountryId: toNumber(phone.countryId),
    id: toNumber(phone.id) || undefined,
    isPrimary: stringToBoolean(phone.isPrimary) || false,
    typeId: toNumber(phone.typeId) || 3,
    extension: phone.extension || '',
  };
};
