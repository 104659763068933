import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Col,
  PersonAvatar,
  Row, // components
  S,
  T,
  activityTypeForActivityTypeId, // constants
  devMockHighlightClassName,
} from '@shared';

import {
  activityTypesToAdvancedKeys,
  advancedConfig,
  formHookValidationLessOrEqualRule, // utils
  formHookValidationRules, // constants
  personsShape,
} from '../../../../../common';
import { selectedActivityDataSel, youthsAndAdultsSel } from '../../../../duck';
import ActivityValueInput from '../ActivityValueInput';
import styles from './AdvancedTab.less';

// eslint-disable-next-line react/display-name
const AdvancedTab = React.memo(
  ({ isActive, showDescription, activityTypeId, diffDays }) => {
    const [youthItems, setYouthItems] = useState(null);
    const [adultItems, setAdultItems] = useState(null);
    const { youths, adults } = useSelector(youthsAndAdultsSel);
    const selectedActivityData = useSelector(selectedActivityDataSel);
    const { getValues } = useFormContext();
    const columnSettings = advancedConfig[activityTypeId];
    const basicActivityKeys = activityTypesToAdvancedKeys[activityTypeId];
    const activityType = activityTypeForActivityTypeId[activityTypeId];

    const renderPersons = useCallback(
      (persons, type) => {
        const formValues = getValues();
        const advancedActivityKeys = {};
        const selectedActivitValues = {};
        const basicValues = {};
        const inputRules = {};

        columnSettings.forEach(columnSetting => {
          const [baseKey, isRequired, validateFloat, isDayDate] = columnSetting;
          inputRules[baseKey] = formHookValidationRules(
            isActive,
            validateFloat,
            isRequired,
          );
          if (isDayDate !== undefined && isActive) {
            const addExtraDay = isDayDate ? 1 : 0;
            inputRules[baseKey] = merge(
              inputRules[baseKey],
              formHookValidationLessOrEqualRule(diffDays + addExtraDay, {
                isDayDate,
              }),
            );
          }
          const selectedActivityKey = (advancedActivityKeys[
            baseKey
          ] = `${type}${baseKey}`);
          const basicValueKey = basicActivityKeys[selectedActivityKey];
          selectedActivitValues[baseKey] =
            selectedActivityData[selectedActivityKey] || {};
          basicValues[baseKey] = formValues[basicValueKey];
        });
        return persons.map(({ userId, personShortFullName, isAdult }) => (
          <Row key={userId} type="flex" className={styles.personItem}>
            <Col span={24}>
              <div className={styles.avatarAndName}>
                <PersonAvatar
                  className={cn(styles.personAvatar, devMockHighlightClassName)}
                  isAdult={isAdult}
                  src={null}
                />
                <span>{personShortFullName}</span>
              </div>
            </Col>
            {columnSettings.map(([baseKey, isRequired]) => {
              const existingActivityValues = selectedActivitValues[baseKey];
              return (
                <Col xs={24} lg={8} key={`${userId}${baseKey}`}>
                  <ActivityValueInput
                    valueName={
                      <React.Fragment>
                        <FormattedMessage
                          id={`progress.activity.ActivityTabs.inputTitle.${baseKey}`}
                        />
                        {isRequired && '*'}
                      </React.Fragment>
                    }
                    name={`${advancedActivityKeys[baseKey]}.u${userId}`}
                    defaultValue={
                      existingActivityValues[`u${userId}`] ||
                      basicValues[baseKey]
                    }
                    rules={inputRules[baseKey]}
                    isActive={isActive}
                  />
                </Col>
              );
            })}
          </Row>
        ));
      },
      [
        getValues,
        isActive,
        diffDays,
        columnSettings,
        selectedActivityData,
        basicActivityKeys,
      ],
    );

    useEffect(() => {
      setYouthItems(renderPersons(youths, 'youth'));
      setAdultItems(renderPersons(adults, 'adult'));
    }, [youths, adults, renderPersons, diffDays, isActive]);

    return (
      <React.Fragment>
        {showDescription && (
          <S size="6">
            <FormattedMessage
              id={`progress.common.ActivityTabs.${activityType}.advancedDescription`}
              values={{
                tabName: (
                  <span className={styles.descriptionTabName}>
                    <FormattedMessage id="progress.activity.ActivityTabs.advanced" />
                  </span>
                ),
              }}
            />
          </S>
        )}
        {youths.length > 0 && (
          <T size="5" bold colored colon>
            <FormattedMessage id="progress.activity.ActivityTabs.youth" />
          </T>
        )}
        {youthItems}
        {adults.length > 0 && (
          <T size="5" bold colored colon>
            <FormattedMessage id="progress.activity.ActivityTabs.adult" />
          </T>
        )}
        {adultItems}
      </React.Fragment>
    );
  },
);

AdvancedTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  youth: personsShape,
  adults: personsShape,
  showDescription: PropTypes.bool,
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  diffDays: PropTypes.number,
};

export default AdvancedTab;
