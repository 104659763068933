import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, intl } from '@shared';
import { openEditAdvancement } from '../../duck';

const EditAdvancementButton = ({
  disabled,
  className,
  children,
  uppercase,
  onOpenEditAdvancement,
  onClick,
}) => {
  const handleClick = useCallback(
    e => {
      onOpenEditAdvancement();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, onOpenEditAdvancement],
  );

  return (
    <Button
      className={className}
      disabled={disabled}
      color="info"
      ghost
      noBorder
      fitText
      uppercase={uppercase}
      onClick={handleClick}
    >
      {children || intl.formatMessage({ id: 'shared.edit' })}
    </Button>
  );
};

EditAdvancementButton.propTypes = {
  // provided by component itself
  onOpenEditAdvancement: PropTypes.func.isRequired,
  // provided by parent
  children: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  advancement: PropTypes.object,
  person: PropTypes.object,
  onClick: PropTypes.func,
  uppercase: PropTypes.bool,
};

EditAdvancementButton.defaultProps = {
  uppercase: false,
};

const mapDispatch = (dispatch, { advancement, person }) => ({
  onOpenEditAdvancement: () =>
    dispatch(openEditAdvancement(person, advancement)),
});

export default connect(null, mapDispatch)(EditAdvancementButton);
