import { Observable } from 'rxjs';
import { ENABLE_GOOGLE_SIGN_IN, GOOGLE_SIGN_IN_CLIENT_ID } from '@config';

const GOOGLE_SIGN_IN_BUTTON_ID = 'ia-google-sign-in';
const GOOGLE_AUTH_SDK_URL = 'https://accounts.google.com/gsi/client';

class GoogleSignIn {
  client = undefined;
  token = '';
  onSuccess() {
    throw new Error('onSuccess must be set');
  }
  onError() {
    throw new Error('onError must be set');
  }

  setSuccessCallback(callback) {
    this.onSuccess = callback;
  }
  setErrorCallback(callback) {
    this.onError = callback;
  }
  setToken(token) {
    this.token = token;
  }

  loadSDK$ = () =>
    Observable.create(observer => {
      if (ENABLE_GOOGLE_SIGN_IN) {
        const script = document.createElement('script');
        script.src = GOOGLE_AUTH_SDK_URL;
        document.body.appendChild(script);
        script.onload = () => {
          try {
            if (window.google) {
              this.client = window.google.accounts.id.initialize({
                client_id: GOOGLE_SIGN_IN_CLIENT_ID,
                scope: 'openid profile email',
                callback: res => this.onSuccess(res),
                error_callback: err => this.onError(err),
              });
            }
          } catch (error) {
            this.onError(error);
          } finally {
            observer.next({});
          }
        };
        script.onerror = () => observer.next({});
      } else {
        return observer.next({});
      }
    });

  init = (onsuccess, onfailure) => {
    this.setErrorCallback(error => {
      // eslint-disable-next-line no-console
      console.error('error Google Sign In', error);
      onfailure(error);
    });
    this.setSuccessCallback(({ credential }) => {
      this.setToken(credential);
      onsuccess(credential);
    });

    if (window.google) {
      window.google.accounts.id.renderButton(
        document.getElementById(GOOGLE_SIGN_IN_BUTTON_ID),
        {
          type: 'standard',
          width: 200,
          text: 'sign_in',
          theme: 'outline',
        },
      );
    }
  };

  signOut = () => {};
}

export default new GoogleSignIn();
