import { combineReducers } from 'redux';

import { locationHash } from '@modules/shared';
import { resetOnLogoutReducer } from '@utils';

import { tabNames } from '../constants';
import {
  LOAD_DISTRICTS_ERROR,
  LOAD_DISTRICTS_REQUEST,
  LOAD_DISTRICTS_RESPONSE,
  SET_ACTIVE_TAB,
  SET_RECENT_UNITS,
  SET_RECENT_UNITS_SEARCH,
  SET_RECENT_UNITS_SORTER,
  SET_SEARCH,
  SET_SORTER,
  SET_UNIT,
  UNITS_SEARCH_ERROR,
  UNITS_SEARCH_REQUEST,
  UNITS_SEARCH_RESPONSE,
} from './actions';

const searchLoading = (state = false, { type }) => {
  switch (type) {
    case UNITS_SEARCH_REQUEST: {
      return true;
    }
    case UNITS_SEARCH_RESPONSE:
    case UNITS_SEARCH_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const units = (state = [], { type, payload }) => {
  switch (type) {
    case UNITS_SEARCH_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedUnit = (state = null, { type, payload }) => {
  switch (type) {
    case SET_UNIT: {
      return payload;
    }
    case UNITS_SEARCH_RESPONSE: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const recentUnitsSearch = (state = '', { type, payload }) => {
  switch (type) {
    case SET_RECENT_UNITS_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const sorter = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SORTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const recentUnitsSorter = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_RECENT_UNITS_SORTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const districtsLoading = (state = false, { type }) => {
  switch (type) {
    case LOAD_DISTRICTS_REQUEST: {
      return true;
    }
    case LOAD_DISTRICTS_ERROR:
    case LOAD_DISTRICTS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const districts = (state = [], { type, payload }) => {
  switch (type) {
    case LOAD_DISTRICTS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const recentUnits = (state = [], { type, payload }) => {
  switch (type) {
    case SET_RECENT_UNITS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const validTabs = [tabNames.UNITS, tabNames.RECENT_UNITS];
const defaultTab = locationHash.getValid(validTabs, tabNames.UNITS);
const activeTab = (state = defaultTab, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_TAB: {
      return payload;
    }
    case UNITS_SEARCH_REQUEST: {
      return tabNames.UNITS;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  searchLoading,
  units,
  selectedUnit,
  search,
  recentUnitsSearch,
  sorter,
  recentUnitsSorter,
  districtsLoading,
  districts,
  recentUnits,
  activeTab,
});

export default resetOnLogoutReducer(reducer);
