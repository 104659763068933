import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { openEagleApplicationForm } from '@utils';
import { Button, Modal, appName } from '@shared';
import {
  youthNameSel,
  userIdSel,
  visibleSel,
  closeEagleScoutApplication,
} from '../duck';
import styles from './EagleScoutApplicationModal.less';

function EagleScoutApplicationModal({ userId, youthName, visible, onClose }) {
  return (
    <Modal
      title={
        <FormattedMessage id="advancementHistory.EagleScoutApplicationModal.title" />
      }
      fullscreenMobile={false}
      headerColor="scouting-warm-gray"
      onCancel={onClose}
      visible={visible}
    >
      <div className={styles.message}>
        <FormattedMessage
          id="advancementHistory.EagleScoutApplicationModal.description"
          values={{ appName }}
        />
      </div>
      <Button
        className={styles.applicationButton}
        color="info"
        fitText
        uppercase={false}
        onClick={() => openEagleApplicationForm(userId)}
      >
        <FormattedMessage
          id="advancementHistory.EagleScoutApplicationModal.button"
          values={{ youthName }}
        />
      </Button>
    </Modal>
  );
}

EagleScoutApplicationModal.propTypes = {
  userId: PropTypes.number,
  youthName: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapState = state => ({
  userId: userIdSel(state),
  youthName: youthNameSel(state),
  visible: visibleSel(state),
});

const mapDispatch = dispatch => ({
  onClose: () => dispatch(closeEagleScoutApplication()),
});

export default connect(mapState, mapDispatch)(EagleScoutApplicationModal);
