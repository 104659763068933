import { DateTime } from 'luxon';
import moment, { type Moment } from 'moment';

/** Luxon date format e.g. 2024-02-15 */
const ESB_API_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Formats to an ESB Date format.
 *
 * *__Note:__ no time will be included*
 *
 * @param date  It can be a string, Date or a Moment value
 * @returns
 */
export function formatToAPIDate(
  date: Date | DateTime | string | Moment,
): string {
  let _date: DateTime;
  if (date instanceof Date) {
    _date = DateTime.fromJSDate(date);
  } else if (date instanceof DateTime) {
    _date = date;
  } else if (typeof date === 'string') {
    _date = DateTime.fromISO(date);
  } else if (moment.isMoment(date)) {
    _date = DateTime.fromJSDate(date.toDate());
  } else {
    throw new Error(`Invalid date provided ${date}`);
  }

  return _date.toFormat(ESB_API_DATE_FORMAT);
}

/**
 * Formats a date using the current locale "short" format.
 * Example for US: `"2024-10-14" => 10/14/2024``
 */
export function formatToUIDate(
  date: Date | DateTime | string | Moment,
): string {
  let _date: DateTime;
  if (date instanceof Date) {
    _date = DateTime.fromJSDate(date);
  } else if (date instanceof DateTime) {
    _date = date;
  } else if (typeof date === 'string') {
    _date = DateTime.fromISO(date);
  } else if (moment.isMoment(date)) {
    _date = DateTime.fromJSDate(date.toDate());
  } else {
    throw new Error(`Invalid date provided ${date}`);
  }

  return _date.toLocaleString(DateTime.DATE_SHORT);
}
