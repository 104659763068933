import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  SearchInput,
  sorterPropTypes,
  intl,
  offlineSel,
  pendingApprovableAdvancementsSel,
  goToAdultProfile,
  goToYouthProfile,
} from '@shared';
import { isMobileSel } from '@screen';
import { toggleArrayItem, fileFromString } from '@utils';

import { cancelFileRequest, cancellingSel } from '../../../advancementUpload';
import { activityItemsTypes } from '../../../common';
import {
  loadingSel,
  expandedKeysSel,
  setExpandedKeys,
  advancementHistoryRequestIfNotLoaded,
  searchFilterSel,
  setSearchFilter,
  updatedDateFilterSel,
  sorterSel,
  setSorter,
  setSelectedRowKeys,
  selectedKeysSel,
  filteredItemsSectionsSel,
} from '../../duck';
import AdvancementHistoryTable from './AdvancementHistoryTable';
import AdvancementHistoryList from './AdvancementHistoryList';

class AdvancementHistory extends React.PureComponent {
  state = {
    searchPinned: false,
  };

  componentDidMount() {
    this.props.onAdvancementHistoryRequest();
  }

  handleSearch = search => {
    this.props.onSetSearch(search);
  };

  handleSorterChange = sorter => {
    this.props.onSetSorter(sorter);
  };

  handleExpandedKeyChange = key => {
    const { expandedKeys } = this.props;
    const newKeys = toggleArrayItem(expandedKeys, key);

    this.props.onSetExpandedKeys(newKeys);
  };

  handleSearchPinChange = searchPinned => this.setState({ searchPinned });

  handleCancelFile = id => {
    const { items } = this.props;
    const { name } = items[activityItemsTypes.HISTORY].find(
      item => item.id === id,
    );
    const successMsg = (
      <FormattedMessage
        id="advancement.AdvancementHistory.cancelFileSuccessMsg"
        values={{ name }}
      />
    );
    this.props.onCancelFile({ id, successMsg });
  };

  handleDownloadFileErrorLog = ({ errors, date }) => {
    if (errors) {
      fileFromString(errors, `import-${date}-log.txt`);
    }
  };

  renderSearch() {
    const { search } = this.props;
    return (
      <SearchInput
        key="search"
        id="qa_inputSearchActivity"
        size="large"
        mobileSticky
        placeholder={this.getSearchPlaceholder()}
        value={search}
        onChange={this.handleSearch}
        onPinChange={this.handleSearchPinChange}
      />
    );
  }

  getSearchPlaceholder = () => {
    const { isMobile, updatedDateFilter } = this.props;
    const messageId = isMobile
      ? 'advancement.AdvancementHistory.search.mobile'
      : `advancement.AdvancementHistory.search.${updatedDateFilter}`;
    return intl.formatMessage({
      id: messageId,
    });
  };

  handleGoToYouthProfile =
    ({ userId }) =>
    event => {
      if (!this.props.isOffline && userId) {
        event.stopPropagation();
        this.props.onGoToYouthProfile(userId);
      }
    };

  handleGoToAdultProfile =
    ({ userId }) =>
    event => {
      if (!this.props.isOffline && userId) {
        event.stopPropagation();
        this.props.onGoToAdultProfile(userId);
      }
    };

  renderItems() {
    const {
      loading,
      items,
      expandedKeys,
      sorter,
      isMobile,
      cancellingFiles,
      pendingApprovableItems,
      selectedRowKeys,
      onSetSelectedRowsKeys,
      isOffline,
    } = this.props;

    const { searchPinned } = this.state;

    return isMobile ? (
      <AdvancementHistoryList
        key="list"
        items={items}
        pendingApprovableItems={pendingApprovableItems}
        loading={loading}
        expandedKeys={expandedKeys}
        searchPinned={searchPinned}
        sorter={sorter}
        isOffline={isOffline}
        cancellingFiles={cancellingFiles}
        selectedRowKeys={selectedRowKeys}
        onSetSelectedRowsKeys={onSetSelectedRowsKeys}
        onSorterChange={this.handleSorterChange}
        onExpandedKeyChange={this.handleExpandedKeyChange}
        onCancelFile={this.handleCancelFile}
        onGoToYouthProfile={this.handleGoToYouthProfile}
        onGoToAdultProfile={this.handleGoToAdultProfile}
        onDownloadFileErrorLog={this.handleDownloadFileErrorLog}
      />
    ) : (
      <AdvancementHistoryTable
        key="table"
        items={items}
        pendingApprovableItems={pendingApprovableItems}
        selectedRowKeys={selectedRowKeys}
        onSetSelectedRowsKeys={onSetSelectedRowsKeys}
        loading={loading}
        isOffline={isOffline}
        expandedKeys={expandedKeys}
        sorter={sorter}
        cancellingFiles={cancellingFiles}
        onSorterChange={this.handleSorterChange}
        onExpandedKeyChange={this.handleExpandedKeyChange}
        onCancelFile={this.handleCancelFile}
        onGoToYouthProfile={this.handleGoToYouthProfile}
        onGoToAdultProfile={this.handleGoToAdultProfile}
        onDownloadFileErrorLog={this.handleDownloadFileErrorLog}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderSearch()}
        {this.renderItems()}
      </React.Fragment>
    );
  }
}

AdvancementHistory.propTypes = {
  items: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  expandedKeys: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  search: PropTypes.string,
  updatedDateFilter: PropTypes.string.isRequired,
  sorter: sorterPropTypes,
  cancellingFiles: PropTypes.object.isRequired,
  pendingApprovableItems: PropTypes.array.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onSetSorter: PropTypes.func.isRequired,
  onCancelFile: PropTypes.func.isRequired,
  onSetSearch: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  onGoToAdultProfile: PropTypes.func.isRequired,
  onSetExpandedKeys: PropTypes.func.isRequired,
  onSetSelectedRowsKeys: PropTypes.func.isRequired,
  onAdvancementHistoryRequest: PropTypes.func.isRequired,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
  loading: loadingSel(state),
  expandedKeys: expandedKeysSel(state),
  search: searchFilterSel(state),
  updatedDateFilter: updatedDateFilterSel(state),
  sorter: sorterSel(state),
  cancellingFiles: cancellingSel(state),
  pendingApprovableItems: pendingApprovableAdvancementsSel(state),
  selectedRowKeys: selectedKeysSel(state),
  items: filteredItemsSectionsSel(state),
  isOffline: offlineSel(state),
});

const mapDispatch = dispatch => ({
  onAdvancementHistoryRequest: () =>
    dispatch(advancementHistoryRequestIfNotLoaded()),
  onSetExpandedKeys: keys => dispatch(setExpandedKeys(keys)),
  onSetSearch: search => dispatch(setSearchFilter(search)),
  onSetSorter: sorter => dispatch(setSorter(sorter)),
  onCancelFile: params => dispatch(cancelFileRequest(params)),
  onSetSelectedRowsKeys: ids => dispatch(setSelectedRowKeys(ids)),
  onGoToYouthProfile: userId => dispatch(goToYouthProfile(userId)),
  onGoToAdultProfile: userId => dispatch(goToAdultProfile(userId)),
});

export default connect(mapState, mapDispatch)(AdvancementHistory);
