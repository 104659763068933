/* eslint-disable react/no-multi-comp */
import React, { useMemo } from 'react';

import { isEmpty, keyBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type {
  GroupedPendingItem,
  GroupedPendingItemChild,
  PendingItem,
} from '@modules/advancement/pendingItems/types';
import {
  advancementStatusDate,
  goToYouthAdvRequirements,
} from '@modules/shared';
import { getPreferredName } from '@modules/shared/utils/preferredName';
import DateView from '@shared/components/DateView';
import PersonAvatar from '@shared/components/PersonAvatar';
import S from '@shared/components/S';
import { advancementTypes } from '@shared/constants';
import {
  adventuresSel,
  allAwardsSel,
  meritBadgesSel,
  ranksSel,
} from '@shared/duck/selectors/general.selectors';

import styles from './PendingItemsTable.less';

function isRowGroup(row: { children: Array<unknown> }) {
  return row?.children && !isEmpty(row?.children);
}

export function RequirementAdvancementType(props: { row: PendingItem }) {
  const { row } = props;
  const dispatch = useDispatch();

  const mbDict = useSelector(meritBadgesSel);
  const awardsDict = useSelector(allAwardsSel);
  const ranksDict = useSelector(ranksSel);
  const adventuresDict = useSelector(adventuresSel);
  const mbsById = useMemo(() => keyBy(mbDict, 'id'), [mbDict]);
  const ranksById = useMemo(() => keyBy(ranksDict, 'id'), [ranksDict]);
  const awardsById = useMemo(() => keyBy(awardsDict, 'id'), [awardsDict]);
  const adventuresById = useMemo(
    () => keyBy(adventuresDict, 'id'),
    [adventuresDict],
  );

  if (!row) return null;

  let imgSrc = '';
  let link = '';
  const userId = row.userId;
  const advancementId = row.advancementId;
  const advancementName = row?.advancementName || '';
  let onClick: () => void;
  switch (row.advancementType) {
    case advancementTypes.MERIT_BADGES: {
      imgSrc = mbsById[row.advancementId]?.imageUrl100;
      const mbId = row.advancementId;
      link = `/youthProfile/${userId}/meritBadges/${mbId}`;
      onClick = () => {
        dispatch(
          goToYouthAdvRequirements({
            userId,
            advancementType: advancementTypes.MERIT_BADGES,
            advancementId,
            userAwardId: undefined,
          }),
        );
      };
      break;
    }
    case advancementTypes.RANKS: {
      imgSrc = ranksById[row.advancementId]?.imageUrl100;
      const awardId = row.advancementId;
      onClick = () => {
        dispatch(
          goToYouthAdvRequirements({
            userId,
            advancementType: advancementTypes.RANKS,
            advancementId: awardId,
            userAwardId: undefined,
          }),
        );
      };
      break;
    }
    case advancementTypes.ADVENTURES: {
      imgSrc = adventuresById[advancementId]?.imageUrl100;
      onClick = () => {
        dispatch(
          goToYouthAdvRequirements({
            userId,
            advancementType: advancementTypes.ADVENTURES,
            advancementId,
            userAwardId: undefined,
          }),
        );
      };
      break;
    }
    case advancementTypes.AWARDS: {
      imgSrc = awardsById[row.advancementId]?.imageUrl100;
      const awardId = row.advancementId;
      onClick = () => {
        dispatch(
          goToYouthAdvRequirements({
            userId,
            advancementType: advancementTypes.AWARDS,
            advancementId: awardId,
            userAwardId: row.userAwardId,
          }),
        );
      };
      break;
    }
  }
  return (
    <div
      className={styles.advancementTypeName}
      data-advancementid={row?.advancementId}
      data-requirementid={row?.id}
    >
      {imgSrc ? (
        <img src={imgSrc} alt={advancementName} />
      ) : (
        <div className={styles.noImagePlaceholder} />
      )}
      <a
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
        rel="noreferrer"
        target="_blank"
        href={link}
        title={advancementName}
      >
        {advancementName}
      </a>
    </div>
  );
}

export const groupedColumns = ({
  onGoToYouthProfile,
  onGoToAdultProfile,
}: {
  onGoToYouthProfile: (
    userId: number,
    activityTypeId: number | string,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  onGoToAdultProfile: (
    userId: number,
    activityTypeId: number | string,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => [
  {
    key: 'name',
    width: '220px',
    title: <FormattedMessage id="advancement.PendingItems.column.name" />,
    dataIndex: 'name',
    render(_value: string, row: GroupedPendingItem) {
      const isGrouped = isRowGroup(row);

      const target = isGrouped ? row?.children[0] : row;

      const {
        userId,
        isAdult,
        profilePictureUrl,
        activityTypeId,
        firstName,
        lastName,
        nickName,
      } = target as GroupedPendingItemChild;

      const personPreferredName = getPreferredName({
        firstName,
        lastName,
        nickName: nickName as string,
      });

      let onClickName = undefined;
      if (isAdult) {
        onClickName = onGoToAdultProfile(userId, activityTypeId);
      } else {
        onClickName = onGoToYouthProfile(userId, activityTypeId);
      }

      return (
        <div className={styles.nameContainer}>
          <PersonAvatar isAdult={isAdult} src={profilePictureUrl} />
          <S
            size="4"
            bold
            inline
            colored
            className={styles.youthName}
            onClick={onClickName}
          >
            {personPreferredName}
          </S>
        </div>
      );
    },
  },
  {
    key: 'advancementType',
    dataIndex: 'advancementType',
    width: '160px',
    render(_type: string, row: GroupedPendingItem | PendingItem) {
      if (isRowGroup(row as GroupedPendingItem)) return null;

      return <RequirementAdvancementType row={row as PendingItem} />;
    },
  },
  {
    title: <FormattedMessage id="advancement.PendingItems.column.memberId" />,
    key: 'memberId',
    dataIndex: 'memberId',
    width: '15ch',
  },
  {
    key: 'description',
    dataIndex: 'description',
    width: '240px',
    render(val: string) {
      const div = document.createElement('p');
      div.innerHTML = val;
      const clean = div.textContent || div.innerText || '';

      const MAX_CHARS = 30;
      if (clean.length < MAX_CHARS) {
        return clean;
      }

      return clean.substring(0, MAX_CHARS).trimEnd() + '...';
    },
    title: (
      <FormattedMessage id="advancement.PendingItems.column.itemDescription" />
    ),
  },
  {
    key: 'completionDate',
    dataIndex: 'date',
    render(value: string, record: PendingItem) {
      return (
        <DateView value={value || advancementStatusDate.getCompleted(record)} />
      );
    },
    title: <FormattedMessage id="advancement.PendingItems.column.itemDate" />,
  },
  {
    key: 'totalAmount',
    dataIndex: 'totalAmount',
    width: '100px',
    title: (
      <FormattedMessage id="advancement.PendingItems.column.totalAmount" />
    ),
  },
];
