import React from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Avatar, Button, Card, FlexSpacer, S, T } from '@shared/components';
import { logout } from '@shared/duck';

import FleurDeLisIcon from '../../../../assets/images/FleurDeLis.svg';
import styles from './SandBoxPage.less';

const SandBoxPage = () => {
  const dispatch = useDispatch();

  const handleAcknowledgeClick = () => {
    window.location.replace('/');
  };

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  return (
    <div className={styles.container}>
      <div className={styles.bg} />
      <Card className={styles.card} shadow>
        <Avatar
          shadow
          className={styles.logoAvatar}
          placeholder={<FleurDeLisIcon className={styles.logoIcon} />}
        />
        <div className={styles.header}>
          <T size="3" className={styles.title}>
            <FormattedMessage id="sandBox.title" />
          </T>
          <S size="3" className={cn(styles.ieFix, styles.subtitle)}>
            <FormattedMessage id="sandBox.message" />
          </S>
        </div>
        <FlexSpacer />
        <div className={styles.options}>
          <div className={styles.option}>
            <Button
              color="scouting-blue"
              uppercase={false}
              className={styles.button}
              onClick={handleAcknowledgeClick}
            >
              <FormattedMessage id="sandBox.acknowledge" />
            </Button>
          </div>
          <div className={styles.option}>
            <Button
              type="primary"
              color="scouting-blue"
              uppercase={false}
              className={styles.button}
              onClick={handleLogoutClick}
            >
              <FormattedMessage id="sandBox.logOut" />
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SandBoxPage;
