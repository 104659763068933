import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Button, Modal, T } from '@shared';

import {
  closeErrorsModal,
  errorsModalVisibleSel,
  uploadErrorDescSel,
} from '../../../duck';
import styles from './ErrorsModal.less';

class ErrorsModal extends React.PureComponent {
  handleCopy = () => {
    const textField = document.createElement('textarea');
    textField.value = this.props.errorsDesc.join('\n');
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  renderErrors = () =>
    this.props.errorsDesc.map((error, i) => <span key={i}>{error}</span>);

  setErrorsAreaRef = element => (this.errorsAreaRef = element);

  render() {
    const { onClose, visible, errorsDesc } = this.props;
    return (
      <Modal
        className={styles.modal}
        visible={visible}
        width="100%"
        onCancel={onClose}
      >
        <T size="4">
          <FormattedMessage
            id="advancementUpload.ErrorsModal.title"
            values={{ count: errorsDesc.length }}
          />
        </T>
        <div className={styles.plainText}>{this.renderErrors()}</div>
        <div className={styles.buttonWrapper}>
          <Button onClick={this.handleCopy}>
            <FormattedMessage id="advancementUpload.ErrorsModal.copyButton" />
          </Button>
        </div>
      </Modal>
    );
  }
}

ErrorsModal.propTypes = {
  //Provided by component itself
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errorsDesc: PropTypes.array.isRequired,
};

const mapState = state => ({
  visible: errorsModalVisibleSel(state),
  errorsDesc: uploadErrorDescSel(state),
});

const mapDispatch = dispatch => ({
  onClose: () => dispatch(closeErrorsModal()),
});

export default connect(mapState, mapDispatch)(ErrorsModal);
