import React, {
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { FormattedMessage } from 'react-intl';

import ConfirmationModal from '../ConfirmationModal';
import styles from './SingleConfirmationModal.less';
import { ButtonType, SingleConfirmationModalRef } from './types';

type Props = {
  message?: string;
  onConfirm: (confirmData: unknown) => void;
  onCancel?: () => void;
  disableConfirm?: boolean;
  defaultMessage?: ReactNode;
  confirmBtnText?: ReactNode;
  loading: boolean;
  closeOnConfirm?: boolean;
  closeOnCancel?: boolean;
};

// eslint-disable-next-line react/display-name
const SingleConfirmationModal = forwardRef<SingleConfirmationModalRef, Props>(
  (
    {
      onConfirm,
      onCancel,
      message,
      confirmBtnText: mainConfirmBtnText,
      disableConfirm = false,
      loading,
      closeOnConfirm = true,
      closeOnCancel = true,
      ...props
    },
    ref,
  ) => {
    const [isVisible, setIsVisible] = useState(false);
    const [extraButtons, setExtraButtons] = useState<ButtonType[]>([]);
    const [confirmBtnText, setConfirmBtnText] = useState(mainConfirmBtnText);
    const [confirmMessage, setConfirmMessage] = useState<ReactNode>();
    const [confirmData, setConfirmData] = useState<unknown>();
    const primaryButtonProps = useMemo(
      () => ({
        id: 'qa_singleConfirmationModal',
        color: 'scouting-red',
        disabled: disableConfirm,
        children: confirmBtnText,
      }),
      [confirmBtnText, disableConfirm],
    );
    const defaultMessage = message || (
      <FormattedMessage id="progress.SingleConfirmationModal.message" />
    );
    const handleCancel = useCallback(() => {
      onCancel && onCancel();
      closeOnCancel && setIsVisible(false);
    }, [setIsVisible, onCancel, closeOnCancel]);
    const handleConfirm = useCallback(() => {
      onConfirm(confirmData);
      closeOnConfirm && setIsVisible(false);
    }, [onConfirm, confirmData, closeOnConfirm]);
    useImperativeHandle(ref, () => ({
      openConfirmationModal(confirmOptions) {
        const {
          confirmData,
          customeMessage,
          customExtraButtons,
          customConfirmBtnText,
        } = confirmOptions || {};
        setIsVisible(true);
        customeMessage
          ? setConfirmMessage(customeMessage)
          : setConfirmMessage(defaultMessage);
        customConfirmBtnText
          ? setConfirmBtnText(customConfirmBtnText)
          : setConfirmBtnText(mainConfirmBtnText);
        customExtraButtons
          ? setExtraButtons(customExtraButtons)
          : setExtraButtons([]);
        confirmData ? setConfirmData(confirmData) : setConfirmData(undefined);
      },
      closeConfirmationModal() {
        setIsVisible(false);
      },
      setCustomMessage(customeMessage) {
        setConfirmMessage(customeMessage);
      },
    }));
    return (
      <ConfirmationModal
        visible={isVisible}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        title={<FormattedMessage id="shared.confirmation" />}
        primaryButtonProps={primaryButtonProps}
        extraButtons={extraButtons}
        loading={loading}
        fullscreenMobile
        {...props}
      >
        <p className={styles.message}>{confirmMessage}</p>
      </ConfirmationModal>
    );
  },
);

export default SingleConfirmationModal;
