import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';

import { S, Progress, Button } from '@shared';
import GenericUploadItem from './GenericUploadItem';
import styles from './UploadItem.less';

class UploadItem extends React.PureComponent {
  handleRetry = () => {
    const { file, onRetry } = this.props;
    onRetry(file);
  };

  renderUploadingItem = () => {
    const { progress, onCancel } = this.props;
    const { percent } = progress;

    return (
      <GenericUploadItem
        title={<FormattedMessage id="advancementUpload.UploadItem.uploading" />}
        progressContent={
          <Progress
            percent={percent}
            status="active"
            showInfo={false}
            className={styles.uploadingProgress}
          />
        }
        actionContent={
          <Button
            size="small"
            color="scouting-red"
            fitText
            ghost
            noBorder
            className={styles.actionButton}
            onClick={onCancel}
          >
            <FormattedMessage id="advancementUpload.UploadItem.cancel" />
          </Button>
        }
      />
    );
  };

  renderUploadedItem = () => {
    const { file } = this.props;
    const { name, uploadedDate } = file;
    const parsedDate = moment(uploadedDate);
    const formattedDate = parsedDate.format('MMMM D, YYYY');
    const formattedTime = parsedDate.format('h:mma');

    return (
      <GenericUploadItem
        title={name}
        progressContent={
          <Progress percent={100} status="success" showInfo={false} />
        }
        actionContent={<CheckCircleIcon className={styles.successIcon} />}
        extraContent={
          <React.Fragment>
            <S size="6">
              <FormattedMessage id="advancementUpload.UploadItem.processing" />
              <br />
              <FormattedMessage
                id="advancementUpload.UploadItem.uploadedDate"
                values={{ date: formattedDate, time: formattedTime }}
              />
            </S>
          </React.Fragment>
        }
      />
    );
  };

  renderErrorItem = () => {
    const { errorsCount, onShowErrors } = this.props;

    return (
      <GenericUploadItem
        title={<FormattedMessage id="advancementUpload.UploadItem.error" />}
        progressContent={
          <Progress percent={100} status="exception" showInfo={false} />
        }
        actionContent={
          <Button
            size="small"
            color="info"
            fitText
            ghost
            noBorder
            className={styles.actionButton}
            onClick={this.handleRetry}
          >
            <FormattedMessage id="advancementUpload.UploadItem.retry" />
          </Button>
        }
        extraContent={
          <S size="6">
            <Button
              size="small"
              color="info"
              fitText
              ghost
              noBorder
              className={styles.actionButton}
              onClick={onShowErrors}
            >
              <FormattedMessage id="advancementUpload.UploadItem.viewErrors" />
            </Button>
            <FormattedMessage
              id="advancementUpload.UploadItem.errorCount"
              values={{ count: errorsCount }}
            />
          </S>
        }
      />
    );
  };

  render() {
    const { type } = this.props;

    switch (type) {
      case 'uploading': {
        return this.renderUploadingItem();
      }
      case 'uploaded': {
        return this.renderUploadedItem();
      }
      case 'error': {
        return this.renderErrorItem();
      }
    }
  }
}

UploadItem.propTypes = {
  type: PropTypes.oneOf(['uploading', 'uploaded', 'error']).isRequired,
  progress: PropTypes.object,
  file: PropTypes.object,
  errorsCount: PropTypes.number,
  onShowErrors: PropTypes.func,
  onCancel: PropTypes.func,
  onRetry: PropTypes.func,
};

export default UploadItem;
