import React, { SyntheticEvent } from 'react';

const onError = (event: SyntheticEvent<HTMLImageElement>) => {
  const target = event.target as HTMLImageElement;
  target.style.visibility = 'hidden';
  return;
};

function Img(
  props: React.JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLImageElement> &
    React.ImgHTMLAttributes<HTMLImageElement>,
) {
  return <img {...props} onError={onError} />;
}

export default Img;
