import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import T from '../../T';
import styles from './CardSection.less';

const CardSection = ({ title, children, className, ...rest }) => {
  const classNames = cn(styles.cardSectionContainer, className);
  return (
    <div className={classNames} {...rest}>
      <div className={styles.cardSectionTitle}>
        <T size="5" bold colored noMargin>
          {title}
        </T>
      </div>
      <div>{children}</div>
    </div>
  );
};

CardSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default CardSection;
