import _ from 'lodash';

import { PositionId } from '@modules/shared/constants';

import type { RegistrarInfo, RosterScout } from '../duck/types';

export const getIsDenChief = (scout: RosterScout) =>
  scout.positions?.some(({ positionId }) => positionId === PositionId.DenChief);

export const dedupeMembers = (scouts: RosterScout[]) =>
  Object.values(
    scouts.reduce((acc, scout) => {
      const { personGuid, userId } = scout;
      const key = `${personGuid}-${userId}`;
      return {
        ...acc,
        [key]: { ...acc[key], ...scout },
      };
    }, {} as Record<string, RosterScout>),
  );

export function findRegistrationRecord(
  items: Array<RegistrarInfo & { personGuid: string }>,
  personGuid: string,
) {
  const person = _.chain(items)
    .filter(item => item.personGuid === personGuid)
    .orderBy(
      ['registrationEffectiveDt', 'registrationExpireDt'],
      ['desc', 'desc'],
    )
    .first()
    .value();

  return person;
}
