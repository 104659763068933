import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

export const groupReport = flatReport => {
  const groupedReport = groupBy(flatReport, 'advancementType');
  Object.keys(groupedReport).forEach(type => {
    groupedReport[type] = uniqBy(groupedReport[type], 'advancementId').map(
      advancement => ({
        ...advancement,
        youth: flatReport
          .filter(
            ({ advancementId, advancementType }) =>
              advancementType === type &&
              advancementId === advancement.advancementId,
          )
          .map(({ youth }) => youth),
      }),
    );
  });

  return groupedReport;
};

export const countUniqueMembers = flatReport =>
  uniqBy(flatReport, 'youth.userId').length;

export const countUniqueAdvancements = flatReport =>
  uniqBy(flatReport, 'advancementId').length;

export const breakdownMembersByType = flatReport => {
  const groups = groupBy(flatReport, 'advancementType');
  return Object.keys(groups).map(type => {
    const count = (groups[type] = uniqBy(groups[type], 'youth.userId').length);

    return {
      type,
      count,
    };
  });
};
