import moment from 'moment';
import { createSelector } from 'reselect';

import { programSel } from '@context/duck/selectorsTyped';
import { isCurrentPageSel } from '@location';
import {
  pendingRequestsSel,
  pendingAdvancementsSel as recordedOfflineAdvancementsSel,
} from '@offline';
import {
  AdvancementStatus,
  Program,
  ProgramId,
  advancementHistoryFieldPreferencesName,
  advancementHistoryItemsSel,
  advancementHistoryLoadingSel,
  advancementStatuses,
  advancementTypes,
  dtoDateFormat,
  getUnapproveConstraint,
  isEagleScoutRank,
  packRosterItemsSel,
  syncOperations,
  userPreferencesSel,
} from '@shared';
import {
  applySort,
  canEditAdvancement,
  getAdvancementDescription,
  personNameBuilder,
} from '@utils';

import {
  ROUTE_ADVANCEMENT,
  activeTabSel,
  activityItemsTypes,
  advancementHistoryModuleSel,
  tabNames,
  youthItemsSel,
} from '../../common';
import advancementHistorySorters from '../advancementHistorySorters';
import {
  activityTypesToFilterType,
  advancementStatusFilters,
  historyItemsTypes,
  peopleFilters,
  visibilityFields,
} from '../constants';
import { getLastUpdatedDate } from '../helpers';

export { pendingAdvancementsSel as pendingItemsSel } from '@shared';

const defaultFieldsVisibility = {
  [visibilityFields.MEMBER_ID]: true,
  [visibilityFields.DATE]: true,
  [visibilityFields.RECORDED_BY]: true,
};

const advancementHistoryFieldsVisibilitySel = state =>
  userPreferencesSel(state, advancementHistoryFieldPreferencesName);

export const fieldsVisibilitySel = createSelector(
  advancementHistoryFieldsVisibilitySel,
  fieldsVisibility => ({
    ...defaultFieldsVisibility,
    ...fieldsVisibility,
  }),
);

const moduleSel = state => advancementHistoryModuleSel(state);

export const loadingSel = state => advancementHistoryLoadingSel(state);
export const sorterSel = state => moduleSel(state).sorter;
export const expandedKeysSel = state => moduleSel(state).expandedKeys;
export const searchFilterSel = state => moduleSel(state).searchFilter;
export const updatedDateFilterSel = state => moduleSel(state).updatedDateFilter;
export const advancementTypesFilterSel = state =>
  moduleSel(state).advancementTypesFilter;
export const advancementStatusFilterSel = state =>
  moduleSel(state).advancementStatusFilter;
export const activityTypesFilterSel = state =>
  moduleSel(state).activityTypesFilter;
export const showAdvancementFilesSel = state =>
  moduleSel(state).showAdvancementFiles;
export const peopleFilterSel = state => moduleSel(state).peopleFilter;
export const selectedKeysSel = state =>
  advancementHistoryModuleSel(state).selectedRowsKeys;
export const expandedMainRowsSel = state =>
  advancementHistoryModuleSel(state).expandedMainRows;

export const isActivityTabCurrentSel = state =>
  isCurrentPageSel(state, ROUTE_ADVANCEMENT) &&
  activeTabSel(state) === tabNames.ACTIVITY;

const filterCubAdvancements = items =>
  items.filter(
    ({ advancementType, programId }) =>
      !(
        advancementType == advancementTypes.ADVENTURES ||
        programId == ProgramId.CUB_SCOUT
      ),
  );

const isSyncedHistoryItem = ({ item, pendingRequests }) => {
  if (item.type === historyItemsTypes.ADVANCEMENT_FILE) {
    return true;
  }

  return !pendingRequests.some(
    request =>
      request.userId == item.userId &&
      request.advancementType == item.advancementType &&
      request.advancementId == item.id &&
      (advancementTypes.AWARDS != item.advancementType ||
        request.userAwardId == item.userAwardId),
  );
};

export const itemsSel = createSelector(
  advancementHistoryItemsSel,
  pendingRequestsSel,
  programSel,
  youthItemsSel,
  (items, pendingRequests, program, youthItems) =>
    (program == Program.CUB_SCOUT ? items : filterCubAdvancements(items)).map(
      item => {
        const canEdit =
          item.type === historyItemsTypes.ACTIVITY ||
          (item.type === historyItemsTypes.ADVANCEMENT &&
            canEditAdvancement({
              item,
              program,
              youthItems,
            }));
        const canUnapprove =
          item.isAdvancementPending &&
          !getUnapproveConstraint({
            program,
            item,
            advancementsHistory: items,
          });

        return {
          ...item,
          canEdit,
          canUnapprove,
          isSynced: isSyncedHistoryItem({
            item,
            pendingRequests,
          }),
          isEagleScoutRank: isEagleScoutRank(item),
        };
      },
    ),
);

const recordedOfflineItemsSel = createSelector(
  recordedOfflineAdvancementsSel,
  packRosterItemsSel,
  (pendingAdvancements, packRoster) =>
    pendingAdvancements
      .filter(
        ({ operation, advancement }) =>
          operation == syncOperations.RECORD_ADVANCEMENT && !!advancement,
      )
      .map(({ advancement, ...offlineItem }) => {
        const youth =
          packRoster.find(({ userId }) => offlineItem.userId == userId) || {};
        return {
          ...offlineItem,
          ...advancement,
          key: offlineItem.id,
          advancementName: advancement.name,
          date: moment(offlineItem.date).format(dtoDateFormat),
          type: historyItemsTypes.ADVANCEMENT,
          description: getAdvancementDescription(
            advancement.name,
            offlineItem.advancementType,
          ),
          name: personNameBuilder.short(youth),
          memberId: youth.memberId,
          isSynced: false,
          isAdvancementPending: false,
          canEdit: true,
          canUnapprove: false,
          isEagleScoutRank: isEagleScoutRank(advancement),
          recordedInformation: undefined,
          recordedBy: undefined,
          status: advancementStatuses.APPROVED,
          statusLabel: AdvancementStatus.getTranslation(
            advancementStatuses.APPROVED,
          ),
          filterableStatus: advancementStatusFilters.APPROVED,
          imgUrl: advancement.imageUrl100,
        };
      }),
);

export const filteredItemsSel = createSelector(
  itemsSel,
  recordedOfflineItemsSel,
  searchFilterSel,
  advancementTypesFilterSel,
  showAdvancementFilesSel,
  advancementStatusFilterSel,
  updatedDateFilterSel,
  activityTypesFilterSel,
  peopleFilterSel,
  (
    items,
    offlineItems,
    searchTerm = '',
    advancementTypes,
    showAdvancementFiles,
    advancementStatus,
    updatedDateFilter,
    activityTypeFilter,
    peopleTypeFilter,
  ) => {
    const selectedAdvancementTypes = Object.keys(advancementTypes).filter(
      key => advancementTypes[key],
    );
    const selectedAdvancementStatus = Object.keys(advancementStatus).filter(
      key => advancementStatus[key],
    );
    const selectedActivityTypes = Object.keys(activityTypeFilter).filter(
      key => activityTypeFilter[key],
    );
    const selectedPersonTypes = Object.keys(peopleTypeFilter).filter(
      key => peopleTypeFilter[key],
    );

    const matchSearchTerm = (attr = '') =>
      attr.toLowerCase().includes(searchTerm.toLowerCase());
    const updatedDateLimit = getLastUpdatedDate(updatedDateFilter);

    const filterableItems = [...offlineItems, ...items];
    const filteredItems = filterableItems.filter(
      ({
        type,
        advancementType,
        filterableStatus,
        name,
        memberId,
        description,
        date,
        isAdvancementPending,
        activityType,
        isAdult,
      }) => {
        const isInSearchTerm =
          matchSearchTerm(name) ||
          matchSearchTerm(String(memberId)) ||
          matchSearchTerm(description);

        const isInPersonFilterTypes = selectedPersonTypes.includes(
          isAdult ? peopleFilters.ADULTS : peopleFilters.YOUTH,
        );

        if (!isInSearchTerm) {
          return false;
        }

        if (
          isAdvancementPending &&
          selectedAdvancementStatus.includes(filterableStatus)
        ) {
          return true;
        }

        if (moment(date).isBefore(updatedDateLimit, 'day')) {
          return false;
        }

        if (activityType) {
          return (
            selectedActivityTypes.includes(
              activityTypesToFilterType[activityType],
            ) && isInPersonFilterTypes
          );
        }

        return type == historyItemsTypes.ADVANCEMENT
          ? selectedAdvancementTypes.includes(advancementType) &&
              selectedAdvancementStatus.includes(filterableStatus) &&
              isInPersonFilterTypes
          : showAdvancementFiles;
      },
    );
    return filteredItems;
  },
);

export const filteredPendingItemsSel = createSelector(
  filteredItemsSel,
  sorterSel,
  (items, sorter) => {
    const pendingItems = items.filter(
      ({ isAdvancementPending }) => isAdvancementPending,
    );

    return applySort({
      items: pendingItems,
      sorter,
      sorters: advancementHistorySorters,
    });
  },
);

export const filteredHistoryItemsSel = createSelector(
  filteredItemsSel,
  sorterSel,
  (items, sorter) => {
    const historyItems = items.filter(
      ({ isAdvancementPending, status }) =>
        !isAdvancementPending ||
        advancementStatuses.COUNSELOR_APPROVED === status,
    );
    return applySort({
      items: historyItems,
      sorter,
      sorters: advancementHistorySorters,
    });
  },
);

export const filteredItemsSectionsSel = createSelector(
  filteredPendingItemsSel,
  filteredHistoryItemsSel,
  (pendingItems, historyItems) => ({
    [activityItemsTypes.PENDING_APPROVAL]: [],
    [activityItemsTypes.HISTORY]: historyItems,
  }),
);
