import { ProgramId, advancementStatuses } from '@shared';

import { warningsCodes } from './constants';

export const getWarningCode = ({
  unitTypeId,
  dateCompleted,
  isPreviousRankEarned,
  isPreviousCoreAwardEarned,
  isApproved,
  isAwarded,
  isInInvalidUnit,
  hasValidAge,
  selectedAdvancementStatus,
}) => {
  if (
    selectedAdvancementStatus === advancementStatuses.APPROVED &&
    dateCompleted
  ) {
    return warningsCodes.ADVANCEMENT_COMPLETED;
  }
  if (unitTypeId != ProgramId.CUB_SCOUT && isPreviousRankEarned === false) {
    return warningsCodes.PREVIOUS_RANK_ERROR;
  }
  if (unitTypeId == ProgramId.CUB_SCOUT && isPreviousRankEarned === false) {
    return warningsCodes.BOBCAT_RANK_REQUIRED;
  }
  if (isPreviousCoreAwardEarned === false) {
    return warningsCodes.PREVIOUS_CORE_AWARD;
  }
  if (
    selectedAdvancementStatus === advancementStatuses.APPROVED &&
    isApproved
  ) {
    return warningsCodes.ADVANCEMENT_APPROVED;
  }
  if (selectedAdvancementStatus === advancementStatuses.AWARDED && isAwarded) {
    return warningsCodes.ADVANCEMENT_AWARDED;
  }
  if (isInInvalidUnit) {
    return warningsCodes.INCORRECT_UNIT;
  }
  if (!hasValidAge) {
    return warningsCodes.AGE_PROBLEM;
  }

  return warningsCodes.PREVIOUS_RANK_WARNING;
};
