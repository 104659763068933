/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { S, ProgramLogo, UnitType, Button, UnitGenderPill } from '@shared';
import styles from './UnitsList.less';
import { expireDateFormat } from '../../../constants';

function columns({ hasSearchedItems, onRemoveItem }) {
  return function historyReportsRows(item) {
    const {
      unitTypeId,
      unitNumber,
      charteredOrganizationName,
      districtName,
      expireDate,
      searchDate,
      organizationGuid,
      unitGender,
    } = item;

    return [
      ...[
        {
          rowId: 'unitType',
          className: styles.typeRow,
          label: (
            <S size="4" bold>
              <FormattedMessage id="councilUnits.CouncilUnitsPage.column.type" />
            </S>
          ),
          content: (
            <div>
              <ProgramLogo programId={unitTypeId} />
              <S size="4" inlineBlock>
                <UnitType programId={unitTypeId} />
              </S>{' '}
              <UnitGenderPill gender={unitGender} />
            </div>
          ),
        },
        {
          rowId: 'unitNumber',
          label: (
            <S size="4" bold>
              <FormattedMessage id="councilUnits.CouncilUnitsPage.column.unitNumber" />
            </S>
          ),
          content: <S size="4">{unitNumber}</S>,
        },
        {
          rowId: 'charteredOrganizationName',
          label: (
            <S size="4" bold>
              <FormattedMessage id="councilUnits.CouncilUnitsPage.column.charteredOrg" />
            </S>
          ),
          content: <S size="4">{charteredOrganizationName}</S>,
        },
        {
          rowId: 'districtName',
          label: (
            <S size="4" bold>
              <FormattedMessage id="councilUnits.CouncilUnitsPage.column.district" />
            </S>
          ),
          content: <S size="4">{districtName}</S>,
        },
        {
          rowId: 'expireDate',
          label: (
            <S size="4" bold>
              <FormattedMessage id="councilUnits.CouncilUnitsPage.column.expireDate" />
            </S>
          ),
          content: (
            <S size="4">{moment(expireDate).format(expireDateFormat)}</S>
          ),
        },
      ],
      ...(hasSearchedItems
        ? [
            {
              rowId: 'searchDate',
              label: (
                <S size="4" bold>
                  <FormattedMessage id="councilUnits.CouncilUnitsPage.column.searchDate" />
                </S>
              ),
              content: <S size="4">{moment(searchDate).format('MMM D')}</S>,
            },
            {
              rowId: 'removeItem',
              content: (
                <Button
                  className={styles.removeButton}
                  ghost
                  noBorder
                  fitText
                  onClick={e => {
                    e.stopPropagation();
                    onRemoveItem(organizationGuid);
                  }}
                >
                  <FormattedMessage id="councilUnits.CouncilUnitsPage.remove" />
                </Button>
              ),
            },
          ]
        : []),
    ];
  };
}

export default columns;
