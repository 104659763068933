import { lazier } from '@shared';

import AdultProfilePagePlaceholder from './AdultProfilePagePlaceholder';

export { AdultProfilePagePlaceholder };

export const AdultProfilePage = lazier(
  () => import('./AdultProfilePage'),
  AdultProfilePagePlaceholder,
);

export const AdultActivityLogsPage = lazier(
  () => import('./AdultActivityLogsPage'),
  AdultProfilePagePlaceholder,
);
