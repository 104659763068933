/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useMemo } from 'react';

import Info from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { RunReportDropdown } from '@modules/advancement/common';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import {
  ApproveAdvancementsButton,
  DeclineAdvancementsButton,
} from '@progress/components';
import { FilterButton, PaginationTotal, Table, Tooltip } from '@shared';
import { hasPermissionSel, permissions } from '@user';
import { toggleArrayItem } from '@utils';

import { setFromActivityLogs } from '../../../../../progress/approveAdvancements';
import PendingItemsFilterCard from '../PendingItemsFilterCard';
import styles from './PendingItemsTable.less';
import columns from './pendingItemsTableColumns';

const PendingItemsTable = ({
  items,
  loading,
  sorter,
  selectedRowKeys,
  isOffline,
  onSetSelectedRowKeys,
  onGoToYouthProfile,
  onGoToAdultProfile,
  onSorterChange,
}) => {
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );
  const areAnyAdvancementsSelected = selectedRowKeys.length > 0;
  const selectedAdvancements = useMemo(
    () => items.filter(({ key }) => selectedRowKeys.includes(key)),
    [items, selectedRowKeys],
  );
  const hasAdvancementsSelected = useMemo(
    () => selectedAdvancements.some(({ type }) => type === 'advancement'),
    [selectedAdvancements],
  );
  const dispatch = useDispatch();
  const handleSelectAll = useCallback(
    isSelectAll => {
      const selectedItemsKeys = isSelectAll ? items.map(({ key }) => key) : [];
      onSetSelectedRowKeys(selectedItemsKeys);
    },
    [items, onSetSelectedRowKeys],
  );

  const handleSelectRow = useCallback(
    record => {
      const newSelectedKeys = toggleArrayItem(selectedRowKeys, record.key);
      onSetSelectedRowKeys(newSelectedKeys);
    },
    [selectedRowKeys, onSetSelectedRowKeys],
  );

  const handleTableChange = useCallback(
    (_pagination, _filters, sorter) => {
      onSorterChange(sorter);
    },
    [onSorterChange],
  );

  const renderPaginationTotal = useCallback(
    total => (
      <div className={styles.pendinTablePaginationContainer}>
        <Tooltip
          title={
            <FormattedMessage id="advancement.PendingItems.totalDisclaimer" />
          }
        >
          <Info className={styles.infoIcon} />
        </Tooltip>
        <PaginationTotal total={total} selected={selectedRowKeys.length} />
      </div>
    ),
    [selectedRowKeys],
  );

  const pagination = {
    showTotal: renderPaginationTotal,
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSetSelectedRowKeys,
    onSelectAll: handleSelectAll,
  };

  useEffect(() => {
    dispatch(setFromActivityLogs(false));
  }, [dispatch]);

  const renderTitle = () => (
    <React.Fragment>
      {!featureFlags.getFlag('SBL_4463_ADVANCEMENTS_TAB') && (
        <RunReportDropdown
          disabled={isOffline || areAnyAdvancementsSelected}
          showAllUsers
        />
      )}
      {canApprove && (
        <ApproveAdvancementsButton
          className={styles.approveAdvancements}
          disabled={isOffline || !areAnyAdvancementsSelected}
          personsAdvancements={selectedAdvancements}
        />
      )}
      {canApprove && (
        <DeclineAdvancementsButton
          className={styles.approveAdvancements}
          disabled={
            isOffline || !areAnyAdvancementsSelected || hasAdvancementsSelected
          }
          personsAdvancements={selectedAdvancements}
        />
      )}
      <PendingItemsFilterCard>
        <FilterButton />
      </PendingItemsFilterCard>
    </React.Fragment>
  );

  return (
    <Table
      className={styles.table}
      size="small"
      fixedLayout
      dataSource={items}
      loading={loading}
      rowKey="key"
      title={renderTitle}
      columns={columns({ sorter, onGoToYouthProfile, onGoToAdultProfile })}
      pagination={pagination}
      sorter={sorter}
      rowSelection={rowSelection}
      onRow={record => ({ onClick: () => handleSelectRow(record) })}
      rowClassName={() => styles.rowItem}
      onChange={handleTableChange}
    />
  );
};

PendingItemsTable.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  sorter: PropTypes.object.isRequired,
  isOffline: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.array,
  onSetSelectedRowKeys: PropTypes.func,
  onGoToYouthProfile: PropTypes.func,
  onGoToAdultProfile: PropTypes.func,
  onSorterChange: PropTypes.func,
};

export default PendingItemsTable;
