import React from 'react';

import { EditActivityButton } from '../../../common';
import { openEditLongCruise, loadAndOpenLongCruiseModal } from '../../duck';

const EditLongCruiseButton = props => (
  <EditActivityButton
    onLoadAction={loadAndOpenLongCruiseModal}
    onOpenAction={openEditLongCruise}
    {...props}
  />
);

export default EditLongCruiseButton;
