import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  activityTypes,
  activityCalendarTypes,
  activityTypeForActivityTypeId,
} from '../../constants';
import { intl } from '../../localization';
import ActivityTypeIcon from '../ActivityTypeIcon';
import styles from './ActivityType.less';

const ActivityType = ({
  icon,
  className,
  activityType,
  activityTypeId,
  isJTE,
  isCalendar,
}) => {
  const activityTypeName = useMemo(
    () => activityType || activityTypeForActivityTypeId[activityTypeId],
    [activityType, activityTypeId],
  );

  const activityNameId = useMemo(() => {
    const isServiceCalendar =
      isCalendar && activityType === activityTypes.SERVICE_PROJECTS;
    return isServiceCalendar
      ? activityCalendarTypes.SERVICE_PROJECT
      : activityTypeName;
  }, [activityTypeName, activityType, isCalendar]);

  const iconNode = icon && (
    <ActivityTypeIcon
      activityType={activityTypeName}
      className={styles.textIcon}
      isJTE={isJTE}
    />
  );

  return (
    <span className={className}>
      {iconNode}
      <FormattedMessage id={`shared.ActivityType.${activityNameId}`} />
    </span>
  );
};

const getTranslation = activityType =>
  intl.formatMessage({ id: `shared.ActivityType.${activityType}` });

ActivityType.propTypes = {
  activityType: PropTypes.oneOf(Object.values(activityTypes)),
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  icon: PropTypes.bool,
  isJTE: PropTypes.bool,
  isCalendar: PropTypes.bool,
};

ActivityType.defaultProps = {
  icon: false,
  isJTE: false,
  isCalendar: false,
};

ActivityType.getTranslation = getTranslation;

export default ActivityType;
