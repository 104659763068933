import { youthProfileModuleName } from '@shared/constants';
import extractOrgGuidAndUserId from '@shared/utils/extractOrgGuidAndUserId';

const DEFAULT_UNITS_STORAGE_KEY = 'DEFAULT_UNITS_STORAGE';
const ACTIVE_UNIT = 'ACTIVE_UNIT';

const retrieveDefaultUnits = () => {
  const data = localStorage.getItem(DEFAULT_UNITS_STORAGE_KEY) || '{}';
  return JSON.parse(data);
};

const retrieveDefaultUnit = personId => {
  const data = retrieveDefaultUnits();
  const defaultUnit = data[personId] || '';
  return defaultUnit;
};

const storeDefaultUnits = data => {
  localStorage.setItem(DEFAULT_UNITS_STORAGE_KEY, JSON.stringify(data));
};

export const saveDefaultUnit = (personGuid, organizationGuid) => {
  const data = retrieveDefaultUnits();
  const organizationsGuids = {
    ...data,
    [personGuid]: organizationGuid,
  };
  storeDefaultUnits(organizationsGuids);
};

const getOrgGuidFromUrl = currentOrgGuid => {
  const location = document.location.pathname;
  if (location) {
    const urlSections = location.split('/');
    const urlModule = urlSections[urlSections.length - 2];
    const urlModuleId = urlSections[urlSections.length - 1];
    if (urlModule === youthProfileModuleName) {
      const { orgGuid, userId } = extractOrgGuidAndUserId(currentOrgGuid);
      return urlModuleId == userId
        ? currentOrgGuid
        : `${orgGuid}*${urlModuleId}`;
    }
  }
  return undefined;
};

export const retrieveCurrentUnit = () => {
  const data = retrieveDefaultUnits();
  const lastUnit = data[ACTIVE_UNIT] || '';
  const orgGuidFromURL = getOrgGuidFromUrl(lastUnit);
  if (orgGuidFromURL) {
    return orgGuidFromURL;
  }

  return lastUnit;
};

export const saveCurrentUnit = organizationGuid => {
  let organizationGuidUpdated = organizationGuid;
  const data = retrieveDefaultUnits();
  const orgGuidFromURL = getOrgGuidFromUrl(organizationGuid);
  if (orgGuidFromURL) {
    organizationGuidUpdated = orgGuidFromURL;
  }

  const organizationGuids = {
    ...data,
    [ACTIVE_UNIT]: organizationGuidUpdated,
  };
  storeDefaultUnits(organizationGuids);
};

const removeCurrentInfo = () => {
  const data = retrieveDefaultUnits();
  const organizationGuids = {
    ...data,
    [ACTIVE_UNIT]: undefined,
  };
  storeDefaultUnits(organizationGuids);
};

export default {
  retrieveDefaultUnit,
  saveDefaultUnit,
  retrieveCurrentUnit,
  saveCurrentUnit,
  removeCurrentInfo,
};
