import React, { useCallback } from 'react';

import { Button } from 'bsa-ui';
import moment from 'moment';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteRecurrentEventInfoSel,
  setDeleteRecurrentEventInfo,
} from '@modules/calendar/duck';
import { DeleteRecurrentEventOption } from '@modules/events/duck/enums';
import { DatePicker, Modal, Radio, S, deleteRecurrentEventReq } from '@shared';

import styles from './RemoveRecurrentEventModal.styles.less';

const RadioGroup = Radio.Group;

export function RemoveRecurrentEventModal() {
  const dispatch = useDispatch();
  const deleteEventInfo = useSelector(deleteRecurrentEventInfoSel);
  const visible = deleteEventInfo.modalVisible;
  const option = deleteEventInfo.deleteOption;

  const onChangeOption = useCallback(
    event => {
      dispatch(
        setDeleteRecurrentEventInfo({
          ...deleteEventInfo,
          deleteOption: event.target?.value,
        }),
      );
    },
    [deleteEventInfo, dispatch],
  );

  const closeModal = () => {
    dispatch(
      setDeleteRecurrentEventInfo({ ...deleteEventInfo, modalVisible: false }),
    );
  };

  const submitDelete = () => {
    dispatch(deleteRecurrentEventReq(deleteEventInfo.recurrentId));
  };

  const isValid =
    option &&
    (option === DeleteRecurrentEventOption.AllFromDate
      ? deleteEventInfo.dateFrom
      : true);

  return (
    <Modal
      useNewStyle
      visible={visible}
      className={styles.removeRecurrentEventModal}
      size="small"
      closable
      title={<div />}
      destroyOnClose
      footer={
        <>
          <Button onClick={closeModal}>
            <FormattedMessage id="shared.cancel" />
          </Button>
          <Button disabled={!isValid} onClick={submitDelete} type="primary">
            <FormattedMessage id="events.deleteEventModal.confirm" />
          </Button>
        </>
      }
      onCancel={closeModal}
    >
      <div className={styles.modalBody}>
        <FormattedMessage id="events.deleteEventModal.info" />
        <RadioGroup
          className={styles.radioGroup}
          value={option}
          onChange={onChangeOption}
        >
          <Radio value={DeleteRecurrentEventOption.AllFromDate}>
            <S size="4" colored inline>
              <FormattedHTMLMessage id="events.deleteEventModal.options.fromDate" />
            </S>
          </Radio>
          <Radio value={DeleteRecurrentEventOption.AllSinceToday}>
            <S size="4" colored inline>
              <FormattedHTMLMessage
                id="events.deleteEventModal.options.fromToday"
                values={{ date: moment().toDate().toLocaleDateString() }}
              />
            </S>
          </Radio>
        </RadioGroup>
        {!!(option === DeleteRecurrentEventOption.AllFromDate) && (
          <DatePicker
            size="small"
            fluid
            floatingLabel
            allowClear
            onChange={momentDate => {
              dispatch(
                setDeleteRecurrentEventInfo({
                  ...deleteEventInfo,
                  dateFrom: momentDate?.toDate(),
                }),
              );
            }}
          />
        )}
        <FormattedMessage id="events.deleteEventModal.note" />
      </div>
    </Modal>
  );
}
