import React from 'react';

// TODO: this is a temporary workaround for eslint until bsa-ui is fixed

/* eslint-disable import/named */
import { DatePicker } from 'bsa-ui';
import { useSelector } from 'react-redux';

import { isMobileSel } from '../../duck';
import BasePicker, { basePropTypes } from './BasePicker';
import styles from './BasePicker.less';
import MobileDatePicker from './Mobile';
import MonthPicker from './MonthPicker';
import RangePicker from './RangePicker';
import WeekPicker from './WeekPicker';

/**
 * @type {React.FC<import('./DatePicker.types').DatePickerProps>}
 */
const SblDatePicker = React.forwardRef(function SblDatePicker(props, ref) {
  const isMobile = useSelector(isMobileSel);

  return (
    <div className={styles.datePickerWrapper}>
      <BasePicker
        ref={ref}
        Picker={isMobile ? MobileDatePicker : DatePicker}
        {...props}
      />
    </div>
  );
});

SblDatePicker.RangePicker = RangePicker;
SblDatePicker.MonthPicker = MonthPicker;
SblDatePicker.WeekPicker = WeekPicker;

SblDatePicker.propTypes = basePropTypes;

SblDatePicker.defaultProps = {
  format: 'MM/DD/YYYY',
};

export default SblDatePicker;
