import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import {
  AdvancementImage,
  ActivityTypeIcon,
  advancementStatuses,
  Tooltip,
  intl,
} from '@shared';
import { historyItemsTypes } from '../../../../common';
import styles from './PendingItemAdvancementName.less';

const PendingItemAdvancementName = ({ item, type, showIcon }) => {
  switch (type) {
    case historyItemsTypes.ADVANCEMENT: {
      return (
        <span>
          {showIcon && (
            <AdvancementImage src={item.imgUrl} className={styles.image} />
          )}
          {item.advancementName}
          {item.status === advancementStatuses.COUNSELOR_APPROVED && (
            <Tooltip
              title={intl.formatMessage({
                id: 'shared.AdvancementStatus.counselorApproved',
              })}
            >
              <DoneAllIcon className={cn(styles.icon, styles.approved)} />
            </Tooltip>
          )}
        </span>
      );
    }
    case historyItemsTypes.ACTIVITY: {
      return (
        <span>
          {showIcon && (
            <ActivityTypeIcon
              isJTE={item.isJTE}
              activityTypeId={item.activityTypeId}
              className={styles.image}
            />
          )}
          {item.activityName}
        </span>
      );
    }

    default: {
      return '';
    }
  }
};

PendingItemAdvancementName.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  showIcon: PropTypes.bool,
};

PendingItemAdvancementName.defaultProps = {
  showIcon: false,
};

export default PendingItemAdvancementName;
