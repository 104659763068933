import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './ProgramColors.less';

const ProgramColors = ({
  program,
  bg,
  text,
  coloredText,
  disabled,
  children: child,
}) => {
  const classNames = cn(
    {
      [styles[`${program}_bg`]]: bg,
      [styles[`${program}_text`]]: text,
      [styles[`${program}_coloredText`]]: coloredText,
      [styles.disabled]: disabled,
    },
    child.props.className,
  );

  const props = {
    ...child.props,
    className: classNames,
  };

  return React.cloneElement(child, props);
};

ProgramColors.propTypes = {
  program: PropTypes.string.isRequired,
  bg: PropTypes.bool, // apply program color to background
  text: PropTypes.bool, // apply text color that is visible against the program color background
  coloredText: PropTypes.bool, // In case you want to apply the same program color to the Text
  disabled: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

ProgramColors.defaultProps = {
  bg: true,
  text: true,
  coloredText: false,
};

export default ProgramColors;
