import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import { resetOnLogoutReducer } from '@utils';

import {
  moduleName as mbcAssignModuleName,
  reducers as mbcAssignReducers,
  epics as mbcAssignEpics,
} from './mbcAssign';
import {
  moduleName as mbcManageModuleName,
  reducers as mbcManageReducers,
  epics as mbcManageEpics,
} from './mbcManage';

export const reducers = resetOnLogoutReducer(
  combineReducers({
    [mbcAssignModuleName]: mbcAssignReducers,
    [mbcManageModuleName]: mbcManageReducers,
  }),
);

export const epics = combineEpics(mbcAssignEpics, mbcManageEpics);
