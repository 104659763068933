import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { addPassiveEventListener } from '../../utils';

class IsElementInViewport extends React.PureComponent {
  state = {
    isInViewport: true,
  };

  componentDidMount = () => {
    this.addListeners();
    this.updateStatus();
  };

  componentDidUpdate = () => {
    this.updateStatus();
  };

  componentWillUnmount = () => {
    this.removeListeners();
  };

  addListeners = () => {
    addPassiveEventListener(window, 'scroll', this.updateStatus);
    addPassiveEventListener(window, 'resize', this.updateStatus);
  };

  removeListeners = () => {
    window.removeEventListener('scroll', this.updateStatus);
    window.removeEventListener('resize', this.updateStatus);
  };

  updateStatus = throttle(
    () =>
      requestAnimationFrame(() => {
        const { elementRef: element, offsetTop, onChange } = this.props;

        if (!element) {
          return;
        }

        const { top } = element.getBoundingClientRect();
        const isInViewport = (top || 0) >= offsetTop;

        if (this.state.isInViewport !== isInViewport) {
          this.setState({ isInViewport });
          onChange(isInViewport);
        }
      }),
    30,
  );

  render() {
    return this.props.children;
  }
}

IsElementInViewport.propTypes = {
  elementRef: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  children: PropTypes.node.isRequired,
  offsetTop: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

IsElementInViewport.defaultProps = {
  offsetTop: 0,
};

export default IsElementInViewport;
