import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Col, Radio, Row, Tooltip, Button } from 'bsa-ui';
import Input from '../Input';
import { intl } from '../../localization';
import Form from '../Form';
import T from '../T';
import styles from './ProfileContactInfoCard.less';
import CancelIcon from 'material-ui-icons/Cancel';
import AddCircleIcon from 'material-ui-icons/AddCircle';
import { clone, isEmpty, isNil } from 'lodash';
import isValueTruthy from '../../utils/isValueTruthy';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';
import { DeleteEmailModal } from './DeleteEmailModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoadingDeleteEmailModalSel,
  setIsLoadingDeleteEmailModal,
  setShowDeleteEmailModal,
  showDeleteEmailModalSel,
} from '../../duck';
import Checkbox from '../Checkbox';
import S from '../S';
import { subscriptionIds } from '../../constants';

const { Item: FormItem } = Form;

export const EmailForm = ({
  form,
  profile,
  isUnregisteredUser,
  onConfirmDelete,
  isYouth = false,
  isReadOnly,
}) => {
  const dispatch = useDispatch();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { emails = [], subscriptions = [] } = profile;
  const [formEmails, setFormEmails] = useState(
    emails.length
      ? clone(emails)
      : [{ id: `id_${uuid()}`, email: '', isPrimary: true }],
  );
  const isEmailRequired = !isEmpty(emails) && !isNil(emails[0].email);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const showModal = useSelector(showDeleteEmailModalSel);
  const isDeleting = useSelector(isLoadingDeleteEmailModalSel);

  useEffect(() => {
    if (emails.length) {
      setFormEmails(clone(emails));
    }
  }, [emails]);

  const onChangePrimary = emailId => () => {
    const formEmails = getFieldValue('emails');
    const [key, emailValues] = Object.entries(formEmails).find(
      ([, value]) => value.isPrimary && emailId !== value.id,
    );
    const cloneFormEmails = clone(formEmails);
    cloneFormEmails[key] = { ...emailValues, isPrimary: false };
    setFieldsValue({ emails: cloneFormEmails });
  };

  const shouldHideButton = emailId => {
    const emails = getFieldValue('emails');
    const email = emails[`id_${emailId}`];
    return email.isPrimary;
  };

  const isSubscribed = () => {
    const subsTopics = subscriptions.filter(
      ({ topicId }) =>
        topicId == subscriptionIds.SCOUTBOOK ||
        topicId == subscriptionIds.REMINDERS,
    );

    if (subsTopics.length === 2) {
      const optOut = subsTopics.some(({ optOutFlag }) => optOutFlag);

      return !optOut;
    }

    return false;
  };

  const onClickAddEmail = () => {
    const cloneFormEmails = clone(formEmails);
    cloneFormEmails.push({
      id: `id_${uuid()}`,
      email: '',
      isPrimary: false,
    });
    setFormEmails(cloneFormEmails);
  };

  const onClickDelete = emailId => () => {
    const indexOfEmail = formEmails.findIndex(email => email.id === emailId);
    const isNewEmail = emailId.startsWith('id_');

    if (isNewEmail && indexOfEmail !== -1) {
      const cloneFormEmails = clone(formEmails);
      cloneFormEmails.splice(indexOfEmail, 1);
      setFormEmails(cloneFormEmails);
    }

    if (!isNewEmail) {
      setIdToDelete(Number(emailId));
      dispatch(setShowDeleteEmailModal(true));
    }
  };

  const handleConfirmDelete = () => {
    dispatch(setIsLoadingDeleteEmailModal(true));
    onConfirmDelete(idToDelete);
  };

  const onCancelDelete = () => {
    if (!isDeleting) {
      dispatch(setShowDeleteEmailModal(false));
    }
  };

  return (
    <div>
      <div className={styles.subTitleContainer}>
        <T size="5" colored>
          <FormattedMessage id="shared.contactInfo.emailTitle" />
        </T>
      </div>
      {formEmails.map((email, index) => (
        <Row type="flex" align="middle" key={index}>
          <Col xs={24} lg={3}>
            <FormItem>
              {getFieldDecorator(`emails.id_${email.id}.isPrimary`, {
                initialValue: isValueTruthy(email.isPrimary),
              })(
                <Radio.Group onChange={onChangePrimary(email.id)}>
                  <Tooltip
                    placement="top"
                    trigger="focus"
                    title={
                      <FormattedMessage id="shared.contactInfo.primaryEmailHint" />
                    }
                  >
                    <Radio
                      value={true}
                      className={styles.radio}
                      disabled={isReadOnly}
                    >
                      <FormattedMessage id="shared.contactInfo.primary" />
                    </Radio>
                  </Tooltip>
                </Radio.Group>,
              )}
            </FormItem>
          </Col>
          <Col xs={23} lg={8}>
            <FormItem>
              {getFieldDecorator(`emails.id_${email.id}.email`, {
                initialValue: email.email,
                rules: [
                  {
                    type: 'email',
                    message: intl.formatMessage({
                      id: 'profile.ProfileInfo.contactInfo.fieldError.email.valid',
                    }),
                  },
                  {
                    required: isEmailRequired,
                    message: intl.formatMessage({
                      id: isYouth
                        ? 'profile.ProfileInfo.contactInfo.fieldError.emailYouth'
                        : 'profile.ProfileInfo.contactInfo.fieldError.email',
                    }),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder={`${intl.formatMessage({
                    id: 'shared.contactInfo.emailAddress',
                  })}${isEmailRequired ? '*' : ''}`}
                  rounded
                  floatingLabel
                  disabled={isReadOnly}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={1} lg={1}>
            <FormItem>
              <div
                className={cn(
                  styles.deleteEmailContainer,
                  (isValueTruthy(email.isPrimary) ||
                    shouldHideButton(email.id)) &&
                    styles.hideButton,
                )}
              >
                <Tooltip title="delete">
                  <Button
                    ghost
                    shape="circle"
                    className={styles.deleteButton}
                    onClick={onClickDelete(email.id)}
                  >
                    <CancelIcon className={styles.deleteIcon} />
                  </Button>
                </Tooltip>
              </div>
            </FormItem>
          </Col>
        </Row>
      ))}
      {!isUnregisteredUser && (
        <Row type="flex">
          <Col xs={24} lg={11} className={styles.addEmailContainer}>
            <Button
              ghost
              className={styles.addEmailBtn}
              onClick={onClickAddEmail}
              hidden={isReadOnly}
            >
              <FormattedMessage id="shared.contactInfo.addEmailAddress" />
              <AddCircleIcon className={styles.addIcon} />
            </Button>
          </Col>
        </Row>
      )}
      {
        // TODO: Remove condicional for SBL-3617
        false && (
          <Row>
            <Col xs={24}>
              <FormItem className={styles.notificationFormItem}>
                {getFieldDecorator('emailSubcriptions', {
                  initialValue: isSubscribed() ? ['True'] : [],
                })(
                  <Checkbox.Group className={styles.notificationContainer}>
                    <div className={styles.checkboxContainer}>
                      <Checkbox value="True" />
                    </div>
                    <S size="4" inline>
                      <FormattedMessage
                        id={'shared.contactInfo.wantNotifications'}
                      />
                    </S>
                  </Checkbox.Group>,
                )}
              </FormItem>
            </Col>
          </Row>
        )
      }
      <DeleteEmailModal
        visible={showModal}
        isLoading={isDeleting}
        onCancel={onCancelDelete}
        onAccept={handleConfirmDelete}
      />
    </div>
  );
};

EmailForm.propTypes = {
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isUnregisteredUser: PropTypes.bool,
  isYouth: PropTypes.bool,
  onConfirmDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};
