import moment from 'moment';
import { createSelector } from 'reselect';

import { isCurrentPageSel } from '@location';
import { addPersonsMissingInfo } from '@progress/common';
import { ROUTE_ACTIVITIES, activityTypeIds, packRosterItemsSel } from '@shared';

import { PERSONAL_ACTIVITY, UNIT_ACTIVITY, moduleName } from '../constants';

export const eventActivitiesSel = state => state[moduleName].events;

export const getActivitiesSel = createSelector(
  state => state[moduleName].activities,
  eventActivitiesSel,
  packRosterItemsSel,
  (activities, events, rosterItems) =>
    activities.map(activity => {
      const eventActivity =
        events.find(e => e.activityId === activity.id) || {};

      return {
        ...activity,
        ...eventActivity,
        registeredYouths: addPersonsMissingInfo(
          activity.registeredYouths,
          rosterItems,
        ),
        registeredAdults: addPersonsMissingInfo(
          activity.registeredAdults,
          rosterItems,
        ),
      };
    }),
);

export const activitiesDateSel = state => state[moduleName].activitiesDate;

export const isActivitiesLoadingSel = state =>
  state[moduleName].isActivitiesLoading;

export const isEditActivitiesLoadingSel = state =>
  state[moduleName].isEditActivitiesLoading;

export const isEventsLoadingSel = state => state[moduleName].isEventsLoading;

export const activityFiltersSel = state => state[moduleName].activityFilters;

export const filterActivityTextSel = state =>
  state[moduleName].filterActivityText;

export const filteredActivitiesSel = createSelector(
  getActivitiesSel,
  activityFiltersSel,
  filterActivityTextSel,
  (activities, activityFilters, filterText) =>
    activities.filter(({ activityTypeId, isPersonalActivity, name }) => {
      const isUnit = isPersonalActivity === false;
      const isPersonal = isPersonalActivity === true;
      const showUnitActivity = activityFilters[UNIT_ACTIVITY] && isUnit;
      const showPersonalActivity =
        activityFilters[PERSONAL_ACTIVITY] && isPersonal;
      const filterTextContains = filterText
        ? name.toLowerCase().includes(filterText.toLowerCase())
        : true;
      return (
        activityFilters[activityTypeId] &&
        (showUnitActivity || showPersonalActivity) &&
        filterTextContains
      );
    }),
);

export const monthActivitiesSel = createSelector(
  activitiesDateSel,
  filteredActivitiesSel,
  (selectedDate, activities) => {
    if (selectedDate) {
      const activeMonth = selectedDate.month();
      const activeYear = selectedDate.year();
      return activities
        .filter(
          activity => moment(activity.startDateTime).year() === activeYear,
        )
        .filter(
          activity => moment(activity.startDateTime).month() === activeMonth,
        )
        .sort(
          (a, b) =>
            moment(a.startDateTime).format('YYYYMMDD') -
            moment(b.startDateTime).format('YYYYMMDD'),
        );
    } else {
      return [];
    }
  },
);

export const totalItemsSel = createSelector(
  monthActivitiesSel,
  monthActivities => monthActivities.length,
);

export const isActivitiesRouteSel = state =>
  isCurrentPageSel(state, ROUTE_ACTIVITIES);

export const itemsPerPageSel = state => state[moduleName].itemsPerPage;

export const currentActivityPageSel = state =>
  state[moduleName].currentActivityPage;

export const paginatedMonthActivitiesSel = createSelector(
  monthActivitiesSel,
  currentActivityPageSel,
  itemsPerPageSel,
  (monthActivities, currentActivityPage, itemsPerPage) => {
    const fromIndex = (currentActivityPage - 1) * itemsPerPage;
    const toIndex = fromIndex + itemsPerPage;
    return monthActivities
      .filter(
        activity =>
          activity.activityTypeId !== activityTypeIds.EAGLE_SERVICE_PROJECT,
      )
      .slice(fromIndex, toIndex);
  },
);
