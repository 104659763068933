import { personNameBuilder, webscriptApiService } from '@utils';

const mapReportsDtoToModel = ({ Reports }) =>
  Reports.map(item => ({
    ...item,
    recordedBy: personNameBuilder.short({
      firstName: item.FirstName,
      lastName: item.LastName,
    }),
    DateCreated: (item.DateCreated || '').replace(/\//g, '-'),
  }));

/**
 * Error codes:
  -1 - Empty OrganizationGUID
  -2 - Failed to get UnitID from Scoutbook
  -2 - Failed to load PO list
  -3 - Failed to generate output
  -4 - Failed to get UnitID from Akela
  -5 - UnitID is empty
  -6 - unit not found
  -7 - more than one unit found
 */
const getReportsInfo$ = organizationGuid =>
  webscriptApiService
    .get$(
      `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLite&ScriptCode=GetPOListByDate&OrganizationGUID=${organizationGuid}`,
      {
        gtm: {
          label:
            '/WebScript/GetPOListByDate&OrganizationGUID={organizationGuid}',
        },
      },
    )
    .map(mapReportsDtoToModel);

export default { getReportsInfo$ };
