export const moduleName = 'counseledYouth';

export const counseledYouthTabNames = Object.freeze({
  COUNSELED_YOUTH: moduleName,
  PENDING_ITEMS: 'pendingItems',
});

export const badgeStatus = Object.freeze({
  STARTED: 'Started',
  COMPLETED: 'Completed',
});

export const MAX_SELECTED_YOUTH = 10;

export const meritBadgesType = Object.freeze({
  started: 'startedMeritBadges',
  pending: 'pendingMeritBadges',
});
