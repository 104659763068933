import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Card } from '@shared/components';

import styles from './SingleReport.less';

const SingleReportMobile = ({ name, description, openLabel, onClick }) => (
  <Card className={styles.singleReport}>
    <div className={styles.dataContainer}>
      <div className={styles.name}>{name}</div>
      <div className={styles.description}> {description} </div>
    </div>
    <div className={styles.url}>
      <Button noPadding noBorder color="info" size="large" onClick={onClick}>
        {openLabel ? openLabel : <FormattedMessage id="shared.view" />}
      </Button>
    </div>
  </Card>
);

SingleReportMobile.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  openLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default SingleReportMobile;
