import React, { useCallback, useRef, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, SingleConfirmationModal, Tooltip } from '@shared';

import { deleteActivityRequest, deleteActivityLoadingSel } from '../../duck';

const DeleteActivityButton = ({
  id,
  askForConfirmation,
  children,
  showTooltip,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(deleteActivityLoadingSel);
  const confirmModalRef = useRef();

  const handleSuccess = useCallback(() => {
    confirmModalRef.current.closeConfirmationModal();
  }, []);

  const onDeleteActivity = useCallback(() => {
    dispatch(
      deleteActivityRequest({
        activityId: id,
        callbackSuccess: handleSuccess,
      }),
    );
  }, [dispatch, id, handleSuccess]);

  const handleDeleteActivity = useCallback(() => {
    if (askForConfirmation) {
      confirmModalRef.current.openConfirmationModal();
    } else {
      onDeleteActivity();
    }
  }, [askForConfirmation, onDeleteActivity]);

  const button = useMemo(
    () => (
      <Button {...rest} onClick={handleDeleteActivity}>
        {children || <FormattedMessage id="shared.delete" />}
      </Button>
    ),
    [rest, handleDeleteActivity, children],
  );

  const getDeleteButton = useCallback(
    children =>
      showTooltip ? (
        <Tooltip title={<FormattedMessage id="shared.delete" />}>
          {children}
        </Tooltip>
      ) : (
        children
      ),
    [showTooltip],
  );

  return (
    <React.Fragment>
      {askForConfirmation && (
        <SingleConfirmationModal
          ref={confirmModalRef}
          loading={isLoading}
          confirmBtnText={<FormattedMessage id="shared.confirm" />}
          message={
            <FormattedMessage id="progress.common.DeleteActivityButton.message" />
          }
          onConfirm={onDeleteActivity}
          closeOnConfirm={false}
        />
      )}
      {getDeleteButton(button)}
    </React.Fragment>
  );
};

DeleteActivityButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  askForConfirmation: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

DeleteActivityButton.defaultProps = {
  showTooltip: true,
};

export default DeleteActivityButton;
