import { lazier } from '@shared';

export const ActivityLogs = lazier(
  // eslint-disable-next-line import/no-cycle
  () => import('./ActivityLogs'),
  false,
);

export { default as ActivityActionsMenu } from './ActivityActionsMenu';

export { default as CreateActivityForm } from './CreateActivityForm';
