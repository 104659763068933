import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { TimePicker as BsaTimePicker } from 'bsa-ui';

import { timeFormat } from '../../constants';
import styles from './TimePicker.less';

class TimePicker extends React.Component {
  render() {
    const {
      fluid,
      rounded,
      floatingLabel,
      hideIcon,
      className,
      use12Hours,
      format,
      ...props
    } = this.props;

    const classNames = cn(
      {
        [styles.fluid]: fluid,
        [styles.rounded]: rounded,
        [styles.hideIcon]: hideIcon,
        [styles.floatingLabelInput]: floatingLabel && !!props.value,
      },
      className,
    );

    const pickerNode = (
      <BsaTimePicker
        className={classNames}
        use12Hours={use12Hours}
        format={format}
        {...props}
      />
    );

    return floatingLabel ? (
      <div className={styles.floatingLabelWrap}>
        {props.value && (
          <label className={styles.floatingLabel}>{props.placeholder}</label>
        )}
        {pickerNode}
      </div>
    ) : (
      pickerNode
    );
  }
}

TimePicker.propTypes = {
  fluid: PropTypes.bool,
  className: PropTypes.string,
  use12Hours: PropTypes.bool,
  format: PropTypes.string,
  rounded: PropTypes.bool,
  hideIcon: PropTypes.bool,
  floatingLabel: PropTypes.bool,
};

TimePicker.defaultProps = {
  fluid: false,
  use12Hours: true,
  format: timeFormat,
};

export default TimePicker;
