import httpErrorToast from './httpErrorToast';

const errorHandler = ({ xhr }, { suppressErrorToasts }) => {
  const { response, status, statusText } = xhr;
  const { message } = response || {};
  const content = message || statusText;

  if (!suppressErrorToasts) {
    return httpErrorToast({
      status,
      content,
    });
  }
};

export default errorHandler;
