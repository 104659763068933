import React, { useEffect, useMemo } from 'react';
import Link from 'redux-first-router-link';
import { useDispatch, useSelector } from 'react-redux';
import { authRedirectRequest } from '../../duck/actions';
import { Spin, T } from '../../../shared/components';
import { push } from 'redux-first-router';
import styles from './AuthRedirect.less';
import { ROUTE_LOGIN } from '../../duck';

function AuthRedirect() {
  const dispatch = useDispatch();

  const data = useMemo(() => {
    const params = new URLSearchParams(document.location.search);
    const sessionToken = params.get('sessionToken');
    const redirectPath = params.get('redirectPath');
    return { sessionToken, redirectPath };
  }, []);

  useEffect(() => {
    dispatch(authRedirectRequest(data));
  }, [dispatch, data.sessionToken, data.redirectPath, data]);

  const hasOrgSetup = useSelector(state => state.context.parentOrgGuid);

  const isAuthLoading = useSelector(state => state.user.authRedirectLoading);
  const hasError = useSelector(state => state.user.authRedirectError);

  useEffect(() => {
    if (isAuthLoading || !hasOrgSetup) return;

    push(data.redirectPath);
  }, [isAuthLoading, data.redirectPath, hasOrgSetup]);

  if (hasError) {
    dispatch({ type: ROUTE_LOGIN });
    return null;
  }

  return (
    <div className={styles.loadingContainer}>
      <Spin size="large" spinning />
      <T size="5" semibold>
        Working on it...
      </T>
      <T size="5" semibold>
        Wait a few seconds or <Link to={data.redirectPath}>click here.</Link>
      </T>
    </div>
  );
}

export default AuthRedirect;
