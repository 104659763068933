import {
  FetchEventsPayload,
  FetchEventsResponse,
  UpdateCalendarEventRequest,
} from '@appTypes/esb';
import { tagTypes } from '@modules/rtk/constants';
import { esbApi } from '@modules/rtk/esb-api';

export const eventsAPI = esbApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * @esbEndpoint POST /advancements/events
     */
    getCalendarEvents: builder.query<
      FetchEventsResponse,
      {
        payload: FetchEventsPayload;
      }
    >({
      query: arg => ({
        url: `/advancements/events`,
        method: 'POST',
        body: arg.payload,
      }),
      providesTags: (result, _error, arg) =>
        result ? [{ type: tagTypes.UnitEvents, id: arg.payload.unitId }] : [],
    }),

    /**
     * @esbEndpoint PUT /advancements/events/:eventId
     */
    updateCalendarEvent: builder.mutation<
      void,
      {
        eventId: number;
        unitId?: number;
        body: UpdateCalendarEventRequest;
      }
    >({
      query: arg => ({
        url: `/advancements/events/${arg.eventId}`,
        method: 'PUT',
        body: arg.body,
      }),
      invalidatesTags: (_result, error, arg) => {
        if (error) {
          return [];
        }
        const tags: Array<{
          id: string | number;
          type: typeof tagTypes.UnitEvents | typeof tagTypes.Event;
        }> = [{ type: tagTypes.Event, id: arg.eventId }];

        if (arg.unitId) {
          tags.push({ type: tagTypes.UnitEvents, id: arg.unitId });
        }

        return tags;
      },
    }),
  }),
});

export const {
  useGetCalendarEventsQuery,
  useLazyGetCalendarEventsQuery,
  useUpdateCalendarEventMutation,
} = eventsAPI;
