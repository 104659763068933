import { personGuidSel } from '@shared';
import { requestStorage } from '@utils';
import { moduleName } from '../constants';

export {
  SET_OFFLINE,
  SET_MANUAL_OFFLINE,
  setOffline,
  SET_PENDING_REQUESTS,
  setPendingRequests,
  updateRequests,
  UPDATE_REQUESTS,
} from '@shared';

export const TEST_REQUEST = `${moduleName}/TEST_REQUEST`;
export const TEST_RESPONSE = `${moduleName}/TEST_RESPONSE`;
export const TEST_DEFERRED = `${moduleName}/TEST_DEFERRED`;
export const TEST_ERROR = `${moduleName}/TEST_ERROR`;
export const testRequest = () => ({ type: TEST_REQUEST });
export const testResponse = () => ({ type: TEST_RESPONSE });
export const testDeferred = () => ({ type: TEST_DEFERRED });
export const testError = err => ({ type: TEST_ERROR, payload: err });

export const CANCEL_PENDING_REQUESTS = `${moduleName}/CANCEL_PENDING_REQUESTS`;
export const cancelPendingRequests = () => ({
  type: CANCEL_PENDING_REQUESTS,
});

export const OPEN_LOGBACKIN_MODAL = `${moduleName}/OPEN_LOGBACKIN_MODAL`;
export const CLOSE_LOGBACKIN_MODAL = `${moduleName}/CLOSE_LOGBACKIN_MODAL`;
export const openLogBackInModal = () => ({ type: OPEN_LOGBACKIN_MODAL });
export const closeLogBackInModal = () => ({ type: CLOSE_LOGBACKIN_MODAL });

export const OPEN_SYNC_MODAL = `${moduleName}/OPEN_SYNC_MODAL`;
export const CLOSE_SYNC_MODAL = `${moduleName}/CLOSE_SYNC_MODAL`;
export const openSyncModal = () => ({ type: OPEN_SYNC_MODAL });
export const closeSyncModal = () => ({ type: CLOSE_SYNC_MODAL });

export const SYNC_ALL = `${moduleName}/SYNC_ALL`;
export const syncAll = () => ({ type: SYNC_ALL });
export const SYNC_ITEM = `${moduleName}/SYNC_ADVANCEMENT`;
export const syncItem = itemId => ({ type: SYNC_ITEM, payload: itemId });
export const SYNC_COMPLETED = `${moduleName}/SYNC_COMPLETED`;
export const syncCompleted = () => ({ type: SYNC_COMPLETED });
export const SET_FAILED_ITEMS = `${moduleName}/SET_FAILED_ITEMS`;
export const SET_FAILED_ITEM = `${moduleName}/SET_FAILED_ITEM`;
export const setFailedItems = failedIds => ({
  type: SET_FAILED_ITEMS,
  payload: failedIds,
});
export const setFailedItem = failedId => ({
  type: SET_FAILED_ITEM,
  payload: failedId,
});

export const SELECT_YOUTH = `${moduleName}/SELECT_YOUTH`;
export const selectYouth = ({ personId }) => ({
  type: SELECT_YOUTH,
  payload: personId,
});

export const REMOVE_ITEM = `${moduleName}/REMOVE_ITEM`;
export const removeItem = itemId => async (dispatch, getState) => {
  const personGuid = personGuidSel(getState());
  const requests = (await requestStorage.getRequests(personGuid)).filter(
    ({ id }) => id != itemId,
  );
  await requestStorage.setRequests(personGuid, requests);

  return dispatch({ type: REMOVE_ITEM });
};

export const EDIT_ITEM = `${moduleName}/EDIT_ITEM`;
export const editItem =
  ({ itemId, date }) =>
  async (dispatch, getState) => {
    const personGuid = personGuidSel(getState());
    const requests = (await requestStorage.getRequests(personGuid)).map(item =>
      item.id == itemId ? { ...item, date } : item,
    );
    await requestStorage.setRequests(personGuid, requests);

    return dispatch({ type: EDIT_ITEM });
  };

export const VERIFY_PENDING_ADVANCEMENTS_REQUEST = `${moduleName}/VERIFY_PENDING_ADVANCEMENTS_REQUEST`;
export const VERIFY_PENDING_ADVANCEMENTS_RESPONSE = `${moduleName}/VERIFY_PENDING_ADVANCEMENTS_RESPONSE`;
export const VERIFY_PENDING_ADVANCEMENTS_ERROR = `${moduleName}/VERIFY_PENDING_ADVANCEMENTS_ERROR`;
export const verifyPendingAdvancementsRequest = () => ({
  type: VERIFY_PENDING_ADVANCEMENTS_REQUEST,
});
export const verifyPendingAdvancementsResponse = response => ({
  type: VERIFY_PENDING_ADVANCEMENTS_RESPONSE,
  payload: response,
});
export const verifyPendingAdvancementsError = () => ({
  type: VERIFY_PENDING_ADVANCEMENTS_ERROR,
});
