import qs from 'qs';
import { NOT_FOUND, redirect } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';

import { ROUTE_HOME } from '@shared/duck/actions';

import { setSidebarOpen, setUpdatingApp } from '../modules/core/duck/actions';
import {
  sidebarOpenSel,
  updateAppSel,
  updatingAppSel,
} from '../modules/core/duck/selectors';
import { routesMapSel } from '../modules/location/duck/selectors';
import {
  ROUTE_LOGIN,
  hasPermissionSel,
  isLoggedInSel,
  loginDataInitializedSel,
  userDataInitializedSel,
} from '../modules/user';

const checkAccess = (dispatch, state, action) => {
  const isLoggedIn = isLoggedInSel(state);
  const loginDataInitialized = loginDataInitializedSel(state);
  const routesMap = routesMapSel(state);
  const actionType = action.type;
  const route = routesMap[actionType];

  if (!loginDataInitialized) {
    return;
  }

  let allowed = false;
  if (route.unauthorizedAccess) {
    allowed = true;
  } else if (route.permission) {
    allowed =
      isLoggedIn &&
      (hasPermissionSel(state, route.permission) ||
        !userDataInitializedSel(state));
  } else {
    allowed = isLoggedIn;
  }

  if (!allowed) {
    if (isLoggedIn) {
      return dispatch(
        redirect({
          ...action,
          type: NOT_FOUND,
          payload: { code: 403 },
        }),
      );
    } else {
      return dispatch(redirect({ type: ROUTE_LOGIN }));
    }
  }

  if (actionType === ROUTE_LOGIN && isLoggedIn) {
    return dispatch(
      redirect({
        type: ROUTE_HOME,
      }),
    );
  }
};

const addReloadMessageListener = () => {
  // When the SW asks to refresh the UI, we'll need to reload the window
  navigator.serviceWorker.addEventListener('message', event => {
    if (event.data.type === 'reload-window') {
      window.location.reload();
    }
  });
};

const triggerAppUpdate = async () => {
  // make sure the page gets reloaded, even if sw fails to notify the app of successful activation (for any reason) - seems to be happening on FF
  window.setTimeout(() => {
    window.location.reload();
  }, 15000);
  const registration = await navigator.serviceWorker.getRegistration();
  registration.waiting.postMessage({ type: 'force-activate' });
  addReloadMessageListener();
};

const checkAppUpdate = (state, dispatch) => {
  const shouldUpdateApp = updateAppSel(state) && !updatingAppSel(state);
  if (shouldUpdateApp) {
    triggerAppUpdate();
    dispatch(setUpdatingApp());
  }
};

const options = {
  onBeforeChange: (dispatch, getState, { action /*, extra*/ }) => {
    const state = getState();
    checkAppUpdate(state, dispatch);
    const sidebarOpen = sidebarOpenSel(state);
    if (sidebarOpen) {
      dispatch(setSidebarOpen(false));
    }
    checkAccess(dispatch, state, action);
  },
  querySerializer: qs,
  restoreScroll: restoreScroll(),
};

export default options;
