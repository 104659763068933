import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { isMobileSel } from '../../duck';
import S from '../S';
import styles from './PaginationTotal.less';

class PaginationTotal extends React.PureComponent {
  renderItemsText = () => (
    <span className={styles.itemsText}>
      {this.props.itemsText || (
        <FormattedMessage id="shared.PaginationTotal.items" />
      )}
    </span>
  );

  renderTotal = () => {
    const { isMobile, total, selected } = this.props;

    return (
      <React.Fragment>
        <S size="5" inline className={styles.totalText}>
          <FormattedMessage id="shared.PaginationTotal.total" />
        </S>{' '}
        <S size="5" inline bold>
          {total} {this.renderItemsText()}
        </S>
        {!isMobile && (
          <S size="5" inline bold className={styles.selectedCount}>
            ({selected}{' '}
            <FormattedMessage id="shared.PaginationTotal.selected" />)
          </S>
        )}
      </React.Fragment>
    );
  };

  renderSelected = () => {
    const { isMobile, total, selected } = this.props;

    return isMobile ? (
      <React.Fragment>
        <S size="5" inline bold>
          <FormattedMessage
            id="shared.PaginationTotal.selectedOfTotal"
            values={{
              total,
              selected,
            }}
          />
        </S>{' '}
        <S size="5" inline>
          {this.renderItemsText()}
          <FormattedMessage id="shared.PaginationTotal.selected" />
        </S>
      </React.Fragment>
    ) : (
      this.renderTotal()
    );
  };

  render() {
    const { className, selected } = this.props;
    const classNames = cn(styles.container, className, {
      [styles.selected]: selected,
    });

    return (
      <div className={classNames}>
        {selected ? this.renderSelected() : this.renderTotal()}
      </div>
    );
  }
}

PaginationTotal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  itemsText: PropTypes.node,
  selected: PropTypes.number,
  className: PropTypes.string,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
});

export default connect(mapState)(PaginationTotal);
