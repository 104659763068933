import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import type { MyScoutItem } from '@appTypes/esb';
import { normalizeUnitGender } from '@modules/shared';
import { getPreferredName } from '@modules/shared/utils/preferredName';
import type { UserOrganizationInfo } from '@modules/user/duck/types';
import { programForUnitType } from '@shared/constants';
import type { UnitTypeName } from '@shared/constants';

import type { ChildrenOrg } from './duck/types';

export const removeParentGuardiansFromOrganizations = (
  orgs: UserOrganizationInfo[],
) => orgs.filter(({ isParentGuardian }) => !isParentGuardian);

export const removeEmptyOrganizations = (
  organizations: UserOrganizationInfo[],
) => {
  const keys: Array<keyof UserOrganizationInfo> = [
    'organizationGuid',
    'organizationName',
    'personGuid',
  ];

  const filtered = organizations.filter(orgInfo => {
    const minimumRequiredFields = keys.map(key => orgInfo[key]);

    if (minimumRequiredFields.every(isEmpty)) return false;

    return true;
  });

  return filtered;
};

export function getOrganizationsFromChildren(params: {
  myScout: MyScoutItem[];
}): Array<ChildrenOrg> {
  if (!params.myScout) return [];

  const result = uniqBy(
    params.myScout.map(item => {
      const childrenOrg: ChildrenOrg = {
        ...item,
        userId: Number(item.userId),
        isParentGuardian: true,
        connection: getPreferredName(item),
        key: `${item.orgGuid}-${item.position}-${item.userId}`,
        organizationGuid: `${item.orgGuid}*${item.userId}`,
        acceptGender: item.acceptGender,
        unitGender: normalizeUnitGender(item.acceptGender),
        organizationName: `${item.unitType} ${item.unitNumber}`,
        programType: item.program,
        program: programForUnitType[item.unitType as UnitTypeName],
        programId: item.programId,
        unitTypeId: item.unitTypeId,
      };

      return childrenOrg;
    }),
    'organizationGuid',
  );

  return result;
}
