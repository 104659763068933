import { SET_ORGANIZATION } from '@context';

import { resetOnLogoutReducer } from '@utils';
import get from 'lodash/get';
import { combineReducers } from 'redux';
import { paymentMethods, stepOrder, nonUnit } from '../constants';
import {
  ADD_BACK_MEMBERS_REQUEST,
  ADD_MEMBER_ERROR,
  ADD_MEMBER_REQUEST,
  ADD_MEMBER_RESPONSE,
  CLOSE_ADD_BACK_MEMBERS_MODAL,
  CLOSE_ADD_MEMBERS_MODAL,
  CLOSE_CONFIRMATION_PAYMENT_MODAL,
  CLOSE_PAYMENT_MODAL,
  CLOSE_REMOVE_MEMBERS_MODAL,
  CLOSE_RESEND_INVITE_MODAL,
  CLOSE_UNIT_PAYMENT_ERROR_MODAL,
  CLOSE_UPDATE_POSITION_MODAL,
  CLOSE_UPLOAD_DOCUMENT_MODAL,
  CLOSE_VALIDATION_MODAL,
  CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_ERROR,
  CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST,
  CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE,
  GENERATE_RECHARTER_PDF_ERROR,
  GENERATE_RECHARTER_PDF_REQUEST,
  GENERATE_RECHARTER_PDF_RESPONSE,
  GET_COUNCILS_ERROR,
  GET_COUNCILS_RESPONSE,
  GET_FUTURE_CHARTER_ERROR,
  GET_FUTURE_CHARTER_REQUEST,
  GET_FUTURE_CHARTER_RESPONSE,
  GET_UNIT_PAYMENT_SUMMARY_ERROR,
  GET_UNIT_PAYMENT_SUMMARY_REQUEST,
  GET_UNIT_PAYMENT_SUMMARY_RESPONSE,
  GET_UNIT_POSITIONS_RESPONSE,
  GET_UNIT_BASIC_REQUIREMENTS_RESPONSE,
  OPEN_ADD_BACK_MEMBERS_MODAL,
  OPEN_ADD_MEMBERS_MODAL,
  OPEN_CONFIRMATION_PAYMENT_MODAL,
  OPEN_PAYMENT_MODAL,
  OPEN_REMOVE_MEMBERS_MODAL,
  OPEN_RESEND_INVITE_MODAL,
  OPEN_UNIT_PAYMENT_ERROR_MODAL,
  OPEN_UPDATE_POSITION_MODAL,
  OPEN_UPLOAD_DOCUMENT_MODAL,
  OPEN_VALIDATION_MODAL,
  PAYMENT_PROCESS_ERROR,
  PAYMENT_PROCESS_REQUEST,
  PAYMENT_PROCESS_RESPONSE,
  ACH_VERIFICATION_REQUEST,
  ACH_VERIFICATION_RESPONSE,
  ACH_VERIFICATION_ERROR,
  RECHARTER_BATCH_INFO_ERROR,
  RECHARTER_BATCH_INFO_REQUEST,
  RECHARTER_BATCH_INFO_RESPONSE,
  RECHARTER_ROSTER_ERROR,
  RECHARTER_ROSTER_REQUEST,
  RECHARTER_ROSTER_RESPONSE,
  RECHARTER_VALIDATE_ROSTER_ERROR,
  RECHARTER_VALIDATE_ROSTER_REQUEST,
  RECHARTER_VALIDATE_ROSTER_RESPONSE,
  REMOVE_MEMBERS_REQUEST,
  REMOVE_PENDING_SELECTED_KEY,
  REMOVE_RECHARTER_ROSTER_SELECTED_KEY,
  SEND_REMINDERS_ERROR,
  SEND_REMINDERS_REQUEST,
  SEND_REMINDERS_RESPONSE,
  SET_CURRENT_STEP,
  SET_MEMBER_POSITION_TO_UPDATE,
  SET_PENDING_SELECTED_KEYS,
  SET_RECHARTER_ROSTER_FILTERS,
  SET_RECHARTER_ROSTER_SEARCH,
  SET_RECHARTER_ROSTER_SELECTED_KEYS,
  SET_REMOVED_MEMBERS_SELECTED_KEYS,
  SET_SELECTED_PAYMENT_METHOD,
  UPDATE_POSITION_ERROR,
  UPDATE_POSITION_REQUEST,
  UPDATE_POSITION_RESPONSE,
  UPLOAD_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_RESPONSE,
  WEPAY_PROCESS_ERROR,
  WEPAY_PROCESS_REQUEST,
  WEPAY_PROCESS_RESPONSE,
  OPEN_MARK_AS_MULTIPLE_MODAL,
  CLOSE_MARK_AS_MULTIPLE_MODAL,
  OPEN_UNMARK_AS_MULTIPLE_MODAL,
  CLOSE_UNMARK_AS_MULTIPLE_MODAL,
  GET_COUNCILS_REQUEST,
  SET_SELECTED_COUNCIL,
  GET_COUNCIL_UNITS_REQUEST,
  GET_COUNCIL_UNITS_RESPONSE,
  GET_COUNCIL_UNITS_ERROR,
  SET_COUNCIL_UNIT_TYPE,
  SET_COUNCIL_UNIT,
  GET_ORG_ADULTS_RESPONSE,
  GET_ORG_ADULTS_ERROR,
  MARK_AS_MULTIPLE_REQUEST,
  MARK_AS_MULTIPLE_RESPONSE,
  MARK_AS_MULTIPLE_ERROR,
  UNMARK_AS_MULTIPLE_REQUEST,
  UNMARK_AS_MULTIPLE_RESPONSE,
  UNMARK_AS_MULTIPLE_ERROR,
  CLOSE_INVITE_MEMBERS_MODAL,
  INVITE_MEMBERS_ERROR,
  INVITE_MEMBERS_REQUEST,
  INVITE_MEMBERS_RESPONSE,
  OPEN_INVITE_MEMBERS_MODAL,
  BATCHES_REQUEST,
  BATCHES_RESPONSE,
  BATCHES_ERROR,
  UNIT_ROSTER_SORT,
  UNIT_ROSTER_PENDING_SORT,
  UNIT_ROSTER_REMOVED_SORT,
  GET_ADJUSTED_OLR_SETTINGS_REQUEST,
  GET_ADJUSTED_OLR_SETTINGS_RESPONSE,
  GET_ADJUSTED_OLR_SETTINGS_ERROR,
  OPEN_PRIVACY_MODAL,
  CLOSE_PRIVACY_MODAL,
  OPEN_SELECT_ADD_MEMBERS_FLOW_MODAL,
  CLOSE_SELECT_ADD_MEMBERS_FLOW_MODAL,
  OPEN_ADOBE_ERROR_MODAL,
  CLOSE_ADOBE_ERROR_MODAL,
  OPEN_EDIT_TERM_MODAL,
  CLOSE_EDIT_TERM_MODAL,
  OPEN_ACH_VERIFICATION_MODAL,
  CLOSE_ACH_VERIFICATION_MODAL,
  EDIT_TERM_REQUEST,
  EDIT_TERM_RESPONSE,
  EDIT_TERM_ERROR,
  SET_SELECTED_TERM,
  SET_SHOW_TERM_SELECTOR,
  SUBMIT_RECHARTER_REQUEST,
  SUBMIT_RECHARTER_RESPONSE,
  SUBMIT_RECHARTER_ERROR,
  GET_PAPERWORK_LINK_RESPONSE,
} from './actions';

const currentStep = (state = stepOrder[0], { type, payload }) => {
  switch (type) {
    case SET_CURRENT_STEP: {
      return payload;
    }
    case RECHARTER_BATCH_INFO_REQUEST:
    case SET_ORGANIZATION: {
      return stepOrder[0];
    }
    default: {
      return state;
    }
  }
};

const isUpdatePositionModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_UPDATE_POSITION_MODAL: {
      return true;
    }
    case CLOSE_UPDATE_POSITION_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAddMembersModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADD_MEMBERS_MODAL: {
      return true;
    }
    case CLOSE_ADD_MEMBERS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isSelectAddMembersFlowModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_SELECT_ADD_MEMBERS_FLOW_MODAL: {
      return true;
    }
    case OPEN_ADD_MEMBERS_MODAL:
    case CLOSE_SELECT_ADD_MEMBERS_FLOW_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isNewMemberApplication = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_ADD_MEMBERS_MODAL: {
      return payload;
    }
    case CLOSE_ADD_MEMBERS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isValidationModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_VALIDATION_MODAL: {
      return true;
    }
    case CLOSE_VALIDATION_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPrivacyModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_PRIVACY_MODAL: {
      return true;
    }
    case CLOSE_PRIVACY_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUnitPaymentErrorModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_UNIT_PAYMENT_ERROR_MODAL:
    case GET_UNIT_PAYMENT_SUMMARY_ERROR: {
      return true;
    }
    case CLOSE_UNIT_PAYMENT_ERROR_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPaymentModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_PAYMENT_MODAL: {
      return true;
    }
    case CLOSE_PAYMENT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUploadDocumentModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_UPLOAD_DOCUMENT_MODAL: {
      return true;
    }
    case CLOSE_UPLOAD_DOCUMENT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isConfirmationPaymentModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_CONFIRMATION_PAYMENT_MODAL: {
      return true;
    }
    case PAYMENT_PROCESS_REQUEST:
    case CLOSE_CONFIRMATION_PAYMENT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const confirmationModalInfo = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_CONFIRMATION_PAYMENT_MODAL: {
      return payload;
    }
    case CLOSE_CONFIRMATION_PAYMENT_MODAL: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const isResendInviteModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_RESEND_INVITE_MODAL: {
      return true;
    }
    case SEND_REMINDERS_RESPONSE:
    case CLOSE_RESEND_INVITE_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditTermModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_TERM_MODAL: {
      return true;
    }
    case CLOSE_EDIT_TERM_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAchVerificationModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_ACH_VERIFICATION_MODAL: {
      return true;
    }
    case CLOSE_ACH_VERIFICATION_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

//
// unit roster
//
const recharterRoster = (state = [], { type, payload }) => {
  switch (type) {
    case RECHARTER_ROSTER_REQUEST: {
      return [];
    }
    case RECHARTER_ROSTER_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingRecharterRoster = (state = false, { type }) => {
  switch (type) {
    case SEND_REMINDERS_RESPONSE:
    case RECHARTER_ROSTER_REQUEST: {
      return true;
    }
    case RECHARTER_ROSTER_RESPONSE:
    case RECHARTER_ROSTER_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const recharterRosterSelectedKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_RECHARTER_ROSTER_SELECTED_KEYS: {
      return payload;
    }
    case REMOVE_RECHARTER_ROSTER_SELECTED_KEY: {
      return state.filter(key => key !== payload);
    }
    case REMOVE_MEMBERS_REQUEST: {
      return state.filter(key => !payload.includes(key));
    }
    case RECHARTER_ROSTER_RESPONSE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const removedMembersSelectedKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_REMOVED_MEMBERS_SELECTED_KEYS: {
      return payload;
    }
    case ADD_BACK_MEMBERS_REQUEST: {
      return state.filter(key => !payload.includes(key));
    }
    case RECHARTER_ROSTER_RESPONSE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const pendingMembersSelectedKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_PENDING_SELECTED_KEYS: {
      return payload;
    }
    case REMOVE_PENDING_SELECTED_KEY: {
      return state.filter(key => key !== payload);
    }
    case RECHARTER_ROSTER_RESPONSE: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const recharterRosterSearch = (state = '', { type, payload }) => {
  switch (type) {
    case SET_RECHARTER_ROSTER_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const recharterRosterValidation = (state = [], { type, payload }) => {
  switch (type) {
    case RECHARTER_VALIDATE_ROSTER_RESPONSE: {
      return payload;
    }
    case RECHARTER_VALIDATE_ROSTER_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const recharterValidationLoading = (state = false, { type }) => {
  switch (type) {
    case RECHARTER_VALIDATE_ROSTER_REQUEST: {
      return true;
    }
    case RECHARTER_VALIDATE_ROSTER_RESPONSE:
    case RECHARTER_VALIDATE_ROSTER_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const defaultRecharterRosterFilters = {
  isNotYpt: true,
  isYpt: true,
  isNotCbc: true,
  isCbc: true,
  isNotScoutsLife: true,
  isScoutsLife: true,
  youth: true,
  adults: true,
};
const recharterRosterFilters = (
  state = defaultRecharterRosterFilters,
  { type, payload },
) => {
  switch (type) {
    case SET_RECHARTER_ROSTER_FILTERS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isUploadDocumentLoading = (state = false, { type }) => {
  switch (type) {
    case UPLOAD_DOCUMENT_REQUEST: {
      return true;
    }
    case UPLOAD_DOCUMENT_ERROR:
    case UPLOAD_DOCUMENT_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAddMemberLoading = (state = false, { type }) => {
  switch (type) {
    case ADD_MEMBER_REQUEST: {
      return true;
    }
    case ADD_MEMBER_ERROR:
    case ADD_MEMBER_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatePositionLoading = (state = false, { type }) => {
  switch (type) {
    case UPDATE_POSITION_REQUEST: {
      return true;
    }
    case UPDATE_POSITION_ERROR:
    case UPDATE_POSITION_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const memberPositionToUpdate = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_MEMBER_POSITION_TO_UPDATE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const unitPositions = (state = [], { type, payload }) => {
  switch (type) {
    case GET_UNIT_POSITIONS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const unitBasicRequirements = (state = [], { type, payload }) => {
  switch (type) {
    case GET_UNIT_BASIC_REQUIREMENTS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingUnitPaymentSummary = (state = false, { type }) => {
  switch (type) {
    case GET_UNIT_PAYMENT_SUMMARY_REQUEST: {
      return true;
    }
    case GET_UNIT_PAYMENT_SUMMARY_RESPONSE:
    case GET_UNIT_PAYMENT_SUMMARY_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitPaymentSummary = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_UNIT_PAYMENT_SUMMARY_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isRemoveMembersModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_REMOVE_MEMBERS_MODAL: {
      return true;
    }
    case CLOSE_REMOVE_MEMBERS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAddToRecharterModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADD_BACK_MEMBERS_MODAL: {
      return true;
    }
    case CLOSE_ADD_BACK_MEMBERS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const batchInformation = (state = [], { type, payload }) => {
  switch (type) {
    case RECHARTER_BATCH_INFO_RESPONSE: {
      return payload;
    }
    case SET_ORGANIZATION:
    case RECHARTER_BATCH_INFO_REQUEST:
    case RECHARTER_BATCH_INFO_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const allBatches = (state = [], { type, payload }) => {
  switch (type) {
    case BATCHES_RESPONSE: {
      return payload;
    }
    case BATCHES_REQUEST:
    case BATCHES_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const isAllBatchesLoading = (state = false, { type }) => {
  switch (type) {
    case BATCHES_REQUEST: {
      return true;
    }
    case BATCHES_ERROR:
    case BATCHES_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const futureCharter = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_FUTURE_CHARTER_RESPONSE: {
      return payload && payload.length > 0 ? payload[0] : {};
    }
    default: {
      return state;
    }
  }
};

const futureCharterLoading = (state = false, { type }) => {
  switch (type) {
    case GET_FUTURE_CHARTER_REQUEST: {
      return true;
    }
    case GET_FUTURE_CHARTER_ERROR:
    case GET_FUTURE_CHARTER_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedPaymentMethod = (
  state = paymentMethods.CREDIT_CARD,
  { type, payload },
) => {
  switch (type) {
    case SET_SELECTED_PAYMENT_METHOD: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingPaymentProcess = (state = false, { type }) => {
  switch (type) {
    case PAYMENT_PROCESS_REQUEST: {
      return true;
    }
    case PAYMENT_PROCESS_RESPONSE:
    case PAYMENT_PROCESS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingAchVerification = (state = false, { type }) => {
  switch (type) {
    case ACH_VERIFICATION_REQUEST: {
      return true;
    }
    case ACH_VERIFICATION_RESPONSE:
    case ACH_VERIFICATION_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingSubmitRecharter = (state = false, { type }) => {
  switch (type) {
    case SUBMIT_RECHARTER_REQUEST: {
      return true;
    }
    case SUBMIT_RECHARTER_RESPONSE:
    case SUBMIT_RECHARTER_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingWepayProcess = (state = false, { type }) => {
  switch (type) {
    case WEPAY_PROCESS_REQUEST: {
      return true;
    }
    case WEPAY_PROCESS_RESPONSE:
    case WEPAY_PROCESS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingSendReminders = (state = false, { type }) => {
  switch (type) {
    case SEND_REMINDERS_REQUEST: {
      return true;
    }
    case SEND_REMINDERS_RESPONSE:
    case SEND_REMINDERS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isMarkAsMultipleModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_MARK_AS_MULTIPLE_MODAL: {
      return true;
    }
    case CLOSE_MARK_AS_MULTIPLE_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUnmarkAsMultipleModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_UNMARK_AS_MULTIPLE_MODAL: {
      return true;
    }
    case CLOSE_UNMARK_AS_MULTIPLE_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedCouncil = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SELECTED_COUNCIL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const councils = (state = [], { type, payload }) => {
  switch (type) {
    case GET_COUNCILS_RESPONSE: {
      return payload;
    }
    case GET_COUNCILS_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const isLoadingCouncils = (state = false, { type }) => {
  switch (type) {
    case GET_COUNCILS_REQUEST: {
      return true;
    }
    case GET_COUNCILS_RESPONSE:
    case GET_COUNCILS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedCouncilUnitType = (state = null, { type, payload }) => {
  switch (type) {
    case SET_COUNCIL_UNIT_TYPE: {
      return payload;
    }
    case SET_SELECTED_COUNCIL: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const selectedCouncilUnit = (state = '', { type, payload }) => {
  switch (type) {
    case SET_COUNCIL_UNIT: {
      return payload;
    }
    case SET_COUNCIL_UNIT_TYPE: {
      return payload !== nonUnit.id ? state : '';
    }
    case SET_SELECTED_COUNCIL: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const councilUnits = (state = [], { type, payload }) => {
  switch (type) {
    case GET_COUNCIL_UNITS_RESPONSE: {
      return payload;
    }
    case GET_COUNCIL_UNITS_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const EMPTY_ARR = [];
const orgAdults = (state = EMPTY_ARR, { type, payload }) => {
  switch (type) {
    case GET_ORG_ADULTS_RESPONSE: {
      return payload.members || EMPTY_ARR;
    }
    case GET_ORG_ADULTS_ERROR: {
      return EMPTY_ARR;
    }
    default:
      return state;
  }
};

const isLoadingCouncilUnits = (state = false, { type }) => {
  switch (type) {
    case GET_COUNCIL_UNITS_REQUEST: {
      return true;
    }
    case GET_COUNCIL_UNITS_RESPONSE:
    case GET_COUNCIL_UNITS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingEditTerm = (state = false, { type }) => {
  switch (type) {
    case EDIT_TERM_REQUEST: {
      return true;
    }
    case EDIT_TERM_RESPONSE:
    case EDIT_TERM_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const selectedTerm = (state = 12, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_TERM: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showTermSelector = (state = true, { type, payload }) => {
  switch (type) {
    case SET_SHOW_TERM_SELECTOR: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingMarkAsMultiple = (state = false, { type }) => {
  switch (type) {
    case MARK_AS_MULTIPLE_REQUEST: {
      return true;
    }
    case MARK_AS_MULTIPLE_RESPONSE:
    case MARK_AS_MULTIPLE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingUnmarkAsMultiple = (state = false, { type }) => {
  switch (type) {
    case UNMARK_AS_MULTIPLE_REQUEST: {
      return true;
    }
    case UNMARK_AS_MULTIPLE_RESPONSE:
    case UNMARK_AS_MULTIPLE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingGenerateRecharterPDF = (state = false, { type }) => {
  switch (type) {
    case GENERATE_RECHARTER_PDF_REQUEST: {
      return true;
    }
    case GENERATE_RECHARTER_PDF_RESPONSE:
    case GENERATE_RECHARTER_PDF_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isGeneratePDFFromPayment = (state = false, { type, payload }) => {
  switch (type) {
    case GENERATE_RECHARTER_PDF_REQUEST: {
      return payload.fromPayment || false;
    }
    case OPEN_PAYMENT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingCreateTransientDocumentWithAgreement = (
  state = false,
  { type },
) => {
  switch (type) {
    case CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_REQUEST: {
      return true;
    }
    case CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_RESPONSE:
    case CREATE_TRANSIENT_DOCUMENT_WITH_AGREEMENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isInviteMembersModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_INVITE_MEMBERS_MODAL: {
      return true;
    }
    case CLOSE_INVITE_MEMBERS_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdobeErrorModalVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADOBE_ERROR_MODAL: {
      return true;
    }
    case CLOSE_ADOBE_ERROR_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isInviteMemberLoading = (state = false, { type }) => {
  switch (type) {
    case INVITE_MEMBERS_REQUEST: {
      return true;
    }
    case INVITE_MEMBERS_ERROR:
    case INVITE_MEMBERS_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitRosterSorter = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIT_ROSTER_SORT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const unitRosterPendingSorter = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIT_ROSTER_PENDING_SORT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const unitRosterRemovedSorter = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIT_ROSTER_REMOVED_SORT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingAdjustedOLRSettings = (state = false, { type }) => {
  switch (type) {
    case GET_ADJUSTED_OLR_SETTINGS_REQUEST: {
      return true;
    }
    case GET_ADJUSTED_OLR_SETTINGS_RESPONSE:
    case GET_ADJUSTED_OLR_SETTINGS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const adjustedOLRSettings = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_ADJUSTED_OLR_SETTINGS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const agreementUris = (state = {}, action) => {
  const type = action.type;
  const batchId = get(action, 'payload.batchId');
  const agreementUri = get(action, 'payload.response.agreementUri');

  if (!batchId) return state;

  switch (type) {
    case GET_PAPERWORK_LINK_RESPONSE: {
      const newState = { ...state };
      newState[batchId] = agreementUri;
      return newState;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  currentStep,
  isUpdatePositionModalVisible,
  memberPositionToUpdate,
  isAddMembersModalVisible,
  isSelectAddMembersFlowModalVisible,
  isNewMemberApplication,
  isValidationModalVisible,
  isUploadDocumentModalVisible,
  isUnitPaymentErrorModalVisible,
  isPaymentModalVisible,
  isConfirmationPaymentModalVisible,
  isResendInviteModalVisible,
  confirmationModalInfo,
  isLoadingRecharterRoster,
  recharterRoster,
  recharterRosterSelectedKeys,
  pendingMembersSelectedKeys,
  recharterRosterSearch,
  recharterRosterFilters,
  isUploadDocumentLoading,
  isAddMemberLoading,
  unitPositions,
  unitBasicRequirements,
  isLoadingUnitPaymentSummary,
  unitPaymentSummary,
  isUpdatePositionLoading,
  isRemoveMembersModalVisible,
  isAddToRecharterModalVisible,
  recharterRosterValidation,
  recharterValidationLoading,
  batchInformation,
  futureCharter,
  futureCharterLoading,
  selectedPaymentMethod,
  isLoadingPaymentProcess,
  isLoadingAchVerification,
  isLoadingSubmitRecharter,
  isLoadingWepayProcess,
  removedMembersSelectedKeys,
  isLoadingSendReminders,
  isMarkAsMultipleModalVisible,
  isUnmarkAsMultipleModalVisible,
  selectedCouncil,
  councils,
  isLoadingCouncils,
  selectedCouncilUnitType,
  selectedCouncilUnit,
  councilUnits,
  isLoadingCouncilUnits,
  isLoadingEditTerm,
  selectedTerm,
  showTermSelector,
  isLoadingMarkAsMultiple,
  isLoadingUnmarkAsMultiple,
  isLoadingGenerateRecharterPDF,
  isGeneratePDFFromPayment,
  isLoadingCreateTransientDocumentWithAgreement,
  isInviteMembersModalVisible,
  isAdobeErrorModalVisible,
  isInviteMemberLoading,
  allBatches,
  isAllBatchesLoading,
  unitRosterSorter,
  unitRosterPendingSorter,
  unitRosterRemovedSorter,
  isLoadingAdjustedOLRSettings,
  adjustedOLRSettings,
  isPrivacyModalVisible,
  isEditTermModalVisible,
  isAchVerificationModalVisible,
  orgAdults,
  agreementUris,
});

export default resetOnLogoutReducer(reducer);
