import { lazier } from '@shared';

import AdvancementPagePlaceholder from './AdvancementPagePlaceholder';

export { AdvancementPagePlaceholder };

export const AdvancementPage = lazier(
  () => import('./AdvancementPage'),
  AdvancementPagePlaceholder,
);
