import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Program, ProgramId } from '../../constants';
import { intl } from '../../localization';

const unitTypeTranslationIds = {
  [Program.CUB_SCOUT]: 'shared.UnitType.pack',
  [ProgramId.CUB_SCOUT]: 'shared.UnitType.pack',
  [Program.BOY_SCOUT]: 'shared.UnitType.troop',
  [ProgramId.BOY_SCOUT]: 'shared.UnitType.troop',
  [Program.VARSITY]: 'shared.UnitType.team',
  [ProgramId.VARSITY]: 'shared.UnitType.team',
  [Program.VENTURING]: 'shared.UnitType.crew',
  [ProgramId.VENTURING]: 'shared.UnitType.crew',
  [Program.SEA_SCOUT]: 'shared.UnitType.ship',
  [ProgramId.SEA_SCOUT]: 'shared.UnitType.ship',
  [Program.EXPLORING]: 'shared.UnitType.post',
  [ProgramId.EXPLORING]: 'shared.UnitType.post',
  [Program.CLUB]: 'shared.UnitType.club',
  [ProgramId.CLUB]: 'shared.UnitType.club',
};

const getTranslationId = (program, programId) =>
  unitTypeTranslationIds[program] || unitTypeTranslationIds[+programId];

const UnitType = ({ program, programId }) => {
  const translationId = getTranslationId(program, programId);

  return <FormattedMessage id={translationId} />;
};

const getTranslation = (program, programId) => {
  if (!program || !programId) return '';
  return intl.formatMessage({ id: getTranslationId(program, programId) });
};

UnitType.propTypes = {
  program: PropTypes.string,
  programId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

// TODO: refactor, remove this function from React component
UnitType.getTranslation = getTranslation;

export default UnitType;
