import moment from 'moment';

export default (date, tzCode, keepDate) => {
  const timezoneCode = tzCode || 'America/Chicago';
  const formattedDate = moment(date);

  const appliedTz = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'longOffset',
    timeZone: timezoneCode,
  }).format(formattedDate.toDate());
  const [, GMT] = appliedTz.split(',');
  const utcOffset = GMT.replace(' GMT', '');

  const formattedOffset = utcOffset.replace(':', '');
  return formattedDate.utcOffset(formattedOffset, keepDate);
};
