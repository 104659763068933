import { createAction } from '@reduxjs/toolkit';

import { pendingItemsModuleNamespace } from '../../common';

const namespace = pendingItemsModuleNamespace;

export const SET_SEARCH_FILTER = `${namespace}/SET_SEARCH_FILTER` as const;
export const setSearchFilter = (filter: unknown) => ({
  type: SET_SEARCH_FILTER,
  payload: filter,
});

export const SET_SORTER = `${namespace}/SET_SORTER` as const;
export const setSorter = ({
  columnKey,
  order,
}: {
  columnKey: string;
  order: string;
}) => ({
  type: SET_SORTER,
  payload: { columnKey, order },
});

export const SET_FILTER = `${namespace}/SET_FILTER` as const;
export const setFilter = ({
  advancementTypes,
  activityTypes: activityTypesFilters,
}: {
  advancementTypes: Record<string, boolean>;
  activityTypes: Record<string, boolean>;
}) => ({
  type: SET_FILTER,
  payload: {
    advancementTypes,
    activityTypes: activityTypesFilters,
  },
});

type SelectedItemKey = string | number;

export const SET_SELECTED_ROW_KEYS =
  `${namespace}/SET_SELECTED_ROW_KEYS` as const;
export const setSelectedRowKeys = (items: SelectedItemKey[]) => ({
  type: SET_SELECTED_ROW_KEYS,
  payload: items,
});

export const removeSelectedItem = createAction<SelectedItemKey>(
  `${namespace}/removeSelectedItem`,
);
export const removeMultipleSelectedItems = createAction<SelectedItemKey[]>(
  `${namespace}/removeMultipleSelectedItem`,
);

export const SET_PENDING_ITEMS_ACTIVE_TAB =
  `${namespace}/SET_PENDING_ITEMS_ACTIVE_TAB` as const;
export const setPendingItemsActiveTab = (activeTab: string) => ({
  type: SET_PENDING_ITEMS_ACTIVE_TAB,
  payload: activeTab,
});
