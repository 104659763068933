import { moduleNamespace } from '../constants';

const namespace = moduleNamespace;

export const BASIC_REPORTS_REQUEST = `${namespace}/REPORTS_REQUEST`;
export const basicReportsRequest = () => ({ type: BASIC_REPORTS_REQUEST });

export const BASIC_REPORTS_RESPONSE = `${namespace}/REPORTS_RESPONSE`;
export const basicReportsResponse = reports => ({
  type: BASIC_REPORTS_RESPONSE,
  payload: reports,
});

export const BASIC_REPORTS_ERROR = `${namespace}/REPORTS_ERROR`;
export const basicReportsError = error => ({
  type: BASIC_REPORTS_ERROR,
  payload: error,
});

export const OPEN_SELECT_YOUTH_MODAL = `${namespace}/OPEN_SELECT_YOUTH_MODAL`;
export const openSelectYouthModal = reportCode => ({
  type: OPEN_SELECT_YOUTH_MODAL,
  payload: reportCode,
});
export const CLOSE_SELECT_YOUTH_MODAL = `${namespace}/CLOSE_SELECT_YOUTH_MODAL`;
export const closeSelectYouthModal = () => ({ type: CLOSE_SELECT_YOUTH_MODAL });
