import { Observable } from 'rxjs';

const maxErrorLifetime = 5 * 60 * 1000; // 5 min
const apiDownMinErrors = 5;
let errors = [];
window.sblOffline = false;

let apiDownObserver;
const apiDown$ = Observable.create(observer => (apiDownObserver = observer));

const checkApiError = ajaxError => {
  const isLoggedIn = !!localStorage.getItem('LOGIN_DATA');
  if (isLoggedIn) {
    const { status, request = {} } = ajaxError;
    const { url } = request;
    const now = new Date().getTime();
    const thresholdTime = now - maxErrorLifetime;

    errors = errors.filter(err => err.timestamp >= thresholdTime);

    if (status == 0 || status >= 400) {
      errors.push({
        status,
        timestamp: now,
        url,
      });
    }

    if (errors.length >= apiDownMinErrors && apiDownObserver) {
      apiDownObserver.next(true);
    }
  }
};

const clearErrors = () => (errors = []);

export default {
  checkApiError,
  clearErrors,
  apiDown$,
};
