import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import SwapVertIcon from '@material-ui/icons/SwapVert';

import { sortOrderPropTypes } from '../../constants';
import styles from './SortIcon.less';

const SortIcon = ({ order, className }) => (
  <div className={cn(styles.container, className)}>
    <SwapVertIcon className={cn(styles.sortIcon, styles.nosort, order)} />
    <ArrowDownIcon className={cn(styles.sortIcon, styles.down, order)} />
  </div>
);

SortIcon.propTypes = {
  order: sortOrderPropTypes,
  className: PropTypes.string,
};

export default SortIcon;
