import React from 'react';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import { DatePicker } from 'bsa-ui';

import BasePicker, { basePropTypes } from './BasePicker';

class WeekPicker extends React.PureComponent {
  render() {
    return <BasePicker Picker={DatePicker.WeekPicker} {...this.props} />;
  }
}

WeekPicker.propTypes = basePropTypes;

WeekPicker.defaultProps = {
  format: 'YYYY-Wo',
};

export default WeekPicker;
