import { lazier } from '@shared';

import ActivitiesPlaceholder from './ActivitiesPlaceholder';
import AddActivityButton from './AddActivityButton';

export { ActivitiesPlaceholder, AddActivityButton };

export const ActivitiesPage = lazier(
  () => import('./ActivitiesPage'),
  ActivitiesPlaceholder,
);
