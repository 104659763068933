import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ResizeObserver from 'resize-observer-polyfill';
import throttle from 'lodash/throttle';
import cn from 'classnames';

import { isMobileSel } from '../../duck';
import S from '../S';
import Fab from '../Fab';
import Button from '../Button';
import Card from '../Card';
import Dropdown from '../Dropdown';
import TableFilterCollapse from './TableFilterCollapse';
import TableFilterPanel from './TableFilterPanel';
import TableFilterPanelGroup from './TableFilterPanelGroup';
import styles from './TableFilterCard.less';

const dropdownTrigger = ['click'];
// need to get ref to overlay on per-instance basis
// Dropdown does not work with `ref` so we need to target by unique class name
const overlayClassName = 'TabFilterCard_Overlay';
let instanceCounter = 0;

/**
 *
 * This component's parent will be used as reference for width/left offset.
 * So usually you would want to place this inside your table/list header.
 *
 */
class TableFilterCard extends React.Component {
  state = {
    open: this.props.defaultOpen || false,
  };

  componentDidMount = () => {
    // TODO: refactor usage of findDOMNode
    // eslint-disable-next-line react/no-find-dom-node
    this.parent = ReactDOM.findDOMNode(this).parentNode;
    this.overlay = document.querySelector(`.${this.instanceOverlayClassName}`);
    this.resizeObserver = new ResizeObserver(
      throttle(this.asyncUpdateOverlay, 30),
    );
    this.resizeObserver.observe(this.parent);
  };

  componentDidUpdate = () => {
    if (!this.overlay && this.isOpen()) {
      setTimeout(() => {
        this.overlay = document.querySelector(
          `.${this.instanceOverlayClassName}`,
        );
        this.updateOverlay();
      });
    }
    this.updateOverlay();
  };

  componentWillUnmount = () => {
    if (this.resizeObserver && this.parent) {
      this.resizeObserver.unobserve(this.parent);
    }
    this.handleBodyScroll(false);
  };

  instanceOverlayClassName = `${overlayClassName}${instanceCounter++}`;
  overlay = null;
  resizeObserver = null;
  parent = null;

  asyncUpdateOverlay = () => setTimeout(this.updateOverlay);

  updateOverlay = () => {
    if (this.overlay && this.parent && this.isOpen()) {
      const { isMobile } = this.props;
      const style = isMobile ? '' : this.getDesktopOverlayStyle(this.overlay);
      this.overlay.setAttribute('style', style);
    }
  };

  getDesktopOverlayStyle = overlay => {
    const { x, width } = this.parent.getBoundingClientRect();

    return `
      top: ${overlay.style.top};
      margin-left: ${x}px !important;
      min-width: ${width}px !important;
    `;
  };

  handleBodyScroll = isOpen => {
    const { isMobile } = this.props;
    if (isOpen && isMobile) {
      setTimeout(() => {
        const content = document.querySelector(
          `.${this.instanceOverlayClassName} .${styles.content}`,
        );
        disableBodyScroll(content);
      });
    } else if (!isOpen) {
      clearAllBodyScrollLocks();
    }
  };

  handleOpenChange = () => {
    const { onOpenChange } = this.props;
    if (onOpenChange) {
      onOpenChange(!this.isOpen());
    }
    if (!this.isControlled()) {
      this.setState(({ open }) => ({ open: !open }));
    }
    this.asyncUpdateOverlay();
    this.handleBodyScroll(!this.isOpen());
  };

  handleClose = () => {
    const { onOpenChange } = this.props;
    if (onOpenChange) {
      onOpenChange(false);
    }
    if (!this.isControlled()) {
      this.setState({ open: false });
    }

    this.handleBodyScroll(false);
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) {
      onSubmit();
    }
    this.handleClose();
  };

  isOpen = () => {
    const { open } = this.props;
    const { open: stateOpen } = this.state;
    return this.isControlled() ? open : stateOpen;
  };

  isControlled = () => typeof this.props.open === 'boolean';

  renderContent = () => {
    const { content, className, isMobile, submitId } = this.props;
    const classNames = cn(className, styles.card);

    return (
      // extra <div> with those attrs because Dropdown applies them to its child and Card would complain
      // eslint-disable-next-line react/no-unknown-property
      <div selectable="false" focusable="false">
        <Card shadow className={classNames}>
          <div className={styles.container}>
            <div className={styles.header}>
              <S size="3">
                <FormattedMessage id="shared.TableFilterCard.title" />
              </S>
              <Button
                ghost
                noBorder
                color="white"
                fitText
                onClick={this.handleClose}
              >
                <FormattedMessage id="shared.cancel" />
              </Button>
            </div>
            <div className={styles.content}>{content}</div>
            {isMobile ? (
              <Fab
                noBorder
                fitText
                color="scouting-blue"
                id={submitId}
                onClick={this.handleSubmit}
              >
                <FormattedMessage id="shared.TableFilterCard.submit" />
              </Fab>
            ) : (
              <Button
                type="default"
                ghost
                noBorder
                fitText
                color="info"
                size="large"
                id={submitId}
                className={styles.submitButton}
                onClick={this.handleSubmit}
              >
                <FormattedMessage id="shared.TableFilterCard.submit" />
              </Button>
            )}
          </div>
        </Card>
      </div>
    );
  };

  render() {
    const { children } = this.props;

    return (
      <Dropdown
        trigger={dropdownTrigger}
        placement="bottomLeft"
        overlay={this.renderContent()}
        overlayClassName={cn(styles.overlay, this.instanceOverlayClassName)}
        visible={this.isOpen()}
        onVisibleChange={this.handleOpenChange}
      >
        {children}
      </Dropdown>
    );
  }
}

TableFilterCard.propTypes = {
  // provided by parent
  // child must be an element that has an 'onClick' prop (preferably a button) :P
  children: PropTypes.element.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string,
  submitId: PropTypes.string,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  onSubmit: PropTypes.func,
  // provided by component itself
  isMobile: PropTypes.bool.isRequired,
};

TableFilterCard.Collapse = TableFilterCollapse;
TableFilterCard.Panel = TableFilterPanel;
TableFilterCard.PanelGroup = TableFilterPanelGroup;

const mapState = state => ({
  isMobile: isMobileSel(state),
});

export default connect(mapState)(TableFilterCard);
