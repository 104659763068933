import { combineEpics, ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { organizationGuidSel } from '@context';
import { getCustomErrorMessage } from '@modules/bulkEntry/duck/utils';
import { tagTypes } from '@modules/rtk/constants';
import { esbApi } from '@modules/rtk/esb-api';
import { toastService } from '@toasts';
import { userIdSel } from '@user';
import { esbApiService } from '@utils';
import { catchAndReport } from '@utils/rxjs/operators';

import { recordAdvancementRequest } from '../../common';
import {
  APPROVE_ADVANCEMENTS_REQUEST,
  DECLINE_ADVANCEMENTS_REQUEST,
  approveAdvancementsDeferred,
  approveAdvancementsError,
  approveAdvancementsResponse,
  declineAdvancementsDeferred,
  declineAdvancementsError,
  declineAdvancementsResponse,
} from './actions';
import { advancementStatusSel } from './selectors';
import services from './services';

const approveAdvancementsRequestEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(APPROVE_ADVANCEMENTS_REQUEST),
    filter(({ payload }) => payload.approveItems.length > 0),
    switchMap(({ payload }) => {
      const {
        approveItems,
        updatedValues,
        successMsg,
        approvedDate = undefined,
      } = payload;
      const state = state$.value;
      const loggedInUserId = userIdSel(state);
      const organizationGuid = organizationGuidSel(state);
      const status = advancementStatusSel(state);

      return services
        .approveItems$({
          loggedInUserId,
          organizationGuid,
          approveItems,
          updatedValues,
          status,
          approvedDate,
        })
        .pipe(
          mergeMap(res => {
            if (res === esbApiService.DEFERRED) {
              return of(approveAdvancementsDeferred());
            }
            toastService.success(successMsg);
            const advancementTypes = approveItems.map(
              ({ advancementType }) => advancementType,
            );

            return concat(
              of(
                approveAdvancementsResponse(advancementTypes),
                esbApi.util.invalidateTags([
                  {
                    id: `Unit-List-${organizationGuid}`,
                    type: tagTypes.AdvancementHistory,
                  },
                ]),
              ),
            );
          }),
          catchAndReport(err => {
            const customError = getCustomErrorMessage(err.response);
            if (customError) {
              toastService.dismiss(undefined);
              toastService.error(customError);
            }
            return of(approveAdvancementsError(err));
          }),
        );
    }),
  );

const recordAdvancementsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(APPROVE_ADVANCEMENTS_REQUEST),
    filter(({ payload }) => payload.recordPersonsAdvancements.length > 0),
    map(({ payload }) => {
      const status = advancementStatusSel(state$.value);
      const {
        updatedValues: completionDates,
        recordSuccessMsg: successMsg,
        recordPersonsAdvancements: personsAdvancements,
      } = payload;

      const [item] = personsAdvancements;
      const { id: advancementId, advancementType } = item;

      return recordAdvancementRequest({
        successMsg,
        advancementId,
        advancementType,
        status,
        users: personsAdvancements,
        date: completionDates,
        closeOnSuccess: true,
      });
    }),
  );

const declineAdvancementsRequestEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DECLINE_ADVANCEMENTS_REQUEST),
    filter(({ payload }) => payload.approveItems.length > 0),
    switchMap(({ payload }) => {
      const { approveItems, updatedValues, successMsg } = payload;
      const state = state$.value;

      const organizationGuid = organizationGuidSel(state);

      const activities = approveItems.map(item => {
        const activityValues = item.activityRecords.map(record => record.id);
        return {
          activityId: item.activityId,
          activityValues,
          note: updatedValues[`${item.userId}_${item.activityId}`].note,
        };
      });

      return services
        .declineMultipleActivities$({
          organizationGuid,
          activities,
        })
        .pipe(
          mergeMap(res => {
            if (res === esbApiService.DEFERRED) {
              return of(declineAdvancementsDeferred());
            }
            toastService.success(successMsg);
            const advancementTypes = approveItems.map(
              ({ advancementType }) => advancementType,
            );

            return of(declineAdvancementsResponse(advancementTypes));
          }),
          catchAndReport(err => of(declineAdvancementsError(err))),
        );
    }),
  );

export default combineEpics(
  approveAdvancementsRequestEpic$,
  recordAdvancementsEpic$,
  declineAdvancementsRequestEpic$,
);
