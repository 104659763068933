import { addressTypeIds } from '../../constants';
import isValueTruthy from '../../utils/isValueTruthy';
import { v4 as uuid } from 'uuid';

export const getNewAddress = typeId => ({
  id: `id_${uuid()}`,
  isPrimary: undefined,
  typeId: typeId,
  countryId: undefined,
});

const getPrimaryAddress = (addresses = [], typeId) => {
  const filteredAddressesByType = addresses.filter(
    address => address.typeId == typeId,
  );

  if (!filteredAddressesByType.length) {
    return getNewAddress(typeId);
  }

  const primaryAddress = filteredAddressesByType.find(address =>
    isValueTruthy(address.isPrimary),
  );

  return primaryAddress ? primaryAddress : filteredAddressesByType[0];
};

export const filterAddresses = (addresses = []) => {
  const homeAddress = getPrimaryAddress(addresses, addressTypeIds.HOME);
  const businessAddress = getPrimaryAddress(addresses, addressTypeIds.BUSINESS);
  const vacationAddress = getPrimaryAddress(addresses, addressTypeIds.VACATION);

  return [homeAddress, businessAddress, vacationAddress];
};
