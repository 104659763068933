import { lazier } from '@shared';

import CounseledYouthPagePlaceholder from './CounseledYouthPagePlaceholder';

export { CounseledYouthPagePlaceholder };

export const CounseledYouthPage = lazier(
  () => import('./CounseledYouthPage'),
  CounseledYouthPagePlaceholder,
);
