import { UnderConstruction } from '@shared';
import { ROUTE_HOME } from './duck';

const routes = {
  [ROUTE_HOME]: {
    path: '/',
    Component: UnderConstruction,
    titleId: 'page.dashboard',
  },
};

export default routes;
