import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import { navigateToTab, tabNames } from '../../../common';
import {
  uploadFileRequest,
  uploadFileCancel,
  uploadingSel,
  uploadProgressSel,
  uploadedFilesSel,
  uploadErrorSel,
  uploadErrorDescSel,
  openErrorsModal,
} from '../../duck';
import UploadDropzone from './UploadDropzone';
import UploadsList from './UploadsList';
import UploadStats from './UploadStats';
import ErrorsModal from './ErrorsModal';
import styles from './UploadForm.less';

class UploadForm extends React.PureComponent {
  state = {
    selectedFile: null,
  };

  handleUpload = selectedFile => {
    this.setState({ selectedFile });
    this.props.onUpload(selectedFile);
  };

  handleCancelUpload = () => {
    this.setState({ selectedFile: null });
    this.props.onCancelUpload();
  };

  handleNavigateToHistory = () => {
    this.props.onCancel();
    this.props.onNavigateToHistory();
  };

  render() {
    const {
      uploading,
      progress,
      uploadedFiles,
      uploadError,
      uploadErrorDesc,
      onShowErrors,
    } = this.props;
    const { selectedFile } = this.state;
    const showAside = uploading || uploadedFiles.length || uploadError;

    return (
      <div className={cn(styles.container, { [styles.showAside]: showAside })}>
        <ErrorsModal />
        <UploadDropzone
          className={styles.dropzone}
          disabled={uploading}
          onDrop={this.handleUpload}
        />
        <div className={styles.aside}>
          <UploadsList
            className={styles.uploads}
            uploading={uploading}
            progress={progress}
            uploadedFiles={uploadedFiles}
            uploadError={uploadError}
            selectedFile={selectedFile}
            uploadErrorDesc={uploadErrorDesc}
            onRetry={this.handleUpload}
            onCancel={this.handleCancelUpload}
            onShowErrors={onShowErrors}
          />
          <UploadStats
            className={styles.stats}
            uploadedCount={uploadedFiles.length}
            onNavigateToHistory={this.handleNavigateToHistory}
          />
        </div>
      </div>
    );
  }
}

UploadForm.propTypes = {
  // provided by parent
  onCancel: PropTypes.func.isRequired,
  // provided by component itself
  uploading: PropTypes.bool.isRequired,
  progress: PropTypes.object.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  uploadError: PropTypes.bool.isRequired,
  uploadErrorDesc: PropTypes.array.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  onNavigateToHistory: PropTypes.func.isRequired,
  onShowErrors: PropTypes.func.isRequired,
};

const mapState = state => ({
  uploading: uploadingSel(state),
  progress: uploadProgressSel(state),
  uploadedFiles: uploadedFilesSel(state),
  uploadError: uploadErrorSel(state),
  uploadErrorDesc: uploadErrorDescSel(state),
});

const mapDispatch = dispatch => ({
  onUpload: file => dispatch(uploadFileRequest(file)),
  onCancelUpload: () => dispatch(uploadFileCancel()),
  onNavigateToHistory: () => dispatch(navigateToTab(tabNames.ACTIVITY)),
  onShowErrors: () => dispatch(openErrorsModal()),
});

export default connect(mapState, mapDispatch)(UploadForm);
