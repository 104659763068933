import { of } from 'rxjs';
import { switchMap, mergeMap, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import uniqBy from 'lodash/uniqBy';

import { setSelectedRosterKeys, intl } from '@shared';
import { catchAndReport } from '@utils/rxjs/operators';
import { userIdSel } from '@user';
import { organizationTypeIdSel, organizationGuidSel } from '@context';
import { toastService } from '@toasts';

import {
  selectedActivitySel,
  savingAction,
  personsAndIdsActivitySel,
  setTime,
  personsToRemoveSel,
  loadAndOpenActivity,
} from '../../common';
import { personsSel, activeTabSel } from './selectors';
import {
  RECORD_CAMPOUTS_RESPONSE,
  RECORD_CAMPOUTS_REQUEST,
  recordCampoutsResponse,
  recordCampoutsError,
  closeCampouts,
  LOAD_AND_OPEN_CAMPOUT_MODAL,
  loadAndOpenCampoutModalError,
  openEditCampout,
} from './actions';
import services from './services';

const recordCampoutsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RECORD_CAMPOUTS_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { activityData } = payload;
      let validActivityData = { ...activityData };

      if (activityData.allDay) {
        validActivityData = {
          ...validActivityData,
          startTime: setTime(),
          endTime: setTime(23, 59),
        };
      }

      const persons = personsSel(state);
      const personsToRemove = personsToRemoveSel(state);
      const loggedInUserId = userIdSel(state);
      const organizationGuid = organizationGuidSel(state);
      const organizationTypeId = organizationTypeIdSel(state);
      const selectedActivity = selectedActivitySel(state);
      const isAdvancedMode = activeTabSel(state) == 'advanced';
      const { personsInActivity } = personsAndIdsActivitySel(state);
      return services
        .saveCampoutActivity$({
          ...selectedActivity,
          ...validActivityData,
          personsToRemove,
          loggedInUserId,
          organizationGuid,
          organizationTypeId: +organizationTypeId,
          persons: uniqBy([...personsInActivity, ...persons], 'userId'),
          isAdvancedMode,
        })
        .pipe(
          mergeMap(() => {
            const { closeOnSuccess } = payload;
            const actions = [recordCampoutsResponse()].concat(
              closeOnSuccess
                ? [closeCampouts(), setSelectedRosterKeys([])]
                : [],
            );

            return of(...actions);
          }),
          tap(({ type }) => {
            if (type === RECORD_CAMPOUTS_RESPONSE) {
              toastService.success(
                intl.formatMessage(
                  {
                    id: `progress.common.${
                      persons.length ? 'saveSuccessParticipants' : 'saveSuccess'
                    }`,
                  },
                  {
                    name: activityData.name,
                    type: selectedActivity.id
                      ? savingAction.UPDATED
                      : savingAction.CREATED,
                    count: persons.length,
                    participants: persons
                      .map(({ personShortFullName }) => personShortFullName)
                      .join(', '),
                  },
                ),
              );
            }
          }),
          catchAndReport(err => of(recordCampoutsError(err))),
        );
    }),
  );

const loadCampoutByIdEpic$ = action$ =>
  action$.pipe(
    ofType(LOAD_AND_OPEN_CAMPOUT_MODAL),
    map(({ payload }) =>
      loadAndOpenActivity({
        ...payload,
        successActionFn: openEditCampout,
        errorActionFn: loadAndOpenCampoutModalError,
      }),
    ),
  );

export default combineEpics(recordCampoutsEpic$, loadCampoutByIdEpic$);
