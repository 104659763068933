import React from 'react';
import ContentLoader from 'react-content-loader';

import { PagePlaceholder, TablePlaceholder } from '@shared';
import styles from './AdvancementPagePlaceholder.less';

const AdvancementPagePlaceholder = () => (
  <PagePlaceholder className={styles.container}>
    <ContentLoader width={240} height={300} className={styles.org}>
      <rect x="0" y="0" rx="3" ry="3" width="120" height="20" />
      <rect x="0" y="60" rx="3" ry="3" width="240" height="20" />
      <rect x="0" y="100" rx="3" ry="3" width="160" height="15" />
      <rect x="0" y="130" rx="3" ry="3" width="140" height="15" />
      <rect x="0" y="160" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="230" rx="3" ry="3" width="100" height="20" />
    </ContentLoader>
    <TablePlaceholder />
  </PagePlaceholder>
);

export default AdvancementPagePlaceholder;
