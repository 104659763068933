import React, { useEffect, useState } from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isMobileSel } from '@screen';
import {
  Avatar,
  ConditionalRender,
  S,
  logout,
  offlineSel,
  selectedOrganizationSel,
  setRecentUnit,
} from '@shared';

import { goToRecentUnitsTabPage } from '../../../councilUnits';
import {
  childOrganizationSel,
  currentOrganizationsPositionsSel,
  organizationGuidSel,
  parentOrgGuidSel,
  recentActiveUnitOrganizationsSel,
  setOrganization,
} from '../../duck';
import ProgramColors from '../CurrentProgramColors';
import CurrentProgramLogo from '../CurrentProgramLogo';
import styles from './RolePicker.less';
import RolePickerDropdown from './RolePickerDropdown';

const RolePicker = ({
  currentOrganizationGuid,
  selectedOrganization,
  parentOrgGuid,
  roles,
  hidden,
  isMobile,
  isOffline,
  recentActiveUnitRoles,
  childOrganization,
  onLogout,
  onSetUnit,
  onSetOrganization,
  onGoToRecentUnitsTabPage,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredRoles, setFilteredRoles] = useState([]);

  useEffect(() => {
    setFilteredRoles(roles);
  }, [roles]);

  useEffect(() => {
    const closeFn = () => setOpen(false);
    if (open) {
      window.addEventListener('click', closeFn);
    } else {
      window.removeEventListener('click', closeFn);
    }
    return () => window.removeEventListener('click', closeFn);
  }, [open]);

  const toggleMenu = () => {
    if (!hidden) {
      setOpen(!open);
    }
  };

  const handleProfileChange = guid => {
    if (parentOrgGuid !== guid) {
      const { programType } = roles.find(
        ({ organizationGuid }) => organizationGuid === guid,
      );

      onSetOrganization({
        guid,
        programType,
        parentOrgGuid: guid,
      });
    }
  };

  const handleUnitChange = guid => {
    const newActiveUnitRole = recentActiveUnitRoles.find(
      ({ organizationGuid }) => guid == organizationGuid,
    );
    const { programType } = newActiveUnitRole;
    onSetOrganization({
      guid,
      programType,
      parentOrgGuid,
    });
    onSetUnit(newActiveUnitRole);
  };

  const handleSearch = ({ target: { value } }) => {
    setSearch(value || '');
    const searchRoles = roles.filter(org => {
      const searchString = value.toLowerCase();
      return (
        org.organizationName.toLowerCase().includes(searchString) ||
        (org.connection && org.connection.toLowerCase().includes(searchString))
      );
    });
    setFilteredRoles(searchRoles);
  };

  const selectedRole = roles.find(
    role => role.organizationGuid === currentOrganizationGuid,
  );
  const selectedOrganizationName = selectedOrganization.organizationName
    ? selectedOrganization.organizationName
    : selectedRole.organizationName;

  const hasMultipleRoles = !!(roles?.length > 1);
  return (
    <div
      id="qa_rolePicker"
      className={cn('Joyride__RolePicker', styles.container, {
        [styles.hidden]: hidden,
        [styles.normalCursor]: !hasMultipleRoles,
      })}
      onClick={toggleMenu}
      title="Program/Unit"
    >
      <ProgramColors>
        <div className={styles.currentPosition}>
          <Avatar
            placeholder={<CurrentProgramLogo />}
            className={styles.programAvatar}
          />
          <S size="4" className={styles.currentOrgName}>
            {selectedOrganizationName ||
              (roles[0] && roles[0].organizationName)}
          </S>
          <ConditionalRender value={hasMultipleRoles}>
            {open ? (
              <ExpandLessIcon className={styles.caret} />
            ) : (
              <ExpandMoreIcon className={styles.caret} />
            )}
          </ConditionalRender>
        </div>
      </ProgramColors>
      <RolePickerDropdown
        open={open}
        roles={filteredRoles}
        allRolesLength={roles.length}
        recentActiveUnitRoles={recentActiveUnitRoles}
        childOrganization={childOrganization}
        currentOrganizationGuid={currentOrganizationGuid}
        parentOrgGuid={parentOrgGuid}
        search={search}
        isMobile={isMobile}
        isOffline={isOffline}
        onProfileChange={handleProfileChange}
        onUnitChange={handleUnitChange}
        onLogout={onLogout}
        onSearch={handleSearch}
        onViewMoreRecentCouncilUnits={onGoToRecentUnitsTabPage}
      />
    </div>
  );
};

RolePicker.propTypes = {
  currentOrganizationGuid: PropTypes.string,
  selectedOrganization: PropTypes.object,
  parentOrgGuid: PropTypes.string,
  roles: PropTypes.array,
  hidden: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  recentActiveUnitRoles: PropTypes.array.isRequired,
  childOrganization: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  onSetUnit: PropTypes.func.isRequired,
  onSetOrganization: PropTypes.func.isRequired,
  onGoToRecentUnitsTabPage: PropTypes.func.isRequired,
};

RolePicker.defaultProps = {
  hidden: false,
};

const mapState = state => ({
  currentOrganizationGuid: organizationGuidSel(state),
  selectedOrganization: selectedOrganizationSel(state),
  parentOrgGuid: parentOrgGuidSel(state),
  roles: currentOrganizationsPositionsSel(state),
  isMobile: isMobileSel(state),
  isOffline: offlineSel(state),
  recentActiveUnitRoles: recentActiveUnitOrganizationsSel(state),
  childOrganization: childOrganizationSel(state),
});

const mapDispatch = dispatch => ({
  onLogout: () => dispatch(logout()),
  onSetUnit: org => dispatch(setRecentUnit(org)),
  onSetOrganization: organizationData =>
    dispatch(setOrganization(organizationData)),
  onGoToRecentUnitsTabPage: () => dispatch(goToRecentUnitsTabPage()),
});

export default connect(mapState, mapDispatch)(RolePicker);
