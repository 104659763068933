import type { RoutesMap } from 'redux-first-router';

import { COMMITHASH, FEATURE_FLAG_EDITOR } from '@config/config';

import FeatureFlagsPage from './components/FeatureFlagsPage';
import { ROUTE_FEATURE_FLAGS } from './duck/actions';

const routes: RoutesMap<{
  Component: React.ReactNode;
  titleId: string;
}> = {};

if (FEATURE_FLAG_EDITOR) {
  routes[ROUTE_FEATURE_FLAGS.type] = {
    path: `/_/${COMMITHASH}/feature-flags`,
    Component: FeatureFlagsPage,
    titleId: 'page.featureFlags',
  };
}
export default routes;
