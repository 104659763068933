import React from 'react';
import PropTypes from 'prop-types';

import { Card, TableMobile } from '@shared';
import AdvancementReportListHeader from './AdvancementReportListHeader';
import advancementReportRows from './advancementReportRows';
import styles from './AdvancementReportList.less';

function AdvancementReportList({ items, loading, showPrice, onEdit }) {
  return (
    <Card className={styles.mainCardWrapper} noPadding shadow>
      <AdvancementReportListHeader onEdit={onEdit} />
      <Card className={styles.cardListContainer} noPadding loading={loading}>
        <TableMobile
          loading={loading}
          items={items}
          rowId="key"
          rows={advancementReportRows(showPrice)}
          itemsClassName={styles.records}
        />
      </Card>
    </Card>
  );
}

AdvancementReportList.propTypes = {
  loading: PropTypes.bool.isRequired,
  showPrice: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  onEdit: PropTypes.func,
};

export default AdvancementReportList;
