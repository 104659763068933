import uniq from 'lodash/uniq';
import moment from 'moment';
import { createSelector } from 'reselect';

import { unitTypeIdSel } from '@context';
import { dictionaryLoadedSel } from '@dict';
import {
  Program,
  ProgramId,
  advancementStatuses,
  advancementTypes,
  eagleScoutRankId,
  packRosterItemsSel,
  unitsMaxAge,
} from '@shared';
import { birthday } from '@utils';

import { eagleScoutName } from '../../constants';
import {
  additionalAdvancementIdSel,
  additionalAdvancementTypeSel,
  additionalAdvancementVerificationSel,
  additionalCompletionDateSel,
  advancementIdSel,
  advancementTypeSel,
  advancementVerificationSel,
  completionDateSel,
  formTabSel,
  personsRanksSel,
  personsSel,
  selectedAdditionalAdvStatusSel,
  selectedAdditionalSuggestedYouthIdsSel,
  selectedAdvancementStatusSel,
  selectedSuggestedYouthIdsSel,
  shouldDisplayTabsSel,
  youthSuggestionsSel,
} from './core.selectors';
import { personsMinRankFirstClassRankSel } from './helpers.selectors';

const getSuggestedUserIds = (
  historyItems,
  persons,
  selectedAdvancementType,
  selectedAdvancementId,
) => {
  if (
    selectedAdvancementType == advancementTypes.RANKS &&
    selectedAdvancementId == eagleScoutRankId
  ) {
    return [];
  }

  const selectedUserIds = persons.map(({ userId }) => userId);
  const suggestedUserIds = historyItems
    .filter(
      ({ userId, id, advancementType }) =>
        selectedAdvancementType === advancementType &&
        id == selectedAdvancementId &&
        !selectedUserIds.some(id => id == userId),
    )
    .map(({ userId }) => userId);

  return uniq(suggestedUserIds);
};

export const suggestedYouthIdsSel = createSelector(
  youthSuggestionsSel,
  personsSel,
  advancementTypeSel,
  advancementIdSel,
  (historyItems, persons, selectedAdvancementType, selectedAdvancementId) =>
    getSuggestedUserIds(
      historyItems,
      persons,
      selectedAdvancementType,
      selectedAdvancementId,
    ),
);

export const additionalSuggestedYouthIdsSel = createSelector(
  youthSuggestionsSel,
  personsSel,
  additionalAdvancementTypeSel,
  additionalAdvancementIdSel,
  (
    historyItems,
    persons,
    selectedAdditionalAdvancementType,
    selectedAdditionalAdvancementId,
  ) =>
    getSuggestedUserIds(
      historyItems,
      persons,
      selectedAdditionalAdvancementType,
      selectedAdditionalAdvancementId,
    ),
);

const getAllPersons = (roster, persons, suggestedUserIds) => {
  const selectedPersons = roster
    .filter(person => persons.some(({ userId }) => userId == person.userId))
    .map(person => ({ ...person, isNewPersonAdvancement: true }));
  const suggestedPersons = roster.filter(person =>
    suggestedUserIds.some(id => id == person.userId),
  );
  return [...selectedPersons, ...suggestedPersons];
};

const allPersonsSel = createSelector(
  packRosterItemsSel,
  personsSel,
  suggestedYouthIdsSel,
  (roster, persons, suggestedUserIds) =>
    getAllPersons(roster, persons, suggestedUserIds),
);

const additionalAllPersonsSel = createSelector(
  packRosterItemsSel,
  personsSel,
  additionalSuggestedYouthIdsSel,
  (roster, persons, suggestedUserIds) =>
    getAllPersons(roster, persons, suggestedUserIds),
);

const makeSuggestedYouthSelector = (allPersonsSel, suggestedIdsSel) =>
  createSelector(
    allPersonsSel,
    suggestedIdsSel,
    (allPersons, suggestedUserIds) =>
      allPersons
        .filter(({ userId }) => suggestedUserIds.some(id => id == userId))
        .map(
          ({
            memberId,
            userId,
            personFullName,
            personShortFullName,
            isAdult,
            currentHighestRankApproved,
            otherHighestRanksApproved,
            dateOfBirth,
            age,
          }) => ({
            personId: memberId,
            memberId,
            userId,
            personFullName,
            personShortFullName,
            isAdult,
            currentHighestRankApproved,
            otherHighestRanksApproved,
            dateOfBirth,
            age,
          }),
        ),
  );

export const suggestedYouthSel = makeSuggestedYouthSelector(
  allPersonsSel,
  suggestedYouthIdsSel,
);
export const additionalSuggestedYouthSel = makeSuggestedYouthSelector(
  additionalAllPersonsSel,
  additionalSuggestedYouthIdsSel,
);

export const activeUnitTypeIdSel = createSelector(
  unitTypeIdSel,
  formTabSel,
  (unitType, formTab) =>
    !formTab || formTab !== Program.BOY_SCOUT ? unitType : ProgramId.BOY_SCOUT,
);

const ageValidation = ({ unitTypeId, age, completionDate, dateOfBirth }) => {
  const unitMaxAge = unitsMaxAge[unitTypeId];
  if (!unitMaxAge) {
    return true;
  }
  const limitBirthdayDate =
    unitMaxAge === 18
      ? birthday.get18th({ age, dob: dateOfBirth })
      : birthday.get21st({ age, dob: dateOfBirth });

  return completionDate.isBefore(moment(limitBirthdayDate));
};

const getAdvancementStatus = ({
  isCompleted,
  isApproved,
  isAwarded,
  advancementStatus,
}) =>
  isAwarded
    ? advancementStatuses.AWARDED
    : isApproved
    ? advancementStatuses.APPROVED
    : isCompleted
    ? advancementStatuses.COMPLETED
    : advancementStatus;

export const getVerifiedPersons = ({
  persons,
  advancementVerification,
  completionDate,
  unitTypeId,
}) => {
  const completionDay = moment(completionDate);
  const today = moment();

  return persons.map(person => {
    let {
      dateCompleted,
      dateEarned,
      dateAwarded,
      awarded,
      leaderApprovedUserId,
      leaderApprovedDate,
      isPreviousRankEarned,
      isPreviousCoreAwardEarned,
      advancementStatus,
    } = advancementVerification.find(v => person.userId == v.userId) || {};
    dateCompleted = dateEarned || dateCompleted;
    const isCompleted = Boolean(
      dateCompleted || advancementStatus === 'Completed',
    );
    const isApproved = Boolean(
      leaderApprovedDate ||
        leaderApprovedUserId ||
        advancementStatus === 'LeaderApproved',
    );
    const isAwarded = Boolean(
      dateAwarded || awarded === 'True' || advancementStatus === 'Awarded',
    );
    const isAtLeastApproved = isApproved || isAwarded;
    const atLeastApprovedDate = leaderApprovedDate || dateAwarded;

    return {
      ...person,
      isCompleted,
      isApproved,
      isAtLeastApproved,
      isAwarded,
      dateCompleted,
      dateAwarded,
      hasAdvancementAwardedToday:
        atLeastApprovedDate && today.isSame(atLeastApprovedDate, 'day'),
      hasValidAge:
        person.isAdult ||
        !completionDate ||
        ageValidation({
          unitTypeId,
          completionDate: completionDay,
          age: person.age,
          dateOfBirth: person.dateOfBirth,
        }),
      leaderApprovedUserId,
      isPreviousRankEarned,
      isPreviousCoreAwardEarned,
      advancementStatus: getAdvancementStatus({
        isCompleted,
        isApproved,
        isAwarded,
        advancementStatus,
      }),
    };
  });
};

export const verifiedPersonsSel = createSelector(
  allPersonsSel,
  advancementVerificationSel,
  completionDateSel,
  activeUnitTypeIdSel,
  (persons, advancementVerification, completionDate, unitTypeId) =>
    getVerifiedPersons({
      persons,
      advancementVerification,
      completionDate,
      unitTypeId,
    }),
);

const getSelectedVerifiedPersons = (
  verifiedPersons,
  persons,
  selectedSuggestedUserIds,
) =>
  verifiedPersons.filter(person => {
    const isSelected = persons.some(({ userId }) => userId == person.userId);
    const isSelectedSuggested = selectedSuggestedUserIds.some(
      userId => userId == person.userId,
    );

    return isSelected || isSelectedSuggested;
  });

export const selectedVerifiedPersonsSel = createSelector(
  verifiedPersonsSel,
  personsSel,
  selectedSuggestedYouthIdsSel,
  (verifiedPersons, persons, selectedSuggestedUserIds) =>
    getSelectedVerifiedPersons(
      verifiedPersons,
      persons,
      selectedSuggestedUserIds,
    ),
);

const getSuggestedVerifiedYouth = (
  persons,
  suggestedPersons,
  selectedAdvStatus,
) => {
  const checkStatus = advancementStatus =>
    (selectedAdvStatus === advancementStatuses.AWARDED &&
      advancementStatus === advancementStatuses.APPROVED) ||
    advancementStatus === advancementStatuses.COMPLETED;

  return persons.filter(
    ({ userId, advancementStatus }) =>
      suggestedPersons.some(person => person.userId == userId) &&
      checkStatus(advancementStatus),
  );
};

export const suggestedVerifiedYouthSel = createSelector(
  verifiedPersonsSel,
  suggestedYouthSel,
  selectedAdvancementStatusSel,
  (persons, suggestedPersons, selectedAdvStatus) =>
    getSuggestedVerifiedYouth(persons, suggestedPersons, selectedAdvStatus),
);

export const additionalVerifiedPersonsSel = createSelector(
  additionalAllPersonsSel,
  additionalAdvancementVerificationSel,
  additionalCompletionDateSel,
  personsMinRankFirstClassRankSel,
  (persons, advancementVerification, completionDate, personsRanks) =>
    getVerifiedPersons({
      persons,
      advancementVerification,
      completionDate,

      unitTypeId: ProgramId.BOY_SCOUT,
    }).map(person => ({
      ...person,
      isInInvalidUnit:
        person.isNewPersonAdvancement && !personsRanks.includes(person.userId),
    })),
);

export const selectedAdditionalVerifiedPersonsSel = createSelector(
  additionalVerifiedPersonsSel,
  personsSel,
  selectedAdditionalSuggestedYouthIdsSel,
  (verifiedPersons, persons, suggestedUserIds) =>
    getSelectedVerifiedPersons(verifiedPersons, persons, suggestedUserIds),
);

export const suggestedAdditionalVerifiedYouthSel = createSelector(
  additionalVerifiedPersonsSel,
  additionalSuggestedYouthSel,
  selectedAdditionalAdvStatusSel,
  (persons, suggestedPersons, selectedAdvStatus) =>
    getSuggestedVerifiedYouth(persons, suggestedPersons, selectedAdvStatus),
);

export const havePersonsEagleRankSel = createSelector(personsRanksSel, ranks =>
  ranks.some(({ rank }) => rank === eagleScoutName),
);

export const advancementDictionaryLoadingSel = state => {
  const type = advancementTypeSel(state);

  return Boolean(type && !dictionaryLoadedSel(state, type));
};

export const selectedAdvStatusSel = createSelector(
  selectedAdvancementStatusSel,
  selectedAdvStatus => selectedAdvStatus,
);

export const isAdditionalTabActiveSel = createSelector(
  formTabSel,
  shouldDisplayTabsSel,
  (activeTab, shouldDisplayTabs) =>
    shouldDisplayTabs && activeTab == Program.BOY_SCOUT,
);

const makeSuggestedAdvStatusSelector = personsSel =>
  createSelector(personsSel, persons => {
    const hasApprovedStatus = persons.some(person => person.isApproved);
    return hasApprovedStatus
      ? advancementStatuses.AWARDED
      : advancementStatuses.APPROVED;
  });

export const suggestedAdvStatusSel = makeSuggestedAdvStatusSelector(
  selectedVerifiedPersonsSel,
);
export const additionalSuggestedAdvStatusSel = makeSuggestedAdvStatusSelector(
  selectedAdditionalVerifiedPersonsSel,
);
