import React from 'react';

import { SCOUTBOOK_LEGACY_URL } from '@config';
import styles from './ScoutbookLegacyPage.less';

const bodyStyle = `
  body {
    overflow-y: auto;
  }
`;

const ScoutbookLegacyPage = () => (
  <div>
    <style>{bodyStyle}</style>
    <iframe src={SCOUTBOOK_LEGACY_URL} className={styles.iframe} />
  </div>
);

export default ScoutbookLegacyPage;
