import React, { useCallback, useMemo } from 'react';

import { Avatar } from 'bsa-ui';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import { ActivityTypeIcon, Button, Tag } from '@shared';

import { goToEventsCreate } from '../../duck/actions';
import styles from './AddEventButton.less';

const AddEventButton = ({ selectedDate, onItemClick }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileSel);

  const handleItemCalendarClick = useCallback(() => {
    if (onItemClick) {
      dispatch(onItemClick);
    }
    dispatch(goToEventsCreate(selectedDate));
  }, [dispatch, selectedDate, onItemClick]);

  const eventIcon = useMemo(
    () => (
      <Avatar
        onClick={handleItemCalendarClick}
        className={styles.activityIcon}
        size={45}
      >
        <ActivityTypeIcon isEvent />
      </Avatar>
    ),
    [handleItemCalendarClick],
  );

  return (
    <React.Fragment>
      {isMobile ? (
        <div>
          <Tag>
            <FormattedMessage id="events.addEventButton" />
          </Tag>
          {eventIcon}
        </div>
      ) : (
        <Button
          onClick={handleItemCalendarClick}
          noBorder
          fitText
          ghost
          uppercase={false}
          className={styles.activityItemButton}
        >
          {eventIcon}
          <FormattedMessage id="events.addEventButton" />
        </Button>
      )}
    </React.Fragment>
  );
};

AddEventButton.propTypes = {
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onItemClick: PropTypes.func,
};

export default AddEventButton;
