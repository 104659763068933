import { purchaseOrdersModuleNamespace } from '../../common';

const namespace = purchaseOrdersModuleNamespace;

export const SHOW_CLOSED_PO_MODAL = `${namespace}/SHOW_CLOSED_PO_MODAL`;
export const showClosedPOModal = () => ({
  type: SHOW_CLOSED_PO_MODAL,
});
export const HIDE_CLOSED_PO_MODAL = `${namespace}/HIDE_CLOSED_PO_MODAL`;
export const hideClosedPOModal = () => ({
  type: HIDE_CLOSED_PO_MODAL,
});

export const SHOW_ADD_TO_CART_MODAL = `${namespace}/SHOW_ADD_TO_CART_MODAL`;
export const showAddToCartModal = () => ({
  type: SHOW_ADD_TO_CART_MODAL,
});
export const HIDE_ADD_TO_CART_MODAL = `${namespace}/HIDE_ADD_TO_CART_MODAL`;
export const hideAddToCartModal = () => ({
  type: HIDE_ADD_TO_CART_MODAL,
});

export const SHOW_ADD_ITEMS_MODAL = `${namespace}/SHOW_ADD_ITEMS_MODAL`;
export const showAddItemsModal = () => ({
  type: SHOW_ADD_ITEMS_MODAL,
});
export const HIDE_ADD_ITEMS_MODAL = `${namespace}/HIDE_ADD_ITEMS_MODAL`;
export const hideAddItemsModal = () => ({
  type: HIDE_ADD_ITEMS_MODAL,
});

export const SET_CART_TOKEN = `${namespace}/SET_CART_TOKEN`;
export const setCartToken = payload => ({
  type: SET_CART_TOKEN,
  payload: payload,
});

export const ADD_ITEMS_TO_CART_REQUEST = `${namespace}/ADD_ITEMS_TO_CART_REQUEST`;
export const addItemsToCartRequest = payload => ({
  type: ADD_ITEMS_TO_CART_REQUEST,
  payload,
});
export const ADD_ITEMS_TO_CART_RESPONSE = `${namespace}/ADD_ITEMS_TO_CART_RESPONSE`;
export const addItemsToCartResponse = payload => ({
  type: ADD_ITEMS_TO_CART_RESPONSE,
  payload,
});
export const ADD_ITEMS_TO_CART_ERROR = `${namespace}/ADD_ITEMS_TO_CART_ERROR`;
export const addItemsToCartError = payload => ({
  type: ADD_ITEMS_TO_CART_ERROR,
  payload,
});

export const CREATE_PURCHASE_ORDER_REQUEST = `${namespace}/CREATE_PURCHASE_ORDER_REQUEST`;
export const createPurchaseOrderRequest = payload => ({
  type: CREATE_PURCHASE_ORDER_REQUEST,
  payload,
});

export const SET_PURCHASE_ORDER_LOADING = `${namespace}/SET_PURCHASE_ORDER_LOADING`;
export const setCreatePurchaseOrderLoading = payload => ({
  type: SET_PURCHASE_ORDER_LOADING,
  payload,
});

export const SET_PURCHASE_ORDER_ID = `${namespace}/SET_PURCHASE_ORDER_ID`;
export const setPurchaseOrderId = payload => ({
  type: SET_PURCHASE_ORDER_ID,
  payload,
});

export const CREATE_PURCHASE_ORDER_RESPONSE = `${namespace}/CREATE_PURCHASE_ORDER_RESPONSE`;
export const createPurchaseOrderResponse = payload => ({
  type: CREATE_PURCHASE_ORDER_RESPONSE,
  payload,
});
export const CREATE_PURCHASE_ORDER_ERROR = `${namespace}/CREATE_PURCHASE_ORDER_ERROR`;
export const createPurchaseOrderError = error => ({
  type: CREATE_PURCHASE_ORDER_ERROR,
  payload: error,
});

export const SET_UPDATE_PURCHASE_ORDER_LOADING = `${namespace}/SET_UPDATE_PURCHASE_ORDER_LOADING`;
export const setUpdatePurchaseOrderLoading = payload => ({
  type: SET_UPDATE_PURCHASE_ORDER_LOADING,
  payload,
});

export const UPDATE_PURCHASE_ORDER_REQUEST = `${namespace}/UPDATE_PURCHASE_ORDER_REQUEST`;
export const updatePurchaseOrderRequest = payload => ({
  type: UPDATE_PURCHASE_ORDER_REQUEST,
  payload,
});
export const UPDATE_PURCHASE_ORDER_RESPONSE = `${namespace}/UPDATE_PURCHASE_ORDER_RESPONSE`;
export const updatePurchaseOrderResponse = payload => ({
  type: UPDATE_PURCHASE_ORDER_RESPONSE,
  payload,
});
export const UPDATE_PURCHASE_ORDER_ERROR = `${namespace}/UPDATE_PURCHASE_ORDER_ERROR`;
export const updatePurchaseOrderError = error => ({
  type: UPDATE_PURCHASE_ORDER_ERROR,
  payload: error,
});

export const DELETE_FROM_PURCHASE_ORDER_REQUEST = `${namespace}/DELETE_FROM_PURCHASE_ORDER_REQUEST`;
export const deleteFromPurchaseOrderRequest = payload => ({
  type: DELETE_FROM_PURCHASE_ORDER_REQUEST,
  payload,
});
export const DELETE_FROM_PURCHASE_ORDER_RESPONSE = `${namespace}/DELETE_FROM_PURCHASE_ORDER_RESPONSE`;
export const deleteFromPurchaseOrderResponse = payload => ({
  type: DELETE_FROM_PURCHASE_ORDER_RESPONSE,
  payload,
});
export const DELETE_FROM_PURCHASE_ORDER_ERROR = `${namespace}/DELETE_FROM_PURCHASE_ORDER_ERROR`;
export const deleteFromPurchaseOrderError = error => ({
  type: DELETE_FROM_PURCHASE_ORDER_ERROR,
  payload: error,
});

export const DELETE_FROM_ORDER_AND_RETRY = `${namespace}/DELETE_FROM_ORDER_AND_RETRY`;
export const deleteFromOrderAndRetry = payload => ({
  type: DELETE_FROM_ORDER_AND_RETRY,
  payload,
});
export const DELETE_FROM_ORDER_AND_RETRY_ERROR = `${namespace}/DELETE_FROM_ORDER_AND_RETRY_ERROR`;
export const deleteFromOrderAndRetryError = error => ({
  type: DELETE_FROM_ORDER_AND_RETRY_ERROR,
  payload: error,
});

export const ADD_TO_PURCHASE_ORDER_REQUEST = `${namespace}/ADD_TO_PURCHASE_ORDER_REQUEST`;
export const addToPurchaseOrderRequest = payload => ({
  type: ADD_TO_PURCHASE_ORDER_REQUEST,
  payload,
});
export const ADD_TO_PURCHASE_ORDER_RESPONSE = `${namespace}/ADD_TO_PURCHASE_ORDER_RESPONSE`;
export const addToPurchaseOrderResponse = payload => ({
  type: ADD_TO_PURCHASE_ORDER_RESPONSE,
  payload,
});
export const ADD_TO_PURCHASE_ORDER_ERROR = `${namespace}/ADD_TO_PURCHASE_ORDER_ERROR`;
export const addToPurchaseOrderError = error => ({
  type: ADD_TO_PURCHASE_ORDER_ERROR,
  payload: error,
});

export const FETCH_ALL_PURCHASE_ORDERS_REQUEST = `${namespace}/FETCH_ALL_PURCHASE_ORDERS_REQUEST`;
export const fetchAllPurchaseOrdersRequest = () => ({
  type: FETCH_ALL_PURCHASE_ORDERS_REQUEST,
});
export const FETCH_ALL_PURCHASE_ORDERS_RESPONSE = `${namespace}/FETCH_ALL_PURCHASE_ORDERS_RESPONSE`;
export const fetchAllPurchaseOrdersResponse = payload => ({
  type: FETCH_ALL_PURCHASE_ORDERS_RESPONSE,
  payload,
});
export const FETCH_ALL_PURCHASE_ORDERS_ERROR = `${namespace}/FETCH_ALL_PURCHASE_ORDERS_ERROR`;
export const fetchAllPurchaseOrdersError = error => ({
  type: FETCH_ALL_PURCHASE_ORDERS_ERROR,
  payload: error,
});

export const FETCH_PURCHASE_ORDER_DETAILS_REQUEST = `${namespace}/FETCH_PURCHASE_ORDER_DETAILS_REQUEST`;
export const fetchPurchaseOrderDetailsRequest = payload => ({
  type: FETCH_PURCHASE_ORDER_DETAILS_REQUEST,
  payload,
});
export const FETCH_PURCHASE_ORDER_DETAILS_RESPONSE = `${namespace}/FETCH_PURCHASE_ORDER_DETAILS_RESPONSE`;
export const fetchPurchaseOrderDetailsResponse = payload => ({
  type: FETCH_PURCHASE_ORDER_DETAILS_RESPONSE,
  payload,
});
export const FETCH_PURCHASE_ORDER_DETAILS_ERROR = `${namespace}/FETCH_PURCHASE_ORDER_DETAILS_ERROR`;
export const fetchPurchaseOrderDetailsError = error => ({
  type: FETCH_PURCHASE_ORDER_DETAILS_ERROR,
  payload: error,
});

export const FETCH_DETAILS_AND_RETRY_REQUEST = `${namespace}/FETCH_DETAILS_AND_RETRY_REQUEST`;
export const fetchDetailsAndRetryRequest = payload => ({
  type: FETCH_DETAILS_AND_RETRY_REQUEST,
  payload,
});
export const FETCH_DETAILS_AND_RETRY_RESPONSE = `${namespace}/FETCH_DETAILS_AND_RETRY_RESPONSE`;
export const fetchDetailsAndRetryResponse = payload => ({
  type: FETCH_DETAILS_AND_RETRY_RESPONSE,
  payload,
});
export const FETCH_DETAILS_AND_RETRY_ERROR = `${namespace}/FETCH_DETAILS_AND_RETRY_ERROR`;
export const fetchDetailsAndRetryError = error => ({
  type: FETCH_DETAILS_AND_RETRY_ERROR,
  payload: error,
});

export const DELETE_PURCHASE_ORDER_REQUEST = `${namespace}/DELETE_PURCHASE_ORDER_REQUEST`;
export const deletePurchaseOrderRequest = payload => ({
  type: DELETE_PURCHASE_ORDER_REQUEST,
  payload,
});
export const DELETE_PURCHASE_ORDER_RESPONSE = `${namespace}/DELETE_PURCHASE_ORDER_RESPONSE`;
export const deletePurchaseOrderResponse = payload => ({
  type: DELETE_PURCHASE_ORDER_RESPONSE,
  payload,
});
export const DELETE_PURCHASE_ORDER_ERROR = `${namespace}/DELETE_PURCHASE_ORDER_ERROR`;
export const deletePurchaseOrderError = error => ({
  type: DELETE_PURCHASE_ORDER_ERROR,
  payload: error,
});
