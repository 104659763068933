import {
  ProgramId,
  advancementTypes,
  bobcatRankOrder,
  firstClassRankOrder,
  ranksOrder,
  venturingCoreAwardsIds,
} from '../constants';
import isEagleScoutRank from './isEagleScoutRank';
import isHierarchicalAdvancement from './isHierarchicalAdvancement';

const isRegularCubScoutAdvancementUnapprovable = (advancement, unitTypeId) =>
  unitTypeId != ProgramId.CUB_SCOUT &&
  advancement.programId == ProgramId.CUB_SCOUT;

const isScoutsBSAAdvancementApprovable = ({ advancement, unitTypeId }) =>
  (unitTypeId == ProgramId.VENTURING || unitTypeId == ProgramId.SEA_SCOUT) &&
  advancement.programId == ProgramId.BOY_SCOUT;

// Cub scout ranks has their own business logic, first you need to approve Bobcat before approving higher level ranks
// after approving Bobcat you can approve any higher level rank in no particular order
const canApproveCubScoutRank = ({
  advancement,
  highestHierarchicalAdvancement,
}) =>
  ranksOrder[advancement.id] > bobcatRankOrder
    ? ranksOrder[highestHierarchicalAdvancement.id] >= bobcatRankOrder
    : true;

const isNonScoutBSAAdvancement = advancement =>
  advancement.programId == ProgramId.SEA_SCOUT ||
  advancement.programId == ProgramId.VENTURING;

const getHierarchicalAdvancementLevel = (
  highestHierarchicalAdvancement,
  advancement,
) =>
  highestHierarchicalAdvancement.level ||
  (advancement.programId == ProgramId.VENTURING &&
  advancement.advancementType === advancementTypes.AWARDS
    ? venturingCoreAwardsIds[0] - 1
    : 0);

/**
 * Returns TRUE if the advancement is approvable, FALSE if that action is not allowed
 *
 * @param {Object} params.advancement - the selected advancement
 * @param {Object} params.highestHierarchicalAdvancement - the latest approved/awarded hierarchical advancement
 * @param {Object} params.unitTypeId - unit type id of current unit
 * @param {Object} params.scoutsBSAHighestRank - the latest approved/awarded rank for the Scouts BSA unit
 * @param {boolean} params.treatEagleScoutAsRegular - if TRUE Eagle Scout Ranks will be treated like regular ranks
 *
 */
export default ({
  advancement,
  highestHierarchicalAdvancement,
  unitTypeId,
  scoutsBSAHighestRank = {},
  treatEagleScoutAsRegular = false,
}) => {
  if (!isHierarchicalAdvancement(advancement)) {
    if (!advancement.programId) {
      return true;
    } else if (
      isRegularCubScoutAdvancementUnapprovable(advancement, unitTypeId)
    ) {
      return false;
    } else if (
      isScoutsBSAAdvancementApprovable({
        advancement,
        unitTypeId,
      })
    ) {
      return ranksOrder[scoutsBSAHighestRank.id] >= firstClassRankOrder;
    }
    return advancement.programId == unitTypeId;
  }

  if (!treatEagleScoutAsRegular && isEagleScoutRank(advancement)) {
    return false;
  }
  if (
    advancement.programId == ProgramId.CUB_SCOUT &&
    unitTypeId != ProgramId.CUB_SCOUT
  ) {
    return false;
  }
  if (!highestHierarchicalAdvancement) {
    return true;
  }
  if (unitTypeId == ProgramId.CUB_SCOUT) {
    return canApproveCubScoutRank({
      advancement,
      highestHierarchicalAdvancement,
    });
  }
  if (
    isScoutsBSAAdvancementApprovable({
      unitTypeId,
      advancement,
    })
  ) {
    return (
      ranksOrder[scoutsBSAHighestRank.id] >= firstClassRankOrder &&
      advancement.level - 1 <= (scoutsBSAHighestRank.level || 0)
    );
  }

  if (
    unitTypeId == ProgramId.BOY_SCOUT &&
    isNonScoutBSAAdvancement(advancement)
  ) {
    return false;
  }

  return (
    (!advancement.programId || advancement.programId == unitTypeId) &&
    advancement.level - 1 <=
      getHierarchicalAdvancementLevel(
        highestHierarchicalAdvancement,
        advancement,
      )
  );
};
