import { default as isValueTruthy } from './isValueTruthy';

export default (sbProfile, countries = [], states = [], suffixes = []) => {
  let formattedSuffix = '';

  if (sbProfile && sbProfile.profile) {
    const foundSuffix = suffixes.find(
      suffix => sbProfile.profile.nameSuffix === suffix.short,
    );
    formattedSuffix = foundSuffix ? foundSuffix.name : '';
  }

  let formattedAddresses = [];

  if (sbProfile && sbProfile.addresses.length) {
    const sbAddress = sbProfile.addresses[0];
    const foundState = states.find(state => state.short === sbAddress.state);
    const foundCountry =
      sbProfile.profile && sbProfile.profile.country
        ? countries.find(country => country.short === sbProfile.profile.country)
        : undefined;
    const countryId = foundCountry ? foundCountry.id : '616';
    formattedAddresses = [
      {
        id: '42',
        address1: sbAddress.address1,
        address2: sbAddress.address2,
        city: sbAddress.city,
        zipCode: sbAddress.zipCode,
        countryId,
        typeId: '65',
        type: 'Home',
        isPrimary: 'True',
        stateId: foundState ? foundState.id : foundState,
        state: foundState ? foundState.name : foundState,
      },
    ];
  }

  let formattedEmails = [];

  if (sbProfile && sbProfile.emails.length) {
    const sbEmail = sbProfile.emails[0];
    formattedEmails = [
      {
        ...sbEmail,
        id: '42',
        isPrimary: 'True',
        typeId: '1',
        type: 'Home Email',
        isUndeliverable: false,
      },
    ];
  }

  let formattedPhones = [];

  if (
    sbProfile &&
    sbProfile.profile &&
    (sbProfile.profile.homePhone ||
      sbProfile.profile.mobilePhone ||
      sbProfile.profile.workPhone)
  ) {
    const { homePhone, mobilePhone, workPhone, mobilePhoneCarrier } =
      sbProfile.profile;
    const getAreaCode = phone => phone.slice(0, 3);
    const getPrefix = phone => phone.slice(3, 6);
    const getLineNumber = phone => phone.slice(6, 10);
    if (homePhone) {
      const areaCode = getAreaCode(homePhone);
      const prefix = getPrefix(homePhone);
      const lineNumber = getLineNumber(homePhone);
      const phoneObj = {
        areaCode,
        country: 'USA',
        countryCode: '001',
        countryId: 636,
        extension: '',
        id: '42',
        isPrimary: 'True',
        lineNumber,
        mobilePhoneCarrierId: null,
        prefix,
        type: 'Home',
        typeId: '3',
      };
      formattedPhones.push(phoneObj);
    }
    if (mobilePhone) {
      const areaCode = getAreaCode(mobilePhone);
      const prefix = getPrefix(mobilePhone);
      const lineNumber = getLineNumber(mobilePhone);
      const phoneObj = {
        areaCode,
        country: 'USA',
        countryCode: '001',
        countryId: 636,
        extension: '',
        id: '422',
        isPrimary: !homePhone ? 'True' : 'False',
        lineNumber,
        mobilePhoneCarrier: mobilePhoneCarrier ? mobilePhoneCarrier : undefined,
        mobilePhoneCarrierId: null,
        prefix,
        type: 'Mobile',
        typeId: '4',
      };
      formattedPhones.push(phoneObj);
    }
    if (workPhone) {
      const areaCode = getAreaCode(workPhone);
      const prefix = getPrefix(workPhone);
      const lineNumber = getLineNumber(workPhone);
      const phoneObj = {
        areaCode,
        country: 'USA',
        countryCode: '001',
        countryId: 636,
        extension: '',
        id: '423',
        isPrimary: !homePhone && !mobilePhone ? 'True' : 'False',
        lineNumber,
        mobilePhoneCarrierId: null,
        prefix,
        type: 'Work',
        typeId: '5',
      };
      formattedPhones.push(phoneObj);
    }
  }

  let sbExtendedProfile = {};

  if (sbProfile && sbProfile.profile) {
    const {
      oaActive,
      oaBrotherhoodDate,
      oaElectionDate,
      oaMember,
      oaMemberNumber,
      oaOrdealDate,
      oaVigilDate,
      swimmingClassification,
      swimmingClassificationDate,
      talentReleaseDate,
      talentReleaseId,
      annualHealthRecordABDate,
      annualHealthRecordCDate,
    } = sbProfile.profile;

    sbExtendedProfile = {
      oaActive: isValueTruthy(oaActive),
      oaMember: isValueTruthy(oaMember),
      oaBrotherhoodDate,
      oaElectionDate,
      oaMemberNumber,
      oaOrdealDate,
      oaVigilDate,
      swimmingClassification,
      swimmingClassificationDate,
      talentReleaseDate,
      talentReleaseUserId: talentReleaseId,
      annualHealthRecordABDate,
      annualHealthRecordCDate,
    };
  }

  // TODO: add school and grade

  return {
    ...sbProfile,
    nameSuffix: formattedSuffix,
    addresses: formattedAddresses,
    emails: formattedEmails,
    phones: formattedPhones,
    sbExtendedProfile: sbExtendedProfile,
  };
};
