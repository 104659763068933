import { createSelector } from 'reselect';

import { unitInfoSel } from '@unit';
import { payloadSel } from '@location';
import { meritBadgesSel, packRosterItemsSel } from '@shared';
import { moduleName, mbcFilters } from '../constants';
import { meritBadgeCounselorsModuleSel } from '../../common';

const moduleSel = state => meritBadgeCounselorsModuleSel(state)[moduleName];

export const itemsSel = state => moduleSel(state).items;
export const totalSel = state => moduleSel(state).total;
export const loadingSel = state => moduleSel(state).loading;
export const pageSel = state => moduleSel(state).page;
export const pageSizeMobileSel = state => moduleSel(state).pageSizeMobile;
export const expandedRowSel = state => moduleSel(state).expandedRow;
export const searchSel = state => moduleSel(state).search;
export const unitZipcodeSel = state => {
  const { primaryAddress = {} } = unitInfoSel(state);
  return primaryAddress.zip5 || '';
};
export const filtersSel = state => moduleSel(state).filter;
export const zipCodeFilterSel = state => filtersSel(state)[mbcFilters.ZIP_CODE];
export const proximityFilterSel = state =>
  filtersSel(state)[mbcFilters.PROXIMITY];
export const availabilityFilterSel = state =>
  filtersSel(state)[mbcFilters.AVAILABILITY];

export const mbcToAssignSel = state => moduleSel(state).mbcToAssign;
export const assignModalOpenSel = state => moduleSel(state).assignModalOpen;
export const assignLoadingSel = state => moduleSel(state).assignLoading;

export const meritBadgeSel = createSelector(
  payloadSel,
  meritBadgesSel,
  ({ advancementId }, meritBadges) =>
    meritBadges.find(({ id }) => id == advancementId),
);
export const youthSel = createSelector(
  payloadSel,
  packRosterItemsSel,
  ({ userId }, roster) => roster.find(({ userId: id }) => id == userId),
);
