import React from 'react';
import cn from 'classnames';

import Img from '../Img';
import styles from './Avatar.less';
import LoadingPlaceholder from './components/LoadingPlaceholder';

type Props = {
  bordered?: boolean;
  shadow?: boolean;
  src?: string;
  placeholder?: React.ReactNode;
  title?: string;
  loading?: boolean;
  id?: string;
  className: string;
};

const Avatar: React.FC<Props> = ({
  bordered = false,
  shadow = false,
  src,
  placeholder,
  title,
  loading,
  id,
  className,
}) => {
  const classNames = cn(
    styles.container,
    {
      [styles.bordered]: bordered,
      [styles.shadow]: shadow,
    },
    className,
  );

  return (
    <div id={id} className={classNames} title={title}>
      <div className={styles.placeholder}>
        {loading ? <LoadingPlaceholder /> : placeholder}
      </div>
      {src && <Img src={src} className={styles.image} />}
    </div>
  );
};

export default Avatar;
