import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, DateView, Vr } from '@shared';
import { isMobileSel } from '@screen';
import {
  pendingRequestsCountSel,
  validPendingRequestsCountSel,
  syncAll,
  syncItem,
  closeSyncModal,
  pendingYouthsSel,
  selectedYouthAdvancementsSel,
  selectYouth,
  selectedYouthIdSel,
  offlineSel,
  lastOnlineSel,
  editItem,
  removeItem,
  verifyPendingAdvancementsRequest,
  failedPendingItemIdsSel,
} from '../../duck';
import YouthList from './YouthList';
import PendingItemsContent from './PendingItemsContent';
import styles from './SyncPendingModal.less';

class SyncPendingContent extends React.PureComponent {
  componentDidMount() {
    this.verifyItems();
  }

  componentDidUpdate({ isOffline: prevIsOffline }) {
    if (prevIsOffline !== this.props.isOffline) {
      this.verifyItems();
    }
  }

  verifyItems = () => {
    if (!this.props.isOffline) {
      this.props.onVerifyItems();
    }
  };

  handleRemoveRequest = requestId => requestId;

  handleSelectYouth = userId => {
    this.props.onSelectYouth(userId);
  };

  handleClose = () => {
    this.props.onCloseModal();
  };

  handleSyncAll = () => {
    this.props.onSyncAll();
  };

  render() {
    const {
      totalItemCount,
      validItemCount,
      lastRetrievedDate,
      pendingYouth,
      selectedYouthRequests,
      selectedYouthId,
      isMobile,
      isOffline,
      failedItemIds,

      onSyncItem,
      onEditItem,
      onRemoveItem,
    } = this.props;

    const noItems = validItemCount == 0;

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <YouthList
            youths={pendingYouth}
            selectedYouthId={selectedYouthId}
            itemCount={totalItemCount}
            onSelectYouth={this.handleSelectYouth}
            isMobile={isMobile}
          />
          <Vr className={styles.vr} />
          <PendingItemsContent
            selectedYouthId={selectedYouthId}
            requests={selectedYouthRequests}
            isMobile={isMobile}
            failedItemIds={failedItemIds}
            isOffline={isOffline}
            onSyncItem={onSyncItem}
            onEditItem={onEditItem}
            onRemoveItem={onRemoveItem}
          />
        </div>
        <div className={styles.bottom}>
          {isOffline && (
            <span className={styles.lastRetrieved}>
              <FormattedMessage
                id="offline.SyncPendingModal.lastRetrieved"
                values={{ value: <DateView value={lastRetrievedDate} /> }}
              />
            </span>
          )}
          <div className={styles.buttons}>
            <Button
              type="primary"
              shadow
              color="success"
              size="large"
              onClick={this.handleSyncAll}
              className={styles.button}
              disabled={isOffline || noItems}
            >
              <FormattedMessage
                id="offline.SyncPendingModal.syncButton"
                values={{ value: validItemCount }}
              />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SyncPendingContent.propTypes = {
  totalItemCount: PropTypes.number.isRequired,
  validItemCount: PropTypes.number.isRequired,
  lastRetrievedDate: PropTypes.string.isRequired,
  pendingYouth: PropTypes.array.isRequired,
  selectedYouthId: PropTypes.number.isRequired,
  selectedYouthRequests: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  failedItemIds: PropTypes.array.isRequired,

  onCloseModal: PropTypes.func.isRequired,
  onSyncAll: PropTypes.func.isRequired,
  onSyncItem: PropTypes.func.isRequired,
  onSelectYouth: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onVerifyItems: PropTypes.func.isRequired,
};

const mapState = state => ({
  totalItemCount: pendingRequestsCountSel(state),
  validItemCount: validPendingRequestsCountSel(state),
  lastRetrievedDate: lastOnlineSel(state),
  pendingYouth: pendingYouthsSel(state),
  selectedYouthId: selectedYouthIdSel(state),
  selectedYouthRequests: selectedYouthAdvancementsSel(state),
  isMobile: isMobileSel(state),
  isOffline: offlineSel(state),
  failedItemIds: failedPendingItemIdsSel(state),
});

const mapDispatch = dispatch => ({
  onSyncAll: () => dispatch(syncAll()),
  onSyncItem: itemId => dispatch(syncItem(itemId)),
  onCloseModal: () => dispatch(closeSyncModal()),
  onSelectYouth: personId => dispatch(selectYouth({ personId })),
  onEditItem: (itemId, date) => dispatch(editItem({ itemId, date })),
  onRemoveItem: itemId => dispatch(removeItem(itemId)),
  onVerifyItems: () => dispatch(verifyPendingAdvancementsRequest()),
});

export default connect(mapState, mapDispatch)(SyncPendingContent);
