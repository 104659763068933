import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
// eslint-disable-next-line import/extensions
import userPermissionsJson from './userPermissions.json';

const getUserPermissions$ = () =>
  Observable.of(userPermissionsJson).delay(1000);

const getUsers$ = () =>
  Observable.of([
    { id: 1, memberId: 99912, name: 'Peter' },
    { id: 2, memberId: 99120, name: 'Micheal' },
    { id: 3, memberId: 99129, name: 'Paull' },
    { id: 4, memberId: 99039, name: 'Eduardo' },
  ]);

const getRoles$ = () =>
  Observable.of([
    { id: 1, name: 'Permission 1', description: 'Description 1 goes here' },
    { id: 2, name: 'Permission 2', description: 'Description 2 goes here' },
    { id: 3, name: 'Permission 3', description: 'Description 3 goes here' },
  ]);

export default { getUserPermissions$, getUsers$, getRoles$ };
