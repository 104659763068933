import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { isLoggedInSel } from '@user';

const ClientTZ = () => {
  const isLoggedIn = useSelector(isLoggedInSel);

  useEffect(() => {
    const cookies = new Cookies();
    if (isLoggedIn) {
      cookies.set('clientTZ', moment.tz.guess(), {
        // this cookie will only be visible in this domain
        domain: '.scouting.org',
        path: '/',
        encode: v => v,
      });
    } else {
      cookies.remove('clientTZ');
    }
  }, [isLoggedIn]);

  return null;
};

export default ClientTZ;
