import { random, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import { organizationPositionsSel } from '@context';
import { selectedOrganizationSel } from '@shared';

import { formatEventUnit } from '../../events/utils';
import { moduleName } from '../constants';
import { sortBySubUnitNumber } from '../duck/utils';

const moduleSel = state => state[moduleName];

export const activeTabSel = state => moduleSel(state).activeTab;
export const unitsAdultsSel = state => moduleSel(state).unitsAdults;
export const unitsYouthsSel = state => moduleSel(state).unitsYouths;
export const allSubUnitsSel = state => moduleSel(state).allSubUnits || [];
export const orgPositionsSel = state => moduleSel(state).orgPositions;
export const tempRegisteredPositionsListSel = state =>
  moduleSel(state).tempRegisteredPositionsList;

export const isLoadingAssignFunctionalSel = state =>
  moduleSel(state).isLoadingAssignFunctional;
export const isLoadingGetOrgPositionsSel = state =>
  moduleSel(state).isLoadingGetOrgPositions;
export const isLoadingExpireAssignFunctionalSel = state =>
  moduleSel(state).isLoadingExpireAssignFunctional;
export const isLoadingReassignPositionsSel = state =>
  moduleSel(state).isLoadingReassignPositions;

export const permissionsEditModalOpenSel = state =>
  moduleSel(state).permissionsEditModalOpen;

export const selectedPersonSel = state => moduleSel(state).selectedPerson;
export const fromPositionSel = state => moduleSel(state).fromPosition;
export const toPositionSel = state => moduleSel(state).toPosition;
export const isDropSel = state => moduleSel(state).isDrop;

export const registeredPositionsSel = createSelector(
  orgPositionsSel,
  orgPositions => {
    const { Positions } = orgPositions;

    if (Positions) {
      return Positions.filter(
        ({ isFunctional, isYouthPosition }) =>
          !isFunctional && !isYouthPosition,
      );
    }

    return [];
  },
);

export const orgFunctionalPositions = createSelector(
  orgPositionsSel,
  orgPositionsSel => {
    const { Positions } = orgPositionsSel;

    if (Positions) {
      return Positions.filter(
        ({ isFunctional, isYouthPosition }) => isFunctional && !isYouthPosition,
      );
    }

    return [];
  },
);

export const processUnitsAdultsSel = createSelector(
  unitsAdultsSel,
  unitsAdults => unitsAdults,
);

export const processUnitsYouthsSel = createSelector(
  unitsYouthsSel,
  unitsYouths => unitsYouths,
);

export const personYouthsPermissionsSel = createSelector(
  unitsYouthsSel,
  unitsYouths => {
    // 3 - Full Control, 4 - View Profile, 5 - Edit Profile, 6 - View Advancement, 7 - Edit Advancement
    // Permissions: Math.floor(Math.random() * (max - min + 1)) + min
    const updatedList = unitsYouths.map(youth => ({
      ...youth,
      permissions: [
        { permissionID: Math.floor(random(0, 1, true) * (7 - 3 + 1)) + 3 },
        { permissionID: Math.floor(random(0, 1, true) * (7 - 3 + 1)) + 3 },
        { permissionID: Math.floor(random(0, 1, true) * (7 - 3 + 1)) + 3 },
      ],
    }));

    return updatedList;
  },
);

export const allUnitsSubunitsSel = createSelector(
  organizationPositionsSel,
  allSubUnitsSel,
  (units, allSubUnits) => {
    const formattedUnitsSubunits = [];

    const validUnits = uniqBy(
      units.filter(unit => unit.unitId),
      'unitId',
    );

    validUnits.forEach(unit => {
      formattedUnitsSubunits.push(formatEventUnit(unit));

      const foundSubUnits = allSubUnits.find(
        subUnits =>
          subUnits && subUnits.length && subUnits[0].unitId === +unit.unitId,
      );

      if (foundSubUnits) {
        foundSubUnits.forEach(subUnit => {
          const isDen = !!subUnit.denType;
          formattedUnitsSubunits.push({
            id: subUnit.subUnitId,
            name: isDen
              ? `${subUnit.denType} ${subUnit.subUnitName}`
              : subUnit.subUnitName,
            isDen,
            unitId: subUnit.unitId,
          });
        });
      }
    });

    return formattedUnitsSubunits;
  },
);

export const selectedUnitsSubUnitsSel = createSelector(
  selectedOrganizationSel,
  allSubUnitsSel,
  (selectedOrganization, allSubUnits) => {
    const { unitId, unitTypeId } = selectedOrganization;
    const concatSubUnits = allSubUnits.reduce(
      (accumulator, currentArray) => accumulator.concat(currentArray),
      [],
    );

    const includedSubUnits = concatSubUnits.filter(sub => unitId == sub.unitId);

    const sorted = sortBySubUnitNumber(includedSubUnits);
    const addIsDen = sorted.map(sub => ({
      ...sub,
      subUnitNameFull:
        unitTypeId === 1
          ? sub.denType + ' ' + sub.subUnitName
          : sub.subUnitName,
      isDen: unitTypeId === 1 ? true : false,
    }));
    const unassigned = {
      subUnitNameFull: 'Unassigned',
      subUnitName: 'Unassigned',
      unitId: null,
      subUnitId: null,
      isDen: unitTypeId === 1 ? true : false,
    };
    const updatedList = [...addIsDen, unassigned];

    return updatedList;
  },
);
