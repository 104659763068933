import { lazier } from '@shared';

import RsvpEventButton from './RsvpEventButton';
import AddEventButton from './AddEventButton';
import EventActionsMenu from './EventActionsMenu';

export const CreateEventPage = lazier(() => import('./CreateEventPage'));
export const AddAdvancement = lazier(() => import('./AddAdvancement'));
export const EditEventPage = lazier(() => import('./EditEventPage'));
export const RSVPPage = lazier(() => import('./RSVPPage'));

export const EventDetails = lazier(() => import('./EventDetails'));

export const ActivityEventDetails = lazier(() =>
  import('./ActivityEventDetails'),
);

export { RsvpEventButton, AddEventButton, EventActionsMenu };
