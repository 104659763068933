import {
  reportsModuleName,
  basicReportsModuleName,
  historyReportsModuleName,
  advancementReportModuleName,
} from './constants';

export const reportsModuleSel = state => {
  /** @type {import('../basicReports/duck/types').ReportsState} */
  const moduleState = state[reportsModuleName];

  return moduleState;
};

/**
 * @returns {import('../basicReports/duck/types').ReportsState['basicRerpots']}
 */
export const basicReportsModuleSel = state =>
  reportsModuleSel(state)[basicReportsModuleName];
export const historyReportsModuleSel = state =>
  reportsModuleSel(state)[historyReportsModuleName];
export const advancementReportModuleSel = state =>
  reportsModuleSel(state)[advancementReportModuleName];
