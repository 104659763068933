export const moduleName = 'localization';

export const locales = {
  en: 'enUS',
  es: 'esMX',
};

export default {
  locales,
};
