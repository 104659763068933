import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { organizationGuidSel } from '@context';
import { toastService } from '@toasts';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  CANCEL_FILE_REQUEST,
  UPLOAD_FILE_CANCEL,
  UPLOAD_FILE_REQUEST,
  cancelFileError,
  cancelFileResponse,
  uploadFileError,
  uploadFileProgress,
  uploadFileResponse,
} from './actions';
import { cancelPendingFile$, uploadFile$ } from './services';

// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/abort
const requestAborted = (request = {}) => {
  const { readyState, status } = request;
  return readyState === XMLHttpRequest.DONE && status === 0;
};

const uploadFileEpic$ = (action$, state$) =>
  action$.ofType(UPLOAD_FILE_REQUEST).switchMap(({ payload: file }) => {
    const organizationGuid = organizationGuidSel(state$.value);
    const progressSubscriber = new Subject();
    const request$ = uploadFile$(file, organizationGuid, progressSubscriber)
      .map(() => uploadFileResponse(file))
      .takeUntil(action$.ofType(UPLOAD_FILE_CANCEL));

    return progressSubscriber
      .map(({ loaded, total }) => ({
        percent: (loaded / total) * 100,
        loaded,
        total,
      }))
      .map(progress => uploadFileProgress(progress))
      .merge(request$)
      .catch(errEvent => {
        let error = errEvent;
        // convert error event to AjaxError so it can be properly caught and reported
        if (
          errEvent &&
          errEvent.target &&
          errEvent.target instanceof XMLHttpRequest
        ) {
          error = new AjaxError(
            errEvent.target.statusText,
            errEvent.target,
            errEvent.target,
          );
        }
        throw error;
      })
      .catchAndReport(
        err => {
          let action;
          let { request, response } = err || {};
          request = request || {};
          response = response || {};

          if (requestAborted(request)) {
            action = { type: '@@noop' };
          } else {
            action = uploadFileError({
              errorDesc:
                response.errorDesc ||
                response.statusText ||
                response.status ||
                '',
            });
          }

          return Observable.of(action);
        },
        { reportAjaxErrors: true },
      );
  });

const cancelPendingFileEpic$ = action$ =>
  action$.ofType(CANCEL_FILE_REQUEST).mergeMap(({ payload }) =>
    cancelPendingFile$(payload.id)
      .do(() => toastService.success(payload.successMsg))
      .map(() => cancelFileResponse(payload.id))
      .catchAndReport(() => Observable.of(cancelFileError(payload.id))),
  );

export default combineEpics(uploadFileEpic$, cancelPendingFileEpic$);
