import { gtm, goToYouthAdvRequirements, advancementTypes } from '@shared';
import { moduleNamespace as namespace } from '../constants';

export const ROUTE_ASSIGN_MBC = `${namespace}/ROUTE_ASSIGN_MBC`;
export const goToAssignMbc = ({ userId, advancementId }) => ({
  type: ROUTE_ASSIGN_MBC,
  payload: {
    userId,
    advancementId,
  },
});

export const goBack = ({ userId, advancementId }) =>
  goToYouthAdvRequirements({
    userId,
    advancementId,
    advancementType: advancementTypes.MERIT_BADGES,
  });

export const MBC_REQUEST = `${namespace}/MBC_REQUEST`;
export const mbcRequest = () => ({
  type: MBC_REQUEST,
  gtm: {
    category: gtm.categories.MERIT_BADGE_COUNSELORS,
  },
});

export const MBC_RESPONSE = `${namespace}/MBC_RESPONSE`;
export const mbcResponse = roster => ({
  type: MBC_RESPONSE,
  payload: roster,
});

export const MBC_ERROR = `${namespace}/MBC_ERROR`;
export const mbcError = error => ({
  type: MBC_ERROR,
  payload: error,
});

export const SET_PAGE = `${namespace}/SET_PAGE`;
export const setPage = page => ({
  type: SET_PAGE,
  payload: page,
});

export const SET_EXPANDED_ROW = `${namespace}/SET_EXPANDED_ROW`;
export const setExpandedRow = (key = '') => ({
  type: SET_EXPANDED_ROW,
  payload: key,
});

export const SET_PAGE_SIZE_MOBILE = `${namespace}/SET_PAGE_SIZE_MOBILE`;
export const setPageSizeMobile = pageSize => ({
  type: SET_PAGE_SIZE_MOBILE,
  payload: pageSize,
});

export const SET_FILTER = `${namespace}/SET_FILTER`;
export const setFilter = filter => ({
  type: SET_FILTER,
  payload: filter,
});

export const SET_SEARCH = `${namespace}/SET_SEARCH`;
export const setSearch = search => ({
  type: SET_SEARCH,
  payload: search,
});

export const OPEN_ASSIGN_MODAL = `${namespace}/OPEN_ASSIGN_MODAL`;
export const openAssignModal = mbcData => ({
  type: OPEN_ASSIGN_MODAL,
  payload: mbcData,
});

export const CLOSE_ASSIGN_MODAL = `${namespace}/CLOSE_ASSIGN_MODAL`;
export const closeAssignModal = () => ({
  type: CLOSE_ASSIGN_MODAL,
});

export const ASSIGN_MBC_REQUEST = `${namespace}/ASSIGN_MBC_REQUEST`;
export const assignMbcRequest = mbcData => ({
  type: ASSIGN_MBC_REQUEST,
  payload: mbcData,
  gtm: {
    category: gtm.categories.MERIT_BADGE_COUNSELORS,
  },
});

export const ASSIGN_MBC_RESPONSE = `${namespace}/ASSIGN_MBC_RESPONSE`;
export const assignMbcResponse = () => ({
  type: ASSIGN_MBC_RESPONSE,
});

export const ASSIGN_MBC_ERROR = `${namespace}/ASSIGN_MBC_ERROR`;
export const assignMbcError = error => ({
  type: ASSIGN_MBC_ERROR,
  payload: error,
});
