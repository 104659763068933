import React from 'react';

import FilterListIcon from '@material-ui/icons/FilterList';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';
import styles from './FilterButton.less';

const FilterButton = ({ children, className, rightPadding, ...rest }) => {
  const classNames = cn(className, {
    [styles.noRightPadding]: !rightPadding,
  });

  return (
    <Button
      ghost
      noBorder
      fitText
      color="white"
      className={classNames}
      uppercase={false}
      icon={<FilterListIcon />}
      {...rest}
    >
      {children}
    </Button>
  );
};

FilterButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  rightPadding: PropTypes.bool,
};

FilterButton.defaultProps = {
  rightPadding: false,
};

export default FilterButton;
