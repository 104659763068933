import { combineEpics } from 'redux-observable';
import { of, merge } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { catchAndReport } from '@utils/rxjs/operators';
import {
  clearAdvancementsToDecline,
  closeDeclineAdvancementsModal,
  DECLINE_ADVANCEMENT_REQUEST,
  declineAdvancementRequestError,
  declineAdvancementResponse,
} from './actions';
import services from './services';

const declineActivityEpic$ = action$ =>
  action$
    .ofType(DECLINE_ADVANCEMENT_REQUEST)
    .switchMap(({ payload: { organizationGuid, activities } }) =>
      services.declineActivity$({ organizationGuid, activities }).pipe(
        mergeMap(() =>
          merge(
            of(declineAdvancementResponse()),
            of(closeDeclineAdvancementsModal()),
            of(clearAdvancementsToDecline()),
          ),
        ),
        catchAndReport(err => of(declineAdvancementRequestError(err))),
      ),
    );

export default combineEpics(declineActivityEpic$);
