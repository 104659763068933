import React from 'react';

import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  AdvancementType,
  Button,
  Card,
  CollapseDetails,
  Currency,
  FlexSpacer,
  S,
  Switch,
  Vr,
} from '@shared';

import {
  breakdownMembersByType,
  countUniqueAdvancements,
  countUniqueMembers,
} from '../../../../helpers';
import styles from './AdvancementReportSummary.less';

class AdvancementReportSummary extends React.PureComponent {
  getTotalFees = memoize(items =>
    items.reduce((sum, { price }) => sum + (price || 0), 0),
  );

  getUniqueMemberCount = memoize(items => countUniqueMembers(items));

  getUniqueAdvancementCount = memoize(items => countUniqueAdvancements(items));

  getMemberBreakdownByType = memoize(items => breakdownMembersByType(items));

  renderStats = () => {
    const { items, showPrice } = this.props;

    return (
      <div className={styles.stats}>
        {showPrice && (
          <React.Fragment>
            <div className={styles.statsItem}>
              <S size="1" bold className={styles.value}>
                <Currency value={this.getTotalFees(items)} />
              </S>
              <S size="4">
                <FormattedMessage id="advancementReport.AdvancementReportSummary.totalFees" />
              </S>
            </div>
            <Vr className={styles.vr} />
          </React.Fragment>
        )}
        <div className={styles.statsItem}>
          <S size="1" bold className={styles.value}>
            {items.length}
          </S>
          <S size="4">
            <FormattedMessage id="advancementReport.AdvancementReportSummary.items" />
          </S>
        </div>
      </div>
    );
  };

  renderDetails = () => {
    const { isMobile, items } = this.props;
    const members = this.getUniqueMemberCount(items);
    const advancements = this.getUniqueAdvancementCount(items);
    const breakdown = this.getMemberBreakdownByType(items);
    const breakdownNodes = breakdown.map(({ type, count }) => (
      <div key={type} className={styles.advancementsDetails}>
        <S size="4" bold colon inline>
          <AdvancementType plural type={type} />
        </S>
        <FormattedMessage
          id="advancementReport.AdvancementReportSummary.youth"
          values={{ count }}
        />
      </div>
    ));

    return (
      <CollapseDetails
        open={isMobile ? undefined : true}
        showArrow={isMobile}
        forceRender
        header={
          <S size="4" bold colon={!isMobile}>
            <FormattedMessage id="advancementReport.AdvancementReportSummary.details" />
          </S>
        }
        className={styles.details}
      >
        <div className={styles.detailsItems}>
          <div className={styles.qualifiedMembers}>
            <FormattedMessage
              id="advancementReport.AdvancementReportSummary.qualifiedYouth"
              values={{ members, advancements }}
            />
          </div>
          {breakdownNodes}
        </div>
      </CollapseDetails>
    );
  };

  render() {
    const {
      isMobile,
      loading,
      onEdit,
      showPrice,
      canEditMultipleItems,
      onToggle,
    } = this.props;

    return (
      <Card
        className={styles.cardWrapper}
        noPadding
        loading={loading}
        shadow={isMobile}
      >
        <div className={styles.container}>
          <S size="4" className={styles.title} bold colored>
            <FormattedMessage id="advancementReport.AdvancementReportSummary.title" />
          </S>
          {this.renderStats()}
          <FlexSpacer className={styles.smallSpacer} />
          <div className={styles.detailsAndActionsContainer}>
            {this.renderDetails()}
            <div className={styles.actions}>
              <div className={styles.showPriceContainer}>
                <FormattedMessage id="advancementReport.AdvancementReportSummary.showEstimatedPrice" />
                <Switch
                  className={styles.showPriceSwitch}
                  checked={showPrice}
                  onChange={onToggle}
                />
              </div>
              {onEdit && canEditMultipleItems && (
                <Button
                  className={styles.editButton}
                  ghost
                  color="info"
                  size="large"
                  uppercase={false}
                  fitText
                  onClick={onEdit}
                >
                  <FormattedMessage id="advancementReport.AdvancementReportForm.edit" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

AdvancementReportSummary.propTypes = {
  loading: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showPrice: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  canEditMultipleItems: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  onToggle: PropTypes.func.isRequired,
};

export default AdvancementReportSummary;
