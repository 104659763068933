import { get } from 'lodash';
import { createSelector } from 'reselect';

import { RootState } from '../../../../../src/root/store';
import { modulePath } from '../constants';
import reducers from './reducers';

const moduleSel = (state: RootState): ReturnType<typeof reducers> =>
  get(state, modulePath);

export const isVisibleAddDenChiefModalSel = createSelector(
  moduleSel,
  ({ isSearchDenChiefModalVisible }) => isSearchDenChiefModalVisible,
);
