import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Form, Input, intl } from '@shared';
import { selectedLongCruiseSel } from '../../../duck';
import { detailModes } from '../../../constants';
import { formatLabel, formatPlaceholder } from '../utils/longCruiseFormatter';

const FormItem = Form.Item;

const LongCruiseName = ({ form, disabled, className, detailMode }) => {
  const { selectedLongCruiseName } = useSelector(selectedLongCruiseSel) || {};

  const uniqueNameValidator = (rule, value, cb) => {
    if (rule.uniqueName && selectedLongCruiseName === value) {
      return cb(intl.formatMessage({ id: 'longCruise.control.uniqueName' }));
    }
    cb();
  };

  const { getFieldDecorator } = form;

  return (
    <FormItem
      required
      label={formatLabel('name')}
      className={className}
      standardLayout
    >
      {getFieldDecorator('name', {
        rules: [
          {
            uniqueName: detailMode === detailModes.CLONE,
            validator: uniqueNameValidator,
          },
          {
            required: true,
            message: intl.formatMessage({
              id: 'shared.form.error.isRequired',
            }),
          },
          {
            max: 50,
            message: intl.formatMessage(
              {
                id: 'shared.maxCharacters',
              },
              {
                max: 50,
              },
            ),
          },
        ],
      })(
        <Input
          disabled={disabled}
          size="large"
          placeholder={formatPlaceholder('name')}
        />,
      )}
    </FormItem>
  );
};

LongCruiseName.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  detailMode: PropTypes.string,
};

export default LongCruiseName;
