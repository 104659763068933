import React, { useCallback } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';

import { personNameBuilder } from '@modules/utils';
import { Col, PersonAvatar, Row, S, Tag } from '@shared';

import styles from './Person.less';

type Props = {
  data: {
    firstName: string;
    lastName: string;
    middleName: string;
    nickName: string;
    isAdult: boolean;
    userId: string;
  };
  onClose: (userId: string) => void;
};

const Person: React.FC<Props> = ({ data, onClose }) => {
  const { isAdult, userId } = data;

  const handleClose = useCallback(() => {
    onClose(userId);
  }, [onClose, userId]);

  const personName = personNameBuilder.short(data);

  return (
    <Tag className={styles.tag} outline={false}>
      <Row type="flex" align="middle">
        <Col>
          <PersonAvatar isAdult={isAdult} />
        </Col>
        <Col className={styles.personName}>
          <S size={'3'}>{personName}</S>
        </Col>
        <Col className={styles.closeIcon}>
          <div onClick={handleClose}>
            <CancelIcon />
          </div>
        </Col>
      </Row>
    </Tag>
  );
};

export default Person;
