import { combineReducers } from 'redux';

import { locationHash } from '@modules/shared';
import { resetOnActionsReducer } from '@utils';

import { counseledYouthTabNames } from '../constants';
import {
  COUNSELED_YOUTH_RESPONSE,
  COUNSELED_YOUTH_RESPONSE_ERROR,
  COUNSELED_YOUTH_ROUTE,
  COUNSELED_YOUTH_SEARCH,
} from './actions';

const counseledYouth = (
  state = { pendingItems: [], startedItems: [] },
  { type, payload },
) => {
  switch (type) {
    case COUNSELED_YOUTH_RESPONSE: {
      return payload;
    }
    default:
      return state;
  }
};

const validTabs = Object.values(counseledYouthTabNames);
const defaultTab = locationHash.getValid(
  validTabs,
  counseledYouthTabNames.COUNSELED_YOUTH,
);

const activeTab = (state = defaultTab, { type, payload }) => {
  switch (type) {
    case COUNSELED_YOUTH_ROUTE: {
      const { hash } = payload;
      return hash || state;
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case COUNSELED_YOUTH_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = true, { type }) => {
  switch (type) {
    case COUNSELED_YOUTH_RESPONSE:
    case COUNSELED_YOUTH_RESPONSE_ERROR:
      return false;
    default: {
      return state;
    }
  }
};

const resetSearchOnAction = resetOnActionsReducer(
  search,
  COUNSELED_YOUTH_ROUTE,
);

export default combineReducers({
  counseledYouth,
  activeTab,
  search: resetSearchOnAction,
  loading,
});
