import { gtm } from '@modules/shared/utils';

import { moduleName } from '../constants';

export { ROUTE_SETTINGS } from '@shared/duck';

export const ENABLE_FORUM_REQUEST = `${moduleName}/ENABLE_FORUM_REQUEST`;
export const ENABLE_FORUM_RESPONSE = `${moduleName}/ENABLE_FORUM_RESPONSE`;
export const ENABLE_FORUM_ERROR = `${moduleName}/ENABLE_FORUM_ERROR`;
export const enableForumRequest = unitGuid => ({
  type: ENABLE_FORUM_REQUEST,
  payload: unitGuid,
  gtm: {
    category: gtm.categories.FORUM,
  },
});
export const enableForumResponse = discourseData => ({
  type: ENABLE_FORUM_RESPONSE,
  payload: discourseData,
});
export const enableForumError = err => ({
  type: ENABLE_FORUM_ERROR,
  payload: err,
});

export const SYNC_FORUM_REQUEST = `${moduleName}/SYNC_FORUM_REQUEST`;
export const SYNC_FORUM_RESPONSE = `${moduleName}/SYNC_FORUM_RESPONSE`;
export const SYNC_FORUM_ERROR = `${moduleName}/SYNC_FORUM_ERROR`;
export const syncForumRequest = unitGuid => ({
  type: SYNC_FORUM_REQUEST,
  payload: unitGuid,
  gtm: {
    category: gtm.categories.FORUM,
  },
});
export const syncForumResponse = discourseData => ({
  type: SYNC_FORUM_RESPONSE,
  payload: discourseData,
});
export const syncForumError = err => ({
  type: SYNC_FORUM_ERROR,
  payload: err,
});

export const GET_TIMEZONES_REQUEST = `${moduleName}/GET_TIMEZONES_REQUEST`;
export const GET_TIMEZONES_RESPONSE = `${moduleName}/GET_TIMEZONES_RESPONSE`;
export const GET_TIMEZONES_ERROR = `${moduleName}/GET_TIMEZONES_ERROR`;
export const getTimezonesRequest = () => ({
  type: GET_TIMEZONES_REQUEST,
});
export const getTimezonesResponse = payload => ({
  type: GET_TIMEZONES_RESPONSE,
  payload: payload,
});
export const getTimezonesError = err => ({
  type: GET_TIMEZONES_ERROR,
  payload: err,
});

export const DISABLE_FORUM_REQUEST = `${moduleName}/DISABLE_FORUM_REQUEST`;
export const DISABLE_FORUM_RESPONSE = `${moduleName}/DISABLE_FORUM_RESPONSE`;
export const DISABLE_FORUM_ERROR = `${moduleName}/DISABLE_FORUM_ERROR`;
export const disableForumRequest = unitGuid => ({
  type: DISABLE_FORUM_REQUEST,
  payload: unitGuid,
  gtm: {
    category: gtm.categories.FORUM,
  },
});
export const disableForumResponse = discourseData => ({
  type: DISABLE_FORUM_RESPONSE,
  payload: discourseData,
});
export const disableForumError = err => ({
  type: DISABLE_FORUM_ERROR,
  payload: err,
});

export const GET_UNIT_DETAILS_REQUEST = `${moduleName}/GET_UNIT_DETAILS_REQUEST`;
export const GET_UNIT_DETAILS_RESPONSE = `${moduleName}/GET_UNIT_DETAILS_RESPONSE`;
export const GET_UNIT_DETAILS_ERROR = `${moduleName}/GET_UNIT_DETAILS_ERROR`;
export const getUnitDetailsRequest = () => ({
  type: GET_UNIT_DETAILS_REQUEST,
});
export const getUnitDetailsResponse = payload => ({
  type: GET_UNIT_DETAILS_RESPONSE,
  payload: payload,
});
export const getUnitDetailsError = err => ({
  type: GET_UNIT_DETAILS_ERROR,
  payload: err,
});

export const SET_TIMEZONE_REQUEST = `${moduleName}/SET_TIMEZONE_REQUEST`;
export const SET_TIMEZONE_RESPONSE = `${moduleName}/SET_TIMEZONE_RESPONSE`;
export const SET_TIMEZONE_ERROR = `${moduleName}/SET_TIMEZONE_ERROR`;
export const setTimezoneRequest = payload => ({
  type: SET_TIMEZONE_REQUEST,
  payload: payload,
});
export const setTimezoneResponse = payload => ({
  type: SET_TIMEZONE_RESPONSE,
  payload: payload,
});
export const setTimezoneError = err => ({
  type: SET_TIMEZONE_ERROR,
  payload: err,
});
