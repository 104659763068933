import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import cn from 'classnames';

import { Avatar } from '@shared';
import styles from './Nav.less';

const labelPlaceholder = (
  <ContentLoader
    width={50}
    height={16}
    style={{ width: '50px', height: '16px' }}
  >
    <rect x="0" y="0" rx="3" ry="3" width="50" height="16" />
  </ContentLoader>
);

class NavMenuItemContent extends React.PureComponent {
  render() {
    const { icon, children, loading, showLabels } = this.props;
    const styledIcon = React.cloneElement(icon, {
      className: cn(styles.icon, icon.props.className),
    });
    const labelClassName = cn(styles.label, {
      [styles.hiddenLabels]: !showLabels,
    });
    return (
      <div className={styles.itemContentContainer}>
        <Avatar
          className={styles.iconContainer}
          placeholder={styledIcon}
          loading={loading}
        />
        <span className={labelClassName}>
          {loading ? labelPlaceholder : children}
        </span>
      </div>
    );
  }
}

NavMenuItemContent.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  showLabels: PropTypes.bool.isRequired,
};

export default NavMenuItemContent;
