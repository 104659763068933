import { of } from 'rxjs';
import { switchMap, mergeMap, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import uniqBy from 'lodash/uniqBy';

import { setSelectedRosterKeys, intl } from '@shared';
import { catchAndReport } from '@utils/rxjs/operators';
import { userIdSel } from '@user';
import { organizationTypeIdSel, organizationGuidSel } from '@context';
import { toastService } from '@toasts';

import {
  selectedActivitySel,
  savingAction,
  personsAndIdsActivitySel,
  setTime,
  personsToRemoveSel,
  loadAndOpenActivity,
} from '../../common';

import { activeTabSel, personsSel } from './selectors';
import {
  RECORD_HIKE_RESPONSE,
  RECORD_HIKE_REQUEST,
  recordHikeResponse,
  recordHikeError,
  closeHike,
  LOAD_AND_OPEN_HIKE_MODAL,
  openEditHike,
  loadAndOpenHikeModalError,
} from './actions';
import { saveHikeActivity$ } from './services';

const recordServiceEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RECORD_HIKE_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { activityData } = payload;
      let validActivityData = { ...activityData };

      if (activityData.allDay) {
        validActivityData = {
          ...validActivityData,
          startTime: setTime(),
          endTime: setTime(23, 59),
        };
      }

      const persons = personsSel(state);
      const { personsInActivity } = personsAndIdsActivitySel(state);
      const loggedInUserId = userIdSel(state);
      const personsToRemove = personsToRemoveSel(state);
      const organizationGuid = organizationGuidSel(state);
      const organizationTypeId = organizationTypeIdSel(state);
      const selectedActivity = selectedActivitySel(state);
      const isAdvancedMode = activeTabSel(state) == 'advanced';
      return saveHikeActivity$({
        ...selectedActivity,
        ...validActivityData,
        persons: uniqBy([...personsInActivity, ...persons], 'userId'),
        personsToRemove,
        loggedInUserId,
        organizationGuid,
        organizationTypeId: +organizationTypeId,
        isAdvancedMode,
      }).pipe(
        mergeMap(() => {
          const { closeOnSuccess } = payload;
          const actions = [recordHikeResponse()].concat(
            closeOnSuccess ? [closeHike(), setSelectedRosterKeys([])] : [],
          );

          return of(...actions);
        }),
        tap(({ type }) => {
          if (type === RECORD_HIKE_RESPONSE) {
            toastService.success(
              intl.formatMessage(
                {
                  id: `progress.common.${
                    persons.length ? 'saveSuccessParticipants' : 'saveSuccess'
                  }`,
                },
                {
                  name: activityData.name,
                  type: selectedActivity.id
                    ? savingAction.UPDATED
                    : savingAction.CREATED,
                  count: persons.length,
                  participants: persons
                    .map(({ personShortFullName }) => personShortFullName)
                    .join(', '),
                },
              ),
            );
          }
        }),
        catchAndReport(err => of(recordHikeError(err))),
      );
    }),
  );

const loadHikeByIdEpic$ = action$ =>
  action$.pipe(
    ofType(LOAD_AND_OPEN_HIKE_MODAL),
    map(({ payload }) =>
      loadAndOpenActivity({
        ...payload,
        successActionFn: openEditHike,
        errorActionFn: loadAndOpenHikeModalError,
      }),
    ),
  );

export default combineEpics(recordServiceEpic$, loadHikeByIdEpic$);
