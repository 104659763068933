import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';

import {
  SET_ORGANIZATION,
  isSelectedOrganizationValidSel,
  organizationGuidSel,
} from '@context';
import { isCurrentPageSel } from '@location';
import { ROUTE_REPORTS } from '@modules/shared';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  HISTORY_REPORTS_REQUEST,
  historyReportsError,
  historyReportsRequest,
  historyReportsResponse,
} from './actions';
import services from './services';

const loadHistoryReportsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(HISTORY_REPORTS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      const reportsLog = services.getReportsInfo$(organizationGuid);
      return reportsLog.pipe(
        map(historyReportsResponse),
        catchAndReport(err => of(historyReportsError(err))),
      );
    }),
  );

const reloadHistoryReportsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SET_ORGANIZATION),
    filter(
      () =>
        isSelectedOrganizationValidSel(state$.value) &&
        isCurrentPageSel(state$.value, ROUTE_REPORTS),
    ),
    mapTo(historyReportsRequest()),
  );

export default combineEpics(loadHistoryReportsEpic$, reloadHistoryReportsEpic$);
