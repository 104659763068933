import React from 'react';
// import ContentLoader from 'react-content-loader';

import Item from './TablePlaceholderItem';
import ItemMobile from './TablePlaceholderItemMobile';
import styles from './TablePlaceholder.less';

const TablePlaceholder = () => (
  <div className={styles.container}>
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <Item />
    <ItemMobile />
    <ItemMobile />
    <ItemMobile />
  </div>
);

export default TablePlaceholder;
