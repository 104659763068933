import { permissions } from '@shared';
import { COUNSELED_YOUTH_ROUTE } from './duck';
import {
  CounseledYouthPage,
  CounseledYouthPagePlaceholder,
} from './components';

const routes = {
  [COUNSELED_YOUTH_ROUTE]: {
    path: '/counseledYouth',
    Component: CounseledYouthPage,
    Placeholder: CounseledYouthPagePlaceholder,
    titleId: 'page.counseledYouth',
    permission: permissions.VIEW_COUNSELED_YOUTH,
  },
};

export default routes;
