import { combineEpics, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import { CASS_REQUEST, fetchCASSResponse } from './actions';
import { CASS$ } from './services';

const fetchCASS$ = action$ =>
  action$.pipe(
    ofType(CASS_REQUEST),
    switchMap(({ payload }) =>
      CASS$(payload).pipe(
        mergeMap(({ response }) => {
          const results = response.Results.map(result => result.Address);
          return Observable.of(fetchCASSResponse(results));
        }),
      ),
    ),
  );

export default combineEpics(fetchCASS$);
