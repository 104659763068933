import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { Form, Input, intl } from '@shared';

import { activityValueTypesToName } from '../../constants';
import styles from './ActivityValuesForm.less';
import activityFields from './activityFields';

const mapActivityRecordValues = activityRecords =>
  activityRecords.reduce((values, currentRecord) => {
    const valueTypeName =
      activityValueTypesToName[currentRecord.activityValueTypeId];
    return { ...values, [valueTypeName]: currentRecord.activityValue };
  }, {});

const FormItem = Form.Item;

const ActivityValuesForm = ({ item, form, fieldNameModifier }) => {
  const { getFieldDecorator } = form;

  const { activityTypeId, activityRecords } = item;

  const values = useMemo(
    () => mapActivityRecordValues(activityRecords),
    [activityRecords],
  );
  const activityFormFields = activityFields[activityTypeId] || [];

  const formItemsNodes = activityFormFields.map(valueType => (
    <FormItem
      key={valueType}
      label={intl.formatMessage({
        id: `progress.common.ActivityValuesForm.${valueType}`,
      })}
      className={styles.formItem}
    >
      {getFieldDecorator(
        fieldNameModifier ? fieldNameModifier(valueType) : valueType,
        {
          initialValue: values[valueType] || 0,
          rules: [
            {
              required: true,
            },
          ],
        },
      )(
        <Input
          type="number"
          disabled
          label={intl.formatMessage({
            id: `progress.common.ActivityValuesForm.${valueType}`,
          })}
        />,
      )}
    </FormItem>
  ));

  return <React.Fragment>{formItemsNodes}</React.Fragment>;
};

ActivityValuesForm.propTypes = {
  item: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  fieldNameModifier: PropTypes.func,
};

export default ActivityValuesForm;
