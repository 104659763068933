import React from 'react';

import PropTypes from 'prop-types';
import Favicon from 'react-favicon';
import { Provider } from 'react-redux';

import { isProductionBuild } from '@config';
import { CalendarModals } from '@modules/events/components/CalendarModals/CalendarModals';
import FeatureFlagsOverridesBanner from '@modules/featureFlags/components/OverrideBanner';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { OfflineNotification, SessionExpiredModal } from '@offline';
import { ResizeWatcher } from '@screen';
import { DevWidget, ManualOffline, UnderMaintenance } from '@shared';
import { ToastContainer } from '@toasts';
import { LoginDataInit, MasqueradeModal } from '@user';

import { IntlProvider } from '../../../localization/components';
import { ProgressModals } from '../../../progress/components';
import { RecharterModals } from '../../../recharter';
import { ReportParamsModal } from '../../../reports';
import ClientTZ from '../ClientTZ';
import ErrorBoundary from '../ErrorBoundary';
import InitiateSbComSession from '../InitiateSbComSession';
import PageTitle from '../PageTitle';
import RouteSwitcher from '../RouteSwitcher';
import UpdateApp from '../UpdateApp';
import faviconUrl from './favicon.ico';

featureFlags.loadFlags();

function App(props) {
  const { store } = props;
  return (
    <div>
      <Favicon url={faviconUrl} />
      <Provider store={store}>
        <IntlProvider>
          <ErrorBoundary>
            <FeatureFlagsOverridesBanner />
            <UnderMaintenance>
              <LoginDataInit>
                <ClientTZ />
                <PageTitle />
                <ResizeWatcher />
                <ToastContainer />
                <SessionExpiredModal />
                <ProgressModals />
                <RecharterModals />
                <RouteSwitcher />
                <InitiateSbComSession />
                <OfflineNotification />
                <MasqueradeModal />
                <ManualOffline />
                <ReportParamsModal />
                <CalendarModals />
                {!!isProductionBuild && <UpdateApp />}
                {!isProductionBuild && <DevWidget />}
              </LoginDataInit>
            </UnderMaintenance>
          </ErrorBoundary>
        </IntlProvider>
      </Provider>
    </div>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
