import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, Row, S } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeRemoveMembersModal,
  isRemoveMembersModalVisible,
  recharterRosterSelectedKeysSel,
  removeMembersRequest,
  selectedAdultMembersSel,
  selectedYouthMembersSel,
  setRecharterRosterSelectedKeys,
} from '../../../duck';
import { PersonTag } from '../../common';
import styles from './RemoveMembersModal.less';

const RemoveMembersModal = () => {
  const dispatch = useDispatch();

  const isVisible = useSelector(isRemoveMembersModalVisible);
  const selectedKeys = useSelector(recharterRosterSelectedKeysSel);
  const youths = useSelector(selectedYouthMembersSel);
  const adults = useSelector(selectedAdultMembersSel);

  const handleCloseRemoveMembersModal = () =>
    dispatch(closeRemoveMembersModal());

  const handleRemoveMembers = () => {
    dispatch(removeMembersRequest(selectedKeys));
  };

  const handleDeselectMember = personId => {
    let newKeys = [];
    if (selectedKeys.includes(personId)) {
      newKeys = selectedKeys.filter(key => key !== personId);
    }
    if (selectedKeys.length === 1) {
      dispatch(closeRemoveMembersModal());
    }
    dispatch(setRecharterRosterSelectedKeys(newKeys));
  };

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.removeMembersModal.title',
      })}
      onCancel={handleCloseRemoveMembersModal}
      onBack={handleCloseRemoveMembersModal}
    >
      <div>
        <S size="4" className={styles.greyLabel}>
          <FormattedMessage id="recharterModals.removeMembersModal.subTitle" />
        </S>
      </div>
      {adults.length > 0 && (
        <div>
          <S size="4" className={styles.countLabel}>
            <FormattedMessage
              id="recharterModals.removeMembersModal.adult"
              values={{ adultCount: adults.length }}
            />
          </S>
          <div className={styles.selectContainer}>
            {adults.map(({ personId, personFullName }) => (
              <PersonTag
                key={`person_tag_${personId}`}
                id={personId}
                isAdult={true}
                name={personFullName}
                onCloseAction={handleDeselectMember}
              />
            ))}
          </div>
        </div>
      )}
      {youths.length > 0 && (
        <div>
          <S size="4" className={styles.countLabel}>
            <FormattedMessage
              id="recharterModals.removeMembersModal.youth"
              values={{ youthCount: youths.length }}
            />
          </S>
          <div className={styles.selectContainer}>
            {youths.map(({ personId, personFullName }) => (
              <PersonTag
                key={`person_tag_${personId}`}
                id={personId}
                isAdult={false}
                name={personFullName}
                onCloseAction={handleDeselectMember}
              />
            ))}
          </div>
        </div>
      )}
      <Row className={styles.container}>
        <Button
          className={styles.cancelButton}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={true}
          color="scouting-warm-gray"
          onClick={handleCloseRemoveMembersModal}
        >
          <FormattedMessage id="recharterModals.removeMembersModal.cancel" />
        </Button>
        <Button
          className={styles.button}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={true}
          disabled={selectedKeys.length === 0}
          onClick={handleRemoveMembers}
        >
          <FormattedMessage id="recharterModals.removeMembersModal.remove" />
        </Button>
      </Row>
    </Modal>
  );
};

export default RemoveMembersModal;
