import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { sortOrderPropTypes } from '../../constants';
import FlexSpacer from '../FlexSpacer';
import S from '../S';
import SortIcon from '../SortIcon';
import styles from './SortOption.less';

const typeDescriptions = Object.freeze({
  string: {
    ascend: <FormattedMessage id="shared.SortOption.string.ascend" />,
    descend: <FormattedMessage id="shared.SortOption.string.descend" />,
  },
  number: {
    ascend: <FormattedMessage id="shared.SortOption.number.ascend" />,
    descend: <FormattedMessage id="shared.SortOption.number.descend" />,
  },
  date: {
    ascend: <FormattedMessage id="shared.SortOption.date.ascend" />,
    descend: <FormattedMessage id="shared.SortOption.date.descend" />,
  },
});

class SortOption extends React.PureComponent {
  handleClick = () => {
    const { name, onClick } = this.props;
    onClick(name);
  };

  render() {
    const { order, children, className, type, ...rest } = this.props;

    return (
      <div
        {...rest}
        className={cn(styles.container, className, { [styles.active]: order })}
        onClick={this.handleClick}
      >
        <S size="4" bold>
          {children}
        </S>
        <FlexSpacer />
        <div className={styles.descriptionContainer}>
          <S
            size="4"
            inlineBlock
            className={cn(styles.description, styles.descriptionAsc, {
              [styles.active]: order === 'ascend',
            })}
          >
            ( {typeDescriptions[type].ascend} )
          </S>
          <S
            size="4"
            inlineBlock
            className={cn(styles.description, {
              [styles.active]: order === 'descend',
            })}
          >
            ( {typeDescriptions[type].descend} )
          </S>
        </div>
        <SortIcon
          order={order}
          className={cn(styles.icon, { [styles.active]: order })}
        />
      </div>
    );
  }
}

SortOption.propTypes = {
  // provided by SortSelect
  order: sortOrderPropTypes,
  onClick: PropTypes.func,
  // provided by parent
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired, // should match 'key' prop in Table's column definition object
  type: PropTypes.oneOf(['string', 'number', 'date']).isRequired,
  className: PropTypes.string,
};

export default SortOption;
