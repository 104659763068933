import {
  GetUserLeadershipPositionsRequest,
  GetUserLeadershipPositionsResponse,
  PostReviewPositionRequest,
  PostReviewPositionResponse,
  PostYouthLeadershipPositionRequest,
  UnitLeadershipPosition,
  UnitPendingLeadershipPosition,
  UpdateLeadershipPositionPayload,
} from '@appTypes/esb';
import {
  UnitLeadershipPositionPOSTRequest,
  UnitLeadershipPositionPUTRequest,
} from '@appTypes/esb/organizations/v2/units/_orgGuid/leadershipPositions';
import { tagTypes } from '@modules/rtk/constants';
import { esbApi } from '@modules/rtk/esb-api';

export const leadershipApi = esbApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * @esbEndpoint GET /advancements/youth/:userId/leadershipPositionHistory
     */
    getUnitPendingLeadershipPositions: builder.query<
      UnitPendingLeadershipPosition[],
      { orgGuid: string }
    >({
      query: arg => ({
        url: `/organizations/v2/units/${arg.orgGuid}/pendingLeadershipPositions`,
      }),
      providesTags: (result, error, arg) =>
        result && !error
          ? [{ type: tagTypes.UnitPendingLeadershipPositions, id: arg.orgGuid }]
          : [],
    }),
    /**
     * @esbEndpoint GET /advancements/youth/:userId/leadershipPositionHistory
     */
    getYouthLeadershipPositions: builder.query<
      GetUserLeadershipPositionsResponse,
      { userId: number; params?: GetUserLeadershipPositionsRequest }
    >({
      query: arg => ({
        url: `/advancements/youth/${arg.userId}/leadershipPositionHistory`,
        params: arg.params,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        result && !error
          ? [{ type: tagTypes.YouthLeadershipPositions, id: arg.userId }]
          : [],
    }),
    /**
     * @esbEndpoint POST /advancements/v2/youth/:userId/leadershipPositionHistory
     */
    createYouthLeadershipPosition: builder.mutation<
      void,
      {
        payload: PostYouthLeadershipPositionRequest;
        userId: number;
      }
    >({
      query: arg => ({
        url: `/advancements/v2/youth/${arg.userId}/leadershipPositionHistory`,
        method: 'POST',
        body: arg.payload,
      }),
      invalidatesTags: (_, error, arg) =>
        error
          ? []
          : [
              {
                type: tagTypes.YouthLeadershipPositions,
                id: arg.userId,
              },
              {
                type: tagTypes.UnitPendingLeadershipPositions,
                id: arg.payload.unitGuid,
              },
            ],
    }),
    /**
     * @esbEndpoint PUT /advancements/v2/youth/:userId/leadershipPositionHistory/:userPositionId
     */
    updateYouthLeadershipPosition: builder.mutation<
      void,
      {
        userId: number;
        id: number;
        /** If passed, it invalidates pending positions for the unit */
        organizationGuid?: string;
        payload: UpdateLeadershipPositionPayload;
      }
    >({
      query: arg => {
        const { userId, id, payload } = arg;
        return {
          url: `/advancements/v2/youth/${userId}/leadershipPositionHistory/${id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: (_result, error, { userId, organizationGuid }) =>
        error
          ? []
          : [
              {
                type: tagTypes.YouthLeadershipPositions,
                id: userId,
              },
              {
                type: tagTypes.UnitPendingLeadershipPositions,
                id: organizationGuid,
              },
            ],
    }),
    /**
     * @esbEndpoint PUT /advancements/v2/youth/:userId/leadershipPositionHistory/:userPositionId
     */
    bulkUpdateYouthLeadershipPositions: builder.mutation<
      unknown,
      {
        positions: Array<UpdateLeadershipPositionPayload & { id: number }>;
        /* Optional to invalidate tags for unit positions */
        orgGuids?: string[];
      }
    >({
      queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
        const requests = arg.positions.map(requestPayload => {
          const { userId, id } = requestPayload;
          return baseQuery({
            url: `/advancements/v2/youth/${userId}/leadershipPositionHistory/${id}`,
            method: 'PUT',
            body: requestPayload,
          });
        });

        const responses = await Promise.all(requests);
        const datas = responses.map(item => item.data);
        return { data: datas };
      },
      invalidatesTags: (_result, error, arg) => {
        if (error) return [];

        const invalidated = arg.positions.map(item => ({
          type: tagTypes.YouthLeadershipPositions,
          id: item.userId,
        }));

        // Also invalidate unit pending positions
        let invalidateOrgPositions: Array<{ type: string; id: string }> = [];
        if (arg.orgGuids) {
          invalidateOrgPositions = arg.orgGuids.map(item => ({
            type: tagTypes.UnitPendingLeadershipPositions,
            id: item,
          }));
        }

        return [...invalidated, ...invalidateOrgPositions];
      },
    }),

    /**
     * @esbEndpoint DELETE /advancements/v2/youth/:userId/leadershipPositionHistory/:positionId
     */
    deleteYouthLeadershipPosition: builder.mutation<
      void,
      { userId: number; id: number; organizationGuid?: string }
    >({
      query: ({ userId, id }) => ({
        url: `/advancements/v2/youth/${userId}/leadershipPositionHistory/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error, arg) =>
        error
          ? []
          : [
              { type: tagTypes.YouthLeadershipPositions, id: arg.userId },
              {
                type: tagTypes.UnitPendingLeadershipPositions,
                id: arg.organizationGuid,
              },
              {
                type: tagTypes.UnitLeadershipPositions,
                id: arg.organizationGuid,
              },
            ],
    }),
    /**
     * @esbEndpoint GET /advancements/v2/denChief
     */
    getDenChiefDetails: builder.query<
      { firstName: string; lastName: string; nickName: string },
      string
    >({
      query: contextToken => ({
        url: `/advancements/v2/denChief?c=${contextToken}`,
        method: 'GET',
        params: { suppressErrorToasts: true },
      }),
    }),
    /**
     * @esbEndpoint POST /advancements/v2/denChief/reviewPosition
     */
    reviewDenChiefPosition: builder.mutation<
      PostReviewPositionResponse,
      { contextToken: string; memberId: number; confirm: boolean }
    >({
      query: ({ confirm, contextToken, memberId }) => ({
        url: '/advancements/v2/denChief/reviewPosition',
        method: 'POST',
        body: {
          c: contextToken,
          i: memberId,
          confirm,
        } as PostReviewPositionRequest,
      }),
    }),
    /**
     * @esbEndpoint GET /organizations/v2/units/:organizationGuid/leadershipPositions
     */
    getUnitLeadershipPositions: builder.query<
      UnitLeadershipPosition[],
      { orgGuid: string }
    >({
      query: arg => ({
        url: `/organizations/v2/units/${arg.orgGuid}/leadershipPositions`,
      }),
      providesTags: (result, error, arg) =>
        result && !error
          ? [{ type: tagTypes.UnitLeadershipPositions, id: arg.orgGuid }]
          : [],
    }),
    /**
     * @esbEndpoint POST /organizations/v2/units/:organizationGuid/leadershipPositions
     */
    createUnitLeadershipPositions: builder.mutation<
      void,
      {
        payload: UnitLeadershipPositionPOSTRequest[];
        orgGuid: number;
      }
    >({
      query: arg => ({
        url: `/organizations/v2/units/${arg.orgGuid}/leadershipPositions`,
        method: 'POST',
        body: arg.payload,
      }),
      invalidatesTags: (_, error, arg) =>
        error
          ? []
          : [{ type: tagTypes.UnitLeadershipPositions, id: arg.orgGuid }],
    }),
    /**
     * @esbEndpoint PUT /organizations/v2/units/:organizationGuid/leadershipPositions
     */
    updateUnitLeadershipPositions: builder.mutation<
      void,
      {
        payload: UnitLeadershipPositionPUTRequest[];
        orgGuid: string;
      }
    >({
      query: arg => ({
        url: `/organizations/v2/units/${arg.orgGuid}/leadershipPositions`,
        method: 'PUT',
        body: arg.payload,
      }),
      invalidatesTags: (_result, error, arg) =>
        error
          ? []
          : [{ type: tagTypes.UnitLeadershipPositions, id: arg.orgGuid }],
    }),
    /**
     * @esbEndpoint DELETE /advancements/v2/youth/:userId/leadershipPositionHistory/:positionId
     */
    bulkDeleteUnitsLeadershipPositions: builder.mutation<
      unknown,
      {
        positions: Array<{ userId: number; id: number }>;
        orgGuid: string;
      }
    >({
      queryFn: async (arg, _queryApi, _extraOptions, baseQuery) => {
        const requests = arg.positions.map(({ userId, id }) =>
          baseQuery({
            url: `/advancements/v2/youth/${userId}/leadershipPositionHistory/${id}`,
            method: 'DELETE',
          }),
        );

        const responses = await Promise.all(requests);
        const datas = responses.map(item => item.data);
        return { data: datas };
      },
      invalidatesTags: (_result, error, arg) =>
        error
          ? []
          : [{ type: tagTypes.UnitLeadershipPositions, id: arg.orgGuid }],
    }),
  }),
});

export const {
  useGetYouthLeadershipPositionsQuery,
  useCreateYouthLeadershipPositionMutation,
  useUpdateYouthLeadershipPositionMutation,
  useDeleteYouthLeadershipPositionMutation,
  useGetDenChiefDetailsQuery,
  useReviewDenChiefPositionMutation,
  useGetUnitPendingLeadershipPositionsQuery,
  useLazyGetUnitPendingLeadershipPositionsQuery,
  useBulkUpdateYouthLeadershipPositionsMutation,
  useGetUnitLeadershipPositionsQuery,
  useCreateUnitLeadershipPositionsMutation,
  useUpdateUnitLeadershipPositionsMutation,
  useBulkDeleteUnitsLeadershipPositionsMutation,
} = leadershipApi;
