import React from 'react';

import { AssignmentLateOutlined } from '@material-ui/icons';
import { Card } from 'bsa-ui';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button } from '@modules/shared';

import { POStatusTypes } from '../../constants';
import {
  createPurchaseOrderRequest,
  setCreatePurchaseOrderLoading,
} from '../../duck';
import styles from './POCardPlaceholder.less';

export const POCardPlaceholder = ({
  status,
  selectedAdvancements = [],
  createDisabled = false,
  isLoading = false,
}) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setCreatePurchaseOrderLoading(true));
    dispatch(createPurchaseOrderRequest(selectedAdvancements));
  };

  return (
    <Card margin rounded bordered loading={isLoading}>
      <div className={styles.placeholderContainer}>
        <AssignmentLateOutlined className={styles.assignmentIcon} />
        <span className={styles.placeholderText}>
          <FormattedMessage
            id="PurchaseOrder.POCardPlaceholder.text"
            values={{ status: status }}
          />
        </span>
        {status === POStatusTypes.OPEN && (
          <FormattedMessage id="PurchaseOrder.POCardPlaceholder.subtext" />
        )}
        {status === POStatusTypes.OPEN && (
          <Button
            onClick={handleClick}
            type="primary"
            shape="round"
            size="large"
            uppercase={false}
            className={styles.actionButton}
            disabled={createDisabled}
          >
            <FormattedMessage id="PurchaseOrder.ToPurchaseTab.createButton" />
          </Button>
        )}
      </div>
    </Card>
  );
};

POCardPlaceholder.propTypes = {
  status: PropTypes.string.isRequired,
  selectedAdvancements: PropTypes.array,
  createDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};
