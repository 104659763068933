import { longCruiseModuleSel, createPersonsSelector } from '../../common';

const moduleSel = state => longCruiseModuleSel(state);

export const visibleSel = state => moduleSel(state).visible;

export const loadingSel = state => moduleSel(state).loading;
export const errorSel = state => moduleSel(state).error;
export const modalModeSel = state => moduleSel(state).modalMode;
export const detailModeSel = state => moduleSel(state).detailMode;
export const selectedLongCruiseSel = state =>
  moduleSel(state).selectedLongCruise;
export const userIdsSel = state => moduleSel(state).userIds;
export const personsSel = createPersonsSelector(userIdsSel, true);
export const datesSel = state => moduleSel(state).dates;
export const activeTabSel = state => moduleSel(state).activeTab;
export const allowEditPersonsSel = state => moduleSel(state).allowEditPersons;
export const disableGetActivitiesSel = state =>
  moduleSel(state).disableGetActivities;
export const disableFutureDaysSel = state => moduleSel(state).disableFutureDays;
export const allowRemovePersonsSel = state =>
  moduleSel(state).allowRemovePersons;
