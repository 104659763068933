import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ModeEditIcon from 'material-ui-icons/ModeEdit';

import { S, Hr, Button } from '@shared';
import styles from './AdvancementReportListHeader.less';

function AdvancementReportListHeader({ onEdit }) {
  return (
    <React.Fragment>
      <div className={styles.title}>
        <S size="4" colored bold>
          <FormattedMessage id="packRoster.AdvancementReportList.title" />
        </S>
        {onEdit && (
          <Button
            icon={<ModeEditIcon className={styles.icon} />}
            noBorder
            color="info"
            size="large"
            fitText
            onClick={onEdit}
          >
            <FormattedMessage id="packRoster.AdvancementReportList.edit" />
          </Button>
        )}
      </div>
      <Hr className={styles.hr} />
    </React.Fragment>
  );
}

AdvancementReportListHeader.propTypes = {
  onEdit: PropTypes.func,
};

export default AdvancementReportListHeader;
