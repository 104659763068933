import { replace } from 'redux-first-router';
import { permissions, ROUTE_ACTIVITIES, emptyObj } from '@shared';
import { organizationPositionsSel, setOrganization } from '@context';
import { querySel } from '@location';
import isEmpty from 'lodash/isEmpty';
import { ActivitiesPage, ActivitiesPlaceholder } from './components';

const routes = {
  [ROUTE_ACTIVITIES]: {
    path: '/activities',
    Component: ActivitiesPage,
    Placeholder: ActivitiesPlaceholder,
    titleId: 'page.activities',
    permission: permissions.VIEW_ROSTER_PAGE,
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;
      if (!isEmpty(organizationGuid) && !isEmpty(positions)) {
        const foundedPosition = positions.find(
          p => p.organizationGuid === organizationGuid,
        );
        replace('/activities');
        if (foundedPosition) {
          dispatch(
            setOrganization({
              guid: foundedPosition.organizationGuid,
              programType: foundedPosition.programType,
              parentOrgGuid: foundedPosition.organizationGuid,
            }),
          );
        }
      }
    },
  },
};

export default routes;
