import React, { useCallback, useMemo } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormattedMessage } from 'react-intl';

import T from '../T';
import styles from './BackToLink.less';

export type BackToLinkProps = {
  title: string | JSX.Element;
  onBack: () => void;
};

const BackToLink = ({ onBack, title }: BackToLinkProps) => {
  const backToValues = useMemo(() => ({ name: title }), [title]);

  const handleOnBack = useCallback(() => onBack(), [onBack]);

  return (
    <T className={styles.title} onClick={handleOnBack} size="3">
      <ArrowBackIcon />
      <FormattedMessage id="shared.backTo" values={backToValues} />
    </T>
  );
};

export default BackToLink;
