import React from 'react';
import PropTypes from 'prop-types';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import { S } from '@shared';
import styles from './UploadItem.less';

const GenericUploadItem = ({
  title,
  progressContent,
  actionContent,
  extraContent,
}) => (
  <div className={styles.container}>
    <div className={styles.mainContent}>
      <InsertDriveFileIcon className={styles.fileIcon} />
      <div className={styles.progressContent}>
        <S size="5" className={styles.title}>
          {title}
        </S>
        {progressContent}
      </div>
      <div className={styles.actionContent}>{actionContent}</div>
    </div>
    <div className={styles.extraContent}>{extraContent}</div>
  </div>
);

GenericUploadItem.propTypes = {
  title: PropTypes.node.isRequired,
  progressContent: PropTypes.node.isRequired,
  actionContent: PropTypes.node.isRequired,
  extraContent: PropTypes.node,
};

export default GenericUploadItem;
