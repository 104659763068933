import React from 'react';

import throttle from 'lodash/throttle';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  INACTIVE_MINUTES,
  INTERVAL_CHECK,
  MAX_INACTIVE_MINUTES,
  PROMPT_TIMEOUT_MINUTES,
  userActivityEvents,
} from '@modules/userInactivity/constants';
import { offlineSel } from '@shared/duck';

import {
  lastSessionRefreshSel,
  logout,
  openTimeoutModal,
  refreshSessionRequest,
  timeoutModalOpenSel,
  tokenExpDateSel,
} from '../../duck';

class InactivityWatcher extends React.PureComponent {
  componentDidMount() {
    this.inactivityInterval = setInterval(
      this.onUpdateInactivityTime,
      INTERVAL_CHECK,
    );
    userActivityEvents.forEach(event =>
      window.addEventListener(event, this.onResetInactivityTime),
    );
  }

  componentWillUnmount() {
    clearInterval(this.inactivityInterval);
    userActivityEvents.forEach(event =>
      window.removeEventListener(event, this.onResetInactivityTime),
    );
  }

  inactivityMinutes = 0;

  isSessionValid = () => {
    const { tokenExpDate } = this.props;
    return !!tokenExpDate && moment().isBefore(tokenExpDate);
  };

  shouldRefreshSession = () => {
    const { lastSessionRefresh } = this.props;
    const check =
      moment().diff(lastSessionRefresh, 'minutes') > PROMPT_TIMEOUT_MINUTES;

    const shouldRefresh = !lastSessionRefresh || check;

    return shouldRefresh;
  };

  handleInactivityTime = inactivityMinutes => {
    const { onOpenTimeoutModal, onLogout } = this.props;
    if (inactivityMinutes == INACTIVE_MINUTES) {
      onOpenTimeoutModal();
    }
    if (inactivityMinutes == MAX_INACTIVE_MINUTES) {
      onLogout();
    }
  };

  onUpdateInactivityTime = () => {
    const { onLogout, isOffline } = this.props;

    // Disable timeout functions
    if (isOffline) {
      return;
    }

    // Hard logout
    if (!this.isSessionValid()) {
      onLogout();
    }

    this.inactivityMinutes = this.inactivityMinutes + 1;
    this.handleInactivityTime(this.inactivityMinutes);
  };

  onResetInactivityTime = throttle(() => {
    const { isTimeoutModalOpen, onRequestSessionRefresh, isOffline } =
      this.props;

    if (!isTimeoutModalOpen) {
      this.inactivityMinutes = 0;

      if (!isOffline && this.shouldRefreshSession()) {
        onRequestSessionRefresh();
      }
    }
  }, 30000);

  render() {
    return '';
  }
}

InactivityWatcher.propTypes = {
  //Provided by component itself
  isTimeoutModalOpen: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  onOpenTimeoutModal: PropTypes.func.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onRequestSessionRefresh: PropTypes.func.isRequired,
  lastSessionRefresh: PropTypes.string.isRequired,
  tokenExpDate: PropTypes.object.isRequired,
};

const mapState = state => ({
  isTimeoutModalOpen: timeoutModalOpenSel(state),
  isOffline: offlineSel(state),
  lastSessionRefresh: lastSessionRefreshSel(state),
  tokenExpDate: tokenExpDateSel(state),
});

const mapDispatch = dispatch => ({
  onLogout: () =>
    dispatch(logout(undefined, { preserveDiscourseSession: true })),
  onOpenTimeoutModal: () => dispatch(openTimeoutModal()),
  onRequestSessionRefresh: () => dispatch(refreshSessionRequest()),
});

export default connect(mapState, mapDispatch)(InactivityWatcher);
