import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SyncPendingModal } from '@offline';
import { LandscapeNotification } from '@screen';
import { Layout as BsaLayout } from '@shared';
import { userDataInitializedSel } from '@user';
import { isMobileDevice } from '@utils';

import { AdvancementReportModal } from '../../../advancementReport';
import { sidebarExpandedSel } from '../../duck';
import Header from '../Header';
import Sidebar from '../Sidebar';
import styles from './Layout.less';

const { Content } = BsaLayout;

class Layout extends React.PureComponent {
  render() {
    const { children, sidebarExpanded, userDataInitialized } = this.props;

    return (
      <BsaLayout className={styles.container}>
        <Sidebar />
        <BsaLayout>
          <Header />
          {/* <RecharterNotification /> */}
          {isMobileDevice && <LandscapeNotification />}
          {userDataInitialized && <SyncPendingModal />}
          <AdvancementReportModal />
          <Content
            className={cn(styles.content, {
              [styles.sidebarExpanded]: sidebarExpanded,
            })}
          >
            {children}
          </Content>
        </BsaLayout>
      </BsaLayout>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  sidebarExpanded: PropTypes.bool.isRequired,
  userDataInitialized: PropTypes.bool.isRequired,
};

const mapState = state => ({
  sidebarExpanded: sidebarExpandedSel(state),
  userDataInitialized: userDataInitializedSel(state),
});

export default connect(mapState)(Layout);
