import React from 'react';

import cn from 'classnames';
import { connect } from 'react-redux';

import { isMobileSel } from '../../duck';
import { customPropTypes } from '../../utils';
import { Collapse } from '../bsaComponents';
import styles from './TableFilterCollapse.less';
import TableFilterPanel from './TableFilterPanel';
import TableFilterPanelGroup from './TableFilterPanelGroup';
import { getChildrenKeys } from './utils';

const validReactChildren = children =>
  React.Children.toArray(children).filter(React.isValidElement);

const decoupleInnerPanels = children =>
  React.Children.toArray(children).reduce((panels, child) => {
    if (React.isValidElement(child) && child.type && child.type.isPanelGroup) {
      return [...panels, ...(child.props.children || [])];
    }
    return [...panels, child];
  }, []);

class TableFilterCollapse extends React.Component {
  getAccordion = () => {
    const { isMobile, accordion } = this.props;
    return isMobile ? accordion : false;
  };

  getActiveKey = () => {
    const { isMobile, children, activeKey } = this.props;

    if (isMobile) {
      const validChildren = validReactChildren(children);
      return validChildren.length == 1 ? validChildren[0].key : activeKey;
    }

    return getChildrenKeys(children);
  };

  getChildren = () => {
    const { isMobile, children } = this.props;
    const validChildren = validReactChildren(children);
    if (validChildren.length == 1) {
      return React.cloneElement(validChildren[0], {
        showArrow: false,
        disabled: true,
      });
    }
    return isMobile ? decoupleInnerPanels(children) : children;
  };

  render() {
    const { className, ...rest } = this.props;
    const accordion = this.getAccordion();
    const activeKey = this.getActiveKey();
    const children = this.getChildren();
    return (
      <Collapse
        {...rest}
        className={cn(styles.container, className)}
        accordion={accordion}
        activeKey={activeKey}
      >
        {children}
      </Collapse>
    );
  }
}

TableFilterCollapse.propTypes = {
  ...Collapse.propTypes,
  children: customPropTypes.nodeOfTypes(
    TableFilterPanel,
    TableFilterPanelGroup,
  ),
};

TableFilterCollapse.defaultProps = {
  accordion: true,
  bordered: false,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
});

export default connect(mapState)(TableFilterCollapse);
