import React from 'react';

import PropTypes from 'prop-types';

import { Fab } from '@shared';

import { ApproveAdvancementsAction } from '../../../../../../progress/components';
import { ReportFab } from '../../../../../common';
import styles from './OptionsFab.less';

class OptionsFab extends React.PureComponent {
  state = { reportFabOpen: false };

  handleShowReportFab = () => this.setState({ reportFabOpen: true });

  handleReportFabOpenChange = () =>
    this.setState(({ reportFabOpen }) => ({
      reportFabOpen: !reportFabOpen,
    }));

  render() {
    const { reportFabOpen } = this.state;
    const { selectedRows, isOffline, visible } = this.props;
    const areAnyAdvancementsSelected = selectedRows.length > 0;

    return (
      <React.Fragment>
        <ReportFab
          visible={visible && (reportFabOpen || !areAnyAdvancementsSelected)}
          open={reportFabOpen}
          onOpenChange={this.handleReportFabOpenChange}
          isOffline={isOffline}
          showAllUsers
        />
        <Fab visible={visible && areAnyAdvancementsSelected && !reportFabOpen}>
          <ApproveAdvancementsAction
            className={styles.approveAdvancement}
            personsAdvancements={selectedRows}
          />
        </Fab>
      </React.Fragment>
    );
  }
}

OptionsFab.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedRows: PropTypes.array.isRequired,
  isOffline: PropTypes.bool.isRequired,
};

export default OptionsFab;
