import { map } from 'rxjs/operators';
import isNil from 'lodash/isNil';

import { webscriptApiService, getParamsFromUri } from '@utils';

import { reportsV2, individualReports, PERSONAL_REPORTS } from '../constants';

const processResponse = response => {
  const reportsResponse = response
    ? response.Reports.filter(report => !isNil(report))
        .map((report, i) => {
          const {
            scriptsetcode: reportCode = '',
            ReportCode: councilReportCode = '',
          } = getParamsFromUri(report.ReportURI);
          const hasV2 = reportsV2.includes(reportCode);
          const isIndividual = individualReports.includes(reportCode);
          const url = new URL(report.ReportURI);

          const { searchParams } = url;
          // some urls have already application type, so we use URL interface to avoid duplicates
          searchParams.set('ApplicationType', 'SBL');
          hasV2 && searchParams.set('activityVersion', '2');
          url.search = searchParams.toString();

          return {
            id: i + 1,
            ...report,
            reportCode: reportCode || councilReportCode,
            isIndividual,
            ReportURI: url.toString(),
          };
        })
        .filter(
          // Dens are not supported yet, so we can omit them
          ({ ReportName }) => !ReportName.toLowerCase().includes('by den'),
        )
    : [];
  return reportsResponse;
};

const getReportsInfo$ = organizationGuid =>
  webscriptApiService
    .get$(
      `/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLite&ScriptCode=GetReportList&OrganizationGUID=${organizationGuid}`,
      {
        gtm: {
          label: '/WebScript/GetReportList',
        },
      },
    )
    .pipe(
      map(response => {
        const reportsResponse = processResponse(response);
        return {
          reports: reportsResponse,
          organizationGuid,
        };
      }),
    );

const getPersonalReportsInfo$ = () =>
  webscriptApiService
    .get$(
      '/WebScript/Output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLite&ScriptCode=GetPersonalReportList',
      {
        gtm: {
          label: '/WebScript/GetPersonalReportList',
        },
      },
    )
    .pipe(
      map(response => {
        const reportsResponse = processResponse(response);
        return {
          reports: reportsResponse,
          organizationGuid: PERSONAL_REPORTS,
        };
      }),
    );

export default {
  getReportsInfo$,
  getPersonalReportsInfo$,
};
