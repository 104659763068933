import { ROUTE_SETTINGS } from './duck';
import { SettingsPage } from './components';

const routes = {
  [ROUTE_SETTINGS]: {
    path: '/settings',
    Component: SettingsPage,
    titleId: 'page.settings',
  },
};

export default routes;
