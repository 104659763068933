import React, { PropsWithChildren, ReactNode } from 'react';

import { ButtonProps } from 'antd/lib/button';
import { ModalProps } from 'antd/lib/modal';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed

/* eslint-disable import/named */
import { Modal as BsaModal } from 'bsa-ui';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { RootState } from '../../../../../src/root/store';
import { isMobileSel, modalClose, modalOpen, modalOpenSel } from '../../duck';
import OfflineIcon from '../OfflineIcon';
import ReturnButton from '../ReturnButton';
import T from '../T';
import styles from './Modal.less';

const { Provider, Consumer } = React.createContext<
  (() => Element | null) | undefined
>(undefined);

// need to get ref to container so that children can use it as mount nodes
// without it, components like Select would not render properly in Modals
// see https://ant.design/components/select/#API getPopupContainer
// could've used ref prop here, except bsa-ui wrapper of Modal is a functional component (those do not support refs)
const baseContainerClassName = 'Modal_Container';
// this is so that each modal gets a unique class name
// this way we can have multiple modals open at the same time and this will still work
let instanceCounter = 0;
const sizeClassnames = Object.freeze({
  small: styles.sizeSmall,
  default: styles.sizeDefault,
  large: styles.sizeLarge,
});

const mapState = (state: RootState) => ({
  isAnyModalOpen: modalOpenSel(state),
  isMobile: isMobileSel(state),
});

const mapDispatch = (dispatch: Dispatch) => ({
  onOpen: () => dispatch(modalOpen()),
  onClose: () => dispatch(modalClose()),
});

type Props = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> &
  ModalProps & {
    fullscreenMobile?: boolean;
    showMobileHeader?: boolean;
    useNewStyle?: boolean;
    noPadding?: boolean;
    headerColor?: string;
    backTitle?: ReactNode;
    backButtonProps?: ButtonProps;
    onBack?: () => void;
    size?: 'small' | 'default' | 'large';
    useDefaultFooter?: boolean;
  };

type State = {
  instanceContainerClassName: string;
};

class Modal extends React.PureComponent<PropsWithChildren<Props>, State> {
  state = {
    instanceContainerClassName: `${baseContainerClassName}${instanceCounter++}`,
  };

  componentDidUpdate = ({
    visible: prevVisible,
    isAnyModalOpen: prevIsAnyModalOpen,
    isMobile: prevIsMobile,
  }: Props) => {
    const { visible, onOpen, onClose, isAnyModalOpen, isMobile } = this.props;

    if (visible !== prevVisible) {
      visible ? onOpen() : onClose();
    }

    if (isMobile !== prevIsMobile || visible !== prevVisible) {
      if (isMobile) {
        setTimeout(() => {
          const modalScrollContent = document.querySelector(
            `.${this.state.instanceContainerClassName} .ant-modal-content`,
          );
          if (modalScrollContent) {
            visible
              ? disableBodyScroll(modalScrollContent)
              : enableBodyScroll(modalScrollContent);
          }
        });
      } else {
        clearAllBodyScrollLocks();
      }
    }

    if (isAnyModalOpen !== prevIsAnyModalOpen && !isAnyModalOpen) {
      // Make sure we can scroll back again
      clearAllBodyScrollLocks();
    }
  };

  getPopupContainer = () =>
    document.querySelector(
      `.${this.state.instanceContainerClassName} .ant-modal-body`,
    );

  render() {
    const {
      fullscreenMobile = true,
      showMobileHeader = false,
      className,
      useNewStyle = false,
      noPadding,
      wrapClassName,
      headerColor,
      footer = null,
      destroyOnClose = true,
      children,
      onCancel,
      backTitle,
      backButtonProps,
      onBack,
      size = 'default',
      title,
      centered,
      useDefaultFooter = false,
      isMobile,
      ...rest
    } = this.props;
    const { instanceContainerClassName } = this.state;

    const sizeClass = sizeClassnames[size];

    const classNames = cn(
      {
        [styles.fullscreenMobile]: fullscreenMobile,
        [styles.hideMobileHeader]: !showMobileHeader,
        [styles.noPadding]: noPadding,
        [styles.centered]: centered,
        [styles.newModalStyle]: !!useNewStyle,
      },
      styles[`header-${headerColor}`],
      instanceContainerClassName,
      sizeClass,
      className,
    );
    const wrapClassNames = cn(
      { [styles.fullscreenMobileWrap]: fullscreenMobile },
      wrapClassName,
    );

    return (
      <Provider value={this.getPopupContainer}>
        <BsaModal
          className={classNames}
          wrapClassName={wrapClassNames}
          footer={useDefaultFooter ? undefined : footer}
          destroyOnClose={destroyOnClose}
          onCancel={onCancel}
          title={
            !!title && (
              <T size="5" noMargin>
                {title}
              </T>
            )
          }
          {...rest}
        >
          {backTitle || onBack ? (
            <React.Fragment>
              <ReturnButton
                modal
                onClick={onBack || onCancel}
                wrapClassName={styles.backButton}
                extra={
                  !!isMobile && <OfflineIcon tooltipPlacement="bottomRight" />
                }
                {...backButtonProps}
              >
                {backTitle}
              </ReturnButton>
              {children}
            </React.Fragment>
          ) : (
            children
          )}
        </BsaModal>
      </Provider>
    );
  }
}

export default connect(mapState, mapDispatch)(Modal);
export { Consumer as ModalContextContainerConsumer };
