import moment from 'moment';
import { combineReducers } from 'redux';

import {
  CONFIRM_INVITE_RESPONSE,
  GET_ALL_SUBUNITS_ERROR,
  GET_ALL_SUBUNITS_REQUEST,
  GET_ALL_SUBUNITS_RESPONSE,
} from '@modules/events/duck/actions';
import { deleteEventsResponse } from '@modules/events/duck/actionsTyped';
import {
  DELETE_EVENTS_ERROR,
  DELETE_EVENTS_REQ,
  DELETE_RECURRENT_EVENTS_REQ,
  locationHash,
} from '@shared';
import { resetOnLogoutReducer } from '@utils';

import { activityFilters, calendarViews } from '../constants';
import {
  CLOSE_ADD_EVENT_POPOVER,
  GET_EVENTS_ERROR,
  GET_EVENTS_REQUEST,
  GET_EVENTS_RESPONSE,
  GET_PERSONAL_ACTIVITIES_ERROR,
  GET_PERSONAL_ACTIVITIES_REQUEST,
  GET_PERSONAL_ACTIVITIES_RESPONSE,
  OPEN_ADD_EVENT_POPOVER,
  ROUTE_CALENDAR,
  SET_ACTIVITY_TYPE_FILTERS,
  SET_CALENDARS_TO_HIDE,
  SET_DELETE_RECURRENT_EVENT_INFO,
  SET_SEARCH_EVENT_NAME,
  SET_SELECTED_DATE,
  UPDATE_EVENTS,
  setMiniCalendarSelectedDate,
} from './actions';

const selectedDate = (state = new Date().toISOString(), { type, payload }) => {
  switch (type) {
    case SET_SELECTED_DATE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const miniCalendarSelectedDate = (
  state = new Date().toISOString(),
  { type, payload },
) => {
  const currentMiniCalendarDate = state;

  // if month is different (i.e. big calendar changed month), sync date
  const isSameMonth =
    moment.isDate(payload) &&
    moment(payload).isSame(currentMiniCalendarDate, 'month');

  if (type === SET_SELECTED_DATE && !isSameMonth) {
    return payload;
  }

  switch (type) {
    case setMiniCalendarSelectedDate.type: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedMonth = (
  state = moment(new Date()).format('MM-YYYY'),
  { type, payload },
) => {
  switch (type) {
    case SET_SELECTED_DATE: {
      const newMonth = moment(payload).format('MM-YYYY');
      return newMonth !== state ? newMonth : state;
    }
    default: {
      return state;
    }
  }
};

const validViews = Object.values(calendarViews);
const defaultView = locationHash.getValid(validViews, calendarViews.MONTH);
const calendarView = (state = defaultView, { type, payload }) => {
  switch (type) {
    case ROUTE_CALENDAR: {
      const { hash } = payload;
      return hash || state;
    }
    default: {
      return state;
    }
  }
};

const addEventDates = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_ADD_EVENT_POPOVER: {
      return payload;
    }
    case CLOSE_ADD_EVENT_POPOVER: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const addEventPopoverVisible = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADD_EVENT_POPOVER: {
      return true;
    }
    case CLOSE_ADD_EVENT_POPOVER: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const events = (state = [], { type, payload }) => {
  switch (type) {
    case GET_EVENTS_RESPONSE:
    case UPDATE_EVENTS:
      return payload;
    case GET_EVENTS_REQUEST:
    case GET_EVENTS_ERROR:
      return [];
    case CONFIRM_INVITE_RESPONSE: {
      return state.map(event => {
        const { resource } = event;
        const { id, invitedUsers = [] } = resource;
        const { eventId, data } = payload;
        if (id === eventId) {
          const newInvitedUsers = invitedUsers.map(user =>
            user.userId === data.userId ? { ...user, rsvp: data.rsvp } : user,
          );
          return {
            ...event,
            resource: { ...resource, invitedUsers: newInvitedUsers },
          };
        }
        return event;
      });
    }
    default:
      return state;
  }
};

const personalActivities = (state = [], { type, payload }) => {
  switch (type) {
    case GET_PERSONAL_ACTIVITIES_RESPONSE:
      return payload;
    case GET_PERSONAL_ACTIVITIES_REQUEST:
    case GET_PERSONAL_ACTIVITIES_ERROR:
      return [];
    default:
      return state;
  }
};

const isEventsLoading = (state = false, { type }) => {
  switch (type) {
    case DELETE_EVENTS_ERROR:
    case GET_EVENTS_RESPONSE:
    case deleteEventsResponse.type:
    case GET_EVENTS_ERROR:
      return false;
    case DELETE_RECURRENT_EVENTS_REQ:
    case DELETE_EVENTS_REQ:
    case GET_EVENTS_REQUEST:
      return true;
    default:
      return state;
  }
};

// TODO: use the getsubUnit ones
const isFeedEventsLoading = (state = false, { type }) => {
  switch (type) {
    case GET_ALL_SUBUNITS_RESPONSE:
    case GET_ALL_SUBUNITS_ERROR:
      return false;
    case GET_ALL_SUBUNITS_REQUEST:
      return true;
    default:
      return state;
  }
};

const isPersonalActivitiesLoading = (state = false, { type }) => {
  switch (type) {
    case GET_PERSONAL_ACTIVITIES_RESPONSE:
    case GET_PERSONAL_ACTIVITIES_ERROR:
      return false;
    case GET_PERSONAL_ACTIVITIES_REQUEST:
      return true;
    default:
      return state;
  }
};

const activityTypeFilters = (state = activityFilters, { type, payload }) => {
  switch (type) {
    case SET_ACTIVITY_TYPE_FILTERS:
      return { ...state, [payload.key]: payload.value };
    default:
      return state;
  }
};

const calendarsToHide = (state = new Set(), { type, payload }) => {
  switch (type) {
    case SET_CALENDARS_TO_HIDE: {
      return payload;
    }
    default:
      return state;
  }
};

const searchEventName = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH_EVENT_NAME: {
      return payload;
    }
    default:
      return state;
  }
};

const deleteRecurrentEventInfo = (
  /** @type {import('@modules/events/duck/types').DeleteRecurrentEventState} */
  state = {
    eventId: null,
    dateFrom: null,
    modalVisible: false,
    deleteOption: undefined,
  },
  { type, payload },
) => {
  switch (type) {
    case SET_DELETE_RECURRENT_EVENT_INFO: {
      return payload;
    }
    default:
      return state;
  }
};

const reducers = combineReducers({
  events,
  deleteRecurrentEventInfo,
  personalActivities,
  isEventsLoading,
  isFeedEventsLoading,
  isPersonalActivitiesLoading,
  selectedDate,
  selectedMonth,
  calendarView,
  addEventDates,
  addEventPopoverVisible,
  activityTypeFilters,
  calendarsToHide,
  searchEventName,
  miniCalendarSelectedDate,
});

export default resetOnLogoutReducer(reducers);
