import { gtm } from '@shared';

import { declineAdvancementModuleNamespace as namespace } from '../../common';

export const OPEN_DECLINE_ADVANCEMENTS_MODAL = `${namespace}/OPEN_DECLINE_ADVANCEMENTS_MODAL`;
export const openDeclineAdvancementsModal = () => ({
  type: OPEN_DECLINE_ADVANCEMENTS_MODAL,
});

export const CLOSE_DECLINE_ADVANCEMENTS_MODAL = `${namespace}/CLOSE_DECLINE_ADVANCEMENTS_MODAL`;
export const closeDeclineAdvancementsModal = () => ({
  type: CLOSE_DECLINE_ADVANCEMENTS_MODAL,
});

export const DECLINE_ADVANCEMENT_REQUEST = `${namespace}/DECLINE_ADVANCEMENT_REQUEST`;
export const declineAdvancementRequest = payload => ({
  type: DECLINE_ADVANCEMENT_REQUEST,
  payload,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const DECLINE_ADVANCEMENT_RESPONSE = `${namespace}/DECLINE_ADVANCEMENT_RESPONSE`;
export const declineAdvancementResponse = () => ({
  type: DECLINE_ADVANCEMENT_RESPONSE,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const DECLINE_ADVANCEMENT_ERROR = `${namespace}/DECLINE_ADVANCEMENT_ERROR`;
export const declineAdvancementRequestError = payload => ({
  type: DECLINE_ADVANCEMENT_ERROR,
  payload,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const SET_ADVANCEMENTS_TO_DECLINE = `${namespace}/SET_ADVANCEMENTS_TO_DECLINE`;
export const setAdvancementsToDecline = payload => ({
  type: SET_ADVANCEMENTS_TO_DECLINE,
  payload,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const CLEAR_ADVANCEMENTS_TO_DECLINE = `${namespace}/CLEAR_ADVANCEMENTS_TO_DECLINE`;
export const clearAdvancementsToDecline = () => ({
  type: CLEAR_ADVANCEMENTS_TO_DECLINE,
});
