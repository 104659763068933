import { gtm } from '@shared';
import { moduleNamespace as namespace } from '../constants';

export const ROUTE_MBC = `${namespace}/ROUTE_MBC`;

export const MBC_REQUEST = `${namespace}/MBC_REQUEST`;
export const mbcRequest = () => ({
  type: MBC_REQUEST,
  gtm: {
    category: gtm.categories.MERIT_BADGE_COUNSELORS,
  },
});

export const MBC_RESPONSE = `${namespace}/MBC_RESPONSE`;
export const mbcResponse = roster => ({
  type: MBC_RESPONSE,
  payload: roster,
});

export const MBC_ERROR = `${namespace}/MBC_ERROR`;
export const mbcError = error => ({
  type: MBC_ERROR,
  payload: error,
});

export const SET_PAGE = `${namespace}/SET_PAGE`;
export const setPage = page => ({
  type: SET_PAGE,
  payload: page,
});

export const SET_EXPANDED_ROW = `${namespace}/SET_EXPANDED_ROW`;
export const setExpandedRow = (key = '') => ({
  type: SET_EXPANDED_ROW,
  payload: key,
});

export const SET_PAGE_SIZE_MOBILE = `${namespace}/SET_PAGE_SIZE_MOBILE`;
export const setPageSizeMobile = pageSize => ({
  type: SET_PAGE_SIZE_MOBILE,
  payload: pageSize,
});

export const SET_FILTER = `${namespace}/SET_FILTER`;
export const setFilter = filter => ({
  type: SET_FILTER,
  payload: filter,
});

export const SET_SEARCH = `${namespace}/SET_SEARCH`;
export const setSearch = search => ({
  type: SET_SEARCH,
  payload: search,
});

export const OPEN_EDIT_MODAL = `${namespace}/OPEN_EDIT_MODAL`;
export const openEditModal = mbcData => ({
  type: OPEN_EDIT_MODAL,
  payload: mbcData,
});

export const CLOSE_EDIT_MODAL = `${namespace}/CLOSE_EDIT_MODAL`;
export const closeEditModal = () => ({
  type: CLOSE_EDIT_MODAL,
});

export const SAVE_MBC_REQUEST = `${namespace}/SAVE_MBC_REQUEST`;
export const saveMbcRequest = mbcData => ({
  type: SAVE_MBC_REQUEST,
  payload: mbcData,
  gtm: {
    category: gtm.categories.MERIT_BADGE_COUNSELORS,
  },
});

export const SAVE_MBC_RESPONSE = `${namespace}/SAVE_MBC_RESPONSE`;
export const saveMbcResponse = () => ({
  type: SAVE_MBC_RESPONSE,
});

export const SAVE_MBC_ERROR = `${namespace}/SAVE_MBC_ERROR`;
export const saveMbcError = error => ({
  type: SAVE_MBC_ERROR,
  payload: error,
});
