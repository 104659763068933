import React, { useState } from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { showSearchDenChiefModal } from '@modules/advancement/denChiefSearch/duck/actions';
import { OfflineTooltip } from '@offline';
import { Button, Dropdown, ExpandToggle, Menu } from '@shared';
import { Program } from '@shared/constants';

import styles from './AddIndividualDropdown.less';

const addDenChief = 'addDenChief';

const MenuItem = Menu.Item;

export const AddIndividualDropdown = ({ disabled }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const unitProgram = useSelector(programSel);

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const handleMenuClick = ({ key }) => {
    setVisible(false);

    switch (key) {
      case addDenChief: {
        dispatch(showSearchDenChiefModal());
        return;
      }

      default: {
        throw new Error(`Unknown report type: ${key}`);
      }
    }
  };

  // more menu options will be added in the future
  const buttons = (
    <Menu onClick={handleMenuClick}>
      {unitProgram === Program.CUB_SCOUT && (
        <MenuItem key={addDenChief}>
          <FormattedMessage id={'packRoster.addIndividual.addDenChief'} />
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <OfflineTooltip placement="top">
      <Dropdown
        overlay={buttons}
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        disabled={disabled}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          className={cn(
            {
              [styles.disabled]: disabled,
            },
            styles.toggleButton,
          )}
          ghost
          noBorder
          color="white"
          size="small"
          uppercase={false}
        >
          <FormattedMessage id="packRoster.addIndividual.addIndividual" />
          <ExpandToggle
            className={styles.arrow}
            expanded={!!visible && !disabled}
          />
        </Button>
      </Dropdown>
    </OfflineTooltip>
  );
};

AddIndividualDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
};
