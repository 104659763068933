import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { Collapse } from '../bsaComponents';
import styles from './CollapseDetails.less';

const { Panel } = Collapse;
const panelKey = 'panel';

class CollapseDetails extends React.PureComponent {
  state = {
    open: this.props.defaultOpen,
  };

  getIsOpen = () => (this.isControlled() ? this.props.open : this.state.open);

  isControlled = () => this.props.open != null;

  handleChange = key => {
    const open = !!key;
    if (!this.isControlled()) {
      this.setState({ open });
    }
    if (this.props.onChange) {
      if (this.props.panelName) {
        this.props.onChange(key, open);
      } else {
        this.props.onChange(open);
      }
    }
  };

  render() {
    const {
      panelId,
      children,
      className,
      panelClassName,
      header,
      destroyInactivePanel,
      forceRender,
      withSeeMore = false,
      showArrow,
      disabled,
      padding,
      panelName = '',
      expandedKeys = '',
      accordion = true,
    } = this.props;
    const open = this.getIsOpen();

    return (
      <Collapse
        className={className}
        accordion={accordion}
        bordered={false}
        activeKey={
          panelName && open ? expandedKeys : open ? panelKey : undefined
        }
        destroyInactivePanel={destroyInactivePanel}
        onChange={this.handleChange}
      >
        <Panel
          id={panelId}
          key={panelName ? panelName : panelKey}
          className={cn(
            styles.panel,
            {
              [styles.noPadding]: !padding,
              [styles.panelWithSeeMore]: withSeeMore,
            },
            panelClassName,
          )}
          header={header}
          forceRender={forceRender}
          showArrow={showArrow}
          extra={
            withSeeMore && !this.getIsOpen() ? (
              <span className={cn(styles.seeMoreExpand)}>See more</span>
            ) : undefined
          }
          disabled={disabled}
        >
          {children}
        </Panel>
      </Collapse>
    );
  }
}

CollapseDetails.propTypes = {
  disabled: PropTypes.bool,
  styleDisabled: PropTypes.bool,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onChange: PropTypes.func,
  header: PropTypes.node,
  showArrow: PropTypes.bool,
  forceRender: PropTypes.bool,
  destroyInactivePanel: PropTypes.bool,
  padding: PropTypes.bool,
  withSeeMore: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  panelClassName: PropTypes.string,
  panelId: PropTypes.string,
  panelName: PropTypes.string,
  expandedKeys: PropTypes.string,
  accordion: PropTypes.bool,
};

CollapseDetails.defaultProps = {
  showArrow: true,
};

export default CollapseDetails;
