import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';

import { combineEpics } from 'redux-observable';

import { personGuidSel, userDataSel, gtm } from '@shared';
import { SET_DEFAULT_ORGANIZATION, SET_ORGANIZATION } from './actions';
import { saveDefaultUnit, saveCurrentUnit } from './services';

const defaultUnit$ = (action$, state$) =>
  action$
    .ofType(SET_DEFAULT_ORGANIZATION)
    .do(({ payload }) => {
      const state = state$.value;
      const personGuid = personGuidSel(state);
      saveDefaultUnit(personGuid, payload);
    })
    .ignoreElements();

const currentUnit$ = (action$, state$) =>
  action$
    .ofType(SET_ORGANIZATION)
    .do(({ payload }) => {
      const state = state$.value;
      const selectedOrganizationGuid = payload.parentOrgGuid;
      const { organizations } = userDataSel(state);
      const { role } =
        organizations.find(
          ({ organizationGuid }) =>
            organizationGuid === selectedOrganizationGuid,
        ) || {};

      gtm.setUserRole(role);
      saveCurrentUnit(selectedOrganizationGuid);
    })
    .ignoreElements();

export default combineEpics(defaultUnit$, currentUnit$);
