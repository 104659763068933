import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';

import {
  AUTH_REDIRECT_ERROR,
  AUTH_REDIRECT_REQUEST,
  AUTH_REDIRECT_SUCCESS,
  CLOSE_MASQUERADE_MODAL,
  CLOSE_TIMEOUT_MODAL,
  GOOGLE_LOGIN_VALIDATE,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  MASQUERADE_ERROR,
  MASQUERADE_REQUEST,
  MASQUERADE_RESPONSE,
  OPEN_MASQUERADE_MODAL,
  OPEN_TIMEOUT_MODAL,
  REFRESH_SESSION_RESPONSE,
  ROUTE_CHOOSE_SCOUTBOOK,
  SELFSESSION_ERROR,
  SELFSESSION_REQUEST,
  SELFSESSION_RESPONSE,
  SELFSESSION_SKIP_CHECK,
  SET_DEFAULT_APP,
  SET_USER_DATA,
  USER_DATA_ERROR,
  USER_DATA_REQUEST,
  USER_DATA_RESPONSE,
} from './actions';

const loginLoading = (state = false, { type }) => {
  switch (type) {
    case GOOGLE_LOGIN_VALIDATE:
    case LOGIN_REQUEST:
    case SELFSESSION_REQUEST:
    case MASQUERADE_REQUEST: {
      return true;
    }
    case LOGIN_RESPONSE:
    case LOGIN_ERROR:
    case SELFSESSION_RESPONSE:
    case SELFSESSION_ERROR:
    case MASQUERADE_RESPONSE:
    case SELFSESSION_SKIP_CHECK:
    case MASQUERADE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loginDataInitialized = (state = false, { type }) => {
  switch (type) {
    case LOGIN_RESPONSE:
    case LOGIN_ERROR:
    case SELFSESSION_RESPONSE:
    case SELFSESSION_ERROR:
    case MASQUERADE_RESPONSE:
    case MASQUERADE_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const loginData = (state = null, { type, payload }) => {
  switch (type) {
    case LOGIN_RESPONSE:
    case REFRESH_SESSION_RESPONSE:
    case SELFSESSION_RESPONSE:
    case MASQUERADE_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const userDataLoading = (state = false, { type }) => {
  switch (type) {
    case LOGIN_RESPONSE:
    case SELFSESSION_RESPONSE:
    case MASQUERADE_RESPONSE:
    case USER_DATA_REQUEST: {
      return true;
    }
    case USER_DATA_RESPONSE:
    case USER_DATA_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const userDataInitialized = (state = false, { type }) => {
  switch (type) {
    case USER_DATA_RESPONSE:
    case USER_DATA_ERROR:
    case SELFSESSION_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const userData = (state = null, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
    case USER_DATA_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const tokenExp = (state = null, { type, payload }) => {
  switch (type) {
    case LOGIN_RESPONSE:
    case SELFSESSION_RESPONSE:
    case MASQUERADE_RESPONSE: {
      const { exp } = payload;
      return exp;
    }
    default: {
      return state;
    }
  }
};

const masqueradeLoading = (state = false, { type }) => {
  switch (type) {
    case MASQUERADE_REQUEST: {
      return true;
    }
    case MASQUERADE_RESPONSE:
    case MASQUERADE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const masquerading = (state = false, { type }) => {
  switch (type) {
    case MASQUERADE_RESPONSE: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const editWhileMasquerading = (
  state = false,
  { type, editWhileMasquerading: edit },
) => {
  switch (type) {
    case MASQUERADE_RESPONSE: {
      return edit;
    }
    default: {
      return state;
    }
  }
};

const masqueradeModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_MASQUERADE_MODAL: {
      return true;
    }
    case CLOSE_MASQUERADE_MODAL:
    case MASQUERADE_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const timeoutModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_TIMEOUT_MODAL: {
      return true;
    }
    case CLOSE_TIMEOUT_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const lastSessionRefresh = (state = null, { type }) => {
  switch (type) {
    case SELFSESSION_RESPONSE:
    case LOGIN_RESPONSE:
    case REFRESH_SESSION_RESPONSE: {
      return new Date().toISOString();
    }
    default: {
      return state;
    }
  }
};

const defaultApp = (state = null, { type, payload }) => {
  if (type === SET_DEFAULT_APP) {
    return payload;
  }

  return state;
};

const redirecting = (state = false, { type, payload = {} }) => {
  if (type === ROUTE_CHOOSE_SCOUTBOOK) {
    return payload.redirecting || false;
  }

  return state;
};

const authRedirectLoading = (state = true, { type }) => {
  switch (type) {
    case AUTH_REDIRECT_SUCCESS:
      return false;
    case AUTH_REDIRECT_REQUEST:
    default:
      return state;
  }
};
const authRedirectError = (state = false, { type }) => {
  switch (type) {
    case AUTH_REDIRECT_REQUEST:
    case AUTH_REDIRECT_SUCCESS:
      return false;
    case AUTH_REDIRECT_ERROR:
      return true;
    default:
      return state;
  }
};

const reducer = combineReducers({
  authRedirectLoading,
  authRedirectError,
  loginLoading,
  loginDataInitialized,
  loginData,
  userDataLoading,
  userDataInitialized,
  userData,
  tokenExp,
  masqueradeLoading,
  masquerading,
  editWhileMasquerading,
  masqueradeModalOpen,
  timeoutModalOpen,
  lastSessionRefresh,
  defaultApp,
  redirecting,
});

export default resetOnLogoutReducer(reducer);
