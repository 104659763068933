import { eagleProjectModuleSel } from '../../common';

const moduleSel = state => eagleProjectModuleSel(state);

export const visibleSel = state => moduleSel(state).visible;

export const modalModeSel = state => moduleSel(state).modalMode;
export const detailModeSel = state => moduleSel(state).detailMode;

const youthProfileModuleSel = state => state['youthProfile'];
export const isLoadingSubmitEagleProjectSel = state =>
  youthProfileModuleSel(state).isLoadingSubmitEagleProject;
