import { PermissionsTabNames } from '@shared';

export const tabNames = PermissionsTabNames;

export const tabHeaders = Object.freeze({
  FULL_CONTROL: 'fullControl',
  ADVANCEMENT_EDIT: 'advancementEdit',
  ADVANCEMENT_APPROVED: 'advancementApproved',
  ADVANCEMENT_VIEW: 'advancementView',
  PROFILE_EDIT: 'profileEdit',
  PROFILE_VIEW: 'profileView',
  PAYMENT_LOGS_EDIT: 'paymentLogsEdit',
  PAYMENT_LOGS_VIEW: 'paymentLogsView',
});

export const permissionTypeLabel = Object.freeze({
  fullControl: 'Full Control Permissions',
  advancementEdit: 'Advancement Permissions (Edit)',
  advancementApproved: 'Advancement Permissions (Approved)',
  advancementView: 'Advancement Permissions (View)',
  profileEdit: 'Profile Permissions (Edit)',
  profileView: 'Profile Permissions (View)',
});

export const permissionTypeId = Object.freeze({
  // 3 - Full Control, 4 - View Profile, 5 - Edit Profile, 6 - View Advancement, 7 - Edit Advancement
  fullControl: 3,
  advancementEdit: 7,
  advancementApproved: 7,
  advancementView: 6,
  profileEdit: 5,
  profileView: 4,
});

export const sortTypeLabel = Object.freeze({
  firstName: 'First Name',
  lastName: 'Last Name',
});
