import { ROUTE_REPORTS } from '@modules/shared';
import { permissions } from '@modules/user';

import { ReportsPage, ReportsPagePlaceholder } from './components';

const routes = {
  [ROUTE_REPORTS]: {
    path: '/reports',
    Component: ReportsPage,
    Placeholder: ReportsPagePlaceholder,
    titleId: 'page.reports',
    permission: permissions.VIEW_REPORTS_PAGE,
  },
};

export default routes;
