// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import 'antd/lib/rate/style/index.css';
import {
  Alert as BSAAlert,
  AutoComplete as BSAAutoComplete,
  // Avatar,
  Badge as BSABadge,
  Breadcrumb as BSABreadcrumb,
  // Button,
  // Card,
  Carousel as BSACarousel,
  // Checkbox,
  Collapse as BSACollapse,
  // DatePicker,
  // Dropdown,
  //Form,
  Col as BSACol,
  Row as BSARow,
  Icon as BSAIcon,
  Iframe as BSAIframe,
  // Input,
  // LocaleProvider,
  // Menu,
  // Modal,
  Pagination as BSAPagination,
  PinSteps as BSAPinSteps,
  Popconfirm as BSAPopconfirm,
  // Popover,
  Progress as BSAProgress,
  Radio as BSARadio,
  // Select,
  SlidePanel as BSASlidePanel,
  // Spin,
  StatsWidget as BSAStatsWidget,
  Steps as BSASteps,
  // Switch,
  // Table,
  Tabs as BSATabs,
  Tag as BSATag,
  // TimePicker,
  Tooltip as BSATooltip,
} from 'bsa-ui';

import BSAList from 'bsa-ui/src/list';

export const Tag = BSATag;
export const Tooltip = BSATooltip;
export const Tabs = BSATabs;
export const Steps = BSASteps;

export const List = BSAList;

export { Calendar, Layout, Rate } from 'antd';

// needs to be imported and then exported because of babel-import plugin that only rewrites imports
// TODO: remove this file, makes harder to debug real issues on imports. Rather import directly from bsa-ui
export const StatsWidget = BSAStatsWidget;
export const Row = BSARow;
export const Radio = BSARadio;

export const Progress = BSAProgress;
export const Pagination = BSAPagination;
export const PinSteps = BSAPinSteps;
export const Popconfirm = BSAPopconfirm;
export const SlidePanel = BSASlidePanel;
export const Icon = BSAIcon;
export const Iframe = BSAIframe;
export const Col = BSACol;
export const Collapse = BSACollapse;
export const Carousel = BSACarousel;
export const Breadcrumb = BSABreadcrumb;

export const Badge = BSABadge;
export const Alert = BSAAlert;
export const AutoComplete = BSAAutoComplete;
