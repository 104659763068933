import React, { useCallback } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { unitTypeIdSel } from '@context';
import { isMobileSel } from '@screen';
import {
  Modal,
  ProgramId, // utils
  intl,
} from '@shared';

import { programTypeIdToSubUnitName } from '../../constants';
import { hideSetSubUnitModal } from '../../duck/actions';
import {
  isAddSubUnitModalVisibleSel,
  selectedSubUnitIdSel,
} from '../../duck/selectors';
import AddSubUnitForm from '../AddSubUnitForm';
import styles from './AddSubUnitModal.less';

const AddSubUnitModal = () => {
  const dispatch = useDispatch();
  const isMobile = useSelector(isMobileSel);
  const isVisible = useSelector(isAddSubUnitModalVisibleSel);
  const unitTypeId = useSelector(unitTypeIdSel);
  const selectedSubunitId = useSelector(selectedSubUnitIdSel);

  const handleCloseModal = useCallback(() => {
    dispatch(hideSetSubUnitModal());
  }, [dispatch]);

  if (!unitTypeId) return null;

  return (
    <Modal
      className={styles.roundedModal}
      mask={!isMobile}
      headerColor="scouting-warm-gray"
      visible={isVisible}
      title={intl.formatMessage({
        id: `packRoster.AddSubUnit.AddSubUnitModal.${
          selectedSubunitId ? 'edit' : 'create'
        }.${
          programTypeIdToSubUnitName[
            unitTypeId as Extract<
              ProgramId,
              ProgramId.BOY_SCOUT | ProgramId.CUB_SCOUT
            >
          ]
        }`,
      })}
      onCancel={handleCloseModal}
      backTitle={intl.formatMessage({
        id: 'packRoster.AddSubUnit.AddSubUnitModal.returnToRoster',
      })}
      onBack={handleCloseModal}
    >
      <div className={cn({ [styles.modalMobileContainer]: isMobile })}>
        <AddSubUnitForm />
      </div>
    </Modal>
  );
};

export default AddSubUnitModal;
