import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import isEqual from 'lodash/isEqual';
import ErrorIcon from '@material-ui/icons/Error';

import Spin from '../Spin';
import styles from './ReduxLoader.less';

class ReduxLoader extends React.PureComponent {
  componentDidMount = () => {
    this.triggerLoad();
  };

  componentDidUpdate = prevProps => {
    const { ducklings } = this.props;

    if (
      !isEqual(this.stripData(ducklings), this.stripData(prevProps.ducklings))
    ) {
      this.triggerLoad();
    }
  };

  stripData = ducklings =>
    ducklings.map(duckling => ({
      ...duckling,
      data: undefined,
    }));

  triggerLoad = () => {
    const { ducklings, dispatch } = this.props;

    ducklings.forEach(({ loadAction, loading, loaded, error }) => {
      if (!loading && !loaded && !error) {
        dispatch(loadAction);
      }
    });
  };

  getDucklingsRenderProps = () => {
    const { ducklings } = this.props;
    return ducklings.length === 1 ? ducklings[0] : ducklings;
  };

  render() {
    const {
      anyError,
      anyLoading,
      children,
      spinner,
      errorComponent,
      renderProps,
      dispatch,
    } = this.props;

    if (renderProps && children) {
      return children(this.getDucklingsRenderProps(), dispatch);
    }

    if (anyError && children) {
      return errorComponent;
    }

    if (anyLoading && children) {
      return spinner;
    }

    if (children) {
      return children;
    }

    return '';
  }
}

const ducklingShape = PropTypes.shape({
  loadAction: PropTypes.object.isRequired, // redux action to trigger data load
  loadedSel: PropTypes.func, // used to prevent dispatching the action again on updates (and in other instances of this component that try to load the same data)
  loadingSel: PropTypes.func, // determines whether to show a spinner (if this component has children)
  errorSel: PropTypes.func, // determines whether to show an error fallback component (if this component has children)
  dataSel: PropTypes.func, // used to pass the loaded data down as render prop (this way the child can avoid connecting to the store)
});

ReduxLoader.propTypes = {
  ducklings: PropTypes.oneOfType([
    ducklingShape,
    PropTypes.arrayOf(ducklingShape),
  ]).isRequired,
  spinner: PropTypes.node,
  errorComponent: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  anyLoading: PropTypes.bool,
  anyError: PropTypes.bool,
  dispatch: PropTypes.func,
  renderProps: PropTypes.bool,
};

ReduxLoader.defaultProps = {
  spinner: (
    <div className={styles.spinnerContainer}>
      <Spin />
    </div>
  ),
  errorComponent: (
    <div className={styles.errorContainer}>
      <ErrorIcon className={styles.errorIcon} />
      <FormattedMessage id="shared.ReduxLoader.errorMsg" />
    </div>
  ),
};

const mapState = (state, ownProps) => {
  let { ducklings } = ownProps;
  ducklings = Array.isArray(ducklings) ? ducklings : [ducklings];

  let anyLoading = false;
  let anyError = false;

  ducklings = ducklings.map(
    ({ loadAction, loadedSel, loadingSel, errorSel, dataSel }) => {
      const loaded = loadedSel ? Boolean(loadedSel(state)) : false;
      const loading = loadingSel ? Boolean(loadingSel(state)) : false;
      const error = errorSel ? Boolean(errorSel(state)) : false;
      const data = dataSel ? dataSel(state) : undefined;
      anyLoading = anyLoading || loading;
      anyError = anyError || error;

      return {
        loadAction,
        loaded,
        loading,
        error,
        data,
      };
    },
  );

  return {
    ducklings,
    anyLoading,
    anyError,
  };
};

export default connect(mapState)(ReduxLoader);
