import identity from 'lodash/identity';
import merge from 'lodash/merge';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/takeUntil';

import { ENV, getLocalhostOrigin } from '@config/config';
import gtm from '@shared/utils/gtm';
import sentryReport from '@shared/utils/sentryReport';

import httpCancelation$ from './httpCancelation';

const reportResponseTime = (startTime, label) => {
  const endTime = new Date().getTime();
  gtm.pushEvent({
    category: gtm.categories.API,
    event: 'RESPONSE_TIME',
    label,
    value: endTime - startTime,
  });
};

const addExtraLocalhostHeaders = (body, options) => {
  let stringifiedBody;
  try {
    stringifiedBody = JSON.stringify(body);
  } catch (e) {
    stringifiedBody = '';
  }

  return merge({}, options, {
    headers: {
      'x-body': stringifiedBody,
      'x-origin': getLocalhostOrigin(),
    },
  });
};

const xhrFactory = function () {
  const xhr = new XMLHttpRequest();
  const setRequestHeader = xhr.setRequestHeader;
  xhr.setRequestHeader = function (name, value) {
    // ignore the 'x-requested-with' header (SB APIs don't like it in CORS requests)
    if (name.toLowerCase() !== 'x-requested-with') {
      setRequestHeader.call(this, name, value);
    }
  };

  return xhr;
};

// https://stackoverflow.com/questions/43785055/randomly-getting-ajax-status-as-0-in-safari-browser
// https://stackoverflow.com/questions/52434011/how-to-fix-ios-12-safari-cors-preflight-error
// https://stackoverflow.com/questions/18867707/http-and-ajax-request-returning-status-0-randomly-in-chrome
// https://stackoverflow.com/questions/38498408/random-jquery-ajax-error-readystate-0
// etc... you get the idea
// this is to try to work around those apparently random http 0 errors:
// retry a couple of times when it happens and hope for the best
const retryWhenHttpStatus0 = errors =>
  errors
    .scan(
      (acc, err) => ({
        count: acc.count + 1,
        err,
      }),
      { count: 0 },
    )
    .filter(({ count, err = {} }) => {
      if (count > 3 || err.status != 0) {
        throw err;
      }
      return true;
    })
    .delay(500);

const shouldReportToSentry = (suppressConfig, err = {}) => {
  if (typeof suppressConfig === 'boolean') {
    return !suppressConfig;
  }

  if (Array.isArray(suppressConfig)) {
    return !suppressConfig.includes(err.status);
  }

  return true;
};

export default ({
  urlTransformer = identity,
  bodyTransformer = identity,
  optionsTransformer = ({ options }) => options,
  responseHandler = identity,
  errorHandler,
}) => {
  const request$ = (method, url, body, options = {}) => {
    const transformedUrl = urlTransformer(url, options);
    const transformedBody = bodyTransformer(body);
    let transformedOptions = optionsTransformer({
      options,
      body,
      url,
      transformedUrl,
    });
    const { gtm, suppressSentry } = transformedOptions;
    const trackResponseTime = Boolean(gtm);
    const startTime = new Date().getTime();
    let { label: gtmLabel } = gtm || {};
    gtmLabel = `${gtmLabel} ${method}`;

    if (ENV === 'local' && url.startsWith('/')) {
      transformedOptions = addExtraLocalhostHeaders(body, transformedOptions);
    }

    const requestObj = {
      method,
      url: transformedUrl,
      body: transformedBody,
      createXHR: xhrFactory,
      ...transformedOptions,
    };

    return Observable.ajax(requestObj)
      .retryWhen(retryWhenHttpStatus0)
      .catch(err => {
        shouldReportToSentry(suppressSentry, err) && sentryReport.api(err);
        trackResponseTime && reportResponseTime(startTime, gtmLabel);
        errorHandler && errorHandler(err, transformedOptions);
        throw err;
      })
      .map(response => {
        trackResponseTime && reportResponseTime(startTime, gtmLabel);
        return responseHandler(response, requestObj, transformedOptions);
      })
      .takeUntil(httpCancelation$);
  };

  const request = (method, url, body, options) =>
    request$(method, url, body, options).toPromise();

  const http = {
    get$: (url, options) => request$('GET', url, undefined, options),
    post$: (url, body, options) => request$('POST', url, body, options),
    put$: (url, body, options) => request$('PUT', url, body, options),
    patch$: (url, body, options) => request$('PATCH', url, body, options),
    delete$: (url, body, options) => request$('DELETE', url, body, options),

    get: (url, options) => request('GET', url, undefined, options),
    post: (url, body, options) => request('POST', url, body, options),
    put: (url, body, options) => request('PUT', url, body, options),
    patch: (url, body, options) => request('PATCH', url, body, options),
    delete: (url, body, options) => request('DELETE', url, body, options),
  };

  return http;
};
