import {
  SBL_3435_SHOW_BULK_ENTRY_MB,
  SBL_3444_BULK_YOUTH_LEADERSHIP_POSITIONS,
  SBL_4005_SHOW_RANK_QUICK_ENTRY,
  SBL_5198_CUB_BULK_ENTRY,
} from '@config';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { Program } from '@shared/constants';

export function shouldShowMeritBadgeBulkEntry(program: string) {
  if (!SBL_3435_SHOW_BULK_ENTRY_MB) return false;

  if (program === Program.CUB_SCOUT) return false;

  return true;
}

export function shouldShowAdventuresBulkEntry(program: string) {
  if (program !== Program.CUB_SCOUT) return false;

  if (SBL_5198_CUB_BULK_ENTRY) return true;

  if (!featureFlags.getFlag('SBL_4358_SHOW_BULK_ENTRY_ADVENTURE')) return false;

  return true;
}

export function shouldShowRankBulkEntry(program: string) {
  if (!SBL_4005_SHOW_RANK_QUICK_ENTRY) return false;

  if (program === Program.CUB_SCOUT) return false;

  return true;
}

export function shouldShowYouthLeadershipBulkEntry(program: string) {
  if (!SBL_3444_BULK_YOUTH_LEADERSHIP_POSITIONS) return false;

  if (
    program === Program.BOY_SCOUT ||
    program === Program.SEA_SCOUT ||
    program === Program.VENTURING
  )
    return true;

  return false;
}
