import React from 'react';
import SelectAddMembersFlowModal from './SelectAddMembersFlowModal';
import UpdatePositionModal from './UpdatePositionModal';
import AddMembersModal from './AddMembersModal';
import ValidationModal from './ValidationModal';
import UnitPaymentErrorModal from './UnitPaymentErrorModal';
import PaymentModal from './PaymentModal';
import UploadDocumentModal from './UploadDocumentModal';
import ConfirmationPaymentModal from './ConfirmationPaymentModal';
import RemoveMembersModal from './RemoveMembersModal';
import AddToRecharterModal from './AddToRecharterModal';
import AdobeErrorModal from './AdobeErrorModal';
import ResendInviteModal from './ResendInviteModal';
import MarkAsMultipleModal from './MarkAsMultipleModal';
import UnmarkAsMultipleModal from './UnmarkAsMultipleModal';
import InviteMembersModal from './InviteMembersModal';
import DocumentPrivacyModal from './DocumentPrivacyModal';
import EditTermModal from './EditTermModal';
import AchVerificationModal from './AchVerificationModal';

const RecharterModals = () => (
  <React.Fragment>
    <InviteMembersModal />
    <UpdatePositionModal />
    <SelectAddMembersFlowModal />
    <AddMembersModal />
    <RemoveMembersModal />
    <ValidationModal />
    <UnitPaymentErrorModal />
    <PaymentModal />
    <UploadDocumentModal />
    <ConfirmationPaymentModal />
    <AddToRecharterModal />
    <AdobeErrorModal />
    <ResendInviteModal />
    <MarkAsMultipleModal />
    <UnmarkAsMultipleModal />
    <DocumentPrivacyModal />
    <EditTermModal />
    <AchVerificationModal />
  </React.Fragment>
);

export default RecharterModals;
