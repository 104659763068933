import { createAction } from '@reduxjs/toolkit';

import { moduleName } from '../constants';

export const rtkRefetchUnitEvents = createAction<{
  unitIds: Array<string | number>;
}>(`${moduleName}/RTK_REFETCH_UNIT_EVENTS`);

export const deleteRecurrentEventsResponse = createAction(
  `${moduleName}/DELETE_RECURRENT_EVENTS_RESPONSE`,
);

export const deleteEventsResponse = createAction(
  `${moduleName}/DELETE_EVENTS_RESPONSE`,
);
