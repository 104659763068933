import React, { useCallback, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { organizationGuidSel } from '@context';
import { isMobileSel } from '@screen';
import { Button, Card, FlexSpacer, Select, T } from '@shared/components';
import { intl } from '@shared/localization';
import { openBasicReport } from '@utils';

import {
  availableYouthSel,
  closeSelectYouthModal,
  selectedReportSel,
} from '../../duck';
import styles from './SelectYouthModal.less';

const { Option } = Select;

const SelectYouthModalContent = () => {
  const dispatch = useDispatch();

  const [selectedPersonGuid, setSelectedPersonGuid] = useState();

  const youths = useSelector(availableYouthSel);
  const organizationGuid = useSelector(organizationGuidSel);
  const report = useSelector(selectedReportSel);
  const isMobile = useSelector(isMobileSel);

  const handleViewReport = useCallback(() => {
    const { ReportURI } = report;
    openBasicReport(ReportURI, {
      organizationGuid,
      personGuid: selectedPersonGuid,
    });
    dispatch(closeSelectYouthModal());
  }, [organizationGuid, dispatch, report, selectedPersonGuid]);

  const handleCancel = () => dispatch(closeSelectYouthModal());

  const youthOptions = useMemo(
    () =>
      youths.map(youth => (
        <Option key={youth.personGuid} value={youth.personGuid}>
          {youth.personShortFullName}
        </Option>
      )),
    [youths],
  );

  return (
    <Card noPadding={!isMobile} shadow={isMobile} margin={isMobile}>
      {isMobile && (
        <T size="5" colored hr serif noMargin>
          <FormattedMessage
            id="reports.BasicReport.reportSelectYouth"
            values={{ reportName: report.ReportName }}
          />
        </T>
      )}
      <div className={styles.container}>
        <Select
          showSearch
          placeholder={intl.formatMessage({
            id: 'reports.BasicReport.selectYouth',
          })}
          onChange={setSelectedPersonGuid}
          optionFilterProp="children"
        >
          {youthOptions}
        </Select>
        <div className={styles.buttons}>
          <Button
            onClick={handleCancel}
            type="primary"
            color="scouting-warm-gray"
            noBorder
            shadow
          >
            <FormattedMessage id="shared.cancel" />
          </Button>
          <FlexSpacer />
          <Button
            onClick={handleViewReport}
            type="primary"
            color="scouting-blue"
            noBorder
            shadow
            disabled={!selectedPersonGuid}
          >
            <FormattedMessage id="reports.BasicReport.viewReport" />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default SelectYouthModalContent;
