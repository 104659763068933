import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { SET_UNIT } from '@shared';
import { SET_ORGANIZATION, SET_DEFAULT_ORGANIZATION } from './actions';

const organizationGuid = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ORGANIZATION: {
      return payload.guid;
    }
    default: {
      return state;
    }
  }
};

const parentOrgGuid = (state = null, { type, payload }) => {
  switch (type) {
    case SET_ORGANIZATION: {
      return payload.parentOrgGuid;
    }
    default: {
      return state;
    }
  }
};

const childOrganization = (state = null, { type, payload }) => {
  switch (type) {
    case SET_UNIT: {
      return payload;
    }
    case SET_ORGANIZATION: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const defaultOrganizationGuid = (state = null, { type, payload }) => {
  switch (type) {
    case SET_DEFAULT_ORGANIZATION: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  organizationGuid,
  childOrganization,
  defaultOrganizationGuid,
  parentOrgGuid,
});

export default resetOnLogoutReducer(reducer);
