import stable from 'stable';

import sorterUtil from './sorters';

/**
 * Applies proper sort function to items based on a simplified version of antd Table's 'sorter' object.
 *
 * @param {Array} items - items to be sorted
 * @param {Object} sorter - current sorting information
 * @param {String} [sorter.columnKey] - key of column to be sorted
 * @param {String} [sorter.order] - order of sorting ('ascend'/'descend')
 * @param {Object} sorters - object containing sorter functions for each possible key in sorter.columnKey
 * @param {Function} sorters.default - default sorter function, applied when no match is found or no specific sorting is selected
 */
export default ({ items, sorter, sorters }) => {
  const { columnKey, order } = sorter;

  const sortFn = sorters[columnKey]
    ? sorterUtil.compose(sorters[columnKey], sorters.default)
    : sorters.default;
  const sorted = stable(items, sortFn);

  return order === 'descend' ? sorted.reverse() : sorted;
};
