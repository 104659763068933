import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Hr } from '@shared/components';

import styles from './SingleReport.less';

const SingleReportDesktop = ({ name, description, openLabel, onClick }) => (
  <React.Fragment>
    <div className={styles.singleReport}>
      <span className={styles.name}>{name}</span>
      <span className={styles.description}> {description} </span>
      <span className={styles.url}>
        <Button fitText noBorder color="info" size="large" onClick={onClick}>
          {openLabel ? openLabel : <FormattedMessage id="shared.view" />}
        </Button>
      </span>
    </div>
    <Hr className={styles.hr} />
  </React.Fragment>
);

SingleReportDesktop.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  openLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default SingleReportDesktop;
