import React from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { emptyObj } from '@shared';

import InputMemo from './InputMemo';

// eslint-disable-next-line react/display-name
const ActivityValueInput = React.memo(props => {
  const methods = useFormContext();
  // eslint-disable-next-line react/prop-types
  const error = get(methods.errors, props.name);
  return (
    <InputMemo
      hasError={!!error}
      error={error}
      typeError={(error || emptyObj).type}
      {...methods}
      {...props}
    />
  );
});

export default ActivityValueInput;
