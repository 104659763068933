import PropTypes from 'prop-types';

export const appName = 'Scoutbook Plus';
export const appNameShort = 'IA';

export const moduleName = 'shared';
export const coreModuleName = 'core';
export const advancementModuleName = 'advancement';
export const activitiesModuleName = 'activities';
export const advancementHistoryModuleName = 'advancementHistory';
export const advancementHistoryModuleNamespace = `${advancementModuleName}/${advancementHistoryModuleName}`;
export const calendarModuleName = 'calendar';
export const eventsModuleName = 'events';
export const dashboardModuleName = 'dashboard';
export const packRosterModuleName = 'packRoster';
export const packRosterModuleNamespace = `${advancementModuleName}/${packRosterModuleName}`;
export const advancementReportModuleName = 'advancementReport';
export const userModuleName = 'user';
export const screenModuleName = 'screen';
export const offlineModuleName = 'offline';
export const dictModuleName = 'dict';
export const settingsModuleName = 'settings';
export const reportsModuleName = 'reports';
export const contextModuleName = 'context';
export const councilUnitsModuleName = 'councilUnits';
export const youthProfileModuleName = 'youthProfile';
export const adultProfileModuleName = 'adultProfile';
export const personProfileModuleName = 'personProfile';
export const parentGuardianModuleName = 'parentGuardian';
export const profileModuleName = 'profile';
export const permissionsManagerModuleName = 'permissionsManager';
export const messagingModuleName = 'messaging';
export const rosterLeaderAgeLabel = 'Leader';
export const packRosterFieldPreferencesName = 'unitRosterFields';
export const advancementHistoryFieldPreferencesName =
  'advancementHistoryFields';

// prefix added to redux actions that should be reported to Google Tag Manager
export const gtmPrefix = 'sbl-gtm/';

export const headerHeight = 60;
export const headerHeightMobile = 52;
export const sidebarWidth = 70;
export const sidebarExpandedWidth = 200;
export const sidebarWidthMobile = 0;
export const searchHeightMobile = 40;

export const timeFormat = 'hh:mm a';
export const viewDateTimeFormat = 'MMM DD, Y @ hh:mm';
export const viewDateTimeAMFormat = 'MMM DD, Y @ hh:mm A';
export const dobDateFormatShort = 'MMM DD, Y'; // Date of Birth short
export const dobDateFormatLong = 'MMMM DD, Y'; // Date of Birth long
export const viewDateFormat = 'MM/DD/YYYY'; // what we show to the user
export const shortViewDateFormat = 'M/D/YYYY'; // what we show to the user
// these two formats just happen to be the same, but they don't have to be
export const modelDateFormat = 'YYYY-MM-DD'; // what we store internally (e.g. in the redux store)
export const dtoDateFormat = 'YYYY-MM-DD'; // what we send/receive to/from APIs
export const dtoDateTimeFormat = 'YYYY-MM-DD[T]HH:mm:ss'; // what we send/receive to/from APIs
export const minDate = '1753-1-2';

export const screenMd = 'md';
export const screenLg = 'lg';
export const screenXl = 'xl';
export const mdWidth = 992;
export const lgWidth = 1200;

export const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const numbersRegex = /^[0-9]*$/;
// see https://en.wikipedia.org/wiki/E.123
export const usPhoneRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
export const usPhonePlaceholder = '(999) 999 9999';
export const internationalPhoneRegex =
  /\+[0-9]{1,3} [0-9]{3} [0-9]{3} [0-9]{2,4}$/;
export const internationalPhonePlaceholder = '+99 999 999 9999';
export const wholeNumberRegex = /^[0-9]+$/;

export const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
export const mastercardRegex =
  /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
export const amexRegex = /^3[47][0-9]{13}$/;
export const discoverRegex =
  /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;

export const cvvAmexRegex = /^\d{4}$/;
export const cvvRegex = /^\d{3}$/;

export const amountRegex = /^(?!0(\.00?)?$)(\d+(\.\d{1,2})?)$/;

export const acceptedCreditCards = Object.freeze({
  visaRegex,
  mastercardRegex,
  amexRegex,
  discoverRegex,
});

export const DEFAULT_TABLE_PAGESIZE = 10;

export const MAX_RECENT_UNITS = 20;

export const assistedEagleProjectId = 14;

export enum advancementTypes {
  MERIT_BADGES = 'meritBadges',
  ADVENTURES = 'adventures',
  RANKS = 'ranks',
  AWARDS = 'awards',
  SSELECTIVES = 'ssElectives',
}

export const unapproveAdvancementTypes = {
  [advancementTypes.MERIT_BADGES]: 'Merit Badge',
  [advancementTypes.ADVENTURES]: 'Adventure',
  [advancementTypes.RANKS]: 'Rank',
  [advancementTypes.AWARDS]: 'Award',
};

export const dictionaryIds = Object.freeze({
  ...advancementTypes,
  STATES: 'states',
  COUNTRIES: 'countries',
  PHONE_COUNTRIES: 'phoneCountries',
  PREFIXES: 'titlePrefixes',
  SUFFIXES: 'nameSuffixes',
  COMUNICATION_TYPES: 'comunicationTypes',
  SCHOOLS: 'schools',
  GRADES: 'grades',
  PHONE_CARRIERS: 'phoneCarriers',
  SWIMMIN_CLASSIFICATION: 'swimmingClassification',
});

// a.k.a. units
export enum Program {
  BOY_SCOUT = 'BOY_SCOUT',
  CUB_SCOUT = 'CUB_SCOUT',
  SEA_SCOUT = 'SEA_SCOUT',
  VENTURING = 'VENTURING',
  EXPLORING = 'EXPLORING',
  VARSITY = 'VARSITY', // discontinued
  CLUB = 'CLUB',
}

export const programNames = Object.freeze({
  BOY_SCOUT: 'Scouts BSA',
  CUB_SCOUT: 'Cub Scout',
  SEA_SCOUT: 'Sea Scouts',
  VENTURING: 'Venturing',
  EXPLORING: 'Exploring',
  VARSITY: 'Varsity', // discontinued
  CLUB: 'Club',
});

export enum ProgramId {
  CUB_SCOUT = 1,
  BOY_SCOUT = 2,
  VARSITY = 3,
  VENTURING = 4,
  SEA_SCOUT = 5,
  EXPLORING = 6,
  CLUB = 9,
}

export const unitsMaxAge = {
  [ProgramId.CUB_SCOUT]: undefined,
  [ProgramId.BOY_SCOUT]: 18,
  [ProgramId.VARSITY]: 21,
  [ProgramId.VENTURING]: 21,
  [ProgramId.SEA_SCOUT]: 21,
  [ProgramId.EXPLORING]: 21,
  [ProgramId.CLUB]: 21,
};

// unitTypeId is (almost?) the same as programId
export const programForUnitTypeId = Object.freeze({
  1: Program.CUB_SCOUT, // pack
  2: Program.BOY_SCOUT, // troop
  3: Program.VARSITY, // team - discontinued
  4: Program.VENTURING, // crew
  5: Program.SEA_SCOUT, // ship
  6: Program.EXPLORING, // post
  9: Program.CLUB, // club
});

export const unitTypes = Object.freeze({
  PACK: 'Pack',
  TROOP: 'Troop',
  TEAM: 'Team',
  CREW: 'Crew',
  SHIP: 'Ship',
  POST: 'Post',
  CLUB: 'Club',
});

export const unitTypeIdForUnitType = Object.freeze({
  [unitTypes.PACK]: ProgramId.CUB_SCOUT,
  [unitTypes.TROOP]: ProgramId.BOY_SCOUT,
  [unitTypes.TEAM]: ProgramId.VARSITY,
  [unitTypes.CREW]: ProgramId.VENTURING,
  [unitTypes.SHIP]: ProgramId.SEA_SCOUT,
  [unitTypes.POST]: ProgramId.EXPLORING,
  [unitTypes.CLUB]: ProgramId.CLUB,
});
export type UnitTypeName = keyof typeof unitTypeIdForUnitType;

export const programForUnitType = Object.freeze({
  [unitTypes.PACK]: Program.CUB_SCOUT,
  [unitTypes.TROOP]: Program.BOY_SCOUT,
  [unitTypes.TEAM]: Program.VARSITY,
  [unitTypes.CREW]: Program.VENTURING,
  [unitTypes.SHIP]: Program.SEA_SCOUT,
  [unitTypes.POST]: Program.EXPLORING,
  [unitTypes.CLUB]: Program.CLUB,
});

export const unitTypeIdForUnitTypeName = Object.freeze({
  1: 'pack',
  2: 'troop',
  3: 'team',
  4: 'crew',
  5: 'ship',
  6: 'post',
  9: 'club',
});

// another mapping - just for /roleTypes response...
// [programId] => unitTypeId
export const unitTypeIdForProgramTypeId = Object.freeze({
  1: 1, // programs.CUB_SCOUT,
  2: 2,
  3: 2, // programs.BOY_SCOUT,
  20: 4, // programs.VENTURING,
  21: 4, // programs.VENTURING,
  22: 5, // programs.SEA_SCOUT,
  7: 6, // programs.EXPLORING,
  // Cub Scout Dens
  12: 1,
  4: 1,
  5: 1,
  29: 1,
  // Latter Day Saints
  13: 1, // LDS Cub Scouts
  14: 2, // LDS Boy Scouts
  15: 2, // LDS Boy Scouts (again)
  16: 1, // LDS Webelos (=Cub Scouts again)
  17: 1, // LDS Cub Scouts (yet again)
  19: 6, // LDS Exploring
  23: 4, // LDS Venturing
  24: 4, // LDS Venturing (again)
  25: 5, // LDS Sea Scouts
  44: 9, //Exploring Sponsor
});

export const programTypeNameForProgramTypeId = Object.freeze({
  'Boy Scouting': 3, // programs.BOY_SCOUT,
  'Cub Scouting': 1, // programs.CUB_SCOUT,
  'Cub Scouts': 1, // programs.CUB_SCOUT,
  'LFL Explorers': 7, // programs.EXPLORING,
  'Lone Cubs': 1, // programs.CUB_SCOUT,
  'Old Explorers': 7, // programs.EXPLORING,
  Venturers: 21, // programs.VENTURING,
  'Sea Scouts': 22, // programs.SEA_SCOUT,
  'Sea Scouting': 22, // programs.SEA_SCOUT,
  'Scouts BSA': 3, // programs.BOY_SCOUT,
  Tigers: 1, // programs.CUB_SCOUT,
  Venturing: 21, // programs.VENTURING,
  Webelos: 1, // programs.CUB_SCOUT,
  Lions: 1, // programs.CUB_SCOUT,
});

export type ProgramTypeName = keyof typeof programTypeNameForProgramTypeId;

export const ranksIds = Object.freeze({
  SCOUT: 1,
  TENDERFOOT: 2,
  SECOND_CLASS: 3,
  FIRST_CLASS: 4,
  STAR_SCOUT: 5,
  LIFE_SCOUT: 6,
  EAGLE_SCOUT: 7,
  TIGER: 8,
  WOLF: 9,
  BEAR: 10,
  WEBELOS: 11,
  ARROW_OF_LIGHT: 12,
  BOBCAT: 13,
  LION: 14,
  APPRENTICE: 15,
  ORDINARY: 16,
  ABLE: 17,
  QUARTERMASTER: 18,
  VENTURING: 19,
  DISCOVERY: 20,
  PATHFINDER: 21,
  SUMMIT: 22,
});

export const ranksLevels = Object.freeze({
  SCOUT: 1,
  TENDERFOOT: 2,
  SECOND_CLASS: 3,
  FIRST_CLASS: 4,
  STAR_SCOUT: 5,
  LIFE_SCOUT: 6,
  EAGLE_SCOUT: 7,
  TIGER: 2,
  WOLF: 3,
  BEAR: 4,
  WEBELOS: 5,
  ARROW_OF_LIGHT: 6,
  BOBCAT: 1,
  LION: 0,
  APPRENTICE: 1,
  ORDINARY: 2,
  ABLE: 3,
  QUARTERMASTER: 4,
  VENTURING: 1,
  DISCOVERY: 2,
  PATHFINDER: 3,
  SUMMIT: 4,
});

export const rankIdsWithoutApproveOption = Object.freeze([
  ranksIds.EAGLE_SCOUT, // Scouts BSA Eagle
  ranksIds.SUMMIT, // Venturing Summit
  ranksIds.QUARTERMASTER, // Sea Scout Quartermaster
]);

export const rankName = Object.freeze({
  EAGLE_SCOUT: 'Eagle Scout',
  LIFE_SCOUT: 'Life Scout',
  STAR_SCOUT: 'Star Scout',
  FIRST_CLASS: 'First Class',
  SECOND_CLASS: 'Second Class',
  ARROW_OF_LIGHT: 'Arrow of Light',
});

export const ranksOrder = Object.freeze({
  // Sea Scout ranks
  [ranksIds.QUARTERMASTER]: 18,
  Quartermaster: 18,
  [ranksIds.ABLE]: 17,
  Able: 17,
  [ranksIds.ORDINARY]: 16,
  Ordinary: 16,
  [ranksIds.APPRENTICE]: 15,
  Apprentice: 15,
  // Scouts BSA's ranks
  [ranksIds.EAGLE_SCOUT]: 14,
  'Eagle Scout': 14,
  Eagle: 14,
  [ranksIds.LIFE_SCOUT]: 13,
  'Life Scout': 13,
  [ranksIds.STAR_SCOUT]: 12,
  'Star Scout': 12,
  [ranksIds.FIRST_CLASS]: 11,
  '1st Class': 11,
  'First Class': 11,
  [ranksIds.SECOND_CLASS]: 10,
  '2nd Class': 10,
  'Second Class': 10,
  [ranksIds.TENDERFOOT]: 9,
  Tenderfoot: 9,
  [ranksIds.SCOUT]: 8,
  Scout: 8,
  // Cub Scout Ranks
  [ranksIds.ARROW_OF_LIGHT]: 7,
  'Arrow of Light': 7,
  [ranksIds.WEBELOS]: 6,
  Webelos: 6,
  [ranksIds.BEAR]: 5,
  Bear: 5,
  [ranksIds.WOLF]: 4,
  Wolf: 4,
  [ranksIds.TIGER]: 3,
  Tiger: 3,
  [ranksIds.BOBCAT]: 2,
  Bobcat: 2,
  [ranksIds.LION]: 1,
  Lion: 1,
});

export const firstClassRankOrder = ranksOrder['1st Class'];
export const bobcatRankOrder = ranksOrder['Bobcat'];
export const arrowOfLightOrder = ranksOrder['Arrow of Light'];
export const firstClassRankId = ranksIds.FIRST_CLASS;
export const eagleScoutRankId = ranksIds.EAGLE_SCOUT;
export const bobcatRankId = ranksIds.BOBCAT;
export const venturingCoreAwardsIds = ['142', '143', '144', '145'];

export const advancementTabNames = Object.freeze({
  ROSTER: 'roster',
  ACTIVITY: 'activity',
  PENDING: 'pending',
  ADVANCEMENTS: 'advancements',
});

export const advancementSubTabNames = Object.freeze({
  BULK_ENTRY: 'bulk',
  TO_APPROVE: 'approve',
  TO_PURCHASE: 'purchase',
  TO_AWARD: 'award',
  AWARDED: 'awarded',
});

export const PermissionsTabNames = Object.freeze({
  PERMISSIONS: 'permissions',
  POSITIONS: 'positions',
  ROLES: 'roles',
});

export const sortOrderPropTypes = PropTypes.oneOf(['ascend', 'descend']);

export const sorterPropTypes = PropTypes.shape({
  columnKey: PropTypes.string,
  order: sortOrderPropTypes,
});

export const advancementStatuses = Object.freeze({
  NOT_STARTED: 'notStarted',
  STARTED: 'started',
  COMPLETED: 'completed',
  APPROVED: 'approved',
  COUNSELOR_APPROVED: 'counselorApproved',
  AWARDED: 'awarded',
  DISABLED: 'disabled',
  DICTIONARY: 'dictionary',
});
export type AdvancementStatus =
  (typeof advancementStatuses)[keyof typeof advancementStatuses];

export const payloadStatusValues = Object.freeze({
  uncompleted: 'Not Complete',
  completed: 'Completed',
  leaderApproved: 'Leader Approved',
});

/**
 * From '~bsa-ui/src/style/color/colors.less';
 */
export const advancementStatusColors = Object.freeze({
  notStarted: '#969897',
  started: '#ff6200',
  completed: '#039b03',
  approved: '#067eeb',
  counselorApproved: '#067eeb',
  awarded: '#a69b7d',
  disabled: '#969897',
});

// Using same values from SBM App
export const advancementStatusValues = Object.freeze({
  disabled: 0,
  notStarted: 10,
  started: 30,
  completed: 50,
  approved: 70,
  counselorApproved: 90,
  awarded: 100,
});

export const advancementFileStatuses = Object.freeze({
  PENDING: 'pending',
  PROCESSED: 'processed',
  CANCELLED: 'cancelled',
  FAILED: 'failed',
});

export const syncOperations = Object.freeze({
  RECORD_ADVANCEMENT: 'RECORD_ADVANCEMENT',
  APPROVE_ADVANCEMENTS: 'APPROVE_ADVANCEMENTS',
  EDIT_ADVANCEMENT: 'EDIT_ADVANCEMENT',
  UNAPPROVE_ADVANCEMENT: 'UNAPPROVE_ADVANCEMENT',
});

export const unapproveLocks = Object.freeze({
  IS_FIRST_CLASS: 'firstClass',
  LOWER_LEVEL: 'lowerLevel',
  IS_EAGLE_SCOUT: 'eagleScout',
  IS_OFFLINE_RECORDED: 'offlineRecorded',
  IS_ACTIVITY: 'isActivity',
});

export const requirementDisplay = Object.freeze({
  default: { headerNumberProp: 'listNumber' },
  [advancementTypes.SSELECTIVES]: { headerNumberProp: 'ssLevel' },
});

export const councilOrganizationTypeId = 4;
export const districtOrganizationTypeId = 5;
export const districtCouncilStructureTypeId = 4;
export const subdistrictOrganizationTypeId = districtOrganizationTypeId;
export const subdistrictCouncilStructureTypeId = 3;

export const activityValuesTabs = Object.freeze({
  BASIC: 'basic',
  ADVANCED: 'advanced',
});

export const activityTypeIds = Object.freeze({
  SERVICE_PROJECTS: 1,
  CAMPOUTS: 2,
  HIKES: 3,
  LONG_CRUISE: 4,
  EAGLE_SERVICE_PROJECT: 5,
});

export const activityTypes = Object.freeze({
  SERVICE_PROJECTS: 'serviceProjects',
  CAMPOUTS: 'camping',
  HIKES: 'hikes',
  LONG_CRUISE: 'longCruise',
  EAGLE_SERVICE_PROJECT: 'eagleServiceProject',
});

export const activityCalendarTypes = Object.freeze({
  SERVICE_PROJECT: 'serviceProject',
});

export const metricTypes = Object.freeze({
  SERVICE_PROJECTS: 'hours',
  CAMPOUTS: 'nights',
  HIKES: 'miles',
  LONG_CRUISE: 'days',
  EAGLE_SERVICE_PROJECT: 'hours',
});

export const activityTypeForActivityTypeId = Object.freeze({
  [activityTypeIds.SERVICE_PROJECTS]: activityTypes.SERVICE_PROJECTS,
  [activityTypeIds.CAMPOUTS]: activityTypes.CAMPOUTS,
  [activityTypeIds.HIKES]: activityTypes.HIKES,
  [activityTypeIds.LONG_CRUISE]: activityTypes.LONG_CRUISE,
  [activityTypeIds.EAGLE_SERVICE_PROJECT]: activityTypes.EAGLE_SERVICE_PROJECT,
});

export const activityTypeIdsForActivityType = Object.freeze({
  [activityTypes.SERVICE_PROJECTS]: activityTypeIds.SERVICE_PROJECTS,
  [activityTypes.CAMPOUTS]: activityTypeIds.CAMPOUTS,
  [activityTypes.HIKES]: activityTypeIds.HIKES,
  [activityTypes.LONG_CRUISE]: activityTypeIds.LONG_CRUISE,
});

export const activityValueTypes = Object.freeze({
  HOURS: 1,
  DAYS: 2,
  NIGHTS: 3,
  FROST_POINTS: 4,
  MILES: 5,
  LOWEST_POINT: 6,
  HIGHEST_POINT: 7,
  CONSERVATION_HOURS: 8,
  LONG_CRUISE_DAYS: 9,
  EAGLE_HOURS: 10,
});

export const activityValueTypesNames = Object.freeze({
  HOURS: 'hours',
  DAYS: 'days',
  NIGHTS: 'nights',
  FROST_POINTS: 'frostPoints',
  MILES: 'miles',
  LOWEST_POINT: 'lowestPoint',
  HIGHEST_POINT: 'highestPoint',
  CONSERVATION_HOURS: 'conservationHours',
  LONG_CRUISE_DAYS: 'longCruiseDays',
});

// add fixed categoryId since is required for create activity endpoint the Id corresponds to "not applicable"
export const notApplicableCategories = {
  [activityTypeIds.CAMPOUTS]: 8,
  [activityTypeIds.HIKES]: 9,
  [activityTypeIds.LONG_CRUISE]: 12,
};

export const historyItemsTypes = Object.freeze({
  ADVANCEMENT: 'advancement',
  ADVANCEMENT_FILE: 'advancementFile',
  REQUIREMENT: 'requirement',
  ACTIVITY: 'activity',
});

export const unitGendersTypes = Object.freeze({
  BOYS: 'BOYS',
  GIRLS: 'GIRLS',
  FAMILY: 'FAMILY',
  FAMILY_SPLIT: 'BOYS & GIRLS',
});

export type UnitGenderType = keyof typeof unitGendersTypes;

export const stepsStatus = Object.freeze({
  WAIT: 'wait',
  FINISH: 'finish',
  PROCESS: 'process',
});

export const memberTypeIds = Object.freeze({
  youth: 1,
  adult: 2,
  participant: 3,
  parent: 4,
});

export const memberTypeLabels = Object.freeze({
  youth: 'Youth',
  adult: 'Adult',
  participant: 'Participant',
  parent: 'Parent',
});

export const memberTypes = [
  { id: memberTypeIds.adult, label: memberTypeLabels.adult },
  { id: memberTypeIds.youth, label: memberTypeLabels.youth },
  { id: memberTypeIds.participant, label: memberTypeLabels.participant },
];

export const parentGuardian = 'Parent/Guardian';

export const denTypesSingleNames: Record<string, string> = {
  lions: 'lion',
  tigers: 'tiger',
  wolves: 'wolf',
  bears: 'bear',
  aol: 'aol',
};

export const denTypesNames = {
  lions: 'lions',
  tigers: 'tigers',
  wolves: 'wolves',
  bears: 'bears',
  webelos: 'webelos',
  aol: 'aol',
};

export const denTypes = [
  {
    id: 5,
    name: denTypesNames.lions,
  },
  {
    id: 1,
    name: denTypesNames.tigers,
  },
  {
    id: 2,
    name: denTypesNames.wolves,
  },
  {
    id: 3,
    name: denTypesNames.bears,
  },
  {
    id: 4,
    name: denTypesNames.webelos,
  },
  {
    id: 6,
    name: denTypesNames.aol,
    label: 'Arrow of Light',
  },
];

// to avoid re-renders when returning and empty object in selectors
export const emptyObj = Object.freeze({});
export const emptyArr = Object.freeze([]);

export const advancementsExpiredKeys = Object.freeze({
  ADVENTURES: 'expiryDt',
  AWARDS: 'expiredDate',
});

export const addressTypeIds = Object.freeze({
  BUSINESS: 64,
  HOME: 65,
  VACATION: 70,
});

export const usAkelaCountryId = 616;

export const subscriptionIds = Object.freeze({
  TEXT: 17,
  SCOUTBOOK: 50,
  REMINDERS: 51,
});

export const phoneTypeIds = Object.freeze({
  HOME: 3,
  MOBILE: 4,
  WORK: 5,
  SMS: 10,
});

export const remindersTypes = Object.freeze({
  ASAP: 'ASAP',
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
});

export const remindersTypeIds = Object.freeze({
  [remindersTypes.ASAP]: 12,
  [remindersTypes.HOURS]: 13,
  [remindersTypes.DAYS]: 14,
  [remindersTypes.WEEKS]: 15,
  [remindersTypes.MONTHS]: 16,
});

export enum PositionId {
  PatrolLeader = 5,
  AsstPatrolLeader = 6,
  DenAdmin = 151,
  DenChief = 10,
  PatrolAdmin = 152,
  CalendarEditor = 350,
  VenturePatrolLeader = 18,
}
export const positionIdsThatRequirePatrolId = [
  PositionId.PatrolLeader,
  PositionId.AsstPatrolLeader,
  PositionId.PatrolAdmin,
];

export const positionIdsThatRequireDenId = [
  PositionId.DenAdmin,
  PositionId.DenChief,
];

export const unitAdminRoles = ['Pack Admin', 'Troop Admin'];
export const subUnitAdminRoles = ['Den Admin', 'Patrol Admin'];

export const rsvpKey = Object.freeze({
  YES: 'shared.rsvp.yes',
  NO: 'shared.rsvp.no',
  MAYBE: 'shared.rsvp.maybe',
  YES_YOUTHS: 'shared.rsvp.yes.shared.youths',
  YES_DEN_CHIEF: 'shared.rsvp.yes.shared.denChiefs',
  YES_ADULTS: 'shared.rsvp.yes.shared.adults',
  YES_PARENTS: 'shared.rsvp.yes.shared.parents',
  YES_GUESTS: 'shared.rsvp.yes.shared.guests',
  NO_YOUTHS: 'shared.rsvp.no.shared.youths',
  NO_DEN_CHIEF: 'shared.rsvp.no.shared.denChiefs',
  NO_ADULTS: 'shared.rsvp.no.shared.adults',
  NO_PARENTS: 'shared.rsvp.no.shared.parents',
  NO_GUESTS: 'shared.rsvp.no.shared.guests',
  MAYBE_YOUTHS: 'shared.rsvp.maybe.shared.youths',
  MAYBE_DEN_CHIEF: 'shared.rsvp.maybe.shared.denChiefs',
  MAYBE_ADULTS: 'shared.rsvp.maybe.shared.adults',
  MAYBE_PARENTS: 'shared.rsvp.maybe.shared.parents',
  MAYBE_GUESTS: 'shared.rsvp.maybe.shared.guests',
});

export const generalKey = Object.freeze({
  YOUTHS: 'shared.youths',
  ADULTS: 'shared.adults',
  PARENTS: 'shared.parents',
  GUESTS: 'shared.guests',
  DEN_CHEIFS: 'shared.denChiefs',
});

export const expandAllPersonListRSVPParent = [
  rsvpKey.YES,
  rsvpKey.NO,
  rsvpKey.MAYBE,
];

export const expandAllPersonListRSVPChild = [
  rsvpKey.YES_YOUTHS,
  rsvpKey.YES_DEN_CHIEF,
  rsvpKey.YES_ADULTS,
  rsvpKey.YES_PARENTS,
  rsvpKey.YES_GUESTS,
  rsvpKey.NO_YOUTHS,
  rsvpKey.NO_DEN_CHIEF,
  rsvpKey.NO_ADULTS,
  rsvpKey.NO_PARENTS,
  rsvpKey.NO_GUESTS,
  rsvpKey.MAYBE_YOUTHS,
  rsvpKey.MAYBE_DEN_CHIEF,
  rsvpKey.MAYBE_ADULTS,
  rsvpKey.MAYBE_PARENTS,
  rsvpKey.MAYBE_GUESTS,
];

export const expandAllPersonList = [
  generalKey.YOUTHS,
  generalKey.ADULTS,
  generalKey.PARENTS,
  generalKey.GUESTS,
  generalKey.DEN_CHEIFS,
];

export const attendeePanelKey = Object.freeze({
  YOUTHS: 'youths',
  ADULTS: 'adults',
  PARENTS: 'parents',
  GUESTS: 'guests',
  MY_YOUTHS: 'myYouths',
  DEN_CHIEFS: 'denChiefs',
});

export const expandAllAttendeeList = [
  attendeePanelKey.YOUTHS,
  attendeePanelKey.ADULTS,
  attendeePanelKey.PARENTS,
  attendeePanelKey.GUESTS,
  attendeePanelKey.MY_YOUTHS,
  attendeePanelKey.DEN_CHIEFS,
];

export const messagingRoles = [
  'Pack Admin',
  'Key 3 Delegate',
  'Chartered Organization Rep Delegate',
  'Unit Outdoors / Activities Chair',
  'Committee Secretary',
  'Troop Admin',
  'Crew Admin',
  'Ship Admin',
  'Cubmaster',
  'Chartered Organization Rep.',
  'Council Unit Representative',
  'Committee Chairman',
  'Scoutmaster',
  'Venturing Crew Advisor',
  'Skipper',
  'Den Admin',
  'Patrol Admin',
  'Assistant Den Leader',
  'Den Leader',
  'Assistant Webelos Den Leader',
  'Webelos I Den Leader',
  'Tiger Den Leader',
  'Assistant Scoutmaster',
];

export const dragType = Object.freeze({
  PERSON: 'person',
});

export const objElementName = Object.freeze({
  NAME: 'name',
  SUB_UNIT_NAME: 'subUnitName',
  UNASSIGNED: 'unassigned',
});

export const personTagType = Object.freeze({
  LEADER: 'Leader',
  PARENT: 'Parent',
  CHILD: 'Child',
  YOUTH: 'Youth',
});

export const ParticipantPositionId = Object.freeze({
  EXPLORING_PARTICIPANT: 349,
  UNIT_PARTICIPANT: 327,
  VENTURING_PARTICIPANT: 247,
});

export const ParticipantPositionIdsList = [
  ParticipantPositionId.EXPLORING_PARTICIPANT,
  ParticipantPositionId.UNIT_PARTICIPANT,
  ParticipantPositionId.VENTURING_PARTICIPANT,
];

export const pageUI = Object.freeze({
  EVENT: 'event',
  ACTIVITY: 'activity',
});
