import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, T } from '@shared';

import { POStatusTypes } from '../../constants';
import {
  closedOrdersSel,
  closedPOModalVisibleSel,
  hideClosedPOModal,
} from '../../duck';
import { POCardPlaceholder } from '../POCardPlaceholder';
import { ClosedPOList } from './ClosedPOList';
import styles from './ClosedPOModal.less';

export const ClosedPOModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(closedPOModalVisibleSel);
  const closedOrders = useSelector(closedOrdersSel);

  const handleCancel = () => dispatch(hideClosedPOModal());

  return (
    <Modal
      visible={isOpen}
      onCancel={handleCancel}
      size="large"
      fullscreenMobile={false}
      centered
    >
      <T size="4" className={styles.modalTitle}>
        <FormattedMessage id="purchaseOrder.ClosedPOModal.title" />
      </T>
      {closedOrders.length ? (
        <ClosedPOList orders={closedOrders} />
      ) : (
        <POCardPlaceholder status={POStatusTypes.CLOSED} />
      )}
    </Modal>
  );
};
