export enum ReminderType {
  ASAP = 'asap',
  HOURS = 'hours',
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
}

export enum DeleteRecurrentEventOption {
  AllFromDate = 'allFromDate',
  AllSinceToday = 'allSinceToday',
}
