import { dtoDateFormat } from '@shared';
import _ from 'lodash';
import moment from 'moment';

export function sortBySubUnitNumber(includedSubUnits) {
  return _.chain(includedSubUnits)
    .map(subunit => {
      let sortKey = '';

      const match = /^(?<subUnitNumber>\d+)/.exec(subunit.subUnitName);

      if (match != null) {
        sortKey = Number(match.groups.subUnitNumber);
      }

      return {
        ...subunit,
        sortKey,
      };
    })
    .orderBy(['sortKey', 'subUnitName'], ['asc', 'asc'])
    .value();
}

export function transferPerson(
  tempOrgRegPositions,
  fromPosition,
  toPosition,
  selectedPerson,
) {
  const { personGuid } = selectedPerson;
  const { PositionId: fromPositionId } = fromPosition;
  const { PositionId: toPositionId } = toPosition;

  const updatedList = tempOrgRegPositions
    .map(position => {
      if (position.PositionId === fromPositionId) {
        const updatedAssigned = position.personsAssigned.filter(
          person => person.personGuid !== personGuid,
        );
        return { ...position, personsAssigned: updatedAssigned };
      }

      return position;
    })
    .map(position => {
      if (position.PositionId === toPositionId) {
        const updatedAssigned = [...position.personsAssigned, selectedPerson];
        return { ...position, personsAssigned: updatedAssigned };
      }

      return position;
    });

  return updatedList;
}

export function formatPersonExpire(selectedPerson, fromPosition) {
  const { personGuid } = selectedPerson;
  const { PositionId: fromPositionId } = fromPosition;

  const formattedEntry = {
    PersionGUID: personGuid,
    PositionId: fromPositionId,
  };

  return formattedEntry;
}

export function formatPersonAssign(selectedPerson, toPosition) {
  const { personGuid } = selectedPerson;
  const { PositionId: toPositionId } = toPosition;

  const formattedEntry = {
    PersionGUID: personGuid,
    PositionId: toPositionId,
    EffectiveDt: moment().format('YYYY-MM-DD'),
  };

  return formattedEntry;
}

export function createReassignPayload(initialList, updatedList) {
  const reduceInitialList = initialList.reduce((acc, position) => {
    const personWithPositionId = position.personsAssigned.map(person => ({
      PositionId: position.PositionId,
      ...person,
    }));
    return acc.concat(personWithPositionId);
  }, []);

  const personListMoveFrom = reduceInitialList
    .map(person => {
      const positionToCheck = updatedList.find(
        position => position.PositionId === person.PositionId,
      );
      const personExist =
        positionToCheck &&
        positionToCheck.personsAssigned.find(
          assigned => assigned.personGuid === person.personGuid,
        );

      if (!personExist) {
        return { fromPositionId: person.PositionId, ...person };
      }
      return null;
    })
    .filter(Boolean);

  const personListMoveTo = personListMoveFrom
    .map(person => {
      let foundRecord = null;
      updatedList.some(position =>
        position.personsAssigned.some(assigned => {
          if (
            assigned.pkpersonPositionId === person.pkpersonPositionId &&
            position.PositionId !== person.fromPositionId
          ) {
            foundRecord = { toPositionId: position.PositionId, ...person };
            return true;
          }
        }),
      );
      return foundRecord;
    })
    .filter(Boolean);

  const reassignPayload = initialList.map(({ PositionId }) => ({
    PositionId: PositionId.toString(),
    Assign: personListMoveTo
      .filter(person => person.toPositionId === PositionId)
      .map(person => ({
        personguid: person.personGuid,
        EffectiveDt: moment().format(dtoDateFormat),
        ExpiryDt: moment().endOf('year').format(dtoDateFormat),
      })),
    Expire: personListMoveFrom
      .filter(person => person.fromPositionId === PositionId)
      .map(person => person.personGuid),
  }));

  return reassignPayload.filter(
    ({ Assign, Expire }) => Assign.length || Expire.length,
  );
}

export function checkExceeds(list) {
  let isExceed = false;

  list.map(position => {
    const { personsAssigned, max } = position;
    if (personsAssigned.length > max) {
      isExceed = true;
    }
  });

  return isExceed;
}
