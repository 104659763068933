import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Col,
  Form,
  Input,
  PersonAvatar,
  Row,
  S,
  activityTypeForActivityTypeId,
  activityTypeIds,
  devMockHighlightClassName,
} from '@shared';

import {
  activityTypesToAdvancedKeys,
  advancedConfig,
  personsShape,
} from '../../../constants';
import { personsToRemoveSel, selectedActivitySel } from '../../../duck';
import { useCalculateDays } from '../../../hooks';
import { activityValueRule, fieldLessOrEqualRule } from '../../../utils';
import styles from './AdvancedTab.less';
import AdvancedTitles from './AdvancedTitles';

const FormItem = Form.Item;

const advancedTitlesPerActivityType = Object.freeze({
  [activityTypeIds.CAMPOUTS]: [
    'progress.common.days',
    'progress.common.nights',
    ['progress.common.frostPoints', false],
  ],
  [activityTypeIds.HIKES]: [
    'progress.common.miles',
    'progress.common.lowestPoint',
    ['progress.common.highestPoint', false],
  ],
  [activityTypeIds.LONG_CRUISE]: ['progress.common.days'],
  [activityTypeIds.SERVICE_PROJECTS]: ['progress.common.hours'],
});

const getFilteredPersons = persons =>
  persons
    // TODO: refactor and remove hasOwnProperty
    // eslint-disable-next-line no-prototype-builtins
    .filter(person => !person.hasOwnProperty('isYouth'))
    .reduce((acc, { userId }) => ({ ...acc, [`u${userId}`]: undefined }), {});

const AdvancedTab = React.memo(function AdvancedTab({
  form,
  isActive,
  youth: selectedYouths = [],
  adults: selectedAdults = [],
  showDescription,
  activityTypeId,
  setInitialTabValues,
}) {
  const [isLoadedFormData, setIsLoadedFormData] = useState(false);
  const [youthItems, setYouthItems] = useState(null);
  const [adultItems, setAdultItems] = useState(null);
  const selectedActivity = useSelector(selectedActivitySel);
  const removedPersons = useSelector(personsToRemoveSel);
  const { getFieldsValue } = form;
  const initValues = useRef();

  const calculatedDays = useCalculateDays(getFieldsValue(), form);

  const filterRemovedPersons = useCallback(
    persons =>
      persons.filter(person => !removedPersons.includes(person.userId)),
    [removedPersons],
  );

  const getUniquePersons = useMemo(() => {
    const { registeredAdults = [], registeredYouths = [] } =
      selectedActivity || [];
    const youths = uniqBy(
      [...filterRemovedPersons(registeredYouths), ...selectedYouths],
      'userId',
    );
    const adults = uniqBy(
      [...filterRemovedPersons(registeredAdults), ...selectedAdults],
      'userId',
    );
    return { youths, adults };
  }, [selectedActivity, filterRemovedPersons, selectedYouths, selectedAdults]);

  const { youths, adults } = getUniquePersons;
  const columnSettings = advancedConfig[activityTypeId];
  const basicActivityKeys = activityTypesToAdvancedKeys[activityTypeId];
  const activityType = activityTypeForActivityTypeId[activityTypeId];
  const activityTitles = advancedTitlesPerActivityType[activityTypeId];

  const renderPersons = useCallback(
    (persons, type) => {
      const { getFieldDecorator, getFieldsValue } = form;
      const formValues = getFieldsValue();
      const advancedActivityKeys = {};
      const selectedActivitValues = {};
      const basicValues = {};
      const inputRules = {};
      const initRenderValues = {};

      columnSettings.forEach(columnSetting => {
        const [baseKey, isRequired, validateFloat, isDayDate] = columnSetting;
        inputRules[baseKey] = [];
        if (isDayDate !== undefined && isActive) {
          const addExtraDay = isDayDate ? 1 : 0;
          inputRules[baseKey].push(
            ...fieldLessOrEqualRule(calculatedDays + addExtraDay, {
              isDayDate,
            }),
          );
        }
        inputRules[baseKey].push(
          ...activityValueRule(isActive, validateFloat, isRequired),
        );
        const selectedActivityKey = (advancedActivityKeys[
          baseKey
        ] = `${type}${baseKey}`);
        const basicValueKey = basicActivityKeys[selectedActivityKey];
        selectedActivitValues[baseKey] =
          selectedActivity[selectedActivityKey] || {};
        basicValues[baseKey] = formValues[basicValueKey];
      });

      const filterPesons = getFilteredPersons(persons);
      Object.keys(advancedActivityKeys).forEach(key => {
        initRenderValues[advancedActivityKeys[key]] = {
          ...selectedActivitValues[key],
          ...filterPesons,
        };
      });
      initValues.current = { ...initValues.current, ...initRenderValues };

      return persons.map(({ userId, personShortFullName, isAdult }) => (
        <Row key={userId} type="flex" className={styles.personItem}>
          <Col xs={24} lg={9}>
            <div className={styles.avatarAndName}>
              <PersonAvatar
                className={cn(styles.personAvatar, devMockHighlightClassName)}
                isAdult={isAdult}
                src={null}
              />
              <span>{personShortFullName}</span>
            </div>
          </Col>
          {columnSettings.map(([baseKey, isRequired]) => {
            const existingActivityValues = selectedActivitValues[baseKey];
            return (
              <Col xs={8} lg={5} key={`${userId}${baseKey}`}>
                <FormItem required={false}>
                  <div>
                    <S size="5" colored className={styles.inputLabel}>
                      <FormattedMessage
                        id={`progress.common.ActivityTabs.inputTitle.${baseKey}`}
                      />
                      {isRequired && '*'}
                    </S>
                    {getFieldDecorator(
                      `${advancedActivityKeys[baseKey]}.u${userId}`,
                      {
                        initialValue:
                          existingActivityValues[`u${userId}`] ||
                          basicValues[baseKey],
                        rules: inputRules[baseKey],
                      },
                    )(
                      <Input
                        size="large"
                        type="number"
                        min="0"
                        step="any"
                        placeholder="0"
                      />,
                    )}
                  </div>
                </FormItem>
              </Col>
            );
          })}
        </Row>
      ));
    },
    [
      form,
      isActive,
      calculatedDays,
      columnSettings,
      basicActivityKeys,
      selectedActivity,
    ],
  );

  useEffect(() => {
    if (youths.length > 0) {
      setYouthItems(renderPersons(youths, 'youth'));
    }
    if (adults.length > 0) {
      setAdultItems(renderPersons(adults, 'adult'));
    }
    setIsLoadedFormData(true);
  }, [youths, adults, renderPersons]);

  useEffect(() => {
    if (!isLoadedFormData) {
      setInitialTabValues(initValues.current);
    }
  }, [isLoadedFormData, setInitialTabValues, initValues]);

  return (
    <React.Fragment>
      {showDescription && (
        <S size="6">
          <FormattedMessage
            id={`progress.common.ActivityTabs.${activityType}.advancedDescription`}
            values={{
              tabName: (
                <span className={styles.descriptionTabName}>
                  <FormattedMessage id="progress.common.ActivityTabs.advanced" />
                </span>
              ),
            }}
          />
        </S>
      )}
      {youths.length > 0 && (
        <AdvancedTitles titles={activityTitles} type="youth" />
      )}
      {youthItems}
      {adults.length > 0 && (
        <AdvancedTitles titles={activityTitles} type="adults" />
      )}
      {adultItems}
    </React.Fragment>
  );
});

AdvancedTab.propTypes = {
  form: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  youth: personsShape,
  adults: personsShape,
  showDescription: PropTypes.bool.isRequired,
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  setInitialTabValues: PropTypes.func.isRequired,
};

export default AdvancedTab;
