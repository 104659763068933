import React from 'react';
import PropTypes from 'prop-types';
import { Affix as AntdAffix } from 'antd';

class Affix extends React.PureComponent {
  state = {};

  componentDidMount() {
    // Wait for ref
    setTimeout(this.updateFixedSize);
  }

  childrenWrapperNode;

  setChildrenWrapperNode = node => {
    this.childrenWrapperNode = node;
  };

  updateFixedSize = () => {
    const childrenWrapperNode = this.childrenWrapperNode;
    if (childrenWrapperNode) {
      this.setState({
        childHeight: childrenWrapperNode.offsetHeight,
      });
    }
  };

  render() {
    const { fixedSize, children, ...props } = this.props;

    const affix = (
      <AntdAffix {...props}>
        <div ref={this.setChildrenWrapperNode}>{children}</div>
      </AntdAffix>
    );

    // On iOS Safari, when the user scrolls down, the URL field and bottom bar
    // get hidden, so the view gets resized triggering an event at antd's affix.
    // For some wierd reason it sets height to undefined and then to child's height
    // causing a flickering on the elements below affix.
    // This fixes that issue.
    if (fixedSize) {
      return <div style={{ height: this.state.childHeight }}>{affix}</div>;
    }

    return affix;
  }
}

Affix.propTypes = {
  ...AntdAffix.propTypes,
  fixedSize: PropTypes.bool,
};

Affix.defaultProps = {
  ...AntdAffix.defaultProps,
  fixedSize: true,
};

export default Affix;
