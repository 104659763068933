import { historyReportsModuleNamespace } from '../../common';

const namespace = historyReportsModuleNamespace;

export const HISTORY_REPORTS_REQUEST = `${namespace}/HISTORY_REPORTS_REQUEST`;
export const historyReportsRequest = () => ({ type: HISTORY_REPORTS_REQUEST });

export const HISTORY_REPORTS_RESPONSE = `${namespace}/HISTORY_REPORTS_RESPONSE`;
export const historyReportsResponse = roster => ({
  type: HISTORY_REPORTS_RESPONSE,
  payload: roster,
});

export const HISTORY_REPORTS_ERROR = `${namespace}/HISTORY_REPORTS_ERROR`;
export const historyReportsError = error => ({
  type: HISTORY_REPORTS_ERROR,
  payload: error,
});

export const HISTORY_REPORTS_SEARCH = `${namespace}/HISTORY_REPORTS_SEARCH`;
export const historyReportsSearch = search => ({
  type: HISTORY_REPORTS_SEARCH,
  payload: search,
});

export const SET_SORTER = `${namespace}/SET_SORTER`;
export const setSorter = ({ columnKey, order }) => ({
  type: SET_SORTER,
  payload: { columnKey, order },
});

export const SET_SHOW_PRICE = `${namespace}/SET_SHOW_PRICE`;
export const setShowPrice = showPrice => ({
  type: SET_SHOW_PRICE,
  payload: showPrice,
});
