import React, { useCallback } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { rsvpKey } from '../../constants';
import S from '../S';
import { Collapse } from '../bsaComponents';
import PersonList from './PersonList';
import styles from './PersonListRSVP.less';

const { Panel } = Collapse;

const PersonListRSVP = React.memo(
  ({
    recurringEventId,
    persons,
    onAttendedToggle,
    isAttendedToggleLoading,
    onRemoveAction,
    onRSVPAction,
    expandedKeys,
    onPanelChange,
    innerExpandedKeys,
    handleInnerPanelChange,
    isCreateEvent,
    enableAttendance,
    hasPackOrDen,
    myYouthsInEvent,
  }) => {
    const { attending, notAttending, maybe } = persons;

    const personsCount = useCallback(
      persons => {
        const denChiefCount = hasPackOrDen ? persons.denChiefs.length : 0;

        return (
          persons.youths.length +
          persons.adults.length +
          persons.parents.length +
          persons.guests.length +
          denChiefCount
        );
      },
      [hasPackOrDen],
    );

    const collapseDetails = useCallback(
      (attendingPersons, notAttendingPersons, maybePersons) => (
        <Collapse
          bordered={false}
          activeKey={expandedKeys}
          onChange={keys => onPanelChange(keys)}
        >
          <Panel
            header={
              <S size={'4'}>
                <CheckCircleIcon className={styles.rsvpYesIcon} />
                <FormattedMessage id={rsvpKey.YES} /> (
                {personsCount(attendingPersons)})
              </S>
            }
            key={rsvpKey.YES}
            className={cn(styles.panel, styles.noPadding)}
          >
            <PersonList
              persons={attendingPersons}
              rsvpKey={rsvpKey.YES}
              onAttendedToggle={onAttendedToggle}
              isAttendedToggleLoading={isAttendedToggleLoading}
              onRemoveAction={onRemoveAction}
              onRSVPAction={onRSVPAction}
              innerExpandedKeys={innerExpandedKeys}
              handleInnerPanelChange={handleInnerPanelChange}
              isRSVP={true}
              isCreateEvent={isCreateEvent}
              enableAttendance={enableAttendance}
              hasPackOrDen={hasPackOrDen}
              myYouthsInEvent={myYouthsInEvent}
            />
          </Panel>
          <Panel
            header={
              <S size={'4'}>
                <CancelIcon className={styles.rsvpNoIcon} />
                <FormattedMessage id={rsvpKey.NO} /> (
                {personsCount(notAttendingPersons)})
              </S>
            }
            key={rsvpKey.NO}
            className={cn(styles.panel, styles.noPadding)}
          >
            <PersonList
              persons={notAttendingPersons}
              rsvpKey={rsvpKey.NO}
              onAttendedToggle={onAttendedToggle}
              isAttendedToggleLoading={isAttendedToggleLoading}
              onRemoveAction={onRemoveAction}
              onRSVPAction={onRSVPAction}
              innerExpandedKeys={innerExpandedKeys}
              handleInnerPanelChange={handleInnerPanelChange}
              isRSVP={true}
              recurringEventId={recurringEventId}
              isCreateEvent={isCreateEvent}
              enableAttendance={enableAttendance}
              hasPackOrDen={hasPackOrDen}
              myYouthsInEvent={myYouthsInEvent}
            />
          </Panel>
          <Panel
            header={
              <S size={'4'}>
                <HelpIcon className={styles.rsvpMaybeIcon} />
                <FormattedMessage id={rsvpKey.MAYBE} /> (
                {personsCount(maybePersons)})
              </S>
            }
            key={rsvpKey.MAYBE}
            className={cn(styles.panel, styles.noPadding)}
          >
            <PersonList
              persons={maybePersons}
              rsvpKey={rsvpKey.MAYBE}
              onAttendedToggle={onAttendedToggle}
              isAttendedToggleLoading={isAttendedToggleLoading}
              onRemoveAction={onRemoveAction}
              onRSVPAction={onRSVPAction}
              innerExpandedKeys={innerExpandedKeys}
              handleInnerPanelChange={handleInnerPanelChange}
              isRSVP={true}
              recurringEventId={recurringEventId}
              isCreateEvent={isCreateEvent}
              enableAttendance={enableAttendance}
              hasPackOrDen={hasPackOrDen}
              myYouthsInEvent={myYouthsInEvent}
            />
          </Panel>
        </Collapse>
      ),
      [
        onAttendedToggle,
        onRemoveAction,
        isAttendedToggleLoading,
        onRSVPAction,
        expandedKeys,
        onPanelChange,
        handleInnerPanelChange,
        innerExpandedKeys,
        recurringEventId,
        isCreateEvent,
        enableAttendance,
        personsCount,
        hasPackOrDen,
        myYouthsInEvent,
      ],
    );

    return <div>{collapseDetails(attending, notAttending, maybe)}</div>;
  },
);

PersonListRSVP.propTypes = {
  recurringEventId: PropTypes.number,
  persons: PropTypes.object,
  isAttendedToggleLoading: PropTypes.bool,
  onRemoveAction: PropTypes.func,
  onAttendedToggle: PropTypes.func,
  onRSVPAction: PropTypes.func,
  expandedKeys: PropTypes.array,
  onPanelChange: PropTypes.func,
  innerExpandedKeys: PropTypes.array,
  handleInnerPanelChange: PropTypes.func,
  isCreateEvent: PropTypes.bool,
  enableAttendance: PropTypes.bool,
  hasPackOrDen: PropTypes.bool,
  myYouthsInEvent: PropTypes.shape({}),
};

PersonListRSVP.defaultProps = {
  persons: [],
  isAttendedToggleLoading: false,
  onRemoveAction: () => {},
  onAttendedToggle: () => {},
  onRSVPAction: () => {},
  hasPackOrDen: false,
  myYouthsInEvent: {},
};

PersonListRSVP.displayName = 'PersonListRSVP';

export default PersonListRSVP;
