import moment from 'moment';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { organizationGuidSel, selectedOrganizationSel } from '@context';
import { fetchCalendarEvents$ } from '@modules/calendar/duck';
import { getActivities$ } from '@progress/common';
import {
  DELETE_ACTIVITIY_RESPONSE,
  RECORD_CAMPOUTS_RESPONSE,
  RECORD_HIKE_RESPONSE,
  RECORD_LONG_CRUISE_RESPONSE,
  RECORD_SERVICE_RESPONSE,
} from '@progress/duck/actions';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  GET_ACTIVITIES_REQUEST,
  getActivitiesError,
  getActivitiesEventResponse,
  getActivitiesEventsError,
  getActivitiesRequest,
  getActivitiesResponse,
} from './actions';
import {
  activitiesDateSel,
  getActivitiesSel,
  isActivitiesRouteSel,
} from './selectors';

const getYearActivitiesEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ACTIVITIES_REQUEST),
    switchMap(({ payload: selectedDate }) => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      return getActivities$({
        startDateTime: moment(selectedDate).startOf('month').format('Y-MM-DD'),
        endDateTime: moment(selectedDate)
          .endOf('month')
          .add(1, 'day')
          .format('Y-MM-DD'),
        organizationGuid,
        showAll: true,
        perPage: 1000,
      }).pipe(
        map(getActivitiesResponse),
        catchAndReport(err => of(getActivitiesError(err))),
      );
    }),
  );

const getYearEventsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(GET_ACTIVITIES_REQUEST),
    switchMap(({ payload: selectedDate }) => {
      const state = state$.value;
      const organization = selectedOrganizationSel(state);
      return fetchCalendarEvents$({
        fromDate: moment(selectedDate).startOf('month').format('Y-MM-DD'),
        toDate: moment(selectedDate)
          .endOf('month')
          .add(1, 'day')
          .format('Y-MM-DD'),
        unitId: +organization.unitId,
        withActivities: true,
      }).pipe(
        mergeMap(res => {
          const eventsWithActivities = res
            .filter(event => event.linkedActivities.length)
            .reduce((result, event) => {
              const updatedActivities = event.linkedActivities.map(
                activityId => ({
                  activityId,
                  eventId: event.id,
                  eventName: event.name,
                }),
              );
              return [...result, ...updatedActivities];
            }, []);

          return Observable.of(
            getActivitiesEventResponse(eventsWithActivities),
          );
        }),
        catchAndReport(err => of(getActivitiesEventsError(err))),
      );
    }),
  );

const afterRemoveActivityEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ACTIVITIY_RESPONSE),
    filter(() => {
      const state = state$.value;
      return isActivitiesRouteSel(state);
    }),
    map(({ payload: deletedActivityId }) => {
      const state = state$.value;
      const activities = getActivitiesSel(state);
      return getActivitiesResponse(
        activities.filter(activity => deletedActivityId !== activity.id),
      );
    }),
  );

const reloadYearActivitiesEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(
      RECORD_CAMPOUTS_RESPONSE,
      RECORD_SERVICE_RESPONSE,
      RECORD_LONG_CRUISE_RESPONSE,
      RECORD_HIKE_RESPONSE,
    ),
    filter(() => {
      const state = state$.value;
      return isActivitiesRouteSel(state);
    }),
    map(() => {
      const state = state$.value;
      const activitiesDate = activitiesDateSel(state);
      return getActivitiesRequest(activitiesDate);
    }),
  );

export default combineEpics(
  getYearActivitiesEpic$,
  getYearEventsEpic$,
  reloadYearActivitiesEpic$,
  afterRemoveActivityEpic$,
);
