import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import tinycolor from 'tinycolor2';

import { SCOUTBOOK_LEGACY_URL, getBaseESBUrl } from '@config';
import { denSortOrder as customOrder } from '@modules/events/constants';

import { calendarViews } from './constants';

const getActivitiesForDate = (date, events) =>
  events.filter(({ start, end }) => date.isBetween(start, end, 'day', '[]'));

export const hasActivities = (...args) => {
  const activitiesInDate = getActivitiesForDate(...args);
  return activitiesInDate.length > 0 || null;
};

export const dateToWeekFormat = selectedDate => {
  const startWeekDay = moment(selectedDate).startOf('week').format('MMM DD');
  const endWeekDay = moment(selectedDate).endOf('week').format('DD YYYY');
  return `${startWeekDay} - ${endWeekDay}`;
};

export const isValidDateRange = (value, range, calendarView = '') => {
  const unit = calendarView === calendarViews.MONTH ? 'day' : 'minute';
  const inclusivity = calendarView === calendarViews.MONTH ? '[]' : '[)';

  return (
    !isEmpty(range) &&
    moment(value).isBetween(range.start, range.end, unit, inclusivity)
  );
};

/**
 * Create iCal link for unit or subunit calendar
 *
 * @param {Object} unit
 * @param {number?} unit.unitId
 * @param {number} unit.id
 */
export function getUnitCalendarLink(unit) {
  const baseUrl = getBaseESBUrl();

  if (unit.unitId) {
    return `${baseUrl}/advancements/events/calendar/${unit.unitId}/${unit.id}`;
  } else {
    return `${baseUrl}/advancements/events/calendar/${unit.id}`;
  }
}

export const colorSettings = color => {
  const mainColor = color;
  const isMainLight = tinycolor(mainColor).isLight();
  const lightColor = tinycolor(mainColor).lighten(isMainLight ? 15 : 40);
  const isLightColorDark = lightColor.isDark();

  return {
    mainColor,
    isMainLight,
    lightColor: lightColor.toString(),
    isLightColorDark,
  };
};

export function getScoutbookUnitLink(unitId) {
  return `${SCOUTBOOK_LEGACY_URL}/admin/unit.asp?UnitID=${unitId}`;
}

export function sortFoundSubUnits(foundSubUnits) {
  if (foundSubUnits.some(sub => !!sub.denType)) {
    return _.sortBy(foundSubUnits, den => [
      _.indexOf(customOrder, den.denType),
      den.subUnitName,
    ]);
  }

  return _.sortBy(foundSubUnits, sub => sub.subUnitName.toLowerCase());
}
