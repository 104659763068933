import React from 'react';

import ErrorIcon from '@material-ui/icons/Error';
import cn from 'classnames';
import { FormattedNumber } from 'react-intl';

import { S } from '@shared';
import type { SProps } from '@shared/components/S/S';

import styles from './Amount.less';

type AmountProps = {
  amount: number;
  bold?: boolean;
  className?: string;
  currency?: string;
  errorIndicator?: boolean;
  redNegative?: boolean;
  greenPositive?: boolean;
  size?: SProps['size'];
};

const Amount = ({
  amount,
  bold,
  className,
  currency,
  errorIndicator,
  redNegative,
  greenPositive,
  size,
}: AmountProps) => {
  const isNegative = amount < 0;
  const isPositive = amount > 0;
  const negativeTextStyle = redNegative && isNegative ? 'text-danger' : '';
  const positiveTextStyle = greenPositive && isPositive ? 'text-success' : '';
  const errorIcon =
    isNegative && errorIndicator ? (
      <ErrorIcon
        className={cn(styles.errorIcon, styles[`errorIcon-${size}`])}
      />
    ) : null;

  return (
    <S
      bold={bold}
      className={cn(
        styles.container,
        negativeTextStyle,
        positiveTextStyle,
        className,
      )}
      size={size}
    >
      {errorIcon}${' '}
      <FormattedNumber
        format={currency}
        maximumFractionDigits={2}
        minimumFractionDigits={2}
        value={amount}
      />
      <span className={styles.currency}>{currency}</span>
    </S>
  );
};

export default Amount;
