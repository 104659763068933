import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Img from '../Img';
import BSALogo from '../BSALogo';
import styles from './AdvancementImage.less';

const AdvancementImage = ({ src, className, ...props }) =>
  src ? (
    <Img src={src} className={cn(styles.image, className)} {...props} />
  ) : (
    <BSALogo className={className} {...props} />
  );

AdvancementImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
};

export default AdvancementImage;
