import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import RsvpAttendingIconLarge from '@assets/images/RsvpAttendingIconLarge.svg';
import RsvpAttendingIcon from '@assets/images/RsvpAttendingIcon.svg';
import RsvpNotAttendingIconLarge from '@assets/images/RsvpNotAttendingIconLarge.svg';
import RsvpNotAttendingIcon from '@assets/images/RsvpNotAttendingIcon.svg';
import RsvpMaybeIconLarge from '@assets/images/RsvpMaybeIconLarge.svg';
import RsvpMaybeIcon from '@assets/images/RsvpMaybeIcon.svg';

import { Tooltip, intl } from '@shared';
import cn from 'classnames';

import { attendeesTypes } from '../../../constants';
import styles from './RsvpCircleButtons.less';

const attendeeTypeToLargeIcon = Object.freeze({
  [attendeesTypes.ATTENDING]: RsvpAttendingIconLarge,
  [attendeesTypes.NOT_ATTENDING]: RsvpNotAttendingIconLarge,
  [attendeesTypes.MAYBE]: RsvpMaybeIconLarge,
});

const attendeeTypeToIcon = Object.freeze({
  [attendeesTypes.ATTENDING]: RsvpAttendingIcon,
  [attendeesTypes.NOT_ATTENDING]: RsvpNotAttendingIcon,
  [attendeesTypes.MAYBE]: RsvpMaybeIcon,
});

const attendeesTypeToStyle = Object.freeze({
  [attendeesTypes.ATTENDING]: styles.attendingColor,
  [attendeesTypes.NOT_ATTENDING]: styles.notAttendingColor,
  [attendeesTypes.MAYBE]: styles.maybeColor,
});

const RsvpCircleButtons = ({ onClick, large, disabled, offline }) => {
  const handleClick = useCallback(
    type => event => {
      event.stopPropagation();
      onClick(type);
    },
    [onClick],
  );

  const circleButtons = useMemo(
    () =>
      Object.keys(attendeesTypes)
        .sort()
        .map(key => {
          const type = attendeesTypes[key];
          const Icon = large
            ? attendeeTypeToLargeIcon[type]
            : attendeeTypeToIcon[type];
          return (
            <Tooltip
              key={`rounded-button-${type}`}
              placement={'bottom'}
              title={intl.formatMessage({
                id: `events.rsvpEventButton.${type}`,
              })}
            >
              <Icon
                className={cn(styles.default, attendeesTypeToStyle[type], {
                  [styles.small]: !large,
                })}
                disabled={disabled || offline || type === attendeesTypes.MAYBE}
                onClick={handleClick(type)}
              />
            </Tooltip>
          );
        }),
    [handleClick, disabled, offline, large],
  );

  return circleButtons;
};

RsvpCircleButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  offline: PropTypes.bool,
};

RsvpCircleButtons.defaultProps = {
  offline: false,
};

export default RsvpCircleButtons;
