import React from 'react';

import { AddCircle } from 'material-ui-icons';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, OfflineTooltip } from '@shared';

import { createPurchaseOrderRequest } from '../../duck';

export const CreatePOButton = ({
  disabled,
  className,
  personsAdvancements,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(createPurchaseOrderRequest(personsAdvancements));
  };

  return (
    <OfflineTooltip placement="top">
      <Button
        id="create_purchase_order"
        className={className}
        disabled={disabled}
        color="info"
        ghost
        noBorder
        fitText
        icon={<AddCircle />}
        onClick={handleClick}
        uppercase={false}
      >
        <FormattedMessage id="PurchaseOrder.ToPurchaseTab.createButton" />
      </Button>
    </OfflineTooltip>
  );
};

CreatePOButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  personsAdvancements: PropTypes.array.isRequired,
};

CreatePOButton.defaultProps = {
  uppercase: false,
};
