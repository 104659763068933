import sum from 'lodash/sum';
import moment from 'moment';

import { PersonRelationShipType } from '@appTypes/enums';
import type { RenewalRelationship } from '@appTypes/esb';
import { OrgAdvancementsDashboard } from '@appTypes/esb/organizations/v2/_orgGuid/advancementDashboard';

import { ExpirationStatus } from './enums';

const EXP_MODALS_KEY = 'expirationModals';

type MembershipModalMeta = {
  personGuid: string;
  organizationGuid: string;
  registrationId: number;
};

function getMembershipModalKey(params: MembershipModalMeta) {
  return `${params.personGuid}_${params.organizationGuid}_${params.registrationId}`;
}

export function storeMembershipExpirationDismissFlag(params: {
  personGuid: string;
  organizationGuid: string;
  registrationId: number;
}) {
  try {
    const data = JSON.parse(localStorage.getItem(EXP_MODALS_KEY) || '{}');
    const key = getMembershipModalKey(params);
    const oldData: MembershipModalMeta = data[key] || {};
    data[key] = { ...oldData, dismissed: new Date().toISOString() };
    localStorage.setItem(EXP_MODALS_KEY, JSON.stringify(data));
    // eslint-disable-next-line no-empty
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('could not save state', error);
  }
}

export function getMembershipExpirationDismissFlag(
  params: MembershipModalMeta,
): Record<string, { dismissed: string }> | null {
  try {
    const data = JSON.parse(localStorage.getItem(EXP_MODALS_KEY) || '{}') || {};
    const key = getMembershipModalKey(params);
    return data[key] || null;
  } catch {
    return null;
  }
}

export function getExpirationStatus(expiryDate: string | Date) {
  const expirationDate = moment(expiryDate);

  const plusTwoMonths = moment().add(60, 'days');
  const aMonthAgo = moment().subtract(30, 'days');

  if (expirationDate.isBetween(moment().startOf('day'), plusTwoMonths)) {
    return ExpirationStatus.ABOUT_TO_EXPIRE;
  } else if (expirationDate.isBetween(aMonthAgo, moment())) {
    return ExpirationStatus.EXPIRED;
  } else if (expirationDate.isSameOrBefore(aMonthAgo)) {
    return ExpirationStatus.LONG_EXPIRED;
  } else {
    return ExpirationStatus.UNKNOWN;
  }
}

const parentRelationship = new Set([
  PersonRelationShipType.FatherOf,
  PersonRelationShipType.MotherOf,
]);

export function isParentRelationship(renewal: RenewalRelationship) {
  if (!renewal) return false;

  return !!(
    renewal.relationshipTypeId &&
    parentRelationship.has(renewal.relationshipTypeId)
  );
}

export function computeNotificationsCount(params: {
  stats?: OrgAdvancementsDashboard;
  positionsCount: number;
}) {
  const { stats, positionsCount } = params;
  const toPurchaseCount = sum(Object.values(stats?.notPurchased || {})) || 0;
  const requirementsCount = sum(
    Object.values(stats?.completed?.requirements || {}),
  );
  const toApproveCount = sum([
    stats?.completed?.adventures,
    stats?.completed?.awards,
    stats?.completed?.ranks,
    stats?.completed?.meritBadges,
    positionsCount,
    requirementsCount,
  ]);
  const totalCount = toApproveCount + toPurchaseCount;
  return {
    totalCount,
    toApproveCount,
    toPurchaseCount,
    positionsCount,
  };
}
