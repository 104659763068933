import React, { useEffect, useMemo, useState } from 'react';

import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isMobileSel } from '../../duck';
import { intl } from '../../localization/translate';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Input from '../Input';
import Modal from '../Modal';
import PersonAvatar from '../PersonAvatar';
import S from '../S';
import { Col, Row } from '../bsaComponents';
import styles from './ActivitiesFormModal.less';

export const UsersModal = ({
  visible,
  selectableUsers,
  eventDetails,
  currentSelectedUsers,
  onClickSave,
  onCancel,
}) => {
  const isMobile = useSelector(isMobileSel);
  const [selected, setSelected] = useState(new Set());
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [confirmVisible, setConfirmVisible] = useState(false);

  const onSelectAll = ({ target: { checked } }) => {
    setSelected(prevSelected => {
      const newSelectedPersons = new Set(prevSelected);
      checked
        ? selectableUsers.forEach(user => newSelectedPersons.add(user.userId))
        : newSelectedPersons.clear();

      return newSelectedPersons;
    });
  };

  const onClickFilter = () => {
    setFilteredUsers(sortBy(selectableUsers, 'firstName'));
  };

  const onChangeSearch = ({ target: { value } }) => setSearch(value);

  useEffect(() => {
    const filteredBySearch = selectableUsers.filter(user =>
      user.personFullName.toLowerCase().includes(search.toLowerCase()),
    );
    setFilteredUsers(filteredBySearch);
  }, [selectableUsers, search]);

  useEffect(() => {
    setSelected(currentSelectedUsers);
  }, [currentSelectedUsers]);

  const onSelect =
    userId =>
    ({ target: { checked } }) => {
      setSelected(prevSelected => {
        const newSelectedPersons = new Set(prevSelected);
        checked
          ? newSelectedPersons.add(userId)
          : newSelectedPersons.delete(userId);

        return newSelectedPersons;
      });
    };

  const handleCancelConfirm = () => {
    setConfirmVisible(false);
  };

  const usersToAddAsAtendees = useMemo(() => {
    if (eventDetails && eventDetails.users) {
      const toBeAdded = [];
      selected.forEach(selectedId => {
        const foundInEvent = eventDetails.users.find(
          ({ userId }) => userId === selectedId,
        );
        const foundInSelectable = selectableUsers.find(
          ({ userId }) => userId === selectedId,
        );

        if (!foundInEvent && foundInSelectable) {
          toBeAdded.push(foundInSelectable.personFullName);
        }
      });
      return toBeAdded.toString().replaceAll(',', ', ');
    }

    return '';
  }, [eventDetails, selected, selectableUsers]);

  const handleClickSave = () => {
    if (usersToAddAsAtendees.length) {
      setConfirmVisible(true);
      return;
    }
    onClickSave(selected);
  };

  const handleConfirm = () => {
    setConfirmVisible(false);
    onClickSave(selected);
  };

  return (
    <Modal
      mask={!isMobile}
      headerColor="scouting-warm-gray"
      visible={visible}
      title={<FormattedMessage id="activitiesModal.addAttendees" />}
      onCancel={onCancel}
      className={cn(styles.roundedModal, styles.usersModal)}
    >
      <Row className={styles.inst}>
        <Col xs={24}>
          <FormattedMessage id="activitiesModal.addAttendees.instructions" />
        </Col>
      </Row>
      <Row type="flex" className={styles.controls} gutter={{ xs: 0, lg: 36 }}>
        <Col xs={24} lg={8}>
          <Button
            uppercase={false}
            ghost
            noBorder
            fitText
            color="info"
            size="small"
            icon={<FilterListIcon />}
            onClick={onClickFilter}
          >
            <FormattedMessage id="activitiesModal.addAttendees.sort" />
          </Button>
        </Col>
        <Col xs={24} lg={16}>
          <Input
            size="large"
            placeholder={intl.formatMessage({
              id: 'activitiesModal.addAttendees.search',
            })}
            onChange={onChangeSearch}
            rounded
            floatingLabel
            suffix={<SearchIcon />}
          />
        </Col>
      </Row>
      <Row className={styles.counter}>
        <Col xs={24}>
          <FormattedMessage
            id="activitiesModal.addAttendees.counter"
            values={{ num: filteredUsers.length }}
          />
        </Col>
      </Row>
      <div className={styles.selectAll}>
        <Checkbox onChange={onSelectAll}>
          <FormattedMessage id="shared.selectAll" />
        </Checkbox>
      </div>
      <div className={styles.selectablesContainer}>
        {filteredUsers.map(user => (
          <div key={user.userId} className={styles.selectableContainer}>
            <Checkbox
              checked={selected.has(user.userId)}
              onChange={onSelect(user.userId)}
            />
            <PersonAvatar
              bordered={false}
              isAdult={user.isAdult}
              className={styles.selectableAvatar}
              pictureUrl={user.pictureUrl}
            />
            <span>{user.personFullName}</span>
          </div>
        ))}
      </div>
      <Row type="flex" justify="center" className={styles.saveUsers}>
        <Col xs={12} lg={8}>
          <Button
            uppercase={false}
            fitText
            type="primary"
            shape="round"
            size="large"
            onClick={handleClickSave}
          >
            <FormattedMessage id="shared.saveChanges" />
          </Button>
        </Col>
      </Row>
      {eventDetails && (
        <Modal
          mask={!isMobile}
          headerColor="scouting-warm-gray"
          visible={confirmVisible}
          title={
            <FormattedMessage id="activitiesModal.addAttendees.confirmTitle" />
          }
          onCancel={handleCancelConfirm}
          className={cn(styles.roundedModal, styles.usersModal)}
        >
          <S size="4">
            <b>{usersToAddAsAtendees}</b>
            <FormattedHTMLMessage
              id="activitiesModal.addAttendees.confirmContent"
              values={{
                eventName: eventDetails.name,
              }}
            />
          </S>
          <Row type="flex" justify="space-around">
            <Col xs={8}>
              <Button
                uppercase={false}
                block
                shape="round"
                size="large"
                onClick={handleCancelConfirm}
              >
                <FormattedMessage id="shared.cancel" />
              </Button>
            </Col>
            <Col xs={8}>
              <Button
                uppercase={false}
                block
                type="primary"
                shape="round"
                size="large"
                onClick={handleConfirm}
              >
                <FormattedMessage id="shared.yes" />
              </Button>
            </Col>
          </Row>
        </Modal>
      )}
    </Modal>
  );
};

UsersModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectableUsers: PropTypes.array.isRequired,
  currentSelectedUsers: PropTypes.instanceOf(Set).isRequired,
  eventDetails: PropTypes.object,
  onClickSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
