import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

const propTypes = {
  section: PropTypes.node,
  className: PropTypes.object,
};

const defaultProps = {
  section: null,
  className: {},
};

function SectionRender({ section, className }) {
  if (isNil(section)) return null;
  return <div className={className}>{section}</div>;
}

SectionRender.propTypes = propTypes;
SectionRender.defaultProps = defaultProps;

export default SectionRender;
