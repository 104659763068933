import { sorters } from '@utils';

const nameSorter = sorters.stringMulti('lastName', 'firstName');
const descriptionSorter = sorters.string('description');
const updatedDateSorter = sorters.date('date');
const statusSorter = sorters.string('statusLabel');
const defaultSorter = sorters.compose(
  sorters.date('date', true),
  nameSorter,
  descriptionSorter,
  statusSorter,
);

export default {
  name: nameSorter,
  updatedDate: updatedDateSorter,
  recordedBy: sorters.stringMulti('recordedByLastName', 'recordedByFirstName'),
  description: descriptionSorter,
  status: statusSorter,
  default: defaultSorter,
};
