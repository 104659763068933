import {
  screenXs,
  screenSm,
  screenMd,
  screenLg,
  screenXl,
  screenXxl,
  xsWidth,
  smWidth,
  mdWidth,
  lgWidth,
  xlWidth,
} from '../constants';

const getScreenMeta = () => {
  const width = window.innerWidth;

  if (width <= xsWidth) {
    return {
      mode: screenXs,
      min: 0,
      max: xsWidth,
    };
  }
  if (width <= smWidth) {
    return {
      mode: screenSm,
      min: xsWidth + 1,
      max: smWidth,
    };
  }
  if (width <= mdWidth) {
    return {
      mode: screenMd,
      min: smWidth + 1,
      max: mdWidth,
    };
  }
  if (width <= lgWidth) {
    return {
      mode: screenLg,
      min: mdWidth + 1,
      max: lgWidth,
    };
  }
  if (width <= xlWidth) {
    return {
      mode: screenXl,
      min: lgWidth + 1,
      max: xlWidth,
    };
  }
  return {
    mode: screenXxl,
    min: xlWidth + 1,
    max: 999999,
  };
};

export { getScreenMeta };
