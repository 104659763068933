import React from 'react';
import PropTypes from 'prop-types';

import { ReduxLoader } from '@shared';
import {
  dictionariesRequest,
  dictionariesLoadedSel,
  dictionariesLoadingSel,
} from '../duck';
import { dictionaryIds } from '../constants';

const DictLoader = ({ ids, ...rest }) => {
  const arrayIds = Array.isArray(ids) ? ids : [ids];
  const loadAction = dictionariesRequest(...arrayIds);
  const loadedSel = state => dictionariesLoadedSel(state, arrayIds);
  const loadingSel = state => dictionariesLoadingSel(state, arrayIds);
  const duckling = {
    loadAction,
    loadedSel,
    loadingSel,
  };

  return <ReduxLoader ducklings={duckling} {...rest} />;
};

const dictionaryIdPropTypes = PropTypes.oneOf(Object.values(dictionaryIds));

DictLoader.propTypes = {
  ids: PropTypes.oneOfType([
    dictionaryIdPropTypes,
    PropTypes.arrayOf(dictionaryIdPropTypes),
  ]).isRequired,
};

export default DictLoader;
