import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';

import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { Modal, organizationGuidSel } from '@shared';

import {
  advancementReportModalOpenSel,
  advancementReportRequest,
  cancelAdvancementReportModal,
  editedReportSel,
  goToReports,
  loadingSel,
  reportSel,
  selectedUserIdsSel,
} from '../../duck';
import AdvancementReportForm from './AdvancementReportForm';
import FallbackMessage from './FallbackMessage';

function AdvancementReportModal(props) {
  const {
    visible,
    onCancel,
    items,
    loading,
    canEditMultipleItems,
    selectedUserIds,
    onGoToReports,
  } = props;

  const areItemsAvailable = loading || items.length > 0;

  return (
    <Modal
      headerColor="scouting-warm-gray"
      visible={visible}
      title={<FormattedMessage id="packRoster.AdvancementReportModal.title" />}
      onCancel={onCancel}
      backTitle={
        <FormattedMessage id="packRoster.AdvancementReportModal.back" />
      }
      size="large"
      noPadding
    >
      <React.Fragment>
        {areItemsAvailable ? (
          <AdvancementReportForm
            items={items}
            loading={loading}
            canEditMultipleItems={canEditMultipleItems}
          />
        ) : (
          <FallbackMessage
            youthCount={selectedUserIds.length}
            onGoToReports={onGoToReports}
          />
        )}
      </React.Fragment>
    </Modal>
  );
}

const AdvancementReportModalContainer = props => {
  const dispatch = useDispatch();
  const orgGuid = useSelector(organizationGuidSel);

  useEffect(() => {
    const enable = featureFlags.getFlag('SBL_5150_DASHBOARD_API');

    if (!enable || !orgGuid) return;

    dispatch(advancementReportRequest(orgGuid));
  }, [dispatch, orgGuid]);

  return <AdvancementReportModal {...props} />;
};

AdvancementReportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  canEditMultipleItems: PropTypes.bool.isRequired,
  selectedUserIds: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGoToReports: PropTypes.func.isRequired,
};

const mapState = state => ({
  visible: advancementReportModalOpenSel(state),
  loading: loadingSel(state),
  items: editedReportSel(state),
  canEditMultipleItems: reportSel(state).length > 1,
  selectedUserIds: selectedUserIdsSel(state),
});

const mapDispatch = dispatch => ({
  onCancel: () => dispatch(cancelAdvancementReportModal()),
  onGoToReports: () => dispatch(goToReports()),
});

export default connect(mapState, mapDispatch)(AdvancementReportModalContainer);
