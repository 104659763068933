import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SortSelect } from '@shared';

const { Option } = SortSelect;

class SortSection extends React.PureComponent {
  render() {
    const { sorter, onChange } = this.props;

    return (
      <SortSelect sorter={sorter} onChange={onChange}>
        <Option name="name" type="string">
          <FormattedMessage id="advancement.PackRoster.column.name" />
        </Option>
        <Option name="age" type="number">
          <FormattedMessage id="advancement.PackRoster.column.age" />
        </Option>
        <Option name="currentHighestRankApproved" type="number">
          <FormattedMessage id="advancement.PackRoster.column.highestRankApproved" />
        </Option>
        <Option name="subUnitName" type="string">
          <FormattedMessage id="advancement.PackRoster.column.den" />
        </Option>
      </SortSelect>
    );
  }
}

SortSection.propTypes = {
  sorter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SortSection;
