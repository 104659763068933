import stable from 'stable';

import isHierarchicalAdvancement from './isHierarchicalAdvancement';

/**
 * returns the hierarchical advancement with the highest level in the list but lower than 'item's, empty object if nothing is found
 * @param {Object} obj
 * @param {Object} item
 * @param {Array} itemsHistory
 */
export default ({ item, itemsHistory }) => {
  if (!isHierarchicalAdvancement(item)) {
    return {};
  }
  const { programId, userId, level, advancementType } = item;
  const advancements = itemsHistory.filter(
    tmpItem =>
      userId == tmpItem.userId &&
      advancementType == tmpItem.advancementType &&
      programId == tmpItem.programId,
  );
  const lowerLevelAdvancements = advancements.filter(
    ({ level: tmpLevel }) => Number(tmpLevel) < Number(level),
  );

  return (
    stable(
      lowerLevelAdvancements,
      ({ level: aLevel }, { level: bLevel }) => Number(aLevel) > Number(bLevel),
    ).pop() || {}
  );
};
