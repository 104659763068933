import { gtm } from '@shared/utils';
import { basicActionCreator } from '@utils';

import {
  advancementModuleNamespace,
  approveAdvancementsModuleNamespace,
  commonModuleNamespace,
  progressModuleName,
} from '../constants';

export const DESELECT_PERSON = `${progressModuleName}/DESELECT_PERSON`;
export const deselectPerson = userId =>
  basicActionCreator(DESELECT_PERSON, userId);

export const SELECT_PERSON = `${progressModuleName}/SELECT_PERSON`;
export const selectPerson = userId => basicActionCreator(SELECT_PERSON, userId);

export const DESELECT_MULTIPLE_PERSONS = `${progressModuleName}/DESELECT_MULTIPLE_PERSONS`;
export const deselectMultiplePerson = userIds =>
  basicActionCreator(DESELECT_MULTIPLE_PERSONS, userIds);

export const SELECT_MULTIPLE_PERSONS = `${progressModuleName}/SELECT_MULTIPLE_PERSONS`;
export const selectMultiplePerson = userIds =>
  basicActionCreator(SELECT_MULTIPLE_PERSONS, userIds);

export const CLEAR_PEOPLE = `${progressModuleName}/CLEAR_PEOPLE`;
export const clearPeople = () => ({
  type: CLEAR_PEOPLE,
});

// advancenment
export const RECORD_ADVANCEMENT_REQUEST = `${advancementModuleNamespace}/RECORD_ADVANCEMENT_REQUEST`;
export const recordAdvancementRequest = ({
  users,
  advancementId,
  advancementType,
  date,
  successMsg,
  closeOnSuccess,
  status,
}) => ({
  type: RECORD_ADVANCEMENT_REQUEST,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
  payload: {
    users,
    advancementId,
    advancementType,
    date,
    successMsg,
    closeOnSuccess,
    status,
  },
});

export const GET_ACTIVITIES_RESPONSE = `${commonModuleNamespace}/GET_ACTIVITIES_RESPONSE`;
export const getActivitiesResponse = (activities = []) =>
  basicActionCreator(GET_ACTIVITIES_RESPONSE, { activities });

export const GET_ACTIVITIES_ERROR = `${commonModuleNamespace}/GET_ACTIVITIES_ERROR`;
export const getActivitiesError = err =>
  basicActionCreator(GET_ACTIVITIES_ERROR, err);

export const GET_ACTIVITIES_REQUEST = `${commonModuleNamespace}/GET_ACTIVITIES_REQUEST`;
export const getActivitiesRequest = payload =>
  basicActionCreator(GET_ACTIVITIES_REQUEST, payload);

export const DELETE_ACTIVITIY_RESPONSE = `${commonModuleNamespace}/DELETE_ACTIVITIY_RESPONSE`;
export const deleteActivityResponse = deletedActivityId =>
  basicActionCreator(DELETE_ACTIVITIY_RESPONSE, deletedActivityId);

export const DELETE_ACTIVITIY_ERROR = `${commonModuleNamespace}/DELETE_ACTIVITIY_ERROR`;
export const deleteActivityError = err =>
  basicActionCreator(DELETE_ACTIVITIY_ERROR, err);

export const DELETE_ACTIVITIY_REQUEST = `${commonModuleNamespace}/DELETE_ACTIVITIY_REQUEST`;
export const deleteActivityRequest = ({
  activityId,
  activityValueIds,
  callbackSuccess,
  callbackError,
}) =>
  basicActionCreator(DELETE_ACTIVITIY_REQUEST, {
    activityId,
    activityValueIds,
    callbackSuccess,
    callbackError,
  });

export const CATEGORIES_AND_ORGANIZATIONS_RESPONSE = `${commonModuleNamespace}/CATEGORIES_AND_ORGANIZATIONS_RESPONSE`;
export const categoriesAndOrganizationsResponse = ({
  categories,
  organizations,
}) =>
  basicActionCreator(CATEGORIES_AND_ORGANIZATIONS_RESPONSE, {
    categories,
    organizations,
  });

export const CATEGORIES_AND_ORGANIZATIONS_ERROR = `${commonModuleNamespace}/CATEGORIES_AND_ORGANIZATIONS_ERROR`;
export const categoriesAndOrganizationsError = err =>
  basicActionCreator(CATEGORIES_AND_ORGANIZATIONS_ERROR, err);

export const CATEGORIES_AND_ORGANIZATIONS_REQUEST = `${commonModuleNamespace}/CATEGORIES_AND_ORGANIZATIONS_REQUEST`;
export const categoriesAndOrganizationsRequest = () =>
  basicActionCreator(CATEGORIES_AND_ORGANIZATIONS_REQUEST);

export const SET_ACTION_BUTTON = `${commonModuleNamespace}/SET_ACTION_BUTTON`;
export const setActionButton = action =>
  basicActionCreator(SET_ACTION_BUTTON, action);

// approveAdvancement
export const APPROVE_ADVANCEMENTS_RESPONSE = `${approveAdvancementsModuleNamespace}/APPROVE_ADVANCEMENTS_RESPONSE`;
export const CLOSE_APPROVE_ADVANCEMENTS = `${approveAdvancementsModuleNamespace}/CLOSE_APPROVE_ADVANCEMENTS`;
export const closeApproveAdvancements = () => ({
  type: CLOSE_APPROVE_ADVANCEMENTS,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const SET_SELECTED_ACTIVITY = `${commonModuleNamespace}/SET_SELECTED_ACTIVITY`;
export const setSelectedActivity = payload =>
  basicActionCreator(SET_SELECTED_ACTIVITY, payload);

export const SET_PERSONS_TO_REMOVE = `${commonModuleNamespace}/SET_PERSONS_TO_REMOVE`;
export const setPersonsToRemove = payload =>
  basicActionCreator(SET_PERSONS_TO_REMOVE, payload);

export const SET_MULTIPLE_PERSONS_TO_REMOVE = `${progressModuleName}/SET_MULTIPLE_PERSONS_TO_REMOVE`;
export const setMultiplePersonsToRemove = userIds =>
  basicActionCreator(SET_MULTIPLE_PERSONS_TO_REMOVE, userIds);

export const DEL_PERSONS_TO_REMOVE = `${commonModuleNamespace}/DEL_PERSONS_TO_REMOVE`;
export const delPersonsToRemove = payload =>
  basicActionCreator(DEL_PERSONS_TO_REMOVE, payload);

export const GET_LOCAL_LOCATIONS_REQUEST = `${commonModuleNamespace}/GET_LOCAL_LOCATIONS_REQUEST`;
export const getLocalLocations = payload =>
  basicActionCreator(GET_LOCAL_LOCATIONS_REQUEST, payload);

export const GET_LOCAL_LOCATIONS_RESPONSE = `${commonModuleNamespace}/GET_LOCAL_LOCATIONS_RESPONSE`;
export const getLocalLocationsResponse = payload =>
  basicActionCreator(GET_LOCAL_LOCATIONS_RESPONSE, payload);

export const GET_LOCAL_LOCATIONS_ERROR = `${commonModuleNamespace}/GET_LOCAL_LOCATIONS_ERROR`;
export const getLocalLocationsError = payload =>
  basicActionCreator(GET_LOCAL_LOCATIONS_ERROR, payload);

export const SEARCH_LOCATIONS_REQUEST = `${commonModuleNamespace}/SEARCH_LOCATIONS_REQUEST`;
export const searchLocations = payload =>
  basicActionCreator(SEARCH_LOCATIONS_REQUEST, payload);

export const SEARCH_LOCATIONS_RESPONSE = `${commonModuleNamespace}/SEARCH_LOCATIONS_RESPONSE`;
export const searchLocationsResponse = payload =>
  basicActionCreator(SEARCH_LOCATIONS_RESPONSE, payload);

export const SEARCH_LOCATIONS_ERROR = `${commonModuleNamespace}/SEARCH_LOCATIONS_ERROR`;
export const searchLocationsError = payload =>
  basicActionCreator(SEARCH_LOCATIONS_ERROR, payload);

export const OPEN_DELETE_ADVANCEMENT_MODAL = `${commonModuleNamespace}/OPEN_DELETE_ADVANCEMENT_MODAL`;
export const openDeleteAdvancement = payload =>
  basicActionCreator(OPEN_DELETE_ADVANCEMENT_MODAL, payload);

export const CLOSE_DELETE_ADVANCEMENT_MODAL = `${commonModuleNamespace}/CLOSE_DELETE_ADVANCEMENT_MODAL`;
export const closeDeleteAdvancement = payload =>
  basicActionCreator(CLOSE_DELETE_ADVANCEMENT_MODAL, payload);

export const DELETE_ADVANCEMENT_REQUEST = `${commonModuleNamespace}/DELETE_ADVANCEMENT_REQUEST`;
export const deleteAdvancementRequest = payload =>
  basicActionCreator(DELETE_ADVANCEMENT_REQUEST, payload);

export const DELETE_ADVANCEMENT_RESPONSE = `${commonModuleNamespace}/CLOSE_DELETE_ADVANCEMENT_MODAL`;
export const deleteAdvancementResponse = payload =>
  basicActionCreator(DELETE_ADVANCEMENT_RESPONSE, payload);

// this is a object to compare if the form has change on edit actvitity view
export const SET_INITIAL_FORM_STATE = `${commonModuleNamespace}/SET_INITIAL_FORM_STATE`;
export const setInitialFormState = payload =>
  basicActionCreator(SET_INITIAL_FORM_STATE, payload);

export const LOAD_AND_OPEN_ACTIVITY = `${commonModuleNamespace}/LOAD_AND_OPEN_ACTIVITY`;
export const loadAndOpenActivity = payload =>
  basicActionCreator(LOAD_AND_OPEN_ACTIVITY, payload);
