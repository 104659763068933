import { moduleName } from '../constants';

export {
  packRosterRequest as rosterRequest,
  packRosterRequestIfNotLoaded as rosterRequestIfNotLoaded,
} from '../packRoster';
export {
  UNIT_ROSTER_REPORT_LOADING,
  OPEN_UNIT_REPORT_MODAL,
  CLOSE_UNIT_REPORT_MODAL,
  closeUnitReportModal,
} from '../common';
export { setExpandedMainRow } from '../advancementHistory';

export const VERSIONS_BY_RANK_REQUEST = `${moduleName}/VERSIONS_BY_RANK_REQUEST`;
export const VERSIONS_BY_RANK_RESPONSE = `${moduleName}/VERSIONS_BY_RANK_RESPONSE`;
export const VERSIONS_BY_RANK_ERROR = `${moduleName}/VERSIONS_BY_RANK_ERROR`;
export const versionsByRankResponse = payload => ({
  type: VERSIONS_BY_RANK_RESPONSE,
  payload,
});
export const versionsByRankError = error => ({
  type: VERSIONS_BY_RANK_ERROR,
  payload: error,
});

export const SELECT_QE_RANK = `${moduleName}/SELECT_QE_RANK`;
export const selectQERank = payload => ({
  type: SELECT_QE_RANK,
  payload,
});
export const SELECT_QE_VERSION = `${moduleName}/SELECT_QE_VERSION`;
export const selectQEVersion = payload => ({
  type: SELECT_QE_VERSION,
  payload,
});

export const REQUIREMENTS_BY_RANK_AND_VERSION_REQUEST = `${moduleName}/REQUIREMENTS_BY_RANK_AND_VERSION_REQUEST`;
export const REQUIREMENTS_BY_RANK_AND_VERSION_RESPONSE = `${moduleName}/REQUIREMENTS_BY_RANK_AND_VERSION_RESPONSE`;
export const REQUIREMENTS_BY_RANK_AND_VERSION_ERROR = `${moduleName}/REQUIREMENTS_BY_RANK_AND_VERSION_ERROR`;

export const requirementsByRankAndVersionRequest = payload => ({
  type: REQUIREMENTS_BY_RANK_AND_VERSION_REQUEST,
  payload,
});

export const requirementsByRankAndVersionResponse = payload => ({
  type: REQUIREMENTS_BY_RANK_AND_VERSION_RESPONSE,
  payload,
});

export const requirementsByRankAndVersionError = error => ({
  type: REQUIREMENTS_BY_RANK_AND_VERSION_ERROR,
  payload: error,
});

export const BULK_REQUIREMENT_ENTRY_REQUEST = `${moduleName}/BULK_REQUIREMENT_ENTRY_REQUEST`;
export const BULK_REQUIREMENT_ENTRY_RESPONSE = `${moduleName}/BULK_REQUIREMENT_ENTRY_RESPONSE`;
export const BULK_REQUIREMENT_ENTRY_ERROR = `${moduleName}/BULK_REQUIREMENT_ENTRY_ERROR`;

export const bulkReqEntryRequest = payload => ({
  type: BULK_REQUIREMENT_ENTRY_REQUEST,
  payload,
});

export const bulkReqEntryResponse = payload => ({
  type: BULK_REQUIREMENT_ENTRY_RESPONSE,
  payload,
});

export const bulkReqEntryError = error => ({
  type: BULK_REQUIREMENT_ENTRY_ERROR,
  payload: error,
});
