/* eslint-disable @typescript-eslint/ban-ts-comment */
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { intl } from '@shared';
import { toastService } from '@toasts';
import { catchAndReport } from '@utils/rxjs/operators';

import type {
  GetEventRes,
  GetEventsRes,
  GetPersonProfileResponse,
  GetUserPaymentLogsRes,
} from '../../../types/esb';
import { Unit } from '../types';
import { transformPersonProfileToUnits } from '../utils';
import {
  GET_EVENT_REQUEST,
  GET_PERSON_UNITS_REQUEST,
  GET_UNIT_EVENTS_REQUEST,
  POST_USER_PAYMENT_LOGS_REQUEST,
  PUT_USER_PAYMENT_LOGS_REQUEST,
  USER_PAYMENT_LOGS_REQUEST,
  getEventError,
  getEventResponse,
  getPersonUnitsError,
  getPersonUnitsResponse,
  getUnitEventsError,
  getUnitEventsResponse,
  getUserPaymentLogsError,
  getUserPaymentLogsRequest,
  getUserPaymentLogsResponse,
  postUserPaymentLogsError,
  postUserPaymentLogsResponse,
  putUserPaymentLogsError,
  putUserPaymentLogsResponse,
} from './actions';
import { currentUserIdSel } from './selectors';
import services from './services';

// @ts-expect-error temporary solution
const getEvent$ = action$ =>
  action$.pipe(
    ofType(GET_EVENT_REQUEST),
    // @ts-ignore
    switchMap(({ eventId }) =>
      services.getEvent$(eventId).pipe(
        map((response: GetEventRes) => getEventResponse(response)),
        catchAndReport((error: Error) => of(getEventError(error))),
      ),
    ),
  );

// @ts-expect-error temporary solution
const getPersonPositions$ = action$ =>
  action$.pipe(
    ofType(GET_PERSON_UNITS_REQUEST),
    // @ts-ignore
    switchMap(({ userId }) =>
      services.getPersonProfile$(userId).pipe(
        map((response: GetPersonProfileResponse) =>
          transformPersonProfileToUnits(response),
        ),
        map((response: Unit[]) => getPersonUnitsResponse(response)),
        catchAndReport((error: Error) => of(getPersonUnitsError(error))),
      ),
    ),
  );

// @ts-expect-error temporary solution
const getUnitEvents$ = action$ =>
  action$.pipe(
    ofType(GET_UNIT_EVENTS_REQUEST),
    // @ts-ignore
    switchMap(({ unitId }) =>
      services.fetchUnitEvents$(unitId).pipe(
        map((response: GetEventsRes) => getUnitEventsResponse(response)),
        catchAndReport((error: Error) => of(getUnitEventsError(error))),
      ),
    ),
  );

// @ts-expect-error temporary solution
const getUserPaymentLogs$ = (action$, state$) =>
  action$.pipe(
    ofType(USER_PAYMENT_LOGS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const userId = currentUserIdSel(state);

      return services.getUserPaymentLogs$(userId).pipe(
        map((response: GetUserPaymentLogsRes) =>
          getUserPaymentLogsResponse(response),
        ),
        catchAndReport((error: Error) => of(getUserPaymentLogsError(error))),
      );
    }),
  );

// @ts-expect-error temporary solution
const postUserPaymentLog$ = action$ =>
  action$.pipe(
    ofType(POST_USER_PAYMENT_LOGS_REQUEST),
    // @ts-ignore
    switchMap(({ userId, requestBody }) =>
      services.postUserPaymentLogs$(userId, requestBody).pipe(
        mergeMap(() =>
          Observable.concat(
            of(postUserPaymentLogsResponse()),
            of(getUserPaymentLogsRequest()),
            of(() =>
              toastService.success(
                intl.formatMessage({ id: 'paymentLogs.addTransactionSuccess' }),
              ),
            ),
          ),
        ),
        catchAndReport((error: Error) => {
          toastService.success(
            intl.formatMessage({ id: 'paymentLogs.addTransactionError' }),
          );

          return of(postUserPaymentLogsError(error));
        }),
      ),
    ),
  );

// @ts-expect-error temporary solution
const putUserPaymentLog$ = action$ =>
  action$.pipe(
    ofType(PUT_USER_PAYMENT_LOGS_REQUEST),
    // @ts-ignore
    switchMap(({ userId, logId, requestBody }) =>
      services.putUserPaymentLogs$(userId, logId, requestBody).pipe(
        mergeMap(() =>
          Observable.concat(
            of(putUserPaymentLogsResponse()),
            of(getUserPaymentLogsRequest()),
            of(() =>
              toastService.success(
                intl.formatMessage({
                  id: 'paymentLogs.updateTransactionSuccess',
                }),
              ),
            ),
          ),
        ),
        catchAndReport((error: Error) => {
          toastService.success(
            intl.formatMessage({ id: 'paymentLogs.updateTransactionError' }),
          );

          return of(putUserPaymentLogsError(error));
        }),
      ),
    ),
  );

export default combineEpics(
  getEvent$,
  getPersonPositions$,
  getUnitEvents$,
  getUserPaymentLogs$,
  postUserPaymentLog$,
  putUserPaymentLog$,
);
