import React from 'react';
import PropTypes from 'prop-types';

import AdvancedTabTitles from '../../AdvancedTabTitles';

const AdvancedTitles = ({ type, titles }) => (
  <AdvancedTabTitles type={type} titles={titles} />
);

AdvancedTitles.propTypes = {
  type: PropTypes.string.isRequired,
  titles: PropTypes.array.isRequired,
};

export default AdvancedTitles;
