import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import cn from 'classnames';

import { Layout } from '@shared';
import { isScreenAtLeastXlSel, isMobileSel } from '@screen';
import {
  sidebarOpenSel,
  sidebarExpandedSel,
  setSidebarExpanded,
} from '../../duck';
import Nav from '../Nav';
import SidebarTrigger from './SidebarTrigger';
import styles from './Sidebar.less';

const { Sider } = Layout;

class Sidebar extends React.PureComponent {
  componentDidUpdate({ open: prevOpen, isMobile: prevIsMobile }) {
    const { open, isMobile } = this.props;

    if (prevOpen !== open || prevIsMobile !== isMobile) {
      setTimeout(() => {
        const menuListNode = document.querySelector(
          `.${styles.sidebar} ul.ant-menu`,
        );

        if (isMobile) {
          this.mobileScrollLock(open, menuListNode);
        } else if (prevIsMobile && menuListNode) {
          // Make sure scroll works on desktop
          enableBodyScroll(menuListNode);
        }
      });
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  mobileScrollLock(open, node) {
    if (!node) {
      return;
    }
    if (open) {
      disableBodyScroll(node);
    } else {
      enableBodyScroll(node);
    }
  }

  render() {
    const {
      open,
      expanded,
      isMobile,
      isScreenAtLeastXl,
      onSetSidebarExpanded,
    } = this.props;
    const isCollapsed = !isMobile && !expanded;

    return (
      <React.Fragment>
        <Sider
          className={cn(styles.sidebar, { [styles.open]: open })}
          collapsible={isScreenAtLeastXl}
          collapsed={isCollapsed}
          collapsedWidth="70"
          width="200"
          onCollapse={onSetSidebarExpanded}
          trigger={<SidebarTrigger collapsed={isCollapsed} />}
        >
          <div className={styles.epaulet} />
          <Nav isExpanded={expanded} isMobile={isMobile} />
        </Sider>
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isScreenAtLeastXl: PropTypes.bool.isRequired,
  onSetSidebarExpanded: PropTypes.func.isRequired,
};

const mapState = state => ({
  open: sidebarOpenSel(state),
  expanded: sidebarExpandedSel(state),
  isMobile: isMobileSel(state),
  isScreenAtLeastXl: isScreenAtLeastXlSel(state),
});

const mapDispatch = dispatch => ({
  onSetSidebarExpanded: collapsed => dispatch(setSidebarExpanded(!collapsed)),
});

export default connect(mapState, mapDispatch)(Sidebar);
