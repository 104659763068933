import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { OrgSubUnit } from '@appTypes/esb';

import {
  deleteSubUnitError,
  deleteSubUnitRequest,
  deleteSubUnitResponse,
  editSubUnitFailure,
  editSubUnitRequest,
  editSubUnitSuccess,
  getSubUnitsError,
  getSubUnitsResponse,
  hideAddToSubUnitModal,
  hideSetSubUnitModal,
  saveSubUnitFailure,
  saveSubUnitRequest,
  saveSubUnitSuccess,
  setSelectedSubUnitId,
  showAddToSubUnitModal,
  showSetSubUnitModal,
} from './actions';

const isAddSubUnitModalVisible = createReducer(false, builder => {
  builder
    .addCase(showSetSubUnitModal, () => true)
    .addCase(hideSetSubUnitModal, () => false);
});

const isAddToSubUnitModalVisible = createReducer(false, builder => {
  builder
    .addCase(showAddToSubUnitModal, () => true)
    .addCase(hideAddToSubUnitModal, () => false);
});

const selectedSubUnitId = createReducer<number | null>(null, builder => {
  builder.addCase(setSelectedSubUnitId, (_, { payload }) => payload);
});

const createSubUnitInProgress = createReducer(false, builder => {
  builder
    .addCase(saveSubUnitRequest, () => true)
    .addCase(editSubUnitRequest, () => true)
    .addCase(saveSubUnitSuccess, () => false)
    .addCase(editSubUnitSuccess, () => false)
    .addCase(saveSubUnitFailure, () => false)
    .addCase(editSubUnitFailure, () => false);
});

const subUnits = createReducer<{ [key: string]: OrgSubUnit[] }>({}, builder => {
  builder
    .addCase(getSubUnitsResponse, (state, { payload }) => {
      const orgGuid = payload.orgGuid;
      const subUnits = payload.subUnits;
      return { ...state, [orgGuid]: subUnits };
    })
    .addCase(saveSubUnitSuccess, (state, { payload }) => {
      const orgGuid = payload.orgGuid;
      const subUnit = payload.subUnit;
      const currentSubUnits = state[orgGuid] ? state[orgGuid] : [];
      return { ...state, [orgGuid]: [...currentSubUnits, subUnit] };
    })
    .addCase(editSubUnitSuccess, (state, { payload }) => {
      const orgGuid = payload.orgGuid;
      const subUnit = payload.subUnit;

      const editedSubUnits = state[orgGuid].reduce((curr, unit) => {
        if (unit.subUnitId === subUnit.subUnitId) {
          return [...curr, subUnit];
        }
        return [...curr, unit];
      }, []);

      return {
        ...state,
        [orgGuid]: editedSubUnits,
      };
    })
    .addCase(deleteSubUnitResponse, (state, { payload }) => {
      const orgGuid = payload.orgGuid;
      const subUnitIdToDelete = payload.subUnitId;
      const filtedOrgSubUnits = state[orgGuid].filter(
        ({ subUnitId }) => subUnitId !== subUnitIdToDelete,
      );
      return { ...state, [orgGuid]: filtedOrgSubUnits };
    })
    .addCase(getSubUnitsError, (state, { payload }) => ({
      ...state,
      [payload.orgGuid]: [],
    }));
});

const deleteSubUnitInProgress = createReducer(false, builder => {
  builder
    .addCase(deleteSubUnitRequest, () => true)
    .addCase(deleteSubUnitResponse, () => false)
    .addCase(deleteSubUnitError, () => false);
});

export default combineReducers({
  isAddSubUnitModalVisible,
  isAddToSubUnitModalVisible,
  selectedSubUnitId,
  createSubUnitInProgress,
  subUnits,
  deleteSubUnitInProgress,
});
