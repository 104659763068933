/* eslint-disable react/no-multi-comp */
import React from 'react';

import PasteContentIcon from 'material-ui-icons/ContentPaste';
import { FormattedMessage } from 'react-intl';

import { Button, DateView, TableHeadingSort } from '@shared/components';
import { tableSortOrder } from '@utils';

import styles from './HistoryReportsTable.less';

export const columns = ({ sorter, onOpenLink }) => [
  {
    width: '6rem',
    key: 'pasteIcon',
    render() {
      return <PasteContentIcon className={styles.reportIcon} />;
    },
  },
  {
    key: 'POID',
    width: '20rem',
    dataIndex: 'POID',
    sorter: true,
    sortOrder: tableSortOrder(sorter, 'POID'),
    title: (
      <TableHeadingSort name="POID" sorter={sorter}>
        <FormattedMessage id="reports.HistoryReports.column.name" />
      </TableHeadingSort>
    ),
    render(poid) {
      return (
        <FormattedMessage
          id="reports.HistoryReports.reportName"
          values={{ poid }}
        />
      );
    },
  },
  {
    width: '20rem',
    title: <FormattedMessage id="reports.HistoryReports.column.createdOn" />,
    dataIndex: 'DateCreated',
    render(date) {
      return <DateView value={date} utc />;
    },
  },
  {
    width: '24rem',
    key: 'recordedBy',
    dataIndex: 'recordedBy',
    title: <FormattedMessage id="reports.HistoryReports.column.recordedBy" />,
    render(recorderName) {
      return recorderName;
    },
  },
  {
    dataIndex: 'ReportURI',
    render(url) {
      return (
        <div className={styles.validateEagleWrapper}>
          <Button
            ghost
            noBorder
            fitText
            color="info"
            className="qa_download"
            onClick={() => onOpenLink(url)}
          >
            <FormattedMessage id="reports.HistoryReports.column.download" />
          </Button>
        </div>
      );
    },
  },
];
