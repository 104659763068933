import React from 'react';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Input as AntInput } from 'antd';
import { Input as BsaInput } from 'bsa-ui';
import cn from 'classnames';

import EditorTextArea from './EditorTextArea';
import styles from './Input.less';
import TextArea from './TextArea';

/**
 * @type {React.FC<import('./Input.types').InputProps>}
 */
const Input = React.forwardRef(function Input(
  { className, floatingLabel, rounded, suffix, ...rest },
  ref,
) {
  const hasErrors = rest['data-__field'] && !!rest['data-__field'].errors;
  const classNames = cn('bsa-input', className, {
    [styles.rounded]: rounded,
    [styles.floatingLabelField]: floatingLabel && !rest.suffix,
  });
  return floatingLabel ? (
    <div className={styles.floatingLabelWrap}>
      <AntInput
        id={rest.id}
        size="large"
        className={classNames}
        ref={ref}
        {...rest}
      />
      <label htmlFor={rest.id} className={styles.floatingLabel}>
        {rest.placeholder}
      </label>
      {(suffix || hasErrors) && (
        <span className={styles.floatingLabelSuffix}>
          {hasErrors ? <ErrorOutlineIcon /> : suffix}
        </span>
      )}
    </div>
  ) : (
    <AntInput className={classNames} ref={ref} suffix={suffix} {...rest} />
  );
});

Input.defaultProps = BsaInput.defaultProps;
Input.propTypes = BsaInput.propTypes;
Input.Group = BsaInput.Group;
Input.Search = BsaInput.Search;
Input.TextArea = TextArea;
Input.EditorTextArea = EditorTextArea;

export default Input;
