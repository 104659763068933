import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import trim from 'lodash/trim';
import uniqBy from 'lodash/uniqBy';
import { forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GET_ACTIVITIES_V2 } from '@config';
import { activityTypeIds } from '@shared';
import { esbApiService, personNameBuilder } from '@utils';

import {
  addActivityKeys,
  updateActivityKeys,
  updateNonRegisteredParticipantsKeys,
} from '../constants';
import {
  formatActivityTime,
  formatApiDateTime,
  formatDate,
  isScoutbookImport,
} from '../utils';

/**
 * @esbEndpoint GET /lookups/advancements/activityCategories
 */
export const fetchActivityCategories$ = () =>
  esbApiService.get$('/lookups/advancements/activityCategories');

/**
 * @esbEndpoint GET /lookups/advancements/activityCollaborativeOrganizations
 */
export const fetchCollaborativeOrganizations$ = () =>
  esbApiService
    .get$('/lookups/advancements/activityCollaborativeOrganizations')
    .pipe(map(organizations => organizations.filter(({ isBSA }) => isBSA)));

/*
  Added recursivity spliting getActivities request on multiple iteration
  when we have more than 300 items per page to avoid internal server error from backend
*/
/**
 * @esbEndpoint POST /advancements/v2/activities
 * @esbEndpoint POST /advancements/activities
 */
export const getActivities$ = payload => {
  const {
    activityTypeId,
    startDateTime,
    endDateTime,
    organizationGuid = undefined,
    personGuid = undefined,
    showAll = false,
    showPersonalActivities = false,
    page = 1,
    perPage = 100,
    maxPerPage = 300,
    accumulatedActivities = [],
  } = payload;

  const numPerPage =
    perPage >= maxPerPage && GET_ACTIVITIES_V2 ? maxPerPage : perPage;
  return esbApiService
    .post$(
      `/advancements${
        GET_ACTIVITIES_V2 ? '/v2' : ''
      }/activities?page=${page}&perPage=${numPerPage}`,
      {
        activityTypeId,
        hostOrganizationGuid: organizationGuid,
        personGuid,
        startDate: startDateTime && formatDate(startDateTime),
        endDate: endDateTime && formatDate(endDateTime),
        includeActivities: showAll
          ? 'both'
          : showPersonalActivities
          ? 'personal'
          : 'unit',
      },
      {
        gtm: {
          label: 'advancements/activities/search',
        },
        swCache: true,
      },
    )
    .pipe(
      switchMap(activitiesResponse => {
        const activities = GET_ACTIVITIES_V2
          ? activitiesResponse.activities
          : activitiesResponse;
        const totalActivites = GET_ACTIVITIES_V2
          ? activitiesResponse.totalActivities
          : 0;
        if (
          totalActivites > numPerPage &&
          page < Math.ceil(totalActivites / numPerPage)
        ) {
          return getActivities$({
            ...payload,
            page: page + 1,
            accumulatedActivities: accumulatedActivities.concat(activities),
          });
        } else {
          const processedActivities = accumulatedActivities
            .concat(activities)
            .map(activity => ({
              ...activity,
              isJTE:
                activity.activityTypeId === activityTypeIds.SERVICE_PROJECTS &&
                !isScoutbookImport(activity.createdBy),
              registeredAdults: activity.registeredAdults.map(adult => ({
                ...adult,
                isAdult: !adult.isYouth,
                personShortFullName: personNameBuilder.short(adult),
              })),
              registeredYouths: activity.registeredYouths.map(youth => ({
                ...youth,
                isAdult: !youth.isYouth,
                personShortFullName: personNameBuilder.short(youth),
              })),
              totalAdults: uniqBy(
                activity.registeredAdults,
                adult => adult.userId,
              ).length,
              totalYouths: uniqBy(
                activity.registeredYouths,
                youth => youth.userId,
              ).length,
            }));
          return of(processedActivities);
        }
      }),
    );
};

/**
 * @esbEndpoint GET /advancements/activities/:activityId
 */
export const getActivityById$ = activityId =>
  esbApiService
    .get$(`/advancements/activities/${activityId}`, {
      gtm: {
        label: '/advancements/activities/{activityId}',
      },
      swCache: true,
    })
    .map(activity => {
      const formattedStartTime = formatActivityTime(activity.startDateTime);
      const formattedEndTime = formatActivityTime(activity.endDateTime);
      const allDay =
        formattedEndTime === '23:59' && formattedStartTime === '00:00';
      return {
        ...activity,
        registeredAdults: activity.registeredAdults.map(adult => ({
          ...adult,
          isAdult: !adult.isYouth,
          personShortFullName: personNameBuilder.short(adult),
        })),
        registeredYouths: activity.registeredYouths.map(youth => ({
          ...youth,
          isAdult: !youth.isYouth,
          personShortFullName: personNameBuilder.short(youth),
        })),
        allDay,
        totalAdults: uniqBy(activity.registeredAdults, adult => adult.userId)
          .length,
        totalYouths: uniqBy(activity.registeredYouths, youth => youth.userId)
          .length,
      };
    });

/**
 * @esbEndpoint POST /organizations/camps/search
 */
export const getLocations$ = payload =>
  esbApiService.post$('/organizations/camps/search', payload, {
    gtm: {
      label: '/organizations/camps/search',
    },
  });

/**
 * @esbEndpoint PUT /advancements/v2/activities/:activityId
 */
const updateBaseActivity$ = ({ id, ...data }) => {
  const processedData = {
    ...data,
    hostOrganizationGuid: undefined,
  };
  return esbApiService.put$(
    `/advancements/v2/activities/${id}`,
    processedData,
    {
      gtm: {
        label: '/advancements/v2/activities/{id}',
      },
    },
  );
};

/**
 * @esbEndpoint DELETE /advancements/activities/:activityId/collaborativeOrganizations/:collabOrgId
 */
const deleteCollaborativeOrganization$ = ({ activityId, collabOrgId }) =>
  esbApiService.delete$(
    `/advancements/activities/${activityId}/collaborativeOrganizations/${collabOrgId}`,
    null,
    {
      gtm: {
        label:
          '/advancements/activities/{activityId}/collaborativeOrganizations/{collabOrgId}',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/activities/:activityId/collaborativeOrganizations/add
 */
const addCollaborativeOrganization$ = ({
  activityId,
  collaborativeOrganizationId,
}) =>
  esbApiService.post$(
    `/advancements/activities/${activityId}/collaborativeOrganizations/add`,
    {
      collaborativeOrganizationId,
    },
    {
      gtm: {
        label:
          '/advancements/activities/{activityId}/collaborativeOrganizations/add',
      },
    },
  );

const updateCollaborativeOrganizations$ = ({
  id,
  savedCollaborativeOrganizations = [],
  collaborativeOrganizations = [],
}) => {
  const operations = [of({})];
  // removed
  savedCollaborativeOrganizations
    .filter(sco => !collaborativeOrganizations.includes(sco.collabOrgId))
    .forEach(org =>
      operations.push(
        deleteCollaborativeOrganization$({
          activityId: id,
          collabOrgId: org.collabOrgId,
        }),
      ),
    );

  // added
  collaborativeOrganizations
    .filter(
      co =>
        !savedCollaborativeOrganizations.find(
          ({ collabOrgId }) => collabOrgId == co,
        ),
    )
    .forEach(collaborativeOrganizationId =>
      operations.push(
        addCollaborativeOrganization$({
          activityId: id,
          collaborativeOrganizationId,
        }),
      ),
    );
  return forkJoin(...operations);
};

/**
 * @esbEndpoint PUT /advancements/activities/:activityId/nonRegisteredOrganizationParticipants/:activityDetailsId
 */
export const editNonRegisteredOrgParticipants$ = (
  activityId,
  participantsId,
  payload,
) =>
  esbApiService.put$(
    `/advancements/activities/${activityId}/nonRegisteredOrganizationParticipants/${participantsId}`,
    payload,
    {
      gtm: {
        label:
          '/advancements/activities/{activityId}/nonRegisteredOrganizationParticipants/{activityDetailsId}',
      },
    },
  );

const updateNonRegisteredOrgParticipants$ = ({
  nonRegisteredYouthCount = 0,
  nonRegisteredYouthHours = 0,
  nonRegisteredAdultCount = 0,
  nonRegisteredAdultHours = 0,
  nonRegisteredOrgParticipants,
  id: activityId,
}) => {
  const newValues = {
    nonRegisteredYouthCount,
    nonRegisteredYouthHours,
    nonRegisteredAdultCount,
    nonRegisteredAdultHours,
  };
  const nonRegisteredOrgValues = pick(
    nonRegisteredOrgParticipants,
    updateNonRegisteredParticipantsKeys,
  );
  if (
    (nonRegisteredYouthCount ||
      nonRegisteredYouthHours ||
      nonRegisteredAdultCount ||
      nonRegisteredAdultHours) &&
    !isEqual(newValues, nonRegisteredOrgValues)
  ) {
    const { id: participantsId } = nonRegisteredOrgParticipants;
    return editNonRegisteredOrgParticipants$(activityId, participantsId, {
      ...nonRegisteredOrgParticipants,
      ...newValues,
      materialCost: nonRegisteredOrgParticipants.materialCost || 0,
      projectCount: nonRegisteredOrgParticipants.projectCount || 0,
    });
  }
  return of({});
};

/**
 * @esbEndpoint PUT /advancements/activities/:activityId/registeredParticipants
 */
export const editPersonDetails$ = ({ activityId, personDetails }) =>
  esbApiService
    .put$(
      `/advancements/activities/${activityId}/registeredParticipants`,
      personDetails,
      {
        gtm: {
          label: '/advancements/activities/{activityId}/registeredParticipants',
        },
      },
    )
    .map(() => personDetails);

/**
 * @esbEndpoint DELETE /advancements/activities/:activityId/registeredParticipants/:activityRecordId
 */
export const removeRegisteredParticipant$ = ({
  activityId,
  activityRecordId,
}) =>
  esbApiService.delete$(
    `/advancements/activities/${activityId}/registeredParticipants/${activityRecordId}`,
    {
      gtm: {
        label:
          '/advancements/activities/{activityId}/registeredParticipants/{activityRecordId}',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/activities/:activityId/registeredParticipants/add
 */
export const addPersonDetails$ = ({ activityId, personDetails }) =>
  esbApiService.post$(
    `/advancements/v2/activities/${activityId}/registeredParticipants/add`,
    personDetails,
    {
      gtm: {
        label:
          '/advancements/v2/activities/{activityId}/registeredParticipants/add',
      },
    },
  );

const updatePersonDetails$ = ({
  id,
  isApproved = true,
  savedPersonDetails = [],
  registeredAdults = [],
  registeredYouths = [],
  personsToRemove = [],
}) => {
  const personDetails = [...registeredAdults, ...registeredYouths];
  const operations = [of({})];
  // removed
  savedPersonDetails
    .filter(spd => personsToRemove.includes(spd.userId))
    .forEach(activityRecord => {
      operations.push(
        removeRegisteredParticipant$({
          activityId: id,
          activityRecordId: activityRecord.id,
        }),
      );
    });

  // added
  const accumulatedDetails = personDetails.filter(
    pd =>
      !savedPersonDetails.find(
        spd =>
          +pd.userId === +spd.userId &&
          +pd.activityValueTypeId === +spd.activityValueTypeId,
      ),
  );

  const groupNewById = groupBy(accumulatedDetails, 'userId');

  const activitiesToPush = Object.keys(groupNewById).map(uid => {
    const activityValues = groupNewById[uid];
    const [activityValue] = activityValues;

    return {
      userId: +uid,
      note: 'N/A',
      organizationGuid: activityValue.organizationGuid,
      isApproved: activityValue.isApproved,
      // isDeclined is optional in V2 (addPersonDetailsV2$)
      leaderApprovedDate: activityValue.leaderApprovedDate,
      leaderApprovedId: activityValue.leaderApprovedId,
      activityValues: activityValues.map(av => ({
        activityValueTypeId: av.activityValueTypeId,
        activityValue: av.activityValue,
      })),
    };
  });

  if (activitiesToPush.length) {
    operations.push(
      addPersonDetails$({
        activityId: id,
        personDetails: activitiesToPush,
      }),
    );
  }
  // updated
  const filteredDetails = personDetails.filter(pd =>
    savedPersonDetails.find(spd => {
      const activityChange =
        !personsToRemove.includes(pd.userId) &&
        pd.userId == spd.userId &&
        pd.activityValueTypeId == spd.activityValueTypeId &&
        pd.activityValue &&
        pd.activityValue != spd.activityValue;
      if (activityChange) {
        pd.id = spd.id;
      }
      return activityChange;
    }),
  );

  const groupedByUserId = groupBy(filteredDetails, 'userId');

  const activitiesDetails = Object.keys(groupedByUserId).map(uid => {
    const activityValues = groupedByUserId[uid];
    const [activityValue] = activityValues;

    return {
      userId: +uid,
      activityValues: activityValues.map(av => ({
        id: +av.id,
        activityValueTypeId: av.activityValueTypeId,
        activityValue: av.activityValue,
      })),
      note: 'N/A',
      isApproved: isApproved,
      isDeclined: false,
      leaderApprovedId: activityValue.leaderApprovedId,
      leaderApprovedDate: activityValue.leaderApprovedDate,
    };
  });

  if (activitiesDetails.length) {
    operations.push(
      editPersonDetails$({
        activityId: id,
        personDetails: activitiesDetails,
      }),
    );
  }

  return forkJoin(...operations);
};

const updateActivity$ = (data, isCalendarActivity) => {
  const { allowUpdateDetails = true } = data;

  return forkJoin([
    allowUpdateDetails
      ? updateBaseActivity$(pick(data, updateActivityKeys(isCalendarActivity)))
      : of({}),
    allowUpdateDetails ? updateCollaborativeOrganizations$(data) : of({}),
    allowUpdateDetails ? updateNonRegisteredOrgParticipants$(data) : of({}),
  ]).pipe(switchMap(() => updatePersonDetails$(data)));
};

// TODO: Refactor activities to send activity values already grouped, since the api now accepts multiple activity values per person object
const mergeActivityValues = userActivities => {
  const groupPersons = groupBy(userActivities, 'userId');
  return Object.keys(groupPersons).map(userId => {
    const activityValues = groupPersons[userId];
    const [activityValue] = activityValues;
    const {
      organizationGuid,
      isApproved,
      leaderApprovedDate,
      leaderApprovedId,
      personGuid,
      memberId,
    } = activityValue || {};
    return {
      userId: +userId,
      note: 'N/A',
      organizationGuid,
      isApproved,
      leaderApprovedDate,
      leaderApprovedId,
      personGuid,
      memberId,
      activityValues: activityValues.map(av => ({
        activityValueTypeId: av.activityValueTypeId,
        activityValue: av.activityValue,
      })),
    };
  });
};

/**
 * @esbEndpoint POST /advancements/v2/activities/add
 */
const createActivity$ = data =>
  esbApiService.post$(
    '/advancements/v2/activities/add',
    {
      ...data,
      registeredAdults: mergeActivityValues(data.registeredAdults),
      registeredYouths: mergeActivityValues(data.registeredYouths),
    },
    {
      gtm: {
        label: '/advancements/v2/activities/add',
      },
    },
  );

export const saveActivity$ = data => {
  const saveObject = {
    ...omitBy(
      {
        ...data,
        nonRegisteredYouthCount:
          parseInt(data.nonRegisteredYouthCount, 10) || undefined,
        nonRegisteredYouthHours: data.nonRegisteredYouthHours
          ? Number(data.nonRegisteredYouthHours)
          : undefined,
        nonRegisteredAdultCount:
          parseInt(data.nonRegisteredAdultCount, 10) || undefined,
        nonRegisteredAdultHours: data.nonRegisteredAdultHours
          ? Number(data.nonRegisteredAdultHours)
          : undefined,
        akelaStateId: parseInt(data.akelaStateId, 10) || undefined,
        startDateTime: formatApiDateTime(data.startDateTime, !!data.id),
        endDateTime: formatApiDateTime(data.endDateTime, !!data.id),
      },
      item => isNil(item) || trim(item) === '',
    ),
    // need to preserve possibility to save empty values for some fields
    ...pick(data, 'description'),
  };

  if (data.id) {
    return updateActivity$(saveObject, data.isCalendarActivity);
  }
  return createActivity$(pick(saveObject, addActivityKeys));
};

/**
 * @esbEndpoint POST /advancements/v2/activities/approve
 */
export const editActivitiesDetails$ = activitiesDetails =>
  esbApiService.post$(
    '/advancements/v2/activities/approve',
    activitiesDetails,
    {
      gtm: {
        label: '/advancements/v2/activities/approve',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/activities/decline
 */
export const declineActivity$ = payload =>
  esbApiService.post$('/advancements/v2/activities/decline', payload, {
    gtm: '/advancements/v2/activities/decline',
  });

/**
 * @esbEndpoint DELETE /advancements/v2/activities/:activityId
 */
export const removeActivity$ = ({ activityId }) =>
  esbApiService.delete$(`/advancements/v2/activities/${activityId}`, {
    gtm: {
      label: '/advancements/v2/activities/{activityId}',
    },
  });
