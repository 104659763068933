import { createAction } from '@reduxjs/toolkit';

import { MessageRequest, Recipients } from '@modules/messaging/types';

import { moduleName } from '../constants';

export const getRecipientsRequest = createAction(
  `${moduleName}/GET_RECIPIENTS_REQUEST`,
);

export const getRecipientsResponse = createAction<Recipients>(
  `${moduleName}/GET_RECIPIENTS_RESPONSE`,
);

export const getRecipientsError = createAction<Error>(
  `${moduleName}/GET_RECIPIENTS_ERROR`,
);

export const sendMessageRequest = createAction(
  `${moduleName}/SEND_MESSAGE_REQUEST`,
  (payload: MessageRequest) => ({
    payload,
  }),
);

export const sendMessageResponse = createAction(
  `${moduleName}/SEND_MESSAGE_RESPONSE`,
);

export const sendMessageError = createAction<Error>(
  `${moduleName}/SEND_MESSAGE_ERROR`,
);

export { ROUTE_MESSAGING } from '@shared';
