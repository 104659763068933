import React, { useState } from 'react';

import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import YouthImageLeft from '@assets/images/portrait_youth_female_199.jpg';
import YouthImageRight from '@assets/images/portrait_youth_male_38.jpg';
import { querySel } from '@location';
import { useAddDenChiefMutation } from '@modules/advancement/api';
import {
  useGetDenChiefDetailsQuery,
  useReviewDenChiefPositionMutation,
} from '@modules/leadershipPositions/api';
import { intl } from '@modules/shared';
import Spin from '@modules/shared/components/Spin';
import { getPreferredName } from '@modules/shared/utils/preferredName';
import { toastService } from '@modules/toasts';
import { formatToAPIDate } from '@modules/utils/datesLuxon';
import Button from '@shared/components/Button';
import T from '@shared/components/T';

import styles from './DenChiefconfirmationPage.less';

const DenChiefConfirmationPage: React.FC = () => {
  const { c: contextToken, i: memberId } = useSelector(querySel); //get context token from url

  const [confirm, setConfirm] = useState<null | boolean>(null);

  const {
    data: denChiefDetails,
    isFetching,
    isLoading: isLoadingDetails,
    error,
  } = useGetDenChiefDetailsQuery(contextToken);
  const [addDenChief, { isLoading: isLoadingAddDenChief }] =
    useAddDenChiefMutation();

  const isLoading = isLoadingAddDenChief || isLoadingDetails;

  const [
    reviewDenChiefPosition,
    { isLoading: reviewDenChiefPositionInprogress, isSuccess, isError },
  ] = useReviewDenChiefPositionMutation();

  const applicantName = denChiefDetails
    ? getPreferredName(denChiefDetails)
    : '';

  const isAlreadyReviewed =
    get(error, 'errorDesc') === 'Position has already been reviewed';
  const reviewedSuccessfully = isSuccess && !isError;

  const createApproveHandler = async (cofirmValue: boolean) => {
    try {
      const response = await reviewDenChiefPosition({
        contextToken,
        memberId: Number(memberId),
        confirm: cofirmValue,
      }).unwrap();
      toastService.dismiss(undefined);
      await addDenChief({
        orgGuid: response.sourceOrganizationGuid,
        payload: {
          packId: response.packId,
          memberId: response.memberId,
          dateStarted: formatToAPIDate(new Date()),
        },
      });

      setConfirm(cofirmValue);
    } catch (error) {
      toastService.dismiss(undefined);
      toastService.error(intl.formatMessage({ id: 'shared.errors.generic' }));
    }
  };

  return (
    <div className={styles.confirmationContainer}>
      <div className={styles.confirmationCard}>
        <T className={styles.title}>
          <FormattedMessage id="youthPositions.denChief.title" />
        </T>
        <div className={styles.imageContainer}>
          <img src={YouthImageLeft} />
          <img src={YouthImageRight} />
        </div>
        <Spin
          spinning={isLoading || isFetching || reviewDenChiefPositionInprogress}
          size="large"
          delay={200}
        >
          {!!isAlreadyReviewed && (
            <div>
              <div className={styles.subtitle}>
                <FormattedMessage id="youthPositions.denChief.alreadyReviewedTitle" />
              </div>
              <div className={styles.description}>
                <FormattedMessage id="youthPositions.denChief.alreadyReviewedDescription" />
              </div>
            </div>
          )}
          {!!error && !isAlreadyReviewed && (
            <div>
              <div className={styles.subtitle}>
                <FormattedMessage id="youthPositions.denChief.invalidTitle" />
              </div>
              <div className={styles.description}>
                <FormattedMessage id="youthPositions.denChief.invalidDescription" />
              </div>
            </div>
          )}
          {!reviewedSuccessfully && !error && (
            <div>
              <div className={styles.subtitle}>
                <FormattedMessage id="youthPositions.denChief.confirmPosition" />
              </div>
              <div className={styles.description}>
                <FormattedMessage
                  id="youthPositions.denChief.description"
                  values={{ name: applicantName }}
                />
              </div>
              <Button
                className={styles.approveButton}
                shape="round"
                type="primary"
                size="large"
                fitText
                onClick={() => createApproveHandler(true)}
              >
                <FormattedMessage id="youthPositions.denChief.approve" />
              </Button>
              <Button
                className={styles.rejectButton}
                ghost
                shape="round"
                size="large"
                fitText
                onClick={() => createApproveHandler(false)}
              >
                <FormattedMessage id="youthPositions.denChief.reject" />
              </Button>
            </div>
          )}
          {!!reviewedSuccessfully && (
            <div>
              <div className={styles.subtitle}>
                <FormattedMessage id="youthPositions.denChief.positionReviewed" />
              </div>
              <div className={styles.description}>
                <FormattedMessage
                  id="youthPositions.denChief.reviewedDescription"
                  values={{
                    name: applicantName,
                    choice: confirm,
                  }}
                />
              </div>
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default DenChiefConfirmationPage;
