import removeLeadingZeros from '@utils/removeLeadingZeros';

export function transformOrgProfile(unitInfo) {
  if (!unitInfo) return unitInfo;

  const { number, name, organizationFullName } = unitInfo;
  const formattedNumber = removeLeadingZeros(number);
  const formattedName = removeLeadingZeros(name);
  const formattedOrganizationFullName = organizationFullName.replace(
    name,
    formattedName,
  );

  return {
    ...unitInfo,
    number: formattedNumber,
    name: formattedName,
    organizationFullName: formattedOrganizationFullName,
  };
}
