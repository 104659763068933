import merge from 'lodash/merge';

import { API_ENV, CORE_API_URLS } from '@config';
import getUrlForEnv from './getUrlForEnv';
import getTargetUrlHeader from './getTargetUrlHeader';
import createHttp from './httpFactory';
import errorHandler from './coreErrorHandler';
import jsonBodyTranformer from './jsonBodyTransformer';
import unwrapResponse from './unwrapResponse';
import addQueryParams from './addQueryParams';

const externalApiPrefix = '/api';
const selfApiPrefix = '/apis' + externalApiPrefix;

const urlTransformer = (endpoint, { skipSw }) => {
  const externalUrl = CORE_API_URLS[API_ENV] + externalApiPrefix + endpoint;
  const selfUrl = skipSw ? externalUrl : selfApiPrefix + endpoint;

  return getUrlForEnv(selfUrl, externalUrl);
};

const optionsTransformer = ({ options, url: endpoint }) => {
  if (window.sblOffline) {
    endpoint = addQueryParams(endpoint, { offline: true });
  }
  const { acceptVersion = '1', ...restOptions } = options;
  const extraOptions = {
    headers: {
      Accept: `application/json${
        acceptVersion ? `; version = ${acceptVersion}` : ''
      }`,
      'Content-Type': 'application/json',
      ...getTargetUrlHeader(
        CORE_API_URLS[API_ENV] + externalApiPrefix + endpoint,
      ),
    },
    withCredentials: true,
  };
  return merge({}, extraOptions, restOptions);
};

const http = createHttp({
  bodyTransformer: jsonBodyTranformer,
  urlTransformer,
  optionsTransformer,
  responseHandler: unwrapResponse,
  errorHandler,
});

export default http;
