import {
  chain,
  get,
  includes,
  intersection,
  isEmpty,
  isNaN,
  isUndefined,
  pickBy,
  replace,
  toLower,
  upperFirst,
} from 'lodash';

import { programForUnitType } from '@shared';

import { PaymentTypes } from '../../types/enums';
import type {
  GetPersonProfileResponse,
  PostUserPaymentLogReq,
} from '../../types/esb';
import { API_DATE_FORMAT } from './constants';
import type { TransformFormToRequestValues, UserData } from './types';

const VIEW_ALLOWED_ROLES = ['key 3', 'unit treasurer'];

export const transformPersonProfileToUnits = (
  personProfile: GetPersonProfileResponse,
) => {
  const organizationPositions = get(personProfile, 'organizationPositions', []);

  return organizationPositions.map(
    ({
      organizationId: unitId,
      organizationName: name,
      unitType: rawUnitType,
    }) => {
      const unitType = upperFirst(toLower(rawUnitType));
      const program = get(programForUnitType, unitType);

      return {
        name,
        program,
        unitId,
        unitType,
      };
    },
  );
};

export const isAllowedOrganization = (
  userData: UserData,
  organizationGuid: string,
) => {
  const allowedRoles = chain(get(userData, 'organizations', []))
    .filter(organization => {
      const isKey3 = get(organization, 'isKey3', false);
      const allRoles = get(organization, 'allRoles', []);

      return (
        isKey3 ||
        !isEmpty(
          intersection(
            allRoles.map(name => toLower(name)),
            VIEW_ALLOWED_ROLES,
          ),
        )
      );
    })
    .map(({ organizationGuid }) => organizationGuid)
    .value();

  return includes(allowedRoles, organizationGuid);
};

export const transformFormToRequest = (
  values: TransformFormToRequestValues,
): PostUserPaymentLogReq => {
  const {
    amount: rawAmount,
    applyPaymentLogId,
    calendarEventId,
    category,
    comments: notes,
    date,
    dueDate,
    name: description,
    transactionId,
    type: paymentType,
    unitId,
  } = values;

  const amount = [PaymentTypes.CHARGE].includes(paymentType)
    ? Number(replace(rawAmount, ',', '.')) * -1
    : Number(replace(rawAmount, ',', '.'));

  const request = pickBy(
    {
      amount,
      applyPaymentLogId,
      calendarEventId: Number(calendarEventId),
      category,
      description,
      logDate: date.format(API_DATE_FORMAT),
      notes,
      paymentDueDate: dueDate && dueDate.format(API_DATE_FORMAT),
      paymentType,
      transactionId,
      unitId,
    },
    value => !isNaN(value) && !isUndefined(value),
  ) as unknown as PostUserPaymentLogReq;

  return request;
};
