import _ from 'lodash';
import flatten from 'lodash/flatten';
import partition from 'lodash/partition';
import moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { advancementStatuses, dtoDateFormat, syncOperations } from '@shared';
import { esbApiService } from '@utils';

import {
  activityValueTypesNameToId,
  activityValueTypesToName,
  addPersonDetails$,
  declineActivity$,
  editActivitiesDetails$,
} from '../../common';
import { groupByPerson } from '../utils';

const serializeFactory =
  ({ organizationGuid, personsAdvancements, completionDates, status }) =>
  () =>
    personsAdvancements.map(({ userId, id, userAwardId, advancementType }) => ({
      operation: syncOperations.APPROVE_ADVANCEMENTS,
      organizationGuid,
      userId,
      advancementType,
      advancementId: id,
      userAwardId,
      date: completionDates[`${userId}_${id}`],
      status,
    }));

const mapToRequestsByType = ({
  loggedInUserId,
  organizationGuid,
  personsAdvancements,
  completionDates,
  status,
}) => {
  loggedInUserId = loggedInUserId ? Number(loggedInUserId) : undefined;
  const types = personsAdvancements.reduce((acc, pa) => {
    acc[pa.advancementType] = acc[pa.advancementType]
      ? [...acc[pa.advancementType], pa]
      : [pa];

    return acc;
  }, {});

  return Object.keys(types).map(advancementType => {
    const persons = groupByPerson(types[advancementType]);
    const body = persons.map(({ userId, advancements }) => {
      const advancementsBody = advancements.map(({ id, userAwardId }) => {
        const date = moment(completionDates[`${userId}_${id}`]).format(
          dtoDateFormat,
        );
        const awarded = status === advancementStatuses.AWARDED;

        const ret = {
          id: Number(id),
          dateCompleted: date,
          dateEarned: date,
          leaderApprovedDate: date,
          leaderApprovedUserId: loggedInUserId,
          awarded,
          dateAwarded: awarded ? moment().format(dtoDateFormat) : '',
        };

        // API is erroring if empty string dateAwarded
        if (
          ret.dateAwarded !== undefined &&
          (ret.dateAwarded == '' || ret.dateAwarded == null)
        ) {
          delete ret.dateAwarded;
        }

        if (userAwardId) {
          ret.userAwardId = Number(userAwardId);
        }

        return ret;
      });

      return {
        organizationGuid,
        userId: Number(userId),
        [advancementType]: advancementsBody,
      };
    });

    return esbApiService.postOrDefer$(
      `/advancements/youth/${advancementType}`,
      body,
      {
        gtm: {
          label: `/advancements/youth/${advancementType}`,
        },
        serialize: serializeFactory({
          organizationGuid,
          personsAdvancements,
          completionDates,
          status,
        }),
      },
    );
  });
};

const approveAdvancements$ = ({
  loggedInUserId,
  organizationGuid,
  personsAdvancements,
  completionDates,
  status,
}) => {
  if (personsAdvancements.length === 0) {
    return of([]);
  }
  const requestsByType = mapToRequestsByType({
    loggedInUserId,
    organizationGuid,
    personsAdvancements,
    completionDates,
    status,
  });

  return forkJoin(requestsByType).pipe(
    map(results =>
      results.reduce((cumulativeRes, res) => {
        if (
          cumulativeRes === esbApiService.DEFERRED ||
          res === esbApiService.DEFERRED
        ) {
          return esbApiService.DEFERRED;
        }

        return cumulativeRes;
      }, results[0]),
    ),
  );
};

const approveActivities$ = ({
  loggedInUserId,
  personsActivities,
  updatedValues,
  organizationGuid,
  approvedDate = undefined,
}) => {
  if (personsActivities.length === 0) {
    return of([]);
  }
  const valuesToAdd = [];
  const activitiesDetails = personsActivities.map(
    ({
      userId,
      activityId,
      activityRecords,
      personGuid,
      memberId,
      isYouth,
      organizationGuid,
    }) => {
      const userUpdatedValues = updatedValues[`${userId}_${activityId}`];
      const valuesToUpdate = activityRecords.map(
        ({ id, activityValueTypeId }) => {
          const activityValueTypeName =
            activityValueTypesToName[activityValueTypeId];
          const activityValue = +userUpdatedValues[activityValueTypeName];
          delete userUpdatedValues[activityValueTypeName];
          return {
            id: +id,
            activityValueTypeId: activityValueTypeId,
            activityValue,
          };
        },
      );
      Object.keys(userUpdatedValues).forEach(valueKey => {
        const activityValue = +userUpdatedValues[valueKey];
        if (activityValue) {
          valuesToAdd.push(
            addPersonDetails$({
              activityId,
              personDetails: {
                personGuid: personGuid || undefined,
                userId,
                memberId,
                isYouth,
                organizationGuid,
                activityValueTypeId: activityValueTypesNameToId[valueKey],
                activityValue,
                note: valueKey,
                isApproved: true,
                leaderApprovedId: loggedInUserId,
                leaderApprovedDate: moment(approvedDate).format(dtoDateFormat),
              },
            }),
          );
        }
      });
      return {
        activityId: activityId,
        activityValues: valuesToUpdate,
        note: `${activityRecords[0].note || 'N/A'}`,
        leaderApprovedId: loggedInUserId,
        leaderApprovedDate: moment(approvedDate).format(dtoDateFormat),
      };
    },
  );
  return forkJoin([
    ...valuesToAdd,
    editActivitiesDetails$({ organizationGuid, activities: activitiesDetails }),
  ]);
};

const approveItems$ = ({
  loggedInUserId,
  organizationGuid,
  approveItems,
  updatedValues,
  status,
  approvedDate,
}) => {
  const [personsAdvancements, personsActivities] = partition(
    approveItems,
    'advancementType',
  );

  const isRequirements = approveItems.some(item => item.type === 'requirement');

  if (!isRequirements) {
    return forkJoin(
      approveAdvancements$({
        loggedInUserId,
        organizationGuid,
        personsAdvancements,
        completionDates: updatedValues,
        status,
      }),
      approveActivities$({
        loggedInUserId,
        personsActivities,
        updatedValues,
        organizationGuid,
        approvedDate,
      }),
    ).pipe(map(flatten));
  } else {
    const meritBadgesRequests = _.chain(approveItems)
      .filter(item => item.advancementType === 'meritBadges')
      .groupBy(item => `${item.userId}-${item.advancementId}-${item.versionId}`)
      .map((item, key) => {
        const [userId, advancementId, versionId] = key.split('-');
        return {
          userId: Number(userId),
          organizationGuid: organizationGuid,
          advancementId: Number(advancementId),
          versionId: Number(versionId),
          requirements: item.map(req => {
            const valueId = `${userId}_${req.id}`;
            const dateCompleted = updatedValues[valueId];
            const body = {
              id: req.id,
              dateStarted: req.dateStarted,
              leaderApprovedDate: approvedDate,
              leaderApprovedUserId: loggedInUserId,
              dateCompleted: moment(dateCompleted).format(dtoDateFormat),
              approved: true,
            };

            return body;
          }),
        };
      })
      .groupBy(item => item.advancementId)
      .map((items, meritBadgeId) => {
        const body = items;
        return esbApiService.postOrDefer$(
          `/advancements/v2/youth/meritBadges/${meritBadgeId}/requirements`,
          body,
          {
            gtm: {
              label: `/advancements/v2/youth/meritBadges/{meritBadgeId}/requirements`,
            },
            serialize: serializeFactory({
              organizationGuid,
              status,
            }),
          },
        );
      })
      .value();

    const ranksRequests = _.chain(approveItems)
      .filter(item => item.advancementType === 'ranks')
      .groupBy(item => `${item.userId}-${item.advancementId}-${item.versionId}`)
      .map((item, key) => {
        const [userId, advancementId, versionId] = key.split('-');
        return {
          userId: Number(userId),
          organizationGuid: organizationGuid,
          versionId: Number(versionId),
          advancementId: Number(advancementId),
          requirements: item.map(req => {
            const valueId = `${userId}_${req.id}`;
            const dateCompleted = updatedValues[valueId];
            const body = {
              id: req.id,
              leaderApprovedDate: approvedDate,
              leaderApprovedUserId: loggedInUserId,
              dateCompleted: moment(dateCompleted).format(dtoDateFormat),
              approved: true,
            };

            return body;
          }),
        };
      })
      .groupBy(item => item.advancementId)
      .map((items, rankId) => {
        const body = items;
        return esbApiService.postOrDefer$(
          `/advancements/v2/youth/ranks/${rankId}/requirements`,
          body,
          {
            gtm: {
              label: `/advancements/v2/youth/ranks/{rankId}/requirements`,
            },
            serialize: serializeFactory({
              organizationGuid,
              status,
            }),
          },
        );
      })
      .value();

    const adventuresRequests = _.chain(approveItems)
      .filter(item => item.advancementType === 'adventures')
      .groupBy(item => `${item.userId}-${item.advancementId}-${item.versionId}`)
      .map((item, key) => {
        const [userId, advancementId, versionId] = key.split('-');
        return {
          userId: Number(userId),
          organizationGuid: organizationGuid,
          versionId: Number(versionId),
          advancementId: Number(advancementId),
          requirements: item.map(req => {
            const valueId = `${userId}_${req.id}`;
            const dateCompleted = updatedValues[valueId];
            const body = {
              id: req.id,
              leaderApprovedDate: approvedDate,
              leaderApprovedUserId: loggedInUserId,
              dateCompleted: moment(dateCompleted).format(dtoDateFormat),
              approved: true,
            };

            return body;
          }),
        };
      })
      .groupBy(item => item.advancementId)
      .map((items, adventureId) => {
        const body = items;
        return esbApiService.postOrDefer$(
          `/advancements/v2/youth/adventures/${adventureId}/requirements`,
          body,
          {
            gtm: {
              label: `/advancements/v2/youth/adventures/{adventureId}/requirements`,
            },
            serialize: serializeFactory({
              organizationGuid,
              status,
            }),
          },
        );
      })
      .value();

    const awardsRequests = _.chain(approveItems)
      .filter(item => item.advancementType === 'awards')
      .groupBy(item => `${item.userId}-${item.advancementId}-${item.versionId}`)
      .map((items, key) => {
        const [userId, advancementId, versionId] = key.split('-');
        const award = items[0];
        return {
          userId: Number(userId),
          organizationGuid: organizationGuid,
          versionId: Number(versionId),
          advancementId: Number(advancementId),
          userAwardId: Number(award.userAwardId),
          requirements: items.map(req => {
            const valueId = `${userId}_${req.id}`;
            const dateCompleted = updatedValues[valueId];
            const body = {
              id: req.id,
              // dateStarted: req.dateStarted,
              leaderApprovedDate: approvedDate,
              leaderApprovedUserId: loggedInUserId,
              dateCompleted: moment(dateCompleted).format(dtoDateFormat),
              approved: true,
            };

            return body;
          }),
        };
      })
      .groupBy(item => item.advancementId)
      .map((items, awardId) => {
        const body = items;
        return esbApiService.postOrDefer$(
          `/advancements/v2/youth/awards/${awardId}/requirements`,
          body,
          {
            gtm: {
              label: `/advancements/v2/youth/awards/{awardId}/requirements`,
            },
            serialize: serializeFactory({
              organizationGuid,
              status,
            }),
          },
        );
      })
      .value();

    return forkJoin([
      ...meritBadgesRequests,
      ...ranksRequests,
      ...adventuresRequests,
      ...awardsRequests,
    ]).pipe(map(flatten));
  }
};

const declineMultipleActivities$ = payload => declineActivity$(payload);

export default { approveItems$, declineMultipleActivities$ };
