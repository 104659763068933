import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

import styles from './Toast.less';

const Toast = ({ title, children, type }) => (
  <div className={styles.toastElements}>
    <div className={styles.iconContainer} type={type}>
      <ErrorIcon />
    </div>
    <div className={styles.content}>
      {title && (
        <div className={styles.title} type={type}>
          {title}
        </div>
      )}
      {children && <div className={styles.body}>{children}</div>}
    </div>
  </div>
);

Toast.defaultProps = {
  title: null,
};

Toast.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.node,
  children: PropTypes.node,
};

export default Toast;
