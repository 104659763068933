import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import MutationObserver from 'mutation-observer';

class BottomScrollListener extends React.PureComponent {
  state = {
    isAtBottom: false,
  };

  componentDidMount() {
    setTimeout(() => {
      const { customScrollSelector, customBodySelector } = this.props;
      this.containerScroll = document.querySelector(customScrollSelector);
      this.containerBody = document.querySelector(customBodySelector);
      this.containerScroll.addEventListener('scroll', this.handleBottomCheck);

      this.mutationObserver = new MutationObserver(this.handleBottomCheck);
      this.mutationObserver.observe(this.containerBody, {
        childList: true,
        subtree: true,
      });
    });
  }

  componentWillUnmount() {
    if (this.containerScroll) {
      this.containerScroll.removeEventListener(
        'scroll',
        this.handleBottomCheck,
      );
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  containerScroll = null;
  containerBody = null;
  mutationObserver = null;

  handleBottomCheck = throttle(() => {
    const { onBottomChange } = this.props;
    const { isAtBottom: wasPreviouslyAtBottom } = this.state;
    const isAtBottom = this.isAtBottom();

    const hasReachedBottom = isAtBottom && !wasPreviouslyAtBottom;
    const hasLeaveBottom = !isAtBottom && wasPreviouslyAtBottom;

    if (hasLeaveBottom || hasReachedBottom) {
      onBottomChange(hasReachedBottom);
      this.setState(({ isAtBottom }) => ({ isAtBottom: !isAtBottom }));
    }
  }, 100);

  isAtBottom = () => {
    const { offset } = this.props;

    if (this.containerBody) {
      return (
        window.innerHeight +
          offset -
          this.containerBody.getBoundingClientRect().top >=
        this.containerBody.offsetHeight
      );
    }
  };

  render() {
    const { children } = this.props;
    if (!children) {
      return '';
    }

    return children;
  }
}

BottomScrollListener.propTypes = {
  customScrollSelector: PropTypes.string.isRequired,
  customBodySelector: PropTypes.string.isRequired,
  onBottomChange: PropTypes.func.isRequired,
  offset: PropTypes.number,
  children: PropTypes.node,
};

BottomScrollListener.defaultProps = {
  offset: 0,
  children: null,
};

export default BottomScrollListener;
