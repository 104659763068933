import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, T } from '@shared';

import PersonItem from '../../PersonItem';
import WarningMessage from '../WarningMessage';
import styles from './PersonsListDesktop.less';

// eslint-disable-next-line react/display-name
const PersonsListDesktop = React.memo(
  ({
    type,
    persons,
    ineligibleUsersIds,
    title,
    warningMessage,
    eligibleCount,
    allowRemove,
    onRemove,
    className,
    personClassName,
    listId,
    showAdvStatus,
    personsNotAllowedToRemove,
    selectedAdvStatus,
  }) => {
    const numOfPersons = persons.length;
    const advStatus =
      selectedAdvStatus == 'awarded' ? 'toBeAwardedCount' : 'toBeApprovedCount';
    if (numOfPersons === 0) {
      return null;
    }

    const hasErrors = ineligibleUsersIds.length !== 0;

    const personNodes = persons.map(person => {
      const {
        userId,
        personShortFullName,
        isAdult,
        advancementStatus,
        isLeader,
        isParent,
      } = person;
      const isAllowedToRemove = personsNotAllowedToRemove
        ? !personsNotAllowedToRemove.includes(userId)
        : true;
      return (
        <PersonItem
          key={userId}
          personName={personShortFullName}
          isAdult={isAdult}
          isLeader={isLeader}
          isParent={isParent}
          color={ineligibleUsersIds.includes(userId) ? 'warn' : undefined}
          showAdvStatus={showAdvStatus}
          advancementStatus={advancementStatus}
          className={
            typeof personClassName === 'function'
              ? personClassName(person)
              : personClassName
          }
        >
          {isAllowedToRemove && allowRemove && (
            <CloseIcon
              className={styles.deselect}
              onClick={() => onRemove && onRemove(userId, person)}
            />
          )}
        </PersonItem>
      );
    });

    const labelId =
      type === 'youth'
        ? 'progress.common.PersonsListDesktop.youth'
        : 'progress.common.PersonsListDesktop.adults';
    title = title || <FormattedMessage id={labelId} />;
    warningMessage = warningMessage || (
      <FormattedMessage id="progress.common.PersonsListDesktop.warning.placeholder" />
    );

    return (
      <div className={cn(styles.container, className)}>
        <div className={styles.header}>
          <T.Responsive>{title}</T.Responsive>
          <S size="6">
            {hasErrors ? (
              <WarningMessage>{warningMessage}</WarningMessage>
            ) : (
              <FormattedMessage
                id={`progress.common.PersonsListDesktop.${advStatus}`}
                values={{ eligible: numOfPersons, total: eligibleCount }}
              />
            )}
          </S>
        </div>
        <div className={styles.list} id={listId}>
          {personNodes}
        </div>
      </div>
    );
  },
);

PersonsListDesktop.propTypes = {
  personsNotAllowedToRemove: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  type: PropTypes.oneOf(['youth', 'adults']),
  persons: PropTypes.array.isRequired,
  selectedAdvStatus: PropTypes.string,
  ineligibleUsersIds: PropTypes.array,
  title: PropTypes.string,
  warningMessage: PropTypes.node,
  eligibleCount: PropTypes.number.isRequired,
  allowRemove: PropTypes.bool.isRequired,
  className: PropTypes.string,
  listId: PropTypes.string,
  onRemove: PropTypes.func,
  personClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  showAdvStatus: PropTypes.bool,
};

PersonsListDesktop.defaultProps = {
  ineligibleUsersIds: [],
};

export default PersonsListDesktop;
