export const tagTypes = {
  UnitPendingLeadershipPositions: 'UnitPendingLeadershipPositions',
  UnitLeadershipPositions: 'UnitLeadershipPositions',
  YouthRankVersionDetails: 'YouthRankVersionDetails',
  AdventureUserRequirements: 'AdventureUserRequirements',
  MeritBadgeUserRequirements: 'MeritBadgeUserRequirements',
  OrganizationProfile: 'OrganizationProfile',
  OrganizationAdvDashboard: 'OrganizationAdvDashboard',
  RankDetails: 'RankDetails',
  RankRequirements: 'RankRequirements',
  AdventureRequirements: 'RankRequirements',
  YouthLeadershipPositions: 'YouthLeadershipPositions',
  YouthRankDetails: 'YouthRankDetails',
  YouthRankRequirements: 'YouthRankRequirements',
  PersonRenewalRelationships: 'PersonRenewalRelationships',
  PersonProfile: 'PersonProfile',
  AdvancementHistory: 'AdvancementHistory',
  PaymentLogSummary: 'PaymentLogSummary',
  // Collections
  Adventure: 'Adventure',
  Rank: 'Rank',
  Event: 'Event',
  UnitEvents: 'UnitEvents',
  YouthMeritBadge: 'YouthMeritBadge',
  UnitSearchResult: 'UnitSearch',
} as const;
