import { advancementStatuses } from '../constants';

const { STARTED, COMPLETED, COUNSELOR_APPROVED, APPROVED, AWARDED } =
  advancementStatuses;

const getStarted = ({ dateStarted }) => dateStarted;

/**
 *
 * @param {object} param
 * @param {string | null} [param.dateCompleted]
 * @param {string | null} [param.dateEarned]
 * @param {string | null} [param.dateCompletedEarned]
 * @param {string | null} [param.markedCompletedDate]
 * @returns
 */
const getCompleted = ({
  dateCompleted,
  dateEarned,
  dateCompletedEarned,
  markedCompletedDate,
}) => dateCompleted || dateEarned || dateCompletedEarned || markedCompletedDate;

const getApproved = ({ leaderApprovedDate }) => leaderApprovedDate;

const getAwarded = ({ awardedDate, dateAwarded }) => awardedDate || dateAwarded;

const getByStatus = (advancement, status) => {
  switch (status) {
    case STARTED: {
      return getStarted(advancement);
    }
    case COMPLETED:
    case COUNSELOR_APPROVED: {
      return getCompleted(advancement);
    }
    case APPROVED: {
      return getApproved(advancement);
    }
    case AWARDED: {
      return getAwarded(advancement);
    }
    default: {
      return '';
    }
  }
};

export default {
  getByStatus,
  getStarted,
  getCompleted,
  getApproved,
  getAwarded,
};
