import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InfoIcon from '@material-ui/icons/Info';

import styles from './CheckedIcon.less';

const CheckedIcon = ({ checked }) => {
  const icon =
    checked === true ? (
      <CheckCircleIcon className={styles.checked} />
    ) : checked === false ? (
      <InfoIcon className={styles.notChecked} />
    ) : (
      <RemoveCircleIcon className={styles.indeterminate} />
    );

  return icon;
};

CheckedIcon.propTypes = {
  checked: PropTypes.bool,
};

export default CheckedIcon;
