import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Hr from '../Hr';
import styles from './FabDivider.less';

const FabDivider = ({ className }) => (
  <Hr className={cn(styles.divider, className)} />
);

FabDivider.propTypes = {
  className: PropTypes.string,
};

export default FabDivider;
