import React from 'react';

import PropTypes from 'prop-types';

import { sorterPropTypes } from '../../constants';
import { customPropTypes } from '../../utils';
import SortOption from './SortOption';

class SortSelect extends React.Component {
  handleOptionClick = name => {
    const { sorter, onChange } = this.props;
    const isSameField = sorter.columnKey === name;
    const isDescend = sorter.order === 'descend';

    onChange({
      columnKey: isSameField && isDescend ? null : name,
      order: isSameField ? (isDescend ? null : 'descend') : 'ascend',
    });
  };

  renderOptions = () => {
    const { sorter, children } = this.props;

    return React.Children.toArray(children)
      .filter(child => child)
      .map(option => {
        const order =
          sorter.columnKey === option.props.name ? sorter.order : null;

        return React.cloneElement(option, {
          order,
          onClick: this.handleOptionClick,
        });
      });
  };

  render() {
    return <div>{this.renderOptions()}</div>;
  }
}

SortSelect.propTypes = {
  sorter: sorterPropTypes,
  className: PropTypes.string,
  children: customPropTypes.nodeOfTypes(SortOption),
  onChange: PropTypes.func.isRequired,
};

SortSelect.defaultProps = {
  sorter: {},
};

SortSelect.Option = SortOption;

export default SortSelect;
