import React from 'react';

// TODO: this is a temporary workaround for eslint until bsa-ui is fixed

/* eslint-disable import/named */
import { Select as BsaSelect } from 'bsa-ui';
import cn from 'classnames';
import toArray from 'lodash/toArray';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { intl } from '../../../localization';
import BasicSelectMobile from '../../BasicSelectMobile';
import Button from '../../Button';
import Modal from '../../Modal';
import SearchInput from '../../SearchInput';
import styles from './SelectMobile.less';

class SelectMobile extends React.PureComponent {
  state = {
    isOpen: false,
    searchQuery: '',
  };

  isMultipleSelect = this.props.mode === 'multiple';

  handleOpenSelector = () => {
    const { disabled, onFocus } = this.props;
    if (!disabled) {
      this.setState({ isOpen: true });

      if (onFocus) {
        onFocus();
      }
    }
  };

  handleCloseSelector = () => {
    const { onBlur } = this.props;
    this.closeModal();
    if (onBlur) {
      onBlur();
    }
  };

  handleSelectOption =
    (selectedValue, handleChildClick = undefined) =>
    () => {
      const {
        onSelect,
        onChange,
        value: currentValue,
        keepOpenOnSelect,
      } = this.props;
      handleChildClick && handleChildClick();
      let inputValue = selectedValue;
      if (this.isMultipleSelect) {
        inputValue = Array.isArray(currentValue)
          ? [...new Set([...currentValue, selectedValue])]
          : [selectedValue];
      }
      onSelect && onSelect(selectedValue);
      onChange && onChange(inputValue);
      if (!keepOpenOnSelect) {
        this.closeModal();
      }
    };

  handleSearchItems = searchQuery => {
    const { onSearch } = this.props;
    onSearch && onSearch(searchQuery);
    this.setState({ searchQuery });
  };

  closeModal = () => {
    const { onModalClose } = this.props;
    this.setState({ isOpen: false, searchQuery: '' });
    onModalClose && onModalClose();
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  defaultFilterFn = (input, child) => {
    // Copied from React-Component Select
    // https://github.com/react-component/select/blob/master/src/util.js#L157
    if (child.props.disabled) {
      return false;
    }
    const value = toArray(
      this.getPropValue(child, this.props.optionFilterProp),
    ).join('');
    return value.toLowerCase().includes(input.toLowerCase());
  };

  getPropValue = (child, prop) => {
    // Copied from React-Component Select
    // https://github.com/react-component/select/blob/master/src/util.js#L30
    if (prop === 'value') {
      return this.getValuePropValue(child);
    }
    return child.props[prop];
  };

  getValuePropValue = child => {
    // Copied from React-Component Select
    // https://github.com/react-component/select/blob/master/src/util.js#L10
    if (!child) {
      return null;
    }

    const { value, key, label } = child.props;
    if (value) {
      return value;
    }
    if (key) {
      return key;
    }
    if (child.type && child.type.isSelectOptGroup && label) {
      return label;
    }
    throw new Error(
      `Need at least a key or a value or a label (only for OptGroup) for ${child}`,
    );
  };

  filterOptions = (options, optionfilter = this.defaultFilterFn) => {
    const { searchQuery } = this.state;
    return React.Children.toArray(options).filter(option => {
      if (!optionfilter) {
        return true;
      } else if (typeof optionfilter === 'function') {
        return optionfilter(searchQuery, option);
      }
      return !option.props.disabled;
    });
  };

  renderOptionsFromChildren = () => {
    const { children } = this.props;

    const optionsNodes = React.Children.toArray(children).map(child =>
      child.type.isSelectOptGroup
        ? this.renderOptionGroups(child)
        : this.renderOptionsItems(child),
    );

    return optionsNodes;
  };

  renderOptionsItems = (childrenOptions, areGroupChildren = false) => {
    const { filterOption, value } = this.props;
    const options = this.filterOptions(
      childrenOptions,
      typeof filterOption === 'boolean' && filterOption
        ? undefined
        : filterOption,
    );

    return options.map(child => {
      if (!child) {
        return;
      }
      const childValue = child.props.value;
      const childContent = child.props.children;
      const childClassName = child.props.className;
      const isSelectedOption = this.isMultipleSelect
        ? (value || []).includes(childValue)
        : childValue === value;
      const onChildClick = child.props.onClick;
      return (
        <li
          key={childValue}
          className={cn(
            styles.optionItem,
            {
              [styles.selectedOption]: isSelectedOption,
              [styles.groupChild]: areGroupChildren,
            },
            childClassName,
          )}
          onClick={this.handleSelectOption(childValue, onChildClick)}
        >
          {childContent}
        </li>
      );
    });
  };

  renderOptionGroups(group) {
    const { key, props } = group;
    const { label, children } = props;

    return (
      <React.Fragment key={key}>
        <li className={cn(styles.optionItem, styles.optionGroupTitle)}>
          {label}
        </li>
        {this.renderOptionsItems(children, true)}
      </React.Fragment>
    );
  }

  render() {
    const { label, keepOpenOnSelect } = this.props;
    const { isOpen, searchQuery } = this.state;

    return (
      <React.Fragment>
        <BasicSelectMobile {...this.props} onFocus={this.handleOpenSelector} />
        <Modal
          mask={false}
          visible={isOpen}
          onCancel={this.handleCloseSelector}
          className={styles.modal}
          zIndex={1070}
        >
          <div className={styles.header}>
            <div className={styles.headerRow}>
              {label ? (
                label
              ) : (
                <FormattedMessage id="shared.Select.selectOption" />
              )}
              <Button
                onClick={this.handleCloseSelector}
                type="primary"
                color="scouting-red"
                fitText
                uppercase
              >
                <FormattedMessage
                  id={`shared.Select.${keepOpenOnSelect ? 'close' : 'cancel'}`}
                />
              </Button>
            </div>
            <div className={styles.headerRow}>
              <SearchInput
                className={styles.searchInput}
                onChange={this.handleSearchItems}
                value={searchQuery}
                size="large"
                placeholder={intl.formatMessage({ id: 'shared.Select.search' })}
              />
            </div>
          </div>

          <ul className={styles.optionsList}>
            {this.renderOptionsFromChildren()}
          </ul>
        </Modal>
      </React.Fragment>
    );
  }
}

SelectMobile.propTypes = {
  ...BsaSelect.propTypes,
  label: PropTypes.oneOf(PropTypes.node, PropTypes.string),
  keepOpenOnSelect: PropTypes.bool,
};

SelectMobile.defaultProps = {
  optionFilterProp: 'value',
};

export default SelectMobile;
