import { activitiesModuleNamespace as namespace } from '../../common';

export const ACTIVITY_LOGS_REQUEST = `${namespace}/ACTIVITY_LOGS_REQUEST`;
export const ACTIVITY_LOGS_RESPONSE = `${namespace}/ACTIVITY_LOGS_RESPONSE`;
export const ACTIVITY_LOGS_ERROR = `${namespace}/ACTIVITY_LOGS_ERROR`;
export const activityLogsRequest = ({
  personGuid,
  activityTypeId,
  userId,
}) => ({
  type: ACTIVITY_LOGS_REQUEST,
  payload: {
    personGuid,
    activityTypeId,
    userId,
  },
});
export const activityLogsResponse = payload => ({
  type: ACTIVITY_LOGS_RESPONSE,
  payload,
});
export const activityLogsError = error => ({
  type: ACTIVITY_LOGS_ERROR,
  payload: error,
});

export const REMOVE_CURRENT_LOGS_INFO = `${namespace}/REMOVE_CURRENT_LOGS_INFO`;
export const removeCurrentLogsInfo = () => ({
  type: REMOVE_CURRENT_LOGS_INFO,
});

export const REMOVE_PARTICIPANT = `${namespace}/REMOVE_PARTICIPANT`;
export const removeParticipant = ({
  activityId,
  activityRecords,
  userId,
  isPersonalActivity,
  onRemoveSuccess,
}) => ({
  type: REMOVE_PARTICIPANT,
  payload: {
    activityId,
    activityRecords,
    userId,
    isPersonalActivity,
    onRemoveSuccess,
  },
});

export const REMOVE_PARTICIPANT_RESPONSE = `${namespace}/REMOVE_PARTICIPANT_RESPONSE`;
export const removeParticipantResponse = ({ userId, activityId }) => ({
  type: REMOVE_PARTICIPANT_RESPONSE,
  payload: { userId, activityId },
});

export const SHOW_REMOVE_PARTICIPANT_MODAL = `${namespace}/SHOW_REMOVE_PARTICIPANT_MODAL`;
export const showRemoveParticipantModal = (show = true) => ({
  type: SHOW_REMOVE_PARTICIPANT_MODAL,
  payload: show,
});

export const SET_ACTIVITY_TO_DELETE = `${namespace}/SET_ACTIVITY_TO_DELETE`;
export const setActivityToDelete = (activity = null) => ({
  type: SET_ACTIVITY_TO_DELETE,
  payload: activity,
});

export const CLEAR_PEOPLE = `${namespace}/CLEAR_PEOPLE`;
export const clearPeople = () => ({
  type: CLEAR_PEOPLE,
});

// create activity form actions

export const SELECT_PERSON = `${namespace}/SELECT_PERSON`;
export const selectPerson = userId => ({
  type: SELECT_PERSON,
  payload: userId,
});

export const SELECT_MULTIPLE_PERSONS = `${namespace}/SELECT_MULTIPLE_PERSONS`;
export const selectMultiplePerson = userIds => ({
  type: SELECT_MULTIPLE_PERSONS,
  payload: userIds,
});

export const DESELECT_PERSON = `${namespace}/DESELECT_PERSON`;
export const deselectPerson = userId => ({
  type: DESELECT_PERSON,
  payload: userId,
});

export const SET_ACTIVE_TAB = `${namespace}/SET_ACTIVE_TAB`;
export const setActiveTab = activeTab => ({
  type: SET_ACTIVE_TAB,
  payload: activeTab,
});

export const SAVE_ACTIVITY_REQUEST = `${namespace}/SAVE_ACTIVITY_REQUEST`;
export const saveActivityRequest = (
  activityTypeId,
  data,
  isCalendarActivity,
  onSuccess,
  onFailure,
) => ({
  type: SAVE_ACTIVITY_REQUEST,
  payload: {
    activityTypeId,
    data,
    isCalendarActivity,
    onSuccess,
    onFailure,
  },
});

export const SAVE_ACTIVITY_RESPONSE = `${namespace}/SAVE_ACTIVITY_RESPONSE`;
export const saveActivityResponse = payload => ({
  type: SAVE_ACTIVITY_RESPONSE,
  payload,
});

export const SAVE_ACTIVITY_ERROR = `${namespace}/SAVE_ACTIVITY_ERROR`;
export const saveActivityError = err => ({
  type: SAVE_ACTIVITY_ERROR,
  payload: err,
});

// edit activity actions

export const GET_ACTIVITY_BY_ID_REQUEST = `${namespace}/GET_ACTIVITY_BY_ID`;
export const getActivityByIdRequest = (activityId, clone = false) => ({
  type: GET_ACTIVITY_BY_ID_REQUEST,
  payload: {
    activityId,
    clone,
  },
});

export const GET_ACTIVITY_BY_ID_RESPONSE = `${namespace}/GET_ACTIVITY_BY_ID_RESPONSE`;
export const getActivityByIdResponse = payload => ({
  type: GET_ACTIVITY_BY_ID_RESPONSE,
  payload,
});

export const GET_ACTIVITY_BY_ID_ERROR = `${namespace}/GET_ACTIVITY_BY_ID_ERROR`;
export const getActivityByIdError = err => ({
  type: GET_ACTIVITY_BY_ID_ERROR,
  payload: err,
});

export const SET_SELECTED_ACTIVITY_DATA = `${namespace}/SET_SELECTED_ACTIVITY_DATA`;
export const setSelectedActivityData = payload => ({
  type: SET_SELECTED_ACTIVITY_DATA,
  payload,
});

export const ACTIVITY_LOGS_SEARCH = `${namespace}/ACTIVITY_LOGS_SEARCH`;
export const activityLogSearch = search => ({
  type: ACTIVITY_LOGS_SEARCH,
  payload: search,
});
