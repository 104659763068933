import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import {
  SET_EDIT_PROFILE,
  SAVE_PROFILE_REQUEST,
  SAVE_PROFILE_RESPONSE,
  SAVE_PROFILE_ERROR,
  SET_EDIT_UNIT,
  SAVE_UNIT_REQUEST,
  SAVE_UNIT_ERROR,
  SAVE_UNIT_RESPONSE,
  SAVE_UNIT_DEFERRED,
  UDPATE_UNIT_PREVIEW,
  SAVE_AKELA_PROFILE_REQUEST,
  SAVE_AKELA_PROFILE_ERROR,
  SAVE_AKELA_PROFILE_RESPONSE,
} from './actions';
import { displayOptions } from '../constants';

const profileEditMode = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EDIT_PROFILE: {
      return payload;
    }
    case SAVE_PROFILE_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const profileSaveLoading = (state = false, { type }) => {
  switch (type) {
    case SAVE_PROFILE_REQUEST: {
      return true;
    }
    case SAVE_PROFILE_RESPONSE:
    case SAVE_PROFILE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isSavingProfile = (state = false, { type }) => {
  switch (type) {
    case SAVE_AKELA_PROFILE_REQUEST: {
      return true;
    }
    case SAVE_AKELA_PROFILE_ERROR:
    case SAVE_AKELA_PROFILE_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitEditMode = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EDIT_UNIT: {
      return payload;
    }
    case SAVE_UNIT_RESPONSE: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const unitSaveLoading = (state = false, { type }) => {
  switch (type) {
    case SAVE_UNIT_REQUEST: {
      return true;
    }
    case SAVE_UNIT_RESPONSE:
    case SAVE_UNIT_ERROR:
    case SAVE_UNIT_DEFERRED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitPreview = (state = {}, { type, payload }) => {
  switch (type) {
    case UDPATE_UNIT_PREVIEW: {
      const {
        address,
        contact,
        website,
        unitPack,
        unitDescription,
        acceptsOnline,
      } = payload;
      return {
        address,
        contact,
        website,
        unitPack,
        unitDescription,
        acceptsOnline,
      };
    }
    default: {
      return state;
    }
  }
};

const unitDisplayOptions = (state = [], { type, payload }) => {
  switch (type) {
    case UDPATE_UNIT_PREVIEW: {
      const { unitAddress, primaryContactInfo, additionalInfo } =
        payload.display;
      return [
        ...(unitAddress ? [displayOptions.ADDRESS] : []),
        ...(primaryContactInfo ? [displayOptions.PRIMARY_CONTACT] : []),
        ...(additionalInfo ? [displayOptions.ADDITIONAL_INFO] : []),
      ];
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  profileEditMode,
  profileSaveLoading,
  isSavingProfile,
  unitEditMode,
  unitSaveLoading,
  unitPreview,
  unitDisplayOptions,
});

export default resetOnLogoutReducer(reducer);
