import React from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import CalendarIcon from '@material-ui/icons/Today';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import { intl } from '../../../localization';
import BasicSelectMobile from '../../BasicSelectMobile';
import Button from '../../Button';
import BaseDatePicker from './BaseDatePicker';
import styles from './DatePicker.less';

const formatDate = (date, format) =>
  date ? moment(date).format(format) : undefined;

class DatePicker extends React.PureComponent {
  state = { value: null };

  handleChange = value => {
    this.setState({ value });
  };

  handleOpenCalendar = () => {
    if (this.props.onOpenChange) {
      this.props.onOpenChange(true);
    }
  };

  handleClear = event => {
    event.stopPropagation();
    this.setState({ value: undefined });

    if (this.props.onChange) {
      this.props.onChange(undefined);
    }
  };

  handleConfirm = () => {
    if (this.props.onChange) {
      const { format, value } = this.props;
      const date = this.state.value || value;
      date && this.props.onChange(moment(date), formatDate(date, format));
    }
    this.handleCloseCalendar();
  };

  handleCloseCalendar = () => {
    this.handleChange();
    if (this.props.onOpenChange) {
      this.props.onOpenChange(false);
    }
  };

  render() {
    const {
      disabled,
      value,
      format,
      size,
      placeholder,
      defaultValue,
      className,
      allowClear,
      ...rest
    } = this.props;
    const selectedDate = this.state.value || value || defaultValue;
    const sufixIcon = !(value && allowClear) && (
      <CalendarIcon
        className={cn('ant-calendar-picker-icon', styles.calendarIcon)}
      />
    );

    return (
      <React.Fragment>
        <span className={cn(styles.inputWrapper, className)}>
          <BasicSelectMobile
            disabled={disabled}
            value={formatDate(selectedDate, format)}
            size={size}
            placeholder={
              placeholder ||
              intl.formatMessage({ id: 'shared.DatePicker.placeholder' })
            }
            className={styles.dateInput}
            suffixIcon={sufixIcon}
            onFocus={this.handleOpenCalendar}
          />
          {value && allowClear && (
            <Button
              shape="circle"
              type="ghost"
              className={styles.clearButton}
              onClick={this.handleClear}
            >
              <ClearIcon className={styles.clearIcon} />
            </Button>
          )}
        </span>
        <BaseDatePicker
          onDismiss={this.handleCloseCalendar}
          onConfirm={this.handleConfirm}
          selected={selectedDate && moment(selectedDate).toDate()}
          onSelect={this.handleChange}
          {...rest}
        />
      </React.Fragment>
    );
  }
}

DatePicker.propTypes = {
  ...BaseDatePicker.propTypes,
  onOpenChange: PropTypes.func,
  onChange: PropTypes.func,
  format: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default DatePicker;
