import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Card, S } from '@shared';
import YouthListItem from './YouthListItem';
import styles from './YouthList.less';

class YouthList extends React.PureComponent {
  handleSelectYouth = userId => () => {
    this.props.onSelectYouth(userId);
  };

  render() {
    const { youths, selectedYouthId, itemCount, isMobile } = this.props;
    const youthNodes = youths.map(
      ({ userId, personShortFullName, advancements }) => (
        <YouthListItem
          key={userId}
          personName={personShortFullName}
          advancements={advancements}
          isSelected={selectedYouthId == userId}
          isMobile={isMobile}
          onSelect={this.handleSelectYouth(userId)}
        />
      ),
    );

    const content = (
      <React.Fragment>
        <div className={styles.titleContainer}>
          <S size="4" bold colored colon>
            <FormattedMessage id="offline.SyncPendingModal.youths.title" />
          </S>
          <S size="6" className={styles.advancementsCount}>
            <FormattedMessage
              id="offline.SyncPendingModal.youths.itemsLeft"
              values={{ value: itemCount }}
            />
          </S>
        </div>

        <div className={styles.list}>{youthNodes}</div>
      </React.Fragment>
    );

    if (isMobile) {
      return (
        <Card margin shadow className={styles.container}>
          {content}
        </Card>
      );
    }
    return <div className={styles.container}>{content}</div>;
  }
}

YouthList.propTypes = {
  youths: PropTypes.array.isRequired,
  selectedYouthId: PropTypes.number.isRequired,
  itemCount: PropTypes.number,
  isMobile: PropTypes.bool,
  onSelectYouth: PropTypes.func,
};

export default YouthList;
