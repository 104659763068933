import React from 'react';
import PropTypes from 'prop-types';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import Select from '../Select';

const { Option } = Select;

class CountrySelect extends React.PureComponent {
  handleChange = value => {
    const { onChange } = this.props;
    onChange(value);
  };

  render() {
    const {
      disabled,
      iconComponent: Icon,
      name,
      options,
      size,
      tabIndex,
      unicodeFlags,
      value,
    } = this.props;
    const selectedOption =
      options.filter(option => option.value === value)[0] || options[0];

    const optionNodes = options.map(({ value = '-', label }) => (
      <Option key={value} value={value} text={label}>
        {label}
      </Option>
    ));

    return (
      <div className="PhoneInputCountry">
        <Select
          className="PhoneInputCountrySelect"
          defaultValue={value}
          disabled={disabled}
          name={name}
          onChange={this.handleChange}
          onSelect={this.handleCloseSelect}
          optionFilterProp="text"
          showSearch
          size={size}
          tabIndex={tabIndex}
          value={value}
        >
          {optionNodes}
        </Select>
        {unicodeFlags && value && (
          <div className="PhoneInputCountryIconUnicode">
            {getUnicodeFlagIcon(value)}
          </div>
        )}
        {!(unicodeFlags && value) && (
          <Icon
            aria-hidden
            aspectRatio={unicodeFlags ? 1 : undefined}
            country={value}
            label={selectedOption && selectedOption.label}
          />
        )}
        <div className="PhoneInputCountrySelectArrow" />
      </div>
    );
  }
}

CountrySelect.propTypes = {
  disabled: PropTypes.bool,
  iconComponent: PropTypes.elementType,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.oneOf(['default', 'large']),
  tabIndex: PropTypes.number,
  unicodeFlags: PropTypes.bool,
  value: PropTypes.string,
};

CountrySelect.defaultProps = {
  value: 'US',
  open: false,
};

export default CountrySelect;
