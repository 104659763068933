import { ROUTE_ROLES } from './duck';
import { RolesPage } from './components';

const routes = {
  [ROUTE_ROLES]: {
    path: '/user-permissions',
    Component: RolesPage,
    titleId: 'page.userPermissions',
  },
};

export default routes;
