import { combineReducers } from 'redux';

import { DEFAULT_TABLE_PAGESIZE } from '../constants';
import {
  CASS_RESPONSE,
  SET_IS_LOADING_DELETE_EMAIL_MODAL,
  SET_SHOW_DELETE_ADDRESS_MODAL,
  SET_SHOW_DELETE_EMAIL_MODAL,
  SET_SHOW_DELETE_PHONE_MODAL,
  SET_TABLE_PAGE_SIZE,
  SET_USER_PREFERENCES,
  TRIGGER_ALL_ROSTERS_REQUESTS,
  TRIGGER_CALENDAR_REQUESTS,
} from './actions';

const tablePageSize = (state = DEFAULT_TABLE_PAGESIZE, { type, payload }) => {
  switch (type) {
    case SET_TABLE_PAGE_SIZE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const userPreferences = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_USER_PREFERENCES: {
      const { preferences, featureName } = payload;
      return {
        ...state,
        [featureName]: preferences,
      };
    }
    default: {
      return state;
    }
  }
};

const showDeleteEmailModal = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SHOW_DELETE_EMAIL_MODAL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showDeleteAddressModal = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SHOW_DELETE_ADDRESS_MODAL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showDeletePhoneModal = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SHOW_DELETE_PHONE_MODAL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingDeleteEmailModal = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_LOADING_DELETE_EMAIL_MODAL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const cassResults = (state = [], res) => {
  const { type, payload } = res;
  switch (type) {
    case CASS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const triggerCalendarRequest = (state = true, { type, payload }) => {
  switch (type) {
    case TRIGGER_CALENDAR_REQUESTS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const triggerAllRostersRequest = (state = true, { type, payload }) => {
  switch (type) {
    case TRIGGER_ALL_ROSTERS_REQUESTS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducers = combineReducers({
  showDeleteEmailModal,
  showDeleteAddressModal,
  showDeletePhoneModal,
  isLoadingDeleteEmailModal,
  tablePageSize,
  userPreferences,
  cassResults,
  triggerCalendarRequest,
  triggerAllRostersRequest,
});

export default reducers;
