import React from 'react';

import { RecordAdvancement } from '../advancement';
import {
  ApproveAdvancementsModal,
  DeclineMultipleAdvancementModal,
} from '../approveAdvancements';
import { Campouts } from '../campouts/components';
import { DeclineAdvancementModal } from '../declineAdvancements';
import { EagleProjectModal } from '../eagleProject';
import { EagleScoutApplicationModal } from '../eagleScoutApplication';
import { EditAdvancementModal } from '../editAdvancement';
import { HikeModal } from '../hikes';
import { LongCruiseModal } from '../longCruise';
import { ServiceModal } from '../service';
import { UnapproveAdvancementModal } from '../unapproveAdvancement';

const ProgressModals = () => (
  <React.Fragment>
    <EagleScoutApplicationModal />
    <RecordAdvancement />
    <ServiceModal />
    <Campouts />
    <EagleProjectModal />
    <HikeModal />
    <EditAdvancementModal />
    <UnapproveAdvancementModal />
    <ApproveAdvancementsModal />
    <DeclineAdvancementModal />
    <DeclineMultipleAdvancementModal />
    <LongCruiseModal />
  </React.Fragment>
);

export default ProgressModals;
