import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Card, ReturnButton, S } from '@shared/components';

import styles from './Notifications.less';
import NotificationsContent from './NotificationsContent';

const NotificationsModalContent: React.FC<{ onClose: () => void }> = props => {
  const { onClose } = props;

  return (
    <div>
      <ReturnButton modal onClick={onClose}>
        <FormattedMessage id="shared.back" />
      </ReturnButton>
      <div className={styles.modalContentWrapper}>
        <S size="3" bold colored className={styles.modalContentTitle}>
          <FormattedMessage id="userNotifications.NotificationsModalContent.title" />
        </S>
        <Card shadow>
          <NotificationsContent />
        </Card>
      </div>
    </div>
  );
};

export default NotificationsModalContent;
