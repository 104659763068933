import React from 'react';

import ContentLoader from 'react-content-loader';

import { PagePlaceholder, TablePlaceholder } from '@shared/components';

import styles from './ReportsPagePlaceholder.less';

const ReportsPagePlaceholder = () => (
  <PagePlaceholder className={styles.container}>
    <ContentLoader width={240} height={100} className={styles.header}>
      <rect x="0" y="0" rx="3" ry="3" width="120" height="20" />
    </ContentLoader>
    <TablePlaceholder />
  </PagePlaceholder>
);

export default ReportsPagePlaceholder;
