import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../Button';
import styles from './ButtonDrawerItem.less';

class ButtonDrawerItem extends React.PureComponent {
  render() {
    const { className, children, icon, ...rest } = this.props;
    const classNames = cn(styles.button, className);

    return (
      <Button className={classNames} {...rest}>
        {icon && <div className={styles.iconWrapper}>{icon}</div>}
        {children}
      </Button>
    );
  }
}

ButtonDrawerItem.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
};

ButtonDrawerItem.defaultProps = {
  size: 'small',
  color: 'scouting-warm-gray',
  fitText: true,
  ghost: true,
  noBorder: true,
  uppercase: false,
};

export default ButtonDrawerItem;
