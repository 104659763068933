import { esbApiService } from '@utils';

const updateEagleActivity$ = (id, data) =>
  esbApiService.put$(`/advancements/v2/activities/${id}`, data, {
    gtm: {
      label: '/advancements/activities/{id}',
    },
  });

const updateEagleParticipants$ = (activityId, payload) =>
  esbApiService.put$(
    `/advancements/activities/${activityId}/registeredParticipants`,
    payload,
    {
      gtm: {
        label: '/advancements/activities/{activityId}/registeredParticipants',
      },
    },
  );

export default {
  updateEagleActivity$,
  updateEagleParticipants$,
};
