import { moduleName } from '../constants';

export { APP_BOOTSTRAP_DONE, MODAL_OPEN, MODAL_CLOSE } from '@shared';

export const SET_HEADER_VISIBLE = `${moduleName}/SET_HEADER_VISIBLE`;
export const setHeaderVisible = hidden => ({
  type: SET_HEADER_VISIBLE,
  payload: hidden,
});

export const SET_SIDEBAR_OPEN = `${moduleName}/SET_SIDEBAR_OPEN`;
export const setSidebarOpen = open => ({
  type: SET_SIDEBAR_OPEN,
  payload: open,
});

export const SET_SIDEBAR_EXPANDED = `${moduleName}/SET_SIDEBAR_EXPANDED`;
export const setSidebarExpanded = expanded => ({
  type: SET_SIDEBAR_EXPANDED,
  payload: expanded,
});

export const SET_UPDATE_APP = `${moduleName}/SET_UPDATE_APP`;
export const setUpdateApp = () => ({
  type: SET_UPDATE_APP,
});

export const SET_UPDATING_APP = `${moduleName}/SET_UPDATING_APP`;
export const setUpdatingApp = () => ({
  type: SET_UPDATING_APP,
});
