import { lazier } from '@shared/components';

import ReportsPagePlaceholder from './ReportsPagePlaceholder';

export { ReportsPagePlaceholder };

export const ReportsPage = lazier(
  () => import('./ReportsPage'),
  ReportsPagePlaceholder,
);

export { default as ReportParamsModal } from './ReportParamsModal';
