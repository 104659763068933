export const createModuleNameSpace = (path: string | string[]) =>
  typeof path === 'string' ? path : path.join('/');

export function getRandom() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const crypto = window.crypto || (window as unknown as any).msCrypto;
  if (!crypto) return Math.random();

  const array = new Uint32Array(1);
  const random = crypto.getRandomValues(array)[0];

  return random;
}
