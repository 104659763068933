import React, { useCallback, useMemo, useState } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { SHOW_OFFLINE_FEATURES } from '@config';
import { isSeaScoutSel } from '@context/duck/selectorsTyped';
import {
  Dropdown,
  Input,
  Menu,
  OfflineTooltip,
  S,
  isScoutbookRoleSel,
} from '@shared';
import { canRecordProgressForSelectedItemsSel } from '@shared/duck/selectors/roster.selectors';

import {
  CampoutsButton,
  RecordAdvancementButton,
  RecordHikeMilesButton,
  RecordLongCruiseButton,
  ServiceHoursButton,
} from '../../../../../../progress/components';
import { SELECTABLE_PEOPLE_LIMIT } from '../../../../constants';
import { isMissingSomeMemberIdSel } from '../../../../duck';
import { checkIsConfirmed } from '../../../../helpers';
import styles from './RecordProgressDropdown.less';

const MenuItem = Menu.Item;
const MenuDivider = Menu.Divider;

const RecordProgressDropdown = ({
  disabled,
  selectedItems,
  recordProgressEnabled,
}) => {
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [visible, setVisible] = useState(false);
  const disableCreateActivity = useSelector(isMissingSomeMemberIdSel);
  const isScoutbookRole = useSelector(isScoutbookRoleSel);
  const showLongCruise = useSelector(isSeaScoutSel);
  const canRecordProgressForSelectedItems = useSelector(
    canRecordProgressForSelectedItemsSel,
  );

  const askForConfirmation = selectedItems.length > SELECTABLE_PEOPLE_LIMIT;

  const handleChange = useCallback(
    event => {
      const { value: inputText } = event.target;
      if (checkIsConfirmed(inputText)) {
        setDisableConfirm(false);
      } else {
        setDisableConfirm(true);
      }
    },
    [setDisableConfirm],
  );

  const confirmationMessage = useMemo(
    () => (
      <React.Fragment>
        <FormattedMessage
          id="advancement.PackRoster.confirmSelection"
          values={{ records: selectedItems.length }}
        />
        <Input type="text" onChange={handleChange} />
      </React.Fragment>
    ),
    [selectedItems, handleChange],
  );

  const handleVisibleChange = useCallback(
    visible => setVisible(visible),
    [setVisible],
  );

  const disableConfirmBtn = useCallback(() => {
    setDisableConfirm(true);
    handleVisibleChange(false);
  }, [setDisableConfirm, handleVisibleChange]);

  const handleButtonClick = () => handleVisibleChange(false);

  const showRecordAdvancement =
    !isScoutbookRole || canRecordProgressForSelectedItems;

  const buttons = (
    <Menu>
      {[].concat(
        [
          showRecordAdvancement ? (
            <MenuItem key="advancement">
              <RecordAdvancementButton
                userIds={selectedItems}
                className={cn(
                  '.Joyride__RecordAdvancementButton',
                  styles.progressButton,
                )}
                onClick={handleButtonClick}
                askForConfirmation={askForConfirmation}
                onConfirm={disableConfirmBtn}
                onCancel={disableConfirmBtn}
                disableConfirmBtn={disableConfirm}
                confirmationMessage={confirmationMessage}
              />
            </MenuItem>
          ) : null,
          showRecordAdvancement ? <MenuDivider key="advancementKey" /> : null,
          <MenuItem key="serviceHours">
            <ServiceHoursButton
              disabled={disableCreateActivity}
              userIds={selectedItems}
              className={styles.progressButton}
              onClick={handleButtonClick}
              askForConfirmation={askForConfirmation}
              onConfirm={disableConfirmBtn}
              onCancel={disableConfirmBtn}
              disableConfirmBtn={disableConfirm}
              confirmationMessage={confirmationMessage}
              disableFutureDays
            />
          </MenuItem>,
          <MenuDivider key="serviceHoursDiv" />,
          <MenuItem key="campout">
            <CampoutsButton
              disabled={disableCreateActivity}
              userIds={selectedItems}
              className={styles.progressButton}
              onClick={handleButtonClick}
              askForConfirmation={askForConfirmation}
              onConfirm={disableConfirmBtn}
              onCancel={disableConfirmBtn}
              disableConfirmBtn={disableConfirm}
              confirmationMessage={confirmationMessage}
              disableFutureDays
            />
          </MenuItem>,
          <MenuDivider key="camputDiv" />,
          <MenuItem key="hike">
            <RecordHikeMilesButton
              disabled={disableCreateActivity}
              userIds={selectedItems}
              className={styles.progressButton}
              onClick={handleButtonClick}
              askForConfirmation={askForConfirmation}
              onConfirm={disableConfirmBtn}
              onCancel={disableConfirmBtn}
              disableConfirmBtn={disableConfirm}
              confirmationMessage={confirmationMessage}
              disableFutureDays
            />
          </MenuItem>,
        ],
        showLongCruise
          ? [
              <MenuDivider key="hikeDiv" />,
              <MenuItem key="longCruise">
                <RecordLongCruiseButton
                  disabled={disableCreateActivity}
                  userIds={selectedItems}
                  className={styles.progressButton}
                  onClick={handleButtonClick}
                  askForConfirmation={askForConfirmation}
                  onConfirm={disableConfirmBtn}
                  onCancel={disableConfirmBtn}
                  disableConfirmBtn={disableConfirm}
                  confirmationMessage={confirmationMessage}
                  disableFutureDays
                />
              </MenuItem>,
            ]
          : [],
      )}
    </Menu>
  );

  const toggleButtonClassNames = cn(styles.toggleButton, {
    [styles.disabled]: disabled,
  });
  const chevronIconClassNames = cn(styles.chevronIcon, {
    [styles.chevronIconOpen]: visible && !disabled,
  });

  const recordProgressNode = recordProgressEnabled ? (
    <Dropdown
      overlay={buttons}
      trigger={['click']}
      placement="bottomRight"
      visible={visible}
      disabled={disabled}
      onVisibleChange={handleVisibleChange}
      overlayClassName={styles.dropdownMenu}
    >
      <S
        size="4"
        inlineBlock
        className={cn(
          'Joyride__RecordProgressDropdown',
          toggleButtonClassNames,
        )}
      >
        <FormattedMessage id="packRoster.RecordProgressDropdown.recordProgress" />{' '}
        <KeyboardArrowDownIcon className={chevronIconClassNames} />
      </S>
    </Dropdown>
  ) : (
    <RecordAdvancementButton
      userIds={selectedItems}
      className={cn(
        'Joyride__RecordProgressDropdown',
        styles.progressButton,
        styles.noToggle,
      )}
      disabled={disabled}
      onClick={handleButtonClick}
    />
  );

  return (
    <OfflineTooltip placement="top" disabled={SHOW_OFFLINE_FEATURES}>
      {recordProgressNode}
    </OfflineTooltip>
  );
};

RecordProgressDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array.isRequired,
  recordProgressEnabled: PropTypes.bool.isRequired,
  showLongCruise: PropTypes.bool,
};

export default RecordProgressDropdown;
