import { isEmpty } from 'lodash';
import { replace } from 'redux-first-router';

import { querySel } from '@location';
import {
  ROUTE_BULK_ENTRY_ADVENTURE,
  ROUTE_BULK_ENTRY_MB,
} from '@modules/bulkEntry/duck/actions';
import {
  emptyObj,
  lazier,
  organizationPositionsSel,
  permissions,
  setOrganization,
} from '@shared';

import {
  ROUTE_ADVANCEMENT,
  ROUTE_PURCHASE_ORDER_DETAILS,
  ROUTE_QE_HEALTH,
  ROUTE_QE_OA,
  ROUTE_QE_RANK,
  ROUTE_QE_SCHOOL,
  ROUTE_QE_SCOUTLEADERSHIP,
  ROUTE_QE_SWIMMING,
} from './common';
import { AdvancementPage, AdvancementPagePlaceholder } from './components';

const routes = {
  [ROUTE_ADVANCEMENT]: {
    path: '/roster',
    Component: AdvancementPage,
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.roster',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const { organizationGuid = '' } = querySel(state) || emptyObj;
      const youthOrgGuid = organizationGuid;

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions)) {
        const foundPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        replace('/roster');
        if (foundPosition) {
          dispatch(
            setOrganization({
              guid: foundPosition.organizationGuid,
              programType: foundPosition.programType,
              parentOrgGuid: foundPosition.organizationGuid,
            }),
          );
        }
      }
    },
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_QE_HEALTH]: {
    path: '/roster/health',
    Component: lazier(
      () => import('./quickEntryHealth/QEHealthPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.health',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_QE_RANK]: {
    path: '/roster/bulk-entry-rank/:unitGuid',
    Component: lazier(
      () => import('../bulkEntry/components/BulkEntryRankPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.bulk-entry.rank',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_BULK_ENTRY_MB.type]: {
    path: '/roster/bulk-entry-mb/:unitGuid',
    Component: lazier(
      () => import('../bulkEntry/components/BulkMeritBadge/BulkMeritBadgePage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.bulk-entry.meritBadge',
    permission: permissions.EDIT_ADVANCEMENTS,
  },
  [ROUTE_BULK_ENTRY_ADVENTURE.type]: {
    path: '/roster/bulk-entry-adventure/:unitGuid',
    Component: lazier(
      () => import('../bulkEntry/components/BulkAdventures/BulkAdventuresPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.bulk-entry.adventure',
    permission: permissions.EDIT_ADVANCEMENTS,
  },
  [ROUTE_QE_SWIMMING]: {
    path: '/roster/swimming',
    Component: lazier(
      () => import('./quickEntrySwimming/QESwimmingPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.swimming',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_QE_SCHOOL]: {
    path: '/roster/school',
    Component: lazier(
      () => import('./quickEntrySchool/QESchoolPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.school',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_QE_SCOUTLEADERSHIP]: {
    path: '/roster/leadership',
    Component: lazier(
      () => import('./quickEntryLeadership/QELeadershipPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.leadership',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_QE_OA]: {
    path: '/roster/oa',
    Component: lazier(
      () => import('./quickEntryOA/QEOAPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.oa',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
  [ROUTE_PURCHASE_ORDER_DETAILS]: {
    path: '/roster/purchaseOrderDetails',
    Component: lazier(
      () => import('./purchaseOrder/components/PODetailsPage/PODetailsPage'),
      AdvancementPagePlaceholder,
    ),
    Placeholder: AdvancementPagePlaceholder,
    titleId: 'page.purchaseOrderDetails',
    permission: permissions.VIEW_ROSTER_PAGE,
  },
};

export default routes;
