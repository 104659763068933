import moment from 'moment';

import { modelDateFormat } from '@modules/shared/constants';
import { ROUTE_YOUTH_ADVANCEMENTS_LIST } from '@modules/shared/duck/actionsTyped';

import {
  RECORD_ADVANCEMENT_RESPONSE,
  UPDATE_ADVANCEMENT_ERROR,
  UPDATE_ADVANCEMENT_RESPONSE,
  UPDATE_ADV_SUMMARY_REQUEST,
} from '../../progress/duck/actions';
import { advancementFilters, advancementSorters } from '../constants';
import {
  ADD_ADV_COMMENTS_ERROR,
  ADD_ADV_COMMENTS_PHOTO_ERROR,
  ADD_ADV_COMMENTS_PHOTO_REQUEST,
  ADD_ADV_COMMENTS_PHOTO_RESPONSE,
  ADD_ADV_COMMENTS_REQUEST,
  ADD_ADV_COMMENTS_RESPONSE,
  ADV_COMMENTS_ERROR,
  ADV_COMMENTS_REQUEST,
  ADV_COMMENTS_RESPONSE,
  ADV_VERSIONS_ERROR,
  ADV_VERSIONS_RESPONSE,
  CLEAR_EAGLE_ACTIVITY,
  CLOSE_EDIT_ADV_RQMT_MODAL,
  DELETE_ADV_COMMENTS_ERROR,
  DELETE_ADV_COMMENTS_PHOTO_ERROR,
  DELETE_ADV_COMMENTS_PHOTO_REQUEST,
  DELETE_ADV_COMMENTS_PHOTO_RESPONSE,
  DELETE_ADV_COMMENTS_REQUEST,
  DELETE_ADV_COMMENTS_RESPONSE,
  EAGLE_ACTIVITY_ERROR,
  EAGLE_ACTIVITY_REQUEST,
  EAGLE_ACTIVITY_RESPONSE,
  EDIT_EAGLE_PROJECT_REQUEST,
  MEMBER_DETAILS_ERROR,
  MEMBER_DETAILS_REQUEST,
  MEMBER_DETAILS_RESPONSE,
  OPEN_EDIT_ADV_RQMT_MODAL,
  ROUTE_YOUTH_ADV_REQUIREMENTS,
  ROUTE_YOUTH_PROFILE,
  SAVE_YOUTH_DETAILS_ERROR,
  SAVE_YOUTH_DETAILS_REQUEST,
  SAVE_YOUTH_DETAILS_RESPONSE,
  SAVE_YOUTH_PROFILE_ERROR,
  SAVE_YOUTH_PROFILE_PICTURE_ERROR,
  SAVE_YOUTH_PROFILE_PICTURE_REQUEST,
  SAVE_YOUTH_PROFILE_PICTURE_RESPONSE,
  SAVE_YOUTH_PROFILE_REQUEST,
  SAVE_YOUTH_PROFILE_RESPONSE,
  SET_ADV_LIST_FILTER,
  SET_ADV_LIST_SEARCH,
  SET_ADV_LIST_SORTER,
  SET_EDIT_MODE,
  SET_EDIT_YOUTH_DETAIL_SIDEBAR,
  SET_SHOULD_MEMBER_DETAILS_RELOAD,
  SUBMIT_EAGLE_PROJECT_ERROR,
  SUBMIT_EAGLE_PROJECT_REQUEST,
  SUBMIT_EAGLE_PROJECT_RESPONSE,
  UPDATE_ACTIVE_VERSION_ERROR,
  UPDATE_ACTIVE_VERSION_REQUEST,
  UPDATE_ACTIVE_VERSION_RESPONSE,
  UPDATE_ADV_COMMENTS_ERROR,
  UPDATE_ADV_COMMENTS_REQUEST,
  UPDATE_ADV_COMMENTS_RESPONSE,
  YOUTH_ACTIVITIES_SUMMARY_ERROR,
  YOUTH_ACTIVITIES_SUMMARY_REQUEST,
  YOUTH_ACTIVITIES_SUMMARY_RESPONSE,
  YOUTH_ADV_REQUIREMENTS_ERROR,
  YOUTH_ADV_REQUIREMENTS_REQUEST,
  YOUTH_ADV_REQUIREMENTS_RESPONSE,
  YOUTH_AKELA_PROFILE_ERROR,
  YOUTH_AKELA_PROFILE_REQUEST,
  YOUTH_AKELA_PROFILE_RESPONSE,
  YOUTH_YPT_RESPONSE,
} from './actions';
import { editAdvRqmtsResponse } from './actionsTyped';

const isMemberDetailsLoading = (state = false, { type }) => {
  switch (type) {
    case MEMBER_DETAILS_REQUEST: {
      return true;
    }
    case MEMBER_DETAILS_RESPONSE:
    case MEMBER_DETAILS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const memberDetails = (state = null, { type, payload }) => {
  switch (type) {
    case ROUTE_YOUTH_PROFILE: {
      const { userId } = state || {};
      const isSameMember = userId == payload.userId;
      return isSameMember ? state : null;
    }
    case MEMBER_DETAILS_RESPONSE: {
      return payload;
    }
    case RECORD_ADVANCEMENT_RESPONSE:
    case MEMBER_DETAILS_ERROR: {
      return null;
    }
    case UPDATE_ADVANCEMENT_RESPONSE: {
      if (state == null) {
        return state;
      }
      const date = moment(payload.completionDate).format(modelDateFormat);
      const advancements = state[payload.advancementType].map(adv =>
        payload.uid == adv.uid
          ? {
              ...adv,
              date,
            }
          : adv,
      );
      return { ...state, [payload.advancementType]: advancements };
    }
    default: {
      return state;
    }
  }
};

const shouldMemberDetailsReload = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SHOULD_MEMBER_DETAILS_RELOAD: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const setEditYouthDetailSidebar = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EDIT_YOUTH_DETAIL_SIDEBAR: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const youthYPTDetails = (state = null, { type, payload }) => {
  switch (type) {
    case YOUTH_YPT_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const youthAkelaProfileDetails = (state = null, { type, payload }) => {
  switch (type) {
    case YOUTH_AKELA_PROFILE_RESPONSE: {
      return payload;
    }
    case YOUTH_AKELA_PROFILE_ERROR:
    default: {
      return state;
    }
  }
};

const youthAkelaProfileLoading = (state = false, { type }) => {
  switch (type) {
    case YOUTH_AKELA_PROFILE_ERROR:
    case YOUTH_AKELA_PROFILE_RESPONSE: {
      return false;
    }
    case YOUTH_AKELA_PROFILE_REQUEST: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const isSavingProfile = (state = false, { type }) => {
  switch (type) {
    case SAVE_YOUTH_PROFILE_REQUEST: {
      return true;
    }
    case SAVE_YOUTH_PROFILE_ERROR:
    case SAVE_YOUTH_PROFILE_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const youthDetailsSaveLoading = (state = false, { type }) => {
  switch (type) {
    case SAVE_YOUTH_DETAILS_REQUEST: {
      return true;
    }
    case SAVE_YOUTH_DETAILS_RESPONSE:
    case SAVE_YOUTH_DETAILS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const youthProfilePictureSaveLoading = (state = false, { type }) => {
  switch (type) {
    case SAVE_YOUTH_PROFILE_PICTURE_REQUEST: {
      return true;
    }
    case SAVE_YOUTH_PROFILE_PICTURE_RESPONSE:
    case SAVE_YOUTH_PROFILE_PICTURE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EDIT_MODE: {
      return payload;
    }
    case ROUTE_YOUTH_PROFILE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEagleActivityLoading = (state = false, { type }) => {
  switch (type) {
    case EAGLE_ACTIVITY_REQUEST: {
      return true;
    }
    case EAGLE_ACTIVITY_RESPONSE:
    case EAGLE_ACTIVITY_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const eagleActivity = (state = null, { type, payload }) => {
  switch (type) {
    case EAGLE_ACTIVITY_RESPONSE: {
      return payload;
    }
    case CLEAR_EAGLE_ACTIVITY: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const youthAdvRequirementsLoading = (state = false, { type }) => {
  switch (type) {
    case UPDATE_ACTIVE_VERSION_REQUEST:
    case YOUTH_ADV_REQUIREMENTS_REQUEST: {
      return true;
    }
    case UPDATE_ACTIVE_VERSION_RESPONSE:
    case UPDATE_ACTIVE_VERSION_ERROR:
    case YOUTH_ADV_REQUIREMENTS_RESPONSE:
    case YOUTH_ADV_REQUIREMENTS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const youthAdvRequirements = (state = {}, { type, payload }) => {
  switch (type) {
    case YOUTH_ADV_REQUIREMENTS_RESPONSE: {
      return payload;
    }
    case ROUTE_YOUTH_ADV_REQUIREMENTS:
    case YOUTH_ADV_REQUIREMENTS_ERROR: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const youthAdvNotFound = (state = false, { type, payload = {} }) => {
  switch (type) {
    case YOUTH_ADV_REQUIREMENTS_REQUEST:
    case YOUTH_ADV_REQUIREMENTS_RESPONSE: {
      return false;
    }
    case YOUTH_ADV_REQUIREMENTS_ERROR: {
      return payload.status == 404;
    }
    default: {
      return state;
    }
  }
};

const defaultSorter = advancementSorters.ALPHA;
const advListSorter = (state = defaultSorter, { type, payload }) => {
  switch (type) {
    case SET_ADV_LIST_SORTER: {
      return payload;
    }
    case ROUTE_YOUTH_ADVANCEMENTS_LIST: {
      return defaultSorter;
    }
    default: {
      return state;
    }
  }
};

const advListFilter = (state = advancementFilters.ALL, { type, payload }) => {
  switch (type) {
    case SET_ADV_LIST_FILTER: {
      return payload;
    }
    case ROUTE_YOUTH_ADVANCEMENTS_LIST: {
      return payload.advancementStatus || advancementFilters.APPROVED;
    }
    default: {
      return state;
    }
  }
};

const advListSearch = (state = '', { type, payload }) => {
  switch (type) {
    case SET_ADV_LIST_SEARCH: {
      return payload;
    }
    case ROUTE_YOUTH_ADVANCEMENTS_LIST: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const advReqEditModalOpen = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_ADV_RQMT_MODAL: {
      return true;
    }
    case CLOSE_EDIT_ADV_RQMT_MODAL:
    case editAdvRqmtsResponse.type: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const advReqToEdit = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_ADV_RQMT_MODAL: {
      return payload;
    }
    case CLOSE_EDIT_ADV_RQMT_MODAL:
    case editAdvRqmtsResponse.type: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const updatingYouthAdvancement = (state = false, { type }) => {
  switch (type) {
    case UPDATE_ADV_SUMMARY_REQUEST: {
      return true;
    }
    case UPDATE_ADVANCEMENT_ERROR:
    case UPDATE_ADVANCEMENT_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const activityLogsSummary = (state = {}, { type, payload }) => {
  switch (type) {
    case YOUTH_ACTIVITIES_SUMMARY_REQUEST:
    case YOUTH_ACTIVITIES_SUMMARY_ERROR:
      return {};
    case YOUTH_ACTIVITIES_SUMMARY_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const isLoadingActivityLogsSummary = (state = false, { type }) => {
  switch (type) {
    case YOUTH_ACTIVITIES_SUMMARY_REQUEST:
      return true;
    case YOUTH_ACTIVITIES_SUMMARY_RESPONSE:
    case YOUTH_ACTIVITIES_SUMMARY_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingSubmitEagleProject = (state = false, { type }) => {
  switch (type) {
    case SUBMIT_EAGLE_PROJECT_REQUEST:
    case EDIT_EAGLE_PROJECT_REQUEST:
      return true;
    case SUBMIT_EAGLE_PROJECT_RESPONSE:
    case EAGLE_ACTIVITY_RESPONSE:
    case SUBMIT_EAGLE_PROJECT_ERROR:
      return false;
    default: {
      return state;
    }
  }
};

const rankVersions = (state = [], { type, payload }) => {
  switch (type) {
    case ADV_VERSIONS_RESPONSE: {
      return payload;
    }
    case ADV_VERSIONS_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const advRequirementsCommentsLoading = (state = false, { type }) => {
  switch (type) {
    case ADD_ADV_COMMENTS_PHOTO_REQUEST:
    case DELETE_ADV_COMMENTS_PHOTO_REQUEST:
    case UPDATE_ADV_COMMENTS_REQUEST:
    case DELETE_ADV_COMMENTS_REQUEST:
    case ADD_ADV_COMMENTS_REQUEST:
    case ADV_COMMENTS_REQUEST: {
      return true;
    }
    case ADD_ADV_COMMENTS_PHOTO_RESPONSE:
    case ADD_ADV_COMMENTS_PHOTO_ERROR:
    case DELETE_ADV_COMMENTS_PHOTO_RESPONSE:
    case DELETE_ADV_COMMENTS_PHOTO_ERROR:
    case UPDATE_ADV_COMMENTS_RESPONSE:
    case UPDATE_ADV_COMMENTS_ERROR:
    case DELETE_ADV_COMMENTS_RESPONSE:
    case DELETE_ADV_COMMENTS_ERROR:
    case ADD_ADV_COMMENTS_RESPONSE:
    case ADD_ADV_COMMENTS_ERROR:
    case ADV_COMMENTS_RESPONSE:
    case ADV_COMMENTS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const advRequirementsComments = (state = {}, { type, payload }) => {
  switch (type) {
    case ADV_COMMENTS_RESPONSE: {
      return payload;
    }
    case ADD_ADV_COMMENTS_REQUEST:
    case ADV_COMMENTS_ERROR: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const advRequirementsCommentsNotFound = (
  state = false,
  { type, payload = {} },
) => {
  switch (type) {
    case ADV_COMMENTS_REQUEST:
    case ADV_COMMENTS_RESPONSE: {
      return false;
    }
    case ADV_COMMENTS_ERROR: {
      return payload.status == 404;
    }
    default: {
      return state;
    }
  }
};

const startedActiveRankVersion = (state = false, { type }) => {
  switch (type) {
    case UPDATE_ACTIVE_VERSION_RESPONSE: {
      return true;
    }
    default: {
      return state;
    }
  }
};

export default {
  isMemberDetailsLoading,
  memberDetails,
  shouldMemberDetailsReload,
  setEditYouthDetailSidebar,
  youthYPTDetails,
  youthAkelaProfileDetails,
  youthAkelaProfileLoading,
  isSavingProfile,
  youthDetailsSaveLoading,
  youthProfilePictureSaveLoading,
  isEditing,
  isEagleActivityLoading,
  eagleActivity,
  youthAdvRequirementsLoading,
  youthAdvRequirements,
  youthAdvNotFound,
  advListFilter,
  advListSorter,
  advListSearch,
  advReqEditModalOpen,
  advReqToEdit,
  activityLogsSummary,
  isLoadingActivityLogsSummary,
  isLoadingSubmitEagleProject,
  rankVersions,
  advRequirementsCommentsLoading,
  advRequirementsComments,
  advRequirementsCommentsNotFound,
  updatingYouthAdvancement,
  startedActiveRankVersion,
};
