import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSbComSsoUrl } from '@config';
import { safeLoginDataSel } from '../../user';

const InitiateSbComSession = ({ sessionToken, ulvc }) => {
  if (sessionToken && ulvc) {
    return (
      <iframe
        style={{ display: 'none' }}
        src={`${getSbComSsoUrl()}&SessionToken=${sessionToken}&ValidationCode=${ulvc}`}
      />
    );
  }

  return '';
};

InitiateSbComSession.propTypes = {
  sessionToken: PropTypes.string,
  ulvc: PropTypes.string,
};

const mapState = state => {
  const { sessionToken, ulvc } = safeLoginDataSel(state);

  return {
    sessionToken,
    ulvc,
  };
};

export default connect(mapState)(InitiateSbComSession);
