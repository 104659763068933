import React, { useMemo, useState } from 'react';

import cn from 'classnames';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { SCOUTBOOK_LEGACY_URL, getMyScoutingUrl } from '@config';
import { Avatar, Button, Card, FlexSpacer, S, T } from '@shared/components';
import { appName } from '@shared/constants';
import { logout } from '@shared/duck';

import FleurDeLisIcon from '../../../../assets/images/FleurDeLis.svg';
import { permissions } from '../../constants';
import {
  hasPermissionSel,
  openMasqueradeModal,
  userDataLoadingSel,
} from '../../duck';
import styles from './NoAccessPage.less';

const NoAccessPage: React.FC = () => {
  const dispatch = useDispatch();

  const [redirectingTo, setRedirectingTo] = useState('');
  const redirecting = useMemo(() => !!redirectingTo, [redirectingTo]);

  const canMasquerade = useSelector(state =>
    hasPermissionSel(state, permissions.MASQUERADE),
  );
  const userDataLoading = useSelector(userDataLoadingSel);

  const handleOpenMasqueradeModal = () => {
    dispatch(openMasqueradeModal());
  };

  const handleLegacyScoutbookClick = () => {
    window.location.href = SCOUTBOOK_LEGACY_URL;
    setRedirectingTo('SB_LEGACY');
  };

  const handleMystClick = () => {
    window.location.href = getMyScoutingUrl();
    setRedirectingTo('MYST');
  };

  const handleLogoutClick = () => dispatch(logout());

  return (
    <div className={styles.container}>
      <div className={styles.bg} />
      <Card className={styles.card} shadow>
        <Avatar
          shadow
          className={styles.logoAvatar}
          placeholder={<FleurDeLisIcon className={styles.logoIcon} />}
        />
        <div className={styles.header}>
          <T size="3" className={styles.title}>
            <FormattedMessage
              id="user.NoAccessPage.title"
              values={{ appName }}
            />
          </T>
          <S size="3" className={cn(styles.ieFix, styles.subtitle)}>
            <FormattedMessage
              id="user.NoAccessPage.subtitle"
              values={{ appName }}
            />
          </S>
          <S size="4" className={cn(styles.ieFix, styles.description)}>
            <FormattedMessage
              id="user.NoAccessPage.description"
              values={{ appName }}
            />
          </S>
        </div>
        <FlexSpacer />
        <S size="3" bold colored className={cn(styles.ieFix, styles.whatNext)}>
          <FormattedMessage id="user.NoAccessPage.whatNext" />
        </S>
        <S size="4" bold className={styles.takeMeTo}>
          <FormattedMessage id="user.NoAccessPage.takeMeTo" />
        </S>
        <div className={styles.options}>
          <div className={styles.option}>
            <Button
              color="scouting-blue"
              uppercase={false}
              className={styles.button}
              disabled={redirecting}
              loading={redirectingTo === 'SB_LEGACY'}
              onClick={handleLegacyScoutbookClick}
            >
              <FormattedHTMLMessage id="user.NoAccessPage.scoutbook" />
            </Button>
          </div>
          <div className={styles.option}>
            <Button
              type="primary"
              color="scouting-blue"
              uppercase={false}
              className={styles.button}
              disabled={redirecting}
              loading={redirectingTo === 'MYST'}
              onClick={handleMystClick}
            >
              <FormattedHTMLMessage id="user.NoAccessPage.myst" />
            </Button>
          </div>
        </div>
        <Button
          color="scouting-warm-gray"
          noBorder
          className={styles.logoutButton}
          disabled={redirecting}
          onClick={handleLogoutClick}
        >
          <FormattedHTMLMessage id="user.NoAccessPage.logout" />
        </Button>
        {canMasquerade && (
          <Button
            className={styles.masqueradeButton}
            size="large"
            color="scouting-red"
            noBorder
            fitText
            loading={userDataLoading}
            onClick={handleOpenMasqueradeModal}
          >
            Masquerade
          </Button>
        )}
      </Card>
    </div>
  );
};

export default NoAccessPage;
