import moment from 'moment';

import { esbApiService } from '@utils';

import type {
  GetEventsRes,
  PostUserPaymentLogReq,
  PutUserPaymentLogReq,
} from '../../../types/esb';

/**
 * @esbEndpoint POST '/advancements/events'
 */
const fetchUnitEvents$ = (unitId: string) =>
  esbApiService
    .post$(
      '/advancements/events',
      {
        unitId,
        fromDate: moment().startOf('year').format('Y-MM-DD'),
        toDate: moment().endOf('year').format('Y-MM-DD'),
        excludeSubUnitEvents: true,
      },
      { gtm: { label: '/paymentLogs/{unitId}/events' } },
    )
    .map((response: GetEventsRes) =>
      response.map(({ id, name, startDate }) => ({
        id,
        name,
        startDate,
      })),
    );

/**
 * @esbEndpoint GET /advancements/events/:eventId
 */
const getEvent$ = (eventId: number) =>
  esbApiService.get$(`/advancements/events/${eventId}`, {
    gtm: { label: '/advancements/events/{eventId}' },
  });

/**
 * @esbEndpoint GET /persons/v2/:userId/personprofile
 */
const getPersonProfile$ = (userId: number) =>
  esbApiService.get$(`/persons/v2/${userId}/personprofile`, {
    gtm: { label: '/persons/v2/{userId}/personprofile' },
  });

/**
 * @esbEndpoint GET /advancements/:userId/paymentLogs
 */
const getUserPaymentLogs$ = (userId: number) =>
  esbApiService.get$(`/advancements/${userId}/paymentLogs`, {
    gtm: { label: '/advancements/{userId}/paymentLogs' },
  });

/**
 * @esbEndpoint POST /advancements/:userId/paymentLogs
 */
const postUserPaymentLogs$ = (userId: number, data: PostUserPaymentLogReq) =>
  esbApiService.post$(`/advancements/${userId}/paymentLogs`, data, {
    gtm: { label: '/advancements/{userId}/paymentLogs' },
  });

/**
 * @esbEndpoint PUT /advancements/:userId/paymentLogs
 */
const putUserPaymentLogs$ = (
  userId: number,
  logId: number,
  data: PutUserPaymentLogReq,
) =>
  esbApiService.put$(`/advancements/${userId}/paymentLogs/${logId}`, data, {
    gtm: { label: '/advancements/{userId}/paymentLogs' },
  });

export default {
  fetchUnitEvents$,
  getEvent$,
  getPersonProfile$,
  getUserPaymentLogs$,
  postUserPaymentLogs$,
  putUserPaymentLogs$,
};
