import { createSelector } from 'reselect';

import { UnitMemberPaymentLogBalance } from '@appTypes/esb';
import { rosterItemsSel } from '@modules/advancement';
import { permissionsSel, urlUserIdSel } from '@shared';
import { userIdSel } from '@user';

import { RootState } from '../../../root/store';
import { moduleName } from '../constants';
import type { CombinedPaymentLogsState } from '../types';

const paymentLogsModuleSel = (state: RootState) =>
  state[moduleName] as CombinedPaymentLogsState;
const sliceReducerModuleSel = (state: RootState) =>
  paymentLogsModuleSel(state).sliceReducer;

export const searchFilterSel = (state: RootState) =>
  sliceReducerModuleSel(state).searchFilter;
export const sorterSel = (state: RootState) =>
  sliceReducerModuleSel(state).sorter;
export const selectedRowKeysSel = (state: RootState) =>
  sliceReducerModuleSel(state).selectedRowKeys;
export const paymentLogsActiveTabSel = (state: RootState) =>
  sliceReducerModuleSel(state).paymentLogsActiveTab;

export const currentUserIdSel = createSelector(
  urlUserIdSel,
  userIdSel,
  (urlUserId, selfUserId) => urlUserId || selfUserId,
);

export const getUserPaymentLogsSel = paymentLogsModuleSel;

export const eventSel = createSelector(
  paymentLogsModuleSel,
  ({ event }) => event,
);

export const loadingSel = createSelector(
  paymentLogsModuleSel,
  ({ loading }) => loading || false,
);

export const loadingEventSel = createSelector(
  paymentLogsModuleSel,
  ({ loadingEvent }) => loadingEvent || false,
);

export const loadingPersonUnitsSel = createSelector(
  paymentLogsModuleSel,
  ({ loadingPersonUnits }) => loadingPersonUnits || false,
);

export const loadingUnitEventsSel = createSelector(
  paymentLogsModuleSel,
  ({ loadingUnitEvents }) => loadingUnitEvents || false,
);

export const personUnitsSel = createSelector(
  paymentLogsModuleSel,
  ({ personUnits }) => personUnits || false,
);

export const postingUserPaymentLogsSel = createSelector(
  paymentLogsModuleSel,
  ({ postingUserPaymentLogs }) => postingUserPaymentLogs || false,
);

export const balanceDetailsSel = createSelector(
  paymentLogsModuleSel,
  ({ balanceDetails }) => balanceDetails,
);

export const paymentLogsSel = createSelector(
  paymentLogsModuleSel,
  ({ paymentLogs }) => paymentLogs || [],
);

export const unitEventsSel = createSelector(
  paymentLogsModuleSel,
  ({ unitEvents }) => unitEvents || [],
);

export const updatingUserPaymentLogSel = createSelector(
  paymentLogsModuleSel,
  ({ updatingUserPaymentLog }) => updatingUserPaymentLog || false,
);

export const editAllowedSel = createSelector(
  permissionsSel,
  permissions => permissions['EDIT_YOUTH_PAYMENT_LOGS'] || false,
);

export const rosterBalanceSel = createSelector(rosterItemsSel, rosterItems => {
  const temporalTestingRoster: UnitMemberPaymentLogBalance[] = rosterItems.map(
    member => ({
      userId: member.userId,
      firstName: member.firstName,
      middleName: member.middleName,
      lastName: member.lastName,
      nameSuffix: member.nameSuffix,
      nickName: member.nickName ?? '',
      memberId: member.memberId,
      personGuid: member.personGuid,
      isYouthMember: !member.isAdult,
      balanceDetails: {
        totalCredits: 0,
        totalCharges: 0,
        balance: 0,
      },
    }),
  );

  return temporalTestingRoster;
});
