import { createSelector } from 'reselect';

import { payloadSel } from '@location';
import { packRosterItemsSel, urlUserIdSel } from '@shared';

export const activityTypeIdSel = state => payloadSel(state).activityType;

export const personInfoSel = createSelector(
  urlUserIdSel,
  packRosterItemsSel,
  (userId, packRoster) => packRoster.find(item => item.userId == userId),
);

export const isPersonWithinUnitSel = createSelector(
  personInfoSel,
  personInfo => !!personInfo,
);
