import React, { useCallback, useEffect, useMemo, useState } from 'react';

import InfoIcon from '@material-ui/icons/Info';
import { FormComponentProps } from 'antd/lib/form';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import stable from 'stable';

import {
  Form,
  Radio,
  S,
  Select,
  Tooltip,
  assistedEagleProjectId,
  intl,
} from '@shared';

import { detailModes } from '../../constants';
import {
  categoriesAndOrganizationsRequest,
  categoriesSel,
  loadingModalSel,
} from '../../duck';
import styles from './ActivityDetails.less';

const FormItem = Form.Item;
const { Option } = Select;
const RadioGroup = Radio.Group;

interface Category {
  actTypeId: number;
  description: string;
  effectiveDt: string;
  expiryDt: string;
  id: number;
  name: string;
}
interface Props extends FormComponentProps {
  detailMode: keyof typeof detailModes;
  sectionTitle: string;
  showCategorySelector: boolean;
  activityTypeId: number;
  disabled: boolean;
  categoryId: number | boolean;
}

const ActivityDetails: React.FC<Props> = ({
  form,
  detailMode,
  sectionTitle,
  showCategorySelector = true,
  activityTypeId,
  disabled,
  categoryId = false,
}) => {
  const [isServiceAssisted, setIsServiceAssisted] = useState<boolean | null>(
    null,
  );
  const dispatch = useDispatch();

  const requiredRule = {
    required: true,
    message: intl.formatMessage({
      id: 'shared.form.error.isRequired',
    }),
  };

  const isModalLoading = useSelector(loadingModalSel);
  const allCategories: Category[] = useSelector(categoriesSel);

  const { resetFields, getFieldDecorator } = form;
  const isProjectTypeDisabled =
    isModalLoading ||
    isNil(isServiceAssisted) ||
    detailModes.RECORD === detailMode ||
    detailModes.JOIN === detailMode ||
    disabled;

  const eagleCategories = useMemo(
    () => allCategories.filter(({ name }) => name.includes('(Eagle Project)')),
    [allCategories],
  );

  const nonEagleCategories = useMemo(
    () => allCategories.filter(({ name }) => !name.includes('(Eagle Project)')),
    [allCategories],
  );

  useEffect(() => {
    dispatch(categoriesAndOrganizationsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (showCategorySelector && categoryId !== 0) {
      //set initial value to Yes if an eagle project category is already selected
      setIsServiceAssisted(
        eagleCategories.some(category => category.id === categoryId),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eagleCategories]);

  const onToggleRadio = useCallback(
    e => {
      setIsServiceAssisted(e.target.value);
      resetFields(['categoryId']);
    },
    [setIsServiceAssisted, resetFields],
  );

  const sortedCategories = useMemo(() => {
    //if eagle project assistance hasn't be marked yet, give no results
    if (isServiceAssisted === null) {
      return [];
    }

    //return only relevant categories, depending on prior answer to whether service is related to an eagle project
    let filteredCategories = isServiceAssisted
      ? eagleCategories
      : nonEagleCategories;

    filteredCategories = filteredCategories
      //remove current category and the unused generic assisted-eagle-project category
      .filter(
        ({ actTypeId, id }) =>
          actTypeId === activityTypeId && id !== assistedEagleProjectId,
      )
      //remove expired categories
      .filter(
        ({ expiryDt }) =>
          !expiryDt || (expiryDt && moment(expiryDt).isAfter(moment())),
      );

    // stable sort the results
    return stable(filteredCategories, (a, b) =>
      String(a.name).localeCompare(b.name),
    );
  }, [eagleCategories, nonEagleCategories, activityTypeId, isServiceAssisted]);

  return (
    <React.Fragment>
      <S size="4" bold colored colon>
        {sectionTitle || (
          <FormattedMessage id="activity.ActivityDetails.field.projectDetails" />
        )}
      </S>
      <br />
      {showCategorySelector && (
        <React.Fragment>
          <FormItem
            label={
              <FormattedMessage id="activity.ActivityDetails.field.category.assisted" />
            }
          >
            {getFieldDecorator('assisted', {
              initialValue: isServiceAssisted,
              rules: [requiredRule],
            })(
              <RadioGroup onChange={onToggleRadio}>
                <Radio value={false}>
                  <FormattedMessage id="activity.ActivityDetails.field.category.assisted.no" />
                </Radio>
                <Radio value={true}>
                  <FormattedMessage id="activity.ActivityDetails.field.category.assisted.yes" />
                </Radio>
              </RadioGroup>,
            )}
          </FormItem>
          <FormItem
            required
            label={
              <React.Fragment>
                <FormattedMessage id="activity.ActivityDetails.field.category" />
                <Tooltip
                  overlayStyle={{ maxWidth: 'unset' }}
                  title={
                    <FormattedHTMLMessage id="activity.ActivityDetails.field.category.tooltip" />
                  }
                >
                  <InfoIcon className={styles.infoIcon} />
                </Tooltip>
              </React.Fragment>
            }
            standardLayout
          >
            {getFieldDecorator('categoryId', {
              rules: [requiredRule],
            })(
              <Select
                disabled={isProjectTypeDisabled}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'activity.ActivityDetails.field.category.placeholder',
                })}
                label={intl.formatMessage({
                  id: 'activity.ActivityDetails.field.category',
                })}
                optionFilterProp="text"
                showSearch
              >
                {sortedCategories.map(({ id, name }) => (
                  <Option key={`categories_${id}`} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ActivityDetails;
