import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import cn from 'classnames';

import styles from './WarningMessage.less';

const WarningMessage = ({ className, children }) => (
  <div className={cn(className, styles.container)}>
    <ErrorIcon className={styles.icon} />
    <div className={styles.text}>{children}</div>
  </div>
);

WarningMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default WarningMessage;
