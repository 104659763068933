import moment from 'moment';

const getRecurrentId = (event = {}) => {
  const { recurringEvent = {} } = event;

  if (!recurringEvent.recurringEventId) {
    return undefined;
  }

  if (moment(event.startDate).isAfter()) {
    return recurringEvent.recurringEventId;
  }

  return undefined;
};

export default getRecurrentId;
