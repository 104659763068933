import React from 'react';

import EmailIcon from '@material-ui/icons/Email';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TodayIcon from '@material-ui/icons/Today';
import cn from 'classnames';
import AttachMoney from 'material-ui-icons/AttachMoney';
import ContentPasteIcon from 'material-ui-icons/ContentPaste';
import DashboardIcon from 'material-ui-icons/Dashboard';
import FaceIcon from 'material-ui-icons/Face';
import FormatListBulletedIcon from 'material-ui-icons/FormatListBulleted';
import InfoOutlineIcon from 'material-ui-icons/InfoOutline';
import MonetizationOn from 'material-ui-icons/MonetizationOn';
import PlaceIcon from 'material-ui-icons/Place';
import SettingsIcon from 'material-ui-icons/Settings';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'redux-first-router-link';

import ActivityIcon from '@assets/images/Activities.svg';
import {
  RECHARTER_DATE_RESTRICTION,
  SHOW_ACTIVITIES_MENU,
  SHOW_COUNSELED_YOUTH,
  SHOW_DASHBOARD,
  SHOW_MB_COUNSELORS,
  SHOW_MESSAGING,
  SHOW_PAYMENT_LOGS,
  SHOW_PERMISSION_MANAGER,
  SHOW_UNIT_PAYMENT_LOGS,
} from '@config';
import { isSelectedOrganizationValidSel } from '@context';
import { pathnameSel } from '@location';
import {
  Menu,
  childUserIdSel,
  isCouncilOrgActiveSel,
  isKey3Sel,
  isParentGuardianSel,
  isParentOrYouthMemberSel,
  isRecharterPeriod,
  isYouthMemberSel,
} from '@shared';
import { unitInfoSel } from '@unit';
import { permissions, permissionsSel, userDataInitializedSel } from '@user';

import { isSettingsRouteSel, sidebarOpenSel } from '../../duck';
import SettingsOptions from '../SettingsOptions';
import ForumMenuItem from './ForumMenuItem';
import styles from './Nav.less';
import NavMenuItem from './NavMenuItem';
import NavMenuItemContent from './NavMenuItemContent';
import ScoutbookLegacyMenuItem from './ScoutbookLegacyMenuItem';

const SubMenu = Menu.SubMenu;

class Nav extends React.PureComponent {
  state = {
    settingsExpanded: false,
    activeOption: this.props.pathname,
    currentPage: this.props.pathname,
  };

  componentDidMount() {
    if (this.props.isSettingsRoute) {
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({ settingsExpanded: true });
    }
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { pathname, isSettingsRoute, isSidebarOpen } = this.props;

    if (
      prevProps.isSettingsRoute !== isSettingsRoute ||
      (prevProps.isSidebarOpen !== isSidebarOpen && isSidebarOpen)
    ) {
      this.setState({ settingsExpanded: isSettingsRoute });
    }
    if (prevProps.pathname !== pathname) {
      this.setState({ activeOption: isSettingsRoute ? '/settings' : pathname });
    }
    if (
      this.props.shouldShowCalendar &&
      this.props.unitInfo &&
      this.props.unitInfo.organizationGuid
    ) {
      const unitGuid = this.props.unitInfo.organizationGuid;
      sessionStorage.setItem(`unit_betacalendar_${unitGuid}`, true);
    }
  }

  handleMenuClick = ({ item, key }) => {
    const { selectable = true } = item.props;

    if (selectable) {
      this.setState({
        activeOption: key,
      });
    }
  };

  onSettingsExpandedChange = (settingsExpanded, key) => {
    const { pathname } = this.props;
    const selectedPathname = key || pathname;
    const activeOption = settingsExpanded ? settingsExpanded : selectedPathname;
    this.setState({ settingsExpanded, activeOption });
  };

  isActivePage = pageName => pageName === this.state.activeOption;

  isCurrentPage = pageName => {
    const { pathname } = this.props;
    return pageName === pathname;
  };

  shouldEnableRoster = () => {
    const { userPermissions, isSelectedUnitValid } = this.props;
    return userPermissions[permissions.VIEW_ROSTER_PAGE] && isSelectedUnitValid;
  };

  shouldShowRoster = () => {
    const { userPermissions } = this.props;
    return (
      userPermissions[permissions.VIEW_ROSTER_PAGE] ||
      userPermissions[permissions.VIEW_COUNCIL_UNITS_PAGE]
    );
  };

  shouldShowUnitPaymentLogs = () => {
    const { isKey3, userPermissions } = this.props;

    return (
      SHOW_UNIT_PAYMENT_LOGS &&
      (userPermissions[permissions.VIEW_UNIT_PAYMENT_LOGS] || isKey3)
    );
  };

  shouldShowYouthPaymentLogs = () => {
    const { childUserId, userPermissions } = this.props;

    return (
      SHOW_PAYMENT_LOGS &&
      userPermissions[permissions.VIEW_YOUTH_PAYMENT_LOGS] &&
      childUserId
    );
  };

  shouldEnablePermissionsManager = () => {
    const { userPermissions, isSelectedUnitValid } = this.props;
    return (
      userPermissions[permissions.VIEW_PERMISSIONS_MANAGER] &&
      isSelectedUnitValid
    );
  };

  handleSubMenuExpand = () => {
    this.setState(({ settingsExpanded }) => ({
      settingsExpanded: !settingsExpanded,
    }));
  };

  hasPerms = permission => {
    const { userPermissions } = this.props;
    permission = Array.isArray(permission) ? permission : [permission];
    return permission.some(
      currentPermission => userPermissions[currentPermission],
    );
  };

  render() {
    const {
      loading,
      isExpanded,
      isMobile,
      pathname,
      isSettingsRoute,
      userPermissions,
      isParentGuardian,
      childUserId,
      isCouncilOrgActive,
      isKey3,
      unitInfo,
      shouldShowCalendar,
    } = this.props;
    const { settingsExpanded, activeOption } = this.state;
    const { hasPerms } = this;
    const showLabels = isMobile || isExpanded;
    const showRoster = this.shouldShowRoster();
    const youthProfileLink =
      isParentGuardian && childUserId && `/youthProfile/${childUserId}`;
    const youthPaymentLogsLink =
      isParentGuardian && childUserId && `/paymentLogs/${childUserId}`;

    return (
      <Menu
        mode="inline"
        className={cn(styles.menu, {
          [styles.subMenuActive]: settingsExpanded && isMobile,
        })}
        defaultSelectedKeys={[pathname]}
        openKeys={settingsExpanded ? ['/settings'] : []}
        selectedKeys={[activeOption]}
        onClick={this.handleMenuClick}
      >
        {hasPerms(permissions.VIEW_COUNCIL_UNITS_PAGE) && (
          <NavMenuItem
            key="/units"
            inlineCollapsed={!showLabels}
            disabled={loading}
          >
            <NavLink to="/units">
              <NavMenuItemContent
                icon={<PlaceIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.units" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!SHOW_DASHBOARD && (
          <NavMenuItem key="/" inlineCollapsed={!showLabels} disabled={loading}>
            <NavLink to="/">
              <NavMenuItemContent
                icon={<DashboardIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.dashboard" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!showRoster && (
          <NavMenuItem
            key="/roster"
            disabled={loading || !this.shouldEnableRoster()}
          >
            <NavLink to="/roster" id="qa_roster">
              <NavMenuItemContent
                icon={<FormatListBulletedIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.roster" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!shouldShowCalendar && hasPerms(permissions.VIEW_CALENDAR_PAGE) && (
          <NavMenuItem
            key="/calendar"
            disabled={
              loading ||
              !userPermissions[permissions.VIEW_CALENDAR_PAGE] ||
              isCouncilOrgActive
            }
          >
            <NavLink to="/calendar">
              <NavMenuItemContent
                icon={<TodayIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.calendar" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!(SHOW_MESSAGING && hasPerms(permissions.VIEW_MESSAGING)) && (
          <NavMenuItem key="/messaging" disabled={loading}>
            <NavLink to="/messaging" id="qa_messaging">
              <NavMenuItemContent
                icon={<EmailIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.messaging" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!SHOW_MB_COUNSELORS &&
          hasPerms(permissions.VIEW_MERIT_BADGE_COUNSELORS_PAGE) && (
            <NavMenuItem key="/meritBadgeCounselors">
              <NavLink to="/meritBadgeCounselors" id="qa_meritBadgeCounselors">
                <NavMenuItemContent
                  icon={<HowToRegIcon />}
                  showLabels={showLabels}
                  loading={loading}
                >
                  <FormattedMessage id="core.Nav.meritBadgeCounselors" />
                </NavMenuItemContent>
              </NavLink>
            </NavMenuItem>
          )}
        {!!(
          SHOW_COUNSELED_YOUTH && hasPerms(permissions.VIEW_COUINSELED_YOUTH)
        ) && (
          <NavMenuItem key="/counseledYouth">
            <NavLink to="/counseledYouth" id="qa_counseledYouth">
              <NavMenuItemContent
                icon={<SupervisorAccountIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.counseledYouth" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!isParentGuardian && (
          <NavMenuItem
            key={youthProfileLink}
            disabled={
              loading || !userPermissions[permissions.VIEW_PROFILE_PAGE]
            }
          >
            <NavLink to={youthProfileLink} id="qa_youth_profile">
              <NavMenuItemContent
                icon={<FaceIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.youthProfile" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {this.shouldShowYouthPaymentLogs() && (
          <NavMenuItem disabled={loading} key={youthPaymentLogsLink}>
            <NavLink id="youth_payment_logs" to={youthPaymentLogsLink}>
              <NavMenuItemContent
                icon={<AttachMoney />}
                loading={loading}
                showLabels={showLabels}
              >
                <FormattedMessage id="core.Nav.youthPaymentLogs" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {this.shouldShowUnitPaymentLogs() && (
          <NavMenuItem disabled={loading} key="/unitPaymentLogs">
            <NavLink id="unit_payment_logs" to="/unitPaymentLogs">
              <NavMenuItemContent
                icon={<AttachMoney />}
                loading={loading}
                showLabels={showLabels}
              >
                <FormattedMessage id="core.Nav.unitPaymentLogs" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        <NavMenuItem
          key="/profile"
          disabled={loading || !userPermissions[permissions.VIEW_PROFILE_PAGE]}
        >
          <NavLink to="/profile" id="qa_profile">
            <NavMenuItemContent
              icon={<FaceIcon />}
              showLabels={showLabels}
              loading={loading}
            >
              <FormattedMessage id="page.profile" />
            </NavMenuItemContent>
          </NavLink>
        </NavMenuItem>
        {!!SHOW_PAYMENT_LOGS && (
          <NavMenuItem disabled={loading} key="/myPaymentLogs">
            <NavLink id="my_payment_logs" to="/myPaymentLogs">
              <NavMenuItemContent
                icon={<MonetizationOn />}
                loading={loading}
                showLabels={showLabels}
              >
                <FormattedMessage id="core.Nav.myPaymentLogs" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {hasPerms([
          permissions.VIEW_ROSTER_PAGE,
          permissions.VIEW_REPORTS_PAGE,
        ]) && (
          <NavMenuItem
            key="/reports"
            disabled={
              loading || !userPermissions[permissions.VIEW_REPORTS_PAGE]
            }
          >
            <NavLink to="/reports" id="qa_reports">
              <NavMenuItemContent
                icon={<ContentPasteIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.reports" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!(showRoster && SHOW_ACTIVITIES_MENU) && (
          <NavMenuItem
            key="/activities"
            disabled={loading || !this.shouldEnableRoster()}
          >
            <NavLink to="/activities" id="qa_activities">
              <NavMenuItemContent
                icon={<ActivityIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.activities" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {hasPerms(permissions.SHOW_FORUM_LINK) && (
          <ForumMenuItem showLabels={showLabels} loading={loading} />
        )}
        {hasPerms(permissions.SHOW_SCOUTBOOK_LINK) && (
          <ScoutbookLegacyMenuItem showLabels={showLabels} loading={loading} />
        )}
        {!!(
          hasPerms(permissions.EDIT_PERMISSIONS_MANAGER) &&
          SHOW_PERMISSION_MANAGER
        ) && (
          <NavMenuItem
            key="/permissionsManager"
            disabled={loading || !this.shouldEnablePermissionsManager()}
          >
            <NavLink to="/permissionsManager" id="qa_permissionsManager">
              <NavMenuItemContent
                icon={<PeopleOutlineIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.permissionsManager" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
        {!!(
          hasPerms(permissions.SHOW_RECHARTER_LINK) &&
          isRecharterPeriod(unitInfo, RECHARTER_DATE_RESTRICTION) &&
          isKey3
        ) && (
          <NavMenuItem
            key="startRecharter"
            id="qa_startRecharter"
            inlineCollapsed={!showLabels}
            disabled={loading}
          >
            <NavLink to="/recharter">
              <NavMenuItemContent
                icon={<InfoOutlineIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="page.startRecharter" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}

        {isMobile ? (
          <SubMenu
            key="/settings"
            id="qa_mobileSettingsMenu"
            className={cn(
              styles.navOption,
              styles.navSection,
              styles.navSubMenu,
              styles.settingsMenu,
              {
                [styles.alwaysActive]: isSettingsRoute,
                Joyride__SettingsMenu: isMobile,
              },
            )}
            title={
              <NavMenuItemContent
                icon={<SettingsIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="core.Nav.settingsMenu" />
              </NavMenuItemContent>
            }
            disabled={loading}
            onTitleClick={this.handleSubMenuExpand}
          >
            <SettingsOptions
              isMobile={true}
              loading={loading}
              onClick={this.handleMenuClick}
            />
          </SubMenu>
        ) : (
          <NavMenuItem
            key="/settings"
            disabled={loading}
            className={cn(styles.settings, styles.bottom)}
          >
            <NavLink to="/settings" id="qa_settings">
              <NavMenuItemContent
                icon={<SettingsIcon />}
                showLabels={showLabels}
                loading={loading}
              >
                <FormattedMessage id="core.Header.Settings.settings" />
              </NavMenuItemContent>
            </NavLink>
          </NavMenuItem>
        )}
      </Menu>
    );
  }
}

Nav.propTypes = {
  // provided by parent
  isExpanded: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  // provided by component itself
  loading: PropTypes.bool.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  isSettingsRoute: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
  userPermissions: PropTypes.object.isRequired,
  isSelectedUnitValid: PropTypes.bool.isRequired,
  isYouthMember: PropTypes.bool.isRequired,
  isParentGuardian: PropTypes.bool,
  isParentOrYouthMember: PropTypes.bool,
  childUserId: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isCouncilOrgActive: PropTypes.bool.isRequired,
  isKey3: PropTypes.bool.isRequired,
  unitInfo: PropTypes.object.isRequired,
  shouldShowCalendar: PropTypes.bool,
};

const mapStateToProps = state => ({
  loading: !userDataInitializedSel(state),
  isSidebarOpen: sidebarOpenSel(state),
  isSettingsRoute: isSettingsRouteSel(state),
  pathname: pathnameSel(state),
  userPermissions: permissionsSel(state),
  isSelectedUnitValid: isSelectedOrganizationValidSel(state),
  isYouthMember: isYouthMemberSel(state),
  isParentGuardian: isParentGuardianSel(state),
  isParentOrYouthMember: isParentOrYouthMemberSel(state),
  childUserId: childUserIdSel(state),
  isCouncilOrgActive: isCouncilOrgActiveSel(state),
  isKey3: isKey3Sel(state),
  unitInfo: unitInfoSel(state),
  shouldShowCalendar: (() => {
    const permission = permissionsSel(state);
    const isParentGuardian = isParentGuardianSel(state);
    const unitInfo = unitInfoSel(state);
    const [newlySelectedUnit] = state.context.organizationGuid.split('*');

    if (sessionStorage.getItem(`unit_betacalendar_${newlySelectedUnit}`))
      return true;

    if (isParentGuardian || permission.VIEW_CALENDAR_PAGE) {
      return true;
    }

    if (!unitInfo) return false;

    const unitGuid = unitInfo.organizationGuid;
    if (sessionStorage.getItem(`unit_betacalendar_${unitGuid}`)) return true;

    return true;
  })(),
});

export default connect(mapStateToProps)(Nav);
