/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, S, TableMobile } from '@shared';
import { activityItemsTypes } from '../../../../../common';
import styles from './advancementHistoryListRows.less';

const { StickyTitle } = TableMobile;
const tableHeaderHeight = 38;

function advancementHistoryListMainRows({
  item,
  searchPinned,
  areAllPendingItemsSelected,
  onSelectedAllItems,
}) {
  const { type } = item;

  return [
    {
      rowId: 'header',
      className: styles.mainRow,
      content: (
        <StickyTitle
          className={styles.mainRowTitle}
          offsetTop={tableHeaderHeight}
          clearSearch={searchPinned}
        >
          <div className={styles.mainRowContent}>
            <S size="4" bold>
              <FormattedMessage
                id={`advancement.PackRosterList.${activityItemsTypes[type]}`}
              />
            </S>
          </div>
        </StickyTitle>
      ),
    },
    {
      rowId: 'headerAction',
      omit: type == activityItemsTypes.HISTORY,
      className: styles.buttonContainer,
      content: (
        <Button onClick={onSelectedAllItems} noBorder bold fitText>
          <FormattedMessage
            id={
              areAllPendingItemsSelected
                ? 'advancement.PackRosterList.selectNone'
                : 'advancement.PackRosterList.selectAll'
            }
          />
        </Button>
      ),
    },
  ];
}

export default advancementHistoryListMainRows;
