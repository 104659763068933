import { gtm } from '@shared';

import {
  RECORD_ADVANCEMENT_REQUEST,
  advancementModuleNamespace,
  recordAdvancementRequest,
} from '../../common';
import { isAdditionalTabActiveSel } from './selectors';

export { RECORD_ADVANCEMENT_REQUEST, recordAdvancementRequest };
export { advancementHistoryRequestIfNotLoaded } from '@shared';

const namespace = `${advancementModuleNamespace}`;

export const OPEN_RECORD_ADVANCEMENT = `${namespace}/OPEN_RECORD_ADVANCEMENT`;
export const CLOSE_RECORD_ADVANCEMENT = `${namespace}/CLOSE_RECORD_ADVANCEMENT`;
export const openRecordAdvancement = userIds => ({
  type: OPEN_RECORD_ADVANCEMENT,
  payload: userIds,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const closeRecordAdvancement = () => ({
  type: CLOSE_RECORD_ADVANCEMENT,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const SET_COMPLETION_DATE = `${namespace}/SET_COMPLETION_DATE`;
export const setCompletionDate = date => ({
  type: SET_COMPLETION_DATE,
  payload: date,
});

export const SET_ADVANCEMENT_TYPE = `${namespace}/SET_ADVANCEMENT_TYPE`;
export const setAdvancementType = type => ({
  type: SET_ADVANCEMENT_TYPE,
  payload: type,
});

export const SET_ADVANCEMENT = `${namespace}/SET_ADVANCEMENT`;
export const setAdvancement = id => ({ type: SET_ADVANCEMENT, payload: id });

export const RESET_ADVANCEMENT = `${namespace}/RESET_ADVANCEMENT`;
export const resetAdvancement = () => ({ type: RESET_ADVANCEMENT });

export const RECORD_ADVANCEMENT_RESPONSE = `${namespace}/RECORD_ADVANCEMENT_RESPONSE`;
export const RECORD_ADVANCEMENT_DEFERRED = `${namespace}/RECORD_ADVANCEMENT_DEFERRED`;
export const RECORD_ADVANCEMENT_ERROR = `${namespace}/RECORD_ADVANCEMENT_ERROR`;
export const recordAdvancementResponse = advancementType => ({
  type: RECORD_ADVANCEMENT_RESPONSE,
  payload: advancementType,
});
export const recordAdvancementDeferred = () => ({
  type: RECORD_ADVANCEMENT_DEFERRED,
});
export const recordAdvancementError = err => ({
  type: RECORD_ADVANCEMENT_ERROR,
  payload: err,
});

export const VERIFY_ADVANCEMENT_REQUEST = `${namespace}/VERIFY_ADVANCEMENT_REQUEST`;
export const VERIFY_ADVANCEMENT_RESPONSE = `${namespace}/VERIFY_ADVANCEMENT_RESPONSE`;
export const VERIFY_ADVANCEMENT_ERROR = `${namespace}/VERIFY_ADVANCEMENT_ERROR`;
const makeVerifyAdvancementRequestAction = type => () => ({
  type,
});
const makeVerifyAdvancementResponseAction =
  type =>
  (verifiedUsers = []) => ({
    type,
    payload: verifiedUsers,
  });
const makeVerifyAdvancementErrorAction = type => err => ({
  type,
  payload: err,
});
export const verifyAdvancementRequest = makeVerifyAdvancementRequestAction(
  VERIFY_ADVANCEMENT_REQUEST,
);
export const verifyAdvancementResponse = makeVerifyAdvancementResponseAction(
  VERIFY_ADVANCEMENT_RESPONSE,
);
export const verifyAdvancementError = makeVerifyAdvancementErrorAction(
  VERIFY_ADVANCEMENT_ERROR,
);

export const VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST = `${namespace}/VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST`;
export const VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE = `${namespace}/VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE`;
export const VERIFY_ADDITIONAL_ADVANCEMENT_ERROR = `${namespace}/VERIFY_ADDITIONAL_ADVANCEMENT_ERROR`;
export const verifyAdditionalAdvancementRequest =
  makeVerifyAdvancementRequestAction(VERIFY_ADDITIONAL_ADVANCEMENT_REQUEST);
export const verifyAdditionalAdvancementResponse =
  makeVerifyAdvancementResponseAction(VERIFY_ADDITIONAL_ADVANCEMENT_RESPONSE);
export const verifyAdditionalAdvancementError =
  makeVerifyAdvancementErrorAction(VERIFY_ADDITIONAL_ADVANCEMENT_ERROR);

export const SET_FORM_TAB = `${namespace}/SET_FORM_TAB`;
export const setFormTab = tab => ({
  type: SET_FORM_TAB,
  payload: tab,
});

export const SET_ADDITIONAL_COMPLETION_DATE = `${namespace}/SET_ADDITIONAL_COMPLETION_DATE`;
export const setAdditionalCompletionDate = date => ({
  type: SET_ADDITIONAL_COMPLETION_DATE,
  payload: date,
});

export const SET_ADDITIONAL_ADVANCEMENT_TYPE = `${namespace}/SET_ADDITIONAL_ADVANCEMENT_TYPE`;
export const setAdditionalAdvancementType = type => ({
  type: SET_ADDITIONAL_ADVANCEMENT_TYPE,
  payload: type,
});

export const SET_ADDITIONAL_ADVANCEMENT = `${namespace}/SET_ADDITIONAL_ADVANCEMENT`;
export const setAdditionalAdvancement = id => ({
  type: SET_ADDITIONAL_ADVANCEMENT,
  payload: id,
});

export const RESET_ADDITIONAL_ADVANCEMENT = `${namespace}/RESET_ADDITIONAL_ADVANCEMENT`;
export const resetAdditionalAdvancement = () => ({
  type: RESET_ADDITIONAL_ADVANCEMENT,
});

export const ADD_SUGGESTED = `${namespace}/ADD_SUGGESTED`;
export const ADD_ADDITIONAL_SUGGESTED = `${namespace}/ADD_ADDITIONAL_SUGGESTED`;

export const addSuggested = userId => ({
  type: ADD_SUGGESTED,
  payload: userId,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const addAdditionalSuggested = userId => ({
  type: ADD_ADDITIONAL_SUGGESTED,
  payload: userId,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const resetGeneralAdvancement = () => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive ? resetAdditionalAdvancement() : resetAdvancement(),
  );
};

export const setGeneralCompletionDate = date => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive
      ? setAdditionalCompletionDate(date)
      : setCompletionDate(date),
  );
};

export const verifyGeneralAdvancementRequest = () => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive
      ? verifyAdditionalAdvancementRequest()
      : verifyAdvancementRequest(),
  );
};

export const setGeneralAdvancementType = type => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive
      ? setAdditionalAdvancementType(type)
      : setAdvancementType(type),
  );
};

export const setGeneralAdvancement = id => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive ? setAdditionalAdvancement(id) : setAdvancement(id),
  );
};

export const addGeneralSuggested = userId => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive
      ? addAdditionalSuggested(userId)
      : addSuggested(userId),
  );
};

export const SET_ADVANCEMENT_STATUS = `${namespace}/SET_ADVANCEMENT_STATUS`;
export const setAdvancementStatus = status => ({
  type: SET_ADVANCEMENT_STATUS,
  payload: status,
});

export const SET_ADDITIONAL_ADVANCEMENT_STATUS = `${namespace}/SET_ADDITIONAL_ADVANCEMENT_STATUS`;
export const setAdditionalAdvancementStatus = status => ({
  type: SET_ADDITIONAL_ADVANCEMENT_STATUS,
  payload: status,
});

export const setGeneralAdvancementStatus = status => (dispatch, getState) => {
  const isAdditionalTabActive = isAdditionalTabActiveSel(getState());
  dispatch(
    isAdditionalTabActive
      ? setAdditionalAdvancementStatus(status)
      : setAdvancementStatus(status),
  );
};
