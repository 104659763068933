import React, { useEffect, useState } from 'react';

import { Button, Col, Radio, Row, Tooltip } from 'bsa-ui';
import cn from 'classnames';
import { AsYouType, format } from 'libphonenumber-js';
import { clone } from 'lodash';
import AddCircleIcon from 'material-ui-icons/AddCircle';
import CancelIcon from 'material-ui-icons/Cancel';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { phoneTypeIds, subscriptionIds } from '../../constants';
import {
  isLoadingDeleteEmailModalSel,
  setIsLoadingDeleteEmailModal,
  setShowDeletePhoneModal,
  showDeletePhoneModalSel,
} from '../../duck';
import { intl } from '../../localization';
import isValueTruthy from '../../utils/isValueTruthy';
import Checkbox from '../Checkbox';
import Form from '../Form';
import Input from '../Input';
import S from '../S';
import Select from '../Select';
import T from '../T';
import { DeleteEmailModal } from './DeleteEmailModal';
import styles from './ProfileContactInfoCard.less';

const { Item: FormItem } = Form;
const usPhoneCountryId = 636;
const nullPhoneCountryId = 635;

export const PhoneForm = ({
  form,
  profile,
  comunicationTypes,
  phoneCountries,
  phoneCarriers,
  isUnregisteredUser,
  onConfirmDelete,
  isReadOnly,
}) => {
  const dispatch = useDispatch();
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const { phones = [], subscriptions = [] } = profile;
  const [formPhones, setFormPhones] = useState(
    phones.length
      ? clone(phones)
      : [{ id: `id_${uuid()}`, countryId: usPhoneCountryId, isPrimary: true }],
  );
  const [idToDelete, setIdToDelete] = useState(undefined);
  const showModal = useSelector(showDeletePhoneModalSel);
  const isDeleting = useSelector(isLoadingDeleteEmailModalSel);

  useEffect(() => {
    if (phones.length) {
      setFormPhones(clone(phones));
    }
  }, [phones]);

  const onChangePrimary = phoneId => () => {
    const formPhones = getFieldValue('phones');
    const [key, phoneValues] = Object.entries(formPhones).find(
      ([, value]) => value.isPrimary && phoneId !== value.id,
    );
    const cloneFormPhones = clone(formPhones);
    cloneFormPhones[key] = { ...phoneValues, isPrimary: false };
    setFieldsValue({ phones: cloneFormPhones });
  };

  const isPrimary = phoneId => {
    const phones = getFieldValue('phones');
    const phone = phones[`id_${phoneId}`];
    return phone.isPrimary;
  };

  const isCarrierRequired = phoneId => {
    const phones = getFieldValue('phones');
    const phoneSubcriptions = getFieldValue('phoneSubcriptions');
    const { typeId } = phones[`id_${phoneId}`];
    if (phoneSubcriptions && !phoneSubcriptions.length) {
      return false;
    }

    return (
      typeId &&
      (Number(typeId) === phoneTypeIds.MOBILE ||
        Number(typeId) === phoneTypeIds.SMS)
    );
  };

  const isCarrierEnabled = phoneId => {
    const phones = getFieldValue('phones');
    const { typeId } = phones[`id_${phoneId}`];

    return (
      typeId &&
      (Number(typeId) === phoneTypeIds.MOBILE ||
        Number(typeId) === phoneTypeIds.SMS)
    );
  };

  const onClickAddPhone = () => {
    const cloneFormPhones = clone(formPhones);
    cloneFormPhones.push({
      id: `id_${uuid()}`,
      countryId: usPhoneCountryId,
      isPrimary: false,
    });
    setFormPhones(cloneFormPhones);
  };

  const deselectSubWhenRemovingMobile = phoneId => {
    const valuePhones = getFieldValue('phones');
    const filteredPhones = Object.entries(valuePhones).filter(
      ([key]) => key !== `id_${phoneId}`,
    );
    const hasMobile = filteredPhones.some(
      ([, value]) => Number(value.typeId) === phoneTypeIds.MOBILE,
    );
    if (!hasMobile) {
      setFieldsValue({ phoneSubcriptions: [] });
    }
  };

  const onClickDelete = phoneId => () => {
    const indexOfPhone = formPhones.findIndex(phone => phone.id === phoneId);
    const isNewPhone = phoneId.startsWith('id_');

    if (isNewPhone && indexOfPhone !== -1) {
      const cloneFormPhones = clone(formPhones);
      cloneFormPhones.splice(indexOfPhone, 1);
      setFormPhones(cloneFormPhones);
      deselectSubWhenRemovingMobile(phoneId);
    }

    if (!isNewPhone) {
      setIdToDelete(Number(phoneId));
      dispatch(setShowDeletePhoneModal(true));
    }
  };

  const handleConfirmDelete = () => {
    dispatch(setIsLoadingDeleteEmailModal(true));
    const phoneToDelete = formPhones.find(
      phone => Number(phone.id) === idToDelete,
    );
    const sbPhones = {
      homePhone:
        Number(phoneToDelete.typeId) === phoneTypeIds.HOME ? '' : undefined,
      mobilePhone:
        Number(phoneToDelete.typeId) === phoneTypeIds.MOBILE ? '' : undefined,
      workPhone:
        Number(phoneToDelete.typeId) === phoneTypeIds.WORK ? '' : undefined,
    };
    onConfirmDelete(idToDelete, sbPhones);

    deselectSubWhenRemovingMobile(phoneToDelete.id);
  };

  const onCancelDelete = () => {
    if (!isDeleting) {
      dispatch(setShowDeletePhoneModal(false));
    }
  };

  const fullPhone = phone => {
    const { areaCode, prefix, lineNumber } = phone;
    if (areaCode && prefix && lineNumber) {
      const phoneNumber = `${areaCode}${prefix}${lineNumber}`;

      return phoneNumber.length === 10
        ? format(phoneNumber, 'US', 'National')
        : phoneNumber;
    }

    return '';
  };

  const normalize = value => {
    if (!value) return '';
    const valueLength = value.length;
    const numbers = value ? value.replace(/\D/g, '') : '';

    if (valueLength > 14) return value;

    // do not format when deleting parentheses.
    if (numbers.length <= 3) return value;

    return new AsYouType('US').input(numbers);
  };

  const isSubscribed = () => {
    const subsTopics = subscriptions.filter(
      ({ topicId }) => topicId == subscriptionIds.TEXT,
    );

    if (subsTopics.length) {
      const optOut = subsTopics.some(({ optOutFlag }) => optOutFlag);

      return !optOut;
    }

    return false;
  };

  const hideAddBtn = () => isUnregisteredUser && formPhones.length === 3;

  // TODO: remove when IA replaces SB
  const handleChangeSub = value => {
    const valuePhones = getFieldValue('phones');
    const foundMobile = Object.entries(valuePhones).find(
      ([, phone]) => Number(phone.typeId) === phoneTypeIds.MOBILE,
    );

    if (value.length && !foundMobile) {
      const cloneFormPhones = clone(formPhones);
      cloneFormPhones.push({
        id: `id_${uuid()}`,
        countryId: usPhoneCountryId,
        isPrimary: false,
        typeId: `${phoneTypeIds.MOBILE}`,
      });
      setFormPhones(cloneFormPhones);
    }

    if (!value.length && foundMobile) {
      const cloneFormPhones = clone(valuePhones);
      Object.entries(valuePhones).forEach(([key, value]) => {
        if (Number(value.typeId) === phoneTypeIds.MOBILE) {
          cloneFormPhones[key].mobilePhoneCarrier = undefined;
        }
      });
      setFieldsValue({ phones: cloneFormPhones });
    }
  };

  // TODO: remove when IA replaces SB
  const handleChangeCarrier = phoneId => value => {
    const phones = getFieldValue('phones');
    const { typeId } = phones[`id_${phoneId}`];
    if (
      value &&
      typeId &&
      (Number(typeId) === phoneTypeIds.MOBILE ||
        Number(typeId) === phoneTypeIds.SMS)
    ) {
      setFieldsValue({ phoneSubcriptions: ['True'] });
    }
  };

  // TODO: remove when IA replaces SB
  const handleChangeType = phoneId => value => {
    const phones = getFieldValue('phones');
    const { mobilePhoneCarrier } = phones[`id_${phoneId}`];
    if (
      mobilePhoneCarrier &&
      (Number(value) === phoneTypeIds.MOBILE ||
        Number(value) === phoneTypeIds.SMS)
    ) {
      setFieldsValue({ phoneSubcriptions: ['True'] });
    }
  };

  return (
    <div>
      <div className={styles.subTitleContainer}>
        <T size="5" colored>
          <FormattedMessage id="shared.contactInfo.phoneTitle" />
        </T>
      </div>
      {formPhones.map((phone, index) => (
        <Row type="flex" align="start" key={index}>
          <Col xs={24} lg={3}>
            <FormItem>
              {getFieldDecorator(`phones.id_${phone.id}.isPrimary`, {
                initialValue: isValueTruthy(phone.isPrimary),
              })(
                <Radio.Group
                  onChange={onChangePrimary(phone.id)}
                  disabled={isUnregisteredUser || isReadOnly}
                >
                  <Tooltip
                    placement="top"
                    trigger="focus"
                    title={
                      <FormattedMessage id="shared.contactInfo.primaryHint" />
                    }
                  >
                    <Radio value={true} className={styles.radio}>
                      <FormattedMessage id="shared.contactInfo.primary" />
                    </Radio>
                  </Tooltip>
                </Radio.Group>,
              )}
            </FormItem>
          </Col>
          <Col xs={23} lg={5}>
            <FormItem className={styles.smallInput}>
              {getFieldDecorator(`phones.id_${phone.id}.typeId`, {
                initialValue: phone.typeId,
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'shared.form.error.isRequired',
                    }),
                  },
                ],
              })(
                <Select
                  rounded
                  size="large"
                  floatingLabel
                  placeholder={intl.formatMessage({
                    id: 'shared.contactInfo.phoneType',
                  })}
                  optionFilterProp="text"
                  showSearch
                  onChange={handleChangeType(phone.id)}
                  disabled={isReadOnly}
                >
                  <Select.OptGroup>
                    {isUnregisteredUser &&
                      comunicationTypes
                        .filter(
                          ({ classificationId, id }) =>
                            classificationId == 1 && id > 2 && id < 6,
                        )
                        .map(({ id = '-', name }) => (
                          <Select.Option key={id} value={id} text={name}>
                            {name}
                          </Select.Option>
                        ))}
                    {!isUnregisteredUser &&
                      comunicationTypes
                        .filter(({ classificationId }) => classificationId == 1)
                        .map(({ id = '-', name }) => (
                          <Select.Option key={id} value={id} text={name}>
                            {name}
                          </Select.Option>
                        ))}
                  </Select.OptGroup>
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col xs={23} lg={5}>
            <FormItem className={styles.smallInput}>
              {getFieldDecorator(`phones.id_${phone.id}.mobilePhoneCarrier`, {
                initialValue: phone.mobilePhoneCarrier
                  ? phone.mobilePhoneCarrier
                  : undefined,
                rules: [
                  {
                    required: isCarrierRequired(phone.id),
                    message: intl.formatMessage({
                      id: 'shared.contactInfo.phoneCarrier.Error',
                    }),
                  },
                ],
              })(
                <Select
                  rounded
                  size="large"
                  floatingLabel
                  placeholder={`${intl.formatMessage({
                    id: 'shared.contactInfo.phoneCarrier',
                  })}${isCarrierRequired(phone.id) ? '*' : ''}`}
                  optionFilterProp="text"
                  showSearch
                  allowClear={!isCarrierRequired(phone.id)}
                  disabled={
                    (isUnregisteredUser && !isCarrierEnabled(phone.id)) ||
                    isReadOnly
                  }
                  onChange={handleChangeCarrier(phone.id)}
                >
                  <Select.OptGroup>
                    {phoneCarriers.map(({ id = '-', name }) => (
                      <Select.Option key={id} value={name} text={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>,
              )}
            </FormItem>
          </Col>
          <Col xs={23} lg={9}>
            <div className={styles.selectInputContainer}>
              <div className={styles.countryContainer}>
                <FormItem>
                  {getFieldDecorator(`phones.id_${phone.id}.countryId`, {
                    initialValue: phone.countryId || nullPhoneCountryId,
                  })(
                    <Select
                      rounded
                      size="large"
                      floatingLabel
                      placeholder={intl.formatMessage({
                        id: 'shared.contactInfo.countryId',
                      })}
                      optionFilterProp="text"
                      showSearch
                      disabled={isUnregisteredUser || isReadOnly}
                    >
                      <Select.OptGroup>
                        {phoneCountries.map(({ name, id }) => (
                          <Select.Option
                            key={id}
                            value={Number(id)}
                            text={name}
                          >
                            {name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>,
                  )}
                </FormItem>
              </div>
              <div className={styles.phoneInputContainer}>
                <FormItem>
                  {getFieldDecorator(`phones.id_${phone.id}.phone`, {
                    normalize: normalize,
                    initialValue: fullPhone(phone),
                    rules: [
                      {
                        max: 14,
                        message: intl.formatMessage({
                          id: 'shared.contactInfo.phone.length',
                        }),
                      },
                      {
                        pattern:
                          '^[+]*[0-9]{0,2}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
                        message: intl.formatMessage({
                          id: 'shared.contactInfo.phone.valid',
                        }),
                      },
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'profile.ProfileInfo.contactInfo.fieldError.phone',
                        }),
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      placeholder={`${intl.formatMessage({
                        id: 'shared.contactInfo.phoneNumber',
                      })}*`}
                      rounded
                      floatingLabel
                      disabled={isReadOnly}
                    />,
                  )}
                </FormItem>
              </div>
            </div>
          </Col>
          <Col xs={1} lg={1}>
            <FormItem>
              <div
                className={cn(
                  styles.deleteEmailContainer,
                  (isValueTruthy(phone.isPrimary) || isPrimary(phone.id)) &&
                    styles.hideButton,
                )}
              >
                <Tooltip title="delete">
                  <Button
                    ghost
                    shape="circle"
                    className={styles.deleteButton}
                    onClick={onClickDelete(phone.id)}
                    hidden={isReadOnly}
                  >
                    <CancelIcon className={styles.deleteIcon} />
                  </Button>
                </Tooltip>
              </div>
            </FormItem>
          </Col>
        </Row>
      ))}
      {!hideAddBtn() && (
        <Row type="flex">
          <Col xs={24} lg={22} className={styles.addEmailContainer}>
            <Button
              ghost
              className={styles.addEmailBtn}
              onClick={onClickAddPhone}
              hidden={isReadOnly}
            >
              <FormattedMessage id="shared.contactInfo.addPhoneNumber" />
              <AddCircleIcon className={styles.addIcon} />
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24}>
          <FormItem className={styles.notificationFormItem}>
            {getFieldDecorator('phoneSubcriptions', {
              initialValue: isSubscribed() ? ['True'] : [],
            })(
              <Checkbox.Group
                onChange={handleChangeSub}
                className={styles.notificationContainer}
                disabled={isUnregisteredUser}
                hidden={isReadOnly}
              >
                <div className={styles.checkboxContainer}>
                  <Checkbox value="True" />
                </div>
                <S size="4" inline>
                  <FormattedMessage id={'shared.contactInfo.wantTexts'} />
                </S>
              </Checkbox.Group>,
            )}
          </FormItem>
        </Col>
      </Row>
      <DeleteEmailModal
        visible={showModal}
        isLoading={isDeleting}
        titleId="deletePhone"
        onCancel={onCancelDelete}
        onAccept={handleConfirmDelete}
      />
    </div>
  );
};

PhoneForm.propTypes = {
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  comunicationTypes: PropTypes.array.isRequired,
  phoneCountries: PropTypes.array.isRequired,
  phoneCarriers: PropTypes.array.isRequired,
  isUnregisteredUser: PropTypes.bool,
  onConfirmDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};
