import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from 'react-intl';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dropdown from '../../../../Dropdown';
import Menu from '../../../../Menu';
import Button from '../../../../Button';
import styles from './RSVPActionsMenu.less';

const { Item: MenuItem } = Menu;

const RSVPActionsMenu = ({
  data,
  rsvpKey,
  onRemoveAction,
  onRSVPAction,
  isRSVP,
}) => (
  <Dropdown
    overlay={
      <Menu className={styles.actionsMenu}>
        {isRSVP && rsvpKey != 'shared.rsvp.yes' && (
          <MenuItem>
            <Button
              onClick={() => onRSVPAction(data, true)}
              type="link"
              block
              uppercase={false}
            >
              <CheckCircleIcon className={styles.rsvpYesIcon} />{' '}
              <FormattedMessage id="shared.rsvpActionMenu.yes" />
            </Button>
          </MenuItem>
        )}
        {isRSVP && rsvpKey != 'shared.rsvp.maybe' && (
          <MenuItem>
            <Button
              onClick={() => onRSVPAction(data, null)}
              type="link"
              block
              uppercase={false}
            >
              <HelpIcon className={styles.rsvpMaybeIcon} />
              <FormattedMessage id="shared.rsvpActionMenu.maybe" />
            </Button>
          </MenuItem>
        )}
        {isRSVP && rsvpKey != 'shared.rsvp.no' && (
          <MenuItem>
            <Button
              onClick={() => onRSVPAction(data, false)}
              type="link"
              block
              uppercase={false}
            >
              <CancelIcon className={styles.rsvpNoIcon} />{' '}
              <FormattedMessage id="shared.rsvpActionMenu.no" />
            </Button>
          </MenuItem>
        )}
        <MenuItem>
          <Button
            onClick={() => onRemoveAction(data)}
            type="link"
            block
            uppercase={false}
          >
            <DeleteForeverIcon className={styles.rsvpRemoveIcon} />{' '}
            <FormattedMessage id="shared.rsvpActionMenu.remove" />
          </Button>
        </MenuItem>
      </Menu>
    }
    trigger={['click']}
    placement="bottomRight"
  >
    <span className={styles.moreIcon}>
      <MoreVertIcon />
    </span>
  </Dropdown>
);

RSVPActionsMenu.propTypes = {
  data: PropTypes.object.isRequired,
  rsvpKey: PropTypes.string,
  onRemoveAction: PropTypes.func,
  onRSVPAction: PropTypes.func,
  isRSVP: PropTypes.bool,
};

RSVPActionsMenu.defaultProps = {
  data: [],
  onRemoveAction: () => {},
  onRSVPAction: () => {},
  isRSVP: true,
};

export default RSVPActionsMenu;
