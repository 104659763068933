import { esbApiService } from '@utils';

const declineActivity$ = ({ organizationGuid, activities }) =>
  esbApiService.post$(
    '/advancements/v2/activities/decline',
    {
      organizationGuid,
      activities,
    },
    { gtm: '/advancements/v2/activities/decline' },
  );

export default {
  declineActivity$,
};
