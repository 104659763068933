import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeInviteMembersModal,
  isInviteMembersModalVisible,
} from '../../../duck';
import InviteMembersForm from './InviteMembersForm';
import styles from './InviteMembersModal.less';

const InviteMembersModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isInviteMembersModalVisible);

  const handleCloseInviteMembersModal = () =>
    dispatch(closeInviteMembersModal());

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.inviteMembersModal.title',
      })}
      onCancel={handleCloseInviteMembersModal}
      onBack={handleCloseInviteMembersModal}
    >
      <InviteMembersForm />
    </Modal>
  );
};

export default InviteMembersModal;
