import { gtm } from '@shared';

import { editAdvancementModuleNamespace as namespace } from '../../common';

export const OPEN_EDIT_ADVANCEMENT = `${namespace}/OPEN_EDIT_ADVANCEMENT`;
export const CLOSE_EDIT_ADVANCEMENT = `${namespace}/CLOSE_EDIT_ADVANCEMENT`;
export const openEditAdvancement = (person, advancement, fromProfile) => ({
  type: OPEN_EDIT_ADVANCEMENT,
  payload: { advancement, person, fromProfile },
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const closeEditAdvancement = () => ({
  type: CLOSE_EDIT_ADVANCEMENT,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});

export const SET_FROM_PROFILE = `${namespace}/SET_FROM_PROFILE`;
export const setFromProfile = fromProfile => ({
  type: SET_FROM_PROFILE,
  payload: fromProfile,
});

export const UPDATE_ADVANCEMENT_REQUEST = `${namespace}/UPDATE_ADVANCEMENT_REQUEST`;
export const UPDATE_ADV_SUMMARY_REQUEST = `${namespace}/UPDATE_ADV_SUMMARY_REQUEST`;
export const UPDATE_ADVANCEMENT_RESPONSE = `${namespace}/UPDATE_ADVANCEMENT_RESPONSE`;
export const UPDATE_ADVANCEMENT_DEFERRED = `${namespace}/UPDATE_ADVANCEMENT_DEFERRED`;
export const UPDATE_OFFLINE_ADVANCEMENT = `${namespace}/UPDATE_OFFLINE_ADVANCEMENT`;
export const UPDATE_ADVANCEMENT_ERROR = `${namespace}/UPDATE_ADVANCEMENT_ERROR`;
export const updateAdvancementRequest = updatedValues => ({
  type: UPDATE_ADVANCEMENT_REQUEST,
  payload: updatedValues,
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const updateAdvancementResponse = advancement => ({
  type: UPDATE_ADVANCEMENT_RESPONSE,
  payload: advancement,
});
export const updateAdvancementDeferred = () => ({
  type: UPDATE_ADVANCEMENT_DEFERRED,
});
export const updateAdvancementError = error => ({
  type: UPDATE_ADVANCEMENT_ERROR,
  payload: error,
});
export const updateOfflineAdvancement = () => ({
  type: UPDATE_OFFLINE_ADVANCEMENT,
});
export const RELOAD_ADVANCEMENT_REQUEST = `${namespace}/RLOAD_ADVANCEMENT_REQUEST`;
export const reloadAdvancementRequest = () => ({
  type: RELOAD_ADVANCEMENT_REQUEST,
});

export const UPDATE_ACTIVITY_RESPONSE = `${namespace}/UPDATE_ACTIVITY_RESPONSE`;
export const updateActivityResponse = ({
  activityId,
  userId,
  updatedRecords,
}) => ({
  type: UPDATE_ACTIVITY_RESPONSE,
  payload: { activityId, userId, updatedRecords },
});
