import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';

import { StateSelect, emptyArr } from '@shared';
import {
  dictionaryIds,
  dictionarySel,
  dictionaryLoadedSel,
  dictionariesRequest,
} from '@dict';

const AddressState = ({ form, disabled, className }) => {
  const dispatch = useDispatch();
  const loaded = useSelector(state =>
    dictionaryLoadedSel(state, dictionaryIds.STATES),
  );
  const states = useSelector(
    state => dictionarySel(state, dictionaryIds.STATES) || emptyArr,
  );
  const { getFieldDecorator } = form;
  useEffect(() => {
    dispatch(dictionariesRequest(dictionaryIds.STATES));
  }, [dispatch]);

  return getFieldDecorator('akelaStateId')(
    <StateSelect
      className={className}
      floatingLabel
      rounded
      disabled={disabled}
      size="large"
      states={states}
      statesLoaded={loaded}
    />,
  );
};

AddressState.propTypes = {
  // provided by parent
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  // provided by component itself
  loaded: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  onLoadStates: PropTypes.func.isRequired,
};

const mapState = state => ({
  loaded: dictionaryLoadedSel(state, dictionaryIds.STATES),
  states: dictionarySel(state, dictionaryIds.STATES) || [],
});

const mapDispatch = dispatch => ({
  onLoadStates: () => dispatch(dictionariesRequest(dictionaryIds.STATES)),
});

export default connect(mapState, mapDispatch)(AddressState);
