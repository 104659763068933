import { sorters } from '@utils';

const reportPOIDSorter = sorters.number('POID');
export const defaultSorter = sorters.compose(
  sorters.date('DateCreated', true),
  sorters.number('POID', true),
);

export default {
  POID: reportPOIDSorter,
  default: defaultSorter,
};
