import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concat';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { manualOfflineHelper } from '@modules/shared/components';
import { logout } from '@shared/duck';
import { sentryReport } from '@shared/utils';

import isHttpSessionExpirationError from './isHttpSessionExpirationError';

/**
 * Exact same behavior as RxJS's `catch`
 * but with extra bells and whistles:
 * - reports the error to Sentry and to the console
 * - dispatches LOGOUT action on session expiration error from HTTP API
 *
 * @example
 *
 * someObservable$
 *  .catchAndReport(handler)
 *
 * @param {function} handler your error handler
 * @param {object} [options] extra options
 * @param {boolean} [options.reportAjaxErrors] useful when you know HTTP layer won't report AjaxErrors
 */
export default function (handler, options = {}) {
  return this.catch(err => {
    // AjaxErrors are normally handled in the http layer
    // but in some cases (e.g. when using progressSubscriber) that won't work
    // so we can optionally report those errors here
    if (err instanceof AjaxError) {
      if (options.reportAjaxErrors) {
        sentryReport.api(err);
      }
      manualOfflineHelper.checkApiError(err);
    } else {
      // eslint-disable-next-line no-console
      console.error(err);
      sentryReport.rxjs(err);
    }

    if (isHttpSessionExpirationError(err)) {
      return Observable.of(logout());
    }

    return handler.call(this, err);
  });
}
