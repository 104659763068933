import React, { ReactNode } from 'react';

import { ButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import Modal from '../Modal';
import Spin from '../Spin';
import styles from './ConfirmationModal.less';

type Props = {
  visible: boolean;
  onCancel: () => void;
  headerColor?: string;
  fullscreenMobile: boolean;
  primaryButtonProps: Omit<ButtonProps, 'icon'>;
  onConfirm: () => void;
  title: ReactNode;
  extraButtons: {
    props?: Omit<ButtonProps, 'icon'>;
    onClick: ButtonProps['onClick'];
    label: ReactNode;
    key: string;
  }[];
  loading?: boolean;
  roundedModal?: boolean;
};

const ConfirmationModal: React.FC<Props> = ({
  visible,
  onCancel,
  headerColor,
  fullscreenMobile,
  title,
  children,
  primaryButtonProps,
  onConfirm,
  extraButtons,
  loading,
  roundedModal,
}) => {
  const { children: buttonChildren } = primaryButtonProps || {};
  return (
    <Modal
      className={cn(styles.modal, { [styles.roundedModal]: roundedModal })}
      fullscreenMobile={fullscreenMobile}
      onCancel={onCancel}
      visible={visible}
      headerColor={headerColor}
      title={title}
    >
      <Spin spinning={loading || false} size="small" delay={200}>
        <div className={styles.modalContent}>
          {children}
          <div className={styles.buttons}>
            <Button
              shadow
              onClick={onCancel}
              color="scouting-warm-gray"
              type="primary"
              size="large"
            >
              <FormattedMessage id="shared.cancel" />
            </Button>
            {extraButtons.map(({ props, onClick, label, key }) => (
              <Button
                key={key}
                shadow
                onClick={onClick}
                type="primary"
                className={styles.primaryButton}
                size="large"
                {...(props || primaryButtonProps)}
              >
                {label}
              </Button>
            ))}
            <Button
              shadow
              onClick={onConfirm}
              type="primary"
              className={styles.primaryButton}
              size="large"
              {...primaryButtonProps}
            >
              {buttonChildren || <FormattedMessage id="shared.yes" />}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  fullscreenMobile: false,
  headerColor: 'scouting-warm-gray',
  primaryButtonProps: {
    color: 'success',
    loading: false,
  },
  title: <FormattedMessage id="shared.confirmation" />,
  extraButtons: [],
  roundedModal: false,
};

export default ConfirmationModal;
