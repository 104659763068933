import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { PersonAvatar, S } from '@shared';
import styles from './YouthList.less';

const YouthListItem = ({
  personName,
  advancements,
  isSelected,
  onSelect,
  isMobile,
}) => (
  <div
    className={cn(styles.youthItem, { [styles.selected]: isSelected })}
    onClick={onSelect}
  >
    <div className={styles.youthInfo}>
      <PersonAvatar isAdult={false} className={styles.avatar} />
      <div className={styles.youthData}>
        <S size={isMobile ? '5' : '4'} bold={isSelected}>
          {personName}
        </S>
        <S size={isMobile ? '6' : '5'}>
          <FormattedMessage
            id="offline.SyncPendingModal.youths.itemAdvancements"
            values={{ value: advancements.length }}
          />
        </S>
      </div>
    </div>
  </div>
);

YouthListItem.propTypes = {
  personName: PropTypes.string,
  advancements: PropTypes.array,
  isSelected: PropTypes.bool,
  isMobile: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default YouthListItem;
