import { dateAndTimeToDateTime } from '@utils';

import {
  activityTypeIds,
  saveActivity$,
  mapPersonValuesToDto,
  activityValueTypes,
  normalizeAdvancedValues,
} from '../../common';
import { notApplicableCategoryId } from '../constants';

const saveCampoutActivity$ = data => {
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    organizationGuid,
    loggedInUserId,
    persons,
    isAdvancedMode,
    basicYouthDays,
    basicYouthNights,
    basicAdultDays,
    basicAdultNights,
    basicYouthFrostPoints,
    basicAdultFrostPoints,
    youthDays,
    youthNights,
    adultDays,
    adultNights,
    youthFrostPoints,
    adultFrostPoints,
    isPersonalActivity = false,
    isApproved,
  } = data;

  const startDateTime = dateAndTimeToDateTime(startDate, startTime);
  const endDateTime = dateAndTimeToDateTime(endDate, endTime);
  const personValues = mapPersonValuesToDto({
    persons,
    organizationGuid,
    loggedInUserId,
    isAdvancedMode,
    isApproved,
    basicAdultValues: {
      [activityValueTypes.DAYS]: basicAdultDays,
      [activityValueTypes.NIGHTS]: basicAdultNights,
      [activityValueTypes.FROST_POINTS]: basicAdultFrostPoints,
    },
    basicYouthValues: {
      [activityValueTypes.DAYS]: basicYouthDays,
      [activityValueTypes.NIGHTS]: basicYouthNights,
      [activityValueTypes.FROST_POINTS]: basicYouthFrostPoints,
    },
    advancedValues: {
      [activityValueTypes.DAYS]: {
        ...normalizeAdvancedValues(adultDays),
        ...normalizeAdvancedValues(youthDays),
      },
      [activityValueTypes.NIGHTS]: {
        ...normalizeAdvancedValues(adultNights),
        ...normalizeAdvancedValues(youthNights),
      },
      [activityValueTypes.FROST_POINTS]: {
        ...normalizeAdvancedValues(youthFrostPoints),
        ...normalizeAdvancedValues(adultFrostPoints),
      },
    },
  });

  const processedData = {
    ...data,
    ...personValues,
    activityTypeId: activityTypeIds.CAMPOUTS,
    categoryId: notApplicableCategoryId,
    startDateTime,
    endDateTime,
    hostOrganizationGuid: organizationGuid,
    isPersonalActivity,
    isEveryChildOrg: false,
  };

  return saveActivity$(processedData);
};

export default {
  saveCampoutActivity$,
};
