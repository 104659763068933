import _ from 'lodash';
import { combineReducers } from 'redux';

import { SET_ORGANIZATION } from '@context';
import {
  ORG_ROSTER_ERROR,
  ORG_ROSTER_RESPONSE,
} from '@modules/advancement/common';
import {
  deleteSubUnitResponse,
  editSubUnitMembers,
  editSubUnitSuccess,
  getSubUnitsError,
  getSubUnitsResponse,
  saveSubUnitSuccess,
} from '@modules/advancement/subUnits/duck/actions';
import {
  getDeletedYouthPos,
  subUnitYouthDictionary,
} from '@modules/advancement/utilsTyped';
import { memberTypeLabels } from '@shared';
import { resetOnLogoutReducer } from '@utils';

import { DESELECT_PERSON } from '../../../progress/duck/actions';
import { UNASSIGNED, peopleFilters, renewalStatuses } from '../constants';
import {
  PACK_ROSTER_ERROR,
  PACK_ROSTER_FILTER,
  PACK_ROSTER_REQUEST,
  PACK_ROSTER_RESPONSE,
  PACK_ROSTER_SEARCH,
  PACK_ROSTER_SORT,
  SAVE_OPTOUT,
  SAVE_OPTOUT_ERROR,
  SET_MEMBER_TYPE_SECTION_EXPANDED,
  SET_SELECTED_KEYS,
  SET_SHOW_MEMBER_TYPES,
  SET_SHOW_SUB_UNITS,
  SET_SUB_UNITS_FILTER_TAGS,
  SET_SUB_UNITS_SECTION_EXPANDED,
  SET_SUB_UNIT_USER_DATA,
} from './actions';

const loading = (state = false, { type }) => {
  switch (type) {
    case PACK_ROSTER_REQUEST: {
      return true;
    }
    case PACK_ROSTER_RESPONSE:
    case PACK_ROSTER_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const items = (state = [], { type, payload }) => {
  switch (type) {
    case deleteSubUnitResponse.type: {
      const { subUnitId, isDen } = payload;
      const modifiedRoster = [...state].reduce((curr, member) => {
        if (!member.positions) return [...curr, member];

        const newPositions = member.positions.reduce((currPos, pos) => {
          const belongsToSubUnit = [pos.patrolId, pos.denId].includes(
            subUnitId,
          );
          if (belongsToSubUnit) {
            return member.isAdult
              ? [...currPos]
              : [...currPos, getDeletedYouthPos(isDen)];
          }
          return [...currPos, pos];
        }, []);
        const reducedMember = {
          ...member,
          positions: newPositions,
        };
        return [...curr, reducedMember];
      }, []);
      return modifiedRoster;
    }

    case editSubUnitMembers.type: {
      const { subUnit, addedYouths, addedAdults, deletedMembers, changedName } =
        payload;

      const modifiedRoster = [...state].reduce((curr, member) => {
        const userId = member.userId;

        const foundDeletedMemberPos = deletedMembers?.find(el => el === userId);
        if (foundDeletedMemberPos) {
          const reducedMember = {
            ...member,
            positions: member.positions.filter(
              pos => ![pos.patrolId, pos.denId].includes(subUnit.subUnitId),
            ),
          };
          return [...curr, reducedMember];
        }

        const addedMembers = [...addedYouths, ...addedAdults];
        const foundMemberPos = addedMembers.find(el => el.userId === userId);
        if (foundMemberPos) {
          const filteredPos = member.positions.filter(
            el => !_.values(subUnitYouthDictionary).includes(el.positionId),
          );
          const newPositions = [...filteredPos, foundMemberPos];
          const extendedMember = {
            ...member,
            positions: newPositions,
          };
          return [...curr, extendedMember];
        }
        if (changedName) {
          const newPositions = member?.positions?.map(pos => {
            if (subUnit.subUnitId === pos.patrolId) {
              return { ...pos, patrolName: subUnit.subUnitName };
            }

            if (subUnit.subUnitId === pos.denId) {
              return { ...pos, denNumber: subUnit.subUnitName };
            }
            return pos;
          });
          const modifiedMember = {
            ...member,
            positions: newPositions,
          };
          return [...curr, modifiedMember];
        }

        return [...curr, member];
      }, []);
      return modifiedRoster;
    }

    case PACK_ROSTER_RESPONSE: {
      return payload;
    }
    case PACK_ROSTER_ERROR:
    case SET_ORGANIZATION: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const selectedKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_SELECTED_KEYS: {
      return payload;
    }
    case DESELECT_PERSON: {
      return state.filter(id => id != payload);
    }
    case PACK_ROSTER_RESPONSE: {
      return payload
        .filter(({ memberId }) => state.includes(memberId))
        .map(({ memberId }) => memberId);
    }
    case PACK_ROSTER_ERROR:
    case PACK_ROSTER_FILTER:
    case SET_SHOW_SUB_UNITS:
    case SET_SHOW_MEMBER_TYPES: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case PACK_ROSTER_SEARCH: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const defaultFilter = {
  [peopleFilters.YOUTH]: true,
  [peopleFilters.AGED_YOUTH]: true,
  [peopleFilters.ADULTS]: false,
  [peopleFilters.DEN_CHIEFS]: false,
  [renewalStatuses.CURRENT]: false,
  [renewalStatuses.ELIGIBLE_TO_RENEW]: false,
  [renewalStatuses.EXPIRED]: false,
  [renewalStatuses.OPTED_OUT]: false,
  [renewalStatuses.RENEWED]: false,
};

const filter = (state = defaultFilter, { type, payload }) => {
  switch (type) {
    case PACK_ROSTER_FILTER: {
      return payload;
    }
    case SET_ORGANIZATION: {
      return defaultFilter;
    }
    default: {
      return state;
    }
  }
};

const sorter = (state = {}, { type, payload }) => {
  switch (type) {
    case PACK_ROSTER_SORT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showSubUnits = (state = false, { type, payload }) => {
  switch (type) {
    case SET_ORGANIZATION: {
      return false;
    }
    case SET_SHOW_SUB_UNITS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const subUnitsSectionExpanded = (state = {}, { type, payload }) => {
  switch (type) {
    case getSubUnitsResponse.type: {
      const subUnitsSectionsExpanded = payload?.subUnits?.reduce(
        (acc, subUnit) => ({ ...acc, [subUnit.subUnitId]: true }),
        {},
      );
      return { ...subUnitsSectionsExpanded, [UNASSIGNED]: true };
    }
    case SET_SUB_UNITS_SECTION_EXPANDED: {
      return { ...state, ...payload };
    }
    default: {
      return state;
    }
  }
};

const subUnitsFilterTags = (state = [], { type, payload }) => {
  switch (type) {
    case SET_ORGANIZATION:
    case getSubUnitsError.type: {
      return [];
    }
    case saveSubUnitSuccess.type: {
      const newSubUnit = payload?.subUnit;
      const denType = newSubUnit.denType ? `${newSubUnit.denType} ` : '';
      const newSubUnitFilter = {
        id: newSubUnit.subUnitId,
        name: `${denType}${newSubUnit.subUnitName}`,
        status: true,
        denType: newSubUnit.denType,
      };
      return [...state, newSubUnitFilter];
    }

    case editSubUnitSuccess.type: {
      const editedSubUnit = payload.subUnit;

      const denType = editedSubUnit.denType ? `${editedSubUnit.denType} ` : '';
      const editedSubUnitFilter = {
        id: editedSubUnit.subUnitId,
        name: `${denType}${editedSubUnit.subUnitName}`,
        status: true,
        denType: editedSubUnit.denType,
      };
      const editedSubUnitsFilter = state.reduce((curr, subUnitFilter) => {
        if (subUnitFilter.id === editedSubUnitFilter.id) {
          return [...curr, editedSubUnitFilter];
        }
        return [...curr, subUnitFilter];
      }, []);

      return editedSubUnitsFilter;
    }

    case getSubUnitsResponse.type: {
      const filters = payload?.subUnits
        .filter(subUnit => subUnit.isApproved)
        .map(subUnit => {
          const denType = subUnit.denType ? `${subUnit.denType} ` : '';
          return {
            id: subUnit.subUnitId,
            name: `${denType}${subUnit.subUnitName}`,
            status: true,
            denType: subUnit.denType,
          };
        });

      return [
        ...filters,
        {
          id: -1,
          name: UNASSIGNED,
          status: true,
        },
      ];
    }
    case SET_SUB_UNITS_FILTER_TAGS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const subUnitUserData = (
  state = { subUnitId: 0, subUnitName: '' },
  { type, payload },
) => {
  switch (type) {
    case SET_SUB_UNIT_USER_DATA: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const showMemberTypes = (state = false, { type, payload }) => {
  switch (type) {
    case SET_ORGANIZATION: {
      return false;
    }
    case SET_SHOW_MEMBER_TYPES: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const memberTypeSectionExpanded = (state = {}, { type, payload }) => {
  const defaultState = {
    [memberTypeLabels.adult]: true,
    [memberTypeLabels.youth]: true,
    [memberTypeLabels.participant]: true,
  };
  switch (type) {
    case SET_MEMBER_TYPE_SECTION_EXPANDED: {
      return { ...state, ...payload };
    }
    default: {
      return defaultState;
    }
  }
};

const renewalRosterInfo = (state = {}, { type, payload }) => {
  switch (type) {
    case ORG_ROSTER_RESPONSE:
      return payload;

    case ORG_ROSTER_ERROR:
    case SET_ORGANIZATION: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const loadingRenewal = (state = false, { type }) => {
  switch (type) {
    case SAVE_OPTOUT:
    case PACK_ROSTER_REQUEST: {
      return true;
    }
    case ORG_ROSTER_RESPONSE:
    case SAVE_OPTOUT_ERROR:
    case ORG_ROSTER_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  loading,
  items,
  selectedKeys,
  search,
  filter,
  sorter,
  showSubUnits,
  subUnitsSectionExpanded,
  subUnitsFilterTags,
  subUnitUserData,
  showMemberTypes,
  memberTypeSectionExpanded,
  renewalRosterInfo,
  loadingRenewal,
});

export default resetOnLogoutReducer(reducer);
