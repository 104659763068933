import flatten from 'lodash/flatten';
import { combineEpics, ofType } from 'redux-observable';
import { concat, forkJoin, of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { organizationGuidSel, organizationTypeIdSel } from '@context';
import { intl } from '@shared';
import { toastService } from '@toasts';
import { userIdSel as loggedInUserIdSel } from '@user';
import { mapActivityItemsDtoToModel } from '@utils';
import { catchAndReport } from '@utils/rxjs/operators';

import eventServices from '../../../events/duck/services';
import { APPROVE_ADVANCEMENTS_RESPONSE } from '../../approveAdvancements';
import { RECORD_CAMPOUTS_RESPONSE } from '../../campouts/duck/actions';
import {
  // actions
  DELETE_ACTIVITIY_RESPONSE,
  activityValuesTabs, // services
  getActivities$,
  getActivityById$,
  getEditActivityObject,
  removeActivity$,
  removeRegisteredParticipant$, // constants
  savingAction, // utils
  setTime,
} from '../../common';
import { DECLINE_ADVANCEMENT_RESPONSE } from '../../declineAdvancements';
import { RECORD_HIKE_RESPONSE } from '../../hikes';
import { RECORD_LONG_CRUISE_RESPONSE } from '../../longCruise';
import { RECORD_SERVICE_RESPONSE } from '../../service';
import {
  ACTIVITY_LOGS_REQUEST,
  GET_ACTIVITY_BY_ID_REQUEST,
  REMOVE_PARTICIPANT,
  REMOVE_PARTICIPANT_RESPONSE,
  SAVE_ACTIVITY_REQUEST,
  SAVE_ACTIVITY_RESPONSE,
  activityLogsError,
  activityLogsRequest,
  activityLogsResponse,
  getActivityByIdResponse,
  removeParticipantResponse,
  saveActivityError,
  saveActivityResponse,
  showRemoveParticipantModal,
} from './actions';
import {
  activeTabSel,
  activityLogsSel,
  currentLogsInfoSel,
  personsSel,
  selectedActivityDataSel,
} from './selectors';
import services from './services';

const getActivityLogs$ = action$ =>
  action$.pipe(
    ofType(ACTIVITY_LOGS_REQUEST),
    switchMap(({ payload: { activityTypeId, personGuid, userId } }) =>
      getActivities$({
        startDateTime: '1969-07-01',
        personGuid,
        activityTypeId: +activityTypeId,
        showAll: true,
        perPage: 1000,
      }).pipe(
        map(activities => {
          const youthActivities = mapActivityItemsDtoToModel(activities);
          return activityLogsResponse(
            youthActivities.filter(
              activity =>
                activity.activityTypeId === +activityTypeId &&
                activity.userId === userId,
            ),
          );
        }),
        catchAndReport(err => of(activityLogsError(err))),
      ),
    ),
  );

const getActivityByIdEpic$ = action$ =>
  action$.pipe(
    ofType(GET_ACTIVITY_BY_ID_REQUEST),
    switchMap(({ payload: { activityId, clone } }) =>
      getActivityById$(activityId).pipe(
        switchMap(activity => {
          const { calendarEventId } = activity;
          return forkJoin([
            of(activity),
            calendarEventId
              ? eventServices.getEventById$(calendarEventId)
              : of({}),
          ]);
        }),
        map(([activity, event]) => {
          let processedActivity;
          if (clone) {
            const {
              collaborativeOrganizations = [],
              akelaStateId = undefined,
            } = activity;
            processedActivity = {
              ...activity,
              id: undefined,
              name: `Copy of ${activity.name}`,
              registeredYouths: [],
              registeredAdults: [],
              akelaStateId: akelaStateId && `${activity.akelaStateId}`,
              collaborativeOrganizations: collaborativeOrganizations.map(
                item => item.collabOrgId,
              ),
              rsvp: event.canRsvp,
            };
          } else {
            processedActivity = getEditActivityObject({
              ...activity,
              rsvp: event.canRsvp,
            });
          }
          return getActivityByIdResponse(processedActivity);
        }),
        catchAndReport(err => of(activityLogsError(err))),
      ),
    ),
  );

const afterRemoveActivityLogEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_ACTIVITIY_RESPONSE),
    map(({ payload: deletedActivityId }) => {
      const state = state$.value;
      const { activityTypeId, personGuid, userId } = currentLogsInfoSel(state);
      const activityLogs = activityLogsSel(state);
      if (activityTypeId && personGuid && userId) {
        return activityLogsResponse(
          activityLogs.filter(activity => deletedActivityId !== activity.id),
        );
      } else {
        return { type: '@@noop' };
      }
    }),
  );

const reloadActivityLogs$ = (action$, state$) =>
  action$.pipe(
    ofType(
      RECORD_CAMPOUTS_RESPONSE,
      RECORD_SERVICE_RESPONSE,
      RECORD_LONG_CRUISE_RESPONSE,
      RECORD_HIKE_RESPONSE,
      APPROVE_ADVANCEMENTS_RESPONSE,
      DECLINE_ADVANCEMENT_RESPONSE,
      REMOVE_PARTICIPANT_RESPONSE,
    ),
    map(() => {
      const state = state$.value;
      const { activityTypeId, personGuid, userId } = currentLogsInfoSel(state);
      if (activityTypeId && personGuid && userId) {
        return activityLogsRequest({
          personGuid,
          activityTypeId,
          userId,
        });
      } else {
        return { type: '@@noop' };
      }
    }),
  );

const removeParticipantEpic$ = action$ =>
  action$.ofType(REMOVE_PARTICIPANT).pipe(
    switchMap(({ payload }) => {
      const {
        activityRecords,
        activityId,
        userId,
        isPersonalActivity,
        onRemoveSuccess = () => {},
      } = payload;
      let activityMap;
      if (!isPersonalActivity) {
        activityMap = activityRecords.map(({ id: activityRecordId }) =>
          removeRegisteredParticipant$({
            activityId,
            activityRecordId,
          }),
        );
      } else {
        activityMap = [
          removeActivity$({
            activityId,
          }),
        ];
      }
      return forkJoin(activityMap).pipe(
        map(flatten),
        mergeMap(() => {
          // show success toast
          onRemoveSuccess();
          toastService.success(
            intl.formatMessage({
              id: 'editAdvancement.EditAdvancementForm.removedFromActivity',
            }),
          );

          return concat(
            of(removeParticipantResponse({ userId, activityId })),
            of(showRemoveParticipantModal(false)),
          );
        }),
      );
    }),
  );

const saveActivity$ = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_ACTIVITY_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const {
        activityTypeId,
        data,
        isCalendarActivity,
        onSuccess = () => {},
        onFailure = () => {},
      } = payload;
      let validActivityData = { ...data };
      if (data.allDay) {
        validActivityData = {
          ...validActivityData,
          startTime: setTime(),
          endTime: setTime(23, 59),
        };
      }
      const selectedActivityData = selectedActivityDataSel(state);
      const persons = personsSel(state);
      const loggedInUserId = loggedInUserIdSel(state);
      const organizationGuid = organizationGuidSel(state);
      const organizationTypeId = organizationTypeIdSel(state);
      const isAdvancedMode = activeTabSel(state) == activityValuesTabs.ADVANCED;
      return services
        .saveActivityForm$({
          ...(selectedActivityData || {}),
          ...validActivityData,
          activityTypeId,
          isCalendarActivity,
          loggedInUserId,
          organizationGuid,
          organizationTypeId: +organizationTypeId,
          persons,
          isAdvancedMode,
        })
        .pipe(
          map(response => {
            onSuccess(response.activityId);
            return saveActivityResponse(response.activityId);
          }),
          tap(({ type, payload: activityId }) => {
            if (type === SAVE_ACTIVITY_RESPONSE) {
              const savingTypeName = intl.formatMessage({
                id: `shared.${
                  activityId ? savingAction.CREATED : savingAction.UPDATED
                }`,
              });
              toastService.success(
                intl.formatMessage(
                  {
                    id: `progress.common.${
                      persons.length ? 'saveSuccessParticipants' : 'saveSuccess'
                    }`,
                  },
                  {
                    name: data.name,
                    type: savingTypeName,
                    count: persons.length,
                    participants: persons
                      .map(({ personShortFullName }) => personShortFullName)
                      .join(', '),
                  },
                ),
              );
            }
          }),
          catchAndReport(err => {
            onFailure(err);
            return of(saveActivityError(err));
          }),
        );
    }),
  );

export default combineEpics(
  getActivityLogs$,
  reloadActivityLogs$,
  removeParticipantEpic$,
  afterRemoveActivityLogEpic$,
  saveActivity$,
  getActivityByIdEpic$,
);
