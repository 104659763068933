import React from 'react';

import EventNoteIcon from '@material-ui/icons/EventNote';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Dropdown, Menu } from '@shared';

import {
  CampoutsCreateButton,
  CreateHikeButton,
  ServiceProjectButton,
} from '../../../../progress/components';
import styles from './CreateProgressDropdown.less';

class CreateProgressDropdown extends React.Component {
  state = {
    visible: false,
  };

  handleButtonClick = () => {
    this.handleVisibleChange(false);
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    const { className } = this.props;
    const { visible } = this.state;
    const buttons = (
      <Menu>
        <Menu.Item key="advancement">
          <Button
            color="scouting-warm-gray"
            noBorder
            fitText
            className={styles.progressButton}
            onClick={this.handleButtonClick}
          >
            <EventNoteIcon className={styles.icon} /> New Calendar Event
          </Button>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="serviceHours">
          <ServiceProjectButton
            className={styles.progressButton}
            onClick={this.handleButtonClick}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="campout">
          <CampoutsCreateButton
            className={styles.progressButton}
            onClick={this.handleButtonClick}
          />
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="hike">
          <CreateHikeButton
            className={styles.progressButton}
            onClick={this.handleButtonClick}
          />
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown
        overlay={buttons}
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Button
          type="primary"
          color="scouting-red"
          fitText
          className={className}
        >
          <FormattedMessage id="core.CreateProgressDropdown.create" />
        </Button>
      </Dropdown>
    );
  }
}

CreateProgressDropdown.propTypes = {
  className: PropTypes.string,
};

export default CreateProgressDropdown;
