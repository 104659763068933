import moment from 'moment';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';

import { rosterLeaderAgeLabel } from '@shared';
import { esbApiService, personNameBuilder } from '@utils';

const adultPackRosterDtoToModel = (roster, mapRosterId) =>
  roster[mapRosterId].map(member => ({
    userId: Number(member.userId),
    isAdult: true,
    isLeader: true,
    isParent: false,
    ...member,
    key3: member.key3 === 'True',
    personId: member.memberId,
    personShortFullName: personNameBuilder.short(member),
    age:
      member.age > 21
        ? `${rosterLeaderAgeLabel} (21+)`
        : `${rosterLeaderAgeLabel} (18-21)`,
    currentHighestRankApproved: { rank: '' },
    otherHighestRanksApproved: [],
    rosterOtherHighestRanksApproved: [],
  }));

/**
 * @esbEndpoint GET organizations/v2/units/:organizationGuid/adults
 */
const adultsCall$ = (organizationGuid, rosterUrl, mapRosterId) => {
  let apiRequest;
  const adultsUrl = `/${rosterUrl}/${organizationGuid}/adults`;
  const requestProps = {
    gtm: { label: '/{rosterUrl}/{organizationGuid}/adults' },
    swCache: true,
  };

  apiRequest = esbApiService.get$(adultsUrl, requestProps);

  return apiRequest.map(members =>
    adultPackRosterDtoToModel(members, mapRosterId),
  );
};

const mapMembers = (roster, mapRosterId) =>
  roster[mapRosterId].map(member => ({
    userId: Number(member.userId),
    isAdult: false,
    isParent: false,
    isLeader: false,
    ...member,
    personShortFullName: personNameBuilder.short(member),
    personId: member.memberId,
    age: member.age
      ? member.age
      : member.dateOfBirth && moment().diff(member.dateOfBirth, 'years'),
  }));

/**
 * @esbEndpoint GET organizations/v2/units/:organizationGuid/youths
 */
const youthsCall$ = (organizationGuid, rosterUrl, mapRosterId) => {
  let apiRequest;
  const youthsUrl = `/${rosterUrl}/${organizationGuid}/youths`;
  const requestProps = {
    gtm: { label: '/{rosterUrl}/{organizationGuid}/youths' },
    swCache: true,
  };

  apiRequest = esbApiService.get$(youthsUrl, requestProps);

  return apiRequest.map(members => mapMembers(members, mapRosterId));
};

/**
 * @esbEndpoint PUT /persons/v2/:personGuid/profile
 */
const updatePermission$ = (data, personGuid) =>
  esbApiService.put$(`/persons/v2/${personGuid}/profile`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/profile',
    },
  });

/**
 * @esbEndpoint POST /persons/positions/:personGuid/assignFunctional
 */
const assignFunctional$ = (data, personGuid) =>
  esbApiService.post$(
    `/persons/positions/${personGuid}/assignFunctional`,
    data,
    {
      gtm: {
        label: '/persons/positions/{personGuid}/assignFunctional',
      },
    },
  );

/**
 * @esbEndpoint POST /persons/positions/${personGuid}/expireFunctional
 */
const expireFunctional$ = (data, personGuid) =>
  esbApiService.post$(
    `/persons/positions/${personGuid}/expireFunctional`,
    data,
    {
      gtm: {
        label: '/persons/positions/{personGuid}/expireFunctional',
      },
    },
  );

/**
 * @esbEndpoint POST /persons/organizations/positions/:orgGuid/reassignPositions
 */
const reassignPositions$ = (data, orgGuid) =>
  esbApiService.post$(
    `/organizations/positions/${orgGuid}/reassignPositions`,
    data,
    {
      gtm: {
        label: '/persons/organizations/positions/{orgGuid}/reassignPositions',
      },
    },
  );

/**
 * @esbEndpoint GET organizations/positions/:orgGuid?positionType=all
 */
const getOrgPositions$ = orgGuid =>
  esbApiService.get$(`/organizations/positions/${orgGuid}?positionType=all`, {
    gtm: {
      label: 'organizations/positions/{orgGuid}?positionType=all',
    },
  });

export default {
  adultsCall$,
  youthsCall$,
  updatePermission$,
  assignFunctional$,
  expireFunctional$,
  getOrgPositions$,
  reassignPositions$,
};
