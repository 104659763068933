import { advancementTypes, venturingCoreAwardsIds } from '../constants';

/**
 * returns TRUE if the advancement has an specific level of seniority within its program
 * @param {Object} obj
 * @param {string} advancementType
 * @param {string} id
 */
export default ({ advancementType, id }) =>
  advancementType === advancementTypes.RANKS ||
  (advancementType === advancementTypes.AWARDS &&
    venturingCoreAwardsIds.includes(`${id}`));
