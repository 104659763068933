import moment from 'moment';

export default (startDate, startTime, endDate, endTime, tzCode) => {
  const timezoneCode = tzCode || 'America/Chicago';
  const dateFormat = 'YYYY-MM-DD';
  const timeFormat = 'HH:mm:ss';
  const dateStr = moment(startDate).format(dateFormat);
  const timeStr = moment(startTime).format(timeFormat);
  const mergeStartDate = moment(`${dateStr} ${timeStr}`);
  const endDateStr = moment(endDate).format(dateFormat);
  const endTimeStr = moment(endTime).format(timeFormat);
  const mergeEndtDate = moment(`${endDateStr} ${endTimeStr}`);

  const formattedDate = mergeStartDate.toDate();
  // We get the offset only from the startDate because back end, caluclate times
  // assuming that you are sending start time and end time of the same day (even if the dates are different)
  const appliedTz = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'longOffset',
    timeZone: timezoneCode,
  }).format(formattedDate);
  const [, GMT] = appliedTz.split(',');
  const utcOffset = GMT.replace(' GMT', '');

  const formattedOffset = utcOffset.replace(':', '');
  const startMergedOffset = moment(mergeStartDate).utcOffset(
    formattedOffset,
    true,
  );
  const endMergedOffset = moment(mergeEndtDate).utcOffset(
    formattedOffset,
    true,
  );
  return {
    fromDate: moment.utc(startMergedOffset).format(dateFormat),
    fromTime: moment.utc(startMergedOffset).format(timeFormat),
    toDate: moment.utc(endMergedOffset).format(dateFormat),
    toTime: moment.utc(endMergedOffset).format(timeFormat),
  };
};
