import React, { useCallback, useState } from 'react';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import { Button, Checkbox, Fab, S } from '@shared';

import styles from '../PackRosterFilterCard.less';

const { Divider } = Fab;

const SubUnitSection = ({
  subUnitTypeName,
  selected,
  disabled,
  onChange,
  subUnitsFilterTags,
  onChangeSubUnits,
  onChangeSelectAllSubUnits,
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const isMobile = useSelector(isMobileSel);
  const toggleCheckboxes = useCallback(
    name => onChangeSubUnits(name),
    [onChangeSubUnits],
  );

  const toggleSelectCheckboxes = useCallback(
    selectAllStatus => onChangeSelectAllSubUnits(selectAllStatus),
    [onChangeSelectAllSubUnits],
  );

  const onHandleSubUnitFilter = e => {
    toggleCheckboxes(e.target.name);
  };

  const handleSelectAllChange = allchecked => () => {
    toggleSelectCheckboxes(!allchecked);
    setIsChecked(!allchecked);
  };

  return (
    <div>
      {disabled && (
        <div>
          <FormattedMessage
            id={`advancement.PackRoster.${subUnitTypeName}.noSubUnitLegend`}
          />
        </div>
      )}
      <Checkbox
        name="showSubUnits"
        checked={selected}
        disabled={disabled}
        onChange={onChange}
      >
        <S size="4" colored inline>
          <FormattedMessage
            id={`advancement.PackRoster.${subUnitTypeName}.groupBy`}
          />
        </S>
      </Checkbox>
      {subUnitsFilterTags && !isMobile && <Divider />}

      <Button
        color="info"
        ghost
        noBorder
        fitText
        size="small"
        uppercase={false}
        icon={<DoneAllIcon />}
        onClick={handleSelectAllChange(isChecked)}
      >
        <FormattedMessage id={`packRoster.PackRosterFilterCard.SelectAll`} />
      </Button>
      {subUnitsFilterTags && !isMobile && <Divider />}
      {subUnitsFilterTags &&
        subUnitsFilterTags.map((filterTag, index) => (
          <Checkbox
            key={`itemsFilter_${filterTag.id}_${index}`}
            name={`${filterTag.id}`}
            checked={filterTag.status}
            disabled={disabled}
            onChange={onHandleSubUnitFilter}
          >
            <S size="4" inline className={styles.filterName}>
              {filterTag.name}
            </S>
          </Checkbox>
        ))}
    </div>
  );
};

SubUnitSection.propTypes = {
  subUnitTypeName: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  subUnitsFilterTags: PropTypes.array,
  onChangeSubUnits: PropTypes.func.isRequired,
  onChangeSelectAllSubUnits: PropTypes.func.isRequired,
};

export default SubUnitSection;
