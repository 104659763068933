import PropTypes from 'prop-types';

const disabledDatesRangeShape = PropTypes.shape({
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
});

export default PropTypes.oneOfType([
  disabledDatesRangeShape,
  PropTypes.oneOf(['future', 'past']),
]);
