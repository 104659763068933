import { combineEpics } from 'redux-observable';

import {
  ALLOW_MBC_LOGIN,
  SHOW_COUNSELED_YOUTH,
  SHOW_USER_PERMISSIONS,
} from '@config/config';

import { epics as activitiesEpics } from '../modules/activities';
import { epics as adultProfileEpics } from '../modules/adultProfile';
import { epics as advancementEpics } from '../modules/advancement';
import { epics as advancementReportEpics } from '../modules/advancementReport';
import calendarEpics from '../modules/calendar/duck/epics';
import { epics as contextEpics } from '../modules/context';
import { epics as councilUnitsEpics } from '../modules/councilUnits';
import { epics as dictEpics } from '../modules/dict';
import eventsEpics from '../modules/events/duck/epics';
import leadershipPositionsEpics from '../modules/leadershipPositions/duck/epics';
import { epics as meritBadgeCounselorsEpics } from '../modules/meritBadgeCounselors';
import { epics as messagingEpics } from '../modules/messaging';
import { epics as offlineEpics } from '../modules/offline';
import { epics as parentGuardianEpics } from '../modules/parentGuardian';
import { epics as paymentLogsEpics } from '../modules/paymentLogs';
import { epics as permissionsManagerEpics } from '../modules/permissionsManager';
import { epics as profileEpics } from '../modules/profile';
import progressEpics from '../modules/progress/duck/epics';
import { epics as reportsEpics } from '../modules/reports';
import { epics as settingsEpics } from '../modules/settings';
import { epics as sharedEpics } from '../modules/shared';
import { epics as unitEpics } from '../modules/unit';
import { epics as userEpics } from '../modules/user';
import youthProfileEpics from '../modules/youthProfile/duck/epics';

const epics = [
  userEpics,
  calendarEpics,
  contextEpics,
  councilUnitsEpics,
  dictEpics,
  eventsEpics,
  leadershipPositionsEpics,
  reportsEpics,
  advancementEpics,
  activitiesEpics,
  profileEpics,
  unitEpics,
  progressEpics,
  offlineEpics,
  advancementReportEpics,
  settingsEpics,
  youthProfileEpics,
  meritBadgeCounselorsEpics,
  parentGuardianEpics,
  adultProfileEpics,
  paymentLogsEpics,
  sharedEpics,
  permissionsManagerEpics,
  messagingEpics,
];

if (SHOW_USER_PERMISSIONS) {
  epics.push(require('../modules/roles').epics);
}

epics.push(require('../modules/recharter').epics);

if (SHOW_COUNSELED_YOUTH || ALLOW_MBC_LOGIN) {
  epics.push(require('../modules/counseledYouth/duck').epics);
}

export default combineEpics(...epics);
