import { combineReducers } from 'redux';

import { SET_ORGANIZATION, activityTypes, advancementTypes } from '@shared';
import { resetOnLogoutReducer } from '@utils';

import { APPROVE_ADVANCEMENTS_RESPONSE } from '../../../progress/duck/actions';
import { PendingItemsTabs } from '../constants';
import {
  SET_FILTER,
  SET_PENDING_ITEMS_ACTIVE_TAB,
  SET_SEARCH_FILTER,
  SET_SELECTED_ROW_KEYS,
  SET_SORTER,
  removeMultipleSelectedItems,
  removeSelectedItem,
} from './actions';

const searchFilter = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH_FILTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const sorter = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SORTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const defaultAdvancementTypes = {
  [advancementTypes.MERIT_BADGES]: true,
  [advancementTypes.ADVENTURES]: true,
  [advancementTypes.RANKS]: true,
  [advancementTypes.AWARDS]: true,
};
const advancementTypesFilter = (
  state = defaultAdvancementTypes,
  { type, payload },
) => {
  switch (type) {
    case SET_FILTER: {
      const { advancementTypes } = payload;
      return advancementTypes;
    }
    case SET_ORGANIZATION: {
      return defaultAdvancementTypes;
    }
    default: {
      return state;
    }
  }
};

const defaultActivityTypes = {
  [activityTypes.CAMPOUTS]: true,
  [activityTypes.SERVICE_PROJECTS]: true,
  [activityTypes.HIKES]: true,
  [activityTypes.LONG_CRUISE]: true,
  [activityTypes.EAGLE_SERVICE_PROJECT]: true,
};

const activityTypesFilter = (
  state = defaultAdvancementTypes,
  { type, payload },
) => {
  switch (type) {
    case SET_FILTER: {
      const { activityTypes } = payload;
      return activityTypes;
    }
    case SET_ORGANIZATION: {
      return defaultActivityTypes;
    }
    default: {
      return state;
    }
  }
};

const selectedRowKeys = (state = [], { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ROW_KEYS:
      return payload;
    case removeSelectedItem.type: {
      return state.filter(item => item !== payload);
    }
    case removeMultipleSelectedItems.type: {
      const toRemove = new Set(payload);
      return state.filter(item => !toRemove.has(item));
    }
    case APPROVE_ADVANCEMENTS_RESPONSE:
      return [];
    default: {
      return state;
    }
  }
};

const pendingItemsActiveTab = (
  state = PendingItemsTabs.RANKS,
  { type, payload },
) => {
  switch (type) {
    case SET_PENDING_ITEMS_ACTIVE_TAB: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  searchFilter,
  sorter,
  advancementTypesFilter,
  activityTypesFilter,
  selectedRowKeys,
  pendingItemsActiveTab,
});

export default resetOnLogoutReducer(reducer);
