import { Observable } from 'rxjs/Observable';

import { getScoutshopBaseURL } from '@config';
import { userServices } from '@modules/shared';
import { esbApiService } from '@modules/utils';

const getLoginData = () => userServices.retrieveLoginData() || {};
const { token } = getLoginData();

const BASE_CARTS_URL = getScoutshopBaseURL();
class CartService {
  createGuestCart$() {
    return Observable.fromPromise(
      // fetch(`/apis/rest/V1/guest-carts`, {
      fetch(`${BASE_CARTS_URL}/rest/V1/guest-carts`, {
        method: 'POST',
        // mode: 'same-origin',
        // credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${token}`,
          // 'x-target-url': `${BASE_CARTS_URL}`,
          // 'x-origin': `${BASE_CARTS_URL}`,
          // 'Host': `${BASE_CARTS_URL}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed with status ${response.status}`);
          }
          return response.json();
        })
        .catch(error => {
          throw error;
        }),
    ).catch(error => {
      throw error;
    });
  }
  updateGuestCart$({ cartToken, items }) {
    return Observable.fromPromise(
      fetch(`${BASE_CARTS_URL}/rest/V1/guest-carts/${cartToken}/multiItems`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items }),
      }).then(response => {
        if (!response.ok) {
          return response.text().then(text => {
            throw new Error(`Failed with status ${response.status}: ${text}`);
          });
        }
        return response.json();
      }),
    ).catch(error => {
      throw error;
    });
  }
}
export const cartService = new CartService();

/**
 * @esbEndpoint POST /advancements/v2/{organizationGuid}/purchaseOrders
 */
const createPurchaseOrder$ = (data, organizationGuid) =>
  esbApiService.post$(
    `/advancements/v2/${organizationGuid}/purchaseOrders`,
    data,
    /*{
      "dateCreated": "2018-03-07",
      "userId": 122786,
      "notes": "test notes",
      "showSkus": false,
      "showPrices": true,
      "showNotes": false,
      "groupBy": "scout",
      "showDenPatrol": false
    }*/
    {
      gtm: {
        label: '/advancements/v2/{organizationGuid}/purchaseOrders',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/purchaseOrders/{id}/items
 */
const addToPurchaseOrder$ = (poid, organizationGuid, data) =>
  esbApiService.post$(
    `/advancements/v2/${organizationGuid}/purchaseOrders/${poid}/items`,
    data,
    /*[
        {
          "userId": 34343
          "advancementType": "ranks",
          "advancementId": 1
        },
        {
          "userId": 34344
          "advancementType": "ranks",
          "advancementId": 8
        },
    ]*/
    {
      gtm: {
        label: '/advancements/v2/{organizationGuid}/purchaseOrders/{id}/items',
      },
    },
  );

const createPurchaseOrderWithItems$ = (POdetails, organizationGuid, items) =>
  createPurchaseOrder$(POdetails, organizationGuid)
    .mergeMap(response => {
      const { id } = response;

      const payload = items.map(itemDetails => ({
        userId: itemDetails.userId,
        advancementType: itemDetails.advancementType,
        advancementId: itemDetails.id,
      }));

      return Observable.forkJoin({
        items: addToPurchaseOrder$(id, organizationGuid, payload),
        id: Observable.of(id),
      });
    })
    .catchAndReport(err => err);

/**
 * @esbEndpoint PUT /advancements/v2/{organizationGuid}/purchaseOrders/{id}
 */
const updatePurchaseOrder$ = (data, organizationGuid, id) =>
  esbApiService.put$(
    `/advancements/v2/${organizationGuid}/purchaseOrders/${id}`,
    data,
    /*{
      "status": "Closed",  // this is only required field
      "notes": "test notes",
      "showSkus": true,
      "showPrices": true,
      "showNotes": true,
      "groupBy": "Item",
      "showDenPatrol": false
    }*/
    {
      gtm: {
        label: '/advancements/v2/{organizationGuid}/purchaseOrders/{id}',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/purchaseOrders/{id}/items/delete
 */

const deleteFromPurchaseOrder$ = (poid, organizationGuid, data) =>
  esbApiService.post$(
    `/advancements/v2/${organizationGuid}/purchaseOrders/${poid}/items/delete`,
    data,
    /*[
        {
          "userId": 34343
          "advancementType": "awards",
          "advancementId": 1
          "userAdvancementId": 1234234   //required for awards only
        },
        {
          "userId": 34344
          "advancementType": "ranks",
          "advancementId": 8
        },
    ]*/
    {
      gtm: {
        label:
          '/advancements/v2/{organizationGuid}/purchaseOrders/{id}/items/delete',
      },
    },
  );

/**
 * @esbEndpoint GET /advancements/v2/{organizationGuid}/purchaseOrders
 */
const getAllPurchaseOrders$ = organizationGuid =>
  esbApiService.get$(`/advancements/v2/${organizationGuid}/purchaseOrders`, {
    gtm: {
      label: '/advancements/v2/{organizationGuid}/purchaseOrders',
    },
  });

/**
 * @esbEndpoint GET /advancements/v2/{organizationGuid}/purchaseOrders/{id}
 */
const getPurchaseOrderDetails$ = (organizationGuid, poid) =>
  esbApiService.get$(
    `/advancements/v2/${organizationGuid}/purchaseOrders/${poid}`,
    {
      gtm: {
        label: '/advancements/v2/{organizationGuid}/purchaseOrders/{id}',
      },
    },
  );

const deletePurchaseOrder$ = (id, organizationGuid) =>
  esbApiService.get$(
    `/advancements/v2/${organizationGuid}/purchaseOrders/${id}`,
    {
      gtm: {
        label: '/advancements/v2/{organizationGuid}/purchaseOrders/{id}',
      },
    },
  );

export const esbServices = {
  createPurchaseOrder$,
  updatePurchaseOrder$,
  deleteFromPurchaseOrder$,
  addToPurchaseOrder$,
  createPurchaseOrderWithItems$,
  getAllPurchaseOrders$,
  getPurchaseOrderDetails$,
  deletePurchaseOrder$,
};
