import get from 'lodash/get';
import { createSelector } from 'reselect';

import {
  Program,
  activityTypeIds,
  activityTypes,
  childOrganizationSel,
  emptyObj,
  organizationPositionsSel,
  recentUnitsSel,
  useDenDetailsArrSel,
  userRoleSel,
} from '@shared';

import {
  isCubScoutsUnitSel,
  isScoutsBsaUnitSel,
  programSel,
} from './selectorsTyped';

export {
  organizationPositionsSel,
  defaultOrganizationGuidSel,
  parentOrgGuidSel,
  organizationGuidSel,
  organizationPositionSel,
  selectedOrganizationSel,
  unitTypeIdSel,
  organizationTypeIdSel,
  isCouncilOrgActiveSel,
  childOrganizationSel,
  isSelectedOrganizationValidSel,
  youthMemberPersonGuidSel,
} from '@shared';

export const recentActiveUnitOrganizationsSel = createSelector(
  recentUnitsSel,
  childOrganizationSel,
  (recentActiveItems, childOrganization) =>
    recentActiveItems.filter(
      ({ organizationGuid }) =>
        organizationGuid != (childOrganization || {}).organizationGuid,
    ),
);

export const isDenOrAssistantSel = createSelector(
  userRoleSel,
  isCubScoutsUnitSel,
  (userRole, isCubScoutsUnit) =>
    isCubScoutsUnit && userRole.includes('Den Chief'),
);

export const isPatrolAdminSel = createSelector(
  userRoleSel,
  isScoutsBsaUnitSel,
  (userRole, isScoutsBsaUnit) => isScoutsBsaUnit && userRole.includes('Patrol'),
);

export const denIdsSel = createSelector(useDenDetailsArrSel, useDenDetailsArr =>
  useDenDetailsArr.map(denDetail => denDetail.denId || -1),
);

export const unitActivityTypesSel = createSelector(programSel, unitType => [
  activityTypes.CAMPOUTS,
  activityTypes.HIKES,
  activityTypes.SERVICE_PROJECTS,
  ...(unitType === Program.SEA_SCOUT ? [activityTypes.LONG_CRUISE] : []),
]);

export const unitActivityTypeIdsSel = createSelector(programSel, unitType => [
  activityTypeIds.CAMPOUTS,
  activityTypeIds.HIKES,
  activityTypeIds.SERVICE_PROJECTS,
  ...(unitType === Program.SEA_SCOUT ? [activityTypeIds.LONG_CRUISE] : []),
]);

const unitProfilesSel = state => get(state, 'unit.unitProfiles') || emptyObj;
export const unitProfileSel = createSelector(
  (state, ownProps) => ownProps.organiztionGuid,
  unitProfilesSel,
  (orgGuid, profiles) => profiles[orgGuid],
);

export const currentOrganizationsPositionsSel = createSelector(
  organizationPositionsSel,
  unitProfilesSel,
  (organizationPositions, unitProfiles) =>
    organizationPositions.filter(org => {
      const foundUnitProfile = unitProfiles[org.organizationGuid];

      if (!foundUnitProfile) return true;

      return !foundUnitProfile.isComingSoon;
    }),
);
