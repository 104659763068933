import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FilterListIcon from '@material-ui/icons/FilterList';
import cn from 'classnames';

import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';
import Button from '../Button';
import Menu from '../Menu';

import styles from './FilterDropdown.less';

const MenuItem = Menu.Item;

const FilterDropdown = ({
  title,
  filterObject,
  getFilterName,
  onFilter,
  keepOpenOnClickItem,
  filterNameStyle,
  overlayClassName,
  extraMenuItems = null,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleClickFilter = useCallback(
    (key, checked) => element => {
      const { domEvent } = element;
      domEvent.stopPropagation();
      domEvent.preventDefault();
      onFilter(key, !checked);
      if (!keepOpenOnClickItem) {
        setIsVisible(false);
      }
    },
    [onFilter, keepOpenOnClickItem],
  );

  const handleVisibleChange = useCallback(flag => {
    setIsVisible(flag);
  }, []);

  const filters = useMemo(
    () =>
      Object.keys(filterObject).map(filterKey => (
        <MenuItem
          key={`filter-${filterKey}`}
          className={cn(styles.filterCheckbox, filterNameStyle)}
          onClick={handleClickFilter(filterKey, filterObject[filterKey])}
        >
          <Checkbox checked={filterObject[filterKey]}>
            {getFilterName(filterKey) || filterKey}
          </Checkbox>
        </MenuItem>
      )),
    [filterObject, handleClickFilter, getFilterName, filterNameStyle],
  );

  return (
    <Dropdown
      overlay={
        <Menu>
          {extraMenuItems}
          {filters}
        </Menu>
      }
      overlayClassName={overlayClassName}
      trigger={['click']}
      placement="bottomRight"
      onVisibleChange={handleVisibleChange}
      visible={isVisible}
    >
      <Button type="link" noBorder size="large" className={styles.filterButton}>
        {title ? title : <FormattedMessage id="shared.filterBy" />}
        <FilterListIcon />
      </Button>
    </Dropdown>
  );
};

FilterDropdown.defaultProps = {
  getFilterName: () => {},
  keepOpenOnClickItem: false,
};

FilterDropdown.propTypes = {
  filterObject: PropTypes.object.isRequired,
  extraMenuItems: PropTypes.node,
  onFilter: PropTypes.func.isRequired,
  title: PropTypes.node,
  keepOpenOnClickItem: PropTypes.bool,
  getFilterName: PropTypes.func,
  filterNameStyle: PropTypes.string,
  overlayClassName: PropTypes.string,
};

export default FilterDropdown;
