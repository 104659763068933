import React from 'react';

import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { organizationGuidSel, unitTypeIdSel } from '@context';
import { Fab, ROUTE_REPORTS, isScoutbookRoleSel } from '@shared';

import {
  openAdvancementReportModal,
  openRosterReportWindow,
} from '../../../../advancementReport';
import { openReportParamsModal, reportsSel } from '../../../../reports';
import { reportTypes } from '../../../constants';
import { openUnitReportModal, setUnitRosterReportLoading } from '../../actions';
import { reportsSelectedItemsSel, selectedYouthSel } from '../../selectors';

const { Item, Divider } = Fab;

class ReportFab extends React.PureComponent {
  handleRunAdvancementReport = () => {
    const { showAllUsers } = this.props;
    this.props.onOpenAdvancementReportModal({
      showAllUsers,
    });
  };

  runReportParams = reportType => {
    const { users, organizationGuid } = this.props;
    this.props.onOpenReportModal({
      reportType,
      users,
      organizationGuid,
    });
  };

  handleRunAdvancementSummaryReport = () =>
    this.runReportParams(reportTypes.ADVANCEMENT_SUMMARY);

  handleRunActivitiesSummaryReport = () =>
    this.runReportParams(reportTypes.ACTIVITIES_SUMMARY);

  handleOpenUnitReport = async () => {
    const { organizationGuid, programId, users, onSetUnitRosterReportLoading } =
      this.props;
    onSetUnitRosterReportLoading(true);
    await openRosterReportWindow({
      organizationGuid,
      programId,
      users,
    });
    onSetUnitRosterReportLoading(false);
  };

  handleNavigateToReports = () => {
    this.props.onNavigateToReports();
  };

  handleOpenBasicReport = reportCode => () => {
    this.props.onOpenUnitReportModal(reportCode);
  };

  renderUnitReports = memoize(unitReports =>
    unitReports.map(({ ReportName, reportCode }) => (
      <React.Fragment key={reportCode}>
        <Divider />
        <Item onClick={this.handleOpenBasicReport(reportCode)}>
          {ReportName}
        </Item>
      </React.Fragment>
    )),
  );

  render() {
    const {
      visible,
      open,
      onOpenChange,
      isOffline,
      selectedYouthCount,
      isScoutbookRole,
    } = this.props;

    return (
      <Fab
        id="qa_runReport"
        className="Joyride__ReportFab"
        content={
          <React.Fragment>
            {!isScoutbookRole && (
              <React.Fragment>
                <Item
                  id="qa_advancementReport"
                  onClick={this.handleRunAdvancementReport}
                >
                  <FormattedMessage id="advancement.ReportFab.advancementReport" />
                </Item>
                <Divider />
              </React.Fragment>
            )}
            <Item
              id="qa_advancementSummary"
              onClick={this.handleRunAdvancementSummaryReport}
            >
              <FormattedMessage id="advancement.ReportFab.advancementHistory" />
            </Item>
            <Divider />
            <Item
              id="qa_activitesSummary"
              onClick={this.handleRunActivitiesSummaryReport}
            >
              <FormattedMessage id="advancement.ReportFab.activitiesSummary" />
            </Item>
            <Divider />
            <Item id="qa_unitRoster" onClick={this.handleOpenUnitReport}>
              <FormattedMessage id="advancement.ReportFab.unitRoster" />
            </Item>
            {selectedYouthCount > 0 &&
              this.renderUnitReports(this.props.unitReports)}
            <Divider />
            <Item onClick={this.handleNavigateToReports}>
              <FormattedMessage id="advancement.ReportFab.more" />
            </Item>
          </React.Fragment>
        }
        visible={visible}
        open={open}
        onOpenChange={onOpenChange}
        disabled={isOffline}
      >
        <FormattedMessage id="advancement.ReportFab.title" />
      </Fab>
    );
  }
}

ReportFab.propTypes = {
  // Provided by parent
  visible: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  showAllUsers: PropTypes.bool.isRequired,
  // Provided by component itself
  users: PropTypes.array.isRequired,
  programId: PropTypes.number.isRequired,
  organizationGuid: PropTypes.string.isRequired,
  selectedYouthCount: PropTypes.number.isRequired,
  unitReports: PropTypes.array.isRequired,
  onOpenChange: PropTypes.func.isRequired,
  onOpenReportModal: PropTypes.func.isRequired,
  onNavigateToReports: PropTypes.func.isRequired,
  onOpenAdvancementReportModal: PropTypes.func.isRequired,
  onSetUnitRosterReportLoading: PropTypes.func.isRequired,
  onOpenUnitReportModal: PropTypes.func.isRequired,
  isScoutbookRole: PropTypes.bool.isRequired,
};

const mapState = state => ({
  organizationGuid: organizationGuidSel(state),
  programId: unitTypeIdSel(state),
  users: reportsSelectedItemsSel(state),
  selectedYouthCount: selectedYouthSel(state).length,
  unitReports: reportsSel(state),
  isScoutbookRole: isScoutbookRoleSel(state),
});

const mapDispatch = dispatch => ({
  onOpenAdvancementReportModal: ({ showAllUsers }) =>
    dispatch(openAdvancementReportModal({ showAllUsers })),
  onOpenReportModal: ({ organizationGuid, users, reportType }) =>
    dispatch(
      openReportParamsModal({
        organizationGuid,
        users,
        reportType,
      }),
    ),
  onSetUnitRosterReportLoading: loading =>
    dispatch(setUnitRosterReportLoading(loading)),
  onNavigateToReports: () => dispatch({ type: ROUTE_REPORTS }),
  onOpenUnitReportModal: reportCode =>
    dispatch(openUnitReportModal(reportCode)),
});

export default connect(mapState, mapDispatch)(ReportFab);
