import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import Button from '../Button';
import S from '../S';
import Divider from './ButtonDrawerDivider';
import Item from './ButtonDrawerItem';
import styles from './ButtonDrawer.less';

class ButtonDrawer extends React.Component {
  state = {
    open: this.props.open || false,
  };

  handleToggleDrawer = () => {
    const open = this.isOpen();
    if (this.props.onOpenChange) {
      this.props.onOpenChange(!open);
    }
    this.setState({ open: !open });
  };

  handleCloseDrawer = () => {
    if (this.props.onOpenChange) {
      this.props.onOpenChange(false);
    }
    this.setState({ open: false });
  };

  isOpen = () => (this.isControlled() ? this.props.open : this.state.open);

  isControlled = () => this.props.open != null;

  renderTitle = () => {
    const { title } = this.props;

    if (title) {
      return title;
    } else {
      const open = this.isOpen();

      return open ? (
        <FormattedMessage id="shared.ButtonDrawer.close" />
      ) : (
        <FormattedMessage id="shared.ButtonDrawer.open" />
      );
    }
  };

  render() {
    const { visible, titleColor, titleClassName, children, className } =
      this.props;
    const open = this.isOpen();
    const classNames = cn(className, styles.container, {
      [styles.visible]: visible,
      [styles.open]: visible && open,
    });
    const titleClassNames = cn(styles.drawerButton, titleClassName);

    return (
      <React.Fragment>
        {open && (
          <div className={styles.mask} onClick={this.handleCloseDrawer} />
        )}
        <div className={classNames}>
          <Button
            className={titleClassNames}
            color={titleColor}
            fitText
            size="large"
            type="primary"
            onClick={this.handleToggleDrawer}
          >
            <S size="3">{this.renderTitle()}</S>
          </Button>
          <div onClick={this.handleCloseDrawer}>{children}</div>
        </div>
      </React.Fragment>
    );
  }
}

ButtonDrawer.propTypes = {
  visible: PropTypes.bool,
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  titleColor: Button.propTypes.color,
  onOpenChange: PropTypes.func,
};

ButtonDrawer.defaultProps = {
  visible: true,
  titleColor: 'scouting-red',
};

ButtonDrawer.Divider = Divider;
ButtonDrawer.Item = Item;

export default ButtonDrawer;
