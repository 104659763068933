import { gtm } from '@shared';

import { moduleName } from '../constants';

export { SET_ORGANIZATION, setOrganization } from '@shared';

export const SET_DEFAULT_ORGANIZATION = `${moduleName}/SET_DEFAULT_ORGANIZATION`;
export const setDefaultOrganization = ({ guid, programType, skipGtm }) => ({
  type: SET_DEFAULT_ORGANIZATION,
  payload: guid,
  gtm: skipGtm
    ? null
    : {
        category: gtm.categories.CONTEXT,
        label: programType,
      },
});
