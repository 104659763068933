import moment from 'moment';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/takeUntil';

import { SET_ORGANIZATION, organizationGuidSel } from '@context';
import {
  ADD_TO_PURCHASE_ORDER_RESPONSE,
  DELETE_FROM_PURCHASE_ORDER_RESPONSE,
} from '@modules/advancement/purchaseOrder';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import {
  LOGIN_RESPONSE,
  SELFSESSION_RESPONSE,
  advancementHistoryFieldPreferencesName,
  hasPermissionSel,
  historyItemsSel,
  permissions,
  personGuidSel,
  userPreferencesServices,
} from '@shared';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  APPROVE_ADVANCEMENTS_RESPONSE,
  DECLINE_ADVANCEMENT_RESPONSE,
  RECORD_ADVANCEMENT_RESPONSE,
  RECORD_CAMPOUTS_RESPONSE,
  RECORD_HIKE_RESPONSE,
  RECORD_LONG_CRUISE_RESPONSE,
  RECORD_SERVICE_RESPONSE,
  UNAPPROVE_ADVANCEMENT_RESPONSE,
} from '../../../progress/duck/actions';
import { SUBMIT_EAGLE_PROJECT_RESPONSE } from '../../../progress/eagleProject';
import {
  PACK_ROSTER_ALREADY_LOADED,
  PACK_ROSTER_RESPONSE,
  UPLOAD_FILE_RESPONSE,
  adultItemsSel,
  isValidOrganizationSel,
  packRosterItemsSel,
  packRosterRequestIfNotLoaded,
} from '../../common';
import { updatedDateFilters } from '../constants';
import { getFallbackDateRange, getLastUpdatedDate } from '../helpers';
import {
  ADVANCEMENT_HISTORY_ALREADY_LOADED,
  ADVANCEMENT_HISTORY_ERROR,
  ADVANCEMENT_HISTORY_REQUEST,
  ADVANCEMENT_HISTORY_REQUEST_IF_NOT_LOADED,
  ADVANCEMENT_HISTORY_RESPONSE,
  advancementHistoryAlreadyLoaded,
  advancementHistoryError,
  advancementHistoryRequest,
  advancementHistoryResponse,
  setFieldsVisibility,
  setUpdatedDateFilter,
} from './actions';
import {
  isActivityTabCurrentSel,
  itemsSel,
  loadingSel,
  peopleFilterSel,
  updatedDateFilterSel,
} from './selectors';
import services from './services';

const requestAdvancementHistoryIfNotLoadedEpic$ = (action$, state$) =>
  action$
    .filter(() => hasPermissionSel(state$.value, permissions.VIEW_ROSTER_PAGE))
    .ofType(ADVANCEMENT_HISTORY_REQUEST_IF_NOT_LOADED)
    .switchMap(() => {
      const state = state$.value;
      const isHistoryLoading = loadingSel(state);

      if (isHistoryLoading) {
        return action$
          .ofType(
            ADVANCEMENT_HISTORY_RESPONSE,
            ADVANCEMENT_HISTORY_ALREADY_LOADED,
          )
          .take(1)
          .map(() => advancementHistoryAlreadyLoaded())
          .takeUntil(action$.ofType(ADVANCEMENT_HISTORY_ERROR));
      }

      const isHistoryLoaded = itemsSel(state).length > 0;
      const action = isHistoryLoaded
        ? advancementHistoryAlreadyLoaded()
        : advancementHistoryRequest();

      return Observable.of(action);
    });

const getNewUpdatedDateFilter = (currentUpdatedDate, isActivtiyTabActive) => {
  const shouldChangeDateFilter =
    !isActivtiyTabActive && currentUpdatedDate === updatedDateFilters.ALL;
  return shouldChangeDateFilter
    ? updatedDateFilters.LAST_365_DAYS
    : currentUpdatedDate;
};

const requestRosterForAdvancementHistoryEpic$ = action$ =>
  action$
    .ofType(ADVANCEMENT_HISTORY_REQUEST)
    .mapTo(packRosterRequestIfNotLoaded());

const loadAdvancementHistory$ = state => {
  const organizationGuid = organizationGuidSel(state);
  const people = peopleFilterSel(state);
  const adults = adultItemsSel(state);
  const rosterItemsCount = packRosterItemsSel(state).length;
  const updatedDateFilter = updatedDateFilterSel(state);
  const isActivtiyTabActive = isActivityTabCurrentSel(state);
  const updatedDate = getNewUpdatedDateFilter(
    updatedDateFilter,
    isActivtiyTabActive,
  );

  return services
    .getUnitHistory$({
      organizationGuid,
      people,
      adults,
      updatedDate,
      rosterItemsCount,
    })
    .map(advancementHistoryResponse)
    .catchAndReport(err => Observable.of(advancementHistoryError(err)));
};

// AdvancementImports require /adults data to be processed
// We need to make sure we have data before requesting  advancement history
const requestAdvancementHistoryDataEpic$ = (action$, state$) =>
  action$
    .filter(() => isValidOrganizationSel(state$.value))
    .ofType(ADVANCEMENT_HISTORY_REQUEST)
    .switchMap(() => {
      // at this point the 'pack roster loaded' action might've already been dispatched
      // so we need to check the store
      // and start loading history if pack roster is there
      // or wait for roster to load first

      const packRosterLoaded = packRosterItemsSel(state$.value).length > 0;

      if (packRosterLoaded) {
        return loadAdvancementHistory$(state$.value);
      }

      return action$
        .ofType(PACK_ROSTER_RESPONSE, PACK_ROSTER_ALREADY_LOADED)
        .take(1)
        .switchMap(() => loadAdvancementHistory$(state$.value))
        .takeUntil(action$.ofType(SET_ORGANIZATION));
    });

const reloadAdvancementHistoryEpic$ = (action$, state$) =>
  action$
    .filter(() => hasPermissionSel(state$.value, permissions.VIEW_ROSTER_PAGE))
    .ofType(
      [
        featureFlags.getFlag('SBL_5150_DASHBOARD_API') && SET_ORGANIZATION,
        RECORD_ADVANCEMENT_RESPONSE,
        APPROVE_ADVANCEMENTS_RESPONSE,
        DECLINE_ADVANCEMENT_RESPONSE,
        UNAPPROVE_ADVANCEMENT_RESPONSE,
        RECORD_CAMPOUTS_RESPONSE,
        RECORD_HIKE_RESPONSE,
        RECORD_SERVICE_RESPONSE,
        RECORD_LONG_CRUISE_RESPONSE,
        SUBMIT_EAGLE_PROJECT_RESPONSE,
        UPLOAD_FILE_RESPONSE,
        ADD_TO_PURCHASE_ORDER_RESPONSE,
        DELETE_FROM_PURCHASE_ORDER_RESPONSE,
      ].filter(Boolean),
    )
    .mapTo(advancementHistoryRequest());

const loadActivityFieldsVisibilityEpic$ = (action$, state$) =>
  action$.ofType(LOGIN_RESPONSE, SELFSESSION_RESPONSE).map(() => {
    const personGuid = personGuidSel(state$.value);
    const fieldsVisiblity = userPreferencesServices.retrievePreferences({
      personGuid,
      featureName: advancementHistoryFieldPreferencesName,
    });
    return setFieldsVisibility(fieldsVisiblity);
  });

const setFallbackDateRangeEpic$ = (action$, state$) =>
  action$.ofType(ADVANCEMENT_HISTORY_RESPONSE).map(() => {
    const state = state$.value;
    const allItems = historyItemsSel(state);
    const currentDateFilter = updatedDateFilterSel(state);
    const dateFilter = getLastUpdatedDate(currentDateFilter);
    const filteredItems = allItems.filter(
      ({ date }) => !moment(date).isBefore(dateFilter, 'day'),
    );

    return setUpdatedDateFilter(
      getFallbackDateRange({ filteredItems, currentDateFilter, allItems }),
    );
  });

const resetDateFilterIfAllEpic$ = (action$, state$) =>
  action$
    .ofType(ADVANCEMENT_HISTORY_RESPONSE, ADVANCEMENT_HISTORY_ERROR)
    .map(() => {
      const state = state$.value;
      const updatedDateFilter = updatedDateFilterSel(state);
      const isActivtiyTabActive = isActivityTabCurrentSel(state);
      const updatedDate = getNewUpdatedDateFilter(
        updatedDateFilter,
        isActivtiyTabActive,
      );
      const didDateChanged = updatedDateFilter !== updatedDate;

      return didDateChanged
        ? setUpdatedDateFilter(updatedDate)
        : () => ({ type: '@@noop' });
    });

export default combineEpics(
  reloadAdvancementHistoryEpic$,
  requestRosterForAdvancementHistoryEpic$,
  requestAdvancementHistoryDataEpic$,
  requestAdvancementHistoryIfNotLoadedEpic$,
  loadActivityFieldsVisibilityEpic$,
  setFallbackDateRangeEpic$,
  resetDateFilterIfAllEpic$,
);
