import { openSyncModal as _openSyncModal } from '@offline';
import { advancementTabNames } from '@shared/constants';
import { navigateToRosterTab } from '@shared/duck';

import { activityItemsTypes } from '../../advancement/common/constants';
import { setExpandedMainRow } from '../../advancement/duck/actions';
import { openAdvancementReportModal as _openAdvancementReportModal } from '../../advancementReport';
import { setNotificationsVisible } from './slice';

export const goToActivity = () => dispatch => {
  dispatch(setNotificationsVisible(false));
  dispatch(setExpandedMainRow({ [activityItemsTypes.PENDING_APPROVAL]: true }));
  const pendingActionLocation = advancementTabNames.PENDING;
  dispatch(navigateToRosterTab(pendingActionLocation));
};

export const openAdvancementReportModal = () => dispatch => {
  dispatch(setNotificationsVisible(false));
  dispatch(_openAdvancementReportModal({ showAllUsers: true }));
};

export const openSyncModal = () => dispatch => {
  dispatch(setNotificationsVisible(false));
  dispatch(_openSyncModal());
};
