import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { TableFilterCard } from '@shared';

import { AdvancementTypesFilterSection } from '../../../../common';
import { filterPanels } from '../../../constants';
import {
  activityTypesFilterSel,
  advancementTypesFilterSel,
  setFilter,
  setSorter,
  sorterSel,
} from '../../../duck';
import { ActivityTypesFilterSection, SorterSection } from './sections';

const { Collapse, Panel } = TableFilterCard;

const PendingItemsFilterCard = ({ children }) => {
  const advancementTypesFilter = useSelector(advancementTypesFilterSel);
  const activityTypesFilter = useSelector(activityTypesFilterSel);
  const sorter = useSelector(sorterSel);
  const program = useSelector(programSel);

  const dispatch = useDispatch();

  const [selectedAdvancementTypes, setSelectedAdvancementTypes] = useState(
    advancementTypesFilter,
  );
  const [selectedActivityTypes, setSelectedActivityTypes] =
    useState(activityTypesFilter);
  const [selectedSorter, setSelectedSorter] = useState(sorter);
  const [activePanel, setActivePanel] = useState(
    filterPanels.ADVANCEMENT_TYPES,
  );

  const handleOpenChange = useCallback(
    open => {
      if (open) {
        setSelectedAdvancementTypes(advancementTypesFilter);
        setSelectedActivityTypes(activityTypesFilter);
        setSelectedSorter(sorter);
        setActivePanel(filterPanels.ADVANCEMENT_TYPES);
      }
    },
    [advancementTypesFilter, activityTypesFilter, sorter],
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      setFilter({
        advancementTypes: selectedAdvancementTypes,
        activityTypes: selectedActivityTypes,
      }),
    );
    dispatch(setSorter(selectedSorter));
  }, [
    dispatch,
    selectedAdvancementTypes,
    selectedActivityTypes,
    selectedSorter,
  ]);

  const filterContent = (
    <Collapse activeKey={activePanel} onChange={setActivePanel}>
      <Panel
        key={filterPanels.ADVANCEMENT_TYPES}
        header={
          <FormattedMessage id="advancement.PendingItems.filter.section.advancements" />
        }
      >
        <AdvancementTypesFilterSection
          program={program}
          values={selectedAdvancementTypes}
          onChange={setSelectedAdvancementTypes}
        />
      </Panel>
      <Panel
        key={filterPanels.ACTIVITY_TYPES}
        header={
          <FormattedMessage id="advancement.PendingItems.filter.section.activities" />
        }
      >
        <ActivityTypesFilterSection
          values={selectedActivityTypes}
          onChange={setSelectedActivityTypes}
        />
      </Panel>
      <Panel
        key={filterPanels.SORTER}
        header={<FormattedMessage id="shared.sortBy" />}
        mobileOnly
      >
        <SorterSection sorter={selectedSorter} onChange={setSelectedSorter} />
      </Panel>
    </Collapse>
  );

  return (
    <TableFilterCard
      content={filterContent}
      onOpenChange={handleOpenChange}
      onSubmit={handleSubmit}
    >
      {children}
    </TableFilterCard>
  );
};

PendingItemsFilterCard.propTypes = {
  children: PropTypes.node,
};

export default PendingItemsFilterCard;
