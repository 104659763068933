import _ from 'lodash';

import { parseStringBoolean } from '@modules/shared/utils/parsers';
import { shouldRequirementDisplayOnly } from '@modules/youthProfile/utils';

import type { AdvancementRequirement, RankRequirement } from '../types';

export function sortBulkRequirements(
  requirements: AdvancementRequirement[],
): AdvancementRequirement[] {
  if (!requirements) return [];
  const containsSortOrder = requirements.some(req => req.sortOrder);

  /**
   * Ranks API already contain sort order information, e.g.
   * parent: { id: 123, sortOrder: "1", listNumber: "1.", requirementNumber: "" }
   * children: { parentRequirementId: 123, sortOrder: "1.1", listNumber: "(a)", requirementNumber: "1a" }
   *
   */
  if (containsSortOrder) {
    const sorted = _.chain(requirements)
      .map(item => {
        const numberValue = Number(item.sortOrder);
        const isNaN = Number.isNaN(numberValue);
        const sortValue = isNaN ? item.sortOrder : numberValue;
        return { ...item, sortValue };
      })
      .orderBy(['sortValue'], ['asc'])
      .map(item => _.omit(item, ['sortValue']))
      .value() as AdvancementRequirement[];
    return sorted;
  }

  const childrenRequirements = requirements.filter(
    req => !!req.parentRequirementId,
  );
  const parentRequirements = requirements.filter(
    req =>
      !req.parentRequirementId ||
      childrenRequirements.some(req => req.parentRequirementId === req.id),
  );

  /**
   * V1 APIs doesn't have a sortOrder property, for children reqs i.e. 1a,1b, they look like:
   *
   * parent: { id: 123, listNumber: "1", requirementNumber: "" }
   * children: { parentRequirementId: 123, listNumber: "(a)", requirementNumber: "1a" }
   */
  //
  const sorted = _.chain(parentRequirements)
    // First sort parent reqs by listNumber e.g. 1,2,3...
    .orderBy(
      req => {
        const value = Number(req.listNumber);
        if (Number.isNaN(value)) return req.listNumber;
        return value;
      },
      ['asc'],
    )
    .map(req =>
      // then sort children requirements by requirementNumber 1a,1b,1c,etc
      [
        req,
        ..._.chain(childrenRequirements)
          .filter(childrenReq => childrenReq.parentRequirementId === req.id)
          .orderBy('requirementNumber', ['asc'])
          .value(),
      ],
    )
    .flatMap()
    .value();
  return sorted;
}

export function isParentRequirement(requirement: AdvancementRequirement) {
  return Boolean(requirement.childrenRequired);
}

export function isUpdateableRequirement(requirement: AdvancementRequirement) {
  const isParent = isParentRequirement(requirement);
  const rankReq = requirement as RankRequirement;

  const hasLinkedMeritBadges = [
    Number(rankReq.eagleMBRequired),
    Number(rankReq.totalMBRequired),
  ].some(Boolean);

  const isSeaScoutElective =
    rankReq.requiresSSElective || !_.isEmpty(rankReq.ssElectives);

  const hasLinkedAdventures = ![
    rankReq.linkedAdventure,
    rankReq.linkedAdventureId,
    rankReq.linkedAwardId,
    rankReq.linkedElectiveAdventures,
  ].every(_.isEmpty);
  const disabledOnQuickEntry = parseStringBoolean(rankReq.disabledOnQuickEntry);

  return (
    !isSeaScoutElective &&
    !hasLinkedMeritBadges &&
    !isParent &&
    !hasLinkedAdventures &&
    !disabledOnQuickEntry
  );
}

export function isNotUpdateableRequirement(
  requirement: AdvancementRequirement,
) {
  return (
    !isUpdateableRequirement(requirement) ||
    shouldRequirementDisplayOnly(requirement)
  );
}
