export const moduleName = 'reports';

export const reportsTabs = Object.freeze({
  BASIC: 'basic',
  HISTORY: 'history',
  COUNCIL: 'council',
});

export const reportTypes = Object.freeze({
  ADVANCEMENT_SUMMARY: 'advancementHistoryReport',
  ACTIVITIES_SUMMARY: 'activitiesSummaryReport',
  COUNCIL_ACTIVITIES_SUMMARY: 'councilActivitiesSummaryReport',
});

export enum CustomReports {
  AdvancementReport = 'AdvancementReport',
  AdvancementHistory = 'AdvancementHistory',
  ActivitiesSummary = 'ActivitiesSummary',
  UnitRoster = 'UnitRosterReport',
}

// This code can be found in scriptsetcode on report uri params
export const reportCodes = Object.freeze({
  [CustomReports.AdvancementReport]: 'SBLAdvancementReport',
  [CustomReports.AdvancementHistory]: 'SBLUnitAdvancementSummary',
  [CustomReports.ActivitiesSummary]: 'SBLActivitiesSummaryReport',
  [CustomReports.UnitRoster]: 'SBLUnitRosterReport',
  REPORT_BUILDER_OUTPUT: 'SBReportBuilderOutput', // Not Working
  ELIGIBILITY_REPORT: 'SBOAEligibilityReport',
  ACTIVITY_LOG: 'SBALActivityLogReport',
  MOST_RECENT_ACTIVITIES_UNIT: 'SBLMostRecentActivitiesSummaryReportByUnit',
  MOST_RECENT_ACTIVITIES: 'SBLMostRecentActivitiesSummaryReport',
  AUDIT_LOG: 'SBAuditLogReport',
  INDIVIDUAL_SCOUTS_BSA: 'SBBoyScoutIndividualRecord',
  CUB_SCOUT_HISTORY: 'SBCubScoutHistoryReport', // Note: this one has a by Den report which is not supported
  SCOUTS_BSA_HISTORY: 'SBSBSAHistoryReport',
  PACK_RECOGNITION: 'SBPackRecognitionReport',
  PAYMENT_LOG: 'SBPLPaymentLogReport', // Not supported yet
  VENTURING_HISTORY: 'SBVHVenturingHistoryReport',
  TROOP_RECOGNITION: 'SBTroopRecognitionReport',
  SEA_SCOUT_HISTORY: 'SBSeaScoutHistoryReport',
  ACTIVITIES_SUMMARY: 'SBLActivitiesSummaryReport',
  COUNCIL_USERS: 'SBCouncilUsersReport',
  COUNCIL_ADVANCEMENT_REPORT_SCOUTS_BSA: 'OLRCouncilAdvancementReportScoutsBSA',
  COUNCIL_ADVANCEMENT_REPORT_CUB_SCOUTS: 'OLRCouncilAdvancementReportCubScouts',
  COUNCIL_ADVANCEMENT_REPORT_VENTURING: 'OLRCouncilAdvancementReportVenturing',
  COUNCIL_EAGLE_HOURS: 'SBLServiceHoursEagleScoutProjectByCouncilReport',
  ATTENDANCE: 'SBAttendanceReport',
});

export const councilAdvancementReports = [
  reportCodes.COUNCIL_ADVANCEMENT_REPORT_SCOUTS_BSA,
  reportCodes.COUNCIL_ADVANCEMENT_REPORT_CUB_SCOUTS,
  reportCodes.COUNCIL_ADVANCEMENT_REPORT_VENTURING,
  reportCodes.ATTENDANCE,
  reportCodes.MOST_RECENT_ACTIVITIES,
  reportCodes.MOST_RECENT_ACTIVITIES_UNIT,
  reportCodes.COUNCIL_EAGLE_HOURS,
];

export const MAX_USERS_SELECT_ACTIVITIES_REPORT = 25;

/** User by the adv. history report to create a context token */
export const ADV_HISTORY_CTX_API = `/advancements/webscript/output/sbreports.aspx?Application=MYST&Method=ExecuteScriptSet&ScriptSetCode=SBLUnitAdvancementSummary&ScriptCode=SaveScoutSelection`;
