import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import { S, Tabs, activityTypeForActivityTypeId, intl } from '@shared';

import { activityValuesTabs, useCalculateDays } from '../../../../common';
import {
  // selectors
  activeTabSel,
  personsCountSel, // actions
  setActiveTab,
} from '../../../duck';
import styles from './ActivityTabs.less';
import AdvancedTab from './AdvancedTab';
import BasicTab from './BasicTab';
import NonRegisteredPersons from './NonRegisteredPersons';

const { TabPane } = Tabs;

const ActivityTabs = ({
  form,
  activityTypeId,
  showTabDescription,
  showNonRegisteredParticipants,
}) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(activeTabSel);
  const personsCount = useSelector(personsCountSel(true));
  const isMobile = useSelector(isMobileSel);
  const { getFieldsValue } = form;
  const fieldsValue = getFieldsValue();
  const diff = useCalculateDays(fieldsValue, form);
  const activityType = activityTypeForActivityTypeId[activityTypeId];

  const handleTabChange = useCallback(
    tab => {
      dispatch(setActiveTab(tab));
    },
    [dispatch],
  );

  const description = useMemo(() => {
    if (isMobile || !showTabDescription) {
      return null;
    }
    return activeTab === activityValuesTabs.BASIC ? (
      <S size="5">
        <FormattedMessage
          id={`progress.activity.ActivityTabs.${activityType}.basicDescription`}
          values={{
            tabName: (
              <span>
                <FormattedMessage id="progress.activity.ActivityTabs.basic" />
              </span>
            ),
          }}
        />
      </S>
    ) : (
      <S size="5">
        <FormattedMessage
          id={`progress.common.ActivityTabs.${activityType}.advancedDescription`}
          values={{
            tabName: (
              <span>
                <FormattedMessage id="progress.activity.ActivityTabs.advanced" />
              </span>
            ),
          }}
        />
      </S>
    );
  }, [activeTab, activityType, isMobile, showTabDescription]);

  if (!activityType) {
    return null;
  }

  return (
    <React.Fragment>
      {personsCount > 0 && (
        <Tabs
          className={styles.tabsContainer}
          size="small"
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarExtraContent={description}
        >
          <TabPane
            tab={intl.formatMessage({
              id: 'progress.activity.ActivityTabs.basic',
            })}
            key={activityValuesTabs.BASIC}
          >
            <BasicTab
              activityTypeId={activityTypeId}
              isActive={activeTab === activityValuesTabs.BASIC}
              diffDays={diff}
              showTabDescription={isMobile && showTabDescription}
            />
          </TabPane>
          <TabPane
            tab={intl.formatMessage({
              id: 'progress.activity.ActivityTabs.advanced',
            })}
            key={activityValuesTabs.ADVANCED}
          >
            <AdvancedTab
              activityTypeId={activityTypeId}
              isActive={activeTab === activityValuesTabs.ADVANCED}
              diffDays={diff}
              showTabDescription={isMobile && showTabDescription}
            />
          </TabPane>
        </Tabs>
      )}
      {showNonRegisteredParticipants && <NonRegisteredPersons form={form} />}
    </React.Fragment>
  );
};

ActivityTabs.propTypes = {
  form: PropTypes.object.isRequired,
  activityTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  showTabDescription: PropTypes.bool.isRequired,
  showNonRegisteredParticipants: PropTypes.bool,
};

export default ActivityTabs;
