import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isSeaScoutSel } from '@context/duck/selectorsTyped';
import { Checkbox, S, activityTypes } from '@shared';

const ActivityTypesFilterSection = ({ values, onChange }) => {
  const handleChange = useCallback(
    e => {
      const { name } = e.target;
      const newValues = {
        ...values,
        [name]: !values[name],
      };

      onChange(newValues);
    },
    [values, onChange],
  );

  const isSeaScout = useSelector(isSeaScoutSel);
  return (
    <div>
      <Checkbox
        checked={values[activityTypes.CAMPOUTS]}
        name={activityTypes.CAMPOUTS}
        onChange={handleChange}
      >
        <S size="4" colored inline>
          <FormattedMessage id="advancement.PendingItems.activity.camping" />
        </S>
      </Checkbox>
      <Checkbox
        checked={values[activityTypes.SERVICE_PROJECTS]}
        name={activityTypes.SERVICE_PROJECTS}
        onChange={handleChange}
      >
        <S size="4" colored inline>
          <FormattedMessage id="advancement.PendingItems.activity.serviceProjects" />
        </S>
      </Checkbox>
      <Checkbox
        checked={values[activityTypes.HIKES]}
        name={activityTypes.HIKES}
        onChange={handleChange}
      >
        <S size="4" colored inline>
          <FormattedMessage id="advancement.PendingItems.activity.hikes" />
        </S>
      </Checkbox>
      {isSeaScout && (
        <Checkbox
          checked={values[activityTypes.LONG_CRUISE]}
          name={activityTypes.LONG_CRUISE}
          onChange={handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.PendingItems.activity.longCruise" />
          </S>
        </Checkbox>
      )}
      <Checkbox
        checked={values[activityTypes.EAGLE_SERVICE_PROJECT]}
        name={activityTypes.EAGLE_SERVICE_PROJECT}
        onChange={handleChange}
      >
        <S size="4" colored inline>
          <FormattedMessage id="advancement.PendingItems.activity.eagleServiceProject" />
        </S>
      </Checkbox>
    </div>
  );
};

ActivityTypesFilterSection.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.object,
};

export default ActivityTypesFilterSection;
