import React, { useCallback, useMemo } from 'react';

import cn from 'classnames';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { RosterScout } from '@modules/advancement/duck/types';
import { groupYouthsAdultsParents } from '@modules/shared/components/PersonSelector/utils';
import { personNameBuilder } from '@modules/utils';
import { Button, Col, PersonAvatar, Row, Select } from '@shared';
import { userDataSel, userIdSel } from '@user';

import { selectPerson } from '../../duck';
import styles from './AddPersonButton.less';

const { Option } = Select;

const filterOption = (input: string, option: React.ReactElement) => {
  if (!option) return false;

  if (option.props.value === 'Label') {
    return true;
  }

  return option.props.title.toLowerCase().includes(input.toLowerCase());
};

type Props = {
  className?: string;
  persons: RosterScout[];
  selectedPersons: number[];
  notAllowedToRemoveIds?: number[];
  canRemove: boolean;
  onToggleView?: (isVisible: boolean) => void;
  onSelectPerson?: (userId: number) => void;
  onDeselectPerson: (userId: number) => void;
  label?: React.ReactNode;
  extraFilter?: (scout: RosterScout) => boolean;
};

const AddPersonButton: React.FC<Props> = ({
  className,
  persons,
  selectedPersons,
  notAllowedToRemoveIds,
  onDeselectPerson,
  onSelectPerson,
  children,
  canRemove = false,
  extraFilter,
  label,
}) => {
  const dispatch = useDispatch();
  const loggedInUserId = useSelector(userIdSel);
  const loggedInUserData = useSelector(userDataSel);

  const foundLoggedInUser = useMemo(
    () => persons.find(person => person.userId === loggedInUserId),
    [persons, loggedInUserId],
  );

  const handleSelectPerson = useCallback(
    (userId: number) => {
      if (onSelectPerson) {
        onSelectPerson(userId);
      } else {
        dispatch(selectPerson(userId));
      }
    },
    [dispatch, onSelectPerson],
  );

  const handleDeselectPerson = useCallback(
    userId => {
      if (canRemove && !notAllowedToRemoveIds?.includes(userId)) {
        onDeselectPerson(userId);
      }
    },
    [canRemove, onDeselectPerson, notAllowedToRemoveIds],
  );

  const filterPerson = useCallback(
    (person: RosterScout) => {
      const { userId, memberId } = person;
      if (!userId || !memberId) return false;
      if (notAllowedToRemoveIds?.includes(userId)) return false;
      if (extraFilter) {
        return extraFilter(person);
      }
      return true;
    },
    [extraFilter, notAllowedToRemoveIds],
  );

  const options = useMemo<
    Pick<
      RosterScout,
      | 'userId'
      | 'personFullName'
      | 'firstName'
      | 'lastName'
      | 'nickName'
      | 'isAdult'
    >[]
  >(() => {
    const allowedPersons = persons
      .filter(filterPerson)
      .map(
        ({
          userId,
          personFullName,
          firstName,
          lastName,
          nickName,
          isAdult,
        }) => ({
          userId,
          personFullName,
          firstName,
          lastName,
          nickName,
          isAdult,
        }),
      );

    if (!foundLoggedInUser && loggedInUserData) {
      const {
        fullName: personFullName,
        firstName,
        lastName,
        nickName,
      } = loggedInUserData.profile;

      const loggedInPerson = {
        userId: loggedInUserId,
        personFullName,
        firstName,
        lastName,
        nickName,
        isAdult: true,
      };
      if (filterPerson(loggedInPerson as RosterScout)) {
        allowedPersons.push(loggedInPerson);
      }
    }

    return allowedPersons.sort((a, b) =>
      String(a.lastName).localeCompare(b.lastName),
    );
  }, [
    persons,
    foundLoggedInUser,
    loggedInUserData,
    loggedInUserId,
    filterPerson,
  ]);

  const focusOnSelect = useCallback(() => {
    const select = document.getElementById('person-select');
    select?.click();
  }, []);

  const toggleButton = useMemo(() => {
    if (React.isValidElement(children)) {
      return React.cloneElement(
        children as React.ReactElement<{ onClick: () => void }>,
        { onClick: focusOnSelect },
      );
    }

    return (
      <Button
        shape="round"
        type="primary"
        onClick={focusOnSelect}
        className={styles.addPersonButton}
        block
      >
        {label || <FormattedMessage id="progress.AddPersonButton.title" />}
      </Button>
    );
  }, [children, focusOnSelect, label]);

  const selectOptions = useMemo(() => {
    const updatedList = groupYouthsAdultsParents(options);

    const youths = _.orderBy(updatedList.youths, ['lastName', 'firstName']).map(
      person => {
        const { userId, personFullName, isAdult } = person;
        return (
          <Option key={userId} value={userId} title={personFullName}>
            <Row type="flex" align="middle" className={styles.person}>
              <Col>
                <PersonAvatar className={styles.avatar} isAdult={isAdult} />
              </Col>
              <Col className={styles.personName}>
                {personNameBuilder.short(person)}
              </Col>
            </Row>
          </Option>
        );
      },
    );

    const adults = _.orderBy(updatedList.adults, ['lastName', 'firstName']).map(
      person => {
        const { userId, personFullName, isAdult } = person;
        return (
          <Option key={userId} value={userId} title={personFullName}>
            <Row type="flex" align="middle" className={styles.person}>
              <Col>
                <PersonAvatar className={styles.avatar} isAdult={isAdult} />
              </Col>
              <Col className={styles.personName}>
                {personNameBuilder.short(person)}
              </Col>
            </Row>
          </Option>
        );
      },
    );

    return [
      <Option
        className={styles.default}
        value="Label"
        title="Youths"
        key={'label'}
        disabled
      >
        <strong>
          <FormattedMessage id="progress.dropdown.label.youths" />
        </strong>
      </Option>,
      youths,
      <Option
        className={styles.default}
        value="Label"
        title="Leaders"
        key={'label'}
        disabled
      >
        <strong>
          <FormattedMessage id="progress.dropdown.label.adults" />
        </strong>
      </Option>,
      adults,
    ];
  }, [options]);

  return (
    <div
      className={className ? cn(styles.container, className) : styles.container}
    >
      <Select
        className={styles.dropDownContainer}
        filterOption={filterOption}
        id="person-select"
        mode="multiple"
        autoClearSearchValue={false}
        onDeselect={handleDeselectPerson}
        onSelect={handleSelectPerson}
        optionFilterProp="title"
        showSearch
        value={selectedPersons}
      >
        {selectOptions}
      </Select>
      <div className={styles.buttonContainer}>{toggleButton}</div>
    </div>
  );
};

export default AddPersonButton;
