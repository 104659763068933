import { usAkelaCountryId } from '../constants';
import { trimField, trimObject } from './trimFields';

const getZipAndExt = zip => {
  const hasExt = zip && zip.length > 5;
  const hasDash = zip && zip.includes('-');

  if (!hasExt) {
    return { zip, zipExtension: null };
  }

  if (hasExt && !hasDash) {
    return { zip: zip.slice(0, 5), zipExtension: zip.slice(5) };
  }

  if (hasExt && hasDash) {
    const [zip5, zipExt] = zip.split('-');
    return { zip: zip5, zipExtension: zipExt };
  }
};

export default (formAddresses = {}, profileAddresses = []) => {
  const formattedAddresses = [];
  Object.entries(formAddresses).forEach(([key, address]) => {
    const foundProfileAddress = profileAddresses.find(
      profileAddress => profileAddress.id == key.split('_')[1],
    );

    if (foundProfileAddress && address.countryId != usAkelaCountryId) {
      formattedAddresses.push({
        ...trimObject(foundProfileAddress),
        ...trimObject(address),
        id: Number(foundProfileAddress.id),
        countryId: Number(address.countryId),
        typeId: Number(address.typeId),
        stateId: null,
        county: '',
        zip: '',
        zipExtension: '',
        city: '',
      });
      return;
    }

    if (foundProfileAddress) {
      formattedAddresses.push({
        ...trimObject(foundProfileAddress),
        ...trimObject(address),
        id: Number(foundProfileAddress.id),
        countryId: Number(address.countryId),
        stateId: Number(address.stateId),
        typeId: Number(address.typeId),
        addressLine3: '',
        county: '',
        city: trimField(address.city || ''),
        ...getZipAndExt(address.zip),
      });
      return;
    }

    if (address && address.addressLine1) {
      formattedAddresses.push({
        ...trimObject(address),
        countryId: Number(address.countryId),
        stateId: Number(address.stateId),
        ...getZipAndExt(address.zip),
      });
    }
  });
  return formattedAddresses;
};
