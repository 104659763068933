import React from 'react';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import moment from 'moment';
import PropTypes from 'prop-types';
import Recaptcha from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import {
  API_SWITCH_DEV,
  APP_VERSION,
  LOGIN_CAPTCHA,
  SCOUTBOOK_LEGACY_URL,
  getMyScoutingUrl,
} from '@config';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import {
  ApiSelector,
  Button,
  Card,
  Col,
  FlexSpacer,
  Input,
  OfflineIcon,
  Row,
  S,
  T,
} from '@shared/components';
import { appName } from '@shared/constants';
import { offlineSel } from '@shared/duck';
import { intl } from '@shared/localization';

import FleurDeLisIcon from '../../../../assets/images/FleurDeLis.svg';
import { loginLoadingSel, loginRequest, userDataLoadingSel } from '../../duck';
import ScoutbookAd from '../ScoutbookAd';
import AppleSignInButton from './AppleSignInButton';
import GoogleSignInButton from './GoogleSignInButton';
import styles from './LoginPage.less';

const captchaEnabled = LOGIN_CAPTCHA;
const RECOVER_URL = `${getMyScoutingUrl()}/recover-credentials`;

class LoginPage extends React.PureComponent {
  state = {
    login: '',
    password: '',
    isPasswordVisible: false,
    captchaValue: null,
    redirecting: false,
  };

  componentDidUpdate(_, prevState) {
    if (prevState.isPasswordVisible != this.state.isPasswordVisible) {
      this.passwordInput.focus();
    }
  }

  componentWillUnmount = () => {
    this.captcha && this.captcha.reset();
  };

  captcha;
  passwordInput;

  storeCaptchaRef = el => (this.captcha = el);
  storePasswordInputRef = el => (this.passwordInput = el);

  isValidForm = () =>
    this.state.login &&
    this.state.password &&
    (captchaEnabled ? this.state.captchaValue : true);

  handleCaptchaChange = captchaValue => {
    this.setState({ captchaValue });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onLogin } = this.props;
    const { login, password } = this.state;

    if (this.isValidForm()) {
      onLogin(login, password);
    }
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleTogglePasswordVisibilityClick = () => {
    this.setState(({ isPasswordVisible }) => ({
      isPasswordVisible: !isPasswordVisible,
    }));
  };

  openScoutingPage() {
    window.open(`${getMyScoutingUrl()}/create-account`, '_blank');
  }

  navigateToScoutbookLegacy = () => {
    window.location.href = SCOUTBOOK_LEGACY_URL;
    this.setState({ redirecting: true });
  };

  showMessageWarning = () =>
    moment(featureFlags.getFlag('MAINTENANCE_END_DATE')).isAfter(moment());

  render() {
    const { loading, offline } = this.props;
    const { login, password, isPasswordVisible } = this.state;
    const isValidFrom = this.isValidForm();
    const disabled = loading || offline || !isValidFrom;
    const toggleViewPasswordIcon = isPasswordVisible ? (
      <VisibilityOffIcon
        id="qa_hidePassword"
        className={styles.toggleViewPasswordIcon}
        onClick={this.handleTogglePasswordVisibilityClick}
      />
    ) : (
      <VisibilityIcon
        id="qa_showPassword"
        className={styles.toggleViewPasswordIcon}
        onClick={this.handleTogglePasswordVisibilityClick}
      />
    );

    return (
      <div className={styles.container}>
        <div className={styles.bg} />
        <Card className={styles.card} shadow>
          <form className={styles.form} onSubmit={this.handleSubmit}>
            <FleurDeLisIcon className={styles.logoIcon} />
            <T size="2" className={styles.formHeader}>
              <FormattedMessage
                id="user.LoginPage.loginToScoutbook"
                values={{ br: <br />, appName }}
              />
            </T>
            {this.showMessageWarning() && (
              <div className={styles.warningContainer}>
                <S size="3" className={styles.formTitleWarning}>
                  <FormattedMessage id="user.LoginPage.titleWarning" />
                </S>
                <S size="4" className={styles.formWarning}>
                  {featureFlags.getFlag('MAINTENANCE_MESSAGE')}
                </S>
              </div>
            )}
            <Input
              type="text"
              name="login"
              id="qa_username"
              size="large"
              autoComplete="username"
              disabled={loading || offline}
              placeholder={intl.formatMessage({
                id: 'user.LoginPage.username',
              })}
              className={styles.loginInput}
              value={login}
              onChange={this.handleInputChange}
            />
            <Input
              type={isPasswordVisible ? 'text' : 'password'}
              name="password"
              id="qa_password"
              autoComplete="current-password"
              size="large"
              disabled={loading || offline}
              placeholder={intl.formatMessage({
                id: 'user.LoginPage.password',
              })}
              className={styles.passwordInput}
              value={password}
              onChange={this.handleInputChange}
              suffix={toggleViewPasswordIcon}
              ref={this.storePasswordInputRef}
            />
            {captchaEnabled && (
              <div className={styles.recaptchaWrapper}>
                <Recaptcha
                  ref={this.storeCaptchaRef}
                  size="normal"
                  sitekey="6Ldy-mcUAAAAAGakOVzogq8D-i1OxSXCCFyAXcrl"
                  onChange={this.handleCaptchaChange}
                />
              </div>
            )}
            {offline ? (
              <OfflineIcon className={styles.offlineIcon} />
            ) : (
              <Button
                htmlType="submit"
                id="qa_login"
                type="primary"
                size="large"
                shadow
                disabled={disabled}
                loading={loading}
                className={styles.loginButton}
              >
                <FormattedMessage id="user.LoginPage.login" />
              </Button>
            )}
            <span className={styles.passwordReminder}>
              <a href={RECOVER_URL} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="user.LoginPage.forgotUsername" />
                {' / '}
                <FormattedMessage id="user.LoginPage.forgotPassword" />
              </a>
            </span>
            <Row justify="center" className={styles.externalLoginButtons}>
              <Col span={24}>
                <GoogleSignInButton />
              </Col>
            </Row>
            <Row justify="center" className={styles.externalLoginButtons}>
              <Col span={24}>
                <AppleSignInButton />
              </Col>
            </Row>
            {API_SWITCH_DEV && <ApiSelector />}
            <span className={styles.formNewUserText}>
              <FormattedMessage id="user.LoginPage.newToScouting" />
            </span>
            <Button
              id="qa_createAccount"
              onClick={this.openScoutingPage}
              ghost
              noBorder
              size="small"
              fitText
              className={styles.formNewUserButton}
            >
              <FormattedMessage id="user.LoginPage.createAccount" />
            </Button>
            <FlexSpacer />
            <S size="5" className={styles.appVersion}>
              v{APP_VERSION}
            </S>
          </form>
        </Card>
        <ScoutbookAd className={styles.ad} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  offline: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
};

const mapState = state => ({
  loading: loginLoadingSel(state) || userDataLoadingSel(state),
  offline: offlineSel(state),
});

const mapDispatch = dispatch => ({
  onLogin: (login, password) => dispatch(loginRequest(login, password)),
});

export default connect(mapState, mapDispatch)(LoginPage);
