import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Form, Input, S, intl } from '@shared';
import styles from './ActivityDescription.less';

const FormItem = Form.Item;
const { TextArea } = Input;
const maxDescriptionLength = 1500;

const ActivityDescription = ({ fieldLabel, form, disabled }) => {
  const { getFieldDecorator, getFieldsValue } = form;

  const verticalItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const { description = '' } = getFieldsValue();

  return (
    <FormItem
      colon={false}
      label={
        <div>
          {fieldLabel}
          <S size="6" className={styles.descriptionCharCounter}>
            <FormattedMessage
              id="progress.common.ActivityDesciption.charCount"
              values={{ chars: description.length, max: maxDescriptionLength }}
            />
          </S>
        </div>
      }
      {...verticalItemLayout}
    >
      {getFieldDecorator('description')(
        <TextArea
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'progress.common.ActivityDesciption.placeholder',
          })}
          maxLength={maxDescriptionLength}
          autosize={{ minRows: 3 }}
        />,
      )}
    </FormItem>
  );
};

ActivityDescription.propTypes = {
  form: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  fieldLabel: PropTypes.node,
};

export default ActivityDescription;
