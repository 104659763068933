import React from 'react';
import ContentLoader from 'react-content-loader';

import styles from './LoginPagePlaceholder.less';

const LoginPagePlaceholder = () => (
  <div className={styles.container}>
    <ContentLoader width={500} height={150} className={styles.left}>
      <rect x="80" y="0" rx="3" ry="3" width="400" height="20" />
      <rect x="80" y="80" rx="3" ry="3" width="360" height="15" />
      <rect x="80" y="105" rx="3" ry="3" width="380" height="15" />
      <rect x="80" y="130" rx="3" ry="3" width="300" height="15" />
    </ContentLoader>
    <ContentLoader width={300} height={400} className={styles.right}>
      <circle cx="150" cy="45" r="45" />
      <rect x="20" y="110" rx="3" ry="3" width="260" height="25" />
      <rect x="10" y="200" rx="3" ry="3" width="280" height="30" />
      <rect x="10" y="280" rx="3" ry="3" width="280" height="30" />
      <rect x="40" y="360" rx="3" ry="3" width="220" height="35" />
    </ContentLoader>
  </div>
);

export default LoginPagePlaceholder;
