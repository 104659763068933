// all values read from .env are strings, so we need to apply conversions to non-string values
const boolValue = val => val === 'true';

const apiEnvStorageKey = 'API_ENV';

export const NODE_ENV = process.env.NODE_ENV;
export const isProductionBuild = NODE_ENV === 'production';
export const APP_VERSION = process.env.APP_VERSION;
export const VERSION = process.env.VERSION;
export const COMMITHASH = process.env.COMMITHASH;
if (NODE_ENV !== 'test') {
  // eslint-disable-next-line no-console, no-undef
  console.log(`SBL v${APP_VERSION} - ${VERSION} - ${COMMITHASH}`);
}
export const SBL_4382_PRINT_BLUE_CARD_ENABLED = false;
export const SBL_3701_PAPERWORK_LINK = boolValue(
  process.env.SBL_3701_PAPERWORK_LINK,
);
export const SHOW_HEADER_CREATE_BUTTON = boolValue(
  process.env.SHOW_HEADER_CREATE_BUTTON,
);
export const SHOW_DASHBOARD = boolValue(process.env.SHOW_DASHBOARD);
export const UPGRADE_PLAN = boolValue(process.env.UPGRADE_PLAN);
export const SHOW_VALIDATE_EAGLE = boolValue(process.env.SHOW_VALIDATE_EAGLE);
export const SHOW_EXTRA_ADVANCEMENT_HISTORY_FILTERS = boolValue(
  process.env.SHOW_EXTRA_ADVANCEMENT_HISTORY_FILTERS,
);

export const FEATURE_FLAG_EDITOR = boolValue(process.env.FEATURE_FLAG_EDITOR);

export const SHOW_UNIT_PIN_INFORMATION = boolValue(
  process.env.SHOW_UNIT_PIN_INFORMATION,
);
export const SHOW_USER_PERMISSIONS = boolValue(
  process.env.SHOW_USER_PERMISSIONS,
);
export const SHOW_RECHARTER_REMINDER_MODAL = boolValue(
  process.env.SHOW_RECHARTER_REMINDER_MODAL,
);
export const SHOW_SETTINGS_EXTRA_FIELDS = boolValue(
  process.env.SHOW_SETTINGS_EXTRA_FIELDS,
);
export const SHOW_YOUTH_PROFILE_EDIT = boolValue(
  process.env.SHOW_YOUTH_PROFILE_EDIT,
);
export const SHOW_YOUTH_PROFILE_INFORMATION = boolValue(
  process.env.SHOW_YOUTH_PROFILE_INFORMATION,
);
export const SHOW_YOUTH_PROFILE_AWARDS = boolValue(
  process.env.SHOW_YOUTH_PROFILE_AWARDS,
);
export const EDIT_YOUTH_PROFILE_POSITIONS = boolValue(
  process.env.EDIT_YOUTH_PROFILE_POSITIONS,
);
export const DEV_WARNINGS =
  !isProductionBuild && boolValue(process.env.DEV_WARNINGS);
export const SHOW_EXTRA_PROFILE_FIELDS = boolValue(
  process.env.SHOW_EXTRA_PROFILE_FIELDS,
);
export const SHOW_IN_APP_FEEDBACK = boolValue(process.env.SHOW_IN_APP_FEEDBACK);
export const SHOW_HOBBIES_INPUT = boolValue(process.env.SHOW_HOBBIES_INPUT);
export const PREVENT_STORE_SB_PREFERENCE = boolValue(
  process.env.PREVENT_STORE_SB_PREFERENCE,
);
export const SHOW_OFFLINE_FEATURES = boolValue(
  process.env.SHOW_OFFLINE_FEATURES,
);
export const SHOW_MB_COUNSELORS = boolValue(process.env.SHOW_MB_COUNSELORS);
export const SHOW_YOUTH_ADV_REQUIREMENTS = boolValue(
  process.env.SHOW_YOUTH_ADV_REQUIREMENTS,
);
export const SBL_5198_CUB_PROFILE_VIEWMORE = boolValue(
  process.env.SBL_5198_CUB_PROFILE_VIEWMORE,
);
export const SBL_4688_ADD_INDIVIDUAL = boolValue(
  process.env.SBL_4688_ADD_INDIVIDUAL,
);
export const SHOW_ADVANCEMENTS_COMMENTS = boolValue(
  process.env.SHOW_ADVANCEMENTS_COMMENTS,
);
export const SHOW_SEA_ELECTIVES_DETAILS_ON_REQUIREMENTS = boolValue(
  process.env.SHOW_SEA_ELECTIVES_DETAILS_ON_REQUIREMENTS,
);
export const SBL_5060_ENABLE_HTML_EVENT_DESCRIPTION = boolValue(
  process.env.SBL_5060_ENABLE_HTML_EVENT_DESCRIPTION,
);
export const EDIT_SUB_UNITS = boolValue(process.env.EDIT_SUB_UNITS);
export const SHOW_NEW_YOUTH_PROFILE = boolValue(
  process.env.SHOW_NEW_YOUTH_PROFILE,
);
export const SHOW_RECORD_ADV_STATUS = boolValue(
  process.env.SHOW_RECORD_ADV_STATUS,
);

export const SHOW_COUNSELED_YOUTH = boolValue(process.env.SHOW_COUNSELED_YOUTH);
export const SHOW_PAYMENT_LOGS = boolValue(process.env.SHOW_PAYMENT_LOGS);
export const SHOW_UNIT_PAYMENT_LOGS = boolValue(
  process.env.SHOW_UNIT_PAYMENT_LOGS,
);

export const SHOW_PAYPAL_PAYMENT_LOGS = boolValue(
  process.env.SHOW_PAYPAL_PAYMENT_LOGS, // SBL-2827
);
export const SHOW_DOWNLOAD_CSV_PAYMENT_LOGS = boolValue(
  process.env.SHOW_DOWNLOAD_CSV_PAYMENT_LOGS, // SBL-2844
);
export const ALLOW_MBC_LOGIN = boolValue(process.env.ALLOW_MBC_LOGIN);
export const SHOW_ACTIVITIES_MENU = boolValue(process.env.SHOW_ACTIVITIES_MENU);
export const COMMISSIONER_ACCESS = boolValue(process.env.COMMISSIONER_ACCESS);
export const ALLOW_DELETE_ACTIVITIES = boolValue(
  process.env.ALLOW_DELETE_ACTIVITIES,
);
export const ENABLE_VENTURING_RANKS = boolValue(
  process.env.ENABLE_VENTURING_RANKS,
);

export const ENABLE_GOOGLE_SIGN_IN = boolValue(
  process.env.ENABLE_GOOGLE_SIGN_IN,
);

export const USE_NEW_SCOUTBOOK_DOMAIN = boolValue(
  process.env.USE_NEW_SCOUTBOOK_DOMAIN,
);

export const GET_ACTIVITIES_V2 = boolValue(process.env.GET_ACTIVITIES_V2);

export const ENABLE_PROFILE_PICTURE_UPDATE = boolValue(
  process.env.ENABLE_PROFILE_PICTURE_UPDATE,
);
export const ENABLE_APPLE_SIGN_IN = boolValue(process.env.ENABLE_APPLE_SIGN_IN);

export const GOOGLE_SIGN_IN_CLIENT_ID = process.env.GOOGLE_SIGN_IN_CLIENT_ID;

export const ENABLE_WEPAY_PAYMENT = boolValue(process.env.ENABLE_WEPAY_PAYMENT);
export const WEPAY_CLIENT_ID = process.env.WEPAY_CLIENT_ID;
export const WEPAY_ENDPOINT = process.env.WEPAY_ENDPOINT;
export const ENABLE_RECHARTER_PAYMENT = boolValue(
  process.env.ENABLE_RECHARTER_PAYMENT,
);

export const SHOW_LOG_FILTERS = boolValue(process.env.SHOW_LOG_FILTERS);

export const ENABLE_EVENT_ACTIVITIES = boolValue(
  process.env.ENABLE_EVENT_ACTIVITIES,
);

export const ENABLE_EVENT_ADVANCEMENTS = boolValue(
  process.env.ENABLE_EVENT_ADVANCEMENTS,
);

export const ENABLE_EAGLE_PROJECT = boolValue(process.env.ENABLE_EAGLE_PROJECT);

export const ENABLE_SAND_BOX_MODAL = boolValue(
  process.env.ENABLE_SAND_BOX_MODAL,
);

export const RECHARTER_DATE_RESTRICTION = boolValue(
  process.env.RECHARTER_DATE_RESTRICTION,
);

export const ENABLE_ADOBE_PDF_TEST_BUTTON = boolValue(
  process.env.ENABLE_ADOBE_PDF_TEST_BUTTON,
);

export const USE_ROLETYPES_PARAMS = boolValue(process.env.USE_ROLETYPES_PARAMS);

export const SHOW_AKELA_PROFILE = boolValue(process.env.SHOW_AKELA_PROFILE);

export const SHOW_QUICK_ENTRY = boolValue(process.env.SHOW_QUICK_ENTRY);

export const SHOW_JOIN_DATE = boolValue(process.env.SHOW_JOIN_DATE);

export const SHOW_PERMISSION_MANAGER = boolValue(
  process.env.SBL_3906_PERMISSION_MANAGER,
);

export const SHOW_PERMISSIONS_TAB = boolValue(
  process.env.SBL_4142_PERMISSIONS_TAB,
);

export const SHOW_POSITIONS_TAB = boolValue(process.env.SBL_4142_POSITIONS_TAB);

export const SHOW_ROLES_TAB = boolValue(process.env.SBL_4142_ROLES_TAB);

export const SHOW_MESSAGING = boolValue(process.env.SBL_4143_SHOW_MESSAGING);

// TODO: remove for PROD when ready
export const SHOW_RSVPSECTION = process.env.SHOW_RSVPSECTION;

export const USE_UNITS_ROLETYPES = boolValue(process.env.USE_UNITS_ROLETYPES);

export const SHOW_LOGIN_WARNING = boolValue(process.env.SHOW_LOGIN_WARNING);

export const MAINTENANCE_END_DATE = process.env.MAINTENANCE_END_DATE;
export const MAINTENANCE_MESSAGE = process.env.MAINTENANCE_MESSAGE;

export const SBL_3435_SHOW_BULK_ENTRY_MB = boolValue(
  process.env.SBL_3435_SHOW_BULK_ENTRY_MB,
);
export const SBL_4005_SHOW_RANK_QUICK_ENTRY = boolValue(
  process.env.SBL_4005_SHOW_RANK_QUICK_ENTRY,
);
export const SBL_4358_SHOW_BULK_ENTRY_ADVENTURE = boolValue(
  process.env.SBL_4358_SHOW_BULK_ENTRY_ADVENTURE,
);
export const SBL_5198_CUB_BULK_ENTRY = boolValue(
  process.env.SBL_5198_CUB_BULK_ENTRY,
);

export const SBL_4797_SHOW_RENEWAL_ROSTER = boolValue(
  process.env.SBL_4797_SHOW_RENEWAL_ROSTER,
);

export const SBL_4798_MEMBERSHIP_EXP_MODALS = boolValue(
  process.env.SBL_4798_MEMBERSHIP_EXP_MODALS,
);

export const SBL_4832_EXPIRED_MEMBERSHIP_ACCESS = boolValue(
  process.env.SBL_4832_EXPIRED_MEMBERSHIP_ACCESS,
);

export const ENABLE_VIEW_AWARDS = boolValue(process.env.ENABLE_VIEW_AWARDS);

export const SBL_4744_DISABLE_RECHARTER = boolValue(
  process.env.SBL_4744_DISABLE_RECHARTER,
);

export const SBL_4523_BULK_ENTRY_COMMENTS = boolValue(
  process.env.SBL_4523_BULK_ENTRY_COMMENTS,
);

export const SBL_4724_PENDING_REQUIREMENTS = boolValue(
  process.env.SBL_4724_PENDING_REQUIREMENTS,
);

export const SBL_3444_BULK_YOUTH_LEADERSHIP_POSITIONS = boolValue(
  process.env.SBL_3444_BULK_YOUTH_LEADERSHIP_POSITIONS,
);

export const SBL_5033_SHOW_DOWNLOAD_SLIP = boolValue(
  process.env.SBL_5033_SHOW_DOWNLOAD_SLIP,
);

export const SBL_5138_OPTIMIZE_CALENDAR_EVENTS = boolValue(
  process.env.SBL_5138_OPTIMIZE_CALENDAR_EVENTS,
);

export const SBL_4848_PENDING_LEADERSHIP_POSITIONS = boolValue(
  process.env.SBL_4848_PENDING_LEADERSHIP_POSITIONS,
);

export const SBL_5154_UNIT_SEARCH_UPDATE = boolValue(
  process.env.SBL_5154_UNIT_SEARCH_UPDATE,
);

export const SBL_5102_MY_SCOUT = boolValue(process.env.SBL_5102_MY_SCOUT);

export const SBL_5372_NEW_ADV_HIST_REPORT = boolValue(
  process.env.SBL_5372_NEW_ADV_HIST_REPORT,
);

export const SBL_5399_SKIP_UNSTARTED_REQUIREMENTS = boolValue(
  process.env.SBL_5399_SKIP_UNSTARTED_REQUIREMENTS,
);

export const SBL_4463_ADVANCEMENTS_TAB = boolValue(
  process.env.SBL_4463_ADVANCEMENTS_TAB,
);

export const SBL_5150_DASHBOARD_API = boolValue(
  process.env.SBL_5150_DASHBOARD_API,
);

//
// DEV values
//
export const API_SWITCH_DEV =
  !isProductionBuild && boolValue(process.env.API_SWITCH_DEV);

export const ENABLE_MSW = boolValue(process.env.ENABLE_MSW);

const guessEnvFromURL = () => {
  const hostname = window.location.hostname.toLowerCase();
  const isLocalhost = Boolean(
    self.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      self.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      self.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );
  if (isLocalhost) {
    return 'local';
  }
  const [subdomainname] = hostname.split('.');
  switch (subdomainname.slice(-2)) {
    case 'dv':
      return 'dv';
    case 'qa':
      return 'qa';
    case 'pp':
      return 'pp';
    default:
      return 'pd';
  }
};

//
// config values that can be changed at runtime
//
export const initApiEnv = () => {
  if (!isProductionBuild && API_SWITCH_DEV) {
    return (
      localStorage.getItem(apiEnvStorageKey) ||
      process.env.API_ENV_DEFAULT ||
      ''
    );
  }
  const env = guessEnvFromURL();
  // default API env for local is qa
  if (env === 'local') {
    return 'qa';
  }
  return env;
};

export const ENV = guessEnvFromURL();
export const IS_PROD_ENV = ENV === 'pd';
export const IS_LOCAL_ENV = ENV === 'local';
export let API_ENV = initApiEnv();
export const setApiEnvConfig = env => {
  localStorage.setItem(apiEnvStorageKey, env);
  API_ENV = env;
};

//
// variables that should be passed in at build time, but need to be inferred at runtime for now
//

export const LOGIN_CAPTCHA = ['pd', 'dv', 'pp'].includes(ENV);

const getScoutbookLegacyLink = (env = '') =>
  `https://scoutbook${env}.scouting.org/mobile/dashboard`;
/**
 * @todo change name to SCOUTBOOK_URL, old domain is already deprecated
 */
export const SCOUTBOOK_LEGACY_URL = (() => {
  switch (ENV) {
    case 'pd':
      return getScoutbookLegacyLink();
    case 'local': {
      return getScoutbookLegacyLink('qa');
    }
    default:
      return getScoutbookLegacyLink(ENV);
  }
})();

export const REPORTS_BASE_URL = (() => {
  const env = ENV === 'pd' ? '' : ENV === 'local' ? 'qa' : ENV;
  return `https://my${env}.scouting.org`;
})();

export const REPORTS_ALT_BASE_URL = (() => {
  if (ENV === 'local') return 'http://localhost:3000';
  return `https://reports${ENV}.scouting.org`;
})();

export const FORUMS_URL = `https://discussions${
  ENV === 'pd' ? '' : 'qa'
}.scouting.org`;

export const HELPDESK_URL = 'https://help.scoutbook.com';

export const IA_HELP_URL = 'https://help.scoutbook.com/internetadvancement';

export const CORE_API_URLS = Object.freeze({
  dv: 'https://mydv.scouting.org',
  qa: 'https://myqa.scouting.org',
  pp: 'https://mypp.scouting.org',
  pd: 'https://my.scouting.org',
});

export const MYST_URLS = Object.freeze({
  dv: 'https://mydv.scouting.org',
  qa: 'https://myqa.scouting.org',
  pp: 'https://mypp.scouting.org',
  pd: 'https://my.scouting.org',
});

export function getMYSTUrl() {
  switch (ENV) {
    case 'dv':
      return MYST_URLS.dv;
    case 'qa':
      return MYST_URLS.qa;
    case 'pp':
      return MYST_URLS.pp;
    case 'pd':
      return MYST_URLS.pd;
    case 'local': {
      return MYST_URLS.qa;
    }
    default:
      return MYST_URLS.pd;
  }
}

export const WEBSCRIPT_API_URLS = Object.freeze({
  ...CORE_API_URLS,
  pp: 'https://advancementspp.scouting.org',
});

export const ESB_API_URLS = Object.freeze({
  dv: 'https://apidv.scouting.org',
  qa: 'https://apiqa.scouting.org',
  pp: 'https://apipp.scouting.org',
  pd: 'https://api.scouting.org',
});

export const SB_URLS = Object.freeze({
  dv: 'https://scoutbookdv.scouting.org/',
  qa: 'https://scoutbookqa.scouting.org/',
  pp: 'https://scoutbookpp.scouting.org/',
  pd: 'https://scoutbook.scouting.org',
});

export const PAYMENTS_API_URLS = Object.freeze({
  dv: 'https://devpayments.scouting.org',
  qa: 'https://qapayments.scouting.org',
  pp: 'https://pppayments.scouting.org',
  pd: 'https://payments.scouting.org',
});

export const GENERATE_RECHARTER_PDF_URLS = Object.freeze({
  dv: 'https://advancementsqa.scouting.org/services/recharter-docgen?enableDocGen=1',
  qa: 'https://advancementsqa.scouting.org/services/recharter-docgen?enableDocGen=1',
  pp: 'https://advancementspp.scouting.org/services/recharter-docgen?enableDocGen=1',
  pd: 'https://advancements.scouting.org/services/recharter-docgen?enableDocGen=1',
});

export const ENABLE_FORUM_URL =
  ENV === 'pd'
    ? 'https://fhc28j17j0.execute-api.us-east-1.amazonaws.com/prod/discourse-sync'
    : 'https://gvvevzlbfd.execute-api.us-east-1.amazonaws.com/prod/bsa-discourse-join-qa';

export const SCOUTSHOP_API_URLS = Object.freeze({
  dv: 'https://scoutbookdv.scoutshop.org',
  qa: 'https://scoutbookpp.scoutshop.org',
  pp: 'https://scoutbookpp.scoutshop.org',
  pd: 'https://scoutbook.scoutshop.org',
});

export const getScoutshopBaseURL = () => {
  switch (ENV) {
    case 'pd':
      return SCOUTSHOP_API_URLS.pd;
    case 'local':
      return SCOUTSHOP_API_URLS.pp;
    default:
      return SCOUTSHOP_API_URLS.pp;
  }
};

export const getScoutshopCartURL = cartToken =>
  `${getScoutshopBaseURL()}/bsa/cart/sync/cart/${cartToken}/token`;

const getSbPath = env =>
  USE_NEW_SCOUTBOOK_DOMAIN
    ? `scoutbook${env === 'pd' ? '' : env}.scouting.org`
    : `${env === 'pd' ? 'www' : env}.scoutbook.com`;

export const getSbComSsoUrl = () => {
  switch (API_ENV) {
    case 'pd':
    case 'pp':
    case 'qa':
    case 'dv':
      return `https://${getSbPath(
        API_ENV,
      )}/mobile/includes/ajax.asp?Action=SBLtoScoutbookSSO`;
    default:
      throw new Error(`Unknown API_ENV ${API_ENV}`);
  }
};

export const getMyScoutingUrl = () => {
  switch (API_ENV) {
    case 'pd':
      return CORE_API_URLS.pd;
    case 'pp':
      return CORE_API_URLS.pp;
    case 'qa':
      return CORE_API_URLS.qa;
    case 'dv':
      return CORE_API_URLS.dv;
    default:
      throw new Error(`Unknown API_ENV ${API_ENV}`);
  }
};

export function getBaseESBUrl() {
  switch (API_ENV) {
    case 'pd':
      return ESB_API_URLS.pd;
    case 'pp':
      return ESB_API_URLS.pp;
    case 'qa':
      return ESB_API_URLS.qa;
    case 'dv':
      return ESB_API_URLS.dv;
    default:
      throw new Error(`Unknown API_ENV ${API_ENV}`);
  }
}

export const getLocalhostOrigin = () =>
  `https://scoutbook${API_ENV}.scouting.org`;

export const getPromoteScoutsURL = orgGUID =>
  `https://my${API_ENV}.scouting.org/${orgGUID}/organization-manager/roster`;

export const getRecharterRosterReportURL = (orgGUID, programId) => {
  switch (API_ENV) {
    case 'pd':
      return `https://my.scouting.org/vim2/webscript/output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLMyRosterReport&ScriptCode=ReportPresentation&OrganizationGUID=${orgGUID}&ProgramID=${programId}`;
    case 'pp':
    case 'qa':
    case 'dv':
      return `https://my${API_ENV}.scouting.org/vim2/webscript/output/proxy.aspx?Method=ExecuteScriptSet&ScriptSetCode=SBLMyRosterReport&ScriptCode=ReportPresentation&OrganizationGUID=${orgGUID}&ProgramID=${programId}`;
    default:
      throw new Error(`Unknown API_ENV ${API_ENV}`);
  }
};

export const getYPTCertificateURL = (memberId, personGuid, enrollmentId) =>
  `https://my.scouting.org/vim2/webscript/output/proxy.aspx?Method=ExecuteScriptSet&ScriptCode=TrainingCertificatePDF&ScriptSetCode=TrainingCertificate&MemberID=${memberId}&PersonGUID=${personGuid}&EnrollmentID=${enrollmentId}`;
