/* eslint-disable react/no-multi-comp */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { tableSortOrder } from '@utils';
import { DateView, TableHeadingSort, intl, PersonAvatar, S } from '@shared';
import PendingItemAdvancementName from '../PendingItemAdvancementName';
import PendingItemType from '../PendingItemType';
import styles from './PendingItemsTable.less';
import { getPreferredName } from '@modules/shared/utils/preferredName';

export default ({ sorter, onGoToYouthProfile, onGoToAdultProfile }) => {
  const nameSortOrder = tableSortOrder(sorter, 'name');
  const advancementItemSortOrder = tableSortOrder(sorter, 'advancementItem');
  const completionDateSortOrder = tableSortOrder(sorter, 'completionDate');

  return [
    {
      key: 'avatar',
      width: '3.5rem',
      align: 'right',
      render(_, { profilePictureUrl, isAdult }) {
        return <PersonAvatar isAdult={isAdult} src={profilePictureUrl} />;
      },
    },
    {
      key: 'name',
      width: '20rem',
      sorter: true,
      sortOrder: nameSortOrder,
      title: (
        <TableHeadingSort name="name" sorter={sorter}>
          <FormattedMessage id="advancement.PendingItems.column.name" />
        </TableHeadingSort>
      ),
      render({
        userId,
        isAdult,
        activityTypeId,
        firstName,
        lastName,
        nickName,
      }) {
        const personPreferredName = getPreferredName({
          firstName,
          lastName,
          nickName,
        });
        return (
          <S
            size="4"
            bold
            inline
            colored
            title={personPreferredName}
            className={styles.youthName}
            onClick={
              isAdult
                ? userId && onGoToAdultProfile(userId, activityTypeId)
                : userId && onGoToYouthProfile(userId, activityTypeId)
            }
          >
            {personPreferredName}
          </S>
        );
      },
    },
    {
      dataIndex: 'memberId',
      title: intl.formatMessage({
        id: 'advancement.PendingItems.column.memberId',
      }),
      width: '10rem',
    },
    {
      key: 'advancementType',
      title: intl.formatMessage({
        id: 'advancement.PendingItems.column.itemType',
      }),
      render(_, item) {
        return <PendingItemType item={item} />;
      },
    },
    {
      key: 'advancementItem',
      title: (
        <TableHeadingSort name="advancementItem" sorter={sorter}>
          <FormattedMessage id="advancement.PendingItems.column.itemDescription" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: advancementItemSortOrder,
      render(_, { type, ...item }) {
        return <PendingItemAdvancementName type={type} item={item} showIcon />;
      },
    },
    {
      key: 'completionDate',
      dataIndex: 'date',
      title: (
        <TableHeadingSort name="completionDate" sorter={sorter}>
          <FormattedMessage id="advancement.PendingItems.column.itemDate" />
        </TableHeadingSort>
      ),
      sorter: true,
      sortOrder: completionDateSortOrder,
      render(date) {
        return <DateView value={date} />;
      },
    },
  ];
};
