import { combineEpics, ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { payloadSel, querySel } from '@location';
import { editPreferedName } from '@modules/youthProfile/duck/actionsTyped';
import {
  intl,
  setIsLoadingDeleteEmailModal,
  setShowDeleteAddressModal,
  setShowDeleteEmailModal,
  setShowDeletePhoneModal,
} from '@shared';
import { toastService } from '@toasts';

import { packRosterRequest } from '../../advancement/common/actions';
import { catchAndReport } from '../../utils/rxjs/operators';
import {
  ADULT_DETAILS_REQUEST,
  DELETE_AKELA_EMAIL_REQUEST,
  SAVE_ADULT_PROFILE_PICTURE_REQUEST,
  SAVE_ADULT_PROFILE_REQUEST,
  adultDetailsError,
  adultDetailsRequest,
  adultDetailsResponse,
  deleteAkelaEmailError,
  saveAdultProfileError,
  saveAdultProfilePictureResponse,
  saveAdultProfileResponse,
  setEditAdultDetailSidebar,
} from './actions';
import { adultDetailsSel, adultProfileSel } from './selectors';
import services from './services';

const loadMemberDetailsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(ADULT_DETAILS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const { personGuid, userId } = adultDetailsSel(state);
      const { adultUserId = '' } = querySel(state) || {};
      const { userId: parentId } = payloadSel(state) || {};

      if (adultUserId || (personGuid && userId)) {
        const dataSource = forkJoin(
          !adultUserId ? services.getProfile$(personGuid) : of({}),
          services.getProfile$(adultUserId && !userId ? parentId : userId),
          !adultUserId
            ? services.getUserYPTrainingRequest$(personGuid)
            : of({}),
          !adultUserId
            ? services.getUsersubscriptionsRequest$(personGuid)
            : of({}),
        ).pipe(
          map(([akelaProfile, youthProfile, ypTraining, subscriptions]) => {
            const profile = !adultUserId
              ? {
                  ...akelaProfile,
                  profile: { ...youthProfile.profile, ...akelaProfile.profile },
                  ypTraining,
                  subscriptions,
                }
              : { ...youthProfile, ypTraining: {}, subscriptions: [] };

            return profile;
          }),
        );

        return dataSource.pipe(
          mergeMap(res =>
            Observable.concat(
              of(adultDetailsResponse(res)),
              of(setEditAdultDetailSidebar(false)),
            ),
          ),
          catchAndReport(err => of(adultDetailsError(err))),
        );
      }

      // Registered parents
      if (!personGuid && !userId && !adultUserId) {
        const dataSource = forkJoin(services.getProfile$(parentId)).pipe(
          map(([SBProfile]) => SBProfile.profile),
        );

        return dataSource.pipe(
          switchMap(res =>
            forkJoin(
              services.getProfile$(res.personGuid),
              services.getUserYPTrainingRequest$(res.personGuid),
              services.getUsersubscriptionsRequest$(res.personGuid),
            ).pipe(
              mergeMap(([akelaProfile, ypTraining, subscriptions]) => {
                const profile = {
                  ...akelaProfile,
                  profile: { ...res.profile, ...akelaProfile.profile },
                  ypTraining,
                  subscriptions,
                };

                return Observable.concat(
                  of(adultDetailsResponse(profile)),
                  of(setEditAdultDetailSidebar(false)),
                );
              }),
            ),
          ),
          catchAndReport(err => of(adultDetailsError(err))),
        );
      }
    }),
  );

const saveProfileSibscriptions = (subs = [], personGuid) => {
  if (!subs.length || !personGuid) {
    return [of({})];
  }

  return subs.map(sub =>
    sub.id
      ? services.updateUsersubscriptions$(sub, personGuid)
      : services.createUsersubscriptions$(sub, personGuid),
  );
};

const saveProfile$ = (action$, state$) =>
  action$.ofType(SAVE_ADULT_PROFILE_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const { adultUserId = '' } = querySel(state) || {};
    const { userId: parentId } = payloadSel(state) || {};
    const { userId } = adultDetailsSel(state);
    const { profile = {} } = adultProfileSel(state);
    const { akelaProfile, sbProfile } = payload;
    return forkJoin([
      services.updateSBProfile$(
        sbProfile,
        adultUserId || !userId ? parentId : userId,
      ),
      adultUserId
        ? of({})
        : services.saveProfile$(akelaProfile, profile.personGuid),
      ...saveProfileSibscriptions(akelaProfile.subs, profile.personGuid),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: 'profile.ProfileInfo.save.success',
          }),
        ),
      ),
      mergeMap(() => {
        const actions = [
          of(saveAdultProfileResponse()),
          of(adultDetailsRequest()),
        ];
        if (profile.nickName !== akelaProfile.nickName) {
          actions.push(of(editPreferedName()));
        }
        return Observable.concat(...actions);
      }),
      catchAndReport(err => Observable.of(saveAdultProfileError(err))),
    );
  });

const saveAdultProfilePicture$ = (action$, state$) =>
  action$
    .ofType(SAVE_ADULT_PROFILE_PICTURE_REQUEST)
    .switchMap(({ payload }) => {
      const state = state$.value;
      const { personGuid } = adultDetailsSel(state);

      return services.saveProfilePicture$({ ...payload, personGuid }).pipe(
        tap(() =>
          toastService.success(
            intl.formatMessage({
              id: 'profile.ProfileInfo.save.success',
            }),
          ),
        ),
        mergeMap(() =>
          Observable.concat(
            of(saveAdultProfilePictureResponse()),
            of(saveAdultProfileResponse()),
            of(adultDetailsRequest()),
            of(setEditAdultDetailSidebar(false)),
            of(packRosterRequest()),
          ),
        ),
        catchAndReport(err => Observable.of(saveAdultProfileError(err))),
      );
    });

const deleteAkelaEmail$ = (action$, state$) =>
  action$.ofType(DELETE_AKELA_EMAIL_REQUEST).switchMap(({ payload }) => {
    const state = state$.value;
    const { personGuid, userId } = adultDetailsSel(state);
    const { adultUserId = '' } = querySel(state) || {};
    const { sbPhones = {} } = payload;
    const deleteSBPhone =
      sbPhones.homePhone === '' ||
      sbPhones.mobilePhone === '' ||
      sbPhones.workPhone === '';

    return forkJoin([
      !adultUserId ? services.deleteProfileItem$(payload, personGuid) : of({}),
      deleteSBPhone ? services.updateSBProfile$(sbPhones, userId) : of({}),
    ]).pipe(
      tap(() =>
        toastService.success(
          intl.formatMessage({
            id: `shared.contactInfo.delete${payload.type}.success`,
          }),
        ),
      ),
      mergeMap(() =>
        Observable.concat(
          of(adultDetailsRequest()),
          of(setShowDeleteEmailModal(false)),
          of(setShowDeleteAddressModal(false)),
          of(setShowDeletePhoneModal(false)),
          of(setIsLoadingDeleteEmailModal(false)),
        ),
      ),
      catchAndReport(err =>
        Observable.concat(
          of(deleteAkelaEmailError(err)),
          of(setShowDeleteEmailModal(false)),
          of(setShowDeleteAddressModal(false)),
          of(setShowDeletePhoneModal(false)),
          of(setIsLoadingDeleteEmailModal(false)),
        ),
      ),
    );
  });

export default combineEpics(
  loadMemberDetailsEpic$,
  saveProfile$,
  deleteAkelaEmail$,
  saveAdultProfilePicture$,
);
