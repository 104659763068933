import get from 'lodash/get';
import { combineReducers } from 'redux';

import { SET_ORGANIZATION } from '@context';
import { resetOnLogoutReducer } from '@utils';

import {
  UNIT_INFO_ERROR,
  UNIT_INFO_REQUEST,
  UNIT_INFO_RESPONSE,
  UNIT_PROFILE_ERROR,
  UNIT_PROFILE_REQUEST,
  UNIT_PROFILE_RESPONSE,
} from './actions';

const unitInfo = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIT_INFO_REQUEST:
    case UNIT_INFO_ERROR:
    case SET_ORGANIZATION: {
      return {};
    }
    case UNIT_INFO_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const unitInfoLoading = (state = false, { type }) => {
  switch (type) {
    case UNIT_INFO_REQUEST: {
      return true;
    }
    case UNIT_INFO_RESPONSE:
    case UNIT_INFO_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitInfoError = (state = null, { type }) => {
  switch (type) {
    case UNIT_INFO_REQUEST:
    case UNIT_INFO_RESPONSE: {
      return null;
    }
    case UNIT_INFO_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const unitProfiles = (state = {}, { type, payload }) => {
  switch (type) {
    case UNIT_PROFILE_REQUEST:
    case UNIT_PROFILE_ERROR:
      return state;
    case UNIT_PROFILE_RESPONSE: {
      const orgGuid = get(payload, ['organizationGuid']);
      return { ...state, [orgGuid]: payload };
    }

    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  unitInfo,
  unitProfiles,
  unitInfoLoading,
  unitInfoError,
});

export default resetOnLogoutReducer(reducer);
