import { stepsStatus } from '@shared/constants';

export default (index, currentIndex) => {
  if (index === currentIndex) {
    return stepsStatus.WAIT;
  }
  if (index < currentIndex) {
    return stepsStatus.FINISH;
  }
  return stepsStatus.PROCESS;
};
