import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';

import { SET_ORGANIZATION } from '@context';
import { isCouncilOrgActiveSel } from '@shared/duck';

import { epics as basicReportsEpics } from '../basicReports';
import { reportsTabs } from '../constants';
import { epics as historyReportsEpics } from '../historyReports';
import { setActiveTab } from './actions';
import { activeTabSel } from './selectors';

const epics = [historyReportsEpics, basicReportsEpics];

const setDefaultTab$ = (action$, state$) =>
  action$.pipe(
    ofType(SET_ORGANIZATION),
    map(() => {
      const state = state$.value;
      const isCouncil = isCouncilOrgActiveSel(state);
      const prevActiveTab = activeTabSel(state);

      const activeTab = isCouncil ? reportsTabs.COUNCIL : reportsTabs.HISTORY;
      return activeTab !== prevActiveTab
        ? setActiveTab(activeTab)
        : { type: '@@noop' };
    }),
  );

export default combineEpics(...epics, setDefaultTab$);
