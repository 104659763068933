import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, Checkbox } from '@shared';
import { visibilityFields } from '../../../../constants';

class VisibilitySection extends React.PureComponent {
  handleChange = e => {
    const { name } = e.target;
    const { values, onChange } = this.props;
    const newValues = {
      ...values,
      [name]: !values[name],
    };

    onChange(newValues);
  };

  render() {
    const { values } = this.props;

    return (
      <div>
        <Checkbox
          name={visibilityFields.MEMBER_ID}
          checked={values[visibilityFields.MEMBER_ID]}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.column.memberId" />
          </S>
        </Checkbox>
        <Checkbox
          name={visibilityFields.DATE}
          checked={values[visibilityFields.DATE]}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.column.updatedDate" />
          </S>
        </Checkbox>
        <Checkbox
          name={visibilityFields.RECORDED_BY}
          checked={values[visibilityFields.RECORDED_BY]}
          onChange={this.handleChange}
        >
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.column.recordedBy" />
          </S>
        </Checkbox>
      </div>
    );
  }
}

VisibilitySection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VisibilitySection;
