import moment from 'moment';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';

import { SBL_5060_ENABLE_HTML_EVENT_DESCRIPTION } from '@config';
import { dateAndTimeToDtoDateTime, esbApiService, parseBBCode } from '@utils';

import { mapRequirementsDtoToModel } from '../utils';

/**
 * @esbEndpoint GET /advancements/events/:eventId
 */
const getEventById$ = eventId =>
  esbApiService
    .get$(`/advancements/events/${eventId}`, {
      gtm: {
        label: '/advancements/events/{eventId}',
      },
      swCache: true,
    })
    .map(event => ({
      ...event,
      description: SBL_5060_ENABLE_HTML_EVENT_DESCRIPTION
        ? event.description
        : parseBBCode(event.description || ''),
      canRsvp: !!event.rsvp,
    }));

const eventInviteesPath = eventId =>
  `/advancements/v2/events/${eventId}/invitees`;

const eventRemindersPath = (eventId, v2 = false) =>
  v2
    ? `/advancements/v2/events/${eventId}/reminders`
    : `/advancements/events/${eventId}/reminders`;
const eventRequirementsPath = eventId =>
  `/advancements/v2/events/${eventId}/requirements`;

/**
 * @esbEndpoint GET /advancements/v2/events/:eventId/invitees
 */
const getEventInvitees$ = eventId =>
  esbApiService.get$(eventInviteesPath(eventId), {
    gtm: {
      label: '/advancements/v2/events/{eventId}/invitees',
    },
    swCache: true,
  });

/**
 * @esbEndpoint POST /advancements/v2/events/:eventId/invitees
 */
const addEventInvitee$ = (eventId, addObj) =>
  esbApiService.post$(eventInviteesPath(eventId), addObj, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/invitees',
    },
    swCache: true,
  });

/**
 * @esbEndpoint PUT /advancements/v2/events/:eventId/invitees
 */
const editEventInvitee$ = (eventId, updateObj) =>
  esbApiService.put$(eventInviteesPath(eventId), updateObj, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/invitees',
    },
    swCache: true,
  });

/**
 * @esbEndpoint DELETE /advancements/v2/events/:eventId/invitees/:userId
 */
const deleteEventInvitee$ = (eventId, userId) =>
  esbApiService.delete$(`${eventInviteesPath(eventId)}/${userId}`, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/invitees/{userId}',
    },
  });

/**
 * @esbEndpoint PUT /advancements/v2/events/:eventId
 */
const editEvent$ = (eventId, event) =>
  esbApiService.put$(`/advancements/events/${eventId}`, event, {
    gtm: {
      label: '/advancements/events/{eventId}',
    },
    swCache: true,
  });

/**
 * @esbEndpoint POST /advancements/v2/events/add
 */
const createEvent$ = event =>
  esbApiService.post$('/advancements/events/add', event, {
    gtm: {
      label: '/advancements/events/add',
    },
    swCache: true,
  });

const saveEvent$ = (event, timezoneCode) => {
  const { eventId } = event;
  const requestBody = {
    ...event,
    startDate: dateAndTimeToDtoDateTime(
      event.startDate,
      event.startTime,
      timezoneCode,
    ),
    endDate: dateAndTimeToDtoDateTime(
      event.endDate,
      event.endTime,
      timezoneCode,
    ),
  };
  delete requestBody.startTime;
  delete requestBody.endTime;

  if (eventId) {
    return editEvent$(eventId, requestBody);
  }

  return createEvent$(requestBody);
};

/**
 * @esbEndpoint DELETE /advancements/v2/events/:eventId
 */
const deleteEvent$ = eventId =>
  esbApiService.delete$(`/advancements/v2/events/${eventId}`, {
    gtm: {
      label: '/advancements/v2/events/{eventId}',
    },
  });

/**
 * @esbEndpoint POST /advancements/events/:eventId/reminders/add
 */
const addEventReminder$ = (eventId, addObj) =>
  esbApiService.post$(`${eventRemindersPath(eventId)}/add`, addObj, {
    gtm: {
      label: '/advancements/events/{eventId}/reminders/add',
    },
    swCache: true,
  });

/**
 * @esbEndpoint POST /advancements/v2/events/:eventId/reminders/send
 */
const sendEventReminder$ = (eventId, payload) =>
  esbApiService.post$(
    `/advancements/v2/events/${eventId}/reminders/send`,
    payload,
    {
      gtm: {
        label: '/advancements/v2/events/{eventId}/reminders/send',
      },
      swCache: true,
    },
  );

/**
 * @esbEndpoint PUT /advancements/events/{eventId}/reminders/{reminderId}
 */
const editEventReminder$ = (eventId, data) =>
  esbApiService.put$(
    `${eventRemindersPath(eventId)}/${data.reminderId}`,
    data,
    {
      gtm: {
        label: '/advancements/events/{eventId}/reminders/{reminderId}',
      },
    },
  );

const deleteEventReminder$ = (eventId, reminderId) =>
  esbApiService.delete$(`${eventRemindersPath(eventId, true)}/${reminderId}`, {
    gtm: {
      label: '/advancements/events/{eventId}/reminders/{reminderId}',
    },
  });

const getAdvancementRequirements$ = ({ advancementType, advancementId }) =>
  esbApiService
    .get$(`/advancements/${advancementType}/${advancementId}/requirements`, {
      gtm: {
        label: `advancements/${advancementType}/{advancementId}/requirements`,
      },
    })
    .pipe(
      map(requirements =>
        mapRequirementsDtoToModel(requirements, advancementType, advancementId),
      ),
    );

/**
 * @esbEndpoint POST /advancements/v2/events/:eventId/requirements
 */
const addEventRequirements$ = (eventId, requirements) =>
  esbApiService.post$(eventRequirementsPath(eventId), requirements, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/requirements',
    },
    swCache: true,
  });

/**
 * @esbEndpoint GET /advancements/v2/events/:eventId/requirements
 */
const getEventRequirements$ = eventId =>
  esbApiService.get$(eventRequirementsPath(eventId), {
    gtm: {
      label: '/advancements/v2/events/{eventId}/requirements',
    },
  });

/**
 * @esbEndpoint GET /lookups/advancements/calendarEventTypes
 */
const getEventTypes$ = () =>
  esbApiService.get$('/lookups/advancements/calendarEventTypes', {
    gtm: {
      label: '/lookups/advancements/calendarEventTypes',
    },
  });

/**
 * @esbEndpoint POST /advancements/v2/events/recurringEvents/add
 */
const createRecurrentEvent$ = event =>
  esbApiService.post$('/advancements/v2/events/recurringEvents/add', event, {
    gtm: {
      label: '/advancements/v2/events/recurringEvents/add',
    },
    swCache: true,
  });

/**
 * @esbEndpoint PUT /advancements/v2/events/recurringEvents/:recurringEventId
 */
const updateRecurrentEvent$ = (recurringEventId, payload) =>
  esbApiService.put$(
    `/advancements/v2/events/recurringEvents/${recurringEventId}`,
    payload,
    {
      gtm: {
        label: '/advancements/v2/events/recurringEvents/{recurringEventId}',
      },
    },
  );

/**
 * @esbEndpoint DELETE /advancements/v2/events/recurringEvents/:recurringEventId
 */
const deleteRecurrentEvent$ = ({ cancellationDate, recurringEventId }) =>
  esbApiService.delete$(
    `/advancements/v2/events/recurringEvents/${recurringEventId}`,
    {
      cancellationDate,
    },
    {
      gtm: {
        label: '/advancements/v2/events/recurringEvents/{recurringEventId}',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/events/:eventId/organizations/add
 */
const addEventUnits$ = (eventId, unitData) =>
  esbApiService.post$(
    `/advancements/v2/events/${eventId}/organizations/add`,
    unitData,
    {
      gtm: {
        label: '/advancements/v2/events/{eventId}/organizations/add',
      },
    },
  );

/**
 * @esbEndpoint DELETE /advancements/v2/events/:eventId/units
 */
const deleteEventUnit$ = (eventId, unitId) =>
  esbApiService.delete$(
    `/advancements/v2/events/${eventId}/units?orgId=${unitId}`,
    {
      gtm: {
        label: '/advancements/v2/events/{eventId}/units',
      },
    },
  );

// /**
//  * @esbEndpoint GET /advancements/v2/events/:eventId/guests
//  */
const getGuests$ = eventId =>
  esbApiService.get$(`/advancements/v2/events/${eventId}/guests`, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/guests',
    },
    swCache: true,
  });

/**
 * @esbEndpoint POST /advancements/v2/events/:eventId/guests
 */
const addGuests$ = (eventId, payload) =>
  esbApiService.post$(`/advancements/v2/events/${eventId}/guests`, payload, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/guests',
    },
  });

/**
 * @esbEndpoint PUT /advancements/v2/events/:eventId/guests/:calendarEventGuestId
 */
const updateGuest$ = (eventId, calendarEventGuestId, payload) =>
  esbApiService.put$(
    `/advancements/v2/events/${eventId}/guests/${calendarEventGuestId}`,
    payload,
    {
      gtm: {
        label:
          '/advancements/v2/events/{eventId}/guests/{calendarEventGuestId}',
      },
    },
  );

/**
 * @esbEndpoint DELETE /advancements/v2/events/:eventId/guests/:calendarEventGuestId
 */
const deleteGuest$ = (eventId, calendarEventGuestId) =>
  esbApiService.delete$(
    `/advancements/v2/events/${eventId}/guests/${calendarEventGuestId}`,
    {
      gtm: {
        label:
          '/advancements/v2/events/{eventId}/guests/{calendarEventGuestId}',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/activities/add
 */
const createActivity$ = data =>
  esbApiService.post$('/advancements/v2/activities/add', data, {
    gtm: {
      label: '/advancements/v2/activities/add',
    },
  });

/**
 * @esbEndpoint PUT /advancements/v2/activities/:activityId
 */
const updateActivity$ = (activityId, data) =>
  esbApiService.put$(`/advancements/v2/activities/${activityId}`, data, {
    gtm: {
      label: '/advancements/v2/activities/{id}',
    },
  });

/**
 * @esbEndpoint GET /advancements/activities/:activityId
 */
const getEventActivityById$ = activityId =>
  esbApiService
    .get$(`/advancements/activities/${activityId}`, {
      gtm: {
        label: '/advancements/activities/{activityId}',
      },
      swCache: true,
      suppressErrorToasts: [404],
    })
    .map(activity => {
      const formattedStartTime = moment(activity.startDateTime).format('HH:mm');
      const formattedEndTime = moment(activity.endDateTime).format('HH:mm');
      const allDay =
        formattedEndTime === '23:59' && formattedStartTime === '00:00';
      return {
        ...activity,
        registeredAdults: activity.registeredAdults.map(adult => ({
          ...adult,
          isAdult: !adult.isYouth,
        })),
        registeredYouths: activity.registeredYouths.map(youth => ({
          ...youth,
          isAdult: !youth.isYouth,
        })),
        allDay,
      };
    })
    .pipe(
      catchError(err => {
        if (err.status !== 404) {
          throw err;
        }
        return of({});
      }),
    );

/**
 * @esbEndpoint POST /advancements/v2/activities/:activityId/registeredParticipants/add
 */
const addActivityRecords$ = (activityId, data) =>
  esbApiService.post$(
    `/advancements/v2/activities/${activityId}/registeredParticipants/add`,
    data,
    {
      gtm: {
        label:
          '/advancements/v2/activities/{activityId}/registeredParticipants/add',
      },
    },
  );

/**
 * @esbEndpoint PUT /advancements/activities/:activityId/registeredParticipants
 */
const editActivityRecords$ = (activityId, data) =>
  esbApiService.put$(
    `/advancements/activities/${activityId}/registeredParticipants`,
    data,
    {
      gtm: {
        label: '/advancements/activities/{activityId}/registeredParticipants',
      },
    },
  );

/**
 * @esbEndpoint PUT /advancements/activities/:activityId/nonRegisteredOrganizationParticipants/:nonRegisteredId
 */
const editNonRegisteredOrgParticipants$ = (
  activityId,
  nonRegisteredId,
  payload,
) =>
  esbApiService.put$(
    `/advancements/activities/${activityId}/nonRegisteredOrganizationParticipants/${nonRegisteredId}`,
    payload,
    {
      gtm: {
        label:
          '/advancements/activities/{activityId}/nonRegisteredOrganizationParticipants/{activityDetailsId}',
      },
    },
  );

/**
 * @esbEndpoint DELETE /advancements/activities/:activityId/registeredParticipants/:activityRecordId
 */
const deleteActivityRecord$ = (activityId, activityRecordId) =>
  esbApiService.delete$(
    `/advancements/activities/${activityId}/registeredParticipants/${activityRecordId}`,
    {
      gtm: {
        label:
          '/advancements/activities/{activityId}/registeredParticipants/{activityRecordId}',
      },
    },
  );

/**
 * @esbEndpoint DELETE /advancements/v2/activities/:activityId
 */
const deleteActivity$ = activityId =>
  esbApiService.delete$(`/advancements/v2/activities/${activityId}`, {
    gtm: {
      label: '/advancements/v2/activities/{activityId}',
    },
  });

/**
 * @esbEndpoint GET /advancements/v2/users/:userId/calendars
 */
const getCalendarRecords$ = userId =>
  esbApiService.get$(`/advancements/v2/users/${userId}/calendars`, {
    gtm: {
      label: '/advancements/v2/users/{userId}/calendars',
    },
  });

/**
 * @esbEndpoint POST /advancements/v2/users/:userId/calendars
 */
const createCalendarRecord$ = (userId, payload) =>
  esbApiService.post$(`/advancements/v2/users/${userId}/calendars`, payload, {
    gtm: {
      label: '/advancements/v2/users/{userId}/calendars',
    },
  });

/**
 * @esbEndpoint PUT /advancements/v2/users/:userId/calendars/:userCalendarId
 */
const updateCalendarRecord$ = (userId, payload) =>
  esbApiService.put$(
    `/advancements/v2/users/${userId}/calendars/${payload.userCalendarId}`,
    payload,
    {
      gtm: {
        label: '/advancements/v2/users/{userId}/calendars/{userCalendarId}',
      },
    },
  );

/**
 * @esbEndpoint PUT /advancements/v2/events/:eventId/rsvp
 */
const postPublicEventRSVP$ = (eventId, payload) =>
  esbApiService.put$(`/advancements/v2/events/${eventId}/rsvp`, payload, {
    gtm: {
      label: '/advancements/v2/events/{eventId}/rsvp',
    },
  });

/**
 * @esbEndpoint POST /advancements/v2/events/:eventId/rsvp/send
 */
const sendPublicEventRSVP$ = (eventId, payload) =>
  esbApiService.post$(`/advancements/v2/events/${eventId}/rsvp/send`, payload, {
    gtm: {
      label: '/advancements/v2/events/:eventId/rsvp/send',
    },
  });

/**
 * @esbEndpoint POST /advancements/v2/events/comments
 */
const getEventComments$ = body =>
  esbApiService.post$(`/advancements/v2/events/comments`, body, {
    gtm: { label: '/advancements/v2/events/comments' },
  });

/**
 * @esbEndpoint POST /advancements/v2/events/comments/add
 */
const postEventComment$ = body =>
  esbApiService.post$(`/advancements/v2/events/comments/add`, body, {
    gtm: { label: '/advancements/v2/events/comments/add' },
  });

/**
 * @esbEndpoint POST /advancements/v2/users/:userId/events/addFile
 */

const postEventCommentWithFiles$ = (userId, body) =>
  esbApiService.post$(`/advancements/v2/users/${userId}/events/addFile`, body, {
    gtm: { label: '/advancements/v2/users/${userId}/events/addFile' },
  });

/**
 * @esbEndpoint PUT /advancements/users/:userId/comments/:commentId
 */
const putEventComment$ = (userId, commentId, body) =>
  esbApiService.put$(
    `/advancements/users/${userId}/comments/${commentId}`,
    body,
    { gtm: { label: '/advancements/users/:userId/comments/:commentId' } },
  );

/**
 * @esbEndpoint DELETE /advancements/v2/events/comments/:commentId
 */
const deleteEventComment$ = commentId =>
  esbApiService.delete$(`/advancements/v2/events/comments/${commentId}`, {
    gtm: { label: '/advancements/v2/events/comments/:commentId' },
  });

/**
 * @esbEndpoint DELETE /advancements/v2/users/:userId/comments/:commentId/photos/:photoId
 */
const deleteEventCommentPhoto$ = (userId, commentId, photoId) =>
  esbApiService.delete$(
    `/advancements/v2/users/${userId}/comments/${commentId}/photos/${photoId}`,
    {
      gtm: {
        label:
          '/advancements/v2/users/${userId}/comments/${commentId}/photos/${photoId}',
      },
    },
  );

/**
 * Image uplad to S3
 * URL is passed in preSignedUrl from postEventCommentWithFiles$
 */

const uploadCommentImageToS3$ = (photo, preSignedUrl) =>
  Observable.fromPromise(
    fetch(preSignedUrl, {
      body: photo,
      headers: { 'Content-Type': photo.type },
      method: 'PUT',
    }),
  );

export default {
  getEventById$,
  getEventInvitees$,
  editEventInvitee$,
  addEventInvitee$,
  deleteEventInvitee$,
  saveEvent$,
  createEvent$,
  editEvent$,
  deleteEvent$,
  addEventReminder$,
  sendEventReminder$,
  editEventReminder$,
  deleteEventReminder$,
  getAdvancementRequirements$,
  addEventRequirements$,
  getEventRequirements$,
  getEventTypes$,
  createRecurrentEvent$,
  updateRecurrentEvent$,
  deleteRecurrentEvent$,
  addEventUnits$,
  deleteEventUnit$,
  getGuests$,
  addGuests$,
  updateGuest$,
  deleteGuest$,
  createActivity$,
  updateActivity$,
  getEventActivityById$,
  addActivityRecords$,
  editActivityRecords$,
  editNonRegisteredOrgParticipants$,
  deleteActivityRecord$,
  deleteActivity$,
  getCalendarRecords$,
  createCalendarRecord$,
  updateCalendarRecord$,
  postPublicEventRSVP$,
  sendPublicEventRSVP$,
  deleteEventComment$,
  deleteEventCommentPhoto$,
  getEventComments$,
  postEventComment$,
  postEventCommentWithFiles$,
  putEventComment$,
  uploadCommentImageToS3$,
};
