import React, { useCallback } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { FormComponentProps } from 'antd/lib/form';
import { Col, Form, Row } from 'bsa-ui';
import cn from 'classnames';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  UnitPendingLeadershipPosition,
  UpdateLeadershipPositionPayload,
} from '@appTypes/esb';
import {
  removeMultipleSelectedItems,
  removeSelectedItem,
} from '@modules/advancement/pendingItems/duck';
import { useBulkUpdateYouthLeadershipPositionsMutation } from '@modules/leadershipPositions/api';
import {
  Button,
  ConditionalRender,
  DatePicker,
  Modal,
  PersonAvatar,
  S,
  T,
  intl,
  organizationGuidSel,
  viewDateFormat,
} from '@modules/shared';
import { getPreferredName } from '@modules/shared/utils/preferredName';
import { toastService } from '@modules/toasts';
import { formatToAPIDate } from '@modules/utils/dates';

import styles from './ApproveModal.styles.less';

const FormItem = Form.Item;

export interface SelectedPositionItem {
  position: UnitPendingLeadershipPosition;
}

interface ApproveModalProps {
  selectedPositions: SelectedPositionItem[];
  visible: boolean;
  onCancel: () => void;
}

enum ApproveFormKeys {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

const LeadershipApproveModalWithForm: React.FC<
  FormComponentProps & ApproveModalProps
> = props => {
  const { form, selectedPositions, visible, onCancel } = props;
  const dispatch = useDispatch();

  const groupedByPerson = _.groupBy(
    selectedPositions,
    item => item.position.bsaMemberId,
  );

  const [updatePositions, { isLoading }] =
    useBulkUpdateYouthLeadershipPositionsMutation();
  const orgGuid = useSelector(organizationGuidSel);

  const showRemovePosition = selectedPositions.length > 1;
  const showRemovePerson = Object.keys(groupedByPerson).length > 1;

  const { getFieldDecorator } = form;

  const personCount = Object.keys(groupedByPerson).length;

  const onSubmit = useCallback(async () => {
    try {
      form.validateFields();
    } catch {
      // Stop if form is invalid
      return;
    }

    const data = form.getFieldsValue();

    const updatePayloads = selectedPositions.map(positionItem => {
      const position = positionItem.position;
      const userId = position.userId;
      const dateStarted = data[
        `${userId}_${ApproveFormKeys.StartDate}`
      ] as Moment;
      const dateEnded = data[`${userId}_${ApproveFormKeys.EndDate}`] as Moment;

      const payload: UpdateLeadershipPositionPayload = {
        userId: userId,
        id: position.userPositionId,
        notes: position.notes || ' ',
        dateStarted: formatToAPIDate(dateStarted),
        // date ended can be deleted
        dateEnded: dateEnded ? formatToAPIDate(dateEnded) : null,
        approved: true,
      };

      return payload;
    });

    try {
      toastService.info(
        <FormattedMessage id="pendingLeadershipPositionsTable.approveModal.updating.progress" />,
      );
      await updatePositions({
        positions: updatePayloads,
        orgGuids: [orgGuid],
      }).unwrap();
      toastService.dismiss(undefined);
      toastService.success(
        <FormattedMessage id="pendingLeadershipPositionsTable.approveModal.updating.ok" />,
      );
      onCancel();
    } catch (error) {
      toastService.dismiss(undefined);
      toastService.error(
        <FormattedMessage id="pendingLeadershipPositionsTable.approveModal.updating.error" />,
      );
    }
  }, [form, orgGuid, onCancel, selectedPositions, updatePositions]);

  return (
    <Modal
      className={cn(styles.approveModal)}
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button
            onClick={onCancel}
            type="primary"
            loading={isLoading}
            shadow
            uppercase
            color="scouting-warm-gray"
          >
            <FormattedMessage id="shared.cancel" />
          </Button>
          <Button
            type="primary"
            shadow
            uppercase
            loading={isLoading}
            color="success"
            onClick={onSubmit}
          >
            <FormattedMessage id="shared.approve" />
          </Button>
        </>
      }
    >
      <Row gutter={16}>
        <Col xs={10}>
          <T size="5" colored bold>
            <FormattedMessage id="pendingLeadershipPositionsTable.approveModal.personListTitle" />
          </T>
          <S size="6">
            <FormattedMessage
              id="approveAdvancements.PersonsList.subtitle"
              values={{ count: personCount, status: 'approved' }}
            />
          </S>
          {_.map(groupedByPerson, positions => {
            const position = positions[0].position;

            return (
              <div
                className={cn(styles.personItem)}
                key={position.userPositionId}
              >
                <PersonAvatar isAdult={position.isAdult} />
                <div>
                  <S size="4">{getPreferredName(position)}</S>
                  <S size="5">
                    <FormattedMessage
                      id="pendingLeadershipPositionsTable.approveModal.positionsCount"
                      values={{ count: positions.length }}
                    />
                  </S>
                </div>
                <div className={styles.removePersonButton}>
                  <ConditionalRender value={showRemovePerson}>
                    <Button
                      shape="circle"
                      data-testid="remove-person"
                      type="link"
                      size="small"
                      onlyIcon
                      onClick={() => {
                        const toRemove = positions.map(
                          position => position.position.userPositionId,
                        );
                        dispatch(removeMultipleSelectedItems(toRemove));
                      }}
                      icon={<CloseIcon />}
                    />
                  </ConditionalRender>
                </div>
              </div>
            );
          })}
        </Col>
        <Col xs={14}>
          <T colored bold size="4">
            <FormattedMessage id="pendingLeadershipPositionsTable.approveModal.listTitle" />
          </T>

          {selectedPositions.map(positionItem => {
            const position = positionItem.position;
            const userId = position.userId;
            const startDate = position.startDate;
            const endDate = position.endDate;

            const getKey = (item: ApproveFormKeys) => `${userId}_${item}`;

            return (
              <div key={position.userPositionId}>
                <div className={styles.row}>
                  <img
                    width={32}
                    height={32}
                    src={position.positionImage}
                    alt="Position"
                  />
                  <S>{position.position}</S>
                </div>

                <Row gutter={16}>
                  <Col md={10}>
                    <FormItem
                      label={
                        (
                          <FormattedMessage id="shared.date.startDate" />
                        ) as unknown as string
                      }
                    >
                      {getFieldDecorator(getKey(ApproveFormKeys.StartDate), {
                        initialValue: moment(startDate),
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'shared.invalidDate',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          fluid
                          placeholder={viewDateFormat}
                          disableDates={{ maxDate: new Date(), minDate: null }}
                          label={intl.formatMessage({
                            id: 'shared.date.startDate',
                          })}
                        />,
                      )}
                    </FormItem>
                  </Col>

                  <Col md={10}>
                    <FormItem
                      label={
                        (
                          <FormattedMessage id="shared.date.endDate" />
                        ) as unknown as string
                      }
                    >
                      {getFieldDecorator(getKey(ApproveFormKeys.EndDate), {
                        initialValue: endDate ? moment(endDate) : undefined,
                        rules: [
                          {
                            required: false,
                            message: intl.formatMessage({
                              id: 'shared.invalidDate',
                            }),
                          },
                        ],
                      })(
                        <DatePicker
                          fluid
                          placeholder={viewDateFormat}
                          disableDates={{ maxDate: new Date(), minDate: null }}
                          label={intl.formatMessage({
                            id: 'shared.date.endDate',
                          })}
                        />,
                      )}
                    </FormItem>
                  </Col>
                  <Col md={2}>
                    <ConditionalRender value={showRemovePosition}>
                      <Button
                        shape="circle"
                        data-testid="remove-entry"
                        onClick={() => {
                          dispatch(removeSelectedItem(position.userPositionId));
                        }}
                        type="link"
                        size="small"
                        onlyIcon
                        icon={<CloseIcon />}
                      />
                    </ConditionalRender>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Col>
      </Row>
    </Modal>
  );
};

const LeadershipApproveModal = connect(
  (_, ownProps: ApproveModalProps) => ownProps,
  undefined,
)(Form.create()(LeadershipApproveModalWithForm));

export const LeadershipApproveModalContainer: React.FC<
  ApproveModalProps
> = props => {
  if (!props.visible) return null;

  return <LeadershipApproveModal {...props} />;
};
