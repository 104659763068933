import React from 'react';
import PropTypes from 'prop-types';
import { CollapsableItem, CustomValidations } from './CustomItems';

const ValidationItem = props => {
  const { validationId, validation } = props;
  const validationOK = !validation;

  const CustomValidation = CustomValidations[validationId];

  if (validationOK) return null;

  if (CustomValidation) return <CustomValidation {...props} />;

  return <CollapsableItem key={`panel-${validationId}`} {...props} />;
};

export default ValidationItem;

ValidationItem.propTypes = {
  validation: PropTypes.bool,
  validationId: PropTypes.number,
  validationType: PropTypes.string,
  missingPositions: PropTypes.array,
};
