import React, { useCallback, useState } from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { InputNumber } from 'antd';
import Upload from 'bsa-ui/src/upload';
import cn from 'classnames';
import find from 'lodash/find';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  S,
  Select,
} from '@shared/components';
import {
  memberTypeIds,
  memberTypeLabels,
  shortViewDateFormat,
} from '@shared/constants';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { validateForm } from '@utils';

import { packRosterAllItemsByMIDSel } from '../../../../../shared/duck/selectors/general.selectors';
import { positionIdsNotAllowedToBeAdded } from '../../../../constants';
import {
  addMemberError,
  addMemberRequest,
  filteredAddMemberTypesSel,
  isAddMemberLoadingSel,
  recharterRosterAdultsSel,
  unitAdultsRequirementsSel,
  unitParticipantsRequirementsSel,
  unitYouthsRequirementsSel,
} from '../../../../duck';
import { useRegisteredUnitPositions } from '../../../../hooks';
import { memberHasPaperApplication } from '../../../../utils';
import styles from './AddMembersForm.less';
import { isExistingMember, shouldDisplayMemberId } from './utils';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

function getMemberTypeNoteMessage(memberType, isNewMemberApplication) {
  switch (memberType) {
    case memberTypeIds.youth: {
      return (
        <S>
          <FormattedMessage
            id={
              isNewMemberApplication
                ? 'recharterModals.addMembersModal.memberTypeHelp.new.youth'
                : 'recharterModals.addMembersModal.memberTypeHelp.existing.youth'
            }
          />
        </S>
      );
    }
    case memberTypeIds.participant: {
      return (
        <S>
          <FormattedMessage
            id={
              isNewMemberApplication
                ? 'recharterModals.addMembersModal.memberTypeHelp.new.participant'
                : 'recharterModals.addMembersModal.memberTypeHelp.existing.participant'
            }
          />
        </S>
      );
    }
    case memberTypeIds.adult: {
      return (
        <S>
          <FormattedMessage
            id={
              isNewMemberApplication
                ? 'recharterModals.addMembersModal.memberTypeHelp.new.adult'
                : 'recharterModals.addMembersModal.memberTypeHelp.existing.adult'
            }
          />
        </S>
      );
    }
    default:
      return null;
  }
}

const AddMembersForm = ({ form, isNewMemberApplication, effectiveDt }) => {
  const {
    getFieldDecorator,
    getFieldsValue,
    setFieldsValue,
    setFields,
    validateFields,
  } = form;
  const { memberType, memberPosition, dateOfBirth, memberId } =
    getFieldsValue();
  const dispatch = useDispatch();
  const isLoading = useSelector(isAddMemberLoadingSel);
  const adultRosterRecharter = useSelector(recharterRosterAdultsSel);
  const { minAge, maxAge } = useSelector(unitYouthsRequirementsSel);
  const unitRosterByMID = useSelector(packRosterAllItemsByMIDSel);
  const { minAge: participantMinAge, maxAge: participantMaxAge } = useSelector(
    unitParticipantsRequirementsSel,
  );
  const { minAge: adultMinAge } = useSelector(unitAdultsRequirementsSel);
  const filteredAddMemberTypes = useSelector(filteredAddMemberTypesSel);
  const [fileList, setFileList] = useState([]);
  const [filteredUnitPositions, unitPositions] =
    useRegisteredUnitPositions(memberType);
  const fileTypesValidation = [
    'application/pdf',
    'application/rtf',
    'text/rtf',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/apng',
    'image/avif',
    'image/gif',
    'image/tiff',
    'image/jpeg',
    'image/png',
    'image/bmp',
  ];

  const requiredRule = {
    required: true,
    message: intl.formatMessage({
      id: 'shared.form.error.isRequired',
    }),
  };

  const displayMemberId = shouldDisplayMemberId({
    isNewMemberApplication,
    memberType,
    positionId: memberPosition,
  });

  const validateAge = useCallback(
    years => {
      const selectedPositionFound = find(unitPositions, {
        PositionId: +memberPosition,
      });
      if (memberType === memberTypeIds.participant) {
        // Participant position should validate default age values
        return years >= participantMinAge && years <= participantMaxAge;
      }
      if (
        memberPosition &&
        selectedPositionFound &&
        selectedPositionFound.minAge
      ) {
        return years >= selectedPositionFound.minAge;
      }
      if (memberType === memberTypeIds.adult) {
        return years >= adultMinAge;
      }
      if (memberType === memberTypeIds.youth) {
        return years >= minAge && years <= maxAge;
      }

      return true;
    },
    [
      memberType,
      minAge,
      maxAge,
      participantMinAge,
      participantMaxAge,
      adultMinAge,
      memberPosition,
      unitPositions,
    ],
  );

  const validateDateOfBirthOnly = useCallback(() => {
    if (dateOfBirth) {
      validateFields(['dateOfBirth'], { force: true });
    }
  }, [dateOfBirth, validateFields]);

  // this is to override antd's default upload implementation
  // https://stackoverflow.com/questions/51514757/action-function-is-required-with-antd-upload-control-but-i-dont-need-it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dummyRequest = ({ _, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleMemberTypeSelect = useCallback(
    memberTypeId => {
      if (memberTypeId === memberTypeIds.adult) {
        setFieldsValue({ memberPosition: undefined }, validateDateOfBirthOnly);
      }

      if (memberTypeId === memberTypeIds.youth) {
        const youthPosition = unitPositions.find(
          ({ isAdultPosition, positionLong }) =>
            !isAdultPosition && positionLong.includes(memberTypeLabels.youth),
        );
        setFieldsValue(
          { memberPosition: youthPosition.PositionId },
          validateDateOfBirthOnly,
        );
      }

      if (memberTypeId === memberTypeIds.participant) {
        const participantPosition = unitPositions.find(
          ({ isParticipant }) => isParticipant,
        );
        setFieldsValue(
          { memberPosition: participantPosition.PositionId },
          validateDateOfBirthOnly,
        );
      }
    },
    [setFieldsValue, unitPositions, validateDateOfBirthOnly],
  );

  const displayPaperApplication = isNewMemberApplication;

  const memberExists = isExistingMember({
    memberId,
    adultsInBatch: adultRosterRecharter,
  });

  const handlePaperApplicationChange = event => {
    if (!fileTypesValidation.includes(event.file.type)) {
      setFileList([]);
      setFields({
        paperApplication: {
          value: undefined,
          errors: [
            new Error(
              intl.formatMessage({
                id: 'recharterModals.addMembersModal.fileTypeError',
              }),
            ),
          ],
        },
      });
    } else {
      setFileList([event.file]);
      setFieldsValue({
        paperApplication: event,
      });
    }
  };

  const handlePaperApplicationRemove = () => {
    setFileList([]);
    setFieldsValue({ paperApplication: undefined });
  };

  const validate = useCallback(() => validateForm(form), [form]);

  const handleAddMember = async () => {
    const memberInfo = await validate();
    const { memberId } = memberInfo;
    /**
     * SBL-3730 When changing a Tiger Parent (314) or Lion Adult (662) Partner require Paper Application
     */
    const isRosterMember = find(adultRosterRecharter, ['memberId', memberId]);
    const hasPaperApplication = memberHasPaperApplication(
      adultRosterRecharter,
      memberId,
    );
    if (!isNewMemberApplication && isRosterMember && !hasPaperApplication) {
      dispatch(addMemberError());
      toastService.error(
        intl.formatMessage({
          id: 'recharterModals.addMembersModal.requiresPaperApplicationError',
        }),
        undefined,
        { withCloseButton: true },
      );
    } else if (positionIdsNotAllowedToBeAdded.has(memberPosition)) {
      dispatch(addMemberError());
      toastService.error(
        intl.formatMessage({
          id: 'recharterModals.addMembersModal.multiplePositionError',
        }),
        undefined,
        { withCloseButton: true },
      );
    } else if (memberInfo) {
      dispatch(addMemberRequest({ memberInfo, isNewMemberApplication }));
    }
  };

  // SBL-3911
  const showPaperApplicationHelp =
    displayPaperApplication && !memberExists && !isNewMemberApplication;

  return (
    <React.Fragment>
      <Row type="flex" justify="center">
        {/* First Name */}
        <Col xs={24} lg={13}>
          <FormItem
            className={cn(styles.formItemNormalWidth, styles.formItemNoMargin)}
          >
            {getFieldDecorator('firstName', { rules: [requiredRule] })(
              <Input
                className={styles.grayInput}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'recharterModals.addMembersModal.firstName',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
        {/* Last Name */}
        <Col xs={24} lg={11}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('lastName', { rules: [requiredRule] })(
              <Input
                className={styles.grayInput}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'recharterModals.addMembersModal.lastName',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        {/* Member Type */}
        <Col xs={24} lg={13}>
          <FormItem
            className={cn(styles.formItemNormalWidth, styles.formItemNoMargin)}
          >
            {getFieldDecorator('memberType', { rules: [requiredRule] })(
              <Select
                className={styles.graySelect}
                showSearch
                filterOption
                placeholder={intl.formatMessage({
                  id: 'recharterModals.addMembersModal.memberType',
                })}
                size="large"
                optionFilterProp="text"
                rounded
                floatingLabel
                onSelect={handleMemberTypeSelect}
              >
                <OptGroup
                  key="memberType"
                  label={
                    <FormattedMessage id="recharterModals.addMembersModal.memberType" />
                  }
                >
                  {filteredAddMemberTypes.map(({ id, label }, i) => (
                    <Option key={`type_${i}`} value={id} text={label}>
                      {label}
                    </Option>
                  ))}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
        {/* Email */}
        <Col xs={24} lg={11}>
          <FormItem
            className={cn(styles.formItemNormalWidth, styles.formItemNoMargin)}
          >
            {getFieldDecorator('email', {
              rules: [
                memberType !== memberTypeIds.youth
                  ? requiredRule
                  : { required: false },
                {
                  type: 'email',
                  message: intl.formatMessage({
                    id: 'profile.ProfileInfo.contactInfo.fieldError.email.valid',
                  }),
                },
              ],
            })(
              <Input
                className={styles.grayInput}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'recharterModals.addMembersModal.email',
                })}
                rounded
                floatingLabel
              />,
            )}
          </FormItem>
        </Col>
      </Row>
      <Row>
        {memberType && (
          <Col xs={24} lg={24}>
            <S className={styles.memberTypeHelpRow}>
              <S bold>
                <FormattedMessage id="recharterModals.addMembersModal.memberTypeHelp.title" />
              </S>
              {getMemberTypeNoteMessage(memberType, isNewMemberApplication)}
            </S>
          </Col>
        )}
      </Row>
      <Row type="flex" justify="center">
        {/* Member Position */}
        <Col xs={24} lg={13}>
          <FormItem className={styles.formItemNormalWidth}>
            {getFieldDecorator('memberPosition', { rules: [requiredRule] })(
              <Select
                disabled={!memberType}
                className={styles.graySelect}
                showSearch
                filterOption
                placeholder={intl.formatMessage({
                  id: 'recharterModals.addMembersModal.primaryPosition',
                })}
                size="large"
                optionFilterProp="text"
                rounded
                floatingLabel
              >
                <OptGroup
                  key="memberType"
                  label={
                    <FormattedMessage id="recharterModals.addMembersModal.primaryPosition" />
                  }
                >
                  {filteredUnitPositions.map(
                    ({ PositionId, positionLong }, i) => (
                      <Option
                        key={`type_${i}`}
                        value={PositionId}
                        text={positionLong}
                      >
                        {positionLong}
                      </Option>
                    ),
                  )}
                </OptGroup>
              </Select>,
            )}
          </FormItem>
        </Col>
        {/* Member ID */}
        <Col xs={24} lg={11}>
          {displayMemberId && (
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('memberId', {
                rules: [
                  { type: 'number' },
                  requiredRule,
                  {
                    message:
                      'The person should exist in the current unit roster with this Member ID',
                    validator: (_rule, value) => {
                      if (isNewMemberApplication) return true;
                      const found = unitRosterByMID[+value];
                      return !!found;
                    },
                  },
                ],
              })(
                <InputNumber
                  className={cn('bsa-input', styles.inputNumber)}
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'recharterModals.addMembersModal.memberId',
                  })}
                  rounded
                  floatingLabel
                />,
              )}
              <S size={'4'} className={styles.helpMessage}>
                <FormattedMessage id="recharterModals.addMembersModal.memberId.help" />
              </S>
            </FormItem>
          )}
        </Col>
      </Row>
      <Row type="flex" justify="center">
        {/* DOB */}
        <Col xs={24} lg={13}>
          <FormItem className={styles.formItemShortWidth}>
            {getFieldDecorator('dateOfBirth', {
              rules: [
                requiredRule,
                {
                  validator: (_, date) => {
                    const _effectiveDt = moment(effectiveDt);
                    const _date = moment(date);
                    const _today = moment();
                    const isEffectiveInPast = _effectiveDt.isBefore(_today);
                    const datediff = moment(
                      isEffectiveInPast ? _today : _effectiveDt,
                    ).diff(_date, 'years');

                    return date ? validateAge(datediff) : true;
                  },
                  message: intl.formatMessage({
                    id: 'recharterModals.addMembersModal.validDateOfBirth',
                  }),
                },
              ],
              validateStatus: false,
            })(
              <DatePicker
                className={styles.grayDatePicker}
                size="large"
                placeholder={intl.formatMessage({
                  id: 'recharterModals.addMembersModal.dob',
                })}
                disableDates="future"
                format={shortViewDateFormat}
                fluid
                floatingLabel
                rounded
              />,
            )}
          </FormItem>
        </Col>
        <Col xs={24} lg={11}>
          {displayPaperApplication && (
            <FormItem>
              {getFieldDecorator('paperApplication', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: showPaperApplicationHelp
                        ? 'recharterModals.addMembersModal.paperApplication.existing.help'
                        : 'shared.form.error.isRequired',
                    }),
                  },
                ],
              })(
                <React.Fragment>
                  {fileList.length > 0 ? (
                    fileList.map(({ name, uid }) => (
                      <div key={uid} className={styles.fileListContainer}>
                        <div>
                          <AttachFileIcon className={styles.clipIcon} />
                          {name}
                        </div>
                        <HighlightOffIcon
                          className={styles.closeIcon}
                          onClick={handlePaperApplicationRemove}
                        />
                      </div>
                    ))
                  ) : (
                    <Upload
                      accept=".pdf,.rtf,.txt,.doc,.docx,.jpeg,.tiff,.png,.gif,.jpg,.apng,.avif,.jfif,.pjpeg,.pjp,.bmp"
                      fileList={fileList}
                      showUploadList={false}
                      onChange={handlePaperApplicationChange}
                      customRequest={dummyRequest}
                    >
                      <Button
                        color="info"
                        noBorder
                        fitText
                        ghost
                        uppercase={false}
                        noPadding
                      >
                        <FormattedMessage id="recharterModals.addMembersModal.paperApplication" />
                        <AddCircleIcon className={styles.addIcon} />
                      </Button>
                    </Upload>
                  )}
                </React.Fragment>,
              )}
            </FormItem>
          )}
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Button
            loading={isLoading}
            className={styles.button}
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={false}
            onClick={handleAddMember}
          >
            <FormattedMessage id="recharterModals.addMembersModal.add" />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

AddMembersForm.propTypes = {
  form: PropTypes.object.isRequired,
  effectiveDt: PropTypes.string.isRequired,
  isNewMemberApplication: PropTypes.bool.isRequired,
};

export default Form.create()(AddMembersForm);
