import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { openApproveAdvancements } from '@progress/duck/actions';
import { Fab, FilterButton, TableMobile } from '@shared';
import { hasPermissionSel, permissions } from '@user';
import { toggleArrayItem } from '@utils';

import PendingItemsFilterCard from '../PendingItemsFilterCard';
import pendingItemsListRows from './pendingItemsListRows';

const { StickyTitle } = TableMobile;

const PendingItemsList = ({
  items,
  loading,
  isOffline,
  selectedRowKeys,
  searchPinned,
  onSetSelectedRowKeys,
  onGoToYouthProfile,
  onGoToAdultProfile,
}) => {
  const dispatch = useDispatch();

  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );

  const handleClickItem = useCallback(
    record => {
      const newSelectedRowKeys = toggleArrayItem(selectedRowKeys, record.key);
      onSetSelectedRowKeys(newSelectedRowKeys);
    },
    [selectedRowKeys, onSetSelectedRowKeys],
  );

  const handleApproveAdvancements = useCallback(() => {
    const selectedAdvancements = items.filter(({ key }) =>
      selectedRowKeys.includes(key),
    );
    dispatch(openApproveAdvancements(selectedAdvancements));
  }, [items, selectedRowKeys, dispatch]);

  const listHeader = (
    <StickyTitle key="header" clearSearch={searchPinned}>
      <FormattedMessage
        id="advancement.AdvancementHistory.historyRecorded"
        values={{
          count: <span>{items.length} </span>,
        }}
      />
      <PendingItemsFilterCard>
        <FilterButton />
      </PendingItemsFilterCard>
    </StickyTitle>
  );

  return (
    <React.Fragment>
      <TableMobile
        rowId="key"
        items={items}
        loading={loading}
        title={listHeader}
        rows={pendingItemsListRows({
          isOffline,
          onGoToYouthProfile,
          onGoToAdultProfile,
        })}
        selectedKeys={selectedRowKeys}
        onItemClick={handleClickItem}
      />
      {canApprove && (
        <Fab
          visible={selectedRowKeys.length > 0}
          onClick={handleApproveAdvancements}
        >
          <FormattedMessage id="advancement.PendingItems.approveItem" />
        </Fab>
      )}
    </React.Fragment>
  );
};

PendingItemsList.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  searchPinned: PropTypes.bool,
  onSetSelectedRowKeys: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  onGoToAdultProfile: PropTypes.func.isRequired,
};

export default PendingItemsList;
