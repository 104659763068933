import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import cn from 'classnames';

import DownloadFileErrorLogButton from '../DownloadFileErrorLogButton';
import styles from './AdvancementFileRecordsInfo.less';

const AdvancementFileRecordsInfo = ({ item, onDownloadErrorLog }) => (
  <div className={styles.container}>
    <div className={cn(styles.row, styles.imported)}>
      <CheckCircleIcon className={styles.icon} />
      <FormattedMessage
        id="advancementHistory.AdvancementFileRecordsInfo.imported"
        values={{ count: item.recordsImported }}
      />
    </div>
    <div className={cn(styles.row, styles.skipped)}>
      <ErrorIcon className={styles.icon} />
      <FormattedMessage
        id="advancementHistory.AdvancementFileRecordsInfo.skipped"
        values={{ count: item.recordsSkipped }}
      />
    </div>
    {onDownloadErrorLog && (
      <DownloadFileErrorLogButton
        size="small"
        ghost={false}
        noBorder={false}
        className={styles.downloadButton}
        item={item}
        onClick={onDownloadErrorLog}
      />
    )}
  </div>
);

AdvancementFileRecordsInfo.propTypes = {
  item: PropTypes.object.isRequired,
  onDownloadErrorLog: PropTypes.func,
};

export default AdvancementFileRecordsInfo;
