import React from 'react';
import { FormattedMessage } from 'react-intl';
import throttle from 'lodash/throttle';

import { toastService } from '@toasts';

class LandscapeNotification extends React.PureComponent {
  componentDidMount = () => {
    window.addEventListener('resize', this.checkDeviceOrientation);
    this.checkDeviceOrientation();
  };

  componentWillUnmount = () => {
    this.removeListener();
  };

  toastId = null;

  removeListener = () =>
    window.removeEventListener('resize', this.checkDeviceOrientation);

  checkDeviceOrientation = throttle(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    if (width > height) {
      this.showNotifucation();
      this.removeListener();
    }
  }, 500);

  showNotifucation = () => {
    toastService.warn(
      <FormattedMessage id="screen.LandscapeNotification.title" />,
    );
  };

  render() {
    return '';
  }
}

export default LandscapeNotification;
