import { ROUTE_PERSON_PROFILE, ROUTE_PERSON_ACTIVITY_LOGS } from './duck';
import { PersonProfilePage, PersonProfilePagePlaceholder } from './components';

const routes = {
  [ROUTE_PERSON_PROFILE]: {
    path: '/personProfile/:userId',
    Component: PersonProfilePage,
    Placeholder: PersonProfilePagePlaceholder,
    titleId: 'page.profile',
  },
  [ROUTE_PERSON_ACTIVITY_LOGS]: {
    path: '/personProfile/:userId/activityLogs/:activityType',
    Component: PersonProfilePage,
    Placeholder: PersonProfilePagePlaceholder,
    titleId: 'page.youthActivityLogs',
  },
};

export default routes;
