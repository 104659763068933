import uniqBy from 'lodash/uniqBy';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { organizationGuidSel, organizationTypeIdSel } from '@context';
import { setSelectedRosterKeys } from '@shared/duck';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { userIdSel } from '@user';
import { catchAndReport } from '@utils/rxjs/operators';

import {
  loadAndOpenActivity,
  personsAndIdsActivitySel,
  personsToRemoveSel,
  selectedActivitySel,
  setTime,
} from '../../common';
import {
  LOAD_AND_OPEN_LONG_CRUISE_MODAL,
  RECORD_LONG_CRUISE_REQUEST,
  RECORD_LONG_CRUISE_RESPONSE,
  closeLongCruiseModal,
  loadAndOpenLongCruiseModalError,
  openEditLongCruise,
  recordLongCruiseError,
  recordLongCruiseResponse,
} from './actions';
import { activeTabSel, personsSel } from './selectors';
import services from './services';

const recordLongCruiseEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(RECORD_LONG_CRUISE_REQUEST),
    switchMap(({ payload }) => {
      const state = state$.value;
      const { activityData } = payload;
      let validActivityData = { ...activityData };

      if (activityData.allDay) {
        validActivityData = {
          ...validActivityData,
          startTime: setTime(),
          endTime: setTime(23, 59),
        };
      }

      const persons = personsSel(state);
      const loggedInUserId = userIdSel(state);
      const organizationGuid = organizationGuidSel(state);
      const personsToRemove = personsToRemoveSel(state);
      const organizationTypeId = organizationTypeIdSel(state);
      const selectedActivity = selectedActivitySel(state);
      const isAdvancedMode = activeTabSel(state) == 'advanced';
      const { personsInActivity } = personsAndIdsActivitySel(state);
      return services
        .saveLongCruiseActivity$({
          ...selectedActivity,
          ...validActivityData,
          persons: uniqBy([...persons, ...personsInActivity], 'userId'),
          personsToRemove,
          loggedInUserId,
          organizationGuid,
          organizationTypeId: +organizationTypeId,
          isAdvancedMode,
        })
        .pipe(
          mergeMap(() => {
            const { closeOnSuccess } = payload;
            const actions = [recordLongCruiseResponse()].concat(
              closeOnSuccess
                ? [closeLongCruiseModal(), setSelectedRosterKeys([])]
                : [],
            );

            return of(...actions);
          }),
          tap(({ type }) => {
            if (type === RECORD_LONG_CRUISE_RESPONSE) {
              toastService.success(
                intl.formatMessage(
                  { id: 'progress.longCruise.success' },
                  { name: activityData.name },
                ),
              );
            }
          }),
          catchAndReport(err => of(recordLongCruiseError(err))),
        );
    }),
  );

const loadLongCruiseByIdEpic$ = action$ =>
  action$.pipe(
    ofType(LOAD_AND_OPEN_LONG_CRUISE_MODAL),
    map(({ payload }) =>
      loadAndOpenActivity({
        ...payload,
        successActionFn: openEditLongCruise,
        errorActionFn: loadAndOpenLongCruiseModalError,
      }),
    ),
  );

export default combineEpics(recordLongCruiseEpic$, loadLongCruiseByIdEpic$);
