import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Row, T } from '@shared/components';

import {
  batchApplicationIdSel,
  getUnitPaymentSummaryRequest,
} from '../../../../duck';
import styles from './UnitPaymentError.less';

const UnitPaymentError = ({ onCancel }) => {
  const dispatch = useDispatch();
  const batchApplicationId = useSelector(batchApplicationIdSel);

  const handleOnTryAgain = useCallback(() => {
    onCancel();
    dispatch(getUnitPaymentSummaryRequest(batchApplicationId));
  }, [dispatch, onCancel, batchApplicationId]);

  return (
    <React.Fragment>
      <Row type="flex" justify="center">
        <T size="3">
          <FormattedMessage id="recharterModals.unitPaymentModal.message" />
        </T>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          <Button
            className={styles.button}
            type="primary"
            shape="round"
            size="default"
            shadow
            block
            uppercase={false}
            onClick={handleOnTryAgain}
          >
            <FormattedMessage id="recharterModals.unitPaymentModal.reload" />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

UnitPaymentError.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default UnitPaymentError;
