import React, { useMemo } from 'react';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { Card, Row, Col, T, S } from '@shared';

import MoveUserPerson from './MoveUserPerson';

import styles from './MoveUsersList.less';
import { personNameBuilder } from '@modules/utils';
import { RosterScout } from '@modules/advancement/duck/types';

type Props = {
  onSelect: (userId: number) => void;
  selectIcon: React.ReactNode;
  persons: RosterScout[];
  emptyListTitle: React.ReactNode;
  emptyListDsc: React.ReactNode;
  searchStr: string;
  disabled?: boolean;
};

const MoveUsersList: React.FC<Props> = ({
  persons,
  selectIcon,
  onSelect,
  emptyListTitle,
  emptyListDsc,
  searchStr,
  disabled = false,
  ...props
}) => {
  const filteredPersons = useMemo(() => {
    if (!searchStr) return persons;
    return persons.filter(({ personShortFullName }) =>
      personShortFullName.toLowerCase().includes(searchStr.toLowerCase()),
    );
  }, [searchStr, persons]);

  return (
    <Card
      bordered
      rounded
      className={cn(styles.cardContainer, { [styles.disabled]: disabled })}
    >
      {(emptyListTitle || emptyListDsc) && isEmpty(filteredPersons) ? (
        <div className={styles.emtptyListMask}>
          <T size="4" bold>
            {emptyListTitle}
          </T>
          <S size="5">{emptyListDsc}</S>
        </div>
      ) : (
        <Row>
          {filteredPersons.map(person => (
            <Col key={`subunit-${person.userId}`}>
              <MoveUserPerson
                isAdult={person.isAdult}
                personName={personNameBuilder.short(person)}
                onSelect={onSelect}
                selectIcon={selectIcon}
                userId={person.userId}
                {...props}
              />
            </Col>
          ))}
        </Row>
      )}
    </Card>
  );
};

export default MoveUsersList;
