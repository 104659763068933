import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  // components
  CollapseDetails,
  S,
} from '@shared';

import { youthsAndAdultsSel } from '../../../duck';

import Person from './Person';

// eslint-disable-next-line react/display-name, react/prop-types
const PersonList = React.memo(({ onDeselectPerson }) => {
  const { youths, adults } = useSelector(youthsAndAdultsSel);

  const collapseDetails = useCallback(
    (persons, translationKey) => (
      <CollapseDetails
        header={
          <S size={'4'}>
            <FormattedMessage id={translationKey} />({persons.length})
          </S>
        }
      >
        {persons.map(person => (
          <Person
            key={person.userId}
            data={person}
            onClose={onDeselectPerson}
          />
        ))}
      </CollapseDetails>
    ),
    [onDeselectPerson],
  );
  return (
    <React.Fragment>
      {collapseDetails(youths, 'shared.youths')}
      {collapseDetails(adults, 'shared.leaders')}
    </React.Fragment>
  );
});

export default PersonList;
