import React, { useCallback } from 'react';
import cn from 'classnames';

import { Tag, Row, Col, PersonAvatar } from '@shared';

import styles from '../MoveUsersList.less';

type Props = {
  isAdult: boolean;
  onSelect: (userId: number) => void;
  personName: string;
  selectIcon: React.ReactNode;
  userId: number;
  className?: string;
};

const MoveUserPerson: React.FC<Props> = ({
  className,
  isAdult,
  personName,
  onSelect,
  selectIcon,
  userId,
}) => {
  const handleOnSelect = useCallback(() => {
    onSelect(userId);
  }, [userId, onSelect]);
  return (
    <Tag
      outline={false}
      className={cn(styles.userTag, className)}
      onClick={handleOnSelect}
    >
      <Row type="flex" align="middle">
        <Col>
          <PersonAvatar className={styles.userIcon} isAdult={isAdult} />
        </Col>
        <Col>{personName}</Col>
        <Col>
          <div className={styles.addIcon}>{selectIcon}</div>
        </Col>
      </Row>
    </Tag>
  );
};

export default MoveUserPerson;
