/* eslint-disable react/no-multi-comp */
import React from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import { CollapseDetails, S } from '@shared/components';
import { Program } from '@shared/constants';
import { organizationGuidSel } from '@shared/duck';

import { getMyScoutingUrl } from '../../../../../../config';
import { BATCH_VALIDATIONS } from '../../../../../constants';
import { SwitchIcon } from '../../../../common';
import styles from '../ValidationItem.less';

/**
 * @typedef {import('../../../../../types').YouthAgeValidationDetails} YouthAgeValidationDetails
 */

export const CollapsableItem = props => {
  const {
    validationId,
    validation,
    validationType,
    customIcon,
    customHeader,
    validationDetails,
    details,
    ...restProps
  } = props;

  const collapsable = !isEmpty(validationDetails);

  const icon = (
    <SwitchIcon validation={validation} validationType={validationType} />
  );
  const headerMessage = (
    <FormattedMessage
      id={
        !validation
          ? `recharterModals.validationModal.validations.flag${validationId}`
          : `recharterModals.validationModal.validations.flag${validationId}.negative`
      }
    />
  );

  const header = (
    <div className={styles.ItemContainer}>
      <div className={styles.Icon}>{customIcon || icon}</div>
      <div>{customHeader || headerMessage}</div>
    </div>
  );
  const content = <div className={styles.ItemDetails}>{details}</div>;

  if (!collapsable) return header;

  return (
    <CollapseDetails
      {...restProps}
      header={header}
      showArrow={collapsable}
      disabled={!collapsable}
      withSeeMore
    >
      {content}
    </CollapseDetails>
  );
};

function PendingApplicationsValidationItem(props) {
  const { validationId, validation } = props;
  const orgGuid = useSelector(organizationGuidSel);
  const url = `${getMyScoutingUrl()}/${orgGuid}/membership-manager/application`;

  const msg = (
    <FormattedMessage
      id={
        !validation
          ? `recharterModals.validationModal.validations.flag${validationId}`
          : `recharterModals.validationModal.validations.flag${validationId}.negative`
      }
    />
  );

  return (
    <CollapsableItem
      {...props}
      customHeader={validation ? <a href={url}>{msg}</a> : msg}
      disabled
      showArrow={false}
      panelClassName={styles.PendingApplicationsPanel}
    />
  );
}

function LeadersWithNoYPTValidationItem(props) {
  /* firstName: string, lastName: string, memberId: number */
  const { validationDetails = [] } = props;
  const details = (
    <ul>
      {validationDetails.map((item, i) => {
        const memberId = get(item, 'memberId');
        const data = [
          get(item, 'firstName'),
          get(item, 'lastName'),
          '-',
          'MID: ',
          memberId,
        ].join(' ');

        return <li key={`${i}-${memberId}`}>{data}</li>;
      })}
    </ul>
  );

  return <CollapsableItem {...props} details={details} />;
}

// id 15
function AdultsWithNoSSNValidationItem(props) {
  /* firstName: string, lastName: string, personId: number */
  const { validationDetails = [] } = props;
  const details = (
    <ul>
      {validationDetails.map((item, i) => {
        const personId = get(item, 'personId');
        const data = [get(item, 'firstName'), get(item, 'lastName')].join(' ');

        return <li key={`${i}-${personId}`}>{data}</li>;
      })}
    </ul>
  );

  return <CollapsableItem {...props} details={details} />;
}

// id 10
function LeadersWithNoCBCValidationItem(props) {
  /* firstName: string, lastName: string, personId: number */
  const { validationDetails = [] } = props;
  const details = (
    <ul>
      {validationDetails.map((item, i) => {
        const personId = get(item, 'personId');
        const data = [get(item, 'firstName'), get(item, 'lastName')].join(' ');

        return <li key={`${i}-${personId}`}>{data}</li>;
      })}
    </ul>
  );

  return <CollapsableItem {...props} details={details} />;
}

/**
 * @param {Object} props
 * @param {YouthAgeValidationDetails} props.validationDetails
 */
function YouthAgeValidation(props) {
  const { validationDetails } = props;
  const unitType = useSelector(programSel);
  const adultYouths = validationDetails.filter(item => item.is18orOlder);
  const otherMembers = validationDetails.filter(item => !item.is18orOlder);
  const getPersonInfo = item =>
    [get(item, 'firstName'), get(item, 'lastName')].join(' ');

  const details = (
    <React.Fragment>
      {adultYouths.length > 0 && (
        <React.Fragment>
          <S size="5">
            <FormattedMessage id="recharterModals.validationModal.validations.flag12.details.youth" />
          </S>
          <ul>
            {validationDetails.map((item, i) => {
              const personId = get(item, 'personId');
              return <li key={`${i}-${personId}`}>{getPersonInfo(item)}</li>;
            })}
          </ul>
        </React.Fragment>
      )}
      {otherMembers.length > 0 && (
        <React.Fragment>
          <S size={'5'}>
            {unitType === Program.CUB_SCOUT ? (
              <FormattedMessage id="recharterModals.validationModal.validations.flag12.details.other.packs" />
            ) : (
              <FormattedMessage id="recharterModals.validationModal.validations.flag12.details.other" />
            )}
          </S>
          <ul>
            {validationDetails.map((item, i) => {
              const personId = get(item, 'personId');
              return <li key={`${i}-${personId}`}>{getPersonInfo(item)}</li>;
            })}
          </ul>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return <CollapsableItem {...props} details={details} />;
}

/**
 * @param {Object} props
 * @param {import('../../../../../types').YouthNoParentGuardianValidationDetals[]} props.validationDetails
 */
function YouthNoParentGuardian(props) {
  const { validationDetails } = props;
  const getPersonInfo = item =>
    [get(item, 'firstName'), get(item, 'lastName')].filter(Boolean).join(' ');

  const details = (
    <React.Fragment>
      {validationDetails.length > 0 && (
        <React.Fragment>
          <S size="5">
            <FormattedMessage id="recharterModals.validationModal.validations.flag18.details" />
          </S>
          <ul>
            {validationDetails.map((item, i) => {
              const personId = get(item, 'personId');
              return (
                <li key={`${i}-${personId}`}>
                  {getPersonInfo(item)} {item.memberId}{' '}
                  {item.memberId ? `- MID: ${item.memberId}` : null}
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      )}
    </React.Fragment>
  );

  return <CollapsableItem {...props} details={details} />;
}

function MissingPositionsValidationItem(props) {
  const { missingPositions } = props;
  const details = (
    <div>
      {missingPositions &&
        missingPositions.map(position => (
          <div key={position.position}>
            {`${position.position} - Expected: ${position.expected} | Actual: ${position.actual}`}
          </div>
        ))}
    </div>
  );
  return <CollapsableItem {...props} details={details} />;
}

const sharedPropTypes = {
  validation: PropTypes.bool,
  validationId: PropTypes.number,
  validationType: PropTypes.string,
  validationDetails: PropTypes.array,
};

PendingApplicationsValidationItem.propTypes = {
  ...sharedPropTypes,
};
LeadersWithNoCBCValidationItem.propTypes = {
  ...sharedPropTypes,
};

AdultsWithNoSSNValidationItem.propTypes = { ...sharedPropTypes };
MissingPositionsValidationItem.propTypes = { ...sharedPropTypes };
LeadersWithNoYPTValidationItem.propTypes = { ...sharedPropTypes };
YouthAgeValidation.propTypes = { ...sharedPropTypes };
YouthNoParentGuardian.propTypes = { ...sharedPropTypes };

CollapsableItem.propTypes = {
  ...sharedPropTypes,
  icon: PropTypes.node,
  header: PropTypes.node,
  details: PropTypes.node,
};

export const CustomValidations = {
  [BATCH_VALIDATIONS.LeadershipPositions.validationId]:
    MissingPositionsValidationItem,
  [BATCH_VALIDATIONS.NoYPTLeaders.validationId]: LeadersWithNoYPTValidationItem,
  [BATCH_VALIDATIONS.NoCBCLeaders.validationId]: LeadersWithNoCBCValidationItem,
  [BATCH_VALIDATIONS.YouthsAgeRequirement.validationId]: YouthAgeValidation,
  [BATCH_VALIDATIONS.YouthNoParentGuardians.validationId]:
    YouthNoParentGuardian,
  [BATCH_VALIDATIONS.AdultsNoSSN.validationId]: AdultsWithNoSSNValidationItem,
  [BATCH_VALIDATIONS.UnitPendingApplications.validationId]:
    PendingApplicationsValidationItem,
};
