import React from 'react';

import type { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import { Button as BsaButton } from 'bsa-ui';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Button.less';

const extraColors = ['success', 'warn', 'info', 'error'];

export interface ButtonProps extends Omit<AntdButtonProps, 'icon'> {
  uppercase?: boolean;
  fitText?: boolean;
  shadow?: boolean;
  noBorder?: boolean;
  color?: string;
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
  rightIcon?: boolean;
  noPadding?: boolean;
  autoHeight?: boolean;
  /* Use with no children to create an IconButton like */
  onlyIcon?: boolean;
  bold?: boolean;
}

const Button = ({
  uppercase,
  shadow,
  disabled,
  loading,
  noBorder,
  color,
  className,
  onlyIcon,
  children,
  icon,
  rightIcon,
  noPadding,
  autoHeight,
  bold,
  onClick,
  type,
  ...props
}: ButtonProps) => {
  let extraColor = '';
  const buttonType = type === 'link' ? undefined : type;
  if (color && extraColors.includes(color)) {
    extraColor = color;
    color = 'scouting-blue';
  }

  const classNames = cn(
    styles.button,
    {
      [styles.uppercase]: uppercase,
      [styles.shadow]: shadow && !disabled,
      [styles.noBorder]: noBorder,
      [styles.withIcon]: icon,
      [styles.noPadding]: noPadding,
      [styles.onlyIcon]: onlyIcon,
      [styles.autoHeight]: autoHeight,
      [styles.bold]: bold,
      [styles.link]: type === 'link',
    },
    styles[extraColor],
    className,
  );

  const isRightIcon = icon && rightIcon;
  const styledIcon =
    icon &&
    React.cloneElement(icon, {
      className: cn(icon.props.className, styles.icon, {
        [styles.iconRMargin]: children && !isRightIcon,
        [styles.iconLMargin]: loading,
        [styles.rightIcon]: isRightIcon,
      }),
    });

  const handleClick: React.MouseEventHandler<HTMLElement> = event => {
    (event.target as HTMLButtonElement).blur();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <BsaButton
      className={classNames}
      color={color}
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      type={buttonType}
      {...props}
    >
      {styledIcon}
      {children}
    </BsaButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  shape: PropTypes.string,
  uppercase: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  shadow: PropTypes.bool,
  noBorder: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  rightIcon: PropTypes.bool,
  noPadding: PropTypes.bool,
  autoHeight: PropTypes.bool,
  bold: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.Group = BsaButton.Group;

Button.defaultProps = {
  uppercase: true,
  shadow: false,
  noBorder: false,
  color: 'scouting-blue',
};

export default Button;
