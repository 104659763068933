import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import {
  ProgramId,
  memberTypeIds,
  memberTypeLabels,
  memberTypes,
  unitTypeIdForUnitTypeName,
  unitTypes,
} from '@shared/constants';
import { packRosterItemsSel } from '@shared/duck';
import { unitInfoSel } from '@unit';
import { applySort } from '@utils';

import {
  BATCH_VALIDATIONS,
  COUNCIL_802_GUID,
  batchTypeIds,
  ccEmailDelegatePositions,
  moduleName,
  recharterStatusTypesForIds,
  stepOrder,
  validationTypes,
} from '../constants';
import unitRosterSorters from '../unitRosterSorters';
import {
  getAgreementRecipients,
  getBasicRequirementsByType,
  isBeforeToday,
  isValidDateNewMember,
} from '../utils';

const moduleSel = state => state[moduleName];

export const currentStepSel = state => moduleSel(state).currentStep;
export const isCurrentFirstStepSel = state =>
  stepOrder.indexOf(currentStepSel(state)) === 0;

export const allBatchesSel = state => moduleSel(state).allBatches;
export const isAllBatchesLoadingSel = state =>
  moduleSel(state).isAllBatchesLoading;

export const isManualBatchSel = createSelector(allBatchesSel, batches => {
  const manualBatch = batches.find(
    batch => batch.batchApplicationTypeId === batchTypeIds.manualRechartering,
  );
  return !isEmpty(manualBatch);
});

export const agreementUriSel = createSelector(
  [state => moduleSel(state), (_state, batchId) => batchId],
  (state, batchId) => get(state, ['agreementUris', batchId]),
);

export const isLoadingUnitPaymentSummarySel = state =>
  moduleSel(state).isLoadingUnitPaymentSummary;

export const unitPaymentSummarySel = state =>
  moduleSel(state).unitPaymentSummary;

export const isAddMembersModalVisible = state =>
  moduleSel(state).isAddMembersModalVisible;
export const isAdobeErrorModalVisible = state =>
  moduleSel(state).isAdobeErrorModalVisible;
export const isSelectAddMembersFlowModalVisible = state =>
  moduleSel(state).isSelectAddMembersFlowModalVisible;
export const isNewMemberApplicationSel = state =>
  moduleSel(state).isNewMemberApplication;
export const isUploadDocumentLoadingSel = state =>
  moduleSel(state).isUploadDocumentLoading;
export const isAddMemberLoadingSel = state =>
  moduleSel(state).isAddMemberLoading;
export const isLoadingEditTermSel = state => moduleSel(state).isLoadingEditTerm;
export const selectedTermSel = state => moduleSel(state).selectedTerm;
export const showTermSelectorSel = state => moduleSel(state).showTermSelector;
export const isValidationModalVisibleSel = state =>
  moduleSel(state).isValidationModalVisible;

export const isPrivacyModalVisibleSel = state =>
  moduleSel(state).isPrivacyModalVisible;

export const isUploadDocumentModalVisible = state =>
  moduleSel(state).isUploadDocumentModalVisible;
export const memberUnitPositionsSel = state => moduleSel(state).unitPositions;
export const unitBasicRequirementsSel = state =>
  moduleSel(state).unitBasicRequirements;

export const isUnitPaymentErrorModalVisibleSel = state =>
  moduleSel(state).isUnitPaymentErrorModalVisible;

export const isResendInviteModalVisibleSel = state =>
  moduleSel(state).isResendInviteModalVisible;

export const isPaymentModalVisibleSel = state =>
  moduleSel(state).isPaymentModalVisible;

export const isConfirmationPaymentModalVisibleSel = state =>
  moduleSel(state).isConfirmationPaymentModalVisible;

export const confirmationModalInfoSel = state =>
  moduleSel(state).confirmationModalInfo;

export const isUpdatePositionModalVisible = state =>
  moduleSel(state).isUpdatePositionModalVisible;

export const isEditTermModalVisibleSel = state =>
  moduleSel(state).isEditTermModalVisible;

export const isAchVerificationModalVisible = state =>
  moduleSel(state).isAchVerificationModalVisible;

export const isUpdatePositionLoadingSel = state =>
  moduleSel(state).isUpdatePositionLoading;

export const memberPositionToUpdateSel = state =>
  moduleSel(state).memberPositionToUpdate;

export const rosterValidationSel = state =>
  moduleSel(state).recharterRosterValidation;

export const unitYouthsRequirementsSel = createSelector(
  unitBasicRequirementsSel,
  unitBasicRequirements =>
    getBasicRequirementsByType(unitBasicRequirements, memberTypeLabels.youth),
);

export const unitParticipantsRequirementsSel = createSelector(
  unitBasicRequirementsSel,
  unitBasicRequirements =>
    getBasicRequirementsByType(
      unitBasicRequirements,
      memberTypeLabels.participant,
    ),
);

export const unitAdultsRequirementsSel = createSelector(
  unitBasicRequirementsSel,
  unitBasicRequirements =>
    getBasicRequirementsByType(unitBasicRequirements, memberTypeLabels.adult),
);

export const rosterHasPaperApplicationsSel = createSelector(
  rosterValidationSel,
  rosterValidations =>
    rosterValidations.filter(validation => validation.validationId === 8)[0],
);

export const rosterMissingPositionsSel = createSelector(
  rosterValidationSel,
  rosterValidations => {
    const missingPositions = rosterValidations.map(validation => {
      const { validationId } = validation;
      if (validationId === 6) {
        return [...validation.validationDetails];
      }
    });
    return missingPositions.filter(mp => mp !== undefined)[0];
  },
);

export const rosterVisibleValidationsSel = createSelector(
  rosterValidationSel,
  rosterValidations => {
    const validationsById = groupBy(
      [
        BATCH_VALIDATIONS.UnitLessThanFive,
        BATCH_VALIDATIONS.LeadershipPositions,
        BATCH_VALIDATIONS.NoYPTLeaders,
        BATCH_VALIDATIONS.NoCBCLeaders,
        BATCH_VALIDATIONS.LeadersLess18YearsOld,
        BATCH_VALIDATIONS.AdultsNoSSN,
        BATCH_VALIDATIONS.YouthsAgeRequirement,
        BATCH_VALIDATIONS.UnitNumberPaidYouths,
        BATCH_VALIDATIONS.UnitPendingApplications,
        BATCH_VALIDATIONS.YouthNoParentGuardians,
      ],
      'validationId',
    );

    const visible = rosterValidations.filter(validation => {
      const { validationId } = validation;
      return validationsById[validationId];
    });

    return visible.filter(validation => validation !== undefined);
  },
);

export const rosterValidationRejectionSel = createSelector(
  rosterVisibleValidationsSel,
  visibleValidations => {
    let failed = false;
    visibleValidations.forEach(validation => {
      const { validationFlag, validationCategoryUnit } = validation;
      const passedValidation = !validationFlag;
      if (
        !passedValidation &&
        validationCategoryUnit.toLowerCase() === validationTypes.ERROR
      ) {
        failed = true;
      }
    });
    return failed;
  },
);

export const rosterValidationProgressSel = createSelector(
  rosterVisibleValidationsSel,
  visibleValidations => {
    let progress = 0;

    visibleValidations.forEach(flag => {
      if (flag.validationFlag) {
        if (flag.validationId === 9 || flag.validationId === 10) {
          progress += 25;
        }
      }
      if (!flag.validationFlag) {
        progress += 25;
      }
    });
    return progress;
  },
);

export const rosterValidationLoadingSel = state =>
  moduleSel(state).recharterValidationLoading;
export const selectedPaymentMethodSel = state =>
  moduleSel(state).selectedPaymentMethod;

export const isLoadingPaymentProcessSel = state =>
  moduleSel(state).isLoadingPaymentProcess;

export const isLoadingAchVerificationSel = state =>
  moduleSel(state).isLoadingAchVerification;

export const isLoadingSubmitRecharterSel = state =>
  moduleSel(state).isLoadingSubmitRecharter;

export const isLoadingWepayProcessSel = state =>
  moduleSel(state).isLoadingWepayProcess;

export const isLoadingSendRemindersSel = state =>
  moduleSel(state).isLoadingSendReminders;

//
// unit roster
//

export const batchInformationSel = createSelector(
  moduleSel,
  allBatchesSel,
  isManualBatchSel,
  (moduleState, batches, isManualBatch) => {
    if (!isManualBatch) return moduleState.batchInformation;

    const manualBatch = batches.find(
      batch => batch.batchApplicationTypeId === batchTypeIds.manualRechartering,
    );
    return [manualBatch] || [];
  },
);

export const batchStatusSel = createSelector(batchInformationSel, batchInfo => {
  if (isEmpty(batchInfo)) {
    return '';
  }
  const { batchApplicationCurrentStatusId } = batchInfo[0];
  return recharterStatusTypesForIds[batchApplicationCurrentStatusId];
});

export const batchPaymentTypeSel = createSelector(
  batchInformationSel,
  batchInfo => {
    if (isEmpty(batchInfo)) {
      return '';
    }
    const { paymentType } = batchInfo[0];
    return paymentType || '';
  },
);

export const paymentGatewayStatusSel = createSelector(
  batchInformationSel,
  batchInfo => {
    if (isEmpty(batchInfo)) {
      return '';
    }
    const { paymentGatewayStatus } = batchInfo[0];
    return paymentGatewayStatus || '';
  },
);

export const isCorSignedSel = createSelector(batchInformationSel, batchInfo => {
  if (isEmpty(batchInfo)) {
    return false;
  }
  const { isCorSigned } = batchInfo[0];
  return isCorSigned;
});

export const isPaidSel = createSelector(batchInformationSel, batchInfo => {
  if (isEmpty(batchInfo)) {
    return false;
  }
  const { isPaid } = batchInfo[0];
  return isPaid;
});

export const batchLastModifiedDtSel = createSelector(
  batchInformationSel,
  batchInfo => {
    if (isEmpty(batchInfo)) {
      return undefined;
    }
    const { lastModifiedDt } = batchInfo[0];
    return lastModifiedDt;
  },
);

export const batchCreatedDtSel = createSelector(
  batchInformationSel,
  batchInfo => {
    if (isEmpty(batchInfo)) {
      return undefined;
    }
    const { createdDt } = batchInfo[0];
    return createdDt;
  },
);

export const recharterRosterSearchSel = state =>
  moduleSel(state).recharterRosterSearch;
export const unitRosterSorterSel = state => moduleSel(state).unitRosterSorter;
export const unitRosterPendingSorterSel = state =>
  moduleSel(state).unitRosterPendingSorter;
export const unitRosterRemovedSorterSel = state =>
  moduleSel(state).unitRosterRemovedSorter;
export const recharterRosterFiltersSel = state =>
  moduleSel(state).recharterRosterFilters;

export const allRecharterRosterSel = state => moduleSel(state).recharterRoster;

export const recharterRosterWithNewMembersSel = createSelector(
  allRecharterRosterSel,
  batchCreatedDtSel,
  (roster, createdDt) =>
    roster.map(p => ({
      ...p,
      isNewMember:
        createdDt &&
        isValidDateNewMember(createdDt, p.createdDt) &&
        p.existingFkPersonPositionId,
    })),
);

export const recharterRosterSel = createSelector(
  recharterRosterWithNewMembersSel,
  roster => roster.filter(p => !p.isRemoved && !p.isInvited),
);

export const batchApplicationIdSel = createSelector(
  recharterRosterSel,
  recharterRoster =>
    !isEmpty(recharterRoster) ? recharterRoster[0].batchApplicationId : -1,
);

export const recharterRosterAdultsSel = createSelector(
  recharterRosterSel,
  roster => roster.filter(p => p.memberTypeId === 2),
);

export const recharterRosterYouthSel = createSelector(
  recharterRosterSel,
  roster => roster.filter(p => p.memberTypeId === 1),
);

const recharterRosterYptFilter = (person, isNotYpt, isYpt) => {
  if (isYpt && isNotYpt) {
    return true;
  }
  if (isNotYpt) {
    return !person.isYpt;
  }
  if (isYpt) {
    return person.isYpt;
  }
  return false;
};

const recharterRosterCbcFilter = (person, isNotCbc, isCbc) => {
  if (isCbc && isNotCbc) {
    return true;
  }
  if (isNotCbc) {
    return !person.isCbc;
  }
  if (isCbc) {
    return person.isCbc;
  }
  return false;
};

const recharterRosterScoutsLifeFilter = (
  person,
  isNotScoutsLife,
  isScoutsLife,
) => {
  if (isScoutsLife && isNotScoutsLife) {
    return true;
  }
  if (isNotScoutsLife) {
    return !person.isBoysLife;
  }
  if (isScoutsLife) {
    return person.isBoysLife;
  }
  return false;
};

const recharterRosterAgeFilter = (person, adult, youth) => {
  if (adult && youth) {
    return true;
  }
  if (youth) {
    return person.memberTypeId === 1 || person.memberTypeId === 3;
  }
  if (adult) {
    return person.memberTypeId === 2;
  }
  return false;
};

export const filteredRecharterRosterSel = createSelector(
  recharterRosterSel,
  recharterRosterSearchSel,
  recharterRosterFiltersSel,
  unitRosterSorterSel,
  (
    roster,
    search,
    {
      isNotYpt,
      isYpt,
      isNotCbc,
      isCbc,
      isNotScoutsLife,
      isScoutsLife,
      adults,
      youth,
    },
    sorter,
  ) => {
    const filteredItems = roster
      .filter(
        p =>
          p.personFullName.toLowerCase().includes(search.toLowerCase()) ||
          p.memberId.toString().includes(search.toLowerCase()),
      )
      .filter(
        p =>
          recharterRosterYptFilter(p, isNotYpt, isYpt) &&
          recharterRosterCbcFilter(p, isNotCbc, isCbc) &&
          recharterRosterScoutsLifeFilter(p, isNotScoutsLife, isScoutsLife) &&
          recharterRosterAgeFilter(p, adults, youth),
      );

    if (isEmpty(sorter) || sorter.columnKey === undefined) {
      return sortBy(filteredItems, [
        'memberType',
        p => p.personFullName.toLowerCase(),
        'positionLong',
      ]);
    }
    return applySort({
      items: filteredItems,
      sorters: unitRosterSorters,
      sorter,
    });
  },
);

export const isLoadingRecharterRosterSel = state =>
  moduleSel(state).isLoadingRecharterRoster;

// TODO: replace with proper selectors when adding/removing is implemented
export const recharterRosterRenewSel = state => recharterRosterSel(state);
export const recharterRosterNewSel = state => recharterRosterSel(state);

export const removedMembersSelectedKeysSel = state =>
  moduleSel(state).removedMembersSelectedKeys;

export const removedMembersSel = createSelector(
  recharterRosterWithNewMembersSel,
  unitRosterRemovedSorterSel,
  (roster, sorter) => {
    const filteredItems = roster.filter(p => p.isRemoved);

    if (isEmpty(sorter) || sorter.columnKey === undefined) {
      return sortBy(filteredItems, [
        'memberType',
        p => p.personFullName.toLowerCase(),
        'positionLong',
      ]);
    }

    return applySort({
      items: filteredItems,
      sorters: unitRosterSorters,
      sorter,
    });
  },
);

export const pendingMembersSelectedKeysSel = state =>
  moduleSel(state).pendingMembersSelectedKeys;

export const pendingMembersSel = createSelector(
  recharterRosterWithNewMembersSel,
  roster =>
    roster
      .filter(({ isInvited }) => isInvited)
      .map(person => ({
        ...person,
        allowSendReminder: isBeforeToday(person.latestInvitedDate),
        email: person.email ? person.email : person.pgEmail,
      })),
);
export const pendingMembersSelectedSel = createSelector(
  pendingMembersSel,
  pendingMembersSelectedKeysSel,
  (pendingMembers, pendingMembersSelectedKeys) =>
    pendingMembers.filter(({ personId }) =>
      pendingMembersSelectedKeys.includes(personId),
    ),
);
export const filteredPendingRecharterRosterSel = createSelector(
  pendingMembersSel,
  recharterRosterSearchSel,
  recharterRosterFiltersSel,
  unitRosterPendingSorterSel,
  (
    roster,
    search,
    {
      isNotYpt,
      isYpt,
      isNotCbc,
      isCbc,
      isNotScoutsLife,
      isScoutsLife,
      adults,
      youth,
    },
    sorter,
  ) => {
    const filteredItems = roster
      .filter(
        p =>
          p.personFullName.toLowerCase().includes(search.toLowerCase()) ||
          p.memberId.toString().includes(search.toLowerCase()),
      )
      .filter(
        p =>
          recharterRosterYptFilter(p, isNotYpt, isYpt) &&
          recharterRosterCbcFilter(p, isNotCbc, isCbc) &&
          recharterRosterScoutsLifeFilter(p, isNotScoutsLife, isScoutsLife) &&
          recharterRosterAgeFilter(p, adults, youth),
      );

    if (isEmpty(sorter) || sorter.columnKey === undefined) {
      return sortBy(filteredItems, [
        'memberType',
        p => p.personFullName.toLowerCase(),
        'positionLong',
      ]);
    }

    return applySort({
      items: filteredItems,
      sorters: unitRosterSorters,
      sorter,
    });
  },
);

export const recharterRosterSelectedKeysSel = state =>
  moduleSel(state).recharterRosterSelectedKeys;

export const selectedYouthMembersSel = createSelector(
  recharterRosterWithNewMembersSel,
  recharterRosterSelectedKeysSel,
  (roster, selectedKeys) =>
    roster
      .filter(
        p => selectedKeys.includes(p.batchApplicationPersonId) && !p.isAdult,
      )
      .sort((a, b) => a.personFullName.localeCompare(b.personFullName)),
);

export const selectedAdultMembersSel = createSelector(
  recharterRosterWithNewMembersSel,
  recharterRosterSelectedKeysSel,
  (roster, selectedKeys) =>
    roster
      .filter(
        p => selectedKeys.includes(p.batchApplicationPersonId) && p.isAdult,
      )
      .sort((a, b) => a.personFullName.localeCompare(b.personFullName)),
);

export const selectedRemovedYouthMembersSel = createSelector(
  removedMembersSel,
  removedMembersSelectedKeysSel,
  (roster, selectedKeys) =>
    roster
      .filter(
        p => selectedKeys.includes(p.batchApplicationPersonId) && !p.isAdult,
      )
      .sort((a, b) => a.personFullName.localeCompare(b.personFullName)),
);

export const selectedRemovedAdultMembersSel = createSelector(
  removedMembersSel,
  removedMembersSelectedKeysSel,
  (roster, selectedKeys) =>
    roster
      .filter(
        p => selectedKeys.includes(p.batchApplicationPersonId) && p.isAdult,
      )
      .sort((a, b) => a.personFullName.localeCompare(b.personFullName)),
);

export const isRemoveMembersModalVisible = state =>
  moduleSel(state).isRemoveMembersModalVisible;

export const isAddToRecharterModalVisible = state =>
  moduleSel(state).isAddToRecharterModalVisible;

export const selectedMembersSel = createSelector(
  recharterRosterWithNewMembersSel,
  recharterRosterSelectedKeysSel,
  (roster, selectedKeys) =>
    roster.filter(p => selectedKeys.includes(p.batchApplicationPersonId)),
);

export const selectedMultipleMembersSel = createSelector(
  selectedMembersSel,
  recharterRosterSel,
  (selectedMembers, recharterRoster) =>
    selectedMembers.filter(({ isMultiple, memberId }) => {
      const records = recharterRoster.filter(r => r.memberId === memberId);
      return isMultiple && records.length === 1;
    }),
);

export const futureCharterSel = state => moduleSel(state).futureCharter;
export const isFutureCharterLoadingSel = state =>
  moduleSel(state).futureCharterLoading;

export const uploadDocumentMembersSel = createSelector(
  filteredRecharterRosterSel,
  recharterRosterSelectedKeysSel,
  (roster, selectedKeys) =>
    roster
      .filter(
        ({ personId, isAdult, isParticipant }) =>
          selectedKeys.includes(personId) && (isAdult || isParticipant),
      )
      .slice(0, 5),
);

export const isMarkAsMultipleModalVisible = state =>
  moduleSel(state).isMarkAsMultipleModalVisible;
export const isUnmarkAsMultipleModalVisible = state =>
  moduleSel(state).isUnmarkAsMultipleModalVisible;

export const selectedCouncilSel = state => moduleSel(state).selectedCouncil;

export const councilsSel = state => moduleSel(state).councils;
export const isLoadingCouncilsSel = state => moduleSel(state).isLoadingCouncils;

export const selectedCouncilUnitTypeSel = state =>
  moduleSel(state).selectedCouncilUnitType;

export const selectedCouncilUnitSel = state =>
  moduleSel(state).selectedCouncilUnit;

export const councilUnitsSel = state => moduleSel(state).councilUnits;
export const isLoadingCouncilUnitsSel = state =>
  moduleSel(state).isLoadingCouncilUnits;

export const filteredCounciUnitsByTypeSel = createSelector(
  selectedCouncilUnitTypeSel,
  councilUnitsSel,
  unitInfoSel,
  (selectedCouncilUnitType, councilUnits, unitInfo) => {
    const isTraditionalUnit =
      unitInfo.type !== unitTypes.POST && unitInfo.type !== unitTypes.CLUB;

    const filteredCouncilUnits = councilUnits.filter(({ unitType }) => {
      if (isTraditionalUnit) {
        return unitType !== unitTypes.POST && unitType !== unitTypes.CLUB;
      }

      return unitType === unitTypes.POST || unitType === unitTypes.CLUB;
    });

    return selectedCouncilUnitType
      ? filteredCouncilUnits.filter(
          ({ unitType }) =>
            unitType.toLowerCase() ===
            unitTypeIdForUnitTypeName[selectedCouncilUnitType],
        )
      : filteredCouncilUnits;
  },
);

export const isLoadingMarkAsMultipleSel = state =>
  moduleSel(state).isLoadingMarkAsMultiple;
export const isLoadingUnmarkAsMultipleSel = state =>
  moduleSel(state).isLoadingUnmarkAsMultiple;

// This generates the paylaod for the API that generates the PDF
export const generateFileInfoSel = createSelector(
  recharterRosterWithNewMembersSel,
  packRosterItemsSel,
  futureCharterSel,
  (unitRoster, packRoster, { term, expiryDt, effectiveDt }) => ({
    persons: unitRoster
      .filter(p => !p.isInvited)
      .map(
        ({
          addressLine1,
          addressLine2,
          city,
          dob,
          email,
          emailAddress,
          firstName,
          fromInfo,
          gender,
          isBoysLife,
          isCbc,
          isMultiple,
          isNewMember,
          isAdvancePayReCharter,
          isRemoved,
          lastName,
          memberId,
          memberType,
          middleName,
          personFullName,
          phoneAreaCode,
          phoneLineNumber,
          phonePrefix,
          positionLong,
          stateCode,
          zip5,
        }) => ({
          addressLine1,
          addressLine2,
          city,
          dob,
          email: emailAddress ? emailAddress : email ? email : '',
          firstName,
          fromInfo,
          gender,
          isBoysLife,
          isCbc,
          isMultiple,
          isNewMember,
          isRemoved,
          isAdvancePayReCharter,
          lastName,
          memberId,
          memberType,
          middleName,
          personFullName,
          phone:
            phoneAreaCode && phonePrefix && phoneLineNumber
              ? `${phoneAreaCode}${phonePrefix}${phoneLineNumber}`
              : '',
          phoneAreaCode,
          phoneLineNumber,
          phonePrefix,
          positionLong,
          stateCode,
          zip5,
        }),
      ),
    futureCharter: { term, expiryDt, effectiveDt },
    agreementRecipients: getAgreementRecipients(unitRoster, packRoster),
  }),
);

export const isLoadingGenerateRecharterPDFSel = state =>
  moduleSel(state).isLoadingGenerateRecharterPDF;

export const isGeneratePDFFromPaymentSel = state =>
  moduleSel(state).isGeneratePDFFromPayment;

export const isLoadingCreateTransientDocumentWithAgreementSel = state =>
  moduleSel(state).isLoadingCreateTransientDocumentWithAgreement;

export const isAdobeFlowLoadingSel = createSelector(
  isLoadingGenerateRecharterPDFSel,
  isLoadingCreateTransientDocumentWithAgreementSel,
  (isGeneratePDFLoading, isCreateTransientDocumentWithAgreementLoading) =>
    isGeneratePDFLoading || isCreateTransientDocumentWithAgreementLoading,
);

export const isInviteMembersModalVisible = state =>
  moduleSel(state).isInviteMembersModalVisible;
export const isInviteMemberLoadingSel = state =>
  moduleSel(state).isInviteMemberLoading;

export const filteredIniviteMemberTypesSel = createSelector(
  unitInfoSel,
  ({ typeId }) =>
    typeId !== ProgramId.VENTURING && typeId !== ProgramId.SEA_SCOUT
      ? memberTypes.filter(({ id }) => id !== memberTypeIds.participant)
      : memberTypes,
);

export const filteredAddMemberTypesSel = createSelector(
  unitInfoSel,
  isNewMemberApplicationSel,
  ({ typeId }, newApplication) => {
    if (!newApplication) {
      return memberTypes.filter(({ id }) => id === memberTypeIds.adult);
    } else if (typeId === ProgramId.CUB_SCOUT || typeId === ProgramId.CLUB) {
      return memberTypes.filter(({ id }) => id !== memberTypeIds.participant);
    } else {
      return memberTypes;
    }
  },
);

export const isLoadingAdjustedOLRSettingsSel = state =>
  moduleSel(state).isLoadingAdjustedOLRSettings;

export const adjustedOLRSettingsSel = state =>
  moduleSel(state).adjustedOLRSettings;

const EMPTY_ARR = [];
export const key3CCPersonEmails = state => {
  const module = moduleSel(state);
  const orgAdults = module.orgAdults || EMPTY_ARR;
  const POSITIONS = new Set(ccEmailDelegatePositions);
  const isKey3Person = person =>
    person.position && POSITIONS.has(person.position);
  const key3Persons = orgAdults.filter(isKey3Person);
  const emailsSet = new Set(
    key3Persons
      .map(person => get(person, 'primaryEmailInfo.emailAddress'))
      .filter(email => email),
  );

  if (emailsSet.size === 0) return EMPTY_ARR;

  return [...emailsSet];
};

// See SBL-3748
export const hidePayAtCouncilSel = createSelector(
  unitInfoSel,
  ({ councilGuid }) => {
    if (!councilGuid) return false;

    if (councilGuid === COUNCIL_802_GUID) return true;

    return false;
  },
);
