import React from 'react';

import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { isMobileSel } from '@screen';
import { Modal, SingleConfirmationModal, intl } from '@shared';

import {
  ModalMode,
  initialFormStateSel,
  isFormChanged,
  selectedActivitySel,
} from '../../../common';
import { detailModes } from '../../constants';
import {
  closeLongCruiseModal,
  detailModeSel,
  modalModeSel,
  setDetailMode,
  visibleSel,
} from '../../duck';
import LongCruiseForm from '../LongCruiseForm';
import styles from './LongCruiseModal.less';

class LongCruiseModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.closeModalRef = React.createRef();
  }

  openConfirmationModal = () => {
    this.closeModalRef.current.openConfirmationModal();
  };

  handleSetGetFieldsValue = fn => {
    this.getFieldsValue = fn;
  };

  handleCloseConfirm = () => {
    const { modalMode, detailMode, onBack, onCancel, activity } = this.props;
    const savedValues = pick(activity, Object.keys(this.getFieldsValue()));
    if (detailMode === detailModes.SUGGEST) {
      onCancel();
    } else if (isFormChanged(savedValues, this.getFieldsValue())) {
      this.openConfirmationModal();
    } else {
      modalMode === ModalMode.EDIT ? onCancel() : onBack();
    }
  };

  handleBackConfirm = () => {
    this.openConfirmationModal();
  };

  render() {
    const { visible, modalMode, onCancel, isMobile, detailMode, onBack } =
      this.props;

    const title = intl.formatMessage({
      id: `longCruise.LongCruiseModal.${
        detailMode === detailModes.JOIN ? 'edit' : modalMode.toLowerCase()
      }.title`,
    });

    const toRoster = detailMode === detailModes.SUGGEST;
    const backTitle = (
      <FormattedMessage
        id={`progress.DetailModeButtons.${
          toRoster || modalMode === ModalMode.EDIT ? 'back' : 'backToProject'
        }`}
      />
    );

    return (
      <Modal
        mask={!isMobile}
        className={styles.modal}
        visible={visible}
        title={title}
        onCancel={this.handleCloseConfirm}
        backTitle={backTitle}
        onBack={
          toRoster || modalMode === ModalMode.EDIT
            ? this.handleCloseConfirm
            : this.handleBackConfirm
        }
      >
        <React.Fragment>
          <SingleConfirmationModal
            ref={this.closeModalRef}
            message={<FormattedMessage id="progress.common.closeConfirm" />}
            onConfirm={
              toRoster || modalMode === ModalMode.EDIT ? onCancel : onBack
            }
          />
          <LongCruiseForm
            modalMode={modalMode}
            isMobile={isMobile}
            setGetFieldsValue={this.handleSetGetFieldsValue}
          />
        </React.Fragment>
      </Modal>
    );
  }
}

LongCruiseModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  modalMode: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  detailMode: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  initialFormState: PropTypes.object,
  activity: PropTypes.object.isRequired,
};

const mapState = state => ({
  visible: visibleSel(state),
  modalMode: modalModeSel(state),
  isMobile: isMobileSel(state),
  detailMode: detailModeSel(state),
  initialFormState: initialFormStateSel(state),
  activity: selectedActivitySel(state),
});

const mapDispatch = dispatch => ({
  onCancel: () => dispatch(closeLongCruiseModal()),
  onBack: () => dispatch(setDetailMode(detailModes.SUGGEST)),
});

export default connect(mapState, mapDispatch)(LongCruiseModal);
