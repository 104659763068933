import React from 'react';
import PropTypes from 'prop-types';
import ReactAnimateOnChange from 'react-animate-on-change';

class AnimateOnChange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prevValue: undefined,
    };
  }

  // TODO: refactor before upgrading React since this is deprecated
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps() {
    this.setState({
      prevValue: this.props.value,
    });
  }

  render() {
    const { prevValue } = this.state;
    const { value, baseClassName, animationClassName, children } = this.props;
    const animate = prevValue !== value;

    return (
      <ReactAnimateOnChange
        baseClassName={baseClassName}
        animationClassName={animationClassName}
        animate={animate}
      >
        {children}
      </ReactAnimateOnChange>
    );
  }
}

AnimateOnChange.propTypes = {
  baseClassName: PropTypes.string,
  animationClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  children: PropTypes.node,
};

AnimateOnChange.defaultProps = {
  baseClassName: '',
};

export default AnimateOnChange;
