import { createAction } from '@reduxjs/toolkit';

import { gtm } from '@modules/shared/utils';
import { createModuleNameSpace } from '@modules/utils/misc';

import { modulePath } from '../constants';

const moduleNamespace = createModuleNameSpace(modulePath);

export const unstartAdvancementRequest = createAction(
  `${moduleNamespace}/UNSTART_ADVANCEMENT_REQUEST`,
  (payload: {
    userId: string;
    advancementType: string;
    advancementId: string;
    userAwardId?: string;
  }) => ({
    payload,
    gtm: {
      category: gtm.categories.ADVANCEMENTS,
    },
  }),
);
export const unstartAdvancementResponse = createAction(
  `${moduleNamespace}/UNSTART_ADVANCEMENT_RESPONSE`,
);
export const unstartAdvancementError = createAction<Error>(
  `${moduleNamespace}/UNSTART_ADVANCEMENT_ERROR`,
);
