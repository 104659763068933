import React from 'react';

import PropTypes from 'prop-types';

import { Button, Input } from '@shared/components';

import styles from './MasqueradeModal.less';

class MasqueradeModalContent extends React.PureComponent {
  state = {
    memberId: '',
  };

  handleInputChange = e => this.setState({ memberId: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    this.handleMasqueradeClick();
  };

  handleMasqueradeClick = () => {
    let { memberId } = this.state;
    let editWhileMasquerading = false;
    if ((memberId || '').toLowerCase().endsWith('_admin')) {
      editWhileMasquerading = true;
      memberId = memberId.replace(/\D/g, '');
    }
    this.props.onMasquerade(memberId, editWhileMasquerading);
  };

  render() {
    const { loading } = this.props;
    const { memberId } = this.state;

    return (
      <form className={styles.container} onSubmit={this.handleSubmit}>
        <img
          src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjAiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTAwIDEwMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGQ9Ik03Mi43MzcsMzguNjE2QzYxLjEwNSwzOC43MjYsNTAsNDUuNDUyLDUwLDQ1LjQ1MnMtMTEuMTA1LTYuNzI3LTIyLjczOC02LjgzN0MxNi4zNDQsMzguNTEyLDUsNDEuNTE5LDUsNDEuNTE5ICBzMC4zMTEsMTMuMTksMTQuNzk2LDE4LjIyQzM0LjQ5LDY0Ljg0LDQxLjgzNyw1Ni40NzMsNTAsNTYuNDczczE1LjUxLDguMzY3LDMwLjIwNCwzLjI2NUM5NC42ODksNTQuNzA5LDk1LDQxLjUxOSw5NSw0MS41MTkgIFM4My42NTYsMzguNTEyLDcyLjczNywzOC42MTZ6IE0yNy43OTcsNTQuNzQxYy02LjkxMS0wLjAzMi0xMC4yODUtNS45OTQtMTAuMjg1LTUuOTk0czMuNDYtMy43MTEsMTAuMjMxLTMuNjYyICBjNy40MDcsMC4wNTMsMTMuMzA2LDYuNDI5LDEzLjMwNiw2LjQyOUMzNS4zOTIsNTEuMjM1LDM0LjcwOCw1NC43NzMsMjcuNzk3LDU0Ljc0MXogTTgyLjQ4OCw0OC43NDdjMCwwLTMuMzc0LDUuOTYyLTEwLjI4NSw1Ljk5NCAgYy02LjkxLDAuMDMyLTcuNTk0LTMuNTA2LTEzLjI1Mi0zLjIyOGMwLDAsNS44OTktNi4zNzUsMTMuMzA2LTYuNDI5Qzc5LjAyOCw0NS4wMzYsODIuNDg4LDQ4Ljc0Nyw4Mi40ODgsNDguNzQ3eiI+PC9wYXRoPjwvc3ZnPg=="
          title="Credit: Bohdan Burmich, US"
          className={styles.image}
        />
        <Input
          placeholder="MemberID"
          className={styles.input}
          value={memberId}
          onChange={this.handleInputChange}
        />
        <Button
          color="scouting-blue"
          noBorder
          className={styles.button}
          loading={loading}
          onClick={this.handleMasqueradeClick}
        >
          Masquerade
        </Button>
      </form>
    );
  }
}

MasqueradeModalContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  onMasquerade: PropTypes.func.isRequired,
};

export default MasqueradeModalContent;
