import { IntlProvider } from 'react-intl';
import { advancementTypes } from '@shared/constants';

/** @type {import('react-intl').InjectedIntl} */
let intl = null;

const updateIntl = (locale, messages) => {
  intl = new IntlProvider({ locale, messages }).getChildContext().intl;
};

let translate = (id, values = {}) => intl && intl.formatMessage({ id }, values);

const getAdvancementTypeTranslationId = (type, plural, isCore) => {
  const newType =
    isCore && type == advancementTypes.AWARDS ? 'coreAwards' : type;
  return `shared.AdvancementType.${newType}${plural ? '.plural' : ''}`;
};

const getAdvancementTypeTranslation = (type, plural, isCore) =>
  intl.formatMessage({ id: getAdvancementTypeTranslationId(type, plural, isCore) });

export default translate;
export { updateIntl, intl, getAdvancementTypeTranslation, getAdvancementTypeTranslationId };
