import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Modal, Spin } from '@shared';
import { isMobileSel } from '@screen';
import {
  syncModalOpenSel,
  closeSyncModal,
  advancementVerificationLoadingSel,
  syncLoadingSel,
} from '../../duck';
import SyncPendingContent from './SyncPendingContent';

const SyncPendingModal = ({
  visible,
  isMobile,
  onCancel,
  isVerifying,
  isSyncLoading,
}) => {
  const handleCancel = () => {
    if (isSyncLoading) {
      return;
    }
    onCancel && onCancel();
  };

  return (
    <Modal
      mask={!isMobile}
      headerColor="scouting-warm-gray"
      visible={visible}
      title={<FormattedMessage id="offline.SyncPendingModal.title" />}
      onCancel={handleCancel}
      noPadding
      backTitle={<FormattedMessage id="offline.SyncPendingModal.backButton" />}
    >
      <Spin
        tip={
          <FormattedMessage
            id={`offline.SyncPendingModal.${
              isSyncLoading ? 'uploading' : 'validating'
            }`}
          />
        }
        spinning={isVerifying || isSyncLoading}
        delay={200}
        centeredInViewport={isMobile}
      >
        <SyncPendingContent />
      </Spin>
    </Modal>
  );
};

SyncPendingModal.propTypes = {
  // Provided by component itself
  visible: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVerifying: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSyncLoading: PropTypes.bool.isRequired,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
  visible: syncModalOpenSel(state),
  isVerifying: advancementVerificationLoadingSel(state),
  isSyncLoading: syncLoadingSel(state),
});

const mapDispatch = dispatch => ({
  onCancel: () => dispatch(closeSyncModal()),
});

export default connect(mapState, mapDispatch)(SyncPendingModal);
