import { createSelector } from 'reselect';

import { payloadSel } from '@location';
import { packRosterItemsSel, urlUserIdSel } from '@shared';
import { moduleName } from '../constants';

const moduleSel = state => state[moduleName];

export const activityTypeIdSel = state => payloadSel(state).activityType;

export const adultDetailsSel = createSelector(
  urlUserIdSel,
  packRosterItemsSel,
  (userId, rosterItems) => {
    const foundPerson = rosterItems.find(person => person.userId == userId);
    return foundPerson || {};
  },
);

export const isAdultWithinUnitSel = createSelector(
  urlUserIdSel,
  packRosterItemsSel,
  (userId, packRoster) => !!packRoster.find(item => item.userId == userId),
);

export const adultProfileSel = state => moduleSel(state).adultProfile;

export const adultBioSel = state =>
  adultProfileSel(state)
    ? adultProfileSel(state).extendedProfile.find(
        attr => attr.classification === 'MyBiography',
      )
    : '';

export const isLoadingAdultProfileSel = state =>
  moduleSel(state).isLoadingAdultProfile;

export const isSavingProfileSel = state => moduleSel(state).isSavingProfile;

export const setEditAdultDetailSidebarSel = state =>
  moduleSel(state).setEditAdultDetailSidebar;

export const adultProfilePictureSaveLoadingSel = state =>
  moduleSel(state).adultProfilePictureSaveLoading;
