import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import { unitGendersTypes } from '../../constants';
import { normalizeUnitGender } from '../../utils';
import styles from './UnitGenderPill.less';

const getLabel = (gender, splitFamilyLabel) => {
  const label =
    gender === unitGendersTypes.FAMILY && splitFamilyLabel
      ? unitGendersTypes.FAMILY_SPLIT
      : gender;
  return Object.values(unitGendersTypes).includes(label)
    ? label
    : normalizeUnitGender(gender);
};

const coloredPill = Object.freeze({
  [unitGendersTypes.BOYS]: styles.boysPill,
  [unitGendersTypes.GIRLS]: styles.girlsPill,
  [unitGendersTypes.FAMILY]: styles.familyPill,
  [unitGendersTypes.FAMILY_SPLIT]: styles.familyPill,
});

const UnitGenderPill = ({ colored, gender, splitFamilyLabel }) => {
  const genderLabel = getLabel(gender, splitFamilyLabel);

  return (
    <div
      className={cn(styles.container, styles[`gender-${genderLabel}`], {
        [styles.coloredPill]: colored,
        [coloredPill[genderLabel]]: colored,
      })}
    >
      {genderLabel}
    </div>
  );
};

UnitGenderPill.propTypes = {
  colored: PropTypes.bool,
  gender: PropTypes.string,
  splitFamilyLabel: PropTypes.bool,
};

export default UnitGenderPill;
