import merge from 'lodash/merge';

import { API_ENV, WEBSCRIPT_API_URLS } from '@config';
import createHttp from './httpFactory';
import errorHandler from './httpErrorHandler';
import getUrlForEnv from './getUrlForEnv';
import getTargetUrlHeader from './getTargetUrlHeader';
import jsonBodyTranformer from './jsonBodyTransformer';
import unwrapResponse from './unwrapResponse';
import businessErrorHandler from './webscriptBusinessErrorHandler';

const apiPrefix = '/vim2';

const urlTransformer = endpoint =>
  getUrlForEnv(
    apiPrefix + endpoint,
    WEBSCRIPT_API_URLS[API_ENV] + apiPrefix + endpoint,
  );

const optionsTransformer = ({ options, url: endpoint }) => {
  const extraOptions = {
    headers: {
      'Content-Type': 'application/json',
      ...getTargetUrlHeader(WEBSCRIPT_API_URLS[API_ENV] + apiPrefix + endpoint),
    },
    withCredentials: true,
  };

  return merge({}, options, extraOptions);
};
const responseHandler = (response, request, options) => {
  const unwrappedResponse = unwrapResponse(response);
  return businessErrorHandler(unwrappedResponse, response, request, options);
};

const http = createHttp({
  bodyTransformer: jsonBodyTranformer,
  urlTransformer,
  optionsTransformer,
  responseHandler,
  errorHandler,
});

export default http;
