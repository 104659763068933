import { activityTypeIds } from '@shared/constants';

export { youthProfileModuleName as moduleName } from '@shared/constants';

export const advancementsStates = Object.freeze({
  PREVIOUS: 'previous',
  LAST_APPROVED: 'lastApproved',
  NEXT: 'next',
});

export const youthProfileTabs = Object.freeze({
  PENDING: 'pending',
  STARTED: 'started',
  APPROVED: 'approved',
  INFORMATION: 'information',
});

export const formItemLayout = Object.freeze({
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
});

export const sortAdvancementTypes = Object.freeze({
  MERIT_BADGES: 'meritBadges',
  RANKS: 'ranks',
  AWARDS: 'awards',
  ADVENTURES: 'adventures',
});

export const advancementSorters = Object.freeze({
  ALPHA: 'alphabetical',
  ALPHA_DESC: 'alphabeticalDesc',
  DATE_EARNED: 'dateEarned',
  DATE_EARNED_DESC: 'dateEarnedDesc',
});

export enum advancementFilters {
  ALL = 'showAll',
  ALL_SSELECTIVES = 'allSSElectives',
  APPROVED = 'approved',
  STARTED = 'started',
  NOT_STARTED = 'notStarted',
  COMPLETED = 'completed',
}

export enum advRequirementOperations {
  APPROVE = 'approve',
  COMPLETE = 'complete',
  UNAPPROVE = 'unapprove',
}

export const activityTypeIdsToSummaryKey = Object.freeze({
  [activityTypeIds.SERVICE_PROJECTS]: 'serviceLogs',
  [activityTypeIds.CAMPOUTS]: 'campingLogs',
  [activityTypeIds.HIKES]: 'hikingLogs',
  [activityTypeIds.LONG_CRUISE]: 'longCruiseLogs',
});

export const summaryKeyToValueKey = Object.freeze({
  serviceLogs: 'totalNumberOfHours',
  campingLogs: 'totalNumberOfNights',
  hikingLogs: 'totalNumberOfMiles',
  longCruiseLogs: 'totalNumberOfDays',
});

// TODO: SBL-5228 Remove this on 9/1/2024
export const rankIdsVersion2024 = Object.freeze([100, 101, 102, 103, 104, 105]);

// TODO: SBL-5228 Remove this on 9/1/2024
export const adventureIdsToExpireIn2024 = Object.freeze([
  96, 103, 104, 105, 98, 47, 109, 106, 99, 73, 77, 110, 107, 84, 101, 111, 108,
  102,
]);
