import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

import { sidebarExpandedSel, isScreenAtLeastLgSel } from '../../duck';
import styles from './SmartAffix.less';

// container with permanent position: fixed that can also be aware of the sidebar and the header
const SmartAffix = ({
  fixed,
  header,
  sidebar,
  sidebarExpanded,
  isScreenAtLeastLg,
  top,
  bottom,
  children,
  className,
}) => {
  const classNames = cn(
    styles.container,
    {
      [styles.absolute]: !fixed,
      [styles.header]: header,
      [styles.sidebar]: sidebar && isScreenAtLeastLg && !sidebarExpanded,
      [styles.sidebarExpanded]: sidebar && isScreenAtLeastLg && sidebarExpanded,
    },
    className,
  );
  const style = {
    top: top != null ? top : undefined,
    bottom: bottom != null ? bottom : undefined,
  };

  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};

SmartAffix.propTypes = {
  fixed: PropTypes.bool, // position: static instead of position: fixed when false
  header: PropTypes.bool,
  sidebar: PropTypes.bool,
  sidebarExpanded: PropTypes.bool.isRequired,
  isScreenAtLeastLg: PropTypes.bool.isRequired,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SmartAffix.defaultProps = {
  fixed: true,
};

const mapState = state => ({
  sidebarExpanded: sidebarExpandedSel(state),
  isScreenAtLeastLg: isScreenAtLeastLgSel(state),
});

export default connect(mapState)(SmartAffix);
