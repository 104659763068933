import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import partition from 'lodash/partition';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { Card, S, syncOperations } from '@shared';
import styles from './PendingItemsContent.less';
import ActionMenu from './ActionMenu';
import PendingList from './PendingList';

class PendingItemsContent extends React.PureComponent {
  state = {
    selectedItemId: null,
    editItemId: null,
  };

  isItemAlreadyRecorded = itemId => {
    const { requests } = this.props;
    const { verification } = requests.find(({ id }) => itemId == id) || {};
    return verification ? !verification.isEligible : false;
  };

  handleSyncItem = itemId => () => {
    this.props.onSyncItem(itemId);
    this.handleCloseActionMenu();
  };

  handleCancelItem = itemId => () => {
    this.handleCloseActionMenu();
    this.props.onRemoveItem(itemId);
  };

  handleSelectItem = selectedItemId => {
    this.setState({ selectedItemId });
  };

  handleCloseActionMenu = () => {
    this.setState({ selectedItemId: null });
  };

  handleSetEdit = editItemId => () => {
    this.setState({ editItemId, selectedItemId: null });
  };

  handleEditItem = newDate => {
    const { editItemId } = this.state;

    this.props.onEditItem(editItemId, newDate);
    this.setState({ editItemId: null });
  };

  render() {
    const { selectedItemId, editItemId } = this.state;
    const { requests, isMobile, failedItemIds, selectedYouthId, isOffline } =
      this.props;

    const [approvalItems, modificationItems] = partition(
      requests,
      ({ operation }) =>
        operation == syncOperations.RECORD_ADVANCEMENT ||
        operation == syncOperations.APPROVE_ADVANCEMENTS,
    );

    const listConfig = {
      isMobile,
      failedItemIds,
      selectedItemId,
      editItemId,
      isOffline,
      onSyncItem: this.handleSyncItem,
      onCancelItem: this.handleCancelItem,
      onSelectItem: this.handleSelectItem,
      onChangeItem: this.handleEditItem,
      onSetEditItem: this.handleSetEdit,
    };

    const content = (
      <div>
        <PendingList
          className={styles.itemList}
          requests={approvalItems}
          title={
            <FormattedMessage id="offline.SyncPendingModal.approvalsList" />
          }
          {...listConfig}
        />
        <PendingList
          className={styles.itemList}
          requests={modificationItems}
          title={
            <FormattedMessage id="offline.SyncPendingModal.modificationsList" />
          }
          {...listConfig}
        />
      </div>
    );

    if (isMobile) {
      return (
        <React.Fragment>
          <ActionMenu
            alreadyRecorded={this.isItemAlreadyRecorded(selectedItemId)}
            isOpen={!!selectedItemId}
            onSync={this.handleSyncItem(selectedItemId)}
            onCancel={this.handleCancelItem(selectedItemId)}
            onEdit={this.handleSetEdit(selectedItemId)}
            onClose={this.handleCloseActionMenu}
            isOffline={isOffline}
          />
          <TransitionGroup>
            <CSSTransition
              key={selectedYouthId}
              timeout={200}
              classNames="slide"
            >
              <Card margin shadow className={styles.container}>
                <S size="4" colored hr>
                  <FormattedMessage id="offline.SyncPendingModal.title" />
                </S>
                {content}
              </Card>
            </CSSTransition>
          </TransitionGroup>
        </React.Fragment>
      );
    }
    return <div className={styles.container}>{content}</div>;
  }
}

PendingItemsContent.propTypes = {
  selectedYouthId: PropTypes.number.isRequired,
  isOffline: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  requests: PropTypes.array,
  failedItemIds: PropTypes.array,
  onSyncItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
};

export default PendingItemsContent;
