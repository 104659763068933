import { REPORTS_ALT_BASE_URL } from '@config';
import { CustomReports, reportCodes } from '@modules/reports/constants';

export function getCustomReportCode(report: CustomReports): string {
  return reportCodes[report];
}

export function getCustomReportUrl(reportCode: string) {
  const fallbackReportUrl = new URL(
    '/advancements/webscript/output/sbreports.aspx?method=executescriptset&scriptcode=ReportPresentation&ApplicationType=SBL',
    REPORTS_ALT_BASE_URL,
  );
  fallbackReportUrl.searchParams.set('scriptsetcode', reportCode);

  return fallbackReportUrl.toString();
}
