import React, { Suspense, lazy } from 'react';

import PagePlaceholder from '../PagePlaceholder';
import ChunkErrorBoundary from './ChunkErrorBoundary';

/**
 * HOC for even lazier loading - now with default Suspense and placeholder!
 * Also waits a moment before showing content that has been loaded for the first time,
 * because it needs to animate into place (this is mostly a CSS transition + Card thing).
 *
 * Lazy recreates the component on each route change (state won't be preserved),
 * that's why we're storing the state outside the component too.
 */
function lazier(importFn, Placeholder = PagePlaceholder) {
  let show = true;
  let loaded = false;

  return class Lazier extends React.PureComponent {
    state = {
      show,
      loaded,
    };

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    importComponent = async () => {
      if (loaded) {
        return importFn();
      }
      show = false;
      this.setState({ show });
      try {
        const component = await importFn();
        setTimeout(() => {
          show = true;
          loaded = true;
          if (this && this.mounted) {
            this.setState({ show, loaded });
          }
        }, 800);

        return component;
      } catch (e) {
        show = true;
        this.setState({ show });
        throw e;
      }
    };

    Lazy = lazy(this.importComponent);

    render() {
      const Lazy = this.Lazy;
      const { show } = this.state;
      const placeholder = !Placeholder ? '' : <Placeholder />;

      return (
        <ChunkErrorBoundary>
          <Suspense fallback={placeholder}>
            {!show && placeholder && (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    top: '0',
                    left: '0',
                  }}
                >
                  {placeholder}
                </div>
              </div>
            )}
            <div style={{ visibility: show ? 'visible' : 'hidden' }}>
              <Lazy {...this.props} />
            </div>
          </Suspense>
        </ChunkErrorBoundary>
      );
    }
  };
}

export default lazier;
