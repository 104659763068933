import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isSeaScoutSel } from '@context/duck/selectorsTyped';
import { Checkbox, S } from '@shared';

import { activityTypeFilters } from '../../../../constants';

const activityTypes = [
  activityTypeFilters.CAMPING,
  activityTypeFilters.SERVICE,
  activityTypeFilters.HIKING,
  activityTypeFilters.EAGLE_SERVICE_PROJECT,
];

const ActivityTypesFilterSection = ({ values, onChange }) => {
  const handleChange = useCallback(
    e => {
      const { name } = e.target;
      const newValues = {
        ...values,
        [name]: !values[name],
      };
      onChange(newValues);
    },
    [values, onChange],
  );

  const isSeaScout = useSelector(isSeaScoutSel);

  return []
    .concat(
      [...activityTypes],
      isSeaScout ? [activityTypeFilters.LONG_CRUISE] : [],
    )
    .map(activityTypeFilter => (
      <Checkbox
        key={activityTypeFilter}
        checked={values[activityTypeFilter]}
        name={activityTypeFilter}
        onChange={handleChange}
      >
        <S size="4" colored inline>
          <FormattedMessage
            id={`advancement.AdvancementHistory.filter.option.${activityTypeFilter}`}
          />
        </S>
      </Checkbox>
    ));
};

ActivityTypesFilterSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActivityTypesFilterSection;
