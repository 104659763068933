import React from 'react';
import PropTypes from 'prop-types';

import { S, Checkbox, AdvancementStatus } from '@shared';
import { advancementStatusFilters } from '../../../../constants';

const qa_ids = {
  [advancementStatusFilters.APPROVED]: 'qa_approved',
  [advancementStatusFilters.AWARDED]: 'qa_awarded',
  [advancementStatusFilters.COUNSELOR_APPROVED]: 'qa_mbc_approved',
};

const advancementFilters = [
  advancementStatusFilters.APPROVED,
  advancementStatusFilters.AWARDED,
  advancementStatusFilters.COUNSELOR_APPROVED,
];

class AdvancementStatusFilterSection extends React.PureComponent {
  handleChange = e => {
    const { name } = e.target;
    const { values, onChange } = this.props;
    const newValues = {
      ...values,
      [name]: !values[name],
    };

    onChange(newValues);
  };

  renderCheckbox = filterName => (
    <Checkbox
      key={qa_ids[filterName]}
      id={qa_ids[filterName]}
      checked={this.props.values[filterName]}
      name={filterName}
      onChange={this.handleChange}
    >
      <S size="4" colored inline>
        <AdvancementStatus status={filterName} />
      </S>
    </Checkbox>
  );

  render() {
    return (
      <div>
        {advancementFilters.map(filterName => this.renderCheckbox(filterName))}
      </div>
    );
  }
}

AdvancementStatusFilterSection.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdvancementStatusFilterSection;
