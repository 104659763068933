import { resetOnLogoutReducer } from '@utils';

import { parentGuardianSlice } from './slice';

const parentGuardianReducer = parentGuardianSlice.reducer;

const finalReducer = resetOnLogoutReducer(
  parentGuardianReducer,
) as typeof parentGuardianReducer;

export default finalReducer;
