import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { organizationGuidSel } from '@context';
import { ReduxLoader } from '@shared/components';

import { unitInfoLoadingSel, unitInfoRequest, unitInfoSel } from '../duck';

const UnitLoader = ({ organizationGuid, ...rest }) => {
  const loadAction = unitInfoRequest(organizationGuid);
  const dataSel = state => unitInfoSel(state);
  const loadedSel = state => Object.keys(unitInfoSel(state)).length > 0;
  const loadingSel = state => unitInfoLoadingSel(state);
  const duckling = {
    loadAction,
    loadedSel,
    loadingSel,
    dataSel,
  };

  return <ReduxLoader ducklings={duckling} {...rest} />;
};

UnitLoader.propTypes = {
  organizationGuid: PropTypes.string.isRequired,
};

const mapState = state => ({
  organizationGuid: organizationGuidSel(state),
});

export default connect(mapState)(UnitLoader);
