import PropTypes from 'prop-types';

export { campoutsModuleName as moduleName, detailModes } from '../common';

export const basicFields = Object.freeze({
  ADULT_DAYS: 'basicAdultDays',
  ADULT_NIGHTS: 'basicAdultNights',
  ADULT_FROST_POINTS: 'basicAdultFrostPoints',
  YOUTH_DAYS: 'basicYouthDays',
  YOUTH_NIGHTS: 'basicYouthNights',
  YOUTH_FROST_POINTS: 'basicYouthFrostPoints',
});

export const getDaysBetweenDates = (start, end) =>
  Math.trunc(end.diff(start, 'days'));

export const actions = Object.freeze({
  RECORD_AND_FINISH: 'RECORD_AND_FINISH',
  RECORD_AND_CONTINUE: 'RECORD_AND_CONTINUE',
});

export const datesShape = PropTypes.shape({
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
});

export const detailFieldNames = [
  'name',
  'startDate',
  'startTime',
  'endDate',
  'endTime',
  'location',
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'zip5',
  'description',
];

export const notApplicableCategoryId = 8; // add fixed categoryId since is required for create activity endpoint the Id corresponds to "not applicable"
