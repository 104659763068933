import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ContentPasteIcon from 'material-ui-icons/ContentPaste';
import TrackChangesIcon from 'material-ui-icons/TrackChanges';
import SyncIcon from '@material-ui/icons/Sync';

import {
  Fab,
  hasPermissionSel,
  isScoutbookRoleSel,
  permissions,
} from '@shared';
import { pendingRequestsCountSel, openSyncModal } from '@offline';
import { ReportFab } from '../../../../../common';
import ProgressFab from './ProgressFab';
import styles from './ActionFab.less';

const { Item, Divider } = Fab;

class ActionFab extends React.PureComponent {
  state = {
    progressFabOpen: false,
    reportFabOpen: false,
  };

  handleShowProgressFab = () => {
    this.setState({ progressFabOpen: true });
  };

  handleShowReportFab = () => {
    this.setState({ reportFabOpen: true });
  };

  handleProgressFabOpenChange = open => {
    this.setState({ progressFabOpen: open });
  };

  handleReportFabOpenChange = open => {
    this.setState({ reportFabOpen: open });
  };

  handleOpenSyncModal = () => {
    this.props.onOpenSyncModal();
  };

  renderRecordAdvancementOption = () => (
    <React.Fragment>
      <Item
        className="Joyride__RosterProgressButton"
        onClick={this.handleShowProgressFab}
      >
        <FormattedMessage id="packRoster.ActionFab.progress" />
      </Item>
      <Divider />
    </React.Fragment>
  );

  render() {
    const {
      selectedItems,
      isOffline,
      visible,
      pendingRequestsCount,
      isScoutbookRole,
      canRecordAdvancement,
    } = this.props;
    const { progressFabOpen, reportFabOpen } = this.state;
    const hasSelectedItems = selectedItems.length > 0;
    const hasPendingRequests = pendingRequestsCount > 0;
    const shouldShowMore = hasSelectedItems || hasPendingRequests;
    return (
      <React.Fragment>
        <ReportFab
          visible={visible && (reportFabOpen || !shouldShowMore)}
          open={reportFabOpen}
          onOpenChange={this.handleReportFabOpenChange}
          isOffline={isOffline}
          showAllUsers={false}
        />
        <ProgressFab
          isScoutbookRole={isScoutbookRole}
          canRecordAdvancement={canRecordAdvancement}
          visible={visible && progressFabOpen}
          open={progressFabOpen}
          selectedItems={selectedItems}
          onOpenChange={this.handleProgressFabOpenChange}
        />
        <Fab
          className="Joyride__RosterActionFab"
          visible={
            visible && shouldShowMore && !progressFabOpen && !reportFabOpen
          }
          content={
            <React.Fragment>
              {hasSelectedItems && this.renderRecordAdvancementOption()}
              {hasPendingRequests && (
                <React.Fragment>
                  <Item
                    id="qa_syncOffline"
                    onClick={this.handleOpenSyncModal}
                    icon={<SyncIcon />}
                  >
                    <FormattedMessage
                      id={
                        isOffline
                          ? 'packRoster.ActionFab.readyToUpload'
                          : 'packRoster.ActionFab.syncContent'
                      }
                      values={{ count: pendingRequestsCount }}
                    />
                  </Item>
                  <Divider />
                </React.Fragment>
              )}
              <Item
                className="Joyride__RosterReportButton"
                icon={<ContentPasteIcon />}
                onClick={this.handleShowReportFab}
                disabled={isOffline}
              >
                <FormattedMessage
                  id={`packRoster.ActionFab.${
                    hasSelectedItems ? 'reportSelected' : 'report'
                  }`}
                />
              </Item>
            </React.Fragment>
          }
        >
          <span className={styles.actionTitle}>
            <FormattedMessage id="packRoster.ActionFab.title" />
            <React.Fragment>
              <TrackChangesIcon className={styles.icon} />
              <ContentPasteIcon className={styles.icon} />
            </React.Fragment>
          </span>
        </Fab>
      </React.Fragment>
    );
  }
}

ActionFab.propTypes = {
  visible: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array.isRequired,
  isOffline: PropTypes.bool.isRequired,
  // Provided by Component itself
  pendingRequestsCount: PropTypes.number.isRequired,
  onOpenSyncModal: PropTypes.func.isRequired,
  isScoutbookRole: PropTypes.bool.isRequired,
  canRecordAdvancement: PropTypes.bool.isRequired,
};

const mapState = state => ({
  pendingRequestsCount: pendingRequestsCountSel(state),
  isScoutbookRole: isScoutbookRoleSel(state),
  canRecordAdvancement: hasPermissionSel(state, [
    permissions.APPROVE_ADVANCEMENTS,
  ]),
});

const mapDispatch = dispatch => ({
  onOpenSyncModal: () => dispatch(openSyncModal()),
});

export default connect(mapState, mapDispatch)(ActionFab);
