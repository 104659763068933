import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  FilterButton,
  FlexSpacer,
  PaginationTotal,
  TableMobile,
} from '@shared';

import { GroupSubUnitButton } from '../../../../../subUnits';
import PackRosterFilterCard from '../../PackRosterFilterCard';
import styles from './PackRosterListHeader.less';

const { StickyTitle } = TableMobile;

class PackRosterListHeader extends React.PureComponent {
  selectAll = () => {
    const { items, selectedRowKeys } = this.props;
    const validItems = items.filter(({ userId }) => !!userId);
    const keys = validItems.map(item => item.userId);
    const newKeys = new Set([...selectedRowKeys, ...keys]);
    const newKeysArray = [...newKeys];
    this.props.onSelectedRowKeysChange(newKeysArray);
  };

  selectNone = () => {
    const { items, selectedRowKeys } = this.props;
    const keys = items.map(item => item.userId);
    const newKeys = selectedRowKeys.filter(key => !keys.includes(key));

    this.props.onSelectedRowKeysChange(newKeys);
  };

  renderFilterButton = () => <FilterButton />;

  renderSelector = () => {
    const { selectedRowKeys } = this.props;
    const shouldSelectAll = selectedRowKeys.length === 0;
    const textId = shouldSelectAll
      ? 'advancement.PackRosterListHeader.selectAll'
      : 'advancement.PackRosterListHeader.selectNone';
    const action = shouldSelectAll ? this.selectAll : this.selectNone;

    return (
      <Button
        className={styles.selectButton}
        color="white"
        fitText
        ghost
        noBorder
        onClick={action}
      >
        <FormattedMessage id={textId} />
      </Button>
    );
  };

  render() {
    const { items, selectedRowKeys, searchPinned } = this.props;

    return (
      <StickyTitle key="header" clearSearch={searchPinned}>
        <div className={styles.menu}>
          <PaginationTotal
            total={items.length}
            selected={selectedRowKeys.length}
          />
          <FlexSpacer />
          {this.renderSelector()}
          <GroupSubUnitButton />
          <PackRosterFilterCard>{this.renderFilterButton}</PackRosterFilterCard>
        </div>
      </StickyTitle>
    );
  }
}

PackRosterListHeader.propTypes = {
  items: PropTypes.array.isRequired,
  searchPinned: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  onSelectedRowKeysChange: PropTypes.func.isRequired,
};

export default PackRosterListHeader;
