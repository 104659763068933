import React from 'react';

import ErrorIcon from '@material-ui/icons/Error';
import MoreIcon from '@material-ui/icons/MoreVert';
import WarningIcon from '@material-ui/icons/Warning';
import cn from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  Button,
  DatePicker,
  DateView,
  Dropdown,
  Hr,
  Img,
  Menu,
  S,
  Tooltip,
} from '@shared';

import styles from './PendingList.less';

const MenuItem = Menu.Item;
const MenuDivider = Menu.Divider;

class PendingListItem extends React.PureComponent {
  state = {
    isDatePickerOpen: this.props.isMobile,
  };

  canBeRecorded = () => {
    const { verification } = this.props;
    return verification ? verification.isEligible : true;
  };

  handleSelectItem = () => {
    if (!this.props.editMode) {
      this.props.onSelect(this.props.id);
    }
  };

  handleSyncItem = () => {
    this.props.onSync();
  };

  handelCancel = () => {
    this.props.onCancel();
  };

  handleSetEditMode = () => {
    this.props.onSetEdit();
  };

  handleChangeDate = date => {
    const dateISO = date ? date.toISOString() : this.props.dateCompleted;
    this.props.onChange(dateISO);
  };

  handleClosePicker = open => {
    if (!open) {
      this.props.onChange(this.props.dateCompleted);
    }
    this.setState({
      isDatePickerOpen: open,
    });
  };

  renderMenu = () => (
    <Menu className={styles.moreMenu}>
      {!this.props.isOffline && [
        <MenuItem className={styles.menuItem} key="sync">
          <Button
            className={styles.menuButton}
            onClick={this.handleSyncItem}
            fitText
            ghost
            noBorder
            noPadding
            uppercase={false}
            disabled={!this.canBeRecorded()}
          >
            <FormattedMessage id="offline.SyncPendingModal.item.syncNow" />
          </Button>
        </MenuItem>,
        <MenuDivider className={styles.menuDivider} key="div1" />,
      ]}
      <MenuItem className={styles.menuItem}>
        <Button
          className={styles.menuButton}
          onClick={this.handleSetEditMode}
          fitText
          ghost
          noBorder
          noPadding
          uppercase={false}
        >
          <FormattedMessage id="offline.SyncPendingModal.item.edit" />
        </Button>
      </MenuItem>
      <MenuDivider className={styles.menuDivider} />
      <MenuItem className={styles.menuItem}>
        <Button
          className={styles.menuButton}
          onClick={this.handelCancel}
          fitText
          ghost
          noBorder
          noPadding
          uppercase={false}
        >
          <FormattedMessage id="offline.SyncPendingModal.item.cancelItem" />
        </Button>
      </MenuItem>
    </Menu>
  );

  renderWarningTooltip = () => {
    if (this.canBeRecorded()) {
      return '';
    }
    return (
      <Tooltip
        title={
          <FormattedMessage id="offline.SyncPendingModal.item.verifyError" />
        }
      >
        <WarningIcon className={cn(styles.icon, styles.warning)} />
      </Tooltip>
    );
  };

  renderErrorTooltip = () => {
    if (this.props.hasError) {
      return (
        <Tooltip
          title={<FormattedMessage id="offline.SyncPendingModal.item.error" />}
        >
          <ErrorIcon className={cn(styles.icon, styles.error)} />
        </Tooltip>
      );
    }
    return '';
  };

  render() {
    const {
      advancementName,
      imageUrl,
      startDate,
      dateCompleted,
      isMobile,
      selected,
      editMode,
    } = this.props;

    const isDatePickerOpen = this.state.isDatePickerOpen || isMobile;

    const advancementImage = imageUrl ? (
      <Img src={imageUrl} className={styles.image} />
    ) : (
      <div className={cn(styles.image, styles.imagePlaceholder)} />
    );

    const advancementInfo = (
      <React.Fragment>
        {startDate && (
          <div className={styles.itemRow}>
            <S size="4" colored>
              <FormattedMessage id="offline.SyncPendingModal.item.startDate" />
            </S>
            <DateView value={startDate} />
          </div>
        )}
        <div className={cn(styles.itemRow, { [styles.editDate]: editMode })}>
          <S size="4" colored>
            <FormattedMessage id="offline.SyncPendingModal.item.completionDate" />
          </S>
          {editMode ? (
            <DatePicker
              defaultValue={moment(dateCompleted)}
              onChange={this.handleChangeDate}
              onOpenChange={this.handleClosePicker}
              disableDates="future"
              open={isDatePickerOpen}
              label={
                <FormattedMessage id="offline.SyncPendingModal.item.completionDate" />
              }
            />
          ) : (
            <DateView value={dateCompleted} />
          )}
        </div>
      </React.Fragment>
    );

    if (isMobile) {
      return (
        <div
          className={cn(styles.itemContainer, {
            [styles.selected]: selected,
          })}
          onClick={this.handleSelectItem}
        >
          {advancementImage}
          <div className={styles.advancementInfo}>
            <div className={styles.itemRow}>
              <S size="4" bold>
                {advancementName}
              </S>
              {this.renderWarningTooltip()}
              {this.renderErrorTooltip()}
            </div>
            {advancementInfo}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.itemContainer}>
        <div className={styles.itemRow}>
          <div className={styles.advancementTitleContainer}>
            {advancementImage}
            <S size="4" colored>
              {advancementName}
            </S>
          </div>
          <div className={styles.moreWrapper}>
            {this.renderWarningTooltip()}
            {this.renderErrorTooltip()}
            <Dropdown
              overlay={this.renderMenu()}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                fitText
                ghost
                noBorder
                noPadding
                icon={<MoreIcon />}
                className={styles.moreButton}
              />
            </Dropdown>
          </div>
        </div>
        {advancementInfo}
        <Hr className={styles.hr} />
      </div>
    );
  }
}

PendingListItem.propTypes = {
  advancementName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  startDate: PropTypes.string,
  dateCompleted: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  editMode: PropTypes.bool,
  verification: PropTypes.object,
  hasError: PropTypes.bool,
  isOffline: PropTypes.bool.isRequired,
  onSync: PropTypes.func,
  onCancel: PropTypes.func,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onSetEdit: PropTypes.func,
};

export default PendingListItem;
