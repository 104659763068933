import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';

import {
  CLEAR_PEOPLE,
  DESELECT_PERSON,
  ModalMode,
  SELECT_MULTIPLE_PERSONS,
  SELECT_PERSON,
  SET_SELECTED_ACTIVITY,
  activityValuesTabs,
} from '../../common';
import { detailModes } from '../constants';
import {
  CLOSE_SERVICE,
  OPEN_EDIT_SERVICE,
  OPEN_SERVICE_HOURS,
  OPEN_SERVICE_PROJECT,
  RECORD_SERVICE_ERROR,
  RECORD_SERVICE_REQUEST,
  RECORD_SERVICE_RESPONSE,
  SET_ACTIVE_TAB,
  SET_DETAIL_MODE,
  SET_SELECTED_PROJECT,
} from './actions';

const visible = (state = false, { type }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
    case OPEN_SERVICE_PROJECT:
    case OPEN_EDIT_SERVICE: {
      return true;
    }
    case CLOSE_SERVICE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const modalMode = (state = ModalMode.RECORD, { type }) => {
  switch (type) {
    case OPEN_SERVICE_PROJECT: {
      return ModalMode.CREATE;
    }
    case OPEN_SERVICE_HOURS: {
      return ModalMode.RECORD;
    }
    case OPEN_EDIT_SERVICE: {
      return ModalMode.EDIT;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case RECORD_SERVICE_REQUEST: {
      return true;
    }
    case RECORD_SERVICE_RESPONSE:
    case RECORD_SERVICE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const userIds = (state = [], { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS: {
      return payload.userIds || [];
    }
    case CLEAR_PEOPLE:
    case OPEN_SERVICE_PROJECT:
    case OPEN_EDIT_SERVICE: {
      return [];
    }
    case DESELECT_PERSON: {
      return state.filter(userId => userId != payload);
    }
    case SELECT_PERSON:
      return [...new Set([...state, payload])];
    case SELECT_MULTIPLE_PERSONS:
      return [...new Set([...state, ...payload])];
    default: {
      return state;
    }
  }
};

const dates = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
    case OPEN_EDIT_SERVICE: {
      return {};
    }
    case OPEN_SERVICE_PROJECT: {
      return payload.dates;
    }
    default: {
      return state;
    }
  }
};

const selectedProject = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
    case OPEN_SERVICE_PROJECT: {
      return null;
    }
    case SET_SELECTED_PROJECT: {
      return payload;
    }
    case SET_DETAIL_MODE: {
      if (payload === detailModes.SUGGEST) {
        return null;
      }
      return state;
    }
    default: {
      return state;
    }
  }
};

const activeTab = (state = activityValuesTabs.BASIC, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
    case OPEN_SERVICE_PROJECT: {
      return activityValuesTabs.BASIC;
    }
    case SET_ACTIVE_TAB: {
      return payload;
    }
    case SET_SELECTED_ACTIVITY: {
      const { areSameAdvancedValues = true } = payload || {};
      return areSameAdvancedValues
        ? activityValuesTabs.BASIC
        : activityValuesTabs.ADVANCED;
    }
    default: {
      return state;
    }
  }
};

const allowRemovePersons = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
    case OPEN_SERVICE_PROJECT:
      return payload.allowRemovePersons;
    case OPEN_EDIT_SERVICE:
      return true;
    default:
      return state;
  }
};

const disableGetActivities = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
      return payload.disableGetActivities;
    case OPEN_SERVICE_PROJECT:
    case OPEN_EDIT_SERVICE:
      return false;
    default:
      return state;
  }
};

const disableFutureDays = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
      return payload.disableFutureDays;
    case OPEN_SERVICE_PROJECT:
    case OPEN_EDIT_SERVICE:
      return false;
    default:
      return state;
  }
};

const detailMode = (state = detailModes.SUGGEST, { type, payload }) => {
  switch (type) {
    case OPEN_SERVICE_HOURS:
    case OPEN_SERVICE_PROJECT: {
      const { dates: { start } = {} } = payload;
      if (start) {
        return detailModes.CREATE;
      }
      return detailModes.SUGGEST;
    }
    case OPEN_EDIT_SERVICE: {
      return detailModes.EDIT;
    }
    case SET_DETAIL_MODE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const allowEditPersons = (state = false, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_SERVICE:
    case OPEN_SERVICE_PROJECT: {
      const { allowEditOrAddPersonsPersons = false } = payload || {};
      return allowEditOrAddPersonsPersons;
    }
    case OPEN_SERVICE_HOURS: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  visible,
  modalMode,
  loading,
  userIds,
  dates,
  selectedProject,
  activeTab,
  detailMode,
  allowEditPersons,
  disableFutureDays,
  disableGetActivities,
  allowRemovePersons,
});

export default resetOnLogoutReducer(reducer);
