import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { UNIT_INFO_RESPONSE } from '@unit';
import {
  ENABLE_FORUM_REQUEST,
  ENABLE_FORUM_RESPONSE,
  ENABLE_FORUM_ERROR,
  DISABLE_FORUM_REQUEST,
  DISABLE_FORUM_RESPONSE,
  DISABLE_FORUM_ERROR,
  SYNC_FORUM_REQUEST,
  SYNC_FORUM_RESPONSE,
  SYNC_FORUM_ERROR,
  GET_TIMEZONES_REQUEST,
  GET_TIMEZONES_RESPONSE,
  GET_TIMEZONES_ERROR,
  GET_UNIT_DETAILS_REQUEST,
  GET_UNIT_DETAILS_RESPONSE,
  GET_UNIT_DETAILS_ERROR,
  SET_TIMEZONE_REQUEST,
  SET_TIMEZONE_RESPONSE,
  SET_TIMEZONE_ERROR,
} from './actions';

const enableForumLoading = (state = false, { type }) => {
  switch (type) {
    case ENABLE_FORUM_REQUEST:
    case DISABLE_FORUM_REQUEST: {
      return true;
    }
    case ENABLE_FORUM_RESPONSE:
    case DISABLE_FORUM_RESPONSE:
    case ENABLE_FORUM_ERROR:
    case DISABLE_FORUM_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const forumEnabled = (state = false, { type, payload }) => {
  switch (type) {
    case ENABLE_FORUM_RESPONSE: {
      return true;
    }
    case UNIT_INFO_RESPONSE: {
      const { unitForums = [] } = payload;
      return unitForums.length > 0 && !!unitForums[0].discourseCategorySlug;
    }
    case DISABLE_FORUM_RESPONSE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const forumCategorySlug = (state = '', { type, payload }) => {
  switch (type) {
    case ENABLE_FORUM_RESPONSE: {
      return payload.categorySlug;
    }
    case UNIT_INFO_RESPONSE: {
      const { unitForums = [] } = payload;
      return unitForums.length > 0 ? unitForums[0].discourseCategorySlug : '';
    }
    case DISABLE_FORUM_RESPONSE: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const syncForumLoading = (state = false, { type }) => {
  switch (type) {
    case SYNC_FORUM_REQUEST: {
      return true;
    }
    case SYNC_FORUM_RESPONSE:
    case SYNC_FORUM_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const getTimezones = (state = [], { type, payload }) => {
  switch (type) {
    case GET_TIMEZONES_RESPONSE:
      return payload;
    case GET_TIMEZONES_REQUEST:
    case GET_TIMEZONES_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const getUnitDetails = (state = [], { type, payload }) => {
  switch (type) {
    case GET_UNIT_DETAILS_RESPONSE:
      return payload;
    case GET_UNIT_DETAILS_REQUEST:
    case GET_UNIT_DETAILS_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const isSettingTimezone = (state = false, { type }) => {
  switch (type) {
    case SET_TIMEZONE_REQUEST: {
      return true;
    }
    case SET_TIMEZONE_RESPONSE:
    case SET_TIMEZONE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingSetTimezone = (state = false, { type }) => {
  switch (type) {
    case SET_TIMEZONE_REQUEST: {
      return true;
    }
    case SET_TIMEZONE_RESPONSE:
    case SET_TIMEZONE_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingGetTimezone = (state = false, { type }) => {
  switch (type) {
    case GET_TIMEZONES_REQUEST: {
      return true;
    }
    case GET_TIMEZONES_RESPONSE:
    case GET_TIMEZONES_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingGetUnitDetails = (state = false, { type }) => {
  switch (type) {
    case GET_UNIT_DETAILS_REQUEST: {
      return true;
    }
    case GET_UNIT_DETAILS_RESPONSE:
    case GET_UNIT_DETAILS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  enableForumLoading,
  forumEnabled,
  forumCategorySlug,
  syncForumLoading,
  getTimezones,
  getUnitDetails,
  isSettingTimezone,
  isLoadingGetUnitDetails,
  isLoadingGetTimezone,
  isLoadingSetTimezone,
});

export default resetOnLogoutReducer(reducer);
