import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, intl } from '@shared';

import { groupByYouthsAdults, isFormChanged } from '../../utils';
import { setInitialFormState, initialFormStateSel } from '../../duck';
import { personsShape, activityValuesTabs } from '../../constants';
import BasicTab from './BasicTab';
import AdvancedTab from './AdvancedTab';

const { TabPane } = Tabs;

const ActivityTabs = ({
  form,
  persons,
  activeTab,
  onTabChange,
  activityTypeId,
  showTabDescription,
}) => {
  const dispatch = useDispatch();
  const initialFormState = useSelector(initialFormStateSel);
  const [initialAdvancedValues, setInitialAdvancedValues] = useState({});

  const handleTabChange = useCallback(
    tab => {
      onTabChange(tab);
    },
    [onTabChange],
  );

  const { youths, adults } = useMemo(
    () => groupByYouthsAdults(persons),
    [persons],
  );

  const setInitialTabValues = values => {
    setInitialAdvancedValues({ ...initialAdvancedValues, ...values });
  };

  useEffect(() => {
    const newInitial = { ...initialFormState, ...initialAdvancedValues };
    if (isFormChanged(initialFormState, newInitial)) {
      dispatch(setInitialFormState(newInitial));
    }
  }, [initialFormState, initialAdvancedValues, dispatch]);

  return (
    <Tabs size="small" activeKey={activeTab} onChange={handleTabChange}>
      <TabPane
        tab={intl.formatMessage({ id: 'progress.common.ActivityTabs.basic' })}
        key="basic"
      >
        <BasicTab
          form={form}
          showDescription={showTabDescription}
          activityTypeId={activityTypeId}
          isActive={activeTab === activityValuesTabs.BASIC}
          showYouth={youths.length > 0}
          showAdults={adults.length > 0}
          youth={youths}
          adults={adults}
          setInitialTabValues={setInitialTabValues}
        />
      </TabPane>
      <TabPane
        tab={intl.formatMessage({
          id: 'progress.common.ActivityTabs.advanced',
        })}
        key="advanced"
      >
        <AdvancedTab
          form={form}
          showDescription={showTabDescription}
          activityTypeId={activityTypeId}
          isActive={activeTab === activityValuesTabs.ADVANCED}
          youth={youths}
          adults={adults}
          setInitialTabValues={setInitialTabValues}
        />
      </TabPane>
    </Tabs>
  );
};

ActivityTabs.propTypes = {
  form: PropTypes.object.isRequired,
  persons: personsShape,
  activeTab: PropTypes.oneOf(['basic', 'advanced']).isRequired,
  onTabChange: PropTypes.func.isRequired,
  activityTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  showTabDescription: PropTypes.bool.isRequired,
};

export default ActivityTabs;
