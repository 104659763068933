import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import {
  closeConfirmationPaymentModal,
  isConfirmationPaymentModalVisibleSel,
} from '../../../duck';
import ConfirmationPayment from './ConfirmationPayment';
import styles from './ConfirmationPaymentModal.less';

const ConfirmationPaymentModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isConfirmationPaymentModalVisibleSel);

  const handleOnCancel = () => {
    dispatch(closeConfirmationPaymentModal());
  };

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.confirmationPaymentModal.title',
      })}
      onCancel={handleOnCancel}
    >
      <ConfirmationPayment onCancel={handleOnCancel} />
    </Modal>
  );
};

export default ConfirmationPaymentModal;
