const localeStorageKey = 'LOCALE';

const getLocaleData = async locale => {
  const response = await fetch(`/assets/translations/${locale}.json`);
  return await response?.json();
};

const storeLocale = locale => localStorage.setItem(localeStorageKey, locale);

const retrieveLocale = () => localStorage.getItem(localeStorageKey);

export default {
  getLocaleData,
  storeLocale,
  retrieveLocale,
};
