import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import styles from './ActivityDateHeader.less';

const ActivityDateHeader = ({
  date,
  children,
  className,
  appendChildBefore,
  ...props
}) => {
  const activityDate = useMemo(
    () => (date instanceof moment ? date : moment(date)),
    [date],
  );
  return (
    <h1 className={cn(styles.h1, className)} {...props}>
      {appendChildBefore && children}
      {activityDate.format('MMM DD').toUpperCase()}
      <span className={styles.year}>&nbsp;{activityDate.format('Y')}</span>
      {!appendChildBefore && children}
    </h1>
  );
};

ActivityDateHeader.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  appendChildBefore: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ActivityDateHeader;
