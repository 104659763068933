import React from 'react';
import { useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import SingleReportDeskop from './SingleReportDesktop';
import SingleReportMobile from './SingleReportMobile';

const SingleReport = props => {
  const isMobile = useSelector(isMobileSel);

  const ReportComponent = isMobile ? SingleReportMobile : SingleReportDeskop;
  return <ReportComponent {...props} />;
};

export default SingleReport;
