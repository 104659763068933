export { permissionsManagerModuleName as moduleName } from '@shared';

export const sortTags = Object.freeze({
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
});

export const sortTranslations = Object.freeze({
  [sortTags.FIRST_NAME]: 'firstName',
  [sortTags.LAST_NAME]: 'lastName',
});
