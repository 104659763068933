import { createSelector } from 'reselect';

import { unitTypeIdSel } from '@context';
import { emptyArr } from '@shared/constants';
import sortBy from 'lodash/sortBy';
import {
  ranksSel,
  isMeritBadgeCounselorSel,
} from '@shared/duck/selectors/general.selectors';

import {
  moduleName,
  unitsAvailableAdvancements,
  allowedAdvancementsMeritBadgeCounselor,
} from '../constants';

const moduleSel = state => state[moduleName];

export const unitInfoSel = state => moduleSel(state).unitInfo;

export const unitRelatedRanksSel = createSelector(
  ranksSel,
  unitTypeIdSel,
  (ranks, unitTypeId) => {
    const relatedRanks = ranks.filter(
      ({ programId }) => unitTypeId == programId,
    );
    return sortBy(relatedRanks, 'level');
  },
);

export const currentUnitGuidSel = createSelector(
  unitInfoSel,
  state => state.organizationGuid,
);

const advancementsTypesSel = state =>
  unitsAvailableAdvancements[unitTypeIdSel(state)] || emptyArr;

export const eligibleAdvancementTypesSel = createSelector(
  advancementsTypesSel,
  isMeritBadgeCounselorSel,
  (eligibileAdvancements, isMeritBadgeCounselor) => {
    if (isMeritBadgeCounselor) {
      return allowedAdvancementsMeritBadgeCounselor;
    }
    return eligibileAdvancements;
  },
);

export const unitInfoLoadingSel = state => moduleSel(state).unitInfoLoading;
export const unitInfoErrorSel = state => moduleSel(state).unitInfoError;
