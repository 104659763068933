import { combineReducers } from 'redux';

import { resetOnLogoutReducer, resetOnActionsReducer } from '@utils';
import {
  UNAPPROVE_ADVANCEMENT_RESPONSE,
  UNAPPROVE_ADVANCEMENT_DEFERRED,
} from '../../unapproveAdvancement';
import {
  OPEN_EDIT_ADVANCEMENT,
  CLOSE_EDIT_ADVANCEMENT,
  UPDATE_ADVANCEMENT_REQUEST,
  UPDATE_ADVANCEMENT_RESPONSE,
  UPDATE_ADVANCEMENT_ERROR,
  UPDATE_ADVANCEMENT_DEFERRED,
  UPDATE_OFFLINE_ADVANCEMENT,
  UPDATE_ACTIVITY_RESPONSE,
  SET_FROM_PROFILE,
} from './actions';

const visible = (state = false, { type }) => {
  switch (type) {
    case OPEN_EDIT_ADVANCEMENT: {
      return true;
    }
    case CLOSE_EDIT_ADVANCEMENT:
    case UNAPPROVE_ADVANCEMENT_RESPONSE:
    case UNAPPROVE_ADVANCEMENT_DEFERRED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case UPDATE_ADVANCEMENT_REQUEST: {
      return true;
    }
    case UPDATE_ADVANCEMENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const updateError = (state = null, { type }) => {
  switch (type) {
    case UPDATE_ADVANCEMENT_REQUEST: {
      return null;
    }
    case UPDATE_ADVANCEMENT_ERROR: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const advancement = (state = null, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_ADVANCEMENT: {
      const {
        person: { userId } = {},
        advancement: { id, awardId, advancementId } = {},
      } = payload;
      return {
        ...payload.advancement,
        id: id || advancementId || awardId,
        userId,
      };
    }
    default: {
      return state;
    }
  }
};

const person = (state = {}, { type, payload }) => {
  switch (type) {
    case OPEN_EDIT_ADVANCEMENT: {
      const { name, userId } = payload.person;
      return { personShortFullName: name, userId };
    }
    default: {
      return state;
    }
  }
};

const fromProfile = (state = false, { payload, type }) => {
  switch (type) {
    case SET_FROM_PROFILE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  visible,
  loading,
  updateError,
  advancement,
  person,
  fromProfile,
});

export default resetOnActionsReducer(
  resetOnLogoutReducer(reducer),
  UPDATE_ADVANCEMENT_RESPONSE,
  UPDATE_ADVANCEMENT_DEFERRED,
  UPDATE_OFFLINE_ADVANCEMENT,
  UPDATE_ACTIVITY_RESPONSE,
);
