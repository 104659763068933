import React from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Button, Col, Row, S, T } from '@shared/components';

import styles from './Payment.less';

const Payment = ({ isLoading, onContinue }) => (
  <React.Fragment>
    <Row type="flex" justify="center">
      <Col>
        <CheckCircleRoundedIcon className={styles.icon} />
      </Col>
      <Col>
        <T size="3" className={styles.text} bold>
          <FormattedMessage id="recharterModals.paymentModal.title" />
        </T>
        <S size="4" className={styles.text}>
          <FormattedMessage id="recharterModals.paymentModal.message" />
        </S>
      </Col>
    </Row>
    <Row type="flex" justify="center">
      <Col>
        <Button
          loading={isLoading}
          className={styles.button}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={false}
          onClick={onContinue}
        >
          <FormattedMessage id="recharterModals.paymentModal.ok" />
        </Button>
      </Col>
    </Row>
  </React.Fragment>
);

Payment.propTypes = {
  isLoading: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
};

export default Payment;
