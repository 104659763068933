import uniqBy from 'lodash/uniqBy';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';

import { ENV } from '@config';
import { esbApiService, serviceWorkerAvailable } from '@utils';

import { dictionaryIds } from '../constants';

const loadAdvancementImages = (advancements = []) =>
  advancements.forEach(({ imageUrl100 }) => {
    if (imageUrl100) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = imageUrl100;
    }
  });

const cacheAdvancementImages = advancements => {
  if (serviceWorkerAvailable() || ENV === 'local') {
    loadAdvancementImages(advancements);
  }
};

const fetchAwards$ = () =>
  esbApiService
    .get$('/advancements/awards', {
      gtm: {
        label: '/advancements/awards',
      },
    })
    .map(res => {
      res.awards = res.awards.filter(({ adminNotes }) => {
        adminNotes = (adminNotes || '').toLowerCase();
        return adminNotes !== 'sblhide' && !adminNotes.includes('council');
      });

      cacheAdvancementImages(res.awards);

      return res;
    });

const filterCouncilAdvancements = ({ adminNotes }) =>
  !(adminNotes || '').includes('council');

const fetchAdventures$ = () =>
  esbApiService
    .get$('/advancements/adventures', {
      gtm: {
        label: '/advancements/adventures',
      },
    })
    .map(response => {
      // remove duplicates and leave only the newest entries
      const newAdventures = response.adventures.reverse();
      response.adventures = uniqBy(newAdventures, 'id')
        .filter(filterCouncilAdvancements)
        .reverse();
      cacheAdvancementImages(response.adventures);
      return response;
    });

const fetchRanks$ = () =>
  esbApiService
    .get$('/advancements/ranks?status=active', {
      gtm: {
        label: '/advancements/ranks',
      },
    })
    .map(response => {
      // remove duplicates and leave only the newest entries
      const newRanks = response.ranks.reverse();
      response.ranks = uniqBy(newRanks, 'id')
        .filter(filterCouncilAdvancements)
        .reverse();
      cacheAdvancementImages(response.ranks);
      return response;
    });

/**
 * @esbEndpoint GET /advancements/ssElectives
 */
const fetchSSElectives$ = () =>
  esbApiService
    .get$('/advancements/ssElectives', {
      gtm: {
        label: '/advancements/ssElectives',
      },
    })
    .map(response => {
      const formattedResponse = { ssElectives: response };
      cacheAdvancementImages(formattedResponse.ssElectives);
      return formattedResponse;
    });

const fetchMeritBadges$ = () =>
  esbApiService
    .get$('/advancements/meritBadges', {
      gtm: {
        label: '/advancements/meritBadges',
      },
    })
    .map(response => {
      response.meritBadges = response.meritBadges
        .filter(filterCouncilAdvancements)
        .map(meritBadge => ({
          ...meritBadge,
          isEagleRequired: meritBadge.eagleRequired == 'True',
        }));
      cacheAdvancementImages(response.meritBadges);
      return response;
    });

const fetchStates$ = () =>
  esbApiService
    .get$('/lookups/address/states', {
      gtm: {
        label: '/lookups/address/states',
      },
    })
    .map(response => ({ states: response }));

const fetchCountries$ = () =>
  esbApiService
    .get$('/lookups/address/countries', {
      gtm: {
        label: '/lookups/address/countries',
      },
    })
    .map(response => ({ countries: response }));

const fetchPhoneCountries$ = () =>
  esbApiService
    .get$('/lookups/communications/phoneCountryCodes', {
      gtm: {
        label: '/lookups/communications/phoneCountryCodes',
      },
    })
    .map(response => ({ [dictionaryIds.PHONE_COUNTRIES]: response }));

const fetchComunicationTypes$ = () =>
  esbApiService
    .get$('/lookups/communications/communicationTypes', {
      gtm: {
        label: '/lookups/communications/communicationTypes',
      },
    })
    .map(response => ({ [dictionaryIds.COMUNICATION_TYPES]: response }));

const fetchPhoneCarriers$ = () =>
  esbApiService
    .get$('/lookups/communications/mobilePhoneCarrier', {
      gtm: {
        label: '/lookups/communications/mobilePhoneCarrier',
      },
    })
    .map(response => ({ [dictionaryIds.PHONE_CARRIERS]: response }));

const fetchPrefixes$ = () =>
  esbApiService
    .get$('/lookups/person/titlePrefixes', {
      gtm: {
        label: '/lookups/person/titlePrefixes',
      },
    })
    .map(response => ({ [dictionaryIds.PREFIXES]: response }));

const fetchSuffixes$ = () =>
  esbApiService
    .get$('/lookups/person/nameSuffixes', {
      gtm: {
        label: '/lookups/person/nameSuffixes',
      },
    })
    .map(response => ({ [dictionaryIds.SUFFIXES]: response }));

const fetchSchools$ = (name = '') =>
  esbApiService
    .get$(`/lookups/person/schools${name ? `?name=${name}` : ''}`, {
      gtm: {
        label: '/lookups/person/schools',
      },
    })
    .map(response => ({ [dictionaryIds.SCHOOLS]: response }));

const fetchGrades$ = () =>
  esbApiService
    .get$('/lookups/person/grades', {
      gtm: {
        label: '/lookups/person/grades',
      },
    })
    .map(response => ({ [dictionaryIds.GRADES]: response }));

const fetchSwimmingClassifications$ = () =>
  esbApiService
    .get$('/lookups/advancements/swimmingClassification', {
      gtm: {
        label: '/lookups/advancements/swimmingClassification',
      },
    })
    .map(response => ({ [dictionaryIds.SWIMMIN_CLASSIFICATION]: response }));

export default {
  fetchAwards$,
  fetchAdventures$,
  fetchRanks$,
  fetchSSElectives$,
  fetchMeritBadges$,
  fetchStates$,
  fetchCountries$,
  fetchPhoneCountries$,
  fetchComunicationTypes$,
  fetchPhoneCarriers$,
  fetchPrefixes$,
  fetchSuffixes$,
  fetchSchools$,
  fetchGrades$,
  fetchSwimmingClassifications$,
};
