import { gtm } from '@shared';

import { moduleName } from '../constants';

export { ROUTE_ADULT_PROFILE, ROUTE_ADULT_ACTIVITY_LOGS } from '@shared';

export const ADULT_DETAILS_REQUEST = `${moduleName}/ADULT_DETAILS_REQUEST`;
export const adultDetailsRequest = () => ({
  type: ADULT_DETAILS_REQUEST,
});
export const ADULT_DETAILS_RESPONSE = `${moduleName}/ADULT_DETAILS_RESPONSE`;
export const adultDetailsResponse = res => ({
  type: ADULT_DETAILS_RESPONSE,
  payload: res,
});
export const ADULT_DETAILS_ERROR = `${moduleName}/ADULT_DETAILS_ERROR`;
export const adultDetailsError = err => ({
  type: ADULT_DETAILS_ERROR,
  payload: err,
});

export const SAVE_ADULT_PROFILE_REQUEST = `${moduleName}/SAVE_ADULT_PROFILE_REQUEST`;
export const saveAdultProfileRequest = profile => ({
  type: SAVE_ADULT_PROFILE_REQUEST,
  payload: profile,
  gtm: {
    category: gtm.categories.ADULT_PROFILE,
  },
});

export const SAVE_ADULT_PROFILE_RESPONSE = `${moduleName}/SAVE_ADULT_PROFILE_RESPONSE`;
export const saveAdultProfileResponse = () => ({
  type: SAVE_ADULT_PROFILE_RESPONSE,
});

export const SAVE_ADULT_PROFILE_ERROR = `${moduleName}/SAVE_ADULT_PROFILE_ERROR`;
export const saveAdultProfileError = err => ({
  type: SAVE_ADULT_PROFILE_ERROR,
  payload: err,
});

export const DELETE_AKELA_EMAIL_REQUEST = `${moduleName}/DELETE_AKELA_EMAI_REQUEST`;
export const DELETE_AKELA_EMAI_RESPONSE = `${moduleName}/DELETE_AKELA_EMAI_RESPONSE`;
export const DELETE_AKELA_EMAI_ERROR = `${moduleName}/DELETE_AKELA_EMAI_ERROR`;
export const deleteAkelaEmailRequest = data => ({
  type: DELETE_AKELA_EMAIL_REQUEST,
  payload: data,
});
export const deleteAkelaEmailResponse = () => ({
  type: DELETE_AKELA_EMAI_RESPONSE,
});
export const deleteAkelaEmailError = err => ({
  type: DELETE_AKELA_EMAI_ERROR,
  payload: err,
});

export const SET_EDIT_ADULT_DETAIL_SIDEBAR = `${moduleName}/SET_EDIT_ADULT_DETAIL_SIDEBAR`;
export const setEditAdultDetailSidebar = setEditAdultDetailSidebar => ({
  type: SET_EDIT_ADULT_DETAIL_SIDEBAR,
  payload: setEditAdultDetailSidebar,
});

export const SAVE_ADULT_PROFILE_PICTURE_REQUEST = `${moduleName}/SAVE_ADULT_PROFILE_PICTURE_REQUEST`;
export const saveAdultProfilePictureRequest = profile => ({
  type: SAVE_ADULT_PROFILE_PICTURE_REQUEST,
  payload: profile,
});

export const SAVE_ADULT_PROFILE_PICTURE_RESPONSE = `${moduleName}/SAVE_ADULT_PROFILE_PICTURE_RESPONSE`;
export const saveAdultProfilePictureResponse = () => ({
  type: SAVE_ADULT_PROFILE_PICTURE_RESPONSE,
});

export const SAVE_ADULT_PROFILE_PICTURE_ERROR = `${moduleName}/SAVE_ADULT_PROFILE_PICTURE_ERROR`;
export const saveAdultProfilePictureError = err => ({
  type: SAVE_ADULT_PROFILE_PICTURE_ERROR,
  payload: err,
});
