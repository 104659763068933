import { of } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { catchAndReport } from '@utils/rxjs/operators';
import { isCurrentPageSel } from '@location';
import {
  parentOrgGuidSel,
  organizationPositionsSel,
  SET_ORGANIZATION,
} from '@shared';
import {
  ROUTE_COUNCIL_UNITS,
  UNITS_SEARCH_REQUEST,
  unitsSearchResponse,
  unitsSearchError,
  LOAD_DISTRICTS_REQUEST,
  loadDistrictsRequest,
  loadDistrictsResponse,
  loadDistrictsError,
} from './actions';
import services from './services';

const fetchUnitsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(UNITS_SEARCH_REQUEST),
    switchMap(({ payload }) => {
      const organizationGuid = parentOrgGuidSel(state$.value);
      const { districtOrgGuid } = payload;

      return services
        .getUnits$({
          organizationGuid: districtOrgGuid || organizationGuid,
          ...payload,
        })
        .pipe(
          map(unitsSearchResponse),
          catchAndReport(err => of(unitsSearchError(err))),
        );
    }),
  );

const fetchDistrictsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(LOAD_DISTRICTS_REQUEST),
    switchMap(() => {
      const state = state$.value;
      const organizationGuid = parentOrgGuidSel(state);

      return services
        .getDistricts$(organizationGuid, organizationPositionsSel(state))
        .pipe(
          map(loadDistrictsResponse),
          catchAndReport(err => of(loadDistrictsError(err))),
        );
    }),
  );

const reloadDistrictsEpic$ = (action$, state$) =>
  action$.pipe(
    ofType(SET_ORGANIZATION),
    filter(() => isCurrentPageSel(state$.value, ROUTE_COUNCIL_UNITS)),
    mapTo(loadDistrictsRequest()),
  );

export default combineEpics(
  fetchUnitsEpic$,
  fetchDistrictsEpic$,
  reloadDistrictsEpic$,
);
