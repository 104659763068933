import React from 'react';
import PropTypes from 'prop-types';

import { S } from '@shared';
import styles from './PendingList.less';
import RecordedAdvancementItem from './PendingListItem';

const PendingList = ({
  requests,
  isMobile,
  isOffline,
  failedItemIds,
  selectedItemId,
  editItemId,
  title,
  className,
  onSyncItem,
  onCancelItem,
  onSelectItem,
  onChangeItem,
  onSetEditItem,
}) => {
  if (requests.length == 0) {
    return null;
  }

  const itemNodes = requests.map(
    ({ advancement, verification, ...request }) => (
      <RecordedAdvancementItem
        key={request.id}
        id={request.id}
        advancementName={advancement.name}
        dateCompleted={request.date}
        isMobile={isMobile}
        isOffline={isOffline}
        imageUrl={advancement.imageUrl100}
        selected={selectedItemId == request.id}
        editMode={editItemId == request.id}
        verification={verification}
        hasError={failedItemIds.includes(request.id)}
        onSync={onSyncItem(request.id)}
        onCancel={onCancelItem(request.id)}
        onSelect={onSelectItem}
        onChange={onChangeItem}
        onSetEdit={onSetEditItem(request.id)}
      />
    ),
  );

  return (
    <div className={className}>
      <S size="4" bold colored>
        {title}
      </S>
      <div className={styles.itemsList}>{itemNodes}</div>
    </div>
  );
};

PendingList.propTypes = {
  requests: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  failedItemIds: PropTypes.array,
  selectedItemId: PropTypes.string,
  editItemId: PropTypes.string,
  title: PropTypes.node,
  className: PropTypes.string,
  onSyncItem: PropTypes.func,
  onEditItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  onCancelItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  onChangeItem: PropTypes.func,
  onSetEditItem: PropTypes.func,
};

export default PendingList;
