import { permissions, emptyObj } from '@shared';
import { replace } from 'redux-first-router';
import { ROUTE_ADULT_PROFILE, ROUTE_ADULT_ACTIVITY_LOGS } from './duck';
import {
  AdultProfilePage,
  AdultActivityLogsPage,
  AdultProfilePagePlaceholder,
} from './components';
import isEmpty from 'lodash/isEmpty';
import { querySel, payloadSel } from '@location';
import { organizationPositionsSel, setOrganization } from '@context';

const routes = {
  [ROUTE_ADULT_PROFILE]: {
    path: '/adultProfile/:userId',
    Component: AdultProfilePage,
    Placeholder: AdultProfilePagePlaceholder,
    titleId: 'page.profile',
    permission: permissions.VIEW_ADULT_PROFILE_PAGE,
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const {
        organizationGuid = '',
        adultUserId = '',
        childUserId = '',
      } = querySel(state) || emptyObj;
      const payload = payloadSel(state);

      if (
        !isEmpty(organizationGuid) &&
        !isEmpty(positions) &&
        !isEmpty(payload)
      ) {
        const { userId } = payload;
        const foundedPosition = positions.find(
          p => p.organizationGuid === organizationGuid,
        );
        replace(
          `/adultProfile/${userId}?${
            adultUserId ? `&adultUserId=${adultUserId}` : ''
          }${childUserId ? `&childUserId=${childUserId}` : ''}`,
        );
        if (foundedPosition) {
          dispatch(
            setOrganization({
              guid: foundedPosition.organizationGuid,
              programType: foundedPosition.programType,
              parentOrgGuid: foundedPosition.organizationGuid,
            }),
          );
        }
      }

      if (isEmpty(organizationGuid) && childUserId && !isEmpty(payload)) {
        const { userId } = payload;
        replace(
          `/adultProfile/${userId}?childUserId=${childUserId}${
            adultUserId ? `&adultUserId=${adultUserId}` : ''
          }`,
        );
      }
    },
  },
  [ROUTE_ADULT_ACTIVITY_LOGS]: {
    path: '/adultProfile/:userId/activityLogs/:activityType',
    Component: AdultActivityLogsPage,
    Placeholder: AdultProfilePagePlaceholder,
    titleId: 'page.youthActivityLogs',
    permission: permissions.VIEW_ADULT_PROFILE_PAGE,
  },
};

export default routes;
