import React from 'react';
import ContentLoader from 'react-content-loader';

import { PagePlaceholder } from '@shared';
import styles from './AdultProfilePagePlaceholder.less';

const ProfilePagePlaceholder = () => (
  <PagePlaceholder className={styles.container}>
    <div className={styles.personContainer}>
      <div className={styles.avatarContainer}>
        <ContentLoader width={120} height={250} className={styles.avatar}>
          <circle cx="60" cy="60" r="60" />
          <rect x="20" y="145" rx="3" ry="3" width="80" height="20" />
          <rect x="30" y="180" rx="3" ry="3" width="60" height="15" />
        </ContentLoader>
      </div>
      <ContentLoader width={240} height={120} className={styles.personDetails}>
        <rect x="0" y="0" rx="3" ry="3" width="140" height="20" />
        <rect x="0" y="30" rx="3" ry="3" width="180" height="15" />
        <rect x="0" y="60" rx="3" ry="3" width="200" height="15" />
        <rect x="0" y="90" rx="3" ry="3" width="80" height="15" />
      </ContentLoader>
    </div>
    <ContentLoader width={240} height={220} className={styles.unitInfo}>
      <rect x="0" y="0" rx="3" ry="3" width="150" height="20" />
      <rect x="0" y="70" rx="3" ry="3" width="210" height="15" />
      <rect x="0" y="100" rx="3" ry="3" width="200" height="15" />
      <rect x="0" y="130" rx="3" ry="3" width="170" height="15" />
      <rect x="0" y="160" rx="3" ry="3" width="130" height="15" />
      <rect x="0" y="190" rx="3" ry="3" width="180" height="15" />
    </ContentLoader>
  </PagePlaceholder>
);

export default ProfilePagePlaceholder;
