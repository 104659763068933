import React, { useState } from 'react';

import cn from 'classnames';
import ContentPasteIcon from 'material-ui-icons/ContentPaste';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { programSel } from '@context/duck/selectorsTyped';
import {
  navigateToBulkEntryAdventure,
  navigateToBulkEntryMB,
} from '@modules/bulkEntry/duck/actions';
import {
  shouldShowAdventuresBulkEntry,
  shouldShowMeritBadgeBulkEntry,
  shouldShowRankBulkEntry,
  shouldShowYouthLeadershipBulkEntry,
} from '@modules/bulkEntry/duck/utils/featureFlags';
import { OfflineTooltip } from '@offline';
import {
  Button,
  Dropdown,
  ExpandToggle,
  Menu,
  navigateToQuickEntryScoutLeadership,
} from '@shared';
import { Program } from '@shared/constants';

import { currentUnitGuidSel } from '../../../../unit/duck';
import {
  navigateToQuickEntryHealth,
  navigateToQuickEntryOA,
  navigateToQuickEntryRank,
  navigateToQuickEntrySchool,
  navigateToQuickEntrySwimming,
} from '../../actions';
import styles from './QuickEntryDropdown.less';

const heathRecord = 'heathRecord';
const swimmerClass = 'swimmerClass';
const schoolInfo = 'schoolInfo';
const oaMember = 'oaMember';
const qeRank = 'qeRank';
const bulkEntryMB = 'bulkEntryMB';
const bulkEntryAdventures = 'bulkEntryAdventures';
const scoutLeadership = 'scoutLeadership';

const MenuItem = Menu.Item;
const MenuDivider = Menu.Divider;

function shouldShowOA({ unitProgram }) {
  if (unitProgram === Program.CUB_SCOUT) {
    return false;
  }

  return true;
}

const QuickEntryDropdown = ({ disabled }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const unitGuid = useSelector(currentUnitGuidSel);
  const unitProgram = useSelector(programSel);

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const handleMenuClick = ({ key }) => {
    setVisible(false);

    switch (key) {
      case heathRecord: {
        dispatch(navigateToQuickEntryHealth());
        return;
      }
      case swimmerClass: {
        dispatch(navigateToQuickEntrySwimming());
        return;
      }
      case schoolInfo: {
        dispatch(navigateToQuickEntrySchool());
        return;
      }
      case oaMember: {
        dispatch(navigateToQuickEntryOA());
        return;
      }

      case qeRank: {
        dispatch(navigateToQuickEntryRank({ unitGuid }));
        return;
      }

      case scoutLeadership: {
        dispatch(navigateToQuickEntryScoutLeadership());
        return;
      }

      case bulkEntryMB: {
        dispatch(navigateToBulkEntryMB({ unitGuid }));
        return;
      }

      case bulkEntryAdventures: {
        dispatch(navigateToBulkEntryAdventure({ unitGuid }));
        return;
      }

      default: {
        throw new Error(`Unknown report type: ${key}`);
      }
    }
  };

  const buttons = (
    <Menu onClick={handleMenuClick}>
      {shouldShowRankBulkEntry(unitProgram) && (
        <MenuItem key={qeRank}>
          <FormattedMessage id="packRoster.QuickEntryDropdown.qeRank" />
        </MenuItem>
      )}
      {shouldShowRankBulkEntry(unitProgram) ? <MenuDivider /> : undefined}

      {shouldShowMeritBadgeBulkEntry(unitProgram) ? (
        <MenuItem key={bulkEntryMB}>
          <FormattedMessage id="packRoster.QuickEntryDropdown.bulkEntryMB" />
        </MenuItem>
      ) : undefined}
      {shouldShowMeritBadgeBulkEntry(unitProgram) ? <MenuDivider /> : undefined}

      {shouldShowAdventuresBulkEntry(unitProgram) ? (
        <MenuItem key={bulkEntryAdventures}>
          <FormattedMessage id="packRoster.QuickEntryDropdown.bulkEntryAdventures" />
        </MenuItem>
      ) : undefined}
      {shouldShowAdventuresBulkEntry(unitProgram) ? <MenuDivider /> : undefined}

      <MenuItem key={heathRecord}>
        <FormattedMessage id="packRoster.QuickEntryDropdown.heathRecord" />
      </MenuItem>
      <MenuDivider />
      <MenuItem key={swimmerClass}>
        <FormattedMessage id="packRoster.QuickEntryDropdown.swimmerClass" />
      </MenuItem>
      {shouldShowYouthLeadershipBulkEntry(unitProgram) && <MenuDivider />}
      {shouldShowYouthLeadershipBulkEntry(unitProgram) && (
        <MenuItem key={scoutLeadership}>
          <FormattedMessage id="packRoster.QuickEntryDropdown.scoutLeadership" />
        </MenuItem>
      )}

      {shouldShowOA({ unitProgram }) ? <MenuDivider /> : undefined}
      {shouldShowOA({ unitProgram }) ? (
        <MenuItem key={oaMember}>
          <FormattedMessage id="packRoster.QuickEntryDropdown.OaData" />
        </MenuItem>
      ) : undefined}
      <MenuDivider />
      <MenuItem key={schoolInfo}>
        <FormattedMessage id="packRoster.QuickEntryDropdown.schoolInfo" />
      </MenuItem>
    </Menu>
  );

  return (
    <OfflineTooltip placement="top">
      <Dropdown
        overlay={buttons}
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        disabled={disabled}
        onVisibleChange={handleVisibleChange}
      >
        <Button
          className={cn(
            {
              [styles.disabled]: disabled,
            },
            styles.toggleButton,
          )}
          ghost
          noBorder
          color="white"
          size="small"
          uppercase={false}
        >
          <ContentPasteIcon className={styles.reportIcon} />
          <FormattedMessage id="packRoster.QuickEntryDropdown.quickEntry" />
          <ExpandToggle
            className={styles.arrow}
            expanded={visible && !disabled}
          />
        </Button>
      </Dropdown>
    </OfflineTooltip>
  );
};

QuickEntryDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default QuickEntryDropdown;
