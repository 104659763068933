import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Select } from '@shared/components';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { validateForm } from '@utils';

import {
  memberTypeIds,
  memberTypes,
  parentCoordinatorId,
  positionIdsExemptOfPaperApp,
  positionTypes,
} from '../../../../constants';
import {
  closeUpdatePositionModal,
  isUpdatePositionLoadingSel,
  memberPositionToUpdateSel,
  memberUnitPositionsSel,
  recharterRosterAdultsSel,
  updatePositionError,
  updatePositionRequest,
} from '../../../../duck';
import { memberHasPaperApplication } from '../../../../utils';
import styles from './UpdatePositionForm.less';

const FormItem = Form.Item;
const { Option, OptGroup } = Select;

const UpdatePositionForm = ({ form }) => {
  const { personId, positionId, memberId, memberTypeId, age } = useSelector(
    memberPositionToUpdateSel,
  );
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const isLoading = useSelector(isUpdatePositionLoadingSel);
  const adultRosterRecharter = useSelector(recharterRosterAdultsSel);
  const [hasChangedPosition, setHasChangedPosition] = useState(false);
  const [selectedMemberType, setSelectedMemberType] = useState(memberTypeId);
  const [hasChangedMemberType, setHasChangedMemberType] = useState(false);
  const hasPaperApplication =
    memberTypeId !== memberTypeIds.adult ||
    memberHasPaperApplication(adultRosterRecharter, memberId);
  const akelaPositions = useSelector(memberUnitPositionsSel)
    .filter(
      p =>
        p.PositionType === positionTypes.registered ||
        p.PositionType === positionTypes.noFee,
    )
    .sort((a, b) => a.positionLong.localeCompare(b.positionLong));

  const positions = akelaPositions.filter(
    ({
      isAdultPosition,
      positionCode,
      minAge = 0,
      isParticipant,
      PositionId,
    }) => {
      if (selectedMemberType === memberTypeIds.participant) {
        return (
          isParticipant && PositionId !== parentCoordinatorId && age >= minAge
        );
      }

      if (selectedMemberType === memberTypeIds.youth) {
        return (
          !isAdultPosition && !isParticipant && !!positionCode && age >= minAge
        );
      }

      if (selectedMemberType === memberTypeIds.adult) {
        if (
          !isParticipant &&
          isAdultPosition &&
          PositionId !== parentCoordinatorId &&
          age >= minAge
        ) {
          if (!hasPaperApplication) {
            return positionIdsExemptOfPaperApp.includes(PositionId);
          }
          return true;
        }
        return false;
      }

      return true;
    },
  );

  const requiredRule = {
    required: true,
    message: intl.formatMessage({
      id: 'shared.form.error.isRequired',
    }),
  };

  const validate = useCallback(() => validateForm(form), [form]);

  const handleUpdateMember = async () => {
    const formData = await validate();
    const { newPositionId, newMemberTypeId } = formData;
    const selectedPosition = akelaPositions.find(
      p => p.PositionId === newPositionId,
    );

    const calcNewMemberTypeId = selectedPosition.isParticipant
      ? memberTypeIds.participant
      : selectedPosition.isAdultPosition
      ? memberTypeIds.adult
      : memberTypeIds.youth;

    const payload = {
      personId,
      positionId: newPositionId,
      memberTypeId: calcNewMemberTypeId,
    };

    if (
      formData &&
      (newPositionId != positionId || newMemberTypeId != memberTypeId)
    ) {
      dispatch(updatePositionRequest(payload));
    } else {
      dispatch(closeUpdatePositionModal());
    }
  };

  const handlePositionSelect = useCallback(
    newPositionId => {
      setHasChangedPosition(newPositionId != positionId);
    },
    [positionId],
  );

  const handleMemberTypeSelect = useCallback(
    newTypeId => {
      setSelectedMemberType(newTypeId);
      setHasChangedMemberType(newTypeId != memberTypeId);
    },
    [memberTypeId],
  );

  useEffect(() => {
    if (hasChangedMemberType) {
      if (positions.length === 1) {
        form.setFieldsValue({ newPositionId: positions[0].PositionId }, () => {
          setHasChangedPosition(true);
        });
      } else {
        form.setFieldsValue({ newPositionId: undefined });
      }
    } else if (hasChangedPosition) {
      form.setFieldsValue({ newPositionId: positionId }, () => {
        setHasChangedPosition(false);
      });
    }
    // eslint-disable-next-line
  }, [hasChangedMemberType]);

  useEffect(() => {
    if (adultRosterRecharter.length > 0 && !hasPaperApplication) {
      dispatch(updatePositionError());
      toastService.error(
        intl.formatMessage({
          id: 'recharterModals.addMembersModal.requiresPaperApplicationError',
        }),
        undefined,
        { withCloseButton: true },
      );
    }
  }, [adultRosterRecharter.length, dispatch, hasPaperApplication]);

  return (
    <React.Fragment>
      {(memberTypeId === 3 || memberTypeId === 2) && (
        <FormItem className={styles.formItemNormalWidth}>
          {getFieldDecorator('newMemberTypeId', {
            initialValue: memberTypeId,
            rules: [requiredRule],
          })(
            <Select
              className={styles.graySelect}
              showSearch
              filterOption
              placeholder={intl.formatMessage({
                id: 'recharterModals.updatePositionModal.memberType',
              })}
              size="large"
              optionFilterProp="text"
              rounded
              floatingLabel
              onSelect={handleMemberTypeSelect}
            >
              <OptGroup
                key="memberType"
                label={
                  <FormattedMessage id="recharterModals.updatePositionModal.memberType" />
                }
              >
                {memberTypes
                  .filter(type => type.id !== 1)
                  .map(({ id, label }, i) => (
                    <Option key={`type_${i}`} value={id} text={label}>
                      {label}
                    </Option>
                  ))}
              </OptGroup>
            </Select>,
          )}
        </FormItem>
      )}
      <FormItem className={styles.formItemNormalWidth}>
        {getFieldDecorator('newPositionId', {
          initialValue: positionId,
          rules: [requiredRule],
        })(
          <Select
            className={styles.graySelect}
            showSearch
            filterOption
            placeholder={intl.formatMessage({
              id: 'recharterModals.updatePositionModal.memberPosition',
            })}
            size="large"
            optionFilterProp="text"
            rounded
            floatingLabel
            onSelect={handlePositionSelect}
          >
            <OptGroup
              key="memberInfo"
              label={
                <FormattedMessage id="recharterModals.updatePositionModal.memberPosition" />
              }
            >
              {positions.map(({ PositionId, positionLong }, i) => (
                <Option
                  key={`type_${i}`}
                  value={PositionId}
                  text={positionLong}
                >
                  {positionLong}
                </Option>
              ))}
            </OptGroup>
          </Select>,
        )}
      </FormItem>
      <div className={styles.buttonContainer}>
        <Button
          loading={isLoading}
          className={styles.button}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={false}
          onClick={handleUpdateMember}
          disabled={!hasChangedPosition}
        >
          <FormattedMessage id="recharterModals.updatePositionModal.update" />
        </Button>
      </div>
    </React.Fragment>
  );
};

UpdatePositionForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create()(UpdatePositionForm);
