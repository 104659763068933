import get from 'lodash/get';
import moment from 'moment';
import { NOT_FOUND } from 'redux-first-router';
import { combineEpics, ofType } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/skipUntil';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import { mergeMap } from 'rxjs/operators';

import { ENABLE_SAND_BOX_MODAL, SHOW_DASHBOARD } from '@config';
import {
  services as contextServices,
  setDefaultOrganization,
  setOrganization,
} from '@context';
import { COUNSELED_YOUTH_ROUTE } from '@modules/counseledYouth/duck';
import { dtoDateFormat, youthProfileModuleName } from '@shared/constants';
import {
  APP_BOOTSTRAP_DONE,
  ROUTE_ADVANCEMENT,
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_EVENT_DETAILS,
  ROUTE_HOME,
  ROUTE_PROFILE,
  ROUTE_YOUTH_ACTIVITY_LOGS,
  ROUTE_YOUTH_PROFILE,
  SET_MANUAL_OFFLINE,
  SET_OFFLINE,
  SET_ORGANIZATION,
  appBootstrapDone,
  childUserIdSel,
  councilUnitsServices,
  fetchRelatedChildren,
  isParentOrYouthMemberSel,
  isYouthMemberSel,
  logout,
  offlineSel,
  retrieveLastOnlineDate,
  retrieveUserTableConfig,
  setLastOnline,
  setPendingRequests,
  setTablePageSize,
  triggerAllRostersRequest,
  updateRequests,
} from '@shared/duck';
import { intl } from '@shared/localization';
import { toastService } from '@toasts';
import { googleSignIn, httpCancel, isDaysOld, requestStorage } from '@utils';
import '@utils/rxjs.add.operator.catchAndReport';

import {
  ROUTE_COUNCIL_UNITS,
  setRecentUnits,
  setUnit,
} from '../../councilUnits';
import { unitProfileRequest } from '../../unit';
import {
  APPLE_LOGIN_VALIDATE,
  AUTH_REDIRECT_REQUEST,
  AUTH_REDIRECT_SUCCESS,
  CLOSE_TIMEOUT_MODAL,
  CONTINUE_LOGIN,
  GOOGLE_LOGIN_VALIDATE,
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT,
  MASQUERADE_REQUEST,
  MASQUERADE_RESPONSE,
  REFRESH_SESSION_REQUEST,
  ROUTE_LOGIN,
  ROUTE_NO_ACCESS,
  ROUTE_SAND_BOX,
  SELFSESSION_REQUEST,
  SELFSESSION_RESPONSE,
  USER_DATA_REQUEST,
  authRedirectError,
  authRedirectSuccess,
  continueLogin,
  googleLoginError,
  loginError,
  loginResponse,
  masqueradeError,
  masqueradeResponse,
  refreshSessionError,
  refreshSessionResponse,
  selfsessionError,
  selfsessionResponse,
  selfsessionSkipCheck,
  userDataError,
  userDataResponse,
} from './actions';
import {
  hasPermissionSel,
  isCurrentRouteProtectedSel,
  isLoggedInSel,
  loginDataSel,
  masqueradingSel,
  personGuidSel,
  userDataSel,
  userIdSel,
} from './selectors';
import services from './services';

const externalLoginValidateEpic$ = action$ =>
  action$
    .ofType(GOOGLE_LOGIN_VALIDATE, APPLE_LOGIN_VALIDATE)
    .switchMap(({ payload: token }) =>
      services
        .selfsession$(
          token ? { headers: { Authorization: `bearer ${token}` } } : undefined,
          true,
        )
        .mergeMap(loginData => {
          services.storeLoginData(loginData);
          services.storeLogoutFlag(false);
          return Observable.of(loginResponse(loginData));
        })
        .catchAndReport(err => {
          if (err.status === 401) {
            toastService.error(
              intl.formatMessage({
                id: 'user.googleLogin.error',
              }),
            );
          }
          return Observable.concat(
            Observable.of(logout()),
            Observable.of(googleLoginError(err)),
          );
        }),
    );

const loginEpic$ = action$ =>
  action$.ofType(LOGIN_REQUEST).switchMap(({ payload }) =>
    services
      .login$(payload.login, payload.password)
      .mergeMap(loginData => {
        services.storeLoginData(loginData);
        services.storeLogoutFlag(false);

        return Observable.of(loginResponse(loginData));
      })
      .catchAndReport(err => Observable.of(loginError(err))),
  );

const selfsessionEpic$ = (action$, state$) =>
  action$
    .ofType(SELFSESSION_REQUEST)
    .switchMap(googleSignIn.loadSDK$)
    .switchMap(() => {
      const isCurrentRouteProtected = isCurrentRouteProtectedSel(state$.value);
      if (!isCurrentRouteProtected) {
        return Observable.concat(
          Observable.of(selfsessionSkipCheck()),
          Observable.of(appBootstrapDone()),
        );
      }

      return services
        .selfsession$()
        .mergeMap(loginData => {
          const storedLoginData = services.retrieveLoginData() || {};
          const storedPersonGuid = (
            get(storedLoginData, 'personGuid') || ''
          ).toLowerCase();
          const { masqueraded } = storedLoginData || {};
          const personGuid = (get(loginData, 'personGuid') || '').toLowerCase();
          /**
           * There can be a case when while offline, we could get cached session data for another user.
           * That's why we need to verify that we have the right one.
           * Easiest way is to compare usernames of what we have in storage with what we got from the request.
           * If nothing in storage - assume it's the right data (since we can't know that it isn't).
           *
           * Another case is when we log out while offline and refresh - since we couldn't call the logout endpoint
           * we had to set a flag to tell us that we're logged out.
           * So we need to check that flag here.
           */
          if (
            (!masqueraded &&
              storedPersonGuid &&
              storedPersonGuid !== personGuid) ||
            (!navigator.onLine && services.retrieveLogoutFlag())
          ) {
            return Observable.concat(
              Observable.of(logout()),
              Observable.of(selfsessionError()),
              Observable.of(appBootstrapDone()),
            );
          }
          services.storeLoginData(loginData);

          return Observable.of(selfsessionResponse(loginData));
        })
        .catchAndReport(err => {
          const isProtectedRoute = isCurrentRouteProtectedSel(state$.value);
          const shouldLogout = isProtectedRoute;
          const actions = [
            shouldLogout
              ? Observable.of(logout({ redirectTo: window.location.href }))
              : null,
            Observable.of(selfsessionError(err)),
            Observable.of(appBootstrapDone()),
          ].filter(item => item);
          return Observable.concat(...actions);
        });
    });

function setCookie(cname, cvalue, exdays = 10) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=scouting.org`;
}

const loginViaSessionToken$ = action$ =>
  action$
    .pipe(
      ofType(AUTH_REDIRECT_REQUEST),
      mergeMap(({ payload }) => {
        setCookie('SessionToken', payload.sessionToken);

        return services.selfsession$().mergeMap(loginData => {
          services.storeLoginData(loginData);

          return Observable.concat(
            Observable.of(selfsessionResponse(loginData)),
            Observable.of(authRedirectSuccess()),
          );
        });
      }),
    )
    .catchAndReport(err => Observable.of(authRedirectError(err)));

const masqueradeEpic$ = action$ =>
  action$
    .ofType(MASQUERADE_REQUEST)
    .switchMap(({ payload, editWhileMasquerading }) =>
      services
        .masquerade$(payload)
        .mergeMap(loginData => {
          services.storeLoginData({ ...loginData, masqueraded: true });
          services.storeLogoutFlag(false);

          return Observable.of(
            masqueradeResponse(loginData, editWhileMasquerading),
          );
        })
        .catchAndReport(err => Observable.of(masqueradeError(err))),
    );

const initTablePageSizeEpic$ = (action$, state$) =>
  action$
    .ofType(LOGIN_RESPONSE, SELFSESSION_RESPONSE, MASQUERADE_RESPONSE)
    .filter(() => isLoggedInSel(state$.value))
    .map(() => {
      const personGuid = personGuidSel(state$.value);
      const tableConfig = retrieveUserTableConfig(personGuid);
      const skipGtm = true;

      return setTablePageSize(tableConfig.pageSize, personGuid, skipGtm);
    });

const initUserDataEpic$ = (action$, state$) =>
  action$
    .ofType(
      LOGIN_RESPONSE,
      SELFSESSION_RESPONSE,
      MASQUERADE_RESPONSE,
      AUTH_REDIRECT_SUCCESS,
    )
    .filter(() => isLoggedInSel(state$.value))
    .switchMap(({ type }) => {
      const state = state$.value;
      const { personGuid, hasMasqueradeRole: canMasquerade = false } =
        loginDataSel(state);
      const userId = userIdSel(state);

      if (ENABLE_SAND_BOX_MODAL && type === LOGIN_RESPONSE) {
        return Observable.concat(
          Observable.of({ type: ROUTE_SAND_BOX }),
          Observable.of(appBootstrapDone()),
        );
      }

      return services
        .getUserData$(personGuid, userId)
        .switchMap(userData => {
          const { organizations } = userData;
          // TODO: After we get the information from a single source of truth, we can do this in the service itself.
          const hasParentGuardianPosition = organizations.some(
            ({ isParentGuardian }) => isParentGuardian,
          );
          userData.hasParentGuardianPosition = hasParentGuardianPosition;
          if (hasParentGuardianPosition) {
            return Observable.of(
              fetchRelatedChildren({ userId, userData, canMasquerade }),
            );
          }

          return Observable.concat(
            Observable.of(continueLogin({ userData, canMasquerade })),
          );
        })
        .catchAndReport(err => Observable.of(userDataError(err)));
    });

const isLoggedInRoute = type => ![ROUTE_LOGIN, ROUTE_NO_ACCESS].includes(type);

const getLocationOrgGuid = state => {
  const currentRoute = state.location;
  const prevRoute = state.location.prev || {};
  if (isLoggedInRoute(currentRoute.type)) {
    const { organizationGuid: locationOrgGuid } =
      (currentRoute && currentRoute.query) || {};
    return locationOrgGuid;
  }
  if (prevRoute.type && isLoggedInRoute(prevRoute.type)) {
    const { organizationGuid: locationOrgGuid } =
      (prevRoute && prevRoute.query) || {};
    return locationOrgGuid;
  }
  return null;
};

const getActiveUnitFallback = ({
  organizations,
  currentUnitGuid,
  defaultUnitGuid,
  locationOrgGuid,
}) => {
  const location = document.location.pathname;
  //when accesing /youthProfile/{id} force to use the url id instead of localstorage id
  if (location) {
    const urlSections = location.split('/');

    if (urlSections[urlSections.length - 2] === youthProfileModuleName) {
      const youthUserId = Number(urlSections[urlSections.length - 1]);
      const exactYouth = organizations.find(
        org => org.organizationGuid === currentUnitGuid,
      );
      if (exactYouth) {
        return exactYouth;
      }
      // fallback, search for youth with same user id
      const youthOrgAndId = organizations.find(
        ({ userId }) => Number(userId) === youthUserId,
      );
      if (youthOrgAndId) {
        return youthOrgAndId;
      }
    }
  }

  const urlQueryUnit =
    locationOrgGuid &&
    organizations.find(
      ({ organizationGuid }) => organizationGuid === locationOrgGuid,
    );
  if (urlQueryUnit) {
    return urlQueryUnit;
  }
  const currentUnit = organizations.find(
    ({ organizationGuid }) => organizationGuid === currentUnitGuid,
  );
  if (currentUnit) {
    return currentUnit;
  }
  const defaultUnit = organizations.find(
    ({ organizationGuid }) => organizationGuid === defaultUnitGuid,
  );
  if (defaultUnit) {
    return defaultUnit;
  }
  const councilUnit = organizations.find(({ isCouncil }) => isCouncil);
  if (councilUnit) {
    return councilUnit;
  }
  return organizations[0];
};

const isValidRoute = (route, state) => {
  const { routesMap } = state.location;

  if ((route || {}).type === NOT_FOUND) {
    return false;
  }

  const routeFromMap = routesMap[route.type];

  if (!routeFromMap) {
    return false;
  } else {
    const { permission } = routesMap[route.type] || {};
    return !permission || hasPermissionSel(state, permission);
  }
};

const getValidRoute = ({ state, type, payload, query }) => {
  const userId = userIdSel(state);
  const isYouthMember = isYouthMemberSel(state);
  const isParentOrYouth = isParentOrYouthMemberSel(state);
  const childUserId = childUserIdSel(state);
  let routes = [];

  if (isParentOrYouth && childUserId && ROUTE_YOUTH_ACTIVITY_LOGS === type) {
    routes.push({ type, payload: { ...payload, userId: childUserId } });
  } else if (isParentOrYouth && ROUTE_PROFILE === type) {
    routes.push({ type, payload: { ...payload, userId: childUserId } });
  } else if (isParentOrYouth && ROUTE_CALENDAR === type) {
    routes.push({ type, payload: { ...payload } });
  } else if (isParentOrYouth && ROUTE_CALENDAR_EVENT_DETAILS === type) {
    routes.push({ type, payload: { ...payload } });
  } else if (isParentOrYouth && childUserId) {
    routes.push({
      type: ROUTE_YOUTH_PROFILE,
      payload: { ...payload, userId: childUserId },
    });
  }

  if (userId && isYouthMember) {
    routes.push({ type: ROUTE_PROFILE, payload: { userId } });
  }

  routes.push(
    { type, payload, query },
    { type: ROUTE_COUNCIL_UNITS },
    { type: ROUTE_ADVANCEMENT },
    SHOW_DASHBOARD && { type: ROUTE_HOME },
    { type: COUNSELED_YOUTH_ROUTE },
    { type: ROUTE_PROFILE },
  );

  routes.push({ type: ROUTE_NO_ACCESS });
  return routes.find(route => isValidRoute(route, state));
};

const getReturnTo = state => {
  const redirectTo = get(state, 'location.query.redirectTo');
  const currentRoute = state.location;
  const prevRoute = state.location.prev || {};
  if (redirectTo) {
    window.location.href = redirectTo;
  }
  if (isLoggedInRoute(currentRoute.type)) {
    return getValidRoute({ state, ...currentRoute });
  }
  if (prevRoute.type && isLoggedInRoute(prevRoute.type)) {
    return getValidRoute({ state, ...prevRoute });
  }
  return getValidRoute({ state });
};

const continueLoginEpic$ = (action$, state$) =>
  action$
    .ofType(CONTINUE_LOGIN)
    .switchMap(({ payload: { userData, canMasquerade } }) => {
      // if hasParentGuadianPosition returns to parentGuardianEpic to fetch all data related to this and after that we return to the main simplifiedEpic
      const state = state$.value;
      const isMasquerading = masqueradingSel(state);
      const { organizations } = userData;
      // users with no valid units cannot access
      if (organizations.length === 0) {
        if (isMasquerading) {
          const { profile } = userData;
          const { memberId, shortName } = profile || {};
          toastService.warn(
            `${shortName} (${memberId}) does not have access to this product.`,
          );
        }

        return Observable.concat(
          Observable.of(userDataResponse({ ...userData, canMasquerade })),
          Observable.of({ type: ROUTE_NO_ACCESS }),
          Observable.of(appBootstrapDone()),
        );
      }

      const personGuid = get(userData, 'profile.personGuid');
      const currentUnitGuid = contextServices.retrieveCurrentUnit();
      const defaultUnitGuid = contextServices.retrieveDefaultUnit(personGuid);

      const locationOrgGuid = getLocationOrgGuid(state$.value);
      const { organizationGuid, programType } = getActiveUnitFallback({
        organizations,
        currentUnitGuid,
        defaultUnitGuid,
        locationOrgGuid,
      });
      const recentUnits =
        councilUnitsServices.retrieveUserRecentUnits(personGuid);
      const lastActiveChildUnit =
        councilUnitsServices.retrieveLastActiveChildUnit(personGuid);

      const orgIds = new Set(
        organizations
          .map(org => org?.organizationGuid)
          // for some reason org guids contain * separator
          .map(orgGuid => (orgGuid || '').split('*')[0])
          .filter(Boolean),
      );
      const orgsInfo = Array.from(orgIds).map(organizationGuid =>
        Observable.of(unitProfileRequest({ organizationGuid })),
      );
      return Observable.concat(
        Observable.fromPromise(
          requestStorage.getRequests(personGuid).then(setPendingRequests),
        ),
        Observable.of(userDataResponse(userData)),
        Observable.of(
          setOrganization({
            guid: organizationGuid,
            programType,
            parentOrgGuid: organizationGuid,
          }),
        ),
        Observable.of(
          setDefaultOrganization({
            guid: defaultUnitGuid,
            skipGtm: true,
          }),
        ),
        Observable.of(triggerAllRostersRequest(true)),
        ...orgsInfo,
        Observable.of(setRecentUnits(recentUnits)),
        Observable.of(setUnit(lastActiveChildUnit)),
        // make sure getReturnTo gets evaluated in the next tick after userDataResponse
        // so that the return route can be properly calculated
        Observable.of({}).map(() => getReturnTo(state$.value)),
        Observable.of(appBootstrapDone()),
      );
    });

const redirectOnOrganizationChangeEpic$ = (action$, state$) =>
  action$
    .skipUntil(action$.ofType(APP_BOOTSTRAP_DONE))
    .ofType(SET_ORGANIZATION)
    // To properly switch between Recent Units as Council Admin we won't redirect when guid and parentOrgGuid are different
    .filter(({ payload: { guid, parentOrgGuid } }) => guid === parentOrgGuid)
    .map(() => getReturnTo(state$.value));

const fetchUserDataEpic$ = (action$, state$) =>
  action$
    .ofType(USER_DATA_REQUEST)
    .switchMap(() => {
      const state = state$.value;
      const personGuid = personGuidSel(state$.value);
      const userId = userIdSel(state);
      return services.getUserData$(personGuid, userId).map(userData => {
        const { organizations } = userDataSel(state$.value);
        return userDataResponse({ ...userData, organizations });
      });
    })
    .catchAndReport(err => Observable.of(userDataError(err)));

const logoutEpic$ = action$ =>
  action$
    .ofType(LOGOUT)
    .do(action => {
      services.logout({
        isDiscourseSessionPreserved: action.meta?.preserveDiscourseSession,
      }); // just fire off and move on
      services.removeLoginData();
      services.storeLogoutFlag(true);
      contextServices.removeCurrentInfo();
      councilUnitsServices.removeRecentUnit();
      httpCancel();
    })
    .switchMap(services.googleSignOut$)
    .map(({ payload: msg }) => {
      if (msg) {
        return { type: ROUTE_LOGIN, meta: { query: msg } };
      }

      return { type: ROUTE_LOGIN };
    });

const logoutPostOfflineEpic$ = action$ =>
  action$
    .ofType(SET_OFFLINE, SET_MANUAL_OFFLINE)
    .filter(({ payload }) => payload === false && services.retrieveLogoutFlag())
    .do(() => {
      services.logout();
      httpCancel();
    })
    .ignoreElements();

const refreshSessionEpic$ = action$ =>
  action$
    .ofType(REFRESH_SESSION_REQUEST, CLOSE_TIMEOUT_MODAL)
    .switchMap(() =>
      services.selfsession$().mergeMap(loginData => {
        services.storeLoginData(loginData);
        return Observable.of(refreshSessionResponse(loginData));
      }),
    )
    .catchAndReport(err => Observable.of(refreshSessionError(err)));

const updateLastOnlineEpic$ = (action$, state$) =>
  action$
    .ofType(SET_OFFLINE, SET_MANUAL_OFFLINE, SELFSESSION_RESPONSE)
    .filter(() => isLoggedInSel(state$.value))
    .map(() => {
      const isOffline = offlineSel(state$.value);
      const lastOnlineDate = isOffline
        ? retrieveLastOnlineDate()
        : moment().format(dtoDateFormat);

      return setLastOnline(lastOnlineDate);
    });

const removeExpiredPendingRequestEpic$ = (action$, state$) =>
  action$.ofType(LOGIN_RESPONSE, SELFSESSION_RESPONSE).switchMap(() =>
    // Tick every 8 hours
    Observable.timer(0, 60000 * 60 * 8)
      .takeUntil(action$.ofType(LOGOUT))
      .mapTo(async () => {
        const personGuid = personGuidSel(state$.value);
        const maxRequestAgeDays = 14;
        const requests = await requestStorage.getRequests(personGuid);

        const validRequests = requests.filter(
          ({ timestamp }) =>
            !isDaysOld({ days: maxRequestAgeDays, date: timestamp }),
        );
        if (validRequests.length !== requests.length) {
          await requestStorage.setRequests(personGuid, validRequests);
          return updateRequests();
        }
        return { type: 'NOOP' };
      }),
  );

export default combineEpics(
  loginEpic$,
  externalLoginValidateEpic$,
  selfsessionEpic$,
  masqueradeEpic$,
  initUserDataEpic$,
  initTablePageSizeEpic$,
  fetchUserDataEpic$,
  logoutEpic$,
  logoutPostOfflineEpic$,
  refreshSessionEpic$,
  updateLastOnlineEpic$,
  removeExpiredPendingRequestEpic$,
  redirectOnOrganizationChangeEpic$,
  continueLoginEpic$,
  loginViaSessionToken$,
);
