/**
 * WizardStorage keeps track of which wizards/tours/joyrides users have seen.
 * Since the app can change and evolve, so can the wizards.
 * For this reason, we're also storing the wizard version
 * to know the last version a user has seen
 * (which should be set to the same value as what was in package.json
 * at the time of creating/updating a wizard).
 * When querying the storage, we compare the stored value
 * with the current wizard version to find out if we need to show it again.
 */

const key = 'WIZARDS';

const retrieve = () => {
  const data = localStorage.getItem(key) || '{}';
  return JSON.parse(data);
};

// 1.0.0    => 1 000 000
// 1.2.3    => 1 002 003
// 2.33.456 => 2 033 456
const versionStrToNumber = version => {
  const [major, minor, patch] = version.split('.').map(Number);
  return major * 1000000 + minor * 1000 + patch;
};

const isWatchedWizardOutdated = (currentWizardVersion, lastWatchedVersion) =>
  versionStrToNumber(lastWatchedVersion) <
  versionStrToNumber(currentWizardVersion);

const isWatched = (username, wizardId, wizardVersion = '1.0.0') => {
  const data = retrieve();
  const wizardsForUser = data[username] || {};
  const lastWatchedVersion = wizardsForUser[wizardId];
  return (
    lastWatchedVersion &&
    !isWatchedWizardOutdated(wizardVersion, lastWatchedVersion)
  );
};

const store = data => {
  localStorage.setItem(key, JSON.stringify(data));
};

const markWatched = (username, wizardId, wizardVersion) => {
  const data = retrieve();
  const wizardsForUser = data[username] || {};
  wizardsForUser[wizardId] = wizardVersion;
  store({
    ...data,
    [username]: wizardsForUser,
  });
};

const unmarkWatched = (username, wizardId) => {
  const data = retrieve();
  const wizardsForUser = data[username] || {};
  delete wizardsForUser[wizardId];
  store({
    ...data,
    [username]: wizardsForUser,
  });
};

export default {
  isWatched,
  markWatched,
  unmarkWatched,
};
