import { createSelector } from '@reduxjs/toolkit';

import { packRosterItemsSel } from '@modules/shared';
import { userIdSel } from '@modules/user';
import { isSubUnitPosition } from '@modules/user/duck/utils';

const subUnitPositionsSel = createSelector(
  userIdSel,
  packRosterItemsSel,
  (userId, rosterItems) => {
    const userInfo = rosterItems.find(person => person.userId == userId);
    if (!userInfo) {
      return [];
    }

    return userInfo.positions?.filter(isSubUnitPosition);
  },
);

export const peopleInSubUnitsSel = createSelector(
  subUnitPositionsSel,
  packRosterItemsSel,
  (subUnitPositions = [], rosterItems) => {
    const denIds = new Set(
      subUnitPositions.map(pos => pos.denId).filter(Boolean),
    );
    const patrolIds = new Set(
      subUnitPositions.map(pos => pos.patrolId).filter(Boolean),
    );

    return rosterItems.filter(person =>
      person.positions?.some(
        position =>
          denIds.has(position.denId) || patrolIds.has(position.patrolId),
      ),
    );
  },
);
