import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  children: PropTypes.node.isRequired,
};

const defaultProps = { value: false };

function ConditionalRender({
  value,
  children,
}: {
  children: React.ReactNode;
  value: unknown;
}) {
  if (!value) return null;
  return <React.Fragment>{children}</React.Fragment>;
}

ConditionalRender.propTypes = propTypes;
ConditionalRender.defaultProps = defaultProps;

export default ConditionalRender;
