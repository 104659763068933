import React, { useCallback, useEffect, useState } from 'react';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import LoopIcon from '@material-ui/icons/Loop';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { dictionaryIds, dictionarySel } from '@dict';
import { Col, Form, Input, Row, Select, emptyArr, intl } from '@shared';
import { unitInfoRequest, unitInfoSel } from '@unit';

import {
  AddressState,
  getLocalLocations,
  isLoadingLocationsSel,
  localLocationsListSel,
  localLocationsZipSel,
  searchLocationListSel,
  searchLocations,
} from '../../../../common';

const { Option, OptGroup } = Select;
const { Item: FormItem } = Form;

const ActivityLocation = ({ form, isDisabled }) => {
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const { primaryAddress: { zip5: unitZipCode = undefined } = {} } =
    useSelector(unitInfoSel);
  const localLocationsList = useSelector(localLocationsListSel);
  const localZipCode = useSelector(localLocationsZipSel);
  const searchResults = useSelector(searchLocationListSel) || emptyArr;
  const states =
    useSelector(state => dictionarySel(state, dictionaryIds.STATES)) ||
    emptyArr;
  const isLoadingLocations = useSelector(isLoadingLocationsSel);
  const [locationText, setLocationText] = useState();
  useEffect(() => {
    if (unitZipCode === undefined) {
      dispatch(unitInfoRequest());
    }
  }, [dispatch, unitZipCode]);

  useEffect(() => {
    if (unitZipCode && localZipCode !== unitZipCode) {
      dispatch(getLocalLocations(unitZipCode));
    }
  }, [dispatch, unitZipCode, localZipCode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchLocations = useCallback(
    debounce(searchText => {
      dispatch(searchLocations(searchText));
    }, 800),
    [debounce, searchLocations],
  );

  const onSearch = useCallback(
    searchText => {
      if (searchText) {
        fetchLocations(searchText);
        setLocationText(searchText);
      }
    },
    [setLocationText, fetchLocations],
  );

  const handleAnyLocationSelect = useCallback(() => {
    form.setFieldsValue({
      campId: undefined,
    });
  }, [form]);

  const populateLocation = useCallback(
    locationDetails => {
      const {
        zip5,
        state: locationState,
        addressLine1,
        city,
        id: campId,
      } = locationDetails;
      const { id: akelaStateId } = states.find(
        state => state.short === locationState,
      );
      form.setFieldsValue({
        zip5,
        akelaStateId,
        addressLine1,
        addressLine2: '',
        city,
        campId,
      });
    },
    [form, states],
  );
  getFieldDecorator('campId');
  return (
    <Row type="flex" justify="space-between">
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('location', {
            rules: [
              {
                max: 255,
                message: intl.formatMessage(
                  {
                    id: 'shared.maxCharacters',
                  },
                  { max: 255 },
                ),
              },
            ],
          })(
            <Select
              showSearch
              filterOption
              placeholder={intl.formatMessage({
                id: 'shared.location',
              })}
              size="large"
              optionFilterProp="text"
              notFoundContent={isLoadingLocations ? <LoopIcon /> : null}
              onSearch={onSearch}
              disabled={isDisabled}
              suffixIcon={<LocationOnIcon />}
              floatingLabel
              rounded
            >
              {isLoadingLocations
                ? []
                : [
                    locationText ? (
                      <Option
                        key="any_locations"
                        value={locationText}
                        text={locationText}
                        onClick={handleAnyLocationSelect}
                      >
                        {locationText}
                      </Option>
                    ) : null,
                    <OptGroup
                      key="local_locations"
                      label={
                        <FormattedMessage id="progress.ActivityLocation.local" />
                      }
                    >
                      {localLocationsList.map(locationItem => (
                        <Option
                          key={`local${locationItem.id}`}
                          value={locationItem.campName}
                          text={locationItem.campName}
                          onClick={() => populateLocation(locationItem)}
                        >
                          {locationItem.campName}
                        </Option>
                      ))}
                    </OptGroup>,
                    <OptGroup
                      key="search_results"
                      label={
                        <FormattedMessage id="progress.ActivityLocation.all" />
                      }
                    >
                      {searchResults.map(locationItem => (
                        <Option
                          key={`all${locationItem.id}`}
                          value={String(locationItem.campName)}
                          text={locationItem.campName}
                          onClick={() => populateLocation(locationItem)}
                        >
                          {locationItem.campName}
                        </Option>
                      ))}
                    </OptGroup>,
                  ]}
            </Select>,
          )}
        </FormItem>
      </Col>
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('addressLine1', {
            rules: [
              {
                max: 255,
                message: intl.formatMessage(
                  {
                    id: 'shared.maxCharacters',
                  },
                  { max: 255 },
                ),
              },
            ],
          })(
            <Input
              disabled={isDisabled}
              size="large"
              placeholder={intl.formatMessage({
                id: 'shared.address.address1.placeholder',
              })}
              suffix={<LocationOnIcon />}
              rounded
              floatingLabel
            />,
          )}
        </FormItem>
      </Col>
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('addressLine2', {
            rules: [
              {
                max: 255,
                message: intl.formatMessage(
                  {
                    id: 'shared.maxCharacters',
                  },
                  { max: 255 },
                ),
              },
            ],
          })(
            <Input
              disabled={isDisabled}
              size="large"
              placeholder={intl.formatMessage({
                id: 'shared.address.address2.placeholder',
              })}
              suffix={<LocationOnIcon />}
              rounded
              floatingLabel
            />,
          )}
        </FormItem>
      </Col>
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('city', {
            rules: [
              {
                max: 50,
                message: intl.formatMessage(
                  {
                    id: 'shared.maxCharacters',
                  },
                  { max: 50 },
                ),
              },
            ],
          })(
            <Input
              disabled={isDisabled}
              size="large"
              placeholder={intl.formatMessage({
                id: 'shared.address.city.placeholder',
              })}
              rounded
              floatingLabel
            />,
          )}
        </FormItem>
      </Col>
      <Col xs={24} lg={11}>
        <AddressState form={form} disabled={isDisabled} />
      </Col>
      <Col xs={24} lg={11}>
        <FormItem>
          {getFieldDecorator('zip5', {
            rules: [
              {
                pattern: /^(?:[0-9]{5})?$/,
                message: intl.formatMessage({
                  id: 'progress.ActivityLocation.field.zipCodeError',
                }),
              },
            ],
          })(
            <Input
              disabled={isDisabled}
              size="large"
              placeholder={intl.formatMessage({
                id: 'shared.address.zipCode.label',
              })}
              rounded
              floatingLabel
            />,
          )}
        </FormItem>
      </Col>
    </Row>
  );
};

ActivityLocation.propTypes = {
  // From Parent
  isDisabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  sectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ActivityLocation;
