import moment from 'moment';
import stable from 'stable';

import {
  activityTypeIds,
  activityValueTypes,
  activityValueTypesNames,
  activityValuesTabs,
  assistedEagleProjectId,
  dtoDateFormat,
} from '../../constants';
import { intl } from '../../localization';

export const positiveNumRule = () => ({
  validator: (_, value, callback) => {
    try {
      return +value >= 0;
    } catch (error) {
      callback(error);
    }
  },
  message:
    intl &&
    intl.formatMessage(
      {
        id: 'shared.form.error.greaterOrEqualThan',
      },
      {
        num: 0,
      },
    ),
});

export const requiredRule = () => ({
  required: true,
  message:
    intl &&
    intl.formatMessage({
      id: 'shared.form.error.isRequired',
    }),
});

export const daysLessOrEqualRule = (diff, isDay) => ({
  validator: (_, value, callback) => {
    try {
      return Number(value) <= diff;
    } catch (error) {
      callback(error);
    }
  },
  message:
    intl &&
    intl.formatMessage({
      id: `progress.Form.error.bigger${isDay ? 'Days' : 'Nights'}`,
    }),
});

export const setTime = (hour = 0, minute = 0, second = 0) =>
  moment().set({ hour, minute, second });

export const activityUserValues = (
  activityTypeId,
  activeTab,
  userType,
  activityData,
  users = [],
  organizationGuid,
  loggedInUserId,
) => {
  const isBasic = activeTab === activityValuesTabs.BASIC;
  const commonData = {
    organizationGuid,
    isApproved: true,
    leaderApprovedId: loggedInUserId,
    leaderApprovedDate: moment().format(dtoDateFormat),
  };

  const getValue = (activityValueTypeName, userId) =>
    activityData[
      `${activityValueTypeName}${userType}${isBasic ? 'Basic' : `_${userId}`}`
    ] || 0;

  if (activityTypeId === activityTypeIds.CAMPOUTS) {
    return users.map(user => {
      const days = getValue(activityValueTypesNames.DAYS, user.userId);
      const nights = getValue(activityValueTypesNames.NIGHTS, user.userId);
      const frostPoints = getValue(
        activityValueTypesNames.FROST_POINTS,
        user.userId,
      );
      return {
        ...commonData,
        userId: user.userId,
        activityValues: [
          {
            activityValueTypeId: activityValueTypes.DAYS,
            activityValue: +days,
          },
          {
            activityValueTypeId: activityValueTypes.NIGHTS,
            activityValue: +nights,
          },
          {
            activityValueTypeId: activityValueTypes.FROST_POINTS,
            activityValue: +frostPoints,
          },
        ],
      };
    });
  }

  if (activityTypeId === activityTypeIds.HIKES) {
    return users.map(user => {
      const miles = getValue(activityValueTypesNames.MILES, user.userId);
      const lowestPoint = getValue(
        activityValueTypesNames.LOWEST_POINT,
        user.userId,
      );
      const highestPoint = getValue(
        activityValueTypesNames.HIGHEST_POINT,
        user.userId,
      );
      return {
        ...commonData,
        userId: user.userId,
        activityValues: [
          {
            activityValueTypeId: activityValueTypes.MILES,
            activityValue: +miles,
          },
          {
            activityValueTypeId: activityValueTypes.LOWEST_POINT,
            activityValue: +lowestPoint,
          },
          {
            activityValueTypeId: activityValueTypes.HIGHEST_POINT,
            activityValue: +highestPoint,
          },
        ],
      };
    });
  }

  if (activityTypeId === activityTypeIds.SERVICE_PROJECTS) {
    return users.map(user => {
      const hours = getValue(activityValueTypesNames.HOURS, user.userId);
      return {
        ...commonData,
        userId: user.userId,
        activityValues: [
          {
            activityValueTypeId: activityValueTypes.HOURS,
            activityValue: +hours,
          },
        ],
      };
    });
  }

  if (activityTypeId === activityTypeIds.LONG_CRUISE) {
    return users.map(user => {
      const days = getValue(activityValueTypesNames.DAYS, user.userId);
      return {
        ...commonData,
        userId: user.userId,
        activityValues: [
          {
            activityValueTypeId: activityValueTypes.LONG_CRUISE_DAYS,
            activityValue: +days,
          },
        ],
      };
    });
  }
};

export const hasDifferentValues = activityUsers => {
  let hasDifferentValues = false;
  if (activityUsers.length) {
    const activityValues = activityUsers.reduce(
      (actVal, currentItem) => [...actVal, ...currentItem.activityValues],
      [],
    );

    activityUsers[0].activityValues.forEach(item => {
      const filteredByType = activityValues.filter(
        val => val.activityValueTypeId === item.activityValueTypeId,
      );
      if (
        filteredByType.some(val => val.activityValue !== item.activityValue)
      ) {
        return (hasDifferentValues = true);
      }
    });
  }
  return hasDifferentValues;
};

export const getSetFormActivityValues = (
  activityTypeId,
  initialTab,
  activeTab,
  userType,
  users = [],
) => {
  const isBasic = activeTab === activityValuesTabs.BASIC;
  const initialTabIsIndividual = initialTab === activityValuesTabs.ADVANCED;
  const valuesToReturn = {};

  const getValue = (activityValueTypeId, activityValues) => {
    const found = activityValues.find(
      val => val.activityValueTypeId === activityValueTypeId,
    );
    return found ? `${found.activityValue}` : '0';
  };

  if (activityTypeId === activityTypeIds.CAMPOUTS) {
    users.forEach(user => {
      const { activityValues, userId } = user;
      const days = getValue(activityValueTypes.DAYS, activityValues);
      const nights = getValue(activityValueTypes.NIGHTS, activityValues);
      const frostPoints = getValue(
        activityValueTypes.FROST_POINTS,
        activityValues,
      );

      valuesToReturn[
        `${activityValueTypesNames.DAYS}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : days;
      valuesToReturn[
        `${activityValueTypesNames.NIGHTS}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : nights;
      valuesToReturn[
        `${activityValueTypesNames.FROST_POINTS}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : frostPoints;
    });
    return valuesToReturn;
  }

  if (activityTypeId === activityTypeIds.HIKES) {
    users.forEach(user => {
      const { activityValues, userId } = user;
      const miles = getValue(activityValueTypes.MILES, activityValues);
      const lowestPoint = getValue(
        activityValueTypes.LOWEST_POINT,
        activityValues,
      );
      const highestPoint = getValue(
        activityValueTypes.HIGHEST_POINT,
        activityValues,
      );

      valuesToReturn[
        `${activityValueTypesNames.MILES}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : miles;
      valuesToReturn[
        `${activityValueTypesNames.LOWEST_POINT}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : lowestPoint;
      valuesToReturn[
        `${activityValueTypesNames.HIGHEST_POINT}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : highestPoint;
    });
    return valuesToReturn;
  }

  if (activityTypeId === activityTypeIds.SERVICE_PROJECTS) {
    users.forEach(user => {
      const { activityValues, userId } = user;
      const hours = getValue(activityValueTypes.HOURS, activityValues);

      valuesToReturn[
        `${activityValueTypesNames.HOURS}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : hours;
    });
    return valuesToReturn;
  }

  if (activityTypeId === activityTypeIds.LONG_CRUISE) {
    users.forEach(user => {
      const { activityValues, userId } = user;
      const days = getValue(
        activityValueTypes.LONG_CRUISE_DAYS,
        activityValues,
      );

      valuesToReturn[
        `${activityValueTypesNames.DAYS}${userType}${
          isBasic ? 'Basic' : `_${userId}`
        }`
      ] = isBasic && initialTabIsIndividual ? '0' : days;
    });
    return valuesToReturn;
  }
};

export const getOperationalUsers = (
  formAdults = [],
  formYouths = [],
  originalAdults = [],
  originalYouths = [],
) => {
  const formUsers = formAdults.concat(formYouths);
  const oginialUsers = originalAdults.concat(originalYouths);

  const usersToAdd = formUsers.filter(
    formUser => !oginialUsers.some(ogUser => ogUser.userId === formUser.userId),
  );

  const foundInForm = [];
  const usersToUpdate = oginialUsers
    .filter(ogUser =>
      formUsers.some(formUser => {
        if (ogUser.userId === formUser.userId) {
          foundInForm.push(formUser);
          return formUser;
        }
      }),
    )
    .map((user, index) => {
      const ogActivitiesValues = user.activityValues || [];
      const formActivitiesValues = foundInForm[index].activityValues || [];

      const updatedActivitiesValues = ogActivitiesValues.map(ogActValue => {
        const foundFormActValue = formActivitiesValues.find(
          ({ activityValueTypeId }) =>
            activityValueTypeId === ogActValue.activityValueTypeId,
        );
        return foundFormActValue
          ? { ...ogActValue, activityValue: foundFormActValue.activityValue }
          : ogActValue;
      });

      return { ...user, activityValues: updatedActivitiesValues, note: 'N/A' };
    });

  const usersToDelete = oginialUsers
    .filter(
      ogUser => !formUsers.some(formUser => ogUser.userId === formUser.userId),
    )
    .map(({ activityValues }) => activityValues)
    .flat();

  return { usersToAdd, usersToUpdate, usersToDelete };
};

export const getNewUsersEvent = (
  eventUsers = [],
  formAdults = [],
  formYouths = [],
) => {
  const formUsers = formAdults.concat(formYouths);

  return formUsers
    .filter(
      formUser => !eventUsers.some(evUser => evUser.userId === formUser.userId),
    )
    .map(user => ({
      userId: user.userId,
      attended: true,
      primaryLeader: false,
    }));
};

export const getNonRegisteredData = (
  activityTypeId,
  formData,
  nonRegisteredOrgParticipants,
) => {
  if (activityTypeId === activityTypeIds.SERVICE_PROJECTS) {
    const {
      nonRegisteredAdultCount: adultCount,
      nonRegisteredAdultHours: adultHours,
      nonRegisteredYouthCount: youthCount,
      nonRegisteredYouthHours: youthHours,
    } = formData;

    const nonRegisteredAdultCount = adultCount ? +adultCount : 0;
    const nonRegisteredAdultHours =
      nonRegisteredAdultCount && adultHours ? +adultHours : 0;
    const nonRegisteredYouthCount = youthCount ? +youthCount : 0;
    const nonRegisteredYouthHours =
      nonRegisteredYouthCount && youthHours ? +youthHours : 0;

    return nonRegisteredOrgParticipants
      ? {
          nonRegisteredOrgParticipants: {
            ...nonRegisteredOrgParticipants,
            materialCost: 0,
            projectCount: 0,
            nonRegisteredAdultCount,
            nonRegisteredAdultHours,
            nonRegisteredYouthCount,
            nonRegisteredYouthHours,
          },
        }
      : {
          nonRegisteredAdultCount,
          nonRegisteredAdultHours,
          nonRegisteredYouthCount,
          nonRegisteredYouthHours,
        };
  }

  return {};
};

export const getSortedCategories = (allCategories, activityTypeId) => {
  const eagleCategories = [];
  const nonEagleCategories = [];

  if (activityTypeId !== activityTypeIds.SERVICE_PROJECTS) {
    return { eagleCategories, nonEagleCategories };
  }

  const filteredCategories = allCategories
    //get current category and remove the unused generic assisted-eagle-project category
    .filter(
      ({ actTypeId, id }) =>
        actTypeId === activityTypeId && id !== assistedEagleProjectId,
    )
    //remove expired categories
    .filter(
      ({ expiryDt }) =>
        !expiryDt || (expiryDt && moment(expiryDt).isAfter(moment())),
    );

  const sorted = stable(filteredCategories, (a, b) =>
    String(a.name).localeCompare(b.name),
  );

  sorted.forEach(category => {
    if (category.name.includes('(Eagle Project)')) {
      return eagleCategories.push(category);
    }
    return nonEagleCategories.push(category);
  });

  return { eagleCategories, nonEagleCategories };
};

export const getEventLength = (startDate, endDate) => {
  const hours = endDate.diff(startDate, 'hours');
  const days = Math.ceil(hours / 24);

  return {
    days,
    hours,
    nights: days - 1,
  };
};
