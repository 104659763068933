import { eagleScoutApplicationModuleNamespace as namespace } from '../../common';

export const OPEN_EAGLE_SCOUT_APPLICATION = `${namespace}/OPEN_EAGLE_SCOUT_APPLICATION`;
export const openEagleScoutApplication = ({ youthName, userId }) => ({
  type: OPEN_EAGLE_SCOUT_APPLICATION,
  payload: { youthName, userId },
});
export const CLOSE_EAGLE_SCOUT_APPLICATION = `${namespace}/CLOSE_EAGLE_SCOUT_APPLICATION`;
export const closeEagleScoutApplication = () => ({
  type: CLOSE_EAGLE_SCOUT_APPLICATION,
});
