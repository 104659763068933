// Google Tag Manager and Analytics helpers

const eventPrefix = 'sbl-gtm/';

const addEventPrefix = event => `${eventPrefix}${event}`;

// event categories
// see more about categories, actions, labels and values here: https://support.google.com/analytics/answer/1033068?hl=en
const categories = Object.freeze({
  ADVANCEMENTS: 'Advancements',
  ADULT_PROFILE: 'Profile',
  API: 'API',
  APP_CRASH: 'App crash',
  ACTIVITIES: 'Activities',
  CALENDAR: 'Calendar',
  CAMPOUTS: 'Campouts',
  CONTEXT: 'Context',
  FEEDBACK: 'Feedback',
  FORUM: 'Forum',
  HIKES: 'Hikes',
  MERIT_BADGE_COUNSELORS: 'Merit Badge Counselors',
  PROFILE: 'Profile',
  RECHARTER: 'Recharter',
  REPORTS: 'Reports',
  ROLES: 'Roles',
  ROSTER: 'Roster',
  SCOUTBOOK_LEGACY: 'Scoutbook Legacy',
  SERVICE_HOURS: 'Service hours',
  SETTINGS: 'Settings',
  USER: 'User',
  CALENDAR_EVENT: 'Calendar Event',
  WELCOME_WIZARD: 'Welcome wizard',
  COUNCIL_UNITS: 'Council units',
  COUNSELED_YOUTH: 'Counseled Youth',
  LONG_CRUISE: 'Long Cruise',
  PERMISSIONS: 'Permissions',
});

function push(params) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(params);
}

function setUserRole(role) {
  push({
    event: addEventPrefix('SET_USER_ROLE'),
    category: categories.CONTEXT,
    value: role,
    role,
  });
}

function pushEvent({
  event, // translates to 'Action' in GTM, it is redux action type in the case of redux actions
  category, // e.g. 'Advancements', use the dictionary above
  label, // extra info, e.g. advancement name, file name, login, etc...
  value, // integer, extra numerical value, e.g. time to upload/download/load something
}) {
  push({
    event: addEventPrefix(event),
    category,
    label,
    value,
  });
}

export default {
  categories,
  setUserRole,
  pushEvent,
  push,
};
