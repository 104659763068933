import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { Hr, T } from '@shared';

import { groupYouthsAdults } from '../../../../common';
import { activeTabSel } from '../../../duck';
import styles from './LongCruiseStats.less';

const getFormNumberValue = (form, key) => Number(form.getFieldValue(key)) || 0;

const getTotalDaysBasic = (form, persons) => {
  const { youths, adults } = groupYouthsAdults(persons);
  return (
    youths.length * getFormNumberValue(form, 'basicYouthDays') +
    adults.length * getFormNumberValue(form, 'basicAdultDays')
  );
};

const getTotalDaysAdvanced = form => {
  const formValues = form.getFieldsValue();
  const youthDays = formValues.youthDays || {};
  const adultDays = formValues.adultDays || {};

  return [...Object.values(youthDays), ...Object.values(adultDays)]
    .map(Number)
    .filter(val => !isNaN(val))
    .reduce((sum, miles) => sum + miles, 0);
};

const getTotalDays = (form, persons, activeTab) => {
  if (activeTab === 'basic') {
    return getTotalDaysBasic(form, persons);
  } else {
    return getTotalDaysAdvanced(form);
  }
};

const LongCruiseStats = ({ form, persons }) => {
  const activeTab = useSelector(activeTabSel);
  const totalDays = getTotalDays(form, persons, activeTab);
  const totalPeople = persons.length;

  return (
    <React.Fragment>
      <div className={styles.stats}>
        <T size="5" bold>
          <FormattedMessage
            id="LongCruiseForm.LongCruiseStats.totalDays"
            values={{ days: totalDays }}
          />
        </T>
        <T size="5" bold>
          <FormattedMessage
            id="progress.common.totalPeople"
            values={{ people: totalPeople }}
          />
        </T>
      </div>
      <Hr className={styles.statsHr} />
    </React.Fragment>
  );
};

LongCruiseStats.propTypes = {
  form: PropTypes.object.isRequired,
  persons: PropTypes.array.isRequired,
};

export default LongCruiseStats;
