import { esbApiService } from '@utils';

/**
 * @esbEndpoint POST /advancements/:organizationGuid/sbExtendedProfiles
 */
const getSBExtendedProfile$ = (data, organizationGuid) =>
  esbApiService.post$(
    `/advancements/${organizationGuid}/sbExtendedProfiles`,
    data,
    {
      gtm: {
        label: '/advancements/{organizationGuid}/sbExtendedProfiles',
      },
    },
  );

/**
 * @esbEndpoint GET /organizations/v2/units/:organizationGuid/parents
 */
const getParentProfile$ = organizationGuid =>
  esbApiService.get$(`/organizations/v2/units/${organizationGuid}/parents`, {
    gtm: {
      label: '/organizations/v2/units/{organizationGuid}/parents',
    },
  });

/**
 * @esbEndpoint GET /advancements/:organizationGuid/sbExtendedProfiles
 */
const fetchQESchools$ = (name = '', city = '', state = '', zip = '') =>
  esbApiService.get$(
    `/lookups/person/schools?${name ? `name=${name}` : ''}${
      city ? `&city=${city}` : ''
    }${state ? `&state=${state}` : ''}${zip ? `&zip=${zip}` : ''}`,
    {
      gtm: {
        label: '/lookups/person/schools',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/v2/:organizationGuid/sbSchoolInformation/quickEntry
 */
const saveSBSchoolInformation$ = (data, organizationGuid) =>
  esbApiService.post$(
    `/advancements/v2/${organizationGuid}/sbSchoolInformation/quickEntry`,
    data,
    {
      gtm: {
        label:
          '/advancements/v2/${organizationGuid}/sbSchoolInformation/quickEntry',
      },
    },
  );

/**
 * @esbEndpoint POST /advancements/:organizationGuid/sbEducationRecords
 */
const getSBEducationRecords$ = (data, organizationGuid) =>
  esbApiService.post$(
    `/advancements/${organizationGuid}/sbEducationRecords`,
    data,
    {
      gtm: {
        label: '/advancements/${organizationGuid}/sbEducationRecords',
      },
    },
  );

/**
 * @esbEndpoint GET /advancements/v2/ranks/:rankId
 */
const fetchRankVersions$ = rankId => {
  const endpoint = `/advancements/v2/ranks/${rankId}`;

  return esbApiService.get$(endpoint, {
    gtm: {
      label: `advancements/v2/ranks/${rankId}`,
    },
  });
};

/**
 * @esbEndpoint GET /advancements/v2/ranks/:rankId/requirements?versionId=:versionId
 */
const fetchRequirementsByRankAndVersion$ = (rankId, versionId) => {
  const endpoint = `/advancements/v2/ranks/${rankId}/requirements?versionId=${versionId}`;

  return esbApiService.get$(endpoint, {
    gtm: {
      label:
        '/advancements/v2/ranks/${rankId}/requirements?versionId=${versionId}',
    },
  });
};

/**
 * @esbEndpoint POST /advancements/v2/youth/ranks/:rankId/requirements
 */
const bulkUpdateRequirements$ = (rankId, data) => {
  const endpoint = `/advancements/v2/youth/ranks/${rankId}/requirements`;

  return esbApiService.post$(endpoint, data, {
    gtm: {
      label: '/advancements/v2/youth/ranks/${rankId}/requirements',
    },
  });
};

export default {
  getSBExtendedProfile$,
  getParentProfile$,
  fetchQESchools$,
  saveSBSchoolInformation$,
  getSBEducationRecords$,
  fetchRankVersions$,
  fetchRequirementsByRankAndVersion$,
  bulkUpdateRequirements$,
};
