import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { forEach, reduce } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  TableMobile,
  hasPermissionSel,
  permissions,
  selectedOrganizationSel,
  sorterPropTypes,
} from '@shared';
import { toggleArrayItem } from '@utils';

import {
  currentUserSubunitsSel,
  fieldsVisibilitySel,
  showSubUnitsSel,
} from '../../../duck';
import CustomMainRow from '../PackRosterTable/MainRow';
import ActionFab from './ActionFab';
import PackRosterListHeader from './PackRosterListHeader';
import PackRosterListTopHeader from './PackRosterListTopHeader/index.ts';
import rows from './packRosterListRows';

const PackRosterList = props => {
  const fieldsVisibility = useSelector(fieldsVisibilitySel);
  const mySubUnits = useSelector(currentUserSubunitsSel);
  const { canEditSubCalendar: hasSubUnitOnlyPerms } = useSelector(
    selectedOrganizationSel,
  );
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );
  const showSubUnits = useSelector(showSubUnitsSel);
  const [defaultSubUnitList, setDefaultSubUnitList] = useState([]);

  useEffect(() => {
    const unnasignedMembers = props.items || [];
    const values = unnasignedMembers.map(member => ({
      userId: member.userId,
      subUnitId: member.subUnitId,
    }));
    setDefaultSubUnitList(values);
  }, [props.items]);

  const handleItemClick = ({ userId }) => {
    const { onSelectedRowKeysChange, selectedRowKeys } = props;
    const newKeys = toggleArrayItem(selectedRowKeys, userId);
    if (userId) {
      onSelectedRowKeysChange(newKeys);
    }
  };

  const getItems = () => {
    const { items, itemsPerSubUnit, subUnitViewActive } = props;

    if (!subUnitViewActive) {
      return items;
    }

    return reduce(
      itemsPerSubUnit,
      (result, item) => {
        result.push({
          row: (
            <CustomMainRow
              denType={item.denType}
              hideExpanded
              itemsCount={item.subRows.length}
              subUnitId={item.subUnitId}
              subUnitName={item.subUnitName}
              subUnitNameRaw={item.subUnitNameRaw}
            />
          ),
          key: item.key,
          type: 'custom',
        });

        forEach(item.subRows, row => {
          result.push(row);
        });

        return result;
      },
      [],
    );
  };

  const renderHeader = () => {
    const {
      items,
      searchPinned,
      selectedRowKeys,
      onSelectedRowKeysChange,
      filter,
    } = props;

    return (
      <PackRosterListHeader
        items={items}
        searchPinned={searchPinned}
        selectedRowKeys={selectedRowKeys}
        onSelectedRowKeysChange={onSelectedRowKeysChange}
        filter={filter}
      />
    );
  };

  const {
    loading,
    rosterReady,
    selectedRowKeys,
    selectedItems,
    isOffline,
    onGoToYouthProfile,
    onGoToAdultProfile,
    subUnitType,
    isSubUnitAllowed,
    approvedSubUnits,
  } = props;
  const items = getItems();

  return (
    <>
      <PackRosterListTopHeader isOffline={isOffline} />
      <div key="wrapper">
        <TableMobile
          className={cn('Joyride__PackRosterList')}
          items={items}
          loading={loading}
          title={renderHeader()}
          rows={rows({
            onGoToYouthProfile,
            onGoToAdultProfile,
            fieldsVisibility,
            isOffline,
            subUnitType,
            isSubUnitAllowed,
            approvedSubUnits,
            mySubUnits,
            canEditSubUnits,
            showSubUnits,
            hasSubUnitOnlyPerms,
            defaultSubUnitList,
          })}
          rowId="userId"
          selectedKeys={selectedRowKeys}
          onItemClick={handleItemClick}
        />
      </div>
      <ActionFab
        visible={rosterReady}
        selectedItems={selectedItems}
        isOffline={isOffline}
      />
    </>
  );
};

PackRosterList.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerSubUnit: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  rosterReady: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  searchPinned: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  sorter: sorterPropTypes,
  subUnitViewActive: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  fieldsVisibility: PropTypes.object.isRequired,
  onSelectedRowKeysChange: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  onGoToAdultProfile: PropTypes.func.isRequired,
  subUnitType: PropTypes.string,
  isSubUnitAllowed: PropTypes.bool.isRequired,
  approvedSubUnits: PropTypes.array,
};

export default PackRosterList;
