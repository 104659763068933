import { PaymentTypes } from '@appTypes/enums';

import { PaymentLogsSliceState } from './types';

export const moduleName = 'paymentLogs';

export const UNIT_SUMMARY_ORDER = [
  { label: 'totalCash', tooltip: false },
  { label: 'feesReceivable', tooltip: true },
  { label: 'totalAssets', tooltip: true },
  { label: 'totalLiabilities', tooltip: true },
  { label: 'netAssets', tooltip: false },
] as const;

export const UNIT_PAYMENT_TYPES = [
  {
    label: 'paymentReceived',
    key: PaymentTypes.PAYMENT,
  },
  { label: 'paymentMade', key: PaymentTypes.CHARGE },
] as const;

export const MEMBER_PAYMENT_TYPES = [
  { label: 'charge', key: PaymentTypes.CHARGE },
  { label: 'credit', key: PaymentTypes.CREDIT },
  {
    label: 'debit',
    key: PaymentTypes.DEBIT,
  },
  {
    label: 'payment',
    key: PaymentTypes.PAYMENT,
  },
] as const;

export const API_DATE_FORMAT = 'YYYY-MM-DD';

export enum PAYMENT_LOG_TABS {
  ROSTER = 'roster',
  PENDING_LOGS = 'pendingLogs',
  HISTORY = 'history',
}

export const initialSliceState: PaymentLogsSliceState = {
  paymentLogsActiveTab: PAYMENT_LOG_TABS.ROSTER,
  selectedRowKeys: [],
  searchFilter: '',
  filters: {
    memberType: 'all',
  },
  sorter: {
    columnKey: 'name',
    order: 'asc',
  },
};
