import { Observable } from 'rxjs';

import { personNameBuilder } from '@utils';

import { meritBadgeCounselorsModuleName } from './constants';

//
// Selectors
//

export const meritBadgeCounselorsModuleSel = state =>
  state[meritBadgeCounselorsModuleName];

//
// Services
//

const mapMbcResponseToModel = res => {
  const { numberOfCounselors, meritBadgeCounselors } = res;

  const items = meritBadgeCounselors.map(
    ({
      personGuid,
      memberId,
      userId,
      firstName,
      lastName,
      gender,
      yptStatus,
      yptExpireDate,
      isCouncilApproved,
      primaryEmailAddress,
      primaryPhone,
      primaryAddress,
      districtInformation,
      councilInformation,
      meritBadges,
      workingWithScoutPreference,
      workingWithScouts,
    }) => {
      const meritBadgeIds = meritBadges.map(({ id }) => id);
      const meritBadgeNames = meritBadges
        .map(({ meritBadge }) => meritBadge)
        .join(', ');

      return {
        personGuid,
        memberId,
        userId,
        firstName,
        lastName,
        name: personNameBuilder.short({ firstName, lastName }),
        gender,
        yptStatus,
        yptExpireDate,
        isCouncilApproved,
        email: primaryEmailAddress,
        phone: primaryPhone,
        address: primaryAddress,
        districtInformation,
        councilInformation,
        meritBadgeIds,
        meritBadgeNames,
        workingWithScoutPreference,
        workingWithScoutsCount: workingWithScouts.length,
      };
    },
  );

  return {
    total: numberOfCounselors,
    items,
  };
};

export const getMbcs$ = (/* {pageSize, page, filter} */) =>
  // TODO: check about this feature
  // OLD comment:
  // TODO: replace with actual server call when API is ready - https://jira.scouting.org/browse/EAGRE-2134
  Observable.of(
    mapMbcResponseToModel({
      numberOfCounselors: 0,
      meritBadgeCounselors: [],
    }),
  );
