import { normalizeAdvancementType } from '@shared/utils';
import { getAdvancementTypeTranslation } from '@shared/localization';

export default (advancementName, advancementType) => {
  const normalizedAdvancementType = normalizeAdvancementType(advancementType);
  const advancementTypeLabel = getAdvancementTypeTranslation(
    normalizedAdvancementType,
  );
  return `${advancementTypeLabel}: ${advancementName}`;
};
