import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { T, S, DateView, intl, Row, Col } from '@shared';
import ToggleDetailsButton from './ToggleDetailsButton';
import styles from './ProjectSummary.less';
import { selectedActivitySel } from '../../duck';

class ProjectSummary extends React.PureComponent {
  renderContent() {
    const { type, selectedActivity } = this.props;
    const { startDateTime, endDateTime, category, location, name } =
      selectedActivity || {};
    const sharedInfo = (
      <React.Fragment>
        <Col span={24}>
          <S size="4" bold colored>
            {name}
          </S>
        </Col>
        <Col span={24}>
          <S size="4">
            <FormattedMessage id="progress.ProjectSummary.date" />:{' '}
            {startDateTime && <DateView value={startDateTime} />}
          </S>
        </Col>
        <Col span={24}>
          <S size="4">
            <FormattedMessage id="shared.date.startTime" />:{' '}
            <DateView value={startDateTime} format="h:mm a" />
          </S>
        </Col>
        <Col span={24}>
          <S size="4">
            <FormattedMessage id="shared.date.endTime" />:{' '}
            <DateView value={endDateTime} format="h:mm a" />
          </S>
        </Col>
        {location && (
          <Col span={24}>
            <S size="4">
              <FormattedMessage id="progress.ProjectSummary.location" />:{' '}
              {location}
            </S>
          </Col>
        )}
      </React.Fragment>
    );
    let notSharedInfo = null;
    switch (type) {
      case 'longCruise':
      case 'service': {
        notSharedInfo = (
          <React.Fragment>
            {!!category && (
              <Col span={24}>
                <S size="4">
                  <FormattedMessage id="progress.ProjectSummary.category" />:{' '}
                  {category}
                </S>
              </Col>
            )}
          </React.Fragment>
        );
        break;
      }
      default:
        break;
    }
    return (
      <Row type="flex" justify="end">
        {sharedInfo}
        {notSharedInfo}
        <Col>{this.renderToggleDetailsButton()}</Col>
      </Row>
    );
  }

  renderToggleDetailsButton() {
    const { className, expanded, onClick } = this.props;
    return (
      <ToggleDetailsButton
        className={className}
        expanded={expanded}
        onClick={onClick}
      />
    );
  }

  getActivityTranslation = () => {
    const { type } = this.props;
    let activity;
    switch (type) {
      case 'longCruise': {
        activity = intl.formatMessage({ id: 'progress.longCruise.label' });
        break;
      }
      default: {
        activity = intl.formatMessage({
          id: 'progress.ProjectSummary.project',
        });
      }
    }
    return {
      activity,
    };
  };

  render() {
    return (
      <div className={styles.container}>
        <T.Responsive className={styles.title}>
          <FormattedMessage
            values={this.getActivityTranslation()}
            id="progress.ProjectSummary.customSummary"
          />
        </T.Responsive>
        {this.renderContent()}
      </div>
    );
  }
}

ProjectSummary.propTypes = {
  type: PropTypes.oneOf(['campouts', 'hikes', 'service', 'longCruise'])
    .isRequired,
  expanded: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  // from redux
  selectedActivity: PropTypes.object.isRequired,
};

const mapState = state => ({
  selectedActivity: selectedActivitySel(state),
});

export default connect(mapState)(ProjectSummary);
