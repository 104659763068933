import React, { useLayoutEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import merge from 'lodash/merge';

import {
  Row,
  Col,
  T,
  S,
  // constants
  activityTypeForActivityTypeId,
} from '@shared';

import { youthsAndAdultsSel } from '../../../../duck';

import {
  formHookValidationRules,
  basicConfig,
  formHookValidationLessOrEqualRule,
} from '../../../../../common';

import ActivityValueInput from '../ActivityValueInput';

import styles from './BasicTab.less';

const checkIfHasDiffDateProp = inputsArray =>
  inputsArray.some(([, , , isDiffDate]) => isDiffDate);

// eslint-disable-next-line react/display-name
const BasicTab = React.memo(
  ({ isActive, showDescription, activityTypeId, diffDays }) => {
    const { youths, adults } = useSelector(youthsAndAdultsSel);
    const { setValue, getValues } = useFormContext();

    const showAdults = adults.length > 0;
    const showYouth = youths.length > 0;

    const basicInputs = basicConfig[activityTypeId];

    const activityType = activityTypeForActivityTypeId[activityTypeId];
    const mergePersons = !!basicInputs.merge;

    useLayoutEffect(() => {
      const configValues = mergePersons
        ? basicInputs.merge
        : [...basicInputs.youths, ...basicInputs.adults];
      let hasDiffDateValues = checkIfHasDiffDateProp(configValues);
      if (diffDays !== null && diffDays >= 0 && hasDiffDateValues) {
        const fieldsToSet = configValues
          .filter(([, , , isDayDate]) => isDayDate !== undefined)
          .reduce(
            (acc, [inputKey, , , isDayDate]) => ({
              ...acc,
              [inputKey]: `${diffDays + (isDayDate ? 1 : 0) || 0}`,
            }),
            {},
          );
        Object.keys(fieldsToSet).forEach(fieldKey => {
          const fieldValue = fieldsToSet[fieldKey];
          setValue(fieldKey, fieldValue);
        });
      }
    }, [
      diffDays,
      setValue,
      getValues,
      mergePersons,
      basicInputs,
      showYouth,
      showAdults,
    ]);

    const getRules = useCallback(
      inputsArray => {
        const rulesObject = {};
        inputsArray.forEach(
          ([
            inputKey,
            isRequired,
            validateFloatValue,
            isDayDate,
            enableInput,
          ]) => {
            rulesObject[inputKey] = formHookValidationRules(
              isActive,
              validateFloatValue,
              isRequired,
            );
            if (isDayDate !== undefined && isActive && enableInput) {
              rulesObject[inputKey] = merge(
                rulesObject[inputKey],
                formHookValidationLessOrEqualRule(
                  diffDays + (isDayDate ? 1 : 0),
                  {
                    isDayDate,
                  },
                ),
              );
            }
          },
        );
        return rulesObject;
      },
      [isActive, diffDays],
    );

    const getFormFields = useCallback(
      (inputsArray, type) => {
        const inputRules = getRules(inputsArray);
        return (
          <Row type="flex">
            <Col span={24}>
              <T size="5" bold colored colon>
                <FormattedMessage
                  id={`progress.activity.ActivityTabs.${type}`}
                />
              </T>
            </Col>
            {inputsArray.map(
              ([inputKey, isRequired, , isDayDate, enableInput], index) => (
                <Col xs={24} lg={8} key={inputKey}>
                  <ActivityValueInput
                    key={inputKey}
                    valueName={
                      <React.Fragment>
                        <FormattedMessage
                          id={`progress.activity.ActivityTabs.inputTitle.${basicInputs.translationKeys[index]}`}
                        />
                        {isRequired && '*'}
                      </React.Fragment>
                    }
                    name={inputKey}
                    disabled={enableInput ? false : isDayDate !== undefined}
                    rules={inputRules[inputKey]}
                    isActive={isActive}
                  />
                </Col>
              ),
            )}
          </Row>
        );
      },
      [basicInputs, getRules, isActive],
    );

    return (
      <React.Fragment>
        {showDescription && (
          <S size="6" className={styles.description}>
            <FormattedMessage
              id={`progress.activity.ActivityTabs.${activityType}.basicDescription`}
              values={{
                tabName: (
                  <span className={styles.descriptionTabName}>
                    <FormattedMessage id="progress.activity.ActivityTabs.basic" />
                  </span>
                ),
              }}
            />
          </S>
        )}
        {showYouth &&
          !mergePersons &&
          getFormFields(basicInputs.youths, 'youth')}
        {showAdults &&
          !mergePersons &&
          getFormFields(basicInputs.adults, 'adult')}
        {mergePersons &&
          (showYouth || showAdults) &&
          getFormFields(basicInputs.merge, basicInputs.mergeTitleKey)}
      </React.Fragment>
    );
  },
);

BasicTab.propTypes = {
  isActive: PropTypes.bool.isRequired,
  activityTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  diffDays: PropTypes.number,
  showDescription: PropTypes.bool,
};

export default BasicTab;
