import React, { FormEvent } from 'react';

import { FormComponentProps } from 'antd/lib/form';
import { Form } from 'bsa-ui';
import { FormattedMessage } from 'react-intl';

import { Button, Input, S } from '@modules/shared';

const LAST_NAME_FIELD = 'lastName';
const MEMBER_ID_FIELD = 'memberId';

type Props = {
  onSearch: (params: {
    [LAST_NAME_FIELD]: string;
    [MEMBER_ID_FIELD]: string;
  }) => void;
} & FormComponentProps;

const AddDenChiefForm: React.FC<Props> = ({ form, onSearch }) => {
  const { getFieldDecorator, validateFields } = form;

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onSearch(values);
      }
    });
  };

  return (
    <div>
      <S size="4">
        <FormattedMessage
          id={'packRoster.addIndividual.addDenChiefModal.subtitle'}
        />
      </S>
      <Form onSubmit={handleSearch}>
        <Form.Item
          label={
            <FormattedMessage
              id={'packRoster.addIndividual.denChiefSearch.lastName'}
            />
          }
        >
          {getFieldDecorator(LAST_NAME_FIELD, {
            rules: [
              {
                required: true,
                message: <FormattedMessage id="shared.form.error.isRequired" />,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item
          label={
            <FormattedMessage
              id={'packRoster.addIndividual.denChiefSearch.memberId'}
            />
          }
        >
          {getFieldDecorator(MEMBER_ID_FIELD, {
            rules: [
              {
                required: true,
                message: <FormattedMessage id="shared.form.error.isRequired" />,
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            shape="round"
            shadow
            size="large"
            fitText
            uppercase={true}
          >
            <FormattedMessage
              id={'packRoster.addIndividual.denChiefSearch.search'}
            />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Form.create<Props>()(AddDenChiefForm);
