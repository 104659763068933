import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { Modal } from '@shared/components';

import { reportTypes } from '../../constants';
import {
  closeReportParamsModal,
  reportParamsDataSel,
  reportParamsVisibleSel,
} from '../../duck';
import ReportParamsForm from './ReportParamsForm';

class ReportParamsModal extends React.PureComponent {
  renderTitle = () => {
    const { reportType } = this.props.reportData;
    if (Object.values(reportTypes).includes(reportType)) {
      return (
        <FormattedMessage id={`reports.ReportParamsModal.${reportType}`} />
      );
    }

    // eslint-disable-next-line no-console
    console.error(`Unsupported report type: ${reportType}`);
    return null;
  };

  render() {
    const { visible, onCancel } = this.props;
    const renderedTitle = visible ? this.renderTitle() : null;

    return (
      <Modal
        headerColor="scouting-warm-gray"
        visible={visible}
        title={renderedTitle}
        onCancel={onCancel}
        backTitle={
          <FormattedMessage id="reports.ReportParamsModal.backButton" />
        }
        size="small"
      >
        <ReportParamsForm title={renderedTitle} onCancel={onCancel} />
      </Modal>
    );
  }
}

ReportParamsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  reportData: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapState = state => ({
  reportData: reportParamsDataSel(state),
  visible: reportParamsVisibleSel(state),
});

const mapDispatch = dispatch => ({
  onCancel: () => dispatch(closeReportParamsModal()),
});

export default connect(mapState, mapDispatch)(ReportParamsModal);
