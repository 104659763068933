import uniq from 'lodash/uniq';

import { showMaintenanceMessage } from '../../shared/components/UnderMaintenance';
import { intl } from '../../shared/localization/translate';
import isHttpSessionExpirationError from '../isHttpSessionExpirationError';
import httpErrorToast from './httpErrorToast';

const maintenanceErrorCode = 'M503';

const extractErrorsFromResponse = ({ response, statusText }) => {
  const { statusMessage, errorDesc, message, status, errorCode } =
    response || {};
  let validatedErrorDesc = errorDesc;
  if (errorDesc instanceof Object) {
    validatedErrorDesc = Object.keys(errorDesc).map(
      errorKey => `${errorKey}: ${errorDesc[errorKey]}`,
    );
  }
  return {
    errorMessage:
      typeof errorDesc === 'string'
        ? errorDesc
        : message ||
          statusMessage ||
          validatedErrorDesc ||
          status ||
          statusText,
    errorCode,
  };
};

const extractErrors = ({ response, statusText }) => {
  if (Array.isArray(response)) {
    const accErrors = response
      .map(item =>
        extractErrorsFromResponse({
          response: item,
          statusText,
        }),
      )
      .reduce(
        (acc, item) => ({
          errorMessage: [...acc.errorMessage, item.errorMessage],
          errorCode: [...acc.errorCode, item.errorCode],
        }),
        {
          errorMessage: [],
          errorCode: [],
        },
      );

    accErrors.errorMessage = uniq(accErrors.errorMessage).join(' ');
    accErrors.errorCode = uniq(accErrors.errorCode).join(' ');

    return accErrors;
  }

  return extractErrorsFromResponse({
    response,
    statusText,
  });
};

const handler = ({ xhr }, { suppressErrorToasts }) => {
  const { status } = xhr;
  const { errorCode, errorMessage } = extractErrors(xhr);
  const isMaintenanceError = errorCode === maintenanceErrorCode;
  let content = errorMessage.includes('Error validating JSON')
    ? intl.formatMessage({ id: 'shared.friendlyError' })
    : errorMessage;
  let toastType = 'error';

  if (isHttpSessionExpirationError(xhr)) {
    content = intl.formatMessage({ id: 'shared.sessionExpired' });
    toastType = 'info';
  }

  if (isMaintenanceError) {
    return showMaintenanceMessage(content);
  }

  if (!suppressErrorToasts) {
    return httpErrorToast({
      status,
      content,
      toastType,
    });
  }
};

export default handler;
