import React from 'react';

import { PlayCircleFilled } from '@material-ui/icons';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Button, Card, navigateToPurchaseOrderDetails } from '@shared';

import { POStatusTypes } from '../../constants';
import { updatePurchaseOrderRequest } from '../../duck';
import styles from './POCard.less';

export const POCard = ({ order, isLoading }) => {
  const dispatch = useDispatch();
  const { poid, status, dateCreated, userAdvancements } = order;

  const itemsCount = userAdvancements?.length || 0;

  const handleViewPODetails = () => {
    dispatch(navigateToPurchaseOrderDetails(poid));
  };

  const handleClosePO = () => {
    const data = { status: POStatusTypes.CLOSED };
    dispatch(updatePurchaseOrderRequest({ id: poid, data }));
  };

  return (
    <Card bordered noPadding loading={isLoading}>
      <div className={styles.poContainer}>
        <div
        // className={styles.infoContainer}
        >
          <div className={styles.row}>
            <div className={cn(styles.statusTag, styles[status])}>
              {status === POStatusTypes.OPEN && (
                <PlayCircleFilled className={styles.openIcon} />
              )}
              <FormattedMessage
                id="PurchaseOrder.POCard.statusLabel"
                className={styles.statusLabel}
                values={{ status: status }}
              />
            </div>
            <div className={styles.purchaseOrderNumber}>
              <FormattedMessage id="PurchaseOrder.POCard.purchaseOrderNumber" />
              {poid}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.infoItem}>
              <FormattedMessage id="PurchaseOrder.POCard.created" />{' '}
              {dateCreated}
            </div>
            <div className={styles.infoItem}>
              <FormattedMessage id="PurchaseOrder.POCard.totalItems" />{' '}
              {itemsCount}
            </div>
          </div>
        </div>
        <div
        // className={styles.buttonContainer}
        >
          <Button
            onClick={handleViewPODetails}
            ghost
            shape="round"
            size="large"
            uppercase={false}
            className={styles.actionButton}
          >
            <FormattedMessage id="PurchaseOrder.POCard.viewOrder" />
          </Button>
          {status === POStatusTypes.OPEN && (
            <Button
              onClick={handleClosePO}
              type="primary"
              shape="round"
              size="large"
              uppercase={false}
              className={styles.actionButton}
            >
              <FormattedMessage id="PurchaseOrder.POCard.closeOrder" />
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

POCard.propTypes = {
  order: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};
