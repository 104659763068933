import { createAction } from '@reduxjs/toolkit';

import { LeadershipPosition } from '../../../types/esb';
import { moduleName } from '../constants';

export const openUpdatePositionsModal = createAction<LeadershipPosition | null>(
  `${moduleName}/OPEN_UPDATE_POSITIONS_MODAL`,
);
export const closeUpdatePositionsModal = createAction(
  `${moduleName}/CLOSE_UPDATE_POSITIONS_MODAL`,
);

export const openDeletePositionModal = createAction<LeadershipPosition>(
  `${moduleName}/OPEN_DELETE_POSITION_MODAL`,
);
export const closeDeletePositionModal = createAction(
  `${moduleName}/CLOSE_DELETE_POSITION_MODAL`,
);

export const enableLeadershipEditMode = createAction(
  `${moduleName}/ENABLE_LEADERSHIP_EDIT_MODE`,
);
export const disableLeadershipEditMode = createAction(
  `${moduleName}/DISABLE_LEADERSHIP_EDIT_MODE`,
);
