const getTzObject = tzCode => {
  const timezoneCode = tzCode || 'America/Chicago';
  const appliedTz = new Intl.DateTimeFormat('en-US', {
    timeZoneName: 'longOffset',
    timeZone: timezoneCode,
  }).format(new Date());
  const [, GMT] = appliedTz.split(',');

  return {
    timezoneCode,
    timezoneDisplay: `${timezoneCode} ${GMT}`,
    utcOffset: GMT.replace(' GMT', ''),
  };
};

export default tzCode => {
  try {
    return getTzObject(tzCode);
  } catch (error) {
    return getTzObject('America/Chicago');
  }
};
