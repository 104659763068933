import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { intl } from '../../localization';

const getTranslationId = status => `shared.AdvancementFileStatus.${status}`;

const AdvancementFileStatus = ({ status, className }) => {
  const translationId = getTranslationId(status);

  return (
    <span className={className}>
      <FormattedMessage id={translationId} />
    </span>
  );
};

const getTranslation = status =>
  intl.formatMessage({ id: getTranslationId(status) });

AdvancementFileStatus.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AdvancementFileStatus.getTranslation = getTranslation;

export default AdvancementFileStatus;
