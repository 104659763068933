import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, DateView, PersonAvatar, S } from '@shared';

import PendingAdvancementIcon from '../PendingAdvancementIcon';
import PendingItemAdvancementName from '../PendingItemAdvancementName';
import PendingItemType from '../PendingItemType';
import styles from './PendingItemsList.less';

export default ({ isOffline, onGoToYouthProfile, onGoToAdultProfile }) =>
  item => {
    const { profilePictureUrl, name, userId, memberId, date, type, isAdult } =
      item;
    const profileLink = isAdult ? onGoToAdultProfile : onGoToYouthProfile;
    return [
      {
        rowId: 'header',
        label: (
          <div className={styles.headerLeft}>
            <PersonAvatar isAdult={isAdult} src={profilePictureUrl} />
            <PendingAdvancementIcon
              type={type}
              item={item}
              activityType={item.activityType}
              imgUrl={item.imgUrl}
              className={styles.advancementIcon}
            />
          </div>
        ),
        content: (
          <S size="4" bold inline colored title={name}>
            {name}
          </S>
        ),
      },
      {
        rowId: 'memberId',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.PendingItems.column.memberId" />
          </S>
        ),
        content: <S size="4">{memberId}</S>,
      },
      {
        rowId: 'advancementType',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.PendingItems.column.itemType" />
          </S>
        ),
        content: (
          <S size="4">
            <PendingItemType item={item} />
          </S>
        ),
      },
      {
        rowId: 'advancementItem',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.PendingItems.column.itemDescription" />
          </S>
        ),
        content: (
          <S size="4">
            <PendingItemAdvancementName type={type} item={item} />
          </S>
        ),
      },
      {
        rowId: 'completionDate',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancement.PendingItems.column.itemDate" />
          </S>
        ),
        content: (
          <S size="4">
            <DateView value={date} />
          </S>
        ),
      },
      {
        rowId: 'viewYouth',
        className: styles.viewYouthRow,
        content: (
          <Button
            ghost
            noBorder
            fitText
            onClick={profileLink(userId)}
            disabled={isOffline}
            color="info"
          >
            <FormattedMessage id="advancement.PendingItems.viewYouth" />
          </Button>
        ),
      },
    ];
  };
