import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import { OPEN_MASQUERADE_MODAL } from '@user';
import {
  APP_BOOTSTRAP_DONE,
  SET_HEADER_VISIBLE,
  SET_SIDEBAR_OPEN,
  SET_SIDEBAR_EXPANDED,
  MODAL_OPEN,
  MODAL_CLOSE,
  SET_UPDATE_APP,
  SET_UPDATING_APP,
} from './actions';

const appBootstrapped = (state = false, { type }) => {
  switch (type) {
    case APP_BOOTSTRAP_DONE: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const headerVisible = (state = true, { type, payload }) => {
  switch (type) {
    case SET_HEADER_VISIBLE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const sidebarOpen = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SIDEBAR_OPEN: {
      return payload;
    }
    case OPEN_MASQUERADE_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const sidebarExpandedPreferred = (state = true, { type, payload }) => {
  switch (type) {
    case SET_SIDEBAR_EXPANDED: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const modalOpenCount = (state = 0, { type }) => {
  switch (type) {
    case MODAL_OPEN: {
      return state + 1;
    }
    case MODAL_CLOSE: {
      return state - 1;
    }
    default: {
      return state;
    }
  }
};

const updateApp = (state = false, { type }) => {
  switch (type) {
    case SET_UPDATE_APP: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const updatingApp = (state = false, { type }) => {
  switch (type) {
    case SET_UPDATING_APP: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  appBootstrapped,
  headerVisible,
  sidebarOpen,
  sidebarExpandedPreferred,
  modalOpenCount,
  updateApp,
  updatingApp,
});

export default resetOnLogoutReducer(reducer);
