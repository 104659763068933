import React from 'react';
import { FormattedMessage } from 'react-intl';
import { advancementTypes } from '@shared/constants';
import { getAdvancementTypeTranslationId } from '@shared/localization';

type Props = {
  type: advancementTypes,
  plural?: boolean,
  isCore?: boolean,
  className?: string,
};

const AdvancementType: React.FC<Props> = ({ type, plural, isCore, className }) => {
  const translationId = getAdvancementTypeTranslationId(type, plural, isCore);

  return (
    <span className={className}>
      <FormattedMessage id={translationId} />
    </span>
  );
};

export default AdvancementType;
