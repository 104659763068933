import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import { DatePicker, Form, intl, pageUI, shortViewDateFormat } from '@shared';

import { useAllDayCheckbox } from '../../hooks';
import {
  getDefaultDate,
  getStartDateRule,
  makeEndDateDisabler,
} from '../../utils';
import ActivityTimeInput from '../ActivityTimeInput';
import AllDayCheckbox from '../AllDayCheckbox';

const FormItem = Form.Item;

const ActivityFormDate = ({
  activityTypeName,
  isDisabled,
  disableFutureDays,
  preselectedStartDate,
  preselectedEndDate,
  showAllDayCheckbox,
  showStartDate,
  showEndDate,
  showStartTime,
  showEndTime,
  form,
}) => {
  const { getFieldDecorator, getFieldsValue, getFieldValue, setFieldsValue } =
    form;
  const formValues = getFieldsValue();
  const startDate = formValues.startDate;
  const isMobile = useSelector(isMobileSel);

  const allDay = useAllDayCheckbox({
    form,
    start: startDate,
    end: formValues.endTime,
    page: pageUI.ACTIVITY,
  });

  const shouldDisableEndDate = useCallback(
    endDate => makeEndDateDisabler(startDate)(endDate, disableFutureDays),
    [startDate, disableFutureDays],
  );
  const handleChangeStartDate = useCallback(
    formStartDate => {
      if (showEndDate) {
        const formEndDate = getFieldValue('endDate');
        const endDate = formEndDate && formEndDate.clone();
        if (formStartDate && !endDate) {
          const startDate = formStartDate.clone();
          setFieldsValue({ endDate: startDate });
        } else if (formStartDate && endDate) {
          const startDate = formStartDate.clone();
          const isEndBeforeStart = endDate.isBefore(startDate, 'day');
          setFieldsValue({ endDate: isEndBeforeStart ? startDate : endDate });
        }
      }
    },
    [getFieldValue, setFieldsValue, showEndDate],
  );

  const disabledTimePicker = isDisabled || allDay;
  const timePickerRules = disabledTimePicker
    ? [
        {
          required: false,
        },
      ]
    : [
        {
          required: true,
          message: intl.formatMessage({
            id: 'shared.form.error.isRequired',
          }),
        },
      ];
  const disableMobileDates = useMemo(() => {
    if (isMobile) {
      return {
        minDate: startDate,
        maxDate: disableFutureDays ? 'today' : null,
      };
    }
  }, [isMobile, startDate, disableFutureDays]);
  return (
    <React.Fragment>
      {showStartDate && (
        <FormItem
          required
          label={intl.formatMessage(
            {
              id: 'progress.common.ActivityFormDate.field.startDate',
            },
            {
              type: activityTypeName,
            },
          )}
          standardLayout
        >
          {getFieldDecorator('startDate', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'shared.form.error.isRequired',
                }),
              },
              ...getStartDateRule(disableFutureDays),
            ],
            validateStatus: false,
            initialValue: getDefaultDate(preselectedStartDate),
          })(
            <DatePicker
              disabled={isDisabled}
              size="large"
              fluid
              placeholder="MM/DD/YYYY"
              onChange={handleChangeStartDate}
              disableDates={disableFutureDays ? 'future' : undefined}
              format={shortViewDateFormat}
            />,
          )}
        </FormItem>
      )}
      {showEndDate && (
        <FormItem
          required
          label={intl.formatMessage(
            {
              id: 'progress.common.ActivityFormDate.field.endDate',
            },
            {
              type: activityTypeName,
            },
          )}
          standardLayout
        >
          {getFieldDecorator('endDate', {
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'shared.form.error.isRequired',
                }),
              },
            ],
            validateStatus: false,
            initialValue: getDefaultDate(preselectedEndDate),
          })(
            <DatePicker
              disabled={isDisabled}
              size="large"
              fluid
              placeholder="MM/DD/YYYY"
              disabledDate={isMobile ? undefined : shouldDisableEndDate}
              disableDates={disableMobileDates}
              format={shortViewDateFormat}
            />,
          )}
        </FormItem>
      )}
      {showAllDayCheckbox && (
        <FormItem>
          <AllDayCheckbox disabled={isDisabled} form={form} />
        </FormItem>
      )}
      {showStartTime && (
        <ActivityTimeInput
          allDay={allDay}
          fieldName="startTime"
          fieldTranslationKey="shared.date.startTime"
          initialValue={preselectedStartDate}
          rules={timePickerRules}
          disabled={disabledTimePicker}
          form={form}
        />
      )}
      {showEndTime && (
        <ActivityTimeInput
          allDay={allDay}
          fieldName="endTime"
          fieldTranslationKey="shared.date.endTime"
          initialValue={preselectedStartDate}
          rules={timePickerRules}
          disabled={disabledTimePicker}
          form={form}
        />
      )}
    </React.Fragment>
  );
};

ActivityFormDate.propTypes = {
  form: PropTypes.object.isRequired,
  preselectedStartDate: PropTypes.object,
  preselectedEndDate: PropTypes.object,
  showAllDayCheckbox: PropTypes.bool,
  showStartDate: PropTypes.bool,
  showEndDate: PropTypes.bool,
  showStartTime: PropTypes.bool,
  showEndTime: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  disableFutureDays: PropTypes.bool,
  activityTypeName: PropTypes.string.isRequired,
};

ActivityFormDate.defaultProps = {
  showAllDayCheckbox: true,
  showStartDate: true,
  showEndDate: true,
  showStartTime: true,
  showEndTime: true,
};

export default ActivityFormDate;
