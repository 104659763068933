import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { deleteProfileBioRequest, saveProfileBioRequest } from '../../duck';
import Button from '../Button';
import Input from '../Input';
import styles from './Bio.less';

const { TextArea } = Input;

const Bio = ({ bio, personGuid, viewOnly }) => {
  const [bioSelected, setBioSelected] = useState(false);
  const [bioEdited, setBioEdited] = useState(false);
  const [bioText, setBioText] = useState('');
  const dispatch = useDispatch();
  if (bio && !bioText && !bioEdited) {
    setBioText(bio.attributeVal);
  }

  const handleSaveBio = () => {
    if (bioText) {
      dispatch(
        saveProfileBioRequest(
          {
            myBio: {
              attributeVal: bioText,
            },
          },
          personGuid,
        ),
      );
    } else {
      dispatch(
        deleteProfileBioRequest(
          {
            type: 'myBio',
          },
          personGuid,
        ),
      );
    }

    setBioSelected(false);
  };

  const handleBioChange = e => {
    setBioSelected(true);
    setBioEdited(true);
    setBioText(e.target.value);
  };

  const handleBioCancel = () => {
    const bioValue = bio ? bio.attributeVal : '';
    setBioSelected(false);
    setBioText(bioValue);
  };

  return (
    <div>
      <TextArea
        className={styles.bioTextField}
        placeholder="About me (hobbies, scouting experience)..."
        maxLength={200}
        autosize={{ minRows: 3 }}
        charCounterBelow
        onChange={handleBioChange}
        value={bioText}
        defaultValue={bioText}
        rounded
        disabled={viewOnly}
      />
      <div>
        <Button
          className={styles.bioButton}
          shape="round"
          size="small"
          type="primary"
          hidden={!bioSelected}
          onClick={handleSaveBio}
        >
          Save
        </Button>
        <Button
          className={styles.bioButton}
          shape="round"
          size="small"
          hidden={!bioSelected}
          onClick={handleBioCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

Bio.propTypes = {
  viewOnly: PropTypes.bool,
  bio: PropTypes.shape({
    attributeVal: PropTypes.string,
  }).isRequired,
  personGuid: PropTypes.string.isRequired,
};

export default Bio;
