import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';

import { isGtfaImport, isScoutbookImport } from '../utils';
import {
  CATEGORIES_AND_ORGANIZATIONS_REQUEST,
  CATEGORIES_AND_ORGANIZATIONS_RESPONSE,
  DELETE_ACTIVITIY_ERROR,
  DELETE_ACTIVITIY_REQUEST,
  DELETE_ACTIVITIY_RESPONSE,
  DEL_PERSONS_TO_REMOVE,
  GET_ACTIVITIES_ERROR,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_RESPONSE,
  GET_LOCAL_LOCATIONS_ERROR,
  GET_LOCAL_LOCATIONS_REQUEST,
  GET_LOCAL_LOCATIONS_RESPONSE,
  SEARCH_LOCATIONS_ERROR,
  SEARCH_LOCATIONS_REQUEST,
  SEARCH_LOCATIONS_RESPONSE,
  SET_ACTION_BUTTON,
  SET_INITIAL_FORM_STATE,
  SET_MULTIPLE_PERSONS_TO_REMOVE,
  SET_PERSONS_TO_REMOVE,
  SET_SELECTED_ACTIVITY,
} from './actions';

const activities = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ACTIVITIES_RESPONSE: {
      return payload.activities || [];
    }
    case GET_ACTIVITIES_ERROR: {
      return [];
    }
    default: {
      return state;
    }
  }
};

const loadingCalendar = (state = false, { type }) => {
  switch (type) {
    case GET_ACTIVITIES_REQUEST:
      return true;
    case GET_ACTIVITIES_RESPONSE:
    case GET_ACTIVITIES_ERROR:
      return false;
    default:
      return state;
  }
};

const categories = (state = [], { type, payload }) => {
  switch (type) {
    case CATEGORIES_AND_ORGANIZATIONS_RESPONSE:
      return payload.categories || [];
    default:
      return state;
  }
};

const organizations = (state = [], { type, payload }) => {
  switch (type) {
    case CATEGORIES_AND_ORGANIZATIONS_RESPONSE:
      return payload.organizations || [];
    default:
      return state;
  }
};

const loadingModal = (state = false, { type }) => {
  switch (type) {
    case CATEGORIES_AND_ORGANIZATIONS_REQUEST:
      return true;
    case CATEGORIES_AND_ORGANIZATIONS_RESPONSE:
      return false;
    default:
      return state;
  }
};

const setActionButton = (state = '', { type, payload }) => {
  switch (type) {
    case SET_ACTION_BUTTON: {
      return payload;
    }
    case SET_SELECTED_ACTIVITY:
      return '';
    default: {
      return state;
    }
  }
};

const selectedActivity = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ACTIVITY:
      return payload;
    default:
      return state;
  }
};

const personsToRemove = (state = [], { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ACTIVITY:
      return [];
    case SET_PERSONS_TO_REMOVE:
      return [...new Set([...state, payload])];
    case SET_MULTIPLE_PERSONS_TO_REMOVE:
      return [...new Set([...state, ...payload])];
    case DEL_PERSONS_TO_REMOVE:
      return [...new Set(state.filter(id => id != payload))];
    default:
      return state;
  }
};

const activityImportedFrom = (state = null, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ACTIVITY: {
      const { createdBy } = payload || {};
      const importedFrom =
        isScoutbookImport(createdBy) || isGtfaImport(createdBy);
      return importedFrom;
    }
    default:
      return state;
  }
};

const acitityLocalLocations = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_LOCAL_LOCATIONS_RESPONSE: {
      const { zip, locations } = payload;
      return { zip, locations };
    }
    case GET_LOCAL_LOCATIONS_ERROR:
      return {};
    default:
      return state;
  }
};

const searchLocationList = (state = [], { type, payload }) => {
  switch (type) {
    case SEARCH_LOCATIONS_RESPONSE:
      return payload;
    case SEARCH_LOCATIONS_ERROR:
      return [];
    default:
      return state;
  }
};

const isLoadingLocations = (state = false, { type }) => {
  switch (type) {
    case SEARCH_LOCATIONS_REQUEST:
    case GET_LOCAL_LOCATIONS_REQUEST:
      return true;
    case GET_LOCAL_LOCATIONS_RESPONSE:
    case SEARCH_LOCATIONS_RESPONSE:
    case GET_LOCAL_LOCATIONS_ERROR:
    case SEARCH_LOCATIONS_ERROR:
      return false;
    default:
      return state;
  }
};

const initialFormState = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_INITIAL_FORM_STATE:
      return { ...payload };
    default:
      return state;
  }
};

const deleteActivityLoading = (state = false, { type }) => {
  switch (type) {
    case DELETE_ACTIVITIY_REQUEST:
      return true;
    case DELETE_ACTIVITIY_ERROR:
    case DELETE_ACTIVITIY_RESPONSE:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  activities,
  selectedActivity,
  personsToRemove,
  categories,
  organizations,
  loadingModal,
  setActionButton,
  loadingCalendar,
  activityImportedFrom,
  acitityLocalLocations,
  searchLocationList,
  isLoadingLocations,
  initialFormState,
  deleteActivityLoading,
});

export default resetOnLogoutReducer(reducer);
