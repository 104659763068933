import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import { Fab } from '@shared';
import styles from './AdvancementReportButtons.less';

function ScrollFab({ isAtBottom, onClick }) {
  return ReactDOM.createPortal(
    <Fab
      containerClassName={cn(styles.scrollFabContainer, {
        [styles.fadeOut]: isAtBottom,
      })}
      color="scouting-warm-gray"
      onClick={onClick}
    >
      <ArrowDownward />
    </Fab>,
    document.querySelector('body'),
  );
}

ScrollFab.propTypes = {
  isAtBottom: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ScrollFab;
