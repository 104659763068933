import { useLayoutEffect } from 'react';

import { formatActivityTime } from '../utils';

const useAllDayCheckbox = ({ start, end, form, page }) => {
  const { setFieldsValue, getFieldsValue } = form;
  const allDay = getFieldsValue().allDay;
  const pageValue = page && page.valueOf();
  const startValue = start && start.valueOf();
  const endValue = end && end.valueOf();
  const allDayEndTime = pageValue === 'event' ? '23:45' : '23:59';

  useLayoutEffect(() => {
    if (start && end) {
      const formattedStartTime = formatActivityTime(startValue);
      const formattedEndTime = formatActivityTime(endValue);
      const result =
        formattedEndTime === allDayEndTime && formattedStartTime === '00:00';

      if (result !== allDay) {
        setFieldsValue({ allDay: result });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startValue, endValue, setFieldsValue, pageValue]);

  return allDay;
};

export default useAllDayCheckbox;
