import { moduleName } from '../constants';

export const UNIT_INFO_REQUEST = `${moduleName}/UNIT_INFO_REQUEST`;
export const UNIT_INFO_REQUEST_IF_NOT_LOADED = `${moduleName}/UNIT_INFO_REQUEST_IF_NOT_LOADED`;
export const UNIT_INFO_RESPONSE = `${moduleName}/UNIT_INFO_RESPONSE`;
export const UNIT_INFO_ERROR = `${moduleName}/UNIT_INFO_ERROR`;
export const unitInfoRequest = () => ({ type: UNIT_INFO_REQUEST });
export const unitInfoRequestIfNotLoaded = () => ({
  type: UNIT_INFO_REQUEST_IF_NOT_LOADED,
});
export const unitInfoResponse = unitInfo => ({
  type: UNIT_INFO_RESPONSE,
  payload: unitInfo,
});
export const unitInfoError = err => ({ type: UNIT_INFO_ERROR, payload: err });

export const UNIT_PROFILE_REQUEST = `${moduleName}/UNIT_PROFILE_REQUEST`;
export const UNIT_PROFILE_RESPONSE = `${moduleName}/UNIT_PROFILE_RESPONSE`;
export const UNIT_PROFILE_ERROR = `${moduleName}/UNIT_PROFILE_ERROR`;
export const unitProfileRequest = unitGuid => ({
  type: UNIT_PROFILE_REQUEST,
  payload: unitGuid,
});
export const unitProfileResponse = unitProfile => ({
  type: UNIT_PROFILE_RESPONSE,
  payload: unitProfile,
});
export const unitProfileError = err => ({
  type: UNIT_PROFILE_ERROR,
  payload: err,
});
