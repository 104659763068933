import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { S } from '@shared';

import { pageTitleIdSel } from '@location';
import styles from './PageName.less';

const PageName = ({ titleId, className }) => {
  const pageName = titleId ? <FormattedMessage id={titleId} /> : '';

  return (
    <div className={className}>
      <div key={titleId} className={styles.pageName}>
        <S size="4">{pageName}</S>
      </div>
    </div>
  );
};

PageName.propTypes = {
  titleId: PropTypes.string,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  titleId: pageTitleIdSel(state),
});

export default connect(mapStateToProps)(PageName);
