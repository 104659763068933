import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';
import { isIncludedIn } from '../../../utils';

import {
  // selectors
  activitiesModuleSel,
  // utils
  createPersonsSelector,
  groupYouthsAdults,
  getPersonsInActivity,
} from '../../common';

const moduleSel = state => activitiesModuleSel(state);

// Activity Logs Selectors

export const activityLogsSel = state => moduleSel(state).activityLogs;

export const searchSel = state => moduleSel(state).activityLogSearch;

export const filteredLogsSel = createSelector(
  activityLogsSel,
  searchSel,
  (logs, search) => {
    const filteredItems = logs.filter(log =>
      isIncludedIn(log.activityName, search),
    );
    return filteredItems;
  },
);

export const isActivityLogsLoadingSel = state =>
  moduleSel(state).isLoadingActivityLogs;

export const currentLogsInfoSel = state => moduleSel(state).currentLogsInfo;

export const showRemoveParticipantModalSel = state =>
  moduleSel(state).showRemoveParticipantModal;

export const removingParticipantSel = state =>
  moduleSel(state).removingParticipant;

export const setActivityToDeleteSel = state =>
  moduleSel(state).setActivityToDelete;

// Create/Edit Activity Form Selectors

export const userIdsSel = state => moduleSel(state).selectedUserIds;

export const sidebarPersonsSel = createPersonsSelector(userIdsSel, true);

export const selectedActivityDataSel = state =>
  moduleSel(state).selectedActivityData;

export const personsAndIdsActivitySel = createSelector(
  selectedActivityDataSel,
  activity => {
    const { personsInActivity, userIds } = getPersonsInActivity(activity);
    return { personsInActivity, userIds };
  },
);

export const personsSel = createSelector(
  personsAndIdsActivitySel,
  sidebarPersonsSel,
  (personsAndIdsActivity, persons) => {
    const { personsInActivity } = personsAndIdsActivity;
    return uniqBy([...persons, ...personsInActivity], 'userId');
  },
);

export const youthsAndAdultsSel = createSelector(personsSel, persons =>
  groupYouthsAdults(persons),
);

export const personsCountSel = (countAll = false) =>
  createSelector(youthsAndAdultsSel, ({ youths, adults }) =>
    countAll ? youths.length + adults.length : [youths.length, adults.length],
  );

export const isLoadingSaveActivitySel = state =>
  moduleSel(state).isLoadingSaveActivity;

export const activeTabSel = state => moduleSel(state).activeTab;

export const getActivityByIdLoadingSel = state =>
  moduleSel(state).getActivityByIdLoading;
