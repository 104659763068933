import { map } from 'rxjs/operators';
import { esbApiService } from '@utils';

const getRelatedChildren$ = userId =>
  esbApiService
    .get$(`/persons/${userId}/myScout`)
    .pipe(map(response => response));

export default {
  getRelatedChildren$,
};
