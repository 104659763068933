import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';

import { DEFAULT_TABLE_PAGESIZE } from '../constants';

const TABLE_CONFIG_STORAGE_KEY = 'TABLE_CONFIG';
const LAST_ONLINE_STORAGE_KEY = 'LAST_ONLINE';

const retrieveTableConfig = () => {
  const rawTableConfig = localStorage.getItem(TABLE_CONFIG_STORAGE_KEY) || '{}';
  return JSON.parse(rawTableConfig);
};

export const retrieveUserTableConfig = personGuid => {
  const tableConfig = retrieveTableConfig();
  return get(tableConfig, personGuid, { pageSize: DEFAULT_TABLE_PAGESIZE });
};

const storeTableConfig = config => {
  const prevConfig = retrieveTableConfig();
  localStorage.setItem(
    TABLE_CONFIG_STORAGE_KEY,
    JSON.stringify({ ...prevConfig, ...config }),
  );
};

export const storeUserTableConfig = (config, personGuid) => {
  storeTableConfig({ [personGuid]: config });
};

export const retrieveLastOnlineDate = () =>
  localStorage.getItem(LAST_ONLINE_STORAGE_KEY) || '';

export const storeLastOnlineDate = date => {
  localStorage.setItem(LAST_ONLINE_STORAGE_KEY, date);
};

export const CASS$ = line1 =>
  Observable.ajax({
    method: 'GET',
    url: `https://expressentry.melissadata.net/web/ExpressAddress?line1=${line1}&id=116097596&format=json&maxrecords=8`,
  }).map(res => res);

export const imageUploadS3$ = (
  userId,
  _imgType,
  { imageFile },
  { preSignedUrl },
) =>
  fetch(preSignedUrl, {
    body: imageFile,
    method: 'PUT',
    headers: {
      'x-amz-tagging': `scoutUserId=${userId}`,
    },
  });
