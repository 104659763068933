import moment from 'moment';

import { API_ENV, SBL_4744_DISABLE_RECHARTER } from '../../config/config';
import {
  COUNCIL_198_GUID,
  COUNCIL_364_GUID,
  COUNCIL_562_GUID,
  COUNCIL_602_GUID,
  HOUSTON_COUNCIL_GUID,
  LAST_ENABLED_COUNCILS,
  PP_CUSTOM_COUNCILS,
} from '../../recharter/recharterPeriod-constants';

const PP_LIMIT = moment.utc({ year: 2024, month: 0, day: 0 });

export function isRecharterPeriod({
  unitInfo,
  recharterRestriction,
  apiEnv = API_ENV,
}) {
  if (!recharterRestriction) return true;

  const { charter } = unitInfo;

  const today = moment(Date.now());

  const councilGuid = unitInfo.councilGuid;

  if (SBL_4744_DISABLE_RECHARTER) {
    const enabled = LAST_ENABLED_COUNCILS.has(councilGuid);

    const isBeforeLimit = moment().isBefore('2024-04-01');
    const canRenew =
      charter?.expiryDate && moment(charter.expiryDate).isBefore(today);

    return enabled && isBeforeLimit && canRenew;
  }

  const ppCouncilsSet = new Set(Object.values(PP_CUSTOM_COUNCILS));

  if (!charter) return false;

  const unitCouncilGuid = unitInfo.councilGuid;

  // SBL-4296
  if (moment(charter.expiryDate).isSameOrAfter('2024-03-01')) return false;

  // SBL-4152
  if (apiEnv === 'pp' && ppCouncilsSet.has(unitCouncilGuid)) {
    const start = moment(charter.expiryDate).subtract(365, 'days');
    const isValid = today.isAfter(start);
    return isValid;
  }

  if (apiEnv === 'pp' && moment(charter.expiryDate).isBefore(PP_LIMIT)) {
    return true;
  }

  const startDate = moment(charter.expiryDate).add(-90, 'days');
  const limitDate = moment(charter.expiryDate).add(105, 'days');

  // See SBL-3729, SBL-3770
  if (
    unitInfo.councilGuid === HOUSTON_COUNCIL_GUID ||
    unitInfo.councilGuid === COUNCIL_364_GUID
  ) {
    const specialStartDate = moment(charter.expiryDate).add(-60, 'days');
    return today >= specialStartDate && today <= limitDate;
  }
  // See SBL-4732
  if (
    unitInfo.councilGuid === COUNCIL_562_GUID ||
    unitInfo.councilGuid === COUNCIL_198_GUID
  ) {
    return true;
  }

  const valid = today >= startDate && today <= limitDate;

  // SBL-4355
  if (unitInfo.councilGuid === COUNCIL_602_GUID) {
    const novFirst = moment()
      .utc()
      .year(2023)
      .month('November')
      .startOf('month')
      .startOf('day');
    const enable = moment(today).isSameOrAfter(novFirst);
    return valid && enable;
  }

  return valid;
}

export default function validate(unitInfo, recharterRestriction) {
  return isRecharterPeriod({ apiEnv: API_ENV, recharterRestriction, unitInfo });
}
