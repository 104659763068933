import React from 'react';

import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { isMobileSel } from '@screen';
import { TableFilterCard, sorterPropTypes } from '@shared';

import {
  approvedSubUnitsSel,
  subUnitTypeSel,
} from '../../../../subUnits/duck/selectors';
import {
  fieldsVisibilitySel,
  filterSel,
  isSubUnitAllowedSel,
  packRosterFilter,
  packRosterSort,
  setFieldsVisibility,
  setShowSubUnits,
  setSubUnitsFilterTags,
  showSubUnitsSel,
  sorterSel,
  subUnitTypeNameSel,
  subUnitsFilteredTagsSel,
} from '../../../duck';
import {
  FilterSection,
  SortSection,
  SubUnitSection,
  VisibilitySection,
} from './Sections';

const { Collapse, Panel } = TableFilterCard;

class PackRosterFilterCard extends React.PureComponent {
  state = {
    filter: this.props.filter,
    sorter: this.props.sorter,
    fieldsVisibility: this.props.fieldsVisibility,
    showSubUnits: this.props.showSubUnits,
    activePanel: 'filter',
    subUnitsFilterTags: this.props.subUnitsFilterTags,
  };

  getFilterName = memoize(filter => {
    const allChecked = Object.values(filter).every(f => !!f);
    if (allChecked) return 'showAllRoster';
    return 'showFiltered';
  });

  handleActivePanelChange = activePanel => {
    this.setState({ activePanel });
  };

  handleFilterChange = filter => {
    this.setState({ filter });
  };

  handleSorterChange = sorter => {
    this.setState({ sorter });
  };

  handleFieldsVisibilityChange = fieldsVisibility => {
    this.setState({ fieldsVisibility });
  };

  handleSubUnitChange = () => {
    this.setState(({ showSubUnits }) => ({ showSubUnits: !showSubUnits }));
  };

  handleOpenChange = open => {
    if (open) {
      this.resetState();
    }
  };

  onSubUnitFilterChange = id => {
    this.setState(({ subUnitsFilterTags }) => ({
      subUnitsFilterTags: subUnitsFilterTags.map(filterTag =>
        filterTag.id === parseInt(id)
          ? { ...filterTag, status: !filterTag.status }
          : filterTag,
      ),
    }));
  };

  onSubUnitSelectAllChange = selectAllStatus => {
    this.setState(({ subUnitsFilterTags }) => ({
      subUnitsFilterTags: subUnitsFilterTags.map(filterTag => {
        const updated = {
          ...filterTag,
          status: selectAllStatus,
        };

        return updated;
      }),
    }));
  };

  resetState = () => {
    const {
      filter,
      sorter,
      fieldsVisibility,
      showSubUnits,
      subUnitsFilterTags,
    } = this.props;

    this.setState({
      filter,
      sorter,
      fieldsVisibility,
      showSubUnits,
      subUnitsFilterTags,
    });
  };

  handleSubmit = () => {
    const {
      onSelectFilter,
      onSetFieldsVisibility,
      onSetSorter,
      onSetShowSubUnits,
      onSetSubUnitsFilterTags,
      isMobile,
    } = this.props;
    if (this.props.filter !== this.state.filter) {
      onSelectFilter(this.state.filter);
    }
    onSetShowSubUnits(this.state.showSubUnits);
    onSetSubUnitsFilterTags(this.state.subUnitsFilterTags);
    if (isMobile) {
      onSetSorter(this.state.sorter);
      onSetFieldsVisibility(this.state.fieldsVisibility);
    }
  };

  renderFilterContent = () => {
    const { subUnits, isSubUnitAllowed, subUnitType, subUnitTypeName } =
      this.props;
    const {
      activePanel,
      filter,
      sorter,
      showSubUnits,
      fieldsVisibility,
      subUnitsFilterTags,
    } = this.state;

    const hasOrgSubUnits = Array.isArray(subUnits) && subUnits.length > 0;

    return (
      <Collapse activeKey={activePanel} onChange={this.handleActivePanelChange}>
        <Panel
          key="filter"
          header={
            <FormattedMessage id="packRoster.PackRosterFilterCard.showRoster" />
          }
        >
          <FilterSection values={filter} onChange={this.handleFilterChange} />
        </Panel>
        <Panel
          key="sorter"
          header={<FormattedMessage id="shared.sortBy" />}
          mobileOnly
        >
          <SortSection sorter={sorter} onChange={this.handleSorterChange} />
        </Panel>
        {!!isSubUnitAllowed && subUnits.length > 0 && (
          <Panel key="dens" header={<FormattedMessage id="shared.display" />}>
            <SubUnitSection
              subUnitTypeName={subUnitTypeName}
              disabled={!hasOrgSubUnits}
              selected={showSubUnits}
              onChange={this.handleSubUnitChange}
              subUnitsFilterTags={subUnitsFilterTags}
              onChangeSubUnits={this.onSubUnitFilterChange}
              onChangeSelectAllSubUnits={this.onSubUnitSelectAllChange}
            />
          </Panel>
        )}
        <Panel
          key="visiblity"
          header={<FormattedMessage id="shared.visibility" />}
          mobileOnly
        >
          <VisibilitySection
            subUnitType={subUnitType}
            isSubUnitAllowed={isSubUnitAllowed}
            values={fieldsVisibility}
            onChange={this.handleFieldsVisibilityChange}
          />
        </Panel>
      </Collapse>
    );
  };

  render() {
    const { children } = this.props;
    const { filter } = this.state;
    const filterName = this.getFilterName(filter);

    return (
      <TableFilterCard
        content={this.renderFilterContent()}
        onOpenChange={this.handleOpenChange}
        onSubmit={this.handleSubmit}
      >
        {children(filterName)}
      </TableFilterCard>
    );
  }
}

PackRosterFilterCard.propTypes = {
  //
  children: PropTypes.func.isRequired,
  //
  isMobile: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  sorter: sorterPropTypes,
  fieldsVisibility: PropTypes.object.isRequired,
  showSubUnits: PropTypes.bool.isRequired,
  subUnits: PropTypes.array.isRequired,
  isSubUnitAllowed: PropTypes.bool,
  subUnitTypeName: PropTypes.string,
  subUnitType: PropTypes.string,
  onSelectFilter: PropTypes.func.isRequired,
  onSetSorter: PropTypes.func.isRequired,
  onSetShowSubUnits: PropTypes.func.isRequired,
  onSetFieldsVisibility: PropTypes.func.isRequired,
  subUnitsFilterTags: PropTypes.array.isRequired,
  onSetSubUnitsFilterTags: PropTypes.func.isRequired,
};

const mapState = state => ({
  isMobile: isMobileSel(state),
  filter: filterSel(state),
  sorter: sorterSel(state),
  fieldsVisibility: fieldsVisibilitySel(state),
  isSubUnitAllowed: isSubUnitAllowedSel(state),
  subUnitTypeName: subUnitTypeNameSel(state),
  subUnitType: subUnitTypeSel(state),
  showSubUnits: showSubUnitsSel(state),
  subUnits: approvedSubUnitsSel(state),
  subUnitsFilterTags: subUnitsFilteredTagsSel(state),
});

const mapDispatch = dispatch => ({
  onSelectFilter: filter => dispatch(packRosterFilter(filter)),
  onSetSorter: sorter => dispatch(packRosterSort(sorter)),
  onSetFieldsVisibility: fieldsVisibility =>
    dispatch(setFieldsVisibility(fieldsVisibility)),
  onSetShowSubUnits: showSubUnits => dispatch(setShowSubUnits(showSubUnits)),
  onSetSubUnitsFilterTags: subUnitsFilterTags =>
    dispatch(setSubUnitsFilterTags(subUnitsFilterTags)),
});

export default connect(mapState, mapDispatch)(PackRosterFilterCard);
