import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import cn from 'classnames';

import styles from './PagePlaceholder.less';

const PagePlaceholder = ({ children, className }) => (
  <div className={cn(styles.container, className)}>
    {children || (
      <ContentLoader width={240} height={500} className={styles.content}>
        <rect x="0" y="0" rx="3" ry="3" width="120" height="20" />
        <rect x="0" y="60" rx="3" ry="3" width="240" height="15" />
        <rect x="0" y="90" rx="3" ry="3" width="200" height="15" />
        <rect x="0" y="120" rx="3" ry="3" width="220" height="15" />
        <rect x="0" y="150" rx="3" ry="3" width="180" height="15" />
        <rect x="0" y="180" rx="3" ry="3" width="210" height="15" />
        <rect x="0" y="210" rx="3" ry="3" width="60" height="15" />
        <rect x="0" y="270" rx="3" ry="3" width="160" height="20" />
        <rect x="0" y="330" rx="3" ry="3" width="110" height="15" />
        <rect x="0" y="360" rx="3" ry="3" width="190" height="15" />
        <rect x="0" y="390" rx="3" ry="3" width="230" height="15" />
        <rect x="0" y="420" rx="3" ry="3" width="200" height="15" />
        <rect x="0" y="450" rx="3" ry="3" width="90" height="15" />
      </ContentLoader>
    )}
  </div>
);

PagePlaceholder.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PagePlaceholder;
