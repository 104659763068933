export const eagleInformationInitialValues = {
  totalHours: undefined,
  projectCost: 0.0,
  categoryId: undefined,
  typeGroupDescription: undefined,
  benefitGroup: undefined,
  startDate: undefined,
  endDate: undefined,
  startTime: undefined,
  endTime: undefined,
};

export const getEagleHours = hrs => [
  {
    name: 'Hours',
    type: 'Hrs',
    fkActTypeId: 5,
    effectiveDt: '1910-01-01',
    expiryDt: '',
    activityValueTypeId: 10,
    activityValue: Number(hrs),
  },
];

export const mapInitialEagleInformation = eagleActivity => {
  const initialValues = { ...eagleInformationInitialValues };

  if (
    eagleActivity.registeredYouths.length &&
    eagleActivity.registeredYouths[0].activityValues.length
  ) {
    const activityValues = eagleActivity.registeredYouths[0].activityValues;
    const hours = activityValues.find(
      value => value.activityValueTypeId === 10,
    );

    if (hours) {
      initialValues.totalHours = hours.activityValue;
    }
  }

  if (
    eagleActivity.nonRegisteredOrgParticipants &&
    eagleActivity.nonRegisteredOrgParticipants.materialCost
  ) {
    initialValues.projectCost =
      eagleActivity.nonRegisteredOrgParticipants.materialCost;
  }

  initialValues.categoryId = eagleActivity.categoryId;

  initialValues.name = eagleActivity.name || undefined;
  initialValues.benefitGroup = eagleActivity.benefitGroup || undefined;
  initialValues.startDate = eagleActivity.startDateTime;
  initialValues.endDate = eagleActivity.endDateTime;

  return initialValues;
};

export const mapEagleActivityToFormFields = eagleActivity => {
  const activity = { ...eagleActivity };

  if (
    eagleActivity.registeredYouths.length &&
    eagleActivity.registeredYouths[0].activityValues.length
  ) {
    const activityValues = eagleActivity.registeredYouths[0].activityValues;
    const hours = activityValues.find(
      value => value.activityValueTypeId === 10,
    );

    if (hours) {
      activity.totalHours = hours.activityValue;
    }
  }

  return activity;
};

export const eagleLocationInitialValues = {
  location: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  zip5: '',
};

export const mapInitialEagleLocation = eagleActivity => {
  const initialValues = { ...eagleLocationInitialValues };
  initialValues.location = eagleActivity.location || '';
  initialValues.addressLine1 = eagleActivity.addressLine1
    ? eagleActivity.addressLine1.trim()
    : '';
  initialValues.addressLine2 = eagleActivity.addressLine2
    ? eagleActivity.addressLine2.trim()
    : '';
  initialValues.city = eagleActivity.city ? eagleActivity.city.trim() : '';
  initialValues.zip5 = eagleActivity.zip5 || '';
  initialValues.akelaStateId = eagleActivity.akelaStateId
    ? eagleActivity.akelaStateId.toString()
    : '';
  return initialValues;
};
