import React from 'react';

import { Badge, S } from '@shared/components';

import styles from './BadgeNotificationItem.less';

const BadgeNotificationItem: React.FC<{
  id?: string;
  title: React.ReactNode;
  description: React.ReactNode;
  onClick?: () => void;
  count: number;
}> = ({ id, title, description, count, onClick }) => (
  <div className={styles.container} onClick={onClick}>
    <div className={styles.notificationHeader}>
      <S size="4" colored bold>
        {title}
      </S>
      <Badge count={count} className={styles.badge} />
    </div>
    <S id={id} className={styles.description} size="5">
      {description}
    </S>
  </div>
);

export default BadgeNotificationItem;
