import { createModuleNameSpace } from '@modules/utils/misc';
import { gtm } from '@shared';

import { modulePath } from '../constants';

const moduleNamespace = createModuleNameSpace(modulePath);

export const START_ADVANCEMENT_REQUEST = `${moduleNamespace}/START_ADVANCEMENT_REQUEST`;
export const START_ADVANCEMENT_RESPONSE = `${moduleNamespace}/START_ADVANCEMENT_RESPONSE`;
export const START_ADVANCEMENT_ERROR = `${moduleNamespace}/START_ADVANCEMENT_ERROR`;
export const startAdvancementRequest = ({
  userId,
  advancementType,
  advancementId,
}) => ({
  type: START_ADVANCEMENT_REQUEST,
  payload: { userId, advancementType, advancementId },
  gtm: {
    category: gtm.categories.ADVANCEMENTS,
  },
});
export const startAdvancementResponse = () => ({
  type: START_ADVANCEMENT_RESPONSE,
});
export const startAdvancementError = error => ({
  type: START_ADVANCEMENT_ERROR,
  payload: error,
});
