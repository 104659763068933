/* eslint-disable react/no-multi-comp */

/* eslint-disable react/display-name */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { capitalize, clone } from 'lodash';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { selectedOrganizationSel } from '@context';
import { deleteSubUnitRequest } from '@modules/advancement/subUnits/duck/actions';
import {
  Button,
  Modal,
  PaginationTotal,
  S,
  SingleConfirmationModal,
  Table,
  hasPermissionSel,
  permissions,
  sorterPropTypes,
  unitTypeIdForUnitType,
} from '@shared';
import { toggleArrayItem } from '@utils';

import { subUnitTypeSel } from '../../../../subUnits/duck/selectors';
import { SELECTABLE_PEOPLE_LIMIT, peopleFilters } from '../../../constants';
import {
  currentUserSubunitsSel,
  loadingRenewalSel,
  packRosterFilter,
  saveOptoutProfile,
  setShowMemberTypes,
  setShowSubUnits,
  setSubUnitUserData,
  showMemberTypesSel,
  showSubUnitsSel,
  subUnitUserDataSel,
} from '../../../duck';
import { qaRowClassName } from '../../../helpers';
import CustomSelectionTitle from './CustomSelectionTitle';
import CustomMainRow from './MainRow';
import styles from './PackRosterTable.less';
import PackRosterTableHeader from './PackRosterTableHeader';
import CustomTypeMainRow from './TypeMainRow';
import columns from './packRosterTableColumns';

const PackRosterTable = props => {
  const dispatch = useDispatch();
  const {
    canEditSubCalendar: hasSubUnitOnlyPerms,
    unitTypeId,
    organizationGuid,
  } = useSelector(selectedOrganizationSel);
  const subUnitTypeName = useSelector(subUnitTypeSel);
  const subUnitUserData = useSelector(subUnitUserDataSel);
  const mySubUnits = useSelector(currentUserSubunitsSel);
  const showSubUnits = useSelector(showSubUnitsSel);
  const showMemberTypes = useSelector(showMemberTypesSel);
  const canEditSubUnits = useSelector(state =>
    hasPermissionSel(state, permissions.EDIT_SUB_UNITS),
  );
  const areRenewalsLoading = useSelector(loadingRenewalSel);
  const closeDeleteModalRef = useRef();
  const [defaultSubUnitList, setDefaultSubUnitList] = useState([]);
  const [optOutData, setOptOutData] = useState();
  const [isConfirmOptOutVisible, setIsConfirmOptOutVisible] = useState(false);

  const orgIsPack = useMemo(
    () => +unitTypeId === unitTypeIdForUnitType.Pack,
    [unitTypeId],
  );

  useEffect(() => {
    if (subUnitUserData.subUnitId && subUnitUserData.subUnitId > 0) {
      closeDeleteModalRef.current.openConfirmationModal();
    }
  }, [dispatch, subUnitUserData]);

  useEffect(() => {
    const unnasignedMembers = props.items || [];
    const values = unnasignedMembers.map(member => ({
      userId: member.userId,
      subUnitId: member.subUnitId,
    }));
    setDefaultSubUnitList(values);
  }, [props.items]);

  const handleTableChange = (pagination, filters, sorter) => {
    props.onSorterChange(sorter);
  };

  const shouldDisableRecord = useCallback(
    record => {
      if (!hasSubUnitOnlyPerms || canEditSubUnits) return !record.userId;

      if (record.subUnitId === -1) return true;

      if (record.userId) {
        const hasMatchingSubUnits = mySubUnits.some(sub => {
          const mySubId = sub.denId || sub.patrolId;
          return record.isLeader
            ? record.subUnitListIds.includes(mySubId)
            : mySubId === record.subUnitId;
        });
        return !hasMatchingSubUnits;
      }

      return !record.userId;
    },
    [hasSubUnitOnlyPerms, mySubUnits, canEditSubUnits],
  );

  const handleRowClick = record => () => {
    if (shouldDisableRecord(record)) return;

    const { userId } = record;
    const { selectedRowKeys } = props;
    if (userId) {
      const newKeys = toggleArrayItem(selectedRowKeys, userId);
      props.onSelectedRowKeysChange(newKeys);
    }
  };

  const getAllRowsIds = memoize(items => items.map(({ userId }) => userId));

  const getRowClassName = row => {
    const { selectedRowKeys } = props;
    const isRowSelected = selectedRowKeys.includes(row.userId);
    const isDisabled = !row.userId;

    return cn(
      {
        selected: isRowSelected,
        [styles.unselectableRow]:
          row.isMainRow ||
          (!isRowSelected && selectedRowKeys.length == SELECTABLE_PEOPLE_LIMIT),
        [styles.disabledRow]: isDisabled,
      },
      qaRowClassName(row),
    );
  };

  // userId should be used as main rowkey for any action
  // memberId is set as a fallback key so react can keep track of it
  // users with out a userId cannot be used so this should not cause any issues
  const getPersonRowKey = ({ userId, memberId }) => userId || memberId;

  const getPaginationConfig = () => {
    const { selectedRowKeys } = props;

    return {
      className: 'qa_pagination',
      showTotal: total => (
        <PaginationTotal total={total} selected={selectedRowKeys.length} />
      ),
    };
  };

  const renderHeader = () => {
    const { selectedItems, selectedRowKeys, isOffline } = props;

    return (
      <PackRosterTableHeader
        selectedItems={selectedItems}
        selectedRowKeys={selectedRowKeys}
        isOffline={isOffline}
      />
    );
  };

  const handleSelectAllItems = () => {
    const { items, onSelectedRowKeysChange } = props;
    const validItems = items.filter(
      record => !!record.userId && !shouldDisableRecord(record),
    );
    const selectedItemsKeys = getAllRowsIds(validItems);
    onSelectedRowKeysChange(selectedItemsKeys);
  };

  const handleDeselectAllItems = () => props.onSelectedRowKeysChange([]);

  const areAllSubUnitItemsSelected = (subUnitItems, selectedRowKeys) =>
    subUnitItems.every(({ userId }) => selectedRowKeys.includes(userId));

  const areSomeSubUnitItemsSelected = (subUnitItems, selectedRowKeys) =>
    subUnitItems.some(({ userId }) => selectedRowKeys.includes(userId));

  const handleSelectSubUnitItems = subUnitId => {
    const hasMatchingSubUnits = mySubUnits.some(sub => {
      const mySubId = sub.denId || sub.patrolId;
      return mySubId === subUnitId;
    });

    if (hasSubUnitOnlyPerms && !hasMatchingSubUnits) return;

    const { itemsPerSubUnit, selectedRowKeys, onSelectedRowKeysChange } = props;

    const subUnitItems = itemsPerSubUnit.find(
      subUnit => subUnit.subUnitId == subUnitId,
    ).subRows;
    const subUnitItemsKeys = subUnitItems.map(({ userId }) => userId);
    const areAllSelected = areAllSubUnitItemsSelected(
      subUnitItems,
      selectedRowKeys,
    );
    const selectedRows = areAllSelected
      ? selectedRowKeys.filter(key => !subUnitItemsKeys.includes(key))
      : subUnitItemsKeys;
    onSelectedRowKeysChange(selectedRows);
  };

  const transform = items => {
    const { selectedRowKeys } = props;
    return items.map(subUnitSection => ({
      ...subUnitSection,
      areAnyItemsSelected: areSomeSubUnitItemsSelected(
        subUnitSection.subRows,
        selectedRowKeys,
      ),
      itemsCount: subUnitSection.subRows.length,
      onSelect: () => handleSelectSubUnitItems(subUnitSection.subUnitId),
    }));
  };

  const getMainRowsConfig = () => {
    if (props.subUnitViewActive) {
      return {
        CustomMainRow,
        colSpan: 11,
      };
    }
    if (showMemberTypes) {
      return {
        CustomMainRow: CustomTypeMainRow,
        colSpan: 11,
      };
    }
  };

  const itemsAmount = memoize(items =>
    items.reduce((acc, item) => {
      const count = item.userId ? 1 : 0;
      return acc + count;
    }, 0),
  );
  const handleconfirmDelete = () => {
    dispatch(deleteSubUnitRequest(subUnitUserData.subUnitId));
    dispatch(setSubUnitUserData({ subUnitId: 0, subUnitName: '' }));
  };

  const handleCancelDelete = () => {
    dispatch(setSubUnitUserData({ subUnitId: 0, subUnitName: '' }));
  };

  const handleClickSubUnitHeader = () => {
    dispatch(setShowMemberTypes(false));
    dispatch(setShowSubUnits(!showSubUnits));
  };

  const handleChangeAdultSubuint = (subUnitId, userId) => {
    const clonedList = clone(defaultSubUnitList);
    const index = defaultSubUnitList.findIndex(
      item => item.userId === Number(userId),
    );
    clonedList[index].subUnitId = Number(subUnitId);
    setDefaultSubUnitList(clonedList);
  };

  const handleClickTypeHeader = () => {
    dispatch(
      packRosterFilter(
        Object.values(peopleFilters).reduce(
          (acc, f) => ({ ...acc, [f]: true }),
          {},
        ),
      ),
    );
    dispatch(setShowSubUnits(false));
    dispatch(setShowMemberTypes(!showMemberTypes));
  };

  const onSaveOptOut = data => {
    setOptOutData(data);
    setIsConfirmOptOutVisible(true);
  };

  const onConfirmOptOut = () => {
    dispatch(saveOptoutProfile(optOutData));
    setIsConfirmOptOutVisible(false);
  };

  const onCancelOptOut = () => {
    setOptOutData(undefined);
    setIsConfirmOptOutVisible(false);
  };

  const {
    loading,
    items,
    itemsPerSubUnit,
    itemsPerMemberType,
    subUnitViewActive,
    selectedRowKeys,
    onSelectedRowKeysChange,
    sorter,
  } = props;

  const itemsPresentation = showMemberTypes
    ? itemsPerMemberType
    : subUnitViewActive
    ? transform(itemsPerSubUnit)
    : items;
  const rowSelection = {
    selectedRowKeys,
    getCheckboxProps: record => ({
      disabled: shouldDisableRecord(record),
    }),
    columnTitle: (
      <CustomSelectionTitle
        selectedItemsAmount={selectedRowKeys.length}
        itemsAmount={itemsAmount(items)}
        onSelectAll={handleSelectAllItems}
        onDeselectAll={handleDeselectAllItems}
      />
    ),
    onChange: onSelectedRowKeysChange,
  };

  return (
    <Fragment>
      <SingleConfirmationModal
        ref={closeDeleteModalRef}
        message={
          <FormattedHTMLMessage
            id="packRoster.MainRow.confirmDeleteSubUnit"
            values={{
              subUnitName: capitalize(subUnitUserData.subUnitName),
              subUnitTypeName: capitalize(subUnitTypeName),
            }}
          />
        }
        onConfirm={handleconfirmDelete}
        onCancel={handleCancelDelete}
        roundedModal
      />
      <Table
        size="middle"
        fixedLayout
        clickableRows
        className={cn('Joyride__PackRosterTable', styles.table)}
        loading={loading}
        rowSelection={rowSelection}
        rowKey={getPersonRowKey}
        rowClassName={getRowClassName}
        title={renderHeader}
        columns={columns(
          {
            ...props,
            showSubUnits,
            canEditSubUnits,
            defaultSubUnitList,
            areRenewalsLoading,
            orgIsPack,
            hasSubUnitOnlyPerms,
            mySubUnits,
            organizationGuid,
            onSaveOptOut,
          },
          styles,
          handleClickSubUnitHeader,
          handleChangeAdultSubuint,
          handleClickTypeHeader,
        )}
        dataSource={itemsPresentation}
        onChange={handleTableChange}
        sorter={sorter}
        onRow={record => ({
          onClick: handleRowClick(record),
        })}
        pagination={getPaginationConfig()}
        mainRows={getMainRowsConfig()}
        scroll={{ x: true }}
      />
      <Modal
        visible={isConfirmOptOutVisible}
        onCancel={onCancelOptOut}
        closable={false}
      >
        <S size="3">
          <FormattedMessage id="advancement.PackRoster.confirmOptOut" />
        </S>
        <div className={styles.modalButtonsContainer}>
          <Button onClick={onCancelOptOut} shape="round" size="small">
            {<FormattedMessage id="shared.no" />}
          </Button>
          <Button
            type="primary"
            onClick={onConfirmOptOut}
            shape="round"
            size="small"
          >
            {<FormattedMessage id="shared.yes" />}
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

PackRosterTable.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerSubUnit: PropTypes.array.isRequired,
  itemsPerMemberType: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  subUnitViewActive: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  sorter: sorterPropTypes,
  loading: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  isOffline: PropTypes.bool.isRequired,
  onSorterChange: PropTypes.func.isRequired,
  onSelectedRowKeysChange: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  subUnitType: PropTypes.string,
  isSubUnitAllowed: PropTypes.bool.isRequired,
  approvedSubUnits: PropTypes.array,
};

export default PackRosterTable;
