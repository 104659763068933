import { createAction } from '@reduxjs/toolkit';

import type { Event } from '@appTypes/esb';

import { calendarModuleName, youthProfileModuleName } from '../constants';

export const ROUTE_YOUTH_ADVANCEMENTS_LIST = `${youthProfileModuleName}/ROUTE_YOUTH_ADVANCEMENTS_LIST`;
export const goToYouthAdvancementsList = createAction<{
  userId: number;
  advancementType: string;
  advancementStatus?: string;
}>(ROUTE_YOUTH_ADVANCEMENTS_LIST);

export const ROUTE_CALENDAR_EVENT_DETAILS = `${calendarModuleName}/ROUTE_CALENDAR_EVENT_DETAILS`;
export const goToCalendarEventDetails = createAction<{
  event?: Event;
  id: number;
}>(ROUTE_CALENDAR_EVENT_DETAILS);

export const goToYouthProfile = createAction(
  `${youthProfileModuleName}/ROUTE_YOUTH_PROFILE`,
  function prepare(userId: number) {
    return {
      payload: {
        userId,
      },
    };
  },
);
export const ROUTE_YOUTH_PROFILE = goToYouthProfile.type;
