import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LoopIcon from '@material-ui/icons/Loop';
import { Avatar } from 'bsa-ui';
import cn from 'classnames';
import _, { groupBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import validateForm from '../../../utils/validateForm';
import {
  activityTypeForActivityTypeId,
  activityTypeIds,
  activityValuesTabs,
  notApplicableCategories,
  pageUI,
  shortViewDateFormat,
} from '../../constants';
import { isMobileSel } from '../../duck';
import useAllDayCheckbox from '../../hooks/useAllDayCheckbox';
import { intl } from '../../localization/translate';
import ActivityTypeIcon from '../ActivityTypeIcon';
import Button from '../Button';
import Checkbox from '../Checkbox';
import CollapseDetails from '../CollapseDetails';
import DatePicker from '../DatePicker';
import Form from '../Form';
import Input from '../Input';
import Modal from '../Modal';
import PersonAvatar from '../PersonAvatar';
import S from '../S';
import Select from '../Select/Select';
import StateSelect from '../StateSelect';
import T from '../T';
import TimePicker from '../TimePicker';
import UnitGenderPill from '../UnitGenderPill';
import { Col, Radio, Row, Tabs, Tag, Tooltip } from '../bsaComponents';
import styles from './ActivitiesFormModal.less';
import { GroupTab } from './GroupTab';
import { IndividualTab } from './IndividualTab';
import { UsersModal } from './UsersModal';
import {
  activityUserValues,
  getNewUsersEvent,
  getNonRegisteredData,
  getOperationalUsers,
  getSortedCategories,
  hasDifferentValues,
  requiredRule,
  setTime,
} from './utils';

const FormItem = Form.Item;
const { Group: InputGroup, TextArea } = Input;
const { TabPane } = Tabs;
const RadioGroup = Radio.Group;
const { Option, OptGroup } = Select;

const ActivitiesFormModal = ({
  visible,
  activityTypeId,
  states,
  localLocationsList,
  searchResults,
  isLoadingLocations,
  allCategories,
  loadingStates,
  selectableUsers,
  eventDetails,
  activityData,
  organizationGuid,
  loggedInUserId,
  saving,
  onSearchLocations,
  onSave,
  onCancel,
  form,
  selectedEventUnits,
  selectedEventUnitsOnly,
}) => {
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [locationText, setLocationText] = useState();
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [activeTab, setActiveTab] = useState(activityValuesTabs.BASIC);
  const [initialTab, setInitialTab] = useState(activityValuesTabs.BASIC);
  const [isServiceAssisted, setIsServiceAssisted] = useState(false);
  const isMobile = useSelector(isMobileSel);
  const isMultiUnitEvent = selectedEventUnitsOnly.length > 1 || false;
  const { getFieldDecorator, getFieldsValue, setFieldsValue, resetFields } =
    form;
  const formValues = getFieldsValue();
  const startDate = formValues.startDate;
  const validate = useCallback(() => validateForm(form), [form]);
  const allDay = useAllDayCheckbox({
    form,
    start: startDate,
    end: formValues.endTime,
    page: pageUI.ACTIVITY,
  });

  const modalType = useMemo(
    () => activityTypeForActivityTypeId[activityTypeId],
    [activityTypeId],
  );

  const isEditing = !!activityData;

  const [selectedUnit, setSelectedUnit] = useState({});
  const [selectedOrgGuid, setSelectedOrgGuid] = useState(() =>
    isEditing ? activityData.hostOrganizationGuid : '',
  );
  const disableAddInvitee = isMultiUnitEvent && isEmpty(selectedUnit);
  const selectedEventSubUnits = selectedEventUnits.filter(
    unit => unit.isSubUnit && unit.unitId === selectedUnit.id,
  );
  const showDeselect = isMultiUnitEvent && !isEmpty(selectedEventSubUnits);

  const [conditionalSelectableUsers, setConditionalSelectableUsers] = useState(
    () =>
      isMultiUnitEvent
        ? selectedUnit
          ? selectableUsers
          : []
        : selectableUsers,
  );

  const onSelectUnit = useCallback(
    (orgGuid, options) => {
      const selectedUnit = options.props.data;

      setSelectedUnit(selectedUnit);
      setSelectedOrgGuid(orgGuid);
      setConditionalSelectableUsers(
        selectableUsers.filter(users =>
          users?.units.some(unit => unit.unitId === selectedUnit.id),
        ),
      );
    },
    [selectableUsers],
  );

  useEffect(() => {
    if (isEditing) {
      const foundSelectedUnit = selectedEventUnitsOnly.find(
        unit => unit.organizationGuid === selectedOrgGuid,
      );
      setSelectedUnit(foundSelectedUnit);
    }
  }, [isEditing, selectedEventUnitsOnly, selectedOrgGuid]);

  useEffect(() => {
    if (activityData) {
      return;
    }

    if (eventDetails && eventDetails.users) {
      const eventUsersId = eventDetails.users.map(user => user.userId);
      setSelectedUsers(new Set(eventUsersId));
    }

    if (eventDetails) {
      const {
        description,
        endDate,
        endTime,
        location,
        name,
        startDate,
        startTime,
      } = eventDetails;
      if (location) {
        setLocationText(location);
      }

      setFieldsValue({
        description: description?.replace(/(<([^>]+)>)/gi, ''),
        endDate,
        endTime,
        location: location ? location : undefined,
        name,
        startDate,
        startTime,
      });
    }
  }, [eventDetails, setFieldsValue, activityData]);

  const sortedCategories = useMemo(
    () => getSortedCategories(allCategories, activityTypeId),
    [allCategories, activityTypeId],
  );

  useEffect(() => {
    if (activityData) {
      const { registeredAdults = [], registeredYouths = [] } = activityData;
      const activityUsers = registeredAdults.concat(registeredYouths);
      const activityUsersId = activityUsers.map(user => user.userId);
      setSelectedUsers(new Set(activityUsersId));

      const youthsHasDifferentValues = hasDifferentValues(registeredYouths);
      const adultsHasDifferentValues = hasDifferentValues(registeredAdults);

      const resultTab =
        youthsHasDifferentValues || adultsHasDifferentValues
          ? activityValuesTabs.ADVANCED
          : activityValuesTabs.BASIC;

      setInitialTab(resultTab);
    }

    if (activityData) {
      const {
        startDate,
        endDate,
        name,
        allDay,
        addressLine1,
        addressLine2,
        city,
        akelaStateId,
        categoryId,
        description,
        nonRegisteredOrgParticipants: {
          nonRegisteredAdultCount = undefined,
          nonRegisteredAdultHours = undefined,
          nonRegisteredYouthCount = undefined,
          nonRegisteredYouthHours = undefined,
        },
        hostOrganizationGuid,
      } = activityData;
      setFieldsValue({
        name,
        startDate,
        endDate,
        startTime: startDate,
        endTime: endDate,
        allDay,
        location: activityData.location || '',
        addressLine1,
        addressLine2,
        city,
        akelaStateId: akelaStateId ? `${akelaStateId}` : undefined,
        categoryId,
        description,
        nonRegisteredAdultCount,
        nonRegisteredAdultHours,
        nonRegisteredYouthCount,
        nonRegisteredYouthHours,
        hostOrganizationGuid,
      });
    }
  }, [activityData, setFieldsValue]);

  useEffect(() => {
    if (activityData && activityData.categoryId !== 0) {
      setIsServiceAssisted(
        sortedCategories.eagleCategories.some(
          category => category.id === activityData.categoryId,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedCategories.eagleCategories]);

  const onSearch = useCallback(
    searchText => {
      if (searchText) {
        onSearchLocations(searchText);
        setLocationText(searchText);
      }
    },
    [setLocationText, onSearchLocations],
  );

  const handleDeselectLocation = useCallback(() => {
    form.setFieldsValue({
      zip5: undefined,
      akelaStateId: undefined,
      addressLine1: undefined,
      addressLine2: undefined,
      city: undefined,
    });
  }, [form]);

  const populateLocation = useCallback(
    locationDetails => {
      const {
        zip5,
        state: locationState,
        addressLine1,
        city,
      } = locationDetails;
      const { id: akelaStateId } = states.find(
        state => state.short === locationState,
      );
      form.setFieldsValue({
        zip5,
        akelaStateId,
        addressLine1,
        addressLine2: '',
        city,
      });
    },
    [form, states],
  );

  const onClickSaveUsers = usersIds => {
    setSelectedUsers(usersIds);
    setUserModalVisible(false);
  };

  const handleRemoveAtendee = userId => () => {
    setSelectedUsers(prevSelected => {
      const newSelectedPersons = new Set(prevSelected);
      newSelectedPersons.delete(userId);

      return newSelectedPersons;
    });
  };

  const handleChangeTab = tab => {
    setActiveTab(tab);
  };

  const usersSelected = useMemo(
    () =>
      isMultiUnitEvent
        ? isEmpty(selectedUnit)
          ? []
          : selectableUsers.filter(
              u =>
                selectedUsers.has(u.userId) &&
                u.units?.some(unit => unit.id === selectedUnit.id),
            )
        : selectableUsers.filter(u => selectedUsers.has(u.userId)),
    [isMultiUnitEvent, selectableUsers, selectedUnit, selectedUsers],
  );

  const selectAllSubUnitAttendee = subUnitId => {
    const eventUsersId = eventDetails.users.map(user => user.userId);

    const selectedUserIds = selectableUsers
      .filter(
        user =>
          user.units.some(
            unit => unit.patrolId === subUnitId || unit.denId === subUnitId,
          ) && eventUsersId.includes(user.userId),
      )
      .map(user => user.userId);

    setSelectedUsers(prevSelected => {
      const newSelectedPersons = new Set(prevSelected);
      selectedUserIds.forEach(userId => newSelectedPersons.add(userId));

      return newSelectedPersons;
    });
  };

  const deselectAllSubUnitAttendee = () => {
    setSelectedUsers(new Set());
  };

  const usersGrouped = useMemo(() => {
    if (usersSelected.length) {
      const { false: youths, true: leaders } = groupBy(
        usersSelected,
        'isAdult',
      );
      return { youths: youths || [], leaders: leaders || [] };
    }

    return { youths: [], leaders: [] };
  }, [usersSelected]);

  const totalToBeRecorded = useMemo(() => {
    const numericKeys = Object.keys(formValues).filter(key => {
      const isValidInput =
        key.includes('days') ||
        key.includes('nights') ||
        key.includes('miles') ||
        key.includes('hours');
      if (activeTab === activityValuesTabs.BASIC) {
        return key.includes('Basic') && isValidInput;
      }

      return key.includes('_') && isValidInput;
    });

    const sumValues = keys =>
      keys.reduce((accumulator, currentKey) => {
        const value = formValues[currentKey]
          ? Number(formValues[currentKey])
          : 0;
        return accumulator + value;
      }, 0);

    if (activeTab === activityValuesTabs.BASIC) {
      const adultKeys = [];
      const youthKeys = [];
      numericKeys.forEach(key => {
        key.includes('Youth')
          ? youthKeys.push(key)
          : key.includes('Adult')
          ? adultKeys.push(key)
          : null;
      });

      return (
        sumValues(adultKeys) * usersGrouped.leaders.length +
        sumValues(youthKeys) * usersGrouped.youths.length
      );
    }

    return sumValues(numericKeys);
  }, [formValues, activeTab, usersGrouped]);

  const onToggleRadio = useCallback(
    e => {
      setIsServiceAssisted(e.target.value);
      resetFields(['categoryId']);
    },
    [setIsServiceAssisted, resetFields],
  );

  const saveFormData = useCallback(async () => {
    const activityFormData = await validate();

    if (activityFormData) {
      const akelaStateId = activityFormData.akelaStateId
        ? +activityFormData.akelaStateId
        : activityFormData.akelaStateId;
      const allDay = activityFormData.allDay;

      const registeredAdults = usersGrouped.leaders.length
        ? activityUserValues(
            activityTypeId,
            activeTab,
            'Adult',
            activityFormData,
            usersGrouped.leaders,
            organizationGuid,
            loggedInUserId,
          )
        : undefined;
      const registeredYouths = usersGrouped.youths.length
        ? activityUserValues(
            activityTypeId,
            activeTab,
            'Youth',
            activityFormData,
            usersGrouped.youths,
            organizationGuid,
            loggedInUserId,
          )
        : undefined;

      const operationalUsers = activityData
        ? getOperationalUsers(
            registeredAdults,
            registeredYouths,
            activityData.registeredAdults,
            activityData.registeredYouths,
          )
        : { usersToAdd: [], usersToUpdate: [], usersToDelete: [] };

      const usersToAddToEvent = getNewUsersEvent(
        eventDetails.users,
        registeredAdults,
        registeredYouths,
      );

      const nonRegisteredData = getNonRegisteredData(
        activityTypeId,
        activityFormData,
        activityData?.nonRegisteredOrgParticipants,
      );

      const formattedActivity = {
        id: activityData ? activityData.id : undefined,
        addressLine1: activityFormData.addressLine1,
        addressLine2: activityFormData.addressLine2,
        akelaStateId,
        allDay,
        city: activityFormData.city,
        description: activityFormData.description,
        endDate: activityFormData.endDate,
        endTime: allDay ? setTime(23, 59) : activityFormData.endTime,
        location: activityFormData.location || undefined,
        name: activityFormData.name,
        startDate: activityFormData.startDate,
        startTime: allDay ? setTime() : activityFormData.startTime,
        activityTypeId,
        categoryId:
          notApplicableCategories[activityTypeId] ||
          +activityFormData.categoryId,
        hostOrganizationGuid: isMultiUnitEvent
          ? activityFormData.hostOrganizationGuid
          : organizationGuid,
        isPersonalActivity: false,
        isEveryChildOrg: false,
        registeredAdults,
        registeredYouths,
        organizationGuid: isMultiUnitEvent
          ? activityFormData.hostOrganizationGuid
          : organizationGuid,
        ...nonRegisteredData,
      };

      onSave({
        formData: formattedActivity,
        ...operationalUsers,
        usersToAddToEvent,
      });
    }
  }, [
    validate,
    activityTypeId,
    activeTab,
    usersGrouped,
    activityData,
    eventDetails.users,
    loggedInUserId,
    onSave,
    organizationGuid,
    isMultiUnitEvent,
  ]);

  return (
    <Modal
      className={styles.roundedModal}
      headerColor="scouting-warm-gray"
      mask={!isMobile}
      onCancel={onCancel}
      visible={visible}
      noPadding
    >
      <div className={styles.header}>
        <Avatar size="large">
          <ActivityTypeIcon activityType={modalType} />
        </Avatar>
        <T size="1" colored>
          <FormattedMessage
            id={`activitiesModal.${modalType}.${activityData ? 'edit' : 'add'}`}
          />
        </T>
      </div>
      <Form className={styles.main}>
        <div className={styles.form}>
          {isMultiUnitEvent && (
            <div className={styles.unitSelector}>
              <Row gutter={{ lg: 12 }}>
                <Col xs={24} lg={24}>
                  <S size="5">
                    <FormattedMessage id="activitiesModal.unitSelector" />
                  </S>
                </Col>
              </Row>
              <Row gutter={{ lg: 12 }}>
                <Col xs={24} lg={24}>
                  <FormItem>
                    {getFieldDecorator('hostOrganizationGuid', {
                      rules: [requiredRule()],
                    })(
                      <Select
                        id="eventUnitsSelector"
                        onSelect={onSelectUnit}
                        showSearch
                        value={selectedOrgGuid}
                        disabled={isEditing}
                        size="large"
                      >
                        {selectedEventUnitsOnly.map(unit => (
                          <Option
                            key={unit.id}
                            value={unit.organizationGuid}
                            data={unit}
                          >
                            <Tag
                              key={unit.id}
                              className={styles.tag}
                              outline={false}
                            >
                              <Row type="flex" align="middle" wrap={false}>
                                <Col className={styles.unitName}>
                                  <S size={'3'}>{_.capitalize(unit.name)}</S>
                                </Col>
                                {unit.unitGender && (
                                  <Col className={styles.unitGender}>
                                    <UnitGenderPill
                                      colored
                                      gender={unit.unitGender}
                                    />
                                  </Col>
                                )}
                              </Row>
                            </Tag>
                          </Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}

          <Row gutter={{ lg: 12 }}>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('name', {
                  initialValue: '',
                  rules: [
                    requiredRule(),
                    {
                      max: 50,
                      message: intl.formatMessage(
                        {
                          id: 'shared.maxCharacters',
                        },
                        {
                          max: 50,
                        },
                      ),
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({
                      id: `activitiesModal.${modalType}.name`,
                    })}
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <InputGroup compact>
                <FormItem>
                  {getFieldDecorator('startDate', {
                    rules: [requiredRule()],
                  })(
                    <DatePicker
                      className={cn(styles.datePicker, styles.datePickerLeft)}
                      suffixIcon={<React.Fragment />}
                      size="small"
                      placeholder={intl.formatMessage({
                        id: 'shared.date.startDate',
                      })}
                      format={shortViewDateFormat}
                      fluid
                      floatingLabel
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('endDate', {
                    rules: [requiredRule()],
                  })(
                    <DatePicker
                      disableDates={{ minDate: startDate }}
                      className={cn(styles.datePicker, styles.datePickerRight)}
                      size="small"
                      placeholder={intl.formatMessage({
                        id: 'shared.date.endDate',
                      })}
                      format={shortViewDateFormat}
                      fluid
                      floatingLabel
                    />,
                  )}
                </FormItem>
              </InputGroup>
            </Col>
          </Row>
          <Row gutter={{ lg: 12 }}>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('allDay', {
                  valuePropName: 'checked',
                  initialValue: false,
                })(
                  <Checkbox setFieldsValue={setFieldsValue}>
                    <FormattedMessage
                      id={`activitiesModal.${modalType}.allDay`}
                    />
                  </Checkbox>,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <InputGroup compact>
                <FormItem className={styles.formItem}>
                  {getFieldDecorator('startTime', {
                    rules: allDay ? [] : [requiredRule()],
                  })(
                    <TimePicker
                      className={cn(styles.datePicker, styles.datePickerLeft)}
                      floatingLabel
                      rounded
                      fluid
                      disabled={allDay}
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.date.startTime',
                      })}
                    />,
                  )}
                </FormItem>
                <FormItem className={styles.formItem}>
                  {getFieldDecorator('endTime', {
                    rules: allDay ? [] : [requiredRule()],
                  })(
                    <TimePicker
                      className={cn(styles.datePicker, styles.datePickerRight)}
                      floatingLabel
                      rounded
                      fluid
                      disabled={allDay}
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.date.endTime',
                      })}
                    />,
                  )}
                </FormItem>
              </InputGroup>
            </Col>
          </Row>
          <Row gutter={{ lg: 12 }}>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('location', {
                  initialValue: undefined,
                  rules: [
                    {
                      max: 255,
                      message: intl.formatMessage(
                        {
                          id: 'shared.maxCharacters',
                        },
                        { max: 255 },
                      ),
                    },
                  ],
                })(
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'shared.location',
                    })}
                    rounded
                    floatingLabel
                    showSearch
                    filterOption
                    size="large"
                    optionFilterProp="text"
                    notFoundContent={isLoadingLocations ? <LoopIcon /> : null}
                    onSearch={onSearch}
                  >
                    {isLoadingLocations
                      ? []
                      : [
                          <Option
                            key="deselct_location"
                            value={undefined}
                            text={undefined}
                            onClick={handleDeselectLocation}
                          >
                            <FormattedMessage id="shared.deselectLocation" />
                          </Option>,
                          locationText ? (
                            <Option
                              key="any_locations"
                              value={locationText}
                              text={locationText}
                            >
                              {locationText}
                            </Option>
                          ) : null,
                          <OptGroup
                            key="local_locations"
                            label={
                              <FormattedMessage id="progress.ActivityLocation.local" />
                            }
                          >
                            {localLocationsList.map(locationItem => (
                              <Option
                                key={`local${locationItem.id}`}
                                value={locationItem.campName}
                                text={locationItem.campName}
                                onClick={() => populateLocation(locationItem)}
                              >
                                {locationItem.campName}
                              </Option>
                            ))}
                          </OptGroup>,
                          <OptGroup
                            key="search_results"
                            label={
                              <FormattedMessage id="progress.ActivityLocation.all" />
                            }
                          >
                            {searchResults.map(locationItem => (
                              <Option
                                key={`all${locationItem.id}`}
                                value={String(locationItem.campName)}
                                text={locationItem.campName}
                                onClick={() => populateLocation(locationItem)}
                              >
                                {locationItem.campName}
                              </Option>
                            ))}
                          </OptGroup>,
                        ]}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('addressLine1', {
                  rules: [
                    {
                      pattern: /^[. a-zA-Z0-9_-]*$/,
                      message: intl.formatMessage({
                        id: 'shared.address.address1.errors.specialCharsValidation',
                      }),
                    },
                    {
                      max: 255,
                      message: intl.formatMessage(
                        {
                          id: 'shared.maxCharacters',
                        },
                        { max: 255 },
                      ),
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({
                      id: 'shared.address.address1.placeholder',
                    })}
                    suffix={<LocationOnIcon className={styles.icon} />}
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ lg: 12 }}>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('addressLine2', {
                  rules: [
                    {
                      pattern: /^[. a-zA-Z0-9_-]*$/,
                      message: intl.formatMessage({
                        id: 'shared.address.address1.errors.specialCharsValidation',
                      }),
                    },
                    {
                      max: 255,
                      message: intl.formatMessage(
                        {
                          id: 'shared.maxCharacters',
                        },
                        { max: 255 },
                      ),
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({
                      id: 'shared.address.address2.placeholder',
                    })}
                    suffix={<LocationOnIcon className={styles.icon} />}
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('city', {
                  rules: [
                    {
                      max: 50,
                      message: intl.formatMessage(
                        {
                          id: 'shared.maxCharacters',
                        },
                        { max: 50 },
                      ),
                    },
                  ],
                })(
                  <Input
                    size="large"
                    placeholder={intl.formatMessage({
                      id: 'shared.address.city.placeholder',
                    })}
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={{ lg: 12 }}>
            <Col xs={24} lg={12}>
              <FormItem>
                {getFieldDecorator('akelaStateId')(
                  <StateSelect
                    size="large"
                    states={states}
                    statesLoaded={loadingStates}
                    rounded
                    floatingLabel
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <T className={styles.detailsT} colored size="5">
              <FormattedMessage
                id={`activitiesModal.${modalType}.detailsTitle`}
              />
            </T>
          </Row>
          {activityTypeId === activityTypeIds.SERVICE_PROJECTS && (
            <Row gutter={{ lg: 12 }}>
              <Col className={styles.categoryLabel}>
                <>
                  <FormattedMessage id="activity.ActivityDetails.field.category.assisted" />
                  <Tooltip
                    overlayStyle={{ maxWidth: 'unset' }}
                    title={
                      <FormattedHTMLMessage id="activity.ActivityDetails.field.category.tooltip" />
                    }
                  >
                    <InfoIcon className={styles.icon} />
                  </Tooltip>
                </>
              </Col>
              <Col xs={24} lg={12}>
                <RadioGroup onChange={onToggleRadio} value={isServiceAssisted}>
                  <Radio value={false}>
                    <FormattedMessage id="activity.ActivityDetails.field.category.assisted.no" />
                  </Radio>
                  <Radio value={true}>
                    <FormattedMessage id="activity.ActivityDetails.field.category.assisted.yes" />
                  </Radio>
                </RadioGroup>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem>
                  {getFieldDecorator('categoryId', {
                    rules: [requiredRule()],
                  })(
                    <Select
                      size="large"
                      rounded
                      floatingLabel
                      placeholder={intl.formatMessage({
                        id: 'activity.ActivityDetails.field.category.placeholder',
                      })}
                      label={intl.formatMessage({
                        id: 'activity.ActivityDetails.field.category',
                      })}
                      optionFilterProp="text"
                      showSearch
                    >
                      {(isServiceAssisted
                        ? sortedCategories.eagleCategories
                        : sortedCategories.nonEagleCategories
                      ).map(({ id, name }) => (
                        <Option key={`category-${id}`} text={name} value={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <FormItem>
                {getFieldDecorator('description')(
                  <TextArea
                    className={styles.roundInput}
                    placeholder={intl.formatMessage({
                      id: 'activitiesModal.notes',
                    })}
                    autosize={{ minRows: 3 }}
                    maxLength={1500}
                    charCounterBelow
                  />,
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs
                size="small"
                activeKey={activeTab}
                onChange={handleChangeTab}
                className={styles.customTabs}
              >
                <TabPane
                  tab={intl.formatMessage({ id: 'activitiesModal.groups' })}
                  key={activityValuesTabs.BASIC}
                >
                  <GroupTab
                    activityData={activityData}
                    activityTypeId={activityTypeId}
                    eventDetails={eventDetails}
                    form={form}
                    initialTab={initialTab}
                    isActive={activeTab === activityValuesTabs.BASIC}
                    leaders={usersGrouped.leaders}
                    youths={usersGrouped.youths}
                  />
                </TabPane>
                <TabPane
                  tab={intl.formatMessage({
                    id: 'activitiesModal.individuals',
                  })}
                  key={activityValuesTabs.ADVANCED}
                >
                  <IndividualTab
                    form={form}
                    isActive={activeTab === activityValuesTabs.ADVANCED}
                    initialTab={initialTab}
                    youths={usersGrouped.youths}
                    leaders={usersGrouped.leaders}
                    activityTypeId={activityTypeId}
                    activityData={activityData}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          {activityTypeId === activityTypeIds.SERVICE_PROJECTS && (
            <Fragment>
              <Row>
                <Col className={styles.tabUsersTitle}>
                  <S size={'4'} colored>
                    <FormattedMessage id="service.NonRegisteredPersons.nonRegisteredYouth" />
                  </S>
                </Col>
                <Col>
                  <Row type="flex" gutter={8}>
                    <Col xs={24} lg={8}>
                      <FormItem>
                        {getFieldDecorator('nonRegisteredYouthCount')(
                          <Input
                            className={styles.removeSuffix}
                            type="number"
                            min="0"
                            size="large"
                            placeholder={
                              <FormattedMessage id="service.NonRegisteredPersons.field.nonRegisteredYouthCount.placeholder" />
                            }
                            rounded
                            floatingLabel
                          />,
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} lg={8}>
                      <FormItem>
                        {getFieldDecorator('nonRegisteredYouthHours')(
                          <Input
                            className={styles.removeSuffix}
                            type="number"
                            min="0"
                            size="large"
                            placeholder={
                              <FormattedMessage id="service.NonRegisteredPersons.field.nonRegisteredYouthHours.placeholder" />
                            }
                            rounded
                            floatingLabel
                          />,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className={styles.tabUsersTitle}>
                  <S size={'4'} colored>
                    <FormattedMessage id="service.NonRegisteredPersons.nonRegisteredAdults" />
                  </S>
                </Col>
                <Col>
                  <Row type="flex" gutter={8}>
                    <Col xs={24} lg={8}>
                      <FormItem>
                        {getFieldDecorator('nonRegisteredAdultCount')(
                          <Input
                            className={styles.removeSuffix}
                            type="number"
                            min="0"
                            size="large"
                            placeholder={
                              <FormattedMessage id="service.NonRegisteredPersons.field.nonRegisteredAdultCount.placeholder" />
                            }
                            rounded
                            floatingLabel
                          />,
                        )}
                      </FormItem>
                    </Col>
                    <Col xs={24} lg={8}>
                      <FormItem>
                        {getFieldDecorator('nonRegisteredAdultHours')(
                          <Input
                            className={styles.removeSuffix}
                            type="number"
                            min="0"
                            size="large"
                            placeholder={
                              <FormattedMessage id="service.NonRegisteredPersons.field.nonRegisteredAdultHours.placeholder" />
                            }
                            rounded
                            floatingLabel
                          />,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
        <div className={styles.sidebar}>
          <div>
            {disableAddInvitee && (
              <S size="5" className={styles.warning}>
                <InfoIcon className={styles.warningIcon} />
                <FormattedMessage id="activitiesModal.addAttendees.warning" />
              </S>
            )}
            <Row display="flex" align="middle">
              <Col span={16}>
                <T colored size="5" noMargin>
                  <FormattedMessage
                    id="activitiesModal.atendeesCounter"
                    values={{ num: usersSelected.length }}
                  />
                </T>
              </Col>
              <Col span={8}>
                <Button
                  color="info"
                  size="small"
                  uppercase
                  ghost
                  noBorder
                  fitText
                  icon={<AddCircleIcon />}
                  onClick={() => setUserModalVisible(true)}
                  className={styles.addBtn}
                  disabled={disableAddInvitee}
                >
                  <FormattedMessage id="shared.add" />
                </Button>
              </Col>
            </Row>
            <Row>
              {selectedUnit &&
                selectedEventSubUnits.map(sub => (
                  <Button
                    key={sub.id}
                    color="info"
                    size="small"
                    uppercase
                    ghost
                    noBorder
                    fitText
                    icon={<AddCircleIcon />}
                    onClick={() => selectAllSubUnitAttendee(sub.id)}
                    className={styles.addBtn}
                    disabled={disableAddInvitee}
                  >
                    <FormattedMessage
                      id="activitiesModal.selectAllAttendeesSub"
                      values={{ name: sub.name }}
                    />
                  </Button>
                ))}
              {showDeselect && (
                <Button
                  color="info"
                  size="small"
                  uppercase
                  ghost
                  noBorder
                  fitText
                  icon={<AddCircleIcon />}
                  onClick={deselectAllSubUnitAttendee}
                  className={styles.deselectBtn}
                  disabled={disableAddInvitee}
                >
                  <FormattedMessage id="activitiesModal.deselectAllAttendees" />
                </Button>
              )}
            </Row>
            {Object.entries(usersGrouped).map(([key, group]) => (
              <Col key={key}>
                <CollapseDetails
                  header={
                    <S size={'3'}>
                      <FormattedMessage id={`activitiesModal.${key}`} />(
                      {group ? group.length : 0})
                    </S>
                  }
                >
                  {group.map(user => (
                    <Tag
                      key={user.userId}
                      className={styles.tag}
                      outline={false}
                    >
                      <PersonAvatar
                        bordered={false}
                        isAdult={user.isAdult}
                        className={styles.tagAvatar}
                        pictureUrl={user.pictureUrl}
                      />
                      <S size={'5'} className={styles.personName}>
                        {user.personFullName}
                      </S>
                      <div
                        className={styles.closeIcon}
                        onClick={handleRemoveAtendee(user.userId)}
                      >
                        <CancelIcon />
                      </div>
                    </Tag>
                  ))}
                </CollapseDetails>
              </Col>
            ))}
          </div>
          {userModalVisible && (
            <UsersModal
              visible={userModalVisible}
              selectableUsers={conditionalSelectableUsers}
              eventDetails={eventDetails}
              currentSelectedUsers={selectedUsers}
              onClickSave={onClickSaveUsers}
              onCancel={() => setUserModalVisible(false)}
            />
          )}
        </div>
      </Form>
      <div className={styles.footerContainer}>
        <div className={styles.totalsContainer}>
          <div className={styles.total}>
            <T bold size="4">
              <FormattedMessage
                id={`activitiesModal.${modalType}.toBeRecorded`}
              />
            </T>
            <span className={styles.totalValue}>{totalToBeRecorded}</span>
          </div>
          <div className={styles.total}>
            <T bold size="4">
              <FormattedMessage id="activitiesModal.totalAtendees" />
            </T>
            <span className={styles.totalValue}>{usersSelected.length}</span>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            type="primary"
            size="large"
            noBorder
            block
            loading={saving}
            onClick={saveFormData}
          >
            <FormattedMessage
              id={`activitiesModal.${modalType}.${
                activityData ? 'editBtn' : 'addBtn'
              }`}
            />
          </Button>
          <Button
            block
            color="scouting-red"
            noBorder
            onClick={onCancel}
            size="large"
            type="primary"
          >
            <FormattedMessage id={'activitiesModal.close'} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ActivitiesFormModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  activityTypeId: PropTypes.number.isRequired,
  states: PropTypes.array.isRequired,
  localLocationsList: PropTypes.array.isRequired,
  searchResults: PropTypes.array.isRequired,
  isLoadingLocations: PropTypes.bool.isRequired,
  allCategories: PropTypes.array.isRequired,
  loadingStates: PropTypes.bool.isRequired,
  selectableUsers: PropTypes.array.isRequired,
  eventDetails: PropTypes.object,
  activityData: PropTypes.object,
  organizationGuid: PropTypes.string.isRequired,
  loggedInUserId: PropTypes.number.isRequired,
  saving: PropTypes.bool.isRequired,
  onSearchLocations: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  selectedEventUnits: PropTypes.array,
  selectedEventUnitsOnly: PropTypes.array,
};

export default Form.create()(ActivitiesFormModal);
