import React, { useEffect, useState } from 'react';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FormattedMessage } from 'react-intl';

import { dragType } from '../../constants';
import S from '../S';
import { Col, Row, Tag, Tooltip } from '../bsaComponents';
import styles from './DragDropPerson.less';

const DragDropPerson = ({
  person,
  onDrop,
  isMax,
  positionDetails,
  openExpirePosition,
  canIndividualExpire,
  addPersonToList,
  isFunctional,
}) => {
  const [ref, setRef] = useState(null);
  const { fullName, isCBCCompleted, expiryDt } = person;

  const [{ isDragging }, dragRef, dragPreview] = useDrag(
    () => ({
      type: dragType.PERSON,
      item: () => {
        const width = ref.offsetWidth;

        return { person, positionDetails, width };
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [person, positionDetails, ref],
  );

  const [, drop] = useDrop(
    () => ({
      accept: dragType.PERSON,
      canDrop: () => !isMax,
      drop: item => {
        isFunctional ? onDrop(item) : addPersonToList(item);
      },
      collect: monitor => ({
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [isFunctional, onDrop, addPersonToList, isMax],
  );

  const handleOnExpire = () => {
    openExpirePosition(person, positionDetails);
  };

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  return (
    <div
      ref={div => {
        dragRef(div);
        setRef(div);
      }}
      style={{ opacity: isDragging ? 0 : 1 }}
    >
      <div ref={drop}>
        <Tag className={styles.tag}>
          <Row
            className={styles.personPlate}
            type="flex"
            align="middle"
            wrap={false}
          >
            <Col>
              <DragIndicatorIcon />
            </Col>
            <Col className={styles.personName}>
              <Tooltip
                title={
                  expiryDt ? (
                    <FormattedMessage
                      id="PositionManager.Tooltip.endDate.position"
                      values={{ endDate: expiryDt }}
                    />
                  ) : (
                    <FormattedMessage id="PositionManager.Tooltip.endDate.default" />
                  )
                }
              >
                <S size={'3'}>{fullName}</S>
              </Tooltip>
            </Col>
            {!!isCBCCompleted && (
              <Tooltip
                title={<FormattedMessage id="PositionManager.Tooltip.CBC" />}
              >
                <Col className={styles.cbcIcon}>
                  <VerifiedUserOutlinedIcon color="primary" />
                </Col>
              </Tooltip>
            )}
            {!!canIndividualExpire && (
              <Col className={styles.removeIcon}>
                <RemoveCircleOutlineIcon
                  onClick={handleOnExpire}
                  color="primary"
                />
              </Col>
            )}
          </Row>
        </Tag>
      </div>
    </div>
  );
};

DragDropPerson.propTypes = {
  person: PropTypes.object,
  onDrop: PropTypes.func,
  isMax: PropTypes.bool,
  positionDetails: PropTypes.object,
  openExpirePosition: PropTypes.func,
  canIndividualExpire: PropTypes.bool,
  addPersonToList: PropTypes.func,
  isFunctional: PropTypes.bool,
};

DragDropPerson.defaultProps = {
  addPersonToList: () => {},
  isFunctional: false,
  onDrop: () => {},
  isMax: false,
  openExpirePosition: () => {},
  canIndividualExpire: false,
};

export default DragDropPerson;
