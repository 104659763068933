import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// TODO: this is a temporary workaround for eslint until bsa-ui is fixed
/* eslint-disable import/named */
import { DatePicker } from 'bsa-ui';

import { isMobileSel } from '../../duck';
import BasePicker, { basePropTypes } from './BasePicker';
import { MobileRangePicker } from './Mobile';

class RangePicker extends React.PureComponent {
  render() {
    const { isMobile } = this.props;
    return (
      <BasePicker
        Picker={isMobile ? MobileRangePicker : DatePicker.RangePicker}
        {...this.props}
      />
    );
  }
}

const mapState = state => ({ isMobile: isMobileSel(state) });

RangePicker.RangePicker = DatePicker.RangePicker;
RangePicker.MonthPicker = DatePicker.MonthPicker;
RangePicker.WeekPicker = DatePicker.WeekPicker;

RangePicker.propTypes = {
  ...basePropTypes,
  isMobile: PropTypes.bool.isRequired,
};

RangePicker.defaultProps = {
  format: 'MM / DD / YYYY',
};

export default connect(mapState)(RangePicker);
