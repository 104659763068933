import { Action } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

import { intl, organizationGuidSel } from '@shared';
import { toastService } from '@toasts';

import { RootState } from '../../../root/store';
import { catchAndReport } from '../../utils/rxjs/operators';
import {
  getRecipientsError,
  getRecipientsRequest,
  getRecipientsResponse,
  sendMessageError,
  sendMessageRequest,
  sendMessageResponse,
} from './actions';
import { getRecipients$, sendMessage$ } from './services';

const recipientsEpic$: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(getRecipientsRequest.match),
    switchMap(() => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      return getRecipients$(organizationGuid).pipe(
        mergeMap(payload =>
          Observable.concat(of(getRecipientsResponse(payload))),
        ),
        catchAndReport(err => of(getRecipientsError(err))),
      );
    }),
  );

const messageEpic$: Epic<Action, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(sendMessageRequest.match),
    switchMap(({ payload }) => {
      const state = state$.value;
      const organizationGuid = organizationGuidSel(state);
      return sendMessage$(payload, organizationGuid).pipe(
        mergeMap(() => {
          toastService.success(
            intl.formatMessage({ id: 'messaging.Message.emailSent' }),
          );
          return Observable.concat(of(sendMessageResponse()));
        }),
        catchAndReport(err => of(sendMessageError(err))),
      );
    }),
  );

export default combineEpics(recipientsEpic$, messageEpic$);
