import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@material-ui/icons/Close';
import { intl } from '../../localization';

import S from '../S';
import Button from '../Button';
import FlexSpacer from '../FlexSpacer';
import styles from './JoyrideTooltip.less';

// can't set 'innerRef' on a DOM element like <div>
// se we need this wrapper class
class TooltipContainer extends React.Component {
  render() {
    return <div className={styles.container}>{this.props.children}</div>;
  }
}

TooltipContainer.propTypes = {
  children: PropTypes.node,
};

// eslint-disable-next-line react/no-multi-comp
const JoyrideTooltip = ({
  continuous,
  index,
  isLastStep,
  locale,
  setTooltipRef,
  size,
  title,
  content,
  primaryProps,
  // backProps,
  closeProps,
  skipProps,
}) => (
  <TooltipContainer innerRef={setTooltipRef}>
    <div className={styles.header}>
      <S size="3" bold>
        {title()}
      </S>
      {/* can't pass arbitrary props to custom tooltips
          so we need to use this wonky logic for now
          https://github.com/gilbarbara/react-joyride/issues/405
        */}
      {continuous && (
        <Button
          color="scouting-warm-gray"
          ghost
          noBorder
          fitText
          icon={<CloseIcon />}
          className={styles.closeButton}
          {...closeProps}
          title={intl.formatMessage({
            id: `${locale.close.props.id}`,
          })}
        />
      )}
    </div>
    <div className={styles.content}>{content()}</div>
    <div className={styles.footer}>
      {!continuous && (
        <Button
          color="scouting-warm-gray"
          ghost
          noBorder
          fitText
          uppercase={false}
          className={styles.skipButton}
          {...skipProps}
          title={intl.formatMessage({
            id: `${locale.skip.props.id}`,
          })}
        >
          <S size="3">{locale.skip}</S>
        </Button>
      )}
      {continuous && size > 1 && (
        <S size="3">
          <FormattedMessage
            id="shared.Joyride.progress"
            values={{ current: index + 1, total: size }}
          />
        </S>
      )}
      <FlexSpacer />
      <Button
        color="scouting-red"
        ghost
        fitText
        uppercase={false}
        {...primaryProps}
        title={intl.formatMessage({
          id: `${isLastStep ? locale.last.props.id : locale.next.props.id}`,
        })}
      >
        {isLastStep ? locale.last : locale.next}
      </Button>
    </div>
  </TooltipContainer>
);

JoyrideTooltip.propTypes = {
  continuous: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
  locale: PropTypes.object.isRequired,
  setTooltipRef: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
  primaryProps: PropTypes.object.isRequired,
  backProps: PropTypes.object.isRequired,
  closeProps: PropTypes.object.isRequired,
  skipProps: PropTypes.object.isRequired,
};

export default JoyrideTooltip;
