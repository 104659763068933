import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';

import { Tooltip, intl } from '@shared';
import { historyItemsTypes } from '../../../constants';
import styles from './HistoryItemStatus.less';

const HistoryItemStatus = ({ type, status, isSynced }) => {
  if (type == historyItemsTypes.ADVANCEMENT_FILE) {
    return <span>{status}</span>;
  }
  return (
    <div className={styles.wrapper}>
      {!isSynced && (
        <Tooltip
          title={intl.formatMessage({
            id: 'advancementHistory.HistoryItemStatus.unSyncedFile',
          })}
        >
          <WarningIcon className={styles.warning} />
        </Tooltip>
      )}
      {status}
    </div>
  );
};

HistoryItemStatus.getUnSyncedFileMessage = () =>
  intl.formatMessage({
    id: 'advancementHistory.HistoryItemStatus.unSyncedFile',
  });

HistoryItemStatus.propTypes = {
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  status: PropTypes.string,
  isSynced: PropTypes.bool,
};

export default HistoryItemStatus;
