import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileSel } from '@screen';
import {
  Button,
  Card,
  Form,
  Vr,
  activityTypeIds,
  historyItemsTypes,
  offlineSel,
} from '@shared';
import { hasPermissionSel, permissions } from '@user';
import { validateForm } from '@utils';

import { showRemoveParticipantModal } from '../../../activities/duck';
import { EditCampoutButton } from '../../../campouts/components';
import { ActivityValuesForm, PersonsList } from '../../../common';
import { EditEagleProjectButton } from '../../../eagleProject';
import { EditHikeButton } from '../../../hikes';
import { EditLongCruiseButton } from '../../../longCruise';
import { EditServiceButton } from '../../../service';
import { openUnapproveModal } from '../../../unapproveAdvancement';
import {
  advancementSel,
  closeEditAdvancement,
  loadingSel,
  personSel,
  updateAdvancementRequest,
} from '../../duck';
import AdvancementInfo from './AdvancementInfo';
import styles from './EditAdvancementForm.less';
import { EditableAdvancementForm } from './EditForms';

const EditAdvancementForm = props => {
  const { form } = props;
  const advancement = useSelector(advancementSel);
  const person = useSelector(personSel);
  const loading = useSelector(loadingSel);
  const isMobile = useSelector(isMobileSel);
  const isOffline = useSelector(offlineSel);
  const canEdit = useSelector(state =>
    hasPermissionSel(
      state,
      permissions.EDIT_DATA,
      permissions.APPROVE_ADVANCEMENTS,
    ),
  );
  const [loadingActivity, setLoadingActivity] = useState(false);

  const dispatch = useDispatch();
  const onUpdateAdvancement = updatedValues => {
    dispatch(updateAdvancementRequest(updatedValues));
  };
  const onOpenUnapproveModal = (advancement, youthName) => {
    dispatch(openUnapproveModal(advancement, youthName));
  };

  const handleUpdateAdvancement = async () => {
    const values = await validateForm(form);
    if (values) {
      onUpdateAdvancement(values);
    }
  };

  const handleOpenUnapproveModal = () => {
    const { personShortFullName } = person;
    onOpenUnapproveModal(advancement, personShortFullName);
  };

  const handleOpenRemovalModal = () => {
    dispatch(showRemoveParticipantModal(true));
  };

  const handleCloseEditModal = () => {
    dispatch(closeEditAdvancement());
  };

  if (!advancement) {
    return null;
  }

  const { canUnapproveAdvancement, activityTypeId, activityId, type } =
    advancement;

  const layout = isMobile ? 'vertical' : 'inline';

  const editButtons = {
    [activityTypeIds.SERVICE_PROJECTS]: EditServiceButton,
    [activityTypeIds.CAMPOUTS]: EditCampoutButton,
    [activityTypeIds.HIKES]: EditHikeButton,
    [activityTypeIds.LONG_CRUISE]: EditLongCruiseButton,
    [activityTypeIds.EAGLE_SERVICE_PROJECT]: EditEagleProjectButton,
  };
  const EditButton = editButtons[activityTypeId];

  return (
    <React.Fragment>
      <Form layout={layout}>
        <div className={styles.mainContentWrapper}>
          <Card noPadding shadow={isMobile} className={styles.formContainer}>
            <AdvancementInfo advancement={advancement} />
            {type === historyItemsTypes.ADVANCEMENT && (
              <EditableAdvancementForm item={advancement} form={form} />
            )}
            {type === historyItemsTypes.ACTIVITY && (
              <ActivityValuesForm item={advancement} form={form} />
            )}
          </Card>
          <Vr className={styles.vr} />
          <PersonsList
            className={styles.personsList}
            persons={[person]}
            type="editAdvancement"
            allowRemoveYouth={false}
            allowRemoveAdults={false}
            allowEligibleYouth
            allowEligibleAdults={type === historyItemsTypes.ACTIVITY}
            stats={
              <div className={styles.editingText}>
                <FormattedMessage id="editAdvancement.EditAdvancementForm.editing" />
              </div>
            }
          />
        </div>
        <div className={styles.buttonContainer}>
          {/* logic to show save/unapprove for advancements and edit/remove for activities */}
          {type === historyItemsTypes.ADVANCEMENT && (
            <Button
              id="qa_saveButton"
              type="primary"
              shadow
              className={styles.button}
              color="success"
              size="large"
              disabled={loading || !canEdit || isOffline}
              loading={loading}
              onClick={handleUpdateAdvancement}
            >
              <FormattedMessage id="editAdvancement.EditAdvancementForm.save" />
            </Button>
          )}
          {canUnapproveAdvancement &&
            type === historyItemsTypes.ADVANCEMENT && (
              <Button
                id="qa_unapproveBtn"
                color="scouting-red"
                type="primary"
                size="large"
                shadow
                onClick={handleOpenUnapproveModal}
              >
                <FormattedMessage id="editAdvancement.EditAdvancementForm.unapproveAdvancement" />
              </Button>
            )}
          {type === historyItemsTypes.ACTIVITY && (
            <EditButton
              disabled={isOffline}
              activityId={activityId}
              loading={loadingActivity}
              afterLoadCallBack={handleCloseEditModal}
              onClick={() => setLoadingActivity(true)}
              type="primary"
              size="large"
              shadow
            >
              <FormattedMessage id="shared.editActivity" />
            </EditButton>
          )}
          {type === historyItemsTypes.ACTIVITY && (
            <Button
              color="scouting-red"
              type="primary"
              size="large"
              shadow
              onClick={handleOpenRemovalModal}
            >
              <FormattedMessage id="editAdvancement.EditAdvancementForm.removeFromActivity" />
            </Button>
          )}
        </div>
      </Form>
    </React.Fragment>
  );
};

EditAdvancementForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default Form.create()(EditAdvancementForm);
