import { createSelector } from 'reselect';

import { SBL_4724_PENDING_REQUIREMENTS } from '@config';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import {
  Program,
  advancementHistoryLoadingSel,
  approvedAdvancementsSel,
  pendingApprovableAdvancementsSel,
  programForUnitTypeId,
  unitTypeIdSel,
} from '@shared';
import { applySort, makeSearch } from '@utils';

import { historyItemsTypes, pendingItemsModuleSel } from '../../common';
import { PendingItemsTabs } from '../constants';
import pendingItemsSorters from './../pendingItemsSorters';

const moduleSel = state => pendingItemsModuleSel(state);

export const loadingSel = state => advancementHistoryLoadingSel(state);
export const itemsSel = state => pendingApprovableAdvancementsSel(state);
export const approvedItemsSel = state => approvedAdvancementsSel(state);

export const searchFilterSel = state => moduleSel(state).searchFilter;
export const sorterSel = state => moduleSel(state).sorter;
export const advancementTypesFilterSel = state =>
  moduleSel(state).advancementTypesFilter;
export const activityTypesFilterSel = state =>
  moduleSel(state).activityTypesFilter;
export const pendingItemsActiveTabSel = state =>
  moduleSel(state).pendingItemsActiveTab;

const getSelectedFilters = filterObj =>
  Object.keys(filterObj).filter(key => filterObj[key]);

const makeItemTypeFilter =
  (advancementTypesFilter, activityTypesFilter) =>
  (item = {}) => {
    switch (item.type) {
      case historyItemsTypes.ADVANCEMENT: {
        return advancementTypesFilter.includes(item.advancementType);
      }
      case historyItemsTypes.ACTIVITY: {
        return activityTypesFilter.includes(item.activityType);
      }
      default: {
        return false;
      }
    }
  };

export const filteredItemsSel = createSelector(
  itemsSel,
  searchFilterSel,
  advancementTypesFilterSel,
  activityTypesFilterSel,
  sorterSel,
  (items, query, advancementTypesFilter, activityTypesFilter, sorter) => {
    const selectedAdvancementTypes = getSelectedFilters(advancementTypesFilter);
    const selectedActivityTypes = getSelectedFilters(activityTypesFilter);

    const searchIncludes = makeSearch(query);
    const matchesItemType = makeItemTypeFilter(
      selectedAdvancementTypes,
      selectedActivityTypes,
    );
    const filteredItems = items
      .map(item => ({
        ...item,
        filterableName: item.activityName || item.advancementName,
      }))
      .filter(
        ({ name, memberId, description, ...item }) =>
          matchesItemType(item) &&
          (searchIncludes(name) ||
            searchIncludes(String(memberId) || searchIncludes(description))),
      );

    return applySort({
      items: filteredItems,
      sorter,
      sorters: pendingItemsSorters,
    });
  },
);

export const getPendingItemsTabsSel = createSelector(
  unitTypeIdSel,
  unitType => {
    const SBL_4848_PENDING_LEADERSHIP_POSITIONS = featureFlags.getFlag(
      'SBL_4848_PENDING_LEADERSHIP_POSITIONS',
    );
    const program = programForUnitTypeId[unitType];
    const getTabs = () => {
      if (program === Program.BOY_SCOUT) {
        return [
          PendingItemsTabs.RANKS,
          PendingItemsTabs.MERIT_BADGES,
          PendingItemsTabs.AWARDS,
          PendingItemsTabs.ACTIVITIES,
          SBL_4848_PENDING_LEADERSHIP_POSITIONS &&
            PendingItemsTabs.LEADERSHIP_POSITIONS,
          SBL_4724_PENDING_REQUIREMENTS && PendingItemsTabs.REQUIREMENTS,
        ];
      } else if (program === Program.CUB_SCOUT) {
        return [
          PendingItemsTabs.RANKS,
          PendingItemsTabs.ADVENTURES,
          PendingItemsTabs.AWARDS,
          PendingItemsTabs.ACTIVITIES,
          SBL_4848_PENDING_LEADERSHIP_POSITIONS &&
            PendingItemsTabs.LEADERSHIP_POSITIONS,
          SBL_4724_PENDING_REQUIREMENTS && PendingItemsTabs.REQUIREMENTS,
        ];
      } else if (program === Program.VENTURING) {
        return [
          PendingItemsTabs.RANKS,
          PendingItemsTabs.MERIT_BADGES,
          PendingItemsTabs.AWARDS,
          PendingItemsTabs.ACTIVITIES,
          SBL_4848_PENDING_LEADERSHIP_POSITIONS &&
            PendingItemsTabs.LEADERSHIP_POSITIONS,
          SBL_4724_PENDING_REQUIREMENTS && PendingItemsTabs.REQUIREMENTS,
        ];
      } else if (program === Program.SEA_SCOUT) {
        return [
          PendingItemsTabs.RANKS,
          PendingItemsTabs.MERIT_BADGES,
          PendingItemsTabs.AWARDS,
          PendingItemsTabs.ACTIVITIES,
          PendingItemsTabs.SS_ELECTIVES,
          SBL_4848_PENDING_LEADERSHIP_POSITIONS &&
            PendingItemsTabs.LEADERSHIP_POSITIONS,
          SBL_4724_PENDING_REQUIREMENTS && PendingItemsTabs.REQUIREMENTS,
        ];
      } else {
        // Show all
        return Object.values(PendingItemsTabs);
      }
    };

    const finalTabs = getTabs().filter(Boolean);

    return finalTabs;
  },
);

export const selectedRowKeysSel = state => moduleSel(state).selectedRowKeys;

export const filteredApprovedItemsSel = createSelector(
  approvedItemsSel,
  searchFilterSel,
  advancementTypesFilterSel,
  activityTypesFilterSel,
  sorterSel,
  (items, query, advancementTypesFilter, activityTypesFilter, sorter) => {
    const selectedAdvancementTypes = getSelectedFilters(advancementTypesFilter);
    const selectedActivityTypes = getSelectedFilters(activityTypesFilter);

    const searchIncludes = makeSearch(query);
    const matchesItemType = makeItemTypeFilter(
      selectedAdvancementTypes,
      selectedActivityTypes,
    );
    const filteredItems = items
      .map(item => ({
        ...item,
        filterableName: item.activityName || item.advancementName,
      }))
      .filter(
        ({ name, memberId, description, ...item }) =>
          matchesItemType(item) &&
          (searchIncludes(name) ||
            searchIncludes(String(memberId) || searchIncludes(description))),
      );

    return applySort({
      items: filteredItems,
      sorter,
      sorters: pendingItemsSorters,
    });
  },
);

export const withoutPOApprovedItemsSel = createSelector(
  filteredApprovedItemsSel,
  items => items.filter(({ poid }) => !poid),
);
