import React from 'react';

import { Col, Row, Tooltip } from 'bsa-ui';
import InfoIcon from 'material-ui-icons/Info';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { dobDateFormatLong } from '@shared/constants';

import { intl } from '../../localization';
import Card from '../Card/Card';
import Form from '../Form';
import Input from '../Input';
import S from '../S';
import Select from '../Select';
import T from '../T';
import styles from './ProfilePersonalInfoCard.less';

const { Section: CardSection } = Card;
const { Item: FormItem } = Form;
const namePattern =
  /^[a-zA-Z\\sàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð ,.'-]*$/;

const ProfilePersonalInfoCard = ({
  form,
  profile = {},
  isLoading = false,
  disabled = true,
  disabledMiddleName = true,
  prefixes,
  suffixes,
  isUnregisteredUser = false,
  isReadOnly,
}) => {
  const { getFieldDecorator } = form;
  const {
    firstName,
    lastName,
    middleName,
    nickName,
    dateOfBirth,
    gender,
    title,
    nameSuffix,
  } = profile;

  return (
    <Card margin noPadding className={styles.card} loading={isLoading}>
      <CardSection
        title={
          <T size="5" colored>
            <FormattedMessage id="shared.personalInfo.title" />
          </T>
        }
      >
        <Row type="flex" justify="space-between">
          <Col xs={24} lg={11} className={styles.col}>
            <div className={styles.selectInputContainer}>
              <div className={styles.prefixContainer}>
                <FormItem>
                  {getFieldDecorator('prefix', {
                    initialValue: title || undefined,
                  })(
                    <Select
                      disabled={isUnregisteredUser || isReadOnly}
                      allowClear
                      rounded
                      size="large"
                      floatingLabel
                      placeholder={intl.formatMessage({
                        id: 'shared.personalInfo.prefix',
                      })}
                      optionFilterProp="text"
                      showSearch
                    >
                      <Select.OptGroup>
                        {prefixes.map(({ name }) => (
                          <Select.Option key={name} value={name} text={name}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>,
                  )}
                </FormItem>
              </div>
              <div className={styles.nameInputContainer}>
                <FormItem>
                  {getFieldDecorator('firstName', {
                    initialValue: firstName,
                    rules: [
                      {
                        max: 255,
                        message: intl.formatMessage(
                          {
                            id: 'shared.maxCharacters',
                          },
                          { max: 255 },
                        ),
                      },
                      {
                        pattern: namePattern,
                        message: intl.formatMessage({
                          id: 'shared.invalidCharacters',
                        }),
                      },
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'shared.form.error.isRequired',
                        }),
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.personalInfo.firstName',
                      })}
                      rounded
                      floatingLabel
                      disabled={disabled}
                    />,
                  )}
                </FormItem>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={13} className={styles.col}>
            <div className={styles.selectInputContainer}>
              <div className={styles.lastNameInputContainer}>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    initialValue: lastName,
                    rules: [
                      {
                        max: 255,
                        message: intl.formatMessage(
                          {
                            id: 'shared.maxCharacters',
                          },
                          { max: 255 },
                        ),
                      },
                      {
                        pattern: namePattern,
                        message: intl.formatMessage({
                          id: 'shared.invalidCharacters',
                        }),
                      },
                      {
                        required: true,
                        message: intl.formatMessage({
                          id: 'shared.form.error.isRequired',
                        }),
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      placeholder={intl.formatMessage({
                        id: 'shared.personalInfo.lastName',
                      })}
                      rounded
                      floatingLabel
                      disabled={disabled}
                    />,
                  )}
                </FormItem>
              </div>
              <div className={styles.suffixContainer}>
                <FormItem>
                  {getFieldDecorator('suffix', {
                    initialValue: nameSuffix || undefined,
                  })(
                    <Select
                      allowClear
                      rounded
                      size="large"
                      floatingLabel
                      placeholder={intl.formatMessage({
                        id: 'shared.personalInfo.suffix',
                      })}
                      optionFilterProp="text"
                      showSearch
                      disabled={isReadOnly}
                    >
                      <Select.OptGroup>
                        {suffixes.map(({ name }) => (
                          <Select.Option key={name} value={name} text={name}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>,
                  )}
                </FormItem>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={11} className={styles.col}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('nickName', {
                initialValue: nickName,
                rules: [
                  {
                    max: 255,
                    message: intl.formatMessage(
                      {
                        id: 'shared.maxCharacters',
                      },
                      { max: 255 },
                    ),
                  },
                  {
                    pattern: namePattern,
                    message: intl.formatMessage({
                      id: 'shared.invalidCharacters',
                    }),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'shared.personalInfo.preferredName',
                  })}
                  rounded
                  floatingLabel
                  disabled={isReadOnly}
                  suffix={
                    <Tooltip
                      placement="bottom"
                      overlayStyle={{ maxWidth: 'unset' }}
                      title={
                        <FormattedHTMLMessage id="shared.personalInfo.preferredNameHint" />
                      }
                    >
                      <InfoIcon className={styles.infoIconTooltip} />
                    </Tooltip>
                  }
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={13} className={styles.col}>
            <FormItem className={styles.formItemNormalWidth}>
              {getFieldDecorator('middleName', {
                initialValue: middleName,
                rules: [
                  {
                    max: 255,
                    message: intl.formatMessage(
                      {
                        id: 'shared.maxCharacters',
                      },
                      { max: 255 },
                    ),
                  },
                  {
                    pattern: namePattern,
                    message: intl.formatMessage({
                      id: 'shared.invalidCharacters',
                    }),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder={intl.formatMessage({
                    id: 'shared.personalInfo.middleName',
                  })}
                  rounded
                  floatingLabel
                  disabled={disabledMiddleName}
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} lg={11} className={styles.col}>
            <S size="2">
              <FormattedHTMLMessage
                id="shared.personalInfo.dateOfBirth"
                values={{
                  dateOfBirth: moment(dateOfBirth).format(dobDateFormatLong),
                }}
              />
            </S>
          </Col>
          <Col xs={24} lg={13} className={styles.col}>
            <S size="2">
              <FormattedHTMLMessage
                id="shared.personalInfo.gender"
                values={{ gender }}
              />
            </S>
          </Col>
          <Col xs={24}>
            <div className={styles.noticeContainer}>
              <InfoIcon className={styles.infoIcon} />
              <S size="4">
                <FormattedMessage id="shared.personalInfo.infoWarning" />
              </S>
            </div>
          </Col>
        </Row>
      </CardSection>
    </Card>
  );
};

ProfilePersonalInfoCard.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledMiddleName: PropTypes.bool,
  form: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  prefixes: PropTypes.array.isRequired,
  suffixes: PropTypes.array.isRequired,
  isUnregisteredUser: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default ProfilePersonalInfoCard;
