import { clone } from 'lodash';
import { createSelector } from 'reselect';

import {
  awardsSel,
  dictionarySel,
  emptyArr,
  venturingCoreAwardsIds,
} from '@shared';

import { dictionaryIds, moduleName } from '../constants';

export {
  allAwardsSel,
  ranksSel,
  ssElectivesSel,
  adventuresSel,
  meritBadgesSel,
} from '@shared';
export { awardsSel, dictionarySel };

const moduleSel = state => state[moduleName];
const dataSel = state => moduleSel(state).data;
const loadingSel = state => moduleSel(state).loading;

export const dictionaryLoadedSel = (state, id) => Boolean(dataSel(state)[id]);
export const dictionaryLoadingSel = (state, id) =>
  Boolean(loadingSel(state)[id]);
export const dictionariesLoadedSel = (state, ids) =>
  ids.reduce((loaded, id) => loaded || dictionaryLoadedSel(state, id), false);
export const dictionariesLoadingSel = (state, ids) =>
  ids.reduce(
    (loading, id) => loading || dictionaryLoadingSel(state, id),
    false,
  );

export const adventuresLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.ADVENTURES);
export const adventuresLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.ADVENTURES);

export const coreAwardsSel = createSelector(
  awardsSel,
  awards =>
    awards
      .filter(({ id }) => venturingCoreAwardsIds.includes(id))
      .map(coreAward => ({ level: coreAward.id, ...coreAward })), //small hack, the id can be used as the level
);
export const awardsLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.AWARDS);
export const awardsLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.AWARDS);

export const ranksLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.RANKS);
export const ranksLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.RANKS);

export const ssElectivesLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.SSELECTIVES);

export const ssElectivesLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.SSELECTIVES);

export const meritBadgesLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.MERIT_BADGES);
export const meritBadgesLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.MERIT_BADGES);

export const statesSel = state =>
  dictionarySel(state, dictionaryIds.STATES) || emptyArr;
export const statesLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.STATES);
export const statesLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.STATES);

export const countriesSel = state =>
  dictionarySel(state, dictionaryIds.COUNTRIES) || emptyArr;
export const countriesLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.COUNTRIES);
export const countriesLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.COUNTRIES);

export const phoneCountriesSel = state =>
  dictionarySel(state, dictionaryIds.PHONE_COUNTRIES) || emptyArr;
export const phoneCountriesLoadedSel = state =>
  dictionaryLoadedSel(state, dictionaryIds.PHONE_COUNTRIES);
export const phoneCountriesLoadingSel = state =>
  dictionaryLoadingSel(state, dictionaryIds.PHONE_COUNTRIES);

export const comunicationTypesSel = state =>
  dictionarySel(state, dictionaryIds.COMUNICATION_TYPES) || emptyArr;

export const phoneCarriersSel = state =>
  dictionarySel(state, dictionaryIds.PHONE_CARRIERS) || emptyArr;

export const prefixesSel = state =>
  dictionarySel(state, dictionaryIds.PREFIXES) || emptyArr;

export const suffixesSel = state =>
  dictionarySel(state, dictionaryIds.SUFFIXES) || emptyArr;

export const gradesSel = state =>
  dictionarySel(state, dictionaryIds.GRADES) || emptyArr;

export const schoolsSel = state => {
  const list = dictionarySel(state, dictionaryIds.SCHOOLS) || emptyArr;

  if (list && list.length > 24) {
    const resultList = clone(list);
    resultList.length = 24;
    return resultList;
  }

  return list || emptyArr;
};

export const swimmingClassificationsSel = state =>
  dictionarySel(state, dictionaryIds.SWIMMIN_CLASSIFICATION) || emptyArr;

export const advancementSel = ({ state, advancementType, advancementId }) =>
  dictionarySel(state, advancementType).find(({ id }) => id == advancementId) ||
  {};
