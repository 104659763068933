import { Observable } from 'rxjs';

import {
  MessageRequest,
  MessageResponse,
  Recipients,
} from '@modules/messaging/types';

import { default as esbApiService } from '../../utils/http/esbApiService';

/**
 * @esbEndpoint GET `/organizations/v2/:organizationGuid/recipients`
 */

export const getRecipients$ = (
  organizationGuid: string,
): Observable<Recipients> =>
  esbApiService.get$(`/organizations/v2/${organizationGuid}/recipients`, {
    gtm: {
      label: '/organizations/v2/{organizationGuid}/recipients',
    },
  });

/**
 * @esbEndpoint POST `/advancements/v2/:organizationGuid/email`
 */

export const sendMessage$ = (
  payload: MessageRequest,
  organizationGuid: string,
): Observable<MessageResponse> =>
  esbApiService.post$(`/advancements/v2/${organizationGuid}/email`, payload, {
    gtm: {
      label: '/advancements/v2/{organizationGuid}/email',
    },
  });
