import { gtm } from '@shared';

import { youthProfileModuleName } from '../../../shared/constants';
import {
  loadAndOpenActivityAction,
  eagelProjectModuleNamespace as namespace,
} from '../../common';

export const OPEN_EDIT_EAGLE_PROJECT = `${namespace}/OPEN_EDIT_EAGLE_PROJECT`;
export const openEditEagleProject = (allowEditOrAddPersonsPersons = false) => ({
  type: OPEN_EDIT_EAGLE_PROJECT,
  payload: {
    allowEditOrAddPersonsPersons,
  },
  gtm: {
    category: gtm.categories.EAGLE_PROJECT,
  },
});

export const CLOSE_EAGLE_PROJECT_EDIT_MODAL = `${namespace}/CLOSE_EAGLE_PROJECT_EDIT_MODAL`;
export const closeEagleProjectEditModal = () => ({
  type: CLOSE_EAGLE_PROJECT_EDIT_MODAL,
  gtm: {
    category: gtm.categories.EAGLE_PROJECT,
  },
});

export const SET_DETAIL_MODE = `${namespace}/SET_DETAIL_MODE`;
export const setDetailMode = mode => ({ type: SET_DETAIL_MODE, payload: mode });

export const LOAD_AND_OPEN_EAGLE_PROJECT_MODAL = `${namespace}/LOAD_AND_OPEN_EAGLE_PROJECT_MODAL`;
export const loadAndOpenEagleProjectModal = loadAndOpenActivityAction(
  LOAD_AND_OPEN_EAGLE_PROJECT_MODAL,
);

export const LOAD_AND_OPEN_EAGLE_PROJECT_MODAL_ERROR = `${namespace}/LOAD_AND_OPEN_EAGLE_PROJECT_MODAL_ERROR`;
export const loadAndOpenEagleProjectModalError = err => ({
  type: LOAD_AND_OPEN_EAGLE_PROJECT_MODAL_ERROR,
  payload: err,
});

export const UPDATE_EAGLE_PROJECT_REQUEST = `${youthProfileModuleName}/UPDATE_EAGLE_PROJECT_REQUEST`;
export const updateEagleProjectRequest = (id, data) => ({
  type: UPDATE_EAGLE_PROJECT_REQUEST,
  payload: { id, data },
});

export const SUBMIT_EAGLE_PROJECT_RESPONSE = `${namespace}/SUBMIT_EAGLE_PROJECT_RESPONSE`;
export const submitEagleProjectResponse = () => ({
  type: SUBMIT_EAGLE_PROJECT_RESPONSE,
});

export const SUBMIT_EAGLE_PROJECT_ERROR = `${namespace}/SUBMIT_EAGLE_PROJECT_ERROR`;
export const submitEagleProjectError = error => ({
  type: SUBMIT_EAGLE_PROJECT_ERROR,
  payload: error,
});

export const ADVANCEMENT_HISTORY_REQUEST = `${namespace}/ADVANCEMENT_HISTORY_REQUEST`;
export const advancementHistoryRequest = options => ({
  type: ADVANCEMENT_HISTORY_REQUEST,
  payload: options,
});
