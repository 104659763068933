import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { S, Radio } from '@shared';
import { updatedDateFilters } from '../../../../constants';

const RadioGroup = Radio.Group;

class UpdatedDateFilterSection extends React.PureComponent {
  handleChange = e => {
    const { value } = e.target;
    this.props.onChange(value);
  };

  render() {
    const { value } = this.props;

    return (
      <RadioGroup value={value} onChange={this.handleChange}>
        <Radio value={updatedDateFilters.TODAY} id="qa_today">
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.filter.option.today" />
          </S>
        </Radio>
        <Radio value={updatedDateFilters.LAST_30_DAYS} id="qa_30days">
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.filter.option.last30Days" />
          </S>
        </Radio>
        <Radio value={updatedDateFilters.LAST_365_DAYS} id="qa_365days">
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.filter.option.last365Days" />
          </S>
        </Radio>
        <Radio value={updatedDateFilters.ALL} id="qa_all">
          <S size="4" colored inline>
            <FormattedMessage id="advancement.AdvancementHistory.filter.option.all" />
          </S>
        </Radio>
      </RadioGroup>
    );
  }
}

UpdatedDateFilterSection.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UpdatedDateFilterSection;
