import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';

import {
  moduleName as activitiesModuleName,
  reducers as activitiesReducers,
} from '../activities';
import {
  moduleName as advancementModuleName,
  reducers as advancementReducers,
} from '../advancement';
import {
  moduleName as approveAdvancementsModuleName,
  reducers as approveAdvancementsReducers,
} from '../approveAdvancements';
import { moduleName as campoutsModuleName } from '../campouts/constants';
import campoutsReducers from '../campouts/duck/reducers';
import {
  moduleName as commonModuleName,
  reducers as commonReducers,
} from '../common';
import {
  moduleName as declineAdvancementModuleName,
  reducers as declineAdvancementReducers,
} from '../declineAdvancements';
import {
  moduleName as eagleProjectModuleName,
  reducers as eagleProjectReducers,
} from '../eagleProject';
import {
  moduleName as eagleScoutApplicationModuleName,
  reducers as eagleScoutApplicationReducers,
} from '../eagleScoutApplication';
import {
  moduleName as editAdvancementModuleName,
  reducers as editAdvancementReducers,
} from '../editAdvancement';
import {
  moduleName as hikesModuleName,
  reducers as hikesReducers,
} from '../hikes';
import {
  moduleName as longCruiseModuleName,
  reducers as longCruiseReducers,
} from '../longCruise';
import {
  moduleName as serviceModuleName,
  reducers as serviceReducers,
} from '../service';
import { moduleName as startAdvancementsModuleName } from '../startAdvancement/constants';
import startAdvancementsReducers from '../startAdvancement/duck/reducers';
import {
  moduleName as unapproveAdvancementsModuleName,
  reducers as unapproveAdvancementsReducers,
} from '../unapproveAdvancement';

const reducer = combineReducers({
  [commonModuleName]: commonReducers,
  [activitiesModuleName]: activitiesReducers,
  [advancementModuleName]: advancementReducers,
  [serviceModuleName]: serviceReducers,
  [campoutsModuleName]: campoutsReducers,
  [eagleProjectModuleName]: eagleProjectReducers,
  [hikesModuleName]: hikesReducers,
  [eagleScoutApplicationModuleName]: eagleScoutApplicationReducers,
  [editAdvancementModuleName]: editAdvancementReducers,
  [approveAdvancementsModuleName]: approveAdvancementsReducers,
  [declineAdvancementModuleName]: declineAdvancementReducers,
  [unapproveAdvancementsModuleName]: unapproveAdvancementsReducers,
  [startAdvancementsModuleName]: resetOnLogoutReducer(
    startAdvancementsReducers,
  ),
  [longCruiseModuleName]: longCruiseReducers,
});

export default resetOnLogoutReducer(reducer);
