import React from 'react';

import WifiOffIcon from '@material-ui/icons/WifiOff';
import { TooltipProps } from 'antd/lib/tooltip';
import { Tooltip } from 'bsa-ui';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { offlineSel } from '../../duck';

type Props = {
  className?: string;
  tooltipPlacement?: TooltipProps['placement'];
};

const OfflineIcon: React.FC<Props> = ({
  className,
  tooltipPlacement = 'top',
}) => {
  const isOffline = useSelector(offlineSel);

  if (!isOffline) return null;

  return (
    <Tooltip
      title={<FormattedMessage id="offline.OfflineIcon.message" />}
      placement={tooltipPlacement}
      {...(isOffline ? { trigger: 'click' } : {})}
    >
      <WifiOffIcon className={className} />
    </Tooltip>
  );
};

export default OfflineIcon;
