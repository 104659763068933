import merge from 'lodash/merge';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mapTo';

import { API_ENV, ENV, PAYMENTS_API_URLS } from '@config';
import { userServices } from '@shared/duck';

import errorHandler from './esbErrorHandler';
import createHttp from './httpFactory';
import jsonBodyTransformer from './jsonBodyTransformer';
import unwrapResponse from './unwrapResponse';

const externalApiPrefix = '/api';
const selfApiPrefix = '/apis' + externalApiPrefix;

const getLoginData = () => userServices.retrieveLoginData() || {};

const getTargetUrlHeader = url =>
  ENV === 'local'
    ? {
        'x-target-url': url,
      }
    : {};

const urlTransformer = endpoint => {
  const externalUrl = PAYMENTS_API_URLS[API_ENV] + externalApiPrefix + endpoint;
  const selfUrl = selfApiPrefix + endpoint;
  return ENV === 'local' ? selfUrl : externalUrl;
};

const bodyTransformer = body =>
  body instanceof FormData ? body : jsonBodyTransformer(body);

const optionsTransformer = ({ options, url: endpoint }) => {
  const { token } = getLoginData();
  const headers = merge(
    {
      Authorization: token ? `bearer ${token}` : '',
      Accept: options.v2 ? 'application/json; version = 2' : 'application/json',
      'Content-Type': 'application/json',
      ...getTargetUrlHeader(
        PAYMENTS_API_URLS[API_ENV] + externalApiPrefix + endpoint,
      ),
    },
    options.headers ? options.headers : {},
  );
  return merge({}, options, { headers, withCredentials: true });
};

const http = createHttp({
  urlTransformer,
  bodyTransformer,
  optionsTransformer,
  responseHandler: unwrapResponse,
  errorHandler,
});

const apiCall$ = (method, endpoint, body, options) =>
  method === http.get$
    ? method(endpoint, options)
    : method(endpoint, body, options);

const apiCalls = Object.freeze({
  get$: (endpoint, options) =>
    apiCall$(http.get$, endpoint, undefined, options),
  post$: (endpoint, body, options) =>
    apiCall$(http.post$, endpoint, body, options),
  put$: (endpoint, body, options) =>
    apiCall$(http.put$, endpoint, body, options),
  delete$: (endpoint, body, options) =>
    apiCall$(http.delete$, endpoint, body, options),
});

export default apiCalls;
