import React from 'react';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import cn from 'classnames';

import { Tooltip, intl } from '@shared';
import styles from './PersonWarning.less';

const PersonWarning = ({ person, className }) => {
  const { userId, personShortFullName: personName } = person;
  const shouldShow = !userId;

  return (
    shouldShow && (
      <Tooltip
        title={intl.formatMessage(
          {
            id: 'advancement.PackRoster.noUserIdWarning',
          },
          { personName },
        )}
      >
        <WarningIcon className={cn(styles.warningIcon, className)} />
      </Tooltip>
    )
  );
};

PersonWarning.propTypes = {
  person: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default PersonWarning;
