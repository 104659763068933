import {
  Program,
  advancementHistoryModuleName,
  advancementHistoryModuleNamespace,
  advancementModuleName,
  advancementStatuses,
  advancementSubTabNames,
  advancementTabNames,
  advancementTypes,
  historyItemsTypes,
  packRosterModuleName,
  packRosterModuleNamespace,
} from '@shared';

export {
  advancementModuleName,
  packRosterModuleName,
  packRosterModuleNamespace,
  advancementHistoryModuleName,
  advancementHistoryModuleNamespace,
  historyItemsTypes,
};

export const pendingItemsModuleName = 'pendingItems';
/** @type {"advancement/pendingItems"} */
export const pendingItemsModuleNamespace = `${advancementModuleName}/${pendingItemsModuleName}`;

export const purchaseOrdersModuleName = 'purchaseOrders';
export const purchaseOrdersModuleNamespace = `${advancementModuleName}/${purchaseOrdersModuleName}`;

export const advancementReportModuleName = 'advancementReport';
export const advancementReportModuleNamespace = `${advancementModuleName}/${advancementReportModuleName}`;

export const advancementUploadModuleName = 'advancementUpload';
export const advancementUploadModuleNamespace = `${advancementModuleName}/${advancementUploadModuleName}`;

export const recordAdvancementModuleName = 'recordAdvancement';
export const recordAdvancementModuleNamespace = `${advancementModuleName}/${recordAdvancementModuleName}`;

export const serviceHoursModuleName = 'serviceHours';
export const serviceHoursModuleNamespace = `${advancementModuleName}/${serviceHoursModuleName}`;

export const campingNightsModuleName = 'campingNights';
export const campingNightsModuleNamespace = `${advancementModuleName}/${campingNightsModuleName}`;

export const editAdvancementModes = Object.freeze({
  NEW: 'new',
  EDIT: 'edit',
});

export const activityItemsTypes = Object.freeze({
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  HISTORY: 'HISTORY',
});

export const tabNames = advancementTabNames;
export const subTabNames = advancementSubTabNames;

const commonAdvancementTypes = [
  advancementTypes.AWARDS,
  advancementTypes.RANKS,
  advancementTypes.MERIT_BADGES,
];
export const advancementTypeFilters = Object.freeze({
  [Program.CUB_SCOUT]: [
    advancementTypes.AWARDS,
    advancementTypes.ADVENTURES,
    advancementTypes.RANKS,
  ],
  [Program.BOY_SCOUT]: commonAdvancementTypes,
  [Program.VENTURING]: commonAdvancementTypes,
  [Program.SEA_SCOUT]: commonAdvancementTypes,
  [Program.EXPLORING]: [advancementTypes.AWARDS],
  [Program.CLUB]: [advancementTypes.AWARDS],
  [Program.VARSITY]: [], // discontinued, fallback value just in case
});

export const advancementStatusFilters = Object.freeze({
  STARTED: advancementStatuses.STARTED,
  COMPLETED: advancementStatuses.COMPLETED,
  COUNSELOR_APPROVED: advancementStatuses.COUNSELOR_APPROVED,
  APPROVED: advancementStatuses.APPROVED,
  AWARDED: advancementStatuses.AWARDED,
});
