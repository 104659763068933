import isEmpty from 'lodash/isEmpty';
import { redirect, replace } from 'redux-first-router';

import { ENABLE_EAGLE_PROJECT } from '@config';
import { organizationPositionsSel, setOrganization } from '@context';
import { payloadSel, querySel } from '@location';
import { featureFlags } from '@modules/featureFlags/utils/featureFlags';
import { permissions } from '@modules/shared';
import { ROUTE_YOUTH_ADVANCEMENTS_LIST } from '@modules/shared/duck/actionsTyped';
import { emptyObj } from '@shared/constants';
import {
  ROUTE_ADVANCEMENT,
  ROUTE_PROFILE,
  ROUTE_READ_ONLY_YOUTH_ACTIVITY_LOGS,
  ROUTE_READ_ONLY_YOUTH_PROFILE,
  isMeritBadgeCounselorSel,
} from '@shared/duck';

import { ProfilePage } from '../profile/components';
import {
  AdvRequirementsPage,
  AdvancementsListPage,
  EagleProjectPage,
  YouthActivityLogsPage,
  YouthProfilePage,
} from './components';
import {
  ROUTE_EAGLE_PROJECT,
  ROUTE_UNREGISTERED_YOUTH_PROFILE,
  ROUTE_YOUTH_ACTIVITY_LOGS,
  ROUTE_YOUTH_ADV_REQUIREMENTS,
  ROUTE_YOUTH_PROFILE,
} from './duck/actions';
import { youthProfileInfoSel } from './duck/selectors';

const conditionalRoutes = {};

const baseRouteActivityLogs = {
  Component: YouthActivityLogsPage,
  titleId: 'page.youthActivityLogs',
};

if (
  featureFlags.getFlag('SHOW_YOUTH_ADV_REQUIREMENTS') ||
  featureFlags.getFlag('SBL_5198_CUB_PROFILE_VIEWMORE')
) {
  conditionalRoutes[ROUTE_YOUTH_ADV_REQUIREMENTS] = {
    path: '/youthProfile/:userId/:advancementType/:advancementId/:userAwardId?',
    Component: AdvRequirementsPage,
    titleId: 'page.youthAdvRequirements',
  };
}

if (ENABLE_EAGLE_PROJECT) {
  conditionalRoutes[ROUTE_EAGLE_PROJECT] = {
    path: '/youthProfile/:userId/eagleProject',
    Component: EagleProjectPage,
    titleId: 'page.eagleProject',
    thunk: (dispatch, getState) => {
      const { currentHighestRankApproved } = youthProfileInfoSel(getState());

      if (
        !currentHighestRankApproved ||
        currentHighestRankApproved.rank !== 'Life Scout'
      ) {
        dispatch(redirect({ type: ROUTE_ADVANCEMENT }));
      }
    },
    permissions: permissions.SUBMIT_EAGLE_PROJECT,
  };
}

const routes = {
  [ROUTE_YOUTH_PROFILE]: {
    path: '/youthProfile/:userId',
    Component: YouthProfilePage,
    titleId: 'page.youthProfile',
    thunk: (dispatch, getState) => {
      const state = getState();
      const positions = organizationPositionsSel(state);
      const {
        organizationGuid = '',
        adultUserId = '',
        section = '',
      } = querySel(state) || emptyObj;
      const payload = payloadSel(state);
      const connectionToYouthPosition = positions.find(
        position => +position.userId === +payload.userId,
      );
      const youthOrgGuid = organizationGuid;

      // See SBL-4252
      if (isMeritBadgeCounselorSel(state) && !youthOrgGuid) {
        dispatch(
          setOrganization({
            guid: connectionToYouthPosition.organizationGuid,
            programType: connectionToYouthPosition.programType,
            parentOrgGuid: connectionToYouthPosition.organizationGuid,
          }),
        );
        return;
      }

      if (!isEmpty(youthOrgGuid) && !isEmpty(positions) && !isEmpty(payload)) {
        const { userId } = payload;
        const foundedPosition = positions.find(
          p => p.organizationGuid === youthOrgGuid,
        );
        replace(
          `/youthProfile/${userId}?section=${section}${
            adultUserId ? `&adultUserId=${adultUserId}` : ''
          }`,
        );
        if (foundedPosition) {
          dispatch(
            setOrganization({
              guid: foundedPosition.organizationGuid,
              programType: foundedPosition.programType,
              parentOrgGuid: foundedPosition.organizationGuid,
            }),
          );
        }
      }

      if (isEmpty(organizationGuid) && adultUserId && !isEmpty(payload)) {
        const { userId } = payload;
        replace(
          `/youthProfile/${userId}?adultUserId=${adultUserId}${
            section ? `&section=${section}` : ''
          }`,
        );
      }
    },
    permission: permissions.VIEW_YOUTH_PROFILE,
  },
  [ROUTE_UNREGISTERED_YOUTH_PROFILE]: {
    path: '/youthProfile/:userId',
    Component: YouthProfilePage,
    titleId: 'page.youthProfile',
    thunk: (dispatch, getState) => {
      const state = getState();
      const payload = payloadSel(state);

      if (!isEmpty(payload)) {
        const { userId } = payload;
        replace(`/youthProfile/${userId}?adultUserId=424242`);
      }
    },
    permission: permissions.VIEW_YOUTH_PROFILE,
  },
  [ROUTE_YOUTH_ADVANCEMENTS_LIST]: {
    path: '/youthProfile/:userId/:advancementType',
    Component: AdvancementsListPage,
    getTitleId: ({ advancementType }) => `page.youthAdvList.${advancementType}`,
  },
  [ROUTE_YOUTH_ACTIVITY_LOGS]: {
    path: '/youthProfile/:userId/activityLogs/:activityType',
    ...baseRouteActivityLogs,
  },
  [ROUTE_READ_ONLY_YOUTH_ACTIVITY_LOGS]: {
    path: '/youthProfile/activityLogs/:activityType',
    permission: permissions.VIEW_YOUTH_PROFILE,
    ...baseRouteActivityLogs,
  },
  [ROUTE_READ_ONLY_YOUTH_PROFILE]: {
    path: '/youthProfile',
    Component: YouthProfilePage,
    titleId: 'page.youthProfile',
    permission: permissions.VIEW_SELF_YOUTH_PROFILE,
  },
  [ROUTE_PROFILE]: {
    path: '/profile',
    Component: ProfilePage,
    titleId: 'page.profile',
    permission: permissions.VIEW_SELF_PROFILE,
  },
  ...conditionalRoutes,
};

export default routes;
