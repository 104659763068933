import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import CheckedIcon from '../CheckedIcon';
import { validationTypes } from '../../../constants';
import styles from './SwitchIcon.less';

const SwitchIcon = props => {
  const { validation, validationType } = props;

  if (validation && validationType.toLowerCase() === validationTypes.WARNING) {
    return <InfoIcon className={styles.WarningIcon} />;
  }

  if (validation && validationType.toLowerCase() === validationTypes.ERROR) {
    return <CheckedIcon checked={!validation} />;
  }

  return <CheckedIcon checked={!validation} />;
};

SwitchIcon.propTypes = {
  validation: PropTypes.bool,
  validationId: PropTypes.number,
  validationType: PropTypes.string,
};

export default SwitchIcon;
