import BBCodeParser from 'bbcode-parser';
import BBTag from 'bbcode-parser/bbTag';
import DOMPurify from 'dompurify';
import { HTML2BBCode } from 'html2bbcode';
import { concat, each, first, get, last, map, reduce } from 'lodash';

const getTag = (tag, htmlTag) =>
  BBTag.createTag(
    tag,
    (_tagName, content) => `<${htmlTag}>${content}</${htmlTag}>`,
  );

const getURLTag = tag =>
  BBTag.createTag(tag, (_tagName, content, params) => {
    let label = content;
    const url = get(params, 'url', content);
    if (first(content) === `"` && last(content) === `"`) {
      label = label.slice(1).slice(0, -1);
    }
    return `<a href="${url}">${label}</a>`;
  });

const getImgTag = tag =>
  BBTag.createTag(tag, (_tagName, content) => `<p><img src="${content}"/></p>`);

const getTags = () => {
  const tags = BBCodeParser.defaultTags();
  tags['b'] = getTag('b', 'strong');
  tags['i'] = getTag('i', 'em');
  tags['u'] = getTag('u', 'ins');
  tags['s'] = getTag('s', 'del');
  tags['url'] = getURLTag();
  tags['img'] = getImgTag();
  return tags;
};

// This function converts BBC Code from database into HTML code to be displayed in the app and potentially edited
export const parseBBCode = (data = '') => {
  try {
    const parser = new BBCodeParser(getTags());
    const parsedData = reduce(
      data.match(/\[url=(.*?)\]/g),
      (memo, link) => {
        const url = link
          .replace(/\[url=/, '')
          .slice(0, -1)
          .replaceAll('"', '');
        memo = memo
          .replaceAll(url, `"${url}"`)
          .replaceAll(`""${url}""`, `"${url}"`);
        return memo;
      },
      data,
    );

    return DOMPurify.sanitize(parser.parseString(parsedData));
  } catch (e) {
    return '';
  }
};

// This function converts HTML code into BBC Code for saving into the database so Scoutbook will be able to display the event correctly
// It also ensures URLs are prepended with https:// or http://
export const parseHTMLCode = (data = '') => {
  let result = '';
  try {
    const converter = new HTML2BBCode();
    const links = concat(
      map(data.match(/href="(.*?)"/g), link =>
        link.replace(/href="/, '').slice(0, -1),
      ),
      map(data.match(/<a href="">(.*?)<\/a>/g), link =>
        link.replace(/<a href="">/, '').replace(/<\/a>/, ''),
      ),
    );
    const parsedData = reduce(
      links,
      (memo, link) => {
        memo = memo.replace(
          `<a href="">${link}</a>`,
          `<a href="${link}">${link}</a>`,
        );
        return memo;
      },
      data,
    );
    result = converter.feed(parsedData).toString();
    each(links, link => {
      let replacement = link;
      if (
        replacement.indexOf('http://') === -1 &&
        replacement.indexOf('https://') === -1
      ) {
        replacement = `https://${replacement}`;
      }
      result = result
        .replaceAll(`[url=${link}]`, `[url=${replacement}]`)
        .replace(/\n$/, '');
    });
    return result.replaceAll('\n', '\r\n');
  } catch (e) {
    return result;
  }
};

export const parseDescription = (data = '') =>
  data.replaceAll('<p></p>', '<br>');
