import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import {
  councilOrganizationTypeId,
  districtCouncilStructureTypeId,
  districtOrganizationTypeId,
} from '../../constants';
import { Badge } from '../bsaComponents';
import styles from './CouncilDistrictBadge.less';

const orgLabels = {
  COUNCIL: 'C',
  DISTRICT: 'D',
  SUBDISTRICT: 'SD',
};

const orgTitles = {
  [orgLabels.COUNCIL]: 'Council',
  [orgLabels.DISTRICT]: 'District',
  [orgLabels.SUBDISTRICT]: 'Sub District',
};

const getLabel = ({ organizationTypeId, councilStructureTypeId }) => {
  if (organizationTypeId == councilOrganizationTypeId) {
    return orgLabels.COUNCIL;
  }
  if (
    organizationTypeId == districtOrganizationTypeId &&
    councilStructureTypeId == districtCouncilStructureTypeId
  ) {
    return orgLabels.DISTRICT;
  }
  return orgLabels.SUBDISTRICT;
};

const CouncilDistrictBadge = ({
  className,
  organizationTypeId,
  councilStructureTypeId,
}) => {
  const label = getLabel({ organizationTypeId, councilStructureTypeId });

  return (
    <Badge
      className={cn(className, styles[label])}
      count={label}
      title={orgTitles[label]}
    />
  );
};

CouncilDistrictBadge.propTypes = {
  className: PropTypes.string,
  organizationTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  councilStructureTypeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default CouncilDistrictBadge;
