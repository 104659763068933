import React, { useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import type { RenewalRelationship } from '@appTypes/esb';
import { SBL_4798_MEMBERSHIP_EXP_MODALS, getMYSTUrl } from '@config';
import { useGetPersonRenewalRelationshipsQuery } from '@modules/rtk/esb-api';
import { Modal, S, T } from '@modules/shared/components';
import {
  loginDataInitializedSel,
  personGuidSel,
  userDataInitializedSel,
} from '@modules/user/duck';
import { ExpirationStatus } from '@modules/userNotifications/enums';
import {
  getExpirationStatus,
  getMembershipExpirationDismissFlag,
  isParentRelationship,
  storeMembershipExpirationDismissFlag,
} from '@modules/userNotifications/utils';

function getExpirationMessage(params: {
  isParent: boolean;
  expiryDate: Date | string;
  personName?: string;
}) {
  const status = getExpirationStatus(params.expiryDate);
  const messages = {
    [ExpirationStatus.ABOUT_TO_EXPIRE]: 'aboutToExpire',
    [ExpirationStatus.EXPIRED]: 'expired',
    [ExpirationStatus.LONG_EXPIRED]: 'longExpired',
    [ExpirationStatus.UNKNOWN]: null,
  };

  const messageTag = messages[status];
  const statusDesc = ExpirationStatus[status];

  const titleId = params.isParent
    ? 'userNotifications.modals.membershipExpiration.parentGuardian.title'
    : 'userNotifications.modals.membershipExpiration.leader.title';

  const messageId = `userNotifications.modals.membershipExpiration.${
    params.isParent ? 'parentGuardian' : 'leader'
  }.message.${messageTag}`;

  const title = <FormattedMessage id={titleId} />;
  const message = (
    <FormattedMessage
      id={messageId}
      values={{
        personName: params.personName,
        link: (
          <a href="#">
            <FormattedMessage id="userNotifications.modals.membershipExpiration.renew" />
          </a>
        ),
        br: <br />,
        date: (
          <strong>
            {moment(params.expiryDate).toDate().toLocaleDateString()}
          </strong>
        ),
      }}
    />
  );

  return { title, message, status, statusDesc, expiryDate: params.expiryDate };
}

function ExpirationModal(props: { renewal: RenewalRelationship }) {
  const { renewal } = props;
  const [isVisible, setIsVisible] = useState(true);
  const isParent = isParentRelationship(renewal);

  const message = getExpirationMessage({
    isParent,
    expiryDate: moment(renewal?.personExpiryDt).toDate(),
    personName: [renewal.firstName, renewal.middleName, renewal.lastName]
      .filter(Boolean)
      .join(' '),
  });
  const onRenew = () => {
    window?.open(`${getMYSTUrl()}/tools/my-applications`, '_blank');
    setIsVisible(false);
  };
  const hasSeenBefore = getMembershipExpirationDismissFlag({
    personGuid: renewal.personGuid,
    organizationGuid: renewal.organizationGuid,
    registrationId: renewal.registrationId,
  });

  if (hasSeenBefore || message.status === ExpirationStatus.UNKNOWN) return null;

  return (
    <Modal
      key={renewal.personId}
      fullscreenMobile={false}
      useNewStyle
      closable={false}
      destroyOnClose
      visible={isVisible}
      okText={
        <FormattedMessage id="userNotifications.modals.membershipExpiration.renew" />
      }
      onCancel={() => {
        setIsVisible(false);
        storeMembershipExpirationDismissFlag({
          personGuid: renewal.personGuid,
          organizationGuid: renewal.organizationGuid,
          registrationId: renewal.registrationId,
        });
      }}
      onOk={onRenew}
      useDefaultFooter
      title={
        <T colored size="3">
          {message.title}
        </T>
      }
    >
      <div>
        <S>{message.message}</S>
      </div>
    </Modal>
  );
}

export function MembershipExpirationModal(props: {
  renewals: RenewalRelationship[];
  currentUserPersonGuid: string;
}) {
  const { renewals } = props;
  const selfRenewal = _.chain(renewals)
    .filter(renewal => renewal.personGuid === props.currentUserPersonGuid)
    .sortBy('organizationExpiryDt')
    .first()
    .value();

  return (
    <ErrorBoundary FallbackComponent={() => <div />}>
      {/* Self membership */}
      {selfRenewal && <ExpirationModal renewal={selfRenewal} />}
      {/* Youth memberships */}
      {renewals
        ?.filter(isParentRelationship)
        .filter(Boolean)
        .map(renewal => (
          <ExpirationModal
            renewal={renewal}
            key={`${renewal.personGuid}-${renewal.positionId}-${renewal.registrationId}`}
          />
        ))}
    </ErrorBoundary>
  );
}

export function MembershipExpirationModalContainer() {
  const userDataInitialized = useSelector(userDataInitializedSel);
  const loginDataInitialized = useSelector(loginDataInitializedSel);
  const personGuid = useSelector(personGuidSel);

  const skip =
    !userDataInitialized ||
    !loginDataInitialized ||
    !personGuid ||
    !SBL_4798_MEMBERSHIP_EXP_MODALS;

  const { data: renewals, isUninitialized } =
    useGetPersonRenewalRelationshipsQuery(
      {
        personGuid: personGuid as string,
      },
      { skip },
    );

  const emptyRenewals = _.isEmpty(renewals);

  const shouldHide = skip || isUninitialized || emptyRenewals;

  if (shouldHide || !renewals) return null;

  return (
    <MembershipExpirationModal
      currentUserPersonGuid={personGuid}
      renewals={renewals}
    />
  );
}
