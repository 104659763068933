import { get } from 'lodash';
import { createSelector } from 'reselect';

import { denIdsSel, isDenOrAssistantSel, organizationGuidSel } from '@context';
import {
  isCubScoutsUnitSel,
  isScoutsBsaUnitSel,
} from '@context/duck/selectorsTyped';
import { getIsDenChief } from '@modules/advancement/packRoster/utilsTyped';
import { packRosterItemsSel, unitTypeIdSel } from '@modules/shared';

import { RootState } from '../../../../root/store';
import { RosterScout } from '../../duck/types';
import {
  modulePath,
  programTypeIdToSubUnitName,
  programTypeIdTosubUnitType,
  subUnitDen,
  subUnitPatrol,
  subUnitTypes,
} from '../constants';
import reducers from './reducers';

export const moduleSel = (state: RootState): ReturnType<typeof reducers> =>
  get(state, modulePath);

export const subUnitTypeSel = createSelector(
  unitTypeIdSel,
  unitTypeId => programTypeIdTosubUnitType[unitTypeId as 1 | 2],
);

export const isDenSel = createSelector(
  subUnitTypeSel,
  subUnitType => subUnitType === subUnitTypes.DEN,
);

export const selectedSubUnitIdSel = createSelector(
  moduleSel,
  ({ selectedSubUnitId }: { selectedSubUnitId: number | null }) =>
    selectedSubUnitId,
);

export const isAddSubUnitModalVisibleSel = createSelector(
  moduleSel,
  ({ isAddSubUnitModalVisible }) => isAddSubUnitModalVisible,
);

export const isAddToSubUnitModalVisibleSel = createSelector(
  moduleSel,
  ({ isAddToSubUnitModalVisible }) => isAddToSubUnitModalVisible,
);

export const createSubUnitInProgressSel = createSelector(
  moduleSel,
  ({ createSubUnitInProgress }) => createSubUnitInProgress,
);

export const subUnitTypeNameSel = createSelector(
  unitTypeIdSel,
  unitTypeId => programTypeIdToSubUnitName[unitTypeId as 1 | 2],
);

export const subUnitTypeKeysSel = createSelector(subUnitTypeSel, subUnitType =>
  subUnitType === subUnitTypes.DEN ? subUnitDen : subUnitPatrol,
);

export const isSubUnitAllowedSel = createSelector(
  isScoutsBsaUnitSel,
  isCubScoutsUnitSel,
  (isScoutsBsa, isCubScout) => isScoutsBsa || isCubScout,
);

export const origMembersSel = createSelector(
  packRosterItemsSel,
  selectedSubUnitIdSel,
  isDenSel,
  (persons, selectedSubUnitId, isDen) => {
    if (!selectedSubUnitId) return [];

    const subUnitIdAttribute = isDen ? 'denId' : 'patrolId';

    return persons.filter(({ positions }) =>
      positions?.some(
        position => position[subUnitIdAttribute] === selectedSubUnitId,
      ),
    );
  },
);

export const groupedRosterAttendeesSel = createSelector(
  packRosterItemsSel,
  rosterAttendees =>
    rosterAttendees
      .slice()
      .sort((a, b) =>
        a.personShortFullName.localeCompare(b.personShortFullName),
      )
      .reduce(
        (acc, attendee) => {
          const { isAdult, isLeader } = attendee;
          const isDenChief = getIsDenChief(attendee);
          if (isDenChief)
            return { ...acc, denChiefs: [...acc.denChiefs, attendee] };
          if (isAdult && isLeader)
            return { ...acc, adults: [...acc.adults, attendee] };
          if (!isAdult) return { ...acc, youths: [...acc.youths, attendee] };

          return acc;
        },
        {
          adults: [],
          youths: [],
          denChiefs: [],
        } as {
          adults: RosterScout[];
          youths: RosterScout[];
          denChiefs: RosterScout[];
        },
      ),
);

export const subUnitsSel = createSelector(
  moduleSel,
  ({ subUnits }) => subUnits,
);

export const subUnitsInCacheSel = createSelector(
  subUnitsSel,
  organizationGuidSel,
  (subUnits, organizationGuid) => subUnits[organizationGuid],
);

export const subUnitsFilteredSel = createSelector(
  subUnitsSel,
  isDenOrAssistantSel,
  denIdsSel,
  organizationGuidSel,
  (subUnits, isDenOrAssistant, denIds, organizationGuid) => {
    if (!subUnits[organizationGuid]) return [];
    return isDenOrAssistant
      ? subUnits[organizationGuid].filter(subUnit =>
          denIds.includes(subUnit.subUnitId),
        )
      : subUnits[organizationGuid];
  },
);

export const selectedSubUnitSel = createSelector(
  selectedSubUnitIdSel,
  subUnitsSel,
  organizationGuidSel,
  (selectedSubUnitId, subUnits, organizationGuid) => {
    if (!subUnits[organizationGuid]) return undefined;
    return subUnits[organizationGuid].find(
      subUnit => subUnit.subUnitId === selectedSubUnitId,
    );
  },
);

export const approvedSubUnitsSel = createSelector(
  subUnitsFilteredSel,
  subUnits =>
    subUnits
      .filter(subUnit => subUnit.isApproved)
      .map(subUnit => {
        const denType = subUnit.denType ? `${subUnit.denType} ` : '';
        return {
          ...subUnit,
          subUnitNameRaw: subUnit.subUnitName,
          subUnitName: `${denType}${subUnit.subUnitName}`,
        };
      }),
);

export const deleteSubUnitInProgressSel = createSelector(
  moduleSel,
  ({ deleteSubUnitInProgress }) => deleteSubUnitInProgress,
);
