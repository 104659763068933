import _ from 'lodash';
import { createSelector } from 'reselect';

import { getPreferredName } from '@modules/shared/utils/preferredName';

import { purchaseOrdersModuleSel } from '../../common';
import { POStatusTypes } from '../constants';

const moduleSel = state => purchaseOrdersModuleSel(state);

export const closedPOModalVisibleSel = state =>
  moduleSel(state).closedPOModalVisible;

export const addToCartModalVisibleSel = state =>
  moduleSel(state).addToCartModalVisible;

export const addItemsModalVisibleSel = state =>
  moduleSel(state).addItemsModalVisible;

export const updatePurchaseOrderLoadingSel = state =>
  moduleSel(state).updatePurchaseOrderLoading;

export const createPurchaseOrderLoadingSel = state =>
  moduleSel(state).createPurchaseOrderLoading;

export const cartStatusSel = state => moduleSel(state).cartStatus;

export const cartProblemsSel = state => moduleSel(state).cartProblems;

export const problematicSKUsSel = createSelector(
  [cartProblemsSel],
  cartProblems => {
    try {
      // Locate JSON portion of response
      const jsonStartIndex = cartProblems.indexOf('{');

      // If no JSON object is found, return an empty array
      if (jsonStartIndex === -1) {
        return [];
      }

      // Extract the JSON part from the string
      const jsonString = cartProblems.substring(jsonStartIndex);

      // Parse JSON
      const parsedError = JSON.parse(jsonString);
      const message = JSON.parse(parsedError.message);

      // Return the keys of the message, which are the relevant SKU numbers
      return Object.keys(message);
    } catch (error) {
      // In case of any other parsing errors, return an empty array
      return [];
    }
  },
);

export const cartTokenSel = state => moduleSel(state).cartToken;

export const allPurchaseOrdersSel = state => moduleSel(state).allPurchaseOrders;

export const closedOrdersSel = state =>
  allPurchaseOrdersSel(state).filter(po => po.status === POStatusTypes.CLOSED);

export const openOrderSel = state =>
  allPurchaseOrdersSel(state).find(po => po.status === POStatusTypes.OPEN);

export const currentOpenOrderSel = createSelector(
  allPurchaseOrdersSel,
  allPurchaseOrders => {
    const openOrder = allPurchaseOrders.filter(
      po => po.status === POStatusTypes.OPEN,
    );
    const sortedOpenOrder = _.orderBy(
      openOrder,
      ['dateCreated', 'poid'],
      ['desc', 'desc'],
    );
    const firstOpenOrder = _.first(sortedOpenOrder);

    if (!firstOpenOrder) return {};

    return firstOpenOrder;
  },
);

export const currentOrderDetailsSel = state =>
  moduleSel(state).currentPurchaseOrderDetails;

export const currentOrderIdSel = state =>
  moduleSel(state).currentPurchaseOrderId;

// group PO advancement items by SKU
export const orderItemsGroupedSel = createSelector(
  [currentOrderDetailsSel],
  order => {
    const { userAdvancements = [] } = order;
    const combinedItems = userAdvancements.reduce((acc, item) => {
      const { userId, userAdvancementId, advancementType, id } = item;
      const member = {
        name: getPreferredName(item),
        userId,
        userAdvancementId,
      };
      // Check if SKU already exists in accumulator
      const existingItem = acc.find(i => i.sku === item.sku);
      if (existingItem) {
        // SKU already exists, just update the members and totalPrice
        existingItem.members.push(member);
        existingItem.totalPrice += item.price;
        existingItem.quantity++;
      } else {
        // SKU doesn't exist, create a new entry
        acc.push({
          name: item.name,
          sku: item.sku,
          members: [member],
          unitPrice: item.price,
          totalPrice: item.price,
          quantity: 1,
          advancementType,
          advancementId: id,
        });
      }
      return acc;
    }, []);

    return combinedItems;
  },
);

export const itemsForCartSel = createSelector(
  [orderItemsGroupedSel],
  groupedItems => {
    const itemsForCart = groupedItems.map(item => ({
      sku: item.sku,
      qty: item.quantity,
    }));
    return itemsForCart;
  },
);
