import sum from 'lodash/sum';
import { createSelector } from 'reselect';
import type { RootState } from 'src/root/store';

import { pendingRequestsCountSel } from '@offline';
import {
  allNotAwardedAdvancementsCountSel,
  pendingApprovableAdvancementsSel,
} from '@shared/duck';

import { moduleName } from '../constants';
import { NotificationsState } from '../types';

export {
  pendingApprovableAdvancementsSel as pendingAdvancementsSel,
  allNotAwardedAdvancementsCountSel,
};

const moduleSel = (state: RootState) => state[moduleName] as NotificationsState;

export const notificationsVisibleSel = (state: RootState) =>
  moduleSel(state).notificationsVisible;

export const pendingAdvancementsCountSel = (state: RootState) =>
  pendingApprovableAdvancementsSel(state).length;

export const badgeCountSel = createSelector(
  pendingAdvancementsCountSel,
  allNotAwardedAdvancementsCountSel,
  pendingRequestsCountSel,
  (
    pendingAdvancementsCount,
    notAwardedAdvancementsCount,
    pendingRequestsCount,
  ) =>
    pendingAdvancementsCount +
    notAwardedAdvancementsCount +
    pendingRequestsCount,
);

export const notificationCountsSel = (state: RootState) => {
  const moduleState = state.userNotifications as NotificationsState;
  const requirementsCount = sum(
    Object.values(moduleState.dashboardStats?.completed?.requirements || {}),
  );
  return {
    totalCounts: moduleState.totalCount,
    toApproveCount: moduleState.toApproveCount,
    toPurchaseCount: moduleState.toPurchaseCount,
    ranksCount: moduleState.dashboardStats?.completed?.ranks || 0,
    meritBadgesCount: moduleState.dashboardStats?.completed?.meritBadges || 0,
    awardsCount: moduleState.dashboardStats?.completed?.awards || 0,
    requirementsCount,
  };
};

export const hasNotificationsToShowSel = (state: NotificationsState) =>
  badgeCountSel(state) > 0;
