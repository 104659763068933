import React from 'react';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { EDIT_SUB_UNITS } from '@config';
import { Dropdown } from '@shared';

import { SubUnitMenu } from './SubUnitMenu/SubUnitMenu';

const EditSubUnits = ({
  data,
  defaultSubUnitList,
  approvedSubUnits,
  hasSubUnitOnlyPerms,
  canEditSubUnits,
  mySubUnits,
  showSubUnits,
}) => {
  const handleClickSubUnitMenu = e => {
    e.stopPropagation();
  };

  const getFormattedData = data => {
    const defaultValues = defaultSubUnitList.find(
      item => item.userId === data.userId,
    );
    let subUnitFound;
    if (defaultValues) {
      subUnitFound = approvedSubUnits.find(
        subUnit => subUnit.subUnitId === defaultValues.subUnitId,
      );
    }

    return {
      ...data,
      ...defaultValues,
      subUnitName: subUnitFound ? subUnitFound.subUnitName : data.subUnitName,
    };
  };

  const getCanDoActions = (showSubUnits, data, formattedData) => {
    if (hasSubUnitOnlyPerms && !canEditSubUnits) {
      const dataToUse = showSubUnits ? data : formattedData;

      if (dataToUse.subUnitId === -1) return true;

      const hasMatchingSubUnits = mySubUnits.some(sub => {
        const mySubId = sub.denId || sub.patrolId;
        return mySubId === dataToUse.subUnitId;
      });
      return hasMatchingSubUnits;
    }

    return canEditSubUnits;
  };

  const formattedData = getFormattedData(data);

  return (
    EDIT_SUB_UNITS &&
    getCanDoActions(showSubUnits, data, formattedData) && (
      <Dropdown
        overlay={<SubUnitMenu data={showSubUnits ? data : formattedData} />}
        placement="bottomRight"
        trigger={['hover']}
        onClick={handleClickSubUnitMenu}
      >
        <FormatListBulletedIcon />
      </Dropdown>
    )
  );
};

export default EditSubUnits;
