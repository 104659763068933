import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { SCOUTBOOK_LEGACY_URL } from '@config';
import { Button, Card, T } from '@shared/components';

import styles from './ScoutbookAd.less';
import BgDesktopImg from './assets/bg_desktop.png';
import BgMobileImg from './assets/bg_mobile.png';
import LogoImg from './assets/logo.png';

const handleClick = () => {
  window.open(SCOUTBOOK_LEGACY_URL, '_blank');
};

const ScoutbookAd = ({ className }) => (
  <Card className={cn(styles.card, className)} noPadding shadow>
    <div className={styles.container}>
      <img src={BgDesktopImg} className={styles.bgDesktop} />
      <div className={styles.bar}>
        <img src={LogoImg} />
      </div>
      <div className={styles.titleAndButton}>
        <T serif size="4" className={styles.title}>
          <FormattedHTMLMessage id="user.ScoutbookAd.title" />
        </T>
        <Button color="scouting-blue" shadow noBorder onClick={handleClick}>
          <FormattedMessage id="user.ScoutbookAd.tryNow" />
        </Button>
      </div>
      <img src={BgMobileImg} className={styles.bgMobile} />
    </div>
  </Card>
);

ScoutbookAd.propTypes = {
  className: PropTypes.string,
};

export default ScoutbookAd;
