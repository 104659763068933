import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isCurrentPageSel } from '@location';
import { userDataSel } from '@user';
import {
  ROUTE_OWN_ACTIVITY_LOGS,
  S,
  Button,
  isParentOrYouthMemberSel,
  youthMemberPersonGuidSel,
} from '@shared';

import ActivityItem from './ActivityItem';
import styles from './ActivityList.less';

const ActivityList = ({
  disableGetActivities,
  items,
  onSelect,
  onClone,
  onJoin,
  onDelete,
  onNew,
}) => {
  const isParentOrYouthMember = useSelector(isParentOrYouthMemberSel);
  const isOwnLogsPage = useSelector(state =>
    isCurrentPageSel(state, ROUTE_OWN_ACTIVITY_LOGS),
  );
  const hasLimitedActions = isParentOrYouthMember || isOwnLogsPage;
  const youthPersonGuid = useSelector(youthMemberPersonGuidSel);
  const userData = useSelector(userDataSel);
  const { profile: { personGuid: loggedInPersonGuid } = {} } = userData || {};
  const personGuid = isOwnLogsPage ? loggedInPersonGuid : youthPersonGuid;

  const handleSelect = useCallback(activity => onSelect(activity), [onSelect]);
  const handleJoin = useCallback(
    (activity, personExists) => onJoin(activity, personExists),
    [onJoin],
  );
  const handleDelete = useCallback(activity => onDelete(activity), [onDelete]);
  const handleClone = useCallback(
    activity => {
      const clonedActivity = {
        ...activity,
        id: undefined,
        name: `Copy of ${activity.name}`,
        startDate: undefined,
        startTime: undefined,
        endTime: undefined,
      };
      onClone(clonedActivity);
    },
    [onClone],
  );

  const itemNodes = useMemo(
    () =>
      items.map(item => (
        <ActivityItem
          key={item.id}
          personGuid={personGuid}
          data={item}
          onSelect={() => !hasLimitedActions && handleSelect(item)}
          onClone={() => handleClone(item)}
          onJoin={personExists => handleJoin(item, personExists)}
          onDelete={() => handleDelete(item)}
          showDropDownMenu={!hasLimitedActions}
          showJoinButton={hasLimitedActions}
        />
      )),
    [
      items,
      handleSelect,
      handleClone,
      handleJoin,
      handleDelete,
      hasLimitedActions,
      personGuid,
    ],
  );

  return (
    <div className={styles.container}>
      <div>
        {hasLimitedActions ? (
          <React.Fragment>
            <S size="4" colored colon>
              <FormattedMessage id="progress.common.ActivityList.personal" />
            </S>
            <div className={styles.createPersonalActivity}>
              <Button
                type="primary"
                shadow
                shape="round"
                onClick={() => onNew(true)}
              >
                <FormattedMessage id="shared.create" />
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <Button
            color="info"
            type="dashed"
            fitText
            block
            className={styles.createButton}
            onClick={() => onNew()}
          >
            <FormattedMessage id="progress.common.ActivityList.create" />
          </Button>
        )}
      </div>
      {!disableGetActivities && (
        <React.Fragment>
          {items.length ? (
            <S size="4" colored colon className={styles.suggestionsTitle}>
              <FormattedMessage id="progress.common.ActivityList.title" />
            </S>
          ) : (
            <i>
              <FormattedMessage id="progress.common.ActivityList.noResults" />
            </i>
          )}
          <div>{itemNodes}</div>
        </React.Fragment>
      )}
    </div>
  );
};

ActivityList.propTypes = {
  disableGetActivities: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  onJoin: PropTypes.func.isRequired,
  onNew: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActivityList;
