import { setUserPreferences, packRosterFieldPreferencesName } from '@shared';
import { packRosterModuleNamespace } from '../../common';
export {
  SET_SELECTED_KEYS,
  setSelectedKeys,
  PACK_ROSTER_IGNORE_SUB_UNITS,
  PACK_ROSTER_REQUEST_IF_NOT_LOADED,
  PACK_ROSTER_REQUEST,
  PACK_ROSTER_RESPONSE,
  PACK_ROSTER_ERROR,
  PACK_ROSTER_ALREADY_LOADED,
  packRosterIgnoreSubUnits,
  packRosterRequestIfNotLoaded,
  packRosterRequest,
  packRosterResponse,
  packRosterError,
  packRosterAlreadyLoaded,
} from '../../common';

const namespace = `${packRosterModuleNamespace}`;

export const PACK_ROSTER_SEARCH = `${namespace}/PACK_ROSTER_SEARCH`;
export const packRosterSearch = search => ({
  type: PACK_ROSTER_SEARCH,
  payload: search,
});

export const PACK_ROSTER_FILTER = `${namespace}/PACK_ROSTER_FILTER`;
export const packRosterFilter = filter => ({
  type: PACK_ROSTER_FILTER,
  payload: filter,
});

export const PACK_ROSTER_SORT = `${namespace}/PACK_ROSTER_SORT`;
export const packRosterSort = ({ columnKey, order }) => ({
  type: PACK_ROSTER_SORT,
  payload: { columnKey, order },
});

export const SET_SHOW_SUB_UNITS = `${namespace}/SET_SHOW_SUB_UNITS`;
export const setShowSubUnits = showSubUnits => ({
  type: SET_SHOW_SUB_UNITS,
  payload: showSubUnits,
});

export const SET_SUB_UNITS_SECTION_EXPANDED = `${namespace}/SET_SUB_UNITS_SECTION_EXPANDED`;
export const setSubUnitsSectionExpanded = (subUnit, expanded) => ({
  type: SET_SUB_UNITS_SECTION_EXPANDED,
  payload: { [subUnit]: expanded },
});

export const SET_SUB_UNITS_FILTER_TAGS = `${namespace}/SET_SUB_UNITS_FILTER_TAGS`;
export const setSubUnitsFilterTags = subUnitsFilterTags => ({
  type: SET_SUB_UNITS_FILTER_TAGS,
  payload: subUnitsFilterTags,
});

export const SET_SUB_UNIT_USER_DATA = `${namespace}/SET_SUB_UNIT_USER_DATA`;
export const setSubUnitUserData = subUnitUserData => ({
  type: SET_SUB_UNIT_USER_DATA,
  payload: subUnitUserData,
});

export const setFieldsVisibility = fieldsVisibility =>
  setUserPreferences(fieldsVisibility, packRosterFieldPreferencesName);

export const SET_SHOW_MEMBER_TYPES = `${namespace}/SET_SHOW_MEMBER_TYPES`;
export const setShowMemberTypes = showMemberTypes => ({
  type: SET_SHOW_MEMBER_TYPES,
  payload: showMemberTypes,
});

export const SET_MEMBER_TYPE_SECTION_EXPANDED = `${namespace}/SET_MEMBER_TYPE_SECTION_EXPANDED`;
export const setMemberTypeSectionExpanded = (typeName, expanded) => ({
  type: SET_MEMBER_TYPE_SECTION_EXPANDED,
  payload: { [typeName]: expanded },
});

export const SAVE_SBEXTENDED_PROFILE = `${namespace}/SAVE_SBEXTENDED_PROFILE`;
export const SAVE_SBEXTENDED_PROFILE_RESPONSE = `${namespace}/SAVE_SBEXTENDED_PROFILERESPONSE`;
export const SAVE_SBEXTENDED_PROFILE_ERROR = `${namespace}/SAVE_SBEXTENDED_PROFILE_ERROR`;
export const saveSBExtendedProfile = (data, toastText) => ({
  type: SAVE_SBEXTENDED_PROFILE,
  payload: { data, toastText },
});

export const saveSBExtendedProfileResponse = () => ({
  type: SAVE_SBEXTENDED_PROFILE_RESPONSE,
});

export const saveSBExtendedProfileError = error => ({
  type: SAVE_SBEXTENDED_PROFILE_ERROR,
  payload: error,
});

export const SAVE_OPTOUT = `${namespace}/SAVE_OPTOUT`;
export const SAVE_OPTOUT_RESPONSE = `${namespace}/SAVE_OPTOUT_RESPONSE`;
export const SAVE_OPTOUT_ERROR = `${namespace}/SAVE_OPTOUT_ERROR`;
export const saveOptoutProfile = ({
  personGuid,
  optOutValue,
  registrationId,
}) => ({
  type: SAVE_OPTOUT,
  payload: { personGuid, optOutValue, registrationId },
});

export const saveOptoutProfileResponse = () => ({
  type: SAVE_OPTOUT_RESPONSE,
});

export const saveOptoutProfileError = error => ({
  type: SAVE_OPTOUT_ERROR,
  payload: error,
});
