import _ from 'lodash';
import { combineReducers } from 'redux';
import { v4 as uuid } from 'uuid';

import { SET_ORGANIZATION } from '@context';
import { emptyArr } from '@shared';
import { resetOnLogoutReducer } from '@utils';

import {
  advancementsSteps,
  advancementsStepsOrder,
  defaultRsvpUser,
  groupTags,
  sortTags,
  userEventStatus,
} from '../constants';
import {
  changeRsvpStatus,
  updateGuestRsvpStatus,
  updateRsvpStatus,
} from '../utils';
import {
  ADD_EVENT_ADVANCEMENT,
  ADD_EVENT_REMINDER,
  ADD_GUESTS_ERROR,
  ADD_GUESTS_REQUEST,
  ADD_GUESTS_RESPONSE,
  ADD_GUEST_TEMP_REQUEST,
  ATTENDED_TOGGLE_ERROR,
  ATTENDED_TOGGLE_REQUEST,
  ATTENDED_TOGGLE_RESPONSE,
  CHANGE_EVENT_INVITEE_RSVP,
  CLEAR_EVENT_DATA,
  CLOSE_ADD_GUEST_MODAL_VISIBLE,
  CLOSE_QE_RSVP_MODAL,
  CONFIRM_INVITE_ERROR,
  CONFIRM_INVITE_REQUEST,
  CONFIRM_INVITE_RESPONSE,
  CONFIRM_MULTI_INVITE_ERROR,
  CONFIRM_MULTI_INVITE_REQUEST,
  CONFIRM_MULTI_INVITE_RESPONSE,
  DELETE_EVENT_ADVANCEMENT,
  DELETE_EVENT_COMMENT_ERROR,
  DELETE_EVENT_COMMENT_PHOTO_ERROR,
  DELETE_EVENT_COMMENT_PHOTO_REQ,
  DELETE_EVENT_COMMENT_PHOTO_RESPONSE,
  DELETE_EVENT_COMMENT_REQ,
  DELETE_EVENT_COMMENT_RESPONSE,
  DELETE_EVENT_REMINDER_LOCAL_ONLY,
  DELETE_EVENT_REMINDER_RESPONSE,
  DELETE_MULTI_INVITEE_REQUEST,
  DELETE_MULTI_INVITEE_RESPONSE,
  DESELECT_ALL_EVENT_PERSON,
  DESELECT_ALL_UNIT_ATTENDEES,
  DESELECT_EVENT_ACTIVITY,
  DESELECT_EVENT_PERSON,
  DESELECT_GROUP_PERSON_EVENT,
  GET_ACTIVITIES_EVENT,
  GET_ACTIVITIES_EVENT_ERROR,
  GET_ACTIVITIES_EVENT_RESPONSE,
  GET_ACTIVITIES_TO_SELECT_ERROR,
  GET_ACTIVITIES_TO_SELECT_REQUEST,
  GET_ACTIVITIES_TO_SELECT_RESPONSE,
  GET_ACTIVITY_BY_ID_ERROR,
  GET_ACTIVITY_BY_ID_REQUEST,
  GET_ACTIVITY_BY_ID_RESPONSE,
  GET_ALL_SUBUNITS_RESPONSE,
  GET_CALENDAR_RECORDS_ERROR,
  GET_CALENDAR_RECORDS_REQ,
  GET_CALENDAR_RECORDS_RESPONSE,
  GET_EVENT_BY_ID_ERROR,
  GET_EVENT_BY_ID_REQUEST,
  GET_EVENT_BY_ID_RESPONSE,
  GET_EVENT_COMMENTS_ERROR,
  GET_EVENT_COMMENTS_REQ,
  GET_EVENT_COMMENTS_RESPONSE,
  GET_EVENT_INVITEES_RESPONSE,
  GET_EVENT_REMINDERS_RESPONSE,
  GET_EVENT_REQUIREMENTS_ERROR,
  GET_EVENT_REQUIREMENTS_REQUEST,
  GET_EVENT_REQUIREMENTS_RESPONSE,
  GET_EVENT_TYPES_ERROR,
  GET_EVENT_TYPES_REQUEST,
  GET_EVENT_TYPES_RESPONSE,
  GET_EXTERNAL_GUESTS_REQUEST,
  GET_EXTERNAL_GUESTS_RESPONSE,
  GET_REQUIREMENTS_ERROR,
  GET_REQUIREMENTS_REQUEST,
  GET_REQUIREMENTS_RESPONSE,
  GET_UNIT_ROSTER_ERROR,
  GET_UNIT_ROSTER_REQUEST,
  GET_UNIT_ROSTER_RESPONSE,
  IS_VISIBLE_POPOVER,
  OPEN_ADD_GUEST_MODAL_VISIBLE,
  OPEN_QE_RSVP_MODAL,
  POST_EVENT_COMMENT_ERROR,
  POST_EVENT_COMMENT_REQ,
  POST_EVENT_COMMENT_RESPONSE,
  POST_EVENT_COMMENT_WITH_FILES_ERROR,
  POST_EVENT_COMMENT_WITH_FILES_REQ,
  POST_EVENT_COMMENT_WITH_FILES_RESPONSE,
  PUT_EVENT_COMMENT_ERROR,
  PUT_EVENT_COMMENT_REQ,
  PUT_EVENT_COMMENT_RESPONSE,
  REMOVE_GUEST_TEMP_REQUEST,
  ROUTE_EVENTS_ADD_ADVANCEMENT,
  SAVE_ACTIVITY_EVENT,
  SAVE_ACTIVITY_EVENT_ERROR,
  SAVE_ACTIVITY_EVENT_RESPONSE,
  SAVE_EVENT_ERROR,
  SAVE_EVENT_REQUEST,
  SAVE_EVENT_RESPONSE,
  SAVE_RECURRENT_EVENT_REQUEST,
  SEARCH_ADVANCEMENT,
  SELECT_ALL_ATTENDEES_EVENT,
  SELECT_ALL_LEADERS_ATTENDEES_EVENT,
  SELECT_ALL_PARENTS_ATTENDEES_EVENT,
  SELECT_ALL_SUB_ATTENDEES_EVENT,
  SELECT_ATTENDEES_BY_EVENT_TYPE,
  SELECT_EVENT_ACTIVITY,
  SELECT_EVENT_PERSON,
  SELECT_LEADERS_PARENTS_ATTENDEES_EVENT,
  SET_ADVANCEMENT_CURRENT_STEP,
  SET_ADVANCEMENT_REQUIREMENTS,
  SET_ADVANCEMENT_TYPE,
  SET_ATTENDEE_GROUP_FILTER,
  SET_ATTENDEE_SORT_ORDER,
  SET_EVENT_QE_LOADING,
  SET_EVENT_RELOAD,
  SET_EVENT_UNITS,
  SET_RSVP_EVENT,
  SET_SELECTED_ADVANCEMENT,
  SET_SHOW_FILTER_POPOVER,
  UPDATE_PERSON_RSVP_ERROR,
  UPDATE_PERSON_RSVP_REQUEST,
  UPDATE_PERSON_RSVP_RESPONSE,
  UPDATE_RECURRENT_EVENT_REQUEST,
  UPDATE_RSVP_EVENT_PERSON,
} from './actions';

const eventDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_EVENT_BY_ID_RESPONSE: {
      return payload.data;
    }
    case CLEAR_EVENT_DATA: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const selectedAdvancement = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_ADVANCEMENT:
    case SET_ADVANCEMENT_REQUIREMENTS:
      return payload;
    default:
      return state;
  }
};

const eventDetailsLoading = (state = false, { type }) => {
  switch (type) {
    case GET_EVENT_BY_ID_REQUEST: {
      return true;
    }
    case GET_EVENT_BY_ID_RESPONSE:
    case GET_EVENT_BY_ID_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const eventInvitees = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ACTIVITY_BY_ID_REQUEST:
    case GET_EVENT_BY_ID_REQUEST:
    case GET_EVENT_BY_ID_ERROR:
    case GET_ACTIVITY_BY_ID_ERROR:
      return [];
    case GET_EVENT_INVITEES_RESPONSE:
      return payload;
    case GET_EVENT_BY_ID_RESPONSE: {
      const { invitedUsers, users } = payload.data;
      const invitees = users || invitedUsers || emptyArr;
      return invitees;
    }
    case CHANGE_EVENT_INVITEE_RSVP: {
      const {
        data: { userId, rsvp },
        updateRsvp,
      } = payload;
      return updateRsvp
        ? state.map(invitee =>
            invitee.userId === userId ? { ...invitee, rsvp } : invitee,
          )
        : [...state, { ...payload.data }];
    }
    case CONFIRM_MULTI_INVITE_RESPONSE: {
      const { invitees } = payload;

      const indexedUsers = _.keyBy(invitees, 'userId');

      return state.map(invitee => {
        const updatedUser = indexedUsers[invitee.userId];
        const rsvp = updatedUser ? updatedUser.rsvp : invitee.rsvp;

        return {
          ...invitee,
          rsvp: rsvp,
        };
      });
    }
    case DELETE_MULTI_INVITEE_RESPONSE: {
      const { invitees: test } = payload;

      return state.filter(invitee => !test.includes(invitee.userId));
    }
    default:
      return state;
  }
};

const isLoadingConfirmInvite = (state = false, { type }) => {
  switch (type) {
    case CONFIRM_INVITE_REQUEST:
      return true;
    case CONFIRM_INVITE_ERROR:
    case CONFIRM_INVITE_RESPONSE:
      return false;
    default:
      return state;
  }
};

const isLoadingConfirmMultiInvite = (state = false, { type }) => {
  switch (type) {
    case CONFIRM_MULTI_INVITE_REQUEST:
      return true;
    case CONFIRM_MULTI_INVITE_ERROR:
    case CONFIRM_MULTI_INVITE_RESPONSE:
      return false;
    default:
      return state;
  }
};

const activityDetails = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_ACTIVITY_BY_ID_RESPONSE:
      return payload;
    default:
      return state;
  }
};

const activityDetailsLoading = (state = false, { type }) => {
  switch (type) {
    case GET_ACTIVITY_BY_ID_RESPONSE:
    case GET_ACTIVITY_BY_ID_ERROR:
      return false;
    case GET_ACTIVITY_BY_ID_REQUEST:
      return true;
    default:
      return state;
  }
};

const isVisiblePopover = (state = false, { type, payload }) => {
  switch (type) {
    case IS_VISIBLE_POPOVER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const advancementCurrentStep = (
  state = advancementsStepsOrder[0],
  { type, payload },
) => {
  switch (type) {
    case SET_ADVANCEMENT_CURRENT_STEP: {
      return payload;
    }
    case ROUTE_EVENTS_ADD_ADVANCEMENT: {
      return advancementsSteps.SELECT_ADVANCEMENT;
    }
    case GET_REQUIREMENTS_RESPONSE:
    case GET_REQUIREMENTS_ERROR:
    case SET_ADVANCEMENT_REQUIREMENTS:
      return advancementsSteps.SELECT_REQUIREMENTS;
    default: {
      return state;
    }
  }
};

const searchAdvancement = (state = '', { type, payload }) => {
  switch (type) {
    case SEARCH_ADVANCEMENT: {
      return payload;
    }
    case ROUTE_EVENTS_ADD_ADVANCEMENT: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const advancementType = (state = '', { type, payload }) => {
  switch (type) {
    case SET_ADVANCEMENT_TYPE: {
      return payload;
    }
    case SET_ORGANIZATION: {
      return '';
    }
    default: {
      return state;
    }
  }
};

const isLoadingRequirements = (state = false, { type }) => {
  switch (type) {
    case GET_REQUIREMENTS_REQUEST: {
      return true;
    }
    case GET_REQUIREMENTS_RESPONSE:
    case GET_REQUIREMENTS_ERROR:
      return false;
    default: {
      return state;
    }
  }
};

const advancementRequirements = (state = {}, { type, payload }) => {
  switch (type) {
    case GET_REQUIREMENTS_RESPONSE:
    case SET_ADVANCEMENT_REQUIREMENTS: {
      return payload;
    }
    case GET_REQUIREMENTS_ERROR: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const selectedEventAdvancements = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_EVENT_ADVANCEMENT: {
      return state.includes(state.find(item => item.id === payload.id))
        ? state.map(item => (item.id === payload.id ? payload : item))
        : [...state, payload];
    }
    case DELETE_EVENT_ADVANCEMENT: {
      return state.filter(advancement => advancement.id !== payload.id);
    }
    case SET_ORGANIZATION:
    case CLEAR_EVENT_DATA:
      return [];
    default: {
      return state;
    }
  }
};

const activitiesToSelect = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ACTIVITIES_TO_SELECT_RESPONSE: {
      return payload;
    }
    case GET_ACTIVITIES_TO_SELECT_REQUEST:
    case GET_ACTIVITIES_TO_SELECT_ERROR:
      return [];
    default: {
      return state;
    }
  }
};

const showFilterPopover = (state = false, { type, payload }) => {
  switch (type) {
    case SET_SHOW_FILTER_POPOVER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const setAttendeeGroupFilter = (
  state = groupTags.IS_ADULT,
  { type, payload },
) => {
  switch (type) {
    case SET_ATTENDEE_GROUP_FILTER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const setAttendeeSortOrder = (
  state = sortTags.FIRST_NAME,
  { type, payload },
) => {
  switch (type) {
    case SET_ATTENDEE_SORT_ORDER: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedEventActivitiesIds = (state = [], { type, payload }) => {
  switch (type) {
    case SELECT_EVENT_ACTIVITY: {
      return [...new Set([...state, payload])];
    }
    case DESELECT_EVENT_ACTIVITY: {
      return state.filter(activityId => activityId != payload);
    }
    default: {
      return state;
    }
  }
};

const isLoadingSaveEvent = (state = false, { type }) => {
  switch (type) {
    case SAVE_RECURRENT_EVENT_REQUEST:
    case UPDATE_RECURRENT_EVENT_REQUEST:
    case SAVE_EVENT_REQUEST: {
      return true;
    }
    case SAVE_EVENT_RESPONSE:
    case SAVE_EVENT_ERROR:
      return false;
    default: {
      return state;
    }
  }
};

const isRSVPEvent = (state = false, { type, payload }) => {
  switch (type) {
    case SET_RSVP_EVENT: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedEventUsers = (state = [], { type, payload }) => {
  switch (type) {
    case DESELECT_ALL_UNIT_ATTENDEES: {
      const updatedList = [];
      payload.forEach(user => {
        const findUser = state.find(({ userId }) => userId === user.userId);
        if (findUser.status !== userEventStatus.NEW) {
          updatedList.push({ ...findUser, status: userEventStatus.DELETED });
        }
      });
      return updatedList;
    }
    case DESELECT_EVENT_PERSON: {
      const { status } = state.find(({ userId }) => userId === payload);
      return status === userEventStatus.NEW
        ? state.filter(({ userId }) => userId !== payload)
        : changeRsvpStatus(state, payload, userEventStatus.DELETED);
    }
    case SELECT_EVENT_PERSON: {
      const findUser = state.find(({ userId }) => userId === payload);
      return state.includes(findUser)
        ? changeRsvpStatus(state, payload, userEventStatus.OLD)
        : [...state, { ...defaultRsvpUser, userId: payload }];
    }
    case SELECT_ALL_ATTENDEES_EVENT: {
      return payload.map(user => {
        const findUser = state.find(({ userId }) => userId === user.userId);
        return state.includes(findUser)
          ? { ...findUser, status: userEventStatus.OLD }
          : { ...defaultRsvpUser, userId: user.userId };
      });
    }
    case SELECT_LEADERS_PARENTS_ATTENDEES_EVENT:
    case SELECT_ALL_LEADERS_ATTENDEES_EVENT:
    case SELECT_ALL_PARENTS_ATTENDEES_EVENT: {
      const newPerson = payload.map(user => {
        const findUser = state.find(({ userId }) => userId === user.userId);
        return findUser && findUser.status !== userEventStatus.NEW
          ? { ...findUser, status: userEventStatus.OLD }
          : { ...defaultRsvpUser, userId: user.userId };
      });

      const filteredState = state.filter(
        oldUser =>
          !newPerson.some(newUser => newUser.userId === oldUser.userId),
      );

      const updatedList = filteredState
        ? [...filteredState, ...newPerson]
        : newPerson;

      return updatedList;
    }
    case SELECT_ALL_SUB_ATTENDEES_EVENT: {
      const newPerson = payload.map(user => {
        const findUser = state.find(({ userId }) => userId === user.userId);
        return !findUser ? { ...defaultRsvpUser, userId: user.userId } : null;
      });

      const updatedList = state ? [...state, ...newPerson] : newPerson;

      return updatedList.filter(person => person !== null);
    }
    case SELECT_ATTENDEES_BY_EVENT_TYPE: {
      return payload.map(user => ({ ...defaultRsvpUser, userId: user.userId }));
    }
    case GET_EVENT_BY_ID_RESPONSE: {
      const { data, clone } = payload;
      const { users = [] } = data;
      return users.map(user => ({
        ...user,
        status: userEventStatus.OLD,
        rsvp: clone ? null : user.rsvp,
        rsvpCode: clone ? null : user.rsvpCode,
      }));
    }
    case UPDATE_RSVP_EVENT_PERSON: {
      const { person } = payload;
      return updateRsvpStatus(state, person);
    }
    case DESELECT_ALL_EVENT_PERSON: {
      const updatedList = [];
      payload.forEach(uId => {
        const findUser = state.find(({ userId }) => userId === uId);
        if (findUser.status !== userEventStatus.NEW) {
          updatedList.push({ ...findUser, status: userEventStatus.DELETED });
        }
      });
      return updatedList;
    }
    case DESELECT_GROUP_PERSON_EVENT: {
      let updatedList = [];
      state.forEach(person => {
        const findUser = payload.find(uId => person.userId === uId);

        if (findUser) {
          if (
            findUser.status !== undefined &&
            findUser.status !== userEventStatus.NEW
          ) {
            updatedList.push({ ...person, status: userEventStatus.DELETED });
          }
          return;
        }

        updatedList.push(person);
      });

      return updatedList;
    }
    case CLEAR_EVENT_DATA:
      return [];
    default: {
      return state;
    }
  }
};

const eventReminders = (state = [], { type, payload }) => {
  switch (type) {
    case GET_EVENT_REMINDERS_RESPONSE: {
      return payload;
    }
    case ADD_EVENT_REMINDER:
      return [...state, { index: uuid() }];
    case DELETE_EVENT_REMINDER_RESPONSE: {
      return state.filter(({ reminderId }) => reminderId !== payload);
    }
    case DELETE_EVENT_REMINDER_LOCAL_ONLY: {
      return state.filter(({ index }) => index !== payload);
    }
    case CLEAR_EVENT_DATA:
      return [];
    default: {
      return state;
    }
  }
};

const eventRequirements = (state = [], { type, payload }) => {
  switch (type) {
    case GET_EVENT_REQUIREMENTS_RESPONSE: {
      return payload;
    }
    case GET_EVENT_REQUIREMENTS_ERROR:
      return [];
    default: {
      return state;
    }
  }
};

const eventRequirementsLoading = (state = false, { type }) => {
  switch (type) {
    case GET_EVENT_REQUIREMENTS_REQUEST: {
      return true;
    }
    case GET_EVENT_REQUIREMENTS_RESPONSE:
    case GET_EVENT_REQUIREMENTS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const eventTypes = (state = [], { type, payload }) => {
  switch (type) {
    case GET_EVENT_TYPES_RESPONSE: {
      return payload;
    }
    case GET_EVENT_TYPES_ERROR:
      return [];
    default: {
      return state;
    }
  }
};

const eventTypesLoading = (state = false, { type }) => {
  switch (type) {
    case GET_EVENT_TYPES_REQUEST: {
      return true;
    }
    case GET_EVENT_TYPES_RESPONSE:
    case GET_EVENT_TYPES_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const allSubUnits = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ALL_SUBUNITS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const selectedEventUnits = (state = [], { type, payload }) => {
  switch (type) {
    case SET_EVENT_UNITS: {
      return payload;
    }
    case CLEAR_EVENT_DATA:
      return [];
    default: {
      return state;
    }
  }
};

const allRosters = (state = [], { type, payload }) => {
  switch (type) {
    case GET_UNIT_ROSTER_RESPONSE: {
      return [...state, ...payload.roster];
    }
    default: {
      return state;
    }
  }
};

const allRostersFetchedGuids = (state = [], { type, payload }) => {
  switch (type) {
    case GET_UNIT_ROSTER_RESPONSE: {
      return [...state, ...payload.unitGuids];
    }
    default: {
      return state;
    }
  }
};

const isAddGuestLoading = (state = false, { type }) => {
  switch (type) {
    case GET_EXTERNAL_GUESTS_REQUEST:
    case ADD_GUESTS_RESPONSE:
    case ADD_GUESTS_ERROR:
      return false;
    case ADD_GUESTS_REQUEST:
      return true;
    default:
      return state;
  }
};

const removedGuestList = (state = [], { type, payload }) => {
  switch (type) {
    case REMOVE_GUEST_TEMP_REQUEST: {
      return state.includes(
        state.find(person => person === payload.calendarEventGuestId),
      )
        ? state
        : [...state, payload];
    }
    case CLEAR_EVENT_DATA:
      return [];
    default: {
      return state;
    }
  }
};

const selectedGuestList = (state = [], { type, payload }) => {
  switch (type) {
    case ADD_GUEST_TEMP_REQUEST: {
      const findUser = state.find(({ calendarEventGuestId, email }) =>
        payload.calendarEventGuestId
          ? calendarEventGuestId === payload.calendarEventGuestId
          : email === payload.email,
      );

      return state.includes(findUser)
        ? updateGuestRsvpStatus(state, payload)
        : [...state, { ...payload }];
    }
    case REMOVE_GUEST_TEMP_REQUEST: {
      return state.filter(person => person.email !== payload.email);
    }
    case GET_EXTERNAL_GUESTS_RESPONSE: {
      const updatedPayload = payload.map(person => ({
        ...person,
        isGuest: true,
        personFullName: `${person.firstName || ''} ${person.lastName || ''}`,
        status: 'old',
      }));

      return updatedPayload;
    }
    case CLEAR_EVENT_DATA:
      return [];
    default: {
      return state;
    }
  }
};

const isVisibleAddGuestModal = (state = false, { type }) => {
  switch (type) {
    case OPEN_ADD_GUEST_MODAL_VISIBLE: {
      return true;
    }
    case CLOSE_ADD_GUEST_MODAL_VISIBLE: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isVisibleQuickEntryRSVPModal = (state = false, { type }) => {
  switch (type) {
    case OPEN_QE_RSVP_MODAL: {
      return true;
    }
    case CLOSE_QE_RSVP_MODAL: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAttendedToggleLoading = (state = false, { type }) => {
  switch (type) {
    case ATTENDED_TOGGLE_ERROR:
    case ATTENDED_TOGGLE_RESPONSE:
      return false;
    case ATTENDED_TOGGLE_REQUEST:
      return true;
    default:
      return state;
  }
};

const isEventQELoading = (state = false, { type, payload }) => {
  switch (type) {
    case SET_EVENT_QE_LOADING:
      return payload;
    case ATTENDED_TOGGLE_ERROR:
    case ATTENDED_TOGGLE_RESPONSE:
    case GET_EVENT_BY_ID_RESPONSE:
    case GET_EVENT_BY_ID_ERROR:
      return false;
    case ATTENDED_TOGGLE_REQUEST:
    case GET_EVENT_BY_ID_REQUEST:
    case DELETE_MULTI_INVITEE_REQUEST:
    case CONFIRM_MULTI_INVITE_REQUEST:
      return true;
    default:
      return state;
  }
};
const isEventUnitRosterLoading = (state = false, { type }) => {
  switch (type) {
    case GET_UNIT_ROSTER_ERROR:
    case GET_UNIT_ROSTER_REQUEST:
      return true;
    case GET_UNIT_ROSTER_RESPONSE:
      return false;
    default:
      return state;
  }
};

const isEventReload = (state = true, { type, payload }) => {
  switch (type) {
    case SET_EVENT_RELOAD:
      return payload;
    default:
      return state;
  }
};

const updatePersonRSVPLoading = (state = false, { type }) => {
  switch (type) {
    case UPDATE_PERSON_RSVP_RESPONSE:
    case UPDATE_PERSON_RSVP_ERROR:
      return false;
    case UPDATE_PERSON_RSVP_REQUEST:
      return true;
    default:
      return state;
  }
};

//Activity Event
const isSavingActivityEvent = (state = false, { type }) => {
  switch (type) {
    case SAVE_ACTIVITY_EVENT: {
      return true;
    }
    case SAVE_ACTIVITY_EVENT_RESPONSE:
    case SAVE_ACTIVITY_EVENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLoadingActivitiesEvent = (state = false, { type }) => {
  switch (type) {
    case GET_ACTIVITIES_EVENT: {
      return true;
    }
    case GET_ACTIVITIES_EVENT_RESPONSE:
    case GET_ACTIVITIES_EVENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const activitiesEvent = (state = [], { type, payload }) => {
  switch (type) {
    case GET_ACTIVITIES_EVENT_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const calendarRecords = (state = [], { type, payload }) => {
  switch (type) {
    case GET_CALENDAR_RECORDS_RESPONSE: {
      return payload;
    }
    default:
      return state;
  }
};

const isLoadingCalendarRecords = (state = false, { type }) => {
  switch (type) {
    case GET_CALENDAR_RECORDS_REQ: {
      return true;
    }
    case GET_CALENDAR_RECORDS_RESPONSE:
    case GET_CALENDAR_RECORDS_ERROR: {
      return false;
    }
    default:
      return state;
  }
};

const eventComments = (state = [], { type, payload }) => {
  switch (type) {
    case GET_EVENT_COMMENTS_REQ: {
      return [];
    }
    case GET_EVENT_COMMENTS_RESPONSE: {
      return payload;
    }
    case DELETE_EVENT_COMMENT_RESPONSE: {
      return state.filter(comment => comment.id !== payload?.commentId);
    }
    default:
      return state;
  }
};

const eventCommentsLoading = (state = false, { type }) => {
  switch (type) {
    case DELETE_EVENT_COMMENT_PHOTO_REQ:
    case DELETE_EVENT_COMMENT_REQ:
    case GET_EVENT_COMMENTS_REQ: {
      return true;
    }
    case DELETE_EVENT_COMMENT_ERROR:
    case DELETE_EVENT_COMMENT_PHOTO_ERROR:
    case DELETE_EVENT_COMMENT_PHOTO_RESPONSE:
    case DELETE_EVENT_COMMENT_RESPONSE:
    case GET_EVENT_COMMENTS_ERROR:
    case GET_EVENT_COMMENTS_RESPONSE: {
      return false;
    }
    default:
      return state;
  }
};

const eventCommentsError = (state = false, { type, payload }) => {
  switch (type) {
    case GET_EVENT_COMMENTS_REQ: {
      return null;
    }
    case GET_EVENT_COMMENTS_ERROR: {
      return payload;
    }
    default:
      return state;
  }
};

const postingEventComment = (state = false, { type }) => {
  switch (type) {
    case POST_EVENT_COMMENT_REQ:
    case POST_EVENT_COMMENT_WITH_FILES_REQ: {
      return true;
    }
    case POST_EVENT_COMMENT_ERROR:
    case POST_EVENT_COMMENT_RESPONSE:
    case POST_EVENT_COMMENT_WITH_FILES_ERROR:
    case POST_EVENT_COMMENT_WITH_FILES_RESPONSE: {
      return false;
    }
    default:
      return state;
  }
};

const updatingEventComment = (state = false, { type }) => {
  switch (type) {
    case PUT_EVENT_COMMENT_REQ: {
      return true;
    }
    case PUT_EVENT_COMMENT_ERROR:
    case PUT_EVENT_COMMENT_RESPONSE: {
      return false;
    }
    default:
      return state;
  }
};

const reducers = combineReducers({
  eventDetails,
  eventDetailsLoading,
  activityDetails,
  activityDetailsLoading,
  eventInvitees,
  isLoadingConfirmInvite,
  isVisiblePopover,
  advancementCurrentStep,
  selectedEventAdvancements,
  selectedAdvancement,
  searchAdvancement,
  advancementType,
  isLoadingRequirements,
  advancementRequirements,
  activitiesToSelect,
  selectedEventActivitiesIds,
  showFilterPopover,
  setAttendeeGroupFilter,
  setAttendeeSortOrder,
  isLoadingSaveEvent,
  isRSVPEvent,
  selectedEventUsers,
  eventReminders,
  eventRequirements,
  eventRequirementsLoading,
  eventTypes,
  eventTypesLoading,
  allSubUnits,
  selectedEventUnits,
  allRosters,
  allRostersFetchedGuids,
  isVisibleAddGuestModal,
  isAddGuestLoading,
  selectedGuestList,
  removedGuestList,
  isVisibleQuickEntryRSVPModal,
  isLoadingConfirmMultiInvite,
  isAttendedToggleLoading,
  isEventQELoading,
  isEventUnitRosterLoading,
  updatePersonRSVPLoading,
  isSavingActivityEvent,
  isLoadingActivitiesEvent,
  activitiesEvent,
  isEventReload,
  calendarRecords,
  isLoadingCalendarRecords,
  eventComments,
  eventCommentsError,
  eventCommentsLoading,
  postingEventComment,
  updatingEventComment,
});

export default resetOnLogoutReducer(reducers);
