import { createSelector } from 'reselect';

import {
  packRosterItemsSel,
  rosterYouthSelectedSel,
} from '@modules/shared/duck/selectors/general.selectors';

import { reportsModuleSel } from '../common';

export { reportsSel, reportsByCodesSel } from '../basicReports';

export const activeTabSel = state => reportsModuleSel(state).activeTab;
export const reportParamsVisibleSel = state =>
  reportsModuleSel(state).reportParamsVisible;
export const reportParamsDataSel = state =>
  reportsModuleSel(state).reportParamsData;

export const areAllYouthSelected = createSelector(
  packRosterItemsSel,
  rosterYouthSelectedSel,
  (allItems, selected) => {
    const youth = allItems.filter(
      item =>
        !item.isLeader &&
        !item.isAdult &&
        !item.isParent &&
        !item.isUnitParticipant,
    );

    const allChecked = youth.length === selected.length;

    return allChecked;
  },
);
