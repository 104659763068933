import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { esbApiService } from '@utils';

import { transformOrgProfile } from './utils';

/**
 * @esbEndpoint GET /organizations/v2/:orgGuid/profile
 */
const getProfileInfo$ = guid =>
  esbApiService
    .get$(`/organizations/v2/${guid}/profile`, {
      suppressErrorToasts: [404],
      suppressSentry: [404],
      gtm: {
        label: '/organizations/v2/{guid}/profile',
      },
    })
    .pipe(
      map(transformOrgProfile),
      catchError(err => {
        if (err.status !== 404) {
          throw err;
        }
        return of({});
      }),
    );

const getUnitInfo$ = guid => getProfileInfo$(guid);

export default {
  getUnitInfo$,
};
