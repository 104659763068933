import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Hr.less';

// horizontal rule
const Hr = ({ className }) => {
  const classNames = cn(styles.hr, className);

  return <div className={classNames} />;
};

Hr.propTypes = {
  className: PropTypes.string,
};

export default Hr;
