import React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { DateView, viewDateFormat } from '@shared';
import { hasPermissionSel, permissions } from '@user';

import {
  EagleScoutButton,
  EditAdvancementButton,
  UnapproveAdvancementButton,
} from '../../../../../progress/components';
import { historyItemsTypes } from '../../../constants';
import styles from './HistoryItemDate.less';

const HistoryItemDate = ({
  isMobile,
  type,
  date,
  person,
  advancement,
  availableAction,
  isEagleScoutRank,
}) => {
  const dateNode = <DateView value={date} format={viewDateFormat} />;
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );

  if (isMobile || type == historyItemsTypes.ADVANCEMENT_FILE) {
    return dateNode;
  }

  const editIcon = <EditIcon className={styles.icon} />;

  return (
    <React.Fragment>
      {dateNode}
      {isEagleScoutRank && (
        <EagleScoutButton
          person={{ youthName: person.name, userId: person.userId }}
        />
      )}
      {availableAction == 'canEdit' && canApprove && (
        <EditAdvancementButton person={person} advancement={advancement}>
          {editIcon}
        </EditAdvancementButton>
      )}
      {availableAction == 'canUnapprove' && canApprove && (
        <UnapproveAdvancementButton
          youthName={person.name}
          advancement={advancement}
        >
          {editIcon}
        </UnapproveAdvancementButton>
      )}
    </React.Fragment>
  );
};

HistoryItemDate.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    historyItemsTypes.ADVANCEMENT,
    historyItemsTypes.ADVANCEMENT_FILE,
    historyItemsTypes.ACTIVITY,
  ]).isRequired,
  status: PropTypes.string,
  date: PropTypes.string,
  person: PropTypes.object,
  advancement: PropTypes.object,
  availableAction: PropTypes.string,
  isEagleScoutRank: PropTypes.bool,
};

export default HistoryItemDate;
