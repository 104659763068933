import {
  isScreenLgSel,
  isScreenMdSel,
  isScreenXlSel,
  maxModeWidthSel,
  minModeWidthSel,
  screenModeSel as modeSel,
} from '@shared/duck';

import {
  lgWidth,
  mdWidth,
  screenSm,
  screenXs,
  screenXxl,
  smWidth,
  xlWidth,
  xsWidth,
} from '../constants';

export {
  modeSel,
  maxModeWidthSel,
  minModeWidthSel,
  isScreenMdSel,
  isScreenLgSel,
  isScreenXlSel,
};
export {
  screenModuleSel as moduleSel,
  isMobileSel,
  isScreenAtLeastLgSel,
  isScreenAtLeastXlSel,
} from '@shared/duck';

export const isScreenXsSel = state => modeSel(state) === screenXs;
export const isScreenAtMostXsSel = state =>
  isScreenXsSel(state) || maxModeWidthSel(state) <= xsWidth;

export const isScreenSmSel = state => modeSel(state) === screenSm;
export const isScreenAtLeastSmSel = state =>
  isScreenSmSel(state) || minModeWidthSel(state) > xsWidth;
export const isScreenAtMostSmSel = state =>
  isScreenSmSel(state) || maxModeWidthSel(state) <= smWidth;

export const isScreenAtLeastMdSel = state =>
  isScreenMdSel(state) || minModeWidthSel(state) > smWidth;
export const isScreenAtMostMdSel = state =>
  isScreenMdSel(state) || maxModeWidthSel(state) <= mdWidth;

export const isScreenAtMostLgSel = state =>
  isScreenLgSel(state) || maxModeWidthSel(state) <= lgWidth;
// alias for convenience
export const isTabletSel = isScreenLgSel;

export const isScreenAtMostXlSel = state =>
  isScreenXlSel(state) || maxModeWidthSel(state) <= xlWidth;

export const isScreenXxlSel = state => modeSel(state) === screenXxl;
export const isScreenAtLeastXxlSel = state =>
  isScreenXxlSel(state) || minModeWidthSel(state) > xlWidth;
