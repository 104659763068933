/*
Custom combineReducers that passes the current slice of the state as 3rd argument.
See: https://redux.js.org/recipes/structuring-reducers/beyond-combinereducers
and: https://github.com/reduxjs/redux/blob/master/src/combineReducers.js
*/
export default function combineReducersWithState(reducers) {
  const reducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    let hasChanged = false;
    const nextState = {};
    for (let i = 0; i < reducerKeys.length; i++) {
      const key = reducerKeys[i];
      const reducer = reducers[key];
      const previousStateForKey = state[key];
      const nextStateForKey = reducer(previousStateForKey, action, state); // <- 3rd param here
      nextState[key] = nextStateForKey;
      hasChanged = hasChanged || nextStateForKey !== previousStateForKey;
    }
    return hasChanged ? nextState : state;
  };
}
