import React from 'react';

import cn from 'classnames';
import { isEmpty } from 'lodash';
import InfoIcon from 'material-ui-icons/Info';

import type { SProps } from '@shared/components/S/S';

import S from '../S';
import styles from './Notice.less';

type NoticeProps = {
  className?: string;
  message?: string | React.ReactNode;
  size: SProps['size'];
};

const Notice = ({ className, message = '', size = '2' }: NoticeProps) => {
  if (isEmpty(message)) {
    return null;
  }

  return (
    <div className={cn(styles.notice, className)}>
      <InfoIcon className={cn(styles.icon, styles[`icon-size-${size}`])} />
      <S size={size}>{message}</S>
    </div>
  );
};

export default Notice;
