import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';
import {
  USER_PERMISSIONS_RESPONSE,
  AVAILABLE_ROLES_RESPONSE,
  AVAILABLE_USERS_RESPONSE,
  DELETE_USER_PERMISSION,
  UPDATE_USER_PERMISSION,
  ADD_USER_PERMISSION,
  SET_SEARCH,
} from './actions';

const userPermissions = (state = [], { type, payload }) => {
  switch (type) {
    case USER_PERMISSIONS_RESPONSE: {
      return payload;
    }
    case DELETE_USER_PERMISSION: {
      return state.filter(({ id }) => id !== payload.id);
    }
    case UPDATE_USER_PERMISSION: {
      return state.map(permission =>
        permission.id !== payload.id
          ? permission
          : { ...permission, roleType: payload.roleType },
      );
    }
    case ADD_USER_PERMISSION: {
      return [
        ...state,
        {
          id: state.length + 1,
          name: payload.user.name,
          roleType: payload.role.name,
          description: payload.role.description,
        },
      ];
    }
    default: {
      return state;
    }
  }
};

const availableRoles = (state = [], { type, payload }) => {
  switch (type) {
    case AVAILABLE_ROLES_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const availableUsers = (state = [], { type, payload }) => {
  switch (type) {
    case AVAILABLE_USERS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

const search = (state = '', { type, payload }) => {
  switch (type) {
    case SET_SEARCH: {
      return payload;
    }
    default:
      return state;
  }
};

const reducer = combineReducers({
  userPermissions,
  availableRoles,
  availableUsers,
  search,
});

export default resetOnLogoutReducer(reducer);
