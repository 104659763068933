import React from 'react';
import PropTypes from 'prop-types';

import { Button, ExpandToggle } from '@shared';

class ItemDetailsToggle extends React.PureComponent {
  handleClick = () => {
    const { itemKey, onClick } = this.props;
    onClick(itemKey);
  };

  render = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      itemKey,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick,
      expanded,
      children,
      chevronClassName,
      ...rest
    } = this.props;

    return (
      <Button
        id="qa_viewButton"
        ghost
        noBorder
        fitText
        color="info"
        onClick={this.handleClick}
        {...rest}
      >
        {children}
        <ExpandToggle expanded={expanded} className={chevronClassName} />
      </Button>
    );
  };
}

ItemDetailsToggle.propTypes = {
  itemKey: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  chevronClassName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default ItemDetailsToggle;
