/**
 * Makes a sanitized search icludes function.
 */

export default rawQuery => {
  const query = `${rawQuery || ''}`.toLowerCase();
  return rawValue => {
    const value = `${rawValue || ''}`.toLowerCase();
    return value.includes(query);
  };
};
