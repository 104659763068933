import React from 'react';

import PropTypes from 'prop-types';

import { sorterPropTypes } from '@modules/shared/constants';
import { Table } from '@shared/components';

import { qaRowClassName } from '../../helpers';
import { columns } from './HistoryReportsTableColumns';

class HistoryReportsTable extends React.PureComponent {
  handleTableChange = (pagination, filters, sorter) => {
    this.props.onSorterChange(sorter);
  };

  render() {
    const { loading, items, sorter } = this.props;

    return (
      <Table
        key="table"
        size="middle"
        fixedLayout
        rowClassName={qaRowClassName}
        loading={loading}
        sorter={sorter}
        rowKey="POID"
        columns={columns(this.props)}
        dataSource={items}
        onChange={this.handleTableChange}
      />
    );
  }
}

HistoryReportsTable.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  sorter: sorterPropTypes.isRequired,
  onSorterChange: PropTypes.func.isRequired,
  onOpenLink: PropTypes.func.isRequired,
};

export default HistoryReportsTable;
