import React from 'react';
import PropTypes from 'prop-types';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InfoIcon from '@material-ui/icons/Info';

import styles from './VerifiedIcon.less';

const VerifiedIcon = ({ verified }) => {
  const icon =
    verified === true ? (
      <VerifiedUserIcon className={styles.verified} />
    ) : verified === false ? (
      <InfoIcon className={styles.notVerified} />
    ) : (
      <RemoveCircleIcon className={styles.indeterminate} />
    );

  return icon;
};

VerifiedIcon.propTypes = {
  verified: PropTypes.bool,
};

export default VerifiedIcon;
