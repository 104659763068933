import React from 'react';
import cn from 'classnames';

import styles from './H.less';

type Props = {
  size: '1' | '2' | '3' | '4'| '5' | '6',
  hr?: boolean,
  bold?: boolean,
  colored?: boolean,
  inline?: boolean,
  inlineBlock?: boolean,
  className?: string
  children?: React.ReactNode,
};

const H: React.FC<Props> = ({
  size,
  hr,
  bold,
  colored,
  inline,
  inlineBlock,
  children,
  className,
  ...rest
}) => {
  const elem = `h${size}`;

  const classNames = cn(
    styles.h,
    styles.block,
    {
      'heading-bold': bold,
      'text-primary': colored,
      [styles.hr]: hr,
      [styles.inline]: inline,
      [styles.inlineBlock]: inlineBlock,
    },
    className,
  );

  return React.createElement(
    elem,
    { className: classNames, ...rest },
    children,
  );
};

H.defaultProps = {
  hr: false,
  bold: false,
  colored: false,
  inline: false,
  inlineBlock: false,
};

export default H;
