import moment from 'moment';

export default (date, originalDate) => {
  const updatedDate = moment(date);
  const localTz = moment(originalDate).format('Z');
  const [hours, minutes] = localTz.split(':');
  if (hours < 0) {
    updatedDate.add(+hours.replace('-', ''), 'hours');
    updatedDate.add(+minutes, 'minutes');
    return updatedDate;
  }
  updatedDate.subtract(+hours.replace('+', ''), 'hours');
  updatedDate.subtract(+minutes, 'minutes');
  return updatedDate;
};
