import React from 'react';
import PropTypes from 'prop-types';

import styles from './StepImage.less';

const StepImage = ({ src }) => (
  <div className={styles.container}>
    <img src={src} className={styles.img} />
  </div>
);

StepImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default StepImage;
