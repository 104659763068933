import { permissions } from '@shared';
import { AssignMbcPage, ROUTE_ASSIGN_MBC } from './mbcAssign';
import { ROUTE_MBC, ManageMbcPage } from './mbcManage';

const routes = {
  [ROUTE_ASSIGN_MBC]: {
    path: '/assignMbc/:userId/:advancementId',
    Component: AssignMbcPage,
    titleId: 'page.assignMbc',
    permission: permissions.EDIT_DATA,
  },
  [ROUTE_MBC]: {
    path: '/meritBadgeCounselors',
    Component: ManageMbcPage,
    titleId: 'page.meritBadgeCounselors',
    permission: permissions.VIEW_MERIT_BADGE_COUNSELORS_PAGE,
  },
};

export default routes;
