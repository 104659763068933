import { combineReducers } from 'redux';

import { SET_LOCALE } from './actions';

const localeReducer = (state = 'en', action = {}) => {
  switch (action.type) {
    case SET_LOCALE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  locale: localeReducer,
});

export default reducer;
