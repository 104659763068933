import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './MainRow.less';

const MainRow = ({ colSpan, CustomMainRow, onSelect, ...props }) => (
  <tr
    className={cn(
      'ant-table-row',
      styles.headerRow,
      'ant-table-row-level-0',
      'customRow',
    )}
  >
    <td>
      {onSelect && (
        <label className="ant-checkbox-wrapper">
          <span className="ant-checkbox">
            <input
              type="checkbox"
              className="ant-checkbox-input"
              onChange={onSelect}
            />
            <span className={cn('ant-checkbox-inner', styles.checkbox)} />
          </span>
        </label>
      )}
    </td>
    <td className={styles.td} colSpan={colSpan}>
      <CustomMainRow {...props} />
    </td>
  </tr>
);

MainRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  CustomMainRow: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
};

export default MainRow;
