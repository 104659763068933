import {
  FETCH_POSITIONS_RESPONSE,
  FETCH_POSITIONS_REQUEST,
  FETCH_POSITIONS_ERROR,
} from './actions';

const isPositionsModalLoading = (state = false, { type }) => {
  switch (type) {
    case FETCH_POSITIONS_REQUEST:
      return true;
    case FETCH_POSITIONS_RESPONSE:
    case FETCH_POSITIONS_ERROR:
      return false;
    default: {
      return state;
    }
  }
};

const leadershipPositionsList = (state = null, { type, payload }) => {
  switch (type) {
    case FETCH_POSITIONS_RESPONSE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export default {
  leadershipPositionsList,
  isPositionsModalLoading,
};
