import { unitGendersTypes } from '../constants';

const isBoy = text => /boy/i.test(text);

const isGirl = text => /girl/i.test(text);

const capitalize = str =>
  `${str.charAt(0)}${str
    .slice(1)
    .split('')
    .map(char => char.toLowerCase())
    .join('')}`;

const shouldCapitalize = (str, isCapitalized) =>
  isCapitalized ? capitalize(str) : str;

const forceGetGender = (text, isCapitalized) => {
  if (isBoy(text) && isGirl(text)) {
    return shouldCapitalize(unitGendersTypes.FAMILY, isCapitalized);
  } else if (isBoy(text)) {
    return shouldCapitalize(unitGendersTypes.BOYS, isCapitalized);
  } else if (isGirl(text)) {
    return shouldCapitalize(unitGendersTypes.GIRLS, isCapitalized);
  }
};

const normalizeUnitGender = (gender, isCapitalized) => {
  switch (gender) {
    case 'BT': // Boys Troop
    case 'BP': {
      // Boys pack
      return shouldCapitalize(unitGendersTypes.BOYS, isCapitalized);
    }
    case 'GT': // Girls troop
    case 'GP': {
      // Girls Pack
      return shouldCapitalize(unitGendersTypes.GIRLS, isCapitalized);
    }
    case 'FP': {
      // Boys and girls pack
      return shouldCapitalize(unitGendersTypes.FAMILY, isCapitalized);
    }
    default: {
      return (
        forceGetGender(gender, isCapitalized) ||
        (gender && unitGendersTypes.FAMILY)
      );
    }
  }
};

export default (input, isCapitalized = false) => {
  if (typeof input === 'string' || input instanceof String) {
    return normalizeUnitGender(input, isCapitalized);
  }
  const { acceptGender, unitDesignationCode } = input || {};
  return normalizeUnitGender(
    acceptGender || unitDesignationCode,
    isCapitalized,
  );
};
