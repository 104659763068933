import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ProgramColors, ProgramLogo, S, UnitGenderPill } from '@shared';

import styles from './RolePickerRole.less';
import RolePickerBaseRole from './components/RolePickerBaseRole';

const RolePickerParentGuardianRole = ({ org, ...props }) => {
  const { connection, unitGender, organizationName, program } = org || {};
  return connection ? (
    <RolePickerBaseRole org={org} {...props}>
      <ProgramColors program={program}>
        <ProgramLogo program={program} className={styles.logo} />
      </ProgramColors>
      <div className={styles.description}>
        <S size="4">
          <FormattedMessage id="context.RolePickerRole.parentGuardian" />
        </S>
        <div className={styles.parentMisc}>
          <S size="5">{organizationName}</S>{' '}
          <UnitGenderPill gender={unitGender} colored splitFamiliLabel />
        </div>
        <S size="5">
          <FormattedMessage
            id="context.RolePickerRole.connection"
            values={{
              person: connection,
            }}
          />
        </S>
      </div>
    </RolePickerBaseRole>
  ) : null;
};

RolePickerParentGuardianRole.propTypes = {
  org: PropTypes.object.isRequired,
};

export default RolePickerParentGuardianRole;
