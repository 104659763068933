import { createSelector } from 'reselect';

import { advancementTypes } from '@shared';
import { eligibleAdvancementTypesSel, boyScoutAdvancementTypes } from '@unit';
import { eagleRankId } from '../../constants';
import {
  advancementTypeSel,
  advancementIdSel,
  completionDateSel,
  advancementVerificationLoadingSel,
  selectedSuggestedYouthIdsSel,
  verifyTimeoutErrorSel,
  additionalAdvancementTypeSel,
  additionalAdvancementIdSel,
  additionalCompletionDateSel,
  additionalVerifyTimeoutErrorSel,
  additionalAdvancementVerificationLoadingSel,
  selectedAdditionalSuggestedYouthIdsSel,
  selectedAdvancementStatusSel,
  selectedAdditionalAdvStatusSel,
} from './core.selectors';
import {
  isAdditionalTabActiveSel,
  suggestedVerifiedYouthSel,
  suggestedAdditionalVerifiedYouthSel,
} from './general.selectors';
import {
  availableAdvancementsSel,
  additionalAvailableAdvancementsSel,
} from './availableAdvancements.selectors';
import {
  eligiblePersonsSel,
  additionalEligiblePersonsSel,
  personsWithWarningsSel,
  additionalpersonsWithWarningsSel,
  suggestedWithWarningsSel,
  additionalSuggestedWithWarningsSel,
} from './eligiblePersons.selectors';

const isEagleRank = ({ advancementType, advancementId }) =>
  advancementType === advancementTypes.RANKS && advancementId == eagleRankId;

export const isEagleRankSelectedSel = createSelector(
  advancementTypeSel,
  advancementIdSel,
  (advancementType, advancementId) =>
    isEagleRank({ advancementId, advancementType }),
);

export const isAdditionalEagleRankSelectedSel = createSelector(
  additionalAdvancementTypeSel,
  additionalAdvancementIdSel,
  (advancementType, advancementId) =>
    isEagleRank({ advancementType, advancementId }),
);

export const primaryTabDataSel = createSelector(
  completionDateSel,
  advancementTypeSel,
  advancementIdSel,
  availableAdvancementsSel,
  isEagleRankSelectedSel,
  advancementVerificationLoadingSel,
  verifyTimeoutErrorSel,
  eligiblePersonsSel,
  suggestedVerifiedYouthSel,
  selectedSuggestedYouthIdsSel,
  personsWithWarningsSel,
  suggestedWithWarningsSel,
  eligibleAdvancementTypesSel,
  selectedAdvancementStatusSel,
  (
    completionDate,
    advancementType,
    advancementId,
    availableAdvancements,
    eagleRankSelected,
    advancementVerificationLoading,
    verifyTimeoutError,
    eligiblePersons,
    suggestedYouth,
    selectedSuggestedYouthIds,
    personsWithWarnings,
    suggestedWithWarnings,
    eligibleAdvancementTypes,
    selectedAdvancementStatus,
  ) => ({
    completionDate,
    advancementType,
    advancementId,
    availableAdvancements,
    eagleRankSelected,
    advancementVerificationLoading,
    verifyTimeoutError,
    eligiblePersons,
    suggestedYouth,
    selectedSuggestedYouthIds,
    personsWithWarnings,
    suggestedWithWarnings,
    eligibleAdvancementTypes,
    selectedAdvancementStatus,
  }),
);

export const additionalTabDataSel = createSelector(
  additionalCompletionDateSel,
  additionalAdvancementTypeSel,
  additionalAdvancementIdSel,
  additionalAvailableAdvancementsSel,
  isAdditionalEagleRankSelectedSel,
  additionalAdvancementVerificationLoadingSel,
  additionalVerifyTimeoutErrorSel,
  additionalEligiblePersonsSel,
  suggestedAdditionalVerifiedYouthSel,
  selectedAdditionalSuggestedYouthIdsSel,
  additionalpersonsWithWarningsSel,
  additionalSuggestedWithWarningsSel,
  selectedAdditionalAdvStatusSel,
  (
    completionDate,
    advancementType,
    advancementId,
    availableAdvancements,
    eagleRankSelected,
    advancementVerificationLoading,
    verifyTimeoutError,
    eligiblePersons,
    suggestedYouth,
    selectedSuggestedYouthIds,
    personsWithWarnings,
    suggestedWithWarnings,
    selectedAdvancementStatus,
  ) => ({
    completionDate,
    advancementType,
    advancementId,
    availableAdvancements,
    eagleRankSelected,
    advancementVerificationLoading,
    verifyTimeoutError,
    eligiblePersons,
    suggestedYouth,
    selectedSuggestedYouthIds,
    personsWithWarnings,
    suggestedWithWarnings,
    eligibleAdvancementTypes: boyScoutAdvancementTypes,
    selectedAdvancementStatus,
  }),
);

export const activeTabDataSel = createSelector(
  isAdditionalTabActiveSel,
  primaryTabDataSel,
  additionalTabDataSel,
  (isAdditionalTabActive, primaryTabData, additionalTabData) =>
    isAdditionalTabActive ? additionalTabData : primaryTabData,
);
