import {
  Program,
  advancementTypes,
  firstClassRankOrder,
  historyItemsTypes,
  ranksOrder,
  syncOperations,
  unapproveLocks,
  venturingCoreAwardsIds,
} from '../constants';
import isEagleScoutRank from './isEagleScoutRank';
import isHierarchicalAdvancement from './isHierarchicalAdvancement';

const isOfflineRecordedAdvancement = ({ operation }) =>
  operation == syncOperations.RECORD_ADVANCEMENT;

const getAdvancementOrder = ({ advancementName, name }) => {
  const realName = advancementName || name;
  return ranksOrder[realName] || -1;
};

const isFirstClassRank = item =>
  getAdvancementOrder(item) === firstClassRankOrder;

export default ({ program, item, advancementsHistory = [] }) => {
  if (isOfflineRecordedAdvancement(item)) {
    return unapproveLocks.IS_OFFLINE_RECORDED;
  } else if (historyItemsTypes.ACTIVITY === item.type) {
    return unapproveLocks.IS_ACTIVITY;
  } else if (!isHierarchicalAdvancement(item)) {
    return null;
  } else if (isEagleScoutRank(item)) {
    return unapproveLocks.IS_EAGLE_SCOUT;
  } else if (
    (program == Program.VENTURING || program == Program.SEA_SCOUT) &&
    isFirstClassRank(item)
  ) {
    return unapproveLocks.IS_FIRST_CLASS;
  }

  const existsHigherLevelAdv = advancementsHistory.some(
    ({ id, userId, advancementType, programId, level }) =>
      item.userId == userId &&
      item.advancementType == advancementType &&
      (advancementType != advancementTypes.AWARDS ||
        venturingCoreAwardsIds.includes(`${id}`)) &&
      item.programId == programId &&
      Number(item.level) < Number(level),
  );

  return existsHigherLevelAdv ? unapproveLocks.LOWER_LEVEL : null;
};
