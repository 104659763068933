import React from 'react';
import PropTypes from 'prop-types';
import { Check, PlayCircleFilled, CheckCircle, Star } from '@material-ui/icons';
import cn from 'classnames';
import {
  NOT_STARTED,
  DISABLED,
  STARTED,
  IN_PROGRESS,
  EARNED,
  COMPLETED,
  COUNSELOR_APPROVED,
  APPROVED,
  LEADER_APPROVED,
  LEADER_SIGNED,
  AWARDED,
  RETIRED,
} from '@bsa/scouting-js-utils/src/constants/advancements';
import styles from './AdvancementStatus.less';

const propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};

const StatusIcon = ({ status = '', className = null }) => {
  switch (status) {
    case AWARDED: {
      return (
        <Star
          className={cn(styles.requirementIcon, styles[status], className)}
        />
      );
    }
    case COUNSELOR_APPROVED:
    case LEADER_APPROVED:
    case LEADER_SIGNED:
    case APPROVED: {
      return (
        <CheckCircle
          className={cn(styles.requirementIcon, styles[status], className)}
        />
      );
    }
    case EARNED:
    case COMPLETED: {
      return (
        <Check
          className={cn(styles.requirementIcon, styles[status], className)}
        />
      );
    }
    case IN_PROGRESS:
    case STARTED: {
      return (
        <PlayCircleFilled
          className={cn(styles.requirementIcon, styles[status], className)}
        />
      );
    }
    case NOT_STARTED:
    case DISABLED:
    case RETIRED:
    default: {
      return null;
    }
  }
};

StatusIcon.propTypes = propTypes;

export default StatusIcon;
