import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/named */
import { Spin as BsaSpin } from 'bsa-ui';

// inline styles to prevent a brief Flash of Unstyled Content
const centeredInViewportStyle = {
  position: 'fixed',
  top: '50%',
  left: '50% ',
  transform: 'translate(-50%, -50%)',
};

/**
 * @type {React.FC<import('./Spin.types').SpinProps>}
 */
const Spin = ({ centeredInViewport, style, ...rest }) => {
  if (centeredInViewport) {
    style = {
      ...centeredInViewportStyle,
      ...style,
    };
  }

  return <BsaSpin style={style} {...rest} />;
};

Spin.propTypes = {
  centeredInViewport: PropTypes.bool,
  style: PropTypes.object,
};

export default Spin;
