import { Observable } from 'rxjs/Observable';
import { zip } from 'rxjs/observable/zip';

import { esbApiService } from '@utils';

const savePicture$ = (personGuid, oldPicture, newPicture) => {
  if (oldPicture === newPicture) {
    return Observable.of(oldPicture);
  }

  const method = oldPicture ? esbApiService.put$ : esbApiService.post$;

  return method(
    `/persons/${personGuid}/profilePicture`,
    {
      picture: newPicture,
    },
    {
      gtm: {
        label: '/persons/{personGuid}/profilePicture',
      },
    },
  ).mapTo(newPicture);
};

const saveProfilePicture$ = ({ personGuid, oldPicture, newPicture }) =>
  zip(savePicture$(personGuid, oldPicture, newPicture));

const getProfile$ = userId =>
  esbApiService.get$(`/persons/v2/${userId}/personprofile`, {
    gtm: {
      label: '/persons/v2/{userId}/personprofile',
    },
  });

const saveProfile$ = (data, personGuid) =>
  esbApiService.put$(`/persons/v2/${personGuid}/profile`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/profile',
    },
  });

const updateSBProfile$ = (data, userId) =>
  esbApiService.put$(`/advancements/users/${userId}`, data, {
    gtm: {
      label: '/advancements/users/{userId}',
    },
  });

const getUserYPTrainingRequest$ = id =>
  esbApiService.get$(`/persons/v2/${id}/trainings/ypt`, {
    gtm: {
      label: '/persons/v2/{personGuid}/trainings/ypt',
    },
  });

const getUsersubscriptionsRequest$ = personGuid =>
  esbApiService.get$(`/persons/${personGuid}/subscriptions`, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions',
    },
  });

const createUsersubscriptions$ = (data, personGuid) =>
  esbApiService.post$(`/persons/${personGuid}/subscriptions`, data, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions',
    },
  });

const updateUsersubscriptions$ = (data, personGuid) =>
  esbApiService.put$(`/persons/${personGuid}/subscriptions/${data.id}`, data, {
    gtm: {
      label: '/persons/{personGuid}/subscriptions/{id}',
    },
  });

const deleteProfileItem$ = (data, personGuid) =>
  esbApiService.post$(`/persons/v2/${personGuid}/deleteProfileInfo`, data, {
    gtm: {
      label: '/persons/v2/{personGuid}/deleteProfileInfo',
    },
  });

export default {
  getProfile$,
  saveProfile$,
  updateSBProfile$,
  getUserYPTrainingRequest$,
  getUsersubscriptionsRequest$,
  createUsersubscriptions$,
  updateUsersubscriptions$,
  deleteProfileItem$,
  saveProfilePicture$,
};
