import React from 'react';

import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setFromProfile } from '@progress/editAdvancement';
import { TableMobile, advancementFileStatuses, sorterPropTypes } from '@shared';

import { activityItemsTypes } from '../../../../common';
import { fieldsVisibilitySel } from '../../../duck';
import styles from './AdvancementHistoryList.less';
import AdvancementHistoryListHeader from './AdvancementHistoryListHeader';
import OptionsFab from './OptionsFab';
import advancementHistoryListRows from './advancementHistoryListRows';

class AdvancementHistoryList extends React.PureComponent {
  getItemClassName = ({ type, status }) => {
    if (activityItemsTypes[type]) {
      return styles.mainTableCard;
    }
    return type === 'advancementFile' &&
      status === advancementFileStatuses.CANCELLED
      ? styles.cancelledFile
      : '';
  };

  handleItemClick = record => {
    const { selectedRowKeys, onSetSelectedRowsKeys } = this.props;
    setFromProfile(false);
    if (
      !activityItemsTypes[record.type] &&
      record.isAdvancementPendingApprovable
    ) {
      const finalSelectedKeys = selectedRowKeys.includes(record.key)
        ? selectedRowKeys.filter(key => key != record.key)
        : [...selectedRowKeys, record.key];
      onSetSelectedRowsKeys(finalSelectedKeys);
    }
  };

  handleSelectAllItems = () => {
    const { selectedRowKeys, onSetSelectedRowsKeys, pendingApprovableItems } =
      this.props;
    const allPendingItemsIds = this.getPendingItemsIds(pendingApprovableItems);
    if (allPendingItemsIds.length !== selectedRowKeys.length) {
      onSetSelectedRowsKeys(allPendingItemsIds);
    } else {
      onSetSelectedRowsKeys([]);
    }
  };

  getPendingItemsIds = memoize(items => items.map(({ key }) => key));

  getSelectedRows = memoize((selectedKeys, items) =>
    items.filter(({ key }) => selectedKeys.includes(key)),
  );

  renderTitle = () => {
    const { items, searchPinned, sorter, onSorterChange } = this.props;

    const totalItemsAmount =
      items[activityItemsTypes.PENDING_APPROVAL].length +
      items[activityItemsTypes.HISTORY].length;

    return (
      <AdvancementHistoryListHeader
        itemsLength={totalItemsAmount}
        searchPinned={searchPinned}
        sorter={sorter}
        onSorterChange={onSorterChange}
      />
    );
  };

  getItemsPresentation = memoize((items, loading) => {
    if (loading) {
      return [];
    }
    return items[activityItemsTypes.HISTORY];
  });

  render() {
    const {
      loading,
      items,
      searchPinned,
      expandedKeys,
      cancellingFiles,
      selectedRowKeys,
      onExpandedKeyChange,
      onCancelFile,
      onDownloadFileErrorLog,
      fieldsVisibility,
      isOffline,
      onGoToYouthProfile,
      onGoToAdultProfile,
      pendingApprovableItems,
    } = this.props;

    const itemsPresentation = this.getItemsPresentation(items, loading);

    const allPendingItemsCount = pendingApprovableItems.length;
    const areAllPendingItemsSelected =
      allPendingItemsCount === selectedRowKeys.length;
    const selectedRows = this.getSelectedRows(
      selectedRowKeys,
      pendingApprovableItems,
    );

    return (
      <div key="contentContainer">
        <TableMobile
          rowId="key"
          itemsClassName={styles.itemsClassName}
          getItemClassName={this.getItemClassName}
          items={itemsPresentation}
          loading={loading}
          title={this.renderTitle()}
          rows={advancementHistoryListRows({
            searchPinned,
            expandedKeys,
            cancellingFiles,
            areAllPendingItemsSelected,
            fieldsVisibility,
            isOffline,
            onExpandedKeyChange,
            onCancelFile,
            onDownloadFileErrorLog,
            onGoToYouthProfile,
            onGoToAdultProfile,
            onSelectedAllItems: this.handleSelectAllItems,
          })}
          selectedKeys={selectedRowKeys}
          onItemClick={this.handleItemClick}
        />
        <OptionsFab
          selectedRows={selectedRows}
          isOffline={isOffline}
          visible={!loading}
        />
      </div>
    );
  }
}

AdvancementHistoryList.propTypes = {
  items: PropTypes.object.isRequired,
  pendingApprovableItems: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  searchPinned: PropTypes.bool.isRequired,
  expandedKeys: PropTypes.array.isRequired,
  sorter: sorterPropTypes,
  isOffline: PropTypes.bool.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  cancellingFiles: PropTypes.object.isRequired,
  fieldsVisibility: PropTypes.object.isRequired,
  onSorterChange: PropTypes.func.isRequired,
  onExpandedKeyChange: PropTypes.func.isRequired,
  onCancelFile: PropTypes.func.isRequired,
  onDownloadFileErrorLog: PropTypes.func.isRequired,
  onSetSelectedRowsKeys: PropTypes.func.isRequired,
  onGoToYouthProfile: PropTypes.func.isRequired,
  onGoToAdultProfile: PropTypes.func.isRequired,
};

const mapState = state => ({
  fieldsVisibility: fieldsVisibilitySel(state),
});

export default connect(mapState)(AdvancementHistoryList);
