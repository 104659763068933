import React, { useCallback } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Row, S } from '@shared/components';

import {
  closeUnmarkAsMultipleModal,
  isLoadingUnmarkAsMultipleSel,
  removeRecharterRosterSelectedKey,
  selectedMultipleMembersSel,
  unmarkAsMultipleRequest,
} from '../../../../duck';
import { PersonTag } from '../../../common';
import styles from './UnmarkAsMultiple.less';

const UnmarkAsMultiple = () => {
  const dispatch = useDispatch();
  const selectedMultipleMembers = useSelector(selectedMultipleMembersSel);
  const isLoadingUnmarkAsMultiple = useSelector(isLoadingUnmarkAsMultipleSel);

  const handleCloseUnmarkAsMultipleModal = () =>
    dispatch(closeUnmarkAsMultipleModal());

  const handleDeselectMember = personId => {
    dispatch(removeRecharterRosterSelectedKey(personId));
  };

  const handleUnMarkAsMultiple = useCallback(() => {
    dispatch(unmarkAsMultipleRequest(selectedMultipleMembers));
  }, [dispatch, selectedMultipleMembers]);

  return (
    <React.Fragment>
      <Row>
        <S size="4" className={styles.greyLabel}>
          <FormattedMessage id="recharterModals.unmarkAsMultipleModal.subTitle" />
        </S>
      </Row>
      <Row className={styles.tagsContainer}>
        {selectedMultipleMembers.map(({ personId, personFullName }) => (
          <PersonTag
            key={`person_tag_${personId}`}
            id={personId}
            isAdult={true}
            name={personFullName}
            onCloseAction={handleDeselectMember}
          />
        ))}
      </Row>
      <Row className={styles.container}>
        <Button
          className={styles.cancelButton}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={true}
          disabled={isLoadingUnmarkAsMultiple}
          color="scouting-warm-gray"
          onClick={handleCloseUnmarkAsMultipleModal}
        >
          <FormattedMessage id="recharterModals.unmarkAsMultipleModal.cancel" />
        </Button>
        <Button
          className={styles.button}
          loading={isLoadingUnmarkAsMultiple}
          type="primary"
          shape="round"
          size="default"
          shadow
          block
          uppercase={true}
          disabled={selectedMultipleMembers.length === 0}
          onClick={handleUnMarkAsMultiple}
        >
          <FormattedMessage id="recharterModals.unmarkAsMultipleModal.confirm" />
        </Button>
      </Row>
    </React.Fragment>
  );
};

export default UnmarkAsMultiple;
