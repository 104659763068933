import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Form, intl, DatePicker } from '@shared';

const FormItem = Form.Item;

const EditableAdvancementForm = ({ form, item }) => {
  const { getFieldDecorator } = form;

  const { previousAdvancementCompletionDate: minDate, date } = item;

  const initialValue = moment(date || undefined);

  const disabledDatesRange = useMemo(
    () => ({
      minDate: minDate ? moment(minDate).toDate() : null,
      maxDate: new Date(),
    }),
    [minDate],
  );

  return (
    <div>
      <FormItem
        label={intl.formatMessage({
          id: 'editAdvancement.EditAdvancementForm.newCompletionDate.label',
        })}
      >
        {getFieldDecorator('completionDate', {
          initialValue,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'editAdvancement.EditAdvancementForm.newCompletionDate.error',
              }),
            },
          ],
        })(
          <DatePicker
            fluid
            size="large"
            placeholder=""
            disableDates={disabledDatesRange}
            label={intl.formatMessage({
              id: 'editAdvancement.EditAdvancementForm.newCompletionDate.label',
            })}
          />,
        )}
      </FormItem>
    </div>
  );
};

EditableAdvancementForm.propTypes = {
  form: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default EditableAdvancementForm;
