import { gtm } from '@shared';

import { moduleName } from '../constants';

export { ROUTE_PROFILE, ROUTE_OWN_ACTIVITY_LOGS } from '@shared';

export { rosterRequestIfNotLoaded as rosterRequest } from '../../advancement';

export const SET_EDIT_PROFILE = `${moduleName}/SET_EDIT_PROFILE`;
export const setEditProfile = edit => ({
  type: SET_EDIT_PROFILE,
  payload: edit,
});

export const SAVE_PROFILE_REQUEST = `${moduleName}/SAVE_PROFILE_REQUEST`;
export const SAVE_PROFILE_RESPONSE = `${moduleName}/SAVE_PROFILE_RESPONSE`;
export const SAVE_PROFILE_ERROR = `${moduleName}/SAVE_PROFILE_ERROR`;
export const SAVE_PROFILE_DEFERRED = `${moduleName}/SAVE_PROFILE_DEFERRED`;
export const saveProfileRequest = profile => ({
  type: SAVE_PROFILE_REQUEST,
  payload: profile,
  gtm: {
    category: gtm.categories.PROFILE,
  },
});
export const saveProfileResponse = () => ({
  type: SAVE_PROFILE_RESPONSE,
});
export const saveProfileError = err => ({
  type: SAVE_PROFILE_ERROR,
  payload: err,
});
export const saveProfileDeferred = () => ({ type: SAVE_PROFILE_DEFERRED });

export const SAVE_AKELA_PROFILE_REQUEST = `${moduleName}/SAVE_AKELA_PROFILE_REQUEST`;
export const SAVE_AKELA_PROFILE_RESPONSE = `${moduleName}/SAVE_AKELA_PROFILE_RESPONSE`;
export const SAVE_AKELA_PROFILE_ERROR = `${moduleName}/SAVE_AKELA_PROFILE_ERROR`;
export const saveAkelaProfileRequest = profiles => ({
  type: SAVE_AKELA_PROFILE_REQUEST,
  payload: profiles,
  gtm: {
    category: gtm.categories.PROFILE,
  },
});
export const saveAkelaProfileResponse = () => ({
  type: SAVE_AKELA_PROFILE_RESPONSE,
});
export const saveAkelaProfileError = err => ({
  type: SAVE_AKELA_PROFILE_ERROR,
  payload: err,
});

export const DELETE_AKELA_EMAIL_REQUEST = `${moduleName}/DELETE_AKELA_EMAI_REQUEST`;
export const DELETE_AKELA_EMAI_RESPONSE = `${moduleName}/DELETE_AKELA_EMAI_RESPONSE`;
export const DELETE_AKELA_EMAI_ERROR = `${moduleName}/DELETE_AKELA_EMAI_ERROR`;
export const deleteAkelaEmailRequest = data => ({
  type: DELETE_AKELA_EMAIL_REQUEST,
  payload: data,
  gtm: {
    category: gtm.categories.PROFILE,
  },
});
export const deleteAkelaEmailResponse = () => ({
  type: DELETE_AKELA_EMAI_RESPONSE,
});
export const deleteAkelaEmailError = err => ({
  type: DELETE_AKELA_EMAI_ERROR,
  payload: err,
});

export const SET_EDIT_UNIT = `${moduleName}/SET_EDIT_UNIT`;
export const setEditUnit = edit => ({ type: SET_EDIT_UNIT, payload: edit });

export const SAVE_UNIT_REQUEST = `${moduleName}/SAVE_UNIT_REQUEST`;
export const SAVE_UNIT_RESPONSE = `${moduleName}/SAVE_UNIT_RESPONSE`;
export const SAVE_UNIT_ERROR = `${moduleName}/SAVE_UNIT_ERROR`;
export const SAVE_UNIT_DEFERRED = `${moduleName}/SAVE_UNIT_DEFERRED`;
export const saveUnitRequest = (unit, successMsg) => ({
  type: SAVE_UNIT_REQUEST,
  payload: { unit, successMsg },
  gtm: {
    category: gtm.categories.PROFILE,
  },
});
export const saveUnitResponse = unit => ({
  type: SAVE_UNIT_RESPONSE,
  payload: unit,
});
export const saveUnitError = err => ({ type: SAVE_UNIT_ERROR, payload: err });
export const saveUnitDeferred = () => ({ type: SAVE_UNIT_DEFERRED });

export const UDPATE_UNIT_PREVIEW = `${moduleName}/UDPATE_UNIT_PREVIEW`;
export const updateUnitPreview = unit => ({
  type: UDPATE_UNIT_PREVIEW,
  payload: unit,
});
