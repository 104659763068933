import React from 'react';

import PropTypes from 'prop-types';

import {
  ProgramColors,
  ProgramLogo,
  ProgramName,
  S,
  UnitGenderPill,
  programForUnitTypeId,
} from '@shared';

import styles from './RolePickerRole.less';
import RolePickerBaseRole from './components/RolePickerBaseRole';

function RolePickerUnitSuggestion({ active, disabled, org, onProfileChange }) {
  return (
    <RolePickerBaseRole
      org={org}
      active={active}
      disabled={disabled}
      onProfileChange={onProfileChange}
    >
      <ProgramColors program={programForUnitTypeId[org.unitTypeId]}>
        <ProgramLogo programId={org.unitTypeId} className={styles.logo} />
      </ProgramColors>
      <div className={styles.description}>
        <S size="4">
          <ProgramName programId={org.unitTypeId} />
        </S>
        <S size="5">
          {org.organizationName}
          <UnitGenderPill gender={org.unitGender} colored splitFamiliLabel />
        </S>
      </div>
    </RolePickerBaseRole>
  );
}

RolePickerUnitSuggestion.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  org: PropTypes.object.isRequired,
  onProfileChange: PropTypes.func.isRequired,
};

export default RolePickerUnitSuggestion;
