import { createSelector } from 'reselect';

import { ProgramId, ranksOrder } from '@shared';

import { advancementModuleSel, createPersonsSelector } from '../../../common';

const firstClassRankLevel = 11;

const moduleSel = state => advancementModuleSel(state);
const userIdsSel = state => moduleSel(state).userIds;
export const personsSel = createPersonsSelector(userIdsSel);

const personsOtherRanksSel = createSelector(personsSel, persons =>
  persons.map(({ userId, otherHighestRanksApproved }) => ({
    userId,
    otherHighestRanksApproved,
  })),
);

export const personsHighestBoyScoutRankSel = createSelector(
  personsOtherRanksSel,
  personsOtherRanks =>
    personsOtherRanks.reduce(
      (boyScoutRanks, { userId, otherHighestRanksApproved }) => {
        const boyScoutRank = otherHighestRanksApproved.find(
          ({ unitTypeId }) => unitTypeId == ProgramId.BOY_SCOUT,
        );

        if (boyScoutRank) {
          return [...boyScoutRanks, { userId, rank: boyScoutRank }];
        }
        return boyScoutRanks;
      },
      [],
    ),
);

export const personsMinRankFirstClassRankSel = createSelector(
  personsHighestBoyScoutRankSel,
  personsBoyScoutRanks =>
    personsBoyScoutRanks
      .filter(
        ({ rank: scoutRanks }) =>
          ranksOrder[scoutRanks.rank] >= firstClassRankLevel,
      )
      .map(({ userId }) => userId),
);
