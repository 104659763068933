/* eslint-disable react/display-name */

/* eslint-disable react/no-multi-comp */
import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button, DateView, S } from '@shared/components';

import styles from './HistoryReportsList.less';

function columns(onOpenLink) {
  return function historyReportsRows(item) {
    return [
      {
        rowId: 'ReportName',
        label: (
          <S size="4" bold>
            <FormattedMessage id="reports.HistoryReports.column.name" />
          </S>
        ),
        content: (
          <S size="4" bold colored>
            <FormattedMessage
              id="reports.HistoryReports.reportName"
              values={{ poid: item.POID }}
            />
          </S>
        ),
      },
      {
        rowId: 'DateCreated',
        label: (
          <S size="4" bold>
            <FormattedMessage id="reports.HistoryReports.column.createdOn" />
          </S>
        ),
        content: (
          <S size="4">
            <DateView value={item.DateCreated} utc />
          </S>
        ),
      },
      {
        rowId: 'recordedBy',
        label: (
          <S size="4" bold>
            <FormattedMessage id="reports.HistoryReports.column.recordedBy" />
          </S>
        ),
        content: <S size="4"> {item.recordedBy}</S>,
      },
      {
        rowId: 'edit',
        className: styles.editRow,
        content: (
          <Button
            ghost
            noBorder
            fitText
            color="info"
            className="qa_download"
            onClick={() => onOpenLink(item.ReportURI)}
          >
            <FormattedMessage id="reports.HistoryReports.column.download" />
          </Button>
        ),
      },
    ];
  };
}

export default columns;
