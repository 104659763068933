import type { MomentInput } from 'moment';
import moment from 'moment';

const ESB_API_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Formats to an ESB Date format.
 *
 * *__Note:__ no time will be included*
 *
 * @param date  It can be a string, Date or a Moment value
 * @returns
 */
export function formatToAPIDate(date: MomentInput): string {
  return moment(date).format(ESB_API_DATE_FORMAT);
}

export function formatToReportsDate(date: MomentInput): string {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}

export const parseApiDate = (date: string) => moment(date, ESB_API_DATE_FORMAT);
