import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  S,
  DateView,
  Currency,
  PersonAvatar,
  AdvancementType,
  Img,
} from '@shared';
import styles from './AdvancementReportList.less';

function advancementReportRows(showPrice) {
  return function columns(item) {
    const { youth, price, advancementType, name, imageUrl100 } = item;
    return [
      {
        rowId: 'header',
        label: <PersonAvatar isAdult={false} />,
        content: (
          <S size="4" bold colored>
            {youth.shortName}
          </S>
        ),
      },
      {
        rowId: 'dateApproved',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancementReport.AdvancementReport.column.dateCompleted" />
          </S>
        ),
        content: <DateView value={youth.dateCompletedEarned} />,
      },
      ...(showPrice
        ? [
            {
              rowId: 'price',
              label: (
                <S size="4" bold>
                  <FormattedMessage id="advancementReport.AdvancementReport.column.price" />
                </S>
              ),
              content: <Currency value={price} />,
            },
          ]
        : []),
      {
        rowId: 'type',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancementReport.AdvancementReport.column.type" />
          </S>
        ),
        content: <AdvancementType type={advancementType} />,
      },
      {
        rowId: 'item',
        label: (
          <S size="4" bold>
            <FormattedMessage id="advancementReport.AdvancementReport.column.item" />
          </S>
        ),
        content: (
          <span>
            {imageUrl100 && (
              <Img className={styles.advancementImage} src={imageUrl100} />
            )}
            {name}
          </span>
        ),
      },
    ];
  };
}

export default advancementReportRows;
