import React from 'react';

import cn from 'classnames';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { reduce } from 'lodash';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import S from '../S';
import styles from './EditorTextArea.less';

const MARKUP_REPLACEMENTS = {
  '': "'",
  '': "'",
};

type Props = {
  onChange?: (value: string) => void;
  toolbar?: Record<string, unknown>;
  spellCheck?: boolean;
  value?: string;
  defaultValue?: string;
  charCounter?: boolean;
  charCounterBelow?: boolean;
  maxLength?: boolean;
  disabled?: boolean;
  placeholder?: string;
  id?: string;
  noBorder?: boolean;
};

// TODO: convert from class component to functional component
class EditorTextArea extends React.Component<Props> {
  state = {
    value: this.props.defaultValue,
    editorState: EditorState.createEmpty(),
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.value && !prevProps.value) {
      this.updateContentState(this.getValue());
    }
  }

  convertMarkup(markup: string) {
    return reduce(
      MARKUP_REPLACEMENTS,
      (memo, to, from) => memo.replaceAll(from, to),
      markup,
    );
  }

  getValue = () => (this.isControlled() ? this.props.value : this.state.value);

  updateContentState = (value = '') => {
    const contentBlock = htmlToDraft(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }
  };

  isControlled = () => this.props.value != null;

  handleChange = (editorState: EditorState) => {
    const markup = this.convertMarkup(
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );

    if (!this.isControlled()) {
      this.setState({
        value: markup,
        editorState,
      });
    } else {
      this.setState({
        editorState,
      });
    }
    if (this.props.onChange) {
      this.props.onChange(markup);
    }
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      charCounter,
      charCounterBelow,
      maxLength,
      spellCheck,
      toolbar,
      disabled,
      noBorder,
      ...rest
    } = this.props;
    const { editorState } = this.state;
    const value = this.getValue();
    const charCount = (value || '').length;
    const textAreaNode = (
      <div
        className={cn(styles.editor, {
          [styles.disabled]: disabled,
          [styles.noBorder]: noBorder,
        })}
      >
        <Editor
          spellCheck={spellCheck}
          editorState={editorState}
          onEditorStateChange={this.handleChange}
          stripPastedStyles
          readOnly={disabled}
          toolbarClassName={disabled ? styles.toolbarClassName : undefined}
          toolbar={{
            options: [
              'inline',
              'list',
              'textAlign',
              'link',
              'image',
              'history',
            ],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered', 'indent', 'outdent'],
            },
            textAlign: {
              inDropdown: false,
              options: ['left', 'center', 'right', 'justify'],
            },
            ...toolbar,
            image: {
              popupClassName: styles.image,
              uploadEnabled: false,
              alignmentEnabled: false,
            },
            link: {
              popupClassName: styles.link,
              defaultTargetOption: '_blank',
            },
          }}
        />
      </div>
    );

    if (charCounter) {
      return (
        <div className={styles.container}>
          <S size="5" className={styles.charCount}>
            {charCount}/{maxLength}
          </S>
          {textAreaNode}
        </div>
      );
    } else if (charCounterBelow) {
      return (
        <div className={styles.container}>
          <label htmlFor={rest.id}>{rest.placeholder}</label>
          {textAreaNode}
          <S size="5" className={styles.charCountBelow}>
            {charCount}/{maxLength}
          </S>
        </div>
      );
    }

    return textAreaNode;
  }
}

export default EditorTextArea;
