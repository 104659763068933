import { createSelector } from 'reselect';

import { userDataSel, safeLoginDataSel } from '../../user';
import { moduleName } from '../constants';

const moduleSel = state => state[moduleName];

export const usernameSel = state => safeLoginDataSel(state).account.username;
export const emailSel = createSelector(userDataSel, userData => {
  const { emails = [] } = userData;
  if (emails.length == 0) {
    return null;
  }

  const primaryEmail = emails.find(email => email.isPrimary);
  return primaryEmail || emails[0];
});

export const enableForumLoadingSel = state =>
  moduleSel(state).enableForumLoading;
export const forumEnabledSel = state => moduleSel(state).forumEnabled;
export const forumCategorySlugSel = state => moduleSel(state).forumCategorySlug;
export const syncForumLoadingSel = state => moduleSel(state).syncForumLoading;
export const getTimezonesSel = state => moduleSel(state).getTimezones;
export const getUnitDetailsSel = state => moduleSel(state).getUnitDetails;
export const isLoadingSetTimezoneSel = state =>
  moduleSel(state).isLoadingSetTimezone;
export const isLoadingGetTimezoneSel = state =>
  moduleSel(state).isLoadingGetTimezone;
export const isLoadingGetUnitDetailsSel = state =>
  moduleSel(state).isLoadingGetUnitDetails;

export const unitTimeZoneCodeSel = createSelector(
  getUnitDetailsSel,
  unitDetails => {
    const { unitTimeZoneCode } = unitDetails;

    return unitTimeZoneCode || '';
  },
);

export const currentUnitIdSel = createSelector(
  getUnitDetailsSel,
  unitDetails => {
    const { id } = unitDetails;

    return id || '';
  },
);
