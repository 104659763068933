import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

import styles from './SlideFadeTransition.less';

const SlideFadeTransition = ({ children, ...props }) => (
  <Transition
    {...props}
    timeout={200}
    onEnter={node => node.classList.add(styles.slideFadeIn, styles.animate)}
    onExit={node => {
      node.classList.remove(styles.slideFadeIn, styles.animate);
      node.classList.add(styles.slideFadeOut, styles.animate);
    }}
  >
    {children}
  </Transition>
);

SlideFadeTransition.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SlideFadeTransition;
