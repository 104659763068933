import React, { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { organizationGuidSel } from '@context';
import { parentOrgGuidSel } from '@shared/duck';
import { openBasicReport } from '@utils';

import { SingleReport } from '../../common';
import {
  basicReportsRequest,
  loadingSel,
  openSelectYouthModal,
  reportsSel,
} from '../duck';
import styles from './BasicReports.less';
import SelectYouthModal from './SelectYouthModal';

const BasicReports = () => {
  const dispatch = useDispatch();
  const reports = useSelector(reportsSel);
  const parentOrganizationGuid = useSelector(parentOrgGuidSel);
  const organizationGuid = useSelector(organizationGuidSel);
  const isLoading = useSelector(loadingSel);
  useEffect(() => {
    if (parentOrganizationGuid && !isLoading) {
      dispatch(basicReportsRequest());
    }
  }, [dispatch, isLoading, parentOrganizationGuid]);

  const handleClick = useCallback(
    report => () => {
      const { ReportURI, isIndividual, reportCode } = report;
      isIndividual
        ? dispatch(openSelectYouthModal(reportCode))
        : openBasicReport(ReportURI, { organizationGuid });
    },
    [dispatch, organizationGuid],
  );

  const reportsList = useMemo(
    () =>
      reports.map(report => (
        <SingleReport
          key={report.id}
          name={report.ReportName}
          description={report.description}
          report={report}
          onClick={handleClick(report)}
        />
      )),
    [reports, handleClick],
  );

  return (
    <div className={styles.content}>
      <SelectYouthModal />
      <div className={styles.reportsList}>{reportsList}</div>
    </div>
  );
};

export default BasicReports;
