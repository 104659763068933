import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ModeEditIcon from 'material-ui-icons/ModeEdit';

import { Button, Dropdown, Menu } from '@shared';
import styles from './DetailModeButtons.less';

const MenuItem = Menu.Item;

function EditButton({ isRecurring, onEditClick, onEditSeriesClick }) {
  if (isRecurring) {
    const options = (
      <Menu>
        <MenuItem key="1">
          <div onClick={onEditClick}>
            <FormattedMessage id="progress.DetailModeButtons.editThisOccurence" />
          </div>
        </MenuItem>
        <MenuItem key="2">
          <div onClick={onEditSeriesClick}>
            <FormattedMessage id="progress.DetailModeButtons.editThisAndFutureOccurences" />
          </div>
        </MenuItem>
      </Menu>
    );

    return (
      <Dropdown trigger={['click']} placement="bottomRight" overlay={options}>
        <Button
          fitText
          ghost
          noBorder
          size="small"
          color="info"
          onClick={onEditClick}
        >
          <ModeEditIcon className={styles.icon} />
          <FormattedMessage id="progress.DetailModeButtons.edit" />
        </Button>
      </Dropdown>
    );
  }

  return (
    <Button
      fitText
      ghost
      noBorder
      size="small"
      color="info"
      onClick={onEditClick}
    >
      <ModeEditIcon className={styles.icon} />
      <FormattedMessage id="progress.DetailModeButtons.edit" />
    </Button>
  );
}

EditButton.propTypes = {
  isRecurring: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onEditSeriesClick: PropTypes.func,
};

export default EditButton;
