import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import './TransitionReplace.less';

const TransitionReplace = ({
  transitionName,
  transitionEnterTimeout,
  transitionLeaveTimeout,
  children,
  ...rest
}) => (
  <ReactCSSTransitionReplace
    transitionName={transitionName}
    transitionEnterTimeout={transitionEnterTimeout}
    transitionLeaveTimeout={transitionLeaveTimeout}
    {...rest}
  >
    {children}
  </ReactCSSTransitionReplace>
);

TransitionReplace.propTypes = {
  transitionName: PropTypes.string,
  transitionEnterTimeout: PropTypes.number,
  transitionLeaveTimeout: PropTypes.number,
  // children MUST have a key prop and CAN NOT be an array (fragment) for this component to work
  children: PropTypes.node.isRequired,
};

TransitionReplace.defaultProps = {
  transitionName: 'transition-replace-fade',
  transitionEnterTimeout: 250,
  transitionLeaveTimeout: 150,
};

export default TransitionReplace;
