import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { SingleConfirmationModal } from '@shared';

import BoatIcon from '@assets/images/Boat.svg';
import { ProgressButton } from '../../../common';
import { openRecordDays } from '../../duck';

const RecordLongCruiseButton = ({
  onClick,
  disabled,
  className,
  userIds,
  askForConfirmation,
  disableConfirmBtn,
  confirmationMessage,
  onCancel,
  onConfirm,
  replaceButton,
  disableGetActivities,
  disableFutureDays,
  allowRemovePersons,
  children,
}) => {
  const dispatch = useDispatch();
  const confirmModalRef = useRef();
  const onOpenRecordDays = useCallback(() => {
    dispatch(
      openRecordDays(
        userIds,
        disableGetActivities,
        disableFutureDays,
        allowRemovePersons,
      ),
    );
    onClick && onClick();
  }, [
    dispatch,
    onClick,
    userIds,
    disableFutureDays,
    disableGetActivities,
    allowRemovePersons,
  ]);

  const openConfirmationModal = useCallback(() => {
    confirmModalRef.current.openConfirmationModal();
  }, [confirmModalRef]);

  const onClickAction = useCallback(() => {
    if (askForConfirmation) {
      openConfirmationModal();
    } else {
      onOpenRecordDays();
    }
  }, [askForConfirmation, openConfirmationModal, onOpenRecordDays]);

  const handleConfirm = useCallback(() => {
    onOpenRecordDays();
    onConfirm && onConfirm();
  }, [onConfirm, onOpenRecordDays]);

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  const button = replaceButton ? (
    React.cloneElement(replaceButton, { onClick: onClickAction })
  ) : (
    <ProgressButton
      noBorder
      fitText
      disabled={disabled}
      icon={<BoatIcon />}
      className={className}
      onClick={onClickAction}
      color="scouting-warm-gray"
    >
      {children || <FormattedMessage id="progress.longCruise.label" />}
    </ProgressButton>
  );

  return (
    <React.Fragment>
      {askForConfirmation && (
        <SingleConfirmationModal
          ref={confirmModalRef}
          disableConfirm={disableConfirmBtn}
          confirmBtnText={<FormattedMessage id="shared.confirm" />}
          onCancel={handleCancel}
          message={confirmationMessage}
          onConfirm={handleConfirm}
        />
      )}
      {button}
    </React.Fragment>
  );
};

const validateIfAskForConfirm =
  validator =>
  (props, ...args) => {
    if (props.askForConfirmation) {
      return validator(props, ...args);
    }
  };

RecordLongCruiseButton.propTypes = {
  userIds: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  askForConfirmation: PropTypes.bool,
  children: PropTypes.node,
  disableGetActivities: PropTypes.bool,
  disableFutureDays: PropTypes.bool,
  allowRemovePersons: PropTypes.bool,
  replaceButton: PropTypes.node,
  disableConfirmBtn: validateIfAskForConfirm(PropTypes.bool),
  confirmationMessage: validateIfAskForConfirm(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  ),
  onCancel: validateIfAskForConfirm(PropTypes.func),
  onConfirm: validateIfAskForConfirm(PropTypes.func),
};

RecordLongCruiseButton.defaultProps = {
  disabled: false,
};

export default RecordLongCruiseButton;
