import { combineReducers } from 'redux';

import { resetOnLogoutReducer } from '@utils';

import {
  GET_EVENT_ERROR,
  GET_EVENT_REQUEST,
  GET_EVENT_RESPONSE,
  GET_PERSON_UNITS_ERROR,
  GET_PERSON_UNITS_REQUEST,
  GET_PERSON_UNITS_RESPONSE,
  GET_UNIT_EVENTS_ERROR,
  GET_UNIT_EVENTS_REQUEST,
  GET_UNIT_EVENTS_RESPONSE,
  POST_USER_PAYMENT_LOGS_ERROR,
  POST_USER_PAYMENT_LOGS_REQUEST,
  POST_USER_PAYMENT_LOGS_RESPONSE,
  PUT_USER_PAYMENT_LOGS_ERROR,
  PUT_USER_PAYMENT_LOGS_REQUEST,
  PUT_USER_PAYMENT_LOGS_RESPONSE,
  USER_PAYMENT_LOGS_ERROR,
  USER_PAYMENT_LOGS_REQUEST,
  USER_PAYMENT_LOGS_RESPONSE,
} from './actions';
import { sliceReducer } from './slice';

const balanceDetails = (state = false, { type, payload }) => {
  switch (type) {
    case USER_PAYMENT_LOGS_RESPONSE: {
      return payload.balanceDetails;
    }
    default: {
      return state;
    }
  }
};

const event = (state = {}, { type, event }) => {
  switch (type) {
    case GET_EVENT_REQUEST: {
      return {};
    }
    case GET_EVENT_RESPONSE: {
      return event;
    }
    default: {
      return state;
    }
  }
};

const loading = (state = false, { type }) => {
  switch (type) {
    case USER_PAYMENT_LOGS_REQUEST: {
      return true;
    }
    case USER_PAYMENT_LOGS_RESPONSE:
    case USER_PAYMENT_LOGS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loadingEvent = (state = false, { type }) => {
  switch (type) {
    case GET_EVENT_REQUEST: {
      return true;
    }
    case GET_EVENT_RESPONSE:
    case GET_EVENT_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loadingPersonUnits = (state = false, { type }) => {
  switch (type) {
    case GET_PERSON_UNITS_REQUEST: {
      return true;
    }
    case GET_PERSON_UNITS_RESPONSE:
    case GET_PERSON_UNITS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const loadingUnitEvents = (state = false, { type }) => {
  switch (type) {
    case GET_UNIT_EVENTS_REQUEST: {
      return true;
    }
    case GET_UNIT_EVENTS_RESPONSE:
    case GET_UNIT_EVENTS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const paymentLogs = (state = [], { type, payload }) => {
  switch (type) {
    case USER_PAYMENT_LOGS_RESPONSE: {
      return payload.paymentLogs;
    }
    default: {
      return state;
    }
  }
};

const personUnits = (state = false, { units, type }) => {
  switch (type) {
    case GET_PERSON_UNITS_REQUEST: {
      return [];
    }
    case GET_PERSON_UNITS_RESPONSE: {
      return units;
    }
    default: {
      return state;
    }
  }
};

const postingUserPaymentLogs = (state = false, { type }) => {
  switch (type) {
    case POST_USER_PAYMENT_LOGS_REQUEST: {
      return true;
    }
    case POST_USER_PAYMENT_LOGS_RESPONSE:
    case POST_USER_PAYMENT_LOGS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const unitEvents = (state = [], { type, unitEvents }) => {
  switch (type) {
    case GET_UNIT_EVENTS_REQUEST: {
      return [];
    }
    case GET_UNIT_EVENTS_RESPONSE: {
      return unitEvents;
    }
    default: {
      return state;
    }
  }
};

const updatingUserPaymentLog = (state = false, { type }) => {
  switch (type) {
    case PUT_USER_PAYMENT_LOGS_REQUEST: {
      return true;
    }
    case PUT_USER_PAYMENT_LOGS_RESPONSE:
    case PUT_USER_PAYMENT_LOGS_ERROR: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const reducer = combineReducers({
  balanceDetails,
  event,
  loading,
  loadingEvent,
  loadingPersonUnits,
  loadingUnitEvents,
  paymentLogs,
  personUnits,
  postingUserPaymentLogs,
  unitEvents,
  updatingUserPaymentLog,
  sliceReducer,
});

export default resetOnLogoutReducer(reducer);
