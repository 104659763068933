import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styles from './ProfileOARank.less';
import member from '@assets/images/OA_Seal_FullColor.png';
import ordeal from '@assets/images/OA Sash Arrow_Ordeal.png';
import bhood from '@assets/images/OA Sash Arrow_Bhood.png';
import vigil from '@assets/images/OA Sash Arrow_Vigil.png';

import { isValueTruthy } from '../../utils';
import S from '../S';

const oaRanks = {
  MEMBER: 'member',
  ORDEAL: 'ordeal',
  BHOOD: 'bhood',
  VIGIL: 'vigil',
};

const ProfileOARank = ({ profile, isLoading = false }) => {
  const { oaBrotherhoodDate, oaMember, oaOrdealDate, oaVigilDate } = profile;

  const isOA = () => isValueTruthy(oaMember);

  const getOARank = () => {
    let rank = oaRanks.MEMBER;

    if (oaOrdealDate) {
      rank = oaRanks.ORDEAL;
    }

    if (oaBrotherhoodDate) {
      rank = oaRanks.BHOOD;
    }

    if (oaVigilDate) {
      rank = oaRanks.VIGIL;
    }

    return rank;
  };

  const getImgSrc = rank => {
    switch (rank) {
      case oaRanks.MEMBER:
        return member;
      case oaRanks.ORDEAL:
        return ordeal;
      case oaRanks.BHOOD:
        return bhood;
      case oaRanks.VIGIL:
        return vigil;

      default:
        return member;
    }
  };

  if (isLoading || !isOA()) {
    return null;
  }

  const oaRank = getOARank();

  return (
    <div className={styles.container}>
      <div className={oaRank === oaRanks.MEMBER ? '' : styles.arrowContainer}>
        <img
          src={getImgSrc(oaRank)}
          className={oaRank === oaRanks.MEMBER ? styles.seal : styles.arrow}
        />
      </div>
      <div className={styles.subtitleContainer}>
        <S size="4">
          <FormattedMessage id={`profile.ProfileOARank.${oaRank}`} />
        </S>
      </div>
    </div>
  );
};

ProfileOARank.propTypes = {
  isLoading: PropTypes.bool,
  profile: PropTypes.object.isRequired,
};

export default ProfileOARank;
