import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { typeSel } from '@location';
import { MembershipExpirationModalContainer } from '@modules/userNotifications/components/MembershipExpiration/MembershipExpirationModal';
import { Spin } from '@shared';
import { InactivityWatcher, TimeoutModal, isLoggedInSel } from '@user';

import { appBootstrappedSel, updatingAppSel } from '../../duck';
import routes from '../../routes';
import Layout from '../Layout';

const RouteSwitcher = ({ page, appBootstrapped, isUpdatingApp }) => {
  const { Component, standalone } = routes[page];

  if (isUpdatingApp) {
    return <Spin size="large" centeredInViewport />;
  } else if (standalone) {
    return <Component />;
  } else if (appBootstrapped) {
    return (
      <Layout>
        <div key={page}>
          <InactivityWatcher />
          <TimeoutModal />
          <MembershipExpirationModalContainer />
          <Component />
        </div>
      </Layout>
    );
  }

  return <Spin size="large" centeredInViewport />;
};

RouteSwitcher.propTypes = {
  page: PropTypes.string,
  appBootstrapped: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isUpdatingApp: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  page: typeSel(state),
  appBootstrapped: appBootstrappedSel(state),
  isLoggedIn: isLoggedInSel(state),
  isUpdatingApp: updatingAppSel(state),
});

export default connect(mapStateToProps)(RouteSwitcher);
