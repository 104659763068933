import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { FilterButton, sorterPropTypes } from '@shared';
import AdvancementHistoryFilterCard from '../AdvancementHistoryFilterCard';

const AdvancementHistoryTableFilter = ({ sorter, onSorterChange }) => (
  <AdvancementHistoryFilterCard sorter={sorter} onSorterChange={onSorterChange}>
    {lastUpdatedDate => (
      <FilterButton id="qa_filters">
        <FormattedMessage
          id={`advancement.AdvancementHistory.filter.option.${lastUpdatedDate}`}
        />
      </FilterButton>
    )}
  </AdvancementHistoryFilterCard>
);

AdvancementHistoryTableFilter.propTypes = {
  sorter: sorterPropTypes,
  onSorterChange: PropTypes.func.isRequired,
};

export default AdvancementHistoryTableFilter;
