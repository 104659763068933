import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '@shared/components';
import { intl } from '@shared/localization';

import { steps } from '../../../constants';
import {
  closeUnitPaymentErrorModal,
  isUnitPaymentErrorModalVisibleSel,
  setCurrentStep,
} from '../../../duck';
import UnitPaymentError from './UnitPaymentError';
import styles from './UnitPaymentErrorModal.less';

const UnitPaymentErrorModal = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isUnitPaymentErrorModalVisibleSel);

  const handleOnCancel = () => {
    dispatch(closeUnitPaymentErrorModal());
  };

  const handleCloseUnitPaymentErrorModal = () => {
    handleOnCancel();
    dispatch(setCurrentStep(steps.RECHARTER_INFO));
  };

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      title={intl.formatMessage({
        id: 'recharterModals.unitPaymentModal.title',
      })}
      onCancel={handleCloseUnitPaymentErrorModal}
      maskClosable={false}
    >
      <UnitPaymentError onCancel={handleOnCancel} />
    </Modal>
  );
};

export default UnitPaymentErrorModal;
