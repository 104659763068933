import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import memoize from 'memoize-one';
import cn from 'classnames';
import { Table as BsaTable } from 'bsa-ui';

import { getItemsPresentation } from './helpers';
import CustomRow from './CustomRow';
import { personGuidSel, tablePageSizeSel, setTablePageSize } from '../../duck';
import styles from './Table.less';

const locale = { emptyText: <FormattedMessage id="shared.Table.noData" /> };

class Table extends React.PureComponent {
  state = {
    activePage: 1,
  };

  componentDidUpdate({ sorter: prevSorter }) {
    const { sorter: currentSorter } = this.props;

    if (
      prevSorter.columnKey !== currentSorter.columnKey &&
      prevSorter.order !== currentSorter.order
    ) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ activePage: 1 });
    }
  }

  preparePagination = pagination => {
    if (pagination) {
      if (this.props.loading) {
        this.setState({ activePage: 1 });
      }
      const { pageSize } = this.props;
      const { activePage } = this.state;

      return {
        current: activePage,
        showSizeChanger: true,
        pageSizeOptions: ['10', '25', '50', '75', '100'],
        pageSize,
        onShowSizeChange: this.handleShowSizeChange,
        onChange: this.handlePageChange,
        ...pagination,
      };
    }

    return pagination;
  };

  handleShowSizeChange = (_, pageSize) => {
    this.props.onSetPageSize(pageSize, this.props.personGuid);
  };

  handlePageChange = page => {
    this.setState({ activePage: page });
  };

  getCustomRow = () => {
    const { mainRows } = this.props;
    if (!mainRows) {
      return undefined;
    }
    return { body: { row: CustomRow } };
  };

  itemsTransformation = memoize((items, CustomMainRow, colSpan) =>
    getItemsPresentation(
      items.map(item => ({ ...item, CustomMainRow, colSpan })),
    ),
  );

  render() {
    const {
      headerColor,
      rowPadding,
      fixedLayout,
      clickableRows,
      showExpandRowColumn,
      expandedRowPadding,
      expandedRowKeys,
      expandedRowRender,
      border,
      className,
      pagination,
      dataSource,
      mainRows,
      ...otherProps
    } = this.props;
    const classNames = cn(
      className,
      styles.table,
      styles[`header-${headerColor}`],
      styles[`rowPadding_${rowPadding}`],
      {
        [styles.withHiddenExpandColumn]:
          !!expandedRowRender && !showExpandRowColumn,
        [styles.fixedLayout]: fixedLayout,
        [styles.clickableRows]: clickableRows,
        [styles.hideExpandRowColumn]: !showExpandRowColumn,
        [styles.expandedRowNoPad]: !expandedRowPadding,
        [styles.noBorder]: !border,
      },
    );

    // Table can mutate this array, so if we pass in a value from redux store,
    // we can end up with an error (can't mutate store values)
    // so here we're copying the array to avoid this
    if (expandedRowKeys) {
      otherProps.expandedRowKeys = [...expandedRowKeys];
    }

    const itemsPresentation = mainRows
      ? this.itemsTransformation(
          dataSource,
          mainRows.CustomMainRow,
          mainRows.colSpan,
        )
      : dataSource;

    return (
      <BsaTable
        className={classNames}
        locale={locale}
        expandedRowRender={expandedRowRender}
        pagination={this.preparePagination(pagination)}
        dataSource={itemsPresentation}
        components={this.getCustomRow()}
        {...otherProps}
      />
    );
  }
}

Table.propTypes = {
  // provided by parent
  ...BsaTable.propTypes,
  className: PropTypes.string,
  headerColor: PropTypes.oneOf([
    'scouting-red',
    'scouting-warm-gray',
    'gray-5',
    'gray-1',
  ]),
  rowPadding: PropTypes.oneOf(['large', 'medium', 'small']),
  fixedLayout: PropTypes.bool,
  clickableRows: PropTypes.bool,
  showExpandRowColumn: PropTypes.bool,
  expandedRowPadding: PropTypes.bool,
  expandedRowRender: PropTypes.func,
  border: PropTypes.bool,
  sorter: PropTypes.object,
  mainRows: PropTypes.shape({
    CustomMainRow: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
      .isRequired,
    colSpan: PropTypes.number.isRequired,
  }),
  // provided by component itself
  pageSize: PropTypes.number.isRequired,
  onSetPageSize: PropTypes.func.isRequired,
  personGuid: PropTypes.string.isRequired,
};

Table.defaultProps = {
  headerColor: 'scouting-red',
  rowPadding: 'medium',
  fixedLayout: true,
  showExpandRowColumn: false,
  expandedRowPadding: false,
  border: true,
  sorter: {},
};

const mapState = state => ({
  pageSize: tablePageSizeSel(state),
  personGuid: personGuidSel(state),
});

const mapDispatch = dispatch => ({
  onSetPageSize: (pageSize, personGuid) =>
    dispatch(setTablePageSize(pageSize, personGuid)),
});

export default connect(mapState, mapDispatch)(Table);
