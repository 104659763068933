import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import WarningIcon from '@material-ui/icons/Warning';

import { gtm, sentryReport } from '../../utils';
import styles from './ChunkErrorBoundary.less';

const FallbackComponent = () => (
  <div className={styles.container}>
    <WarningIcon className={styles.icon} />
    <span>
      You have encountered a problem with this feature. Please clear your cache
      and try again. If this does not resolve your issue, please contact your
      council.
    </span>
  </div>
);

// eslint-disable-next-line react/no-multi-comp
class ChunkErrorBoundary extends React.Component {
  handleError = error => {
    sentryReport.ui(error);
    gtm.pushEvent({
      event: 'APP_CHUNK_ERROR',
      category: gtm.categories.APP_CRASH,
    });
  };

  render() {
    const { children } = this.props;
    return (
      <ReactErrorBoundary
        FallbackComponent={FallbackComponent}
        onError={this.handleError}
      >
        {children}
      </ReactErrorBoundary>
    );
  }
}

ChunkErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChunkErrorBoundary;
