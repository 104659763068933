import { unitInfoSel } from '@unit';

import { moduleName, mbcFilters } from '../constants';
import { meritBadgeCounselorsModuleSel } from '../../common';

const moduleSel = state => meritBadgeCounselorsModuleSel(state)[moduleName];

export const itemsSel = state => moduleSel(state).items;
export const totalSel = state => moduleSel(state).total;
export const loadingSel = state => moduleSel(state).loading;
export const pageSel = state => moduleSel(state).page;
export const pageSizeMobileSel = state => moduleSel(state).pageSizeMobile;
export const expandedRowSel = state => moduleSel(state).expandedRow;
export const searchSel = state => moduleSel(state).search;
export const unitZipcodeSel = state => {
  const { primaryAddress = {} } = unitInfoSel(state);
  return primaryAddress.zip5 || '';
};
export const filtersSel = state => moduleSel(state).filter;
export const meritBadgesFilterSel = state =>
  filtersSel(state)[mbcFilters.MERIT_BADGES];
export const proximityFilterSel = state =>
  filtersSel(state)[mbcFilters.PROXIMITY];
export const availabilityFilterSel = state =>
  filtersSel(state)[mbcFilters.AVAILABILITY];

export const mbcToEditSel = state => moduleSel(state).mbcToEdit;
export const editModalOpenSel = state => moduleSel(state).editModalOpen;
export const editLoadingSel = state => moduleSel(state).editLoading;
