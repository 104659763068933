import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { CouncilDistrictBadge, ProgramLogo, S } from '@shared';

import styles from './RolePickerRole.less';
import RolePickerBaseRole from './components/RolePickerBaseRole';

function RolePickerCouncilRole({
  active,
  unitRole,
  disabled,
  org,
  onProfileChange,
}) {
  const positionNames = org.role;
  const { unitTypeId } = unitRole || {};
  return (
    <RolePickerBaseRole
      org={org}
      active={active}
      disabled={disabled}
      onProfileChange={onProfileChange}
    >
      <div className={styles.councilRoleLogoWrapper}>
        <ProgramLogo programId={unitTypeId} className={styles.logo} />
        <CouncilDistrictBadge
          organizationTypeId={org.organizationTypeId}
          councilStructureTypeId={org.councilStructureTypeId}
        />
      </div>
      <div className={styles.description}>
        <S size="4">
          <FormattedMessage id="context.RolePickerRole.bsaEmployee" />
        </S>
        <S size="4">{org.organizationName}</S>
        <S size="5">
          <FormattedMessage id="context.RolePicker.position" />: {positionNames}
        </S>
      </div>
    </RolePickerBaseRole>
  );
}

RolePickerCouncilRole.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  org: PropTypes.object.isRequired,
  unitRole: PropTypes.object,
  onProfileChange: PropTypes.func.isRequired,
};

export default RolePickerCouncilRole;
