import React from 'react';
import PropTypes from 'prop-types';

import CustomCheckbox from './CustomCheckbox';

const AllDayCheckbox = ({
  form: { getFieldDecorator, setFieldsValue },
  ...props
}) => (
  <React.Fragment>
    {getFieldDecorator('allDay', {
      valuePropName: 'checked',
      initialValue: true,
    })(<CustomCheckbox setFieldsValue={setFieldsValue} {...props} />)}
  </React.Fragment>
);

AllDayCheckbox.propTypes = {
  form: PropTypes.object.isRequired,
};

export default AllDayCheckbox;
