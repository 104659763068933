import { lazier } from '@shared';

import PersonProfilePagePlaceholder from './PersonProfilePagePlaceholder';

export { PersonProfilePagePlaceholder };

export const PersonProfilePage = lazier(
  () => import('./PersonProfilePage'),
  PersonProfilePagePlaceholder,
);
