import { combineEpics } from 'redux-observable';

import { epics as activitiesEpics } from '../activities';
import { epics as advancementEpics } from '../advancement';
import { epics as approveAdvancementsEpics } from '../approveAdvancements';
import campoutsEpics from '../campouts/duck/epics';
import { epics as commonEpics } from '../common';
import { epics as declineAdvancementsEpics } from '../declineAdvancements';
import { epics as eagleProjectEpics } from '../eagleProject';
import { epics as editAdvancementEpics } from '../editAdvancement';
import { epics as hikesEpics } from '../hikes';
import { epics as longCruiseEpics } from '../longCruise';
import { epics as serviceEpics } from '../service';
import startAdvancementEpics from '../startAdvancement/duck/epics';
import { epics as unapproveAdvancementEpics } from '../unapproveAdvancement';

export default combineEpics(
  commonEpics,
  advancementEpics,
  activitiesEpics,
  editAdvancementEpics,
  approveAdvancementsEpics,
  declineAdvancementsEpics,
  serviceEpics,
  campoutsEpics,
  eagleProjectEpics,
  hikesEpics,
  unapproveAdvancementEpics,
  startAdvancementEpics,
  longCruiseEpics,
);
