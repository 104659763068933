import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Modal, T, S } from '@shared';
import { modalVisibleSel, closeUploadModal } from '../../duck';
import UploadForm from '../UploadForm';
import styles from './UploadModal.less';

class UploadModal extends React.PureComponent {
  render() {
    const { visible, onCancel } = this.props;

    return (
      <Modal noPadding visible={visible} width="100%" onCancel={onCancel}>
        <T size="4" className={styles.importMessage}>
          <FormattedMessage id="advancement.TabCard.importFile" />
        </T>
        <S size="3" className={styles.descriptionMessage}>
          <FormattedHTMLMessage id="advancement.TabCard.importFile.Description" />
        </S>
        <UploadForm onCancel={onCancel} />
      </Modal>
    );
  }
}

UploadModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapState = state => ({
  visible: modalVisibleSel(state),
});

const mapDispatch = dispatch => ({
  onCancel: () => dispatch(closeUploadModal()),
});

export default connect(mapState, mapDispatch)(UploadModal);
