import { default as esbApiService } from '../../utils/http/esbApiService';

/**
 * @esbEndpoint GET /lookups/advancements/youthLeadershipPositions
 */
export const getYouthLeadershipPositions$ = () =>
  esbApiService.get$('/lookups/advancements/youthLeadershipPositions', {
    gtm: {
      label: '/lookups/advancements/youthLeadershipPositions',
    },
  });
