import React, { useEffect, useState } from 'react';

import UpdateNotificationContent from './UpdateNotificationContent';

/* eslint-disable no-console */
const UpdateApp: React.FC = () => {
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    async function update() {
      try {
        const registration = await navigator.serviceWorker.register(
          '/service-worker.js',
          { scope: '/' },
        );

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (!installingWorker) return;

          installingWorker.onstatechange = async () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New content is available; please refresh.');
                setNeedsUpdate(true);
              } else {
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      } catch (e) {
        console.error(e);
      }
    }

    const hasServiceWorker = 'serviceWorker' in navigator;
    if (!hasServiceWorker) return;

    update();
  }, []);

  return <UpdateNotificationContent visible={needsUpdate} />;
};

export default UpdateApp;
