import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { sorterPropTypes } from '../../constants';
import SortIcon from '../SortIcon';
import styles from './TableHeadingSort.less';

const TableHeadingSort = ({ name, sorter, children, className }) => {
  const { columnKey, order } = sorter;
  const isSorted = name === columnKey;
  const classNames = cn(styles.heading, isSorted ? order : 'nosort', className);

  return (
    <div className={classNames}>
      {children}{' '}
      <SortIcon className={styles.sortIcon} order={isSorted ? order : null} />
    </div>
  );
};

TableHeadingSort.propTypes = {
  name: PropTypes.string.isRequired,
  sorter: sorterPropTypes,
  className: PropTypes.string,
  children: PropTypes.node,
};

TableHeadingSort.defaultProps = {
  sorter: {},
};

export default TableHeadingSort;
