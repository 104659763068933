import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row } from '../bsaComponents';
import Button from '../Button';
import CollapseDetails from '../CollapseDetails';
import S from '../S';
import Person from './Person';
import styles from './PersonList.less';

const PersonList = React.memo(function PersonList({
  notAllowedToRemove,
  allowDeselectPersons,
  onDeselectPerson,
  persons,
  onRestorePerson,
  onCheckPerson,
  isRestore,
  onOpenModal,
}) {
  const { youths, adults, parents, guests } = persons;
  const collapseDetails = useCallback(
    (persons, translationKey) => (
      <CollapseDetails
        header={
          <S size={'4'}>
            <FormattedMessage id={translationKey} /> ({persons.length})
          </S>
        }
      >
        {persons.map(person => (
          <Person
            key={person.userId}
            data={person}
            closeAction={
              !notAllowedToRemove.includes(person.userId) &&
              allowDeselectPersons
            }
            onCloseAction={onDeselectPerson}
            isRestore={isRestore}
            onRestoreAction={onRestorePerson}
            onCheckAction={onCheckPerson}
          />
        ))}
        {translationKey === 'shared.guests' && (
          <Row justify="center">
            <Button
              ghost
              noBorder
              color="info"
              size="small"
              uppercase={false}
              block
              className={styles.centerUnderline}
              onClick={onOpenModal}
            >
              <FormattedMessage id="shared.guests.addButton" />
            </Button>
          </Row>
        )}
      </CollapseDetails>
    ),
    [
      notAllowedToRemove,
      allowDeselectPersons,
      onDeselectPerson,
      onRestorePerson,
      onCheckPerson,
      isRestore,
      onOpenModal,
    ],
  );

  return (
    <React.Fragment>
      {collapseDetails(youths, 'shared.youths')}
      {collapseDetails(adults, 'shared.leaders')}
      {collapseDetails(parents, 'shared.parents')}
      {collapseDetails(guests, 'shared.guests')}
    </React.Fragment>
  );
});

PersonList.propTypes = {
  notAllowedToRemove: PropTypes.array,
  allowDeselectPersons: PropTypes.bool,
  onDeselectPerson: PropTypes.func,
  onRestorePerson: PropTypes.func,
  onCheckPerson: PropTypes.func,
  isRestore: PropTypes.bool,
  onOpenModal: PropTypes.func,
  persons: PropTypes.object,
};

PersonList.defaultProps = {
  notAllowedToRemove: [],
  allowDeselectPersons: false,
  onDeselectPerson: () => {},
  onRestorePerson: () => {},
  onCheckPerson: () => {},
  isRestore: false,
  onOpenModal: () => {},
  persons: [],
};

export default PersonList;
