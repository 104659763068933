import React, { useMemo } from 'react';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectedRowKeysSel,
  setSelectedRowKeys,
} from '@modules/advancement/pendingItems/duck';
import {
  GroupedPendingItem,
  PendingItem,
} from '@modules/advancement/pendingItems/types';
import { groupPendingItems } from '@modules/advancement/pendingItems/utils';
import { ApproveAdvancementsButton } from '@modules/progress/approveAdvancements';
import { useGetAdvancementHistoryQuery } from '@modules/rtk/esb-api';
import {
  PaginationTotal,
  hasPermissionSel,
  packRosterItemsCountSel,
  permissions,
  selectedOrganizationSel,
  shouldFilterUsersBySubunitsSel,
} from '@modules/shared';
import Table from '@modules/shared/components/Table';

import { userSubUnitMembersSel } from '../../../../packRoster/duck';
import styles from './PendingItemsTable.less';
import { groupedColumns } from './PendingRequirementsColumns';

interface PendingRequirementsTableProps {
  // onGoToYouthProfile: (userId: number, activityType: string) => () => void;
  // onGoToAdultProfile: (userId: number, activityType: string) => () => void;
  items?: GroupedPendingItem[];

  rowSelection?: {
    selectedRowKeys: string[];
    onChange: (rowKeys: string) => void;
    onSelectAll: (isSelectAll: boolean) => void;
  };

  onGoToYouthProfile: (
    userId: number,
    activityType: string,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  onGoToAdultProfile: (
    userId: number,
    activityType: string,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const PendingRequirementsTableOriginal: React.FC<
  PendingRequirementsTableProps
> = props => {
  const { onGoToAdultProfile, onGoToYouthProfile } = props;
  const selectedRowKeys = useSelector(selectedRowKeysSel);
  const selectedOrg = useSelector(selectedOrganizationSel);
  const rosterItemsCount = useSelector(packRosterItemsCountSel);
  const shouldFilterUsersBySubunits = useSelector(
    shouldFilterUsersBySubunitsSel,
  );

  const userSubUnitMembers = useSelector(userSubUnitMembersSel);

  const {
    data: pendingItemsData,
    isLoading,
    isFetching,
  } = useGetAdvancementHistoryQuery(
    {
      params: { perPage: rosterItemsCount },
      body: {
        status: ['Started', 'Completed'],
        showAdults: true,
        showYouths: true,
        organizationGuid: selectedOrg?.organizationGuid,
      },
    },
    { skip: !selectedOrg?.organizationGuid },
  );
  const canApprove = useSelector(state =>
    hasPermissionSel(state, permissions.APPROVE_ADVANCEMENTS),
  );

  const userSubUnitUsersIds = useMemo(
    () => userSubUnitMembers.map(member => member.userId),
    [userSubUnitMembers],
  );

  const items = useMemo(() => {
    if (!pendingItemsData) return [];
    let pendingItems = pendingItemsData;
    if (shouldFilterUsersBySubunits) {
      pendingItems = pendingItemsData.filter(item =>
        userSubUnitUsersIds.includes(item.userId),
      );
    }
    const values = groupPendingItems(pendingItems);
    return values;
  }, [pendingItemsData, shouldFilterUsersBySubunits, userSubUnitUsersIds]);

  const areAnyAdvancementsSelected = selectedRowKeys.length > 0;

  const selectedAdvancements = useMemo(() => {
    const setKeys = new Set(selectedRowKeys);

    return items
      .flatMap(({ children }) => children)
      .filter(item => setKeys.has(item.key))
      .filter(Boolean);
  }, [items, selectedRowKeys]);

  const dispatch = useDispatch();

  const renderTitle = () => (
    <>
      {canApprove && (
        <ApproveAdvancementsButton
          className={styles.approveAdvancements}
          advancementStatus={null}
          disabled={!areAnyAdvancementsSelected}
          personsAdvancements={selectedAdvancements}
        />
      )}
    </>
  );

  return (
    <div className={styles.tableContainer}>
      <Table
        className={styles.table}
        dataSource={items}
        loading={isLoading || isFetching}
        rowKey="key"
        title={renderTitle}
        pagination={{
          showTotal: () => {
            const totalSelected = selectedRowKeys.filter(
              (item: string) => !item?.includes('group'),
            ).length;
            const totalNoGroup = items.flatMap(item => item.children)?.length;

            return (
              <PaginationTotal total={totalNoGroup} selected={totalSelected} />
            );
          },
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onSelect(
            record: GroupedPendingItem | PendingItem,
            selected: boolean,
          ) {
            const isGroup = 'groupId' in record;
            if (selected) {
              // Toggle on
              if (isGroup) {
                // Select all items for MID
                const selected = record.children.map(item => item.key);
                dispatch(
                  setSelectedRowKeys([
                    ...selectedRowKeys,
                    ...selected,
                    record.key,
                  ]),
                );
              } else {
                dispatch(setSelectedRowKeys([...selectedRowKeys, record.key]));
              }
            } else if (isGroup) {
              const selectedItems = new Set<string | number>(selectedRowKeys);
              selectedItems.delete(record.key);
              record.children.forEach(item => {
                selectedItems.delete(item.key);
              });
              dispatch(setSelectedRowKeys(Array.from(selectedItems)));
            } else {
              const currentSelectedKeys = new Set<string | number>(
                selectedRowKeys,
              );
              currentSelectedKeys.delete(record.key);
              dispatch(setSelectedRowKeys(Array.from(currentSelectedKeys)));
            }
          },
        }}
        columns={groupedColumns({
          onGoToYouthProfile,
          onGoToAdultProfile,
        })}
        rowClassName={(row: GroupedPendingItem) => {
          if (isEmpty(row?.children)) return styles.rowItem;
          else return styles.rowItemGroup;
        }}
        size="small"
      />
    </div>
  );
};
export const PendingRequirementsTable = React.memo(
  PendingRequirementsTableOriginal,
);
