import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@shared/components';
import { intl } from '@shared/localization';

import { openUnapproveModal } from '../../duck';

class UnapproveAdvancementButton extends React.PureComponent {
  handleClick = event => {
    event.stopPropagation();
    this.props.onOpenUnapproveAdvancement();
  };
  render() {
    const { children, uppercase } = this.props;

    return (
      <Button
        color="info"
        uppercase={uppercase}
        ghost
        noBorder
        fitText
        onClick={this.handleClick}
      >
        {children ||
          intl.formatMessage({
            id: 'unapproveAdvancement.UnapproveAdvancementButton.message',
          })}
      </Button>
    );
  }
}

UnapproveAdvancementButton.propTypes = {
  // provided by component itself
  onOpenUnapproveAdvancement: PropTypes.func.isRequired,
  // provided by parent
  advancement: PropTypes.object.isRequired,
  youthName: PropTypes.string.isRequired,
  children: PropTypes.node,
  uppercase: PropTypes.bool,
};

UnapproveAdvancementButton.defaultProps = {
  uppercase: false,
};

const mapDispatch = (dispatch, { advancement, youthName }) => ({
  onOpenUnapproveAdvancement: () =>
    dispatch(openUnapproveModal(advancement, youthName)),
});

export default connect(null, mapDispatch)(UnapproveAdvancementButton);
